import { getDistance } from "geolib";

export const useMap = () => {
    // Define a function to find locations within a certain distance from a given location
    function findLocationsWithinDistance(locations, center, distance) {
      const nearbyLocations = [];
      locations.forEach((location) => {
        const dist = getDistance(center, location.coords);
        if (dist <= distance) {
          nearbyLocations.push({ location: location, distance: dist });
        }
      });
      console.log(nearbyLocations);
      return nearbyLocations;
    }

  return { findLocationsWithinDistance };
};
