import { useLocation } from "wouter";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setSignUp, setLoader } from "../redux/redux";
import { useLoader } from "../hooks/useLoader";
import { useLogin } from "../hooks/useLogin";

const PageLoader = () => {
  //redux
  const dispatch = useDispatch();
  const [location, setLocation] = useLocation();
  const user = useSelector((state) => state.user);
  const signupWaitlist = useSelector((state) => state.signup);
  const topProperties = useSelector((state) => state.topProperties);

  const { loadTopProperties, loadLikes, loadNotifications, loadUsersProperties, loadProperties } = useLoader();
  const { loginLastUser } = useLogin();

  useEffect(() => {
    // Log the last user
    const fetchLastUser = async () => {
      loadTopProperties();
      if (user) {
        loadLikes(user._id);
        loadNotifications(user._id);
      }
      if (!user) {
        const localUser = JSON.parse(localStorage.getItem("user"));
        if (localUser) {
          if (localUser?.user) {
            loadLikes(localUser.user._id);
            loadNotifications(localUser.user._id);
            loginLastUser(localUser.user._id);
          } else {
            loadLikes(localUser._id);
            loadNotifications(localUser._id);
            loginLastUser(localUser._id);
          }
        }
      }
      if (!signupWaitlist) {
        const localSignup = JSON.parse(localStorage.getItem("signup"));
        if (localSignup) {
          dispatch(setSignUp(localSignup));
        }
      }
      if (topProperties) {
        setLocation("/explore");
        dispatch(setLoader("done"));
        if (user) {
          loadUsersProperties(user._id);
        }
      };
      loadProperties();
    };
    fetchLastUser();
    console.log(location);
  }, [ user, topProperties, location]);

  // Render the main content
  return (
    <div>
      <div className="logo-loader"></div>
      <div className="page-loader"></div>
    </div>
  );
};

export default PageLoader;
