export const offlineLoader = () => {
  const loadMap = async () => {
    const json = [
      {
        coords: {
          lat: -18.9058572,
          lng: 47.5244734,
        },
        _id: "64f75284ef8f606073e981e9",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "antanimalalaka analakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9094364,
          lng: 47.5149616,
        },
        _id: "64f75284ef8f606073e981ea",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "ambalavao isotry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9138455,
          lng: 47.523246,
        },
        _id: "64f75284ef8f606073e981eb",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "ambatonakanga ambohitsorohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9159131,
          lng: 47.530611,
        },
        _id: "64f75284ef8f606073e981ec",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "ambatovinaky",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9061625,
          lng: 47.5266215,
        },
        _id: "64f75284ef8f606073e981ed",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "soarano ambondrona ambodifilao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9067336,
          lng: 47.53183869999999,
        },
        _id: "64f75284ef8f606073e981ee",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "ampandrana ankadivato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9157252,
          lng: 47.5257009,
        },
        _id: "64f75284ef8f606073e981ef",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "amparibe ambohidahy mahamasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9090839,
          lng: 47.523246,
        },
        _id: "64f75284ef8f606073e981f0",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "amboasarikely ambatomena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9124352,
          lng: 47.5097461,
        },
        _id: "64f75284ef8f606073e981f1",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "anatihazo isotry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9163506,
          lng: 47.5109732,
        },
        _id: "64f75284ef8f606073e981f2",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "andavamamba anatihazo I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9199214,
          lng: 47.5109732,
        },
        _id: "64f75284ef8f606073e981f3",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "andavamamba anatihazo II",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9149717,
          lng: 47.5060649,
        },
        _id: "64f75284ef8f606073e981f4",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "andavamamba anjezika I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9149717,
          lng: 47.5060649,
        },
        _id: "64f75284ef8f606073e981f5",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "andavamamba anjezika I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9072196,
          lng: 47.5017705,
        },
        _id: "64f75284ef8f606073e981f6",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "andohatapenaka I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9072196,
          lng: 47.5017705,
        },
        _id: "64f75284ef8f606073e981f7",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "andohatapenaka I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9000792,
          lng: 47.5017705,
        },
        _id: "64f75284ef8f606073e981f8",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "andohatapenaka III",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9101879,
          lng: 47.51128,
        },
        _id: "64f75284ef8f606073e981f9",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "andranomanalina afovoany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9113782,
          lng: 47.51128,
        },
        _id: "64f75284ef8f606073e981fa",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "andranomanalina I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9087472,
          lng: 47.51250719999999,
        },
        _id: "64f75284ef8f606073e981fb",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "andranomanalina isotry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8966967,
          lng: 47.5066784,
        },
        _id: "64f75284ef8f606073e981fc",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "ankasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9114012,
          lng: 47.5060649,
        },
        _id: "64f75284ef8f606073e981fd",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "antetezanafovoany I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.906723,
          lng: 47.5111266,
        },
        _id: "64f75284ef8f606073e981fe",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "antetezanafovoany II",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9000865,
          lng: 47.5155752,
        },
        _id: "64f75284ef8f606073e981ff",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "antohomadinika afovoany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.905983,
          lng: 47.5122004,
        },
        _id: "64f75284ef8f606073e98200",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "antohomadinika atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8963044,
          lng: 47.515882,
        },
        _id: "64f75284ef8f606073e98201",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "antohomadinika avaratra antani",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8988418,
          lng: 47.5122004,
        },
        _id: "64f75284ef8f606073e98202",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "antohomadinika antsalovana faa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9022556,
          lng: 47.515882,
        },
        _id: "64f75284ef8f606073e98203",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "antohomadinika IIIG hangar",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9079015,
          lng: 47.5137344,
        },
        _id: "64f75284ef8f606073e98204",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "avaratetezana bekiraro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9080189,
          lng: 47.5109732,
        },
        _id: "64f75284ef8f606073e98205",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "cite ambodin'isotry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9137356,
          lng: 47.5164956,
        },
        _id: "64f75284ef8f606073e98206",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "cite ampefiloha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9066014,
          lng: 47.5069852,
        },
        _id: "64f75284ef8f606073e98207",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "67ha afovoany andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9093655,
          lng: 47.507292,
        },
        _id: "64f75284ef8f606073e98208",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "67ha atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9022245,
          lng: 47.507292,
        },
        _id: "64f75284ef8f606073e98209",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "67ha avaratra andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9028352,
          lng: 47.5115868,
        },
        _id: "64f75284ef8f606073e9820a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "67ha avaratra atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.903289,
          lng: 47.51956389999999,
        },
        _id: "64f75284ef8f606073e9820b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "lalamby sy ny manodidina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9099606,
          lng: 47.530611,
        },
        _id: "64f75284ef8f606073e9820c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "faravohitra ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -18.911997,
          lng: 47.52938349999999,
        },
        _id: "64f75284ef8f606073e9820d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "faravohitra mandrosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9116213,
          lng: 47.51956389999999,
        },
        _id: "64f75284ef8f606073e9820e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "isoraka ampatsakana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9068599,
          lng: 47.51956389999999,
        },
        _id: "64f75284ef8f606073e9820f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "fiata",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9122789,
          lng: 47.51342760000001,
        },
        _id: "64f75284ef8f606073e98210",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "manarintsoa afovoany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9137587,
          lng: 47.51128,
        },
        _id: "64f75284ef8f606073e98211",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "manarintsoa anatihazo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9178075,
          lng: 47.5140412,
        },
        _id: "64f75284ef8f606073e98212",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "manarintsoa atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9103016,
          lng: 47.5132742,
        },
        _id: "64f75284ef8f606073e98213",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "manarintsoa isotry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9039539,
          lng: 47.5272353,
        },
        _id: "64f75284ef8f606073e98214",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "1er arrondissement",
        fokontany: "soarano ambondrona tsiazotafo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9183253,
          lng: 47.53920489999999,
        },
        _id: "64f75284ef8f606073e98215",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "faliarivo ambanidia",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9142518,
          lng: 47.5416606,
        },
        _id: "64f75284ef8f606073e98216",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "antanimora ampasanimalo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9152941,
          lng: 47.5358286,
        },
        _id: "64f75284ef8f606073e98217",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "antsahabe ankorahotra ankazoto",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9159679,
          lng: 47.533987,
        },
        _id: "64f75284ef8f606073e98218",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "ambohitsiroa VN",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9180983,
          lng: 47.5352147,
        },
        _id: "64f75284ef8f606073e98219",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "ankazotokana ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9175489,
          lng: 47.5481072,
        },
        _id: "64f75284ef8f606073e9821a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "tsiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.923088,
          lng: 47.53920489999999,
        },
        _id: "64f75284ef8f606073e9821b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "volosarika ambanidia",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9201741,
          lng: 47.53306629999999,
        },
        _id: "64f75284ef8f606073e9821c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "andafiavaratra ambavahadimitaf",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9223438,
          lng: 47.5333732,
        },
        _id: "64f75284ef8f606073e9821d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "manjakamiadana antsahondra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9236989,
          lng: 47.5435024,
        },
        _id: "64f75284ef8f606073e9821e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "ambatoroka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.92478,
          lng: 47.5367494,
        },
        _id: "64f75284ef8f606073e9821f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "miandrarivo ambanidia",
        __v: 0,
      },
      {
        coords: {
          lat: -18.920374,
          lng: 47.5656092,
        },
        _id: "64f75284ef8f606073e98220",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "ambolokandrina 5A",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9288379,
          lng: 47.5533265,
        },
        _id: "64f75284ef8f606073e98221",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "andohan'i mandroseza ambohibat",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9268311,
          lng: 47.5631524,
        },
        _id: "64f75284ef8f606073e98222",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "ambohipo tanana ampahateza and",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9295656,
          lng: 47.5315318,
        },
        _id: "64f75284ef8f606073e98223",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "ambohipotsy ambohimitsimbina A",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9312735,
          lng: 47.5333732,
        },
        _id: "64f75284ef8f606073e98224",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "andohamandry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9307333,
          lng: 47.5367494,
        },
        _id: "64f75284ef8f606073e98225",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "manakambahiny ankerakely ambat",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9300758,
          lng: 47.5428884,
        },
        _id: "64f75284ef8f606073e98226",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "ambohimiandra fenomanana antsa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9341812,
          lng: 47.5490282,
        },
        _id: "64f75284ef8f606073e98227",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "mandroseza afovoany mandroseza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9410598,
          lng: 47.5459582,
        },
        _id: "64f75284ef8f606073e98228",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "mahazoarivo ambohidraserika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.93534,
          lng: 47.5404327,
        },
        _id: "64f75284ef8f606073e98229",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "morarano andrangaranga ambatol",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9428297,
          lng: 47.5416606,
        },
        _id: "64f75284ef8f606073e9822a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "ambohitsoa ankazolava antanamb",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9414498,
          lng: 47.5367494,
        },
        _id: "64f75284ef8f606073e9822b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "androndrabe ampamantanana ambo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9495179,
          lng: 47.5336801,
        },
        _id: "64f75284ef8f606073e9822c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "2e arrondissement",
        fokontany: "androndrakely saropody antonta",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9028177,
          lng: 47.530611,
        },
        _id: "64f75284ef8f606073e9822d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "antaninandro ampandrana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8834777,
          lng: 47.5189502,
        },
        _id: "64f75284ef8f606073e9822e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "ankorondrano andranomahery",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8879233,
          lng: 47.5263146,
        },
        _id: "64f75284ef8f606073e9822f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "ankorondrano atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8913862,
          lng: 47.51956389999999,
        },
        _id: "64f75284ef8f606073e98230",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "ankorondrano andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8837929,
          lng: 47.5115868,
        },
        _id: "64f75284ef8f606073e98231",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "ankazomanga andraharo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8928173,
          lng: 47.527849,
        },
        _id: "64f75284ef8f606073e98232",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "ambodivona ankadifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8915436,
          lng: 47.515882,
        },
        _id: "64f75284ef8f606073e98233",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "tsaramasay",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8951833,
          lng: 47.5235529,
        },
        _id: "64f75284ef8f606073e98234",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "ankadifotsy befelatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.894957,
          lng: 47.51956389999999,
        },
        _id: "64f75284ef8f606073e98235",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "ambohibary antanimena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8944847,
          lng: 47.530611,
        },
        _id: "64f75284ef8f606073e98236",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "andravoahangy tsena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8957933,
          lng: 47.527849,
        },
        _id: "64f75284ef8f606073e98237",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "mandialaza ambodivona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8975831,
          lng: 47.5416606,
        },
        _id: "64f75284ef8f606073e98238",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "soavinandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8982134,
          lng: 47.5269284,
        },
        _id: "64f75284ef8f606073e98239",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "mandialaza ankadifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8991773,
          lng: 47.5229391,
        },
        _id: "64f75284ef8f606073e9823a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "antanimena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.896295,
          lng: 47.525394,
        },
        _id: "64f75284ef8f606073e9823b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "ankadifotsy antanifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.896836,
          lng: 47.5220186,
        },
        _id: "64f75284ef8f606073e9823c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "ankaditapaka avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8985184,
          lng: 47.5290766,
        },
        _id: "64f75284ef8f606073e9823d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "mandialaza ambatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9000531,
          lng: 47.5303041,
        },
        _id: "64f75284ef8f606073e9823e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "andravoahangy andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8987446,
          lng: 47.53306629999999,
        },
        _id: "64f75284ef8f606073e9823f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "mahavoky",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9001466,
          lng: 47.5327594,
        },
        _id: "64f75284ef8f606073e98240",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "andravoahangy atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9002498,
          lng: 47.5257009,
        },
        _id: "64f75284ef8f606073e98241",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "behoririka ambatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9001902,
          lng: 47.54565119999999,
        },
        _id: "64f75284ef8f606073e98242",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "avaradoha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9008056,
          lng: 47.5266215,
        },
        _id: "64f75284ef8f606073e98243",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "ambatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8999447,
          lng: 47.5235529,
        },
        _id: "64f75284ef8f606073e98244",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "behoririka ankaditapaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.906606,
          lng: 47.5441163,
        },
        _id: "64f75284ef8f606073e98245",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "betongolo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9038509,
          lng: 47.534294,
        },
        _id: "64f75284ef8f606073e98246",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "ampandrana besarety",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9009287,
          lng: 47.5376702,
        },
        _id: "64f75284ef8f606073e98247",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "besarety",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9004069,
          lng: 47.5220186,
        },
        _id: "64f75284ef8f606073e98248",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "behoririka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9043133,
          lng: 47.5327594,
        },
        _id: "64f75284ef8f606073e98249",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "ampandrana andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9045691,
          lng: 47.5453442,
        },
        _id: "64f75284ef8f606073e9824a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "ambohitrakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9045397,
          lng: 47.5367494,
        },
        _id: "64f75284ef8f606073e9824b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "ampandrana atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9084015,
          lng: 47.5346009,
        },
        _id: "64f75284ef8f606073e9824c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "ankadivato IIL",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9111029,
          lng: 47.5410466,
        },
        _id: "64f75284ef8f606073e9824d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "ampahibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9121062,
          lng: 47.5361355,
        },
        _id: "64f75284ef8f606073e9824e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "3e arrondissement",
        fokontany: "antsakaviro ambodirotra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9223348,
          lng: 47.51956389999999,
        },
        _id: "64f75284ef8f606073e9824f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "andrefan'ambohijanahary IIIG-I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9136708,
          lng: 47.4993168,
        },
        _id: "64f75284ef8f606073e98250",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "ambodirano ampefiloha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9231917,
          lng: 47.4993168,
        },
        _id: "64f75284ef8f606073e98251",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "ilanivato ampasika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9259946,
          lng: 47.4987033,
        },
        _id: "64f75284ef8f606073e98252",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "ampangabe anjanakinifolo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9285302,
          lng: 47.4950229,
        },
        _id: "64f75284ef8f606073e98253",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "anosipatrana andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9295651,
          lng: 47.4987033,
        },
        _id: "64f75284ef8f606073e98254",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "anosipatrana atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.926874,
          lng: 47.5060649,
        },
        _id: "64f75284ef8f606073e98255",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "ivolaniray",
        __v: 0,
      },
      {
        coords: {
          lat: -18.922113,
          lng: 47.5060649,
        },
        _id: "64f75284ef8f606073e98256",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "andavamamba ambilanibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9258729,
          lng: 47.5109732,
        },
        _id: "64f75284ef8f606073e98257",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "anosibe andrefana II",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9229916,
          lng: 47.51342760000001,
        },
        _id: "64f75284ef8f606073e98258",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "anosibe andrefana I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9250604,
          lng: 47.5207912,
        },
        _id: "64f75284ef8f606073e98259",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "andrefan'ambohijanahary IIIH-I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9236357,
          lng: 47.5263146,
        },
        _id: "64f75284ef8f606073e9825a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "mahamasina atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9191401,
          lng: 47.52938349999999,
        },
        _id: "64f75284ef8f606073e9825b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "tsimialonjafy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9223279,
          lng: 47.5290766,
        },
        _id: "64f75284ef8f606073e9825c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "ambanin'ampamarinana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9250928,
          lng: 47.52938349999999,
        },
        _id: "64f75284ef8f606073e9825d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "ankadilalana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9318914,
          lng: 47.52815589999999,
        },
        _id: "64f75284ef8f606073e9825e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "tsarafaritra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9323923,
          lng: 47.5257009,
        },
        _id: "64f75284ef8f606073e9825f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "fiadanana IIIL",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9337386,
          lng: 47.5220186,
        },
        _id: "64f75284ef8f606073e98260",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "fiadanana IIIN",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9270185,
          lng: 47.5214049,
        },
        _id: "64f75284ef8f606073e98261",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "mananjara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9323589,
          lng: 47.5171093,
        },
        _id: "64f75284ef8f606073e98262",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "andrefan'i mananjara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9264068,
          lng: 47.5171093,
        },
        _id: "64f75284ef8f606073e98263",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "anosibe ambohibarikely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9263513,
          lng: 47.5137344,
        },
        _id: "64f75284ef8f606073e98264",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "mandrangobato I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9309792,
          lng: 47.5122004,
        },
        _id: "64f75284ef8f606073e98265",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "mandrangobato II",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9334032,
          lng: 47.5017705,
        },
        _id: "64f75284ef8f606073e98266",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "anosizato atsinanana II",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9388544,
          lng: 47.5042244,
        },
        _id: "64f75284ef8f606073e98267",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "anosizato atsinanana I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9385441,
          lng: 47.5115868,
        },
        _id: "64f75284ef8f606073e98268",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "angarangarana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9375434,
          lng: 47.5164956,
        },
        _id: "64f75284ef8f606073e98269",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "madera namontana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9385007,
          lng: 47.5220186,
        },
        _id: "64f75284ef8f606073e9826a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "soanierana III-I cite gare",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9391907,
          lng: 47.5244734,
        },
        _id: "64f75284ef8f606073e9826b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "soanierana IIIJ",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9393021,
          lng: 47.5312248,
        },
        _id: "64f75284ef8f606073e9826c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "ankaditoho marohoho",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9453765,
          lng: 47.5189502,
        },
        _id: "64f75284ef8f606073e9826d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "andrefan'ankadimbahoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9473771,
          lng: 47.5091326,
        },
        _id: "64f75284ef8f606073e9826e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "4e arrondissement",
        fokontany: "ankazotoho anosimahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8921325,
          lng: 47.53920489999999,
        },
        _id: "64f75284ef8f606073e9826f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8595164,
          lng: 47.5226323,
        },
        _id: "64f75284ef8f606073e98270",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "soavimasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8621075,
          lng: 47.5361355,
        },
        _id: "64f75284ef8f606073e98271",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "androhibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8749604,
          lng: 47.5140412,
        },
        _id: "64f75284ef8f606073e98272",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8698838,
          lng: 47.5214049,
        },
        _id: "64f75284ef8f606073e98273",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "alarobia amboniloha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8753324,
          lng: 47.5238597,
        },
        _id: "64f75284ef8f606073e98274",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "ivandry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8748573,
          lng: 47.5349078,
        },
        _id: "64f75284ef8f606073e98275",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "ambodivoanjo ambohijatovo fara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8709419,
          lng: 47.5474932,
        },
        _id: "64f75284ef8f606073e98276",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "analamahitsy cite",
        __v: 0,
      },
      {
        coords: {
          lat: -18.875069,
          lng: 47.5484142,
        },
        _id: "64f75284ef8f606073e98277",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "analamahitsy tanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8754385,
          lng: 47.5582392,
        },
        _id: "64f75284ef8f606073e98278",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8835341,
          lng: 47.5361355,
        },
        _id: "64f75284ef8f606073e98279",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "amboditsiry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8869193,
          lng: 47.5312248,
        },
        _id: "64f75284ef8f606073e9827a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "manjakaray IIC",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8895653,
          lng: 47.534294,
        },
        _id: "64f75284ef8f606073e9827b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "manjakaray IIB",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8912577,
          lng: 47.53183869999999,
        },
        _id: "64f75284ef8f606073e9827c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "manjakaray IID",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8939434,
          lng: 47.533987,
        },
        _id: "64f75284ef8f606073e9827d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "anjanahary IIS",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8958618,
          lng: 47.5355217,
        },
        _id: "64f75284ef8f606073e9827e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "anjanahary IIO",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8937067,
          lng: 47.5395119,
        },
        _id: "64f75284ef8f606073e9827f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "anjanahary IIA",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8965503,
          lng: 47.5379771,
        },
        _id: "64f75284ef8f606073e98280",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "anjanahary IIN",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8885094,
          lng: 47.5496422,
        },
        _id: "64f75284ef8f606073e98281",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "nanisana iadiambola",
        __v: 0,
      },
      {
        coords: {
          lat: -18.889039,
          lng: 47.5557828,
        },
        _id: "64f75284ef8f606073e98282",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "ankerana ankadindramamy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8945694,
          lng: 47.5563969,
        },
        _id: "64f75284ef8f606073e98283",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "ambohidahy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.896923,
          lng: 47.5478002,
        },
        _id: "64f75284ef8f606073e98284",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "ambatokaranana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8964207,
          lng: 47.5502562,
        },
        _id: "64f75284ef8f606073e98285",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "ambohimirary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8992534,
          lng: 47.5582392,
        },
        _id: "64f75284ef8f606073e98286",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "ampanotokana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.903565,
          lng: 47.5502562,
        },
        _id: "64f75284ef8f606073e98287",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9060029,
          lng: 47.5545597,
        },
        _id: "64f75284ef8f606073e98288",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "andraisoro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9069289,
          lng: 47.5643808,
        },
        _id: "64f75284ef8f606073e98289",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "5e arrondissement",
        fokontany: "ambatomaro antsobolo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8707239,
          lng: 47.4925695,
        },
        _id: "64f75284ef8f606073e9828a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "anosisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8422238,
          lng: 47.4821436,
        },
        _id: "64f75284ef8f606073e9828b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "avaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8495738,
          lng: 47.4956363,
        },
        _id: "64f75284ef8f606073e9828c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "ampefiloha ankeniheny",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8459778,
          lng: 47.4870496,
        },
        _id: "64f75284ef8f606073e9828d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.853431,
          lng: 47.4796907,
        },
        _id: "64f75284ef8f606073e9828e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8515818,
          lng: 47.485823,
        },
        _id: "64f75284ef8f606073e9828f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "ambohidroa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8592494,
          lng: 47.4919561,
        },
        _id: "64f75284ef8f606073e98290",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "ampandriambehivavy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8548619,
          lng: 47.5017705,
        },
        _id: "64f75284ef8f606073e98291",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "ambaravarankazo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8605681,
          lng: 47.4796907,
        },
        _id: "64f75284ef8f606073e98292",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "amboavahy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8594861,
          lng: 47.4864363,
        },
        _id: "64f75284ef8f606073e98293",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "avaratetezana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8650685,
          lng: 47.5042244,
        },
        _id: "64f75284ef8f606073e98294",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "amorona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8658571,
          lng: 47.485823,
        },
        _id: "64f75284ef8f606073e98295",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "ambodimita",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8653843,
          lng: 47.4968631,
        },
        _id: "64f75284ef8f606073e98296",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "ambodivona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8678626,
          lng: 47.4760116,
        },
        _id: "64f75284ef8f606073e98297",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "anosibe zaivola",
        __v: 0,
      },
      {
        coords: {
          lat: -18.870251,
          lng: 47.5036109,
        },
        _id: "64f75284ef8f606073e98298",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "antanjombe avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8715986,
          lng: 47.49993019999999,
        },
        _id: "64f75284ef8f606073e98299",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "antanety avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8706058,
          lng: 47.4953296,
        },
        _id: "64f75284ef8f606073e9829a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "ambohimanandray",
        __v: 0,
      },
      {
        coords: {
          lat: -18.875276,
          lng: 47.5066784,
        },
        _id: "64f75284ef8f606073e9829b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "anosivavaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8731864,
          lng: 47.5045312,
        },
        _id: "64f75284ef8f606073e9829c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "antanjombe ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8726506,
          lng: 47.5031508,
        },
        _id: "64f75284ef8f606073e9829d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "anjanakomboro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8726019,
          lng: 47.4950229,
        },
        _id: "64f75284ef8f606073e9829e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "antsararay",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8738602,
          lng: 47.5026907,
        },
        _id: "64f75284ef8f606073e9829f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "ambohimiadana avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8746374,
          lng: 47.4937962,
        },
        _id: "64f75284ef8f606073e982a0",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "ambohimitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8736343,
          lng: 47.4987033,
        },
        _id: "64f75284ef8f606073e982a1",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "antanety atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.876068,
          lng: 47.5020773,
        },
        _id: "64f75284ef8f606073e982a2",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "ambohimiadana atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8796623,
          lng: 47.4968631,
        },
        _id: "64f75284ef8f606073e982a3",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8778529,
          lng: 47.5020773,
        },
        _id: "64f75284ef8f606073e982a4",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "betafo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.885799,
          lng: 47.5017705,
        },
        _id: "64f75284ef8f606073e982a5",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "ambodivonkely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.874529,
          lng: 47.4870496,
        },
        _id: "64f75284ef8f606073e982a6",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "ambodihady",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8783443,
          lng: 47.5091326,
        },
        _id: "64f75284ef8f606073e982a7",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "andraharo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8933138,
          lng: 47.5115868,
        },
        _id: "64f75284ef8f606073e982a8",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo renivohitra",
        commune: "6e arrondissement",
        fokontany: "ankazomanga atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9590241,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e982a9",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "alasora",
        fokontany: "alasora",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9382709,
          lng: 47.5742088,
        },
        _id: "64f75284ef8f606073e982aa",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "alasora",
        fokontany: "ankazobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9354163,
          lng: 47.5852675,
        },
        _id: "64f75284ef8f606073e982ab",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "alasora",
        fokontany: "mandikanamana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.93567,
          lng: 47.5606957,
        },
        _id: "64f75284ef8f606073e982ac",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "alasora",
        fokontany: "sud ambohipo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9390199,
          lng: 47.5938703,
        },
        _id: "64f75284ef8f606073e982ad",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "alasora",
        fokontany: "mahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9363595,
          lng: 47.5631524,
        },
        _id: "64f75284ef8f606073e982ae",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "alasora",
        fokontany: "ankadindratombo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9431292,
          lng: 47.5533265,
        },
        _id: "64f75284ef8f606073e982af",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "alasora",
        fokontany: "est mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9480359,
          lng: 47.5686803,
        },
        _id: "64f75284ef8f606073e982b0",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "alasora",
        fokontany: "ambodivoanjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9466881,
          lng: 47.5723659,
        },
        _id: "64f75284ef8f606073e982b1",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "alasora",
        fokontany: "ambohidrazaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9459511,
          lng: 47.5803522,
        },
        _id: "64f75284ef8f606073e982b2",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "alasora",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9505748,
          lng: 47.564995,
        },
        _id: "64f75284ef8f606073e982b3",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "alasora",
        fokontany: "ambatomalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9512771,
          lng: 47.5484142,
        },
        _id: "64f75284ef8f606073e982b4",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "alasora",
        fokontany: "ankadiaivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9531914,
          lng: 47.5594675,
        },
        _id: "64f75284ef8f606073e982b5",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "alasora",
        fokontany: "amboaroy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9487094,
          lng: 47.5901832,
        },
        _id: "64f75284ef8f606073e982b6",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "alasora",
        fokontany: "ambohitanety",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9539459,
          lng: 47.57912349999999,
        },
        _id: "64f75284ef8f606073e982b7",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "alasora",
        fokontany: "ampahibato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.963698,
          lng: 47.5926413,
        },
        _id: "64f75284ef8f606073e982b8",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "alasora",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9604939,
          lng: 47.5557828,
        },
        _id: "64f75284ef8f606073e982b9",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "alasora",
        fokontany: "ambodivondava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9646356,
          lng: 47.5705231,
        },
        _id: "64f75284ef8f606073e982ba",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "alasora",
        fokontany: "miadana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9652584,
          lng: 47.5557828,
        },
        _id: "64f75284ef8f606073e982bb",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "alasora",
        fokontany: "mendrikolovana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9673967,
          lng: 47.5803522,
        },
        _id: "64f75284ef8f606073e982bc",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "alasora",
        fokontany: "ambohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8470245,
          lng: 47.5545546,
        },
        _id: "64f75284ef8f606073e982bd",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadikely ilafy",
        fokontany: "ankadikely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8274082,
          lng: 47.6037038,
        },
        _id: "64f75284ef8f606073e982be",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadikely ilafy",
        fokontany: "antsahamarofoza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8409752,
          lng: 47.5840386,
        },
        _id: "64f75284ef8f606073e982bf",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadikely ilafy",
        fokontany: "andranovelona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8429036,
          lng: 47.5398188,
        },
        _id: "64f75284ef8f606073e982c0",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadikely ilafy",
        fokontany: "ambohipanja",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8411767,
          lng: 47.5975577,
        },
        _id: "64f75284ef8f606073e982c1",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadikely ilafy",
        fokontany: "ambohitraina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8404755,
          lng: 47.5226323,
        },
        _id: "64f75284ef8f606073e982c2",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadikely ilafy",
        fokontany: "belanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8429928,
          lng: 47.5742088,
        },
        _id: "64f75284ef8f606073e982c3",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadikely ilafy",
        fokontany: "antsampandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8502048,
          lng: 47.5361355,
        },
        _id: "64f75284ef8f606073e982c4",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadikely ilafy",
        fokontany: "antanandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8497717,
          lng: 47.5643808,
        },
        _id: "64f75284ef8f606073e982c5",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadikely ilafy",
        fokontany: "ilafy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8587697,
          lng: 47.5582392,
        },
        _id: "64f75284ef8f606073e982c6",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadikely ilafy",
        fokontany: "manjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8583222,
          lng: 47.5502562,
        },
        _id: "64f75284ef8f606073e982c7",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadikely ilafy",
        fokontany: "andafiavaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8594083,
          lng: 47.5435024,
        },
        _id: "64f75284ef8f606073e982c8",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadikely ilafy",
        fokontany: "ambohitrarahaba",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8634192,
          lng: 47.57912349999999,
        },
        _id: "64f75284ef8f606073e982c9",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadikely ilafy",
        fokontany: "manazary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8633232,
          lng: 47.5447303,
        },
        _id: "64f75284ef8f606073e982ca",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadikely ilafy",
        fokontany: "andrononobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8722103,
          lng: 47.5594675,
        },
        _id: "64f75284ef8f606073e982cb",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadikely ilafy",
        fokontany: "mandrosoa ilafy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.878189,
          lng: 47.5680661,
        },
        _id: "64f75284ef8f606073e982cc",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadikely ilafy",
        fokontany: "masinandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.87787,
          lng: 47.5754374,
        },
        _id: "64f75284ef8f606073e982cd",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadikely ilafy",
        fokontany: "ambohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9510442,
          lng: 47.6006307,
        },
        _id: "64f75284ef8f606073e982ce",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanambola",
        fokontany: "ambohimanambola gara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9302324,
          lng: 47.6135392,
        },
        _id: "64f75284ef8f606073e982cf",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanambola",
        fokontany: "ambohimahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9260999,
          lng: 47.5987868,
        },
        _id: "64f75284ef8f606073e982d0",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanambola",
        fokontany: "ambohipeno",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9405102,
          lng: 47.6332155,
        },
        _id: "64f75284ef8f606073e982d1",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanambola",
        fokontany: "iharamy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9404058,
          lng: 47.6015999,
        },
        _id: "64f75284ef8f606073e982d2",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanambola",
        fokontany: "ampahimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9426808,
          lng: 47.6196873,
        },
        _id: "64f75284ef8f606073e982d3",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanambola",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9431552,
          lng: 47.6086213,
        },
        _id: "64f75284ef8f606073e982d4",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanambola",
        fokontany: "ambohibato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.947282,
          lng: 47.595714,
        },
        _id: "64f75284ef8f606073e982d5",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanambola",
        fokontany: "tanjonandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.95,
          lng: 47.616667,
        },
        _id: "64f75284ef8f606073e982d6",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanambola",
        fokontany: "ambohimanambola firaisana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9498883,
          lng: 47.6369056,
        },
        _id: "64f75284ef8f606073e982d7",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanambola",
        fokontany: "andramanonga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8382678,
          lng: 47.5551687,
        },
        _id: "64f75284ef8f606073e982d8",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "atsinanantsena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7966697,
          lng: 47.5447303,
        },
        _id: "64f75284ef8f606073e982d9",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "isahafa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8055428,
          lng: 47.5594675,
        },
        _id: "64f75284ef8f606073e982da",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "lazaina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.816667,
          lng: 47.55,
        },
        _id: "64f75284ef8f606073e982db",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "ambohitrinimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8162751,
          lng: 47.5680661,
        },
        _id: "64f75284ef8f606073e982dc",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "faravohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8189795,
          lng: 47.5606957,
        },
        _id: "64f75284ef8f606073e982dd",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "anosimiarinimerina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8231297,
          lng: 47.5201775,
        },
        _id: "64f75284ef8f606073e982de",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "andidiana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8224879,
          lng: 47.5349078,
        },
        _id: "64f75284ef8f606073e982df",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8264851,
          lng: 47.5705231,
        },
        _id: "64f75284ef8f606073e982e0",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "ambatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8251735,
          lng: 47.5551687,
        },
        _id: "64f75284ef8f606073e982e1",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "ambodivona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8232942,
          lng: 47.5889542,
        },
        _id: "64f75284ef8f606073e982e2",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "ambohidrano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8294308,
          lng: 47.5576251,
        },
        _id: "64f75284ef8f606073e982e3",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "antsofinondry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8267663,
          lng: 47.5459582,
        },
        _id: "64f75284ef8f606073e982e4",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "amorondria",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8253937,
          lng: 47.5410466,
        },
        _id: "64f75284ef8f606073e982e5",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "manarintsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.829189,
          lng: 47.5631524,
        },
        _id: "64f75284ef8f606073e982e6",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "antsahatsiresy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8308409,
          lng: 47.5435024,
        },
        _id: "64f75284ef8f606073e982e7",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "namehana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8370184,
          lng: 47.5656092,
        },
        _id: "64f75284ef8f606073e982e8",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "tsarafara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8300959,
          lng: 47.5514843,
        },
        _id: "64f75284ef8f606073e982e9",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "ambohinaorina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8338911,
          lng: 47.5735945,
        },
        _id: "64f75284ef8f606073e982ea",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "botona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8352005,
          lng: 47.5527124,
        },
        _id: "64f75284ef8f606073e982eb",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "andrefantsena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8373825,
          lng: 47.5754374,
        },
        _id: "64f75284ef8f606073e982ec",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "beravina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8384503,
          lng: 47.5600816,
        },
        _id: "64f75284ef8f606073e982ed",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "sabotsy namehana",
        fokontany: "soaniadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8976652,
          lng: 47.5950994,
        },
        _id: "64f75284ef8f606073e982ee",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimangakely",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8716633,
          lng: 47.6086213,
        },
        _id: "64f75284ef8f606073e982ef",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimangakely",
        fokontany: "antanetibe ikianja",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8725538,
          lng: 47.6246062,
        },
        _id: "64f75284ef8f606073e982f0",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimangakely",
        fokontany: "ambohipiainana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8787654,
          lng: 47.5914122,
        },
        _id: "64f75284ef8f606073e982f1",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimangakely",
        fokontany: "betsizaraina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8853177,
          lng: 47.6233764,
        },
        _id: "64f75284ef8f606073e982f2",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimangakely",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8895572,
          lng: 47.617228,
        },
        _id: "64f75284ef8f606073e982f3",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimangakely",
        fokontany: "ambohidehilahy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8857994,
          lng: 47.6123097,
        },
        _id: "64f75284ef8f606073e982f4",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimangakely",
        fokontany: "ambohitrombihavana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8933783,
          lng: 47.5840386,
        },
        _id: "64f75284ef8f606073e982f5",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimangakely",
        fokontany: "tsarahasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8887216,
          lng: 47.5631524,
        },
        _id: "64f75284ef8f606073e982f6",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimangakely",
        fokontany: "soamanandrariny",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8945686,
          lng: 47.5626705,
        },
        _id: "64f75284ef8f606073e982f7",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimangakely",
        fokontany: "ambohimahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8944352,
          lng: 47.59632850000001,
        },
        _id: "64f75284ef8f606073e982f8",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimangakely",
        fokontany: "ambohimangakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9002572,
          lng: 47.6086213,
        },
        _id: "64f75284ef8f606073e982f9",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimangakely",
        fokontany: "andranovao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9027486,
          lng: 47.5877253,
        },
        _id: "64f75284ef8f606073e982fa",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimangakely",
        fokontany: "ankadindambo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9110601,
          lng: 47.57912349999999,
        },
        _id: "64f75284ef8f606073e982fb",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimangakely",
        fokontany: "ikianja",
        __v: 0,
      },
      {
        coords: {
          lat: -18.913179,
          lng: 47.6037038,
        },
        _id: "64f75284ef8f606073e982fc",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimangakely",
        fokontany: "betafo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9138141,
          lng: 47.5889542,
        },
        _id: "64f75284ef8f606073e982fd",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimangakely",
        fokontany: "behitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9267323,
          lng: 47.5840386,
        },
        _id: "64f75284ef8f606073e982fe",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimangakely",
        fokontany: "amoronakona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8107657,
          lng: 47.62091700000001,
        },
        _id: "64f75284ef8f606073e982ff",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "manandriana",
        fokontany: "manandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8020557,
          lng: 47.6024746,
        },
        _id: "64f75284ef8f606073e98300",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "manandriana",
        fokontany: "ambohipananina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8097228,
          lng: 47.6086213,
        },
        _id: "64f75284ef8f606073e98301",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "manandriana",
        fokontany: "ambohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8190179,
          lng: 47.5778948,
        },
        _id: "64f75284ef8f606073e98302",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "manandriana",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8202952,
          lng: 47.62091700000001,
        },
        _id: "64f75284ef8f606073e98303",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "manandriana",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8996174,
          lng: 47.6233764,
        },
        _id: "64f75284ef8f606073e98304",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimalaza miray",
        fokontany: "ambatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8635841,
          lng: 47.6479775,
        },
        _id: "64f75284ef8f606073e98305",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimalaza miray",
        fokontany: "andranosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8677018,
          lng: 47.66274370000001,
        },
        _id: "64f75284ef8f606073e98306",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimalaza miray",
        fokontany: "masombahiny",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8739675,
          lng: 47.6467472,
        },
        _id: "64f75284ef8f606073e98307",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimalaza miray",
        fokontany: "mahia",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8751395,
          lng: 47.63813570000001,
        },
        _id: "64f75284ef8f606073e98308",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimalaza miray",
        fokontany: "ambohitrandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8865312,
          lng: 47.63198550000001,
        },
        _id: "64f75284ef8f606073e98309",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimalaza miray",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8887959,
          lng: 47.6528991,
        },
        _id: "64f75284ef8f606073e9830a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimalaza miray",
        fokontany: "antentona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9052551,
          lng: 47.6380799,
        },
        _id: "64f75284ef8f606073e9830b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimalaza miray",
        fokontany: "ambohimalaza miray",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9003508,
          lng: 47.6430564,
        },
        _id: "64f75284ef8f606073e9830c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimalaza miray",
        fokontany: "ambohitremo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9007587,
          lng: 47.67012829999999,
        },
        _id: "64f75284ef8f606073e9830d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimalaza miray",
        fokontany: "ambatomalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.911903,
          lng: 47.6332155,
        },
        _id: "64f75284ef8f606073e9830e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimalaza miray",
        fokontany: "andranonomby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9126377,
          lng: 47.6528991,
        },
        _id: "64f75284ef8f606073e9830f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimalaza miray",
        fokontany: "atsimon'ambohidray",
        __v: 0,
      },
      {
        coords: {
          lat: -18.834655,
          lng: 47.6553601,
        },
        _id: "64f75284ef8f606073e98310",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "fiaferana",
        fokontany: "fiaferana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8172863,
          lng: 47.6528991,
        },
        _id: "64f75284ef8f606073e98311",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "fiaferana",
        fokontany: "andranomanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8383085,
          lng: 47.6086213,
        },
        _id: "64f75284ef8f606073e98312",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "fiaferana",
        fokontany: "ambohimarina ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -18.838378,
          lng: 47.6430564,
        },
        _id: "64f75284ef8f606073e98313",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "fiaferana",
        fokontany: "tsararay",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8409867,
          lng: 47.673821,
        },
        _id: "64f75284ef8f606073e98314",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "fiaferana",
        fokontany: "fandrakotra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8505828,
          lng: 47.6184576,
        },
        _id: "64f75284ef8f606073e98315",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "fiaferana",
        fokontany: "ambohitriniandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8424912,
          lng: 47.6578212,
        },
        _id: "64f75284ef8f606073e98316",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "fiaferana",
        fokontany: "manohisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8532484,
          lng: 47.5938703,
        },
        _id: "64f75284ef8f606073e98317",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "fiaferana",
        fokontany: "rangaina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8560723,
          lng: 47.63813570000001,
        },
        _id: "64f75284ef8f606073e98318",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "fiaferana",
        fokontany: "soanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8660912,
          lng: 47.6270659,
        },
        _id: "64f75284ef8f606073e98319",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "fiaferana",
        fokontany: "ambohimarina atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7639004,
          lng: 47.5680661,
        },
        _id: "64f75284ef8f606073e9831a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "avarakady",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7470486,
          lng: 47.5545546,
        },
        _id: "64f75284ef8f606073e9831b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "ambohitrimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7416298,
          lng: 47.56929450000001,
        },
        _id: "64f75284ef8f606073e9831c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "soavinandriamanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7604894,
          lng: 47.5624106,
        },
        _id: "64f75284ef8f606073e9831d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "ambohimanga rova",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7586464,
          lng: 47.57912349999999,
        },
        _id: "64f75284ef8f606073e9831e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.774299,
          lng: 47.5840386,
        },
        _id: "64f75284ef8f606073e9831f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "soamonina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7742391,
          lng: 47.5496422,
        },
        _id: "64f75284ef8f606073e98320",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "imanja",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7748906,
          lng: 47.5349078,
        },
        _id: "64f75284ef8f606073e98321",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "ankazobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.783039,
          lng: 47.5299973,
        },
        _id: "64f75284ef8f606073e98322",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7778253,
          lng: 47.5582392,
        },
        _id: "64f75284ef8f606073e98323",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "vakinampasika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7835965,
          lng: 47.5533265,
        },
        _id: "64f75284ef8f606073e98324",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "manankasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7823895,
          lng: 47.5447303,
        },
        _id: "64f75284ef8f606073e98325",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "iavoambony",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7810848,
          lng: 47.5742088,
        },
        _id: "64f75284ef8f606073e98326",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "ambohitrandriamanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7878685,
          lng: 47.5643808,
        },
        _id: "64f75284ef8f606073e98327",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.788357,
          lng: 47.5533265,
        },
        _id: "64f75284ef8f606073e98328",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "imerintsiafindra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7914559,
          lng: 47.5729802,
        },
        _id: "64f75284ef8f606073e98329",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "fiekena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7925575,
          lng: 47.5299973,
        },
        _id: "64f75284ef8f606073e9832a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "anosiarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7960206,
          lng: 47.5594675,
        },
        _id: "64f75284ef8f606073e9832b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "ambohidahy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7991724,
          lng: 47.5238597,
        },
        _id: "64f75284ef8f606073e9832c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "antsahakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8001315,
          lng: 47.5742088,
        },
        _id: "64f75284ef8f606073e9832d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "soavinimerina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.804819,
          lng: 47.5398188,
        },
        _id: "64f75284ef8f606073e9832e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "malaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8102237,
          lng: 47.52508719999999,
        },
        _id: "64f75284ef8f606073e9832f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ambohimanga rova",
        fokontany: "ambodisiarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8166276,
          lng: 47.6676666,
        },
        _id: "64f75284ef8f606073e98330",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "viliahazo",
        fokontany: "ambohitompo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7893932,
          lng: 47.6725901,
        },
        _id: "64f75284ef8f606073e98331",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "viliahazo",
        fokontany: "ambahona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8036946,
          lng: 47.6725901,
        },
        _id: "64f75284ef8f606073e98332",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "viliahazo",
        fokontany: "andriankely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.79874,
          lng: 47.6590517,
        },
        _id: "64f75284ef8f606073e98333",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "viliahazo",
        fokontany: "antsahakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8016185,
          lng: 47.6479775,
        },
        _id: "64f75284ef8f606073e98334",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "viliahazo",
        fokontany: "ambohidrano atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8013312,
          lng: 47.6898257,
        },
        _id: "64f75284ef8f606073e98335",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "viliahazo",
        fokontany: "anosimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8111991,
          lng: 47.6824383,
        },
        _id: "64f75284ef8f606073e98336",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "viliahazo",
        fokontany: "andranohandrona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8272028,
          lng: 47.6799761,
        },
        _id: "64f75284ef8f606073e98337",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "viliahazo",
        fokontany: "ambovona atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.74575,
          lng: 47.644981,
        },
        _id: "64f75284ef8f606073e98338",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "talata volonondry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.70731,
          lng: 47.5914122,
        },
        _id: "64f75284ef8f606073e98339",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "amparafara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7144863,
          lng: 47.6430564,
        },
        _id: "64f75284ef8f606073e9833a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "ambohimiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7182222,
          lng: 47.6307555,
        },
        _id: "64f75284ef8f606073e9833b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "ambolo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7328126,
          lng: 47.5889542,
        },
        _id: "64f75284ef8f606073e9833c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "morarano idilana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7301161,
          lng: 47.6135392,
        },
        _id: "64f75284ef8f606073e9833d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "antsahamaro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7334756,
          lng: 47.5742088,
        },
        _id: "64f75284ef8f606073e9833e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "ambohitrangano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7369098,
          lng: 47.6037038,
        },
        _id: "64f75284ef8f606073e9833f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "ankadivoribe avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.74575,
          lng: 47.644981,
        },
        _id: "64f75284ef8f606073e98340",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "avaratsena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7437015,
          lng: 47.5938703,
        },
        _id: "64f75284ef8f606073e98341",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "ampanataovana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.743738,
          lng: 47.6282957,
        },
        _id: "64f75284ef8f606073e98342",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "ambohimahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7587297,
          lng: 47.6479775,
        },
        _id: "64f75284ef8f606073e98343",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "mamoriarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7645277,
          lng: 47.6602824,
        },
        _id: "64f75284ef8f606073e98344",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "andranotsimihozo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7576566,
          lng: 47.6012453,
        },
        _id: "64f75284ef8f606073e98345",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "ambodiala",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7661899,
          lng: 47.6233764,
        },
        _id: "64f75284ef8f606073e98346",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "ampahidralambo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.76737,
          lng: 47.6147688,
        },
        _id: "64f75284ef8f606073e98347",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "fonohasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7745023,
          lng: 47.6085699,
        },
        _id: "64f75284ef8f606073e98348",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "ambohidrabiby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7722735,
          lng: 47.5938703,
        },
        _id: "64f75284ef8f606073e98349",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "ambatondralambo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7724866,
          lng: 47.6246062,
        },
        _id: "64f75284ef8f606073e9834a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "kelifaritra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7777903,
          lng: 47.6479775,
        },
        _id: "64f75284ef8f606073e9834b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "falimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7750558,
          lng: 47.63813570000001,
        },
        _id: "64f75284ef8f606073e9834c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "ambatomahamanina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7797726,
          lng: 47.6037038,
        },
        _id: "64f75284ef8f606073e9834d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "ambodifahitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7843436,
          lng: 47.5901832,
        },
        _id: "64f75284ef8f606073e9834e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7852908,
          lng: 47.6578212,
        },
        _id: "64f75284ef8f606073e9834f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7906678,
          lng: 47.6086213,
        },
        _id: "64f75284ef8f606073e98350",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "ambatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7944453,
          lng: 47.6307555,
        },
        _id: "64f75284ef8f606073e98351",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "ambohibao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7952381,
          lng: 47.5950994,
        },
        _id: "64f75284ef8f606073e98352",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7955651,
          lng: 47.5877253,
        },
        _id: "64f75284ef8f606073e98353",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "talata volonondry",
        fokontany: "ambohitrantenaina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9457021,
          lng: 47.6602824,
        },
        _id: "64f75284ef8f606073e98354",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "anjeva gara",
        fokontany: "anjeva gara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9241912,
          lng: 47.6430564,
        },
        _id: "64f75284ef8f606073e98355",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "anjeva gara",
        fokontany: "imerikasinina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9266239,
          lng: 47.6602824,
        },
        _id: "64f75284ef8f606073e98356",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "anjeva gara",
        fokontany: "ankadiefajoro mavolamba",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9262061,
          lng: 47.6332155,
        },
        _id: "64f75284ef8f606073e98357",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "anjeva gara",
        fokontany: "ambohidrazana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9380187,
          lng: 47.6541296,
        },
        _id: "64f75284ef8f606073e98358",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "anjeva gara",
        fokontany: "anjeva tanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.938068,
          lng: 47.6713592,
        },
        _id: "64f75284ef8f606073e98359",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "anjeva gara",
        fokontany: "ampahimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9461797,
          lng: 47.6492079,
        },
        _id: "64f75284ef8f606073e9835a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "anjeva gara",
        fokontany: "morarano faliary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9501532,
          lng: 47.6676666,
        },
        _id: "64f75284ef8f606073e9835b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "anjeva gara",
        fokontany: "manohisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9617076,
          lng: 47.6578212,
        },
        _id: "64f75284ef8f606073e9835c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "anjeva gara",
        fokontany: "ambatofolaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9706137,
          lng: 47.6725901,
        },
        _id: "64f75284ef8f606073e9835d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "anjeva gara",
        fokontany: "imaola vohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9885106,
          lng: 47.625836,
        },
        _id: "64f75284ef8f606073e9835e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "masindray",
        fokontany: "masindray",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9609625,
          lng: 47.63813570000001,
        },
        _id: "64f75284ef8f606073e9835f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "masindray",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.966991,
          lng: 47.6086213,
        },
        _id: "64f75284ef8f606073e98360",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "masindray",
        fokontany: "ampanobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9840552,
          lng: 47.6184576,
        },
        _id: "64f75284ef8f606073e98361",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "masindray",
        fokontany: "ivoara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9819184,
          lng: 47.5938703,
        },
        _id: "64f75284ef8f606073e98362",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "masindray",
        fokontany: "manazary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9893821,
          lng: 47.6799761,
        },
        _id: "64f75284ef8f606073e98363",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "masindray",
        fokontany: "antanamalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9841788,
          lng: 47.6528991,
        },
        _id: "64f75284ef8f606073e98364",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "masindray",
        fokontany: "antsaharoaloha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9957281,
          lng: 47.6430564,
        },
        _id: "64f75284ef8f606073e98365",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "masindray",
        fokontany: "aminampanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0026321,
          lng: 47.6676666,
        },
        _id: "64f75284ef8f606073e98366",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "masindray",
        fokontany: "miadamanjaka II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0041991,
          lng: 47.6307555,
        },
        _id: "64f75284ef8f606073e98367",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "masindray",
        fokontany: "faliarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0054443,
          lng: 47.6012453,
        },
        _id: "64f75284ef8f606073e98368",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "masindray",
        fokontany: "antanimenabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.065639,
          lng: 47.65974,
        },
        _id: "64f75284ef8f606073e98369",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadinandriana",
        fokontany: "ankadinandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0135564,
          lng: 47.6725901,
        },
        _id: "64f75284ef8f606073e9836a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadinandriana",
        fokontany: "antanetibe maromby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0303234,
          lng: 47.6898257,
        },
        _id: "64f75284ef8f606073e9836b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadinandriana",
        fokontany: "andranovelona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0288088,
          lng: 47.6504382,
        },
        _id: "64f75284ef8f606073e9836c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadinandriana",
        fokontany: "soamonina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0232776,
          lng: 47.6307555,
        },
        _id: "64f75284ef8f606073e9836d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadinandriana",
        fokontany: "ambohitsararay",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0326447,
          lng: 47.6725901,
        },
        _id: "64f75284ef8f606073e9836e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadinandriana",
        fokontany: "andranomonina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0423578,
          lng: 47.6307555,
        },
        _id: "64f75284ef8f606073e9836f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadinandriana",
        fokontany: "ambohimahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.033333,
          lng: 47.716667,
        },
        _id: "64f75284ef8f606073e98370",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadinandriana",
        fokontany: "antanimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0561966,
          lng: 47.6799761,
        },
        _id: "64f75284ef8f606073e98371",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadinandriana",
        fokontany: "ampahitrosy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0694413,
          lng: 47.6676666,
        },
        _id: "64f75284ef8f606073e98372",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadinandriana",
        fokontany: "andraravola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.066817,
          lng: 47.6922884,
        },
        _id: "64f75284ef8f606073e98373",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadinandriana",
        fokontany: "ambohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0735958,
          lng: 47.6824383,
        },
        _id: "64f75284ef8f606073e98374",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadinandriana",
        fokontany: "ambohitraina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0796028,
          lng: 47.6528991,
        },
        _id: "64f75284ef8f606073e98375",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadinandriana",
        fokontany: "ambohijato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0934597,
          lng: 47.7021403,
        },
        _id: "64f75284ef8f606073e98376",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadinandriana",
        fokontany: "fihasinana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.091613,
          lng: 47.67012829999999,
        },
        _id: "64f75284ef8f606073e98377",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadinandriana",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1162565,
          lng: 47.6898257,
        },
        _id: "64f75284ef8f606073e98378",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadinandriana",
        fokontany: "miorikampoza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1051578,
          lng: 47.6504382,
        },
        _id: "64f75284ef8f606073e98379",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo avaradrano",
        commune: "ankadinandriana",
        fokontany: "andriampamaky",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8178569,
          lng: 47.4588461,
        },
        _id: "64f75284ef8f606073e9837a",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohidratrimo",
        fokontany: "ambohidratrimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8072892,
          lng: 47.4465884,
        },
        _id: "64f75284ef8f606073e9837b",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohidratrimo",
        fokontany: "ambohitsiroa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8125193,
          lng: 47.435559,
        },
        _id: "64f75284ef8f606073e9837c",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohidratrimo",
        fokontany: "soamananety",
        __v: 0,
      },
      {
        coords: {
          lat: -18.814683,
          lng: 47.4220818,
        },
        _id: "64f75284ef8f606073e9837d",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohidratrimo",
        fokontany: "ampanataovana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8230316,
          lng: 47.4306578,
        },
        _id: "64f75284ef8f606073e9837e",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohidratrimo",
        fokontany: "ambohidehilahy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8196515,
          lng: 47.435559,
        },
        _id: "64f75284ef8f606073e9837f",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohidratrimo",
        fokontany: "atsimomparihy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8257812,
          lng: 47.4404607,
        },
        _id: "64f75284ef8f606073e98380",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohidratrimo",
        fokontany: "ambodisaha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8289455,
          lng: 47.4220818,
        },
        _id: "64f75284ef8f606073e98381",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohidratrimo",
        fokontany: "antohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8330715,
          lng: 47.4367844,
        },
        _id: "64f75284ef8f606073e98382",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohidratrimo",
        fokontany: "ambovo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7944528,
          lng: 47.4135072,
        },
        _id: "64f75284ef8f606073e98383",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anosiala",
        fokontany: "anjanamasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.762484,
          lng: 47.4563943,
        },
        _id: "64f75284ef8f606073e98384",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anosiala",
        fokontany: "avarabohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7698914,
          lng: 47.4318831,
        },
        _id: "64f75284ef8f606073e98385",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anosiala",
        fokontany: "ambohitsimeloka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7748515,
          lng: 47.4453628,
        },
        _id: "64f75284ef8f606073e98386",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anosiala",
        fokontany: "fiakarana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7781199,
          lng: 47.461298,
        },
        _id: "64f75284ef8f606073e98387",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anosiala",
        fokontany: "ambohimpanasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7847882,
          lng: 47.4171819,
        },
        _id: "64f75284ef8f606073e98388",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anosiala",
        fokontany: "tsarahonenana ivoanjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7841524,
          lng: 47.4318831,
        },
        _id: "64f75284ef8f606073e98389",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anosiala",
        fokontany: "ambatomena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7734894,
          lng: 47.3853435,
        },
        _id: "64f75284ef8f606073e9838a",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anosiala",
        fokontany: "ankazo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7969314,
          lng: 47.3926891,
        },
        _id: "64f75284ef8f606073e9838b",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anosiala",
        fokontany: "ambohitrinibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.789432,
          lng: 47.3828952,
        },
        _id: "64f75284ef8f606073e9838c",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anosiala",
        fokontany: "ambohitsiroa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7992596,
          lng: 47.4306578,
        },
        _id: "64f75284ef8f606073e9838d",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anosiala",
        fokontany: "tsaramandroso namontana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7964062,
          lng: 47.4416862,
        },
        _id: "64f75284ef8f606073e9838e",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anosiala",
        fokontany: "andrakaja",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8005249,
          lng: 47.4563943,
        },
        _id: "64f75284ef8f606073e9838f",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anosiala",
        fokontany: "soamanandray",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8038013,
          lng: 47.4171819,
        },
        _id: "64f75284ef8f606073e98390",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anosiala",
        fokontany: "mandrosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8044321,
          lng: 47.4024848,
        },
        _id: "64f75284ef8f606073e98391",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anosiala",
        fokontany: "ambohipiara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8070631,
          lng: 47.37799889999999,
        },
        _id: "64f75284ef8f606073e98392",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anosiala",
        fokontany: "manjakazafy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8125629,
          lng: 47.3975867,
        },
        _id: "64f75284ef8f606073e98393",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anosiala",
        fokontany: "lavahitsiny",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8324413,
          lng: 47.4514912,
        },
        _id: "64f75284ef8f606073e98394",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "talatamaty",
        fokontany: "amboropotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8080268,
          lng: 47.4662021,
        },
        _id: "64f75284ef8f606073e98395",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "talatamaty",
        fokontany: "mamory miray antoby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8161657,
          lng: 47.461298,
        },
        _id: "64f75284ef8f606073e98396",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "talatamaty",
        fokontany: "fitroafana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8223214,
          lng: 47.4472013,
        },
        _id: "64f75284ef8f606073e98397",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "talatamaty",
        fokontany: "mandriambero",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8295892,
          lng: 47.4625239,
        },
        _id: "64f75284ef8f606073e98398",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "talatamaty",
        fokontany: "maibahoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.83979,
          lng: 47.464976,
        },
        _id: "64f75284ef8f606073e98399",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "talatamaty",
        fokontany: "ambohitravao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8397329,
          lng: 47.4478141,
        },
        _id: "64f75284ef8f606073e9839a",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "talatamaty",
        fokontany: "tanjondava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8402631,
          lng: 47.4539427,
        },
        _id: "64f75284ef8f606073e9839b",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "talatamaty",
        fokontany: "talatamaty",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8432788,
          lng: 47.4668151,
        },
        _id: "64f75284ef8f606073e9839c",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "talatamaty",
        fokontany: "imerinafovoany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8489308,
          lng: 47.4551685,
        },
        _id: "64f75284ef8f606073e9839d",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "talatamaty",
        fokontany: "faralaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8479283,
          lng: 47.460072,
        },
        _id: "64f75284ef8f606073e9839e",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "talatamaty",
        fokontany: "ambohinambo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8444889,
          lng: 47.4478141,
        },
        _id: "64f75284ef8f606073e9839f",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "talatamaty",
        fokontany: "ankadivory",
        __v: 0,
      },
      {
        coords: {
          lat: -18.848831,
          lng: 47.4760116,
        },
        _id: "64f75284ef8f606073e983a0",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antehiroka",
        fokontany: "ambohibao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.826675,
          lng: 47.4943466,
        },
        _id: "64f75284ef8f606073e983a1",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antehiroka",
        fokontany: "antalamohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8329983,
          lng: 47.4937962,
        },
        _id: "64f75284ef8f606073e983a2",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antehiroka",
        fokontany: "ambohijanahary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8299019,
          lng: 47.5103597,
        },
        _id: "64f75284ef8f606073e983a3",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antehiroka",
        fokontany: "antsakambahiny",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8333421,
          lng: 47.4674282,
        },
        _id: "64f75284ef8f606073e983a4",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antehiroka",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.835933,
          lng: 47.4809171,
        },
        _id: "64f75284ef8f606073e983a5",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antehiroka",
        fokontany: "amboaroy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8385232,
          lng: 47.4944096,
        },
        _id: "64f75284ef8f606073e983a6",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antehiroka",
        fokontany: "ankadivory",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8523705,
          lng: 47.4674282,
        },
        _id: "64f75284ef8f606073e983a7",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antehiroka",
        fokontany: "morondava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8578159,
          lng: 47.4698804,
        },
        _id: "64f75284ef8f606073e983a8",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antehiroka",
        fokontany: "andranoro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8265082,
          lng: 47.404934,
        },
        _id: "64f75284ef8f606073e983a9",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "iarinarivo",
        fokontany: "iarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8161572,
          lng: 47.4061587,
        },
        _id: "64f75284ef8f606073e983aa",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "iarinarivo",
        fokontany: "maroloha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.821318,
          lng: 47.37799889999999,
        },
        _id: "64f75284ef8f606073e983ab",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "iarinarivo",
        fokontany: "antanatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8252891,
          lng: 47.3963622,
        },
        _id: "64f75284ef8f606073e983ac",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "iarinarivo",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8301039,
          lng: 47.4135072,
        },
        _id: "64f75284ef8f606073e983ad",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "iarinarivo",
        fokontany: "amboasary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.83908,
          lng: 47.4073834,
        },
        _id: "64f75284ef8f606073e983ae",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "iarinarivo",
        fokontany: "manonilahy I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8409881,
          lng: 47.4184068,
        },
        _id: "64f75284ef8f606073e983af",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "iarinarivo",
        fokontany: "ambohinanjakana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8445852,
          lng: 47.4269822,
        },
        _id: "64f75284ef8f606073e983b0",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "iarinarivo",
        fokontany: "tsarazaza andriantany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8491203,
          lng: 47.4135072,
        },
        _id: "64f75284ef8f606073e983b1",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "iarinarivo",
        fokontany: "tanjondroa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8222959,
          lng: 47.4662021,
        },
        _id: "64f75284ef8f606073e983b2",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ivato firaisana",
        fokontany: "mandrosoa ivato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8179878,
          lng: 47.4741722,
        },
        _id: "64f75284ef8f606073e983b3",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ivato firaisana",
        fokontany: "andafiavaratra tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8208145,
          lng: 47.4821436,
        },
        _id: "64f75284ef8f606073e983b4",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ivato firaisana",
        fokontany: "ilaivola",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8212624,
          lng: 47.49011609999999,
        },
        _id: "64f75284ef8f606073e983b5",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ivato firaisana",
        fokontany: "ambodirano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8262589,
          lng: 47.48459649999999,
        },
        _id: "64f75284ef8f606073e983b6",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ivato firaisana",
        fokontany: "ankadindravola",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8009397,
          lng: 47.48337,
        },
        _id: "64f75284ef8f606073e983b7",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ivato aeroport",
        fokontany: "ivato aeroport",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8625594,
          lng: 47.4422989,
        },
        _id: "64f75284ef8f606073e983b8",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "ambatolampy avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.853313,
          lng: 47.4453628,
        },
        _id: "64f75284ef8f606073e983b9",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "beloha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.853548,
          lng: 47.439848,
        },
        _id: "64f75284ef8f606073e983ba",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "farahindra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8552376,
          lng: 47.4373971,
        },
        _id: "64f75284ef8f606073e983bb",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "ampanomahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8576936,
          lng: 47.435559,
        },
        _id: "64f75284ef8f606073e983bc",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8622283,
          lng: 47.4220818,
        },
        _id: "64f75284ef8f606073e983bd",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "anosimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8655939,
          lng: 47.4361717,
        },
        _id: "64f75284ef8f606073e983be",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "andranomahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8685827,
          lng: 47.4404607,
        },
        _id: "64f75284ef8f606073e983bf",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8668152,
          lng: 47.44475,
        },
        _id: "64f75284ef8f606073e983c0",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "miadana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.863514,
          lng: 47.4478141,
        },
        _id: "64f75284ef8f606073e983c1",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "antsahamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8577559,
          lng: 47.4527169,
        },
        _id: "64f75284ef8f606073e983c2",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "fiakarana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8615108,
          lng: 47.4576202,
        },
        _id: "64f75284ef8f606073e983c3",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "ampahibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8659545,
          lng: 47.464976,
        },
        _id: "64f75284ef8f606073e983c4",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "mahitsy avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8730908,
          lng: 47.464976,
        },
        _id: "64f75284ef8f606073e983c5",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "mahitsy firaisana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8717136,
          lng: 47.460072,
        },
        _id: "64f75284ef8f606073e983c6",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "lehilava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8689591,
          lng: 47.4502654,
        },
        _id: "64f75284ef8f606073e983c7",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "antsahafohy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8757171,
          lng: 47.4404607,
        },
        _id: "64f75284ef8f606073e983c8",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "ambohimananjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8723835,
          lng: 47.43494630000001,
        },
        _id: "64f75284ef8f606073e983c9",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "andringitana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8688165,
          lng: 47.43494630000001,
        },
        _id: "64f75284ef8f606073e983ca",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "ikopakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8701222,
          lng: 47.4321894,
        },
        _id: "64f75284ef8f606073e983cb",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "ambodivona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8867638,
          lng: 47.4416862,
        },
        _id: "64f75284ef8f606073e983cc",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "ampiriaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8802067,
          lng: 47.4373971,
        },
        _id: "64f75284ef8f606073e983cd",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "namorana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8805847,
          lng: 47.4472013,
        },
        _id: "64f75284ef8f606073e983ce",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "ambatolampy atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8784728,
          lng: 47.4502654,
        },
        _id: "64f75284ef8f606073e983cf",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "antsahavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.877472,
          lng: 47.4551685,
        },
        _id: "64f75284ef8f606073e983d0",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohitrimanjaka",
        fokontany: "ambodivoanjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7467313,
          lng: 47.34496130000001,
        },
        _id: "64f75284ef8f606073e983d1",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "mahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6998985,
          lng: 47.4024848,
        },
        _id: "64f75284ef8f606073e983d2",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "amberomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7151959,
          lng: 47.41473209999999,
        },
        _id: "64f75284ef8f606073e983d3",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "ambodifiakarana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7290439,
          lng: 47.3877919,
        },
        _id: "64f75284ef8f606073e983d4",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "ankadifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7300026,
          lng: 47.3657603,
        },
        _id: "64f75284ef8f606073e983d5",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "ankazo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7242024,
          lng: 47.3535246,
        },
        _id: "64f75284ef8f606073e983d6",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "bejofo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.723915,
          lng: 47.3419034,
        },
        _id: "64f75284ef8f606073e983d7",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "antandrokomby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7343338,
          lng: 47.3388456,
        },
        _id: "64f75284ef8f606073e983d8",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "ambohimilemaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7369176,
          lng: 47.3523012,
        },
        _id: "64f75284ef8f606073e983d9",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "miadampahonina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7464148,
          lng: 47.3523012,
        },
        _id: "64f75284ef8f606073e983da",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7489996,
          lng: 47.3657603,
        },
        _id: "64f75284ef8f606073e983db",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "fierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7527963,
          lng: 47.3877919,
        },
        _id: "64f75284ef8f606073e983dc",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7474073,
          lng: 47.4024848,
        },
        _id: "64f75284ef8f606073e983dd",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "antanetibe est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7481375,
          lng: 47.4220818,
        },
        _id: "64f75284ef8f606073e983de",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "antangirika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7519269,
          lng: 47.4441373,
        },
        _id: "64f75284ef8f606073e983df",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "antanety est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7610242,
          lng: 47.4171819,
        },
        _id: "64f75284ef8f606073e983e0",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "andranovelona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7739101,
          lng: 47.4122824,
        },
        _id: "64f75284ef8f606073e983e1",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "ambohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7648234,
          lng: 47.3290618,
        },
        _id: "64f75284ef8f606073e983e2",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "fiadanankely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7574427,
          lng: 47.3535246,
        },
        _id: "64f75284ef8f606073e983e3",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "antanetilava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7640928,
          lng: 47.3645366,
        },
        _id: "64f75284ef8f606073e983e4",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "antongombato est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7720046,
          lng: 47.3461846,
        },
        _id: "64f75284ef8f606073e983e5",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "miandrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7730635,
          lng: 47.3584185,
        },
        _id: "64f75284ef8f606073e983e6",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "ambohimandray",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7779586,
          lng: 47.3272276,
        },
        _id: "64f75284ef8f606073e983e7",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "ambohimahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7882519,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e983e8",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "ambohimanatrika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7873128,
          lng: 47.3584185,
        },
        _id: "64f75284ef8f606073e983e9",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7900611,
          lng: 47.3682078,
        },
        _id: "64f75284ef8f606073e983ea",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7830643,
          lng: 47.3094999,
        },
        _id: "64f75284ef8f606073e983eb",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "soavinimerina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8041853,
          lng: 47.3339535,
        },
        _id: "64f75284ef8f606073e983ec",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "antokomaro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8194993,
          lng: 47.3461846,
        },
        _id: "64f75284ef8f606073e983ed",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "ambohibao sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8158144,
          lng: 47.3584185,
        },
        _id: "64f75284ef8f606073e983ee",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "ambatofamamba",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8156795,
          lng: 47.3241707,
        },
        _id: "64f75284ef8f606073e983ef",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahitsy",
        fokontany: "andrefambohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7337002,
          lng: 47.4980899,
        },
        _id: "64f75284ef8f606073e983f0",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "merimandroso",
        fokontany: "merimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.704506,
          lng: 47.512814,
        },
        _id: "64f75284ef8f606073e983f1",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "merimandroso",
        fokontany: "manantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7245148,
          lng: 47.4907295,
        },
        _id: "64f75284ef8f606073e983f2",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "merimandroso",
        fokontany: "manankasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7233938,
          lng: 47.5336801,
        },
        _id: "64f75284ef8f606073e983f3",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "merimandroso",
        fokontany: "ampamaho",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7265944,
          lng: 47.5152684,
        },
        _id: "64f75284ef8f606073e983f4",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "merimandroso",
        fokontany: "amboniloha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7316158,
          lng: 47.4735591,
        },
        _id: "64f75284ef8f606073e983f5",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "merimandroso",
        fokontany: "ambohipoloalina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7354532,
          lng: 47.5299973,
        },
        _id: "64f75284ef8f606073e983f6",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "merimandroso",
        fokontany: "ambohiborosy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7441879,
          lng: 47.4760116,
        },
        _id: "64f75284ef8f606073e983f7",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "merimandroso",
        fokontany: "mananjara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7477051,
          lng: 47.5398188,
        },
        _id: "64f75284ef8f606073e983f8",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "merimandroso",
        fokontany: "ambohidrakitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7415188,
          lng: 47.5005436,
        },
        _id: "64f75284ef8f606073e983f9",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "merimandroso",
        fokontany: "alatsinainy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7488165,
          lng: 47.4968631,
        },
        _id: "64f75284ef8f606073e983fa",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "merimandroso",
        fokontany: "ambodivona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7552668,
          lng: 47.4944096,
        },
        _id: "64f75284ef8f606073e983fb",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "merimandroso",
        fokontany: "belanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7584543,
          lng: 47.4760116,
        },
        _id: "64f75284ef8f606073e983fc",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "merimandroso",
        fokontany: "mangarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7596991,
          lng: 47.5017705,
        },
        _id: "64f75284ef8f606073e983fd",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "merimandroso",
        fokontany: "tsarasaotra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7548126,
          lng: 47.5226323,
        },
        _id: "64f75284ef8f606073e983fe",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "merimandroso",
        fokontany: "anovorano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7558546,
          lng: 47.5349078,
        },
        _id: "64f75284ef8f606073e983ff",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "merimandroso",
        fokontany: "amboatany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7679658,
          lng: 47.4760116,
        },
        _id: "64f75284ef8f606073e98400",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "merimandroso",
        fokontany: "ankazomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7728003,
          lng: 47.5103597,
        },
        _id: "64f75284ef8f606073e98401",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "merimandroso",
        fokontany: "ankosy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.766186,
          lng: 47.5164956,
        },
        _id: "64f75284ef8f606073e98402",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "merimandroso",
        fokontany: "ambatonaorina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.774693,
          lng: 47.5214049,
        },
        _id: "64f75284ef8f606073e98403",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "merimandroso",
        fokontany: "ambohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7836867,
          lng: 47.5152684,
        },
        _id: "64f75284ef8f606073e98404",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambatolampy",
        fokontany: "ambatolampy tsimahafotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7686903,
          lng: 47.4956363,
        },
        _id: "64f75284ef8f606073e98405",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambatolampy",
        fokontany: "ambohidava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7746166,
          lng: 47.4870496,
        },
        _id: "64f75284ef8f606073e98406",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambatolampy",
        fokontany: "ambohinome",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7790096,
          lng: 47.477238,
        },
        _id: "64f75284ef8f606073e98407",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambatolampy",
        fokontany: "anjomakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7924763,
          lng: 47.4956363,
        },
        _id: "64f75284ef8f606073e98408",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambatolampy",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7856185,
          lng: 47.4711066,
        },
        _id: "64f75284ef8f606073e98409",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambatolampy",
        fokontany: "soavinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7979622,
          lng: 47.5152684,
        },
        _id: "64f75284ef8f606073e9840a",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambatolampy",
        fokontany: "avarajozoro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8074797,
          lng: 47.5152684,
        },
        _id: "64f75284ef8f606073e9840b",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambatolampy",
        fokontany: "ambodifasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8595732,
          lng: 47.40524019999999,
        },
        _id: "64f75284ef8f606073e9840c",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampangabe",
        fokontany: "ampangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8437008,
          lng: 47.37310309999999,
        },
        _id: "64f75284ef8f606073e9840d",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampangabe",
        fokontany: "antananambony",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8400151,
          lng: 47.3853435,
        },
        _id: "64f75284ef8f606073e9840e",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampangabe",
        fokontany: "soamanjaka nord",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8505873,
          lng: 47.3975867,
        },
        _id: "64f75284ef8f606073e9840f",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampangabe",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8507576,
          lng: 47.3559715,
        },
        _id: "64f75284ef8f606073e98410",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampangabe",
        fokontany: "ambohidreny",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8585616,
          lng: 47.3963622,
        },
        _id: "64f75284ef8f606073e98411",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampangabe",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8720616,
          lng: 47.3388456,
        },
        _id: "64f75284ef8f606073e98412",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampangabe",
        fokontany: "ampandrilaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8697634,
          lng: 47.3559715,
        },
        _id: "64f75284ef8f606073e98413",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampangabe",
        fokontany: "ambohimiandra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8777311,
          lng: 47.3926891,
        },
        _id: "64f75284ef8f606073e98414",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampangabe",
        fokontany: "falihavana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8789558,
          lng: 47.4012602,
        },
        _id: "64f75284ef8f606073e98415",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampangabe",
        fokontany: "manjaka est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8755879,
          lng: 47.3682078,
        },
        _id: "64f75284ef8f606073e98416",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampangabe",
        fokontany: "ikianja",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8942929,
          lng: 47.37555099999999,
        },
        _id: "64f75284ef8f606073e98417",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampangabe",
        fokontany: "anakakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.72948,
          lng: 47.3046106,
        },
        _id: "64f75284ef8f606073e98418",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "antanety",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6684348,
          lng: 47.2899454,
        },
        _id: "64f75284ef8f606073e98419",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "ambohimiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6713953,
          lng: 47.2581852,
        },
        _id: "64f75284ef8f606073e9841a",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "ambohiboahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6755957,
          lng: 47.3070552,
        },
        _id: "64f75284ef8f606073e9841b",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "androhibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6751844,
          lng: 47.280171,
        },
        _id: "64f75284ef8f606073e9841c",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "andralantoina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6873074,
          lng: 47.2557429,
        },
        _id: "64f75284ef8f606073e9841d",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "belanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.690784,
          lng: 47.285058,
        },
        _id: "64f75284ef8f606073e9841e",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "miadampahonina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6948931,
          lng: 47.2997218,
        },
        _id: "64f75284ef8f606073e9841f",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "antsaharatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7000528,
          lng: 47.3266162,
        },
        _id: "64f75284ef8f606073e98420",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "ambaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7057528,
          lng: 47.3046106,
        },
        _id: "64f75284ef8f606073e98421",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "ambatomirahavavy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7107074,
          lng: 47.3180574,
        },
        _id: "64f75284ef8f606073e98422",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "antanetibe avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7148193,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e98423",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "vangaina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7187209,
          lng: 47.3339535,
        },
        _id: "64f75284ef8f606073e98424",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "ampanataovana avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7093336,
          lng: 47.2581852,
        },
        _id: "64f75284ef8f606073e98425",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "ambatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7230472,
          lng: 47.3070552,
        },
        _id: "64f75284ef8f606073e98426",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "ampanotokana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7317494,
          lng: 47.3253934,
        },
        _id: "64f75284ef8f606073e98427",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "andohanimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7337958,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e98428",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "bemasoandro nord",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7339832,
          lng: 47.2362087,
        },
        _id: "64f75284ef8f606073e98429",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7335397,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e9842a",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "antsapanimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7333606,
          lng: 47.2508586,
        },
        _id: "64f75284ef8f606073e9842b",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "antsaonjomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7529525,
          lng: 47.2362087,
        },
        _id: "64f75284ef8f606073e9842c",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "ambohikely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7555215,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e9842d",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "anosivolakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7592338,
          lng: 47.1996022,
        },
        _id: "64f75284ef8f606073e9842e",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "amparibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7500265,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e9842f",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "ampanataovana atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7693138,
          lng: 47.2606276,
        },
        _id: "64f75284ef8f606073e98430",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "soavimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7596872,
          lng: 47.2264444,
        },
        _id: "64f75284ef8f606073e98431",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "ambohitrinilanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7780295,
          lng: 47.2789493,
        },
        _id: "64f75284ef8f606073e98432",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "amboniavaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7852162,
          lng: 47.2960554,
        },
        _id: "64f75284ef8f606073e98433",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "soavikanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7856809,
          lng: 47.285058,
        },
        _id: "64f75284ef8f606073e98434",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ampanotokana",
        fokontany: "bemasoandro atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7573294,
          lng: 47.31928,
        },
        _id: "64f75284ef8f606073e98435",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mananjara",
        fokontany: "mananjara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7417162,
          lng: 47.3143897,
        },
        _id: "64f75284ef8f606073e98436",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mananjara",
        fokontany: "ambohitsilaizina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7492079,
          lng: 47.3241707,
        },
        _id: "64f75284ef8f606073e98437",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mananjara",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7654502,
          lng: 47.3143897,
        },
        _id: "64f75284ef8f606073e98438",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mananjara",
        fokontany: "andranovelona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7741931,
          lng: 47.29483339999999,
        },
        _id: "64f75284ef8f606073e98439",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mananjara",
        fokontany: "alarobia",
        __v: 0,
      },
      {
        coords: {
          lat: -18.687838,
          lng: 47.4956363,
        },
        _id: "64f75284ef8f606073e9843a",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "manjakavaradrano",
        fokontany: "marokibobo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.654222,
          lng: 47.5029975,
        },
        _id: "64f75284ef8f606073e9843b",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "manjakavaradrano",
        fokontany: "antanetibe III",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6812223,
          lng: 47.5017705,
        },
        _id: "64f75284ef8f606073e9843c",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "manjakavaradrano",
        fokontany: "merinavaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.696461,
          lng: 47.48490899999999,
        },
        _id: "64f75284ef8f606073e9843d",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "manjakavaradrano",
        fokontany: "manjakavaradrano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7034696,
          lng: 47.5005436,
        },
        _id: "64f75284ef8f606073e9843e",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "manjakavaradrano",
        fokontany: "soamanandray",
        __v: 0,
      },
      {
        coords: {
          lat: -18.712597,
          lng: 47.4735591,
        },
        _id: "64f75284ef8f606073e9843f",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "manjakavaradrano",
        fokontany: "antanetilava II",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6494907,
          lng: 47.5373633,
        },
        _id: "64f75284ef8f606073e98440",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antsahafilo",
        fokontany: "antsahafilo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6365826,
          lng: 47.5422745,
        },
        _id: "64f75284ef8f606073e98441",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antsahafilo",
        fokontany: "mamory",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6610569,
          lng: 47.5619241,
        },
        _id: "64f75284ef8f606073e98442",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antsahafilo",
        fokontany: "diankazo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6912707,
          lng: 47.52508719999999,
        },
        _id: "64f75284ef8f606073e98443",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antsahafilo",
        fokontany: "ampahimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.716667,
          lng: 47.4166669,
        },
        _id: "64f75284ef8f606073e98444",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohimanjaka",
        fokontany: "ambohimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6857117,
          lng: 47.4367844,
        },
        _id: "64f75284ef8f606073e98445",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohimanjaka",
        fokontany: "ambohidrazaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7067371,
          lng: 47.4269822,
        },
        _id: "64f75284ef8f606073e98446",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohimanjaka",
        fokontany: "ambohimarina andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7139002,
          lng: 47.4441373,
        },
        _id: "64f75284ef8f606073e98447",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohimanjaka",
        fokontany: "ambohitrinibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7373429,
          lng: 47.4514912,
        },
        _id: "64f75284ef8f606073e98448",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohimanjaka",
        fokontany: "andranomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7258663,
          lng: 47.4061587,
        },
        _id: "64f75284ef8f606073e98449",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohimanjaka",
        fokontany: "ankodondona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7345234,
          lng: 47.4073834,
        },
        _id: "64f75284ef8f606073e9844a",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohimanjaka",
        fokontany: "anjehivola",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8981274,
          lng: 47.3975867,
        },
        _id: "64f75284ef8f606073e9844b",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "fiadanana",
        fokontany: "antanety sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8832471,
          lng: 47.4122824,
        },
        _id: "64f75284ef8f606073e9844c",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "fiadanana",
        fokontany: "ambohinaorina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8893397,
          lng: 47.4275948,
        },
        _id: "64f75284ef8f606073e9844d",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "fiadanana",
        fokontany: "ampefiloha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8985841,
          lng: 47.4245319,
        },
        _id: "64f75284ef8f606073e9844e",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "fiadanana",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8983564,
          lng: 47.4110576,
        },
        _id: "64f75284ef8f606073e9844f",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "fiadanana",
        fokontany: "merimitatra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8915317,
          lng: 47.4037094,
        },
        _id: "64f75284ef8f606073e98450",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "fiadanana",
        fokontany: "atsimombohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8894622,
          lng: 47.3963622,
        },
        _id: "64f75284ef8f606073e98451",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "fiadanana",
        fokontany: "manjakatompo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8969005,
          lng: 47.3890161,
        },
        _id: "64f75284ef8f606073e98452",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "fiadanana",
        fokontany: "ambohitsaratelo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8986639,
          lng: 47.4037094,
        },
        _id: "64f75284ef8f606073e98453",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "fiadanana",
        fokontany: "soamanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9078672,
          lng: 47.4110576,
        },
        _id: "64f75284ef8f606073e98454",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "fiadanana",
        fokontany: "antanambahiny",
        __v: 0,
      },
      {
        coords: {
          lat: -18.909861,
          lng: 47.4012602,
        },
        _id: "64f75284ef8f606073e98455",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "fiadanana",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9110104,
          lng: 47.3926891,
        },
        _id: "64f75284ef8f606073e98456",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "fiadanana",
        fokontany: "marianina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6275068,
          lng: 47.3217253,
        },
        _id: "64f75284ef8f606073e98457",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahabo",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5655106,
          lng: 47.3290618,
        },
        _id: "64f75284ef8f606073e98458",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahabo",
        fokontany: "andraonimanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5736357,
          lng: 47.3241707,
        },
        _id: "64f75284ef8f606073e98459",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahabo",
        fokontany: "ambohibehivavy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5837783,
          lng: 47.3094999,
        },
        _id: "64f75284ef8f606073e9845a",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahabo",
        fokontany: "andriantsampana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5973528,
          lng: 47.3241707,
        },
        _id: "64f75284ef8f606073e9845b",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahabo",
        fokontany: "antsiriry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5888996,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e9845c",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahabo",
        fokontany: "ambohimanantiana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6028048,
          lng: 47.3437381,
        },
        _id: "64f75284ef8f606073e9845d",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahabo",
        fokontany: "andriamasy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6173666,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e9845e",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahabo",
        fokontany: "fiadanana sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6074926,
          lng: 47.3094999,
        },
        _id: "64f75284ef8f606073e9845f",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahabo",
        fokontany: "antanetibe nord",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6281552,
          lng: 47.3070552,
        },
        _id: "64f75284ef8f606073e98460",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahabo",
        fokontany: "ambatomanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6460854,
          lng: 47.29483339999999,
        },
        _id: "64f75284ef8f606073e98461",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahabo",
        fokontany: "ambodivona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6583067,
          lng: 47.3046106,
        },
        _id: "64f75284ef8f606073e98462",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahabo",
        fokontany: "antsahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6593524,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e98463",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahabo",
        fokontany: "ambohitrakoho",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6414164,
          lng: 47.3290618,
        },
        _id: "64f75284ef8f606073e98464",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahabo",
        fokontany: "analamahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6502593,
          lng: 47.3437381,
        },
        _id: "64f75284ef8f606073e98465",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahabo",
        fokontany: "ambohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6462286,
          lng: 47.363313,
        },
        _id: "64f75284ef8f606073e98466",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahabo",
        fokontany: "ankadivory",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6632923,
          lng: 47.33517639999999,
        },
        _id: "64f75284ef8f606073e98467",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahabo",
        fokontany: "antanivony",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6710948,
          lng: 47.33762249999999,
        },
        _id: "64f75284ef8f606073e98468",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahabo",
        fokontany: "ambodivato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8955129,
          lng: 47.3461846,
        },
        _id: "64f75284ef8f606073e98469",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahereza",
        fokontany: "mahereza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8815634,
          lng: 47.3388456,
        },
        _id: "64f75284ef8f606073e9846a",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahereza",
        fokontany: "ambilany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8872377,
          lng: 47.35474809999999,
        },
        _id: "64f75284ef8f606073e9846b",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahereza",
        fokontany: "ampilanonana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8896845,
          lng: 47.3339535,
        },
        _id: "64f75284ef8f606073e9846c",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahereza",
        fokontany: "ambohidava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8971981,
          lng: 47.3437381,
        },
        _id: "64f75284ef8f606073e9846d",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahereza",
        fokontany: "ambodivona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.901342,
          lng: 47.3584185,
        },
        _id: "64f75284ef8f606073e9846e",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahereza",
        fokontany: "ambohitrinilahy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9100868,
          lng: 47.3767749,
        },
        _id: "64f75284ef8f606073e9846f",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "mahereza",
        fokontany: "ambohikely sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8178569,
          lng: 47.4588461,
        },
        _id: "64f75284ef8f606073e98470",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antanetibe",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6419157,
          lng: 47.4245319,
        },
        _id: "64f75284ef8f606073e98471",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antanetibe",
        fokontany: "ambohidravaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6624845,
          lng: 47.3535246,
        },
        _id: "64f75284ef8f606073e98472",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antanetibe",
        fokontany: "antanety",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6584544,
          lng: 47.37310309999999,
        },
        _id: "64f75284ef8f606073e98473",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antanetibe",
        fokontany: "ankadisarotra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6704188,
          lng: 47.4245319,
        },
        _id: "64f75284ef8f606073e98474",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antanetibe",
        fokontany: "antoby avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6745861,
          lng: 47.38411929999999,
        },
        _id: "64f75284ef8f606073e98475",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antanetibe",
        fokontany: "ambatomboahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6719783,
          lng: 47.3535246,
        },
        _id: "64f75284ef8f606073e98476",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antanetibe",
        fokontany: "miantso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6622258,
          lng: 47.3951378,
        },
        _id: "64f75284ef8f606073e98477",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antanetibe",
        fokontany: "merikanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6774464,
          lng: 47.37310309999999,
        },
        _id: "64f75284ef8f606073e98478",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antanetibe",
        fokontany: "ambatombositra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6816336,
          lng: 47.3498545,
        },
        _id: "64f75284ef8f606073e98479",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antanetibe",
        fokontany: "ambanimaso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.682116,
          lng: 47.3388456,
        },
        _id: "64f75284ef8f606073e9847a",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antanetibe",
        fokontany: "bearambola",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6896782,
          lng: 47.3828952,
        },
        _id: "64f75284ef8f606073e9847b",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antanetibe",
        fokontany: "mahazaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6876637,
          lng: 47.3926891,
        },
        _id: "64f75284ef8f606073e9847c",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antanetibe",
        fokontany: "antaninandro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6943463,
          lng: 47.3486311,
        },
        _id: "64f75284ef8f606073e9847d",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antanetibe",
        fokontany: "ambohidranomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6998798,
          lng: 47.35780680000001,
        },
        _id: "64f75284ef8f606073e9847e",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antanetibe",
        fokontany: "ambohitraivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6964402,
          lng: 47.37310309999999,
        },
        _id: "64f75284ef8f606073e9847f",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antanetibe",
        fokontany: "antsahamaina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7059378,
          lng: 47.37310309999999,
        },
        _id: "64f75284ef8f606073e98480",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antanetibe",
        fokontany: "ambohiboahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7113276,
          lng: 47.3584185,
        },
        _id: "64f75284ef8f606073e98481",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antanetibe",
        fokontany: "ambohijatovo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7086753,
          lng: 47.3828952,
        },
        _id: "64f75284ef8f606073e98482",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "antanetibe",
        fokontany: "antoby atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.693002,
          lng: 47.557011,
        },
        _id: "64f75284ef8f606073e98483",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohipihaonana",
        fokontany: "ambohipihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6682029,
          lng: 47.57912349999999,
        },
        _id: "64f75284ef8f606073e98484",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohipihaonana",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6624306,
          lng: 47.6012453,
        },
        _id: "64f75284ef8f606073e98485",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohipihaonana",
        fokontany: "ambatotelo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6838437,
          lng: 47.5840386,
        },
        _id: "64f75284ef8f606073e98486",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohipihaonana",
        fokontany: "maharidaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.705581,
          lng: 47.5594675,
        },
        _id: "64f75284ef8f606073e98487",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohipihaonana",
        fokontany: "ampahimaro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.703995,
          lng: 47.54626520000001,
        },
        _id: "64f75284ef8f606073e98488",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohipihaonana",
        fokontany: "amparafaravato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6872131,
          lng: 47.5447303,
        },
        _id: "64f75284ef8f606073e98489",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohipihaonana",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6831214,
          lng: 47.5299973,
        },
        _id: "64f75284ef8f606073e9848a",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohipihaonana",
        fokontany: "avaratrimangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7048798,
          lng: 47.5398188,
        },
        _id: "64f75284ef8f606073e9848b",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohipihaonana",
        fokontany: "ambohimitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7145822,
          lng: 47.5533265,
        },
        _id: "64f75284ef8f606073e9848c",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohipihaonana",
        fokontany: "ambohibao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7252784,
          lng: 47.5447303,
        },
        _id: "64f75284ef8f606073e9848d",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambohipihaonana",
        fokontany: "ankadifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5503279,
          lng: 47.4882762,
        },
        _id: "64f75284ef8f606073e9848e",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambato",
        fokontany: "ambato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4485228,
          lng: 47.4980899,
        },
        _id: "64f75284ef8f606073e9848f",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambato",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5055259,
          lng: 47.4980899,
        },
        _id: "64f75284ef8f606073e98490",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambato",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5435371,
          lng: 47.4980899,
        },
        _id: "64f75284ef8f606073e98491",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambato",
        fokontany: "ambohijorery",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5632254,
          lng: 47.48337,
        },
        _id: "64f75284ef8f606073e98492",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambato",
        fokontany: "miaramasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.532689,
          lng: 47.4588461,
        },
        _id: "64f75284ef8f606073e98493",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambato",
        fokontany: "ampanotokana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5407858,
          lng: 47.557011,
        },
        _id: "64f75284ef8f606073e98494",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambato",
        fokontany: "mahitsitady",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5835807,
          lng: 47.4539427,
        },
        _id: "64f75284ef8f606073e98495",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambato",
        fokontany: "soaloka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5873218,
          lng: 47.4760116,
        },
        _id: "64f75284ef8f606073e98496",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambato",
        fokontany: "antsahalava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6080461,
          lng: 47.5422745,
        },
        _id: "64f75284ef8f606073e98497",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambato",
        fokontany: "vatovandana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.624311,
          lng: 47.4637499,
        },
        _id: "64f75284ef8f606073e98498",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambato",
        fokontany: "ambohimitsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6284175,
          lng: 47.512814,
        },
        _id: "64f75284ef8f606073e98499",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambato",
        fokontany: "ankadibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6463771,
          lng: 47.4662021,
        },
        _id: "64f75284ef8f606073e9849a",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambato",
        fokontany: "ambanimaso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6582782,
          lng: 47.48337,
        },
        _id: "64f75284ef8f606073e9849b",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambato",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6623297,
          lng: 47.4637499,
        },
        _id: "64f75284ef8f606073e9849c",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "ambato",
        fokontany: "ankafotra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6057408,
          lng: 47.38411929999999,
        },
        _id: "64f75284ef8f606073e9849d",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anjanadoria",
        fokontany: "anjanadoria",
        __v: 0,
      },
      {
        coords: {
          lat: -18.516667,
          lng: 47.4166669,
        },
        _id: "64f75284ef8f606073e9849e",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anjanadoria",
        fokontany: "ambohimanatrika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5455633,
          lng: 47.3853435,
        },
        _id: "64f75284ef8f606073e9849f",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anjanadoria",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5279244,
          lng: 47.3559715,
        },
        _id: "64f75284ef8f606073e984a0",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anjanadoria",
        fokontany: "andranomaintso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5631574,
          lng: 47.3461846,
        },
        _id: "64f75284ef8f606073e984a1",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anjanadoria",
        fokontany: "satrokoditra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.581143,
          lng: 47.3682078,
        },
        _id: "64f75284ef8f606073e984a2",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anjanadoria",
        fokontany: "bevato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5699859,
          lng: 47.404934,
        },
        _id: "64f75284ef8f606073e984a3",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anjanadoria",
        fokontany: "antetezankazo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5889765,
          lng: 47.404934,
        },
        _id: "64f75284ef8f606073e984a4",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anjanadoria",
        fokontany: "antamboho",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5945346,
          lng: 47.3694316,
        },
        _id: "64f75284ef8f606073e984a5",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anjanadoria",
        fokontany: "ampataka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6160573,
          lng: 47.3657603,
        },
        _id: "64f75284ef8f606073e984a6",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anjanadoria",
        fokontany: "merinavaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6043985,
          lng: 47.3963622,
        },
        _id: "64f75284ef8f606073e984a7",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anjanadoria",
        fokontany: "mandrosoa est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6039178,
          lng: 47.4245319,
        },
        _id: "64f75284ef8f606073e984a8",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anjanadoria",
        fokontany: "manankasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6252703,
          lng: 47.4073834,
        },
        _id: "64f75284ef8f606073e984a9",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anjanadoria",
        fokontany: "amboniriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.637251,
          lng: 47.380341,
        },
        _id: "64f75284ef8f606073e984aa",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "anjanadoria",
        fokontany: "madiorano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6141484,
          lng: 47.6159983,
        },
        _id: "64f75284ef8f606073e984ab",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "avaratsena",
        fokontany: "avaratsena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5509255,
          lng: 47.6110802,
        },
        _id: "64f75284ef8f606073e984ac",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "avaratsena",
        fokontany: "mahavanona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5675946,
          lng: 47.5938703,
        },
        _id: "64f75284ef8f606073e984ad",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "avaratsena",
        fokontany: "avaratrimanarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5951098,
          lng: 47.6159983,
        },
        _id: "64f75284ef8f606073e984ae",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "avaratsena",
        fokontany: "antanetimboahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6124595,
          lng: 47.5840386,
        },
        _id: "64f75284ef8f606073e984af",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "avaratsena",
        fokontany: "ankodondona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6175503,
          lng: 47.6110802,
        },
        _id: "64f75284ef8f606073e984b0",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "avaratsena",
        fokontany: "malaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6386245,
          lng: 47.6356755,
        },
        _id: "64f75284ef8f606073e984b1",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "avaratsena",
        fokontany: "ambatomanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6461104,
          lng: 47.6110802,
        },
        _id: "64f75284ef8f606073e984b2",
        province: "antananarivo",
        region: "analamanga",
        district: "ambohidratrimo",
        commune: "avaratsena",
        fokontany: "sahalemaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3235872,
          lng: 47.1313396,
        },
        _id: "64f75284ef8f606073e984b3",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ankazobe",
        fokontany: "ankazobe III",
        __v: 0,
      },
      {
        coords: {
          lat: -18.113377,
          lng: 47.24353319999999,
        },
        _id: "64f75284ef8f606073e984b4",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ankazobe",
        fokontany: "kiva",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1958101,
          lng: 47.2337675,
        },
        _id: "64f75284ef8f606073e984b5",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ankazobe",
        fokontany: "ambohitrampovoany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2709397,
          lng: 47.1118527,
        },
        _id: "64f75284ef8f606073e984b6",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ankazobe",
        fokontany: "ampahadiminy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3201678,
          lng: 46.9999471,
        },
        _id: "64f75284ef8f606073e984b7",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ankazobe",
        fokontany: "antanetibe soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3006409,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e984b8",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ankazobe",
        fokontany: "voninahitrinitany II",
        __v: 0,
      },
      {
        coords: {
          lat: -18.302324,
          lng: 47.1142882,
        },
        _id: "64f75284ef8f606073e984b9",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ankazobe",
        fokontany: "ankazobe II",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3218999,
          lng: 47.0996772,
        },
        _id: "64f75284ef8f606073e984ba",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ankazobe",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3212254,
          lng: 47.1142882,
        },
        _id: "64f75284ef8f606073e984bb",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ankazobe",
        fokontany: "ankazobe I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3127735,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e984bc",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ankazobe",
        fokontany: "ambatomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3377645,
          lng: 47.1654595,
        },
        _id: "64f75284ef8f606073e984bd",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ankazobe",
        fokontany: "tsisangaina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3586991,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e984be",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ankazobe",
        fokontany: "antamboho sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3897709,
          lng: 47.1313396,
        },
        _id: "64f75284ef8f606073e984bf",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ankazobe",
        fokontany: "mandrosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3505852,
          lng: 47.0923732,
        },
        _id: "64f75284ef8f606073e984c0",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ankazobe",
        fokontany: "ambatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3559501,
          lng: 47.043707,
        },
        _id: "64f75284ef8f606073e984c1",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ankazobe",
        fokontany: "voninahitrinitany I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1394133,
          lng: 47.0972424,
        },
        _id: "64f75284ef8f606073e984c2",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "talata angavo",
        fokontany: "talata angavo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1151833,
          lng: 47.0777685,
        },
        _id: "64f75284ef8f606073e984c3",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "talata angavo",
        fokontany: "maharidaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0951735,
          lng: 46.9707947,
        },
        _id: "64f75284ef8f606073e984c4",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "talata angavo",
        fokontany: "amboanjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1483548,
          lng: 46.9756523,
        },
        _id: "64f75284ef8f606073e984c5",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "talata angavo",
        fokontany: "sarodrivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1622476,
          lng: 47.1459597,
        },
        _id: "64f75284ef8f606073e984c6",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "talata angavo",
        fokontany: "manontanitsiloza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.18162,
          lng: 47.031261,
        },
        _id: "64f75284ef8f606073e984c7",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "talata angavo",
        fokontany: "mangasoavina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1894378,
          lng: 46.9707947,
        },
        _id: "64f75284ef8f606073e984c8",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "talata angavo",
        fokontany: "ambohimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2271571,
          lng: 46.9707947,
        },
        _id: "64f75284ef8f606073e984c9",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "talata angavo",
        fokontany: "amberobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1785685,
          lng: 47.0680343,
        },
        _id: "64f75284ef8f606073e984ca",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "talata angavo",
        fokontany: "tsaramasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2244457,
          lng: 47.0291162,
        },
        _id: "64f75284ef8f606073e984cb",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "talata angavo",
        fokontany: "ambohijafy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2405929,
          lng: 47.08750449999999,
        },
        _id: "64f75284ef8f606073e984cc",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "talata angavo",
        fokontany: "ambohimanarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2243074,
          lng: 47.1654595,
        },
        _id: "64f75284ef8f606073e984cd",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "talata angavo",
        fokontany: "androva atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4345637,
          lng: 47.1691166,
        },
        _id: "64f75284ef8f606073e984ce",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ambohitromby",
        fokontany: "ambohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4006027,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e984cf",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ambohitromby",
        fokontany: "avarabato voajanahary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3918266,
          lng: 47.2240036,
        },
        _id: "64f75284ef8f606073e984d0",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ambohitromby",
        fokontany: "firaisantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4215876,
          lng: 47.2630701,
        },
        _id: "64f75284ef8f606073e984d1",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ambohitromby",
        fokontany: "andavak'ambohimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4266349,
          lng: 47.1874058,
        },
        _id: "64f75284ef8f606073e984d2",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ambohitromby",
        fokontany: "ambohimora",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4465179,
          lng: 47.1313396,
        },
        _id: "64f75284ef8f606073e984d3",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ambohitromby",
        fokontany: "miantsoarivo I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4520134,
          lng: 47.2191224,
        },
        _id: "64f75284ef8f606073e984d4",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ambohitromby",
        fokontany: "fieferana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4560557,
          lng: 47.1996022,
        },
        _id: "64f75284ef8f606073e984d5",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ambohitromby",
        fokontany: "soavimbahoaka lovasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4844591,
          lng: 47.1996022,
        },
        _id: "64f75284ef8f606073e984d6",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ambohitromby",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.488491,
          lng: 47.1800893,
        },
        _id: "64f75284ef8f606073e984d7",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ambohitromby",
        fokontany: "ambohidroa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5026332,
          lng: 47.1459597,
        },
        _id: "64f75284ef8f606073e984d8",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ambohitromby",
        fokontany: "sambaina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4930349,
          lng: 47.0777685,
        },
        _id: "64f75284ef8f606073e984d9",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "antotohazo",
        fokontany: "antotohazo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4174046,
          lng: 47.0777685,
        },
        _id: "64f75284ef8f606073e984da",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "antotohazo",
        fokontany: "mangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4333162,
          lng: 47.1094174,
        },
        _id: "64f75284ef8f606073e984db",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "antotohazo",
        fokontany: "ankazonaorina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5186769,
          lng: 47.0680343,
        },
        _id: "64f75284ef8f606073e984dc",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "antotohazo",
        fokontany: "ambohimanoro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5754198,
          lng: 47.0680343,
        },
        _id: "64f75284ef8f606073e984dd",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "antotohazo",
        fokontany: "antongombato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4590773,
          lng: 46.9902277,
        },
        _id: "64f75284ef8f606073e984de",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "marondry",
        fokontany: "miarinarivo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3889629,
          lng: 47.0096682,
        },
        _id: "64f75284ef8f606073e984df",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "marondry",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3915563,
          lng: 46.9513691,
        },
        _id: "64f75284ef8f606073e984e0",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "marondry",
        fokontany: "mariarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5096639,
          lng: 46.9853688,
        },
        _id: "64f75284ef8f606073e984e1",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "marondry",
        fokontany: "andriavato masindray",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5300321,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e984e2",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "marondry",
        fokontany: "voninahitrinitany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.55,
          lng: 47.016667,
        },
        _id: "64f75284ef8f606073e984e3",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "marondry",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6052,
          lng: 47.203621,
        },
        _id: "64f75284ef8f606073e984e4",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fihaonana",
        fokontany: "fihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5568486,
          lng: 47.2044815,
        },
        _id: "64f75284ef8f606073e984e5",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fihaonana",
        fokontany: "tsimiamboholahy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5820328,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e984e6",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fihaonana",
        fokontany: "andranofotsifandrosoana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5539646,
          lng: 47.1264672,
        },
        _id: "64f75284ef8f606073e984e7",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fihaonana",
        fokontany: "lovasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5899189,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e984e8",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fihaonana",
        fokontany: "tsimialona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5968304,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e984e9",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fihaonana",
        fokontany: "andriatsibibiarivony",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5911905,
          lng: 47.1410858,
        },
        _id: "64f75284ef8f606073e984ea",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fihaonana",
        fokontany: "manantenasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6086471,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e984eb",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fihaonana",
        fokontany: "tsaramivondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6074267,
          lng: 47.2752844,
        },
        _id: "64f75284ef8f606073e984ec",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fihaonana",
        fokontany: "ambohitraina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6175542,
          lng: 47.2606276,
        },
        _id: "64f75284ef8f606073e984ed",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fihaonana",
        fokontany: "manankasina fomami",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6212501,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e984ee",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fihaonana",
        fokontany: "tsitakondaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6265373,
          lng: 47.1996022,
        },
        _id: "64f75284ef8f606073e984ef",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fihaonana",
        fokontany: "antsampanimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6168575,
          lng: 47.1313396,
        },
        _id: "64f75284ef8f606073e984f0",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fihaonana",
        fokontany: "sambatra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6278038,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e984f1",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fihaonana",
        fokontany: "masindray est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6412712,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e984f2",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fihaonana",
        fokontany: "fokontsambo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6361974,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e984f3",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fihaonana",
        fokontany: "andranovelona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6650697,
          lng: 47.1849668,
        },
        _id: "64f75284ef8f606073e984f4",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fihaonana",
        fokontany: "madiokororoka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6537006,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e984f5",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fihaonana",
        fokontany: "ambohipiainana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5102366,
          lng: 47.2581852,
        },
        _id: "64f75284ef8f606073e984f6",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "mahavelona",
        fokontany: "ambohimanantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4676189,
          lng: 47.3608657,
        },
        _id: "64f75284ef8f606073e984f7",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "mahavelona",
        fokontany: "mandriambero andrenivatosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4703254,
          lng: 47.3021661,
        },
        _id: "64f75284ef8f606073e984f8",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "mahavelona",
        fokontany: "manirisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4493341,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e984f9",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "mahavelona",
        fokontany: "tsaratobisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4730024,
          lng: 47.24353319999999,
        },
        _id: "64f75284ef8f606073e984fa",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "mahavelona",
        fokontany: "volaniray",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4949581,
          lng: 47.2118015,
        },
        _id: "64f75284ef8f606073e984fb",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "mahavelona",
        fokontany: "mandimbisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5057882,
          lng: 47.216682,
        },
        _id: "64f75284ef8f606073e984fc",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "mahavelona",
        fokontany: "andrianondrana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5112263,
          lng: 47.2362087,
        },
        _id: "64f75284ef8f606073e984fd",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "mahavelona",
        fokontany: "telomirefy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5089106,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e984fe",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "mahavelona",
        fokontany: "tsiaverindoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5035228,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e984ff",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "mahavelona",
        fokontany: "anjezika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5170598,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e98500",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "mahavelona",
        fokontany: "tsilokana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.53191,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e98501",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "mahavelona",
        fokontany: "ambohitrimangalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5399763,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e98502",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "mahavelona",
        fokontany: "antolojanahary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5290841,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e98503",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "mahavelona",
        fokontany: "manantenasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5655204,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e98504",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "mahavelona",
        fokontany: "fifanasoavana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5616511,
          lng: 47.2386501,
        },
        _id: "64f75284ef8f606073e98505",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "mahavelona",
        fokontany: "ambohibe firaisantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5576228,
          lng: 47.2581852,
        },
        _id: "64f75284ef8f606073e98506",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "mahavelona",
        fokontany: "ambatotsaraorina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5630704,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e98507",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "mahavelona",
        fokontany: "manjakazonirina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5522735,
          lng: 47.3070552,
        },
        _id: "64f75284ef8f606073e98508",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "mahavelona",
        fokontany: "andrianony manjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.226629,
          lng: 46.882488,
        },
        _id: "64f75284ef8f606073e98509",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fiadanana",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.860767,
          lng: 46.6319212,
        },
        _id: "64f75284ef8f606073e9850a",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fiadanana",
        fokontany: "antsahandravolaina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9543708,
          lng: 46.7671967,
        },
        _id: "64f75284ef8f606073e9850b",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fiadanana",
        fokontany: "amparihikambana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0362604,
          lng: 46.7575221,
        },
        _id: "64f75284ef8f606073e9850c",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fiadanana",
        fokontany: "androva",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1262345,
          lng: 46.8446611,
        },
        _id: "64f75284ef8f606073e9850d",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fiadanana",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1867609,
          lng: 46.8931368,
        },
        _id: "64f75284ef8f606073e9850e",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fiadanana",
        fokontany: "ambararata",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1691882,
          lng: 46.7285093,
        },
        _id: "64f75284ef8f606073e9850f",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fiadanana",
        fokontany: "ambohimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2970172,
          lng: 46.815598,
        },
        _id: "64f75284ef8f606073e98510",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fiadanana",
        fokontany: "tsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.33021,
          lng: 46.91663,
        },
        _id: "64f75284ef8f606073e98511",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fiadanana",
        fokontany: "soavimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4276563,
          lng: 46.8446611,
        },
        _id: "64f75284ef8f606073e98512",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "fiadanana",
        fokontany: "soavimbahoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.083333,
          lng: 46.766667,
        },
        _id: "64f75284ef8f606073e98513",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "tsaramasoandro",
        fokontany: "tsaramasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7751729,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e98514",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "tsaramasoandro",
        fokontany: "ampanikely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7869499,
          lng: 47.2240036,
        },
        _id: "64f75284ef8f606073e98515",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "tsaramasoandro",
        fokontany: "andranomiely nord",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8240097,
          lng: 47.11672369999999,
        },
        _id: "64f75284ef8f606073e98516",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "tsaramasoandro",
        fokontany: "irindravato",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8330308,
          lng: 47.1849668,
        },
        _id: "64f75284ef8f606073e98517",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "tsaramasoandro",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8027135,
          lng: 47.2826144,
        },
        _id: "64f75284ef8f606073e98518",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "tsaramasoandro",
        fokontany: "analamirafy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8457512,
          lng: 47.3021661,
        },
        _id: "64f75284ef8f606073e98519",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "tsaramasoandro",
        fokontany: "bevony atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9250925,
          lng: 47.3510778,
        },
        _id: "64f75284ef8f606073e9851a",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "tsaramasoandro",
        fokontany: "sambotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8435703,
          lng: 47.2240036,
        },
        _id: "64f75284ef8f606073e9851b",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "tsaramasoandro",
        fokontany: "andranomiely sud",
        __v: 0,
      },
      {
        coords: {
          lat: -17.883333,
          lng: 47.1666669,
        },
        _id: "64f75284ef8f606073e9851c",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "tsaramasoandro",
        fokontany: "ambolotarabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.954541,
          lng: 47.1459597,
        },
        _id: "64f75284ef8f606073e9851d",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "tsaramasoandro",
        fokontany: "tsiazokararo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9417626,
          lng: 47.2728413,
        },
        _id: "64f75284ef8f606073e9851e",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "tsaramasoandro",
        fokontany: "tsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0467429,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e9851f",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "tsaramasoandro",
        fokontany: "manerinerina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2794269,
          lng: 47.33150759999999,
        },
        _id: "64f75284ef8f606073e98520",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ambolotarakely",
        fokontany: "ambolotarakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2551155,
          lng: 47.3119448,
        },
        _id: "64f75284ef8f606073e98521",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ambolotarakely",
        fokontany: "maharidaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3699915,
          lng: 47.4196318,
        },
        _id: "64f75284ef8f606073e98522",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ambolotarakely",
        fokontany: "ankorohoro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3471976,
          lng: 47.2337675,
        },
        _id: "64f75284ef8f606073e98523",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ambolotarakely",
        fokontany: "manerinerina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3999112,
          lng: 47.3217253,
        },
        _id: "64f75284ef8f606073e98524",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "ambolotarakely",
        fokontany: "anosikely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0734723,
          lng: 47.4098328,
        },
        _id: "64f75284ef8f606073e98525",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "antakavana",
        fokontany: "antakavana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9946541,
          lng: 47.4686543,
        },
        _id: "64f75284ef8f606073e98526",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "antakavana",
        fokontany: "anosikely nord",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0832654,
          lng: 47.3412918,
        },
        _id: "64f75284ef8f606073e98527",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "antakavana",
        fokontany: "antaniditra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1083658,
          lng: 47.4686543,
        },
        _id: "64f75284ef8f606073e98528",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "antakavana",
        fokontany: "maromandia",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1521929,
          lng: 47.3510778,
        },
        _id: "64f75284ef8f606073e98529",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "antakavana",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9637827,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e9852a",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "kiangara",
        fokontany: "kianjasoa kiangara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9072543,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e9852b",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "kiangara",
        fokontany: "ambohinaorina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.866667,
          lng: 46.95,
        },
        _id: "64f75284ef8f606073e9852c",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "kiangara",
        fokontany: "andriamenakely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.939137,
          lng: 46.9513691,
        },
        _id: "64f75284ef8f606073e9852d",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "kiangara",
        fokontany: "mandrarahody",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9280167,
          lng: 47.0485715,
        },
        _id: "64f75284ef8f606073e9852e",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "kiangara",
        fokontany: "bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9623199,
          lng: 46.912769,
        },
        _id: "64f75284ef8f606073e9852f",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "kiangara",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.001978,
          lng: 47.0777685,
        },
        _id: "64f75284ef8f606073e98530",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "kiangara",
        fokontany: "ambohimiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9735975,
          lng: 46.8882871,
        },
        _id: "64f75284ef8f606073e98531",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "kiangara",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0347027,
          lng: 46.9222445,
        },
        _id: "64f75284ef8f606073e98532",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "kiangara",
        fokontany: "ambohitrolomahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0251326,
          lng: 46.9902277,
        },
        _id: "64f75284ef8f606073e98533",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "kiangara",
        fokontany: "ambohimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0546579,
          lng: 47.0291162,
        },
        _id: "64f75284ef8f606073e98534",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "kiangara",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0435949,
          lng: 47.1264672,
        },
        _id: "64f75284ef8f606073e98535",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "kiangara",
        fokontany: "tsiafahy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.716435,
          lng: 47.1654595,
        },
        _id: "64f75284ef8f606073e98536",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "miantso",
        fokontany: "miantso lovasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6266961,
          lng: 47.0485715,
        },
        _id: "64f75284ef8f606073e98537",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "miantso",
        fokontany: "tafaina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6486255,
          lng: 47.1264672,
        },
        _id: "64f75284ef8f606073e98538",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "miantso",
        fokontany: "talata avaradrano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6666684,
          lng: 47.0729012,
        },
        _id: "64f75284ef8f606073e98539",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "miantso",
        fokontany: "fenosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6794009,
          lng: 47.2191224,
        },
        _id: "64f75284ef8f606073e9853a",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "miantso",
        fokontany: "avaratrambato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6983614,
          lng: 47.2191224,
        },
        _id: "64f75284ef8f606073e9853b",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "miantso",
        fokontany: "antsampanimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6968642,
          lng: 47.1800893,
        },
        _id: "64f75284ef8f606073e9853c",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "miantso",
        fokontany: "soavina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6883437,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e9853d",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "miantso",
        fokontany: "mandrosorasaha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6909293,
          lng: 47.133776,
        },
        _id: "64f75284ef8f606073e9853e",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "miantso",
        fokontany: "andriateiara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7175038,
          lng: 47.1357198,
        },
        _id: "64f75284ef8f606073e9853f",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "miantso",
        fokontany: "andraiso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.713331,
          lng: 47.2386501,
        },
        _id: "64f75284ef8f606073e98540",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "miantso",
        fokontany: "andrefambato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7387502,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e98541",
        province: "antananarivo",
        region: "analamanga",
        district: "ankazobe",
        commune: "miantso",
        fokontany: "mandriankeniheny",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9209738,
          lng: 47.8069304,
        },
        _id: "64f75284ef8f606073e98542",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "ampiadianombalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8931646,
          lng: 47.8056964,
        },
        _id: "64f75284ef8f606073e98543",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "ambohimahandry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9006835,
          lng: 47.8155695,
        },
        _id: "64f75284ef8f606073e98544",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "antsakambahiny",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9156756,
          lng: 47.8007606,
        },
        _id: "64f75284ef8f606073e98545",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "betambatra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9121059,
          lng: 47.80939859999999,
        },
        _id: "64f75284ef8f606073e98546",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9082035,
          lng: 47.8254443,
        },
        _id: "64f75284ef8f606073e98547",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "ambohimiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9157246,
          lng: 47.835321,
        },
        _id: "64f75284ef8f606073e98548",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "andranomangatsiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9105687,
          lng: 47.8081645,
        },
        _id: "64f75284ef8f606073e98549",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "manjakandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9281396,
          lng: 47.8069304,
        },
        _id: "64f75284ef8f606073e9854a",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "marinjara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9307208,
          lng: 47.8205067,
        },
        _id: "64f75284ef8f606073e9854b",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "volavy gara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9263551,
          lng: 47.8112498,
        },
        _id: "64f75284ef8f606073e9854c",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "manakasikely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9311326,
          lng: 47.8112498,
        },
        _id: "64f75284ef8f606073e9854d",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "anosimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.701031,
          lng: 47.880089,
        },
        _id: "64f75284ef8f606073e9854e",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "soavinandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9286326,
          lng: 47.7958252,
        },
        _id: "64f75284ef8f606073e9854f",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "antsahamalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9370074,
          lng: 47.8044624,
        },
        _id: "64f75284ef8f606073e98550",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "mahatafandry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9415888,
          lng: 47.7908903,
        },
        _id: "64f75284ef8f606073e98551",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "samia",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9436816,
          lng: 47.8155695,
        },
        _id: "64f75284ef8f606073e98552",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "anosiarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9474738,
          lng: 47.8377905,
        },
        _id: "64f75284ef8f606073e98553",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "ambohiboromanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9500933,
          lng: 47.778555,
        },
        _id: "64f75284ef8f606073e98554",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "betsitoavina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9641695,
          lng: 47.8205067,
        },
        _id: "64f75284ef8f606073e98555",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "fieferana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9544483,
          lng: 47.8242099,
        },
        _id: "64f75284ef8f606073e98556",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "antanibe centre",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9652769,
          lng: 47.867433,
        },
        _id: "64f75284ef8f606073e98557",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "antsahamaina est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9747036,
          lng: 47.7982928,
        },
        _id: "64f75284ef8f606073e98558",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "ambohibao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9919532,
          lng: 47.8044624,
        },
        _id: "64f75284ef8f606073e98559",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "manjakandriana",
        fokontany: "antanimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9140598,
          lng: 47.872375,
        },
        _id: "64f75284ef8f606073e9855a",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatolaona",
        fokontany: "ambatolaona ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8731064,
          lng: 47.931715,
        },
        _id: "64f75284ef8f606073e9855b",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatolaona",
        fokontany: "andasibe avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9147855,
          lng: 47.9267674,
        },
        _id: "64f75284ef8f606073e9855c",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatolaona",
        fokontany: "mandraka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9246189,
          lng: 47.8501395,
        },
        _id: "64f75284ef8f606073e9855d",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatolaona",
        fokontany: "ambatomivahy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9352593,
          lng: 47.897092,
        },
        _id: "64f75284ef8f606073e9855e",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatolaona",
        fokontany: "ambatolaona est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9382953,
          lng: 47.8649622,
        },
        _id: "64f75284ef8f606073e9855f",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatolaona",
        fokontany: "mahitsitady",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9626243,
          lng: 47.9267674,
        },
        _id: "64f75284ef8f606073e98560",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatolaona",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9913326,
          lng: 47.9267674,
        },
        _id: "64f75284ef8f606073e98561",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatolaona",
        fokontany: "andranobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8898785,
          lng: 47.7723885,
        },
        _id: "64f75284ef8f606073e98562",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sambaina",
        fokontany: "sambaina ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8736876,
          lng: 47.778555,
        },
        _id: "64f75284ef8f606073e98563",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sambaina",
        fokontany: "mandimbisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8747257,
          lng: 47.7908903,
        },
        _id: "64f75284ef8f606073e98564",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sambaina",
        fokontany: "ambatomafana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8830473,
          lng: 47.76498950000001,
        },
        _id: "64f75284ef8f606073e98565",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sambaina",
        fokontany: "sambaina ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8847957,
          lng: 47.797059,
        },
        _id: "64f75284ef8f606073e98566",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sambaina",
        fokontany: "nanganehana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8929763,
          lng: 47.792124,
        },
        _id: "64f75284ef8f606073e98567",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sambaina",
        fokontany: "antombotokana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9012936,
          lng: 47.7662226,
        },
        _id: "64f75284ef8f606073e98568",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sambaina",
        fokontany: "marovohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9054103,
          lng: 47.7810219,
        },
        _id: "64f75284ef8f606073e98569",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sambaina",
        fokontany: "ankazobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9025278,
          lng: 47.792124,
        },
        _id: "64f75284ef8f606073e9856a",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sambaina",
        fokontany: "antsahafohy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9092385,
          lng: 47.7754717,
        },
        _id: "64f75284ef8f606073e9856b",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sambaina",
        fokontany: "amboanemba",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9123796,
          lng: 47.7674557,
        },
        _id: "64f75284ef8f606073e9856c",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sambaina",
        fokontany: "soarano ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9137816,
          lng: 47.7896566,
        },
        _id: "64f75284ef8f606073e9856d",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sambaina",
        fokontany: "ambondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9171544,
          lng: 47.7674557,
        },
        _id: "64f75284ef8f606073e9856e",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sambaina",
        fokontany: "ankadihambana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9197368,
          lng: 47.7810219,
        },
        _id: "64f75284ef8f606073e9856f",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sambaina",
        fokontany: "ankadimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9285682,
          lng: 47.7612905,
        },
        _id: "64f75284ef8f606073e98570",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sambaina",
        fokontany: "andranonjaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8669746,
          lng: 47.80079740000001,
        },
        _id: "64f75284ef8f606073e98571",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohibary",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8369046,
          lng: 47.88720379999999,
        },
        _id: "64f75284ef8f606073e98572",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohibary",
        fokontany: "ankofika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8529177,
          lng: 47.8155695,
        },
        _id: "64f75284ef8f606073e98573",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohibary",
        fokontany: "andrafy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8512333,
          lng: 47.8526097,
        },
        _id: "64f75284ef8f606073e98574",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohibary",
        fokontany: "lapahambana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8583635,
          lng: 47.8007606,
        },
        _id: "64f75284ef8f606073e98575",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohibary",
        fokontany: "antanimasaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8669746,
          lng: 47.80079740000001,
        },
        _id: "64f75284ef8f606073e98576",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohibary",
        fokontany: "tsarasaotra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8737558,
          lng: 47.84766949999999,
        },
        _id: "64f75284ef8f606073e98577",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohibary",
        fokontany: "ambohimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8740599,
          lng: 47.8056964,
        },
        _id: "64f75284ef8f606073e98578",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohibary",
        fokontany: "anosy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8781689,
          lng: 47.8205067,
        },
        _id: "64f75284ef8f606073e98579",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohibary",
        fokontany: "anosivato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8863526,
          lng: 47.8155695,
        },
        _id: "64f75284ef8f606073e9857a",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohibary",
        fokontany: "ampanazava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.967898,
          lng: 47.7354041,
        },
        _id: "64f75284ef8f606073e9857b",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomanga",
        fokontany: "ambatomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9681463,
          lng: 47.7662226,
        },
        _id: "64f75284ef8f606073e9857c",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomanga",
        fokontany: "ambohibary est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9804523,
          lng: 47.7760883,
        },
        _id: "64f75284ef8f606073e9857d",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomanga",
        fokontany: "ambohimbory sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9844956,
          lng: 47.7563588,
        },
        _id: "64f75284ef8f606073e9857e",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomanga",
        fokontany: "ambohitsimeloka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9981818,
          lng: 47.7711552,
        },
        _id: "64f75284ef8f606073e9857f",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomanga",
        fokontany: "isoavina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9656249,
          lng: 47.7144578,
        },
        _id: "64f75284ef8f606073e98580",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "alarobia",
        fokontany: "alarobia",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9407915,
          lng: 47.7366365,
        },
        _id: "64f75284ef8f606073e98581",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "alarobia",
        fokontany: "miadanandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9396178,
          lng: 47.7452641,
        },
        _id: "64f75284ef8f606073e98582",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "alarobia",
        fokontany: "andrianony",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9413493,
          lng: 47.6873631,
        },
        _id: "64f75284ef8f606073e98583",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "alarobia",
        fokontany: "ambohimiandra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9414382,
          lng: 47.7218497,
        },
        _id: "64f75284ef8f606073e98584",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "alarobia",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9570404,
          lng: 47.6922884,
        },
        _id: "64f75284ef8f606073e98585",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "alarobia",
        fokontany: "ambohitrandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9638281,
          lng: 47.6824383,
        },
        _id: "64f75284ef8f606073e98586",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "alarobia",
        fokontany: "iravoandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9594748,
          lng: 47.7095305,
        },
        _id: "64f75284ef8f606073e98587",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "alarobia",
        fokontany: "antsahamaina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9569353,
          lng: 47.7495784,
        },
        _id: "64f75284ef8f606073e98588",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "alarobia",
        fokontany: "anerindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.96934,
          lng: 47.7021403,
        },
        _id: "64f75284ef8f606073e98589",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "alarobia",
        fokontany: "ambatonikala",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9768689,
          lng: 47.7119941,
        },
        _id: "64f75284ef8f606073e9858a",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "alarobia",
        fokontany: "ambato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9747495,
          lng: 47.6873631,
        },
        _id: "64f75284ef8f606073e9858b",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "alarobia",
        fokontany: "namiana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9949005,
          lng: 47.6996772,
        },
        _id: "64f75284ef8f606073e9858c",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "alarobia",
        fokontany: "ankadimbazimba",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9963858,
          lng: 47.7391014,
        },
        _id: "64f75284ef8f606073e9858d",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "alarobia",
        fokontany: "andidy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0114533,
          lng: 47.7588245,
        },
        _id: "64f75284ef8f606073e9858e",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "alarobia",
        fokontany: "ankorona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0096893,
          lng: 47.872375,
        },
        _id: "64f75284ef8f606073e9858f",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "mantasoa",
        fokontany: "mantasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9771304,
          lng: 47.8155695,
        },
        _id: "64f75284ef8f606073e98590",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "mantasoa",
        fokontany: "andriambazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9846615,
          lng: 47.8254443,
        },
        _id: "64f75284ef8f606073e98591",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "mantasoa",
        fokontany: "ambohidandy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9905601,
          lng: 47.872375,
        },
        _id: "64f75284ef8f606073e98592",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "mantasoa",
        fokontany: "anjozoro est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9899282,
          lng: 47.8143352,
        },
        _id: "64f75284ef8f606073e98593",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "mantasoa",
        fokontany: "ambohitravoko",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0078283,
          lng: 47.8056964,
        },
        _id: "64f75284ef8f606073e98594",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "mantasoa",
        fokontany: "lohomby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0084723,
          lng: 47.7908903,
        },
        _id: "64f75284ef8f606073e98595",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "mantasoa",
        fokontany: "ambohitrinibe II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0096434,
          lng: 47.7822553,
        },
        _id: "64f75284ef8f606073e98596",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "mantasoa",
        fokontany: "ambohidahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0242788,
          lng: 47.8303825,
        },
        _id: "64f75284ef8f606073e98597",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "mantasoa",
        fokontany: "masombahiny",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0403217,
          lng: 47.8279133,
        },
        _id: "64f75284ef8f606073e98598",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "mantasoa",
        fokontany: "miadamanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0458394,
          lng: 47.84766949999999,
        },
        _id: "64f75284ef8f606073e98599",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "mantasoa",
        fokontany: "andrefanivorona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8130127,
          lng: 47.8526097,
        },
        _id: "64f75284ef8f606073e9859a",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ranovao",
        fokontany: "ranovao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8041416,
          lng: 47.8377905,
        },
        _id: "64f75284ef8f606073e9859b",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ranovao",
        fokontany: "manazary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.811981,
          lng: 47.8056964,
        },
        _id: "64f75284ef8f606073e9859c",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ranovao",
        fokontany: "amboniriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8102679,
          lng: 47.9119276,
        },
        _id: "64f75284ef8f606073e9859d",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ranovao",
        fokontany: "antsahambavy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8123294,
          lng: 47.867433,
        },
        _id: "64f75284ef8f606073e9859e",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ranovao",
        fokontany: "antamponala",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8283461,
          lng: 47.7958252,
        },
        _id: "64f75284ef8f606073e9859f",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ranovao",
        fokontany: "ankeramadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8285242,
          lng: 47.80939859999999,
        },
        _id: "64f75284ef8f606073e985a0",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ranovao",
        fokontany: "ambohimiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8249524,
          lng: 47.835321,
        },
        _id: "64f75284ef8f606073e985a1",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ranovao",
        fokontany: "ambohimihaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8355976,
          lng: 47.8026115,
        },
        _id: "64f75284ef8f606073e985a2",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ranovao",
        fokontany: "amboanjobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8477794,
          lng: 47.78842299999999,
        },
        _id: "64f75284ef8f606073e985a3",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ranovao",
        fokontany: "ambohimirary sud",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0419216,
          lng: 47.7908903,
        },
        _id: "64f75284ef8f606073e985a4",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "miadanandriana",
        fokontany: "ambohitsoabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0282246,
          lng: 47.7760883,
        },
        _id: "64f75284ef8f606073e985a5",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "miadanandriana",
        fokontany: "ampanataovana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0252446,
          lng: 47.8081645,
        },
        _id: "64f75284ef8f606073e985a6",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "miadanandriana",
        fokontany: "ambohijanaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0362407,
          lng: 47.774855,
        },
        _id: "64f75284ef8f606073e985a7",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "miadanandriana",
        fokontany: "ambodifahitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0294969,
          lng: 47.74649669999999,
        },
        _id: "64f75284ef8f606073e985a8",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "miadanandriana",
        fokontany: "ambohijanahary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0522231,
          lng: 47.8106327,
        },
        _id: "64f75284ef8f606073e985a9",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "miadanandriana",
        fokontany: "ambohitrombalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.052964,
          lng: 47.8303825,
        },
        _id: "64f75284ef8f606073e985aa",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "miadanandriana",
        fokontany: "ambohinofono",
        __v: 0,
      },
      {
        coords: {
          lat: -19.05371,
          lng: 47.79459139999999,
        },
        _id: "64f75284ef8f606073e985ab",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "miadanandriana",
        fokontany: "merinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0496682,
          lng: 47.7588245,
        },
        _id: "64f75284ef8f606073e985ac",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "miadanandriana",
        fokontany: "ambohitsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0473364,
          lng: 47.7760883,
        },
        _id: "64f75284ef8f606073e985ad",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "miadanandriana",
        fokontany: "ambazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0699625,
          lng: 47.8056964,
        },
        _id: "64f75284ef8f606073e985ae",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "miadanandriana",
        fokontany: "mioridrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0693268,
          lng: 47.8205067,
        },
        _id: "64f75284ef8f606073e985af",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "miadanandriana",
        fokontany: "andandemy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.07794,
          lng: 47.83622,
        },
        _id: "64f75284ef8f606073e985b0",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "miadanandriana",
        fokontany: "ambohipeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.080151,
          lng: 47.8629547,
        },
        _id: "64f75284ef8f606073e985b1",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "miadanandriana",
        fokontany: "ambohimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9086802,
          lng: 47.707067,
        },
        _id: "64f75284ef8f606073e985b2",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "nandihizana",
        fokontany: "carion",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8869145,
          lng: 47.7317071,
        },
        _id: "64f75284ef8f606073e985b3",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "nandihizana",
        fokontany: "ambohitravao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8909681,
          lng: 47.7119941,
        },
        _id: "64f75284ef8f606073e985b4",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "nandihizana",
        fokontany: "maharidaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8945292,
          lng: 47.70337199999999,
        },
        _id: "64f75284ef8f606073e985b5",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "nandihizana",
        fokontany: "ankadiefajoro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9058067,
          lng: 47.7181536,
        },
        _id: "64f75284ef8f606073e985b6",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "nandihizana",
        fokontany: "ankadivory",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8981592,
          lng: 47.7292426,
        },
        _id: "64f75284ef8f606073e985b7",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "nandihizana",
        fokontany: "nanelezana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9114978,
          lng: 47.7514275,
        },
        _id: "64f75284ef8f606073e985b8",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "nandihizana",
        fokontany: "ambohijafy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.918298,
          lng: 47.7415664,
        },
        _id: "64f75284ef8f606073e985b9",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "nandihizana",
        fokontany: "angavokely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9162004,
          lng: 47.7169217,
        },
        _id: "64f75284ef8f606073e985ba",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "nandihizana",
        fokontany: "ambohimiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9202451,
          lng: 47.6972141,
        },
        _id: "64f75284ef8f606073e985bb",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "nandihizana",
        fokontany: "ambohimifangitra atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9325388,
          lng: 47.707067,
        },
        _id: "64f75284ef8f606073e985bc",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "nandihizana",
        fokontany: "farariana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.841761,
          lng: 47.703579,
        },
        _id: "64f75284ef8f606073e985bd",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "anjepy",
        fokontany: "anjepy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8303188,
          lng: 47.7169217,
        },
        _id: "64f75284ef8f606073e985be",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "anjepy",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8340933,
          lng: 47.7391014,
        },
        _id: "64f75284ef8f606073e985bf",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "anjepy",
        fokontany: "antananarivokely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8383245,
          lng: 47.7156897,
        },
        _id: "64f75284ef8f606073e985c0",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "anjepy",
        fokontany: "antanetibe avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8425986,
          lng: 47.7267782,
        },
        _id: "64f75284ef8f606073e985c1",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "anjepy",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8423053,
          lng: 47.7686889,
        },
        _id: "64f75284ef8f606073e985c2",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "anjepy",
        fokontany: "anjozoro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8371203,
          lng: 47.707067,
        },
        _id: "64f75284ef8f606073e985c3",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "anjepy",
        fokontany: "manakavaly",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8541714,
          lng: 47.7169217,
        },
        _id: "64f75284ef8f606073e985c4",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "anjepy",
        fokontany: "ambodivona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8698555,
          lng: 47.7218497,
        },
        _id: "64f75284ef8f606073e985c5",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "anjepy",
        fokontany: "antanetibe atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8637641,
          lng: 47.7514275,
        },
        _id: "64f75284ef8f606073e985c6",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "anjepy",
        fokontany: "mandritsara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8705681,
          lng: 47.7415664,
        },
        _id: "64f75284ef8f606073e985c7",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "anjepy",
        fokontany: "miarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8780831,
          lng: 47.7514275,
        },
        _id: "64f75284ef8f606073e985c8",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "anjepy",
        fokontany: "soamalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.874564,
          lng: 47.6873631,
        },
        _id: "64f75284ef8f606073e985c9",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambanitsena",
        fokontany: "ambanitsena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8459481,
          lng: 47.6873631,
        },
        _id: "64f75284ef8f606073e985ca",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambanitsena",
        fokontany: "ambohimanandray",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8582287,
          lng: 47.6972141,
        },
        _id: "64f75284ef8f606073e985cb",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambanitsena",
        fokontany: "ambohidralambo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8622813,
          lng: 47.677514,
        },
        _id: "64f75284ef8f606073e985cc",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambanitsena",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8699577,
          lng: 47.68366950000001,
        },
        _id: "64f75284ef8f606073e985cd",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambanitsena",
        fokontany: "isoavina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8691402,
          lng: 47.7021403,
        },
        _id: "64f75284ef8f606073e985ce",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambanitsena",
        fokontany: "ambohibehasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8854765,
          lng: 47.6922884,
        },
        _id: "64f75284ef8f606073e985cf",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambanitsena",
        fokontany: "ambatokely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9045579,
          lng: 47.6922884,
        },
        _id: "64f75284ef8f606073e985d0",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambanitsena",
        fokontany: "nandihizankely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9208903,
          lng: 47.6824383,
        },
        _id: "64f75284ef8f606073e985d1",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambanitsena",
        fokontany: "ambohimifangitra ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0875763,
          lng: 47.7662226,
        },
        _id: "64f75284ef8f606073e985d2",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrandriamanitra",
        fokontany: "ambohitrinibe I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0596561,
          lng: 47.7859558,
        },
        _id: "64f75284ef8f606073e985d3",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrandriamanitra",
        fokontany: "ambohijatovo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0664498,
          lng: 47.7760883,
        },
        _id: "64f75284ef8f606073e985d4",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrandriamanitra",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0887246,
          lng: 47.776705,
        },
        _id: "64f75284ef8f606073e985d5",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrandriamanitra",
        fokontany: "ambohitrandriamanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0853696,
          lng: 47.81803800000001,
        },
        _id: "64f75284ef8f606073e985d6",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrandriamanitra",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0755669,
          lng: 47.7489621,
        },
        _id: "64f75284ef8f606073e985d7",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrandriamanitra",
        fokontany: "andraihoja",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0891383,
          lng: 47.7292426,
        },
        _id: "64f75284ef8f606073e985d8",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrandriamanitra",
        fokontany: "ambohiby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0958784,
          lng: 47.7958252,
        },
        _id: "64f75284ef8f606073e985d9",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrandriamanitra",
        fokontany: "ambodiala",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1026706,
          lng: 47.7859558,
        },
        _id: "64f75284ef8f606073e985da",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrandriamanitra",
        fokontany: "ambohitraivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.098596,
          lng: 47.7693054,
        },
        _id: "64f75284ef8f606073e985db",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrandriamanitra",
        fokontany: "angodongodona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1137884,
          lng: 47.7489621,
        },
        _id: "64f75284ef8f606073e985dc",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrandriamanitra",
        fokontany: "ambohimanantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1221026,
          lng: 47.778555,
        },
        _id: "64f75284ef8f606073e985dd",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrandriamanitra",
        fokontany: "ambohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1180829,
          lng: 47.7982928,
        },
        _id: "64f75284ef8f606073e985de",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrandriamanitra",
        fokontany: "ambohinaorina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1273537,
          lng: 47.7292426,
        },
        _id: "64f75284ef8f606073e985df",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrandriamanitra",
        fokontany: "ampataka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1356754,
          lng: 47.7588245,
        },
        _id: "64f75284ef8f606073e985e0",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrandriamanitra",
        fokontany: "amboronosy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7825786,
          lng: 47.7579289,
        },
        _id: "64f75284ef8f606073e985e1",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomena",
        fokontany: "ambatomena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7647522,
          lng: 47.76005749999999,
        },
        _id: "64f75284ef8f606073e985e2",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomena",
        fokontany: "atsimontsena avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7719089,
          lng: 47.76005749999999,
        },
        _id: "64f75284ef8f606073e985e3",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomena",
        fokontany: "atsimotsena atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7698481,
          lng: 47.7181536,
        },
        _id: "64f75284ef8f606073e985e4",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomena",
        fokontany: "mangazaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7703698,
          lng: 47.7415664,
        },
        _id: "64f75284ef8f606073e985e5",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomena",
        fokontany: "ankafotra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7753155,
          lng: 47.7551259,
        },
        _id: "64f75284ef8f606073e985e6",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomena",
        fokontany: "ambohitrakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7765956,
          lng: 47.761907,
        },
        _id: "64f75284ef8f606073e985e7",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomena",
        fokontany: "antsahakely atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.778039,
          lng: 47.7477294,
        },
        _id: "64f75284ef8f606073e985e8",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomena",
        fokontany: "ambohitravoko",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7826217,
          lng: 47.7169217,
        },
        _id: "64f75284ef8f606073e985e9",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomena",
        fokontany: "amboatavo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7746263,
          lng: 47.7354041,
        },
        _id: "64f75284ef8f606073e985ea",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomena",
        fokontany: "ambohimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7810188,
          lng: 47.7434152,
        },
        _id: "64f75284ef8f606073e985eb",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomena",
        fokontany: "antanimietry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7875807,
          lng: 47.7477294,
        },
        _id: "64f75284ef8f606073e985ec",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomena",
        fokontany: "ambodivondroa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.792856,
          lng: 47.7366365,
        },
        _id: "64f75284ef8f606073e985ed",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomena",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8010531,
          lng: 47.7662226,
        },
        _id: "64f75284ef8f606073e985ee",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomena",
        fokontany: "ankazotokana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.805466,
          lng: 47.7391014,
        },
        _id: "64f75284ef8f606073e985ef",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomena",
        fokontany: "ravoandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8109337,
          lng: 47.7588245,
        },
        _id: "64f75284ef8f606073e985f0",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambatomena",
        fokontany: "soavinandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7414098,
          lng: 47.7489621,
        },
        _id: "64f75284ef8f606073e985f1",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohibao sud",
        fokontany: "ambohibao sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7274421,
          lng: 47.7415664,
        },
        _id: "64f75284ef8f606073e985f2",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohibao sud",
        fokontany: "ambohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7403874,
          lng: 47.7366365,
        },
        _id: "64f75284ef8f606073e985f3",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohibao sud",
        fokontany: "ankazotokana nord",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7441353,
          lng: 47.7588245,
        },
        _id: "64f75284ef8f606073e985f4",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohibao sud",
        fokontany: "ambatovikinina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7541026,
          lng: 47.7582081,
        },
        _id: "64f75284ef8f606073e985f5",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohibao sud",
        fokontany: "atsimonanosy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7540168,
          lng: 47.7514275,
        },
        _id: "64f75284ef8f606073e985f6",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohibao sud",
        fokontany: "moramena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7577626,
          lng: 47.7391014,
        },
        _id: "64f75284ef8f606073e985f7",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohibao sud",
        fokontany: "antanimenakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7768563,
          lng: 47.8081645,
        },
        _id: "64f75284ef8f606073e985f8",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitseheno",
        fokontany: "ambohitseheno",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7782124,
          lng: 47.84766949999999,
        },
        _id: "64f75284ef8f606073e985f9",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitseheno",
        fokontany: "bedia",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7687589,
          lng: 47.776705,
        },
        _id: "64f75284ef8f606073e985fa",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitseheno",
        fokontany: "ambohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.76765,
          lng: 47.7662226,
        },
        _id: "64f75284ef8f606073e985fb",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitseheno",
        fokontany: "ifalimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7770237,
          lng: 47.769922,
        },
        _id: "64f75284ef8f606073e985fc",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitseheno",
        fokontany: "ambohimiara sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7766843,
          lng: 47.77732169999999,
        },
        _id: "64f75284ef8f606073e985fd",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitseheno",
        fokontany: "volazato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7795836,
          lng: 47.81803800000001,
        },
        _id: "64f75284ef8f606073e985fe",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitseheno",
        fokontany: "tsarazaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7894672,
          lng: 47.7760883,
        },
        _id: "64f75284ef8f606073e985ff",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitseheno",
        fokontany: "tsifenokova",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8017439,
          lng: 47.7859558,
        },
        _id: "64f75284ef8f606073e98600",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitseheno",
        fokontany: "antsampandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8060101,
          lng: 47.797059,
        },
        _id: "64f75284ef8f606073e98601",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitseheno",
        fokontany: "ambohitantara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.816401,
          lng: 47.778555,
        },
        _id: "64f75284ef8f606073e98602",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitseheno",
        fokontany: "nanjakana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8182827,
          lng: 47.7896566,
        },
        _id: "64f75284ef8f606073e98603",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitseheno",
        fokontany: "soamanandray",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7316919,
          lng: 47.89822119999999,
        },
        _id: "64f75284ef8f606073e98604",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "antsahalalina",
        fokontany: "antsahalalina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7454489,
          lng: 47.867433,
        },
        _id: "64f75284ef8f606073e98605",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "antsahalalina",
        fokontany: "ambatomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7451286,
          lng: 47.84026009999999,
        },
        _id: "64f75284ef8f606073e98606",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "antsahalalina",
        fokontany: "ambohidava sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7519555,
          lng: 47.8303825,
        },
        _id: "64f75284ef8f606073e98607",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "antsahalalina",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7454907,
          lng: 47.7982928,
        },
        _id: "64f75284ef8f606073e98608",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "antsahalalina",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7492439,
          lng: 47.7859558,
        },
        _id: "64f75284ef8f606073e98609",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "antsahalalina",
        fokontany: "ambohidanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7657764,
          lng: 47.7896566,
        },
        _id: "64f75284ef8f606073e9860a",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "antsahalalina",
        fokontany: "ambohijafy avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7563314,
          lng: 47.906982,
        },
        _id: "64f75284ef8f606073e9860b",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "antsahalalina",
        fokontany: "ankadinanahary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7342522,
          lng: 47.7662226,
        },
        _id: "64f75284ef8f606073e9860c",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrony",
        fokontany: "andriampamaky",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7417474,
          lng: 47.7760883,
        },
        _id: "64f75284ef8f606073e9860d",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrony",
        fokontany: "ambohijanaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.75234,
          lng: 47.77041999999999,
        },
        _id: "64f75284ef8f606073e9860e",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrony",
        fokontany: "ambohitrony",
        __v: 0,
      },
      {
        coords: {
          lat: -18.752484,
          lng: 47.7674557,
        },
        _id: "64f75284ef8f606073e9860f",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrony",
        fokontany: "ambohibary avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7594706,
          lng: 47.7711552,
        },
        _id: "64f75284ef8f606073e98610",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrony",
        fokontany: "ampiadianombalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1396488,
          lng: 47.8378184,
        },
        _id: "64f75284ef8f606073e98611",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "merikanjaka",
        fokontany: "merikanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0925992,
          lng: 47.835321,
        },
        _id: "64f75284ef8f606073e98612",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "merikanjaka",
        fokontany: "ambohidraondriana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1117291,
          lng: 47.835321,
        },
        _id: "64f75284ef8f606073e98613",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "merikanjaka",
        fokontany: "ambohijafy est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1109757,
          lng: 47.8155695,
        },
        _id: "64f75284ef8f606073e98614",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "merikanjaka",
        fokontany: "antanimasaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1087614,
          lng: 47.867433,
        },
        _id: "64f75284ef8f606073e98615",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "merikanjaka",
        fokontany: "falefika centre",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1289667,
          lng: 47.8797889,
        },
        _id: "64f75284ef8f606073e98616",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "merikanjaka",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1347001,
          lng: 47.8575503,
        },
        _id: "64f75284ef8f606073e98617",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "merikanjaka",
        fokontany: "ambahinia",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1362029,
          lng: 47.897092,
        },
        _id: "64f75284ef8f606073e98618",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "merikanjaka",
        fokontany: "ambohidraisolo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1399792,
          lng: 47.8081645,
        },
        _id: "64f75284ef8f606073e98619",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "merikanjaka",
        fokontany: "antanitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1627811,
          lng: 47.872375,
        },
        _id: "64f75284ef8f606073e9861a",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "merikanjaka",
        fokontany: "tsiazompaniry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8080032,
          lng: 47.7181536,
        },
        _id: "64f75284ef8f606073e9861b",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "anjoma betoho",
        fokontany: "betoho",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8044336,
          lng: 47.7267782,
        },
        _id: "64f75284ef8f606073e9861c",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "anjoma betoho",
        fokontany: "manakambahiny",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7955627,
          lng: 47.7119941,
        },
        _id: "64f75284ef8f606073e9861d",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "anjoma betoho",
        fokontany: "antatamo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.808503,
          lng: 47.707067,
        },
        _id: "64f75284ef8f606073e9861e",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "anjoma betoho",
        fokontany: "anjomakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8248426,
          lng: 47.6972141,
        },
        _id: "64f75284ef8f606073e9861f",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "anjoma betoho",
        fokontany: "ampiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8206122,
          lng: 47.7206176,
        },
        _id: "64f75284ef8f606073e98620",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "anjoma betoho",
        fokontany: "anerinerina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8201128,
          lng: 47.7317071,
        },
        _id: "64f75284ef8f606073e98621",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "anjoma betoho",
        fokontany: "ambohitsararay",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7,
          lng: 47.783333,
        },
        _id: "64f75284ef8f606073e98622",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "ankazondandy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6367495,
          lng: 47.8106327,
        },
        _id: "64f75284ef8f606073e98623",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "anosivola",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6348248,
          lng: 47.9168738,
        },
        _id: "64f75284ef8f606073e98624",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "morarano beorana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6295622,
          lng: 47.8279133,
        },
        _id: "64f75284ef8f606073e98625",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "analatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.65,
          lng: 47.833333,
        },
        _id: "64f75284ef8f606073e98626",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "ambohipihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6500583,
          lng: 47.7982928,
        },
        _id: "64f75284ef8f606073e98627",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "anjohy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6541729,
          lng: 47.778555,
        },
        _id: "64f75284ef8f606073e98628",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "fandana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6800752,
          lng: 47.7686889,
        },
        _id: "64f75284ef8f606073e98629",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "ambohibao nord",
        __v: 0,
      },
      {
        coords: {
          lat: -18.675619,
          lng: 47.7958252,
        },
        _id: "64f75284ef8f606073e9862a",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "mafaitrorana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6704517,
          lng: 47.8377905,
        },
        _id: "64f75284ef8f606073e9862b",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "antaninandro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6796919,
          lng: 47.8106327,
        },
        _id: "64f75284ef8f606073e9862c",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "andranotsimihozo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6865195,
          lng: 47.8007606,
        },
        _id: "64f75284ef8f606073e9862d",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "anosy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6933706,
          lng: 47.7563588,
        },
        _id: "64f75284ef8f606073e9862e",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "andriakely nord",
        __v: 0,
      },
      {
        coords: {
          lat: -18.691253,
          lng: 47.835321,
        },
        _id: "64f75284ef8f606073e9862f",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "moratelo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7,
          lng: 47.783333,
        },
        _id: "64f75284ef8f606073e98630",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "ambohitsaratany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7021936,
          lng: 47.8056964,
        },
        _id: "64f75284ef8f606073e98631",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "antsahatanteraka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7076588,
          lng: 47.7908903,
        },
        _id: "64f75284ef8f606073e98632",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "fierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7049578,
          lng: 47.74649669999999,
        },
        _id: "64f75284ef8f606073e98633",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "ambohitrakatso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7088673,
          lng: 47.76498950000001,
        },
        _id: "64f75284ef8f606073e98634",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "andozoka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7086401,
          lng: 47.8377905,
        },
        _id: "64f75284ef8f606073e98635",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "mandena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7083501,
          lng: 47.7760883,
        },
        _id: "64f75284ef8f606073e98636",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "ankomby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7236792,
          lng: 47.78842299999999,
        },
        _id: "64f75284ef8f606073e98637",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7185794,
          lng: 47.7612905,
        },
        _id: "64f75284ef8f606073e98638",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "ankafotra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7192303,
          lng: 47.8155695,
        },
        _id: "64f75284ef8f606073e98639",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "ambohitrandraina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7236256,
          lng: 47.8575503,
        },
        _id: "64f75284ef8f606073e9863a",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "andranolava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7335499,
          lng: 47.8155695,
        },
        _id: "64f75284ef8f606073e9863b",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ankazondandy",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.701031,
          lng: 47.880089,
        },
        _id: "64f75284ef8f606073e9863c",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "soavinandriana",
        fokontany: "soavinandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6607775,
          lng: 47.906982,
        },
        _id: "64f75284ef8f606073e9863d",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "soavinandriana",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6612007,
          lng: 47.8649622,
        },
        _id: "64f75284ef8f606073e9863e",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "soavinandriana",
        fokontany: "fierenana est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6771738,
          lng: 47.897092,
        },
        _id: "64f75284ef8f606073e9863f",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "soavinandriana",
        fokontany: "ambohidava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6785898,
          lng: 47.867433,
        },
        _id: "64f75284ef8f606073e98640",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "soavinandriana",
        fokontany: "rangaina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6876108,
          lng: 47.8785532,
        },
        _id: "64f75284ef8f606073e98641",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "soavinandriana",
        fokontany: "tsarasaotra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6988958,
          lng: 47.8587856,
        },
        _id: "64f75284ef8f606073e98642",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "soavinandriana",
        fokontany: "amboahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7044202,
          lng: 47.9267674,
        },
        _id: "64f75284ef8f606073e98643",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "soavinandriana",
        fokontany: "andasinimiaramila",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7283625,
          lng: 47.8921477,
        },
        _id: "64f75284ef8f606073e98644",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "soavinandriana",
        fokontany: "ambohimanatrika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7,
          lng: 47.683333,
        },
        _id: "64f75284ef8f606073e98645",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "ambohitrolomahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6733187,
          lng: 47.640596,
        },
        _id: "64f75284ef8f606073e98646",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "mandrosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.669235,
          lng: 47.6602824,
        },
        _id: "64f75284ef8f606073e98647",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "andranomody",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6729734,
          lng: 47.6824383,
        },
        _id: "64f75284ef8f606073e98648",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "tsitiabedy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6828362,
          lng: 47.7095305,
        },
        _id: "64f75284ef8f606073e98649",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "ambatomiranty",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6859075,
          lng: 47.677514,
        },
        _id: "64f75284ef8f606073e9864a",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "andrainarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7032427,
          lng: 47.6110802,
        },
        _id: "64f75284ef8f606073e9864b",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "ampahitrizina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6913457,
          lng: 47.6282957,
        },
        _id: "64f75284ef8f606073e9864c",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "ambohitrakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6933933,
          lng: 47.6528991,
        },
        _id: "64f75284ef8f606073e9864d",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "ampohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7,
          lng: 47.683333,
        },
        _id: "64f75284ef8f606073e9864e",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "ambohitrerana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.696117,
          lng: 47.6972141,
        },
        _id: "64f75284ef8f606073e9864f",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "analakely avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7035964,
          lng: 47.63813570000001,
        },
        _id: "64f75284ef8f606073e98650",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "avaratrambolo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6981546,
          lng: 47.7218497,
        },
        _id: "64f75284ef8f606073e98651",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "ankatso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7138121,
          lng: 47.6578212,
        },
        _id: "64f75284ef8f606073e98652",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "maroary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7151825,
          lng: 47.6972141,
        },
        _id: "64f75284ef8f606073e98653",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "ambohibemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7332065,
          lng: 47.6504382,
        },
        _id: "64f75284ef8f606073e98654",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "ankorombe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7253931,
          lng: 47.6824383,
        },
        _id: "64f75284ef8f606073e98655",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "analamanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7168842,
          lng: 47.7292426,
        },
        _id: "64f75284ef8f606073e98656",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "ambohimanarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7226742,
          lng: 47.707067,
        },
        _id: "64f75284ef8f606073e98657",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "ambohitraza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7390168,
          lng: 47.6972141,
        },
        _id: "64f75284ef8f606073e98658",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "analakely atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7427693,
          lng: 47.7193856,
        },
        _id: "64f75284ef8f606073e98659",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "antokala",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7591143,
          lng: 47.7095305,
        },
        _id: "64f75284ef8f606073e9865a",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ambohitrolomahitsy",
        fokontany: "ambatomitokona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9105687,
          lng: 47.8081645,
        },
        _id: "64f75284ef8f606073e9865b",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ampaneva",
        fokontany: "ampaneva",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7413927,
          lng: 47.6799761,
        },
        _id: "64f75284ef8f606073e9865c",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ampaneva",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7575601,
          lng: 47.673821,
        },
        _id: "64f75284ef8f606073e9865d",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ampaneva",
        fokontany: "antanetilava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7728916,
          lng: 47.6861319,
        },
        _id: "64f75284ef8f606073e9865e",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ampaneva",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7805589,
          lng: 47.6922884,
        },
        _id: "64f75284ef8f606073e9865f",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "ampaneva",
        fokontany: "ambodivona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.617781,
          lng: 47.709942,
        },
        _id: "64f75284ef8f606073e98660",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "sadabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.501789,
          lng: 47.7095305,
        },
        _id: "64f75284ef8f606073e98661",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "mangatany avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4863134,
          lng: 47.6356755,
        },
        _id: "64f75284ef8f606073e98662",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "antanetilava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5243804,
          lng: 47.6356755,
        },
        _id: "64f75284ef8f606073e98663",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "andranovelona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5323526,
          lng: 47.7341718,
        },
        _id: "64f75284ef8f606073e98664",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "andranomalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5596502,
          lng: 47.6947512,
        },
        _id: "64f75284ef8f606073e98665",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "soavina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.569762,
          lng: 47.7489621,
        },
        _id: "64f75284ef8f606073e98666",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "antanetibe nord",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5780893,
          lng: 47.640596,
        },
        _id: "64f75284ef8f606073e98667",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "andriakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5910335,
          lng: 47.7526603,
        },
        _id: "64f75284ef8f606073e98668",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "ambohitrandriamamba",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5875281,
          lng: 47.7095305,
        },
        _id: "64f75284ef8f606073e98669",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "ambatofisaorana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5973004,
          lng: 47.7711552,
        },
        _id: "64f75284ef8f606073e9866a",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "talatakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6,
          lng: 47.783333,
        },
        _id: "64f75284ef8f606073e9866b",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "anjozorofady",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6051845,
          lng: 47.7391014,
        },
        _id: "64f75284ef8f606073e9866c",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "ampanazava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6052683,
          lng: 47.67012829999999,
        },
        _id: "64f75284ef8f606073e9866d",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "ankerana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.621605,
          lng: 47.6602824,
        },
        _id: "64f75284ef8f606073e9866e",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "antanetibe VI",
        __v: 0,
      },
      {
        coords: {
          lat: -18.630289,
          lng: 47.736752,
        },
        _id: "64f75284ef8f606073e9866f",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6259937,
          lng: 47.7021403,
        },
        _id: "64f75284ef8f606073e98670",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "ambanilakandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6255576,
          lng: 47.778555,
        },
        _id: "64f75284ef8f606073e98671",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "ambatofotsy atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6487459,
          lng: 47.7588245,
        },
        _id: "64f75284ef8f606073e98672",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "amboarakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6460893,
          lng: 47.6799761,
        },
        _id: "64f75284ef8f606073e98673",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "amboanemba",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6538933,
          lng: 47.7169217,
        },
        _id: "64f75284ef8f606073e98674",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "ambodivonkely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6606833,
          lng: 47.7415664,
        },
        _id: "64f75284ef8f606073e98675",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "mangatany atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6613976,
          lng: 47.6922884,
        },
        _id: "64f75284ef8f606073e98676",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "antamponala",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6787409,
          lng: 47.7292426,
        },
        _id: "64f75284ef8f606073e98677",
        province: "antananarivo",
        region: "analamanga",
        district: "manjakandriana",
        commune: "sadabe",
        fokontany: "ambohijanaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4001194,
          lng: 47.8699039,
        },
        _id: "64f75284ef8f606073e98678",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "anjozorobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3514844,
          lng: 47.8575503,
        },
        _id: "64f75284ef8f606073e98679",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "befieranana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3308924,
          lng: 47.88720379999999,
        },
        _id: "64f75284ef8f606073e9867a",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "masakalina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.365524,
          lng: 47.7686889,
        },
        _id: "64f75284ef8f606073e9867b",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "beronono",
        __v: 0,
      },
      {
        coords: {
          lat: -18.369382,
          lng: 47.81803800000001,
        },
        _id: "64f75284ef8f606073e9867c",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "ambohinierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3846813,
          lng: 47.8921477,
        },
        _id: "64f75284ef8f606073e9867d",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "miandrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.366667,
          lng: 47.833333,
        },
        _id: "64f75284ef8f606073e9867e",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "kamanja",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3818933,
          lng: 47.7588245,
        },
        _id: "64f75284ef8f606073e9867f",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "antsahabe ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3827617,
          lng: 47.867433,
        },
        _id: "64f75284ef8f606073e98680",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4150958,
          lng: 47.7933577,
        },
        _id: "64f75284ef8f606073e98681",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "ambohimamory",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4056809,
          lng: 47.9168738,
        },
        _id: "64f75284ef8f606073e98682",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "antsahabe atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4060123,
          lng: 47.84766949999999,
        },
        _id: "64f75284ef8f606073e98683",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "ambohibeloma",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4292525,
          lng: 47.8279133,
        },
        _id: "64f75284ef8f606073e98684",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "ambohibazoina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4396119,
          lng: 47.8748462,
        },
        _id: "64f75284ef8f606073e98685",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "antsorindrana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4373017,
          lng: 47.8575503,
        },
        _id: "64f75284ef8f606073e98686",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "ankadivory",
        __v: 0,
      },
      {
        coords: {
          lat: -18.455169,
          lng: 47.81803800000001,
        },
        _id: "64f75284ef8f606073e98687",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "ambohitsaratany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.453692,
          lng: 47.84766949999999,
        },
        _id: "64f75284ef8f606073e98688",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "ampilanonana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.472767,
          lng: 47.84766949999999,
        },
        _id: "64f75284ef8f606073e98689",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "ambodifiakarana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4610042,
          lng: 47.8921477,
        },
        _id: "64f75284ef8f606073e9868a",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "andranokotona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4526479,
          lng: 47.931715,
        },
        _id: "64f75284ef8f606073e9868b",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "amboasarianala",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4668889,
          lng: 47.8699039,
        },
        _id: "64f75284ef8f606073e9868c",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "anosivola",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4816609,
          lng: 47.9242939,
        },
        _id: "64f75284ef8f606073e9868d",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "andasiniboata",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4984374,
          lng: 47.906982,
        },
        _id: "64f75284ef8f606073e9868e",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "fiarenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4969532,
          lng: 47.93666289999999,
        },
        _id: "64f75284ef8f606073e9868f",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "anjozorobe",
        fokontany: "andranomay",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5708689,
          lng: 47.8662909,
        },
        _id: "64f75284ef8f606073e98690",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "mangamila",
        fokontany: "mangamila",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4891537,
          lng: 47.8377905,
        },
        _id: "64f75284ef8f606073e98691",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "mangamila",
        fokontany: "antoby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4783918,
          lng: 47.7982928,
        },
        _id: "64f75284ef8f606073e98692",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "mangamila",
        fokontany: "tafaina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4893791,
          lng: 47.7686889,
        },
        _id: "64f75284ef8f606073e98693",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "mangamila",
        fokontany: "soavinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.51087,
          lng: 47.7514275,
        },
        _id: "64f75284ef8f606073e98694",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "mangamila",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5079765,
          lng: 47.8106327,
        },
        _id: "64f75284ef8f606073e98695",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "mangamila",
        fokontany: "antanatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5128824,
          lng: 47.872375,
        },
        _id: "64f75284ef8f606073e98696",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "mangamila",
        fokontany: "anosy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.527504,
          lng: 47.7686889,
        },
        _id: "64f75284ef8f606073e98697",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "mangamila",
        fokontany: "analavinidy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5138319,
          lng: 47.78842299999999,
        },
        _id: "64f75284ef8f606073e98698",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "mangamila",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5246132,
          lng: 47.8279133,
        },
        _id: "64f75284ef8f606073e98699",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "mangamila",
        fokontany: "amboarakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5382955,
          lng: 47.8081645,
        },
        _id: "64f75284ef8f606073e9869a",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "mangamila",
        fokontany: "antatamokely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5449362,
          lng: 47.867433,
        },
        _id: "64f75284ef8f606073e9869b",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "mangamila",
        fokontany: "analabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5615055,
          lng: 47.78842299999999,
        },
        _id: "64f75284ef8f606073e9869c",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "mangamila",
        fokontany: "ambohitsaratany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5870651,
          lng: 47.9168738,
        },
        _id: "64f75284ef8f606073e9869d",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "mangamila",
        fokontany: "ankaifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5694206,
          lng: 47.88720379999999,
        },
        _id: "64f75284ef8f606073e9869e",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "mangamila",
        fokontany: "ambohitsimiray",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5995025,
          lng: 47.8575503,
        },
        _id: "64f75284ef8f606073e9869f",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "mangamila",
        fokontany: "ampotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5921073,
          lng: 47.813101,
        },
        _id: "64f75284ef8f606073e986a0",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "mangamila",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6007647,
          lng: 47.897092,
        },
        _id: "64f75284ef8f606073e986a1",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "mangamila",
        fokontany: "isaonjo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6284206,
          lng: 47.8847321,
        },
        _id: "64f75284ef8f606073e986a2",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "mangamila",
        fokontany: "antsahafina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3503378,
          lng: 47.9416114,
        },
        _id: "64f75284ef8f606073e986a3",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambongamarina",
        fokontany: "ambongamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2874275,
          lng: 47.867433,
        },
        _id: "64f75284ef8f606073e986a4",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambongamarina",
        fokontany: "amborompotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2954184,
          lng: 47.897092,
        },
        _id: "64f75284ef8f606073e986a5",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambongamarina",
        fokontany: "andreba",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3095426,
          lng: 47.8081645,
        },
        _id: "64f75284ef8f606073e986a6",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambongamarina",
        fokontany: "bongatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3028739,
          lng: 47.8451996,
        },
        _id: "64f75284ef8f606073e986a7",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambongamarina",
        fokontany: "ambonindriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3,
          lng: 47.966667,
        },
        _id: "64f75284ef8f606073e986a8",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambongamarina",
        fokontany: "antanifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3200245,
          lng: 47.8205067,
        },
        _id: "64f75284ef8f606073e986a9",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambongamarina",
        fokontany: "ambohimijery",
        __v: 0,
      },
      {
        coords: {
          lat: -18.307232,
          lng: 47.91440069999999,
        },
        _id: "64f75284ef8f606073e986aa",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambongamarina",
        fokontany: "amboanonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3266914,
          lng: 47.906982,
        },
        _id: "64f75284ef8f606073e986ab",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambongamarina",
        fokontany: "ambongabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3381221,
          lng: 47.8081645,
        },
        _id: "64f75284ef8f606073e986ac",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambongamarina",
        fokontany: "amparihy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3572106,
          lng: 47.931715,
        },
        _id: "64f75284ef8f606073e986ad",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambongamarina",
        fokontany: "ampamoha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3541307,
          lng: 47.9911208,
        },
        _id: "64f75284ef8f606073e986ae",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambongamarina",
        fokontany: "anosimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4114249,
          lng: 47.9911208,
        },
        _id: "64f75284ef8f606073e986af",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambongamarina",
        fokontany: "ambohimiaramanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5587801,
          lng: 47.9094547,
        },
        _id: "64f75284ef8f606073e986b0",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "tsarasaotra",
        fokontany: "andanona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5477634,
          lng: 47.9391371,
        },
        _id: "64f75284ef8f606073e986b1",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "tsarasaotra",
        fokontany: "morarano sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5726248,
          lng: 47.95150959999999,
        },
        _id: "64f75284ef8f606073e986b2",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "tsarasaotra",
        fokontany: "tsarasaotra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5641715,
          lng: 47.9292411,
        },
        _id: "64f75284ef8f606073e986b3",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "tsarasaotra",
        fokontany: "antetezana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5596414,
          lng: 47.95645940000001,
        },
        _id: "64f75284ef8f606073e986b4",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "tsarasaotra",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5770352,
          lng: 47.9589345,
        },
        _id: "64f75284ef8f606073e986b5",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "tsarasaotra",
        fokontany: "ampatsikahitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6108439,
          lng: 47.95150959999999,
        },
        _id: "64f75284ef8f606073e986b6",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "tsarasaotra",
        fokontany: "vodivato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2348911,
          lng: 47.778555,
        },
        _id: "64f75284ef8f606073e986b7",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohimanarina marovazaha",
        fokontany: "andranonahoatra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1724786,
          lng: 47.7588245,
        },
        _id: "64f75284ef8f606073e986b8",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohimanarina marovazaha",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.183333,
          lng: 47.766667,
        },
        _id: "64f75284ef8f606073e986b9",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohimanarina marovazaha",
        fokontany: "vohitrandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1926126,
          lng: 47.7982928,
        },
        _id: "64f75284ef8f606073e986ba",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohimanarina marovazaha",
        fokontany: "antsevakely nord",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2107093,
          lng: 47.7859558,
        },
        _id: "64f75284ef8f606073e986bb",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohimanarina marovazaha",
        fokontany: "ambohitsaratany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.236011,
          lng: 47.81803800000001,
        },
        _id: "64f75284ef8f606073e986bc",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohimanarina marovazaha",
        fokontany: "ambatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2535159,
          lng: 47.84766949999999,
        },
        _id: "64f75284ef8f606073e986bd",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohimanarina marovazaha",
        fokontany: "ambohitratsery",
        __v: 0,
      },
      {
        coords: {
          lat: -18.266667,
          lng: 47.8,
        },
        _id: "64f75284ef8f606073e986be",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohimanarina marovazaha",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2683664,
          lng: 47.867433,
        },
        _id: "64f75284ef8f606073e986bf",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohimanarina marovazaha",
        fokontany: "betsifasika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2771549,
          lng: 47.8205067,
        },
        _id: "64f75284ef8f606073e986c0",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohimanarina marovazaha",
        fokontany: "antsevakely atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2901106,
          lng: 47.8155695,
        },
        _id: "64f75284ef8f606073e986c1",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohimanarina marovazaha",
        fokontany: "fieferana atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.21229,
          lng: 47.87833,
        },
        _id: "64f75284ef8f606073e986c2",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "betatao",
        fokontany: "betatao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0679166,
          lng: 47.9911208,
        },
        _id: "64f75284ef8f606073e986c3",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "betatao",
        fokontany: "miakadaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1198483,
          lng: 47.9713115,
        },
        _id: "64f75284ef8f606073e986c4",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "betatao",
        fokontany: "manankasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.133333,
          lng: 47.84999999999999,
        },
        _id: "64f75284ef8f606073e986c5",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "betatao",
        fokontany: "andohala",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1421274,
          lng: 47.9119276,
        },
        _id: "64f75284ef8f606073e986c6",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "betatao",
        fokontany: "mahalasitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2083207,
          lng: 47.9812152,
        },
        _id: "64f75284ef8f606073e986c7",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "betatao",
        fokontany: "ambohimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1836861,
          lng: 47.906982,
        },
        _id: "64f75284ef8f606073e986c8",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "betatao",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2427476,
          lng: 47.931715,
        },
        _id: "64f75284ef8f606073e986c9",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "betatao",
        fokontany: "fieferana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5290309,
          lng: 47.931715,
        },
        _id: "64f75284ef8f606073e986ca",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "alakamisy",
        fokontany: "alakamisy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.514566,
          lng: 47.9663604,
        },
        _id: "64f75284ef8f606073e986cb",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "alakamisy",
        fokontany: "ambohibary avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5397945,
          lng: 47.9713115,
        },
        _id: "64f75284ef8f606073e986cc",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "alakamisy",
        fokontany: "analanakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5297681,
          lng: 47.9168738,
        },
        _id: "64f75284ef8f606073e986cd",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "alakamisy",
        fokontany: "ankazoandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5390696,
          lng: 47.9379,
        },
        _id: "64f75284ef8f606073e986ce",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "alakamisy",
        fokontany: "andranomadio",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4004642,
          lng: 47.7046036,
        },
        _id: "64f75284ef8f606073e986cf",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "analaroa",
        fokontany: "analaroa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.366667,
          lng: 47.7,
        },
        _id: "64f75284ef8f606073e986d0",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "analaroa",
        fokontany: "ambohitsimenaloha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3982596,
          lng: 47.7489621,
        },
        _id: "64f75284ef8f606073e986d1",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "analaroa",
        fokontany: "antananarivokely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4217033,
          lng: 47.7563588,
        },
        _id: "64f75284ef8f606073e986d2",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "analaroa",
        fokontany: "mahazina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4554106,
          lng: 47.7489621,
        },
        _id: "64f75284ef8f606073e986d3",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "analaroa",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4365787,
          lng: 47.6799761,
        },
        _id: "64f75284ef8f606073e986d4",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "analaroa",
        fokontany: "tsivakiana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4475368,
          lng: 47.6504382,
        },
        _id: "64f75284ef8f606073e986d5",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "analaroa",
        fokontany: "antoby avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4644159,
          lng: 47.6947512,
        },
        _id: "64f75284ef8f606073e986d6",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "analaroa",
        fokontany: "ambohimanganjafy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4062608,
          lng: 47.58649639999999,
        },
        _id: "64f75284ef8f606073e986d7",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "antanetibe",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3458189,
          lng: 47.5914122,
        },
        _id: "64f75284ef8f606073e986d8",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "antanetibe",
        fokontany: "ambatoasana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3472704,
          lng: 47.5619241,
        },
        _id: "64f75284ef8f606073e986d9",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "antanetibe",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.35,
          lng: 47.48333299999999,
        },
        _id: "64f75284ef8f606073e986da",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "antanetibe",
        fokontany: "ambohijavona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3608996,
          lng: 47.5422745,
        },
        _id: "64f75284ef8f606073e986db",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "antanetibe",
        fokontany: "andranovelona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3883267,
          lng: 47.5643808,
        },
        _id: "64f75284ef8f606073e986dc",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "antanetibe",
        fokontany: "ambohibarikely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3761352,
          lng: 47.5545546,
        },
        _id: "64f75284ef8f606073e986dd",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "antanetibe",
        fokontany: "merinavaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3906568,
          lng: 47.581581,
        },
        _id: "64f75284ef8f606073e986de",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "antanetibe",
        fokontany: "andranomadio",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4218676,
          lng: 47.5914122,
        },
        _id: "64f75284ef8f606073e986df",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "antanetibe",
        fokontany: "ambohimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.451103,
          lng: 47.5766661,
        },
        _id: "64f75284ef8f606073e986e0",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "antanetibe",
        fokontany: "ambohimitsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3823931,
          lng: 47.62091700000001,
        },
        _id: "64f75284ef8f606073e986e1",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "belanitra",
        fokontany: "belanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3711782,
          lng: 47.6233764,
        },
        _id: "64f75284ef8f606073e986e2",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "belanitra",
        fokontany: "mahamaintiomby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.31311,
          lng: 47.624229,
        },
        _id: "64f75284ef8f606073e986e3",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambatomanoina",
        fokontany: "ambatomanoina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.25,
          lng: 47.616667,
        },
        _id: "64f75284ef8f606073e986e4",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambatomanoina",
        fokontany: "andakana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2603001,
          lng: 47.5914122,
        },
        _id: "64f75284ef8f606073e986e5",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambatomanoina",
        fokontany: "ambatonosy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2694957,
          lng: 47.6602824,
        },
        _id: "64f75284ef8f606073e986e6",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambatomanoina",
        fokontany: "amparihibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2856169,
          lng: 47.6233764,
        },
        _id: "64f75284ef8f606073e986e7",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambatomanoina",
        fokontany: "ampananganana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2914779,
          lng: 47.6012453,
        },
        _id: "64f75284ef8f606073e986e8",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambatomanoina",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2990382,
          lng: 47.5766661,
        },
        _id: "64f75284ef8f606073e986e9",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambatomanoina",
        fokontany: "anosikely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3,
          lng: 47.683333,
        },
        _id: "64f75284ef8f606073e986ea",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambatomanoina",
        fokontany: "ambohidrakitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3377704,
          lng: 47.6578212,
        },
        _id: "64f75284ef8f606073e986eb",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambatomanoina",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.349723,
          lng: 47.640596,
        },
        _id: "64f75284ef8f606073e986ec",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambatomanoina",
        fokontany: "amboniakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3775252,
          lng: 47.6553601,
        },
        _id: "64f75284ef8f606073e986ed",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambatomanoina",
        fokontany: "lohavondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0807244,
          lng: 47.8291479,
        },
        _id: "64f75284ef8f606073e986ee",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "amparatanjona",
        fokontany: "amparatanjona ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9531584,
          lng: 47.8279133,
        },
        _id: "64f75284ef8f606073e986ef",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "amparatanjona",
        fokontany: "andranomisa ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9602052,
          lng: 47.872375,
        },
        _id: "64f75284ef8f606073e986f0",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "amparatanjona",
        fokontany: "antetezanambo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.049089,
          lng: 47.907829,
        },
        _id: "64f75284ef8f606073e986f1",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "amparatanjona",
        fokontany: "besorindrana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0395702,
          lng: 47.813101,
        },
        _id: "64f75284ef8f606073e986f2",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "amparatanjona",
        fokontany: "ambohimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0630677,
          lng: 47.84766949999999,
        },
        _id: "64f75284ef8f606073e986f3",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "amparatanjona",
        fokontany: "ambato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0942624,
          lng: 47.8575503,
        },
        _id: "64f75284ef8f606073e986f4",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "amparatanjona",
        fokontany: "amparatanjona ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.116667,
          lng: 47.766667,
        },
        _id: "64f75284ef8f606073e986f5",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "amboasary nord",
        fokontany: "amboasary nord",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0480463,
          lng: 47.7736217,
        },
        _id: "64f75284ef8f606073e986f6",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "amboasary nord",
        fokontany: "ankodondona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0808002,
          lng: 47.7538931,
        },
        _id: "64f75284ef8f606073e986f7",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "amboasary nord",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.059656,
          lng: 47.675052,
        },
        _id: "64f75284ef8f606073e986f8",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "amboasary nord",
        fokontany: "antevamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1291295,
          lng: 47.7391014,
        },
        _id: "64f75284ef8f606073e986f9",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "amboasary nord",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.154541,
          lng: 47.7982928,
        },
        _id: "64f75284ef8f606073e986fa",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "amboasary nord",
        fokontany: "ampasika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1587555,
          lng: 47.778555,
        },
        _id: "64f75284ef8f606073e986fb",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "amboasary nord",
        fokontany: "ambohitsambo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4105299,
          lng: 47.4980899,
        },
        _id: "64f75284ef8f606073e986fc",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "androvakely",
        fokontany: "androvakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3813285,
          lng: 47.512814,
        },
        _id: "64f75284ef8f606073e986fd",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "androvakely",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.361072,
          lng: 47.4735591,
        },
        _id: "64f75284ef8f606073e986fe",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "androvakely",
        fokontany: "beanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3936549,
          lng: 47.4539427,
        },
        _id: "64f75284ef8f606073e986ff",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "androvakely",
        fokontany: "andranonahoatra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4187346,
          lng: 47.4588461,
        },
        _id: "64f75284ef8f606073e98700",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "androvakely",
        fokontany: "bemandotra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4220177,
          lng: 47.5226323,
        },
        _id: "64f75284ef8f606073e98701",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "androvakely",
        fokontany: "miadamanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4437175,
          lng: 47.5324525,
        },
        _id: "64f75284ef8f606073e98702",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "androvakely",
        fokontany: "antovontany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.481251,
          lng: 47.555161,
        },
        _id: "64f75284ef8f606073e98703",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "androvakely",
        fokontany: "ambatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2208047,
          lng: 47.62091700000001,
        },
        _id: "64f75284ef8f606073e98704",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohibary vohilena",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0142457,
          lng: 47.5766661,
        },
        _id: "64f75284ef8f606073e98705",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohibary vohilena",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0574857,
          lng: 47.59632850000001,
        },
        _id: "64f75284ef8f606073e98706",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohibary vohilena",
        fokontany: "ambohimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0363116,
          lng: 47.5177229,
        },
        _id: "64f75284ef8f606073e98707",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohibary vohilena",
        fokontany: "miarikofeno",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1128994,
          lng: 47.625836,
        },
        _id: "64f75284ef8f606073e98708",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohibary vohilena",
        fokontany: "andranomiantra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1132315,
          lng: 47.6799761,
        },
        _id: "64f75284ef8f606073e98709",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohibary vohilena",
        fokontany: "vohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.14509,
          lng: 47.661919,
        },
        _id: "64f75284ef8f606073e9870a",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohibary vohilena",
        fokontany: "ambatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1599786,
          lng: 47.6947512,
        },
        _id: "64f75284ef8f606073e9870b",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohibary vohilena",
        fokontany: "ambohidrivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1926865,
          lng: 47.675052,
        },
        _id: "64f75284ef8f606073e9870c",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohibary vohilena",
        fokontany: "andavabary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1949658,
          lng: 47.6307555,
        },
        _id: "64f75284ef8f606073e9870d",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohibary vohilena",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2166332,
          lng: 47.640596,
        },
        _id: "64f75284ef8f606073e9870e",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohibary vohilena",
        fokontany: "ambatokely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2086459,
          lng: 47.7341718,
        },
        _id: "64f75284ef8f606073e9870f",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohibary vohilena",
        fokontany: "ankazongoaika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2413718,
          lng: 47.7144578,
        },
        _id: "64f75284ef8f606073e98710",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohibary vohilena",
        fokontany: "andrebakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2371395,
          lng: 47.6110802,
        },
        _id: "64f75284ef8f606073e98711",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohibary vohilena",
        fokontany: "ambohitrampovoany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2383074,
          lng: 47.6504382,
        },
        _id: "64f75284ef8f606073e98712",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohibary vohilena",
        fokontany: "andranomalama",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2497287,
          lng: 47.675052,
        },
        _id: "64f75284ef8f606073e98713",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohibary vohilena",
        fokontany: "miadana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3037975,
          lng: 47.7341718,
        },
        _id: "64f75284ef8f606073e98714",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohimirary",
        fokontany: "ambohimirary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2607852,
          lng: 47.7686889,
        },
        _id: "64f75284ef8f606073e98715",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohimirary",
        fokontany: "beampombo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.294681,
          lng: 47.78842299999999,
        },
        _id: "64f75284ef8f606073e98716",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohimirary",
        fokontany: "antanetibe antsakay",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2901016,
          lng: 47.7538931,
        },
        _id: "64f75284ef8f606073e98717",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohimirary",
        fokontany: "manakana vohitraivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3072202,
          lng: 47.7292426,
        },
        _id: "64f75284ef8f606073e98718",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohimirary",
        fokontany: "maherirano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.334279,
          lng: 47.7588245,
        },
        _id: "64f75284ef8f606073e98719",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "ambohimirary",
        fokontany: "amboniakondro est",
        __v: 0,
      },
      {
        coords: {
          lat: -17.93314,
          lng: 47.660931,
        },
        _id: "64f75284ef8f606073e9871a",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "marotsipoy",
        fokontany: "marotsipoy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.77537,
          lng: 47.819592,
        },
        _id: "64f75284ef8f606073e9871b",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "marotsipoy",
        fokontany: "ambavasambo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8788774,
          lng: 47.8526097,
        },
        _id: "64f75284ef8f606073e9871c",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "marotsipoy",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8681467,
          lng: 47.7046036,
        },
        _id: "64f75284ef8f606073e9871d",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "marotsipoy",
        fokontany: "ambohitrimarofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.98588,
          lng: 47.78334,
        },
        _id: "64f75284ef8f606073e9871e",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "marotsipoy",
        fokontany: "ankokaina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0079447,
          lng: 47.6947512,
        },
        _id: "64f75284ef8f606073e9871f",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "marotsipoy",
        fokontany: "mangarivotra baiboka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2328381,
          lng: 47.5079055,
        },
        _id: "64f75284ef8f606073e98720",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "beronono",
        fokontany: "beronono",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1417537,
          lng: 47.4956363,
        },
        _id: "64f75284ef8f606073e98721",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "beronono",
        fokontany: "miadanatsaha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1797419,
          lng: 47.557011,
        },
        _id: "64f75284ef8f606073e98722",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "beronono",
        fokontany: "ambodimadiro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1739882,
          lng: 47.48337,
        },
        _id: "64f75284ef8f606073e98723",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "beronono",
        fokontany: "ambohitsitaitaika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.215335,
          lng: 47.4784643,
        },
        _id: "64f75284ef8f606073e98724",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "beronono",
        fokontany: "ambohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2313619,
          lng: 47.5373633,
        },
        _id: "64f75284ef8f606073e98725",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "beronono",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.283333,
          lng: 47.48333299999999,
        },
        _id: "64f75284ef8f606073e98726",
        province: "antananarivo",
        region: "analamanga",
        district: "anjozorobe",
        commune: "beronono",
        fokontany: "amparihimaromaso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.189301,
          lng: 47.588009,
        },
        _id: "64f75284ef8f606073e98727",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "andramasina",
        fokontany: "andramasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1503091,
          lng: 47.557011,
        },
        _id: "64f75284ef8f606073e98728",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "andramasina",
        fokontany: "ankorona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1426626,
          lng: 47.5889542,
        },
        _id: "64f75284ef8f606073e98729",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "andramasina",
        fokontany: "ambodivato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1608512,
          lng: 47.6110802,
        },
        _id: "64f75284ef8f606073e9872a",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "andramasina",
        fokontany: "ambohijafy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1732693,
          lng: 47.57912349999999,
        },
        _id: "64f75284ef8f606073e9872b",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "andramasina",
        fokontany: "ambohitrandraina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1878742,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e9872c",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "andramasina",
        fokontany: "ambalavao firaisana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2057834,
          lng: 47.6012453,
        },
        _id: "64f75284ef8f606073e9872d",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "andramasina",
        fokontany: "antanetibe firaisana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1828818,
          lng: 47.5373633,
        },
        _id: "64f75284ef8f606073e9872e",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "andramasina",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2299913,
          lng: 47.5520984,
        },
        _id: "64f75284ef8f606073e9872f",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "andramasina",
        fokontany: "bemalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2220806,
          lng: 47.5914122,
        },
        _id: "64f75284ef8f606073e98730",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "sabotsy ambohitromby",
        fokontany: "sabotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2304558,
          lng: 47.62091700000001,
        },
        _id: "64f75284ef8f606073e98731",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "sabotsy ambohitromby",
        fokontany: "anempoka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2479182,
          lng: 47.581581,
        },
        _id: "64f75284ef8f606073e98732",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "sabotsy ambohitromby",
        fokontany: "ambohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2348596,
          lng: 47.67012829999999,
        },
        _id: "64f75284ef8f606073e98733",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "sabotsy ambohitromby",
        fokontany: "manarintsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2107718,
          lng: 47.6356755,
        },
        _id: "64f75284ef8f606073e98734",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "sabotsy ambohitromby",
        fokontany: "amboanjobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2647824,
          lng: 47.557011,
        },
        _id: "64f75284ef8f606073e98735",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "sabotsy ambohitromby",
        fokontany: "manjaka andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2455886,
          lng: 47.640596,
        },
        _id: "64f75284ef8f606073e98736",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "sabotsy ambohitromby",
        fokontany: "antsahamanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2658517,
          lng: 47.6110802,
        },
        _id: "64f75284ef8f606073e98737",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "sabotsy ambohitromby",
        fokontany: "andrataninandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2556561,
          lng: 47.6676666,
        },
        _id: "64f75284ef8f606073e98738",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "sabotsy ambohitromby",
        fokontany: "mahasoratra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3276512,
          lng: 47.5766661,
        },
        _id: "64f75284ef8f606073e98739",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "andohariana",
        fokontany: "andohariana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3175375,
          lng: 47.5914122,
        },
        _id: "64f75284ef8f606073e9873a",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "andohariana",
        fokontany: "amby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2928403,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e9873b",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "andohariana",
        fokontany: "ambatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3405676,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e9873c",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "andohariana",
        fokontany: "antandrokomby I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3546074,
          lng: 47.57912349999999,
        },
        _id: "64f75284ef8f606073e9873d",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "andohariana",
        fokontany: "antandrokomby II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3765147,
          lng: 47.5889542,
        },
        _id: "64f75284ef8f606073e9873e",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "andohariana",
        fokontany: "beravina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1559199,
          lng: 47.664509,
        },
        _id: "64f75284ef8f606073e9873f",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "mandrosoa",
        fokontany: "mandrosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1595382,
          lng: 47.6824383,
        },
        _id: "64f75284ef8f606073e98740",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "mandrosoa",
        fokontany: "manjambohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1498071,
          lng: 47.6479775,
        },
        _id: "64f75284ef8f606073e98741",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "mandrosoa",
        fokontany: "antovontany",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1692012,
          lng: 47.640596,
        },
        _id: "64f75284ef8f606073e98742",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "mandrosoa",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1270263,
          lng: 47.6602824,
        },
        _id: "64f75284ef8f606073e98743",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "mandrosoa",
        fokontany: "ambika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1820253,
          lng: 47.677514,
        },
        _id: "64f75284ef8f606073e98744",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "mandrosoa",
        fokontany: "andranovelona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1938666,
          lng: 47.6602824,
        },
        _id: "64f75284ef8f606073e98745",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "mandrosoa",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3236502,
          lng: 47.6799761,
        },
        _id: "64f75284ef8f606073e98746",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alatsinainy bakaro",
        fokontany: "alatsinainy bakaro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2854238,
          lng: 47.6799761,
        },
        _id: "64f75284ef8f606073e98747",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alatsinainy bakaro",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2938104,
          lng: 47.7095305,
        },
        _id: "64f75284ef8f606073e98748",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alatsinainy bakaro",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2718994,
          lng: 47.6996772,
        },
        _id: "64f75284ef8f606073e98749",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alatsinainy bakaro",
        fokontany: "soavinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2994009,
          lng: 47.7292426,
        },
        _id: "64f75284ef8f606073e9874a",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alatsinainy bakaro",
        fokontany: "soanafindra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3270057,
          lng: 47.69598269999999,
        },
        _id: "64f75284ef8f606073e9874b",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alatsinainy bakaro",
        fokontany: "amboanjobe I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3224901,
          lng: 47.7095305,
        },
        _id: "64f75284ef8f606073e9874c",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alatsinainy bakaro",
        fokontany: "amboanjobe II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3533966,
          lng: 47.7341718,
        },
        _id: "64f75284ef8f606073e9874d",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alatsinainy bakaro",
        fokontany: "anjoma faliarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3184544,
          lng: 47.7711552,
        },
        _id: "64f75284ef8f606073e9874e",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alatsinainy bakaro",
        fokontany: "bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3488491,
          lng: 47.7686889,
        },
        _id: "64f75284ef8f606073e9874f",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alatsinainy bakaro",
        fokontany: "analamerana antambiazina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3060932,
          lng: 47.7612905,
        },
        _id: "64f75284ef8f606073e98750",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alatsinainy bakaro",
        fokontany: "anororo centre",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3342039,
          lng: 47.7760883,
        },
        _id: "64f75284ef8f606073e98751",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alatsinainy bakaro",
        fokontany: "ambohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3590776,
          lng: 47.67012829999999,
        },
        _id: "64f75284ef8f606073e98752",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alatsinainy bakaro",
        fokontany: "ambohidavenona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3646876,
          lng: 47.6898257,
        },
        _id: "64f75284ef8f606073e98753",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alatsinainy bakaro",
        fokontany: "antevana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.312448,
          lng: 47.640596,
        },
        _id: "64f75284ef8f606073e98754",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alatsinainy bakaro",
        fokontany: "vatosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2835015,
          lng: 47.6479775,
        },
        _id: "64f75284ef8f606073e98755",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alatsinainy bakaro",
        fokontany: "ampihiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3040453,
          lng: 47.6110802,
        },
        _id: "64f75284ef8f606073e98756",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alatsinainy bakaro",
        fokontany: "antoho",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3343572,
          lng: 47.6504382,
        },
        _id: "64f75284ef8f606073e98757",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alatsinainy bakaro",
        fokontany: "ambohitra bemahatazana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3433018,
          lng: 47.707067,
        },
        _id: "64f75284ef8f606073e98758",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alatsinainy bakaro",
        fokontany: "anjozoro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2677759,
          lng: 47.6430564,
        },
        _id: "64f75284ef8f606073e98759",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alatsinainy bakaro",
        fokontany: "andranomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3579753,
          lng: 47.6159983,
        },
        _id: "64f75284ef8f606073e9875a",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "antotohazo",
        fokontany: "antotohazo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.375394,
          lng: 47.6184576,
        },
        _id: "64f75284ef8f606073e9875b",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "antotohazo",
        fokontany: "ambohidraondriana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3945003,
          lng: 47.6184576,
        },
        _id: "64f75284ef8f606073e9875c",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "antotohazo",
        fokontany: "fiakarantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3554496,
          lng: 47.5987868,
        },
        _id: "64f75284ef8f606073e9875d",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "antotohazo",
        fokontany: "ambohitrakanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3574095,
          lng: 47.6307555,
        },
        _id: "64f75284ef8f606073e9875e",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "antotohazo",
        fokontany: "antoribe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.339156,
          lng: 47.6086213,
        },
        _id: "64f75284ef8f606073e9875f",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "antotohazo",
        fokontany: "ankazo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.24552,
          lng: 47.772129,
        },
        _id: "64f75284ef8f606073e98760",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "ambohimiadana",
        fokontany: "ambohimiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2903492,
          lng: 47.7563588,
        },
        _id: "64f75284ef8f606073e98761",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "ambohimiadana",
        fokontany: "amberobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2665217,
          lng: 47.8328517,
        },
        _id: "64f75284ef8f606073e98762",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "ambohimiadana",
        fokontany: "iharamalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2698263,
          lng: 47.7514275,
        },
        _id: "64f75284ef8f606073e98763",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "ambohimiadana",
        fokontany: "antsahamasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.287764,
          lng: 47.7810219,
        },
        _id: "64f75284ef8f606073e98764",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "ambohimiadana",
        fokontany: "ambodivato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2300673,
          lng: 47.78842299999999,
        },
        _id: "64f75284ef8f606073e98765",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "ambohimiadana",
        fokontany: "antsahondra miadamanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2288504,
          lng: 47.81803800000001,
        },
        _id: "64f75284ef8f606073e98766",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "ambohimiadana",
        fokontany: "miadampahonina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2488966,
          lng: 47.7958252,
        },
        _id: "64f75284ef8f606073e98767",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "ambohimiadana",
        fokontany: "ambohimahitsy telonorefy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2449277,
          lng: 47.8921477,
        },
        _id: "64f75284ef8f606073e98768",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "ambohimiadana",
        fokontany: "manandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2711199,
          lng: 47.7982928,
        },
        _id: "64f75284ef8f606073e98769",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "ambohimiadana",
        fokontany: "antsahondra maroambika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2022883,
          lng: 47.7662226,
        },
        _id: "64f75284ef8f606073e9876a",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "ambohimiadana",
        fokontany: "tsarasaotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2194146,
          lng: 47.7760883,
        },
        _id: "64f75284ef8f606073e9876b",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "ambohimiadana",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2052376,
          lng: 47.8106327,
        },
        _id: "64f75284ef8f606073e9876c",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "ambohimiadana",
        fokontany: "andrere",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2013735,
          lng: 47.78842299999999,
        },
        _id: "64f75284ef8f606073e9876d",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "ambohimiadana",
        fokontany: "antsahandenda",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2958881,
          lng: 47.797059,
        },
        _id: "64f75284ef8f606073e9876e",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "tankafatra",
        fokontany: "tankafatra sud",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2853225,
          lng: 47.8019945,
        },
        _id: "64f75284ef8f606073e9876f",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "tankafatra",
        fokontany: "tankafatra centre",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2881126,
          lng: 47.81186690000001,
        },
        _id: "64f75284ef8f606073e98770",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "tankafatra",
        fokontany: "tankafatra nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3339271,
          lng: 47.9020367,
        },
        _id: "64f75284ef8f606073e98771",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "tankafatra",
        fokontany: "ankazotelo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.312188,
          lng: 47.8081645,
        },
        _id: "64f75284ef8f606073e98772",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "tankafatra",
        fokontany: "analandambo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3009704,
          lng: 47.7896566,
        },
        _id: "64f75284ef8f606073e98773",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "tankafatra",
        fokontany: "ampangabe I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3021146,
          lng: 47.7810219,
        },
        _id: "64f75284ef8f606073e98774",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "tankafatra",
        fokontany: "ampangabe II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3262475,
          lng: 47.8155695,
        },
        _id: "64f75284ef8f606073e98775",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "tankafatra",
        fokontany: "anosivola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3156193,
          lng: 47.7822553,
        },
        _id: "64f75284ef8f606073e98776",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "tankafatra",
        fokontany: "andranofankatia",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3397983,
          lng: 47.7958252,
        },
        _id: "64f75284ef8f606073e98777",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "tankafatra",
        fokontany: "andohariana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3532713,
          lng: 47.81803800000001,
        },
        _id: "64f75284ef8f606073e98778",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "tankafatra",
        fokontany: "morarano soafiraisana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4760382,
          lng: 47.8624914,
        },
        _id: "64f75284ef8f606073e98779",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "tankafatra",
        fokontany: "anovondriana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3724187,
          lng: 47.81803800000001,
        },
        _id: "64f75284ef8f606073e9877a",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "tankafatra",
        fokontany: "analamihoatra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4117697,
          lng: 47.872375,
        },
        _id: "64f75284ef8f606073e9877b",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "tankafatra",
        fokontany: "kelilalina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2398454,
          lng: 47.7046036,
        },
        _id: "64f75284ef8f606073e9877c",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alarobia vatosola",
        fokontany: "alarobia vatosola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2121534,
          lng: 47.7588245,
        },
        _id: "64f75284ef8f606073e9877d",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alarobia vatosola",
        fokontany: "masindray tsaramiera",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2094847,
          lng: 47.707067,
        },
        _id: "64f75284ef8f606073e9877e",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alarobia vatosola",
        fokontany: "antamboho",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2308844,
          lng: 47.6898257,
        },
        _id: "64f75284ef8f606073e9877f",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alarobia vatosola",
        fokontany: "ambohibemanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2269045,
          lng: 47.7095305,
        },
        _id: "64f75284ef8f606073e98780",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alarobia vatosola",
        fokontany: "soavinandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2583667,
          lng: 47.7193856,
        },
        _id: "64f75284ef8f606073e98781",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alarobia vatosola",
        fokontany: "antovontany",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2352655,
          lng: 47.7391014,
        },
        _id: "64f75284ef8f606073e98782",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alarobia vatosola",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2861599,
          lng: 47.7415664,
        },
        _id: "64f75284ef8f606073e98783",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "alarobia vatosola",
        fokontany: "andobo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4107895,
          lng: 47.7341718,
        },
        _id: "64f75284ef8f606073e98784",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "fitsinjovana bakaro",
        fokontany: "ambatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4084923,
          lng: 47.7933577,
        },
        _id: "64f75284ef8f606073e98785",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "fitsinjovana bakaro",
        fokontany: "ambohijanaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4034567,
          lng: 47.7588245,
        },
        _id: "64f75284ef8f606073e98786",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "fitsinjovana bakaro",
        fokontany: "kelimafana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4124925,
          lng: 47.6898257,
        },
        _id: "64f75284ef8f606073e98787",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "fitsinjovana bakaro",
        fokontany: "tankasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3838085,
          lng: 47.6898257,
        },
        _id: "64f75284ef8f606073e98788",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "fitsinjovana bakaro",
        fokontany: "bakaro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3973114,
          lng: 47.67012829999999,
        },
        _id: "64f75284ef8f606073e98789",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "fitsinjovana bakaro",
        fokontany: "ampanataovana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3984375,
          lng: 47.640596,
        },
        _id: "64f75284ef8f606073e9878a",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "fitsinjovana bakaro",
        fokontany: "analamilona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1806915,
          lng: 47.7489621,
        },
        _id: "64f75284ef8f606073e9878b",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "sabotsy manjakavahoaka",
        fokontany: "sabotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1604917,
          lng: 47.7366365,
        },
        _id: "64f75284ef8f606073e9878c",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "sabotsy manjakavahoaka",
        fokontany: "soavinimerina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1706579,
          lng: 47.7218497,
        },
        _id: "64f75284ef8f606073e9878d",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "sabotsy manjakavahoaka",
        fokontany: "mananjara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1914635,
          lng: 47.7193856,
        },
        _id: "64f75284ef8f606073e9878e",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "sabotsy manjakavahoaka",
        fokontany: "mangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1858985,
          lng: 47.6996772,
        },
        _id: "64f75284ef8f606073e9878f",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "sabotsy manjakavahoaka",
        fokontany: "ambohimamory",
        __v: 0,
      },
      {
        coords: {
          lat: -19.150464,
          lng: 47.7095305,
        },
        _id: "64f75284ef8f606073e98790",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "sabotsy manjakavahoaka",
        fokontany: "ambohitrandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1671289,
          lng: 47.7686889,
        },
        _id: "64f75284ef8f606073e98791",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "anosibe trimoloharano",
        fokontany: "anosibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1631204,
          lng: 47.78842299999999,
        },
        _id: "64f75284ef8f606073e98792",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "anosibe trimoloharano",
        fokontany: "ambohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1782368,
          lng: 47.8081645,
        },
        _id: "64f75284ef8f606073e98793",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "anosibe trimoloharano",
        fokontany: "tsarazaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1786963,
          lng: 47.835321,
        },
        _id: "64f75284ef8f606073e98794",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "anosibe trimoloharano",
        fokontany: "est mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1893431,
          lng: 47.84766949999999,
        },
        _id: "64f75284ef8f606073e98795",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "anosibe trimoloharano",
        fokontany: "angodongodona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1930212,
          lng: 47.9119276,
        },
        _id: "64f75284ef8f606073e98796",
        province: "antananarivo",
        region: "analamanga",
        district: "andramasina",
        commune: "anosibe trimoloharano",
        fokontany: "andriantsiazo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9377108,
          lng: 47.4747853,
        },
        _id: "64f75284ef8f606073e98797",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampitatafika",
        fokontany: "ampitatafika vaovao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9343319,
          lng: 47.4796907,
        },
        _id: "64f75284ef8f606073e98798",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampitatafika",
        fokontany: "avaratetezana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9413966,
          lng: 47.4625239,
        },
        _id: "64f75284ef8f606073e98799",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampitatafika",
        fokontany: "ambodiafontsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9438517,
          lng: 47.4796907,
        },
        _id: "64f75284ef8f606073e9879a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampitatafika",
        fokontany: "atsimombohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9513923,
          lng: 47.446282,
        },
        _id: "64f75284ef8f606073e9879b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampitatafika",
        fokontany: "malaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9556741,
          lng: 47.4625239,
        },
        _id: "64f75284ef8f606073e9879c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampitatafika",
        fokontany: "faliarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.951299,
          lng: 47.4723328,
        },
        _id: "64f75284ef8f606073e9879d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampitatafika",
        fokontany: "ankaditany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9497583,
          lng: 47.4331084,
        },
        _id: "64f75284ef8f606073e9879e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampitatafika",
        fokontany: "ambodizozoro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9515287,
          lng: 47.485823,
        },
        _id: "64f75284ef8f606073e9879f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampitatafika",
        fokontany: "ambohimangidy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9581256,
          lng: 47.4416862,
        },
        _id: "64f75284ef8f606073e987a0",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampitatafika",
        fokontany: "soavimbahoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.966423,
          lng: 47.4711066,
        },
        _id: "64f75284ef8f606073e987a1",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampitatafika",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9622744,
          lng: 47.4563943,
        },
        _id: "64f75284ef8f606073e987a2",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampitatafika",
        fokontany: "ambohipamonjy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.973024,
          lng: 47.464976,
        },
        _id: "64f75284ef8f606073e987a3",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampitatafika",
        fokontany: "ambohimahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9414312,
          lng: 47.49011609999999,
        },
        _id: "64f75284ef8f606073e987a4",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "anosizato andrefana",
        fokontany: "antandrokomby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9341357,
          lng: 47.4937962,
        },
        _id: "64f75284ef8f606073e987a5",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "anosizato andrefana",
        fokontany: "ampefiloha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.937361,
          lng: 47.4925695,
        },
        _id: "64f75284ef8f606073e987a6",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "anosizato andrefana",
        fokontany: "antokontanitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9377323,
          lng: 47.4961446,
        },
        _id: "64f75284ef8f606073e987a7",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "anosizato andrefana",
        fokontany: "antananambony",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9400865,
          lng: 47.4937962,
        },
        _id: "64f75284ef8f606073e987a8",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "anosizato andrefana",
        fokontany: "ambanimaso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.943657,
          lng: 47.4937962,
        },
        _id: "64f75284ef8f606073e987a9",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "anosizato andrefana",
        fokontany: "ambodivona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9446927,
          lng: 47.4974765,
        },
        _id: "64f75284ef8f606073e987aa",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "anosizato andrefana",
        fokontany: "ankazotoho",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9229678,
          lng: 47.485823,
        },
        _id: "64f75284ef8f606073e987ab",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "andranonahoatra",
        fokontany: "andranonahoatra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9094223,
          lng: 47.4778511,
        },
        _id: "64f75284ef8f606073e987ac",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "andranonahoatra",
        fokontany: "akany sambatra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9125132,
          lng: 47.4750919,
        },
        _id: "64f75284ef8f606073e987ad",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "andranonahoatra",
        fokontany: "soamiampita",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9176734,
          lng: 47.4796907,
        },
        _id: "64f75284ef8f606073e987ae",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "andranonahoatra",
        fokontany: "ambanilalana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9215104,
          lng: 47.4827568,
        },
        _id: "64f75284ef8f606073e987af",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "andranonahoatra",
        fokontany: "akany firaisana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9251926,
          lng: 47.4895028,
        },
        _id: "64f75284ef8f606073e987b0",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "andranonahoatra",
        fokontany: "ambaniala",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9302624,
          lng: 47.4821436,
        },
        _id: "64f75284ef8f606073e987b1",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "andranonahoatra",
        fokontany: "ambohimamory",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9095777,
          lng: 47.4741722,
        },
        _id: "64f75284ef8f606073e987b2",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambohidrapeto",
        fokontany: "avaratsena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8887422,
          lng: 47.4698804,
        },
        _id: "64f75284ef8f606073e987b3",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambohidrapeto",
        fokontany: "merimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8997932,
          lng: 47.4711066,
        },
        _id: "64f75284ef8f606073e987b4",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambohidrapeto",
        fokontany: "ambohidrapeto",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9031727,
          lng: 47.4662021,
        },
        _id: "64f75284ef8f606073e987b5",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambohidrapeto",
        fokontany: "antsahamasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9057752,
          lng: 47.4796907,
        },
        _id: "64f75284ef8f606073e987b6",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambohidrapeto",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9053078,
          lng: 47.4907295,
        },
        _id: "64f75284ef8f606073e987b7",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "bemasoandro",
        fokontany: "bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9079214,
          lng: 47.4852098,
        },
        _id: "64f75284ef8f606073e987b8",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "bemasoandro",
        fokontany: "ambodiamberivatry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9098007,
          lng: 47.4876629,
        },
        _id: "64f75284ef8f606073e987b9",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "bemasoandro",
        fokontany: "antanety",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9053858,
          lng: 47.4888895,
        },
        _id: "64f75284ef8f606073e987ba",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "bemasoandro",
        fokontany: "ambohijafy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9075318,
          lng: 47.4944096,
        },
        _id: "64f75284ef8f606073e987bb",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "bemasoandro",
        fokontany: "anosimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9124915,
          lng: 47.4803039,
        },
        _id: "64f75284ef8f606073e987bc",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "bemasoandro",
        fokontany: "ambohidahy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8945668,
          lng: 47.4821436,
        },
        _id: "64f75284ef8f606073e987bd",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "fiombonana",
        fokontany: "marobiby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8807598,
          lng: 47.4711066,
        },
        _id: "64f75284ef8f606073e987be",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "fiombonana",
        fokontany: "anosivita boina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8891189,
          lng: 47.4796907,
        },
        _id: "64f75284ef8f606073e987bf",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "fiombonana",
        fokontany: "vonelina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8919208,
          lng: 47.4790775,
        },
        _id: "64f75284ef8f606073e987c0",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "fiombonana",
        fokontany: "antsahakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8935652,
          lng: 47.4870496,
        },
        _id: "64f75284ef8f606073e987c1",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "fiombonana",
        fokontany: "andranovaky",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9068617,
          lng: 47.4539427,
        },
        _id: "64f75284ef8f606073e987c2",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "itaosy",
        fokontany: "itaosy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9008795,
          lng: 47.4453628,
        },
        _id: "64f75284ef8f606073e987c3",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "itaosy",
        fokontany: "anjakaivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.903026,
          lng: 47.4508783,
        },
        _id: "64f75284ef8f606073e987c4",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "itaosy",
        fokontany: "tsarazaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9133084,
          lng: 47.4514912,
        },
        _id: "64f75284ef8f606073e987c5",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "itaosy",
        fokontany: "ambohiparaky",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0069735,
          lng: 47.4882762,
        },
        _id: "64f75284ef8f606073e987c6",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "itaosy",
        fokontany: "ambodifasika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9265023,
          lng: 47.477238,
        },
        _id: "64f75284ef8f606073e987c7",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "itaosy",
        fokontany: "avarabohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9283869,
          lng: 47.460685,
        },
        _id: "64f75284ef8f606073e987c8",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambavahaditokana",
        fokontany: "anjanadrambony",
        __v: 0,
      },
      {
        coords: {
          lat: -18.918293,
          lng: 47.464976,
        },
        _id: "64f75284ef8f606073e987c9",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambavahaditokana",
        fokontany: "amboatavo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9216713,
          lng: 47.460072,
        },
        _id: "64f75284ef8f606073e987ca",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambavahaditokana",
        fokontany: "antanjona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9260475,
          lng: 47.4502654,
        },
        _id: "64f75284ef8f606073e987cb",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambavahaditokana",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9298812,
          lng: 47.4723328,
        },
        _id: "64f75284ef8f606073e987cc",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambavahaditokana",
        fokontany: "loharanombato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9337216,
          lng: 47.4563943,
        },
        _id: "64f75284ef8f606073e987cd",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambavahaditokana",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9576631,
          lng: 47.5287697,
        },
        _id: "64f75284ef8f606073e987ce",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "tanjombato",
        fokontany: "tanjombato iraitsimivaky",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9550557,
          lng: 47.5152684,
        },
        _id: "64f75284ef8f606073e987cf",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "tanjombato",
        fokontany: "ankeniheny miray hina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9556616,
          lng: 47.538591,
        },
        _id: "64f75284ef8f606073e987d0",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "tanjombato",
        fokontany: "ambohimanatrika mivoatra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9643849,
          lng: 47.52938349999999,
        },
        _id: "64f75284ef8f606073e987d1",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "tanjombato",
        fokontany: "andafiatsimo mijoro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9688798,
          lng: 47.5263146,
        },
        _id: "64f75284ef8f606073e987d2",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "tanjombato",
        fokontany: "tongarivo mandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9793256,
          lng: 47.5422745,
        },
        _id: "64f75284ef8f606073e987d3",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "andoharanofotsy",
        fokontany: "andoharanofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9770237,
          lng: 47.5214049,
        },
        _id: "64f75284ef8f606073e987d4",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "andoharanofotsy",
        fokontany: "ambohimanala",
        __v: 0,
      },
      {
        coords: {
          lat: -18.977098,
          lng: 47.538591,
        },
        _id: "64f75284ef8f606073e987d5",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "andoharanofotsy",
        fokontany: "belambanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9760245,
          lng: 47.5263146,
        },
        _id: "64f75284ef8f606073e987d6",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "andoharanofotsy",
        fokontany: "volotara firaisana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9859347,
          lng: 47.5361355,
        },
        _id: "64f75284ef8f606073e987d7",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "andoharanofotsy",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9838607,
          lng: 47.5287697,
        },
        _id: "64f75284ef8f606073e987d8",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "andoharanofotsy",
        fokontany: "mahalavolona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.989623,
          lng: 47.5238597,
        },
        _id: "64f75284ef8f606073e987d9",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "andoharanofotsy",
        fokontany: "morarano firaisana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9978448,
          lng: 47.5361355,
        },
        _id: "64f75284ef8f606073e987da",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "andoharanofotsy",
        fokontany: "iavoloha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9657663,
          lng: 47.534294,
        },
        _id: "64f75284ef8f606073e987db",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ankaraobato",
        fokontany: "antanetisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9620388,
          lng: 47.5379771,
        },
        _id: "64f75284ef8f606073e987dc",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ankaraobato",
        fokontany: "ambohibahiny",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9621938,
          lng: 47.534294,
        },
        _id: "64f75284ef8f606073e987dd",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ankaraobato",
        fokontany: "ambodivoanjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9624966,
          lng: 47.5459582,
        },
        _id: "64f75284ef8f606073e987de",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ankaraobato",
        fokontany: "ifarihy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9669928,
          lng: 47.5428884,
        },
        _id: "64f75284ef8f606073e987df",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ankaraobato",
        fokontany: "ankadinandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9673412,
          lng: 47.5346009,
        },
        _id: "64f75284ef8f606073e987e0",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ankaraobato",
        fokontany: "antsahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9699523,
          lng: 47.538591,
        },
        _id: "64f75284ef8f606073e987e1",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ankaraobato",
        fokontany: "ankadilalampotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.992923,
          lng: 47.5017705,
        },
        _id: "64f75284ef8f606073e987e2",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "soalandy",
        fokontany: "ankadivoribe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9842797,
          lng: 47.49011609999999,
        },
        _id: "64f75284ef8f606073e987e3",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "soalandy",
        fokontany: "tsararirinina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9931561,
          lng: 47.5152684,
        },
        _id: "64f75284ef8f606073e987e4",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "soalandy",
        fokontany: "ambatomanoina lovasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9884664,
          lng: 47.4944096,
        },
        _id: "64f75284ef8f606073e987e5",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "soalandy",
        fokontany: "lailava est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9896158,
          lng: 47.485823,
        },
        _id: "64f75284ef8f606073e987e6",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "soalandy",
        fokontany: "lailava ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9968407,
          lng: 47.5029975,
        },
        _id: "64f75284ef8f606073e987e7",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "soalandy",
        fokontany: "soalandy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0128171,
          lng: 47.5005436,
        },
        _id: "64f75284ef8f606073e987e8",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "soalandy",
        fokontany: "beravina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0074455,
          lng: 47.5152684,
        },
        _id: "64f75284ef8f606073e987e9",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "soalandy",
        fokontany: "ambohimasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0899219,
          lng: 47.4784643,
        },
        _id: "64f75284ef8f606073e987ea",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "antanetikely",
        fokontany: "antanetikely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0383782,
          lng: 47.477238,
        },
        _id: "64f75284ef8f606073e987eb",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "antanetikely",
        fokontany: "ampanaosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0419963,
          lng: 47.485823,
        },
        _id: "64f75284ef8f606073e987ec",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "antanetikely",
        fokontany: "ampefiloha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.048596,
          lng: 47.4796907,
        },
        _id: "64f75284ef8f606073e987ed",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "antanetikely",
        fokontany: "amboasary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0521197,
          lng: 47.4711066,
        },
        _id: "64f75284ef8f606073e987ee",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "antanetikely",
        fokontany: "antakiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0548406,
          lng: 47.5017705,
        },
        _id: "64f75284ef8f606073e987ef",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "antanetikely",
        fokontany: "ambohitsitakatra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0554403,
          lng: 47.4870496,
        },
        _id: "64f75284ef8f606073e987f0",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "antanetikely",
        fokontany: "lohanosy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0677947,
          lng: 47.4760116,
        },
        _id: "64f75284ef8f606073e987f1",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "antanetikely",
        fokontany: "antaboaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0632241,
          lng: 47.5103597,
        },
        _id: "64f75284ef8f606073e987f2",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "antanetikely",
        fokontany: "manjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0699755,
          lng: 47.5005436,
        },
        _id: "64f75284ef8f606073e987f3",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "antanetikely",
        fokontany: "andranofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0644618,
          lng: 47.5189502,
        },
        _id: "64f75284ef8f606073e987f4",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "antanetikely",
        fokontany: "ampanataovana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1000377,
          lng: 47.4637499,
        },
        _id: "64f75284ef8f606073e987f5",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "antanetikely",
        fokontany: "anjoma fanamiana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0988567,
          lng: 47.4931828,
        },
        _id: "64f75284ef8f606073e987f6",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "antanetikely",
        fokontany: "ampany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9764762,
          lng: 47.4582331,
        },
        _id: "64f75284ef8f606073e987f7",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "alatsinainy ambazaha",
        fokontany: "ambohimahamanina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9737846,
          lng: 47.4465884,
        },
        _id: "64f75284ef8f606073e987f8",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "alatsinainy ambazaha",
        fokontany: "anjanamanoro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9798593,
          lng: 47.4723328,
        },
        _id: "64f75284ef8f606073e987f9",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "alatsinainy ambazaha",
        fokontany: "morombato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9840798,
          lng: 47.4662021,
        },
        _id: "64f75284ef8f606073e987fa",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "alatsinainy ambazaha",
        fokontany: "soatsimeloka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9880616,
          lng: 47.4465884,
        },
        _id: "64f75284ef8f606073e987fb",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "alatsinainy ambazaha",
        fokontany: "ivatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9750262,
          lng: 47.4931828,
        },
        _id: "64f75284ef8f606073e987fc",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampanefy",
        fokontany: "ampanefy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9642748,
          lng: 47.48459649999999,
        },
        _id: "64f75284ef8f606073e987fd",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampanefy",
        fokontany: "behoririka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9714159,
          lng: 47.48459649999999,
        },
        _id: "64f75284ef8f606073e987fe",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampanefy",
        fokontany: "ampandrotrarana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9685755,
          lng: 47.4956363,
        },
        _id: "64f75284ef8f606073e987ff",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampanefy",
        fokontany: "isaingy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9727227,
          lng: 47.5103597,
        },
        _id: "64f75284ef8f606073e98800",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampanefy",
        fokontany: "malaho",
        __v: 0,
      },
      {
        coords: {
          lat: -18.98021,
          lng: 47.4925695,
        },
        _id: "64f75284ef8f606073e98801",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampanefy",
        fokontany: "ambohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9802461,
          lng: 47.4821436,
        },
        _id: "64f75284ef8f606073e98802",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampanefy",
        fokontany: "ambohidronono",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9833987,
          lng: 47.5017705,
        },
        _id: "64f75284ef8f606073e98803",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampanefy",
        fokontany: "antalata",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8869866,
          lng: 47.4551685,
        },
        _id: "64f75284ef8f606073e98804",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ankadimanga",
        fokontany: "ambohidavenona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.889821,
          lng: 47.46313689999999,
        },
        _id: "64f75284ef8f606073e98805",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ankadimanga",
        fokontany: "ambodivoanjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8928113,
          lng: 47.4674282,
        },
        _id: "64f75284ef8f606073e98806",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ankadimanga",
        fokontany: "ambohimidasy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8942563,
          lng: 47.4567008,
        },
        _id: "64f75284ef8f606073e98807",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ankadimanga",
        fokontany: "antanimenakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8891319,
          lng: 47.460685,
        },
        _id: "64f75284ef8f606073e98808",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ankadimanga",
        fokontany: "andravako",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8927441,
          lng: 47.4502654,
        },
        _id: "64f75284ef8f606073e98809",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ankadimanga",
        fokontany: "andohalafy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9351797,
          lng: 47.4404607,
        },
        _id: "64f75284ef8f606073e9880a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "fenoarivo",
        fokontany: "tampontanana fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9116256,
          lng: 47.4159569,
        },
        _id: "64f75284ef8f606073e9880b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "fenoarivo",
        fokontany: "soavinimerina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9117009,
          lng: 47.4331084,
        },
        _id: "64f75284ef8f606073e9880c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "fenoarivo",
        fokontany: "ambohijafy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9136965,
          lng: 47.4233069,
        },
        _id: "64f75284ef8f606073e9880d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "fenoarivo",
        fokontany: "ambatomilona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9164577,
          lng: 47.4331084,
        },
        _id: "64f75284ef8f606073e9880e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "fenoarivo",
        fokontany: "madiomanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9153057,
          lng: 47.4416862,
        },
        _id: "64f75284ef8f606073e9880f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "fenoarivo",
        fokontany: "ampefiloha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9260463,
          lng: 47.4122824,
        },
        _id: "64f75284ef8f606073e98810",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "fenoarivo",
        fokontany: "ambohijatovo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9337982,
          lng: 47.435559,
        },
        _id: "64f75284ef8f606073e98811",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "fenoarivo",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9303068,
          lng: 47.433721,
        },
        _id: "64f75284ef8f606073e98812",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "fenoarivo",
        fokontany: "ambaniavaratra ankadivory",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9359079,
          lng: 47.4324957,
        },
        _id: "64f75284ef8f606073e98813",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "fenoarivo",
        fokontany: "andrefankady ambonisaha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9341042,
          lng: 47.4282074,
        },
        _id: "64f75284ef8f606073e98814",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "fenoarivo",
        fokontany: "ambohidrazana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9424692,
          lng: 47.4367844,
        },
        _id: "64f75284ef8f606073e98815",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "fenoarivo",
        fokontany: "firavahana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.945997,
          lng: 47.4282074,
        },
        _id: "64f75284ef8f606073e98816",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "alakamisy fenoarivo",
        fokontany: "ambohimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9387039,
          lng: 47.4128948,
        },
        _id: "64f75284ef8f606073e98817",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "alakamisy fenoarivo",
        fokontany: "ambodivona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9391665,
          lng: 47.4208568,
        },
        _id: "64f75284ef8f606073e98818",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "alakamisy fenoarivo",
        fokontany: "ankadivory",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9425406,
          lng: 47.4159569,
        },
        _id: "64f75284ef8f606073e98819",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "alakamisy fenoarivo",
        fokontany: "ambohimiariana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9651784,
          lng: 47.4245319,
        },
        _id: "64f75284ef8f606073e9881a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "alakamisy fenoarivo",
        fokontany: "antanety II",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9587449,
          lng: 47.5029975,
        },
        _id: "64f75284ef8f606073e9881b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "soavina",
        fokontany: "soavina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9531384,
          lng: 47.5042244,
        },
        _id: "64f75284ef8f606073e9881c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "soavina",
        fokontany: "ambihivy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9551367,
          lng: 47.4944096,
        },
        _id: "64f75284ef8f606073e9881d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "soavina",
        fokontany: "vahilava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9629694,
          lng: 47.4968631,
        },
        _id: "64f75284ef8f606073e9881e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "soavina",
        fokontany: "ambanivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9633525,
          lng: 47.5066784,
        },
        _id: "64f75284ef8f606073e9881f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "soavina",
        fokontany: "analapanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9820897,
          lng: 47.5520984,
        },
        _id: "64f75284ef8f606073e98820",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambohijanaka",
        fokontany: "ambohijanaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9770151,
          lng: 47.5594675,
        },
        _id: "64f75284ef8f606073e98821",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambohijanaka",
        fokontany: "soanavela",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9790164,
          lng: 47.5496422,
        },
        _id: "64f75284ef8f606073e98822",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambohijanaka",
        fokontany: "imerimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9797781,
          lng: 47.56929450000001,
        },
        _id: "64f75284ef8f606073e98823",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambohijanaka",
        fokontany: "tsilazaina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9899281,
          lng: 47.5545546,
        },
        _id: "64f75284ef8f606073e98824",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambohijanaka",
        fokontany: "lohanosy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9884636,
          lng: 47.5705231,
        },
        _id: "64f75284ef8f606073e98825",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambohijanaka",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9920736,
          lng: 47.57912349999999,
        },
        _id: "64f75284ef8f606073e98826",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambohijanaka",
        fokontany: "mandalotsimaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9988413,
          lng: 47.56929450000001,
        },
        _id: "64f75284ef8f606073e98827",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambohijanaka",
        fokontany: "antovontany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9994583,
          lng: 47.5545546,
        },
        _id: "64f75284ef8f606073e98828",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambohijanaka",
        fokontany: "mahaimandry",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0043385,
          lng: 47.5613099,
        },
        _id: "64f75284ef8f606073e98829",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambohijanaka",
        fokontany: "soaranokely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0102356,
          lng: 47.55003079999999,
        },
        _id: "64f75284ef8f606073e9882a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambohijanaka",
        fokontany: "ambodiakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0162148,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e9882b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambohijanaka",
        fokontany: "ankadivola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0352448,
          lng: 47.4956363,
        },
        _id: "64f75284ef8f606073e9882c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampahitrosy",
        fokontany: "ampahitrosy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0139648,
          lng: 47.4919561,
        },
        _id: "64f75284ef8f606073e9882d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampahitrosy",
        fokontany: "ankorondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0142677,
          lng: 47.48459649999999,
        },
        _id: "64f75284ef8f606073e9882e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampahitrosy",
        fokontany: "ampamoabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0400077,
          lng: 47.4956363,
        },
        _id: "64f75284ef8f606073e9882f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampahitrosy",
        fokontany: "ambarindehilahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0441681,
          lng: 47.5103597,
        },
        _id: "64f75284ef8f606073e98830",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ampahitrosy",
        fokontany: "tongoloina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0429575,
          lng: 47.5398188,
        },
        _id: "64f75284ef8f606073e98831",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "bongatsara",
        fokontany: "anjomakely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0137544,
          lng: 47.5545546,
        },
        _id: "64f75284ef8f606073e98832",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "bongatsara",
        fokontany: "ambohimiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0146729,
          lng: 47.5324525,
        },
        _id: "64f75284ef8f606073e98833",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "bongatsara",
        fokontany: "ambohibao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0281883,
          lng: 47.512814,
        },
        _id: "64f75284ef8f606073e98834",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "bongatsara",
        fokontany: "amberokely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0282042,
          lng: 47.5508703,
        },
        _id: "64f75284ef8f606073e98835",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "bongatsara",
        fokontany: "amboanjobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0392736,
          lng: 47.5520984,
        },
        _id: "64f75284ef8f606073e98836",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "bongatsara",
        fokontany: "ambolamena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0449502,
          lng: 47.5299973,
        },
        _id: "64f75284ef8f606073e98837",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "bongatsara",
        fokontany: "antsahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0272253,
          lng: 47.4588461,
        },
        _id: "64f75284ef8f606073e98838",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "androhibe",
        fokontany: "androhibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9900724,
          lng: 47.4747853,
        },
        _id: "64f75284ef8f606073e98839",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "androhibe",
        fokontany: "antalaho",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0056525,
          lng: 47.4625239,
        },
        _id: "64f75284ef8f606073e9883a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "androhibe",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0140282,
          lng: 47.4711066,
        },
        _id: "64f75284ef8f606073e9883b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "androhibe",
        fokontany: "mandalova",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0087857,
          lng: 47.4441373,
        },
        _id: "64f75284ef8f606073e9883c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "androhibe",
        fokontany: "fidasiana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0160171,
          lng: 47.461298,
        },
        _id: "64f75284ef8f606073e9883d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "androhibe",
        fokontany: "antsahadinta",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0401199,
          lng: 47.4539427,
        },
        _id: "64f75284ef8f606073e9883e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "androhibe",
        fokontany: "ambatomalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0608494,
          lng: 47.4514912,
        },
        _id: "64f75284ef8f606073e9883f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "androhibe",
        fokontany: "ankadivory",
        __v: 0,
      },
      {
        coords: {
          lat: -19.070948,
          lng: 47.5545546,
        },
        _id: "64f75284ef8f606073e98840",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "tsiafahy",
        fokontany: "ambatofotsy gara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0317435,
          lng: 47.6184576,
        },
        _id: "64f75284ef8f606073e98841",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "tsiafahy",
        fokontany: "masomboay",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0309771,
          lng: 47.5987868,
        },
        _id: "64f75284ef8f606073e98842",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "tsiafahy",
        fokontany: "andrefandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0405138,
          lng: 47.5987868,
        },
        _id: "64f75284ef8f606073e98843",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "tsiafahy",
        fokontany: "ambohimiadana nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0475865,
          lng: 47.581581,
        },
        _id: "64f75284ef8f606073e98844",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "tsiafahy",
        fokontany: "avarabohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0557159,
          lng: 47.538591,
        },
        _id: "64f75284ef8f606073e98845",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "tsiafahy",
        fokontany: "ambohikely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0577962,
          lng: 47.5459582,
        },
        _id: "64f75284ef8f606073e98846",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "tsiafahy",
        fokontany: "ankorondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0611115,
          lng: 47.5619241,
        },
        _id: "64f75284ef8f606073e98847",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "tsiafahy",
        fokontany: "soavina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0687758,
          lng: 47.5299973,
        },
        _id: "64f75284ef8f606073e98848",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "tsiafahy",
        fokontany: "ambatolokanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0671296,
          lng: 47.6086213,
        },
        _id: "64f75284ef8f606073e98849",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "tsiafahy",
        fokontany: "ambohibololona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0667855,
          lng: 47.5398188,
        },
        _id: "64f75284ef8f606073e9884a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "tsiafahy",
        fokontany: "vatovaky",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0694313,
          lng: 47.5914122,
        },
        _id: "64f75284ef8f606073e9884b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "tsiafahy",
        fokontany: "soamanandray",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0874217,
          lng: 47.57912349999999,
        },
        _id: "64f75284ef8f606073e9884c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "tsiafahy",
        fokontany: "ambohaja",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0605037,
          lng: 47.5766661,
        },
        _id: "64f75284ef8f606073e9884d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "tsiafahy",
        fokontany: "tsiafahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1,
          lng: 47.59999999999999,
        },
        _id: "64f75284ef8f606073e9884e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "tsiafahy",
        fokontany: "ankazobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.106604,
          lng: 47.5373633,
        },
        _id: "64f75284ef8f606073e9884f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambalavao",
        fokontany: "ambalavao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0786066,
          lng: 47.5226323,
        },
        _id: "64f75284ef8f606073e98850",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambalavao",
        fokontany: "moratsiazo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0824309,
          lng: 47.5066784,
        },
        _id: "64f75284ef8f606073e98851",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambalavao",
        fokontany: "zafimbazahakely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0850054,
          lng: 47.5410466,
        },
        _id: "64f75284ef8f606073e98852",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambalavao",
        fokontany: "anosibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0898252,
          lng: 47.5201775,
        },
        _id: "64f75284ef8f606073e98853",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambalavao",
        fokontany: "ambohidahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0953825,
          lng: 47.5398188,
        },
        _id: "64f75284ef8f606073e98854",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambalavao",
        fokontany: "manankasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0874818,
          lng: 47.5582392,
        },
        _id: "64f75284ef8f606073e98855",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambalavao",
        fokontany: "ambohimamory",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0981611,
          lng: 47.5496422,
        },
        _id: "64f75284ef8f606073e98856",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambalavao",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1057072,
          lng: 47.5594675,
        },
        _id: "64f75284ef8f606073e98857",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambalavao",
        fokontany: "lohamandry",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1096824,
          lng: 47.5398188,
        },
        _id: "64f75284ef8f606073e98858",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambalavao",
        fokontany: "ambohidavenona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1115651,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e98859",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambalavao",
        fokontany: "ambohitsilaizina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.106902,
          lng: 47.5299973,
        },
        _id: "64f75284ef8f606073e9885a",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambalavao",
        fokontany: "amboniandrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1074965,
          lng: 47.5152684,
        },
        _id: "64f75284ef8f606073e9885b",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambalavao",
        fokontany: "antamboho",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1233883,
          lng: 47.5545546,
        },
        _id: "64f75284ef8f606073e9885c",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambalavao",
        fokontany: "ambohibarikely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1262634,
          lng: 47.5226323,
        },
        _id: "64f75284ef8f606073e9885d",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambalavao",
        fokontany: "ampangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1202029,
          lng: 47.5938703,
        },
        _id: "64f75284ef8f606073e9885e",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambatofahavalo",
        fokontany: "ankadivoribe faravohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0805229,
          lng: 47.6307555,
        },
        _id: "64f75284ef8f606073e9885f",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambatofahavalo",
        fokontany: "ankarefo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0940576,
          lng: 47.6110802,
        },
        _id: "64f75284ef8f606073e98860",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambatofahavalo",
        fokontany: "andohavary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1115996,
          lng: 47.6098507,
        },
        _id: "64f75284ef8f606073e98861",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambatofahavalo",
        fokontany: "ankazotokana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.115917,
          lng: 47.62091700000001,
        },
        _id: "64f75284ef8f606073e98862",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambatofahavalo",
        fokontany: "ambohimiadana ambatofahavalo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1255722,
          lng: 47.57912349999999,
        },
        _id: "64f75284ef8f606073e98863",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambatofahavalo",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1350029,
          lng: 47.62091700000001,
        },
        _id: "64f75284ef8f606073e98864",
        province: "antananarivo",
        region: "analamanga",
        district: "antananarivo atsimondrano",
        commune: "ambatofahavalo",
        fokontany: "ambalatokana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8716105,
          lng: 47.0224301,
        },
        _id: "64f75284ef8f606073e98865",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "antsenakely andraikiba",
        fokontany: "atsimontsena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8650505,
          lng: 47.0303319,
        },
        _id: "64f75284ef8f606073e98866",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "antsenakely andraikiba",
        fokontany: "avaratsena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8713282,
          lng: 46.9756523,
        },
        _id: "64f75284ef8f606073e98867",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "antsenakely andraikiba",
        fokontany: "andraikiba",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8685608,
          lng: 47.01817579999999,
        },
        _id: "64f75284ef8f606073e98868",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "antsenakely andraikiba",
        fokontany: "ivory",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8754739,
          lng: 46.9975171,
        },
        _id: "64f75284ef8f606073e98869",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "antsenakely andraikiba",
        fokontany: "andrefan'ivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8709416,
          lng: 47.0321556,
        },
        _id: "64f75284ef8f606073e9886a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "antsenakely andraikiba",
        fokontany: "antsenakely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8812701,
          lng: 47.0169603,
        },
        _id: "64f75284ef8f606073e9886b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "antsenakely andraikiba",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8775524,
          lng: 47.0224301,
        },
        _id: "64f75284ef8f606073e9886c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "antsenakely andraikiba",
        fokontany: "amboronomby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8863271,
          lng: 47.00602259999999,
        },
        _id: "64f75284ef8f606073e9886d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "antsenakely andraikiba",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8944833,
          lng: 46.9975171,
        },
        _id: "64f75284ef8f606073e9886e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "antsenakely andraikiba",
        fokontany: "andrangy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8769352,
          lng: 47.0303319,
        },
        _id: "64f75284ef8f606073e9886f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "ampatana mandriankeniheny",
        fokontany: "ampatana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8786389,
          lng: 47.0260769,
        },
        _id: "64f75284ef8f606073e98870",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "ampatana mandriankeniheny",
        fokontany: "bemasoandro ambalakisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8842698,
          lng: 47.0230379,
        },
        _id: "64f75284ef8f606073e98871",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "ampatana mandriankeniheny",
        fokontany: "ambohipeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8856171,
          lng: 47.0315477,
        },
        _id: "64f75284ef8f606073e98872",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "ampatana mandriankeniheny",
        fokontany: "ambohidravaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8955305,
          lng: 47.0169603,
        },
        _id: "64f75284ef8f606073e98873",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "ampatana mandriankeniheny",
        fokontany: "ambohidava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9114431,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e98874",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "ampatana mandriankeniheny",
        fokontany: "ambohitsarabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9149454,
          lng: 47.00237720000001,
        },
        _id: "64f75284ef8f606073e98875",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "ampatana mandriankeniheny",
        fokontany: "fananana ambositrakely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9221746,
          lng: 46.9987321,
        },
        _id: "64f75284ef8f606073e98876",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "ampatana mandriankeniheny",
        fokontany: "befaritra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9331334,
          lng: 47.0035923,
        },
        _id: "64f75284ef8f606073e98877",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "ampatana mandriankeniheny",
        fokontany: "mandriankeniheny",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8719763,
          lng: 47.037627,
        },
        _id: "64f75284ef8f606073e98878",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "soamalaza mahatsinjo",
        fokontany: "soamalaza tsarasaotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8562656,
          lng: 47.0607349,
        },
        _id: "64f75284ef8f606073e98879",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "soamalaza mahatsinjo",
        fokontany: "sahatsiho",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8708413,
          lng: 47.0497877,
        },
        _id: "64f75284ef8f606073e9887a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "soamalaza mahatsinjo",
        fokontany: "marodinta",
        __v: 0,
      },
      {
        coords: {
          lat: -19.871771,
          lng: 47.0449231,
        },
        _id: "64f75284ef8f606073e9887b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "soamalaza mahatsinjo",
        fokontany: "tomboarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8807647,
          lng: 47.0491796,
        },
        _id: "64f75284ef8f606073e9887c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "soamalaza mahatsinjo",
        fokontany: "ampanataovana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8841802,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e9887d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "soamalaza mahatsinjo",
        fokontany: "vohitrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8891373,
          lng: 47.0473554,
        },
        _id: "64f75284ef8f606073e9887e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "soamalaza mahatsinjo",
        fokontany: "ambalavato sud",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8974129,
          lng: 47.0631679,
        },
        _id: "64f75284ef8f606073e9887f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "soamalaza mahatsinjo",
        fokontany: "androvakely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.90962,
          lng: 47.0802023,
        },
        _id: "64f75284ef8f606073e98880",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "soamalaza mahatsinjo",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8760065,
          lng: 47.0351952,
        },
        _id: "64f75284ef8f606073e98881",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "antsirabe afovoany atsinanana",
        fokontany: "antsirabe afovoany atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8814851,
          lng: 47.037627,
        },
        _id: "64f75284ef8f606073e98882",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "antsirabe afovoany atsinanana",
        fokontany: "mahazina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8853111,
          lng: 47.0424909,
        },
        _id: "64f75284ef8f606073e98883",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "antsirabe afovoany atsinanana",
        fokontany: "ambohimena nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8940962,
          lng: 47.0400589,
        },
        _id: "64f75284ef8f606073e98884",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "antsirabe afovoany atsinanana",
        fokontany: "ambohimena atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9056792,
          lng: 47.051004,
        },
        _id: "64f75284ef8f606073e98885",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "antsirabe afovoany atsinanana",
        fokontany: "antanety",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9180912,
          lng: 47.0607349,
        },
        _id: "64f75284ef8f606073e98886",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "antsirabe afovoany atsinanana",
        fokontany: "tsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9302596,
          lng: 47.0218223,
        },
        _id: "64f75284ef8f606073e98887",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "antsirabe afovoany atsinanana",
        fokontany: "ambalavato 601",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9172466,
          lng: 47.0339793,
        },
        _id: "64f75284ef8f606073e98888",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "antsirabe afovoany atsinanana",
        fokontany: "verezambola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9327629,
          lng: 47.0461392,
        },
        _id: "64f75284ef8f606073e98889",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "antsirabe afovoany atsinanana",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9385687,
          lng: 47.06560109999999,
        },
        _id: "64f75284ef8f606073e9888a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "antsirabe afovoany atsinanana",
        fokontany: "ambohimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8624679,
          lng: 47.037627,
        },
        _id: "64f75284ef8f606073e9888b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "manodidina ny gara ambilombe",
        fokontany: "manodidina ny gara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8424173,
          lng: 47.0461392,
        },
        _id: "64f75284ef8f606073e9888c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "manodidina ny gara ambilombe",
        fokontany: "mahafaly",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8498595,
          lng: 47.0351952,
        },
        _id: "64f75284ef8f606073e9888d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "manodidina ny gara ambilombe",
        fokontany: "antsirabe avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8501681,
          lng: 47.0522202,
        },
        _id: "64f75284ef8f606073e9888e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "manodidina ny gara ambilombe",
        fokontany: "saharoaloha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8536832,
          lng: 47.0400589,
        },
        _id: "64f75284ef8f606073e9888f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "manodidina ny gara ambilombe",
        fokontany: "fitomilasy bevokatra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8620551,
          lng: 47.0522202,
        },
        _id: "64f75284ef8f606073e98890",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "manodidina ny gara ambilombe",
        fokontany: "ambohimanambola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8527479,
          lng: 47.0729012,
        },
        _id: "64f75284ef8f606073e98891",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "manodidina ny gara ambilombe",
        fokontany: "ambilombe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8507895,
          lng: 47.0303319,
        },
        _id: "64f75284ef8f606073e98892",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "mahazoarivo avarabohitra",
        fokontany: "mahazoarivo avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8198993,
          lng: 47.00237720000001,
        },
        _id: "64f75284ef8f606073e98893",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "mahazoarivo avarabohitra",
        fokontany: "tsarafara andranobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8227904,
          lng: 47.0120988,
        },
        _id: "64f75284ef8f606073e98894",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "mahazoarivo avarabohitra",
        fokontany: "ambohitsokina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8312611,
          lng: 46.9926574,
        },
        _id: "64f75284ef8f606073e98895",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "mahazoarivo avarabohitra",
        fokontany: "avarabohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.837047,
          lng: 47.0120988,
        },
        _id: "64f75284ef8f606073e98896",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "mahazoarivo avarabohitra",
        fokontany: "tsiafahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8366331,
          lng: 47.0266848,
        },
        _id: "64f75284ef8f606073e98897",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "mahazoarivo avarabohitra",
        fokontany: "vohijanahary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8379753,
          lng: 47.0351952,
        },
        _id: "64f75284ef8f606073e98898",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "mahazoarivo avarabohitra",
        fokontany: "miaramasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8509958,
          lng: 47.0230379,
        },
        _id: "64f75284ef8f606073e98899",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "mahazoarivo avarabohitra",
        fokontany: "antsongo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8484096,
          lng: 47.00237720000001,
        },
        _id: "64f75284ef8f606073e9889a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "mahazoarivo avarabohitra",
        fokontany: "ambalavato 401",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8473709,
          lng: 46.9829395,
        },
        _id: "64f75284ef8f606073e9889b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "mahazoarivo avarabohitra",
        fokontany: "ambohijafy nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8564658,
          lng: 46.9975171,
        },
        _id: "64f75284ef8f606073e9889c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "mahazoarivo avarabohitra",
        fokontany: "antanamanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.861226,
          lng: 47.0254691,
        },
        _id: "64f75284ef8f606073e9889d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "mahazoarivo avarabohitra",
        fokontany: "mahazoarivo atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8560569,
          lng: 47.0120988,
        },
        _id: "64f75284ef8f606073e9889e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "mahazoarivo avarabohitra",
        fokontany: "tsivatrinikamo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.863174,
          lng: 46.9841541,
        },
        _id: "64f75284ef8f606073e9889f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "mahazoarivo avarabohitra",
        fokontany: "antsanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8638076,
          lng: 47.01817579999999,
        },
        _id: "64f75284ef8f606073e988a0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe I",
        commune: "mahazoarivo avarabohitra",
        fokontany: "andafiatsimo star",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8414303,
          lng: 46.85374669999999,
        },
        _id: "64f75284ef8f606073e988a1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "betafo",
        fokontany: "atsinanatsena II atsimotsena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7998361,
          lng: 46.8713169,
        },
        _id: "64f75284ef8f606073e988a2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "betafo",
        fokontany: "andriamasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8175635,
          lng: 46.8591989,
        },
        _id: "64f75284ef8f606073e988a3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "betafo",
        fokontany: "ambohiambo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8290929,
          lng: 46.8422385,
        },
        _id: "64f75284ef8f606073e988a4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "betafo",
        fokontany: "soamiafara vakinifasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8315968,
          lng: 46.8664694,
        },
        _id: "64f75284ef8f606073e988a5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "betafo",
        fokontany: "ankazobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8385789,
          lng: 46.8422385,
        },
        _id: "64f75284ef8f606073e988a6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "betafo",
        fokontany: "ambohipihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8318936,
          lng: 46.855564,
        },
        _id: "64f75284ef8f606073e988a7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "betafo",
        fokontany: "avaratsena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8344955,
          lng: 46.876165,
        },
        _id: "64f75284ef8f606073e988a8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "betafo",
        fokontany: "atsinanantsena I ambodifarihy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8472803,
          lng: 46.8713169,
        },
        _id: "64f75284ef8f606073e988a9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "betafo",
        fokontany: "ambohinaorina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8622955,
          lng: 46.8422385,
        },
        _id: "64f75284ef8f606073e988aa",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "betafo",
        fokontany: "mahamasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8555153,
          lng: 46.8591989,
        },
        _id: "64f75284ef8f606073e988ab",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "betafo",
        fokontany: "ambohimanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8596716,
          lng: 46.88101349999999,
        },
        _id: "64f75284ef8f606073e988ac",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "betafo",
        fokontany: "miaramamindra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8839828,
          lng: 46.8591989,
        },
        _id: "64f75284ef8f606073e988ad",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "betafo",
        fokontany: "andriamboromanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8250378,
          lng: 46.81680859999999,
        },
        _id: "64f75284ef8f606073e988ae",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "andranomafana",
        fokontany: "anjazafotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8286212,
          lng: 46.8010727,
        },
        _id: "64f75284ef8f606073e988af",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "andranomafana",
        fokontany: "hiambanony",
        __v: 0,
      },
      {
        coords: {
          lat: -19.836069,
          lng: 46.81801919999999,
        },
        _id: "64f75284ef8f606073e988b0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "andranomafana",
        fokontany: "tsarazafy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.816667,
          lng: 46.8,
        },
        _id: "64f75284ef8f606073e988b1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "andranomafana",
        fokontany: "andranomafana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8455526,
          lng: 46.81801919999999,
        },
        _id: "64f75284ef8f606073e988b2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "andranomafana",
        fokontany: "manataokafa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8628759,
          lng: 46.8204406,
        },
        _id: "64f75284ef8f606073e988b3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "andranomafana",
        fokontany: "ampilanonana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8246064,
          lng: 46.8907119,
        },
        _id: "64f75284ef8f606073e988b4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mandritsara",
        fokontany: "ambohijato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8070723,
          lng: 46.8955618,
        },
        _id: "64f75284ef8f606073e988b5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mandritsara",
        fokontany: "miantsoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8127595,
          lng: 46.91860519999999,
        },
        _id: "64f75284ef8f606073e988b6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mandritsara",
        fokontany: "tsaratoko",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8202612,
          lng: 46.876165,
        },
        _id: "64f75284ef8f606073e988b7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mandritsara",
        fokontany: "malaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8256531,
          lng: 46.9101143,
        },
        _id: "64f75284ef8f606073e988b8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mandritsara",
        fokontany: "morafeno mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8217467,
          lng: 46.9368047,
        },
        _id: "64f75284ef8f606073e988b9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mandritsara",
        fokontany: "soamanandray",
        __v: 0,
      },
      {
        coords: {
          lat: -19.841342,
          lng: 46.914966,
        },
        _id: "64f75284ef8f606073e988ba",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mandritsara",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8355465,
          lng: 46.8955618,
        },
        _id: "64f75284ef8f606073e988bb",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mandritsara",
        fokontany: "ankabahaba",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8510357,
          lng: 46.9076886,
        },
        _id: "64f75284ef8f606073e988bc",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mandritsara",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.848586,
          lng: 46.93923179999999,
        },
        _id: "64f75284ef8f606073e988bd",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mandritsara",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8489874,
          lng: 46.924671,
        },
        _id: "64f75284ef8f606073e988be",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mandritsara",
        fokontany: "mandrosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8638564,
          lng: 46.93073750000001,
        },
        _id: "64f75284ef8f606073e988bf",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mandritsara",
        fokontany: "ampahatrimaha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8616816,
          lng: 46.9234577,
        },
        _id: "64f75284ef8f606073e988c0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mandritsara",
        fokontany: "iavomalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8848669,
          lng: 46.9033015,
        },
        _id: "64f75284ef8f606073e988c1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alakamisy anativato",
        fokontany: "alakamisy anativato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8720655,
          lng: 46.8907119,
        },
        _id: "64f75284ef8f606073e988c2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alakamisy anativato",
        fokontany: "antovotany anjanamasy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8700238,
          lng: 46.9076886,
        },
        _id: "64f75284ef8f606073e988c3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alakamisy anativato",
        fokontany: "ampamelomana anativato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.878891,
          lng: 46.9016248,
        },
        _id: "64f75284ef8f606073e988c4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alakamisy anativato",
        fokontany: "soamanandrariny",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8990919,
          lng: 46.88586249999999,
        },
        _id: "64f75284ef8f606073e988c5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alakamisy anativato",
        fokontany: "iakarina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9176935,
          lng: 46.9004122,
        },
        _id: "64f75284ef8f606073e988c6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alakamisy anativato",
        fokontany: "imanja",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9116854,
          lng: 46.8882871,
        },
        _id: "64f75284ef8f606073e988c7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alakamisy anativato",
        fokontany: "ambalakatra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9480173,
          lng: 46.8907119,
        },
        _id: "64f75284ef8f606073e988c8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alakamisy anativato",
        fokontany: "soavina belanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7771268,
          lng: 46.83497149999999,
        },
        _id: "64f75284ef8f606073e988c9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "antsoso",
        fokontany: "antsoso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7614565,
          lng: 46.83012739999999,
        },
        _id: "64f75284ef8f606073e988ca",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "antsoso",
        fokontany: "faravato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7701312,
          lng: 46.8591989,
        },
        _id: "64f75284ef8f606073e988cb",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "antsoso",
        fokontany: "ambatombano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7899044,
          lng: 46.83012739999999,
        },
        _id: "64f75284ef8f606073e988cc",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "antsoso",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7680755,
          lng: 46.876165,
        },
        _id: "64f75284ef8f606073e988cd",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "antsoso",
        fokontany: "antolotrandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9299048,
          lng: 46.9173921,
        },
        _id: "64f75284ef8f606073e988ce",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "tritriva",
        fokontany: "tritriva",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8840693,
          lng: 46.914966,
        },
        _id: "64f75284ef8f606073e988cf",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "tritriva",
        fokontany: "ambohimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8917207,
          lng: 46.924671,
        },
        _id: "64f75284ef8f606073e988d0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "tritriva",
        fokontany: "faravohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8968609,
          lng: 46.9101143,
        },
        _id: "64f75284ef8f606073e988d1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "tritriva",
        fokontany: "loharano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9043184,
          lng: 46.9270975,
        },
        _id: "64f75284ef8f606073e988d2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "tritriva",
        fokontany: "iavonarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.905533,
          lng: 46.9113272,
        },
        _id: "64f75284ef8f606073e988d3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "tritriva",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9141084,
          lng: 46.9161791,
        },
        _id: "64f75284ef8f606073e988d4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "tritriva",
        fokontany: "antanetivory",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9300977,
          lng: 46.9101143,
        },
        _id: "64f75284ef8f606073e988d5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "tritriva",
        fokontany: "miarinatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9649847,
          lng: 46.9076886,
        },
        _id: "64f75284ef8f606073e988d6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "tritriva",
        fokontany: "miantsoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.798759,
          lng: 46.7360309,
        },
        _id: "64f75284ef8f606073e988d7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "soavina",
        fokontany: "soavina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7725323,
          lng: 46.7720347,
        },
        _id: "64f75284ef8f606073e988d8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "soavina",
        fokontany: "marolaona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7906985,
          lng: 46.8010727,
        },
        _id: "64f75284ef8f606073e988d9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "soavina",
        fokontany: "alakamisy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.809855,
          lng: 46.7938116,
        },
        _id: "64f75284ef8f606073e988da",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "soavina",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8293955,
          lng: 46.7720347,
        },
        _id: "64f75284ef8f606073e988db",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "soavina",
        fokontany: "antanety",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8141146,
          lng: 46.7526854,
        },
        _id: "64f75284ef8f606073e988dc",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "soavina",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8560574,
          lng: 46.7188421,
        },
        _id: "64f75284ef8f606073e988dd",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "soavina",
        fokontany: "ambohitrambo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7020967,
          lng: 46.805914,
        },
        _id: "64f75284ef8f606073e988de",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "manohisoa",
        fokontany: "manohisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6377934,
          lng: 46.8446611,
        },
        _id: "64f75284ef8f606073e988df",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "manohisoa",
        fokontany: "antanetitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.66713,
          lng: 46.761169,
        },
        _id: "64f75284ef8f606073e988e0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "manohisoa",
        fokontany: "tsarazaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6703689,
          lng: 46.8107557,
        },
        _id: "64f75284ef8f606073e988e1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "manohisoa",
        fokontany: "kelimanefy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7160846,
          lng: 46.7575221,
        },
        _id: "64f75284ef8f606073e988e2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "manohisoa",
        fokontany: "alakamisy andrianovona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7301235,
          lng: 46.8204406,
        },
        _id: "64f75284ef8f606073e988e3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "manohisoa",
        fokontany: "ambohidatsika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.75,
          lng: 46.8,
        },
        _id: "64f75284ef8f606073e988e4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "manohisoa",
        fokontany: "tsarahasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6987549,
          lng: 46.9222445,
        },
        _id: "64f75284ef8f606073e988e5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambatonikolahy",
        fokontany: "ambatonikolahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6652426,
          lng: 46.9853688,
        },
        _id: "64f75284ef8f606073e988e6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambatonikolahy",
        fokontany: "tsaramody",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6599321,
          lng: 46.9513691,
        },
        _id: "64f75284ef8f606073e988e7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambatonikolahy",
        fokontany: "tsarazaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7069664,
          lng: 46.9659376,
        },
        _id: "64f75284ef8f606073e988e8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambatonikolahy",
        fokontany: "antanifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7296875,
          lng: 46.9465138,
        },
        _id: "64f75284ef8f606073e988e9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambatonikolahy",
        fokontany: "tsarazafy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6996012,
          lng: 46.8931368,
        },
        _id: "64f75284ef8f606073e988ea",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambatonikolahy",
        fokontany: "tsarafara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7676666,
          lng: 46.8907119,
        },
        _id: "64f75284ef8f606073e988eb",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambatonikolahy",
        fokontany: "belanosina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7833462,
          lng: 46.8955618,
        },
        _id: "64f75284ef8f606073e988ec",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambatonikolahy",
        fokontany: "mahafanalo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7998635,
          lng: 46.9270975,
        },
        _id: "64f75284ef8f606073e988ed",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambatonikolahy",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7431845,
          lng: 46.6898516,
        },
        _id: "64f75284ef8f606073e988ee",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "antohobe",
        fokontany: "antohobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7122884,
          lng: 46.6657058,
        },
        _id: "64f75284ef8f606073e988ef",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "antohobe",
        fokontany: "masoandronarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7333263,
          lng: 46.7043448,
        },
        _id: "64f75284ef8f606073e988f0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "antohobe",
        fokontany: "korosovola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7381665,
          lng: 46.64157160000001,
        },
        _id: "64f75284ef8f606073e988f1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "antohobe",
        fokontany: "matielona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7757397,
          lng: 46.7115929,
        },
        _id: "64f75284ef8f606073e988f2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "antohobe",
        fokontany: "soavina II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7580536,
          lng: 46.7236754,
        },
        _id: "64f75284ef8f606073e988f3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "antohobe",
        fokontany: "ambohimatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.907089,
          lng: 46.805691,
        },
        _id: "64f75284ef8f606073e988f4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mahaiza",
        fokontany: "mahaiza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8685747,
          lng: 46.7841318,
        },
        _id: "64f75284ef8f606073e988f5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mahaiza",
        fokontany: "mandoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8833614,
          lng: 46.7623591,
        },
        _id: "64f75284ef8f606073e988f6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mahaiza",
        fokontany: "miandrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8908232,
          lng: 46.7792927,
        },
        _id: "64f75284ef8f606073e988f7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mahaiza",
        fokontany: "ambalamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9037264,
          lng: 46.83012739999999,
        },
        _id: "64f75284ef8f606073e988f8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mahaiza",
        fokontany: "ambohimanamora",
        __v: 0,
      },
      {
        coords: {
          lat: -19.934725,
          lng: 46.8543525,
        },
        _id: "64f75284ef8f606073e988f9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mahaiza",
        fokontany: "ambohimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9668582,
          lng: 46.83497149999999,
        },
        _id: "64f75284ef8f606073e988fa",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mahaiza",
        fokontany: "mahazina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9424216,
          lng: 46.8010727,
        },
        _id: "64f75284ef8f606073e988fb",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mahaiza",
        fokontany: "ankafotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9559228,
          lng: 46.7671967,
        },
        _id: "64f75284ef8f606073e988fc",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mahaiza",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9152679,
          lng: 46.7502673,
        },
        _id: "64f75284ef8f606073e988fd",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mahaiza",
        fokontany: "marotsiraka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8935844,
          lng: 46.7333436,
        },
        _id: "64f75284ef8f606073e988fe",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "mahaiza",
        fokontany: "ambohitrananana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9718634,
          lng: 46.6319212,
        },
        _id: "64f75284ef8f606073e988ff",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "anosiarivo manapa",
        fokontany: "anosiarivo manapa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9470807,
          lng: 46.612626,
        },
        _id: "64f75284ef8f606073e98900",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "anosiarivo manapa",
        fokontany: "soafierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9387714,
          lng: 46.6946822,
        },
        _id: "64f75284ef8f606073e98901",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "anosiarivo manapa",
        fokontany: "anosiarivo ambatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0606833,
          lng: 46.612626,
        },
        _id: "64f75284ef8f606073e98902",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "anosiarivo manapa",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0469487,
          lng: 46.660878,
        },
        _id: "64f75284ef8f606073e98903",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "anosiarivo manapa",
        fokontany: "antsentsindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0835127,
          lng: 46.7188421,
        },
        _id: "64f75284ef8f606073e98904",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "anosiarivo manapa",
        fokontany: "bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0631765,
          lng: 46.7768732,
        },
        _id: "64f75284ef8f606073e98905",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "anosiarivo manapa",
        fokontany: "antanimenana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0884821,
          lng: 46.9076886,
        },
        _id: "64f75284ef8f606073e98906",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alakamisy marososona",
        fokontany: "soamahazina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0058958,
          lng: 46.9173921,
        },
        _id: "64f75284ef8f606073e98907",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alakamisy marososona",
        fokontany: "vatolahy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0197914,
          lng: 46.8688931,
        },
        _id: "64f75284ef8f606073e98908",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alakamisy marososona",
        fokontany: "laobatomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0807599,
          lng: 46.83497149999999,
        },
        _id: "64f75284ef8f606073e98909",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alakamisy marososona",
        fokontany: "ranomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0734846,
          lng: 46.8737409,
        },
        _id: "64f75284ef8f606073e9890a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alakamisy marososona",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0782559,
          lng: 46.9368047,
        },
        _id: "64f75284ef8f606073e9890b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alakamisy marososona",
        fokontany: "antanety tsararano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8085,
          lng: 46.617359,
        },
        _id: "64f75284ef8f606073e9890c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimanambola",
        fokontany: "ambohimanambola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7542751,
          lng: 46.5066359,
        },
        _id: "64f75284ef8f606073e9890d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimanambola",
        fokontany: "andranomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7949368,
          lng: 46.64157160000001,
        },
        _id: "64f75284ef8f606073e9890e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimanambola",
        fokontany: "ambilomaro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8182012,
          lng: 46.4681501,
        },
        _id: "64f75284ef8f606073e9890f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimanambola",
        fokontany: "ambalamasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7840622,
          lng: 46.574058,
        },
        _id: "64f75284ef8f606073e98910",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimanambola",
        fokontany: "vinanisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8258617,
          lng: 46.6657058,
        },
        _id: "64f75284ef8f606073e98911",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimanambola",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8338898,
          lng: 46.5981595,
        },
        _id: "64f75284ef8f606073e98912",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimanambola",
        fokontany: "soavinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8676576,
          lng: 46.5066359,
        },
        _id: "64f75284ef8f606073e98913",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimanambola",
        fokontany: "ambalavato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8575411,
          lng: 46.660878,
        },
        _id: "64f75284ef8f606073e98914",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimanambola",
        fokontany: "faravohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8775604,
          lng: 46.61744909999999,
        },
        _id: "64f75284ef8f606073e98915",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimanambola",
        fokontany: "masinierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9012429,
          lng: 46.6801919,
        },
        _id: "64f75284ef8f606073e98916",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimanambola",
        fokontany: "ambohijanakony",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9236879,
          lng: 46.5355198,
        },
        _id: "64f75284ef8f606073e98917",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimanambola",
        fokontany: "tsinjoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.65699,
          lng: 46.729889,
        },
        _id: "64f75284ef8f606073e98918",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimasina",
        fokontany: "ambohimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5317197,
          lng: 46.6946822,
        },
        _id: "64f75284ef8f606073e98919",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimasina",
        fokontany: "ambohitraivo ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5300037,
          lng: 46.7526854,
        },
        _id: "64f75284ef8f606073e9891a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimasina",
        fokontany: "antokomaro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5588274,
          lng: 46.7381783,
        },
        _id: "64f75284ef8f606073e9891b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimasina",
        fokontany: "talatan'antoby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6081898,
          lng: 46.7768732,
        },
        _id: "64f75284ef8f606073e9891c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimasina",
        fokontany: "andranomanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6008173,
          lng: 46.7043448,
        },
        _id: "64f75284ef8f606073e9891d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimasina",
        fokontany: "antsomangana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6275503,
          lng: 46.7623591,
        },
        _id: "64f75284ef8f606073e9891e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimasina",
        fokontany: "ambohijanahary sud",
        __v: 0,
      },
      {
        coords: {
          lat: -19.63867,
          lng: 46.7043448,
        },
        _id: "64f75284ef8f606073e9891f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimasina",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.654714,
          lng: 46.6946822,
        },
        _id: "64f75284ef8f606073e98920",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimasina",
        fokontany: "belanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6843754,
          lng: 46.7623591,
        },
        _id: "64f75284ef8f606073e98921",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimasina",
        fokontany: "amboanjobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6987477,
          lng: 46.6995133,
        },
        _id: "64f75284ef8f606073e98922",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "ambohimasina",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.65337,
          lng: 46.61977,
        },
        _id: "64f75284ef8f606073e98923",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "inanantonana",
        fokontany: "inanantonana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5703923,
          lng: 46.6657058,
        },
        _id: "64f75284ef8f606073e98924",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "inanantonana",
        fokontany: "androfia",
        __v: 0,
      },
      {
        coords: {
          lat: -19.596703,
          lng: 46.6270967,
        },
        _id: "64f75284ef8f606073e98925",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "inanantonana",
        fokontany: "amparihimboahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6069634,
          lng: 46.5981595,
        },
        _id: "64f75284ef8f606073e98926",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "inanantonana",
        fokontany: "bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6111034,
          lng: 46.6753627,
        },
        _id: "64f75284ef8f606073e98927",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "inanantonana",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6818019,
          lng: 46.6270967,
        },
        _id: "64f75284ef8f606073e98928",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "inanantonana",
        fokontany: "antanety sud",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1987686,
          lng: 46.8835092,
        },
        _id: "64f75284ef8f606073e98929",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alarobia bemaha",
        fokontany: "alarobia bemaha",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1422143,
          lng: 46.91254010000001,
        },
        _id: "64f75284ef8f606073e9892a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alarobia bemaha",
        fokontany: "ankofafa ambolo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1436021,
          lng: 46.8543525,
        },
        _id: "64f75284ef8f606073e9892b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alarobia bemaha",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1683061,
          lng: 46.88101349999999,
        },
        _id: "64f75284ef8f606073e9892c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alarobia bemaha",
        fokontany: "ambohijafy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1835164,
          lng: 46.9076886,
        },
        _id: "64f75284ef8f606073e9892d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alarobia bemaha",
        fokontany: "fisaorana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1947069,
          lng: 46.83497149999999,
        },
        _id: "64f75284ef8f606073e9892e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alarobia bemaha",
        fokontany: "analalava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2287654,
          lng: 46.8688931,
        },
        _id: "64f75284ef8f606073e9892f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alarobia bemaha",
        fokontany: "andranovorilava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2457947,
          lng: 46.815598,
        },
        _id: "64f75284ef8f606073e98930",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "alarobia bemaha",
        fokontany: "imalo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1220974,
          lng: 46.6898516,
        },
        _id: "64f75284ef8f606073e98931",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "andrembesoa",
        fokontany: "sakelo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.12388,
          lng: 46.423859,
        },
        _id: "64f75284ef8f606073e98932",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "andrembesoa",
        fokontany: "lazarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1835857,
          lng: 46.7671967,
        },
        _id: "64f75284ef8f606073e98933",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "andrembesoa",
        fokontany: "vatogaga",
        __v: 0,
      },
      {
        coords: {
          lat: -20.166667,
          lng: 46.65,
        },
        _id: "64f75284ef8f606073e98934",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "andrembesoa",
        fokontany: "antananolofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1761535,
          lng: 46.52589,
        },
        _id: "64f75284ef8f606073e98935",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "andrembesoa",
        fokontany: "maditsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2003994,
          lng: 46.574058,
        },
        _id: "64f75284ef8f606073e98936",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "andrembesoa",
        fokontany: "laondany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2423973,
          lng: 46.6801919,
        },
        _id: "64f75284ef8f606073e98937",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "betafo",
        commune: "andrembesoa",
        fokontany: "boina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3954334,
          lng: 47.4220818,
        },
        _id: "64f75284ef8f606073e98938",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambatolampy",
        fokontany: "andafiatsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3682463,
          lng: 47.4269822,
        },
        _id: "64f75284ef8f606073e98939",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambatolampy",
        fokontany: "haute ville",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3764259,
          lng: 47.4637499,
        },
        _id: "64f75284ef8f606073e9893a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambatolampy",
        fokontany: "ambatomena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3748255,
          lng: 47.4208568,
        },
        _id: "64f75284ef8f606073e9893b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambatolampy",
        fokontany: "ambodiriana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3724719,
          lng: 47.4416862,
        },
        _id: "64f75284ef8f606073e9893c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambatolampy",
        fokontany: "ambanimaso I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3921102,
          lng: 47.4478141,
        },
        _id: "64f75284ef8f606073e9893d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambatolampy",
        fokontany: "ambanimaso II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3895598,
          lng: 47.4306578,
        },
        _id: "64f75284ef8f606073e9893e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambatolampy",
        fokontany: "bemasoandro est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3901303,
          lng: 47.4367844,
        },
        _id: "64f75284ef8f606073e9893f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambatolampy",
        fokontany: "ankodondona I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4061097,
          lng: 47.4343337,
        },
        _id: "64f75284ef8f606073e98940",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambatolampy",
        fokontany: "ankodondona II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.433333,
          lng: 47.48333299999999,
        },
        _id: "64f75284ef8f606073e98941",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "morarano",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4078561,
          lng: 47.4735591,
        },
        _id: "64f75284ef8f606073e98942",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "morarano",
        fokontany: "ambatolampy bas",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4212892,
          lng: 47.4539427,
        },
        _id: "64f75284ef8f606073e98943",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "morarano",
        fokontany: "fierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4403637,
          lng: 47.4539427,
        },
        _id: "64f75284ef8f606073e98944",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "morarano",
        fokontany: "andohafarihy manampisoa andran",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4364733,
          lng: 47.4735591,
        },
        _id: "64f75284ef8f606073e98945",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "morarano",
        fokontany: "andriamingodana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4384915,
          lng: 47.5054514,
        },
        _id: "64f75284ef8f606073e98946",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "morarano",
        fokontany: "belambo est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4437996,
          lng: 47.4907295,
        },
        _id: "64f75284ef8f606073e98947",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "morarano",
        fokontany: "ankorompotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4481306,
          lng: 47.41473209999999,
        },
        _id: "64f75284ef8f606073e98948",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambohipihaonana",
        fokontany: "ambohipihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4178539,
          lng: 47.4171819,
        },
        _id: "64f75284ef8f606073e98949",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambohipihaonana",
        fokontany: "soavina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4272911,
          lng: 47.37555099999999,
        },
        _id: "64f75284ef8f606073e9894a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambohipihaonana",
        fokontany: "marohisana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4251789,
          lng: 47.4343337,
        },
        _id: "64f75284ef8f606073e9894b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambohipihaonana",
        fokontany: "antsiravana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4374471,
          lng: 47.4024848,
        },
        _id: "64f75284ef8f606073e9894c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambohipihaonana",
        fokontany: "mandrosohasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4379721,
          lng: 47.3877919,
        },
        _id: "64f75284ef8f606073e9894d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambohipihaonana",
        fokontany: "ambondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4700286,
          lng: 47.4245319,
        },
        _id: "64f75284ef8f606073e9894e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambohipihaonana",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4650938,
          lng: 47.4735591,
        },
        _id: "64f75284ef8f606073e9894f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambohipihaonana",
        fokontany: "sahamadio andranofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.485226,
          lng: 47.4441373,
        },
        _id: "64f75284ef8f606073e98950",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambohipihaonana",
        fokontany: "lovainjafy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.500428,
          lng: 47.4637499,
        },
        _id: "64f75284ef8f606073e98951",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambohipihaonana",
        fokontany: "mananjarasoa andakana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3259873,
          lng: 47.4314255,
        },
        _id: "64f75284ef8f606073e98952",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "manjakatompo",
        fokontany: "manjakatompo firaisana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2984477,
          lng: 47.4245319,
        },
        _id: "64f75284ef8f606073e98953",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "manjakatompo",
        fokontany: "tsaramiafara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3214114,
          lng: 47.404934,
        },
        _id: "64f75284ef8f606073e98954",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "manjakatompo",
        fokontany: "ambodivona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3365662,
          lng: 47.4245319,
        },
        _id: "64f75284ef8f606073e98955",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "manjakatompo",
        fokontany: "antanimbarilehibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3404675,
          lng: 47.404934,
        },
        _id: "64f75284ef8f606073e98956",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "manjakatompo",
        fokontany: "ambohipihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3517257,
          lng: 47.4441373,
        },
        _id: "64f75284ef8f606073e98957",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "manjakatompo",
        fokontany: "miadamanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4325826,
          lng: 47.50584629999999,
        },
        _id: "64f75284ef8f606073e98958",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambatondrakalavao",
        fokontany: "ambatondrakalavao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4317695,
          lng: 47.5152684,
        },
        _id: "64f75284ef8f606073e98959",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambatondrakalavao",
        fokontany: "ambodirano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4802907,
          lng: 47.4931828,
        },
        _id: "64f75284ef8f606073e9895a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambatondrakalavao",
        fokontany: "bezezika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4792272,
          lng: 47.5226323,
        },
        _id: "64f75284ef8f606073e9895b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambatondrakalavao",
        fokontany: "ambohipoloalina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4980555,
          lng: 47.5299973,
        },
        _id: "64f75284ef8f606073e9895c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambatondrakalavao",
        fokontany: "vatovandana II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5022084,
          lng: 47.5029975,
        },
        _id: "64f75284ef8f606073e9895d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambatondrakalavao",
        fokontany: "ampangabe sud",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2934213,
          lng: 47.4735591,
        },
        _id: "64f75284ef8f606073e9895e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andriambilany",
        fokontany: "ankadilalana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2510875,
          lng: 47.4171819,
        },
        _id: "64f75284ef8f606073e9895f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andriambilany",
        fokontany: "behorimo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.261458,
          lng: 47.4367844,
        },
        _id: "64f75284ef8f606073e98960",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andriambilany",
        fokontany: "ambodivona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2782779,
          lng: 47.4539427,
        },
        _id: "64f75284ef8f606073e98961",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andriambilany",
        fokontany: "miarintsoa vaovao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2945338,
          lng: 47.4441373,
        },
        _id: "64f75284ef8f606073e98962",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andriambilany",
        fokontany: "imerimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3183163,
          lng: 47.4732673,
        },
        _id: "64f75284ef8f606073e98963",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andriambilany",
        fokontany: "andriambilany",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3152982,
          lng: 47.48337,
        },
        _id: "64f75284ef8f606073e98964",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andriambilany",
        fokontany: "ambondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3183163,
          lng: 47.4732673,
        },
        _id: "64f75284ef8f606073e98965",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andriambilany",
        fokontany: "amboniriana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3568068,
          lng: 47.4784643,
        },
        _id: "64f75284ef8f606073e98966",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andriambilany",
        fokontany: "lohanandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3975221,
          lng: 47.51539950000001,
        },
        _id: "64f75284ef8f606073e98967",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "belambo firaisana",
        fokontany: "belambo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3035183,
          lng: 47.5422745,
        },
        _id: "64f75284ef8f606073e98968",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "belambo firaisana",
        fokontany: "alarobia vaovao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3298961,
          lng: 47.5177229,
        },
        _id: "64f75284ef8f606073e98969",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "belambo firaisana",
        fokontany: "andriatsivalana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3579646,
          lng: 47.5324525,
        },
        _id: "64f75284ef8f606073e9896a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "belambo firaisana",
        fokontany: "ambondrona est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3686101,
          lng: 47.5029975,
        },
        _id: "64f75284ef8f606073e9896b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "belambo firaisana",
        fokontany: "ambohipeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3851475,
          lng: 47.485823,
        },
        _id: "64f75284ef8f606073e9896c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "belambo firaisana",
        fokontany: "miadana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3806909,
          lng: 47.5201775,
        },
        _id: "64f75284ef8f606073e9896d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "belambo firaisana",
        fokontany: "ankadilalana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3992293,
          lng: 47.5349078,
        },
        _id: "64f75284ef8f606073e9896e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "belambo firaisana",
        fokontany: "ambahondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4067736,
          lng: 47.5029975,
        },
        _id: "64f75284ef8f606073e9896f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "belambo firaisana",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3996019,
          lng: 47.3667721,
        },
        _id: "64f75284ef8f606073e98970",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andravola vohipeno",
        fokontany: "andravola vohipeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4079705,
          lng: 47.3828952,
        },
        _id: "64f75284ef8f606073e98971",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andravola vohipeno",
        fokontany: "ambinanibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4107944,
          lng: 47.3926891,
        },
        _id: "64f75284ef8f606073e98972",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andravola vohipeno",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4183839,
          lng: 47.4024848,
        },
        _id: "64f75284ef8f606073e98973",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andravola vohipeno",
        fokontany: "fihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4213759,
          lng: 47.363313,
        },
        _id: "64f75284ef8f606073e98974",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andravola vohipeno",
        fokontany: "ambatomirahavavy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3796523,
          lng: 47.37555099999999,
        },
        _id: "64f75284ef8f606073e98975",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsiafajavona ankaratra",
        fokontany: "tsiafajavona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3465793,
          lng: 47.3682078,
        },
        _id: "64f75284ef8f606073e98976",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsiafajavona ankaratra",
        fokontany: "ankaratra avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3583857,
          lng: 47.3926891,
        },
        _id: "64f75284ef8f606073e98977",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsiafajavona ankaratra",
        fokontany: "amparihimena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3626118,
          lng: 47.4073834,
        },
        _id: "64f75284ef8f606073e98978",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsiafajavona ankaratra",
        fokontany: "ankadivory",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3554952,
          lng: 47.3412918,
        },
        _id: "64f75284ef8f606073e98979",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsiafajavona ankaratra",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3757648,
          lng: 47.3951378,
        },
        _id: "64f75284ef8f606073e9897a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsiafajavona ankaratra",
        fokontany: "tsangambatonintaolo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3709916,
          lng: 47.2630701,
        },
        _id: "64f75284ef8f606073e9897b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsiafajavona ankaratra",
        fokontany: "anosiarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3903246,
          lng: 47.3877919,
        },
        _id: "64f75284ef8f606073e9897c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsiafajavona ankaratra",
        fokontany: "ankofafa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3976449,
          lng: 47.404934,
        },
        _id: "64f75284ef8f606073e9897d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsiafajavona ankaratra",
        fokontany: "ambolokotona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3961924,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e9897e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsiafajavona ankaratra",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4126349,
          lng: 47.3412918,
        },
        _id: "64f75284ef8f606073e9897f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsiafajavona ankaratra",
        fokontany: "andranomaria",
        __v: 0,
      },
      {
        coords: {
          lat: -19.463562,
          lng: 47.5594675,
        },
        _id: "64f75284ef8f606073e98980",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambodifarihy fenomanana",
        fokontany: "ambodifarihy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4416882,
          lng: 47.5914122,
        },
        _id: "64f75284ef8f606073e98981",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambodifarihy fenomanana",
        fokontany: "ambohiboahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.447827,
          lng: 47.5545546,
        },
        _id: "64f75284ef8f606073e98982",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambodifarihy fenomanana",
        fokontany: "andohafarihy bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4669815,
          lng: 47.59632850000001,
        },
        _id: "64f75284ef8f606073e98983",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambodifarihy fenomanana",
        fokontany: "lambamaintso fierenantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4877061,
          lng: 47.5520984,
        },
        _id: "64f75284ef8f606073e98984",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "ambodifarihy fenomanana",
        fokontany: "mahazina fihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3118387,
          lng: 47.363313,
        },
        _id: "64f75284ef8f606073e98985",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "sabotsy namatoana",
        fokontany: "soantenaina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2756815,
          lng: 47.3327305,
        },
        _id: "64f75284ef8f606073e98986",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "sabotsy namatoana",
        fokontany: "ankoratsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3017226,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e98987",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "sabotsy namatoana",
        fokontany: "bethlehema",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2928301,
          lng: 47.404934,
        },
        _id: "64f75284ef8f606073e98988",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "sabotsy namatoana",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2970098,
          lng: 47.37799889999999,
        },
        _id: "64f75284ef8f606073e98989",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "sabotsy namatoana",
        fokontany: "siona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3195478,
          lng: 47.2826144,
        },
        _id: "64f75284ef8f606073e9898a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "sabotsy namatoana",
        fokontany: "soamitazana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3222272,
          lng: 47.3828952,
        },
        _id: "64f75284ef8f606073e9898b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "sabotsy namatoana",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3233084,
          lng: 47.3535246,
        },
        _id: "64f75284ef8f606073e9898c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "sabotsy namatoana",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3246495,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e9898d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "sabotsy namatoana",
        fokontany: "tsiazompaniry",
        __v: 0,
      },
      {
        coords: {
          lat: -19.399268,
          lng: 47.5665077,
        },
        _id: "64f75284ef8f606073e9898e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antanimasaka",
        fokontany: "antanimasaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3759443,
          lng: 47.5619241,
        },
        _id: "64f75284ef8f606073e9898f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antanimasaka",
        fokontany: "ankalapatana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3928015,
          lng: 47.57912349999999,
        },
        _id: "64f75284ef8f606073e98990",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antanimasaka",
        fokontany: "andrangikely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4113175,
          lng: 47.5520984,
        },
        _id: "64f75284ef8f606073e98991",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antanimasaka",
        fokontany: "ambohidrafy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4332315,
          lng: 47.5619241,
        },
        _id: "64f75284ef8f606073e98992",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antanimasaka",
        fokontany: "angodongodona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2098858,
          lng: 47.4563943,
        },
        _id: "64f75284ef8f606073e98993",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "behenjy centre",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1341862,
          lng: 47.48337,
        },
        _id: "64f75284ef8f606073e98994",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "ankofika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1588202,
          lng: 47.5029975,
        },
        _id: "64f75284ef8f606073e98995",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "tsinjony",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1725659,
          lng: 47.4968631,
        },
        _id: "64f75284ef8f606073e98996",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "morarano ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1695127,
          lng: 47.4735591,
        },
        _id: "64f75284ef8f606073e98997",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "ambohidrano nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1896797,
          lng: 47.485823,
        },
        _id: "64f75284ef8f606073e98998",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "ambohikambana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1891021,
          lng: 47.5005436,
        },
        _id: "64f75284ef8f606073e98999",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "mandady",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2011823,
          lng: 47.4760116,
        },
        _id: "64f75284ef8f606073e9899a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "ambohidrano sud",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2081681,
          lng: 47.5005436,
        },
        _id: "64f75284ef8f606073e9899b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "soaloka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.220244,
          lng: 47.4760116,
        },
        _id: "64f75284ef8f606073e9899c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "tsarafara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2306266,
          lng: 47.4539427,
        },
        _id: "64f75284ef8f606073e9899d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2252646,
          lng: 47.5103597,
        },
        _id: "64f75284ef8f606073e9899e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "amboanjobe II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2446207,
          lng: 47.5029975,
        },
        _id: "64f75284ef8f606073e9899f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "amboanjobe I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2359406,
          lng: 47.4809171,
        },
        _id: "64f75284ef8f606073e989a0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "ravinilo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2516397,
          lng: 47.485823,
        },
        _id: "64f75284ef8f606073e989a1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "kianja nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2636918,
          lng: 47.5029975,
        },
        _id: "64f75284ef8f606073e989a2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "ambatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2524873,
          lng: 47.4637499,
        },
        _id: "64f75284ef8f606073e989a3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2530161,
          lng: 47.5324525,
        },
        _id: "64f75284ef8f606073e989a4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "bemasoandro est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2707077,
          lng: 47.485823,
        },
        _id: "64f75284ef8f606073e989a5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "antsahalava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2720942,
          lng: 47.5324525,
        },
        _id: "64f75284ef8f606073e989a6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "ambato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3029608,
          lng: 47.5152684,
        },
        _id: "64f75284ef8f606073e989a7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "ampanataovana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2827644,
          lng: 47.5029975,
        },
        _id: "64f75284ef8f606073e989a8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "behenjy",
        fokontany: "fieferana sud",
        __v: 0,
      },
      {
        coords: {
          lat: -19.54674,
          lng: 47.589802,
        },
        _id: "64f75284ef8f606073e989a9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antsampandrano",
        fokontany: "antsampandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4844574,
          lng: 47.640596,
        },
        _id: "64f75284ef8f606073e989aa",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antsampandrano",
        fokontany: "ampitankely lovasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5131373,
          lng: 47.640596,
        },
        _id: "64f75284ef8f606073e989ab",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antsampandrano",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4951043,
          lng: 47.6110802,
        },
        _id: "64f75284ef8f606073e989ac",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antsampandrano",
        fokontany: "ankafotra avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5294314,
          lng: 47.6307555,
        },
        _id: "64f75284ef8f606073e989ad",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antsampandrano",
        fokontany: "tanambe mantsikena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5085647,
          lng: 47.5914122,
        },
        _id: "64f75284ef8f606073e989ae",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antsampandrano",
        fokontany: "ankafotra sud",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5163578,
          lng: 47.5520984,
        },
        _id: "64f75284ef8f606073e989af",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antsampandrano",
        fokontany: "ambohimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5315706,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e989b0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antsampandrano",
        fokontany: "ankerana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5428051,
          lng: 47.56929450000001,
        },
        _id: "64f75284ef8f606073e989b1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antsampandrano",
        fokontany: "ambatotsipihina ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5552821,
          lng: 47.62091700000001,
        },
        _id: "64f75284ef8f606073e989b2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antsampandrano",
        fokontany: "antanimbaritsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5446492,
          lng: 47.6504382,
        },
        _id: "64f75284ef8f606073e989b3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antsampandrano",
        fokontany: "ambolotara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5628039,
          lng: 47.5889542,
        },
        _id: "64f75284ef8f606073e989b4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antsampandrano",
        fokontany: "antseva",
        __v: 0,
      },
      {
        coords: {
          lat: -19.587853,
          lng: 47.6012453,
        },
        _id: "64f75284ef8f606073e989b5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antsampandrano",
        fokontany: "ambatotsipihina est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4400354,
          lng: 47.6356755,
        },
        _id: "64f75284ef8f606073e989b6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antanamalaza",
        fokontany: "antanamalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3967433,
          lng: 47.6012453,
        },
        _id: "64f75284ef8f606073e989b7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antanamalaza",
        fokontany: "andrangy nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4158476,
          lng: 47.6012453,
        },
        _id: "64f75284ef8f606073e989b8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antanamalaza",
        fokontany: "sud andrangy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4501441,
          lng: 47.62091700000001,
        },
        _id: "64f75284ef8f606073e989b9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antanamalaza",
        fokontany: "androhifataka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.45,
          lng: 47.7,
        },
        _id: "64f75284ef8f606073e989ba",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antanamalaza",
        fokontany: "andriambe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4411664,
          lng: 47.6479775,
        },
        _id: "64f75284ef8f606073e989bb",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antanamalaza",
        fokontany: "ambohimasina bas",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4422965,
          lng: 47.6602824,
        },
        _id: "64f75284ef8f606073e989bc",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antanamalaza",
        fokontany: "mahazina fanantenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5188658,
          lng: 47.7440315,
        },
        _id: "64f75284ef8f606073e989bd",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antakasina",
        fokontany: "antakasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4743883,
          lng: 47.7391014,
        },
        _id: "64f75284ef8f606073e989be",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antakasina",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -19.476062,
          lng: 47.6947512,
        },
        _id: "64f75284ef8f606073e989bf",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antakasina",
        fokontany: "ambololondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4986047,
          lng: 47.7736217,
        },
        _id: "64f75284ef8f606073e989c0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antakasina",
        fokontany: "ankazomena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5340028,
          lng: 47.6799761,
        },
        _id: "64f75284ef8f606073e989c1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antakasina",
        fokontany: "andravoravo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5560566,
          lng: 47.7736217,
        },
        _id: "64f75284ef8f606073e989c2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "antakasina",
        fokontany: "andavakitsikitsika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.636681,
          lng: 47.680641,
        },
        _id: "64f75284ef8f606073e989c3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsinjoarivo",
        fokontany: "tsinjoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.583048,
          lng: 47.7341718,
        },
        _id: "64f75284ef8f606073e989c4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsinjoarivo",
        fokontany: "tamiana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5795374,
          lng: 47.6553601,
        },
        _id: "64f75284ef8f606073e989c5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsinjoarivo",
        fokontany: "andavabato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6051325,
          lng: 47.6528991,
        },
        _id: "64f75284ef8f606073e989c6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsinjoarivo",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6056802,
          lng: 47.813101,
        },
        _id: "64f75284ef8f606073e989c7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsinjoarivo",
        fokontany: "soamanandrariny",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5994605,
          lng: 47.6332155,
        },
        _id: "64f75284ef8f606073e989c8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsinjoarivo",
        fokontany: "antanjondava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6307286,
          lng: 47.6504382,
        },
        _id: "64f75284ef8f606073e989c9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsinjoarivo",
        fokontany: "angodongodona nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6462369,
          lng: 47.66274370000001,
        },
        _id: "64f75284ef8f606073e989ca",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsinjoarivo",
        fokontany: "ambatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6746826,
          lng: 47.67012829999999,
        },
        _id: "64f75284ef8f606073e989cb",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsinjoarivo",
        fokontany: "ankaditapaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6674325,
          lng: 47.6947512,
        },
        _id: "64f75284ef8f606073e989cc",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsinjoarivo",
        fokontany: "ambohikambana est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6596448,
          lng: 47.7341718,
        },
        _id: "64f75284ef8f606073e989cd",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsinjoarivo",
        fokontany: "antsampandrano lovasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6823512,
          lng: 47.813101,
        },
        _id: "64f75284ef8f606073e989ce",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsinjoarivo",
        fokontany: "ankazomena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6523734,
          lng: 47.7588245,
        },
        _id: "64f75284ef8f606073e989cf",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsinjoarivo",
        fokontany: "antandrokomby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6996351,
          lng: 47.7317071,
        },
        _id: "64f75284ef8f606073e989d0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "tsinjoarivo",
        fokontany: "andranotobaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6446971,
          lng: 47.6159983,
        },
        _id: "64f75284ef8f606073e989d1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andranovelona",
        fokontany: "andranovelona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6098103,
          lng: 47.6110802,
        },
        _id: "64f75284ef8f606073e989d2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andranovelona",
        fokontany: "morarano ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6232515,
          lng: 47.5914122,
        },
        _id: "64f75284ef8f606073e989d3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andranovelona",
        fokontany: "bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6503816,
          lng: 47.6356755,
        },
        _id: "64f75284ef8f606073e989d4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andranovelona",
        fokontany: "sahanamalona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.642371,
          lng: 47.5914122,
        },
        _id: "64f75284ef8f606073e989d5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andranovelona",
        fokontany: "andidy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6558006,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e989d6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andranovelona",
        fokontany: "antanetibe ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6821816,
          lng: 47.63813570000001,
        },
        _id: "64f75284ef8f606073e989d7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andranovelona",
        fokontany: "ambodivona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6767459,
          lng: 47.6110802,
        },
        _id: "64f75284ef8f606073e989d8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andranovelona",
        fokontany: "andranodobo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.695108,
          lng: 47.6332155,
        },
        _id: "64f75284ef8f606073e989d9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "ambatolampy",
        commune: "andranovelona",
        fokontany: "avaratsena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6513424,
          lng: 47.3266162,
        },
        _id: "64f75284ef8f606073e989da",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "antanifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5694759,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e989db",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5846973,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e989dc",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "sarodroa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6087338,
          lng: 47.221563,
        },
        _id: "64f75284ef8f606073e989dd",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "mananitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6027769,
          lng: 47.2581852,
        },
        _id: "64f75284ef8f606073e989de",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "ianaborona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.595379,
          lng: 47.2899454,
        },
        _id: "64f75284ef8f606073e989df",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "ankararana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6075142,
          lng: 47.3070552,
        },
        _id: "64f75284ef8f606073e989e0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "tokotanitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5882136,
          lng: 47.3143897,
        },
        _id: "64f75284ef8f606073e989e1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "ambohimanatrika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5874757,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e989e2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6065376,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e989e3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5934187,
          lng: 47.3486311,
        },
        _id: "64f75284ef8f606073e989e4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "tsarafara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5991148,
          lng: 47.3682078,
        },
        _id: "64f75284ef8f606073e989e5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "antanety",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6191162,
          lng: 47.3877919,
        },
        _id: "64f75284ef8f606073e989e6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "mahatsinjo centre",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6274743,
          lng: 47.37555099999999,
        },
        _id: "64f75284ef8f606073e989e7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "andrefaniala",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6058003,
          lng: 47.3584185,
        },
        _id: "64f75284ef8f606073e989e8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "ambohijanaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6161696,
          lng: 47.357195,
        },
        _id: "64f75284ef8f606073e989e9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6296355,
          lng: 47.3584185,
        },
        _id: "64f75284ef8f606073e989ea",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "andranomalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.625601,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e989eb",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "antemotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6291831,
          lng: 47.3241707,
        },
        _id: "64f75284ef8f606073e989ec",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "amboniandrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6253855,
          lng: 47.29483339999999,
        },
        _id: "64f75284ef8f606073e989ed",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "saonjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6306296,
          lng: 47.280171,
        },
        _id: "64f75284ef8f606073e989ee",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "ambatoboka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6420617,
          lng: 47.2703983,
        },
        _id: "64f75284ef8f606073e989ef",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "ambilona I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6380126,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e989f0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "mananetivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6227757,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e989f1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "anosilehibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.623721,
          lng: 47.1996022,
        },
        _id: "64f75284ef8f606073e989f2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "masimpieferana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6513424,
          lng: 47.3266162,
        },
        _id: "64f75284ef8f606073e989f3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "ankazondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6532541,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e989f4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "soamanandrariny",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6507537,
          lng: 47.2960554,
        },
        _id: "64f75284ef8f606073e989f5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "antanety I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6430129,
          lng: 47.2899454,
        },
        _id: "64f75284ef8f606073e989f6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "andohafarihy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6389556,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e989f7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "antsahamaina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6449074,
          lng: 47.3290618,
        },
        _id: "64f75284ef8f606073e989f8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "antanety nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6373949,
          lng: 47.3400687,
        },
        _id: "64f75284ef8f606073e989f9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "fierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6500251,
          lng: 47.3425149,
        },
        _id: "64f75284ef8f606073e989fa",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "antsahondra est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6472781,
          lng: 47.3535246,
        },
        _id: "64f75284ef8f606073e989fb",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "sahavato ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6494011,
          lng: 47.3853435,
        },
        _id: "64f75284ef8f606073e989fc",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "antsevakely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6639519,
          lng: 47.37799889999999,
        },
        _id: "64f75284ef8f606073e989fd",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "sahavato haut",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6830284,
          lng: 47.37799889999999,
        },
        _id: "64f75284ef8f606073e989fe",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "morarano ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6665891,
          lng: 47.3461846,
        },
        _id: "64f75284ef8f606073e989ff",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "sahavato centre",
        __v: 0,
      },
      {
        coords: {
          lat: -19.663972,
          lng: 47.3290618,
        },
        _id: "64f75284ef8f606073e98a00",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "antobiniaro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.658734,
          lng: 47.29483339999999,
        },
        _id: "64f75284ef8f606073e98a01",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "andohavary II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6773042,
          lng: 47.2606276,
        },
        _id: "64f75284ef8f606073e98a02",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "ambalavao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6856319,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e98a03",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "ambatomaintikely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6741756,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e98a04",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "antanikatsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6910908,
          lng: 47.2264444,
        },
        _id: "64f75284ef8f606073e98a05",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "morarano iv",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7027374,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e98a06",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "morarano ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7189103,
          lng: 47.1996022,
        },
        _id: "64f75284ef8f606073e98a07",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "ambatovaventy ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7215523,
          lng: 47.216682,
        },
        _id: "64f75284ef8f606073e98a08",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "ambatovaventy est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7275204,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e98a09",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "anosimboahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7104144,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e98a0a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "andohariana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6958893,
          lng: 47.2752844,
        },
        _id: "64f75284ef8f606073e98a0b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "morarano III",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6780287,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e98a0c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "mahalavolona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6999503,
          lng: 47.2972775,
        },
        _id: "64f75284ef8f606073e98a0d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "ambodiriana atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6925716,
          lng: 47.3290618,
        },
        _id: "64f75284ef8f606073e98a0e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "ambatomiankina nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6825604,
          lng: 47.3437381,
        },
        _id: "64f75284ef8f606073e98a0f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6885185,
          lng: 47.3559715,
        },
        _id: "64f75284ef8f606073e98a10",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "andriatsilahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.723803,
          lng: 47.3461846,
        },
        _id: "64f75284ef8f606073e98a11",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "angavo est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7021054,
          lng: 47.3290618,
        },
        _id: "64f75284ef8f606073e98a12",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "anondrilahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7152101,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e98a13",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "bepaiso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7235444,
          lng: 47.3046106,
        },
        _id: "64f75284ef8f606073e98a14",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antanifotsy",
        fokontany: "antanetilava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7380753,
          lng: 47.2972775,
        },
        _id: "64f75284ef8f606073e98a15",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatolahy",
        fokontany: "ambatolahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7332599,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e98a16",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatolahy",
        fokontany: "morarano est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7535214,
          lng: 47.2606276,
        },
        _id: "64f75284ef8f606073e98a17",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatolahy",
        fokontany: "maromena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7612142,
          lng: 47.31928,
        },
        _id: "64f75284ef8f606073e98a18",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatolahy",
        fokontany: "amboatavo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7536719,
          lng: 47.2044815,
        },
        _id: "64f75284ef8f606073e98a19",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatolahy",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7713686,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e98a1a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatolahy",
        fokontany: "ambatomalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7724155,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e98a1b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatolahy",
        fokontany: "ambolavola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7704601,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e98a1c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatolahy",
        fokontany: "ambohimanatrika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.565559,
          lng: 47.35861999999999,
        },
        _id: "64f75284ef8f606073e98a1d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "ampitatafika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.530436,
          lng: 47.2386501,
        },
        _id: "64f75284ef8f606073e98a1e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "ambodivona tsarazazamandimby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5348901,
          lng: 47.29483339999999,
        },
        _id: "64f75284ef8f606073e98a1f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "ambatomainty tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5436693,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e98a20",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "andaontany",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5463315,
          lng: 47.285058,
        },
        _id: "64f75284ef8f606073e98a21",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "andranomahery nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5487701,
          lng: 47.3302847,
        },
        _id: "64f75284ef8f606073e98a22",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "mananetivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5505993,
          lng: 47.2997218,
        },
        _id: "64f75284ef8f606073e98a23",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "andranomahery centre",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5389718,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e98a24",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "ambodivona kianjasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5491062,
          lng: 47.3437381,
        },
        _id: "64f75284ef8f606073e98a25",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "ambohitsarabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5561423,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e98a26",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "fenoarivo faravohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.565559,
          lng: 47.35861999999999,
        },
        _id: "64f75284ef8f606073e98a27",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "andakandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5592585,
          lng: 47.3253934,
        },
        _id: "64f75284ef8f606073e98a28",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "amboalefoka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5582076,
          lng: 47.3094999,
        },
        _id: "64f75284ef8f606073e98a29",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "antaninandro centre",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5566257,
          lng: 47.4024848,
        },
        _id: "64f75284ef8f606073e98a2a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "ambohimandroso atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5742103,
          lng: 47.2581852,
        },
        _id: "64f75284ef8f606073e98a2b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "miadanimerina mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5609798,
          lng: 47.3682078,
        },
        _id: "64f75284ef8f606073e98a2c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "amorona fierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5714341,
          lng: 47.3877919,
        },
        _id: "64f75284ef8f606073e98a2d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "masoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5732346,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e98a2e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "ambohibetazana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5705811,
          lng: 47.31928,
        },
        _id: "64f75284ef8f606073e98a2f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "tsimahabeharona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5661625,
          lng: 47.4024848,
        },
        _id: "64f75284ef8f606073e98a30",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "amboniandrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5816387,
          lng: 47.41473209999999,
        },
        _id: "64f75284ef8f606073e98a31",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "antoby tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5769518,
          lng: 47.3657603,
        },
        _id: "64f75284ef8f606073e98a32",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5985415,
          lng: 47.4318831,
        },
        _id: "64f75284ef8f606073e98a33",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "ambatoasana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5950247,
          lng: 47.3951378,
        },
        _id: "64f75284ef8f606073e98a34",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "ambonivary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6007171,
          lng: 47.41473209999999,
        },
        _id: "64f75284ef8f606073e98a35",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6226453,
          lng: 47.4245319,
        },
        _id: "64f75284ef8f606073e98a36",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ampitatafika",
        fokontany: "amboafotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.693489,
          lng: 47.41183100000001,
        },
        _id: "64f75284ef8f606073e98a37",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatomiady",
        fokontany: "ambatomiady",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6185473,
          lng: 47.4514912,
        },
        _id: "64f75284ef8f606073e98a38",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatomiady",
        fokontany: "fanovozantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6397335,
          lng: 47.48337,
        },
        _id: "64f75284ef8f606073e98a39",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatomiady",
        fokontany: "antsahanandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6371364,
          lng: 47.4662021,
        },
        _id: "64f75284ef8f606073e98a3a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatomiady",
        fokontany: "amboanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6376364,
          lng: 47.4514912,
        },
        _id: "64f75284ef8f606073e98a3b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatomiady",
        fokontany: "anivonisaina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6417288,
          lng: 47.4245319,
        },
        _id: "64f75284ef8f606073e98a3c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatomiady",
        fokontany: "antanetikely est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6474299,
          lng: 47.4441373,
        },
        _id: "64f75284ef8f606073e98a3d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatomiady",
        fokontany: "andriankely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6653394,
          lng: 47.4318831,
        },
        _id: "64f75284ef8f606073e98a3e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatomiady",
        fokontany: "ampangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6551076,
          lng: 47.404934,
        },
        _id: "64f75284ef8f606073e98a3f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatomiady",
        fokontany: "atsimoniala",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6789187,
          lng: 47.4539427,
        },
        _id: "64f75284ef8f606073e98a40",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatomiady",
        fokontany: "ialatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6740825,
          lng: 47.5029975,
        },
        _id: "64f75284ef8f606073e98a41",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatomiady",
        fokontany: "ambatolampikely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6772886,
          lng: 47.4073834,
        },
        _id: "64f75284ef8f606073e98a42",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatomiady",
        fokontany: "analambano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.711391,
          lng: 47.4343337,
        },
        _id: "64f75284ef8f606073e98a43",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatomiady",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7075602,
          lng: 47.4539427,
        },
        _id: "64f75284ef8f606073e98a44",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatomiady",
        fokontany: "ialakorovana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7066365,
          lng: 47.3853435,
        },
        _id: "64f75284ef8f606073e98a45",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatomiady",
        fokontany: "antambohomena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7250008,
          lng: 47.4073834,
        },
        _id: "64f75284ef8f606073e98a46",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatomiady",
        fokontany: "amboavahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7333441,
          lng: 47.4441373,
        },
        _id: "64f75284ef8f606073e98a47",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatomiady",
        fokontany: "analambato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7538694,
          lng: 47.4000357,
        },
        _id: "64f75284ef8f606073e98a48",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatomiady",
        fokontany: "ankazoavo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7915781,
          lng: 47.41473209999999,
        },
        _id: "64f75284ef8f606073e98a49",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "andranofito",
        fokontany: "andranofito centre",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7390703,
          lng: 47.3657603,
        },
        _id: "64f75284ef8f606073e98a4a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "andranofito",
        fokontany: "antambiazina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7619525,
          lng: 47.3461846,
        },
        _id: "64f75284ef8f606073e98a4b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "andranofito",
        fokontany: "andranokely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7829698,
          lng: 47.3853435,
        },
        _id: "64f75284ef8f606073e98a4c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "andranofito",
        fokontany: "ambohimiarambe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7989863,
          lng: 47.4318831,
        },
        _id: "64f75284ef8f606073e98a4d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "andranofito",
        fokontany: "andranofito est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8326406,
          lng: 47.4245319,
        },
        _id: "64f75284ef8f606073e98a4e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "andranofito",
        fokontany: "ambatovaventy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8345816,
          lng: 47.4637499,
        },
        _id: "64f75284ef8f606073e98a4f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "andranofito",
        fokontany: "ambohimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8670435,
          lng: 47.4441373,
        },
        _id: "64f75284ef8f606073e98a50",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "andranofito",
        fokontany: "andranomangarana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.518871,
          lng: 47.4367844,
        },
        _id: "64f75284ef8f606073e98a51",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "ambohimandroso A",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4250002,
          lng: 47.3058329,
        },
        _id: "64f75284ef8f606073e98a52",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "amboromanga tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4324617,
          lng: 47.31928,
        },
        _id: "64f75284ef8f606073e98a53",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "tsaratanimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4502183,
          lng: 47.3559715,
        },
        _id: "64f75284ef8f606073e98a54",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "isody",
        __v: 0,
      },
      {
        coords: {
          lat: -19.45,
          lng: 47.383333,
        },
        _id: "64f75284ef8f606073e98a55",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "ambalavao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4612782,
          lng: 47.4024848,
        },
        _id: "64f75284ef8f606073e98a56",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "ihazolava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4785454,
          lng: 47.363313,
        },
        _id: "64f75284ef8f606073e98a57",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "ambatobe bongatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4834363,
          lng: 47.404934,
        },
        _id: "64f75284ef8f606073e98a58",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "ambatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5053396,
          lng: 47.41473209999999,
        },
        _id: "64f75284ef8f606073e98a59",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "antsampandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4937441,
          lng: 47.3828952,
        },
        _id: "64f75284ef8f606073e98a5a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "kelilalina bas",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5050175,
          lng: 47.4012602,
        },
        _id: "64f75284ef8f606073e98a5b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "ankazomenavahatra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5041733,
          lng: 47.4478141,
        },
        _id: "64f75284ef8f606073e98a5c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "mahaketraka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5156457,
          lng: 47.3926891,
        },
        _id: "64f75284ef8f606073e98a5d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "antanety est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5195039,
          lng: 47.37310309999999,
        },
        _id: "64f75284ef8f606073e98a5e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "andalantsoavaly centre",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5265988,
          lng: 47.3975867,
        },
        _id: "64f75284ef8f606073e98a5f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "maromoka fonenantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5303427,
          lng: 47.4269822,
        },
        _id: "64f75284ef8f606073e98a60",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "ambohimandroso B",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5399888,
          lng: 47.37799889999999,
        },
        _id: "64f75284ef8f606073e98a61",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "maromoka centre",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5359804,
          lng: 47.3559715,
        },
        _id: "64f75284ef8f606073e98a62",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "andohanimaromoka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5356238,
          lng: 47.4122824,
        },
        _id: "64f75284ef8f606073e98a63",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "maromoka bas",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5409015,
          lng: 47.3975867,
        },
        _id: "64f75284ef8f606073e98a64",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "maromoka vavaraoly",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5500259,
          lng: 47.363313,
        },
        _id: "64f75284ef8f606073e98a65",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "tsarafara antatamo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.543231,
          lng: 47.4220818,
        },
        _id: "64f75284ef8f606073e98a66",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5623081,
          lng: 47.4220818,
        },
        _id: "64f75284ef8f606073e98a67",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohimandroso",
        fokontany: "ambohimandroso est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5140811,
          lng: 47.3461846,
        },
        _id: "64f75284ef8f606073e98a68",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatotsipihina",
        fokontany: "ambatotsipihina centre",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4280842,
          lng: 47.2630701,
        },
        _id: "64f75284ef8f606073e98a69",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatotsipihina",
        fokontany: "bongabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4427553,
          lng: 47.2972775,
        },
        _id: "64f75284ef8f606073e98a6a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatotsipihina",
        fokontany: "analafandriambe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.451251,
          lng: 47.3266162,
        },
        _id: "64f75284ef8f606073e98a6b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatotsipihina",
        fokontany: "ambatobe ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4628124,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e98a6c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatotsipihina",
        fokontany: "tsarafara andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4681562,
          lng: 47.2044815,
        },
        _id: "64f75284ef8f606073e98a6d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatotsipihina",
        fokontany: "marirana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4728782,
          lng: 47.3437381,
        },
        _id: "64f75284ef8f606073e98a6e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatotsipihina",
        fokontany: "ambatobe ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4833329,
          lng: 47.25,
        },
        _id: "64f75284ef8f606073e98a6f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatotsipihina",
        fokontany: "analalava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4769904,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e98a70",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatotsipihina",
        fokontany: "analamana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4877778,
          lng: 47.280171,
        },
        _id: "64f75284ef8f606073e98a71",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatotsipihina",
        fokontany: "ambatomandondona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5104093,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e98a72",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatotsipihina",
        fokontany: "bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4883312,
          lng: 47.3559715,
        },
        _id: "64f75284ef8f606073e98a73",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatotsipihina",
        fokontany: "ambatomikotrana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5055649,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e98a74",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatotsipihina",
        fokontany: "ambatotsipihina ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5217751,
          lng: 47.3070552,
        },
        _id: "64f75284ef8f606073e98a75",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambatotsipihina",
        fokontany: "sobaba bas",
        __v: 0,
      },
      {
        coords: {
          lat: -19.75839,
          lng: 47.569592,
        },
        _id: "64f75284ef8f606073e98a76",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "ambohitompoina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7040993,
          lng: 47.557011,
        },
        _id: "64f75284ef8f606073e98a77",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "antsahondra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7060882,
          lng: 47.4980899,
        },
        _id: "64f75284ef8f606073e98a78",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7056913,
          lng: 47.6037038,
        },
        _id: "64f75284ef8f606073e98a79",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "ampasika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7320302,
          lng: 47.57912349999999,
        },
        _id: "64f75284ef8f606073e98a7a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "vohitrambo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7285413,
          lng: 47.4931828,
        },
        _id: "64f75284ef8f606073e98a7b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "ambatodidy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7267484,
          lng: 47.5938703,
        },
        _id: "64f75284ef8f606073e98a7c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "ambohijafy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7438124,
          lng: 47.512814,
        },
        _id: "64f75284ef8f606073e98a7d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "ambatosira",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7390656,
          lng: 47.4637499,
        },
        _id: "64f75284ef8f606073e98a7e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "ambodiharana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.745974,
          lng: 47.4956363,
        },
        _id: "64f75284ef8f606073e98a7f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7594759,
          lng: 47.6159983,
        },
        _id: "64f75284ef8f606073e98a80",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "ambohitompoina atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7677169,
          lng: 47.4637499,
        },
        _id: "64f75284ef8f606073e98a81",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "ambondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7587699,
          lng: 47.483631,
        },
        _id: "64f75284ef8f606073e98a82",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "anjamanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7523891,
          lng: 47.5422745,
        },
        _id: "64f75284ef8f606073e98a83",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "sahanamalona bas",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7645625,
          lng: 47.5594675,
        },
        _id: "64f75284ef8f606073e98a84",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "andoharanofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7686463,
          lng: 47.5324525,
        },
        _id: "64f75284ef8f606073e98a85",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "ambohimandroso ilaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7906271,
          lng: 47.5422745,
        },
        _id: "64f75284ef8f606073e98a86",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "sahanamalona haut",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7920246,
          lng: 47.59632850000001,
        },
        _id: "64f75284ef8f606073e98a87",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "ampangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7963712,
          lng: 47.4637499,
        },
        _id: "64f75284ef8f606073e98a88",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "manendy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7982845,
          lng: 47.5029975,
        },
        _id: "64f75284ef8f606073e98a89",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "andohanilaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.816667,
          lng: 47.566667,
        },
        _id: "64f75284ef8f606073e98a8a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "analamanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8149097,
          lng: 47.675052,
        },
        _id: "64f75284ef8f606073e98a8b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "antamiana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8207417,
          lng: 47.4980899,
        },
        _id: "64f75284ef8f606073e98a8c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "faravohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8231003,
          lng: 47.62091700000001,
        },
        _id: "64f75284ef8f606073e98a8d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "lohavohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.82121,
          lng: 47.581581,
        },
        _id: "64f75284ef8f606073e98a8e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "andranofady",
        __v: 0,
      },
      {
        coords: {
          lat: -19.883835,
          lng: 47.7144578,
        },
        _id: "64f75284ef8f606073e98a8f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "antanjombolamena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8915225,
          lng: 47.675052,
        },
        _id: "64f75284ef8f606073e98a90",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambohitompoina",
        fokontany: "ambatolampikely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7621505,
          lng: 47.7243139,
        },
        _id: "64f75284ef8f606073e98a91",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "belanitra",
        fokontany: "belanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7139871,
          lng: 47.640596,
        },
        _id: "64f75284ef8f606073e98a92",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "belanitra",
        fokontany: "talakimaso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7114156,
          lng: 47.7144578,
        },
        _id: "64f75284ef8f606073e98a93",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "belanitra",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7328984,
          lng: 47.7391014,
        },
        _id: "64f75284ef8f606073e98a94",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "belanitra",
        fokontany: "ambohimandrosokely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7455585,
          lng: 47.8328517,
        },
        _id: "64f75284ef8f606073e98a95",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "belanitra",
        fokontany: "antenina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7476552,
          lng: 47.7736217,
        },
        _id: "64f75284ef8f606073e98a96",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "belanitra",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7440202,
          lng: 47.6947512,
        },
        _id: "64f75284ef8f606073e98a97",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "belanitra",
        fokontany: "ambatotokana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7916957,
          lng: 47.7933577,
        },
        _id: "64f75284ef8f606073e98a98",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "belanitra",
        fokontany: "fisoronana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8129053,
          lng: 47.7341718,
        },
        _id: "64f75284ef8f606073e98a99",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "belanitra",
        fokontany: "ankadivory",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8471841,
          lng: 47.8526097,
        },
        _id: "64f75284ef8f606073e98a9a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "belanitra",
        fokontany: "manaripatsa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.59037,
          lng: 47.47890899999999,
        },
        _id: "64f75284ef8f606073e98a9b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsahalava",
        fokontany: "antsahalava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5318879,
          lng: 47.4735591,
        },
        _id: "64f75284ef8f606073e98a9c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsahalava",
        fokontany: "amorona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5293117,
          lng: 47.4563943,
        },
        _id: "64f75284ef8f606073e98a9d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsahalava",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5280124,
          lng: 47.4931828,
        },
        _id: "64f75284ef8f606073e98a9e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsahalava",
        fokontany: "anjavidy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5432274,
          lng: 47.512814,
        },
        _id: "64f75284ef8f606073e98a9f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsahalava",
        fokontany: "ambohimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5407868,
          lng: 47.4465884,
        },
        _id: "64f75284ef8f606073e98aa0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsahalava",
        fokontany: "ambohikambana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5420767,
          lng: 47.4551685,
        },
        _id: "64f75284ef8f606073e98aa1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsahalava",
        fokontany: "manohisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5464613,
          lng: 47.4662021,
        },
        _id: "64f75284ef8f606073e98aa2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsahalava",
        fokontany: "ambatotokana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5602619,
          lng: 47.4809171,
        },
        _id: "64f75284ef8f606073e98aa3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsahalava",
        fokontany: "ambatondratsira",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5570292,
          lng: 47.4367844,
        },
        _id: "64f75284ef8f606073e98aa4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsahalava",
        fokontany: "ibonga mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5641283,
          lng: 47.461298,
        },
        _id: "64f75284ef8f606073e98aa5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsahalava",
        fokontany: "soafiraisana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.588131,
          lng: 47.5029975,
        },
        _id: "64f75284ef8f606073e98aa6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsahalava",
        fokontany: "fenomanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5727607,
          lng: 47.4416862,
        },
        _id: "64f75284ef8f606073e98aa7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsahalava",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5645191,
          lng: 47.4956363,
        },
        _id: "64f75284ef8f606073e98aa8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsahalava",
        fokontany: "anosiroa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5901696,
          lng: 47.4441373,
        },
        _id: "64f75284ef8f606073e98aa9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsahalava",
        fokontany: "amboatavo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5975317,
          lng: 47.461298,
        },
        _id: "64f75284ef8f606073e98aaa",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsahalava",
        fokontany: "ampotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6139342,
          lng: 47.4931828,
        },
        _id: "64f75284ef8f606073e98aab",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsahalava",
        fokontany: "ambatoantrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6482763,
          lng: 47.512814,
        },
        _id: "64f75284ef8f606073e98aac",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsahalava",
        fokontany: "alatsinainy ankitsikitsika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.658831,
          lng: 47.48337,
        },
        _id: "64f75284ef8f606073e98aad",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsahalava",
        fokontany: "anosimihatsaranangodana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5871005,
          lng: 47.5324525,
        },
        _id: "64f75284ef8f606073e98aae",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambodiriana",
        fokontany: "ambodiriana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5514687,
          lng: 47.5496422,
        },
        _id: "64f75284ef8f606073e98aaf",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambodiriana",
        fokontany: "iharana andranomalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5458004,
          lng: 47.5299973,
        },
        _id: "64f75284ef8f606073e98ab0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambodiriana",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5641181,
          lng: 47.5520984,
        },
        _id: "64f75284ef8f606073e98ab1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambodiriana",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5793442,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e98ab2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambodiriana",
        fokontany: "tsarahotiana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5664493,
          lng: 47.5312248,
        },
        _id: "64f75284ef8f606073e98ab3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambodiriana",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5772893,
          lng: 47.5398188,
        },
        _id: "64f75284ef8f606073e98ab4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambodiriana",
        fokontany: "mitsinjosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5903198,
          lng: 47.485823,
        },
        _id: "64f75284ef8f606073e98ab5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambodiriana",
        fokontany: "antsiriribe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6007775,
          lng: 47.5054514,
        },
        _id: "64f75284ef8f606073e98ab6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambodiriana",
        fokontany: "andrambiazina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5984562,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e98ab7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambodiriana",
        fokontany: "tsaramiafara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6147285,
          lng: 47.5619241,
        },
        _id: "64f75284ef8f606073e98ab8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambodiriana",
        fokontany: "ambalanosy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6219552,
          lng: 47.5373633,
        },
        _id: "64f75284ef8f606073e98ab9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "ambodiriana",
        fokontany: "andohariana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9455892,
          lng: 47.581581,
        },
        _id: "64f75284ef8f606073e98aba",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "antsampandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8566046,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e98abb",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "vohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8719125,
          lng: 47.5914122,
        },
        _id: "64f75284ef8f606073e98abc",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "bevahona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8604279,
          lng: 47.5520984,
        },
        _id: "64f75284ef8f606073e98abd",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "ankafotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.854685,
          lng: 47.5324525,
        },
        _id: "64f75284ef8f606073e98abe",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "ampongavato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.892799,
          lng: 47.5327,
        },
        _id: "64f75284ef8f606073e98abf",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "beronono",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8780867,
          lng: 47.4980899,
        },
        _id: "64f75284ef8f606073e98ac0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "antoby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.910562,
          lng: 47.4784643,
        },
        _id: "64f75284ef8f606073e98ac1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "trafonomby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9278447,
          lng: 47.5373633,
        },
        _id: "64f75284ef8f606073e98ac2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "ialasola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9065783,
          lng: 47.5545546,
        },
        _id: "64f75284ef8f606073e98ac3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "anosifito",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9140045,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e98ac4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "antsampandrano nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9034932,
          lng: 47.6012453,
        },
        _id: "64f75284ef8f606073e98ac5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9240885,
          lng: 47.6553601,
        },
        _id: "64f75284ef8f606073e98ac6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "ambohimitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9532922,
          lng: 47.640596,
        },
        _id: "64f75284ef8f606073e98ac7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "tsikalakalaina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9873181,
          lng: 47.675052,
        },
        _id: "64f75284ef8f606073e98ac8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9771836,
          lng: 47.5914122,
        },
        _id: "64f75284ef8f606073e98ac9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "morarano bilisy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9781009,
          lng: 47.5619241,
        },
        _id: "64f75284ef8f606073e98aca",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "masinandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9603353,
          lng: 47.5177229,
        },
        _id: "64f75284ef8f606073e98acb",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "ambohimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9770249,
          lng: 47.4931828,
        },
        _id: "64f75284ef8f606073e98acc",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "ankovotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0094873,
          lng: 47.4735591,
        },
        _id: "64f75284ef8f606073e98acd",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.036853,
          lng: 47.5177229,
        },
        _id: "64f75284ef8f606073e98ace",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "masindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0010314,
          lng: 47.5422745,
        },
        _id: "64f75284ef8f606073e98acf",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "anjoma",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0292883,
          lng: 47.557011,
        },
        _id: "64f75284ef8f606073e98ad0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "ikotomanory",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0323413,
          lng: 47.66520509999999,
        },
        _id: "64f75284ef8f606073e98ad1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antanifotsy",
        commune: "antsampandrano",
        fokontany: "ambatodidy sud",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4021302,
          lng: 46.9537969,
        },
        _id: "64f75284ef8f606073e98ad2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faratsiho",
        fokontany: "faratsiho",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2731502,
          lng: 46.7962319,
        },
        _id: "64f75284ef8f606073e98ad3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faratsiho",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.286949,
          lng: 46.87163899999999,
        },
        _id: "64f75284ef8f606073e98ad4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faratsiho",
        fokontany: "dango",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3029301,
          lng: 46.8495065,
        },
        _id: "64f75284ef8f606073e98ad5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faratsiho",
        fokontany: "fihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3204201,
          lng: 46.8931368,
        },
        _id: "64f75284ef8f606073e98ad6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faratsiho",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3350791,
          lng: 46.9270975,
        },
        _id: "64f75284ef8f606073e98ad7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faratsiho",
        fokontany: "ambodiala",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3521554,
          lng: 46.8882871,
        },
        _id: "64f75284ef8f606073e98ad8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faratsiho",
        fokontany: "ambohipoloalina antaboaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3658923,
          lng: 46.8543525,
        },
        _id: "64f75284ef8f606073e98ad9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faratsiho",
        fokontany: "ambondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.373943,
          lng: 46.89798690000001,
        },
        _id: "64f75284ef8f606073e98ada",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faratsiho",
        fokontany: "antsira tsiandramana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3597072,
          lng: 46.9465138,
        },
        _id: "64f75284ef8f606073e98adb",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faratsiho",
        fokontany: "fisoronana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3803271,
          lng: 46.9440864,
        },
        _id: "64f75284ef8f606073e98adc",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faratsiho",
        fokontany: "miadanandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3748636,
          lng: 46.9659376,
        },
        _id: "64f75284ef8f606073e98add",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faratsiho",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3891023,
          lng: 46.9173921,
        },
        _id: "64f75284ef8f606073e98ade",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faratsiho",
        fokontany: "marohanina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.408769,
          lng: 46.9440864,
        },
        _id: "64f75284ef8f606073e98adf",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faratsiho",
        fokontany: "sahomby firaisana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4127955,
          lng: 46.9659376,
        },
        _id: "64f75284ef8f606073e98ae0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faratsiho",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4208527,
          lng: 46.91254010000001,
        },
        _id: "64f75284ef8f606073e98ae1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faratsiho",
        fokontany: "antohomadio",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4478857,
          lng: 46.9562248,
        },
        _id: "64f75284ef8f606073e98ae2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faratsiho",
        fokontany: "ampizarankisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4658567,
          lng: 46.8882871,
        },
        _id: "64f75284ef8f606073e98ae3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faratsiho",
        fokontany: "mahatsinjo mampihavana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4108709,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e98ae4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "antsampanimahazo",
        fokontany: "avaratrakolahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4659014,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e98ae5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "antsampanimahazo",
        fokontany: "ambatodidy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4004048,
          lng: 47.0534365,
        },
        _id: "64f75284ef8f606073e98ae6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "antsampanimahazo",
        fokontany: "est bevoka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4440631,
          lng: 47.0729012,
        },
        _id: "64f75284ef8f606073e98ae7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "antsampanimahazo",
        fokontany: "kelilalina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4184136,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e98ae8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "antsampanimahazo",
        fokontany: "mandravasarotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.419391,
          lng: 47.0534365,
        },
        _id: "64f75284ef8f606073e98ae9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "antsampanimahazo",
        fokontany: "morafeno mamolahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3833779,
          lng: 46.9950872,
        },
        _id: "64f75284ef8f606073e98aea",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "antsampanimahazo",
        fokontany: "tsaratanana lovasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4450297,
          lng: 47.043707,
        },
        _id: "64f75284ef8f606073e98aeb",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "antsampanimahazo",
        fokontany: "ambohimpianarantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.445989,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e98aec",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "antsampanimahazo",
        fokontany: "antsahalava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4308113,
          lng: 46.9950872,
        },
        _id: "64f75284ef8f606073e98aed",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "antsampanimahazo",
        fokontany: "atsimondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3940409,
          lng: 47.0072378,
        },
        _id: "64f75284ef8f606073e98aee",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "antsampanimahazo",
        fokontany: "ambatolahifisaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4758728,
          lng: 46.9707947,
        },
        _id: "64f75284ef8f606073e98aef",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faravohitra",
        fokontany: "faravohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4621231,
          lng: 47.0048074,
        },
        _id: "64f75284ef8f606073e98af0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faravohitra",
        fokontany: "amparihimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4858546,
          lng: 47.0534365,
        },
        _id: "64f75284ef8f606073e98af1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faravohitra",
        fokontany: "andrefaninanobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4758728,
          lng: 46.9707947,
        },
        _id: "64f75284ef8f606073e98af2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faravohitra",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4934452,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e98af3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "faravohitra",
        fokontany: "fenomanantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.307171,
          lng: 47.009102,
        },
        _id: "64f75284ef8f606073e98af4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ramainandro",
        fokontany: "ramainandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2788061,
          lng: 47.00237720000001,
        },
        _id: "64f75284ef8f606073e98af5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ramainandro",
        fokontany: "alahady",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3605913,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e98af6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ramainandro",
        fokontany: "alatsinainy bevohoka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3065038,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e98af7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ramainandro",
        fokontany: "ambatomenaloha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2503059,
          lng: 46.91254010000001,
        },
        _id: "64f75284ef8f606073e98af8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ramainandro",
        fokontany: "ambohitrambo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3328001,
          lng: 47.0412749,
        },
        _id: "64f75284ef8f606073e98af9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ramainandro",
        fokontany: "ankonabe I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2881945,
          lng: 46.91254010000001,
        },
        _id: "64f75284ef8f606073e98afa",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ramainandro",
        fokontany: "ankonabe II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2589368,
          lng: 46.9829395,
        },
        _id: "64f75284ef8f606073e98afb",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ramainandro",
        fokontany: "isaha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3657657,
          lng: 47.0485715,
        },
        _id: "64f75284ef8f606073e98afc",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ramainandro",
        fokontany: "maromena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3054961,
          lng: 47.0534365,
        },
        _id: "64f75284ef8f606073e98afd",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ramainandro",
        fokontany: "miarinkofeno II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2862131,
          lng: 46.9707947,
        },
        _id: "64f75284ef8f606073e98afe",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ramainandro",
        fokontany: "tsaramandimby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3297963,
          lng: 46.9902277,
        },
        _id: "64f75284ef8f606073e98aff",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ramainandro",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2283246,
          lng: 46.9999471,
        },
        _id: "64f75284ef8f606073e98b00",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "andranomiady",
        fokontany: "andranomiady",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2126965,
          lng: 46.9950872,
        },
        _id: "64f75284ef8f606073e98b01",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "andranomiady",
        fokontany: "ambatoatsipy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2306295,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e98b02",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "andranomiady",
        fokontany: "ambatonilaisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2519029,
          lng: 47.0485715,
        },
        _id: "64f75284ef8f606073e98b03",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "andranomiady",
        fokontany: "ambohitraivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2237048,
          lng: 46.9513691,
        },
        _id: "64f75284ef8f606073e98b04",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "andranomiady",
        fokontany: "ambohitratsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5370258,
          lng: 46.9368047,
        },
        _id: "64f75284ef8f606073e98b05",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "vinaninony atsimo",
        fokontany: "tsarazafy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5010174,
          lng: 46.9756523,
        },
        _id: "64f75284ef8f606073e98b06",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "vinaninony atsimo",
        fokontany: "manaovasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5162147,
          lng: 46.9950872,
        },
        _id: "64f75284ef8f606073e98b07",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "vinaninony atsimo",
        fokontany: "ambohimahasoa II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5309522,
          lng: 47.0291162,
        },
        _id: "64f75284ef8f606073e98b08",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "vinaninony atsimo",
        fokontany: "telomirefy soahiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4990871,
          lng: 46.9368047,
        },
        _id: "64f75284ef8f606073e98b09",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "vinaninony atsimo",
        fokontany: "mananetivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5037691,
          lng: 46.8882871,
        },
        _id: "64f75284ef8f606073e98b0a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "vinaninony atsimo",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5421332,
          lng: 46.8737409,
        },
        _id: "64f75284ef8f606073e98b0b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "vinaninony atsimo",
        fokontany: "ambohimahasoa I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5327997,
          lng: 46.9707947,
        },
        _id: "64f75284ef8f606073e98b0c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "vinaninony atsimo",
        fokontany: "ambohitrarivo mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5636739,
          lng: 46.9950872,
        },
        _id: "64f75284ef8f606073e98b0d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "vinaninony atsimo",
        fokontany: "tsaramandroso morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.601197,
          lng: 47.0096682,
        },
        _id: "64f75284ef8f606073e98b0e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "vinaninony atsimo",
        fokontany: "tsaramiafara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5683465,
          lng: 46.9465138,
        },
        _id: "64f75284ef8f606073e98b0f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "vinaninony atsimo",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5502735,
          lng: 46.9173921,
        },
        _id: "64f75284ef8f606073e98b10",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "vinaninony atsimo",
        fokontany: "tsarafara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.580051,
          lng: 46.8737409,
        },
        _id: "64f75284ef8f606073e98b11",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "vinaninony atsimo",
        fokontany: "ambero tsaramody",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6104908,
          lng: 46.91254010000001,
        },
        _id: "64f75284ef8f606073e98b12",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "vinaninony atsimo",
        fokontany: "ambohimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6120348,
          lng: 46.9659376,
        },
        _id: "64f75284ef8f606073e98b13",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "vinaninony atsimo",
        fokontany: "tsaramandimby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3967639,
          lng: 47.1142882,
        },
        _id: "64f75284ef8f606073e98b14",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ambohiborona",
        fokontany: "antsonjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.358609,
          lng: 47.0729012,
        },
        _id: "64f75284ef8f606073e98b15",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ambohiborona",
        fokontany: "analamihantona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3694318,
          lng: 47.1264672,
        },
        _id: "64f75284ef8f606073e98b16",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ambohiborona",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.35,
          lng: 47.15,
        },
        _id: "64f75284ef8f606073e98b17",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ambohiborona",
        fokontany: "ambohitsampana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3927604,
          lng: 47.0923732,
        },
        _id: "64f75284ef8f606073e98b18",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ambohiborona",
        fokontany: "betsizaraina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.393594,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e98b19",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ambohiborona",
        fokontany: "andranomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4049216,
          lng: 47.1996022,
        },
        _id: "64f75284ef8f606073e98b1a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ambohiborona",
        fokontany: "ambavahady ambohijoky",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4176757,
          lng: 47.104547,
        },
        _id: "64f75284ef8f606073e98b1b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ambohiborona",
        fokontany: "bongamanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.422108,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e98b1c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ambohiborona",
        fokontany: "mamoladahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4454394,
          lng: 47.1264672,
        },
        _id: "64f75284ef8f606073e98b1d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ambohiborona",
        fokontany: "ambohitrandriamanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4525891,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e98b1e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "ambohiborona",
        fokontany: "ampasanomby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.44276,
          lng: 46.751492,
        },
        _id: "64f75284ef8f606073e98b1f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "miandrarivo",
        fokontany: "miandrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.384306,
          lng: 46.7720347,
        },
        _id: "64f75284ef8f606073e98b20",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "miandrarivo",
        fokontany: "ambarinomby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4549006,
          lng: 46.83497149999999,
        },
        _id: "64f75284ef8f606073e98b21",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "miandrarivo",
        fokontany: "ambavahadivato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3789175,
          lng: 46.64157160000001,
        },
        _id: "64f75284ef8f606073e98b22",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "miandrarivo",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4373251,
          lng: 46.7913915,
        },
        _id: "64f75284ef8f606073e98b23",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "miandrarivo",
        fokontany: "andranomanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4391209,
          lng: 46.7333436,
        },
        _id: "64f75284ef8f606073e98b24",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "miandrarivo",
        fokontany: "lohavohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4298865,
          lng: 46.6222727,
        },
        _id: "64f75284ef8f606073e98b25",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "miandrarivo",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.490591,
          lng: 46.6995133,
        },
        _id: "64f75284ef8f606073e98b26",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "miandrarivo",
        fokontany: "manarintsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4756361,
          lng: 46.7768732,
        },
        _id: "64f75284ef8f606073e98b27",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "miandrarivo",
        fokontany: "mangarivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.417349,
          lng: 46.7236754,
        },
        _id: "64f75284ef8f606073e98b28",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "miandrarivo",
        fokontany: "tompombohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5192282,
          lng: 46.7962319,
        },
        _id: "64f75284ef8f606073e98b29",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "miandrarivo",
        fokontany: "voahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3898798,
          lng: 46.6946822,
        },
        _id: "64f75284ef8f606073e98b2a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "miandrarivo",
        fokontany: "namana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4056222,
          lng: 46.7962319,
        },
        _id: "64f75284ef8f606073e98b2b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "miandrarivo",
        fokontany: "miaramasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4170192,
          lng: 46.83497149999999,
        },
        _id: "64f75284ef8f606073e98b2c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "miandrarivo",
        fokontany: "mananetivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4281374,
          lng: 46.6801919,
        },
        _id: "64f75284ef8f606073e98b2d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "miandrarivo",
        fokontany: "ambatolahindrafito",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4617812,
          lng: 46.71400920000001,
        },
        _id: "64f75284ef8f606073e98b2e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "miandrarivo",
        fokontany: "andrainarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3374695,
          lng: 46.7575221,
        },
        _id: "64f75284ef8f606073e98b2f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "valabetokana",
        fokontany: "amboniloha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3299148,
          lng: 46.7962319,
        },
        _id: "64f75284ef8f606073e98b30",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "valabetokana",
        fokontany: "antsahatanteraka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3393155,
          lng: 46.6995133,
        },
        _id: "64f75284ef8f606073e98b31",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "valabetokana",
        fokontany: "tsiona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3696098,
          lng: 46.7381783,
        },
        _id: "64f75284ef8f606073e98b32",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "valabetokana",
        fokontany: "vohibazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3738235,
          lng: 46.7043448,
        },
        _id: "64f75284ef8f606073e98b33",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "faratsiho",
        commune: "valabetokana",
        fokontany: "andranomanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7994502,
          lng: 47.0278773,
        },
        _id: "64f75284ef8f606073e98b34",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambano",
        fokontany: "ambano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.683333,
          lng: 47,
        },
        _id: "64f75284ef8f606073e98b35",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambano",
        fokontany: "andrakodavaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6886675,
          lng: 47.0485715,
        },
        _id: "64f75284ef8f606073e98b36",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambano",
        fokontany: "amparihindramananiolona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7299963,
          lng: 47.043707,
        },
        _id: "64f75284ef8f606073e98b37",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambano",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7213607,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e98b38",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambano",
        fokontany: "ankerambe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7324386,
          lng: 47.0680343,
        },
        _id: "64f75284ef8f606073e98b39",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambano",
        fokontany: "ambohitsaratelo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7585105,
          lng: 47.043707,
        },
        _id: "64f75284ef8f606073e98b3a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambano",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8020943,
          lng: 47.07046769999999,
        },
        _id: "64f75284ef8f606073e98b3b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambano",
        fokontany: "tsarafara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8147315,
          lng: 47.0169603,
        },
        _id: "64f75284ef8f606073e98b3c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambano",
        fokontany: "antanety avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8172025,
          lng: 47.0412749,
        },
        _id: "64f75284ef8f606073e98b3d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambano",
        fokontany: "manampisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8143133,
          lng: 47.0315477,
        },
        _id: "64f75284ef8f606073e98b3e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambano",
        fokontany: "mahazina atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8215362,
          lng: 47.0558692,
        },
        _id: "64f75284ef8f606073e98b3f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambano",
        fokontany: "tsarafiraisana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8796985,
          lng: 46.96602720000001,
        },
        _id: "64f75284ef8f606073e98b40",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "belazao",
        fokontany: "belazao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.878728,
          lng: 46.9368047,
        },
        _id: "64f75284ef8f606073e98b41",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "belazao",
        fokontany: "tsarahasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8861802,
          lng: 46.9537969,
        },
        _id: "64f75284ef8f606073e98b42",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "belazao",
        fokontany: "amboniavaratra sud",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9004305,
          lng: 46.9537969,
        },
        _id: "64f75284ef8f606073e98b43",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "belazao",
        fokontany: "andranonandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9128355,
          lng: 46.9635092,
        },
        _id: "64f75284ef8f606073e98b44",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "belazao",
        fokontany: "ambohinapetraka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9122415,
          lng: 46.9853688,
        },
        _id: "64f75284ef8f606073e98b45",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "belazao",
        fokontany: "anjanamiakatra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9369813,
          lng: 46.9489414,
        },
        _id: "64f75284ef8f606073e98b46",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "belazao",
        fokontany: "miadakofeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.766407,
          lng: 46.9902277,
        },
        _id: "64f75284ef8f606073e98b47",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "alakamisy",
        fokontany: "alakamisy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7412176,
          lng: 46.9853688,
        },
        _id: "64f75284ef8f606073e98b48",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "alakamisy",
        fokontany: "ambohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7593663,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e98b49",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "alakamisy",
        fokontany: "soamatamana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7700981,
          lng: 47.0266848,
        },
        _id: "64f75284ef8f606073e98b4a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "alakamisy",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7829368,
          lng: 46.9659376,
        },
        _id: "64f75284ef8f606073e98b4b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "alakamisy",
        fokontany: "marofangady",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7951161,
          lng: 46.9829395,
        },
        _id: "64f75284ef8f606073e98b4c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "alakamisy",
        fokontany: "mahandraza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8089514,
          lng: 46.9975171,
        },
        _id: "64f75284ef8f606073e98b4d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "alakamisy",
        fokontany: "andohafarihy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8473198,
          lng: 46.9592845,
        },
        _id: "64f75284ef8f606073e98b4e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanimandry",
        fokontany: "antanimandry",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8221712,
          lng: 46.9780812,
        },
        _id: "64f75284ef8f606073e98b4f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanimandry",
        fokontany: "avaradrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8258841,
          lng: 46.9586528,
        },
        _id: "64f75284ef8f606073e98b50",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanimandry",
        fokontany: "masiniloharano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8397252,
          lng: 46.97322339999999,
        },
        _id: "64f75284ef8f606073e98b51",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanimandry",
        fokontany: "ampahadiminy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8568565,
          lng: 46.9550108,
        },
        _id: "64f75284ef8f606073e98b52",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanimandry",
        fokontany: "ambohidrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8604787,
          lng: 46.9671518,
        },
        _id: "64f75284ef8f606073e98b53",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanimandry",
        fokontany: "antsongobe nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8704807,
          lng: 46.9489414,
        },
        _id: "64f75284ef8f606073e98b54",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanimandry",
        fokontany: "masinandraina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8686304,
          lng: 46.9586528,
        },
        _id: "64f75284ef8f606073e98b55",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanimandry",
        fokontany: "andrianana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9597469,
          lng: 47.0449231,
        },
        _id: "64f75284ef8f606073e98b56",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "vinaninkarena",
        fokontany: "ankarinomby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9451788,
          lng: 47.0558692,
        },
        _id: "64f75284ef8f606073e98b57",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "vinaninkarena",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9592184,
          lng: 47.0351952,
        },
        _id: "64f75284ef8f606073e98b58",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "vinaninkarena",
        fokontany: "ambohipeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9669128,
          lng: 47.0729012,
        },
        _id: "64f75284ef8f606073e98b59",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "vinaninkarena",
        fokontany: "anjanamanjaka ambohitraivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9816912,
          lng: 46.9950872,
        },
        _id: "64f75284ef8f606073e98b5a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "vinaninkarena",
        fokontany: "fiakarandava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9904244,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e98b5b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "vinaninkarena",
        fokontany: "mahaimandry",
        __v: 0,
      },
      {
        coords: {
          lat: -19.997526,
          lng: 47.0698593,
        },
        _id: "64f75284ef8f606073e98b5c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "vinaninkarena",
        fokontany: "ampandrotrarana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7839747,
          lng: 47.1065417,
        },
        _id: "64f75284ef8f606073e98b5d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "andranomanelatra",
        fokontany: "andranomanelatra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7187335,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e98b5e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "andranomanelatra",
        fokontany: "ambolotsararano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7552356,
          lng: 47.1532712,
        },
        _id: "64f75284ef8f606073e98b5f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "andranomanelatra",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7463844,
          lng: 47.1313396,
        },
        _id: "64f75284ef8f606073e98b60",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "andranomanelatra",
        fokontany: "antanetibe toavala",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7662884,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e98b61",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "andranomanelatra",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7815631,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e98b62",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "andranomanelatra",
        fokontany: "tsaramandroso gara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7930874,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e98b63",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "andranomanelatra",
        fokontany: "amberobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8014536,
          lng: 47.0923732,
        },
        _id: "64f75284ef8f606073e98b64",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "andranomanelatra",
        fokontany: "miarinarivo bemololo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8271027,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e98b65",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "andranomanelatra",
        fokontany: "tsaravavaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8196238,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e98b66",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "andranomanelatra",
        fokontany: "tsaramandroso soamahavoky",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8233656,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e98b67",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "andranomanelatra",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8318125,
          lng: 47.13864909999999,
        },
        _id: "64f75284ef8f606073e98b68",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "andranomanelatra",
        fokontany: "tsarazazamandimby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8403132,
          lng: 47.1191594,
        },
        _id: "64f75284ef8f606073e98b69",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "andranomanelatra",
        fokontany: "anosimboahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8581118,
          lng: 47.1069821,
        },
        _id: "64f75284ef8f606073e98b6a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "andranomanelatra",
        fokontany: "fandrindrano manaovasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.883333,
          lng: 47.15,
        },
        _id: "64f75284ef8f606073e98b6b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohidranandriana",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.883349,
          lng: 47.1118527,
        },
        _id: "64f75284ef8f606073e98b6c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohidranandriana",
        fokontany: "antsahavory",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8961608,
          lng: 47.16302160000001,
        },
        _id: "64f75284ef8f606073e98b6d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohidranandriana",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9023837,
          lng: 47.1118527,
        },
        _id: "64f75284ef8f606073e98b6e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohidranandriana",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9017602,
          lng: 47.133776,
        },
        _id: "64f75284ef8f606073e98b6f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohidranandriana",
        fokontany: "soamonina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9064987,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e98b70",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohidranandriana",
        fokontany: "anosibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9168645,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e98b71",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohidranandriana",
        fokontany: "tsaramody",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9205938,
          lng: 47.1410858,
        },
        _id: "64f75284ef8f606073e98b72",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohidranandriana",
        fokontany: "tongarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9255519,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e98b73",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohidranandriana",
        fokontany: "fanjakamandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.960762,
          lng: 47.1800893,
        },
        _id: "64f75284ef8f606073e98b74",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohidranandriana",
        fokontany: "miandrisoa tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8502083,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e98b75",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohimiarivo",
        fokontany: "betsiholany",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8017298,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e98b76",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohimiarivo",
        fokontany: "ambohikambana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8282811,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e98b77",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohimiarivo",
        fokontany: "ambatolahimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8588714,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e98b78",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohimiarivo",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8556088,
          lng: 47.13864909999999,
        },
        _id: "64f75284ef8f606073e98b79",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohimiarivo",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8665733,
          lng: 47.1435227,
        },
        _id: "64f75284ef8f606073e98b7a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohimiarivo",
        fokontany: "ambohimasy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8721414,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e98b7b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohimiarivo",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9492789,
          lng: 47.11393109999999,
        },
        _id: "64f75284ef8f606073e98b7c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohitsimanova",
        fokontany: "ambohitsimanova",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9011423,
          lng: 47.0996772,
        },
        _id: "64f75284ef8f606073e98b7d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohitsimanova",
        fokontany: "soamanandrariny",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9187261,
          lng: 47.0948078,
        },
        _id: "64f75284ef8f606073e98b7e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohitsimanova",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9214198,
          lng: 47.1118527,
        },
        _id: "64f75284ef8f606073e98b7f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohitsimanova",
        fokontany: "soavina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9492789,
          lng: 47.11393109999999,
        },
        _id: "64f75284ef8f606073e98b80",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohitsimanova",
        fokontany: "miadanimerina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9375561,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e98b81",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohitsimanova",
        fokontany: "ambohibohangy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.000485,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e98b82",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohitsimanova",
        fokontany: "antanamalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0278606,
          lng: 47.1654595,
        },
        _id: "64f75284ef8f606073e98b83",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohitsimanova",
        fokontany: "antsongombato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0041891,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e98b84",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohitsimanova",
        fokontany: "maronony",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0659742,
          lng: 47.1654595,
        },
        _id: "64f75284ef8f606073e98b85",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohitsimanova",
        fokontany: "avarabohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.965231,
          lng: 46.97401,
        },
        _id: "64f75284ef8f606073e98b86",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "mangarano",
        fokontany: "mangarano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9358064,
          lng: 46.9926574,
        },
        _id: "64f75284ef8f606073e98b87",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "mangarano",
        fokontany: "ambohijatovo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9378493,
          lng: 46.9756523,
        },
        _id: "64f75284ef8f606073e98b88",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "mangarano",
        fokontany: "maninarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.940165,
          lng: 47.0072378,
        },
        _id: "64f75284ef8f606073e98b89",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "mangarano",
        fokontany: "mahafaliarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9428723,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e98b8a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "mangarano",
        fokontany: "ankerana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9398891,
          lng: 46.9586528,
        },
        _id: "64f75284ef8f606073e98b8b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "mangarano",
        fokontany: "ambalamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9676243,
          lng: 46.9877982,
        },
        _id: "64f75284ef8f606073e98b8c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "mangarano",
        fokontany: "antanibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9861388,
          lng: 46.9465138,
        },
        _id: "64f75284ef8f606073e98b8d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "mangarano",
        fokontany: "anivosaha",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0074918,
          lng: 46.9780812,
        },
        _id: "64f75284ef8f606073e98b8e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "mangarano",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0270705,
          lng: 46.9562248,
        },
        _id: "64f75284ef8f606073e98b8f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "mangarano",
        fokontany: "sahatany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.062611,
          lng: 47.05575899999999,
        },
        _id: "64f75284ef8f606073e98b90",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "manandona",
        fokontany: "manandona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0115836,
          lng: 47.0631679,
        },
        _id: "64f75284ef8f606073e98b91",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "manandona",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0343029,
          lng: 47.043707,
        },
        _id: "64f75284ef8f606073e98b92",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "manandona",
        fokontany: "maharivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0306166,
          lng: 47.0631679,
        },
        _id: "64f75284ef8f606073e98b93",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "manandona",
        fokontany: "zanakambony",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0294465,
          lng: 47.1069821,
        },
        _id: "64f75284ef8f606073e98b94",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "manandona",
        fokontany: "ranomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0527593,
          lng: 47.06560109999999,
        },
        _id: "64f75284ef8f606073e98b95",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "manandona",
        fokontany: "ambohipahana soaray",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0502249,
          lng: 47.0412749,
        },
        _id: "64f75284ef8f606073e98b96",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "manandona",
        fokontany: "ambohitrimanjato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0692558,
          lng: 47.0412749,
        },
        _id: "64f75284ef8f606073e98b97",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "manandona",
        fokontany: "ambohiponana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0708379,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e98b98",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "manandona",
        fokontany: "ambatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0782045,
          lng: 47.0631679,
        },
        _id: "64f75284ef8f606073e98b99",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "manandona",
        fokontany: "ambohimirary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0943198,
          lng: 47.0534365,
        },
        _id: "64f75284ef8f606073e98b9a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "manandona",
        fokontany: "fierenantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.691721,
          lng: 47.1557087,
        },
        _id: "64f75284ef8f606073e98b9b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antsoatany",
        fokontany: "antsoatany",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6636359,
          lng: 47.1410858,
        },
        _id: "64f75284ef8f606073e98b9c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antsoatany",
        fokontany: "ambalavao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6817495,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e98b9d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antsoatany",
        fokontany: "antsampanimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6921744,
          lng: 47.1410858,
        },
        _id: "64f75284ef8f606073e98b9e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antsoatany",
        fokontany: "mandritsarakely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7483711,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e98b9f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antsoatany",
        fokontany: "ambohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0358254,
          lng: 46.9853688,
        },
        _id: "64f75284ef8f606073e98ba0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "alatsinainy ibity",
        fokontany: "ambalavao",
        __v: 0,
      },
      {
        coords: {
          lat: -20.038365,
          lng: 47.0096682,
        },
        _id: "64f75284ef8f606073e98ba1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "alatsinainy ibity",
        fokontany: "mananjara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0555872,
          lng: 46.9562248,
        },
        _id: "64f75284ef8f606073e98ba2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "alatsinainy ibity",
        fokontany: "antanetinilapa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0701957,
          lng: 47.0048074,
        },
        _id: "64f75284ef8f606073e98ba3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "alatsinainy ibity",
        fokontany: "ambarinakanga",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0928824,
          lng: 46.9853688,
        },
        _id: "64f75284ef8f606073e98ba4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "alatsinainy ibity",
        fokontany: "ihasy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1192072,
          lng: 46.9465138,
        },
        _id: "64f75284ef8f606073e98ba5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "alatsinainy ibity",
        fokontany: "antsira",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1407974,
          lng: 46.9707947,
        },
        _id: "64f75284ef8f606073e98ba6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "alatsinainy ibity",
        fokontany: "apopoha",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1847136,
          lng: 46.9902277,
        },
        _id: "64f75284ef8f606073e98ba7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "alatsinainy ibity",
        fokontany: "sahamalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1107038,
          lng: 47.1557087,
        },
        _id: "64f75284ef8f606073e98ba8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "sahanivotry manandona",
        fokontany: "sahanivotry manandona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.095722,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e98ba9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "sahanivotry manandona",
        fokontany: "ampamehena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1040929,
          lng: 47.1654595,
        },
        _id: "64f75284ef8f606073e98baa",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "sahanivotry manandona",
        fokontany: "farihitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1203183,
          lng: 47.0291162,
        },
        _id: "64f75284ef8f606073e98bab",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "sahanivotry manandona",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1305241,
          lng: 47.1264672,
        },
        _id: "64f75284ef8f606073e98bac",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "sahanivotry manandona",
        fokontany: "laimbolo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1448409,
          lng: 47.0631679,
        },
        _id: "64f75284ef8f606073e98bad",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "sahanivotry manandona",
        fokontany: "ambatolahy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1583851,
          lng: 47.0291162,
        },
        _id: "64f75284ef8f606073e98bae",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "sahanivotry manandona",
        fokontany: "maromanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.90625,
          lng: 47.2342299,
        },
        _id: "64f75284ef8f606073e98baf",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "soanindrariny",
        fokontany: "soanindrariny",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8306892,
          lng: 47.3853435,
        },
        _id: "64f75284ef8f606073e98bb0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "soanindrariny",
        fokontany: "antsapanimahazo fianarana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8478088,
          lng: 47.3461846,
        },
        _id: "64f75284ef8f606073e98bb1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "soanindrariny",
        fokontany: "tsaramody",
        __v: 0,
      },
      {
        coords: {
          lat: -19.86834,
          lng: 47.315231,
        },
        _id: "64f75284ef8f606073e98bb2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "soanindrariny",
        fokontany: "bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8819693,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e98bb3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "soanindrariny",
        fokontany: "antanimasaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8810924,
          lng: 47.2972775,
        },
        _id: "64f75284ef8f606073e98bb4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "soanindrariny",
        fokontany: "antambiazona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8897483,
          lng: 47.3266162,
        },
        _id: "64f75284ef8f606073e98bb5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "soanindrariny",
        fokontany: "ambodiala",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8828389,
          lng: 47.2386501,
        },
        _id: "64f75284ef8f606073e98bb6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "soanindrariny",
        fokontany: "manarintsoa sud",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9027568,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e98bb7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "soanindrariny",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9209644,
          lng: 47.2386501,
        },
        _id: "64f75284ef8f606073e98bb8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "soanindrariny",
        fokontany: "soanafindra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9101396,
          lng: 47.2826144,
        },
        _id: "64f75284ef8f606073e98bb9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "soanindrariny",
        fokontany: "est tendro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9458195,
          lng: 47.2581852,
        },
        _id: "64f75284ef8f606073e98bba",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "soanindrariny",
        fokontany: "antanikatsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9437691,
          lng: 47.2191224,
        },
        _id: "64f75284ef8f606073e98bbb",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "soanindrariny",
        fokontany: "fierenantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8165749,
          lng: 47.2240036,
        },
        _id: "64f75284ef8f606073e98bbc",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambatomena",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7941311,
          lng: 47.3339535,
        },
        _id: "64f75284ef8f606073e98bbd",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambatomena",
        fokontany: "ambodivato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7961781,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e98bbe",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambatomena",
        fokontany: "mitsinjoarivo betampona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8014778,
          lng: 47.3021661,
        },
        _id: "64f75284ef8f606073e98bbf",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambatomena",
        fokontany: "ambolavola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7970758,
          lng: 47.2386501,
        },
        _id: "64f75284ef8f606073e98bc0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambatomena",
        fokontany: "tsarafierana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7996469,
          lng: 47.3608657,
        },
        _id: "64f75284ef8f606073e98bc1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambatomena",
        fokontany: "ampanataovana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8229733,
          lng: 47.3266162,
        },
        _id: "64f75284ef8f606073e98bc2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambatomena",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8247722,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e98bc3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambatomena",
        fokontany: "anosy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8238764,
          lng: 47.2972775,
        },
        _id: "64f75284ef8f606073e98bc4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambatomena",
        fokontany: "antsahanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8500935,
          lng: 47.25315570000001,
        },
        _id: "64f75284ef8f606073e98bc5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambatomena",
        fokontany: "ambatomena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8591359,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e98bc6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambatomena",
        fokontany: "manarintsoa est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8546849,
          lng: 47.2240036,
        },
        _id: "64f75284ef8f606073e98bc7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambatomena",
        fokontany: "fenoarivo I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6185724,
          lng: 47.1374308,
        },
        _id: "64f75284ef8f606073e98bc8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohibary",
        fokontany: "ambohibary iray tsimivaky",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5100731,
          lng: 47.08750449999999,
        },
        _id: "64f75284ef8f606073e98bc9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohibary",
        fokontany: "inanobe manerinerina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5423703,
          lng: 47.0680343,
        },
        _id: "64f75284ef8f606073e98bca",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohibary",
        fokontany: "mandritsara fiarahamiasa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5404823,
          lng: 47.1264672,
        },
        _id: "64f75284ef8f606073e98bcb",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohibary",
        fokontany: "morodrano trafonomby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5685304,
          lng: 47.1410858,
        },
        _id: "64f75284ef8f606073e98bcc",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohibary",
        fokontany: "andranokely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5761212,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e98bcd",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohibary",
        fokontany: "ambohimandroso II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5993759,
          lng: 47.0680343,
        },
        _id: "64f75284ef8f606073e98bce",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohibary",
        fokontany: "kianjasoa atsimondapa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5951315,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e98bcf",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohibary",
        fokontany: "tsarafiraisana morarano III",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5972921,
          lng: 47.133776,
        },
        _id: "64f75284ef8f606073e98bd0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohibary",
        fokontany: "faravohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6139496,
          lng: 47.1581462,
        },
        _id: "64f75284ef8f606073e98bd1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohibary",
        fokontany: "ankeniheny III",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5999161,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e98bd2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohibary",
        fokontany: "tsarazazamandimby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6236498,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e98bd3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohibary",
        fokontany: "antsofombato telomiray",
        __v: 0,
      },
      {
        coords: {
          lat: -19.62163,
          lng: 47.1344179,
        },
        _id: "64f75284ef8f606073e98bd4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohibary",
        fokontany: "mahatsinjo miaradia",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6275188,
          lng: 47.1800893,
        },
        _id: "64f75284ef8f606073e98bd5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohibary",
        fokontany: "ambatomainty fihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.626788,
          lng: 47.1532712,
        },
        _id: "64f75284ef8f606073e98bd6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohibary",
        fokontany: "sahabe tetezana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6474756,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e98bd7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohibary",
        fokontany: "fanomezantsoa sambaina gara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6506575,
          lng: 47.0485715,
        },
        _id: "64f75284ef8f606073e98bd8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohibary",
        fokontany: "madera ambohimadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6465499,
          lng: 47.1800893,
        },
        _id: "64f75284ef8f606073e98bd9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohibary",
        fokontany: "fenomanana ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6678671,
          lng: 47.1069821,
        },
        _id: "64f75284ef8f606073e98bda",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "ambohibary",
        fokontany: "ambohitrandriana miray",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5609211,
          lng: 47.1800893,
        },
        _id: "64f75284ef8f606073e98bdb",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "mandrosohasina",
        fokontany: "tongasoa ambanimaso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5024608,
          lng: 47.1264672,
        },
        _id: "64f75284ef8f606073e98bdc",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "mandrosohasina",
        fokontany: "betampona mahavokatra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4848311,
          lng: 47.1800893,
        },
        _id: "64f75284ef8f606073e98bdd",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "mandrosohasina",
        fokontany: "kianjanakanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5150283,
          lng: 47.1776507,
        },
        _id: "64f75284ef8f606073e98bde",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "mandrosohasina",
        fokontany: "avarabary fanantenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5257202,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e98bdf",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "mandrosohasina",
        fokontany: "miarintsoa soahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5361975,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e98be0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "mandrosohasina",
        fokontany: "manjakamiadana avarabary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5540183,
          lng: 47.19716260000001,
        },
        _id: "64f75284ef8f606073e98be1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "mandrosohasina",
        fokontany: "amparihy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.574239,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e98be2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "mandrosohasina",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5853399,
          lng: 47.1672977,
        },
        _id: "64f75284ef8f606073e98be3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "mandrosohasina",
        fokontany: "mandrosohasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6018311,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e98be4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "mandrosohasina",
        fokontany: "tsaramody ambalafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9677874,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e98be5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "tsarahonenana sahanivotry",
        fokontany: "tsaramirafitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.998036,
          lng: 47.3217253,
        },
        _id: "64f75284ef8f606073e98be6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "tsarahonenana sahanivotry",
        fokontany: "soavinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9684191,
          lng: 47.2459749,
        },
        _id: "64f75284ef8f606073e98be7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "tsarahonenana sahanivotry",
        fokontany: "tsarafierana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9818946,
          lng: 47.2191224,
        },
        _id: "64f75284ef8f606073e98be8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "tsarahonenana sahanivotry",
        fokontany: "ampamoabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9775346,
          lng: 47.2606276,
        },
        _id: "64f75284ef8f606073e98be9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "tsarahonenana sahanivotry",
        fokontany: "belanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9874887,
          lng: 47.2459749,
        },
        _id: "64f75284ef8f606073e98bea",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "tsarahonenana sahanivotry",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9951522,
          lng: 47.1996022,
        },
        _id: "64f75284ef8f606073e98beb",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "tsarahonenana sahanivotry",
        fokontany: "miarintsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0246,
          lng: 47.2826144,
        },
        _id: "64f75284ef8f606073e98bec",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "tsarahonenana sahanivotry",
        fokontany: "sahanivotry",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0262451,
          lng: 47.2240036,
        },
        _id: "64f75284ef8f606073e98bed",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "tsarahonenana sahanivotry",
        fokontany: "maromandraisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0536317,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e98bee",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "tsarahonenana sahanivotry",
        fokontany: "ambohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0610714,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e98bef",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "tsarahonenana sahanivotry",
        fokontany: "soafianatra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9390311,
          lng: 47.380447,
        },
        _id: "64f75284ef8f606073e98bf0",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanambao",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8462162,
          lng: 47.3975867,
        },
        _id: "64f75284ef8f606073e98bf1",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanambao",
        fokontany: "ambalarano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8688707,
          lng: 47.3853435,
        },
        _id: "64f75284ef8f606073e98bf2",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanambao",
        fokontany: "soamiakatra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8955185,
          lng: 47.3461846,
        },
        _id: "64f75284ef8f606073e98bf3",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanambao",
        fokontany: "iharandramosa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8870599,
          lng: 47.41473209999999,
        },
        _id: "64f75284ef8f606073e98bf4",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanambao",
        fokontany: "tsarasaotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9041729,
          lng: 47.37555099999999,
        },
        _id: "64f75284ef8f606073e98bf5",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanambao",
        fokontany: "fenoarivo II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9157109,
          lng: 47.41473209999999,
        },
        _id: "64f75284ef8f606073e98bf6",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanambao",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9212597,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e98bf7",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanambao",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9412126,
          lng: 47.3070552,
        },
        _id: "64f75284ef8f606073e98bf8",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanambao",
        fokontany: "merimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9434726,
          lng: 47.4441373,
        },
        _id: "64f75284ef8f606073e98bf9",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanambao",
        fokontany: "soavimbahoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9654717,
          lng: 47.4539427,
        },
        _id: "64f75284ef8f606073e98bfa",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanambao",
        fokontany: "ambatomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9794635,
          lng: 47.4122824,
        },
        _id: "64f75284ef8f606073e98bfb",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanambao",
        fokontany: "menatratra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9738973,
          lng: 47.3853435,
        },
        _id: "64f75284ef8f606073e98bfc",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanambao",
        fokontany: "soamiampita",
        __v: 0,
      },
      {
        coords: {
          lat: -20.016667,
          lng: 47.366667,
        },
        _id: "64f75284ef8f606073e98bfd",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanambao",
        fokontany: "indroamita",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0083397,
          lng: 47.404934,
        },
        _id: "64f75284ef8f606073e98bfe",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanambao",
        fokontany: "soanakambana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0328106,
          lng: 47.4392352,
        },
        _id: "64f75284ef8f606073e98bff",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "antsirabe II",
        commune: "antanambao",
        fokontany: "ambatobe fisahanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.584961,
          lng: 46.29047,
        },
        _id: "64f75284ef8f606073e98c00",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "mandoto",
        fokontany: "mandoto",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4770366,
          lng: 46.2905429,
        },
        _id: "64f75284ef8f606073e98c01",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "mandoto",
        fokontany: "amparihy fenomanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4947035,
          lng: 46.3336857,
        },
        _id: "64f75284ef8f606073e98c02",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "mandoto",
        fokontany: "antanety nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5118041,
          lng: 46.2809608,
        },
        _id: "64f75284ef8f606073e98c03",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "mandoto",
        fokontany: "analavory",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5250689,
          lng: 46.3720666,
        },
        _id: "64f75284ef8f606073e98c04",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "mandoto",
        fokontany: "ambatolahimaro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5652676,
          lng: 46.3960699,
        },
        _id: "64f75284ef8f606073e98c05",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "mandoto",
        fokontany: "antanambao manerinerina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5652094,
          lng: 46.2785656,
        },
        _id: "64f75284ef8f606073e98c06",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "mandoto",
        fokontany: "tsaramody",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5813284,
          lng: 46.2618022,
        },
        _id: "64f75284ef8f606073e98c07",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "mandoto",
        fokontany: "morarano ambararata",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5931705,
          lng: 46.4104775,
        },
        _id: "64f75284ef8f606073e98c08",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "mandoto",
        fokontany: "maromandray",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5569903,
          lng: 46.3528724,
        },
        _id: "64f75284ef8f606073e98c09",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "mandoto",
        fokontany: "ampotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6004652,
          lng: 46.3720666,
        },
        _id: "64f75284ef8f606073e98c0a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "mandoto",
        fokontany: "tsaramandroso ambohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6037746,
          lng: 46.2426511,
        },
        _id: "64f75284ef8f606073e98c0b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "mandoto",
        fokontany: "viliabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6454329,
          lng: 46.3336857,
        },
        _id: "64f75284ef8f606073e98c0c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "mandoto",
        fokontany: "mananjara tsarahafatra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6153005,
          lng: 46.4056745,
        },
        _id: "64f75284ef8f606073e98c0d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "mandoto",
        fokontany: "tsarafara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4243065,
          lng: 46.38166649999999,
        },
        _id: "64f75284ef8f606073e98c0e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "betsohana",
        fokontany: "betsohana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4512849,
          lng: 46.3145064,
        },
        _id: "64f75284ef8f606073e98c0f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "betsohana",
        fokontany: "vatomanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4480787,
          lng: 46.4296942,
        },
        _id: "64f75284ef8f606073e98c10",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "betsohana",
        fokontany: "ampasatokana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4177546,
          lng: 46.3912683,
        },
        _id: "64f75284ef8f606073e98c11",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "betsohana",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -19.501368,
          lng: 46.4344996,
        },
        _id: "64f75284ef8f606073e98c12",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "betsohana",
        fokontany: "morafeno nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5238963,
          lng: 46.415281,
        },
        _id: "64f75284ef8f606073e98c13",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "betsohana",
        fokontany: "tsaramasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5303312,
          lng: 46.1709012,
        },
        _id: "64f75284ef8f606073e98c14",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "antanambao ambary",
        fokontany: "antanambao ambary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4796997,
          lng: 46.1900242,
        },
        _id: "64f75284ef8f606073e98c15",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "antanambao ambary",
        fokontany: "antevamena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4973816,
          lng: 46.2330783,
        },
        _id: "64f75284ef8f606073e98c16",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "antanambao ambary",
        fokontany: "antsahavory",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5281181,
          lng: 46.25701369999999,
        },
        _id: "64f75284ef8f606073e98c17",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "antanambao ambary",
        fokontany: "antsapanimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5384547,
          lng: 46.221115,
        },
        _id: "64f75284ef8f606073e98c18",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "antanambao ambary",
        fokontany: "tsarafaritra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5697527,
          lng: 46.2235075,
        },
        _id: "64f75284ef8f606073e98c19",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "antanambao ambary",
        fokontany: "bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5870989,
          lng: 46.152451,
        },
        _id: "64f75284ef8f606073e98c1a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "antanambao ambary",
        fokontany: "ambatotsipihina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5948144,
          lng: 46.0968707,
        },
        _id: "64f75284ef8f606073e98c1b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "antanambao ambary",
        fokontany: "tsaramody",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7352154,
          lng: 45.9800848,
        },
        _id: "64f75284ef8f606073e98c1c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "anjoma ramartina",
        fokontany: "anjoma ramartina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4458164,
          lng: 46.0467851,
        },
        _id: "64f75284ef8f606073e98c1d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "anjoma ramartina",
        fokontany: "antsampandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5345688,
          lng: 45.9991326,
        },
        _id: "64f75284ef8f606073e98c1e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "anjoma ramartina",
        fokontany: "tsaramiakatra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6134352,
          lng: 46.10402999999999,
        },
        _id: "64f75284ef8f606073e98c1f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "anjoma ramartina",
        fokontany: "ambatondradama",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5127133,
          lng: 45.8659561,
        },
        _id: "64f75284ef8f606073e98c20",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "anjoma ramartina",
        fokontany: "morafeno andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8361382,
          lng: 45.9420118,
        },
        _id: "64f75284ef8f606073e98c21",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "anjoma ramartina",
        fokontany: "ambaravaranala",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6541442,
          lng: 46.5427434,
        },
        _id: "64f75284ef8f606073e98c22",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "ankazomiriotra",
        fokontany: "ankazomiriotra II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5678977,
          lng: 46.5307047,
        },
        _id: "64f75284ef8f606073e98c23",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "ankazomiriotra",
        fokontany: "vohitrarivo II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6147202,
          lng: 46.5451516,
        },
        _id: "64f75284ef8f606073e98c24",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "ankazomiriotra",
        fokontany: "ankilahila",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6262636,
          lng: 46.5836972,
        },
        _id: "64f75284ef8f606073e98c25",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "ankazomiriotra",
        fokontany: "morarano ankerana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6424675,
          lng: 46.4489184,
        },
        _id: "64f75284ef8f606073e98c26",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "ankazomiriotra",
        fokontany: "mandakibo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6631022,
          lng: 46.5018235,
        },
        _id: "64f75284ef8f606073e98c27",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "ankazomiriotra",
        fokontany: "andranovory",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6717833,
          lng: 46.5307047,
        },
        _id: "64f75284ef8f606073e98c28",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "ankazomiriotra",
        fokontany: "ankazomiriotra I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6646506,
          lng: 46.5620116,
        },
        _id: "64f75284ef8f606073e98c29",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "ankazomiriotra",
        fokontany: "andratsaimahamasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6675069,
          lng: 46.4537256,
        },
        _id: "64f75284ef8f606073e98c2a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "ankazomiriotra",
        fokontany: "ambodifiakarana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6686214,
          lng: 46.4104775,
        },
        _id: "64f75284ef8f606073e98c2b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "ankazomiriotra",
        fokontany: "moraranokely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6732995,
          lng: 46.4729592,
        },
        _id: "64f75284ef8f606073e98c2c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "ankazomiriotra",
        fokontany: "tatamolava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6829712,
          lng: 46.5836972,
        },
        _id: "64f75284ef8f606073e98c2d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "ankazomiriotra",
        fokontany: "antanetikely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6943289,
          lng: 46.5114487,
        },
        _id: "64f75284ef8f606073e98c2e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "ankazomiriotra",
        fokontany: "ampanarivomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7063553,
          lng: 46.4104775,
        },
        _id: "64f75284ef8f606073e98c2f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "ankazomiriotra",
        fokontany: "marogoaika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7204016,
          lng: 46.5981595,
        },
        _id: "64f75284ef8f606073e98c30",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "ankazomiriotra",
        fokontany: "belanitra est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7636693,
          lng: 46.38166649999999,
        },
        _id: "64f75284ef8f606073e98c31",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "ankazomiriotra",
        fokontany: "ambohipeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.513559,
          lng: 46.565022,
        },
        _id: "64f75284ef8f606073e98c32",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "fidirana",
        fokontany: "fidirana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4258818,
          lng: 46.5451516,
        },
        _id: "64f75284ef8f606073e98c33",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "fidirana",
        fokontany: "mamoriomby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4562184,
          lng: 46.5836972,
        },
        _id: "64f75284ef8f606073e98c34",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "fidirana",
        fokontany: "ambohibolakely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.430014,
          lng: 46.5114487,
        },
        _id: "64f75284ef8f606073e98c35",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "fidirana",
        fokontany: "ambohimanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4792323,
          lng: 46.5499681,
        },
        _id: "64f75284ef8f606073e98c36",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "fidirana",
        fokontany: "soamananety",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4865743,
          lng: 46.6222727,
        },
        _id: "64f75284ef8f606073e98c37",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "fidirana",
        fokontany: "ambalafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4968816,
          lng: 46.4825787,
        },
        _id: "64f75284ef8f606073e98c38",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "fidirana",
        fokontany: "ampiadiambola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5112512,
          lng: 46.5307047,
        },
        _id: "64f75284ef8f606073e98c39",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "fidirana",
        fokontany: "antampondravola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5432754,
          lng: 46.6222727,
        },
        _id: "64f75284ef8f606073e98c3a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "fidirana",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5888675,
          lng: 46.5692391,
        },
        _id: "64f75284ef8f606073e98c3b",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "fidirana",
        fokontany: "ambohimasikely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8547757,
          lng: 46.2474381,
        },
        _id: "64f75284ef8f606073e98c3c",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "vasiana",
        fokontany: "vasiana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6407143,
          lng: 46.2713806,
        },
        _id: "64f75284ef8f606073e98c3d",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "vasiana",
        fokontany: "ambatolahihazo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.666667,
          lng: 46.3,
        },
        _id: "64f75284ef8f606073e98c3e",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "vasiana",
        fokontany: "ambohitrandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7054337,
          lng: 46.1900242,
        },
        _id: "64f75284ef8f606073e98c3f",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "vasiana",
        fokontany: "beakanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9301593,
          lng: 46.2474381,
        },
        _id: "64f75284ef8f606073e98c40",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "vasiana",
        fokontany: "amparihy bara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6031759,
          lng: 46.5066359,
        },
        _id: "64f75284ef8f606073e98c41",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "vinany",
        fokontany: "vinany nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.466667,
          lng: 46.466667,
        },
        _id: "64f75284ef8f606073e98c42",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "vinany",
        fokontany: "ambatofotsikely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5465371,
          lng: 46.5066359,
        },
        _id: "64f75284ef8f606073e98c43",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "vinany",
        fokontany: "andromba",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5485177,
          lng: 46.4344996,
        },
        _id: "64f75284ef8f606073e98c44",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "vinany",
        fokontany: "ivory",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5542826,
          lng: 46.4537256,
        },
        _id: "64f75284ef8f606073e98c45",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "vinany",
        fokontany: "ambatolahy est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5723665,
          lng: 46.4825787,
        },
        _id: "64f75284ef8f606073e98c46",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "vinany",
        fokontany: "ampasatokana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5920183,
          lng: 46.4537256,
        },
        _id: "64f75284ef8f606073e98c47",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "vinany",
        fokontany: "antanambe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6121408,
          lng: 46.4657457,
        },
        _id: "64f75284ef8f606073e98c48",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "vinany",
        fokontany: "mazoto",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6174333,
          lng: 46.4441117,
        },
        _id: "64f75284ef8f606073e98c49",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "vinany",
        fokontany: "tsimandiarano ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6364137,
          lng: 46.4994175,
        },
        _id: "64f75284ef8f606073e98c4a",
        province: "antananarivo",
        region: "vakinankaratra",
        district: "mandoto",
        commune: "vinany",
        fokontany: "ankamory",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0098894,
          lng: 47.1782604,
        },
        _id: "64f75284ef8f606073e98c4b",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo I",
        fokontany: "arivonimamo afovoany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9919422,
          lng: 47.192284,
        },
        _id: "64f75284ef8f606073e98c4c",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo I",
        fokontany: "kianja",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9897336,
          lng: 47.1678975,
        },
        _id: "64f75284ef8f606073e98c4d",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo I",
        fokontany: "ankeniheny",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0053581,
          lng: 47.1727739,
        },
        _id: "64f75284ef8f606073e98c4e",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo I",
        fokontany: "miadamanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0000423,
          lng: 47.1874058,
        },
        _id: "64f75284ef8f606073e98c4f",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo I",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0022814,
          lng: 47.180699,
        },
        _id: "64f75284ef8f606073e98c50",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo I",
        fokontany: "saromilanja",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0059115,
          lng: 47.17887,
        },
        _id: "64f75284ef8f606073e98c51",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo I",
        fokontany: "arivonimamo avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0103532,
          lng: 47.2069214,
        },
        _id: "64f75284ef8f606073e98c52",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo I",
        fokontany: "manankasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0140095,
          lng: 47.1739931,
        },
        _id: "64f75284ef8f606073e98c53",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo I",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0209852,
          lng: 47.1776507,
        },
        _id: "64f75284ef8f606073e98c54",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo I",
        fokontany: "morafeno atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0248613,
          lng: 47.2203427,
        },
        _id: "64f75284ef8f606073e98c55",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo I",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0410784,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e98c56",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo I",
        fokontany: "antanibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0276632,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e98c57",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo I",
        fokontany: "mangatany",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0109537,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e98c58",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "ambohipeno",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9540377,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e98c59",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "antsahavory",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9535432,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e98c5a",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "kianjanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.959105,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e98c5b",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "vatolaivy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9691226,
          lng: 47.1289033,
        },
        _id: "64f75284ef8f606073e98c5c",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "ambodifarihy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.974639,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e98c5d",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "ambohitrinilahy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9741118,
          lng: 47.16302160000001,
        },
        _id: "64f75284ef8f606073e98c5e",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "betafo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9707047,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e98c5f",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "ambohijatovo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9885328,
          lng: 47.2386501,
        },
        _id: "64f75284ef8f606073e98c60",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "antanetilava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9855608,
          lng: 47.1532712,
        },
        _id: "64f75284ef8f606073e98c61",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "ialaroa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0019632,
          lng: 47.2191224,
        },
        _id: "64f75284ef8f606073e98c62",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "antanety est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9947891,
          lng: 47.1191594,
        },
        _id: "64f75284ef8f606073e98c63",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "ankalalahana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0053833,
          lng: 47.1313396,
        },
        _id: "64f75284ef8f606073e98c64",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "antanetikely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0139524,
          lng: 47.2362087,
        },
        _id: "64f75284ef8f606073e98c65",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "manerinerina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0243538,
          lng: 47.1313396,
        },
        _id: "64f75284ef8f606073e98c66",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "amby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0394173,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e98c67",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "mangarano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0614407,
          lng: 47.2362087,
        },
        _id: "64f75284ef8f606073e98c68",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "ambaralamba sud",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0544855,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e98c69",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "ambohimahavony",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0547287,
          lng: 47.2459749,
        },
        _id: "64f75284ef8f606073e98c6a",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "ambaralamba nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0528128,
          lng: 47.1313396,
        },
        _id: "64f75284ef8f606073e98c6b",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "mahadonga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0648141,
          lng: 47.1483967,
        },
        _id: "64f75284ef8f606073e98c6c",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "arivonimamo II",
        fokontany: "sambaikoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.922899,
          lng: 47.176929,
        },
        _id: "64f75284ef8f606073e98c6d",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "ambohitrambo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8765748,
          lng: 47.1118527,
        },
        _id: "64f75284ef8f606073e98c6e",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8659242,
          lng: 47.1410858,
        },
        _id: "64f75284ef8f606073e98c6f",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "ambohitrivahoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8742264,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e98c70",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "soalanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.861681,
          lng: 47.1678975,
        },
        _id: "64f75284ef8f606073e98c71",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "ambohijanamasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8658297,
          lng: 47.182528,
        },
        _id: "64f75284ef8f606073e98c72",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "ampanikely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8797608,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e98c73",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "ambohijanaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8813911,
          lng: 47.2081414,
        },
        _id: "64f75284ef8f606073e98c74",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "tsimatahodaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9042719,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e98c75",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "ambohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.899479,
          lng: 47.2301058,
        },
        _id: "64f75284ef8f606073e98c76",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "soaronono",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9203916,
          lng: 47.2410916,
        },
        _id: "64f75284ef8f606073e98c77",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "avarabato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9271137,
          lng: 47.2313263,
        },
        _id: "64f75284ef8f606073e98c78",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "ambatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9215711,
          lng: 47.2118015,
        },
        _id: "64f75284ef8f606073e98c79",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "ampany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9310612,
          lng: 47.2118015,
        },
        _id: "64f75284ef8f606073e98c7a",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "bejofo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9517218,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e98c7b",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "antanisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9587266,
          lng: 47.192284,
        },
        _id: "64f75284ef8f606073e98c7c",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "mahereza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9464616,
          lng: 47.182528,
        },
        _id: "64f75284ef8f606073e98c7d",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "manjakazaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.935069,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e98c7e",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "amberobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9161019,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e98c7f",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "anosy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9007835,
          lng: 47.13864909999999,
        },
        _id: "64f75284ef8f606073e98c80",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "ambodifarihy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.905008,
          lng: 47.1118527,
        },
        _id: "64f75284ef8f606073e98c81",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "tsarazaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9267415,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e98c82",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9337318,
          lng: 47.104547,
        },
        _id: "64f75284ef8f606073e98c83",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohitrambo",
        fokontany: "anelobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0946916,
          lng: 47.1947232,
        },
        _id: "64f75284ef8f606073e98c84",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ampahimanga",
        fokontany: "ampahimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0712224,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e98c85",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ampahimanga",
        fokontany: "amboanjobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0695586,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e98c86",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ampahimanga",
        fokontany: "alakamisy antsapanimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0946885,
          lng: 47.2362087,
        },
        _id: "64f75284ef8f606073e98c87",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ampahimanga",
        fokontany: "ankazotokana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0857515,
          lng: 47.221563,
        },
        _id: "64f75284ef8f606073e98c88",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ampahimanga",
        fokontany: "manjakatompo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0963554,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e98c89",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ampahimanga",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.100272,
          lng: 47.2557429,
        },
        _id: "64f75284ef8f606073e98c8a",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ampahimanga",
        fokontany: "imerinatsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0935731,
          lng: 47.182528,
        },
        _id: "64f75284ef8f606073e98c8b",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ampahimanga",
        fokontany: "fisoronana efadreny",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1019467,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e98c8c",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ampahimanga",
        fokontany: "antanetilava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1203952,
          lng: 47.2264444,
        },
        _id: "64f75284ef8f606073e98c8d",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ampahimanga",
        fokontany: "ambanifahitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1122656,
          lng: 47.1483967,
        },
        _id: "64f75284ef8f606073e98c8e",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ampahimanga",
        fokontany: "ankamory",
        __v: 0,
      },
      {
        coords: {
          lat: -19.124309,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e98c8f",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ampahimanga",
        fokontany: "antanimasaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1435928,
          lng: 47.2410916,
        },
        _id: "64f75284ef8f606073e98c90",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ampahimanga",
        fokontany: "nangaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1623229,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e98c91",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ampahimanga",
        fokontany: "ambohitringahimainty",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1516335,
          lng: 47.1673195,
        },
        _id: "64f75284ef8f606073e98c92",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "amboanana",
        fokontany: "amboanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1303934,
          lng: 47.1289033,
        },
        _id: "64f75284ef8f606073e98c93",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "amboanana",
        fokontany: "antsahabiabiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.01421,
          lng: 47.1797518,
        },
        _id: "64f75284ef8f606073e98c94",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "amboanana",
        fokontany: "andavabato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1505051,
          lng: 47.1410858,
        },
        _id: "64f75284ef8f606073e98c95",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "amboanana",
        fokontany: "antsolabato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1779055,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e98c96",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "amboanana",
        fokontany: "andohavary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1628446,
          lng: 47.192284,
        },
        _id: "64f75284ef8f606073e98c97",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "amboanana",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1385497,
          lng: 47.2069214,
        },
        _id: "64f75284ef8f606073e98c98",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "amboanana",
        fokontany: "ambohipetraka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1555986,
          lng: 47.216682,
        },
        _id: "64f75284ef8f606073e98c99",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "amboanana",
        fokontany: "ambatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1726518,
          lng: 47.2264444,
        },
        _id: "64f75284ef8f606073e98c9a",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "amboanana",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1813321,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e98c9b",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "amboanana",
        fokontany: "analakotrana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2165487,
          lng: 47.2386501,
        },
        _id: "64f75284ef8f606073e98c9c",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "amboanana",
        fokontany: "ambohimanambola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.21763,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e98c9d",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "amboanana",
        fokontany: "antsampandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1986262,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e98c9e",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "amboanana",
        fokontany: "antsahamaroloha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1827001,
          lng: 47.2118015,
        },
        _id: "64f75284ef8f606073e98c9f",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "amboanana",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1736085,
          lng: 47.200822,
        },
        _id: "64f75284ef8f606073e98ca0",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "amboanana",
        fokontany: "betaolana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2025137,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e98ca1",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "amboanana",
        fokontany: "mandrosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2074648,
          lng: 47.1410858,
        },
        _id: "64f75284ef8f606073e98ca2",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "amboanana",
        fokontany: "ambohidava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1854014,
          lng: 47.13864909999999,
        },
        _id: "64f75284ef8f606073e98ca3",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "amboanana",
        fokontany: "ambondrona sud",
        __v: 0,
      },
      {
        coords: {
          lat: -19.166667,
          lng: 47.116667,
        },
        _id: "64f75284ef8f606073e98ca4",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "amboanana",
        fokontany: "ambohitsaratelo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.092529,
          lng: 47.097919,
        },
        _id: "64f75284ef8f606073e98ca5",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "morafeno",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.056715,
          lng: 47.1118527,
        },
        _id: "64f75284ef8f606073e98ca6",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "morafeno",
        fokontany: "ambonivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0645057,
          lng: 47.0729012,
        },
        _id: "64f75284ef8f606073e98ca7",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "morafeno",
        fokontany: "savidahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0974553,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e98ca8",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "morafeno",
        fokontany: "mamoeramanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0996332,
          lng: 47.0631679,
        },
        _id: "64f75284ef8f606073e98ca9",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "morafeno",
        fokontany: "ambohimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1270036,
          lng: 47.0923732,
        },
        _id: "64f75284ef8f606073e98caa",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "morafeno",
        fokontany: "antanetibe lohavondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1487805,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e98cab",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "morafeno",
        fokontany: "antanisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1716298,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e98cac",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "morafeno",
        fokontany: "anosy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9850931,
          lng: 47.324782,
        },
        _id: "64f75284ef8f606073e98cad",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "antsenakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9393653,
          lng: 47.3584185,
        },
        _id: "64f75284ef8f606073e98cae",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "antemitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9481929,
          lng: 47.2972775,
        },
        _id: "64f75284ef8f606073e98caf",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "tsarazaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9525408,
          lng: 47.3461846,
        },
        _id: "64f75284ef8f606073e98cb0",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "amboara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9585796,
          lng: 47.2752844,
        },
        _id: "64f75284ef8f606073e98cb1",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "antsentsidranovato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9632377,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e98cb2",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9723182,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e98cb3",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "ambohidehibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9787811,
          lng: 47.363313,
        },
        _id: "64f75284ef8f606073e98cb4",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "ampitanomby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9795509,
          lng: 47.2655127,
        },
        _id: "64f75284ef8f606073e98cb5",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "tsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9719997,
          lng: 47.33517639999999,
        },
        _id: "64f75284ef8f606073e98cb6",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "miakadaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9749691,
          lng: 47.3205026,
        },
        _id: "64f75284ef8f606073e98cb7",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "mamoladahy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.976696,
          lng: 47.2972775,
        },
        _id: "64f75284ef8f606073e98cb8",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "tsenamasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9746231,
          lng: 47.3486311,
        },
        _id: "64f75284ef8f606073e98cb9",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "merimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9788564,
          lng: 47.3321191,
        },
        _id: "64f75284ef8f606073e98cba",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9853146,
          lng: 47.31928,
        },
        _id: "64f75284ef8f606073e98cbb",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "labrousse",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9893789,
          lng: 47.37555099999999,
        },
        _id: "64f75284ef8f606073e98cbc",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "antamboho II",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9861104,
          lng: 47.3388456,
        },
        _id: "64f75284ef8f606073e98cbd",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "tsarafaritra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9902712,
          lng: 47.3535246,
        },
        _id: "64f75284ef8f606073e98cbe",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "avarabary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9958465,
          lng: 47.29361129999999,
        },
        _id: "64f75284ef8f606073e98cbf",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "fonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0003707,
          lng: 47.3388456,
        },
        _id: "64f75284ef8f606073e98cc0",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9996464,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e98cc1",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "apangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9997797,
          lng: 47.3535246,
        },
        _id: "64f75284ef8f606073e98cc2",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "soavindray",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0012537,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e98cc3",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "morarano nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0163145,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e98cc4",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "talata maromena nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0218698,
          lng: 47.3559715,
        },
        _id: "64f75284ef8f606073e98cc5",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0147059,
          lng: 47.2972775,
        },
        _id: "64f75284ef8f606073e98cc6",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "ambohitrantenaina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0182083,
          lng: 47.3682078,
        },
        _id: "64f75284ef8f606073e98cc7",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "ambohimiadana II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0147664,
          lng: 47.2557429,
        },
        _id: "64f75284ef8f606073e98cc8",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0314515,
          lng: 47.3143897,
        },
        _id: "64f75284ef8f606073e98cc9",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "ambohitsaratelo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0370125,
          lng: 47.3339535,
        },
        _id: "64f75284ef8f606073e98cca",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "talata maromena sud",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0348688,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e98ccb",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "antamboho I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.050464,
          lng: 47.3143897,
        },
        _id: "64f75284ef8f606073e98ccc",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "fiantsonana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0437393,
          lng: 47.3241707,
        },
        _id: "64f75284ef8f606073e98ccd",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "malaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0432176,
          lng: 47.2972775,
        },
        _id: "64f75284ef8f606073e98cce",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "ankazondandy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0633736,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e98ccf",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "alatsinainy loharano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0689466,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e98cd0",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "imerintsiatosika",
        fokontany: "andavaka loharano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9056855,
          lng: 47.31072229999999,
        },
        _id: "64f75284ef8f606073e98cd1",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "morarano",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8868696,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e98cd2",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "morarano",
        fokontany: "mahalavolona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8951657,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e98cd3",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "morarano",
        fokontany: "antsahanadrisa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8994893,
          lng: 47.3266162,
        },
        _id: "64f75284ef8f606073e98cd4",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "morarano",
        fokontany: "mandrosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.920838,
          lng: 47.337011,
        },
        _id: "64f75284ef8f606073e98cd5",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "morarano",
        fokontany: "fenomanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9350557,
          lng: 47.2991107,
        },
        _id: "64f75284ef8f606073e98cd6",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "morarano",
        fokontany: "sabotsy antongona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9056855,
          lng: 47.31072229999999,
        },
        _id: "64f75284ef8f606073e98cd7",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "morarano",
        fokontany: "antsahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9398795,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e98cd8",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "morarano",
        fokontany: "mangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9441256,
          lng: 47.2410916,
        },
        _id: "64f75284ef8f606073e98cd9",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "morarano",
        fokontany: "ambatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9339015,
          lng: 47.3952813,
        },
        _id: "64f75284ef8f606073e98cda",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomirahavavy",
        fokontany: "ambatomirahavavy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9242953,
          lng: 47.3492428,
        },
        _id: "64f75284ef8f606073e98cdb",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomirahavavy",
        fokontany: "antanimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9208167,
          lng: 47.3663722,
        },
        _id: "64f75284ef8f606073e98cdc",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomirahavavy",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9211284,
          lng: 47.37799889999999,
        },
        _id: "64f75284ef8f606073e98cdd",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomirahavavy",
        fokontany: "miakotsorano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9320192,
          lng: 47.3828952,
        },
        _id: "64f75284ef8f606073e98cde",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomirahavavy",
        fokontany: "imeritsiafindra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9516405,
          lng: 47.3682078,
        },
        _id: "64f75284ef8f606073e98cdf",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomirahavavy",
        fokontany: "ambohidranomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9541074,
          lng: 47.3853435,
        },
        _id: "64f75284ef8f606073e98ce0",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomirahavavy",
        fokontany: "andriatompoiray",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9484443,
          lng: 47.4073834,
        },
        _id: "64f75284ef8f606073e98ce1",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomirahavavy",
        fokontany: "ampano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9627132,
          lng: 47.4073834,
        },
        _id: "64f75284ef8f606073e98ce2",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomirahavavy",
        fokontany: "imerikanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9700584,
          lng: 47.3828952,
        },
        _id: "64f75284ef8f606073e98ce3",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomirahavavy",
        fokontany: "ambohibato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9755982,
          lng: 47.4024848,
        },
        _id: "64f75284ef8f606073e98ce4",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomirahavavy",
        fokontany: "antsahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9932389,
          lng: 47.3975867,
        },
        _id: "64f75284ef8f606073e98ce5",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomirahavavy",
        fokontany: "ambohimanandray",
        __v: 0,
      },
      {
        coords: {
          lat: -18.993725,
          lng: 47.4245319,
        },
        _id: "64f75284ef8f606073e98ce6",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomirahavavy",
        fokontany: "manarintsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0063136,
          lng: 47.4269822,
        },
        _id: "64f75284ef8f606073e98ce7",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomirahavavy",
        fokontany: "ivelo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.011176,
          lng: 47.3853435,
        },
        _id: "64f75284ef8f606073e98ce8",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomirahavavy",
        fokontany: "amboniriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.817933,
          lng: 47.1557087,
        },
        _id: "64f75284ef8f606073e98ce9",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "ambatomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.734466,
          lng: 47.1118527,
        },
        _id: "64f75284ef8f606073e98cea",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "ambodirano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7534082,
          lng: 47.1118527,
        },
        _id: "64f75284ef8f606073e98ceb",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7759038,
          lng: 47.216682,
        },
        _id: "64f75284ef8f606073e98cec",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "meridaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7739029,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e98ced",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "tsimadilo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7757097,
          lng: 47.1069821,
        },
        _id: "64f75284ef8f606073e98cee",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "soaniombonana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7754434,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e98cef",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "manandona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7773564,
          lng: 47.200822,
        },
        _id: "64f75284ef8f606073e98cf0",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "ampasika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8026754,
          lng: 47.2191224,
        },
        _id: "64f75284ef8f606073e98cf1",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7968166,
          lng: 47.1313396,
        },
        _id: "64f75284ef8f606073e98cf2",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "soamandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8066461,
          lng: 47.1996022,
        },
        _id: "64f75284ef8f606073e98cf3",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "anjanamanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8078532,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e98cf4",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "mahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8292763,
          lng: 47.1874058,
        },
        _id: "64f75284ef8f606073e98cf5",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "ambohitrazo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8240534,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e98cf6",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "ambohiboromanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8252455,
          lng: 47.1313396,
        },
        _id: "64f75284ef8f606073e98cf7",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "imerinavaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8401407,
          lng: 47.192284,
        },
        _id: "64f75284ef8f606073e98cf8",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8501081,
          lng: 47.2191224,
        },
        _id: "64f75284ef8f606073e98cf9",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "antsampanimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8394128,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e98cfa",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "ambohitseheno",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8442002,
          lng: 47.1313396,
        },
        _id: "64f75284ef8f606073e98cfb",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "miadamanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8635549,
          lng: 47.1996022,
        },
        _id: "64f75284ef8f606073e98cfc",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "ambohitrinibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8614576,
          lng: 47.2508586,
        },
        _id: "64f75284ef8f606073e98cfd",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "antovontany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8654224,
          lng: 47.2313263,
        },
        _id: "64f75284ef8f606073e98cfe",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "ambohijafy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.866667,
          lng: 47.2,
        },
        _id: "64f75284ef8f606073e98cff",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "ankadivory",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8743134,
          lng: 47.2459749,
        },
        _id: "64f75284ef8f606073e98d00",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambatomanga",
        fokontany: "vatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.25835,
          lng: 47.11410900000001,
        },
        _id: "64f75284ef8f606073e98d01",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "manalalondo",
        fokontany: "manalalondo centre",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2387635,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e98d02",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "manalalondo",
        fokontany: "heritokana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2464944,
          lng: 47.1118527,
        },
        _id: "64f75284ef8f606073e98d03",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "manalalondo",
        fokontany: "manalalondo avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2613604,
          lng: 47.13864909999999,
        },
        _id: "64f75284ef8f606073e98d04",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "manalalondo",
        fokontany: "bongatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2475355,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e98d05",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "manalalondo",
        fokontany: "morarano I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2666301,
          lng: 47.1240312,
        },
        _id: "64f75284ef8f606073e98d06",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "manalalondo",
        fokontany: "andapakely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2622443,
          lng: 47.1581462,
        },
        _id: "64f75284ef8f606073e98d07",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "manalalondo",
        fokontany: "ankafotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2844691,
          lng: 47.1118527,
        },
        _id: "64f75284ef8f606073e98d08",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "manalalondo",
        fokontany: "antanetilava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.282283,
          lng: 47.1289033,
        },
        _id: "64f75284ef8f606073e98d09",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "manalalondo",
        fokontany: "mahatsinjo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2826755,
          lng: 47.0729012,
        },
        _id: "64f75284ef8f606073e98d0a",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "manalalondo",
        fokontany: "andavabato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2929309,
          lng: 47.1410858,
        },
        _id: "64f75284ef8f606073e98d0b",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "manalalondo",
        fokontany: "ankararana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2060216,
          lng: 47.0991867,
        },
        _id: "64f75284ef8f606073e98d0c",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "antenimbe",
        fokontany: "antenimbe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2018448,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e98d0d",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "antenimbe",
        fokontany: "andrabesambatra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2180174,
          lng: 47.1118527,
        },
        _id: "64f75284ef8f606073e98d0e",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "antenimbe",
        fokontany: "ambatonilaifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2035879,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e98d0f",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "alakamisikely",
        fokontany: "ambohidehibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2225816,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e98d10",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "alakamisikely",
        fokontany: "ambatomenaloha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2433269,
          lng: 47.1996022,
        },
        _id: "64f75284ef8f606073e98d11",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "alakamisikely",
        fokontany: "antanimietry",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2970046,
          lng: 47.2044815,
        },
        _id: "64f75284ef8f606073e98d12",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "mahatsinjo est",
        fokontany: "ankazondandy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2836269,
          lng: 47.2240036,
        },
        _id: "64f75284ef8f606073e98d13",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "mahatsinjo est",
        fokontany: "mahatsinjo est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2764021,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e98d14",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "mahatsinjo est",
        fokontany: "faravohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2774661,
          lng: 47.2191224,
        },
        _id: "64f75284ef8f606073e98d15",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "mahatsinjo est",
        fokontany: "bongavato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.31564,
          lng: 47.0802023,
        },
        _id: "64f75284ef8f606073e98d16",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "marofangady",
        fokontany: "andoharano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.298068,
          lng: 47.0850703,
        },
        _id: "64f75284ef8f606073e98d17",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "marofangady",
        fokontany: "mangirakely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.316667,
          lng: 47.083333,
        },
        _id: "64f75284ef8f606073e98d18",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "marofangady",
        fokontany: "marofangady",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3112863,
          lng: 47.1142882,
        },
        _id: "64f75284ef8f606073e98d19",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "marofangady",
        fokontany: "maharirana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.331437,
          lng: 47.1264672,
        },
        _id: "64f75284ef8f606073e98d1a",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "marofangady",
        fokontany: "ankenihenibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3371376,
          lng: 47.19888,
        },
        _id: "64f75284ef8f606073e98d1b",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "rambolamasoandro andranomiely",
        fokontany: "andranomiely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2954917,
          lng: 47.1581462,
        },
        _id: "64f75284ef8f606073e98d1c",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "rambolamasoandro andranomiely",
        fokontany: "fiatsonana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3383692,
          lng: 47.1996022,
        },
        _id: "64f75284ef8f606073e98d1d",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "rambolamasoandro andranomiely",
        fokontany: "tsaraonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3221789,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e98d1e",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "rambolamasoandro andranomiely",
        fokontany: "mahareta",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3242506,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e98d1f",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "rambolamasoandro andranomiely",
        fokontany: "manazary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3545564,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e98d20",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "rambolamasoandro andranomiely",
        fokontany: "bongafisaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0909458,
          lng: 47.37310309999999,
        },
        _id: "64f75284ef8f606073e98d21",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "ambohimandry est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0245561,
          lng: 47.4073834,
        },
        _id: "64f75284ef8f606073e98d22",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0413109,
          lng: 47.4245319,
        },
        _id: "64f75284ef8f606073e98d23",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "tsinjoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0453534,
          lng: 47.363313,
        },
        _id: "64f75284ef8f606073e98d24",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "soamanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.05289,
          lng: 47.37310309999999,
        },
        _id: "64f75284ef8f606073e98d25",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "manankasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0520096,
          lng: 47.3951378,
        },
        _id: "64f75284ef8f606073e98d26",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "ambohidava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0459381,
          lng: 47.3486311,
        },
        _id: "64f75284ef8f606073e98d27",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "ambalamanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0662023,
          lng: 47.4367844,
        },
        _id: "64f75284ef8f606073e98d28",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "antanety",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0670867,
          lng: 47.41473209999999,
        },
        _id: "64f75284ef8f606073e98d29",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "tsarafara miadana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0638575,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e98d2a",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "andriatsimeonona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0832649,
          lng: 47.4465884,
        },
        _id: "64f75284ef8f606073e98d2b",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "ambodivona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0693578,
          lng: 47.3975867,
        },
        _id: "64f75284ef8f606073e98d2c",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "ankeribe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1046876,
          lng: 47.3461846,
        },
        _id: "64f75284ef8f606073e98d2d",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "manolobony",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0789368,
          lng: 47.3559715,
        },
        _id: "64f75284ef8f606073e98d2e",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "analamanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0903656,
          lng: 47.3877919,
        },
        _id: "64f75284ef8f606073e98d2f",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "merinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0945425,
          lng: 47.4024848,
        },
        _id: "64f75284ef8f606073e98d30",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "soanavela",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0984274,
          lng: 47.4245319,
        },
        _id: "64f75284ef8f606073e98d31",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "ambohitsorohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0907452,
          lng: 47.2972775,
        },
        _id: "64f75284ef8f606073e98d32",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "ambohitrandraina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.11137,
          lng: 47.37799889999999,
        },
        _id: "64f75284ef8f606073e98d33",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "mandrosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1164744,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e98d34",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "anjanamahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.118339,
          lng: 47.4024848,
        },
        _id: "64f75284ef8f606073e98d35",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "ambohitrakanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.123042,
          lng: 47.4441373,
        },
        _id: "64f75284ef8f606073e98d36",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "antsobolo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.127613,
          lng: 47.3682078,
        },
        _id: "64f75284ef8f606073e98d37",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "antsaha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1422,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e98d38",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimandry",
        fokontany: "amboanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8492296,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e98d39",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "antambolo",
        fokontany: "antambolo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8034301,
          lng: 47.3143897,
        },
        _id: "64f75284ef8f606073e98d3a",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "antambolo",
        fokontany: "ambohibao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8179827,
          lng: 47.3070552,
        },
        _id: "64f75284ef8f606073e98d3b",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "antambolo",
        fokontany: "ambohiboanjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8452448,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e98d3c",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "antambolo",
        fokontany: "ambohimanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.872203,
          lng: 47.2972775,
        },
        _id: "64f75284ef8f606073e98d3d",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "antambolo",
        fokontany: "antanifisaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8,
          lng: 47.23333299999999,
        },
        _id: "64f75284ef8f606073e98d3e",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimasina",
        fokontany: "ambohimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7824787,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e98d3f",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimasina",
        fokontany: "avarabohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7974738,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e98d40",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimasina",
        fokontany: "ambohidrazana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8029807,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e98d41",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimasina",
        fokontany: "ambohipiainana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8109438,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e98d42",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimasina",
        fokontany: "ankadipetaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8219673,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e98d43",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimasina",
        fokontany: "amboangivy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8571852,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e98d44",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohimasina",
        fokontany: "ankarenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1513539,
          lng: 47.3656596,
        },
        _id: "64f75284ef8f606073e98d45",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohipandrano",
        fokontany: "ambohipandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1248509,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e98d46",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohipandrano",
        fokontany: "ankadivory",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1301504,
          lng: 47.3437381,
        },
        _id: "64f75284ef8f606073e98d47",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohipandrano",
        fokontany: "ankonaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.153383,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e98d48",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohipandrano",
        fokontany: "mananetivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1617693,
          lng: 47.3461846,
        },
        _id: "64f75284ef8f606073e98d49",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohipandrano",
        fokontany: "ambohimiandra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1701549,
          lng: 47.37555099999999,
        },
        _id: "64f75284ef8f606073e98d4a",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohipandrano",
        fokontany: "antanetimboahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1773019,
          lng: 47.3131672,
        },
        _id: "64f75284ef8f606073e98d4b",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohipandrano",
        fokontany: "andrianony",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1852771,
          lng: 47.3535246,
        },
        _id: "64f75284ef8f606073e98d4c",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohipandrano",
        fokontany: "mahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1987117,
          lng: 47.37555099999999,
        },
        _id: "64f75284ef8f606073e98d4d",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "ambohipandrano",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2110097,
          lng: 47.3021661,
        },
        _id: "64f75284ef8f606073e98d4e",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miandrandra",
        fokontany: "miandrandra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1707215,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e98d4f",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miandrandra",
        fokontany: "antsiriry",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1698873,
          lng: 47.2997218,
        },
        _id: "64f75284ef8f606073e98d50",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miandrandra",
        fokontany: "antevapotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1925396,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e98d51",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miandrandra",
        fokontany: "anositsiandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1931093,
          lng: 47.3143897,
        },
        _id: "64f75284ef8f606073e98d52",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miandrandra",
        fokontany: "andoharanovelona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1970316,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e98d53",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miandrandra",
        fokontany: "atsimonimanango",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2199716,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e98d54",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miandrandra",
        fokontany: "anorana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2188653,
          lng: 47.3461846,
        },
        _id: "64f75284ef8f606073e98d55",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miandrandra",
        fokontany: "ankadivavala",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2524138,
          lng: 47.2972775,
        },
        _id: "64f75284ef8f606073e98d56",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miandrandra",
        fokontany: "mandavotsiraka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2506872,
          lng: 47.2581852,
        },
        _id: "64f75284ef8f606073e98d57",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miandrandra",
        fokontany: "anosy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1617157,
          lng: 47.4294326,
        },
        _id: "64f75284ef8f606073e98d58",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miantsoarivo",
        fokontany: "miantsoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1314624,
          lng: 47.4318831,
        },
        _id: "64f75284ef8f606073e98d59",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miantsoarivo",
        fokontany: "tsarahasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1488254,
          lng: 47.4343337,
        },
        _id: "64f75284ef8f606073e98d5a",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miantsoarivo",
        fokontany: "ambatofanekena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1404554,
          lng: 47.404934,
        },
        _id: "64f75284ef8f606073e98d5b",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miantsoarivo",
        fokontany: "miakotsorano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1734579,
          lng: 47.4539427,
        },
        _id: "64f75284ef8f606073e98d5c",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miantsoarivo",
        fokontany: "ambohinonoka anosikely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.169018,
          lng: 47.404934,
        },
        _id: "64f75284ef8f606073e98d5d",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miantsoarivo",
        fokontany: "manerinerina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1905735,
          lng: 47.4220818,
        },
        _id: "64f75284ef8f606073e98d5e",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miantsoarivo",
        fokontany: "ambohimidasy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1897182,
          lng: 47.4441373,
        },
        _id: "64f75284ef8f606073e98d5f",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miantsoarivo",
        fokontany: "mamory",
        __v: 0,
      },
      {
        coords: {
          lat: -19.231755,
          lng: 47.4245319,
        },
        _id: "64f75284ef8f606073e98d60",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miantsoarivo",
        fokontany: "manjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2334337,
          lng: 47.380447,
        },
        _id: "64f75284ef8f606073e98d61",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miantsoarivo",
        fokontany: "ankadilalana est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.255005,
          lng: 47.3975867,
        },
        _id: "64f75284ef8f606073e98d62",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miantsoarivo",
        fokontany: "antanimandeha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2333329,
          lng: 47.366667,
        },
        _id: "64f75284ef8f606073e98d63",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miantsoarivo",
        fokontany: "ambohimahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2594691,
          lng: 47.363313,
        },
        _id: "64f75284ef8f606073e98d64",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miantsoarivo",
        fokontany: "andavabato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2670576,
          lng: 47.41473209999999,
        },
        _id: "64f75284ef8f606073e98d65",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miantsoarivo",
        fokontany: "anjanamahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2633742,
          lng: 47.3437381,
        },
        _id: "64f75284ef8f606073e98d66",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miantsoarivo",
        fokontany: "tsarasaotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2715227,
          lng: 47.380447,
        },
        _id: "64f75284ef8f606073e98d67",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miantsoarivo",
        fokontany: "maharidaza sud",
        __v: 0,
      },
      {
        coords: {
          lat: -19.274252,
          lng: 47.3070552,
        },
        _id: "64f75284ef8f606073e98d68",
        province: "antananarivo",
        region: "itasy",
        district: "arivonimamo",
        commune: "miantsoarivo",
        fokontany: "ankafanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9646759,
          lng: 46.914966,
        },
        _id: "64f75284ef8f606073e98d69",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "miarinarivo I",
        fokontany: "atsinananihopitaly",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9495034,
          lng: 46.89919949999999,
        },
        _id: "64f75284ef8f606073e98d6a",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "miarinarivo I",
        fokontany: "andrefanihopitaly",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9382418,
          lng: 46.905263,
        },
        _id: "64f75284ef8f606073e98d6b",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "miarinarivo I",
        fokontany: "ampaisokely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9590968,
          lng: 46.8955618,
        },
        _id: "64f75284ef8f606073e98d6c",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "miarinarivo I",
        fokontany: "antanambao avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9699496,
          lng: 46.9004122,
        },
        _id: "64f75284ef8f606073e98d6d",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "miarinarivo I",
        fokontany: "antanambao atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9818258,
          lng: 46.83497149999999,
        },
        _id: "64f75284ef8f606073e98d6e",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "miarinarivo II",
        fokontany: "ampasamanantongotra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9073628,
          lng: 46.9707947,
        },
        _id: "64f75284ef8f606073e98d6f",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "miarinarivo II",
        fokontany: "antanety",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9362753,
          lng: 46.8737409,
        },
        _id: "64f75284ef8f606073e98d70",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "miarinarivo II",
        fokontany: "ambalalava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9452186,
          lng: 46.9707947,
        },
        _id: "64f75284ef8f606073e98d71",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "miarinarivo II",
        fokontany: "miadana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9651783,
          lng: 46.8591989,
        },
        _id: "64f75284ef8f606073e98d72",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "miarinarivo II",
        fokontany: "amboalefoka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.977502,
          lng: 46.9513691,
        },
        _id: "64f75284ef8f606073e98d73",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "miarinarivo II",
        fokontany: "manankasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9312429,
          lng: 46.839816,
        },
        _id: "64f75284ef8f606073e98d74",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "miarinarivo II",
        fokontany: "igararana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9969747,
          lng: 46.9368047,
        },
        _id: "64f75284ef8f606073e98d75",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "miarinarivo II",
        fokontany: "moraranokely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9985949,
          lng: 46.8931368,
        },
        _id: "64f75284ef8f606073e98d76",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "miarinarivo II",
        fokontany: "antsahamaina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0197741,
          lng: 46.9173921,
        },
        _id: "64f75284ef8f606073e98d77",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "miarinarivo II",
        fokontany: "antsampanimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.880751,
          lng: 46.8666989,
        },
        _id: "64f75284ef8f606073e98d78",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "ambatomanjaka",
        fokontany: "ambatomanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.716667,
          lng: 46.833333,
        },
        _id: "64f75284ef8f606073e98d79",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "ambatomanjaka",
        fokontany: "bedasy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7583395,
          lng: 46.91254010000001,
        },
        _id: "64f75284ef8f606073e98d7a",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "ambatomanjaka",
        fokontany: "andranomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7795311,
          lng: 46.8543525,
        },
        _id: "64f75284ef8f606073e98d7b",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "ambatomanjaka",
        fokontany: "ambaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7817929,
          lng: 46.7962319,
        },
        _id: "64f75284ef8f606073e98d7c",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "ambatomanjaka",
        fokontany: "antambiazina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8127528,
          lng: 46.9707947,
        },
        _id: "64f75284ef8f606073e98d7d",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "ambatomanjaka",
        fokontany: "ambohijafy faliarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8394998,
          lng: 46.9319509,
        },
        _id: "64f75284ef8f606073e98d7e",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "ambatomanjaka",
        fokontany: "andrefanalatsinainy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8773266,
          lng: 46.9319509,
        },
        _id: "64f75284ef8f606073e98d7f",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "ambatomanjaka",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0530786,
          lng: 46.8688931,
        },
        _id: "64f75284ef8f606073e98d80",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "manazary",
        fokontany: "fiankarantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0201614,
          lng: 46.8204406,
        },
        _id: "64f75284ef8f606073e98d81",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "manazary",
        fokontany: "maropapelika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0212081,
          lng: 46.7913915,
        },
        _id: "64f75284ef8f606073e98d82",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "manazary",
        fokontany: "amboniriana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0219064,
          lng: 46.8591989,
        },
        _id: "64f75284ef8f606073e98d83",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "manazary",
        fokontany: "falimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0416066,
          lng: 46.8373937,
        },
        _id: "64f75284ef8f606073e98d84",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "manazary",
        fokontany: "ambohimanambola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0425628,
          lng: 46.89798690000001,
        },
        _id: "64f75284ef8f606073e98d85",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "manazary",
        fokontany: "antsahavory",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0495554,
          lng: 46.7913915,
        },
        _id: "64f75284ef8f606073e98d86",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "manazary",
        fokontany: "avarabohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0569297,
          lng: 46.8495065,
        },
        _id: "64f75284ef8f606073e98d87",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "manazary",
        fokontany: "marosahala",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0656028,
          lng: 46.8713169,
        },
        _id: "64f75284ef8f606073e98d88",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "manazary",
        fokontany: "beapombo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0702308,
          lng: 46.83012739999999,
        },
        _id: "64f75284ef8f606073e98d89",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "manazary",
        fokontany: "ambohimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0786493,
          lng: 46.8591989,
        },
        _id: "64f75284ef8f606073e98d8a",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "manazary",
        fokontany: "masindray",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0840365,
          lng: 46.7962319,
        },
        _id: "64f75284ef8f606073e98d8b",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "manazary",
        fokontany: "anatroa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1187814,
          lng: 46.8834379,
        },
        _id: "64f75284ef8f606073e98d8c",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "antoby est",
        fokontany: "antoby est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0470949,
          lng: 46.9465138,
        },
        _id: "64f75284ef8f606073e98d8d",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "antoby est",
        fokontany: "manjaka est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0414979,
          lng: 46.9270975,
        },
        _id: "64f75284ef8f606073e98d8e",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "antoby est",
        fokontany: "ankafotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0604618,
          lng: 46.9477276,
        },
        _id: "64f75284ef8f606073e98d8f",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "antoby est",
        fokontany: "fihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0842614,
          lng: 46.8785892,
        },
        _id: "64f75284ef8f606073e98d90",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "antoby est",
        fokontany: "antoby andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0954847,
          lng: 46.9173921,
        },
        _id: "64f75284ef8f606073e98d91",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "antoby est",
        fokontany: "sambaina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1011497,
          lng: 46.8470837,
        },
        _id: "64f75284ef8f606073e98d92",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "antoby est",
        fokontany: "andakana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1134184,
          lng: 46.8567756,
        },
        _id: "64f75284ef8f606073e98d93",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "antoby est",
        fokontany: "anosiarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1221075,
          lng: 46.8785892,
        },
        _id: "64f75284ef8f606073e98d94",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "antoby est",
        fokontany: "ambohimanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1238828,
          lng: 46.9173921,
        },
        _id: "64f75284ef8f606073e98d95",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "antoby est",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.133333,
          lng: 46.90000000000001,
        },
        _id: "64f75284ef8f606073e98d96",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "antoby est",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9741829,
          lng: 47.0388429,
        },
        _id: "64f75284ef8f606073e98d97",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "soamahamanina",
        fokontany: "soamahamanina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9385065,
          lng: 47.0631679,
        },
        _id: "64f75284ef8f606073e98d98",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "soamahamanina",
        fokontany: "andranomahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9374186,
          lng: 47.0096682,
        },
        _id: "64f75284ef8f606073e98d99",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "soamahamanina",
        fokontany: "antairoka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9864431,
          lng: 47.0485715,
        },
        _id: "64f75284ef8f606073e98d9a",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "soamahamanina",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0243466,
          lng: 47.0485715,
        },
        _id: "64f75284ef8f606073e98d9b",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "soamahamanina",
        fokontany: "antanetibe ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0165489,
          lng: 47.08750449999999,
        },
        _id: "64f75284ef8f606073e98d9c",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "soamahamanina",
        fokontany: "mandrosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9743658,
          lng: 46.7152173,
        },
        _id: "64f75284ef8f606073e98d9d",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "analavory",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8465157,
          lng: 46.57887729999999,
        },
        _id: "64f75284ef8f606073e98d9e",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "soaronono",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9749354,
          lng: 46.7212587,
        },
        _id: "64f75284ef8f606073e98d9f",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "aminaviavy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8882058,
          lng: 46.64157160000001,
        },
        _id: "64f75284ef8f606073e98da0",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "amboniriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9232929,
          lng: 46.71400920000001,
        },
        _id: "64f75284ef8f606073e98da1",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "ngilomby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9426898,
          lng: 46.6995133,
        },
        _id: "64f75284ef8f606073e98da2",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "tsarazaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9749354,
          lng: 46.7212587,
        },
        _id: "64f75284ef8f606073e98da3",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "amparaky",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9400486,
          lng: 46.7720347,
        },
        _id: "64f75284ef8f606073e98da4",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9389795,
          lng: 46.8010727,
        },
        _id: "64f75284ef8f606073e98da5",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "ambohimanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9477554,
          lng: 46.7333436,
        },
        _id: "64f75284ef8f606073e98da6",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "ankotrabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9647899,
          lng: 46.7841318,
        },
        _id: "64f75284ef8f606073e98da7",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "antanetimboahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9655829,
          lng: 46.7623591,
        },
        _id: "64f75284ef8f606073e98da8",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "mandrevo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9691248,
          lng: 46.7297178,
        },
        _id: "64f75284ef8f606073e98da9",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9833329,
          lng: 46.783333,
        },
        _id: "64f75284ef8f606073e98daa",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "ambohijafy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9870682,
          lng: 46.6898516,
        },
        _id: "64f75284ef8f606073e98dab",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "ankonabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0046628,
          lng: 46.6367462,
        },
        _id: "64f75284ef8f606073e98dac",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "andranomavo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9921541,
          lng: 46.7236754,
        },
        _id: "64f75284ef8f606073e98dad",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "ambatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9962439,
          lng: 46.6078033,
        },
        _id: "64f75284ef8f606073e98dae",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "kianjasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.020732,
          lng: 46.7164256,
        },
        _id: "64f75284ef8f606073e98daf",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "marosoka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0100005,
          lng: 46.7526854,
        },
        _id: "64f75284ef8f606073e98db0",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "ankorondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0245602,
          lng: 46.6970977,
        },
        _id: "64f75284ef8f606073e98db1",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "analavory",
        fokontany: "amparihy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9534429,
          lng: 46.574058,
        },
        _id: "64f75284ef8f606073e98db2",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "alatsinainikely",
        fokontany: "alatsinainikely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8902955,
          lng: 46.5836972,
        },
        _id: "64f75284ef8f606073e98db3",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "alatsinainikely",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.914211,
          lng: 46.61744909999999,
        },
        _id: "64f75284ef8f606073e98db4",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "alatsinainikely",
        fokontany: "andranomody",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9977481,
          lng: 46.5644206,
        },
        _id: "64f75284ef8f606073e98db5",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "alatsinainikely",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9804013,
          lng: 46.5210757,
        },
        _id: "64f75284ef8f606073e98db6",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "alatsinainikely",
        fokontany: "ambatofolaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.09556,
          lng: 47.02058,
        },
        _id: "64f75284ef8f606073e98db7",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "mandiavato",
        fokontany: "mandiavato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0454798,
          lng: 46.9902277,
        },
        _id: "64f75284ef8f606073e98db8",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "mandiavato",
        fokontany: "amboatavo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0477334,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e98db9",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "mandiavato",
        fokontany: "manjakanony",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0526916,
          lng: 46.9659376,
        },
        _id: "64f75284ef8f606073e98dba",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "mandiavato",
        fokontany: "amparibohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0744308,
          lng: 46.9756523,
        },
        _id: "64f75284ef8f606073e98dbb",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "mandiavato",
        fokontany: "amboniriana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.115651,
          lng: 46.9707947,
        },
        _id: "64f75284ef8f606073e98dbc",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "mandiavato",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1196688,
          lng: 47.0339793,
        },
        _id: "64f75284ef8f606073e98dbd",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "mandiavato",
        fokontany: "ambohimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1463545,
          lng: 46.9950872,
        },
        _id: "64f75284ef8f606073e98dbe",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "mandiavato",
        fokontany: "iaranarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.15706,
          lng: 47.0485715,
        },
        _id: "64f75284ef8f606073e98dbf",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "mandiavato",
        fokontany: "ambohimanazaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1503042,
          lng: 47.0169603,
        },
        _id: "64f75284ef8f606073e98dc0",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "mandiavato",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1793617,
          lng: 47.043707,
        },
        _id: "64f75284ef8f606073e98dc1",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "mandiavato",
        fokontany: "faliarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1801788,
          lng: 46.9319509,
        },
        _id: "64f75284ef8f606073e98dc2",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "mandiavato",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1837422,
          lng: 47.0096682,
        },
        _id: "64f75284ef8f606073e98dc3",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "mandiavato",
        fokontany: "anjahana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8842104,
          lng: 46.7502673,
        },
        _id: "64f75284ef8f606073e98dc4",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "anosibe ifanja",
        fokontany: "anosibe centre",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8583289,
          lng: 46.6850215,
        },
        _id: "64f75284ef8f606073e98dc5",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "anosibe ifanja",
        fokontany: "ambatomenarana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.899493,
          lng: 46.7623591,
        },
        _id: "64f75284ef8f606073e98dc6",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "anosibe ifanja",
        fokontany: "ampokonato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8860875,
          lng: 46.6995133,
        },
        _id: "64f75284ef8f606073e98dc7",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "anosibe ifanja",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.900566,
          lng: 46.7333436,
        },
        _id: "64f75284ef8f606073e98dc8",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "anosibe ifanja",
        fokontany: "ampahimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8206574,
          lng: 46.7671967,
        },
        _id: "64f75284ef8f606073e98dc9",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "sarobaratra ifanja",
        fokontany: "sarobaratra ifanja",
        __v: 0,
      },
      {
        coords: {
          lat: -18.795679,
          lng: 46.7623591,
        },
        _id: "64f75284ef8f606073e98dca",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "sarobaratra ifanja",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8156517,
          lng: 46.7333436,
        },
        _id: "64f75284ef8f606073e98dcb",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "sarobaratra ifanja",
        fokontany: "tianarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8384309,
          lng: 46.7962319,
        },
        _id: "64f75284ef8f606073e98dcc",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "sarobaratra ifanja",
        fokontany: "sanganoro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8350634,
          lng: 46.7188421,
        },
        _id: "64f75284ef8f606073e98dcd",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "sarobaratra ifanja",
        fokontany: "ambaiboho",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8572899,
          lng: 46.75510370000001,
        },
        _id: "64f75284ef8f606073e98dce",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "sarobaratra ifanja",
        fokontany: "ampefy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8673024,
          lng: 46.7817122,
        },
        _id: "64f75284ef8f606073e98dcf",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "sarobaratra ifanja",
        fokontany: "fialofa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8727724,
          lng: 47.0485715,
        },
        _id: "64f75284ef8f606073e98dd0",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "zoma bealoka",
        fokontany: "zoma bealoka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8460997,
          lng: 47.0048074,
        },
        _id: "64f75284ef8f606073e98dd1",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "zoma bealoka",
        fokontany: "avarabato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8359043,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e98dd2",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "zoma bealoka",
        fokontany: "antoby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8615599,
          lng: 47.0923732,
        },
        _id: "64f75284ef8f606073e98dd3",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "zoma bealoka",
        fokontany: "mananjara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8544119,
          lng: 47.0339793,
        },
        _id: "64f75284ef8f606073e98dd4",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "zoma bealoka",
        fokontany: "andavabato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8717194,
          lng: 46.9950872,
        },
        _id: "64f75284ef8f606073e98dd5",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "zoma bealoka",
        fokontany: "ambatonahantona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8844395,
          lng: 47.0729012,
        },
        _id: "64f75284ef8f606073e98dd6",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "zoma bealoka",
        fokontany: "ankerana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9001138,
          lng: 46.9950872,
        },
        _id: "64f75284ef8f606073e98dd7",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "zoma bealoka",
        fokontany: "mahajijakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8917133,
          lng: 47.0485715,
        },
        _id: "64f75284ef8f606073e98dd8",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "zoma bealoka",
        fokontany: "analakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.914003,
          lng: 47.043707,
        },
        _id: "64f75284ef8f606073e98dd9",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "zoma bealoka",
        fokontany: "ankidona sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.81184,
          lng: 47.038738,
        },
        _id: "64f75284ef8f606073e98dda",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "soavimbazaha",
        fokontany: "soavimbazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7408309,
          lng: 47.0339793,
        },
        _id: "64f75284ef8f606073e98ddb",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "soavimbazaha",
        fokontany: "sabotsy soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7546121,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e98ddc",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "soavimbazaha",
        fokontany: "bemahatazana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.75,
          lng: 47.05,
        },
        _id: "64f75284ef8f606073e98ddd",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "soavimbazaha",
        fokontany: "ankadinanahary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7568497,
          lng: 47.0279005,
        },
        _id: "64f75284ef8f606073e98dde",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "soavimbazaha",
        fokontany: "vangaina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7701062,
          lng: 47.0120988,
        },
        _id: "64f75284ef8f606073e98ddf",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "soavimbazaha",
        fokontany: "ampamaho",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7802597,
          lng: 47.0729012,
        },
        _id: "64f75284ef8f606073e98de0",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "soavimbazaha",
        fokontany: "bemananony",
        __v: 0,
      },
      {
        coords: {
          lat: -18.766667,
          lng: 46.98333299999999,
        },
        _id: "64f75284ef8f606073e98de1",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "soavimbazaha",
        fokontany: "fanjakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8080763,
          lng: 47.08750449999999,
        },
        _id: "64f75284ef8f606073e98de2",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "soavimbazaha",
        fokontany: "bejofo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8204766,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e98de3",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "soavimbazaha",
        fokontany: "ankidona nord",
        __v: 0,
      },
      {
        coords: {
          lat: -18.75391,
          lng: 46.63568100000001,
        },
        _id: "64f75284ef8f606073e98de4",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "andolofotsy",
        fokontany: "andolofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6964413,
          lng: 46.5644206,
        },
        _id: "64f75284ef8f606073e98de5",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "andolofotsy",
        fokontany: "ambohidrano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6859004,
          lng: 46.6753627,
        },
        _id: "64f75284ef8f606073e98de6",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "andolofotsy",
        fokontany: "beraketa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7186031,
          lng: 46.64157160000001,
        },
        _id: "64f75284ef8f606073e98de7",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "andolofotsy",
        fokontany: "alakamisy nord",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7213518,
          lng: 46.5692391,
        },
        _id: "64f75284ef8f606073e98de8",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "andolofotsy",
        fokontany: "betsinjovina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7219066,
          lng: 46.7188421,
        },
        _id: "64f75284ef8f606073e98de9",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "andolofotsy",
        fokontany: "talata ambatofolaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7806761,
          lng: 46.660878,
        },
        _id: "64f75284ef8f606073e98dea",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "andolofotsy",
        fokontany: "andranovelona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.752903,
          lng: 46.5644206,
        },
        _id: "64f75284ef8f606073e98deb",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "andolofotsy",
        fokontany: "bemangidy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8072458,
          lng: 46.6222727,
        },
        _id: "64f75284ef8f606073e98dec",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "andolofotsy",
        fokontany: "amparihiritra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8433301,
          lng: 46.6657058,
        },
        _id: "64f75284ef8f606073e98ded",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "andolofotsy",
        fokontany: "ambohibary est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8388268,
          lng: 46.61744909999999,
        },
        _id: "64f75284ef8f606073e98dee",
        province: "antananarivo",
        region: "itasy",
        district: "miarinarivo",
        commune: "andolofotsy",
        fokontany: "mangarivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1788869,
          lng: 46.7405959,
        },
        _id: "64f75284ef8f606073e98def",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "antranoroa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0989765,
          lng: 46.7744539,
        },
        _id: "64f75284ef8f606073e98df0",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "antorapasika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0952629,
          lng: 46.7454314,
        },
        _id: "64f75284ef8f606073e98df1",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "ambohimiarintsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1099339,
          lng: 46.7309264,
        },
        _id: "64f75284ef8f606073e98df2",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "ambohitraivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1137464,
          lng: 46.7115929,
        },
        _id: "64f75284ef8f606073e98df3",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "manoroanja",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1128996,
          lng: 46.7817122,
        },
        _id: "64f75284ef8f606073e98df4",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "tanjombato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1233548,
          lng: 46.7526854,
        },
        _id: "64f75284ef8f606073e98df5",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "ampiakarana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1245993,
          lng: 46.7164256,
        },
        _id: "64f75284ef8f606073e98df6",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "merikandrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1320539,
          lng: 46.7744539,
        },
        _id: "64f75284ef8f606073e98df7",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "fiantsonana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1410049,
          lng: 46.7889715,
        },
        _id: "64f75284ef8f606073e98df8",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "ambohimanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1460629,
          lng: 46.7333436,
        },
        _id: "64f75284ef8f606073e98df9",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "tamoronala firaisana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1470473,
          lng: 46.7043448,
        },
        _id: "64f75284ef8f606073e98dfa",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "anosilava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1477808,
          lng: 46.6826067,
        },
        _id: "64f75284ef8f606073e98dfb",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1546854,
          lng: 46.8034933,
        },
        _id: "64f75284ef8f606073e98dfc",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "andranofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1551854,
          lng: 46.7889715,
        },
        _id: "64f75284ef8f606073e98dfd",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "tsinjovary andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1542739,
          lng: 46.7696157,
        },
        _id: "64f75284ef8f606073e98dfe",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1661239,
          lng: 46.7454314,
        },
        _id: "64f75284ef8f606073e98dff",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "soavinandriana ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1683177,
          lng: 46.73878269999999,
        },
        _id: "64f75284ef8f606073e98e00",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "avaratsena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1634248,
          lng: 46.7321349,
        },
        _id: "64f75284ef8f606073e98e01",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "ampitsaharana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.170511,
          lng: 46.7321349,
        },
        _id: "64f75284ef8f606073e98e02",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "ambatombositra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1810313,
          lng: 46.7236754,
        },
        _id: "64f75284ef8f606073e98e03",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "sahapetraka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1820018,
          lng: 46.6946822,
        },
        _id: "64f75284ef8f606073e98e04",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "ambohimita",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1686196,
          lng: 46.8107557,
        },
        _id: "64f75284ef8f606073e98e05",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "fidasiana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1802322,
          lng: 46.7938116,
        },
        _id: "64f75284ef8f606073e98e06",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "ambohitraina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1762468,
          lng: 46.7720347,
        },
        _id: "64f75284ef8f606073e98e07",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "befaritra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1979778,
          lng: 46.7817122,
        },
        _id: "64f75284ef8f606073e98e08",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "ambohidanerana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1895048,
          lng: 46.7526854,
        },
        _id: "64f75284ef8f606073e98e09",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "androhimainty",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2037203,
          lng: 46.7043448,
        },
        _id: "64f75284ef8f606073e98e0a",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "antsahalavabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2112351,
          lng: 46.7623591,
        },
        _id: "64f75284ef8f606073e98e0b",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "andranomanoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2133289,
          lng: 46.7938116,
        },
        _id: "64f75284ef8f606073e98e0c",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "miavotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2092743,
          lng: 46.8204406,
        },
        _id: "64f75284ef8f606073e98e0d",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "ampahimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2263443,
          lng: 46.7817122,
        },
        _id: "64f75284ef8f606073e98e0e",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "mampiherika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2414584,
          lng: 46.8010727,
        },
        _id: "64f75284ef8f606073e98e0f",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "soavinandriana",
        fokontany: "andakana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2011413,
          lng: 46.8737409,
        },
        _id: "64f75284ef8f606073e98e10",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "dondona",
        fokontany: "dondona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1873415,
          lng: 46.9076886,
        },
        _id: "64f75284ef8f606073e98e11",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "dondona",
        fokontany: "tetivato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.215748,
          lng: 46.9076886,
        },
        _id: "64f75284ef8f606073e98e12",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "dondona",
        fokontany: "bevomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1788886,
          lng: 46.8785892,
        },
        _id: "64f75284ef8f606073e98e13",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "dondona",
        fokontany: "ambohitrafovoany",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1704351,
          lng: 46.8495065,
        },
        _id: "64f75284ef8f606073e98e14",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "dondona",
        fokontany: "malailay",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2139281,
          lng: 46.8688931,
        },
        _id: "64f75284ef8f606073e98e15",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "dondona",
        fokontany: "bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1957496,
          lng: 46.8470837,
        },
        _id: "64f75284ef8f606073e98e16",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "dondona",
        fokontany: "madera",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1962479,
          lng: 46.8325494,
        },
        _id: "64f75284ef8f606073e98e17",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "dondona",
        fokontany: "ambohitrakely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1834467,
          lng: 46.5903279,
        },
        _id: "64f75284ef8f606073e98e18",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ampary",
        fokontany: "ampary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0564434,
          lng: 46.6850215,
        },
        _id: "64f75284ef8f606073e98e19",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ampary",
        fokontany: "antsevabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0680387,
          lng: 46.6681199,
        },
        _id: "64f75284ef8f606073e98e1a",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ampary",
        fokontany: "ambodifarihy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.076477,
          lng: 46.6970977,
        },
        _id: "64f75284ef8f606073e98e1b",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ampary",
        fokontany: "aminamontana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0847602,
          lng: 46.6850215,
        },
        _id: "64f75284ef8f606073e98e1c",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ampary",
        fokontany: "gasige",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1130807,
          lng: 46.6850215,
        },
        _id: "64f75284ef8f606073e98e1d",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ampary",
        fokontany: "firaisantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1395566,
          lng: 46.6463975,
        },
        _id: "64f75284ef8f606073e98e1e",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mananasy",
        fokontany: "tsitakondaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0787771,
          lng: 46.5836972,
        },
        _id: "64f75284ef8f606073e98e1f",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mananasy",
        fokontany: "antanimenakely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0895566,
          lng: 46.6367462,
        },
        _id: "64f75284ef8f606073e98e20",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mananasy",
        fokontany: "ambohimanga marotsingala",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1164931,
          lng: 46.5836972,
        },
        _id: "64f75284ef8f606073e98e21",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mananasy",
        fokontany: "anteza firaisana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1178618,
          lng: 46.6367462,
        },
        _id: "64f75284ef8f606073e98e22",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mananasy",
        fokontany: "antsampanimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1499573,
          lng: 46.61744909999999,
        },
        _id: "64f75284ef8f606073e98e23",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mananasy",
        fokontany: "manjakasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1542154,
          lng: 46.5836972,
        },
        _id: "64f75284ef8f606073e98e24",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mananasy",
        fokontany: "mahazove belavenona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1640827,
          lng: 46.6657058,
        },
        _id: "64f75284ef8f606073e98e25",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mananasy",
        fokontany: "andrakimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1773104,
          lng: 46.6463975,
        },
        _id: "64f75284ef8f606073e98e26",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mananasy",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1877001,
          lng: 46.61744909999999,
        },
        _id: "64f75284ef8f606073e98e27",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mananasy",
        fokontany: "ambohimitrena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.201849,
          lng: 46.6657058,
        },
        _id: "64f75284ef8f606073e98e28",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mananasy",
        fokontany: "miarinkofeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2134182,
          lng: 46.6488106,
        },
        _id: "64f75284ef8f606073e98e29",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mananasy",
        fokontany: "andranomafana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2750573,
          lng: 46.7381783,
        },
        _id: "64f75284ef8f606073e98e2a",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "masindray",
        fokontany: "masindray",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2518831,
          lng: 46.7720347,
        },
        _id: "64f75284ef8f606073e98e2b",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "masindray",
        fokontany: "anjanakambony",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3048163,
          lng: 46.6946822,
        },
        _id: "64f75284ef8f606073e98e2c",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "masindray",
        fokontany: "andranomafana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2315849,
          lng: 46.7188421,
        },
        _id: "64f75284ef8f606073e98e2d",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "amparibohitra",
        fokontany: "amparibohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.231106,
          lng: 46.7333436,
        },
        _id: "64f75284ef8f606073e98e2e",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "amparibohitra",
        fokontany: "tsaramasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2320621,
          lng: 46.7043448,
        },
        _id: "64f75284ef8f606073e98e2f",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "amparibohitra",
        fokontany: "ambohitrinibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2793063,
          lng: 46.7043448,
        },
        _id: "64f75284ef8f606073e98e30",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "amparibohitra",
        fokontany: "variana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0438241,
          lng: 46.7309264,
        },
        _id: "64f75284ef8f606073e98e31",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ampefy",
        fokontany: "ampefindradama",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0232735,
          lng: 46.64398449999999,
        },
        _id: "64f75284ef8f606073e98e32",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ampefy",
        fokontany: "vohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0319507,
          lng: 46.6657058,
        },
        _id: "64f75284ef8f606073e98e33",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ampefy",
        fokontany: "manolotiana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0425388,
          lng: 46.6777773,
        },
        _id: "64f75284ef8f606073e98e34",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ampefy",
        fokontany: "ambalavao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.043184,
          lng: 46.7043448,
        },
        _id: "64f75284ef8f606073e98e35",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ampefy",
        fokontany: "avaratriniavo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0348928,
          lng: 46.7164256,
        },
        _id: "64f75284ef8f606073e98e36",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ampefy",
        fokontany: "fikambanantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0329785,
          lng: 46.7260923,
        },
        _id: "64f75284ef8f606073e98e37",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ampefy",
        fokontany: "ambohitrakanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0383336,
          lng: 46.7526854,
        },
        _id: "64f75284ef8f606073e98e38",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ampefy",
        fokontany: "avarabohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0694794,
          lng: 46.7623591,
        },
        _id: "64f75284ef8f606073e98e39",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ampefy",
        fokontany: "manjakasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0574796,
          lng: 46.7454314,
        },
        _id: "64f75284ef8f606073e98e3a",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ampefy",
        fokontany: "faliarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0627106,
          lng: 46.7309264,
        },
        _id: "64f75284ef8f606073e98e3b",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ampefy",
        fokontany: "atalata vaovao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0740652,
          lng: 46.7212587,
        },
        _id: "64f75284ef8f606073e98e3c",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ampefy",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0827521,
          lng: 46.7430136,
        },
        _id: "64f75284ef8f606073e98e3d",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ampefy",
        fokontany: "miadanandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.128119,
          lng: 46.8422385,
        },
        _id: "64f75284ef8f606073e98e3e",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "antanetibe",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1080533,
          lng: 46.83012739999999,
        },
        _id: "64f75284ef8f606073e98e3f",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "antanetibe",
        fokontany: "ambohimizana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1244074,
          lng: 46.81317680000001,
        },
        _id: "64f75284ef8f606073e98e40",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "antanetibe",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1277361,
          lng: 46.8531409,
        },
        _id: "64f75284ef8f606073e98e41",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "antanetibe",
        fokontany: "ambatomitsangana soanavela",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1364245,
          lng: 46.83012739999999,
        },
        _id: "64f75284ef8f606073e98e42",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "antanetibe",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1543291,
          lng: 46.8591989,
        },
        _id: "64f75284ef8f606073e98e43",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "antanetibe",
        fokontany: "ambohimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.15996,
          lng: 46.8785892,
        },
        _id: "64f75284ef8f606073e98e44",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "antanetibe",
        fokontany: "soavimbahoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.16173,
          lng: 46.8277055,
        },
        _id: "64f75284ef8f606073e98e45",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "antanetibe",
        fokontany: "tsinjovary atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.316667,
          lng: 46.633333,
        },
        _id: "64f75284ef8f606073e98e46",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "amparaky",
        fokontany: "amparaky",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2679553,
          lng: 46.6657058,
        },
        _id: "64f75284ef8f606073e98e47",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "amparaky",
        fokontany: "sahapetraka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.278785,
          lng: 46.6222727,
        },
        _id: "64f75284ef8f606073e98e48",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "amparaky",
        fokontany: "antambiazina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3613591,
          lng: 46.5981595,
        },
        _id: "64f75284ef8f606073e98e49",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "amparaky",
        fokontany: "antoby ankerana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3292914,
          lng: 46.61744909999999,
        },
        _id: "64f75284ef8f606073e98e4a",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "amparaky",
        fokontany: "sarodrivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3057388,
          lng: 46.6657058,
        },
        _id: "64f75284ef8f606073e98e4b",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "amparaky",
        fokontany: "ampamaho",
        __v: 0,
      },
      {
        coords: {
          lat: -19.25,
          lng: 46.616667,
        },
        _id: "64f75284ef8f606073e98e4c",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "amberomanga",
        fokontany: "amberomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2160114,
          lng: 46.61744909999999,
        },
        _id: "64f75284ef8f606073e98e4d",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "amberomanga",
        fokontany: "anosibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2424121,
          lng: 46.57887729999999,
        },
        _id: "64f75284ef8f606073e98e4e",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "amberomanga",
        fokontany: "antohomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2334829,
          lng: 46.660878,
        },
        _id: "64f75284ef8f606073e98e4f",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "amberomanga",
        fokontany: "antoby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2735593,
          lng: 46.58851749999999,
        },
        _id: "64f75284ef8f606073e98e50",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "amberomanga",
        fokontany: "maharavoandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0339562,
          lng: 46.6078033,
        },
        _id: "64f75284ef8f606073e98e51",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ankaranana",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0377606,
          lng: 46.58851749999999,
        },
        _id: "64f75284ef8f606073e98e52",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ankaranana",
        fokontany: "manirisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0284919,
          lng: 46.6295089,
        },
        _id: "64f75284ef8f606073e98e53",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ankaranana",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0481709,
          lng: 46.5596027,
        },
        _id: "64f75284ef8f606073e98e54",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ankaranana",
        fokontany: "marofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0574461,
          lng: 46.65605069999999,
        },
        _id: "64f75284ef8f606073e98e55",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ankaranana",
        fokontany: "antsapanimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0501068,
          lng: 46.6198202,
        },
        _id: "64f75284ef8f606073e98e56",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ankaranana",
        fokontany: "ankaranana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.061255,
          lng: 46.6367462,
        },
        _id: "64f75284ef8f606073e98e57",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ankaranana",
        fokontany: "ambovomahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1561009,
          lng: 46.52589,
        },
        _id: "64f75284ef8f606073e98e58",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mahavelona",
        fokontany: "akon'ambohimenabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1094552,
          lng: 46.5114487,
        },
        _id: "64f75284ef8f606073e98e59",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mahavelona",
        fokontany: "andohady",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1014522,
          lng: 46.4681501,
        },
        _id: "64f75284ef8f606073e98e5a",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mahavelona",
        fokontany: "andafiatsimomboay",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1329974,
          lng: 46.4633415,
        },
        _id: "64f75284ef8f606073e98e5b",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mahavelona",
        fokontany: "soaniadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.164084,
          lng: 46.4729592,
        },
        _id: "64f75284ef8f606073e98e5c",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mahavelona",
        fokontany: "masoandromaherana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1773179,
          lng: 46.5499681,
        },
        _id: "64f75284ef8f606073e98e5d",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mahavelona",
        fokontany: "ambatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1885947,
          lng: 46.4922002,
        },
        _id: "64f75284ef8f606073e98e5e",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mahavelona",
        fokontany: "miaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2168648,
          lng: 46.4922002,
        },
        _id: "64f75284ef8f606073e98e5f",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mahavelona",
        fokontany: "bevazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2058365,
          lng: 46.5427434,
        },
        _id: "64f75284ef8f606073e98e60",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mahavelona",
        fokontany: "akon'i firaisana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2108108,
          lng: 46.5836972,
        },
        _id: "64f75284ef8f606073e98e61",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "mahavelona",
        fokontany: "miarinatsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2898601,
          lng: 46.4681501,
        },
        _id: "64f75284ef8f606073e98e62",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ankisabe",
        fokontany: "akon'i samitaha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2333213,
          lng: 46.4681501,
        },
        _id: "64f75284ef8f606073e98e63",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ankisabe",
        fokontany: "soavimbahoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2503717,
          lng: 46.52589,
        },
        _id: "64f75284ef8f606073e98e64",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ankisabe",
        fokontany: "soamiafara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2994757,
          lng: 46.5644206,
        },
        _id: "64f75284ef8f606073e98e65",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ankisabe",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3407008,
          lng: 46.4489184,
        },
        _id: "64f75284ef8f606073e98e66",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ankisabe",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3503879,
          lng: 46.5451516,
        },
        _id: "64f75284ef8f606073e98e67",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ankisabe",
        fokontany: "fenoarivo mahazina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3742689,
          lng: 46.4825787,
        },
        _id: "64f75284ef8f606073e98e68",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ankisabe",
        fokontany: "ankerana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3881319,
          lng: 46.5451516,
        },
        _id: "64f75284ef8f606073e98e69",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ankisabe",
        fokontany: "ambohidroa mahafaly",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1786126,
          lng: 46.4104775,
        },
        _id: "64f75284ef8f606073e98e6a",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ambatoasana centre",
        fokontany: "antsampanimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1466278,
          lng: 46.4296942,
        },
        _id: "64f75284ef8f606073e98e6b",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ambatoasana centre",
        fokontany: "ambohijanaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2044576,
          lng: 46.3864672,
        },
        _id: "64f75284ef8f606073e98e6c",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ambatoasana centre",
        fokontany: "miarinkofeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.20267,
          lng: 46.4441117,
        },
        _id: "64f75284ef8f606073e98e6d",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ambatoasana centre",
        fokontany: "tanamalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2375016,
          lng: 46.4344996,
        },
        _id: "64f75284ef8f606073e98e6e",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "ambatoasana centre",
        fokontany: "vanjan'itsiazomborona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3056839,
          lng: 46.408939,
        },
        _id: "64f75284ef8f606073e98e6f",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "tamponala",
        fokontany: "tamponala",
        __v: 0,
      },
      {
        coords: {
          lat: -19.230722,
          lng: 46.34807499999999,
        },
        _id: "64f75284ef8f606073e98e70",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "tamponala",
        fokontany: "miaramanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2687869,
          lng: 46.3336857,
        },
        _id: "64f75284ef8f606073e98e71",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "tamponala",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2670671,
          lng: 46.3912683,
        },
        _id: "64f75284ef8f606073e98e72",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "tamponala",
        fokontany: "ambalabararata",
        __v: 0,
      },
      {
        coords: {
          lat: -19.296592,
          lng: 46.34807499999999,
        },
        _id: "64f75284ef8f606073e98e73",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "tamponala",
        fokontany: "amparihilava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3260761,
          lng: 46.3049196,
        },
        _id: "64f75284ef8f606073e98e74",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "tamponala",
        fokontany: "miaramasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3743467,
          lng: 46.3720666,
        },
        _id: "64f75284ef8f606073e98e75",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "tamponala",
        fokontany: "ambatolampikely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.377597,
          lng: 46.25701369999999,
        },
        _id: "64f75284ef8f606073e98e76",
        province: "antananarivo",
        region: "itasy",
        district: "soavinandriana",
        commune: "tamponala",
        fokontany: "ambohipihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7711813,
          lng: 46.0467851,
        },
        _id: "64f75284ef8f606073e98e77",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy ville",
        fokontany: "atsimotsena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7711813,
          lng: 46.0467851,
        },
        _id: "64f75284ef8f606073e98e78",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy ville",
        fokontany: "amparihikambana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.76791,
          lng: 46.051497,
        },
        _id: "64f75284ef8f606073e98e79",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy ville",
        fokontany: "mangarivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7711813,
          lng: 46.0467851,
        },
        _id: "64f75284ef8f606073e98e7a",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy ville",
        fokontany: "andrefan'ny gara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7679187,
          lng: 46.05147230000001,
        },
        _id: "64f75284ef8f606073e98e7b",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy ville",
        fokontany: "avaratsena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7711813,
          lng: 46.0467851,
        },
        _id: "64f75284ef8f606073e98e7c",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy ville",
        fokontany: "tsaralalana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7711813,
          lng: 46.0467851,
        },
        _id: "64f75284ef8f606073e98e7d",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy ville",
        fokontany: "anosivola",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7705908,
          lng: 46.0486776,
        },
        _id: "64f75284ef8f606073e98e7e",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy ville",
        fokontany: "tsiroanomandidy atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7711813,
          lng: 46.0467851,
        },
        _id: "64f75284ef8f606073e98e7f",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy ville",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7679187,
          lng: 46.05147230000001,
        },
        _id: "64f75284ef8f606073e98e80",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy ville",
        fokontany: "ankadinakanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7711813,
          lng: 46.0467851,
        },
        _id: "64f75284ef8f606073e98e81",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy ville",
        fokontany: "soanafindra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7711813,
          lng: 46.0467851,
        },
        _id: "64f75284ef8f606073e98e82",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy ville",
        fokontany: "androtra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7711813,
          lng: 46.0467851,
        },
        _id: "64f75284ef8f606073e98e83",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy ville",
        fokontany: "amboasarikely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7819099,
          lng: 46.0485787,
        },
        _id: "64f75284ef8f606073e98e84",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy ville",
        fokontany: "soamahamanina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7711813,
          lng: 46.0467851,
        },
        _id: "64f75284ef8f606073e98e85",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy ville",
        fokontany: "ambohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7711813,
          lng: 46.0467851,
        },
        _id: "64f75284ef8f606073e98e86",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy ville",
        fokontany: "amparihibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8291352,
          lng: 46.1804618,
        },
        _id: "64f75284ef8f606073e98e87",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy fihaonana",
        fokontany: "marolaona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8291733,
          lng: 45.98960779999999,
        },
        _id: "64f75284ef8f606073e98e88",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy fihaonana",
        fokontany: "amboniriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.56139,
          lng: 45.997169,
        },
        _id: "64f75284ef8f606073e98e89",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy fihaonana",
        fokontany: "amparihibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7429942,
          lng: 46.1422309,
        },
        _id: "64f75284ef8f606073e98e8a",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy fihaonana",
        fokontany: "ankaditapaka I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8420694,
          lng: 46.03134,
        },
        _id: "64f75284ef8f606073e98e8b",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy fihaonana",
        fokontany: "ambohidrangory",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6366579,
          lng: 45.97056380000001,
        },
        _id: "64f75284ef8f606073e98e8c",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy fihaonana",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7380021,
          lng: 46.0134234,
        },
        _id: "64f75284ef8f606073e98e8d",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy fihaonana",
        fokontany: "antsampanimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6768945,
          lng: 45.8849587,
        },
        _id: "64f75284ef8f606073e98e8e",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy fihaonana",
        fokontany: "bemangoraka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7191771,
          lng: 46.1088035,
        },
        _id: "64f75284ef8f606073e98e8f",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy fihaonana",
        fokontany: "fiadanamanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7552024,
          lng: 45.7710559,
        },
        _id: "64f75284ef8f606073e98e90",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy fihaonana",
        fokontany: "talata mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.75157,
          lng: 45.8849587,
        },
        _id: "64f75284ef8f606073e98e91",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy fihaonana",
        fokontany: "miadakofeno",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6978128,
          lng: 46.0038958,
        },
        _id: "64f75284ef8f606073e98e92",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy fihaonana",
        fokontany: "mandrosonoro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7674657,
          lng: 45.97056380000001,
        },
        _id: "64f75284ef8f606073e98e93",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy fihaonana",
        fokontany: "analatsifaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7763045,
          lng: 46.0801697,
        },
        _id: "64f75284ef8f606073e98e94",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy fihaonana",
        fokontany: "amparihinomby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6888074,
          lng: 46.0849408,
        },
        _id: "64f75284ef8f606073e98e95",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy fihaonana",
        fokontany: "antsahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7729071,
          lng: 46.1804618,
        },
        _id: "64f75284ef8f606073e98e96",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy fihaonana",
        fokontany: "ambohibary kofay",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7755837,
          lng: 46.1016434,
        },
        _id: "64f75284ef8f606073e98e97",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy fihaonana",
        fokontany: "ambarivatry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8296594,
          lng: 46.0706289,
        },
        _id: "64f75284ef8f606073e98e98",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy fihaonana",
        fokontany: "andranomadio",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7360763,
          lng: 46.0706289,
        },
        _id: "64f75284ef8f606073e98e99",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy fihaonana",
        fokontany: "antanimbaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6776517,
          lng: 46.0467851,
        },
        _id: "64f75284ef8f606073e98e9a",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsiroanomandidy fihaonana",
        fokontany: "morafeno nord",
        __v: 0,
      },
      {
        coords: {
          lat: -18.906499,
          lng: 46.11749710000001,
        },
        _id: "64f75284ef8f606073e98e9b",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambatolampy",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8497829,
          lng: 46.1231267,
        },
        _id: "64f75284ef8f606073e98e9c",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambatolampy",
        fokontany: "ambohimahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.893468,
          lng: 46.1521047,
        },
        _id: "64f75284ef8f606073e98e9d",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambatolampy",
        fokontany: "antaniditra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9270904,
          lng: 46.1470081,
        },
        _id: "64f75284ef8f606073e98e9e",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambatolampy",
        fokontany: "ambohimamory",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9163195,
          lng: 46.1900242,
        },
        _id: "64f75284ef8f606073e98e9f",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambatolampy",
        fokontany: "andranovelona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8947338,
          lng: 46.0849408,
        },
        _id: "64f75284ef8f606073e98ea0",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambatolampy",
        fokontany: "ambatomainty ferme",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9509312,
          lng: 46.0849408,
        },
        _id: "64f75284ef8f606073e98ea1",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambatolampy",
        fokontany: "ambatomainty tanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.03825,
          lng: 46.115452,
        },
        _id: "64f75284ef8f606073e98ea2",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "maritampona",
        fokontany: "maritampona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9884045,
          lng: 46.0849408,
        },
        _id: "64f75284ef8f606073e98ea3",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "maritampona",
        fokontany: "ankitsikitsika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.004754,
          lng: 46.06109,
        },
        _id: "64f75284ef8f606073e98ea4",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "maritampona",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9997118,
          lng: 46.1231267,
        },
        _id: "64f75284ef8f606073e98ea5",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "maritampona",
        fokontany: "amparihimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0160734,
          lng: 46.09925699999999,
        },
        _id: "64f75284ef8f606073e98ea6",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "maritampona",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0742808,
          lng: 46.1374541,
        },
        _id: "64f75284ef8f606073e98ea7",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "maritampona",
        fokontany: "betaikomby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.142359,
          lng: 46.1613426,
        },
        _id: "64f75284ef8f606073e98ea8",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "maritampona",
        fokontany: "zatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6930751,
          lng: 46.2584801,
        },
        _id: "64f75284ef8f606073e98ea9",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bevato",
        fokontany: "bevato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6567241,
          lng: 46.184351,
        },
        _id: "64f75284ef8f606073e98eaa",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bevato",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6233294,
          lng: 46.3432781,
        },
        _id: "64f75284ef8f606073e98eab",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bevato",
        fokontany: "andohanimanambolo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6992249,
          lng: 46.2330783,
        },
        _id: "64f75284ef8f606073e98eac",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bevato",
        fokontany: "ankadinandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7181986,
          lng: 46.3145064,
        },
        _id: "64f75284ef8f606073e98ead",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bevato",
        fokontany: "fihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7166945,
          lng: 46.1804618,
        },
        _id: "64f75284ef8f606073e98eae",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bevato",
        fokontany: "mandrosoa antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6842865,
          lng: 46.2139385,
        },
        _id: "64f75284ef8f606073e98eaf",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bevato",
        fokontany: "fiakarantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7613078,
          lng: 46.3336857,
        },
        _id: "64f75284ef8f606073e98eb0",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bevato",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7521756,
          lng: 46.2378645,
        },
        _id: "64f75284ef8f606073e98eb1",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bevato",
        fokontany: "manakambahiny mandrosoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7352856,
          lng: 46.2755781,
        },
        _id: "64f75284ef8f606073e98eb2",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bevato",
        fokontany: "ambatoantrano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6563596,
          lng: 46.2953347,
        },
        _id: "64f75284ef8f606073e98eb3",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bevato",
        fokontany: "soanafindra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0067261,
          lng: 46.4621394,
        },
        _id: "64f75284ef8f606073e98eb4",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "ankadinondry II",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9943515,
          lng: 46.48017369999999,
        },
        _id: "64f75284ef8f606073e98eb5",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "ankadinondry I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9757721,
          lng: 46.4729592,
        },
        _id: "64f75284ef8f606073e98eb6",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "firaisantsoa mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9042992,
          lng: 46.4537256,
        },
        _id: "64f75284ef8f606073e98eb7",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "soanierana ambohimarina est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9475394,
          lng: 46.4729592,
        },
        _id: "64f75284ef8f606073e98eb8",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "soavimbahoaka marohazo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9244117,
          lng: 46.5066359,
        },
        _id: "64f75284ef8f606073e98eb9",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "tsarafiraisana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8775925,
          lng: 46.4104775,
        },
        _id: "64f75284ef8f606073e98eba",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "diavolana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8344049,
          lng: 46.3912683,
        },
        _id: "64f75284ef8f606073e98ebb",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "anosibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9372995,
          lng: 46.4056745,
        },
        _id: "64f75284ef8f606073e98ebc",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "fanjakamandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.95,
          lng: 46.433333,
        },
        _id: "64f75284ef8f606073e98ebd",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "antsahatanteraka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9610497,
          lng: 46.3984709,
        },
        _id: "64f75284ef8f606073e98ebe",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "tsaramandroso ibory",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9777245,
          lng: 46.415281,
        },
        _id: "64f75284ef8f606073e98ebf",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "andohanakivoka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0092372,
          lng: 46.4104775,
        },
        _id: "64f75284ef8f606073e98ec0",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "ihazomay atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0369825,
          lng: 46.4248894,
        },
        _id: "64f75284ef8f606073e98ec1",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "ambohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0360273,
          lng: 46.4537256,
        },
        _id: "64f75284ef8f606073e98ec2",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "manarintsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0802688,
          lng: 46.4441117,
        },
        _id: "64f75284ef8f606073e98ec3",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "soamihary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.084032,
          lng: 46.4248894,
        },
        _id: "64f75284ef8f606073e98ec4",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "ambalanirana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0618699,
          lng: 46.52589,
        },
        _id: "64f75284ef8f606073e98ec5",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "bonara tiafandrosoana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0256501,
          lng: 46.4825787,
        },
        _id: "64f75284ef8f606073e98ec6",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "tsisoahoanirery",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0086543,
          lng: 46.5210757,
        },
        _id: "64f75284ef8f606073e98ec7",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "manjakamiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9955694,
          lng: 46.4441117,
        },
        _id: "64f75284ef8f606073e98ec8",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "ambohimahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9793179,
          lng: 46.4609373,
        },
        _id: "64f75284ef8f606073e98ec9",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankadinondry sakay",
        fokontany: "ankadinondrikely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.563629,
          lng: 46.11953,
        },
        _id: "64f75284ef8f606073e98eca",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankerana avaratra",
        fokontany: "ambohimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6008278,
          lng: 46.10402999999999,
        },
        _id: "64f75284ef8f606073e98ecb",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankerana avaratra",
        fokontany: "tamponala",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6124903,
          lng: 46.1554148,
        },
        _id: "64f75284ef8f606073e98ecc",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankerana avaratra",
        fokontany: "antanisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5304946,
          lng: 46.1517858,
        },
        _id: "64f75284ef8f606073e98ecd",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankerana avaratra",
        fokontany: "marofarihy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6382454,
          lng: 46.10402999999999,
        },
        _id: "64f75284ef8f606073e98ece",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankerana avaratra",
        fokontany: "ankerana atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5536859,
          lng: 46.1995885,
        },
        _id: "64f75284ef8f606073e98ecf",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ankerana avaratra",
        fokontany: "tsinjoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9087374,
          lng: 46.0372508,
        },
        _id: "64f75284ef8f606073e98ed0",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "miandrarivo",
        fokontany: "miandrarivo I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8689424,
          lng: 46.0134234,
        },
        _id: "64f75284ef8f606073e98ed1",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "miandrarivo",
        fokontany: "ambohimirahavavy toby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8707526,
          lng: 45.9562857,
        },
        _id: "64f75284ef8f606073e98ed2",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "miandrarivo",
        fokontany: "antsahanitavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8852894,
          lng: 45.98960779999999,
        },
        _id: "64f75284ef8f606073e98ed3",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "miandrarivo",
        fokontany: "morafeno sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8345684,
          lng: 45.8184824,
        },
        _id: "64f75284ef8f606073e98ed4",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "miandrarivo",
        fokontany: "morarivo centre",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8757968,
          lng: 45.8944628,
        },
        _id: "64f75284ef8f606073e98ed5",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "miandrarivo",
        fokontany: "andohafarihy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9114188,
          lng: 45.9515272,
        },
        _id: "64f75284ef8f606073e98ed6",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "miandrarivo",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9455627,
          lng: 45.9562857,
        },
        _id: "64f75284ef8f606073e98ed7",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "miandrarivo",
        fokontany: "vohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9601344,
          lng: 45.98960779999999,
        },
        _id: "64f75284ef8f606073e98ed8",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "miandrarivo",
        fokontany: "miandrarivo II",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9886405,
          lng: 45.97532409999999,
        },
        _id: "64f75284ef8f606073e98ed9",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "miandrarivo",
        fokontany: "soamanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.016667,
          lng: 46.033333,
        },
        _id: "64f75284ef8f606073e98eda",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "miandrarivo",
        fokontany: "sarotravoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.919096,
          lng: 46.2953347,
        },
        _id: "64f75284ef8f606073e98edb",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsinjoarivo imanga",
        fokontany: "firaisantsoa imanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8374073,
          lng: 46.3049196,
        },
        _id: "64f75284ef8f606073e98edc",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsinjoarivo imanga",
        fokontany: "soamahatamana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8880062,
          lng: 46.3247398,
        },
        _id: "64f75284ef8f606073e98edd",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsinjoarivo imanga",
        fokontany: "fiadanamanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9082531,
          lng: 46.33848159999999,
        },
        _id: "64f75284ef8f606073e98ede",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsinjoarivo imanga",
        fokontany: "ambatovary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9514535,
          lng: 46.3576702,
        },
        _id: "64f75284ef8f606073e98edf",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsinjoarivo imanga",
        fokontany: "andandihazo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9824632,
          lng: 46.3672674,
        },
        _id: "64f75284ef8f606073e98ee0",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsinjoarivo imanga",
        fokontany: "ampitiliana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9683896,
          lng: 46.3193005,
        },
        _id: "64f75284ef8f606073e98ee1",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsinjoarivo imanga",
        fokontany: "ankijandava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9693359,
          lng: 46.2905429,
        },
        _id: "64f75284ef8f606073e98ee2",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsinjoarivo imanga",
        fokontany: "analamisakana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9205291,
          lng: 46.2522257,
        },
        _id: "64f75284ef8f606073e98ee3",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsinjoarivo imanga",
        fokontany: "fonoraty",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9543151,
          lng: 46.2713806,
        },
        _id: "64f75284ef8f606073e98ee4",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsinjoarivo imanga",
        fokontany: "ambohimarina imanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.944374,
          lng: 46.2243557,
        },
        _id: "64f75284ef8f606073e98ee5",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "tsinjoarivo imanga",
        fokontany: "ampizarantany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6452542,
          lng: 46.25701369999999,
        },
        _id: "64f75284ef8f606073e98ee6",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "soanierana",
        fokontany: "ankazondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6374437,
          lng: 46.2139385,
        },
        _id: "64f75284ef8f606073e98ee7",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "soanierana",
        fokontany: "soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5647538,
          lng: 46.2378645,
        },
        _id: "64f75284ef8f606073e98ee8",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "soanierana",
        fokontany: "analatsifaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5549107,
          lng: 46.3336857,
        },
        _id: "64f75284ef8f606073e98ee9",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "soanierana",
        fokontany: "ankarahara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.508381,
          lng: 46.308601,
        },
        _id: "64f75284ef8f606073e98eea",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "soanierana",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5813548,
          lng: 46.2953347,
        },
        _id: "64f75284ef8f606073e98eeb",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "soanierana",
        fokontany: "analamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.709511,
          lng: 46.38166649999999,
        },
        _id: "64f75284ef8f606073e98eec",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "anosy",
        fokontany: "anosy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7879419,
          lng: 46.3768663,
        },
        _id: "64f75284ef8f606073e98eed",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "anosy",
        fokontany: "amboara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.737169,
          lng: 46.379601,
        },
        _id: "64f75284ef8f606073e98eee",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "anosy",
        fokontany: "androtra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.683333,
          lng: 46.4,
        },
        _id: "64f75284ef8f606073e98eef",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "anosy",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8123117,
          lng: 46.3960699,
        },
        _id: "64f75284ef8f606073e98ef0",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "anosy",
        fokontany: "morafeno ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6891395,
          lng: 46.4248894,
        },
        _id: "64f75284ef8f606073e98ef1",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "anosy",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8234793,
          lng: 46.4344996,
        },
        _id: "64f75284ef8f606073e98ef2",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "anosy",
        fokontany: "ampamantanantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0166718,
          lng: 46.1804618,
        },
        _id: "64f75284ef8f606073e98ef3",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambararatabe",
        fokontany: "ambatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9926486,
          lng: 46.1911436,
        },
        _id: "64f75284ef8f606073e98ef4",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambararatabe",
        fokontany: "ambararatabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9796072,
          lng: 46.1661217,
        },
        _id: "64f75284ef8f606073e98ef5",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambararatabe",
        fokontany: "ankijambe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.054199,
          lng: 46.1804618,
        },
        _id: "64f75284ef8f606073e98ef6",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambararatabe",
        fokontany: "iaboketraka ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0312572,
          lng: 46.2139385,
        },
        _id: "64f75284ef8f606073e98ef7",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambararatabe",
        fokontany: "ambahatra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.026151,
          lng: 46.2761704,
        },
        _id: "64f75284ef8f606073e98ef8",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambararatabe",
        fokontany: "tsaramiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.002186,
          lng: 46.2426511,
        },
        _id: "64f75284ef8f606073e98ef9",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambararatabe",
        fokontany: "soahikambanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9971253,
          lng: 45.6834411,
        },
        _id: "64f75284ef8f606073e98efa",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "belobaka",
        fokontany: "mandrosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8462044,
          lng: 45.6385125,
        },
        _id: "64f75284ef8f606073e98efb",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "belobaka",
        fokontany: "beambiaty",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8662565,
          lng: 45.7995062,
        },
        _id: "64f75284ef8f606073e98efc",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "belobaka",
        fokontany: "ampandrana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9045027,
          lng: 45.5535013,
        },
        _id: "64f75284ef8f606073e98efd",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "belobaka",
        fokontany: "ambohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9109357,
          lng: 45.76157629999999,
        },
        _id: "64f75284ef8f606073e98efe",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "belobaka",
        fokontany: "antsakaviro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9255796,
          lng: 45.685807,
        },
        _id: "64f75284ef8f606073e98eff",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "belobaka",
        fokontany: "antsirasira",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9283989,
          lng: 45.586543,
        },
        _id: "64f75284ef8f606073e98f00",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "belobaka",
        fokontany: "ankerana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9498628,
          lng: 45.5959878,
        },
        _id: "64f75284ef8f606073e98f01",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "belobaka",
        fokontany: "andakana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.956789,
          lng: 45.6810754,
        },
        _id: "64f75284ef8f606073e98f02",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "belobaka",
        fokontany: "andranomavokely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9782693,
          lng: 45.7995062,
        },
        _id: "64f75284ef8f606073e98f03",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "belobaka",
        fokontany: "androtra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9903515,
          lng: 45.5912652,
        },
        _id: "64f75284ef8f606073e98f04",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "belobaka",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9878007,
          lng: 45.6834411,
        },
        _id: "64f75284ef8f606073e98f05",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "belobaka",
        fokontany: "vohimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9742283,
          lng: 45.7236765,
        },
        _id: "64f75284ef8f606073e98f06",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "belobaka",
        fokontany: "manarintsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0155787,
          lng: 45.690539,
        },
        _id: "64f75284ef8f606073e98f07",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "belobaka",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0260752,
          lng: 45.6479676,
        },
        _id: "64f75284ef8f606073e98f08",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "belobaka",
        fokontany: "tsinjoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0261665,
          lng: 45.7568372,
        },
        _id: "64f75284ef8f606073e98f09",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "belobaka",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0941969,
          lng: 45.6574246,
        },
        _id: "64f75284ef8f606073e98f0a",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "belobaka",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1284842,
          lng: 45.7710559,
        },
        _id: "64f75284ef8f606073e98f0b",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "belobaka",
        fokontany: "ambohijatovo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2190533,
          lng: 45.6385125,
        },
        _id: "64f75284ef8f606073e98f0c",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "belobaka",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7733281,
          lng: 46.5210757,
        },
        _id: "64f75284ef8f606073e98f0d",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambalanirana",
        fokontany: "fihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7489543,
          lng: 46.5018235,
        },
        _id: "64f75284ef8f606073e98f0e",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambalanirana",
        fokontany: "ankijana mandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7614354,
          lng: 46.5246864,
        },
        _id: "64f75284ef8f606073e98f0f",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambalanirana",
        fokontany: "mangarivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8099095,
          lng: 46.5499681,
        },
        _id: "64f75284ef8f606073e98f10",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambalanirana",
        fokontany: "ambatomavo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8203722,
          lng: 46.5210757,
        },
        _id: "64f75284ef8f606073e98f11",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambalanirana",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8735302,
          lng: 46.52589,
        },
        _id: "64f75284ef8f606073e98f12",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambalanirana",
        fokontany: "ambohitrimiantsery",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8191439,
          lng: 46.4681501,
        },
        _id: "64f75284ef8f606073e98f13",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambalanirana",
        fokontany: "amboarakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8506635,
          lng: 46.4633415,
        },
        _id: "64f75284ef8f606073e98f14",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambalanirana",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7792681,
          lng: 46.4441117,
        },
        _id: "64f75284ef8f606073e98f15",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambalanirana",
        fokontany: "mahatsinjokely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7959822,
          lng: 46.5018235,
        },
        _id: "64f75284ef8f606073e98f16",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambalanirana",
        fokontany: "ankijambe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.76882,
          lng: 46.4729592,
        },
        _id: "64f75284ef8f606073e98f17",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambalanirana",
        fokontany: "manarintsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7294959,
          lng: 46.4344996,
        },
        _id: "64f75284ef8f606073e98f18",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambalanirana",
        fokontany: "navokobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7378355,
          lng: 46.4633415,
        },
        _id: "64f75284ef8f606073e98f19",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambalanirana",
        fokontany: "tsiazompaniry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7063268,
          lng: 46.4681501,
        },
        _id: "64f75284ef8f606073e98f1a",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambalanirana",
        fokontany: "kankaolo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6742767,
          lng: 46.4873892,
        },
        _id: "64f75284ef8f606073e98f1b",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambalanirana",
        fokontany: "amparihivato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7168894,
          lng: 46.5210757,
        },
        _id: "64f75284ef8f606073e98f1c",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "ambalanirana",
        fokontany: "morafeno est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.127159,
          lng: 46.36404,
        },
        _id: "64f75284ef8f606073e98f1d",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "mahasolo",
        fokontany: "mahasolo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1559116,
          lng: 46.3336857,
        },
        _id: "64f75284ef8f606073e98f1e",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "mahasolo",
        fokontany: "ambohimandroso bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1182991,
          lng: 46.3336857,
        },
        _id: "64f75284ef8f606073e98f1f",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "mahasolo",
        fokontany: "tsarahoby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0863497,
          lng: 46.3528724,
        },
        _id: "64f75284ef8f606073e98f20",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "mahasolo",
        fokontany: "soafierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1131895,
          lng: 46.3960699,
        },
        _id: "64f75284ef8f606073e98f21",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "mahasolo",
        fokontany: "ambohikambana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0430935,
          lng: 46.3336857,
        },
        _id: "64f75284ef8f606073e98f22",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "mahasolo",
        fokontany: "bemahatazana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1,
          lng: 46.283333,
        },
        _id: "64f75284ef8f606073e98f23",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "mahasolo",
        fokontany: "ambararata",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1087482,
          lng: 46.2378645,
        },
        _id: "64f75284ef8f606073e98f24",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "mahasolo",
        fokontany: "maevarano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0655239,
          lng: 46.2187227,
        },
        _id: "64f75284ef8f606073e98f25",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "mahasolo",
        fokontany: "soafianarana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1581159,
          lng: 46.2618022,
        },
        _id: "64f75284ef8f606073e98f26",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "mahasolo",
        fokontany: "fenomanana fenoarivokely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1406356,
          lng: 46.2187227,
        },
        _id: "64f75284ef8f606073e98f27",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "mahasolo",
        fokontany: "bezavona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2164886,
          lng: 46.3001269,
        },
        _id: "64f75284ef8f606073e98f28",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "mahasolo",
        fokontany: "mandrosoarivo amparihikambana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1878453,
          lng: 46.3145064,
        },
        _id: "64f75284ef8f606073e98f29",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "mahasolo",
        fokontany: "andavabary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1806219,
          lng: 46.3456765,
        },
        _id: "64f75284ef8f606073e98f2a",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "mahasolo",
        fokontany: "anosiarivo sakay",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0416932,
          lng: 46.3768663,
        },
        _id: "64f75284ef8f606073e98f2b",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "mahasolo",
        fokontany: "kianjasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0633256,
          lng: 46.3864672,
        },
        _id: "64f75284ef8f606073e98f2c",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "mahasolo",
        fokontany: "antokomaro BCl",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0055004,
          lng: 46.3336857,
        },
        _id: "64f75284ef8f606073e98f2d",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "mahasolo",
        fokontany: "ambohimiarintsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0724516,
          lng: 45.98960779999999,
        },
        _id: "64f75284ef8f606073e98f2e",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bemahatazana",
        fokontany: "bemahatazana I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9270646,
          lng: 45.84696100000001,
        },
        _id: "64f75284ef8f606073e98f2f",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bemahatazana",
        fokontany: "soafiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.987094,
          lng: 45.92298659999999,
        },
        _id: "64f75284ef8f606073e98f30",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bemahatazana",
        fokontany: "andriambe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0391735,
          lng: 45.84696100000001,
        },
        _id: "64f75284ef8f606073e98f31",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bemahatazana",
        fokontany: "bemahatazana II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0552905,
          lng: 46.0420177,
        },
        _id: "64f75284ef8f606073e98f32",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bemahatazana",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0951815,
          lng: 46.0658592,
        },
        _id: "64f75284ef8f606073e98f33",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bemahatazana",
        fokontany: "ambatofotsy feo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.099319,
          lng: 45.817108,
        },
        _id: "64f75284ef8f606073e98f34",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bemahatazana",
        fokontany: "fiakarantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1155957,
          lng: 46.0086594,
        },
        _id: "64f75284ef8f606073e98f35",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bemahatazana",
        fokontany: "tsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1367633,
          lng: 45.92298659999999,
        },
        _id: "64f75284ef8f606073e98f36",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bemahatazana",
        fokontany: "tindoha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.138363,
          lng: 46.0849408,
        },
        _id: "64f75284ef8f606073e98f37",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bemahatazana",
        fokontany: "ambatofotsy est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.164471,
          lng: 45.994629,
        },
        _id: "64f75284ef8f606073e98f38",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bemahatazana",
        fokontany: "ambalavatokely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.355909,
          lng: 46.00369999999999,
        },
        _id: "64f75284ef8f606073e98f39",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "bemahatazana",
        fokontany: "mazamiempo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.545099,
          lng: 45.845032,
        },
        _id: "64f75284ef8f606073e98f3a",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "fierenana",
        fokontany: "fierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2510803,
          lng: 45.6385125,
        },
        _id: "64f75284ef8f606073e98f3b",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "fierenana",
        fokontany: "ambaravaranala",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5093132,
          lng: 45.6952715,
        },
        _id: "64f75284ef8f606073e98f3c",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "fierenana",
        fokontany: "mandrosoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4002076,
          lng: 45.7995062,
        },
        _id: "64f75284ef8f606073e98f3d",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "fierenana",
        fokontany: "ankadimena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4595927,
          lng: 45.8754564,
        },
        _id: "64f75284ef8f606073e98f3e",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "fierenana",
        fokontany: "bory bepoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5044281,
          lng: 45.83746619999999,
        },
        _id: "64f75284ef8f606073e98f3f",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "fierenana",
        fokontany: "antsanatry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.515528,
          lng: 45.8754564,
        },
        _id: "64f75284ef8f606073e98f40",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "fierenana",
        fokontany: "borikely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5850495,
          lng: 45.8422134,
        },
        _id: "64f75284ef8f606073e98f41",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "fierenana",
        fokontany: "ambohimahazo mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5949179,
          lng: 45.73314860000001,
        },
        _id: "64f75284ef8f606073e98f42",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "fierenana",
        fokontany: "mahazina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6293214,
          lng: 45.6290592,
        },
        _id: "64f75284ef8f606073e98f43",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "fierenana",
        fokontany: "bepoaka taloha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6730307,
          lng: 45.6196079,
        },
        _id: "64f75284ef8f606073e98f44",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "fierenana",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3048118,
          lng: 46.1709012,
        },
        _id: "64f75284ef8f606073e98f45",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "maroharona",
        fokontany: "maroharona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2280178,
          lng: 46.2282927,
        },
        _id: "64f75284ef8f606073e98f46",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "maroharona",
        fokontany: "analamiranga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2835702,
          lng: 46.25701369999999,
        },
        _id: "64f75284ef8f606073e98f47",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "maroharona",
        fokontany: "fianarantsoa belanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3342536,
          lng: 46.2378645,
        },
        _id: "64f75284ef8f606073e98f48",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "maroharona",
        fokontany: "fianarana volanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3040057,
          lng: 46.1995885,
        },
        _id: "64f75284ef8f606073e98f49",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "maroharona",
        fokontany: "ambohimandroso mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3439494,
          lng: 46.1135774,
        },
        _id: "64f75284ef8f606073e98f4a",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "maroharona",
        fokontany: "kambatsoa conde",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2427538,
          lng: 46.1517858,
        },
        _id: "64f75284ef8f606073e98f4b",
        province: "antananarivo",
        region: "bongolava",
        district: "tsiroanomandidy",
        commune: "maroharona",
        fokontany: "ampasipotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.45356,
          lng: 46.563728,
        },
        _id: "64f75284ef8f606073e98f4c",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "fenoarivo centre",
        fokontany: "fenoarivo centre",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3198208,
          lng: 46.4296942,
        },
        _id: "64f75284ef8f606073e98f4d",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "fenoarivo centre",
        fokontany: "ambalarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.55439,
          lng: 46.681061,
        },
        _id: "64f75284ef8f606073e98f4e",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "fenoarivo centre",
        fokontany: "ankotrabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4386385,
          lng: 46.5836972,
        },
        _id: "64f75284ef8f606073e98f4f",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "fenoarivo centre",
        fokontany: "ambohitsaratelo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2144616,
          lng: 46.5547852,
        },
        _id: "64f75284ef8f606073e98f50",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "fenoarivo centre",
        fokontany: "imerimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3840527,
          lng: 46.6898516,
        },
        _id: "64f75284ef8f606073e98f51",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "fenoarivo centre",
        fokontany: "miadanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5935727,
          lng: 46.4681501,
        },
        _id: "64f75284ef8f606073e98f52",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "fenoarivo centre",
        fokontany: "miadampahonina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4270983,
          lng: 46.5596027,
        },
        _id: "64f75284ef8f606073e98f53",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "fenoarivo centre",
        fokontany: "andranomirafy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4433341,
          lng: 46.4681501,
        },
        _id: "64f75284ef8f606073e98f54",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "fenoarivo centre",
        fokontany: "ambatomitsangambe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5393028,
          lng: 46.574058,
        },
        _id: "64f75284ef8f606073e98f55",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "fenoarivo centre",
        fokontany: "aminampanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.533333,
          lng: 46.4,
        },
        _id: "64f75284ef8f606073e98f56",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "fenoarivo centre",
        fokontany: "analasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4795666,
          lng: 46.57887729999999,
        },
        _id: "64f75284ef8f606073e98f57",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "fenoarivo centre",
        fokontany: "mangarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4918704,
          lng: 46.5066359,
        },
        _id: "64f75284ef8f606073e98f58",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "fenoarivo centre",
        fokontany: "tsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3767945,
          lng: 46.5644206,
        },
        _id: "64f75284ef8f606073e98f59",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "fenoarivo centre",
        fokontany: "ambonivondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6046176,
          lng: 46.5066359,
        },
        _id: "64f75284ef8f606073e98f5a",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "fenoarivo centre",
        fokontany: "anonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4357969,
          lng: 46.3432781,
        },
        _id: "64f75284ef8f606073e98f5b",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "fenoarivo centre",
        fokontany: "ankadibevava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6272807,
          lng: 46.8834379,
        },
        _id: "64f75284ef8f606073e98f5c",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "firavahana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.490923,
          lng: 46.83497149999999,
        },
        _id: "64f75284ef8f606073e98f5d",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "tsaramasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5207238,
          lng: 46.8737409,
        },
        _id: "64f75284ef8f606073e98f5e",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "miandrarivo II",
        __v: 0,
      },
      {
        coords: {
          lat: -18.533333,
          lng: 46.9166669,
        },
        _id: "64f75284ef8f606073e98f5f",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "tsiafakarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5718547,
          lng: 46.8543525,
        },
        _id: "64f75284ef8f606073e98f60",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "tsianovana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5632722,
          lng: 46.7575221,
        },
        _id: "64f75284ef8f606073e98f61",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "mangatany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5803855,
          lng: 46.9513691,
        },
        _id: "64f75284ef8f606073e98f62",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "ambohipihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5365429,
          lng: 46.7962319,
        },
        _id: "64f75284ef8f606073e98f63",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "ambohikambana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.601132,
          lng: 46.6753627,
        },
        _id: "64f75284ef8f606073e98f64",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "marohova",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6055201,
          lng: 46.914966,
        },
        _id: "64f75284ef8f606073e98f65",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "tsimatahodaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.597145,
          lng: 46.9270975,
        },
        _id: "64f75284ef8f606073e98f66",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6244401,
          lng: 46.64157160000001,
        },
        _id: "64f75284ef8f606073e98f67",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "mahaimandry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6179582,
          lng: 46.8422385,
        },
        _id: "64f75284ef8f606073e98f68",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "tafaina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6221463,
          lng: 46.6995133,
        },
        _id: "64f75284ef8f606073e98f69",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "soaloka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6269816,
          lng: 46.8907119,
        },
        _id: "64f75284ef8f606073e98f6a",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "bemahatazana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6215245,
          lng: 46.9465138,
        },
        _id: "64f75284ef8f606073e98f6b",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6174728,
          lng: 46.815598,
        },
        _id: "64f75284ef8f606073e98f6c",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6443713,
          lng: 46.9270975,
        },
        _id: "64f75284ef8f606073e98f6d",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6505718,
          lng: 46.7744539,
        },
        _id: "64f75284ef8f606073e98f6e",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "miandrarivo I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6400673,
          lng: 46.8785892,
        },
        _id: "64f75284ef8f606073e98f6f",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "manantiahitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6882614,
          lng: 46.9319509,
        },
        _id: "64f75284ef8f606073e98f70",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "ambohibary est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6617025,
          lng: 46.8882871,
        },
        _id: "64f75284ef8f606073e98f71",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "bemasoandro est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6762517,
          lng: 46.839816,
        },
        _id: "64f75284ef8f606073e98f72",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "andranomanoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6719013,
          lng: 46.7913915,
        },
        _id: "64f75284ef8f606073e98f73",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "antoby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6962882,
          lng: 46.8107557,
        },
        _id: "64f75284ef8f606073e98f74",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "mandrosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6961337,
          lng: 46.8931368,
        },
        _id: "64f75284ef8f606073e98f75",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "firavahana",
        fokontany: "morafeno I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.691719,
          lng: 47.035751,
        },
        _id: "64f75284ef8f606073e98f76",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "morarano maritampona",
        fokontany: "morarano maritampona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7271139,
          lng: 47.0607349,
        },
        _id: "64f75284ef8f606073e98f77",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "morarano maritampona",
        fokontany: "antsahamafana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7204017,
          lng: 47.07046769999999,
        },
        _id: "64f75284ef8f606073e98f78",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "morarano maritampona",
        fokontany: "ambodiriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.701775,
          lng: 47.0631679,
        },
        _id: "64f75284ef8f606073e98f79",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "morarano maritampona",
        fokontany: "ankadivory maroandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7124454,
          lng: 47.0339793,
        },
        _id: "64f75284ef8f606073e98f7a",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "morarano maritampona",
        fokontany: "andranovelona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7270481,
          lng: 46.9853688,
        },
        _id: "64f75284ef8f606073e98f7b",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "morarano maritampona",
        fokontany: "fenoarivokely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7014335,
          lng: 46.9950872,
        },
        _id: "64f75284ef8f606073e98f7c",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "morarano maritampona",
        fokontany: "morafeno II",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6782625,
          lng: 47.0218223,
        },
        _id: "64f75284ef8f606073e98f7d",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "morarano maritampona",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6755134,
          lng: 47.0120988,
        },
        _id: "64f75284ef8f606073e98f7e",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "morarano maritampona",
        fokontany: "morafeno III",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6501946,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e98f7f",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "morarano maritampona",
        fokontany: "antanety III",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6730661,
          lng: 46.9950872,
        },
        _id: "64f75284ef8f606073e98f80",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "morarano maritampona",
        fokontany: "ampanobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6648172,
          lng: 46.9659376,
        },
        _id: "64f75284ef8f606073e98f81",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "morarano maritampona",
        fokontany: "soaniadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6392107,
          lng: 46.9756523,
        },
        _id: "64f75284ef8f606073e98f82",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "morarano maritampona",
        fokontany: "anosimborona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6108584,
          lng: 46.9756523,
        },
        _id: "64f75284ef8f606073e98f83",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "morarano maritampona",
        fokontany: "miadamanjaka miantso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6302172,
          lng: 47.00237720000001,
        },
        _id: "64f75284ef8f606073e98f84",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "morarano maritampona",
        fokontany: "mandriankeniheny",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6123734,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e98f85",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "morarano maritampona",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2991063,
          lng: 46.0521489,
        },
        _id: "64f75284ef8f606073e98f86",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "kiranomena",
        fokontany: "kiranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.116667,
          lng: 46.1,
        },
        _id: "64f75284ef8f606073e98f87",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "kiranomena",
        fokontany: "ankoririka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1795798,
          lng: 46.1995885,
        },
        _id: "64f75284ef8f606073e98f88",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "kiranomena",
        fokontany: "ankaditsaravala",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1768005,
          lng: 45.9610446,
        },
        _id: "64f75284ef8f606073e98f89",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "kiranomena",
        fokontany: "ambohitsivalana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1733718,
          lng: 46.0467851,
        },
        _id: "64f75284ef8f606073e98f8a",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "kiranomena",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2531764,
          lng: 46.2282927,
        },
        _id: "64f75284ef8f606073e98f8b",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "kiranomena",
        fokontany: "antsahatanteraka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2161372,
          lng: 46.0658592,
        },
        _id: "64f75284ef8f606073e98f8c",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "kiranomena",
        fokontany: "mandrarahody",
        __v: 0,
      },
      {
        coords: {
          lat: -18.244764,
          lng: 45.97056380000001,
        },
        _id: "64f75284ef8f606073e98f8d",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "kiranomena",
        fokontany: "mangavilany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2204459,
          lng: 46.1135774,
        },
        _id: "64f75284ef8f606073e98f8e",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "kiranomena",
        fokontany: "mandrosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.283649,
          lng: 46.16876999999999,
        },
        _id: "64f75284ef8f606073e98f8f",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "kiranomena",
        fokontany: "analavory",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2775994,
          lng: 46.0849408,
        },
        _id: "64f75284ef8f606073e98f90",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "kiranomena",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.31739,
          lng: 46.21670899999999,
        },
        _id: "64f75284ef8f606073e98f91",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "kiranomena",
        fokontany: "ambatosariomby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.380866,
          lng: 46.1517858,
        },
        _id: "64f75284ef8f606073e98f92",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "kiranomena",
        fokontany: "ambatofotsy be",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4,
          lng: 46.066667,
        },
        _id: "64f75284ef8f606073e98f93",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "kiranomena",
        fokontany: "amparihitany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.352421,
          lng: 45.847759,
        },
        _id: "64f75284ef8f606073e98f94",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "tsinjoarivo 22",
        fokontany: "tsinjoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1560955,
          lng: 45.8564576,
        },
        _id: "64f75284ef8f606073e98f95",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "tsinjoarivo 22",
        fokontany: "ambararatabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2042624,
          lng: 45.8137377,
        },
        _id: "64f75284ef8f606073e98f96",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "tsinjoarivo 22",
        fokontany: "mangasoavina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2228724,
          lng: 45.8944628,
        },
        _id: "64f75284ef8f606073e98f97",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "tsinjoarivo 22",
        fokontany: "mahasambo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2929575,
          lng: 45.84696100000001,
        },
        _id: "64f75284ef8f606073e98f98",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "tsinjoarivo 22",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3235125,
          lng: 45.7757965,
        },
        _id: "64f75284ef8f606073e98f99",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "tsinjoarivo 22",
        fokontany: "ankazoavo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4268013,
          lng: 45.92298659999999,
        },
        _id: "64f75284ef8f606073e98f9a",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "tsinjoarivo 22",
        fokontany: "betaimboraka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.052679,
          lng: 45.9967,
        },
        _id: "64f75284ef8f606073e98f9b",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "mahajeby",
        fokontany: "mahajeby",
        __v: 0,
      },
      {
        coords: {
          lat: -17.938911,
          lng: 46.0086594,
        },
        _id: "64f75284ef8f606073e98f9c",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "mahajeby",
        fokontany: "ambohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -17.940781,
          lng: 45.94455,
        },
        _id: "64f75284ef8f606073e98f9d",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "mahajeby",
        fokontany: "antanimbaritsara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0914109,
          lng: 45.92298659999999,
        },
        _id: "64f75284ef8f606073e98f9e",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "mahajeby",
        fokontany: "kamolandy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.966667,
          lng: 46.316667,
        },
        _id: "64f75284ef8f606073e98f9f",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "ambohitromby",
        fokontany: "ambohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7694572,
          lng: 46.4537256,
        },
        _id: "64f75284ef8f606073e98fa0",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "ambohitromby",
        fokontany: "kingoribe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8061739,
          lng: 46.4681501,
        },
        _id: "64f75284ef8f606073e98fa1",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "ambohitromby",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.838938,
          lng: 46.4344996,
        },
        _id: "64f75284ef8f606073e98fa2",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "ambohitromby",
        fokontany: "bezahatra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8222688,
          lng: 46.3912683,
        },
        _id: "64f75284ef8f606073e98fa3",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "ambohitromby",
        fokontany: "andranomavokely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8569691,
          lng: 46.3145064,
        },
        _id: "64f75284ef8f606073e98fa4",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "ambohitromby",
        fokontany: "antsahanavony",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8616549,
          lng: 46.415281,
        },
        _id: "64f75284ef8f606073e98fa5",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "ambohitromby",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8836959,
          lng: 46.4104775,
        },
        _id: "64f75284ef8f606073e98fa6",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "ambohitromby",
        fokontany: "ambatomainty nord",
        __v: 0,
      },
      {
        coords: {
          lat: -17.905039,
          lng: 46.3528724,
        },
        _id: "64f75284ef8f606073e98fa7",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "ambohitromby",
        fokontany: "antsahatanteraka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9893765,
          lng: 46.5547852,
        },
        _id: "64f75284ef8f606073e98fa8",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "ambohitromby",
        fokontany: "andasibe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.954121,
          lng: 46.410259,
        },
        _id: "64f75284ef8f606073e98fa9",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "ambohitromby",
        fokontany: "ankadilalandrevaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.02006,
          lng: 46.42086,
        },
        _id: "64f75284ef8f606073e98faa",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "ambohitromby",
        fokontany: "kelilalina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0529907,
          lng: 46.2474381,
        },
        _id: "64f75284ef8f606073e98fab",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "ambohitromby",
        fokontany: "kelimahery",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0359284,
          lng: 46.3528724,
        },
        _id: "64f75284ef8f606073e98fac",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "ambohitromby",
        fokontany: "antokotoko",
        __v: 0,
      },
      {
        coords: {
          lat: -18.066667,
          lng: 46.316667,
        },
        _id: "64f75284ef8f606073e98fad",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "ambohitromby",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1844123,
          lng: 46.38166649999999,
        },
        _id: "64f75284ef8f606073e98fae",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "ambatomainty atsimo",
        fokontany: "ambatomainty atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1560691,
          lng: 46.3145064,
        },
        _id: "64f75284ef8f606073e98faf",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "ambatomainty atsimo",
        fokontany: "mahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2339552,
          lng: 46.3912683,
        },
        _id: "64f75284ef8f606073e98fb0",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "ambatomainty atsimo",
        fokontany: "andravoahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.13114,
          lng: 46.4585333,
        },
        _id: "64f75284ef8f606073e98fb1",
        province: "antananarivo",
        region: "bongolava",
        district: "fenoarivobe",
        commune: "ambatomainty atsimo",
        fokontany: "idoko",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4562726,
          lng: 47.08141930000001,
        },
        _id: "64f75284ef8f606073e98fb2",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambony",
        fokontany: "ambatolahikisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4548474,
          lng: 47.06560109999999,
        },
        _id: "64f75284ef8f606073e98fb3",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambony",
        fokontany: "ambalandapa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.453961,
          lng: 47.074118,
        },
        _id: "64f75284ef8f606073e98fb4",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambony",
        fokontany: "andohanatady",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4530349,
          lng: 47.0862874,
        },
        _id: "64f75284ef8f606073e98fb5",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambony",
        fokontany: "ambalapaiso",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4554047,
          lng: 47.0881131,
        },
        _id: "64f75284ef8f606073e98fb6",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambony",
        fokontany: "isaha",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4601737,
          lng: 47.0890259,
        },
        _id: "64f75284ef8f606073e98fb7",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambony",
        fokontany: "ampitakely",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4659354,
          lng: 47.0902431,
        },
        _id: "64f75284ef8f606073e98fb8",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambony",
        fokontany: "ambatovory",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4661957,
          lng: 47.0844618,
        },
        _id: "64f75284ef8f606073e98fb9",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambony",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4614557,
          lng: 47.08081079999999,
        },
        _id: "64f75284ef8f606073e98fba",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambony",
        fokontany: "ambodiharana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4607235,
          lng: 47.0747264,
        },
        _id: "64f75284ef8f606073e98fbb",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambony",
        fokontany: "rova",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4265095,
          lng: 47.1081997,
        },
        _id: "64f75284ef8f606073e98fbc",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambany",
        fokontany: "ankofafa ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4366748,
          lng: 47.0893302,
        },
        _id: "64f75284ef8f606073e98fbd",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambany",
        fokontany: "sahalava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4295867,
          lng: 47.11794159999999,
        },
        _id: "64f75284ef8f606073e98fbe",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambany",
        fokontany: "talatamaty",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4353799,
          lng: 47.0984598,
        },
        _id: "64f75284ef8f606073e98fbf",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambany",
        fokontany: "antsororokavo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4411081,
          lng: 47.0844618,
        },
        _id: "64f75284ef8f606073e98fc0",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambany",
        fokontany: "mokana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4422533,
          lng: 47.0890259,
        },
        _id: "64f75284ef8f606073e98fc1",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambany",
        fokontany: "antarandolo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4450771,
          lng: 47.0856789,
        },
        _id: "64f75284ef8f606073e98fc2",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambany",
        fokontany: "tambohobe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4459456,
          lng: 47.0789854,
        },
        _id: "64f75284ef8f606073e98fc3",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambany",
        fokontany: "ambatomena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4481773,
          lng: 47.0935905,
        },
        _id: "64f75284ef8f606073e98fc4",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambany",
        fokontany: "ivory",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4553655,
          lng: 47.0917646,
        },
        _id: "64f75284ef8f606073e98fc5",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambany",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4601445,
          lng: 47.0917646,
        },
        _id: "64f75284ef8f606073e98fc6",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "tanana ambany",
        fokontany: "isada",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4456482,
          lng: 47.1063734,
        },
        _id: "64f75284ef8f606073e98fc7",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "andrainjato avaratra",
        fokontany: "idanda",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4351363,
          lng: 47.1203773,
        },
        _id: "64f75284ef8f606073e98fc8",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "andrainjato avaratra",
        fokontany: "beravina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4414148,
          lng: 47.1289033,
        },
        _id: "64f75284ef8f606073e98fc9",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "andrainjato avaratra",
        fokontany: "anjaninoro",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4572124,
          lng: 47.1051558,
        },
        _id: "64f75284ef8f606073e98fca",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "andrainjato avaratra",
        fokontany: "antanifotsy V",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4511379,
          lng: 47.1142882,
        },
        _id: "64f75284ef8f606073e98fcb",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "andrainjato avaratra",
        fokontany: "tsimanarirazana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4589984,
          lng: 47.1240312,
        },
        _id: "64f75284ef8f606073e98fcc",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "andrainjato avaratra",
        fokontany: "ambatolahy V",
        __v: 0,
      },
      {
        coords: {
          lat: -21.46775,
          lng: 47.1252492,
        },
        _id: "64f75284ef8f606073e98fcd",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "andrainjato avaratra",
        fokontany: "ambalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4643821,
          lng: 47.1051558,
        },
        _id: "64f75284ef8f606073e98fce",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "andrainjato sud",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4561064,
          lng: 47.0969381,
        },
        _id: "64f75284ef8f606073e98fcf",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "andrainjato sud",
        fokontany: "soatsihadino",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4592762,
          lng: 47.0984598,
        },
        _id: "64f75284ef8f606073e98fd0",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "andrainjato sud",
        fokontany: "igaga",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4724003,
          lng: 47.1002859,
        },
        _id: "64f75284ef8f606073e98fd1",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "andrainjato sud",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4862194,
          lng: 47.1118527,
        },
        _id: "64f75284ef8f606073e98fd2",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "andrainjato sud",
        fokontany: "ambodikavola",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4258623,
          lng: 47.0948078,
        },
        _id: "64f75284ef8f606073e98fd3",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "manolafaka",
        fokontany: "ambalavato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4067473,
          lng: 47.0948078,
        },
        _id: "64f75284ef8f606073e98fd4",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "manolafaka",
        fokontany: "maromby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4116895,
          lng: 47.0802023,
        },
        _id: "64f75284ef8f606073e98fd5",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "manolafaka",
        fokontany: "andriamboasary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4244842,
          lng: 47.0753348,
        },
        _id: "64f75284ef8f606073e98fd6",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "manolafaka",
        fokontany: "ambahisamotra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4223751,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e98fd7",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "manolafaka",
        fokontany: "ankofafalahy ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4739203,
          lng: 47.069251,
        },
        _id: "64f75284ef8f606073e98fd8",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "lalazana",
        fokontany: "mandriandalana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4708357,
          lng: 47.05951839999999,
        },
        _id: "64f75284ef8f606073e98fd9",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "lalazana",
        fokontany: "amontana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4818211,
          lng: 47.0753348,
        },
        _id: "64f75284ef8f606073e98fda",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "lalazana",
        fokontany: "ambatofolaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4867483,
          lng: 47.0607349,
        },
        _id: "64f75284ef8f606073e98fdb",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "lalazana",
        fokontany: "mahazengy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4836618,
          lng: 47.051004,
        },
        _id: "64f75284ef8f606073e98fdc",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "lalazana",
        fokontany: "ambodiharana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4976999,
          lng: 47.0802023,
        },
        _id: "64f75284ef8f606073e98fdd",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "lalazana",
        fokontany: "ambatolahy II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4927709,
          lng: 47.0948078,
        },
        _id: "64f75284ef8f606073e98fde",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "lalazana",
        fokontany: "sahamavo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4294202,
          lng: 47.0607349,
        },
        _id: "64f75284ef8f606073e98fdf",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "vatosola",
        fokontany: "ambalambositra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4405148,
          lng: 47.06560109999999,
        },
        _id: "64f75284ef8f606073e98fe0",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "vatosola",
        fokontany: "ambalamarina kianjasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4454467,
          lng: 47.051004,
        },
        _id: "64f75284ef8f606073e98fe1",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "vatosola",
        fokontany: "antsaharoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.458235,
          lng: 47.0461392,
        },
        _id: "64f75284ef8f606073e98fe2",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "vatosola",
        fokontany: "anasana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4628608,
          lng: 47.0607349,
        },
        _id: "64f75284ef8f606073e98fe3",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "fianarantsoa I",
        commune: "vatosola",
        fokontany: "ankazobe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8303434,
          lng: 46.9422658,
        },
        _id: "64f75284ef8f606073e98fe4",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "atsinanamanda",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8187291,
          lng: 46.9562248,
        },
        _id: "64f75284ef8f606073e98fe5",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "ambalalova avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7823863,
          lng: 46.9101143,
        },
        _id: "64f75284ef8f606073e98fe6",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "vatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8062095,
          lng: 46.9173921,
        },
        _id: "64f75284ef8f606073e98fe7",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "maroparasy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.827584,
          lng: 46.9374115,
        },
        _id: "64f75284ef8f606073e98fe8",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "ezaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8172041,
          lng: 46.9440864,
        },
        _id: "64f75284ef8f606073e98fe9",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "ambohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8154361,
          lng: 46.9756523,
        },
        _id: "64f75284ef8f606073e98fea",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "tsaranoro",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8243852,
          lng: 46.94044539999999,
        },
        _id: "64f75284ef8f606073e98feb",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "alatsinainy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8319856,
          lng: 46.9325576,
        },
        _id: "64f75284ef8f606073e98fec",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "sahamasy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.830839,
          lng: 46.9234577,
        },
        _id: "64f75284ef8f606073e98fed",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "androka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8572131,
          lng: 46.89798690000001,
        },
        _id: "64f75284ef8f606073e98fee",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "bemahalanja",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8539049,
          lng: 46.924671,
        },
        _id: "64f75284ef8f606073e98fef",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "vondrokely",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8387825,
          lng: 46.9258842,
        },
        _id: "64f75284ef8f606073e98ff0",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "antsenanomby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8347688,
          lng: 46.93286089999999,
        },
        _id: "64f75284ef8f606073e98ff1",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "ambohijafy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8394342,
          lng: 46.9537969,
        },
        _id: "64f75284ef8f606073e98ff2",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "ambalamahasoa nord",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8440932,
          lng: 46.9756523,
        },
        _id: "64f75284ef8f606073e98ff3",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "soamanandray",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8387455,
          lng: 46.93316430000001,
        },
        _id: "64f75284ef8f606073e98ff4",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "ampanaovantsavony",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8387081,
          lng: 46.94044539999999,
        },
        _id: "64f75284ef8f606073e98ff5",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "ankofika",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8489845,
          lng: 46.9537969,
        },
        _id: "64f75284ef8f606073e98ff6",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "teloambinifolo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8599503,
          lng: 46.9877982,
        },
        _id: "64f75284ef8f606073e98ff7",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "alatsinainy fonenantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.860102,
          lng: 46.9586528,
        },
        _id: "64f75284ef8f606073e98ff8",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambalavao",
        fokontany: "ambalamahasoa sud",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0944499,
          lng: 46.698929,
        },
        _id: "64f75284ef8f606073e98ff9",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "manamisoa",
        fokontany: "manamisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7235164,
          lng: 46.91254010000001,
        },
        _id: "64f75284ef8f606073e98ffa",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "manamisoa",
        fokontany: "fenoarivo II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7553582,
          lng: 46.9076886,
        },
        _id: "64f75284ef8f606073e98ffb",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "manamisoa",
        fokontany: "vohitrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7550044,
          lng: 46.9659376,
        },
        _id: "64f75284ef8f606073e98ffc",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "manamisoa",
        fokontany: "tambohonierana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7742792,
          lng: 46.9368047,
        },
        _id: "64f75284ef8f606073e98ffd",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "manamisoa",
        fokontany: "imbato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.816667,
          lng: 46.866667,
        },
        _id: "64f75284ef8f606073e98ffe",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "iarintsena",
        fokontany: "firaisantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6932954,
          lng: 46.612626,
        },
        _id: "64f75284ef8f606073e98fff",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "iarintsena",
        fokontany: "ambinanimbaza",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7502626,
          lng: 46.64157160000001,
        },
        _id: "64f75284ef8f606073e99000",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "iarintsena",
        fokontany: "fivanona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7182134,
          lng: 46.7188421,
        },
        _id: "64f75284ef8f606073e99001",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "iarintsena",
        fokontany: "vinany",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7177768,
          lng: 46.805914,
        },
        _id: "64f75284ef8f606073e99002",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "iarintsena",
        fokontany: "iharihary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7428585,
          lng: 46.8688931,
        },
        _id: "64f75284ef8f606073e99003",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "iarintsena",
        fokontany: "manandrambato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7748316,
          lng: 46.83497149999999,
        },
        _id: "64f75284ef8f606073e99004",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "iarintsena",
        fokontany: "miaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7873526,
          lng: 46.8737409,
        },
        _id: "64f75284ef8f606073e99005",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "iarintsena",
        fokontany: "sakaviro",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8325928,
          lng: 46.6898516,
        },
        _id: "64f75284ef8f606073e99006",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "iarintsena",
        fokontany: "tamia",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7815029,
          lng: 46.7671967,
        },
        _id: "64f75284ef8f606073e99007",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "iarintsena",
        fokontany: "laimandry",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8319885,
          lng: 46.8495065,
        },
        _id: "64f75284ef8f606073e99008",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "iarintsena",
        fokontany: "tananomby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8511274,
          lng: 46.83497149999999,
        },
        _id: "64f75284ef8f606073e99009",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "iarintsena",
        fokontany: "anja",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8796847,
          lng: 46.8495065,
        },
        _id: "64f75284ef8f606073e9900a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "iarintsena",
        fokontany: "manampy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9591109,
          lng: 46.8737409,
        },
        _id: "64f75284ef8f606073e9900b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "iarintsena",
        fokontany: "lalangina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8828706,
          lng: 46.9494519,
        },
        _id: "64f75284ef8f606073e9900c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambohimandroso",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8921274,
          lng: 46.9173921,
        },
        _id: "64f75284ef8f606073e9900d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambohimandroso",
        fokontany: "vohitrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8681567,
          lng: 46.93923179999999,
        },
        _id: "64f75284ef8f606073e9900e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambohimandroso",
        fokontany: "avaradrano ambalavaokely",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8828706,
          lng: 46.9494519,
        },
        _id: "64f75284ef8f606073e9900f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambohimandroso",
        fokontany: "andrefakidona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8774914,
          lng: 46.9829395,
        },
        _id: "64f75284ef8f606073e99010",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambohimandroso",
        fokontany: "ankazofady",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9176262,
          lng: 46.9076886,
        },
        _id: "64f75284ef8f606073e99011",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambohimandroso",
        fokontany: "tsaramandroso felimaha",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9398082,
          lng: 46.9319509,
        },
        _id: "64f75284ef8f606073e99012",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambohimandroso",
        fokontany: "ankarinarivo andoharano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9395628,
          lng: 46.9902277,
        },
        _id: "64f75284ef8f606073e99013",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "andrainjato",
        fokontany: "andrainjato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.920519,
          lng: 46.9756523,
        },
        _id: "64f75284ef8f606073e99014",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "andrainjato",
        fokontany: "mahatsinjony",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9235216,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e99015",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "andrainjato",
        fokontany: "fiarenana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9460374,
          lng: 46.9659376,
        },
        _id: "64f75284ef8f606073e99016",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "andrainjato",
        fokontany: "tanambao tsiakary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9651441,
          lng: 46.9659376,
        },
        _id: "64f75284ef8f606073e99017",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "andrainjato",
        fokontany: "seranana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.89152,
          lng: 47.0412749,
        },
        _id: "64f75284ef8f606073e99018",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "anjoma",
        fokontany: "ambohimiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8370585,
          lng: 47.08750449999999,
        },
        _id: "64f75284ef8f606073e99019",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "anjoma",
        fokontany: "ankazosoaravina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8500207,
          lng: 47.0534365,
        },
        _id: "64f75284ef8f606073e9901a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "anjoma",
        fokontany: "angavo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.878535,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e9901b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "anjoma",
        fokontany: "iavomalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8693083,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e9901c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "anjoma",
        fokontany: "iarinomby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8787045,
          lng: 47.0534365,
        },
        _id: "64f75284ef8f606073e9901d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "anjoma",
        fokontany: "tsikahoe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8821561,
          lng: 47.0048074,
        },
        _id: "64f75284ef8f606073e9901e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "anjoma",
        fokontany: "anaody",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8965275,
          lng: 46.9975171,
        },
        _id: "64f75284ef8f606073e9901f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "anjoma",
        fokontany: "ifandana est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8979837,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e99020",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "anjoma",
        fokontany: "ambatomena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.915751,
          lng: 47.059662,
        },
        _id: "64f75284ef8f606073e99021",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "anjoma",
        fokontany: "tambohobe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9040957,
          lng: 47.0729012,
        },
        _id: "64f75284ef8f606073e99022",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "anjoma",
        fokontany: "samimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9233765,
          lng: 47.043707,
        },
        _id: "64f75284ef8f606073e99023",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "anjoma",
        fokontany: "soamanandray",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8341604,
          lng: 47.0412749,
        },
        _id: "64f75284ef8f606073e99024",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "kirano",
        fokontany: "kirano firaisantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8038267,
          lng: 47.051004,
        },
        _id: "64f75284ef8f606073e99025",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "kirano",
        fokontany: "manamihandro",
        __v: 0,
      },
      {
        coords: {
          lat: -21.814902,
          lng: 47.0631679,
        },
        _id: "64f75284ef8f606073e99026",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "kirano",
        fokontany: "tsimaitohasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8024046,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e99027",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "kirano",
        fokontany: "fihaihana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8279488,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e99028",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "kirano",
        fokontany: "vohidahy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8470621,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e99029",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "kirano",
        fokontany: "ambalavao hoditavy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0097619,
          lng: 46.9562248,
        },
        _id: "64f75284ef8f606073e9902a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "sendrisoa",
        fokontany: "beanana centre",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9939322,
          lng: 46.92952409999999,
        },
        _id: "64f75284ef8f606073e9902b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "sendrisoa",
        fokontany: "vondrokely",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9779548,
          lng: 46.9465138,
        },
        _id: "64f75284ef8f606073e9902c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "sendrisoa",
        fokontany: "beanana sahafatra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9938052,
          lng: 46.9659376,
        },
        _id: "64f75284ef8f606073e9902d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "sendrisoa",
        fokontany: "maroakoho",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0067038,
          lng: 46.9173921,
        },
        _id: "64f75284ef8f606073e9902e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "sendrisoa",
        fokontany: "kilonjy iava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0798343,
          lng: 46.9513691,
        },
        _id: "64f75284ef8f606073e9902f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "sendrisoa",
        fokontany: "amindranjamanony",
        __v: 0,
      },
      {
        coords: {
          lat: -22.02562,
          lng: 46.9756523,
        },
        _id: "64f75284ef8f606073e99030",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "sendrisoa",
        fokontany: "moronarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0449005,
          lng: 46.9173921,
        },
        _id: "64f75284ef8f606073e99031",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "sendrisoa",
        fokontany: "kilonjy iray",
        __v: 0,
      },
      {
        coords: {
          lat: -22.035267,
          lng: 46.9465138,
        },
        _id: "64f75284ef8f606073e99032",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "sendrisoa",
        fokontany: "manakony",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0542416,
          lng: 46.9902277,
        },
        _id: "64f75284ef8f606073e99033",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "sendrisoa",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0608741,
          lng: 46.8931368,
        },
        _id: "64f75284ef8f606073e99034",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "sendrisoa",
        fokontany: "fivanona",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0832146,
          lng: 46.8591989,
        },
        _id: "64f75284ef8f606073e99035",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "sendrisoa",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0943034,
          lng: 46.88586249999999,
        },
        _id: "64f75284ef8f606073e99036",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "sendrisoa",
        fokontany: "namoly mahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1372553,
          lng: 46.8931368,
        },
        _id: "64f75284ef8f606073e99037",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "sendrisoa",
        fokontany: "namoly ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1213007,
          lng: 46.9173921,
        },
        _id: "64f75284ef8f606073e99038",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "sendrisoa",
        fokontany: "namoly centre",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1243093,
          lng: 46.9999471,
        },
        _id: "64f75284ef8f606073e99039",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "sendrisoa",
        fokontany: "namoly est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.91217,
          lng: 46.781239,
        },
        _id: "64f75284ef8f606073e9903a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "besoa",
        fokontany: "besoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8704159,
          lng: 46.7768732,
        },
        _id: "64f75284ef8f606073e9903b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "besoa",
        fokontany: "ambalamarina iv",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9083559,
          lng: 46.83497149999999,
        },
        _id: "64f75284ef8f606073e9903c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "besoa",
        fokontany: "andraibe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9465113,
          lng: 46.83497149999999,
        },
        _id: "64f75284ef8f606073e9903d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "besoa",
        fokontany: "antako",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9371019,
          lng: 46.7913915,
        },
        _id: "64f75284ef8f606073e9903e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "besoa",
        fokontany: "tambohobe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9999907,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e9903f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "mahazony",
        fokontany: "alatsinainy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9584121,
          lng: 47.0485715,
        },
        _id: "64f75284ef8f606073e99040",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "mahazony",
        fokontany: "ambalamahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9648981,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e99041",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "mahazony",
        fokontany: "vohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9809915,
          lng: 46.9853688,
        },
        _id: "64f75284ef8f606073e99042",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "mahazony",
        fokontany: "tsarasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.968138,
          lng: 47.0120988,
        },
        _id: "64f75284ef8f606073e99043",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "mahazony",
        fokontany: "sahatsiota",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9901776,
          lng: 47.0729012,
        },
        _id: "64f75284ef8f606073e99044",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "mahazony",
        fokontany: "ankola nord",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9710473,
          lng: 47.0729012,
        },
        _id: "64f75284ef8f606073e99045",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "mahazony",
        fokontany: "ankiazolahivato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9982664,
          lng: 47.0461392,
        },
        _id: "64f75284ef8f606073e99046",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "mahazony",
        fokontany: "ambohidroa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0031085,
          lng: 47.0315477,
        },
        _id: "64f75284ef8f606073e99047",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "mahazony",
        fokontany: "ambohijafy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0155999,
          lng: 47.0923732,
        },
        _id: "64f75284ef8f606073e99048",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "mahazony",
        fokontany: "ampangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0157272,
          lng: 47.0631679,
        },
        _id: "64f75284ef8f606073e99049",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "mahazony",
        fokontany: "ambohimahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0286145,
          lng: 47.0291162,
        },
        _id: "64f75284ef8f606073e9904a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "mahazony",
        fokontany: "antokolava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9326316,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e9904b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambinanindovoka",
        fokontany: "miaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8749518,
          lng: 47.1459597,
        },
        _id: "64f75284ef8f606073e9904c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambinanindovoka",
        fokontany: "volafotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9101942,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e9904d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambinanindovoka",
        fokontany: "firariantsoa miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9039296,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e9904e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambinanindovoka",
        fokontany: "vohidroa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9327883,
          lng: 47.0729012,
        },
        _id: "64f75284ef8f606073e9904f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambinanindovoka",
        fokontany: "andrainarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9485906,
          lng: 47.0996772,
        },
        _id: "64f75284ef8f606073e99050",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambinanindovoka",
        fokontany: "fijoroana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9677629,
          lng: 47.0923732,
        },
        _id: "64f75284ef8f606073e99051",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambinanindovoka",
        fokontany: "mirarisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9477372,
          lng: 46.639762,
        },
        _id: "64f75284ef8f606073e99052",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ankaramena",
        fokontany: "ankaramena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8962156,
          lng: 46.6222727,
        },
        _id: "64f75284ef8f606073e99053",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ankaramena",
        fokontany: "morafeno ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9055859,
          lng: 46.6946822,
        },
        _id: "64f75284ef8f606073e99054",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ankaramena",
        fokontany: "morafeno nord",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9245685,
          lng: 46.7236754,
        },
        _id: "64f75284ef8f606073e99055",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ankaramena",
        fokontany: "vitanalina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9501899,
          lng: 46.5692391,
        },
        _id: "64f75284ef8f606073e99056",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ankaramena",
        fokontany: "mahamongo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9723766,
          lng: 46.57887729999999,
        },
        _id: "64f75284ef8f606073e99057",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ankaramena",
        fokontany: "mafaitra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9501651,
          lng: 46.5981595,
        },
        _id: "64f75284ef8f606073e99058",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ankaramena",
        fokontany: "malazarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.946913,
          lng: 46.660878,
        },
        _id: "64f75284ef8f606073e99059",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ankaramena",
        fokontany: "vohibola",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9532048,
          lng: 46.6946822,
        },
        _id: "64f75284ef8f606073e9905a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ankaramena",
        fokontany: "morafeno sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9881406,
          lng: 46.6850215,
        },
        _id: "64f75284ef8f606073e9905b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ankaramena",
        fokontany: "lanjainony",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0103934,
          lng: 46.6512239,
        },
        _id: "64f75284ef8f606073e9905c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ankaramena",
        fokontany: "ambalamahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8963864,
          lng: 46.4777687,
        },
        _id: "64f75284ef8f606073e9905d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambinaniroa",
        fokontany: "ambinaniroa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7950956,
          lng: 46.4585333,
        },
        _id: "64f75284ef8f606073e9905e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambinaniroa",
        fokontany: "manara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8456098,
          lng: 46.5547852,
        },
        _id: "64f75284ef8f606073e9905f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambinaniroa",
        fokontany: "bokony",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9090631,
          lng: 46.4296942,
        },
        _id: "64f75284ef8f606073e99060",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambinaniroa",
        fokontany: "soamahatamana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9280081,
          lng: 46.5596027,
        },
        _id: "64f75284ef8f606073e99061",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambinaniroa",
        fokontany: "soarea",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9438862,
          lng: 46.4922002,
        },
        _id: "64f75284ef8f606073e99062",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambinaniroa",
        fokontany: "ranotsara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.972395,
          lng: 46.5066359,
        },
        _id: "64f75284ef8f606073e99063",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambinaniroa",
        fokontany: "ambaho",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0484559,
          lng: 46.5355198,
        },
        _id: "64f75284ef8f606073e99064",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambinaniroa",
        fokontany: "mandazaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.938061,
          lng: 47.185051,
        },
        _id: "64f75284ef8f606073e99065",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambohimahamasina",
        fokontany: "ambohimahamasina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8745561,
          lng: 47.2044815,
        },
        _id: "64f75284ef8f606073e99066",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambohimahamasina",
        fokontany: "soatsihanino",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9067045,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e99067",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambohimahamasina",
        fokontany: "tsarafara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9096425,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e99068",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambohimahamasina",
        fokontany: "andohanimananatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9291628,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e99069",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambohimahamasina",
        fokontany: "ambohipaha",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9402595,
          lng: 47.16302160000001,
        },
        _id: "64f75284ef8f606073e9906a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambohimahamasina",
        fokontany: "tokoamivondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9701615,
          lng: 47.2337675,
        },
        _id: "64f75284ef8f606073e9906b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambohimahamasina",
        fokontany: "itaolana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9480931,
          lng: 47.1874058,
        },
        _id: "64f75284ef8f606073e9906c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambohimahamasina",
        fokontany: "anjamana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9547454,
          lng: 47.1410858,
        },
        _id: "64f75284ef8f606073e9906d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambohimahamasina",
        fokontany: "miraihery",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9468641,
          lng: 47.1240312,
        },
        _id: "64f75284ef8f606073e9906e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambohimahamasina",
        fokontany: "firariantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9770282,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e9906f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambohimahamasina",
        fokontany: "ambohitravo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0026803,
          lng: 47.1264672,
        },
        _id: "64f75284ef8f606073e99070",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambohimahamasina",
        fokontany: "antandindo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0468235,
          lng: 47.2337675,
        },
        _id: "64f75284ef8f606073e99071",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "ambohimahamasina",
        fokontany: "lomaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0794245,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e99072",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "miarinarivo",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.047572,
          lng: 47.0729012,
        },
        _id: "64f75284ef8f606073e99073",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "miarinarivo",
        fokontany: "soamanandrariny",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0636565,
          lng: 47.0339793,
        },
        _id: "64f75284ef8f606073e99074",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "miarinarivo",
        fokontany: "vatanamaso",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0923438,
          lng: 47.0339793,
        },
        _id: "64f75284ef8f606073e99075",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "miarinarivo",
        fokontany: "vohiboay",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1177429,
          lng: 47.0680343,
        },
        _id: "64f75284ef8f606073e99076",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "miarinarivo",
        fokontany: "angalampona",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0674758,
          lng: 46.7381783,
        },
        _id: "64f75284ef8f606073e99077",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "vohitsaoka",
        fokontany: "soamiaradia",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9721174,
          lng: 46.7671967,
        },
        _id: "64f75284ef8f606073e99078",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "vohitsaoka",
        fokontany: "anara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0228291,
          lng: 46.83497149999999,
        },
        _id: "64f75284ef8f606073e99079",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "vohitsaoka",
        fokontany: "kipase",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0737628,
          lng: 46.8010727,
        },
        _id: "64f75284ef8f606073e9907a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "vohitsaoka",
        fokontany: "soavahiny",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0992593,
          lng: 46.6898516,
        },
        _id: "64f75284ef8f606073e9907b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "vohitsaoka",
        fokontany: "ambohimana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.10947,
          lng: 46.6200789,
        },
        _id: "64f75284ef8f606073e9907c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "vohitsaoka",
        fokontany: "soaseranana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1118996,
          lng: 46.815598,
        },
        _id: "64f75284ef8f606073e9907d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "vohitsaoka",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1500587,
          lng: 46.815598,
        },
        _id: "64f75284ef8f606073e9907e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "vohitsaoka",
        fokontany: "tananarivokely",
        __v: 0,
      },
      {
        coords: {
          lat: -21.726549,
          lng: 46.384491,
        },
        _id: "64f75284ef8f606073e9907f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "fenoarivo",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6052091,
          lng: 46.4585333,
        },
        _id: "64f75284ef8f606073e99080",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "fenoarivo",
        fokontany: "kilalo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7194632,
          lng: 46.2282927,
        },
        _id: "64f75284ef8f606073e99081",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "fenoarivo",
        fokontany: "mahavanona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.726549,
          lng: 46.384491,
        },
        _id: "64f75284ef8f606073e99082",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "fenoarivo",
        fokontany: "ambondrobe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.666667,
          lng: 46.433333,
        },
        _id: "64f75284ef8f606073e99083",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambalavao",
        commune: "fenoarivo",
        fokontany: "maroilo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1069954,
          lng: 47.225224,
        },
        _id: "64f75284ef8f606073e99084",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambohimahasoa",
        fokontany: "antalata",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0865246,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e99085",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambohimahasoa",
        fokontany: "ampanidinana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.100696,
          lng: 47.2203427,
        },
        _id: "64f75284ef8f606073e99086",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambohimahasoa",
        fokontany: "ankiboka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0977194,
          lng: 47.2069214,
        },
        _id: "64f75284ef8f606073e99087",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambohimahasoa",
        fokontany: "anteviahitra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1044834,
          lng: 47.2075314,
        },
        _id: "64f75284ef8f606073e99088",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambohimahasoa",
        fokontany: "ambohimanatrika",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1049484,
          lng: 47.2032616,
        },
        _id: "64f75284ef8f606073e99089",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambohimahasoa",
        fokontany: "bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1072249,
          lng: 47.2105814,
        },
        _id: "64f75284ef8f606073e9908a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambohimahasoa",
        fokontany: "avaratsena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1111335,
          lng: 47.2154618,
        },
        _id: "64f75284ef8f606073e9908b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambohimahasoa",
        fokontany: "antoabe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.111019,
          lng: 47.2227833,
        },
        _id: "64f75284ef8f606073e9908c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambohimahasoa",
        fokontany: "andondona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.143904,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e9908d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ampitana",
        fokontany: "ampitana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0876905,
          lng: 47.2362087,
        },
        _id: "64f75284ef8f606073e9908e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ampitana",
        fokontany: "idimby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.116388,
          lng: 47.2362087,
        },
        _id: "64f75284ef8f606073e9908f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ampitana",
        fokontany: "andoharondra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1248892,
          lng: 47.2020418,
        },
        _id: "64f75284ef8f606073e99090",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ampitana",
        fokontany: "andohasoamahainty",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1359714,
          lng: 47.2069214,
        },
        _id: "64f75284ef8f606073e99091",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ampitana",
        fokontany: "ambalavola",
        __v: 0,
      },
      {
        coords: {
          lat: -21.137266,
          lng: 47.2264444,
        },
        _id: "64f75284ef8f606073e99092",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ampitana",
        fokontany: "soafandry",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1485192,
          lng: 47.2203427,
        },
        _id: "64f75284ef8f606073e99093",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ampitana",
        fokontany: "vatositry",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1695537,
          lng: 47.1996022,
        },
        _id: "64f75284ef8f606073e99094",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ampitana",
        fokontany: "amboasary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.033333,
          lng: 47.116667,
        },
        _id: "64f75284ef8f606073e99095",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ankerana",
        fokontany: "ankerana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.096882,
          lng: 47.1581462,
        },
        _id: "64f75284ef8f606073e99096",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ankerana",
        fokontany: "ambohimasina itomboana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0965442,
          lng: 47.1800893,
        },
        _id: "64f75284ef8f606073e99097",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ankerana",
        fokontany: "tsiandatsiana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1038826,
          lng: 47.1691166,
        },
        _id: "64f75284ef8f606073e99098",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ankerana",
        fokontany: "malaza itomboana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1106022,
          lng: 47.1983824,
        },
        _id: "64f75284ef8f606073e99099",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ankerana",
        fokontany: "ankazondrano andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1203325,
          lng: 47.1874058,
        },
        _id: "64f75284ef8f606073e9909a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ankerana",
        fokontany: "lavarano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1443453,
          lng: 47.1800893,
        },
        _id: "64f75284ef8f606073e9909b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ankerana",
        fokontany: "manakanda",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1398922,
          lng: 47.1581462,
        },
        _id: "64f75284ef8f606073e9909c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ankerana",
        fokontany: "imaro",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1398474,
          lng: 47.2655127,
        },
        _id: "64f75284ef8f606073e9909d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "manandroy",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1013325,
          lng: 47.280171,
        },
        _id: "64f75284ef8f606073e9909e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "manandroy",
        fokontany: "sahafody",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1138165,
          lng: 47.2972775,
        },
        _id: "64f75284ef8f606073e9909f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "manandroy",
        fokontany: "ambalarano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0888512,
          lng: 47.2630701,
        },
        _id: "64f75284ef8f606073e990a0",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "manandroy",
        fokontany: "fahidahy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1238592,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e990a1",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "manandroy",
        fokontany: "vondrombe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1363482,
          lng: 47.285058,
        },
        _id: "64f75284ef8f606073e990a2",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "manandroy",
        fokontany: "ankarinera ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1337742,
          lng: 47.2459749,
        },
        _id: "64f75284ef8f606073e990a3",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "manandroy",
        fokontany: "ialamalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1409009,
          lng: 47.2997218,
        },
        _id: "64f75284ef8f606073e990a4",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "manandroy",
        fokontany: "ankarinera est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.149645,
          lng: 47.2508586,
        },
        _id: "64f75284ef8f606073e990a5",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "manandroy",
        fokontany: "soatsihovana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1625898,
          lng: 47.2386501,
        },
        _id: "64f75284ef8f606073e990a6",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "manandroy",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1556072,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e990a7",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "manandroy",
        fokontany: "ambohimahatsinjo ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1599171,
          lng: 47.256964,
        },
        _id: "64f75284ef8f606073e990a8",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "manandroy",
        fokontany: "ambohimanarivo fototra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1766335,
          lng: 47.3608657,
        },
        _id: "64f75284ef8f606073e990a9",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambalakindresy",
        fokontany: "antsiho",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0432885,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e990aa",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambalakindresy",
        fokontany: "tambohobe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0659562,
          lng: 47.2972775,
        },
        _id: "64f75284ef8f606073e990ab",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambalakindresy",
        fokontany: "ankarenana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.066667,
          lng: 47.316667,
        },
        _id: "64f75284ef8f606073e990ac",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambalakindresy",
        fokontany: "andambinana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0882499,
          lng: 47.2997218,
        },
        _id: "64f75284ef8f606073e990ad",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambalakindresy",
        fokontany: "ambatofolaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0929353,
          lng: 47.4000357,
        },
        _id: "64f75284ef8f606073e990ae",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambalakindresy",
        fokontany: "sahanimaitso",
        __v: 0,
      },
      {
        coords: {
          lat: -21.113336,
          lng: 47.3266162,
        },
        _id: "64f75284ef8f606073e990af",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambalakindresy",
        fokontany: "andraina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1443752,
          lng: 47.380447,
        },
        _id: "64f75284ef8f606073e990b0",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambalakindresy",
        fokontany: "andraina fototra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1390304,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e990b1",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambalakindresy",
        fokontany: "ankarinezaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1633164,
          lng: 47.29483339999999,
        },
        _id: "64f75284ef8f606073e990b2",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambalakindresy",
        fokontany: "ambohimahatsinjo est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.202275,
          lng: 47.2508586,
        },
        _id: "64f75284ef8f606073e990b3",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ankafina tsarafidy",
        fokontany: "ambohimanoro",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1847677,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e990b4",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ankafina tsarafidy",
        fokontany: "ambohipiarenana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.213904,
          lng: 47.2191224,
        },
        _id: "64f75284ef8f606073e990b5",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ankafina tsarafidy",
        fokontany: "andohanakona tsimanova",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2099955,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e990b6",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ankafina tsarafidy",
        fokontany: "amboditanjona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2356635,
          lng: 47.2581852,
        },
        _id: "64f75284ef8f606073e990b7",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ankafina tsarafidy",
        fokontany: "soatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2428112,
          lng: 47.2044815,
        },
        _id: "64f75284ef8f606073e990b8",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ankafina tsarafidy",
        fokontany: "vatoavo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2548063,
          lng: 47.2581852,
        },
        _id: "64f75284ef8f606073e990b9",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ankafina tsarafidy",
        fokontany: "vohitrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0769221,
          lng: 47.1094174,
        },
        _id: "64f75284ef8f606073e990ba",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "sahave",
        fokontany: "ambohiboahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0335979,
          lng: 47.1313396,
        },
        _id: "64f75284ef8f606073e990bb",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "sahave",
        fokontany: "iavoloha",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0728035,
          lng: 47.06560109999999,
        },
        _id: "64f75284ef8f606073e990bc",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "sahave",
        fokontany: "ankazo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.05619,
          lng: 47.1118527,
        },
        _id: "64f75284ef8f606073e990bd",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "sahave",
        fokontany: "ambohimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0713586,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e990be",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "sahave",
        fokontany: "manarinony",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0734412,
          lng: 47.1289033,
        },
        _id: "64f75284ef8f606073e990bf",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "sahave",
        fokontany: "iharanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0883203,
          lng: 47.0923732,
        },
        _id: "64f75284ef8f606073e990c0",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "sahave",
        fokontany: "ivako",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0975452,
          lng: 47.1142882,
        },
        _id: "64f75284ef8f606073e990c1",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "sahave",
        fokontany: "ambohipo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0923271,
          lng: 47.1435227,
        },
        _id: "64f75284ef8f606073e990c2",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "sahave",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -21.113068,
          lng: 47.1410858,
        },
        _id: "64f75284ef8f606073e990c3",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "sahave",
        fokontany: "andranomavo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.126097,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e990c4",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "sahave",
        fokontany: "tamiana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.187476,
          lng: 47.280171,
        },
        _id: "64f75284ef8f606073e990c5",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "morafeno",
        fokontany: "tsarafidifototra I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2025672,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e990c6",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "morafeno",
        fokontany: "namorombe volahotro",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1904046,
          lng: 47.2972775,
        },
        _id: "64f75284ef8f606073e990c7",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "morafeno",
        fokontany: "tsarafidifototra II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2221728,
          lng: 47.3070552,
        },
        _id: "64f75284ef8f606073e990c8",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "morafeno",
        fokontany: "toapinga",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2160844,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e990c9",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "morafeno",
        fokontany: "miadakofeno",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2378392,
          lng: 47.3266162,
        },
        _id: "64f75284ef8f606073e990ca",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "morafeno",
        fokontany: "vohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1829761,
          lng: 47.09588850000001,
        },
        _id: "64f75284ef8f606073e990cb",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ikalalao",
        fokontany: "ikalalao",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1573805,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e990cc",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ikalalao",
        fokontany: "antetindravita",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1582226,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e990cd",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ikalalao",
        fokontany: "imango I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1616803,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e990ce",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ikalalao",
        fokontany: "vohibato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.166667,
          lng: 47.116667,
        },
        _id: "64f75284ef8f606073e990cf",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ikalalao",
        fokontany: "ambohitsaony",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1759056,
          lng: 47.0899388,
        },
        _id: "64f75284ef8f606073e990d0",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ikalalao",
        fokontany: "ambalavato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1626878,
          lng: 47.1240312,
        },
        _id: "64f75284ef8f606073e990d1",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ikalalao",
        fokontany: "malaza",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1830115,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e990d2",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ikalalao",
        fokontany: "andohamango I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1998848,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e990d3",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ikalalao",
        fokontany: "ambohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1913543,
          lng: 47.1240312,
        },
        _id: "64f75284ef8f606073e990d4",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ikalalao",
        fokontany: "iavimanangana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.211483,
          lng: 47.1654595,
        },
        _id: "64f75284ef8f606073e990d5",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ikalalao",
        fokontany: "andohamango II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.212292,
          lng: 47.1069821,
        },
        _id: "64f75284ef8f606073e990d6",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ikalalao",
        fokontany: "ankofafamavo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9923499,
          lng: 47.1604,
        },
        _id: "64f75284ef8f606073e990d7",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "vohiposa",
        fokontany: "vohiposa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9425971,
          lng: 47.2386501,
        },
        _id: "64f75284ef8f606073e990d8",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "vohiposa",
        fokontany: "ambohimanendrika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9496535,
          lng: 47.1996022,
        },
        _id: "64f75284ef8f606073e990d9",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "vohiposa",
        fokontany: "ambohipierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9597112,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e990da",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "vohiposa",
        fokontany: "soasamihoela",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9615939,
          lng: 47.2459749,
        },
        _id: "64f75284ef8f606073e990db",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "vohiposa",
        fokontany: "ambondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9883783,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e990dc",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "vohiposa",
        fokontany: "analalava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.001913,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e990dd",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "vohiposa",
        fokontany: "manaovasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.003983,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e990de",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "vohiposa",
        fokontany: "ambohipeno vohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0140208,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e990df",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "vohiposa",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0515424,
          lng: 47.2044815,
        },
        _id: "64f75284ef8f606073e990e0",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "vohiposa",
        fokontany: "anara andakana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0457187,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e990e1",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "vohiposa",
        fokontany: "sahamadio",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9609458,
          lng: 47.2826144,
        },
        _id: "64f75284ef8f606073e990e2",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambatosoa",
        fokontany: "sahanimira",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9338834,
          lng: 47.280171,
        },
        _id: "64f75284ef8f606073e990e3",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambatosoa",
        fokontany: "ambatohazo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9730041,
          lng: 47.3217253,
        },
        _id: "64f75284ef8f606073e990e4",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambatosoa",
        fokontany: "ranomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9939318,
          lng: 47.2191224,
        },
        _id: "64f75284ef8f606073e990e5",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambatosoa",
        fokontany: "maroaomby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0062677,
          lng: 47.24353319999999,
        },
        _id: "64f75284ef8f606073e990e6",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambatosoa",
        fokontany: "ankaditany",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0216279,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e990e7",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambatosoa",
        fokontany: "soanataonjafy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1433924,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e990e8",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "isaka",
        fokontany: "antalata",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1048027,
          lng: 47.0534365,
        },
        _id: "64f75284ef8f606073e990e9",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "isaka",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1117163,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e990ea",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "isaka",
        fokontany: "ambalafeno namboarina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1269402,
          lng: 47.0631679,
        },
        _id: "64f75284ef8f606073e990eb",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "isaka",
        fokontany: "itsito ambalatsimiedika",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1305999,
          lng: 47.0291162,
        },
        _id: "64f75284ef8f606073e990ec",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "isaka",
        fokontany: "isaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1151662,
          lng: 46.9950872,
        },
        _id: "64f75284ef8f606073e990ed",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "isaka",
        fokontany: "ampangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1360734,
          lng: 47.0923732,
        },
        _id: "64f75284ef8f606073e990ee",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "isaka",
        fokontany: "itsito ankalambato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.156766,
          lng: 46.9756523,
        },
        _id: "64f75284ef8f606073e990ef",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "isaka",
        fokontany: "fiehana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1690705,
          lng: 47.0072378,
        },
        _id: "64f75284ef8f606073e990f0",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "isaka",
        fokontany: "soanataonimaro",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1029801,
          lng: 46.9562248,
        },
        _id: "64f75284ef8f606073e990f1",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "vohitrarivo",
        fokontany: "manjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.030041,
          lng: 46.8750799,
        },
        _id: "64f75284ef8f606073e990f2",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "vohitrarivo",
        fokontany: "ambalatsilalovana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.05,
          lng: 46.95,
        },
        _id: "64f75284ef8f606073e990f3",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "vohitrarivo",
        fokontany: "vohitrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0733384,
          lng: 47.0291162,
        },
        _id: "64f75284ef8f606073e990f4",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "vohitrarivo",
        fokontany: "sahavahona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0673634,
          lng: 47.00237720000001,
        },
        _id: "64f75284ef8f606073e990f5",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "vohitrarivo",
        fokontany: "andrefanambositra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0688819,
          lng: 46.8931368,
        },
        _id: "64f75284ef8f606073e990f6",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "vohitrarivo",
        fokontany: "ambivy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0907924,
          lng: 46.9173921,
        },
        _id: "64f75284ef8f606073e990f7",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "vohitrarivo",
        fokontany: "ambalamarina seranana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0165236,
          lng: 46.9999471,
        },
        _id: "64f75284ef8f606073e990f8",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambohinamboarina",
        fokontany: "ambohinamboarina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9978846,
          lng: 46.9707947,
        },
        _id: "64f75284ef8f606073e990f9",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambohinamboarina",
        fokontany: "ambatolahy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9999207,
          lng: 47.0461392,
        },
        _id: "64f75284ef8f606073e990fa",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambohinamboarina",
        fokontany: "ampilanonana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0170424,
          lng: 47.07046769999999,
        },
        _id: "64f75284ef8f606073e990fb",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambohinamboarina",
        fokontany: "soananorenana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0160851,
          lng: 47.0291162,
        },
        _id: "64f75284ef8f606073e990fc",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambohinamboarina",
        fokontany: "tsiromboina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0421019,
          lng: 46.9902277,
        },
        _id: "64f75284ef8f606073e990fd",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambohinamboarina",
        fokontany: "ambohibolafotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.031024,
          lng: 47.0923732,
        },
        _id: "64f75284ef8f606073e990fe",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambohinamboarina",
        fokontany: "analamboarafy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0540374,
          lng: 47.043707,
        },
        _id: "64f75284ef8f606073e990ff",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "ambohinamboarina",
        fokontany: "riananana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9639505,
          lng: 47.1069821,
        },
        _id: "64f75284ef8f606073e99100",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "sahatona",
        fokontany: "tsimialonjafy tomboarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9136815,
          lng: 47.0680343,
        },
        _id: "64f75284ef8f606073e99101",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "sahatona",
        fokontany: "soamiafara ambalasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.947753,
          lng: 47.1240312,
        },
        _id: "64f75284ef8f606073e99102",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "sahatona",
        fokontany: "antsihara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9490672,
          lng: 47.043707,
        },
        _id: "64f75284ef8f606073e99103",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "sahatona",
        fokontany: "soamiafara ambatovaventy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9932928,
          lng: 47.0631679,
        },
        _id: "64f75284ef8f606073e99104",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "sahatona",
        fokontany: "iatara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.912696,
          lng: 47.1264672,
        },
        _id: "64f75284ef8f606073e99105",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "camp robin",
        fokontany: "camp robin",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8807806,
          lng: 47.1313396,
        },
        _id: "64f75284ef8f606073e99106",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "camp robin",
        fokontany: "manda",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9023834,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e99107",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "camp robin",
        fokontany: "andalotsola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9066629,
          lng: 47.1069821,
        },
        _id: "64f75284ef8f606073e99108",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "camp robin",
        fokontany: "ankitsikitsika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9184762,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e99109",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "camp robin",
        fokontany: "ambatovory I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9340627,
          lng: 47.1800893,
        },
        _id: "64f75284ef8f606073e9910a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "camp robin",
        fokontany: "tambohobe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9424846,
          lng: 47.1532712,
        },
        _id: "64f75284ef8f606073e9910b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "camp robin",
        fokontany: "ambohijanakova",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2321684,
          lng: 47.0485715,
        },
        _id: "64f75284ef8f606073e9910c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "befeta",
        fokontany: "tsimanavakavaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1748804,
          lng: 47.0485715,
        },
        _id: "64f75284ef8f606073e9910d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "befeta",
        fokontany: "ambalatiaray",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2006603,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e9910e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "befeta",
        fokontany: "tsimialonjafy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2262389,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e9910f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "befeta",
        fokontany: "tsimanarirazana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2505116,
          lng: 47.1069821,
        },
        _id: "64f75284ef8f606073e99110",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "befeta",
        fokontany: "isahy tangimaso",
        __v: 0,
      },
      {
        coords: {
          lat: -21.263691,
          lng: 47.0729012,
        },
        _id: "64f75284ef8f606073e99111",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "befeta",
        fokontany: "iasana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2988359,
          lng: 47.0631679,
        },
        _id: "64f75284ef8f606073e99112",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "befeta",
        fokontany: "vohitsambo andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2889196,
          lng: 47.0923732,
        },
        _id: "64f75284ef8f606073e99113",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "befeta",
        fokontany: "ambinda",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8783685,
          lng: 47.1776507,
        },
        _id: "64f75284ef8f606073e99114",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "fiadanana",
        fokontany: "fiadanana I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8631009,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e99115",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "fiadanana",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8270253,
          lng: 47.2826144,
        },
        _id: "64f75284ef8f606073e99116",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "fiadanana",
        fokontany: "andovoka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8308685,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e99117",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "fiadanana",
        fokontany: "valozoro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8413873,
          lng: 47.19716260000001,
        },
        _id: "64f75284ef8f606073e99118",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "fiadanana",
        fokontany: "tezalava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8480706,
          lng: 47.1800893,
        },
        _id: "64f75284ef8f606073e99119",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "fiadanana",
        fokontany: "manirisoa fandrosoana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8604982,
          lng: 47.19716260000001,
        },
        _id: "64f75284ef8f606073e9911a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "fiadanana",
        fokontany: "amboasary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8610293,
          lng: 47.1678975,
        },
        _id: "64f75284ef8f606073e9911b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "fiadanana",
        fokontany: "jerosalema vaovao",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8690772,
          lng: 47.16302160000001,
        },
        _id: "64f75284ef8f606073e9911c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "fiadanana",
        fokontany: "fiadanana II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8811339,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e9911d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "fiadanana",
        fokontany: "ambohimitombo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.880265,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e9911e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "fiadanana",
        fokontany: "andrere",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8975293,
          lng: 47.2630701,
        },
        _id: "64f75284ef8f606073e9911f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "fiadanana",
        fokontany: "matahitrony",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8892977,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e99120",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "fiadanana",
        fokontany: "mahatsiaro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9177078,
          lng: 47.2044815,
        },
        _id: "64f75284ef8f606073e99121",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ambohimahasoa",
        commune: "fiadanana",
        fokontany: "ambohidrainony",
        __v: 0,
      },
      {
        coords: {
          lat: -21.161637,
          lng: 46.6258906,
        },
        _id: "64f75284ef8f606073e99122",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "ikalamavony",
        fokontany: "ampanivana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0849874,
          lng: 46.612626,
        },
        _id: "64f75284ef8f606073e99123",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "ikalamavony",
        fokontany: "matsiatra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1118596,
          lng: 46.4585333,
        },
        _id: "64f75284ef8f606073e99124",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "ikalamavony",
        fokontany: "ivatsiana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1422747,
          lng: 46.5836972,
        },
        _id: "64f75284ef8f606073e99125",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "ikalamavony",
        fokontany: "manorikandro",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1992568,
          lng: 46.5836972,
        },
        _id: "64f75284ef8f606073e99126",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "ikalamavony",
        fokontany: "ambodivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1738719,
          lng: 46.58972259999999,
        },
        _id: "64f75284ef8f606073e99127",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "ikalamavony",
        fokontany: "vohitrarivo manirisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1925532,
          lng: 46.6222727,
        },
        _id: "64f75284ef8f606073e99128",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "ikalamavony",
        fokontany: "fotadalina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.288753,
          lng: 46.4777687,
        },
        _id: "64f75284ef8f606073e99129",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "ikalamavony",
        fokontany: "mananatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.25992,
          lng: 46.791569,
        },
        _id: "64f75284ef8f606073e9912a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "mangidy",
        fokontany: "mangidy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1597549,
          lng: 46.7285093,
        },
        _id: "64f75284ef8f606073e9912b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "mangidy",
        fokontany: "laobato avaradrano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2158952,
          lng: 46.815598,
        },
        _id: "64f75284ef8f606073e9912c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "mangidy",
        fokontany: "ivozona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2661134,
          lng: 46.8688931,
        },
        _id: "64f75284ef8f606073e9912d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "mangidy",
        fokontany: "anjorozoro",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2664301,
          lng: 46.839816,
        },
        _id: "64f75284ef8f606073e9912e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "mangidy",
        fokontany: "tamboretina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2988262,
          lng: 46.7768732,
        },
        _id: "64f75284ef8f606073e9912f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "mangidy",
        fokontany: "mandro",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2873249,
          lng: 46.6512239,
        },
        _id: "64f75284ef8f606073e99130",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "mangidy",
        fokontany: "laobato atsimondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2950117,
          lng: 46.839816,
        },
        _id: "64f75284ef8f606073e99131",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "mangidy",
        fokontany: "filemahana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.414209,
          lng: 46.616329,
        },
        _id: "64f75284ef8f606073e99132",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "solila",
        fokontany: "solila",
        __v: 0,
      },
      {
        coords: {
          lat: -21.366667,
          lng: 46.683333,
        },
        _id: "64f75284ef8f606073e99133",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "solila",
        fokontany: "ambalamahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3626509,
          lng: 46.7381783,
        },
        _id: "64f75284ef8f606073e99134",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "solila",
        fokontany: "fihasinana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3749992,
          lng: 46.7768732,
        },
        _id: "64f75284ef8f606073e99135",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "solila",
        fokontany: "ambatovory",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3766542,
          lng: 46.574058,
        },
        _id: "64f75284ef8f606073e99136",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "solila",
        fokontany: "kirano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4073862,
          lng: 46.6995133,
        },
        _id: "64f75284ef8f606073e99137",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "solila",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4449933,
          lng: 46.7575221,
        },
        _id: "64f75284ef8f606073e99138",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "solila",
        fokontany: "ipaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4644782,
          lng: 46.6995133,
        },
        _id: "64f75284ef8f606073e99139",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "solila",
        fokontany: "tearomizaha",
        __v: 0,
      },
      {
        coords: {
          lat: -21.527792,
          lng: 46.7188421,
        },
        _id: "64f75284ef8f606073e9913a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "solila",
        fokontany: "ankazomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -20.90332,
          lng: 46.45079,
        },
        _id: "64f75284ef8f606073e9913b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "ambatomainty",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7842752,
          lng: 46.38166649999999,
        },
        _id: "64f75284ef8f606073e9913c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "ambatomainty",
        fokontany: "sakamadio",
        __v: 0,
      },
      {
        coords: {
          lat: -20.866667,
          lng: 46.35,
        },
        _id: "64f75284ef8f606073e9913d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "ambatomainty",
        fokontany: "ranotsara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8271356,
          lng: 46.4873892,
        },
        _id: "64f75284ef8f606073e9913e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "ambatomainty",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9349565,
          lng: 46.4537256,
        },
        _id: "64f75284ef8f606073e9913f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "ambatomainty",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8578687,
          lng: 46.5547852,
        },
        _id: "64f75284ef8f606073e99140",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "ambatomainty",
        fokontany: "amboasary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9588376,
          lng: 46.574058,
        },
        _id: "64f75284ef8f606073e99141",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "ambatomainty",
        fokontany: "ambalamahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0027359,
          lng: 46.6078033,
        },
        _id: "64f75284ef8f606073e99142",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "ambatomainty",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9980917,
          lng: 46.4585333,
        },
        _id: "64f75284ef8f606073e99143",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "ambatomainty",
        fokontany: "ambodisaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.969,
          lng: 46.308022,
        },
        _id: "64f75284ef8f606073e99144",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "fitampito",
        fokontany: "fitampito",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9049179,
          lng: 46.3145064,
        },
        _id: "64f75284ef8f606073e99145",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "fitampito",
        fokontany: "lanjainony",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0267338,
          lng: 46.0753991,
        },
        _id: "64f75284ef8f606073e99146",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "fitampito",
        fokontany: "ambava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.15,
          lng: 45.95,
        },
        _id: "64f75284ef8f606073e99147",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "fitampito",
        fokontany: "andanoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2919153,
          lng: 45.79002089999999,
        },
        _id: "64f75284ef8f606073e99148",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "fitampito",
        fokontany: "bedray",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1898193,
          lng: 46.1709012,
        },
        _id: "64f75284ef8f606073e99149",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "fitampito",
        fokontany: "androy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3040492,
          lng: 45.97056380000001,
        },
        _id: "64f75284ef8f606073e9914a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "fitampito",
        fokontany: "bekihavy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5286876,
          lng: 46.574058,
        },
        _id: "64f75284ef8f606073e9914b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "tanamarina sakay",
        fokontany: "tanamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4410567,
          lng: 46.3912683,
        },
        _id: "64f75284ef8f606073e9914c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "tanamarina sakay",
        fokontany: "antsamby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4533517,
          lng: 46.4585333,
        },
        _id: "64f75284ef8f606073e9914d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "tanamarina sakay",
        fokontany: "fompohony",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6170687,
          lng: 46.64157160000001,
        },
        _id: "64f75284ef8f606073e9914e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "tanamarina sakay",
        fokontany: "manomboarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6041206,
          lng: 46.6898516,
        },
        _id: "64f75284ef8f606073e9914f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "tanamarina sakay",
        fokontany: "ambalamahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.316839,
          lng: 45.98999,
        },
        _id: "64f75284ef8f606073e99150",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "tsitondroina",
        fokontany: "tsitondroina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3544077,
          lng: 45.9515272,
        },
        _id: "64f75284ef8f606073e99151",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "tsitondroina",
        fokontany: "ambalatsifary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.328867,
          lng: 46.0753991,
        },
        _id: "64f75284ef8f606073e99152",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "tsitondroina",
        fokontany: "marovotry",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3,
          lng: 46.3,
        },
        _id: "64f75284ef8f606073e99153",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "tsitondroina",
        fokontany: "volovandana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3793812,
          lng: 46.0277184,
        },
        _id: "64f75284ef8f606073e99154",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "tsitondroina",
        fokontany: "ambalatelo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4424365,
          lng: 45.9610446,
        },
        _id: "64f75284ef8f606073e99155",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "tsitondroina",
        fokontany: "iabohazo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4797395,
          lng: 46.1900242,
        },
        _id: "64f75284ef8f606073e99156",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "tsitondroina",
        fokontany: "vohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.574949,
          lng: 45.9358099,
        },
        _id: "64f75284ef8f606073e99157",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "tanamarina bekisopa",
        fokontany: "tanamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5179248,
          lng: 45.84696100000001,
        },
        _id: "64f75284ef8f606073e99158",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "tanamarina bekisopa",
        fokontany: "soatanimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5304623,
          lng: 46.0277184,
        },
        _id: "64f75284ef8f606073e99159",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "tanamarina bekisopa",
        fokontany: "ionisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5679739,
          lng: 45.7142063,
        },
        _id: "64f75284ef8f606073e9915a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "tanamarina bekisopa",
        fokontany: "vohitsarana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.61558,
          lng: 46.16027099999999,
        },
        _id: "64f75284ef8f606073e9915b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "tanamarina bekisopa",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6690188,
          lng: 46.0753991,
        },
        _id: "64f75284ef8f606073e9915c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "tanamarina bekisopa",
        fokontany: "betokolava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.66296,
          lng: 45.886501,
        },
        _id: "64f75284ef8f606073e9915d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "ikalamavony",
        commune: "tanamarina bekisopa",
        fokontany: "bekisopa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4920719,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e9915e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "andrainjato centre",
        fokontany: "sahamena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4781218,
          lng: 47.1240312,
        },
        _id: "64f75284ef8f606073e9915f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "andrainjato centre",
        fokontany: "andohamerina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5019502,
          lng: 47.1313396,
        },
        _id: "64f75284ef8f606073e99160",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "andrainjato centre",
        fokontany: "hoditrazo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5054166,
          lng: 47.104547,
        },
        _id: "64f75284ef8f606073e99161",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "andrainjato centre",
        fokontany: "ampampana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5276264,
          lng: 47.1142882,
        },
        _id: "64f75284ef8f606073e99162",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "andrainjato centre",
        fokontany: "ambanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5272508,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e99163",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "andrainjato centre",
        fokontany: "tambikandrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.536511,
          lng: 47.1800893,
        },
        _id: "64f75284ef8f606073e99164",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "andrainjato est",
        fokontany: "mitongoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5013185,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e99165",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "andrainjato est",
        fokontany: "taniditra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5188362,
          lng: 47.192284,
        },
        _id: "64f75284ef8f606073e99166",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "andrainjato est",
        fokontany: "fatakanina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5331127,
          lng: 47.1996022,
        },
        _id: "64f75284ef8f606073e99167",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "andrainjato est",
        fokontany: "tambohonienjanina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5490905,
          lng: 47.19716260000001,
        },
        _id: "64f75284ef8f606073e99168",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "andrainjato est",
        fokontany: "savahaonina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.416667,
          lng: 47.1666669,
        },
        _id: "64f75284ef8f606073e99169",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ambalakely",
        fokontany: "ambalakely",
        __v: 0,
      },
      {
        coords: {
          lat: -21.359608,
          lng: 47.1727739,
        },
        _id: "64f75284ef8f606073e9916a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ambalakely",
        fokontany: "anato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3674573,
          lng: 47.182528,
        },
        _id: "64f75284ef8f606073e9916b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ambalakely",
        fokontany: "anara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3544551,
          lng: 47.2020418,
        },
        _id: "64f75284ef8f606073e9916c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ambalakely",
        fokontany: "akondro est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.385055,
          lng: 47.1776507,
        },
        _id: "64f75284ef8f606073e9916d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ambalakely",
        fokontany: "ambatolahimavo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3911931,
          lng: 47.19716260000001,
        },
        _id: "64f75284ef8f606073e9916e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ambalakely",
        fokontany: "ivoay",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4024746,
          lng: 47.1874058,
        },
        _id: "64f75284ef8f606073e9916f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ambalakely",
        fokontany: "miandrifekona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3978684,
          lng: 47.1727739,
        },
        _id: "64f75284ef8f606073e99170",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ambalakely",
        fokontany: "ampitanandriambola",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4028259,
          lng: 47.1581462,
        },
        _id: "64f75284ef8f606073e99171",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ambalakely",
        fokontany: "volamena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4233195,
          lng: 47.1776507,
        },
        _id: "64f75284ef8f606073e99172",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ambalakely",
        fokontany: "ambalabe anteza",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4219543,
          lng: 47.1581462,
        },
        _id: "64f75284ef8f606073e99173",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ambalakely",
        fokontany: "vohimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3904507,
          lng: 47.1264672,
        },
        _id: "64f75284ef8f606073e99174",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ivoamba",
        fokontany: "ivoamba",
        __v: 0,
      },
      {
        coords: {
          lat: -21.361682,
          lng: 47.133776,
        },
        _id: "64f75284ef8f606073e99175",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ivoamba",
        fokontany: "mandrindrina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3809769,
          lng: 47.1191594,
        },
        _id: "64f75284ef8f606073e99176",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ivoamba",
        fokontany: "ankadindambo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.385584,
          lng: 47.133776,
        },
        _id: "64f75284ef8f606073e99177",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ivoamba",
        fokontany: "ranomadio",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3773817,
          lng: 47.1532712,
        },
        _id: "64f75284ef8f606073e99178",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ivoamba",
        fokontany: "ambodirano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3886553,
          lng: 47.1435227,
        },
        _id: "64f75284ef8f606073e99179",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ivoamba",
        fokontany: "tombadinana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3936945,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e9917a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ivoamba",
        fokontany: "antady",
        __v: 0,
      },
      {
        coords: {
          lat: -21.384391,
          lng: 47.0996772,
        },
        _id: "64f75284ef8f606073e9917b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ivoamba",
        fokontany: "ambalamahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3830228,
          lng: 47.0802023,
        },
        _id: "64f75284ef8f606073e9917c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ivoamba",
        fokontany: "ranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3895034,
          lng: 47.07046769999999,
        },
        _id: "64f75284ef8f606073e9917d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ivoamba",
        fokontany: "vohitrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3987273,
          lng: 47.0996772,
        },
        _id: "64f75284ef8f606073e9917e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ivoamba",
        fokontany: "fangodahy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4033389,
          lng: 47.1142882,
        },
        _id: "64f75284ef8f606073e9917f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ivoamba",
        fokontany: "amontana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.409614,
          lng: 47.1228132,
        },
        _id: "64f75284ef8f606073e99180",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ivoamba",
        fokontany: "ambalatany",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4055175,
          lng: 47.1325578,
        },
        _id: "64f75284ef8f606073e99181",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ivoamba",
        fokontany: "ambondrombato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4669116,
          lng: 47.1482297,
        },
        _id: "64f75284ef8f606073e99182",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "taindambo",
        fokontany: "taindambo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.456465,
          lng: 47.2069214,
        },
        _id: "64f75284ef8f606073e99183",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "taindambo",
        fokontany: "maromangaika",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4679101,
          lng: 47.182528,
        },
        _id: "64f75284ef8f606073e99184",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "taindambo",
        fokontany: "manaotsara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4709886,
          lng: 47.192284,
        },
        _id: "64f75284ef8f606073e99185",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "taindambo",
        fokontany: "sevaina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4756061,
          lng: 47.2069214,
        },
        _id: "64f75284ef8f606073e99186",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "taindambo",
        fokontany: "ambatomena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4760202,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e99187",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "taindambo",
        fokontany: "ambalasarotra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4262185,
          lng: 47.2020418,
        },
        _id: "64f75284ef8f606073e99188",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "mahatsinjony",
        fokontany: "ranoroahina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3734987,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e99189",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "mahatsinjony",
        fokontany: "andranolava II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3892023,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e9918a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "mahatsinjony",
        fokontany: "maromby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3778168,
          lng: 47.2459749,
        },
        _id: "64f75284ef8f606073e9918b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "mahatsinjony",
        fokontany: "ambatolahihambana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4052758,
          lng: 47.2191224,
        },
        _id: "64f75284ef8f606073e9918c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "mahatsinjony",
        fokontany: "andohananatara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4306501,
          lng: 47.2313263,
        },
        _id: "64f75284ef8f606073e9918d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "mahatsinjony",
        fokontany: "amparihibe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4435598,
          lng: 47.2191224,
        },
        _id: "64f75284ef8f606073e9918e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "mahatsinjony",
        fokontany: "ambatazana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4367035,
          lng: 47.2581852,
        },
        _id: "64f75284ef8f606073e9918f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "sahambavy",
        fokontany: "antamiana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4076978,
          lng: 47.280171,
        },
        _id: "64f75284ef8f606073e99190",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "sahambavy",
        fokontany: "antanifotsy I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4079781,
          lng: 47.2581852,
        },
        _id: "64f75284ef8f606073e99191",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "sahambavy",
        fokontany: "amindratombo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4256838,
          lng: 47.2459749,
        },
        _id: "64f75284ef8f606073e99192",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "sahambavy",
        fokontany: "ankazondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4320907,
          lng: 47.2839456,
        },
        _id: "64f75284ef8f606073e99193",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "sahambavy",
        fokontany: "ampasina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4511558,
          lng: 47.2508586,
        },
        _id: "64f75284ef8f606073e99194",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "sahambavy",
        fokontany: "antotohazo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4554956,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e99195",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "sahambavy",
        fokontany: "bedia",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4518731,
          lng: 47.3217253,
        },
        _id: "64f75284ef8f606073e99196",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "sahambavy",
        fokontany: "imorona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4783431,
          lng: 47.2459749,
        },
        _id: "64f75284ef8f606073e99197",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "sahambavy",
        fokontany: "ambalavao ivondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4780823,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e99198",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "sahambavy",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4546147,
          lng: 47.08750449999999,
        },
        _id: "64f75284ef8f606073e99199",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ambalamahasoa",
        fokontany: "ambalamahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3175843,
          lng: 47.0923732,
        },
        _id: "64f75284ef8f606073e9919a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ambalamahasoa",
        fokontany: "ambatolahy vohidravina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3172215,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e9919b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ambalamahasoa",
        fokontany: "bevoanjo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3343042,
          lng: 47.1569274,
        },
        _id: "64f75284ef8f606073e9919c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ambalamahasoa",
        fokontany: "andranomenanjaza",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3293833,
          lng: 47.1678975,
        },
        _id: "64f75284ef8f606073e9919d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ambalamahasoa",
        fokontany: "miandriarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3486937,
          lng: 47.1532712,
        },
        _id: "64f75284ef8f606073e9919e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ambalamahasoa",
        fokontany: "soazanga",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3645714,
          lng: 47.1581462,
        },
        _id: "64f75284ef8f606073e9919f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ambalamahasoa",
        fokontany: "ambalafenomby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.321619,
          lng: 47.2246834,
        },
        _id: "64f75284ef8f606073e991a0",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alakamisy ambohimaha",
        fokontany: "alakamisy ambohimaha",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2627383,
          lng: 47.1459597,
        },
        _id: "64f75284ef8f606073e991a1",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alakamisy ambohimaha",
        fokontany: "safata",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2847232,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e991a2",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alakamisy ambohimaha",
        fokontany: "analamasina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.271307,
          lng: 47.2191224,
        },
        _id: "64f75284ef8f606073e991a3",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alakamisy ambohimaha",
        fokontany: "vatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2996111,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e991a4",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alakamisy ambohimaha",
        fokontany: "tambohivo ambohimaha",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3164738,
          lng: 47.1800893,
        },
        _id: "64f75284ef8f606073e991a5",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alakamisy ambohimaha",
        fokontany: "niadiana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3177158,
          lng: 47.2069214,
        },
        _id: "64f75284ef8f606073e991a6",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alakamisy ambohimaha",
        fokontany: "ambohibory andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3395268,
          lng: 47.2459749,
        },
        _id: "64f75284ef8f606073e991a7",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alakamisy ambohimaha",
        fokontany: "itsangana II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.33532,
          lng: 47.2020418,
        },
        _id: "64f75284ef8f606073e991a8",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alakamisy ambohimaha",
        fokontany: "lavaina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3334053,
          lng: 47.2264444,
        },
        _id: "64f75284ef8f606073e991a9",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alakamisy ambohimaha",
        fokontany: "itsangana I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3356034,
          lng: 47.1800893,
        },
        _id: "64f75284ef8f606073e991aa",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alakamisy ambohimaha",
        fokontany: "talata iboaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3509186,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e991ab",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alakamisy ambohimaha",
        fokontany: "lambohavana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3303726,
          lng: 47.33150759999999,
        },
        _id: "64f75284ef8f606073e991ac",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "androy",
        fokontany: "androy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2434787,
          lng: 47.37555099999999,
        },
        _id: "64f75284ef8f606073e991ad",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "androy",
        fokontany: "vohiparara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2855197,
          lng: 47.3412918,
        },
        _id: "64f75284ef8f606073e991ae",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "androy",
        fokontany: "ambatovaky",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3057226,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e991af",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "androy",
        fokontany: "andovoka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.327314,
          lng: 47.2642914,
        },
        _id: "64f75284ef8f606073e991b0",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "androy",
        fokontany: "nanda",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3631688,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e991b1",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "androy",
        fokontany: "tambohivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3853836,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e991b2",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "androy",
        fokontany: "igodona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3430102,
          lng: 47.3412918,
        },
        _id: "64f75284ef8f606073e991b3",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "androy",
        fokontany: "iambara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3874629,
          lng: 47.3608657,
        },
        _id: "64f75284ef8f606073e991b4",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "androy",
        fokontany: "amindrabe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3945763,
          lng: 47.3070552,
        },
        _id: "64f75284ef8f606073e991b5",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "androy",
        fokontany: "andranovondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.509014,
          lng: 47.3510778,
        },
        _id: "64f75284ef8f606073e991b6",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alatsinainy ialamarina",
        fokontany: "alatsinainy ialamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4380654,
          lng: 47.4000357,
        },
        _id: "64f75284ef8f606073e991b7",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alatsinainy ialamarina",
        fokontany: "ranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4865838,
          lng: 47.3559715,
        },
        _id: "64f75284ef8f606073e991b8",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alatsinainy ialamarina",
        fokontany: "sahafiana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4808021,
          lng: 47.3070552,
        },
        _id: "64f75284ef8f606073e991b9",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alatsinainy ialamarina",
        fokontany: "manakarongana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5126248,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e991ba",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alatsinainy ialamarina",
        fokontany: "andaobatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5033926,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e991bb",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alatsinainy ialamarina",
        fokontany: "vohidalona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5086489,
          lng: 47.380447,
        },
        _id: "64f75284ef8f606073e991bc",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alatsinainy ialamarina",
        fokontany: "anjamambe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5236597,
          lng: 47.3290618,
        },
        _id: "64f75284ef8f606073e991bd",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alatsinainy ialamarina",
        fokontany: "mananda",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5475359,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e991be",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alatsinainy ialamarina",
        fokontany: "todiana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5310859,
          lng: 47.37555099999999,
        },
        _id: "64f75284ef8f606073e991bf",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alatsinainy ialamarina",
        fokontany: "andralamboanivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5417135,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e991c0",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alatsinainy ialamarina",
        fokontany: "besihara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5574601,
          lng: 47.3070552,
        },
        _id: "64f75284ef8f606073e991c1",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alatsinainy ialamarina",
        fokontany: "anjelahy manirisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5661914,
          lng: 47.380447,
        },
        _id: "64f75284ef8f606073e991c2",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alatsinainy ialamarina",
        fokontany: "ambalaivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5762945,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e991c3",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alatsinainy ialamarina",
        fokontany: "ampatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5951346,
          lng: 47.3657603,
        },
        _id: "64f75284ef8f606073e991c4",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "alatsinainy ialamarina",
        fokontany: "anara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5164307,
          lng: 47.2486347,
        },
        _id: "64f75284ef8f606073e991c5",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "fandrandava",
        fokontany: "talata fandrandava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.475352,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e991c6",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "fandrandava",
        fokontany: "iseta",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4944984,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e991c7",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "fandrandava",
        fokontany: "ampalahodambo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.503736,
          lng: 47.2581852,
        },
        _id: "64f75284ef8f606073e991c8",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "fandrandava",
        fokontany: "andranotakatra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4301489,
          lng: 47.13864909999999,
        },
        _id: "64f75284ef8f606073e991c9",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ialananindro",
        fokontany: "sendrinalina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4158053,
          lng: 47.13864909999999,
        },
        _id: "64f75284ef8f606073e991ca",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ialananindro",
        fokontany: "ampasakambana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4299811,
          lng: 47.1532712,
        },
        _id: "64f75284ef8f606073e991cb",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ialananindro",
        fokontany: "tetezambato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.441167,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e991cc",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "lalangina",
        commune: "ialananindro",
        fokontany: "antsiobe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5887911,
          lng: 47.2148517,
        },
        _id: "64f75284ef8f606073e991cd",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahasoabe",
        fokontany: "alarobia",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5423679,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e991ce",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahasoabe",
        fokontany: "ambohibarihena II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5583516,
          lng: 47.2264444,
        },
        _id: "64f75284ef8f606073e991cf",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahasoabe",
        fokontany: "ambohibarihena I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5600513,
          lng: 47.216682,
        },
        _id: "64f75284ef8f606073e991d0",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahasoabe",
        fokontany: "soamanandrariny",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5558,
          lng: 47.1654595,
        },
        _id: "64f75284ef8f606073e991d1",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahasoabe",
        fokontany: "isomitra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5759562,
          lng: 47.221563,
        },
        _id: "64f75284ef8f606073e991d2",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahasoabe",
        fokontany: "ramampiray",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5725578,
          lng: 47.2410916,
        },
        _id: "64f75284ef8f606073e991d3",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahasoabe",
        fokontany: "ambalavao mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5861231,
          lng: 47.16302160000001,
        },
        _id: "64f75284ef8f606073e991d4",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahasoabe",
        fokontany: "vohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5869224,
          lng: 47.2410916,
        },
        _id: "64f75284ef8f606073e991d5",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahasoabe",
        fokontany: "tsararivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.594351,
          lng: 47.2202,
        },
        _id: "64f75284ef8f606073e991d6",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahasoabe",
        fokontany: "soamanandray",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5865663,
          lng: 47.1983824,
        },
        _id: "64f75284ef8f606073e991d7",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahasoabe",
        fokontany: "mandaratsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5937083,
          lng: 47.2020418,
        },
        _id: "64f75284ef8f606073e991d8",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahasoabe",
        fokontany: "sahely",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5980442,
          lng: 47.2459749,
        },
        _id: "64f75284ef8f606073e991d9",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahasoabe",
        fokontany: "mahasoakely",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6004043,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e991da",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahasoabe",
        fokontany: "anjanamahasoa avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.591863,
          lng: 47.2264444,
        },
        _id: "64f75284ef8f606073e991db",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahasoabe",
        fokontany: "manamisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5968003,
          lng: 47.2118015,
        },
        _id: "64f75284ef8f606073e991dc",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahasoabe",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6124853,
          lng: 47.2386501,
        },
        _id: "64f75284ef8f606073e991dd",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahasoabe",
        fokontany: "soaiombonana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6127076,
          lng: 47.216682,
        },
        _id: "64f75284ef8f606073e991de",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahasoabe",
        fokontany: "vohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6205889,
          lng: 47.2264444,
        },
        _id: "64f75284ef8f606073e991df",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahasoabe",
        fokontany: "vohitsoa maharitra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6255218,
          lng: 47.2118015,
        },
        _id: "64f75284ef8f606073e991e0",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahasoabe",
        fokontany: "ambatomifanongoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7336293,
          lng: 47.2581852,
        },
        _id: "64f75284ef8f606073e991e1",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vinanitelo",
        fokontany: "vinanitelo ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7144677,
          lng: 47.2581852,
        },
        _id: "64f75284ef8f606073e991e2",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vinanitelo",
        fokontany: "nandraina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7333608,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e991e3",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vinanitelo",
        fokontany: "vinanitelo centre",
        __v: 0,
      },
      {
        coords: {
          lat: -21.764816,
          lng: 47.3412918,
        },
        _id: "64f75284ef8f606073e991e4",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vinanitelo",
        fokontany: "vinanitelo atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8166306,
          lng: 47.2630701,
        },
        _id: "64f75284ef8f606073e991e5",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vinanitelo",
        fokontany: "vinanitelo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8170935,
          lng: 47.2044815,
        },
        _id: "64f75284ef8f606073e991e6",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vinanitelo",
        fokontany: "sandranata",
        __v: 0,
      },
      {
        coords: {
          lat: -21.64608,
          lng: 47.161701,
        },
        _id: "64f75284ef8f606073e991e7",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "alakamisy itenina",
        fokontany: "alakamisy itenina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6257359,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e991e8",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "alakamisy itenina",
        fokontany: "anjanamahasoa atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6338285,
          lng: 47.1776507,
        },
        _id: "64f75284ef8f606073e991e9",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "alakamisy itenina",
        fokontany: "anjanamahasoa andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6341017,
          lng: 47.1483967,
        },
        _id: "64f75284ef8f606073e991ea",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "alakamisy itenina",
        fokontany: "vohitraivo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.64608,
          lng: 47.161701,
        },
        _id: "64f75284ef8f606073e991eb",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "alakamisy itenina",
        fokontany: "midongy avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6407004,
          lng: 47.1240312,
        },
        _id: "64f75284ef8f606073e991ec",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "alakamisy itenina",
        fokontany: "sangasanga nord",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6552384,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e991ed",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "alakamisy itenina",
        fokontany: "sangasanga centre",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6578225,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e991ee",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "alakamisy itenina",
        fokontany: "riambary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6671249,
          lng: 47.1996022,
        },
        _id: "64f75284ef8f606073e991ef",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "alakamisy itenina",
        fokontany: "tsiahorea",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6831694,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e991f0",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "alakamisy itenina",
        fokontany: "sahavindrany",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6836825,
          lng: 47.1313396,
        },
        _id: "64f75284ef8f606073e991f1",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "alakamisy itenina",
        fokontany: "midongy afovoany",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6808206,
          lng: 47.0923732,
        },
        _id: "64f75284ef8f606073e991f2",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "alakamisy itenina",
        fokontany: "sangasanga sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6849173,
          lng: 47.1727739,
        },
        _id: "64f75284ef8f606073e991f3",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "alakamisy itenina",
        fokontany: "ankaromalaza nord",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6898939,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e991f4",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "alakamisy itenina",
        fokontany: "midongy est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5678899,
          lng: 47.0424999,
        },
        _id: "64f75284ef8f606073e991f5",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "talata ampano",
        fokontany: "talata ampano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5121093,
          lng: 47.0729012,
        },
        _id: "64f75284ef8f606073e991f6",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "talata ampano",
        fokontany: "iharanany atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5280973,
          lng: 47.06681769999999,
        },
        _id: "64f75284ef8f606073e991f7",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "talata ampano",
        fokontany: "vohidroa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5214488,
          lng: 47.0948078,
        },
        _id: "64f75284ef8f606073e991f8",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "talata ampano",
        fokontany: "tanjombita",
        __v: 0,
      },
      {
        coords: {
          lat: -21.532981,
          lng: 47.0558692,
        },
        _id: "64f75284ef8f606073e991f9",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "talata ampano",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5438734,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e991fa",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "talata ampano",
        fokontany: "ialadalangy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5379651,
          lng: 47.0339793,
        },
        _id: "64f75284ef8f606073e991fb",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "talata ampano",
        fokontany: "langela",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5413288,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e991fc",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "talata ampano",
        fokontany: "mahatsanda",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5472457,
          lng: 47.0631679,
        },
        _id: "64f75284ef8f606073e991fd",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "talata ampano",
        fokontany: "marovory",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5606883,
          lng: 46.9853688,
        },
        _id: "64f75284ef8f606073e991fe",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "talata ampano",
        fokontany: "ambalafandrambato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5632638,
          lng: 47.0534365,
        },
        _id: "64f75284ef8f606073e991ff",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "talata ampano",
        fokontany: "marodinta",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5699837,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e99200",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "talata ampano",
        fokontany: "ampano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5857344,
          lng: 47.0339793,
        },
        _id: "64f75284ef8f606073e99201",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "talata ampano",
        fokontany: "manandreana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5928655,
          lng: 47.2826144,
        },
        _id: "64f75284ef8f606073e99202",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "ihazoara",
        fokontany: "ihazoara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5627827,
          lng: 47.25940629999999,
        },
        _id: "64f75284ef8f606073e99203",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "ihazoara",
        fokontany: "ivondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5674515,
          lng: 47.2703983,
        },
        _id: "64f75284ef8f606073e99204",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "ihazoara",
        fokontany: "ikilivia",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5800373,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e99205",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "ihazoara",
        fokontany: "soavimbahoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5756455,
          lng: 47.2508586,
        },
        _id: "64f75284ef8f606073e99206",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "ihazoara",
        fokontany: "andreanamahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5845855,
          lng: 47.3094999,
        },
        _id: "64f75284ef8f606073e99207",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "ihazoara",
        fokontany: "ambohimaranitra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5987996,
          lng: 47.3241707,
        },
        _id: "64f75284ef8f606073e99208",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "ihazoara",
        fokontany: "andraramila",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5958737,
          lng: 47.2997218,
        },
        _id: "64f75284ef8f606073e99209",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "ihazoara",
        fokontany: "soatsiavela",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5961117,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e9920a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "ihazoara",
        fokontany: "nanadreana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5915563,
          lng: 47.2557429,
        },
        _id: "64f75284ef8f606073e9920b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "ihazoara",
        fokontany: "ambozontany",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7346285,
          lng: 47.1410858,
        },
        _id: "64f75284ef8f606073e9920c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "ankaromalaza mifanasoa",
        fokontany: "asana vatomitatana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7154313,
          lng: 47.1483967,
        },
        _id: "64f75284ef8f606073e9920d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "ankaromalaza mifanasoa",
        fokontany: "midongy est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7248183,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e9920e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "ankaromalaza mifanasoa",
        fokontany: "ankaromalaza ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7602201,
          lng: 47.1313396,
        },
        _id: "64f75284ef8f606073e9920f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "ankaromalaza mifanasoa",
        fokontany: "fanameana soalozoka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7566452,
          lng: 47.1800893,
        },
        _id: "64f75284ef8f606073e99210",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "ankaromalaza mifanasoa",
        fokontany: "ambatoharanana fanameana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6248708,
          lng: 46.9173921,
        },
        _id: "64f75284ef8f606073e99211",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohimarina",
        fokontany: "soaronenana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5712859,
          lng: 46.8543525,
        },
        _id: "64f75284ef8f606073e99212",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohimarina",
        fokontany: "vahambe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5973661,
          lng: 46.7575221,
        },
        _id: "64f75284ef8f606073e99213",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohimarina",
        fokontany: "vaolahy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5526479,
          lng: 46.7962319,
        },
        _id: "64f75284ef8f606073e99214",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohimarina",
        fokontany: "tsifo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5804923,
          lng: 46.89798690000001,
        },
        _id: "64f75284ef8f606073e99215",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohimarina",
        fokontany: "dandemy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6417025,
          lng: 46.7768732,
        },
        _id: "64f75284ef8f606073e99216",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohimarina",
        fokontany: "anja lamosina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6254821,
          lng: 46.83012739999999,
        },
        _id: "64f75284ef8f606073e99217",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohimarina",
        fokontany: "mahasoa II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6156469,
          lng: 46.8737409,
        },
        _id: "64f75284ef8f606073e99218",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohimarina",
        fokontany: "mahasoa I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.660028,
          lng: 46.8931368,
        },
        _id: "64f75284ef8f606073e99219",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohimarina",
        fokontany: "ambalamirary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6583214,
          lng: 46.9101143,
        },
        _id: "64f75284ef8f606073e9921a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohimarina",
        fokontany: "andoharano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.659619,
          lng: 46.9513691,
        },
        _id: "64f75284ef8f606073e9921b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohimarina",
        fokontany: "anjanomalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6920437,
          lng: 46.8591989,
        },
        _id: "64f75284ef8f606073e9921c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohimarina",
        fokontany: "vohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6794767,
          lng: 46.83497149999999,
        },
        _id: "64f75284ef8f606073e9921d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohimarina",
        fokontany: "fanameana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6914761,
          lng: 46.9465138,
        },
        _id: "64f75284ef8f606073e9921e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohimarina",
        fokontany: "lazainarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5917358,
          lng: 47.0753348,
        },
        _id: "64f75284ef8f606073e9921f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "maneva",
        fokontany: "ampasimbe beravina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5629912,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e99220",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "maneva",
        fokontany: "avaradrano maneva",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5823102,
          lng: 47.0607349,
        },
        _id: "64f75284ef8f606073e99221",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "maneva",
        fokontany: "andoharanodita",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6045892,
          lng: 47.0631679,
        },
        _id: "64f75284ef8f606073e99222",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "maneva",
        fokontany: "maneva andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6202844,
          lng: 47.0899388,
        },
        _id: "64f75284ef8f606073e99223",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "maneva",
        fokontany: "ambalavao maneva",
        __v: 0,
      },
      {
        coords: {
          lat: -21.623642,
          lng: 47.07046769999999,
        },
        _id: "64f75284ef8f606073e99224",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "maneva",
        fokontany: "ambalamarina I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6955803,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e99225",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohitrafeno",
        fokontany: "vohitrafeno centre",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6446703,
          lng: 47.2118015,
        },
        _id: "64f75284ef8f606073e99226",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohitrafeno",
        fokontany: "mitoko nord",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6573433,
          lng: 47.221563,
        },
        _id: "64f75284ef8f606073e99227",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohitrafeno",
        fokontany: "mitoko est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.671707,
          lng: 47.221563,
        },
        _id: "64f75284ef8f606073e99228",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohitrafeno",
        fokontany: "andondona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6795265,
          lng: 47.2386501,
        },
        _id: "64f75284ef8f606073e99229",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohitrafeno",
        fokontany: "ambalavao",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7085254,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e9922a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohitrafeno",
        fokontany: "ankaromalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7403602,
          lng: 47.2191224,
        },
        _id: "64f75284ef8f606073e9922b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohitrafeno",
        fokontany: "ambohimana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.64921,
          lng: 46.990879,
        },
        _id: "64f75284ef8f606073e9922c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranovorivato",
        fokontany: "andranovorivato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5579584,
          lng: 46.9319509,
        },
        _id: "64f75284ef8f606073e9922d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranovorivato",
        fokontany: "ambodiranomaitso",
        __v: 0,
      },
      {
        coords: {
          lat: -21.589807,
          lng: 46.9270975,
        },
        _id: "64f75284ef8f606073e9922e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranovorivato",
        fokontany: "sahanavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5797859,
          lng: 46.9853688,
        },
        _id: "64f75284ef8f606073e9922f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranovorivato",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5923754,
          lng: 47.00237720000001,
        },
        _id: "64f75284ef8f606073e99230",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranovorivato",
        fokontany: "ambatosoa II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6084336,
          lng: 46.9853688,
        },
        _id: "64f75284ef8f606073e99231",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranovorivato",
        fokontany: "ambatosoa I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6182135,
          lng: 46.9562248,
        },
        _id: "64f75284ef8f606073e99232",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranovorivato",
        fokontany: "somaina II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6435245,
          lng: 46.9756523,
        },
        _id: "64f75284ef8f606073e99233",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranovorivato",
        fokontany: "vohihambana somaina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6081928,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e99234",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranovorivato",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6335081,
          lng: 47.0339793,
        },
        _id: "64f75284ef8f606073e99235",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranovorivato",
        fokontany: "sahavanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6845089,
          lng: 47.0291162,
        },
        _id: "64f75284ef8f606073e99236",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranovorivato",
        fokontany: "ankaritsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6587127,
          lng: 47.0680343,
        },
        _id: "64f75284ef8f606073e99237",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranovorivato",
        fokontany: "sangasanga",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6906164,
          lng: 47.0631679,
        },
        _id: "64f75284ef8f606073e99238",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranovorivato",
        fokontany: "ambohibolamena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.64921,
          lng: 46.990879,
        },
        _id: "64f75284ef8f606073e99239",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranovorivato",
        fokontany: "vinanitsareana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6943863,
          lng: 46.9853688,
        },
        _id: "64f75284ef8f606073e9923a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranovorivato",
        fokontany: "andrainjato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7231392,
          lng: 46.9707947,
        },
        _id: "64f75284ef8f606073e9923b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranovorivato",
        fokontany: "ambalavohimay",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5944948,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e9923c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohibato ouest",
        fokontany: "sahavoha",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5883025,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e9923d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohibato ouest",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6200878,
          lng: 47.1118527,
        },
        _id: "64f75284ef8f606073e9923e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohibato ouest",
        fokontany: "antsiobe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.616721,
          lng: 47.1313396,
        },
        _id: "64f75284ef8f606073e9923f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "vohibato ouest",
        fokontany: "vohitraivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6440168,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e99240",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranomiditra",
        fokontany: "manatenasoa I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6081415,
          lng: 47.3461846,
        },
        _id: "64f75284ef8f606073e99241",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranomiditra",
        fokontany: "vohibato seranana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6217622,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e99242",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranomiditra",
        fokontany: "andreana vohibato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6150399,
          lng: 47.2997218,
        },
        _id: "64f75284ef8f606073e99243",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranomiditra",
        fokontany: "tanitsihomehy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6267766,
          lng: 47.2459749,
        },
        _id: "64f75284ef8f606073e99244",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranomiditra",
        fokontany: "mamarina tsileondahy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6317121,
          lng: 47.2313263,
        },
        _id: "64f75284ef8f606073e99245",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranomiditra",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6411435,
          lng: 47.2459749,
        },
        _id: "64f75284ef8f606073e99246",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranomiditra",
        fokontany: "ranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6625798,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e99247",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranomiditra",
        fokontany: "antarambiby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6367465,
          lng: 47.3608657,
        },
        _id: "64f75284ef8f606073e99248",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranomiditra",
        fokontany: "ikongo ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -21.653818,
          lng: 47.2557429,
        },
        _id: "64f75284ef8f606073e99249",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranomiditra",
        fokontany: "manatenasoa II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6882479,
          lng: 47.3266162,
        },
        _id: "64f75284ef8f606073e9924a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranomiditra",
        fokontany: "marokona II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6696644,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e9924b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranomiditra",
        fokontany: "marokona I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7108104,
          lng: 47.3070552,
        },
        _id: "64f75284ef8f606073e9924c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "andranomiditra",
        fokontany: "marokona III",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5187888,
          lng: 47.0412749,
        },
        _id: "64f75284ef8f606073e9924d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "soaindrana",
        fokontany: "imarotavy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4871123,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e9924e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "soaindrana",
        fokontany: "lalazana ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -21.501226,
          lng: 47.0461392,
        },
        _id: "64f75284ef8f606073e9924f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "soaindrana",
        fokontany: "lalazana ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5090924,
          lng: 47.0558692,
        },
        _id: "64f75284ef8f606073e99250",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "soaindrana",
        fokontany: "iharanany andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5222264,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e99251",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "soaindrana",
        fokontany: "sahamena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7491818,
          lng: 47.0994536,
        },
        _id: "64f75284ef8f606073e99252",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahaditra",
        fokontany: "asabotsy moralina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7031718,
          lng: 47.08750449999999,
        },
        _id: "64f75284ef8f606073e99253",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahaditra",
        fokontany: "anjanomanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7188397,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e99254",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahaditra",
        fokontany: "midongy andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7348597,
          lng: 47.1118527,
        },
        _id: "64f75284ef8f606073e99255",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahaditra",
        fokontany: "vatomitantana ambatoteraka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7480801,
          lng: 47.0485715,
        },
        _id: "64f75284ef8f606073e99256",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahaditra",
        fokontany: "ambohibory moralina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.779726,
          lng: 47.0802023,
        },
        _id: "64f75284ef8f606073e99257",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahaditra",
        fokontany: "tambohosoa monongona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7793561,
          lng: 47.1313396,
        },
        _id: "64f75284ef8f606073e99258",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahaditra",
        fokontany: "vohitraveotra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7799763,
          lng: 47.043707,
        },
        _id: "64f75284ef8f606073e99259",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahaditra",
        fokontany: "ambalavao fihahia",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7922023,
          lng: 47.1191594,
        },
        _id: "64f75284ef8f606073e9925a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahaditra",
        fokontany: "maromiandra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7891354,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e9925b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahaditra",
        fokontany: "tsimaitohasoa nord",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8304222,
          lng: 47.1264672,
        },
        _id: "64f75284ef8f606073e9925c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahaditra",
        fokontany: "tsimaitohasoa est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8046224,
          lng: 47.1654595,
        },
        _id: "64f75284ef8f606073e9925d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "vohibato",
        commune: "mahaditra",
        fokontany: "ambalamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3226002,
          lng: 46.93437770000001,
        },
        _id: "64f75284ef8f606073e9925e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "isorana",
        fokontany: "isorana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2337863,
          lng: 46.9173921,
        },
        _id: "64f75284ef8f606073e9925f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "isorana",
        fokontany: "mandanona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2638406,
          lng: 46.92952409999999,
        },
        _id: "64f75284ef8f606073e99260",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "isorana",
        fokontany: "amboakitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2528558,
          lng: 46.9173921,
        },
        _id: "64f75284ef8f606073e99261",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "isorana",
        fokontany: "andohareana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2783922,
          lng: 46.9076886,
        },
        _id: "64f75284ef8f606073e99262",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "isorana",
        fokontany: "sabotsy itomboana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2976205,
          lng: 46.8931368,
        },
        _id: "64f75284ef8f606073e99263",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "isorana",
        fokontany: "sahasoa itomboana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3259893,
          lng: 46.914966,
        },
        _id: "64f75284ef8f606073e99264",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "isorana",
        fokontany: "tanantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3481392,
          lng: 46.924671,
        },
        _id: "64f75284ef8f606073e99265",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "isorana",
        fokontany: "vatambe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3451386,
          lng: 46.9076886,
        },
        _id: "64f75284ef8f606073e99266",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "isorana",
        fokontany: "tambohobe vohitsisaky",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3542149,
          lng: 46.9513691,
        },
        _id: "64f75284ef8f606073e99267",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "isorana",
        fokontany: "marolahy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2276584,
          lng: 46.89798690000001,
        },
        _id: "64f75284ef8f606073e99268",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "anjoma itsara",
        fokontany: "benangana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2033875,
          lng: 46.93437770000001,
        },
        _id: "64f75284ef8f606073e99269",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "anjoma itsara",
        fokontany: "ambohimahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1983485,
          lng: 46.9562248,
        },
        _id: "64f75284ef8f606073e9926a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "anjoma itsara",
        fokontany: "ikando",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2204849,
          lng: 46.9659376,
        },
        _id: "64f75284ef8f606073e9926b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "anjoma itsara",
        fokontany: "tambohobe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2398271,
          lng: 46.9440864,
        },
        _id: "64f75284ef8f606073e9926c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "anjoma itsara",
        fokontany: "lavatrafo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4695255,
          lng: 46.9501552,
        },
        _id: "64f75284ef8f606073e9926d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "alatsinainy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4079345,
          lng: 46.9853688,
        },
        _id: "64f75284ef8f606073e9926e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "vatomaitso",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4273189,
          lng: 46.9562248,
        },
        _id: "64f75284ef8f606073e9926f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "irano anosilava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4387573,
          lng: 46.924671,
        },
        _id: "64f75284ef8f606073e99270",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "vohitenina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4509276,
          lng: 46.9817249,
        },
        _id: "64f75284ef8f606073e99271",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "ampanenjanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4492037,
          lng: 46.9950872,
        },
        _id: "64f75284ef8f606073e99272",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "soamanakova",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4575621,
          lng: 46.9537969,
        },
        _id: "64f75284ef8f606073e99273",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "andovoka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4634531,
          lng: 47.00237720000001,
        },
        _id: "64f75284ef8f606073e99274",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "ranomaitso",
        __v: 0,
      },
      {
        coords: {
          lat: -21.459517,
          lng: 46.914966,
        },
        _id: "64f75284ef8f606073e99275",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "ambalamisaotra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4716696,
          lng: 46.9756523,
        },
        _id: "64f75284ef8f606073e99276",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "ambalatondroina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4688551,
          lng: 46.9368047,
        },
        _id: "64f75284ef8f606073e99277",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "andreamitsioka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4770492,
          lng: 46.9101143,
        },
        _id: "64f75284ef8f606073e99278",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "mahavanona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4951339,
          lng: 46.839816,
        },
        _id: "64f75284ef8f606073e99279",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "itohy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4910312,
          lng: 46.9465138,
        },
        _id: "64f75284ef8f606073e9927a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "betapoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.489284,
          lng: 46.9635092,
        },
        _id: "64f75284ef8f606073e9927b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "ambohitrasoavina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4880046,
          lng: 46.92952409999999,
        },
        _id: "64f75284ef8f606073e9927c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "antaniditra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4993494,
          lng: 46.905263,
        },
        _id: "64f75284ef8f606073e9927d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "andranomiboaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5034,
          lng: 46.9853688,
        },
        _id: "64f75284ef8f606073e9927e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "mitsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5075248,
          lng: 46.8785892,
        },
        _id: "64f75284ef8f606073e9927f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "ambalaseva",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5016824,
          lng: 46.815598,
        },
        _id: "64f75284ef8f606073e99280",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "mahalay",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5134696,
          lng: 46.9270975,
        },
        _id: "64f75284ef8f606073e99281",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "tanamarina laobato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.529932,
          lng: 46.8591989,
        },
        _id: "64f75284ef8f606073e99282",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "vohimanombo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5227569,
          lng: 46.9562248,
        },
        _id: "64f75284ef8f606073e99283",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "andoharanomaitso",
        fokontany: "ambalabetsila",
        __v: 0,
      },
      {
        coords: {
          lat: -21.266667,
          lng: 46.98333299999999,
        },
        _id: "64f75284ef8f606073e99284",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ambondrona",
        fokontany: "ambondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2426265,
          lng: 46.9756523,
        },
        _id: "64f75284ef8f606073e99285",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ambondrona",
        fokontany: "tranovondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.25145,
          lng: 47.0339793,
        },
        _id: "64f75284ef8f606073e99286",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ambondrona",
        fokontany: "tsarafidy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2704552,
          lng: 47.0412749,
        },
        _id: "64f75284ef8f606073e99287",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ambondrona",
        fokontany: "antsiravaza",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2620536,
          lng: 46.9465138,
        },
        _id: "64f75284ef8f606073e99288",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ambondrona",
        fokontany: "tambohoniomby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2903342,
          lng: 46.9756523,
        },
        _id: "64f75284ef8f606073e99289",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ambondrona",
        fokontany: "ambalafila",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2995369,
          lng: 47.0048074,
        },
        _id: "64f75284ef8f606073e9928a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ambondrona",
        fokontany: "ambalamahatsinjo sakaroa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.296124,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e9928b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ambondrona",
        fokontany: "fierana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3023438,
          lng: 47.0364111,
        },
        _id: "64f75284ef8f606073e9928c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ambondrona",
        fokontany: "ambalafanovana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2890519,
          lng: 46.9489414,
        },
        _id: "64f75284ef8f606073e9928d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ambondrona",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3189613,
          lng: 46.9756523,
        },
        _id: "64f75284ef8f606073e9928e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ambondrona",
        fokontany: "ankofafamavo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3322342,
          lng: 46.85800709999999,
        },
        _id: "64f75284ef8f606073e9928f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ambalamidera II",
        fokontany: "ambalamidera II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3140672,
          lng: 46.839816,
        },
        _id: "64f75284ef8f606073e99290",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ambalamidera II",
        fokontany: "ambatolahitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3137661,
          lng: 46.8688931,
        },
        _id: "64f75284ef8f606073e99291",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ambalamidera II",
        fokontany: "tanantsoa ambohimila",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3485176,
          lng: 46.8882871,
        },
        _id: "64f75284ef8f606073e99292",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ambalamidera II",
        fokontany: "ambatolahifolaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3425797,
          lng: 46.8470837,
        },
        _id: "64f75284ef8f606073e99293",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ambalamidera II",
        fokontany: "antoetra I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3946854,
          lng: 47.0339793,
        },
        _id: "64f75284ef8f606073e99294",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ankarinarivo manirisoa",
        fokontany: "ankarinarivo centre",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3691084,
          lng: 47.043707,
        },
        _id: "64f75284ef8f606073e99295",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ankarinarivo manirisoa",
        fokontany: "ambohifirariantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3944439,
          lng: 47.0558692,
        },
        _id: "64f75284ef8f606073e99296",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ankarinarivo manirisoa",
        fokontany: "fivoarantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4038352,
          lng: 47.07046769999999,
        },
        _id: "64f75284ef8f606073e99297",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ankarinarivo manirisoa",
        fokontany: "tandrokininosy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4135501,
          lng: 47.0558692,
        },
        _id: "64f75284ef8f606073e99298",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ankarinarivo manirisoa",
        fokontany: "analalava mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4267259,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e99299",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ankarinarivo manirisoa",
        fokontany: "ambalamarina ankaranosy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3980794,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e9929a",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ankarinarivo manirisoa",
        fokontany: "ambalavao andohony",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4392821,
          lng: 47.0315477,
        },
        _id: "64f75284ef8f606073e9929b",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "ankarinarivo manirisoa",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.38858,
          lng: 46.915321,
        },
        _id: "64f75284ef8f606073e9929c",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "soatanana",
        fokontany: "soatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3624461,
          lng: 46.924671,
        },
        _id: "64f75284ef8f606073e9929d",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "soatanana",
        fokontany: "ampolatany",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3736733,
          lng: 46.914966,
        },
        _id: "64f75284ef8f606073e9929e",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "soatanana",
        fokontany: "iarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3848975,
          lng: 46.905263,
        },
        _id: "64f75284ef8f606073e9929f",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "soatanana",
        fokontany: "ambalalovana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3734489,
          lng: 46.9368047,
        },
        _id: "64f75284ef8f606073e992a0",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "soatanana",
        fokontany: "ambalamarina tsianda",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3943258,
          lng: 46.9161791,
        },
        _id: "64f75284ef8f606073e992a1",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "soatanana",
        fokontany: "ambalatsileo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4020687,
          lng: 46.9368047,
        },
        _id: "64f75284ef8f606073e992a2",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "soatanana",
        fokontany: "sonjoandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4039709,
          lng: 46.905263,
        },
        _id: "64f75284ef8f606073e992a3",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "soatanana",
        fokontany: "samilahy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4245184,
          lng: 46.9173921,
        },
        _id: "64f75284ef8f606073e992a4",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "soatanana",
        fokontany: "tandemoka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4546147,
          lng: 47.08750449999999,
        },
        _id: "64f75284ef8f606073e992a5",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "nasandratrony",
        fokontany: "nasandratrony",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3294564,
          lng: 47.0315477,
        },
        _id: "64f75284ef8f606073e992a6",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "nasandratrony",
        fokontany: "andrefan'iavomanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3388361,
          lng: 47.0461392,
        },
        _id: "64f75284ef8f606073e992a7",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "nasandratrony",
        fokontany: "iavomanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3411203,
          lng: 46.9853688,
        },
        _id: "64f75284ef8f606073e992a8",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "nasandratrony",
        fokontany: "soanierana ivinga",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3516265,
          lng: 47.0412749,
        },
        _id: "64f75284ef8f606073e992a9",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "nasandratrony",
        fokontany: "ambohipihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3472664,
          lng: 47.0048074,
        },
        _id: "64f75284ef8f606073e992aa",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "nasandratrony",
        fokontany: "ambalamirarisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3602091,
          lng: 46.9853688,
        },
        _id: "64f75284ef8f606073e992ab",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "nasandratrony",
        fokontany: "tsaramandroso anara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3759065,
          lng: 47.0048074,
        },
        _id: "64f75284ef8f606073e992ac",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "nasandratrony",
        fokontany: "soamiandrizafy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3682926,
          lng: 46.815598,
        },
        _id: "64f75284ef8f606073e992ad",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "mahazoarivo",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3712384,
          lng: 46.839816,
        },
        _id: "64f75284ef8f606073e992ae",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "mahazoarivo",
        fokontany: "ambatolahilava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4058633,
          lng: 46.8737409,
        },
        _id: "64f75284ef8f606073e992af",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "mahazoarivo",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4255815,
          lng: 46.8010727,
        },
        _id: "64f75284ef8f606073e992b0",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "mahazoarivo",
        fokontany: "ambalafanompoana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4441296,
          lng: 46.8591989,
        },
        _id: "64f75284ef8f606073e992b1",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "mahazoarivo",
        fokontany: "ankazotana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4572556,
          lng: 46.8107557,
        },
        _id: "64f75284ef8f606073e992b2",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "mahazoarivo",
        fokontany: "lavahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4724749,
          lng: 46.8882871,
        },
        _id: "64f75284ef8f606073e992b3",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "mahazoarivo",
        fokontany: "ambaibo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.19018,
          lng: 46.891312,
        },
        _id: "64f75284ef8f606073e992b4",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "fanjakana",
        fokontany: "fanjakana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0578226,
          lng: 46.7671967,
        },
        _id: "64f75284ef8f606073e992b5",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "fanjakana",
        fokontany: "antanimena manody",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1336016,
          lng: 46.7962319,
        },
        _id: "64f75284ef8f606073e992b6",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "fanjakana",
        fokontany: "lazaina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1315883,
          lng: 46.9562248,
        },
        _id: "64f75284ef8f606073e992b7",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "fanjakana",
        fokontany: "fiva",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1382635,
          lng: 46.9319509,
        },
        _id: "64f75284ef8f606073e992b8",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "fanjakana",
        fokontany: "ambohipihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1258677,
          lng: 46.9076886,
        },
        _id: "64f75284ef8f606073e992b9",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "fanjakana",
        fokontany: "ranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1709986,
          lng: 46.8543525,
        },
        _id: "64f75284ef8f606073e992ba",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "fanjakana",
        fokontany: "ambohimahaihova",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1702879,
          lng: 46.91254010000001,
        },
        _id: "64f75284ef8f606073e992bb",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "fanjakana",
        fokontany: "ambalamarina est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1949241,
          lng: 46.9756523,
        },
        _id: "64f75284ef8f606073e992bc",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "fanjakana",
        fokontany: "ambodisandra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.19018,
          lng: 46.891312,
        },
        _id: "64f75284ef8f606073e992bd",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "fanjakana",
        fokontany: "ambatolahinandriamanalina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.336695,
          lng: 47.0923732,
        },
        _id: "64f75284ef8f606073e992be",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "iavonomby vohibola",
        fokontany: "manantenasoa handroso",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3244238,
          lng: 47.0534365,
        },
        _id: "64f75284ef8f606073e992bf",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "iavonomby vohibola",
        fokontany: "soanataondray",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3381402,
          lng: 47.104547,
        },
        _id: "64f75284ef8f606073e992c0",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "iavonomby vohibola",
        fokontany: "ambalalamay",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3554564,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e992c1",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "iavonomby vohibola",
        fokontany: "lovasoanirina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3557198,
          lng: 47.0996772,
        },
        _id: "64f75284ef8f606073e992c2",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "iavonomby vohibola",
        fokontany: "fivoarana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3480458,
          lng: 47.0753348,
        },
        _id: "64f75284ef8f606073e992c3",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "iavonomby vohibola",
        fokontany: "nohasoavimbahoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3718469,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e992c4",
        province: "fianarantsoa",
        region: "haute matsiatra",
        district: "isandra",
        commune: "iavonomby vohibola",
        fokontany: "behena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5501168,
          lng: 46.8010727,
        },
        _id: "64f75284ef8f606073e992c5",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "ambatofinandrahana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3236024,
          lng: 46.7285093,
        },
        _id: "64f75284ef8f606073e992c6",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "antsangindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4689651,
          lng: 46.7381783,
        },
        _id: "64f75284ef8f606073e992c7",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "sahavatoana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4613248,
          lng: 46.805914,
        },
        _id: "64f75284ef8f606073e992c8",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "andraikita",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5297212,
          lng: 46.8737409,
        },
        _id: "64f75284ef8f606073e992c9",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "antsahavory",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5245893,
          lng: 46.8107557,
        },
        _id: "64f75284ef8f606073e992ca",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "vinany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5319187,
          lng: 46.7575221,
        },
        _id: "64f75284ef8f606073e992cb",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "ambatomenaloha",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5590816,
          lng: 46.83012739999999,
        },
        _id: "64f75284ef8f606073e992cc",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "tetikanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5691265,
          lng: 46.8010727,
        },
        _id: "64f75284ef8f606073e992cd",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "ambolomborona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5769389,
          lng: 46.7188421,
        },
        _id: "64f75284ef8f606073e992ce",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "sambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5821512,
          lng: 46.7817122,
        },
        _id: "64f75284ef8f606073e992cf",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "matahimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5911301,
          lng: 46.8107557,
        },
        _id: "64f75284ef8f606073e992d0",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "anavozo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5938571,
          lng: 46.83497149999999,
        },
        _id: "64f75284ef8f606073e992d1",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "mahavanona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5797189,
          lng: 46.91254010000001,
        },
        _id: "64f75284ef8f606073e992d2",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "ivary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6081758,
          lng: 46.7430136,
        },
        _id: "64f75284ef8f606073e992d3",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "andrahalana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6171692,
          lng: 46.7720347,
        },
        _id: "64f75284ef8f606073e992d4",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "saririaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6579363,
          lng: 46.7962319,
        },
        _id: "64f75284ef8f606073e992d5",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "ambohimanandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6416658,
          lng: 46.8204406,
        },
        _id: "64f75284ef8f606073e992d6",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "ambohitsaony",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6438829,
          lng: 46.8737409,
        },
        _id: "64f75284ef8f606073e992d7",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.633333,
          lng: 46.933333,
        },
        _id: "64f75284ef8f606073e992d8",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "tolohomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6357377,
          lng: 46.9707947,
        },
        _id: "64f75284ef8f606073e992d9",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatofinandrahana",
        fokontany: "bedihy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.595751,
          lng: 46.63298,
        },
        _id: "64f75284ef8f606073e992da",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "itremo",
        fokontany: "itremo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2758741,
          lng: 46.58851749999999,
        },
        _id: "64f75284ef8f606073e992db",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "itremo",
        fokontany: "ambalahady",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3444809,
          lng: 46.619949,
        },
        _id: "64f75284ef8f606073e992dc",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "itremo",
        fokontany: "andranovory",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5155359,
          lng: 46.612626,
        },
        _id: "64f75284ef8f606073e992dd",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "itremo",
        fokontany: "ifasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5492228,
          lng: 46.6753627,
        },
        _id: "64f75284ef8f606073e992de",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "itremo",
        fokontany: "ambondrona est",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6029268,
          lng: 46.6801919,
        },
        _id: "64f75284ef8f606073e992df",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "itremo",
        fokontany: "ambalamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6793426,
          lng: 46.6512239,
        },
        _id: "64f75284ef8f606073e992e0",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "itremo",
        fokontany: "tsimanariloka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.87072,
          lng: 46.874969,
        },
        _id: "64f75284ef8f606073e992e1",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "fenoarivo",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7140011,
          lng: 46.8543525,
        },
        _id: "64f75284ef8f606073e992e2",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "fenoarivo",
        fokontany: "ambalamahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7794406,
          lng: 46.7285093,
        },
        _id: "64f75284ef8f606073e992e3",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "fenoarivo",
        fokontany: "ianakona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8212294,
          lng: 46.8931368,
        },
        _id: "64f75284ef8f606073e992e4",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "fenoarivo",
        fokontany: "tsinjavina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8675842,
          lng: 46.7671967,
        },
        _id: "64f75284ef8f606073e992e5",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "fenoarivo",
        fokontany: "tsimamaliavona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9257658,
          lng: 46.9076886,
        },
        _id: "64f75284ef8f606073e992e6",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "fenoarivo",
        fokontany: "nierana vohidahy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9303005,
          lng: 46.815598,
        },
        _id: "64f75284ef8f606073e992e7",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "fenoarivo",
        fokontany: "antsahamiady",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9614735,
          lng: 46.8543525,
        },
        _id: "64f75284ef8f606073e992e8",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "fenoarivo",
        fokontany: "ambohimanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.973612,
          lng: 46.8931368,
        },
        _id: "64f75284ef8f606073e992e9",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "fenoarivo",
        fokontany: "ambaro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.387251,
          lng: 46.91552,
        },
        _id: "64f75284ef8f606073e992ea",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "soavina",
        fokontany: "soavina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3238423,
          lng: 47.0096682,
        },
        _id: "64f75284ef8f606073e992eb",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "soavina",
        fokontany: "ilovoka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3409073,
          lng: 46.9562248,
        },
        _id: "64f75284ef8f606073e992ec",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "soavina",
        fokontany: "ikatelo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3540209,
          lng: 46.9368047,
        },
        _id: "64f75284ef8f606073e992ed",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "soavina",
        fokontany: "fitamantsina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3559766,
          lng: 46.9198183,
        },
        _id: "64f75284ef8f606073e992ee",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "soavina",
        fokontany: "ambalakinana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3592945,
          lng: 46.9853688,
        },
        _id: "64f75284ef8f606073e992ef",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "soavina",
        fokontany: "miadakofeno",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3884868,
          lng: 46.9562248,
        },
        _id: "64f75284ef8f606073e992f0",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "soavina",
        fokontany: "ambinda",
        __v: 0,
      },
      {
        coords: {
          lat: -20.390813,
          lng: 46.9950872,
        },
        _id: "64f75284ef8f606073e992f1",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "soavina",
        fokontany: "namoniana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4155991,
          lng: 46.8737409,
        },
        _id: "64f75284ef8f606073e992f2",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "soavina",
        fokontany: "malaindromisotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4045562,
          lng: 46.9465138,
        },
        _id: "64f75284ef8f606073e992f3",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "soavina",
        fokontany: "soanafindra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4334115,
          lng: 46.9319509,
        },
        _id: "64f75284ef8f606073e992f4",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "soavina",
        fokontany: "lovasoa tsihadino",
        __v: 0,
      },
      {
        coords: {
          lat: -20.468799,
          lng: 46.9076886,
        },
        _id: "64f75284ef8f606073e992f5",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "soavina",
        fokontany: "andranomalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4905085,
          lng: 46.9319509,
        },
        _id: "64f75284ef8f606073e992f6",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "soavina",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5285788,
          lng: 46.9319509,
        },
        _id: "64f75284ef8f606073e992f7",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "soavina",
        fokontany: "voenana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.546749,
          lng: 46.9756523,
        },
        _id: "64f75284ef8f606073e992f8",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "soavina",
        fokontany: "tanamahalana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5598314,
          lng: 46.9562248,
        },
        _id: "64f75284ef8f606073e992f9",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "soavina",
        fokontany: "vatotokana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5758905,
          lng: 46.9465138,
        },
        _id: "64f75284ef8f606073e992fa",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "soavina",
        fokontany: "itaty",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3230083,
          lng: 46.905263,
        },
        _id: "64f75284ef8f606073e992fb",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambondromisotra",
        fokontany: "ambondromisotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2896963,
          lng: 46.83497149999999,
        },
        _id: "64f75284ef8f606073e992fc",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambondromisotra",
        fokontany: "mahazina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3591701,
          lng: 46.8446611,
        },
        _id: "64f75284ef8f606073e992fd",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambondromisotra",
        fokontany: "tomboarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3225265,
          lng: 46.9270975,
        },
        _id: "64f75284ef8f606073e992fe",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambondromisotra",
        fokontany: "ambohimandroso I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3123631,
          lng: 46.9562248,
        },
        _id: "64f75284ef8f606073e992ff",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambondromisotra",
        fokontany: "andranovory",
        __v: 0,
      },
      {
        coords: {
          lat: -20.266667,
          lng: 46.9166669,
        },
        _id: "64f75284ef8f606073e99300",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatomifanongoa",
        fokontany: "ambatomifanongoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.183333,
          lng: 46.933333,
        },
        _id: "64f75284ef8f606073e99301",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatomifanongoa",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.231045,
          lng: 46.9076886,
        },
        _id: "64f75284ef8f606073e99302",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatomifanongoa",
        fokontany: "ambohipaly",
        __v: 0,
      },
      {
        coords: {
          lat: -20.235917,
          lng: 46.9707947,
        },
        _id: "64f75284ef8f606073e99303",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatomifanongoa",
        fokontany: "mananjara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2762802,
          lng: 46.8688931,
        },
        _id: "64f75284ef8f606073e99304",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatomifanongoa",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2690734,
          lng: 46.9076886,
        },
        _id: "64f75284ef8f606073e99305",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatomifanongoa",
        fokontany: "iatara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2910414,
          lng: 46.9173921,
        },
        _id: "64f75284ef8f606073e99306",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatomifanongoa",
        fokontany: "antsoamaina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2930032,
          lng: 46.9707947,
        },
        _id: "64f75284ef8f606073e99307",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "ambatomifanongoa",
        fokontany: "ambalamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.616659,
          lng: 46.245419,
        },
        _id: "64f75284ef8f606073e99308",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "amborompotsy",
        fokontany: "amborompotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5600552,
          lng: 46.1804618,
        },
        _id: "64f75284ef8f606073e99309",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "amborompotsy",
        fokontany: "manavotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6578236,
          lng: 46.400872,
        },
        _id: "64f75284ef8f606073e9930a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "amborompotsy",
        fokontany: "belitsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6544394,
          lng: 46.1804618,
        },
        _id: "64f75284ef8f606073e9930b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "amborompotsy",
        fokontany: "ambalavato andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6611962,
          lng: 46.1422309,
        },
        _id: "64f75284ef8f606073e9930c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "amborompotsy",
        fokontany: "ambalamontana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6726089,
          lng: 46.2378645,
        },
        _id: "64f75284ef8f606073e9930d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "amborompotsy",
        fokontany: "soanafindra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6907765,
          lng: 46.2953347,
        },
        _id: "64f75284ef8f606073e9930e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "amborompotsy",
        fokontany: "mahaimandry",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7856584,
          lng: 46.2665911,
        },
        _id: "64f75284ef8f606073e9930f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "amborompotsy",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7494823,
          lng: 46.1231267,
        },
        _id: "64f75284ef8f606073e99310",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "amborompotsy",
        fokontany: "ambalakinana andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9,
          lng: 46.183333,
        },
        _id: "64f75284ef8f606073e99311",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "amborompotsy",
        fokontany: "ambarivondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.59185,
          lng: 45.99957999999999,
        },
        _id: "64f75284ef8f606073e99312",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "mandrosonoro",
        fokontany: "mandrosonoro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4006991,
          lng: 45.8849587,
        },
        _id: "64f75284ef8f606073e99313",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "mandrosonoro",
        fokontany: "janjina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4616733,
          lng: 46.01818799999999,
        },
        _id: "64f75284ef8f606073e99314",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "mandrosonoro",
        fokontany: "ankerambe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6126956,
          lng: 45.98960779999999,
        },
        _id: "64f75284ef8f606073e99315",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "mandrosonoro",
        fokontany: "belavenona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6180946,
          lng: 46.0658592,
        },
        _id: "64f75284ef8f606073e99316",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "mandrosonoro",
        fokontany: "manamy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.687121,
          lng: 46.0753991,
        },
        _id: "64f75284ef8f606073e99317",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "mandrosonoro",
        fokontany: "ambararatasarotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7896151,
          lng: 45.8659561,
        },
        _id: "64f75284ef8f606073e99318",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "mandrosonoro",
        fokontany: "mandabe manatsahala",
        __v: 0,
      },
      {
        coords: {
          lat: -20.26796,
          lng: 46.186741,
        },
        _id: "64f75284ef8f606073e99319",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "mangataboahangy",
        fokontany: "mangataboahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1862108,
          lng: 45.9800848,
        },
        _id: "64f75284ef8f606073e9931a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "mangataboahangy",
        fokontany: "antokolava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1316458,
          lng: 46.2282927,
        },
        _id: "64f75284ef8f606073e9931b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "mangataboahangy",
        fokontany: "vohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2908863,
          lng: 46.10402999999999,
        },
        _id: "64f75284ef8f606073e9931c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "mangataboahangy",
        fokontany: "ambalahady",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3957776,
          lng: 46.2282927,
        },
        _id: "64f75284ef8f606073e9931d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "mangataboahangy",
        fokontany: "modia",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4023,
          lng: 46.400509,
        },
        _id: "64f75284ef8f606073e9931e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambatofinandrahana",
        commune: "mangataboahangy",
        fokontany: "maharivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5346571,
          lng: 47.2465853,
        },
        _id: "64f75284ef8f606073e9931f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "atsinananivinany (est vinany)",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5178174,
          lng: 47.2520797,
        },
        _id: "64f75284ef8f606073e99320",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "vatovory",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5247344,
          lng: 47.2453644,
        },
        _id: "64f75284ef8f606073e99321",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.51897,
          lng: 47.2362087,
        },
        _id: "64f75284ef8f606073e99322",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "ilanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5270403,
          lng: 47.2490272,
        },
        _id: "64f75284ef8f606073e99323",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "iajaky",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5233759,
          lng: 47.2526902,
        },
        _id: "64f75284ef8f606073e99324",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5272052,
          lng: 47.25940629999999,
        },
        _id: "64f75284ef8f606073e99325",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "ampany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5302517,
          lng: 47.24780620000001,
        },
        _id: "64f75284ef8f606073e99326",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "ampivarotanomby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5312407,
          lng: 47.256964,
        },
        _id: "64f75284ef8f606073e99327",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "ampitahana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5400524,
          lng: 47.25452180000001,
        },
        _id: "64f75284ef8f606073e99328",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "tanamahalana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5353986,
          lng: 47.2490272,
        },
        _id: "64f75284ef8f606073e99329",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "manolotrony",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5315685,
          lng: 47.24231229999999,
        },
        _id: "64f75284ef8f606073e9932a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "andrefantsena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5245686,
          lng: 47.2349881,
        },
        _id: "64f75284ef8f606073e9932b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "ampihadiamby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5303298,
          lng: 47.2264444,
        },
        _id: "64f75284ef8f606073e9932c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "antoetra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5320617,
          lng: 47.2380397,
        },
        _id: "64f75284ef8f606073e9932d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "vohidahy tsileondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5386916,
          lng: 47.2441436,
        },
        _id: "64f75284ef8f606073e9932e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "alakamisy ambohimiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5472988,
          lng: 47.2508586,
        },
        _id: "64f75284ef8f606073e9932f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "ankorombe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.538773,
          lng: 47.2404812,
        },
        _id: "64f75284ef8f606073e99330",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "volafotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5427664,
          lng: 47.2398708,
        },
        _id: "64f75284ef8f606073e99331",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "ambohipierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5412827,
          lng: 47.2349881,
        },
        _id: "64f75284ef8f606073e99332",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "ambalamahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5374113,
          lng: 47.2301058,
        },
        _id: "64f75284ef8f606073e99333",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "amongy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5491882,
          lng: 47.2374294,
        },
        _id: "64f75284ef8f606073e99334",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "idiana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5554485,
          lng: 47.24231229999999,
        },
        _id: "64f75284ef8f606073e99335",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra I",
        fokontany: "tanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5209397,
          lng: 47.2899454,
        },
        _id: "64f75284ef8f606073e99336",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "vatambe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4812369,
          lng: 47.285058,
        },
        _id: "64f75284ef8f606073e99337",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "anjoma masapoana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4775957,
          lng: 47.3046106,
        },
        _id: "64f75284ef8f606073e99338",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "vinaninoro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4904521,
          lng: 47.2997218,
        },
        _id: "64f75284ef8f606073e99339",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "manisa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4904855,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e9933a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "vohimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4965752,
          lng: 47.2410916,
        },
        _id: "64f75284ef8f606073e9933b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "ampila",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4977223,
          lng: 47.2606276,
        },
        _id: "64f75284ef8f606073e9933c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "andriambelonomby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5036444,
          lng: 47.280171,
        },
        _id: "64f75284ef8f606073e9933d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "talaky",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5047874,
          lng: 47.2997218,
        },
        _id: "64f75284ef8f606073e9933e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "volazato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5041508,
          lng: 47.2227833,
        },
        _id: "64f75284ef8f606073e9933f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "andranovory miezaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5053024,
          lng: 47.24231229999999,
        },
        _id: "64f75284ef8f606073e99340",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "andraratsilefy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5112305,
          lng: 47.2264444,
        },
        _id: "64f75284ef8f606073e99341",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5091717,
          lng: 47.2471958,
        },
        _id: "64f75284ef8f606073e99342",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "ambohiponana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5087563,
          lng: 47.2655127,
        },
        _id: "64f75284ef8f606073e99343",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "antsahavita",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5197916,
          lng: 47.2703983,
        },
        _id: "64f75284ef8f606073e99344",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "fasimena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5341235,
          lng: 47.2703983,
        },
        _id: "64f75284ef8f606073e99345",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "ambatolahy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5369228,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e99346",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "antanifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5321376,
          lng: 47.216682,
        },
        _id: "64f75284ef8f606073e99347",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "andriamanalina betsileo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5461392,
          lng: 47.2313263,
        },
        _id: "64f75284ef8f606073e99348",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "tsarahasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5484559,
          lng: 47.2703983,
        },
        _id: "64f75284ef8f606073e99349",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "tsimitono",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5639159,
          lng: 47.2191224,
        },
        _id: "64f75284ef8f606073e9934a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5631133,
          lng: 47.2557429,
        },
        _id: "64f75284ef8f606073e9934b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "ambalapary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5582552,
          lng: 47.25940629999999,
        },
        _id: "64f75284ef8f606073e9934c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambositra II",
        fokontany: "anapemby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5715827,
          lng: 47.2325469,
        },
        _id: "64f75284ef8f606073e9934d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ankazoambo",
        fokontany: "ambatomenaloha",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5362627,
          lng: 47.2459749,
        },
        _id: "64f75284ef8f606073e9934e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ankazoambo",
        fokontany: "ankazoambo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6014899,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e9934f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ankazoambo",
        fokontany: "anasana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5721827,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e99350",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ankazoambo",
        fokontany: "ampanazava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6104114,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e99351",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ankazoambo",
        fokontany: "ampanasamaina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5625893,
          lng: 47.2069214,
        },
        _id: "64f75284ef8f606073e99352",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivony miaramiasa",
        fokontany: "firaisantsoa I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5521892,
          lng: 47.1727739,
        },
        _id: "64f75284ef8f606073e99353",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivony miaramiasa",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5500705,
          lng: 47.19716260000001,
        },
        _id: "64f75284ef8f606073e99354",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivony miaramiasa",
        fokontany: "maititondro lovasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5739397,
          lng: 47.19716260000001,
        },
        _id: "64f75284ef8f606073e99355",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivony miaramiasa",
        fokontany: "firaisantsoa II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5681496,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e99356",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivony miaramiasa",
        fokontany: "ambohimahatsiahy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.61305,
          lng: 47.1532712,
        },
        _id: "64f75284ef8f606073e99357",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivony miaramiasa",
        fokontany: "ambivisoratra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5154957,
          lng: 47.1776507,
        },
        _id: "64f75284ef8f606073e99358",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "andina",
        fokontany: "atsimondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4977274,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e99359",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "andina",
        fokontany: "loharano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.502143,
          lng: 47.133776,
        },
        _id: "64f75284ef8f606073e9935a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "andina",
        fokontany: "anjama ivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4930818,
          lng: 47.1118527,
        },
        _id: "64f75284ef8f606073e9935b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "andina",
        fokontany: "talaky",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4983756,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e9935c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "andina",
        fokontany: "antananomby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5051104,
          lng: 47.1435227,
        },
        _id: "64f75284ef8f606073e9935d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "andina",
        fokontany: "ankadilanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5190325,
          lng: 47.08750449999999,
        },
        _id: "64f75284ef8f606073e9935e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "andina",
        fokontany: "ambalamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5246634,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e9935f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "andina",
        fokontany: "ampasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5208264,
          lng: 47.1520526,
        },
        _id: "64f75284ef8f606073e99360",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "andina",
        fokontany: "andina centre",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5304471,
          lng: 47.1483967,
        },
        _id: "64f75284ef8f606073e99361",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "andina",
        fokontany: "ampotsinatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5562542,
          lng: 47.1313396,
        },
        _id: "64f75284ef8f606073e99362",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "andina",
        fokontany: "antanifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5329409,
          lng: 47.1800893,
        },
        _id: "64f75284ef8f606073e99363",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "andina",
        fokontany: "ampiterena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.551017,
          lng: 47.1532712,
        },
        _id: "64f75284ef8f606073e99364",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "andina",
        fokontany: "marinera",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5473316,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e99365",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "andina",
        fokontany: "ambinome",
        __v: 0,
      },
      {
        coords: {
          lat: -20.583333,
          lng: 47.133333,
        },
        _id: "64f75284ef8f606073e99366",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "andina",
        fokontany: "ampamahotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.533966,
          lng: 47.3325069,
        },
        _id: "64f75284ef8f606073e99367",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "imerina imady",
        fokontany: "imerina imady",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5219114,
          lng: 47.3256991,
        },
        _id: "64f75284ef8f606073e99368",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "imerina imady",
        fokontany: "telolahy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5261889,
          lng: 47.3388456,
        },
        _id: "64f75284ef8f606073e99369",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "imerina imady",
        fokontany: "andraranina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5230777,
          lng: 47.30094390000001,
        },
        _id: "64f75284ef8f606073e9936a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "imerina imady",
        fokontany: "ampotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5268645,
          lng: 47.3094999,
        },
        _id: "64f75284ef8f606073e9936b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "imerina imady",
        fokontany: "antanimena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5343543,
          lng: 47.3302847,
        },
        _id: "64f75284ef8f606073e9936c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "imerina imady",
        fokontany: "ambohimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5402114,
          lng: 47.3180574,
        },
        _id: "64f75284ef8f606073e9936d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "imerina imady",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5397209,
          lng: 47.3046106,
        },
        _id: "64f75284ef8f606073e9936e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "imerina imady",
        fokontany: "mandritsara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5415253,
          lng: 47.3302847,
        },
        _id: "64f75284ef8f606073e9936f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "imerina imady",
        fokontany: "ampanarivoana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5504293,
          lng: 47.3241707,
        },
        _id: "64f75284ef8f606073e99370",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "imerina imady",
        fokontany: "ankarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6232493,
          lng: 47.1983824,
        },
        _id: "64f75284ef8f606073e99371",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivato",
        fokontany: "ivato centre",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5925674,
          lng: 47.2191224,
        },
        _id: "64f75284ef8f606073e99372",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivato",
        fokontany: "tsaingy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5900609,
          lng: 47.1874058,
        },
        _id: "64f75284ef8f606073e99373",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivato",
        fokontany: "trangaina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6043824,
          lng: 47.1874058,
        },
        _id: "64f75284ef8f606073e99374",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivato",
        fokontany: "ampanafanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6070497,
          lng: 47.2118015,
        },
        _id: "64f75284ef8f606073e99375",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivato",
        fokontany: "manangary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6124412,
          lng: 47.182528,
        },
        _id: "64f75284ef8f606073e99376",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivato",
        fokontany: "ambanitanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6284047,
          lng: 47.1800893,
        },
        _id: "64f75284ef8f606073e99377",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivato",
        fokontany: "ambohipanalinana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6367307,
          lng: 47.2386501,
        },
        _id: "64f75284ef8f606073e99378",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivato",
        fokontany: "miarina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6480973,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e99379",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivato",
        fokontany: "sahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6541962,
          lng: 47.2410916,
        },
        _id: "64f75284ef8f606073e9937a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivato",
        fokontany: "ambalamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6533147,
          lng: 47.2069214,
        },
        _id: "64f75284ef8f606073e9937b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivato",
        fokontany: "toareny",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6624126,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e9937c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivato",
        fokontany: "soafandry",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6851042,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e9937d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivato",
        fokontany: "amindrazaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6701744,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e9937e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivato",
        fokontany: "ankaramainty",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6680705,
          lng: 47.2630701,
        },
        _id: "64f75284ef8f606073e9937f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivato",
        fokontany: "tangina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6955366,
          lng: 47.1654595,
        },
        _id: "64f75284ef8f606073e99380",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivato",
        fokontany: "ankotsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6964415,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e99381",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivato",
        fokontany: "ampadirana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7161607,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e99382",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ivato",
        fokontany: "ambalalehibe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4276251,
          lng: 47.1947232,
        },
        _id: "64f75284ef8f606073e99383",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "tsarasaotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3986521,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e99384",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4093858,
          lng: 47.1581462,
        },
        _id: "64f75284ef8f606073e99385",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "vinany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4164679,
          lng: 47.1264672,
        },
        _id: "64f75284ef8f606073e99386",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4174542,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e99387",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "ampandrana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4127974,
          lng: 47.216682,
        },
        _id: "64f75284ef8f606073e99388",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "ivoainana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.424331,
          lng: 47.1996022,
        },
        _id: "64f75284ef8f606073e99389",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "atsimotanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4184204,
          lng: 47.1800893,
        },
        _id: "64f75284ef8f606073e9938a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "tsimiariloha",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4251732,
          lng: 47.16302160000001,
        },
        _id: "64f75284ef8f606073e9938b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "antranobongo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4325653,
          lng: 47.1874058,
        },
        _id: "64f75284ef8f606073e9938c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "iarinoro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4348442,
          lng: 47.2264444,
        },
        _id: "64f75284ef8f606073e9938d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "anjamana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4318889,
          lng: 47.216682,
        },
        _id: "64f75284ef8f606073e9938e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "lefaheloka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4631713,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e9938f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "amboniarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4417727,
          lng: 47.2020418,
        },
        _id: "64f75284ef8f606073e99390",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "tranolava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4398149,
          lng: 47.1483967,
        },
        _id: "64f75284ef8f606073e99391",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "ampandrotrarana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4453173,
          lng: 47.1861863,
        },
        _id: "64f75284ef8f606073e99392",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "tanjato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4425735,
          lng: 47.2362087,
        },
        _id: "64f75284ef8f606073e99393",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "tsarazaza",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4524603,
          lng: 47.221563,
        },
        _id: "64f75284ef8f606073e99394",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4391057,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e99395",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "tsinjony",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4625091,
          lng: 47.1996022,
        },
        _id: "64f75284ef8f606073e99396",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "manarinony",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4687252,
          lng: 47.2752844,
        },
        _id: "64f75284ef8f606073e99397",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "ambohipierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4703963,
          lng: 47.2374294,
        },
        _id: "64f75284ef8f606073e99398",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "ambohipia",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4700591,
          lng: 47.2520797,
        },
        _id: "64f75284ef8f606073e99399",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "tanjona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4678404,
          lng: 47.244754,
        },
        _id: "64f75284ef8f606073e9939a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "soatsiahana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4845618,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e9939b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "maneva",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4789544,
          lng: 47.2459749,
        },
        _id: "64f75284ef8f606073e9939c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "andrainarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.473365,
          lng: 47.1410858,
        },
        _id: "64f75284ef8f606073e9939d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "ambolotara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4881698,
          lng: 47.2606276,
        },
        _id: "64f75284ef8f606073e9939e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "tsarasaotra",
        fokontany: "zanaposa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5375156,
          lng: 47.4000357,
        },
        _id: "64f75284ef8f606073e9939f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "marosoa",
        fokontany: "marosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5288084,
          lng: 47.363313,
        },
        _id: "64f75284ef8f606073e993a0",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "marosoa",
        fokontany: "ilampy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5305565,
          lng: 47.4245319,
        },
        _id: "64f75284ef8f606073e993a1",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "marosoa",
        fokontany: "antafotona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5299449,
          lng: 47.3828952,
        },
        _id: "64f75284ef8f606073e993a2",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "marosoa",
        fokontany: "andriamainty",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5371473,
          lng: 47.3474078,
        },
        _id: "64f75284ef8f606073e993a3",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "marosoa",
        fokontany: "mahaimiray anerinerina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5382879,
          lng: 47.3669841,
        },
        _id: "64f75284ef8f606073e993a4",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "marosoa",
        fokontany: "fanapohana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5357256,
          lng: 47.374327,
        },
        _id: "64f75284ef8f606073e993a5",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "marosoa",
        fokontany: "farazato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5412305,
          lng: 47.3431265,
        },
        _id: "64f75284ef8f606073e993a6",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "marosoa",
        fokontany: "manarintsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.536576,
          lng: 47.33762249999999,
        },
        _id: "64f75284ef8f606073e993a7",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "marosoa",
        fokontany: "ambohipo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5494193,
          lng: 47.3682078,
        },
        _id: "64f75284ef8f606073e993a8",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "marosoa",
        fokontany: "ampanenjanana miraihina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5503886,
          lng: 47.3951378,
        },
        _id: "64f75284ef8f606073e993a9",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "marosoa",
        fokontany: "ankazofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5530582,
          lng: 47.3486311,
        },
        _id: "64f75284ef8f606073e993aa",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "marosoa",
        fokontany: "antezanivoara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5548759,
          lng: 47.3388456,
        },
        _id: "64f75284ef8f606073e993ab",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "marosoa",
        fokontany: "fiadanana matsiatra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5606357,
          lng: 47.3657603,
        },
        _id: "64f75284ef8f606073e993ac",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "marosoa",
        fokontany: "mangazo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5738357,
          lng: 47.3461846,
        },
        _id: "64f75284ef8f606073e993ad",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "marosoa",
        fokontany: "ankarefo mahatory",
        __v: 0,
      },
      {
        coords: {
          lat: -20.504107,
          lng: 47.3290618,
        },
        _id: "64f75284ef8f606073e993ae",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "fahizay",
        fokontany: "ambohibary ava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5077475,
          lng: 47.3094999,
        },
        _id: "64f75284ef8f606073e993af",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "fahizay",
        fokontany: "soanierana volazato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5004619,
          lng: 47.3486311,
        },
        _id: "64f75284ef8f606073e993b0",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "fahizay",
        fokontany: "ampiadiana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5011483,
          lng: 47.31928,
        },
        _id: "64f75284ef8f606073e993b1",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "fahizay",
        fokontany: "ampahipeno",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5091426,
          lng: 47.3180574,
        },
        _id: "64f75284ef8f606073e993b2",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "fahizay",
        fokontany: "ankorabe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5115034,
          lng: 47.3535246,
        },
        _id: "64f75284ef8f606073e993b3",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "fahizay",
        fokontany: "anovy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5166272,
          lng: 47.3388456,
        },
        _id: "64f75284ef8f606073e993b4",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "fahizay",
        fokontany: "ambohitsoa tananomby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5695519,
          lng: 47.3241707,
        },
        _id: "64f75284ef8f606073e993b5",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "alakamisy ambohijato",
        fokontany: "ambohijato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5484555,
          lng: 47.3058329,
        },
        _id: "64f75284ef8f606073e993b6",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "alakamisy ambohijato",
        fokontany: "ambohimanandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5585077,
          lng: 47.31928,
        },
        _id: "64f75284ef8f606073e993b7",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "alakamisy ambohijato",
        fokontany: "tsinjony",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5573555,
          lng: 47.2997218,
        },
        _id: "64f75284ef8f606073e993b8",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "alakamisy ambohijato",
        fokontany: "ambatovihina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5669143,
          lng: 47.2997218,
        },
        _id: "64f75284ef8f606073e993b9",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "alakamisy ambohijato",
        fokontany: "ambalavao afovoany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5797682,
          lng: 47.29483339999999,
        },
        _id: "64f75284ef8f606073e993ba",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "alakamisy ambohijato",
        fokontany: "ivolo firaisana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5936198,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e993bb",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "alakamisy ambohijato",
        fokontany: "soarotanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6018576,
          lng: 47.3046106,
        },
        _id: "64f75284ef8f606073e993bc",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "alakamisy ambohijato",
        fokontany: "behena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6259187,
          lng: 47.2972775,
        },
        _id: "64f75284ef8f606073e993bd",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "alakamisy ambohijato",
        fokontany: "fandraofana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6330979,
          lng: 47.3682139,
        },
        _id: "64f75284ef8f606073e993be",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "kianjandrakefina",
        fokontany: "kianjandrakefina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5827337,
          lng: 47.37555099999999,
        },
        _id: "64f75284ef8f606073e993bf",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "kianjandrakefina",
        fokontany: "andranonahantona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5913152,
          lng: 47.3486311,
        },
        _id: "64f75284ef8f606073e993c0",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "kianjandrakefina",
        fokontany: "ankarinjato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5757929,
          lng: 47.4000357,
        },
        _id: "64f75284ef8f606073e993c1",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "kianjandrakefina",
        fokontany: "ambolotara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6048372,
          lng: 47.3853435,
        },
        _id: "64f75284ef8f606073e993c2",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "kianjandrakefina",
        fokontany: "marofototra miray",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5972513,
          lng: 47.3682078,
        },
        _id: "64f75284ef8f606073e993c3",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "kianjandrakefina",
        fokontany: "ambalasoaray",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6195114,
          lng: 47.33517639999999,
        },
        _id: "64f75284ef8f606073e993c4",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "kianjandrakefina",
        fokontany: "namelana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6211697,
          lng: 47.3682078,
        },
        _id: "64f75284ef8f606073e993c5",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "kianjandrakefina",
        fokontany: "soafiherenana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6328729,
          lng: 47.3437381,
        },
        _id: "64f75284ef8f606073e993c6",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "kianjandrakefina",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6252786,
          lng: 47.3266162,
        },
        _id: "64f75284ef8f606073e993c7",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "kianjandrakefina",
        fokontany: "tsimatahodalana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6414304,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e993c8",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "kianjandrakefina",
        fokontany: "anasana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6454128,
          lng: 47.3535246,
        },
        _id: "64f75284ef8f606073e993c9",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "kianjandrakefina",
        fokontany: "anjanina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6330979,
          lng: 47.3682139,
        },
        _id: "64f75284ef8f606073e993ca",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "kianjandrakefina",
        fokontany: "soanirina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6635342,
          lng: 47.3266162,
        },
        _id: "64f75284ef8f606073e993cb",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "kianjandrakefina",
        fokontany: "ambodivolamena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6627392,
          lng: 47.363313,
        },
        _id: "64f75284ef8f606073e993cc",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "kianjandrakefina",
        fokontany: "ankosy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6708192,
          lng: 47.3584185,
        },
        _id: "64f75284ef8f606073e993cd",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "kianjandrakefina",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -20.743219,
          lng: 47.180931,
        },
        _id: "64f75284ef8f606073e993ce",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambalamanakana",
        fokontany: "ambalamanakana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7209033,
          lng: 47.1703357,
        },
        _id: "64f75284ef8f606073e993cf",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambalamanakana",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7325788,
          lng: 47.2240036,
        },
        _id: "64f75284ef8f606073e993d0",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambalamanakana",
        fokontany: "manarinony",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7330407,
          lng: 47.280171,
        },
        _id: "64f75284ef8f606073e993d1",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambalamanakana",
        fokontany: "ambozontany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7508159,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e993d2",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambalamanakana",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7807821,
          lng: 47.2020418,
        },
        _id: "64f75284ef8f606073e993d3",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambalamanakana",
        fokontany: "maninaivo fanantenana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.777078,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e993d4",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambalamanakana",
        fokontany: "sahatsihara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3013541,
          lng: 47.1557087,
        },
        _id: "64f75284ef8f606073e993d5",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ilaka centre",
        fokontany: "ilaka centre",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2639313,
          lng: 47.1264672,
        },
        _id: "64f75284ef8f606073e993d6",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ilaka centre",
        fokontany: "fandrianjato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2928771,
          lng: 47.1118527,
        },
        _id: "64f75284ef8f606073e993d7",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ilaka centre",
        fokontany: "ambalavao voainana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2894075,
          lng: 47.1240312,
        },
        _id: "64f75284ef8f606073e993d8",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ilaka centre",
        fokontany: "mahatsanda",
        __v: 0,
      },
      {
        coords: {
          lat: -20.29476,
          lng: 47.1654595,
        },
        _id: "64f75284ef8f606073e993d9",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ilaka centre",
        fokontany: "ikianja",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2935736,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e993da",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ilaka centre",
        fokontany: "morarano voalohany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3084716,
          lng: 47.1240312,
        },
        _id: "64f75284ef8f606073e993db",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ilaka centre",
        fokontany: "andakana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3114619,
          lng: 47.0984598,
        },
        _id: "64f75284ef8f606073e993dc",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ilaka centre",
        fokontany: "mamovoka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3242436,
          lng: 47.1289033,
        },
        _id: "64f75284ef8f606073e993dd",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ilaka centre",
        fokontany: "ambalatany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3065243,
          lng: 47.2044815,
        },
        _id: "64f75284ef8f606073e993de",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ilaka centre",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3260632,
          lng: 47.1191594,
        },
        _id: "64f75284ef8f606073e993df",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ilaka centre",
        fokontany: "ambatovory",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3472812,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e993e0",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ilaka centre",
        fokontany: "bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.340018,
          lng: 47.133776,
        },
        _id: "64f75284ef8f606073e993e1",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ilaka centre",
        fokontany: "ankeniheny",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3448666,
          lng: 47.19716260000001,
        },
        _id: "64f75284ef8f606073e993e2",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ilaka centre",
        fokontany: "ambohiperivoana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3457406,
          lng: 47.1605839,
        },
        _id: "64f75284ef8f606073e993e3",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ilaka centre",
        fokontany: "soavina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3729688,
          lng: 47.2191224,
        },
        _id: "64f75284ef8f606073e993e4",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ilaka centre",
        fokontany: "antokodambo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3736652,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e993e5",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ilaka centre",
        fokontany: "ankadilanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3714031,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e993e6",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ilaka centre",
        fokontany: "antanimenakely",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4848055,
          lng: 47.0534365,
        },
        _id: "64f75284ef8f606073e993e7",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ihadilanana",
        fokontany: "ambohimanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4056146,
          lng: 47.043707,
        },
        _id: "64f75284ef8f606073e993e8",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ihadilanana",
        fokontany: "ambohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.416667,
          lng: 47,
        },
        _id: "64f75284ef8f606073e993e9",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ihadilanana",
        fokontany: "malakialina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4412307,
          lng: 47.0120988,
        },
        _id: "64f75284ef8f606073e993ea",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ihadilanana",
        fokontany: "ambohitranakova",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4371591,
          lng: 47.0534365,
        },
        _id: "64f75284ef8f606073e993eb",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ihadilanana",
        fokontany: "ambohipiandrianana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4534085,
          lng: 47.0364111,
        },
        _id: "64f75284ef8f606073e993ec",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ihadilanana",
        fokontany: "ambatonandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4702657,
          lng: 46.9902277,
        },
        _id: "64f75284ef8f606073e993ed",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ihadilanana",
        fokontany: "mandrovana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4618296,
          lng: 47.08750449999999,
        },
        _id: "64f75284ef8f606073e993ee",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ihadilanana",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4854245,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e993ef",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ihadilanana",
        fokontany: "ambohimirary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.816667,
          lng: 47.183333,
        },
        _id: "64f75284ef8f606073e993f0",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambatofitorahana",
        fokontany: "ambatofitorahana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.769207,
          lng: 47.1410858,
        },
        _id: "64f75284ef8f606073e993f1",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambatofitorahana",
        fokontany: "nandihizana antety",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7918455,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e993f2",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambatofitorahana",
        fokontany: "nandihizana ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.793753,
          lng: 47.1898448,
        },
        _id: "64f75284ef8f606073e993f3",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambatofitorahana",
        fokontany: "miandritsara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8104016,
          lng: 47.15083389999999,
        },
        _id: "64f75284ef8f606073e993f4",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambatofitorahana",
        fokontany: "farihilalina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8057501,
          lng: 47.22888529999999,
        },
        _id: "64f75284ef8f606073e993f5",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambatofitorahana",
        fokontany: "miandrifaikona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8157239,
          lng: 47.2069214,
        },
        _id: "64f75284ef8f606073e993f6",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambatofitorahana",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7745127,
          lng: 47.3180574,
        },
        _id: "64f75284ef8f606073e993f7",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "antoetra",
        fokontany: "antoetra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6923839,
          lng: 47.31928,
        },
        _id: "64f75284ef8f606073e993f8",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "antoetra",
        fokontany: "soamandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7597877,
          lng: 47.2972775,
        },
        _id: "64f75284ef8f606073e993f9",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "antoetra",
        fokontany: "ankarenana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7979173,
          lng: 47.3828952,
        },
        _id: "64f75284ef8f606073e993fa",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "antoetra",
        fokontany: "faliarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8107824,
          lng: 47.37799889999999,
        },
        _id: "64f75284ef8f606073e993fb",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "antoetra",
        fokontany: "antetezandrotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.816667,
          lng: 47.4,
        },
        _id: "64f75284ef8f606073e993fc",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "antoetra",
        fokontany: "vohitrandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8207254,
          lng: 47.35964209999999,
        },
        _id: "64f75284ef8f606073e993fd",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "antoetra",
        fokontany: "sakaivo avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8378603,
          lng: 47.380447,
        },
        _id: "64f75284ef8f606073e993fe",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "antoetra",
        fokontany: "fempina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8498423,
          lng: 47.4196318,
        },
        _id: "64f75284ef8f606073e993ff",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "antoetra",
        fokontany: "andraitokonana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8690872,
          lng: 47.3339535,
        },
        _id: "64f75284ef8f606073e99400",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "antoetra",
        fokontany: "ambahona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8736447,
          lng: 47.4269822,
        },
        _id: "64f75284ef8f606073e99401",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "antoetra",
        fokontany: "ambalavero",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8824498,
          lng: 47.3853435,
        },
        _id: "64f75284ef8f606073e99402",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "antoetra",
        fokontany: "ampanenjanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8835835,
          lng: 47.3266162,
        },
        _id: "64f75284ef8f606073e99403",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "antoetra",
        fokontany: "ambatomalama",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9144666,
          lng: 47.380447,
        },
        _id: "64f75284ef8f606073e99404",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "antoetra",
        fokontany: "sakaivo atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.92528,
          lng: 47.45167199999999,
        },
        _id: "64f75284ef8f606073e99405",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "antoetra",
        fokontany: "analamanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9417913,
          lng: 47.4539427,
        },
        _id: "64f75284ef8f606073e99406",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "antoetra",
        fokontany: "anosizato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9407378,
          lng: 47.3412918,
        },
        _id: "64f75284ef8f606073e99407",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "antoetra",
        fokontany: "anjorozoro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3530158,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e99408",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "mahazina ambohipierenana",
        fokontany: "mahazina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3165996,
          lng: 47.0485715,
        },
        _id: "64f75284ef8f606073e99409",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "mahazina ambohipierenana",
        fokontany: "andoharano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3216425,
          lng: 47.104547,
        },
        _id: "64f75284ef8f606073e9940a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "mahazina ambohipierenana",
        fokontany: "ambalamahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3615807,
          lng: 47.0948078,
        },
        _id: "64f75284ef8f606073e9940b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "mahazina ambohipierenana",
        fokontany: "ambohipierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3776881,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e9940c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "mahazina ambohipierenana",
        fokontany: "soanafindra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3768719,
          lng: 47.051004,
        },
        _id: "64f75284ef8f606073e9940d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "mahazina ambohipierenana",
        fokontany: "amby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.388862,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e9940e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "mahazina ambohipierenana",
        fokontany: "ankapo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.204809,
          lng: 47.091671,
        },
        _id: "64f75284ef8f606073e9940f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "sahatsiho ambohimanjaka",
        fokontany: "ambohimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1929864,
          lng: 47.0412749,
        },
        _id: "64f75284ef8f606073e99410",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "sahatsiho ambohimanjaka",
        fokontany: "sahamalola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1756026,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e99411",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "sahatsiho ambohimanjaka",
        fokontany: "ambohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2094866,
          lng: 47.0802023,
        },
        _id: "64f75284ef8f606073e99412",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "sahatsiho ambohimanjaka",
        fokontany: "ambohipo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2331206,
          lng: 47.08750449999999,
        },
        _id: "64f75284ef8f606073e99413",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "sahatsiho ambohimanjaka",
        fokontany: "ankeniheny ampiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2345334,
          lng: 47.0291162,
        },
        _id: "64f75284ef8f606073e99414",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "sahatsiho ambohimanjaka",
        fokontany: "fierenantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7182048,
          lng: 47.4290934,
        },
        _id: "64f75284ef8f606073e99415",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo I",
        fokontany: "ambohimitombo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.666667,
          lng: 47.4,
        },
        _id: "64f75284ef8f606073e99416",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo I",
        fokontany: "itandroka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6981907,
          lng: 47.3461846,
        },
        _id: "64f75284ef8f606073e99417",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo I",
        fokontany: "ambohimanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7071328,
          lng: 47.37555099999999,
        },
        _id: "64f75284ef8f606073e99418",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo I",
        fokontany: "antanifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7111279,
          lng: 47.4122824,
        },
        _id: "64f75284ef8f606073e99419",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo I",
        fokontany: "ambohipo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7022353,
          lng: 47.4539427,
        },
        _id: "64f75284ef8f606073e9941a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo I",
        fokontany: "andepontany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.74403,
          lng: 47.45471999999999,
        },
        _id: "64f75284ef8f606073e9941b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo I",
        fokontany: "ambohimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7507649,
          lng: 47.4245319,
        },
        _id: "64f75284ef8f606073e9941c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo I",
        fokontany: "ranomena sud",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7301859,
          lng: 47.3412918,
        },
        _id: "64f75284ef8f606073e9941d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo I",
        fokontany: "ifasina miherintsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7361223,
          lng: 47.5103597,
        },
        _id: "64f75284ef8f606073e9941e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo I",
        fokontany: "mahambo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7763567,
          lng: 47.4220818,
        },
        _id: "64f75284ef8f606073e9941f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo I",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7612698,
          lng: 47.380447,
        },
        _id: "64f75284ef8f606073e99420",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo I",
        fokontany: "kidodo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7884576,
          lng: 47.4539427,
        },
        _id: "64f75284ef8f606073e99421",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo I",
        fokontany: "manindroavia",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8175083,
          lng: 47.4392352,
        },
        _id: "64f75284ef8f606073e99422",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo I",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6200112,
          lng: 47.4196318,
        },
        _id: "64f75284ef8f606073e99423",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo II",
        fokontany: "ampidirana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5455185,
          lng: 47.4662021,
        },
        _id: "64f75284ef8f606073e99424",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo II",
        fokontany: "ranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.558578,
          lng: 47.4539427,
        },
        _id: "64f75284ef8f606073e99425",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo II",
        fokontany: "ambatokapaika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5653657,
          lng: 47.4367844,
        },
        _id: "64f75284ef8f606073e99426",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo II",
        fokontany: "ambalamahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.581379,
          lng: 47.4343337,
        },
        _id: "64f75284ef8f606073e99427",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo II",
        fokontany: "tsaramasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6,
          lng: 47.45,
        },
        _id: "64f75284ef8f606073e99428",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo II",
        fokontany: "ampasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6123869,
          lng: 47.4735591,
        },
        _id: "64f75284ef8f606073e99429",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo II",
        fokontany: "anivoala",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6440925,
          lng: 47.48337,
        },
        _id: "64f75284ef8f606073e9942a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo II",
        fokontany: "behelatra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.666667,
          lng: 47.466667,
        },
        _id: "64f75284ef8f606073e9942b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo II",
        fokontany: "sahanato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6824173,
          lng: 47.48337,
        },
        _id: "64f75284ef8f606073e9942c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambohimitombo II",
        fokontany: "ambohimanendrika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6809214,
          lng: 47.5650139,
        },
        _id: "64f75284ef8f606073e9942d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambinanindrano",
        fokontany: "ambinanindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6054264,
          lng: 47.4980899,
        },
        _id: "64f75284ef8f606073e9942e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambinanindrano",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6366215,
          lng: 47.5299973,
        },
        _id: "64f75284ef8f606073e9942f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambinanindrano",
        fokontany: "vohitraivo I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6294764,
          lng: 47.5619241,
        },
        _id: "64f75284ef8f606073e99430",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambinanindrano",
        fokontany: "ambaladingana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6413259,
          lng: 47.6012453,
        },
        _id: "64f75284ef8f606073e99431",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambinanindrano",
        fokontany: "ambodiaviavy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6657342,
          lng: 47.6504382,
        },
        _id: "64f75284ef8f606073e99432",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambinanindrano",
        fokontany: "ilempona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7,
          lng: 47.633333,
        },
        _id: "64f75284ef8f606073e99433",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambinanindrano",
        fokontany: "ankilahila",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6988898,
          lng: 47.6012453,
        },
        _id: "64f75284ef8f606073e99434",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambinanindrano",
        fokontany: "marofahana I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7032225,
          lng: 47.5520984,
        },
        _id: "64f75284ef8f606073e99435",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambinanindrano",
        fokontany: "vohitrandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6589313,
          lng: 47.5324525,
        },
        _id: "64f75284ef8f606073e99436",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambinanindrano",
        fokontany: "vohibola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.703893,
          lng: 47.5226323,
        },
        _id: "64f75284ef8f606073e99437",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambinanindrano",
        fokontany: "marimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7290173,
          lng: 47.5422745,
        },
        _id: "64f75284ef8f606073e99438",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambinanindrano",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -20.72835,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e99439",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambinanindrano",
        fokontany: "ambodiara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7306475,
          lng: 47.6110802,
        },
        _id: "64f75284ef8f606073e9943a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "ambinanindrano",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5623622,
          lng: 47.5619241,
        },
        _id: "64f75284ef8f606073e9943b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "vohidahy",
        fokontany: "ambodilendemy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5074216,
          lng: 47.58649639999999,
        },
        _id: "64f75284ef8f606073e9943c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "vohidahy",
        fokontany: "mandoakaratana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5346927,
          lng: 47.5177229,
        },
        _id: "64f75284ef8f606073e9943d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "vohidahy",
        fokontany: "kianjanomby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5789359,
          lng: 47.5373633,
        },
        _id: "64f75284ef8f606073e9943e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "vohidahy",
        fokontany: "fenomanta",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5834078,
          lng: 47.6159983,
        },
        _id: "64f75284ef8f606073e9943f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "vohidahy",
        fokontany: "ambodisana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6066298,
          lng: 47.581581,
        },
        _id: "64f75284ef8f606073e99440",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "ambositra",
        commune: "vohidahy",
        fokontany: "ambodirafia",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2398999,
          lng: 47.3822831,
        },
        _id: "64f75284ef8f606073e99441",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "ambohidralamanga",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0785433,
          lng: 47.4000357,
        },
        _id: "64f75284ef8f606073e99442",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "ankazondrano nord",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1030472,
          lng: 47.4343337,
        },
        _id: "64f75284ef8f606073e99443",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "madera firaisana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1358814,
          lng: 47.4563943,
        },
        _id: "64f75284ef8f606073e99444",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "vohitrambo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1412865,
          lng: 47.4343337,
        },
        _id: "64f75284ef8f606073e99445",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "lavahiky",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1545822,
          lng: 47.41473209999999,
        },
        _id: "64f75284ef8f606073e99446",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "anjomakely",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1705818,
          lng: 47.4122824,
        },
        _id: "64f75284ef8f606073e99447",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "ambolotara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.149569,
          lng: 47.3657603,
        },
        _id: "64f75284ef8f606073e99448",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "ambatoasana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1362875,
          lng: 47.3853435,
        },
        _id: "64f75284ef8f606073e99449",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "manjarisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1691037,
          lng: 47.37922289999999,
        },
        _id: "64f75284ef8f606073e9944a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1684753,
          lng: 47.37310309999999,
        },
        _id: "64f75284ef8f606073e9944b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "anivonosy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1774268,
          lng: 47.3951378,
        },
        _id: "64f75284ef8f606073e9944c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "fandanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1764117,
          lng: 47.4318831,
        },
        _id: "64f75284ef8f606073e9944d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "malakialina II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1911591,
          lng: 47.4171819,
        },
        _id: "64f75284ef8f606073e9944e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "malakialina I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2052986,
          lng: 47.4245319,
        },
        _id: "64f75284ef8f606073e9944f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "amboatsaonjo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2121403,
          lng: 47.4073834,
        },
        _id: "64f75284ef8f606073e99450",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "ambohijato ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3297,
          lng: 47.410419,
        },
        _id: "64f75284ef8f606073e99451",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2089867,
          lng: 47.3767749,
        },
        _id: "64f75284ef8f606073e99452",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "malaza",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1908038,
          lng: 47.3718793,
        },
        _id: "64f75284ef8f606073e99453",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "andoharano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1736534,
          lng: 47.3584185,
        },
        _id: "64f75284ef8f606073e99454",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "andohariana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1944191,
          lng: 47.3559715,
        },
        _id: "64f75284ef8f606073e99455",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "soavina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.207092,
          lng: 47.3584185,
        },
        _id: "64f75284ef8f606073e99456",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2194711,
          lng: 47.3718793,
        },
        _id: "64f75284ef8f606073e99457",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "andraimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2293439,
          lng: 47.374939,
        },
        _id: "64f75284ef8f606073e99458",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "fandriana enimpololahy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2239539,
          lng: 47.3828952,
        },
        _id: "64f75284ef8f606073e99459",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "maharivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2360408,
          lng: 47.4073834,
        },
        _id: "64f75284ef8f606073e9945a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "tadio I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2217003,
          lng: 47.4073834,
        },
        _id: "64f75284ef8f606073e9945b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "ambohijato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2501851,
          lng: 47.41473209999999,
        },
        _id: "64f75284ef8f606073e9945c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "tadio II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2614061,
          lng: 47.4122824,
        },
        _id: "64f75284ef8f606073e9945d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "ampatora",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2555541,
          lng: 47.3926891,
        },
        _id: "64f75284ef8f606073e9945e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "ankilahila",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2459946,
          lng: 47.3926891,
        },
        _id: "64f75284ef8f606073e9945f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "andaobato atsimotsena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2418258,
          lng: 47.3847314,
        },
        _id: "64f75284ef8f606073e99460",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "ambatonandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2326823,
          lng: 47.38411929999999,
        },
        _id: "64f75284ef8f606073e99461",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "anteza",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2359991,
          lng: 47.37922289999999,
        },
        _id: "64f75284ef8f606073e99462",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "ialasora",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2354638,
          lng: 47.3694316,
        },
        _id: "64f75284ef8f606073e99463",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "ambohibary I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2247393,
          lng: 47.3535246,
        },
        _id: "64f75284ef8f606073e99464",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2334124,
          lng: 47.3266162,
        },
        _id: "64f75284ef8f606073e99465",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "antanetiatsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2421915,
          lng: 47.3559715,
        },
        _id: "64f75284ef8f606073e99466",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "andranoraikitra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2472148,
          lng: 47.3767749,
        },
        _id: "64f75284ef8f606073e99467",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "tsimialona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2649991,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e99468",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "ambohibary II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2312529,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e99469",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "vohibolo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2549335,
          lng: 47.29483339999999,
        },
        _id: "64f75284ef8f606073e9946a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "andranonahoatra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2686909,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e9946b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fandriana",
        fokontany: "ambohimirary atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2700877,
          lng: 47.3853435,
        },
        _id: "64f75284ef8f606073e9946c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "milamaina",
        fokontany: "milamaina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2640379,
          lng: 47.37310309999999,
        },
        _id: "64f75284ef8f606073e9946d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "milamaina",
        fokontany: "angavo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2632606,
          lng: 47.4024848,
        },
        _id: "64f75284ef8f606073e9946e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "milamaina",
        fokontany: "ambohidahy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2712605,
          lng: 47.4012602,
        },
        _id: "64f75284ef8f606073e9946f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "milamaina",
        fokontany: "ambohitraivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2694524,
          lng: 47.37922289999999,
        },
        _id: "64f75284ef8f606073e99470",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "milamaina",
        fokontany: "vohitrinimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2676009,
          lng: 47.3890161,
        },
        _id: "64f75284ef8f606073e99471",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "milamaina",
        fokontany: "ankerambe nord",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2740385,
          lng: 47.3865677,
        },
        _id: "64f75284ef8f606073e99472",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "milamaina",
        fokontany: "ankerambe atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2753575,
          lng: 47.3969745,
        },
        _id: "64f75284ef8f606073e99473",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "milamaina",
        fokontany: "ambohitrinibe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2778928,
          lng: 47.3914647,
        },
        _id: "64f75284ef8f606073e99474",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "milamaina",
        fokontany: "ambohipo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3006776,
          lng: 47.4037094,
        },
        _id: "64f75284ef8f606073e99475",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "miadanimerina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2833456,
          lng: 47.3657603,
        },
        _id: "64f75284ef8f606073e99476",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "anara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2648062,
          lng: 47.4637499,
        },
        _id: "64f75284ef8f606073e99477",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "ambatomisihotraka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2854775,
          lng: 47.3908526,
        },
        _id: "64f75284ef8f606073e99478",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "antetezantany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2788719,
          lng: 47.41473209999999,
        },
        _id: "64f75284ef8f606073e99479",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2748677,
          lng: 47.4453628,
        },
        _id: "64f75284ef8f606073e9947a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "ankafotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2823829,
          lng: 47.4024848,
        },
        _id: "64f75284ef8f606073e9947b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2655981,
          lng: 47.4343337,
        },
        _id: "64f75284ef8f606073e9947c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "ambodihady",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2852556,
          lng: 47.38411929999999,
        },
        _id: "64f75284ef8f606073e9947d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "ambohimanandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2922334,
          lng: 47.3914647,
        },
        _id: "64f75284ef8f606073e9947e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "voainana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2903348,
          lng: 47.4030971,
        },
        _id: "64f75284ef8f606073e9947f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "ambohidravaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2949711,
          lng: 47.4086081,
        },
        _id: "64f75284ef8f606073e99480",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "avarapasana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2972213,
          lng: 47.4441373,
        },
        _id: "64f75284ef8f606073e99481",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "angavo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2856022,
          lng: 47.461298,
        },
        _id: "64f75284ef8f606073e99482",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3033558,
          lng: 47.3926891,
        },
        _id: "64f75284ef8f606073e99483",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "varevika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2978044,
          lng: 47.4220818,
        },
        _id: "64f75284ef8f606073e99484",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "ampapana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3069819,
          lng: 47.4367844,
        },
        _id: "64f75284ef8f606073e99485",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "ambohipeno",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3198652,
          lng: 47.4318831,
        },
        _id: "64f75284ef8f606073e99486",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "soavina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2936619,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e99487",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "ambatodidy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3158948,
          lng: 47.3388456,
        },
        _id: "64f75284ef8f606073e99488",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "ambohimila",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3090855,
          lng: 47.3559715,
        },
        _id: "64f75284ef8f606073e99489",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "iharana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3022713,
          lng: 47.37310309999999,
        },
        _id: "64f75284ef8f606073e9948a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "mahazina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3034992,
          lng: 47.3871798,
        },
        _id: "64f75284ef8f606073e9948b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "andrainarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3069245,
          lng: 47.4086081,
        },
        _id: "64f75284ef8f606073e9948c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "antanimboanjo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3125348,
          lng: 47.4073834,
        },
        _id: "64f75284ef8f606073e9948d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "vohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.312917,
          lng: 47.3926891,
        },
        _id: "64f75284ef8f606073e9948e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "ankadimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3170593,
          lng: 47.3865677,
        },
        _id: "64f75284ef8f606073e9948f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sahamadio fisakana",
        fokontany: "beakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3422423,
          lng: 47.3988112,
        },
        _id: "64f75284ef8f606073e99490",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "fiadanana ville",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3276373,
          lng: 47.37799889999999,
        },
        _id: "64f75284ef8f606073e99491",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "manarintsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3319457,
          lng: 47.3963622,
        },
        _id: "64f75284ef8f606073e99492",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3254126,
          lng: 47.4024848,
        },
        _id: "64f75284ef8f606073e99493",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "andohasaha",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3220025,
          lng: 47.4110576,
        },
        _id: "64f75284ef8f606073e99494",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "miarina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3297,
          lng: 47.410419,
        },
        _id: "64f75284ef8f606073e99495",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "ambato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.342598,
          lng: 47.4159569,
        },
        _id: "64f75284ef8f606073e99496",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "ambanitanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3364431,
          lng: 47.4073834,
        },
        _id: "64f75284ef8f606073e99497",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "andranovondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3401342,
          lng: 47.3877919,
        },
        _id: "64f75284ef8f606073e99498",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "antanetilehibe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3377587,
          lng: 47.3559715,
        },
        _id: "64f75284ef8f606073e99499",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "ambohipo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3486005,
          lng: 47.3682078,
        },
        _id: "64f75284ef8f606073e9949a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "mahambaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3585303,
          lng: 47.3535246,
        },
        _id: "64f75284ef8f606073e9949b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "andriana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3530079,
          lng: 47.3828952,
        },
        _id: "64f75284ef8f606073e9949c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "ambohimahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3563993,
          lng: 47.4061587,
        },
        _id: "64f75284ef8f606073e9949d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "andranolava II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3569455,
          lng: 47.4159569,
        },
        _id: "64f75284ef8f606073e9949e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "ambohimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3504112,
          lng: 47.4220818,
        },
        _id: "64f75284ef8f606073e9949f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "sahanandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3354893,
          lng: 47.4441373,
        },
        _id: "64f75284ef8f606073e994a0",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "ankadifeno",
        __v: 0,
      },
      {
        coords: {
          lat: -20.359219,
          lng: 47.4514912,
        },
        _id: "64f75284ef8f606073e994a1",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "andranondraikitra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3612566,
          lng: 47.4343337,
        },
        _id: "64f75284ef8f606073e994a2",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "ambohimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3699177,
          lng: 47.4073834,
        },
        _id: "64f75284ef8f606073e994a3",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "ambohiboahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3669785,
          lng: 47.3975867,
        },
        _id: "64f75284ef8f606073e994a4",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "andranolava I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3665228,
          lng: 47.38411929999999,
        },
        _id: "64f75284ef8f606073e994a5",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "antananomby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3723165,
          lng: 47.37555099999999,
        },
        _id: "64f75284ef8f606073e994a6",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "ilaivory",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3883135,
          lng: 47.37310309999999,
        },
        _id: "64f75284ef8f606073e994a7",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "ambatomaroanaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.373003,
          lng: 47.4735591,
        },
        _id: "64f75284ef8f606073e994a8",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "vinanimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3886402,
          lng: 47.485823,
        },
        _id: "64f75284ef8f606073e994a9",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "ambalavao",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3847477,
          lng: 47.512814,
        },
        _id: "64f75284ef8f606073e994aa",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "ankaramainty",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4097773,
          lng: 47.5324525,
        },
        _id: "64f75284ef8f606073e994ab",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "fiadanana",
        fokontany: "ambatonakanga",
        __v: 0,
      },
      {
        coords: {
          lat: -20.16984,
          lng: 47.30534000000001,
        },
        _id: "64f75284ef8f606073e994ac",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "tsarazaza",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0644351,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e994ad",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "sarodroa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0652614,
          lng: 47.3070552,
        },
        _id: "64f75284ef8f606073e994ae",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "ranomainty bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0731521,
          lng: 47.3657603,
        },
        _id: "64f75284ef8f606073e994af",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "aniso",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0822522,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e994b0",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "ranomainty vohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.095988,
          lng: 47.3461846,
        },
        _id: "64f75284ef8f606073e994b1",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "andriakely",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0933868,
          lng: 47.2093614,
        },
        _id: "64f75284ef8f606073e994b2",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "tatabe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1,
          lng: 47.316667,
        },
        _id: "64f75284ef8f606073e994b3",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "ambahimerana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.104715,
          lng: 47.37555099999999,
        },
        _id: "64f75284ef8f606073e994b4",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "antanetilehibe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0929535,
          lng: 47.1959429,
        },
        _id: "64f75284ef8f606073e994b5",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1275505,
          lng: 47.3559715,
        },
        _id: "64f75284ef8f606073e994b6",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "antanety",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1262973,
          lng: 47.3437381,
        },
        _id: "64f75284ef8f606073e994b7",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "ambatovaventy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1083584,
          lng: 47.24353319999999,
        },
        _id: "64f75284ef8f606073e994b8",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "tsarazazakely",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1377083,
          lng: 47.3339535,
        },
        _id: "64f75284ef8f606073e994b9",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "mahazoarivo ampany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1348359,
          lng: 47.2044815,
        },
        _id: "64f75284ef8f606073e994ba",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "manjakandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1328761,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e994bb",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "ambohimirary mahazina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1532906,
          lng: 47.3461846,
        },
        _id: "64f75284ef8f606073e994bc",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "vohidoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1490443,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e994bd",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "ambodifiakarana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1347936,
          lng: 47.3241707,
        },
        _id: "64f75284ef8f606073e994be",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "antanambao firaisana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1476582,
          lng: 47.31928,
        },
        _id: "64f75284ef8f606073e994bf",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "zanakandrianjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1498261,
          lng: 47.2386501,
        },
        _id: "64f75284ef8f606073e994c0",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "amby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1572141,
          lng: 47.1996022,
        },
        _id: "64f75284ef8f606073e994c1",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "ampitamidy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1634402,
          lng: 47.3241707,
        },
        _id: "64f75284ef8f606073e994c2",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "ambohidravaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1773686,
          lng: 47.3388456,
        },
        _id: "64f75284ef8f606073e994c3",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "amboatavo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.16984,
          lng: 47.30534000000001,
        },
        _id: "64f75284ef8f606073e994c4",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "mpanjakarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1979086,
          lng: 47.2240036,
        },
        _id: "64f75284ef8f606073e994c5",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1740159,
          lng: 47.3156122,
        },
        _id: "64f75284ef8f606073e994c6",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "boba",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1825394,
          lng: 47.3241707,
        },
        _id: "64f75284ef8f606073e994c7",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "andafiatsimo fenosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1952076,
          lng: 47.3266162,
        },
        _id: "64f75284ef8f606073e994c8",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "andranonasia",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1999719,
          lng: 47.3174255,
        },
        _id: "64f75284ef8f606073e994c9",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "alakamisy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2155765,
          lng: 47.3388456,
        },
        _id: "64f75284ef8f606073e994ca",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "ambohipinony",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2026116,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e994cb",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "fandriakely",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2195398,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e994cc",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "ambalamilolona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2298337,
          lng: 47.2191224,
        },
        _id: "64f75284ef8f606073e994cd",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "amboaroy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2489146,
          lng: 47.2191224,
        },
        _id: "64f75284ef8f606073e994ce",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tsarazaza",
        fokontany: "ankazondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.186613,
          lng: 47.4662021,
        },
        _id: "64f75284ef8f606073e994cf",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tatamalaza",
        fokontany: "andriapitoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1662335,
          lng: 47.4539427,
        },
        _id: "64f75284ef8f606073e994d0",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tatamalaza",
        fokontany: "bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1884806,
          lng: 47.4563943,
        },
        _id: "64f75284ef8f606073e994d1",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tatamalaza",
        fokontany: "tsimadilo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1878541,
          lng: 47.4502654,
        },
        _id: "64f75284ef8f606073e994d2",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tatamalaza",
        fokontany: "antaponikelipetaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2111357,
          lng: 47.4441373,
        },
        _id: "64f75284ef8f606073e994d3",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "tatamalaza",
        fokontany: "ankafobalo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3540513,
          lng: 47.58649639999999,
        },
        _id: "64f75284ef8f606073e994d4",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "ankarinoro",
        fokontany: "ankarinoro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3118637,
          lng: 47.4931828,
        },
        _id: "64f75284ef8f606073e994d5",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "ankarinoro",
        fokontany: "ampahibe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3298281,
          lng: 47.5373633,
        },
        _id: "64f75284ef8f606073e994d6",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "ankarinoro",
        fokontany: "ampangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3157722,
          lng: 47.4662021,
        },
        _id: "64f75284ef8f606073e994d7",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "ankarinoro",
        fokontany: "befaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3657659,
          lng: 47.625836,
        },
        _id: "64f75284ef8f606073e994d8",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "ankarinoro",
        fokontany: "andohariana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3530871,
          lng: 47.5029975,
        },
        _id: "64f75284ef8f606073e994d9",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "ankarinoro",
        fokontany: "andakana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3555191,
          lng: 47.4711066,
        },
        _id: "64f75284ef8f606073e994da",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "ankarinoro",
        fokontany: "irondroala",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3618846,
          lng: 47.5324525,
        },
        _id: "64f75284ef8f606073e994db",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "ankarinoro",
        fokontany: "ankona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3496126,
          lng: 47.2960554,
        },
        _id: "64f75284ef8f606073e994dc",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "sandrandahy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2579001,
          lng: 47.2410916,
        },
        _id: "64f75284ef8f606073e994dd",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "ambolo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.260647,
          lng: 47.2581852,
        },
        _id: "64f75284ef8f606073e994de",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "tsarafidy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2744069,
          lng: 47.280171,
        },
        _id: "64f75284ef8f606073e994df",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "ambohibeloma",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2892843,
          lng: 47.2581852,
        },
        _id: "64f75284ef8f606073e994e0",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "andongoabe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2788232,
          lng: 47.2313263,
        },
        _id: "64f75284ef8f606073e994e1",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "ambolotarakely",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2817405,
          lng: 47.3046106,
        },
        _id: "64f75284ef8f606073e994e2",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "ikototany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2914783,
          lng: 47.2972775,
        },
        _id: "64f75284ef8f606073e994e3",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "iavomanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3,
          lng: 47.23333299999999,
        },
        _id: "64f75284ef8f606073e994e4",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "ambalafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3041507,
          lng: 47.2997218,
        },
        _id: "64f75284ef8f606073e994e5",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "ambalatany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3164538,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e994e6",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "ambanifieferana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3103931,
          lng: 47.2410916,
        },
        _id: "64f75284ef8f606073e994e7",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "ambohipananana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3208639,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e994e8",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "zafindriamanakana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3199466,
          lng: 47.3046106,
        },
        _id: "64f75284ef8f606073e994e9",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "amoronimania",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3305994,
          lng: 47.3241707,
        },
        _id: "64f75284ef8f606073e994ea",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3341834,
          lng: 47.3082776,
        },
        _id: "64f75284ef8f606073e994eb",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "marovahona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3343668,
          lng: 47.30094390000001,
        },
        _id: "64f75284ef8f606073e994ec",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "ankafotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3313355,
          lng: 47.29483339999999,
        },
        _id: "64f75284ef8f606073e994ed",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "fisakatsiavadika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3236206,
          lng: 47.285058,
        },
        _id: "64f75284ef8f606073e994ee",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "zafindrenikobe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3199382,
          lng: 47.2410916,
        },
        _id: "64f75284ef8f606073e994ef",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "boba",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3390294,
          lng: 47.2410916,
        },
        _id: "64f75284ef8f606073e994f0",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "sahalava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3370188,
          lng: 47.2581852,
        },
        _id: "64f75284ef8f606073e994f1",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "efadray II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3434616,
          lng: 47.31928,
        },
        _id: "64f75284ef8f606073e994f2",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "amboaimena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3486954,
          lng: 47.30094390000001,
        },
        _id: "64f75284ef8f606073e994f3",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3561147,
          lng: 47.2581852,
        },
        _id: "64f75284ef8f606073e994f4",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "efadray I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3574077,
          lng: 47.2703983,
        },
        _id: "64f75284ef8f606073e994f5",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "ambohimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3649473,
          lng: 47.2875016,
        },
        _id: "64f75284ef8f606073e994f6",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "zafindratsinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3548518,
          lng: 47.3094999,
        },
        _id: "64f75284ef8f606073e994f7",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "zafindrazokarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.373779,
          lng: 47.3168348,
        },
        _id: "64f75284ef8f606073e994f8",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "andriamahadimby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3757922,
          lng: 47.2997218,
        },
        _id: "64f75284ef8f606073e994f9",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "zafindriantsambo I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.391361,
          lng: 47.2484167,
        },
        _id: "64f75284ef8f606073e994fa",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "ambalasoaray",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3934258,
          lng: 47.29483339999999,
        },
        _id: "64f75284ef8f606073e994fb",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "zafindriantsambo II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4193114,
          lng: 47.2777276,
        },
        _id: "64f75284ef8f606073e994fc",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "nandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.419747,
          lng: 47.25940629999999,
        },
        _id: "64f75284ef8f606073e994fd",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "zafindriamahatsiahy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4316413,
          lng: 47.29483339999999,
        },
        _id: "64f75284ef8f606073e994fe",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "anjoma",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4354631,
          lng: 47.2679555,
        },
        _id: "64f75284ef8f606073e994ff",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "iary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4411958,
          lng: 47.29483339999999,
        },
        _id: "64f75284ef8f606073e99500",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4477967,
          lng: 47.285058,
        },
        _id: "64f75284ef8f606073e99501",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "sandrandahy",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4019242,
          lng: 47.4024848,
        },
        _id: "64f75284ef8f606073e99502",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "ambohimanaiky",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3809526,
          lng: 47.4122824,
        },
        _id: "64f75284ef8f606073e99503",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "ambohitrikena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3875769,
          lng: 47.4024848,
        },
        _id: "64f75284ef8f606073e99504",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "ankazoambo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3787345,
          lng: 47.4367844,
        },
        _id: "64f75284ef8f606073e99505",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "ankadilanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3887692,
          lng: 47.4184068,
        },
        _id: "64f75284ef8f606073e99506",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3960377,
          lng: 47.3828952,
        },
        _id: "64f75284ef8f606073e99507",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "fakazato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3929,
          lng: 47.4441373,
        },
        _id: "64f75284ef8f606073e99508",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "ankarana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4103823,
          lng: 47.3828952,
        },
        _id: "64f75284ef8f606073e99509",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "andrenilaivelo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4107454,
          lng: 47.3682078,
        },
        _id: "64f75284ef8f606073e9950a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "ampany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4061541,
          lng: 47.4245319,
        },
        _id: "64f75284ef8f606073e9950b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "vohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4772098,
          lng: 47.5177229,
        },
        _id: "64f75284ef8f606073e9950c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "trafonomby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.426562,
          lng: 47.37310309999999,
        },
        _id: "64f75284ef8f606073e9950d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "antsimotanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4149815,
          lng: 47.4539427,
        },
        _id: "64f75284ef8f606073e9950e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "ilempombe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4325574,
          lng: 47.4208568,
        },
        _id: "64f75284ef8f606073e9950f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "lanjana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4193999,
          lng: 47.404934,
        },
        _id: "64f75284ef8f606073e99510",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "vinany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4462724,
          lng: 47.4784643,
        },
        _id: "64f75284ef8f606073e99511",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "beririnina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.44075,
          lng: 47.4441373,
        },
        _id: "64f75284ef8f606073e99512",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "seranana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4445176,
          lng: 47.4208568,
        },
        _id: "64f75284ef8f606073e99513",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "ambohidrakitra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.45,
          lng: 47.433333,
        },
        _id: "64f75284ef8f606073e99514",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "ambohinamboarina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4527021,
          lng: 47.4122824,
        },
        _id: "64f75284ef8f606073e99515",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "ambohitsiedehana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4827039,
          lng: 47.4245319,
        },
        _id: "64f75284ef8f606073e99516",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "mahazoarivo",
        fokontany: "ankarina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1757813,
          lng: 47.5668376,
        },
        _id: "64f75284ef8f606073e99517",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "miarinavaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0635236,
          lng: 47.4784643,
        },
        _id: "64f75284ef8f606073e99518",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1000565,
          lng: 47.5373633,
        },
        _id: "64f75284ef8f606073e99519",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "lakandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0920692,
          lng: 47.5914122,
        },
        _id: "64f75284ef8f606073e9951a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "soavimbahoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0984464,
          lng: 47.48337,
        },
        _id: "64f75284ef8f606073e9951b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "ankazomifandraitanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1233863,
          lng: 47.5029975,
        },
        _id: "64f75284ef8f606073e9951c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "ambohimanatrika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1052029,
          lng: 47.57912349999999,
        },
        _id: "64f75284ef8f606073e9951d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1023151,
          lng: 47.675052,
        },
        _id: "64f75284ef8f606073e9951e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "miandrisoa sarandrana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1245703,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e9951f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "voatavomonta",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1350063,
          lng: 47.5422745,
        },
        _id: "64f75284ef8f606073e99520",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "ampihadiamby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1354625,
          lng: 47.4711066,
        },
        _id: "64f75284ef8f606073e99521",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "ambondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1367062,
          lng: 47.48337,
        },
        _id: "64f75284ef8f606073e99522",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "antanambao lavenona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1587479,
          lng: 47.4931828,
        },
        _id: "64f75284ef8f606073e99523",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "ambohimananarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1501754,
          lng: 47.56929450000001,
        },
        _id: "64f75284ef8f606073e99524",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "ambalavao",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1637232,
          lng: 47.5422745,
        },
        _id: "64f75284ef8f606073e99525",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "vatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1483362,
          lng: 47.5226323,
        },
        _id: "64f75284ef8f606073e99526",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "fadiorana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1710171,
          lng: 47.5103597,
        },
        _id: "64f75284ef8f606073e99527",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "antsahalava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1791146,
          lng: 47.5619241,
        },
        _id: "64f75284ef8f606073e99528",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "ambinanindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1740659,
          lng: 47.625836,
        },
        _id: "64f75284ef8f606073e99529",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "iavomanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1941051,
          lng: 47.48337,
        },
        _id: "64f75284ef8f606073e9952a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "faliandro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.199312,
          lng: 47.52508719999999,
        },
        _id: "64f75284ef8f606073e9952b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "mandefitra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1866193,
          lng: 47.5226323,
        },
        _id: "64f75284ef8f606073e9952c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "tsararay",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2082591,
          lng: 47.5189502,
        },
        _id: "64f75284ef8f606073e9952d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "nosiarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2145046,
          lng: 47.5520984,
        },
        _id: "64f75284ef8f606073e9952e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "sahalava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2307131,
          lng: 47.6553601,
        },
        _id: "64f75284ef8f606073e9952f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "tratrambolo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2182282,
          lng: 47.4760116,
        },
        _id: "64f75284ef8f606073e99530",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "ambohimirary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.217175,
          lng: 47.4563943,
        },
        _id: "64f75284ef8f606073e99531",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "tatabe anteza",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2178203,
          lng: 47.4907295,
        },
        _id: "64f75284ef8f606073e99532",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "anditsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2219906,
          lng: 47.512814,
        },
        _id: "64f75284ef8f606073e99533",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "vohipiraisana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2271426,
          lng: 47.4416862,
        },
        _id: "64f75284ef8f606073e99534",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "ambatomenaloha",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2232431,
          lng: 47.52508719999999,
        },
        _id: "64f75284ef8f606073e99535",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "zanakatovo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2307398,
          lng: 47.5422745,
        },
        _id: "64f75284ef8f606073e99536",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "andranomafana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2440529,
          lng: 47.5226323,
        },
        _id: "64f75284ef8f606073e99537",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2329833,
          lng: 47.461298,
        },
        _id: "64f75284ef8f606073e99538",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "miarintsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2132453,
          lng: 47.59632850000001,
        },
        _id: "64f75284ef8f606073e99539",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "iharamena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2400277,
          lng: 47.4367844,
        },
        _id: "64f75284ef8f606073e9953a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "ambatolava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.244411,
          lng: 47.4514912,
        },
        _id: "64f75284ef8f606073e9953b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "ambohibolafotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2390255,
          lng: 47.4735591,
        },
        _id: "64f75284ef8f606073e9953c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "ambohimanamora",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2490665,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e9953d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "saharoamiolotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2448661,
          lng: 47.4931828,
        },
        _id: "64f75284ef8f606073e9953e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "analakely",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2689923,
          lng: 47.485823,
        },
        _id: "64f75284ef8f606073e9953f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "miarinavaratra",
        fokontany: "sahamisotro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2623854,
          lng: 47.5520984,
        },
        _id: "64f75284ef8f606073e99540",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "betsimisotra",
        fokontany: "sahamananina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2769818,
          lng: 47.6012453,
        },
        _id: "64f75284ef8f606073e99541",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "betsimisotra",
        fokontany: "ambatoharanana ampitambe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3074182,
          lng: 47.6553601,
        },
        _id: "64f75284ef8f606073e99542",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "betsimisotra",
        fokontany: "krisiasy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2861257,
          lng: 47.5594675,
        },
        _id: "64f75284ef8f606073e99543",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "betsimisotra",
        fokontany: "besofina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2692448,
          lng: 47.5349078,
        },
        _id: "64f75284ef8f606073e99544",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "betsimisotra",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2727738,
          lng: 47.5226323,
        },
        _id: "64f75284ef8f606073e99545",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "betsimisotra",
        fokontany: "andohanatavy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2667278,
          lng: 47.5103597,
        },
        _id: "64f75284ef8f606073e99546",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "betsimisotra",
        fokontany: "masindrary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2796251,
          lng: 47.5054514,
        },
        _id: "64f75284ef8f606073e99547",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "betsimisotra",
        fokontany: "antara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2956459,
          lng: 47.5029975,
        },
        _id: "64f75284ef8f606073e99548",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "betsimisotra",
        fokontany: "vohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2977712,
          lng: 47.5422745,
        },
        _id: "64f75284ef8f606073e99549",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "betsimisotra",
        fokontany: "analavory",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3131988,
          lng: 47.5619241,
        },
        _id: "64f75284ef8f606073e9954a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "betsimisotra",
        fokontany: "andranomadio",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4681206,
          lng: 47.3682078,
        },
        _id: "64f75284ef8f606073e9954b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "alakamisy ambohimahazo",
        fokontany: "alakamisy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4460441,
          lng: 47.3584185,
        },
        _id: "64f75284ef8f606073e9954c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "alakamisy ambohimahazo",
        fokontany: "zanabahona I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4471628,
          lng: 47.37799889999999,
        },
        _id: "64f75284ef8f606073e9954d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "alakamisy ambohimahazo",
        fokontany: "zanabahona II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4563699,
          lng: 47.3926891,
        },
        _id: "64f75284ef8f606073e9954e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "alakamisy ambohimahazo",
        fokontany: "fandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4677669,
          lng: 47.3828952,
        },
        _id: "64f75284ef8f606073e9954f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "alakamisy ambohimahazo",
        fokontany: "miaranomby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4716013,
          lng: 47.3559715,
        },
        _id: "64f75284ef8f606073e99550",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "alakamisy ambohimahazo",
        fokontany: "marofototra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4836414,
          lng: 47.3523012,
        },
        _id: "64f75284ef8f606073e99551",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "alakamisy ambohimahazo",
        fokontany: "maharivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4920299,
          lng: 47.3682078,
        },
        _id: "64f75284ef8f606073e99552",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "alakamisy ambohimahazo",
        fokontany: "ambohimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4834149,
          lng: 47.3951378,
        },
        _id: "64f75284ef8f606073e99553",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "alakamisy ambohimahazo",
        fokontany: "imainga",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5030724,
          lng: 47.37310309999999,
        },
        _id: "64f75284ef8f606073e99554",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "alakamisy ambohimahazo",
        fokontany: "ambondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5121162,
          lng: 47.3951378,
        },
        _id: "64f75284ef8f606073e99555",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "alakamisy ambohimahazo",
        fokontany: "lohalo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5005438,
          lng: 47.4122824,
        },
        _id: "64f75284ef8f606073e99556",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "alakamisy ambohimahazo",
        fokontany: "nanandrana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5115908,
          lng: 47.4171819,
        },
        _id: "64f75284ef8f606073e99557",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "alakamisy ambohimahazo",
        fokontany: "vohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5169696,
          lng: 47.4588461,
        },
        _id: "64f75284ef8f606073e99558",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "alakamisy ambohimahazo",
        fokontany: "sainte anne",
        __v: 0,
      },
      {
        coords: {
          lat: -20.42318,
          lng: 47.325649,
        },
        _id: "64f75284ef8f606073e99559",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "imito",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3509956,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e9955a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "ampasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3826098,
          lng: 47.3461846,
        },
        _id: "64f75284ef8f606073e9955b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "iharandraony",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4015467,
          lng: 47.3535246,
        },
        _id: "64f75284ef8f606073e9955c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "mandimbisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3938219,
          lng: 47.3437381,
        },
        _id: "64f75284ef8f606073e9955d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "ankerambe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4010811,
          lng: 47.3400687,
        },
        _id: "64f75284ef8f606073e9955e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4022667,
          lng: 47.3241707,
        },
        _id: "64f75284ef8f606073e9955f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "ampandriambositra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4107048,
          lng: 47.3046106,
        },
        _id: "64f75284ef8f606073e99560",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "ambohimila",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4151278,
          lng: 47.31928,
        },
        _id: "64f75284ef8f606073e99561",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "tsararivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4161559,
          lng: 47.3425149,
        },
        _id: "64f75284ef8f606073e99562",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "vohitraivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4167134,
          lng: 47.3523012,
        },
        _id: "64f75284ef8f606073e99563",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "andriapenomanatotsarazafy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4250374,
          lng: 47.3046106,
        },
        _id: "64f75284ef8f606073e99564",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "riamanombo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4327661,
          lng: 47.3143897,
        },
        _id: "64f75284ef8f606073e99565",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "ampanangananavozo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4287531,
          lng: 47.3486311,
        },
        _id: "64f75284ef8f606073e99566",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "soamahatamana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4341093,
          lng: 47.324782,
        },
        _id: "64f75284ef8f606073e99567",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "antoladrano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4465747,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e99568",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "zanabahona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4453625,
          lng: 47.3205026,
        },
        _id: "64f75284ef8f606073e99569",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "andraizay",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4570054,
          lng: 47.2997218,
        },
        _id: "64f75284ef8f606073e9956a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "andraina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4647383,
          lng: 47.3094999,
        },
        _id: "64f75284ef8f606073e9956b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "ambatomanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4780767,
          lng: 47.3180574,
        },
        _id: "64f75284ef8f606073e9956c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "ambohimananto",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4835089,
          lng: 47.3241707,
        },
        _id: "64f75284ef8f606073e9956d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "vohitrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4848143,
          lng: 47.3363995,
        },
        _id: "64f75284ef8f606073e9956e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "fandriana",
        commune: "imito",
        fokontany: "ambohidravaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7751312,
          lng: 47.0789854,
        },
        _id: "64f75284ef8f606073e9956f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambovombe centre",
        fokontany: "alarobia andalandranobe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7249998,
          lng: 46.9513691,
        },
        _id: "64f75284ef8f606073e99570",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambovombe centre",
        fokontany: "itea",
        __v: 0,
      },
      {
        coords: {
          lat: -20.759387,
          lng: 47.07046769999999,
        },
        _id: "64f75284ef8f606073e99571",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambovombe centre",
        fokontany: "vohimanombo ambodiala",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7572109,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e99572",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambovombe centre",
        fokontany: "somangana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7517508,
          lng: 47.0534365,
        },
        _id: "64f75284ef8f606073e99573",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambovombe centre",
        fokontany: "vohimanombo ambondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7552843,
          lng: 47.0339793,
        },
        _id: "64f75284ef8f606073e99574",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambovombe centre",
        fokontany: "ambohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7558096,
          lng: 47.0048074,
        },
        _id: "64f75284ef8f606073e99575",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambovombe centre",
        fokontany: "mirary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7838917,
          lng: 47.0339793,
        },
        _id: "64f75284ef8f606073e99576",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambovombe centre",
        fokontany: "ambodiamontana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7754632,
          lng: 47.0607349,
        },
        _id: "64f75284ef8f606073e99577",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambovombe centre",
        fokontany: "vohimanombo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7828378,
          lng: 47.0923732,
        },
        _id: "64f75284ef8f606073e99578",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambovombe centre",
        fokontany: "bemanta",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7897718,
          lng: 47.0607349,
        },
        _id: "64f75284ef8f606073e99579",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambovombe centre",
        fokontany: "ambovombe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8049281,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e9957a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambovombe centre",
        fokontany: "ilazao",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8180024,
          lng: 47.0826363,
        },
        _id: "64f75284ef8f606073e9957b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambovombe centre",
        fokontany: "zafindraravola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8136206,
          lng: 47.0607349,
        },
        _id: "64f75284ef8f606073e9957c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambovombe centre",
        fokontany: "sambohery mahasoa II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8029646,
          lng: 47.0339793,
        },
        _id: "64f75284ef8f606073e9957d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambovombe centre",
        fokontany: "ambohipana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8106127,
          lng: 47.051004,
        },
        _id: "64f75284ef8f606073e9957e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambovombe centre",
        fokontany: "fihasinana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8190284,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e9957f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambovombe centre",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.691179,
          lng: 47.071461,
        },
        _id: "64f75284ef8f606073e99580",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimahazo",
        fokontany: "ambohimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6615497,
          lng: 47.074118,
        },
        _id: "64f75284ef8f606073e99581",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimahazo",
        fokontany: "ankonaivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6509699,
          lng: 47.0048074,
        },
        _id: "64f75284ef8f606073e99582",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimahazo",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6684895,
          lng: 47.0850703,
        },
        _id: "64f75284ef8f606073e99583",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimahazo",
        fokontany: "fiakarana avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6750375,
          lng: 47.0753348,
        },
        _id: "64f75284ef8f606073e99584",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimahazo",
        fokontany: "antsahavory nord",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6704808,
          lng: 47.0643845,
        },
        _id: "64f75284ef8f606073e99585",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimahazo",
        fokontany: "soatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.661368,
          lng: 47.0424909,
        },
        _id: "64f75284ef8f606073e99586",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimahazo",
        fokontany: "ambohimalakony",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6723279,
          lng: 47.051004,
        },
        _id: "64f75284ef8f606073e99587",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimahazo",
        fokontany: "masitaho",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6815834,
          lng: 47.06560109999999,
        },
        _id: "64f75284ef8f606073e99588",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimahazo",
        fokontany: "ambalaparaky",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6956099,
          lng: 47.0802023,
        },
        _id: "64f75284ef8f606073e99589",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimahazo",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6904173,
          lng: 47.102112,
        },
        _id: "64f75284ef8f606073e9958a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimahazo",
        fokontany: "antanifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7195956,
          lng: 47.0729012,
        },
        _id: "64f75284ef8f606073e9958b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimahazo",
        fokontany: "antsahavory atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7255876,
          lng: 47.0923732,
        },
        _id: "64f75284ef8f606073e9958c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimahazo",
        fokontany: "antapia",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7026673,
          lng: 47.1264672,
        },
        _id: "64f75284ef8f606073e9958d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimahazo",
        fokontany: "vatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7388092,
          lng: 47.06560109999999,
        },
        _id: "64f75284ef8f606073e9958e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimahazo",
        fokontany: "ampotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.849384,
          lng: 47.1069821,
        },
        _id: "64f75284ef8f606073e9958f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoma nandihizana",
        fokontany: "anjoma nandihizana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8204841,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e99590",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoma nandihizana",
        fokontany: "ianatsonga",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8327008,
          lng: 47.0607349,
        },
        _id: "64f75284ef8f606073e99591",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoma nandihizana",
        fokontany: "kilovia",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8336913,
          lng: 47.0948078,
        },
        _id: "64f75284ef8f606073e99592",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoma nandihizana",
        fokontany: "andohanimanandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8461158,
          lng: 47.1118527,
        },
        _id: "64f75284ef8f606073e99593",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoma nandihizana",
        fokontany: "antetezana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8515291,
          lng: 47.0753348,
        },
        _id: "64f75284ef8f606073e99594",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoma nandihizana",
        fokontany: "boro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8532451,
          lng: 47.1581462,
        },
        _id: "64f75284ef8f606073e99595",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoma nandihizana",
        fokontany: "andohaony",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8646928,
          lng: 47.1410858,
        },
        _id: "64f75284ef8f606073e99596",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoma nandihizana",
        fokontany: "ambohimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8692324,
          lng: 47.0631679,
        },
        _id: "64f75284ef8f606073e99597",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoma nandihizana",
        fokontany: "antaniditra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.86873,
          lng: 47.0923732,
        },
        _id: "64f75284ef8f606073e99598",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoma nandihizana",
        fokontany: "soafandry",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6985097,
          lng: 47.0178742,
        },
        _id: "64f75284ef8f606073e99599",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimilanja",
        fokontany: "ambohimilanja",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6770345,
          lng: 47.0333714,
        },
        _id: "64f75284ef8f606073e9959a",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimilanja",
        fokontany: "bemongo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6765649,
          lng: 46.9950872,
        },
        _id: "64f75284ef8f606073e9959b",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimilanja",
        fokontany: "ambohitsavirano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6845932,
          lng: 47.03276349999999,
        },
        _id: "64f75284ef8f606073e9959c",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimilanja",
        fokontany: "fandanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6828198,
          lng: 47.0424909,
        },
        _id: "64f75284ef8f606073e9959d",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimilanja",
        fokontany: "miandrinirariny",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6985097,
          lng: 47.0178742,
        },
        _id: "64f75284ef8f606073e9959e",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimilanja",
        fokontany: "sahafosa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7044777,
          lng: 47.0315477,
        },
        _id: "64f75284ef8f606073e9959f",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimilanja",
        fokontany: "soatanana fiakarana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7050191,
          lng: 47.00237720000001,
        },
        _id: "64f75284ef8f606073e995a0",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimilanja",
        fokontany: "ampasina fiakarana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7272138,
          lng: 47.0048074,
        },
        _id: "64f75284ef8f606073e995a1",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimilanja",
        fokontany: "fiakarana atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7071998,
          lng: 47.0558692,
        },
        _id: "64f75284ef8f606073e995a2",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimilanja",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7296779,
          lng: 47.043707,
        },
        _id: "64f75284ef8f606073e995a3",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohimilanja",
        fokontany: "ampataka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.85,
          lng: 47.016667,
        },
        _id: "64f75284ef8f606073e995a4",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohipo",
        fokontany: "ambohipo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7908055,
          lng: 47.00237720000001,
        },
        _id: "64f75284ef8f606073e995a5",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohipo",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8702152,
          lng: 47.0048074,
        },
        _id: "64f75284ef8f606073e995a6",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohipo",
        fokontany: "ambohipeno",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8697275,
          lng: 47.0339793,
        },
        _id: "64f75284ef8f606073e995a7",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohipo",
        fokontany: "manandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8953247,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e995a8",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambohipo",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6203584,
          lng: 47.1069821,
        },
        _id: "64f75284ef8f606073e995a9",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoman'ankona",
        fokontany: "anjoman'ankona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.582503,
          lng: 47.0923732,
        },
        _id: "64f75284ef8f606073e995aa",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoman'ankona",
        fokontany: "andranomokoko",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5818992,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e995ab",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoman'ankona",
        fokontany: "ambero",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6143926,
          lng: 47.08750449999999,
        },
        _id: "64f75284ef8f606073e995ac",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoman'ankona",
        fokontany: "amparihilavan'antsaharoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6282591,
          lng: 47.1094174,
        },
        _id: "64f75284ef8f606073e995ad",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoman'ankona",
        fokontany: "vatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6446438,
          lng: 47.0850703,
        },
        _id: "64f75284ef8f606073e995ae",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoman'ankona",
        fokontany: "antamboholehibe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6472442,
          lng: 47.074118,
        },
        _id: "64f75284ef8f606073e995af",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoman'ankona",
        fokontany: "ambalatoavola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.642129,
          lng: 47.1313396,
        },
        _id: "64f75284ef8f606073e995b0",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoman'ankona",
        fokontany: "ambatomikatsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6677711,
          lng: 47.12159519999999,
        },
        _id: "64f75284ef8f606073e995b1",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoman'ankona",
        fokontany: "faliarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6667091,
          lng: 47.0948078,
        },
        _id: "64f75284ef8f606073e995b2",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoman'ankona",
        fokontany: "ambalamahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6579886,
          lng: 47.0935905,
        },
        _id: "64f75284ef8f606073e995b3",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "anjoman'ankona",
        fokontany: "fizinana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6466406,
          lng: 47.0643845,
        },
        _id: "64f75284ef8f606073e995b4",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "vinany andakatanikely",
        fokontany: "ambahombe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5942715,
          lng: 47.0607349,
        },
        _id: "64f75284ef8f606073e995b5",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "vinany andakatanikely",
        fokontany: "ankadilalana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6117022,
          lng: 47.0631679,
        },
        _id: "64f75284ef8f606073e995b6",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "vinany andakatanikely",
        fokontany: "kianjavola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6307721,
          lng: 47.0631679,
        },
        _id: "64f75284ef8f606073e995b7",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "vinany andakatanikely",
        fokontany: "soalazaina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.643724,
          lng: 47.051004,
        },
        _id: "64f75284ef8f606073e995b8",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "vinany andakatanikely",
        fokontany: "vinany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6625811,
          lng: 47.0619514,
        },
        _id: "64f75284ef8f606073e995b9",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "vinany andakatanikely",
        fokontany: "tsiandrarafana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.85,
          lng: 46.95,
        },
        _id: "64f75284ef8f606073e995ba",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "talata vohimena",
        fokontany: "talata vohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7510764,
          lng: 46.91254010000001,
        },
        _id: "64f75284ef8f606073e995bb",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "talata vohimena",
        fokontany: "tsararano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7691182,
          lng: 46.9707947,
        },
        _id: "64f75284ef8f606073e995bc",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "talata vohimena",
        fokontany: "namirana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8202695,
          lng: 46.9513691,
        },
        _id: "64f75284ef8f606073e995bd",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "talata vohimena",
        fokontany: "matoka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8260507,
          lng: 46.9853688,
        },
        _id: "64f75284ef8f606073e995be",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "talata vohimena",
        fokontany: "ranomafana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8523479,
          lng: 46.9319509,
        },
        _id: "64f75284ef8f606073e995bf",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "talata vohimena",
        fokontany: "vohimena andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8965032,
          lng: 46.9513691,
        },
        _id: "64f75284ef8f606073e995c0",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "talata vohimena",
        fokontany: "sahafatra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9402238,
          lng: 46.9999471,
        },
        _id: "64f75284ef8f606073e995c1",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "talata vohimena",
        fokontany: "sahamandresy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9666889,
          lng: 46.9319509,
        },
        _id: "64f75284ef8f606073e995c2",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "talata vohimena",
        fokontany: "ambalamanandray",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5681965,
          lng: 47.01452949999999,
        },
        _id: "64f75284ef8f606073e995c3",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambatomarina",
        fokontany: "ambohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5259012,
          lng: 47.0631679,
        },
        _id: "64f75284ef8f606073e995c4",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambatomarina",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5425905,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e995c5",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambatomarina",
        fokontany: "ambohibolamena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5486946,
          lng: 47.0364111,
        },
        _id: "64f75284ef8f606073e995c6",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambatomarina",
        fokontany: "ambohiboahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5531607,
          lng: 47.051004,
        },
        _id: "64f75284ef8f606073e995c7",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambatomarina",
        fokontany: "anananotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5527089,
          lng: 46.9950872,
        },
        _id: "64f75284ef8f606073e995c8",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambatomarina",
        fokontany: "tsiakato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5512208,
          lng: 47.0680343,
        },
        _id: "64f75284ef8f606073e995c9",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambatomarina",
        fokontany: "vohidrafito",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5771385,
          lng: 47.043707,
        },
        _id: "64f75284ef8f606073e995ca",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambatomarina",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.593798,
          lng: 47.0048074,
        },
        _id: "64f75284ef8f606073e995cb",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambatomarina",
        fokontany: "mandazaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5945648,
          lng: 47.0461392,
        },
        _id: "64f75284ef8f606073e995cc",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambatomarina",
        fokontany: "zingizilo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.590091,
          lng: 47.0315477,
        },
        _id: "64f75284ef8f606073e995cd",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambatomarina",
        fokontany: "vodihena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5996217,
          lng: 47.0315477,
        },
        _id: "64f75284ef8f606073e995ce",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambatomarina",
        fokontany: "tanifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6068808,
          lng: 46.9853688,
        },
        _id: "64f75284ef8f606073e995cf",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambatomarina",
        fokontany: "maromanisoka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.637889,
          lng: 47.0242535,
        },
        _id: "64f75284ef8f606073e995d0",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambatomarina",
        fokontany: "ilanjana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6218135,
          lng: 47.0339793,
        },
        _id: "64f75284ef8f606073e995d1",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "ambatomarina",
        fokontany: "marinony anosy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5108809,
          lng: 47.0218223,
        },
        _id: "64f75284ef8f606073e995d2",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "andakatany",
        fokontany: "ambatofangehana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.49556,
          lng: 46.9950872,
        },
        _id: "64f75284ef8f606073e995d3",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "andakatany",
        fokontany: "ambohimirary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5241332,
          lng: 46.9950872,
        },
        _id: "64f75284ef8f606073e995d4",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "andakatany",
        fokontany: "analavory",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5233831,
          lng: 47.0315477,
        },
        _id: "64f75284ef8f606073e995d5",
        province: "fianarantsoa",
        region: "amoron'i mania",
        district: "manandriana",
        commune: "andakatany",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3032134,
          lng: 47.63813570000001,
        },
        _id: "64f75284ef8f606073e995d6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ifanadiana",
        fokontany: "ifanadiana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2618571,
          lng: 47.62091700000001,
        },
        _id: "64f75284ef8f606073e995d7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ifanadiana",
        fokontany: "maromena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2858596,
          lng: 47.7144578,
        },
        _id: "64f75284ef8f606073e995d8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ifanadiana",
        fokontany: "sahafitoroka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2977543,
          lng: 47.581581,
        },
        _id: "64f75284ef8f606073e995d9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ifanadiana",
        fokontany: "andranofolahina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3455546,
          lng: 47.59632850000001,
        },
        _id: "64f75284ef8f606073e995da",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ifanadiana",
        fokontany: "amboraka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3286434,
          lng: 47.6504382,
        },
        _id: "64f75284ef8f606073e995db",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ifanadiana",
        fokontany: "antafotenina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3375113,
          lng: 47.6947512,
        },
        _id: "64f75284ef8f606073e995dc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ifanadiana",
        fokontany: "ambodirafia II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3769597,
          lng: 47.6356755,
        },
        _id: "64f75284ef8f606073e995dd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ifanadiana",
        fokontany: "ambodiara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3839945,
          lng: 47.59632850000001,
        },
        _id: "64f75284ef8f606073e995de",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ifanadiana",
        fokontany: "ambiabe I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4003916,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e995df",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ifanadiana",
        fokontany: "ankaboka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.408618,
          lng: 47.6602824,
        },
        _id: "64f75284ef8f606073e995e0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ifanadiana",
        fokontany: "fenoarivo I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4294373,
          lng: 47.557011,
        },
        _id: "64f75284ef8f606073e995e1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ifanadiana",
        fokontany: "ambinanindranotelo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4130055,
          lng: 47.7834888,
        },
        _id: "64f75284ef8f606073e995e2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "antaretra",
        fokontany: "antaretra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2490323,
          lng: 47.7982928,
        },
        _id: "64f75284ef8f606073e995e3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "antaretra",
        fokontany: "ambinanivatomena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.265812,
          lng: 47.7588245,
        },
        _id: "64f75284ef8f606073e995e4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "antaretra",
        fokontany: "ambodiara I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2897414,
          lng: 47.7662226,
        },
        _id: "64f75284ef8f606073e995e5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "antaretra",
        fokontany: "betampona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3012824,
          lng: 47.7489621,
        },
        _id: "64f75284ef8f606073e995e6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "antaretra",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3364884,
          lng: 47.7538931,
        },
        _id: "64f75284ef8f606073e995e7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "antaretra",
        fokontany: "andranomaitso",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3749944,
          lng: 47.7538931,
        },
        _id: "64f75284ef8f606073e995e8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "antaretra",
        fokontany: "ambodirotra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.45,
          lng: 47.783333,
        },
        _id: "64f75284ef8f606073e995e9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "antaretra",
        fokontany: "andonabe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4195922,
          lng: 47.7736217,
        },
        _id: "64f75284ef8f606073e995ea",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "antaretra",
        fokontany: "amboatrotroka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4512845,
          lng: 47.7982928,
        },
        _id: "64f75284ef8f606073e995eb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "antaretra",
        fokontany: "ampasimbe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1794123,
          lng: 47.6379209,
        },
        _id: "64f75284ef8f606073e995ec",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9985526,
          lng: 47.6553601,
        },
        _id: "64f75284ef8f606073e995ed",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "sandrasaty",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0633825,
          lng: 47.6159983,
        },
        _id: "64f75284ef8f606073e995ee",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "ambohimita",
        __v: 0,
      },
      {
        coords: {
          lat: -21.033333,
          lng: 47.633333,
        },
        _id: "64f75284ef8f606073e995ef",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "analamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.05,
          lng: 47.7,
        },
        _id: "64f75284ef8f606073e995f0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "marofototra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0571131,
          lng: 47.7686889,
        },
        _id: "64f75284ef8f606073e995f1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "ambalafatakana est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1175565,
          lng: 47.7933577,
        },
        _id: "64f75284ef8f606073e995f2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "ambalafary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1055215,
          lng: 47.7538931,
        },
        _id: "64f75284ef8f606073e995f3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "ambohimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1379803,
          lng: 47.7341718,
        },
        _id: "64f75284ef8f606073e995f4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "ambodirafia est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1388395,
          lng: 47.6898257,
        },
        _id: "64f75284ef8f606073e995f5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "ambohinihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1267745,
          lng: 47.6504382,
        },
        _id: "64f75284ef8f606073e995f6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "sandrakely",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1435268,
          lng: 47.6110802,
        },
        _id: "64f75284ef8f606073e995f7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "ambodimanga ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1597181,
          lng: 47.6012453,
        },
        _id: "64f75284ef8f606073e995f8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "vohitrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1682108,
          lng: 47.5742088,
        },
        _id: "64f75284ef8f606073e995f9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "ambohipo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.182485,
          lng: 47.581581,
        },
        _id: "64f75284ef8f606073e995fa",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "antaralava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1915633,
          lng: 47.6110802,
        },
        _id: "64f75284ef8f606073e995fb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "ambodigoavy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1968067,
          lng: 47.675052,
        },
        _id: "64f75284ef8f606073e995fc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "ambohitsara I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1704243,
          lng: 47.7144578,
        },
        _id: "64f75284ef8f606073e995fd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1560603,
          lng: 47.7933577,
        },
        _id: "64f75284ef8f606073e995fe",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "befary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.207783,
          lng: 47.7736217,
        },
        _id: "64f75284ef8f606073e995ff",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "ambatoharananakely",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2413229,
          lng: 47.6947512,
        },
        _id: "64f75284ef8f606073e99600",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "ambodimanga est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.223155,
          lng: 47.6356755,
        },
        _id: "64f75284ef8f606073e99601",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "sahofika",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1986659,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e99602",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "tsaratanana",
        fokontany: "androrangavola vatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2607539,
          lng: 47.4518318,
        },
        _id: "64f75284ef8f606073e99603",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ranomafana",
        fokontany: "ranomafana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2266512,
          lng: 47.4294326,
        },
        _id: "64f75284ef8f606073e99604",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ranomafana",
        fokontany: "ambatolahy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2251983,
          lng: 47.5177229,
        },
        _id: "64f75284ef8f606073e99605",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ranomafana",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2672332,
          lng: 47.4907295,
        },
        _id: "64f75284ef8f606073e99606",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ranomafana",
        fokontany: "ambodiaviavy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2899363,
          lng: 47.4711066,
        },
        _id: "64f75284ef8f606073e99607",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ranomafana",
        fokontany: "menarano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2598663,
          lng: 47.4514912,
        },
        _id: "64f75284ef8f606073e99608",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ranomafana",
        fokontany: "ampasimpotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3665938,
          lng: 47.4784643,
        },
        _id: "64f75284ef8f606073e99609",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ranomafana",
        fokontany: "vohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4017144,
          lng: 47.48337,
        },
        _id: "64f75284ef8f606073e9960a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ranomafana",
        fokontany: "sahandrazana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2949569,
          lng: 47.557011,
        },
        _id: "64f75284ef8f606073e9960b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "kelilalina",
        fokontany: "kelilalina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1928648,
          lng: 47.5373633,
        },
        _id: "64f75284ef8f606073e9960c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "kelilalina",
        fokontany: "ambodivoangy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2302543,
          lng: 47.59632850000001,
        },
        _id: "64f75284ef8f606073e9960d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "kelilalina",
        fokontany: "ambohinihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2370829,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e9960e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "kelilalina",
        fokontany: "kianjanomby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2532572,
          lng: 47.5619241,
        },
        _id: "64f75284ef8f606073e9960f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "kelilalina",
        fokontany: "mandrivany",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2577477,
          lng: 47.5803522,
        },
        _id: "64f75284ef8f606073e99610",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "kelilalina",
        fokontany: "ampitambe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2834669,
          lng: 47.5742088,
        },
        _id: "64f75284ef8f606073e99611",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "kelilalina",
        fokontany: "ambodipaiso nord",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3074219,
          lng: 47.5778948,
        },
        _id: "64f75284ef8f606073e99612",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "kelilalina",
        fokontany: "ambodipaiso sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3235332,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e99613",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "kelilalina",
        fokontany: "andranomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3337266,
          lng: 47.5349078,
        },
        _id: "64f75284ef8f606073e99614",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "kelilalina",
        fokontany: "anosy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3525866,
          lng: 47.557011,
        },
        _id: "64f75284ef8f606073e99615",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "kelilalina",
        fokontany: "fotsialanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3816306,
          lng: 47.5422745,
        },
        _id: "64f75284ef8f606073e99616",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "kelilalina",
        fokontany: "ambaladingana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4073906,
          lng: 47.5324525,
        },
        _id: "64f75284ef8f606073e99617",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "kelilalina",
        fokontany: "sahavatana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.506781,
          lng: 47.719952,
        },
        _id: "64f75284ef8f606073e99618",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "androrangavola",
        fokontany: "androrangavola",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4013243,
          lng: 47.7144578,
        },
        _id: "64f75284ef8f606073e99619",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "androrangavola",
        fokontany: "ambodifontsina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4459175,
          lng: 47.7341718,
        },
        _id: "64f75284ef8f606073e9961a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "androrangavola",
        fokontany: "ambodiara nord",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4536545,
          lng: 47.6504382,
        },
        _id: "64f75284ef8f606073e9961b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "androrangavola",
        fokontany: "ambalatenina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4529636,
          lng: 47.6947512,
        },
        _id: "64f75284ef8f606073e9961c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "androrangavola",
        fokontany: "ampasimpotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4773748,
          lng: 47.7736217,
        },
        _id: "64f75284ef8f606073e9961d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "androrangavola",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4829496,
          lng: 47.62091700000001,
        },
        _id: "64f75284ef8f606073e9961e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "androrangavola",
        fokontany: "ambalafasina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4855653,
          lng: 47.6602824,
        },
        _id: "64f75284ef8f606073e9961f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "androrangavola",
        fokontany: "nosibe sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5001046,
          lng: 47.6528991,
        },
        _id: "64f75284ef8f606073e99620",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "androrangavola",
        fokontany: "mandriapotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5214108,
          lng: 47.62091700000001,
        },
        _id: "64f75284ef8f606073e99621",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "androrangavola",
        fokontany: "ambohimahavelona betoho",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5177029,
          lng: 47.6553601,
        },
        _id: "64f75284ef8f606073e99622",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "androrangavola",
        fokontany: "ambalavaona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5301667,
          lng: 47.6799761,
        },
        _id: "64f75284ef8f606073e99623",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "androrangavola",
        fokontany: "ambodiroranga",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5620985,
          lng: 47.6898257,
        },
        _id: "64f75284ef8f606073e99624",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "androrangavola",
        fokontany: "ambohimisafy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5227053,
          lng: 47.7489621,
        },
        _id: "64f75284ef8f606073e99625",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "androrangavola",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5424116,
          lng: 47.7193856,
        },
        _id: "64f75284ef8f606073e99626",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "androrangavola",
        fokontany: "ambalatabaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5655906,
          lng: 47.67012829999999,
        },
        _id: "64f75284ef8f606073e99627",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "androrangavola",
        fokontany: "lavakianja sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.593397,
          lng: 47.7440315,
        },
        _id: "64f75284ef8f606073e99628",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "marotoko",
        fokontany: "marotoko",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5318764,
          lng: 47.778555,
        },
        _id: "64f75284ef8f606073e99629",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "marotoko",
        fokontany: "ambodiara sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5533253,
          lng: 47.6307555,
        },
        _id: "64f75284ef8f606073e9962a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "marotoko",
        fokontany: "ambalavia",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5607749,
          lng: 47.778555,
        },
        _id: "64f75284ef8f606073e9962b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "marotoko",
        fokontany: "manampandrana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.567791,
          lng: 47.7391014,
        },
        _id: "64f75284ef8f606073e9962c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "marotoko",
        fokontany: "mandiafasina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.580099,
          lng: 47.649361,
        },
        _id: "64f75284ef8f606073e9962d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "marotoko",
        fokontany: "ambarijatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.619219,
          lng: 47.7341718,
        },
        _id: "64f75284ef8f606073e9962e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "marotoko",
        fokontany: "kianjanomby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.598342,
          lng: 47.62091700000001,
        },
        _id: "64f75284ef8f606073e9962f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "marotoko",
        fokontany: "ambatomitratraka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.607558,
          lng: 47.6504382,
        },
        _id: "64f75284ef8f606073e99630",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "marotoko",
        fokontany: "ambohimahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6071492,
          lng: 47.6799761,
        },
        _id: "64f75284ef8f606073e99631",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "marotoko",
        fokontany: "amboatrotroka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.065109,
          lng: 47.50901,
        },
        _id: "64f75284ef8f606073e99632",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "ambohimiera",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8806931,
          lng: 47.4735591,
        },
        _id: "64f75284ef8f606073e99633",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "maromanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9028637,
          lng: 47.48337,
        },
        _id: "64f75284ef8f606073e99634",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "ambolo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9250398,
          lng: 47.4931828,
        },
        _id: "64f75284ef8f606073e99635",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "ambinanitomarosana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9370412,
          lng: 47.5324525,
        },
        _id: "64f75284ef8f606073e99636",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "ambohimahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9553398,
          lng: 47.5766661,
        },
        _id: "64f75284ef8f606073e99637",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "fiadanana nord",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9676405,
          lng: 47.6012453,
        },
        _id: "64f75284ef8f606073e99638",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9696957,
          lng: 47.4980899,
        },
        _id: "64f75284ef8f606073e99639",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "ambohimahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9839761,
          lng: 47.4196318,
        },
        _id: "64f75284ef8f606073e9963a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "ambohidaza",
        __v: 0,
      },
      {
        coords: {
          lat: -20.981717,
          lng: 47.5373633,
        },
        _id: "64f75284ef8f606073e9963b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "antarambakana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9970336,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e9963c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "faliarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21,
          lng: 47.48333299999999,
        },
        _id: "64f75284ef8f606073e9963d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "marofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.022842,
          lng: 47.3902404,
        },
        _id: "64f75284ef8f606073e9963e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "ampantsona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0222471,
          lng: 47.5914122,
        },
        _id: "64f75284ef8f606073e9963f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "sahalalona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0261183,
          lng: 47.557011,
        },
        _id: "64f75284ef8f606073e99640",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "andemaka nord",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0480432,
          lng: 47.581581,
        },
        _id: "64f75284ef8f606073e99641",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "androrangavola",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0682203,
          lng: 47.5299973,
        },
        _id: "64f75284ef8f606073e99642",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "tsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1160714,
          lng: 47.5373633,
        },
        _id: "64f75284ef8f606073e99643",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "sahasomangana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1281533,
          lng: 47.5766661,
        },
        _id: "64f75284ef8f606073e99644",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "manarinony",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1,
          lng: 47.59999999999999,
        },
        _id: "64f75284ef8f606073e99645",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "vohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0919017,
          lng: 47.4588461,
        },
        _id: "64f75284ef8f606073e99646",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimiera",
        fokontany: "ambohimilanja",
        __v: 0,
      },
      {
        coords: {
          lat: -20.98119,
          lng: 47.73976099999999,
        },
        _id: "64f75284ef8f606073e99647",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "antsindra",
        fokontany: "antsindra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9,
          lng: 47.7,
        },
        _id: "64f75284ef8f606073e99648",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "antsindra",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9008713,
          lng: 47.7292426,
        },
        _id: "64f75284ef8f606073e99649",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "antsindra",
        fokontany: "kianjavato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9383562,
          lng: 47.7736217,
        },
        _id: "64f75284ef8f606073e9964a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "antsindra",
        fokontany: "ambatovory",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9698832,
          lng: 47.7982928,
        },
        _id: "64f75284ef8f606073e9964b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "antsindra",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0093219,
          lng: 47.7538931,
        },
        _id: "64f75284ef8f606073e9964c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "antsindra",
        fokontany: "ampitomita",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0438712,
          lng: 47.78842299999999,
        },
        _id: "64f75284ef8f606073e9964d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "antsindra",
        fokontany: "ampitabe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.88339,
          lng: 47.59436,
        },
        _id: "64f75284ef8f606073e9964e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "ambohimanga sud",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7601055,
          lng: 47.581581,
        },
        _id: "64f75284ef8f606073e9964f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "marolananana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7557899,
          lng: 47.6307555,
        },
        _id: "64f75284ef8f606073e99650",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "vohitrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7706912,
          lng: 47.6086213,
        },
        _id: "64f75284ef8f606073e99651",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "ambodimanga nord",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7749892,
          lng: 47.6307555,
        },
        _id: "64f75284ef8f606073e99652",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "soaniherenana nord",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8017912,
          lng: 47.5766661,
        },
        _id: "64f75284ef8f606073e99653",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "androrangavola nord",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8133908,
          lng: 47.6307555,
        },
        _id: "64f75284ef8f606073e99654",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "ambalavary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.812729,
          lng: 47.6602824,
        },
        _id: "64f75284ef8f606073e99655",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "fasintsara andakana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8464641,
          lng: 47.581581,
        },
        _id: "64f75284ef8f606073e99656",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "ambohimiranty",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8458253,
          lng: 47.6110802,
        },
        _id: "64f75284ef8f606073e99657",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "mandroalina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8680129,
          lng: 47.62091700000001,
        },
        _id: "64f75284ef8f606073e99658",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8643849,
          lng: 47.640596,
        },
        _id: "64f75284ef8f606073e99659",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "ambalaherana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8607522,
          lng: 47.6602824,
        },
        _id: "64f75284ef8f606073e9965a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "nosimboahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8842258,
          lng: 47.6110802,
        },
        _id: "64f75284ef8f606073e9965b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "sahamarana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8961855,
          lng: 47.6504382,
        },
        _id: "64f75284ef8f606073e9965c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "ambilanibe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8974424,
          lng: 47.5914122,
        },
        _id: "64f75284ef8f606073e9965d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "ambodiara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9032712,
          lng: 47.6184576,
        },
        _id: "64f75284ef8f606073e9965e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "masoarovana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9202477,
          lng: 47.5717516,
        },
        _id: "64f75284ef8f606073e9965f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "ambodivoasary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9388367,
          lng: 47.6012453,
        },
        _id: "64f75284ef8f606073e99660",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "ambatofanokonana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.954128,
          lng: 47.6356755,
        },
        _id: "64f75284ef8f606073e99661",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "marosatroka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9250018,
          lng: 47.6504382,
        },
        _id: "64f75284ef8f606073e99662",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "ambodirafia",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9628089,
          lng: 47.6799761,
        },
        _id: "64f75284ef8f606073e99663",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "faliarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9790308,
          lng: 47.67012829999999,
        },
        _id: "64f75284ef8f606073e99664",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "ambohimanga du sud",
        fokontany: "antanjona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.833333,
          lng: 47.55,
        },
        _id: "64f75284ef8f606073e99665",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "analampasina",
        fokontany: "analampasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7577862,
          lng: 47.5422745,
        },
        _id: "64f75284ef8f606073e99666",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "analampasina",
        fokontany: "matsitsirano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8099885,
          lng: 47.4931828,
        },
        _id: "64f75284ef8f606073e99667",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "analampasina",
        fokontany: "betampo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -20.825551,
          lng: 47.512814,
        },
        _id: "64f75284ef8f606073e99668",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "analampasina",
        fokontany: "androrangavola sud",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8615218,
          lng: 47.4735591,
        },
        _id: "64f75284ef8f606073e99669",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "analampasina",
        fokontany: "ambaro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8604548,
          lng: 47.52508719999999,
        },
        _id: "64f75284ef8f606073e9966a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "analampasina",
        fokontany: "analavory",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8566888,
          lng: 47.5520984,
        },
        _id: "64f75284ef8f606073e9966b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "analampasina",
        fokontany: "ambohimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8830872,
          lng: 47.512814,
        },
        _id: "64f75284ef8f606073e9966c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "analampasina",
        fokontany: "manjiriana sud",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9049633,
          lng: 47.5373633,
        },
        _id: "64f75284ef8f606073e9966d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "analampasina",
        fokontany: "ambohimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6580146,
          lng: 47.7046036,
        },
        _id: "64f75284ef8f606073e9966e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "fasintsara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5494325,
          lng: 47.6947512,
        },
        _id: "64f75284ef8f606073e9966f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "fiadanana nord",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5952254,
          lng: 47.6553601,
        },
        _id: "64f75284ef8f606073e99670",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "volobe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6,
          lng: 47.683333,
        },
        _id: "64f75284ef8f606073e99671",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "ampasinambo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.561219,
          lng: 47.7341718,
        },
        _id: "64f75284ef8f606073e99672",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6179358,
          lng: 47.707067,
        },
        _id: "64f75284ef8f606073e99673",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "ampasimbola I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6251349,
          lng: 47.7391014,
        },
        _id: "64f75284ef8f606073e99674",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "ambodiara nord",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6421292,
          lng: 47.6996772,
        },
        _id: "64f75284ef8f606073e99675",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "sakavanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6583772,
          lng: 47.6898257,
        },
        _id: "64f75284ef8f606073e99676",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "ambalapaiso",
        __v: 0,
      },
      {
        coords: {
          lat: -20.653706,
          lng: 47.6184576,
        },
        _id: "64f75284ef8f606073e99677",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "ambodivoangy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6510013,
          lng: 47.7292426,
        },
        _id: "64f75284ef8f606073e99678",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "ambinanisery",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6768631,
          lng: 47.7193856,
        },
        _id: "64f75284ef8f606073e99679",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "antenimbelona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6957517,
          lng: 47.6664359,
        },
        _id: "64f75284ef8f606073e9967a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "marovoraka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6931141,
          lng: 47.7095305,
        },
        _id: "64f75284ef8f606073e9967b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "ambohinihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7105908,
          lng: 47.6479775,
        },
        _id: "64f75284ef8f606073e9967c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7123268,
          lng: 47.7095305,
        },
        _id: "64f75284ef8f606073e9967d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7165267,
          lng: 47.6676666,
        },
        _id: "64f75284ef8f606073e9967e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "tsararivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7249912,
          lng: 47.6479775,
        },
        _id: "64f75284ef8f606073e9967f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.716667,
          lng: 47.7,
        },
        _id: "64f75284ef8f606073e99680",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "ambatomanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7551093,
          lng: 47.6602824,
        },
        _id: "64f75284ef8f606073e99681",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "tsarakianja",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7832392,
          lng: 47.6898257,
        },
        _id: "64f75284ef8f606073e99682",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "ambodiara sud",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7807732,
          lng: 47.6578212,
        },
        _id: "64f75284ef8f606073e99683",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "sahakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8090815,
          lng: 47.6799761,
        },
        _id: "64f75284ef8f606073e99684",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "manakana sud",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8435297,
          lng: 47.7144578,
        },
        _id: "64f75284ef8f606073e99685",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "fasintsara",
        fokontany: "andranomaintso",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7480699,
          lng: 47.77376899999999,
        },
        _id: "64f75284ef8f606073e99686",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "maroharatra",
        fokontany: "maroharatra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6114444,
          lng: 47.7736217,
        },
        _id: "64f75284ef8f606073e99687",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "maroharatra",
        fokontany: "malazamasina nord",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6657857,
          lng: 47.778555,
        },
        _id: "64f75284ef8f606073e99688",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "maroharatra",
        fokontany: "antanjomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6883386,
          lng: 47.7736217,
        },
        _id: "64f75284ef8f606073e99689",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "maroharatra",
        fokontany: "betampona nord",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7020027,
          lng: 47.7391014,
        },
        _id: "64f75284ef8f606073e9968a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "maroharatra",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7177199,
          lng: 47.7514275,
        },
        _id: "64f75284ef8f606073e9968b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "maroharatra",
        fokontany: "tanambao ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7308324,
          lng: 47.7391014,
        },
        _id: "64f75284ef8f606073e9968c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "maroharatra",
        fokontany: "ampasimpotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7725956,
          lng: 47.7341718,
        },
        _id: "64f75284ef8f606073e9968d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "maroharatra",
        fokontany: "ampasimadinikakely",
        __v: 0,
      },
      {
        coords: {
          lat: -20.666667,
          lng: 47.8,
        },
        _id: "64f75284ef8f606073e9968e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "maroharatra",
        fokontany: "ambinanindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7480699,
          lng: 47.77376899999999,
        },
        _id: "64f75284ef8f606073e9968f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "maroharatra",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7619258,
          lng: 47.778555,
        },
        _id: "64f75284ef8f606073e99690",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "maroharatra",
        fokontany: "malemiambo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.706093,
          lng: 47.8328517,
        },
        _id: "64f75284ef8f606073e99691",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "maroharatra",
        fokontany: "ambalavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.737546,
          lng: 47.8575503,
        },
        _id: "64f75284ef8f606073e99692",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "maroharatra",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7826975,
          lng: 47.84766949999999,
        },
        _id: "64f75284ef8f606073e99693",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "maroharatra",
        fokontany: "ambohitsara est",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7708287,
          lng: 47.8081645,
        },
        _id: "64f75284ef8f606073e99694",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "maroharatra",
        fokontany: "ampasimadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8037107,
          lng: 47.7736217,
        },
        _id: "64f75284ef8f606073e99695",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "maroharatra",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7915976,
          lng: 47.8773175,
        },
        _id: "64f75284ef8f606073e99696",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "maroharatra",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.821546,
          lng: 47.8328517,
        },
        _id: "64f75284ef8f606073e99697",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "maroharatra",
        fokontany: "malazamasina sud",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8203112,
          lng: 47.7489621,
        },
        _id: "64f75284ef8f606073e99698",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ifanadiana",
        commune: "maroharatra",
        fokontany: "sahomby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5772022,
          lng: 48.5162223,
        },
        _id: "64f75284ef8f606073e99699",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "tanambao V",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4921776,
          lng: 48.5487397,
        },
        _id: "64f75284ef8f606073e9969a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "ambinany sakaleona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5665386,
          lng: 48.496221,
        },
        _id: "64f75284ef8f606073e9969b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "ambohitsara I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5749046,
          lng: 48.383849,
        },
        _id: "64f75284ef8f606073e9969c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "antanambao I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.607681,
          lng: 48.4712296,
        },
        _id: "64f75284ef8f606073e9969d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "sarina I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5391772,
          lng: 48.4937213,
        },
        _id: "64f75284ef8f606073e9969e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "sahafary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6450253,
          lng: 48.5162223,
        },
        _id: "64f75284ef8f606073e9969f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "fenoarivo (ambazato)",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6360374,
          lng: 48.4937213,
        },
        _id: "64f75284ef8f606073e996a0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "fanivelona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6168353,
          lng: 48.4362607,
        },
        _id: "64f75284ef8f606073e996a1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "andranomahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6049984,
          lng: 48.4537424,
        },
        _id: "64f75284ef8f606073e996a2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "ambalafamafa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6031172,
          lng: 48.5137217,
        },
        _id: "64f75284ef8f606073e996a3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "tsararivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5759527,
          lng: 48.4537424,
        },
        _id: "64f75284ef8f606073e996a4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "sahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5846914,
          lng: 48.4837239,
        },
        _id: "64f75284ef8f606073e996a5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "mandanivatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6194451,
          lng: 48.4038094,
        },
        _id: "64f75284ef8f606073e996a6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "sahandrotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5624116,
          lng: 48.4737282,
        },
        _id: "64f75284ef8f606073e996a7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "ifasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5565902,
          lng: 48.4537424,
        },
        _id: "64f75284ef8f606073e996a8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "mahafitodika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6228476,
          lng: 48.5024706,
        },
        _id: "64f75284ef8f606073e996a9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "masondranokely",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6056957,
          lng: 48.4312669,
        },
        _id: "64f75284ef8f606073e996aa",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4704012,
          lng: 48.5237246,
        },
        _id: "64f75284ef8f606073e996ab",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "tanambao berano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6369664,
          lng: 48.4637344,
        },
        _id: "64f75284ef8f606073e996ac",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "antanihady",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5868563,
          lng: 48.4662327,
        },
        _id: "64f75284ef8f606073e996ad",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "bebozaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6421072,
          lng: 48.5062207,
        },
        _id: "64f75284ef8f606073e996ae",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "ampahomanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6247993,
          lng: 48.5174726,
        },
        _id: "64f75284ef8f606073e996af",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "ambodisana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6168978,
          lng: 48.4862231,
        },
        _id: "64f75284ef8f606073e996b0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "sarina II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5273128,
          lng: 48.5112213,
        },
        _id: "64f75284ef8f606073e996b1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "andonaka I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6235962,
          lng: 48.4262736,
        },
        _id: "64f75284ef8f606073e996b2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "nosy varika",
        fokontany: "sahaherana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.77265,
          lng: 48.4762,
        },
        _id: "64f75284ef8f606073e996b3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambahy",
        fokontany: "ambahy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7072364,
          lng: 48.4887224,
        },
        _id: "64f75284ef8f606073e996b4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambahy",
        fokontany: "analanolona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6955285,
          lng: 48.448747,
        },
        _id: "64f75284ef8f606073e996b5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambahy",
        fokontany: "ambodiharamy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6753767,
          lng: 48.4212808,
        },
        _id: "64f75284ef8f606073e996b6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambahy",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7793217,
          lng: 48.4537424,
        },
        _id: "64f75284ef8f606073e996b7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambahy",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7556065,
          lng: 48.3813544,
        },
        _id: "64f75284ef8f606073e996b8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambahy",
        fokontany: "maromby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7509244,
          lng: 48.4312669,
        },
        _id: "64f75284ef8f606073e996b9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambahy",
        fokontany: "ambodisandroy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7726317,
          lng: 48.406305,
        },
        _id: "64f75284ef8f606073e996ba",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambahy",
        fokontany: "ambodivandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7595776,
          lng: 48.4112965,
        },
        _id: "64f75284ef8f606073e996bb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambahy",
        fokontany: "sahorana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7456407,
          lng: 48.4462495,
        },
        _id: "64f75284ef8f606073e996bc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambahy",
        fokontany: "ampasimaneva",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7338214,
          lng: 48.4637344,
        },
        _id: "64f75284ef8f606073e996bd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambahy",
        fokontany: "mahafihetsika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7941097,
          lng: 48.3888384,
        },
        _id: "64f75284ef8f606073e996be",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "andara",
        fokontany: "andara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.834504,
          lng: 48.4437522,
        },
        _id: "64f75284ef8f606073e996bf",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "andara",
        fokontany: "ampasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7941097,
          lng: 48.3888384,
        },
        _id: "64f75284ef8f606073e996c0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "andara",
        fokontany: "sahasindra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.808005,
          lng: 48.3539219,
        },
        _id: "64f75284ef8f606073e996c1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "andara",
        fokontany: "maintimbato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7819162,
          lng: 48.36389579999999,
        },
        _id: "64f75284ef8f606073e996c2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "andara",
        fokontany: "andranohambana II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7596222,
          lng: 48.3539219,
        },
        _id: "64f75284ef8f606073e996c3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "andara",
        fokontany: "ankaranambo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.444241,
          lng: 48.37661,
        },
        _id: "64f75284ef8f606073e996c4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "fiadanana",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.449885,
          lng: 48.3614021,
        },
        _id: "64f75284ef8f606073e996c5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "fiadanana",
        fokontany: "ambalahady I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4017904,
          lng: 48.3539219,
        },
        _id: "64f75284ef8f606073e996c6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "fiadanana",
        fokontany: "ambalamanasa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4244737,
          lng: 48.448747,
        },
        _id: "64f75284ef8f606073e996c7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "fiadanana",
        fokontany: "ambatoafo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3986282,
          lng: 48.40131400000001,
        },
        _id: "64f75284ef8f606073e996c8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "fiadanana",
        fokontany: "andranomahita",
        __v: 0,
      },
      {
        coords: {
          lat: -20.370813,
          lng: 48.41379240000001,
        },
        _id: "64f75284ef8f606073e996c9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "fiadanana",
        fokontany: "mahadio",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3882494,
          lng: 48.3738715,
        },
        _id: "64f75284ef8f606073e996ca",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "fiadanana",
        fokontany: "mahatsara I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4670282,
          lng: 48.3289951,
        },
        _id: "64f75284ef8f606073e996cb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "fiadanana",
        fokontany: "sakalava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4452938,
          lng: 48.4038094,
        },
        _id: "64f75284ef8f606073e996cc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "fiadanana",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4537448,
          lng: 48.3414571,
        },
        _id: "64f75284ef8f606073e996cd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "fiadanana",
        fokontany: "vohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4230509,
          lng: 48.3938283,
        },
        _id: "64f75284ef8f606073e996ce",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "fiadanana",
        fokontany: "fihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4095013,
          lng: 48.41379240000001,
        },
        _id: "64f75284ef8f606073e996cf",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "fiadanana",
        fokontany: "antanatrambana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4200871,
          lng: 48.4837239,
        },
        _id: "64f75284ef8f606073e996d0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "fiadanana",
        fokontany: "analamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4278888,
          lng: 48.3439498,
        },
        _id: "64f75284ef8f606073e996d1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "fiadanana",
        fokontany: "andranotsara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.444241,
          lng: 48.37661,
        },
        _id: "64f75284ef8f606073e996d2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "fiadanana",
        fokontany: "andonaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4951212,
          lng: 48.4587382,
        },
        _id: "64f75284ef8f606073e996d3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodirian'i sahafary",
        fokontany: "ambodirian'i sahafary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5188252,
          lng: 48.4237772,
        },
        _id: "64f75284ef8f606073e996d4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodirian'i sahafary",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5229479,
          lng: 48.4462495,
        },
        _id: "64f75284ef8f606073e996d5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodirian'i sahafary",
        fokontany: "menagisy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5287623,
          lng: 48.4662327,
        },
        _id: "64f75284ef8f606073e996d6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodirian'i sahafary",
        fokontany: "andranomaitso",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5437618,
          lng: 48.4512446,
        },
        _id: "64f75284ef8f606073e996d7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodirian'i sahafary",
        fokontany: "volobe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5676802,
          lng: 48.4088007,
        },
        _id: "64f75284ef8f606073e996d8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodirian'i sahafary",
        fokontany: "vohimaro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5362101,
          lng: 48.383849,
        },
        _id: "64f75284ef8f606073e996d9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodirian'i sahafary",
        fokontany: "ambalafatakana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5077057,
          lng: 48.4687311,
        },
        _id: "64f75284ef8f606073e996da",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodirian'i sahafary",
        fokontany: "ambodiroranga",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4936583,
          lng: 48.5037206,
        },
        _id: "64f75284ef8f606073e996db",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodirian'i sahafary",
        fokontany: "sahanikasy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4798691,
          lng: 48.4812248,
        },
        _id: "64f75284ef8f606073e996dc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodirian'i sahafary",
        fokontany: "marofaria",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4588104,
          lng: 48.4837239,
        },
        _id: "64f75284ef8f606073e996dd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodirian'i sahafary",
        fokontany: "tanambao sahafony",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4287668,
          lng: 48.5137217,
        },
        _id: "64f75284ef8f606073e996de",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodirian'i sahafary",
        fokontany: "ampanasana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4568941,
          lng: 48.4437522,
        },
        _id: "64f75284ef8f606073e996df",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodirian'i sahafary",
        fokontany: "ambodiara III",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4675422,
          lng: 48.41379240000001,
        },
        _id: "64f75284ef8f606073e996e0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodirian'i sahafary",
        fokontany: "androranga",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4907543,
          lng: 48.3938283,
        },
        _id: "64f75284ef8f606073e996e1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodirian'i sahafary",
        fokontany: "manandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5013746,
          lng: 48.36389579999999,
        },
        _id: "64f75284ef8f606073e996e2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodirian'i sahafary",
        fokontany: "ambodiara II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4542381,
          lng: 48.4262736,
        },
        _id: "64f75284ef8f606073e996e3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodirian'i sahafary",
        fokontany: "ampitabe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5771918,
          lng: 48.3090619,
        },
        _id: "64f75284ef8f606073e996e4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "ampitamalandy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5977376,
          lng: 48.3279746,
        },
        _id: "64f75284ef8f606073e996e5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "ampiranambo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6271076,
          lng: 48.36389579999999,
        },
        _id: "64f75284ef8f606073e996e6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "ambavan'i sahavato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6549703,
          lng: 48.2941167,
        },
        _id: "64f75284ef8f606073e996e7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "ambatobe II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6147376,
          lng: 48.3464426,
        },
        _id: "64f75284ef8f606073e996e8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "ambodiramiavona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6540902,
          lng: 48.3240111,
        },
        _id: "64f75285ef8f606073e996e9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "lakamandrorona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6250813,
          lng: 48.3240111,
        },
        _id: "64f75285ef8f606073e996ea",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "ampitsahambe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5884157,
          lng: 48.36389579999999,
        },
        _id: "64f75285ef8f606073e996eb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "ambalafary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6016915,
          lng: 48.3514287,
        },
        _id: "64f75285ef8f606073e996ec",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "sahanofa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6190076,
          lng: 48.3115531,
        },
        _id: "64f75285ef8f606073e996ed",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "vohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5556215,
          lng: 48.2766859,
        },
        _id: "64f75285ef8f606073e996ee",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5659573,
          lng: 48.254283,
        },
        _id: "64f75285ef8f606073e996ef",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "malazamasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5989673,
          lng: 48.2269142,
        },
        _id: "64f75285ef8f606073e996f0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "andibodiboka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6151375,
          lng: 48.2244268,
        },
        _id: "64f75285ef8f606073e996f1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "ambodiroranga",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6046007,
          lng: 48.254283,
        },
        _id: "64f75285ef8f606073e996f2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "ambodivandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6482963,
          lng: 48.2468174,
        },
        _id: "64f75285ef8f606073e996f3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "andranokopy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6518523,
          lng: 48.2629941,
        },
        _id: "64f75285ef8f606073e996f4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "ampasimaniona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.610453,
          lng: 48.2741962,
        },
        _id: "64f75285ef8f606073e996f5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "ambazato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5184316,
          lng: 48.2816655,
        },
        _id: "64f75285ef8f606073e996f6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "malimalina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.52639,
          lng: 48.27103,
        },
        _id: "64f75285ef8f606073e996f7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "ampasimazava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5003334,
          lng: 48.2941167,
        },
        _id: "64f75285ef8f606073e996f8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "andranotsara I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4981811,
          lng: 48.3115531,
        },
        _id: "64f75285ef8f606073e996f9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "manakana I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5216483,
          lng: 48.3339796,
        },
        _id: "64f75285ef8f606073e996fa",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "ambatoina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5052244,
          lng: 48.3439498,
        },
        _id: "64f75285ef8f606073e996fb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "ambodimanga I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5257841,
          lng: 48.3564152,
        },
        _id: "64f75285ef8f606073e996fc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "ambodihasina I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.566667,
          lng: 48.34999999999999,
        },
        _id: "64f75285ef8f606073e996fd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "ambatomivarina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.557403,
          lng: 48.3240111,
        },
        _id: "64f75285ef8f606073e996fe",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "niarovana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5322382,
          lng: 48.3040797,
        },
        _id: "64f75285ef8f606073e996ff",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "sahavato",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6986384,
          lng: 48.3439498,
        },
        _id: "64f75285ef8f606073e99700",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohilava",
        fokontany: "vohilava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6586075,
          lng: 48.3888384,
        },
        _id: "64f75285ef8f606073e99701",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohilava",
        fokontany: "ambandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6968731,
          lng: 48.4038094,
        },
        _id: "64f75285ef8f606073e99702",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohilava",
        fokontany: "andranomavo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.650491,
          lng: 48.4437522,
        },
        _id: "64f75285ef8f606073e99703",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohilava",
        fokontany: "ankosilamba",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7360313,
          lng: 48.3888384,
        },
        _id: "64f75285ef8f606073e99704",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohilava",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7133007,
          lng: 48.3938283,
        },
        _id: "64f75285ef8f606073e99705",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohilava",
        fokontany: "amboditavia",
        __v: 0,
      },
      {
        coords: {
          lat: -20.650785,
          lng: 48.4075528,
        },
        _id: "64f75285ef8f606073e99706",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohilava",
        fokontany: "ambohimiritika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6756998,
          lng: 48.3564152,
        },
        _id: "64f75285ef8f606073e99707",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohilava",
        fokontany: "antanjondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.709261,
          lng: 48.4212808,
        },
        _id: "64f75285ef8f606073e99708",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohilava",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7045038,
          lng: 48.36389579999999,
        },
        _id: "64f75285ef8f606073e99709",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohilava",
        fokontany: "ambodibakoly",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7305956,
          lng: 48.3539219,
        },
        _id: "64f75285ef8f606073e9970a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohilava",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6597252,
          lng: 48.3514287,
        },
        _id: "64f75285ef8f606073e9970b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohilava",
        fokontany: "mahatsara I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6704184,
          lng: 48.37137740000001,
        },
        _id: "64f75285ef8f606073e9970c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohilava",
        fokontany: "vohipahy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.76285,
          lng: 48.26305,
        },
        _id: "64f75285ef8f606073e9970d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohitrandriana",
        fokontany: "vohitrandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7314586,
          lng: 48.3240111,
        },
        _id: "64f75285ef8f606073e9970e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohitrandriana",
        fokontany: "ambinanindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6995092,
          lng: 48.31404449999999,
        },
        _id: "64f75285ef8f606073e9970f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohitrandriana",
        fokontany: "vohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6717458,
          lng: 48.3265031,
        },
        _id: "64f75285ef8f606073e99710",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohitrandriana",
        fokontany: "androroangavola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.66128,
          lng: 48.2677989,
        },
        _id: "64f75285ef8f606073e99711",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohitrandriana",
        fokontany: "mahafihetsika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7087843,
          lng: 48.2717067,
        },
        _id: "64f75285ef8f606073e99712",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohitrandriana",
        fokontany: "morarano II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7142539,
          lng: 48.24930579999999,
        },
        _id: "64f75285ef8f606073e99713",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohitrandriana",
        fokontany: "vohitrarivo I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7604556,
          lng: 48.2095048,
        },
        _id: "64f75285ef8f606073e99714",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohitrandriana",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7561816,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e99715",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohitrandriana",
        fokontany: "morarano I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7904472,
          lng: 48.1722179,
        },
        _id: "64f75285ef8f606073e99716",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohitrandriana",
        fokontany: "ambatoseza",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7772342,
          lng: 48.184644,
        },
        _id: "64f75285ef8f606073e99717",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohitrandriana",
        fokontany: "manakana I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8054075,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e99718",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohitrandriana",
        fokontany: "mandroromody II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8201638,
          lng: 48.2642387,
        },
        _id: "64f75285ef8f606073e99719",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohitrandriana",
        fokontany: "ambodiara I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7974684,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e9971a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohitrandriana",
        fokontany: "malazamasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7899148,
          lng: 48.3090619,
        },
        _id: "64f75285ef8f606073e9971b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohitrandriana",
        fokontany: "tanambao I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7575349,
          lng: 48.31404449999999,
        },
        _id: "64f75285ef8f606073e9971c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohitrandriana",
        fokontany: "vohidamba",
        __v: 0,
      },
      {
        coords: {
          lat: -20.687339,
          lng: 48.2891359,
        },
        _id: "64f75285ef8f606073e9971d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohitrandriana",
        fokontany: "marosika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6773017,
          lng: 48.1896152,
        },
        _id: "64f75285ef8f606073e9971e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohidroa",
        fokontany: "vohidroa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.676029,
          lng: 48.23437699999999,
        },
        _id: "64f75285ef8f606073e9971f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohidroa",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7037458,
          lng: 48.1647635,
        },
        _id: "64f75285ef8f606073e99720",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohidroa",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6604986,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e99721",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohidroa",
        fokontany: "andapa ambalafary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6344551,
          lng: 48.2244268,
        },
        _id: "64f75285ef8f606073e99722",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohidroa",
        fokontany: "mizahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6428872,
          lng: 48.154826,
        },
        _id: "64f75285ef8f606073e99723",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohidroa",
        fokontany: "vohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6697463,
          lng: 48.1722179,
        },
        _id: "64f75285ef8f606073e99724",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohidroa",
        fokontany: "tsaravinany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6642853,
          lng: 48.194587,
        },
        _id: "64f75285ef8f606073e99725",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "vohidroa",
        fokontany: "ambinanirano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6923854,
          lng: 48.1101299,
        },
        _id: "64f75285ef8f606073e99726",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambakobe",
        fokontany: "ambakobe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6156257,
          lng: 48.0952394,
        },
        _id: "64f75285ef8f606073e99727",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambakobe",
        fokontany: "sakafotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7158395,
          lng: 48.0753917,
        },
        _id: "64f75285ef8f606073e99728",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambakobe",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -20.75,
          lng: 48.083333,
        },
        _id: "64f75285ef8f606073e99729",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambakobe",
        fokontany: "maroantova",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7217353,
          lng: 48.0952394,
        },
        _id: "64f75285ef8f606073e9972a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambakobe",
        fokontany: "bekazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6628184,
          lng: 48.1324733,
        },
        _id: "64f75285ef8f606073e9972b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambakobe",
        fokontany: "sahamanerana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7045697,
          lng: 48.1349565,
        },
        _id: "64f75285ef8f606073e9972c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambakobe",
        fokontany: "sahamahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6475002,
          lng: 48.1051659,
        },
        _id: "64f75285ef8f606073e9972d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambakobe",
        fokontany: "ampasinambo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.39377,
          lng: 48.28796,
        },
        _id: "64f75285ef8f606073e9972e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "soavina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3814498,
          lng: 48.184644,
        },
        _id: "64f75285ef8f606073e9972f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "ambalakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3745003,
          lng: 48.3015888,
        },
        _id: "64f75285ef8f606073e99730",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "ambalavero I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3993409,
          lng: 48.2294017,
        },
        _id: "64f75285ef8f606073e99731",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "ambalavero II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3544563,
          lng: 48.2244268,
        },
        _id: "64f75285ef8f606073e99732",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "ambalavia",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4356057,
          lng: 48.3040797,
        },
        _id: "64f75285ef8f606073e99733",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "ambaninambato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3788217,
          lng: 48.2169653,
        },
        _id: "64f75285ef8f606073e99734",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "ambinanihafotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3534968,
          lng: 48.254283,
        },
        _id: "64f75285ef8f606073e99735",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "ambodivoangy I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3337031,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e99736",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "ambohimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3239706,
          lng: 48.36888339999999,
        },
        _id: "64f75285ef8f606073e99737",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "ampasimaniona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3090971,
          lng: 48.23437699999999,
        },
        _id: "64f75285ef8f606073e99738",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "ampasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3658084,
          lng: 48.2717067,
        },
        _id: "64f75285ef8f606073e99739",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "ampitabe I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2338801,
          lng: 48.1747029,
        },
        _id: "64f75285ef8f606073e9973a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "analamazava I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3923161,
          lng: 48.197073,
        },
        _id: "64f75285ef8f606073e9973b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "andapa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.371595,
          lng: 48.2418409,
        },
        _id: "64f75285ef8f606073e9973c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "andranobe I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3580487,
          lng: 48.3614021,
        },
        _id: "64f75285ef8f606073e9973d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "betampona II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4655288,
          lng: 48.2741962,
        },
        _id: "64f75285ef8f606073e9973e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "manakana I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.355431,
          lng: 48.17916899999999,
        },
        _id: "64f75285ef8f606073e9973f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "marofototra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3515542,
          lng: 48.31404449999999,
        },
        _id: "64f75285ef8f606073e99740",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "maroharatra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.423988,
          lng: 48.2642387,
        },
        _id: "64f75285ef8f606073e99741",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "marovato II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2980077,
          lng: 48.1796732,
        },
        _id: "64f75285ef8f606073e99742",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "marosika II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3454921,
          lng: 48.3514287,
        },
        _id: "64f75285ef8f606073e99743",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "sahamahitsy I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3793219,
          lng: 48.2517943,
        },
        _id: "64f75285ef8f606073e99744",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "sahandrarezona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3247197,
          lng: 48.3464426,
        },
        _id: "64f75285ef8f606073e99745",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "sahanimborona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3158039,
          lng: 48.3240111,
        },
        _id: "64f75285ef8f606073e99746",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "tsaralazaina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3930966,
          lng: 48.3240111,
        },
        _id: "64f75285ef8f606073e99747",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "vohibola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3226092,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e99748",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "soavina",
        fokontany: "vohijanahary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5154,
          lng: 48.175621,
        },
        _id: "64f75285ef8f606073e99749",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodilafa",
        fokontany: "ambodilafa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5636179,
          lng: 48.1150943,
        },
        _id: "64f75285ef8f606073e9974a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodilafa",
        fokontany: "ambalahasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3976073,
          lng: 48.18215850000001,
        },
        _id: "64f75285ef8f606073e9974b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodilafa",
        fokontany: "ambodilafakely",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4673777,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e9974c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodilafa",
        fokontany: "ambodimanga II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4084093,
          lng: 48.1448904,
        },
        _id: "64f75285ef8f606073e9974d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodilafa",
        fokontany: "ampasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6076412,
          lng: 48.14985799999999,
        },
        _id: "64f75285ef8f606073e9974e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodilafa",
        fokontany: "andohalobe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5058802,
          lng: 48.2182088,
        },
        _id: "64f75285ef8f606073e9974f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodilafa",
        fokontany: "antanambao I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.555593,
          lng: 48.169733,
        },
        _id: "64f75285ef8f606073e99750",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodilafa",
        fokontany: "antanjomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5062191,
          lng: 48.2070182,
        },
        _id: "64f75285ef8f606073e99751",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodilafa",
        fokontany: "farafasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.506188,
          lng: 48.1002024,
        },
        _id: "64f75285ef8f606073e99752",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodilafa",
        fokontany: "sak'ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.416667,
          lng: 48.15,
        },
        _id: "64f75285ef8f606073e99753",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodilafa",
        fokontany: "tsaravinany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3702987,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e99754",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodilafa",
        fokontany: "vohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5241609,
          lng: 48.1448904,
        },
        _id: "64f75285ef8f606073e99755",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodilafa",
        fokontany: "ambohitsara I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3917958,
          lng: 48.162279,
        },
        _id: "64f75285ef8f606073e99756",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodilafa",
        fokontany: "mahadonaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.464922,
          lng: 48.1896152,
        },
        _id: "64f75285ef8f606073e99757",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodilafa",
        fokontany: "marovato I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5900479,
          lng: 48.0902768,
        },
        _id: "64f75285ef8f606073e99758",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodilafa",
        fokontany: "ambinanitranomaro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5412458,
          lng: 48.2194523,
        },
        _id: "64f75285ef8f606073e99759",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "androrangavola",
        fokontany: "androrangavola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5492182,
          lng: 48.2219395,
        },
        _id: "64f75285ef8f606073e9975a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "androrangavola",
        fokontany: "ambalakafe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5697716,
          lng: 48.23437699999999,
        },
        _id: "64f75285ef8f606073e9975b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "androrangavola",
        fokontany: "ambalafarihy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4857706,
          lng: 48.24432909999999,
        },
        _id: "64f75285ef8f606073e9975c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "androrangavola",
        fokontany: "ambatomasina II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6160051,
          lng: 48.194587,
        },
        _id: "64f75285ef8f606073e9975d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "androrangavola",
        fokontany: "ambodimanga IV",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5080003,
          lng: 48.254283,
        },
        _id: "64f75285ef8f606073e9975e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "androrangavola",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4568008,
          lng: 48.24432909999999,
        },
        _id: "64f75285ef8f606073e9975f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "androrangavola",
        fokontany: "antanandehibe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5223837,
          lng: 48.2045317,
        },
        _id: "64f75285ef8f606073e99760",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "androrangavola",
        fokontany: "sandranamba",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5773856,
          lng: 48.194587,
        },
        _id: "64f75285ef8f606073e99761",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "androrangavola",
        fokontany: "vohimasina II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5610012,
          lng: 48.2045317,
        },
        _id: "64f75285ef8f606073e99762",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "androrangavola",
        fokontany: "analila",
        __v: 0,
      },
      {
        coords: {
          lat: -20.523491,
          lng: 48.020279,
        },
        _id: "64f75285ef8f606073e99763",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ampasinambo",
        fokontany: "ampasinambo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5803911,
          lng: 47.9762632,
        },
        _id: "64f75285ef8f606073e99764",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ampasinambo",
        fokontany: "ambalahasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.591724,
          lng: 48.03076129999999,
        },
        _id: "64f75285ef8f606073e99765",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ampasinambo",
        fokontany: "vatoatody",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5041902,
          lng: 47.9465602,
        },
        _id: "64f75285ef8f606073e99766",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ampasinambo",
        fokontany: "ampasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5489776,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e99767",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ampasinambo",
        fokontany: "ambodilafakely",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4661121,
          lng: 47.931715,
        },
        _id: "64f75285ef8f606073e99768",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ampasinambo",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.433031,
          lng: 47.9663604,
        },
        _id: "64f75285ef8f606073e99769",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ampasinambo",
        fokontany: "ampasimadinika I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4388711,
          lng: 47.9861678,
        },
        _id: "64f75285ef8f606073e9976a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ampasinambo",
        fokontany: "ambodivoangy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4840909,
          lng: 47.9762632,
        },
        _id: "64f75285ef8f606073e9976b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ampasinambo",
        fokontany: "ambalatenina nord",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4476303,
          lng: 48.01589269999999,
        },
        _id: "64f75285ef8f606073e9976c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ampasinambo",
        fokontany: "ambohinihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.476094,
          lng: 48.03076129999999,
        },
        _id: "64f75285ef8f606073e9976d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ampasinambo",
        fokontany: "ambohimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5397617,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e9976e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ampasinambo",
        fokontany: "lavakianja est",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5129439,
          lng: 47.8624914,
        },
        _id: "64f75285ef8f606073e9976f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "antanambao",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4677891,
          lng: 47.872375,
        },
        _id: "64f75285ef8f606073e99770",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "antanambao",
        fokontany: "mahadonaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5020928,
          lng: 47.906982,
        },
        _id: "64f75285ef8f606073e99771",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "antanambao",
        fokontany: "androrangavola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5694522,
          lng: 47.906982,
        },
        _id: "64f75285ef8f606073e99772",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "antanambao",
        fokontany: "mahatsara I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6250358,
          lng: 47.867433,
        },
        _id: "64f75285ef8f606073e99773",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "antanambao",
        fokontany: "antanjonomby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5547552,
          lng: 47.8575503,
        },
        _id: "64f75285ef8f606073e99774",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "antanambao",
        fokontany: "ambodiriana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.595382,
          lng: 47.897092,
        },
        _id: "64f75285ef8f606073e99775",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "antanambao",
        fokontany: "ankerana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5278988,
          lng: 47.7834888,
        },
        _id: "64f75285ef8f606073e99776",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "angodongodona",
        fokontany: "angodongodona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4777199,
          lng: 47.7440315,
        },
        _id: "64f75285ef8f606073e99777",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "angodongodona",
        fokontany: "ambinanindrano II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5592803,
          lng: 47.8081645,
        },
        _id: "64f75285ef8f606073e99778",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "angodongodona",
        fokontany: "ambohitsara IV",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5845914,
          lng: 47.8205067,
        },
        _id: "64f75285ef8f606073e99779",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "angodongodona",
        fokontany: "tsaraseranana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5616307,
          lng: 47.84026009999999,
        },
        _id: "64f75285ef8f606073e9977a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "angodongodona",
        fokontany: "antrobaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5999034,
          lng: 47.84766949999999,
        },
        _id: "64f75285ef8f606073e9977b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "angodongodona",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6236269,
          lng: 47.7982928,
        },
        _id: "64f75285ef8f606073e9977c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "angodongodona",
        fokontany: "ambinanindrano I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6585655,
          lng: 47.8106327,
        },
        _id: "64f75285ef8f606073e9977d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "angodongodona",
        fokontany: "ampasimadinika IV",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7645152,
          lng: 47.9321528,
        },
        _id: "64f75285ef8f606073e9977e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "befody",
        fokontany: "befody",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6719913,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e9977f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "befody",
        fokontany: "ambohimilanja",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7044006,
          lng: 47.89956429999999,
        },
        _id: "64f75285ef8f606073e99780",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "befody",
        fokontany: "ambodinambalo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7493897,
          lng: 47.897092,
        },
        _id: "64f75285ef8f606073e99781",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "befody",
        fokontany: "analamarina I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7256606,
          lng: 47.9465602,
        },
        _id: "64f75285ef8f606073e99782",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "befody",
        fokontany: "andrambotsivelezina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7148666,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e99783",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "befody",
        fokontany: "ampasimbola II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7493,
          lng: 48.0258046,
        },
        _id: "64f75285ef8f606073e99784",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "befody",
        fokontany: "ambodinonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8219945,
          lng: 47.9465602,
        },
        _id: "64f75285ef8f606073e99785",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "befody",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8143944,
          lng: 47.9292411,
        },
        _id: "64f75285ef8f606073e99786",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "befody",
        fokontany: "vohitrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7899425,
          lng: 47.9440857,
        },
        _id: "64f75285ef8f606073e99787",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "befody",
        fokontany: "ambalakafe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7875343,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e99788",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "befody",
        fokontany: "ambodipaiso",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8167885,
          lng: 47.897092,
        },
        _id: "64f75285ef8f606073e99789",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "befody",
        fokontany: "ambohipeno",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6252059,
          lng: 47.9812152,
        },
        _id: "64f75285ef8f606073e9978a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodiara",
        fokontany: "ambodiara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5720072,
          lng: 47.960172,
        },
        _id: "64f75285ef8f606073e9978b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodiara",
        fokontany: "ambalatenina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5812075,
          lng: 47.9465602,
        },
        _id: "64f75285ef8f606073e9978c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodiara",
        fokontany: "tsahandraty",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6938132,
          lng: 47.93666289999999,
        },
        _id: "64f75285ef8f606073e9978d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodiara",
        fokontany: "vohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6108928,
          lng: 47.9168738,
        },
        _id: "64f75285ef8f606073e9978e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodiara",
        fokontany: "antanambao I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6452696,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e9978f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodiara",
        fokontany: "ambodilafa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6472161,
          lng: 47.99855119999999,
        },
        _id: "64f75285ef8f606073e99790",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodiara",
        fokontany: "marosakaiza",
        __v: 0,
      },
      {
        coords: {
          lat: -20.636156,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e99791",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodiara",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6940052,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e99792",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "nosy-varika",
        commune: "ambodiara",
        fokontany: "vohimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.23988,
          lng: 48.3451962,
        },
        _id: "64f75285ef8f606073e99793",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mananjary",
        fokontany: "masindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2097182,
          lng: 48.3576619,
        },
        _id: "64f75285ef8f606073e99794",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mananjary",
        fokontany: "andovosira",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2086667,
          lng: 48.3339796,
        },
        _id: "64f75285ef8f606073e99795",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mananjary",
        fokontany: "marofinaritra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2123645,
          lng: 48.31404449999999,
        },
        _id: "64f75285ef8f606073e99796",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mananjary",
        fokontany: "ambonaramena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.206638,
          lng: 48.3514287,
        },
        _id: "64f75285ef8f606073e99797",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mananjary",
        fokontany: "ambatolambo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2230175,
          lng: 48.3414571,
        },
        _id: "64f75285ef8f606073e99798",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mananjary",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -21.22764,
          lng: 48.3508054,
        },
        _id: "64f75285ef8f606073e99799",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mananjary",
        fokontany: "anosinakoho",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2308982,
          lng: 48.3495589,
        },
        _id: "64f75285ef8f606073e9979a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mananjary",
        fokontany: "ankadirano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2296209,
          lng: 48.3352257,
        },
        _id: "64f75285ef8f606073e9979b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mananjary",
        fokontany: "analananjavidy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2352122,
          lng: 48.3377182,
        },
        _id: "64f75285ef8f606073e9979c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mananjary",
        fokontany: "ampasimandrorona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2442358,
          lng: 48.3314873,
        },
        _id: "64f75285ef8f606073e9979d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mananjary",
        fokontany: "fangato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2494245,
          lng: 48.316536,
        },
        _id: "64f75285ef8f606073e9979e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mananjary",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2617997,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e9979f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "tsaravary",
        fokontany: "tsaravary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2292522,
          lng: 48.2816655,
        },
        _id: "64f75285ef8f606073e997a0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "tsaravary",
        fokontany: "ampasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2524588,
          lng: 48.254283,
        },
        _id: "64f75285ef8f606073e997a1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "tsaravary",
        fokontany: "sarina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2597915,
          lng: 48.2866457,
        },
        _id: "64f75285ef8f606073e997a2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "tsaravary",
        fokontany: "marofody",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2552908,
          lng: 48.2717067,
        },
        _id: "64f75285ef8f606073e997a3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "tsaravary",
        fokontany: "tanambao marofody",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2911686,
          lng: 48.254283,
        },
        _id: "64f75285ef8f606073e997a4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "tsaravary",
        fokontany: "ambodikizy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3652648,
          lng: 48.2592606,
        },
        _id: "64f75285ef8f606073e997a5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ankatafana",
        fokontany: "ankatafana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2741437,
          lng: 48.2941167,
        },
        _id: "64f75285ef8f606073e997a6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ankatafana",
        fokontany: "ambaro",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2630766,
          lng: 48.3413013,
        },
        _id: "64f75285ef8f606073e997a7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ankatafana",
        fokontany: "ambohimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3025181,
          lng: 48.3240111,
        },
        _id: "64f75285ef8f606073e997a8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ankatafana",
        fokontany: "ampangalana sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3255615,
          lng: 48.3040797,
        },
        _id: "64f75285ef8f606073e997a9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ankatafana",
        fokontany: "ambalavontaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3001845,
          lng: 48.2841556,
        },
        _id: "64f75285ef8f606073e997aa",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ankatafana",
        fokontany: "maropaika",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4033557,
          lng: 48.2891359,
        },
        _id: "64f75285ef8f606073e997ab",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ankatafana",
        fokontany: "marohita II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3919119,
          lng: 48.2194523,
        },
        _id: "64f75285ef8f606073e997ac",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ankatafana",
        fokontany: "marohita I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1568985,
          lng: 48.33896439999999,
        },
        _id: "64f75285ef8f606073e997ad",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahatsara sud",
        fokontany: "mahatsara sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1125682,
          lng: 48.3040797,
        },
        _id: "64f75285ef8f606073e997ae",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahatsara sud",
        fokontany: "manampotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.081274,
          lng: 48.3938283,
        },
        _id: "64f75285ef8f606073e997af",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahatsara sud",
        fokontany: "amboanato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1501995,
          lng: 48.3489356,
        },
        _id: "64f75285ef8f606073e997b0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahatsara sud",
        fokontany: "anjanavorona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1356346,
          lng: 48.2841556,
        },
        _id: "64f75285ef8f606073e997b1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahatsara sud",
        fokontany: "iamborano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1984268,
          lng: 48.2194523,
        },
        _id: "64f75285ef8f606073e997b2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "tsiatosika",
        fokontany: "tsiatosika",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0559813,
          lng: 48.24432909999999,
        },
        _id: "64f75285ef8f606073e997b3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "tsiatosika",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.100998,
          lng: 48.24930579999999,
        },
        _id: "64f75285ef8f606073e997b4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "tsiatosika",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1337153,
          lng: 48.2294017,
        },
        _id: "64f75285ef8f606073e997b5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "tsiatosika",
        fokontany: "anosimparihy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1623827,
          lng: 48.24432909999999,
        },
        _id: "64f75285ef8f606073e997b6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "tsiatosika",
        fokontany: "ankarongana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1664176,
          lng: 48.2095048,
        },
        _id: "64f75285ef8f606073e997b7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "tsiatosika",
        fokontany: "volomborona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1944004,
          lng: 48.254283,
        },
        _id: "64f75285ef8f606073e997b8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "tsiatosika",
        fokontany: "betampona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.199105,
          lng: 48.1896152,
        },
        _id: "64f75285ef8f606073e997b9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "tsiatosika",
        fokontany: "ambohimiarina I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2144345,
          lng: 48.2244268,
        },
        _id: "64f75285ef8f606073e997ba",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "tsiatosika",
        fokontany: "tsarahafatra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2100672,
          lng: 48.2741962,
        },
        _id: "64f75285ef8f606073e997bb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "tsiatosika",
        fokontany: "andranomaresaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2301062,
          lng: 48.24432909999999,
        },
        _id: "64f75285ef8f606073e997bc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "tsiatosika",
        fokontany: "manakana I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2537904,
          lng: 48.194587,
        },
        _id: "64f75285ef8f606073e997bd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "tsiatosika",
        fokontany: "andranomavo II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2658063,
          lng: 48.23437699999999,
        },
        _id: "64f75285ef8f606073e997be",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "tsiatosika",
        fokontany: "ambalaromba",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2894664,
          lng: 48.184644,
        },
        _id: "64f75285ef8f606073e997bf",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "tsiatosika",
        fokontany: "andranomiteka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0794347,
          lng: 48.33896439999999,
        },
        _id: "64f75285ef8f606073e997c0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahatsara iefaka",
        fokontany: "mahatsara iefaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0701287,
          lng: 48.3240111,
        },
        _id: "64f75285ef8f606073e997c1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahatsara iefaka",
        fokontany: "amboditandroho",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0742241,
          lng: 48.2891359,
        },
        _id: "64f75285ef8f606073e997c2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahatsara iefaka",
        fokontany: "amberomanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0786798,
          lng: 48.36888339999999,
        },
        _id: "64f75285ef8f606073e997c3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahatsara iefaka",
        fokontany: "mahavoky sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1176088,
          lng: 48.3614021,
        },
        _id: "64f75285ef8f606073e997c4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahatsara iefaka",
        fokontany: "antanjondava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.228359,
          lng: 48.147491,
        },
        _id: "64f75285ef8f606073e997c5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marokarima",
        fokontany: "marokarima",
        __v: 0,
      },
      {
        coords: {
          lat: -21.149799,
          lng: 48.0902768,
        },
        _id: "64f75285ef8f606073e997c6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marokarima",
        fokontany: "ambaladara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1391308,
          lng: 48.1349565,
        },
        _id: "64f75285ef8f606073e997c7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marokarima",
        fokontany: "antanjona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2077483,
          lng: 48.0902768,
        },
        _id: "64f75285ef8f606073e997c8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marokarima",
        fokontany: "tsaramiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2596832,
          lng: 48.070431,
        },
        _id: "64f75285ef8f606073e997c9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marokarima",
        fokontany: "maromandia",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2913629,
          lng: 48.0952394,
        },
        _id: "64f75285ef8f606073e997ca",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marokarima",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2937504,
          lng: 48.1349565,
        },
        _id: "64f75285ef8f606073e997cb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marokarima",
        fokontany: "marohandrina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2514384,
          lng: 48.154826,
        },
        _id: "64f75285ef8f606073e997cc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marokarima",
        fokontany: "ankarimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -21.105949,
          lng: 48.179089,
        },
        _id: "64f75285ef8f606073e997cd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "morafeno",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9923842,
          lng: 48.2095048,
        },
        _id: "64f75285ef8f606073e997ce",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "morafeno",
        fokontany: "antaramasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0012649,
          lng: 48.1101299,
        },
        _id: "64f75285ef8f606073e997cf",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "morafeno",
        fokontany: "ambodivandrika II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0131816,
          lng: 48.14985799999999,
        },
        _id: "64f75285ef8f606073e997d0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "morafeno",
        fokontany: "mandroromody",
        __v: 0,
      },
      {
        coords: {
          lat: -21.053234,
          lng: 48.0902768,
        },
        _id: "64f75285ef8f606073e997d1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "morafeno",
        fokontany: "antanambaokely",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0844988,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e997d2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "morafeno",
        fokontany: "manakana I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3913462,
          lng: 48.0468735,
        },
        _id: "64f75285ef8f606073e997d3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antsenavolo",
        fokontany: "antsenavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3081662,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e997d4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antsenavolo",
        fokontany: "andranomavo II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.326991,
          lng: 48.0853146,
        },
        _id: "64f75285ef8f606073e997d5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antsenavolo",
        fokontany: "ambodibonary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3191094,
          lng: 48.154826,
        },
        _id: "64f75285ef8f606073e997d6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antsenavolo",
        fokontany: "ankazotokana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3426902,
          lng: 48.1051659,
        },
        _id: "64f75285ef8f606073e997d7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antsenavolo",
        fokontany: "ambodivoangy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3309123,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e997d8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antsenavolo",
        fokontany: "ambodiara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.361108,
          lng: 48.14985799999999,
        },
        _id: "64f75285ef8f606073e997d9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antsenavolo",
        fokontany: "tsararano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3692501,
          lng: 48.0654706,
        },
        _id: "64f75285ef8f606073e997da",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antsenavolo",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3752977,
          lng: 48.0853146,
        },
        _id: "64f75285ef8f606073e997db",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antsenavolo",
        fokontany: "ambatofaritana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3813447,
          lng: 48.1051659,
        },
        _id: "64f75285ef8f606073e997dc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antsenavolo",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3892905,
          lng: 48.0282829,
        },
        _id: "64f75285ef8f606073e997dd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antsenavolo",
        fokontany: "ambodiakatra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4000815,
          lng: 48.1349565,
        },
        _id: "64f75285ef8f606073e997de",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antsenavolo",
        fokontany: "betampona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3982752,
          lng: 47.9787391,
        },
        _id: "64f75285ef8f606073e997df",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antsenavolo",
        fokontany: "irondro",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4042841,
          lng: 48.0853146,
        },
        _id: "64f75285ef8f606073e997e0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antsenavolo",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4277764,
          lng: 48.0357184,
        },
        _id: "64f75285ef8f606073e997e1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antsenavolo",
        fokontany: "sahafotahina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.419417,
          lng: 48.1349565,
        },
        _id: "64f75285ef8f606073e997e2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antsenavolo",
        fokontany: "madiolamba",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9901111,
          lng: 48.299098,
        },
        _id: "64f75285ef8f606073e997e3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marosangy",
        fokontany: "marosangy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9413411,
          lng: 48.31404449999999,
        },
        _id: "64f75285ef8f606073e997e4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marosangy",
        fokontany: "androranga",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9555402,
          lng: 48.2642387,
        },
        _id: "64f75285ef8f606073e997e5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marosangy",
        fokontany: "ambohimahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.956308,
          lng: 48.23437699999999,
        },
        _id: "64f75285ef8f606073e997e6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marosangy",
        fokontany: "iankana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9606941,
          lng: 48.31404449999999,
        },
        _id: "64f75285ef8f606073e997e7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marosangy",
        fokontany: "ambalakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9949829,
          lng: 48.23437699999999,
        },
        _id: "64f75285ef8f606073e997e8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marosangy",
        fokontany: "ambalafary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9808206,
          lng: 48.2841556,
        },
        _id: "64f75285ef8f606073e997e9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marosangy",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.988948,
          lng: 48.3439498,
        },
        _id: "64f75285ef8f606073e997ea",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marosangy",
        fokontany: "ampatrivolo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0202711,
          lng: 48.254283,
        },
        _id: "64f75285ef8f606073e997eb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marosangy",
        fokontany: "marovato sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3320894,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e997ec",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohimiarina II",
        fokontany: "ambohimiarina II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3260423,
          lng: 47.9713115,
        },
        _id: "64f75285ef8f606073e997ed",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohimiarina II",
        fokontany: "sahasondroka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3221455,
          lng: 48.00598249999999,
        },
        _id: "64f75285ef8f606073e997ee",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohimiarina II",
        fokontany: "manakana tsiangaly",
        __v: 0,
      },
      {
        coords: {
          lat: -21.337843,
          lng: 48.0258046,
        },
        _id: "64f75285ef8f606073e997ef",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohimiarina II",
        fokontany: "ambinanifasina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.392888,
          lng: 48.0084599,
        },
        _id: "64f75285ef8f606073e997f0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohimiarina II",
        fokontany: "ampasimazava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9000788,
          lng: 48.2891359,
        },
        _id: "64f75285ef8f606073e997f1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andranambolava",
        fokontany: "andranambolava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8773759,
          lng: 48.2941167,
        },
        _id: "64f75285ef8f606073e997f2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andranambolava",
        fokontany: "ampitabe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8878462,
          lng: 48.2642387,
        },
        _id: "64f75285ef8f606073e997f3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andranambolava",
        fokontany: "marohita",
        __v: 0,
      },
      {
        coords: {
          lat: -20.910932,
          lng: 48.24432909999999,
        },
        _id: "64f75285ef8f606073e997f4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andranambolava",
        fokontany: "ambohimiarina I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9248501,
          lng: 48.26672790000001,
        },
        _id: "64f75285ef8f606073e997f5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andranambolava",
        fokontany: "menagisy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9326489,
          lng: 48.2766859,
        },
        _id: "64f75285ef8f606073e997f6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andranambolava",
        fokontany: "marovany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8925594,
          lng: 48.3289951,
        },
        _id: "64f75285ef8f606073e997f7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andranambolava",
        fokontany: "ambodivoangy nord",
        __v: 0,
      },
      {
        coords: {
          lat: -21.090151,
          lng: 48.0172389,
        },
        _id: "64f75285ef8f606073e997f8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "vohilava",
        fokontany: "vohilava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8572025,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e997f9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "vohilava",
        fokontany: "tanambao nord",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9090683,
          lng: 47.931715,
        },
        _id: "64f75285ef8f606073e997fa",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "vohilava",
        fokontany: "sandravakoka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8883652,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e997fb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "vohilava",
        fokontany: "ampasinambo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -21,
          lng: 47.966667,
        },
        _id: "64f75285ef8f606073e997fc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "vohilava",
        fokontany: "ambakoana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0100452,
          lng: 48.0109374,
        },
        _id: "64f75285ef8f606073e997fd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "vohilava",
        fokontany: "ampasimazava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0429874,
          lng: 47.9762632,
        },
        _id: "64f75285ef8f606073e997fe",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "vohilava",
        fokontany: "lavakianja",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0692523,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e997ff",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "vohilava",
        fokontany: "ambodihara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0519594,
          lng: 48.00598249999999,
        },
        _id: "64f75285ef8f606073e99800",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "vohilava",
        fokontany: "tanambaobe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0219856,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e99801",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "vohilava",
        fokontany: "ambalanomby I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0735712,
          lng: 48.045634,
        },
        _id: "64f75285ef8f606073e99802",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "vohilava",
        fokontany: "iambojiro",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1198159,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e99803",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "vohilava",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0991967,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e99804",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "vohilava",
        fokontany: "mahazava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1511132,
          lng: 48.03076129999999,
        },
        _id: "64f75285ef8f606073e99805",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "vohilava",
        fokontany: "ambalamanasa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.96954,
          lng: 48.44123099999999,
        },
        _id: "64f75285ef8f606073e99806",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahela",
        fokontany: "mahela",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9254784,
          lng: 48.4237772,
        },
        _id: "64f75285ef8f606073e99807",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahela",
        fokontany: "ambalahady",
        __v: 0,
      },
      {
        coords: {
          lat: -20.923745,
          lng: 48.36888339999999,
        },
        _id: "64f75285ef8f606073e99808",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahela",
        fokontany: "ambatosada",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9687959,
          lng: 48.3738715,
        },
        _id: "64f75285ef8f606073e99809",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahela",
        fokontany: "ampasimanjeva",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0223637,
          lng: 48.4237772,
        },
        _id: "64f75285ef8f606073e9980a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahela",
        fokontany: "anilavinany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9901328,
          lng: 48.4212808,
        },
        _id: "64f75285ef8f606073e9980b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahela",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0265098,
          lng: 48.3888384,
        },
        _id: "64f75285ef8f606073e9980c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahela",
        fokontany: "ambodimanga II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.865101,
          lng: 48.461479,
        },
        _id: "64f75285ef8f606073e9980d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohitsara est",
        fokontany: "ambohitsara est",
        __v: 0,
      },
      {
        coords: {
          lat: -20.82007,
          lng: 48.408199,
        },
        _id: "64f75285ef8f606073e9980e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohitsara est",
        fokontany: "ambodipaiso",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8458821,
          lng: 48.383849,
        },
        _id: "64f75285ef8f606073e9980f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohitsara est",
        fokontany: "manambatana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8614612,
          lng: 48.4038094,
        },
        _id: "64f75285ef8f606073e99810",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohitsara est",
        fokontany: "iamboala",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8858923,
          lng: 48.4537424,
        },
        _id: "64f75285ef8f606073e99811",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohitsara est",
        fokontany: "ampahatany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8768348,
          lng: 48.4312669,
        },
        _id: "64f75285ef8f606073e99812",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohitsara est",
        fokontany: "ampatakana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.916667,
          lng: 48.45,
        },
        _id: "64f75285ef8f606073e99813",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohitsara est",
        fokontany: "andranomahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8611877,
          lng: 48.2966073,
        },
        _id: "64f75285ef8f606073e99814",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahavoky nord",
        fokontany: "mahavoky avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8252428,
          lng: 48.31404449999999,
        },
        _id: "64f75285ef8f606073e99815",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahavoky nord",
        fokontany: "ambodiriana ifanantara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8357357,
          lng: 48.2841556,
        },
        _id: "64f75285ef8f606073e99816",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahavoky nord",
        fokontany: "lavakianja",
        __v: 0,
      },
      {
        coords: {
          lat: -20.843766,
          lng: 48.3439498,
        },
        _id: "64f75285ef8f606073e99817",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahavoky nord",
        fokontany: "mahatsara avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8631206,
          lng: 48.3439498,
        },
        _id: "64f75285ef8f606073e99818",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "mahavoky nord",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4753994,
          lng: 47.8921477,
        },
        _id: "64f75285ef8f606073e99819",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andonabe",
        fokontany: "andonabe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4563696,
          lng: 47.8773175,
        },
        _id: "64f75285ef8f606073e9981a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andonabe",
        fokontany: "ambodisaina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4654989,
          lng: 47.906982,
        },
        _id: "64f75285ef8f606073e9981b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andonabe",
        fokontany: "mahatsara II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4365652,
          lng: 47.906982,
        },
        _id: "64f75285ef8f606073e9981c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andonabe",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4507874,
          lng: 47.8279133,
        },
        _id: "64f75285ef8f606073e9981d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andonabe",
        fokontany: "ambatoringitra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4755265,
          lng: 47.8847321,
        },
        _id: "64f75285ef8f606073e9981e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andonabe",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4761546,
          lng: 47.84766949999999,
        },
        _id: "64f75285ef8f606073e9981f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andonabe",
        fokontany: "seranana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4873185,
          lng: 47.9465602,
        },
        _id: "64f75285ef8f606073e99820",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andonabe",
        fokontany: "androrangavola",
        __v: 0,
      },
      {
        coords: {
          lat: -21.485295,
          lng: 47.8773175,
        },
        _id: "64f75285ef8f606073e99821",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andonabe",
        fokontany: "tanambao tsimivatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5149529,
          lng: 47.8328517,
        },
        _id: "64f75285ef8f606073e99822",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andonabe",
        fokontany: "maholiafo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5007803,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e99823",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andonabe",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2169057,
          lng: 47.9614096,
        },
        _id: "64f75285ef8f606073e99824",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohinihaonana",
        fokontany: "ambohinihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.14162,
          lng: 47.872375,
        },
        _id: "64f75285ef8f606073e99825",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohinihaonana",
        fokontany: "ankalaitra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.168111,
          lng: 47.8328517,
        },
        _id: "64f75285ef8f606073e99826",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohinihaonana",
        fokontany: "maromandia ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2319377,
          lng: 47.8526097,
        },
        _id: "64f75285ef8f606073e99827",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohinihaonana",
        fokontany: "ambodimanga nosivolo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1991327,
          lng: 47.88720379999999,
        },
        _id: "64f75285ef8f606073e99828",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohinihaonana",
        fokontany: "ampasimadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1530263,
          lng: 47.9416114,
        },
        _id: "64f75285ef8f606073e99829",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohinihaonana",
        fokontany: "ambodivoangy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2158549,
          lng: 47.9341889,
        },
        _id: "64f75285ef8f606073e9982a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohinihaonana",
        fokontany: "ambohimiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2364982,
          lng: 47.9465602,
        },
        _id: "64f75285ef8f606073e9982b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohinihaonana",
        fokontany: "androrangavola",
        __v: 0,
      },
      {
        coords: {
          lat: -21.235894,
          lng: 47.9762632,
        },
        _id: "64f75285ef8f606073e9982c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohinihaonana",
        fokontany: "ankazotelo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2223114,
          lng: 48.0109374,
        },
        _id: "64f75285ef8f606073e9982d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohinihaonana",
        fokontany: "bemoka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.273886,
          lng: 48.00598249999999,
        },
        _id: "64f75285ef8f606073e9982e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohinihaonana",
        fokontany: "ambololona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2814097,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e9982f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohinihaonana",
        fokontany: "ambalapaiso",
        __v: 0,
      },
      {
        coords: {
          lat: -21.266667,
          lng: 47.883333,
        },
        _id: "64f75285ef8f606073e99830",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambohinihaonana",
        fokontany: "lavakianja",
        __v: 0,
      },
      {
        coords: {
          lat: -20.85273,
          lng: 48.154629,
        },
        _id: "64f75285ef8f606073e99831",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marofototra",
        fokontany: "marofototra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8297767,
          lng: 48.0208484,
        },
        _id: "64f75285ef8f606073e99832",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marofototra",
        fokontany: "antaviranambo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.825926,
          lng: 48.169733,
        },
        _id: "64f75285ef8f606073e99833",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marofototra",
        fokontany: "ambohitsara II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8140999,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e99834",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marofototra",
        fokontany: "madiotomotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8601718,
          lng: 48.0902768,
        },
        _id: "64f75285ef8f606073e99835",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marofototra",
        fokontany: "ampasimadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8558825,
          lng: 48.254283,
        },
        _id: "64f75285ef8f606073e99836",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marofototra",
        fokontany: "ambatoafo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8629912,
          lng: 48.2294017,
        },
        _id: "64f75285ef8f606073e99837",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "marofototra",
        fokontany: "ampasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9039607,
          lng: 48.1399232,
        },
        _id: "64f75285ef8f606073e99838",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andranomavo",
        fokontany: "andranomavo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8987767,
          lng: 48.0902768,
        },
        _id: "64f75285ef8f606073e99839",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andranomavo",
        fokontany: "ambohinihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.916207,
          lng: 48.1647635,
        },
        _id: "64f75285ef8f606073e9983a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andranomavo",
        fokontany: "maromitety",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8894176,
          lng: 48.2045317,
        },
        _id: "64f75285ef8f606073e9983b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andranomavo",
        fokontany: "ambalatany",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9299628,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e9983c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andranomavo",
        fokontany: "ambodiaviavy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9507404,
          lng: 48.070431,
        },
        _id: "64f75285ef8f606073e9983d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andranomavo",
        fokontany: "antapadrano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9622754,
          lng: 48.12502449999999,
        },
        _id: "64f75285ef8f606073e9983e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "andranomavo",
        fokontany: "vohilavakely",
        __v: 0,
      },
      {
        coords: {
          lat: -21.55,
          lng: 47.833333,
        },
        _id: "64f75285ef8f606073e9983f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "vatohandrina",
        fokontany: "vatohandrina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5203217,
          lng: 47.897092,
        },
        _id: "64f75285ef8f606073e99840",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "vatohandrina",
        fokontany: "faralambo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5375209,
          lng: 47.8279133,
        },
        _id: "64f75285ef8f606073e99841",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "vatohandrina",
        fokontany: "sahafananina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.55,
          lng: 47.833333,
        },
        _id: "64f75285ef8f606073e99842",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "vatohandrina",
        fokontany: "manarilefona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.566666,
          lng: 47.813101,
        },
        _id: "64f75285ef8f606073e99843",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "vatohandrina",
        fokontany: "mandroalina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2941447,
          lng: 47.8748462,
        },
        _id: "64f75285ef8f606073e99844",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambalahosy nord",
        fokontany: "ambalahosy avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.290026,
          lng: 47.8377905,
        },
        _id: "64f75285ef8f606073e99845",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambalahosy nord",
        fokontany: "nosivolo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3081806,
          lng: 47.897092,
        },
        _id: "64f75285ef8f606073e99846",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambalahosy nord",
        fokontany: "ambinanifitoerana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3274623,
          lng: 47.897092,
        },
        _id: "64f75285ef8f606073e99847",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambalahosy nord",
        fokontany: "ambodihasina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.323874,
          lng: 47.9168738,
        },
        _id: "64f75285ef8f606073e99848",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambalahosy nord",
        fokontany: "ambodibakoly",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9831499,
          lng: 47.889778,
        },
        _id: "64f75285ef8f606073e99849",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambodinonoka",
        fokontany: "ambodinonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8778959,
          lng: 47.8921477,
        },
        _id: "64f75285ef8f606073e9984a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambodinonoka",
        fokontany: "analamazava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8806452,
          lng: 47.7736217,
        },
        _id: "64f75285ef8f606073e9984b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambodinonoka",
        fokontany: "manakana nord",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9609151,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e9984c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambodinonoka",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.88593,
          lng: 47.8229754,
        },
        _id: "64f75285ef8f606073e9984d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambodinonoka",
        fokontany: "analamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9489758,
          lng: 47.872375,
        },
        _id: "64f75285ef8f606073e9984e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambodinonoka",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0064307,
          lng: 47.88720379999999,
        },
        _id: "64f75285ef8f606073e9984f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambodinonoka",
        fokontany: "tsarakianja sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0154031,
          lng: 47.9168738,
        },
        _id: "64f75285ef8f606073e99850",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambodinonoka",
        fokontany: "amboditambitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.03933,
          lng: 47.9242939,
        },
        _id: "64f75285ef8f606073e99851",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "ambodinonoka",
        fokontany: "ambodiara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0519216,
          lng: 47.8624914,
        },
        _id: "64f75285ef8f606073e99852",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antaretra",
        fokontany: "antaretra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0200344,
          lng: 47.8526097,
        },
        _id: "64f75285ef8f606073e99853",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antaretra",
        fokontany: "ambodivandrozana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0306207,
          lng: 47.8081645,
        },
        _id: "64f75285ef8f606073e99854",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antaretra",
        fokontany: "ambatomalama",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0323359,
          lng: 47.8773175,
        },
        _id: "64f75285ef8f606073e99855",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antaretra",
        fokontany: "ambalatenina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0654988,
          lng: 47.8279133,
        },
        _id: "64f75285ef8f606073e99856",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antaretra",
        fokontany: "antaviranambo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.066667,
          lng: 47.933333,
        },
        _id: "64f75285ef8f606073e99857",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antaretra",
        fokontany: "ambodivandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0943837,
          lng: 47.8279133,
        },
        _id: "64f75285ef8f606073e99858",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "antaretra",
        fokontany: "ambohitsara sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3802362,
          lng: 47.8668153,
        },
        _id: "64f75285ef8f606073e99859",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "kianjavato",
        fokontany: "kianjavato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3552728,
          lng: 47.95645940000001,
        },
        _id: "64f75285ef8f606073e9985a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "kianjavato",
        fokontany: "vohimpotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3825629,
          lng: 47.9589345,
        },
        _id: "64f75285ef8f606073e9985b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "kianjavato",
        fokontany: "ambodifandramanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3911078,
          lng: 47.931715,
        },
        _id: "64f75285ef8f606073e9985c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "kianjavato",
        fokontany: "ambolotara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3803554,
          lng: 47.9045093,
        },
        _id: "64f75285ef8f606073e9985d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "kianjavato",
        fokontany: "ambalahosy sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.411202,
          lng: 47.88720379999999,
        },
        _id: "64f75285ef8f606073e9985e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "kianjavato",
        fokontany: "ambodibonary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4345582,
          lng: 47.8377905,
        },
        _id: "64f75285ef8f606073e9985f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "kianjavato",
        fokontany: "ankosibe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3701393,
          lng: 47.84766949999999,
        },
        _id: "64f75285ef8f606073e99860",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "kianjavato",
        fokontany: "fotobohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3833386,
          lng: 47.8279133,
        },
        _id: "64f75285ef8f606073e99861",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "kianjavato",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.59506,
          lng: 47.8910709,
        },
        _id: "64f75285ef8f606073e99862",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "sandrohy",
        fokontany: "sandrohy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5891847,
          lng: 48.0109374,
        },
        _id: "64f75285ef8f606073e99863",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "sandrohy",
        fokontany: "ambalona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5779543,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e99864",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "sandrohy",
        fokontany: "sahanavaka II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6045355,
          lng: 47.8575503,
        },
        _id: "64f75285ef8f606073e99865",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "sandrohy",
        fokontany: "maroamboka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6264257,
          lng: 47.897092,
        },
        _id: "64f75285ef8f606073e99866",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "sandrohy",
        fokontany: "tanambao sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6163103,
          lng: 47.9267674,
        },
        _id: "64f75285ef8f606073e99867",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "sandrohy",
        fokontany: "sahanavaka I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6287837,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e99868",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "sandrohy",
        fokontany: "ambodimarohita",
        __v: 0,
      },
      {
        coords: {
          lat: -21.501301,
          lng: 47.984482,
        },
        _id: "64f75285ef8f606073e99869",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "anosimparihy",
        fokontany: "anosimparihy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4415712,
          lng: 47.9861678,
        },
        _id: "64f75285ef8f606073e9986a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "anosimparihy",
        fokontany: "andoharokana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4475062,
          lng: 48.01341499999999,
        },
        _id: "64f75285ef8f606073e9986b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "anosimparihy",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4528805,
          lng: 48.070431,
        },
        _id: "64f75285ef8f606073e9986c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "anosimparihy",
        fokontany: "vohibiavy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4833329,
          lng: 48.116667,
        },
        _id: "64f75285ef8f606073e9986d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "anosimparihy",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4639153,
          lng: 47.9960743,
        },
        _id: "64f75285ef8f606073e9986e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "anosimparihy",
        fokontany: "tsarafidy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4959202,
          lng: 48.00598249999999,
        },
        _id: "64f75285ef8f606073e9986f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "anosimparihy",
        fokontany: "maromanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.501301,
          lng: 47.984482,
        },
        _id: "64f75285ef8f606073e99870",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "anosimparihy",
        fokontany: "iaborano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5050437,
          lng: 48.0357184,
        },
        _id: "64f75285ef8f606073e99871",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "anosimparihy",
        fokontany: "ambodiamba",
        __v: 0,
      },
      {
        coords: {
          lat: -21.512482,
          lng: 47.9793582,
        },
        _id: "64f75285ef8f606073e99872",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "anosimparihy",
        fokontany: "bac namorona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5322669,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e99873",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "anosimparihy",
        fokontany: "ambodirafia",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5444587,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e99874",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "anosimparihy",
        fokontany: "ampotsimavo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5533416,
          lng: 48.0357184,
        },
        _id: "64f75285ef8f606073e99875",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "anosimparihy",
        fokontany: "nihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5169339,
          lng: 48.0902768,
        },
        _id: "64f75285ef8f606073e99876",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "anosimparihy",
        fokontany: "ambalamainty",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3219583,
          lng: 47.84766949999999,
        },
        _id: "64f75285ef8f606073e99877",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "manakana nord",
        fokontany: "manakana nord",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2779201,
          lng: 47.7982928,
        },
        _id: "64f75285ef8f606073e99878",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "manakana nord",
        fokontany: "androrangavola",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3161706,
          lng: 47.813101,
        },
        _id: "64f75285ef8f606073e99879",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "manakana nord",
        fokontany: "andranomadio",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3233357,
          lng: 47.86002080000001,
        },
        _id: "64f75285ef8f606073e9987a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "manakana nord",
        fokontany: "madiofasina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.347293,
          lng: 47.867433,
        },
        _id: "64f75285ef8f606073e9987b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "manakana nord",
        fokontany: "sakave",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3438523,
          lng: 47.8797889,
        },
        _id: "64f75285ef8f606073e9987c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "manakana nord",
        fokontany: "sahandovoka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3563863,
          lng: 47.897092,
        },
        _id: "64f75285ef8f606073e9987d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "manakana nord",
        fokontany: "ambohimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5992917,
          lng: 48.169733,
        },
        _id: "64f75285ef8f606073e9987e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "namorona",
        fokontany: "manotro",
        __v: 0,
      },
      {
        coords: {
          lat: -21.46386,
          lng: 48.169733,
        },
        _id: "64f75285ef8f606073e9987f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "namorona",
        fokontany: "fangato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5168408,
          lng: 48.2642387,
        },
        _id: "64f75285ef8f606073e99880",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "namorona",
        fokontany: "nakitaina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5290909,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e99881",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "namorona",
        fokontany: "sandranava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5467554,
          lng: 48.2194523,
        },
        _id: "64f75285ef8f606073e99882",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "namorona",
        fokontany: "antsakarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5898943,
          lng: 48.2418409,
        },
        _id: "64f75285ef8f606073e99883",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "namorona",
        fokontany: "ambinany namorona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6178319,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e99884",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "namorona",
        fokontany: "ambinany tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6263122,
          lng: 48.1002024,
        },
        _id: "64f75285ef8f606073e99885",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "namorona",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.654221,
          lng: 48.178452,
        },
        _id: "64f75285ef8f606073e99886",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "namorona",
        fokontany: "valanady",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6371921,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e99887",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "namorona",
        fokontany: "vohitrindry",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6794906,
          lng: 48.194587,
        },
        _id: "64f75285ef8f606073e99888",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "mananjary",
        commune: "namorona",
        fokontany: "masindranokely",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1411813,
          lng: 48.0090793,
        },
        _id: "64f75285ef8f606073e99889",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "manakara",
        fokontany: "andranovato",
        __v: 0,
      },
      {
        coords: {
          lat: -22.117342,
          lng: 48.01589269999999,
        },
        _id: "64f75285ef8f606073e9988a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "manakara",
        fokontany: "tanakidy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.130368,
          lng: 48.0035053,
        },
        _id: "64f75285ef8f606073e9988b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "manakara",
        fokontany: "mangarivotra ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1343002,
          lng: 48.0121761,
        },
        _id: "64f75285ef8f606073e9988c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "manakara",
        fokontany: "mangarivotra est",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1350373,
          lng: 48.0183705,
        },
        _id: "64f75285ef8f606073e9988d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "manakara",
        fokontany: "andriana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.13135,
          lng: 48.023946,
        },
        _id: "64f75285ef8f606073e9988e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "manakara",
        fokontany: "ampilao",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1384363,
          lng: 48.0022667,
        },
        _id: "64f75285ef8f606073e9988f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "manakara",
        fokontany: "midongikely",
        __v: 0,
      },
      {
        coords: {
          lat: -22.149859,
          lng: 47.9886442,
        },
        _id: "64f75285ef8f606073e99890",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "manakara",
        fokontany: "ambodiampaly",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1424905,
          lng: 47.9997896,
        },
        _id: "64f75285ef8f606073e99891",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "manakara",
        fokontany: "andranofasika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1460649,
          lng: 48.0044342,
        },
        _id: "64f75285ef8f606073e99892",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "manakara",
        fokontany: "ambalakazaha sud",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1444169,
          lng: 48.0078405,
        },
        _id: "64f75285ef8f606073e99893",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "manakara",
        fokontany: "ambalakazaha avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1423287,
          lng: 48.0146538,
        },
        _id: "64f75285ef8f606073e99894",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "manakara",
        fokontany: "ambalafary gara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1463024,
          lng: 48.0196094,
        },
        _id: "64f75285ef8f606073e99895",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "manakara",
        fokontany: "manakara be",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1520159,
          lng: 48.0127955,
        },
        _id: "64f75285ef8f606073e99896",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "manakara",
        fokontany: "andranodaro",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1488205,
          lng: 48.010318,
        },
        _id: "64f75285ef8f606073e99897",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "manakara",
        fokontany: "tanambao ombimena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1484714,
          lng: 48.0053632,
        },
        _id: "64f75285ef8f606073e99898",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "manakara",
        fokontany: "maroalakely",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1674732,
          lng: 47.99855119999999,
        },
        _id: "64f75285ef8f606073e99899",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "manakara",
        fokontany: "andranomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1786982,
          lng: 48.0035053,
        },
        _id: "64f75285ef8f606073e9989a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "manakara",
        fokontany: "vangaindranokely",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1419113,
          lng: 47.9787391,
        },
        _id: "64f75285ef8f606073e9989b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "tataho",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0917061,
          lng: 48.0035053,
        },
        _id: "64f75285ef8f606073e9989c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "tataho",
        fokontany: "masiakakoho",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1175903,
          lng: 47.9935975,
        },
        _id: "64f75285ef8f606073e9989d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "tataho",
        fokontany: "mahatsinjoriaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1258595,
          lng: 47.9737873,
        },
        _id: "64f75285ef8f606073e9989e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "tataho",
        fokontany: "andromba",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1862,
          lng: 47.897092,
        },
        _id: "64f75285ef8f606073e9989f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "tataho",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1502486,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e998a0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "tataho",
        fokontany: "vohidranahy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1853214,
          lng: 47.9861678,
        },
        _id: "64f75285ef8f606073e998a1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "tataho",
        fokontany: "analabe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2049453,
          lng: 47.95645940000001,
        },
        _id: "64f75285ef8f606073e998a2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mangatsiotra",
        fokontany: "ambohimahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1955733,
          lng: 47.9267674,
        },
        _id: "64f75285ef8f606073e998a3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mangatsiotra",
        fokontany: "valovahy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2305204,
          lng: 47.9762632,
        },
        _id: "64f75285ef8f606073e998a4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mangatsiotra",
        fokontany: "antsary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2501262,
          lng: 47.9465602,
        },
        _id: "64f75285ef8f606073e998a5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mangatsiotra",
        fokontany: "andringotra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.24686,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e998a6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mangatsiotra",
        fokontany: "mangatsiotra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0343314,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e998a7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "marofarihy",
        fokontany: "marofarihy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0216547,
          lng: 47.9341889,
        },
        _id: "64f75285ef8f606073e998a8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "marofarihy",
        fokontany: "ambotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0230336,
          lng: 47.9539844,
        },
        _id: "64f75285ef8f606073e998a9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "marofarihy",
        fokontany: "mideboka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0534797,
          lng: 47.9663604,
        },
        _id: "64f75285ef8f606073e998aa",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "marofarihy",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0474351,
          lng: 47.931715,
        },
        _id: "64f75285ef8f606073e998ab",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "marofarihy",
        fokontany: "alakamisy anivosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0529582,
          lng: 48.0109374,
        },
        _id: "64f75285ef8f606073e998ac",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anosiala",
        fokontany: "nosiala centre",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0215777,
          lng: 48.0729113,
        },
        _id: "64f75285ef8f606073e998ad",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anosiala",
        fokontany: "ambinany mananano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0848885,
          lng: 48.0357184,
        },
        _id: "64f75285ef8f606073e998ae",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anosiala",
        fokontany: "anosy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.067716,
          lng: 47.9886442,
        },
        _id: "64f75285ef8f606073e998af",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anosiala",
        fokontany: "ankazoharaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0952345,
          lng: 47.9762632,
        },
        _id: "64f75285ef8f606073e998b0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anosiala",
        fokontany: "epetraka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0890132,
          lng: 47.95645940000001,
        },
        _id: "64f75285ef8f606073e998b1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anosiala",
        fokontany: "tanjongato",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0023899,
          lng: 47.97067999999999,
        },
        _id: "64f75285ef8f606073e998b2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambila",
        fokontany: "ambila",
        __v: 0,
      },
      {
        coords: {
          lat: -21.939476,
          lng: 48.0654706,
        },
        _id: "64f75285ef8f606073e998b3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambila",
        fokontany: "ambarolava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9246171,
          lng: 47.9713115,
        },
        _id: "64f75285ef8f606073e998b4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambila",
        fokontany: "andriana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.95319,
          lng: 48.097099,
        },
        _id: "64f75285ef8f606073e998b5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambila",
        fokontany: "loharano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9402685,
          lng: 48.00598249999999,
        },
        _id: "64f75285ef8f606073e998b6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambila",
        fokontany: "mandrosovelo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9734611,
          lng: 47.9267674,
        },
        _id: "64f75285ef8f606073e998b7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambila",
        fokontany: "beretra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.972354,
          lng: 48.01589269999999,
        },
        _id: "64f75285ef8f606073e998b8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambila",
        fokontany: "vohipanany",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9792952,
          lng: 47.9762632,
        },
        _id: "64f75285ef8f606073e998b9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambila",
        fokontany: "vohilava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0013491,
          lng: 48.01589269999999,
        },
        _id: "64f75285ef8f606073e998ba",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambila",
        fokontany: "beanana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0041775,
          lng: 47.9824533,
        },
        _id: "64f75285ef8f606073e998bb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambila",
        fokontany: "tamboro ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0097835,
          lng: 47.9849296,
        },
        _id: "64f75285ef8f606073e998bc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambila",
        fokontany: "tamboro est",
        __v: 0,
      },
      {
        coords: {
          lat: -22.133333,
          lng: 47.866667,
        },
        _id: "64f75285ef8f606073e998bd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sorombo",
        fokontany: "ambodinato",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0611656,
          lng: 47.8526097,
        },
        _id: "64f75285ef8f606073e998be",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sorombo",
        fokontany: "ankarimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1092639,
          lng: 47.867433,
        },
        _id: "64f75285ef8f606073e998bf",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sorombo",
        fokontany: "manjarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0678522,
          lng: 47.8279133,
        },
        _id: "64f75285ef8f606073e998c0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sorombo",
        fokontany: "nosivelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1089616,
          lng: 47.897092,
        },
        _id: "64f75285ef8f606073e998c1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sorombo",
        fokontany: "ampasina",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0862247,
          lng: 47.9168738,
        },
        _id: "64f75285ef8f606073e998c2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sorombo",
        fokontany: "sandrarafa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.096865,
          lng: 47.8205067,
        },
        _id: "64f75285ef8f606073e998c3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sorombo",
        fokontany: "belongoza",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1214247,
          lng: 47.93666289999999,
        },
        _id: "64f75285ef8f606073e998c4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sorombo",
        fokontany: "maroala",
        __v: 0,
      },
      {
        coords: {
          lat: -22.116667,
          lng: 47.8,
        },
        _id: "64f75285ef8f606073e998c5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sorombo",
        fokontany: "maroantova",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1307658,
          lng: 47.8056964,
        },
        _id: "64f75285ef8f606073e998c6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sorombo",
        fokontany: "ampitabao",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1410443,
          lng: 47.906982,
        },
        _id: "64f75285ef8f606073e998c7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sorombo",
        fokontany: "ambolosy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1450281,
          lng: 47.8279133,
        },
        _id: "64f75285ef8f606073e998c8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sorombo",
        fokontany: "sahalapa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1658182,
          lng: 47.84026009999999,
        },
        _id: "64f75285ef8f606073e998c9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sorombo",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1548786,
          lng: 47.8056964,
        },
        _id: "64f75285ef8f606073e998ca",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sorombo",
        fokontany: "ampitabe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1693467,
          lng: 47.8056964,
        },
        _id: "64f75285ef8f606073e998cb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sorombo",
        fokontany: "ampataka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.183351,
          lng: 47.8575503,
        },
        _id: "64f75285ef8f606073e998cc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sorombo",
        fokontany: "andranombary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9933885,
          lng: 47.95380189999999,
        },
        _id: "64f75285ef8f606073e998cd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mizilo gara",
        fokontany: "mizilo gara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.933333,
          lng: 47.883333,
        },
        _id: "64f75285ef8f606073e998ce",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mizilo gara",
        fokontany: "ambinanindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9452131,
          lng: 47.867433,
        },
        _id: "64f75285ef8f606073e998cf",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mizilo gara",
        fokontany: "andrafia",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9352061,
          lng: 47.897092,
        },
        _id: "64f75285ef8f606073e998d0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mizilo gara",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9640738,
          lng: 47.9045093,
        },
        _id: "64f75285ef8f606073e998d1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mizilo gara",
        fokontany: "analamiditra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8495414,
          lng: 47.8200233,
        },
        _id: "64f75285ef8f606073e998d2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambohitsara m",
        fokontany: "ambohitsara M",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8618454,
          lng: 47.84766949999999,
        },
        _id: "64f75285ef8f606073e998d3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambohitsara m",
        fokontany: "kianjanomby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8880468,
          lng: 47.8081645,
        },
        _id: "64f75285ef8f606073e998d4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambohitsara m",
        fokontany: "ampasimpotsy M",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9067996,
          lng: 47.8526097,
        },
        _id: "64f75285ef8f606073e998d5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambohitsara m",
        fokontany: "vohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9359148,
          lng: 47.8377905,
        },
        _id: "64f75285ef8f606073e998d6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambohitsara m",
        fokontany: "sahanento",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9393586,
          lng: 47.81803800000001,
        },
        _id: "64f75285ef8f606073e998d7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambohitsara m",
        fokontany: "vohilava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9586461,
          lng: 47.81803800000001,
        },
        _id: "64f75285ef8f606073e998d8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambohitsara m",
        fokontany: "ambodiharamy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9748287,
          lng: 47.8081645,
        },
        _id: "64f75285ef8f606073e998d9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambohitsara m",
        fokontany: "ambodivandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8074004,
          lng: 47.8316171,
        },
        _id: "64f75285ef8f606073e998da",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sahasinaka",
        fokontany: "ville basse",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7592378,
          lng: 47.8279133,
        },
        _id: "64f75285ef8f606073e998db",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sahasinaka",
        fokontany: "analabe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7898035,
          lng: 47.8254443,
        },
        _id: "64f75285ef8f606073e998dc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sahasinaka",
        fokontany: "ambodilaza",
        __v: 0,
      },
      {
        coords: {
          lat: -21.794429,
          lng: 47.84026009999999,
        },
        _id: "64f75285ef8f606073e998dd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sahasinaka",
        fokontany: "vatolambo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8140045,
          lng: 47.81803800000001,
        },
        _id: "64f75285ef8f606073e998de",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sahasinaka",
        fokontany: "tsimanimanina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.811411,
          lng: 47.835468,
        },
        _id: "64f75285ef8f606073e998df",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sahasinaka",
        fokontany: "ambodiramiavona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8349262,
          lng: 47.8155695,
        },
        _id: "64f75285ef8f606073e998e0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sahasinaka",
        fokontany: "vangaindranokely",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8362828,
          lng: 47.835321,
        },
        _id: "64f75285ef8f606073e998e1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sahasinaka",
        fokontany: "ambodivoangy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7926902,
          lng: 47.8501395,
        },
        _id: "64f75285ef8f606073e998e2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sahasinaka",
        fokontany: "maroandronana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8038769,
          lng: 47.8550799,
        },
        _id: "64f75285ef8f606073e998e3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sahasinaka",
        fokontany: "sahavoatsilana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8312701,
          lng: 47.8501395,
        },
        _id: "64f75285ef8f606073e998e4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sahasinaka",
        fokontany: "ampiringalava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.816705,
          lng: 47.8575503,
        },
        _id: "64f75285ef8f606073e998e5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sahasinaka",
        fokontany: "ambodibonara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8691204,
          lng: 47.778555,
        },
        _id: "64f75285ef8f606073e998e6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sahanambohitra",
        fokontany: "sahanambohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8463895,
          lng: 47.7982928,
        },
        _id: "64f75285ef8f606073e998e7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sahanambohitra",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8756626,
          lng: 47.7686889,
        },
        _id: "64f75285ef8f606073e998e8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sahanambohitra",
        fokontany: "ambodiharamy II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8625761,
          lng: 47.78842299999999,
        },
        _id: "64f75285ef8f606073e998e9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sahanambohitra",
        fokontany: "ambohimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -21.866667,
          lng: 47.95,
        },
        _id: "64f75285ef8f606073e998ea",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambahatrazo",
        fokontany: "ambahatrazo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8711172,
          lng: 47.8773175,
        },
        _id: "64f75285ef8f606073e998eb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambahatrazo",
        fokontany: "ambodifaliambo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8576052,
          lng: 47.9267674,
        },
        _id: "64f75285ef8f606073e998ec",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambahatrazo",
        fokontany: "vohitrandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.866667,
          lng: 47.95,
        },
        _id: "64f75285ef8f606073e998ed",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambahatrazo",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8405616,
          lng: 47.9960743,
        },
        _id: "64f75285ef8f606073e998ee",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambahatrazo",
        fokontany: "ambalavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0160299,
          lng: 47.8624914,
        },
        _id: "64f75285ef8f606073e998ef",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "amboanjo",
        fokontany: "amboanjo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.958308,
          lng: 47.84766949999999,
        },
        _id: "64f75285ef8f606073e998f0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "amboanjo",
        fokontany: "betsako",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9838097,
          lng: 47.867433,
        },
        _id: "64f75285ef8f606073e998f1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "amboanjo",
        fokontany: "ankatafana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0322989,
          lng: 47.8451996,
        },
        _id: "64f75285ef8f606073e998f2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "amboanjo",
        fokontany: "vohitravoha",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0155305,
          lng: 47.906982,
        },
        _id: "64f75285ef8f606073e998f3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "amboanjo",
        fokontany: "mahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0196215,
          lng: 47.8279133,
        },
        _id: "64f75285ef8f606073e998f4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "amboanjo",
        fokontany: "mitenopika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0224899,
          lng: 47.86002080000001,
        },
        _id: "64f75285ef8f606073e998f5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "amboanjo",
        fokontany: "vohitsivalana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.993791,
          lng: 47.8377905,
        },
        _id: "64f75285ef8f606073e998f6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "amboanjo",
        fokontany: "androrangavola",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0037585,
          lng: 47.8081645,
        },
        _id: "64f75285ef8f606073e998f7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "amboanjo",
        fokontany: "ambodivoamboana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0109565,
          lng: 47.8847321,
        },
        _id: "64f75285ef8f606073e998f8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "amboanjo",
        fokontany: "mizilo",
        __v: 0,
      },
      {
        coords: {
          lat: -22,
          lng: 47.883333,
        },
        _id: "64f75285ef8f606073e998f9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "amboanjo",
        fokontany: "vohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0302601,
          lng: 47.8847321,
        },
        _id: "64f75285ef8f606073e998fa",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "amboanjo",
        fokontany: "ifaho",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7,
          lng: 47.883333,
        },
        _id: "64f75285ef8f606073e998fb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anorombato",
        fokontany: "anorombato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9975406,
          lng: 47.78842299999999,
        },
        _id: "64f75285ef8f606073e998fc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anorombato",
        fokontany: "analavory",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0200118,
          lng: 47.7908903,
        },
        _id: "64f75285ef8f606073e998fd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anorombato",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0312101,
          lng: 47.7958252,
        },
        _id: "64f75285ef8f606073e998fe",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anorombato",
        fokontany: "malazamasy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.036368,
          lng: 47.7625235,
        },
        _id: "64f75285ef8f606073e998ff",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anorombato",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0425599,
          lng: 47.7859558,
        },
        _id: "64f75285ef8f606073e99900",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anorombato",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0491596,
          lng: 47.7686889,
        },
        _id: "64f75285ef8f606073e99901",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anorombato",
        fokontany: "mahavelo II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1050935,
          lng: 47.8007606,
        },
        _id: "64f75285ef8f606073e99902",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasy",
        fokontany: "vohimasy sud",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0520528,
          lng: 47.8007606,
        },
        _id: "64f75285ef8f606073e99903",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasy",
        fokontany: "vohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0618436,
          lng: 47.7859558,
        },
        _id: "64f75285ef8f606073e99904",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasy",
        fokontany: "anivotsara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0686522,
          lng: 47.74649669999999,
        },
        _id: "64f75285ef8f606073e99905",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasy",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0685111,
          lng: 47.7612905,
        },
        _id: "64f75285ef8f606073e99906",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasy",
        fokontany: "ambohimiary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0810559,
          lng: 47.7933577,
        },
        _id: "64f75285ef8f606073e99907",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasy",
        fokontany: "vohimasy nord",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0942398,
          lng: 47.7588245,
        },
        _id: "64f75285ef8f606073e99908",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasy",
        fokontany: "ambodivoahangy nord",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1151481,
          lng: 47.7563588,
        },
        _id: "64f75285ef8f606073e99909",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasy",
        fokontany: "ambodivoahangy sud",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1101927,
          lng: 47.7711552,
        },
        _id: "64f75285ef8f606073e9990a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasy",
        fokontany: "ankarimbary I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1084941,
          lng: 47.7810219,
        },
        _id: "64f75285ef8f606073e9990b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasy",
        fokontany: "marofinaritra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.18647,
          lng: 47.74885200000001,
        },
        _id: "64f75285ef8f606073e9990c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "lokomby",
        fokontany: "lokomby",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1486925,
          lng: 47.778555,
        },
        _id: "64f75285ef8f606073e9990d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "lokomby",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1681677,
          lng: 47.7563588,
        },
        _id: "64f75285ef8f606073e9990e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "lokomby",
        fokontany: "vohibazimba",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1698883,
          lng: 47.7427989,
        },
        _id: "64f75285ef8f606073e9990f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "lokomby",
        fokontany: "mahavelo sirana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1903937,
          lng: 47.78842299999999,
        },
        _id: "64f75285ef8f606073e99910",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "lokomby",
        fokontany: "fandova",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2084154,
          lng: 47.74649669999999,
        },
        _id: "64f75285ef8f606073e99911",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "lokomby",
        fokontany: "mitanty est",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2173002,
          lng: 47.7403339,
        },
        _id: "64f75285ef8f606073e99912",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "lokomby",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2357096,
          lng: 47.7489621,
        },
        _id: "64f75285ef8f606073e99913",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vatana",
        fokontany: "vatana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2229611,
          lng: 47.7354041,
        },
        _id: "64f75285ef8f606073e99914",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vatana",
        fokontany: "nato",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2212782,
          lng: 47.7452641,
        },
        _id: "64f75285ef8f606073e99915",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vatana",
        fokontany: "fotatra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2292059,
          lng: 47.7588245,
        },
        _id: "64f75285ef8f606073e99916",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vatana",
        fokontany: "andranomavo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2485449,
          lng: 47.7514275,
        },
        _id: "64f75285ef8f606073e99917",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vatana",
        fokontany: "mandritsara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2462183,
          lng: 47.7403339,
        },
        _id: "64f75285ef8f606073e99918",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vatana",
        fokontany: "ankaramalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2110613,
          lng: 47.8155695,
        },
        _id: "64f75285ef8f606073e99919",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sakoana",
        fokontany: "mahavelo sakoana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.190138,
          lng: 47.81803800000001,
        },
        _id: "64f75285ef8f606073e9991a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sakoana",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1835528,
          lng: 47.835321,
        },
        _id: "64f75285ef8f606073e9991b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sakoana",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2193261,
          lng: 47.78842299999999,
        },
        _id: "64f75285ef8f606073e9991c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sakoana",
        fokontany: "ampitsinjovaninena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2173242,
          lng: 47.835321,
        },
        _id: "64f75285ef8f606073e9991d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sakoana",
        fokontany: "malazamasy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2154344,
          lng: 47.867433,
        },
        _id: "64f75285ef8f606073e9991e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sakoana",
        fokontany: "ambatomahavano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2367464,
          lng: 47.8205067,
        },
        _id: "64f75285ef8f606073e9991f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sakoana",
        fokontany: "ranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2401234,
          lng: 47.8007606,
        },
        _id: "64f75285ef8f606073e99920",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "sakoana",
        fokontany: "manasara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.181154,
          lng: 47.7403339,
        },
        _id: "64f75285ef8f606073e99921",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambahive",
        fokontany: "nanarena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1720768,
          lng: 47.67012829999999,
        },
        _id: "64f75285ef8f606073e99922",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambahive",
        fokontany: "mitsirintona",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1812444,
          lng: 47.7292426,
        },
        _id: "64f75285ef8f606073e99923",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambahive",
        fokontany: "mitanty ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1907928,
          lng: 47.7403339,
        },
        _id: "64f75285ef8f606073e99924",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambahive",
        fokontany: "ambalatenina",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1948288,
          lng: 47.737869,
        },
        _id: "64f75285ef8f606073e99925",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambahive",
        fokontany: "tazokondrobe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1924785,
          lng: 47.7304748,
        },
        _id: "64f75285ef8f606073e99926",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambahive",
        fokontany: "iabazaza",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1879793,
          lng: 47.6898257,
        },
        _id: "64f75285ef8f606073e99927",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambahive",
        fokontany: "vohiboatavo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.194192,
          lng: 47.7169217,
        },
        _id: "64f75285ef8f606073e99928",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambahive",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2231574,
          lng: 47.7095305,
        },
        _id: "64f75285ef8f606073e99929",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambandrika",
        fokontany: "ambandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1334133,
          lng: 47.732326,
        },
        _id: "64f75285ef8f606073e9992a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambalaroka",
        fokontany: "ambalaroka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1392475,
          lng: 47.7563588,
        },
        _id: "64f75285ef8f606073e9992b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambalaroka",
        fokontany: "vohitrakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1399932,
          lng: 47.6676666,
        },
        _id: "64f75285ef8f606073e9992c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambalaroka",
        fokontany: "voditanjo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1461949,
          lng: 47.6947512,
        },
        _id: "64f75285ef8f606073e9992d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambalaroka",
        fokontany: "farafasy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1508292,
          lng: 47.7169217,
        },
        _id: "64f75285ef8f606073e9992e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambalaroka",
        fokontany: "vohitrapanga",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1652832,
          lng: 47.7169217,
        },
        _id: "64f75285ef8f606073e9992f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambalaroka",
        fokontany: "ankaranila",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1458233,
          lng: 47.7391014,
        },
        _id: "64f75285ef8f606073e99930",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambalaroka",
        fokontany: "ankarimbelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1603272,
          lng: 47.7335556,
        },
        _id: "64f75285ef8f606073e99931",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambalaroka",
        fokontany: "ambohitrova",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1642864,
          lng: 47.7403339,
        },
        _id: "64f75285ef8f606073e99932",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambalaroka",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7012633,
          lng: 47.6998566,
        },
        _id: "64f75285ef8f606073e99933",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimanitra",
        fokontany: "vohimanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6864282,
          lng: 47.7489621,
        },
        _id: "64f75285ef8f606073e99934",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimanitra",
        fokontany: "ankazoambo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7012633,
          lng: 47.6998566,
        },
        _id: "64f75285ef8f606073e99935",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimanitra",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6964581,
          lng: 47.7193856,
        },
        _id: "64f75285ef8f606073e99936",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimanitra",
        fokontany: "amparihibe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7127324,
          lng: 47.7021403,
        },
        _id: "64f75285ef8f606073e99937",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimanitra",
        fokontany: "mahasoa II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6796485,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e99938",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mitanty",
        fokontany: "mitanty",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6473775,
          lng: 47.9960743,
        },
        _id: "64f75285ef8f606073e99939",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mitanty",
        fokontany: "antanjondava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6794132,
          lng: 48.00598249999999,
        },
        _id: "64f75285ef8f606073e9993a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mitanty",
        fokontany: "tsararano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6845939,
          lng: 47.98369150000001,
        },
        _id: "64f75285ef8f606073e9993b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mitanty",
        fokontany: "betampona II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6990794,
          lng: 47.98369150000001,
        },
        _id: "64f75285ef8f606073e9993c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mitanty",
        fokontany: "mideboka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6987319,
          lng: 48.00598249999999,
        },
        _id: "64f75285ef8f606073e9993d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mitanty",
        fokontany: "manevina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7199279,
          lng: 47.9886442,
        },
        _id: "64f75285ef8f606073e9993e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mitanty",
        fokontany: "ankarimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6867021,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e9993f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "analavory",
        fokontany: "analavory",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6784657,
          lng: 47.9638849,
        },
        _id: "64f75285ef8f606073e99940",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "analavory",
        fokontany: "ambodivoangy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6711461,
          lng: 47.9168738,
        },
        _id: "64f75285ef8f606073e99941",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "analavory",
        fokontany: "antavibe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7316802,
          lng: 47.95645940000001,
        },
        _id: "64f75285ef8f606073e99942",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "analavory",
        fokontany: "satramaha",
        __v: 0,
      },
      {
        coords: {
          lat: -22.10437,
          lng: 47.71896,
        },
        _id: "64f75285ef8f606073e99943",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "bekatra",
        fokontany: "bekatra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0848069,
          lng: 47.7366365,
        },
        _id: "64f75285ef8f606073e99944",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "bekatra",
        fokontany: "nihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0723861,
          lng: 47.6898257,
        },
        _id: "64f75285ef8f606073e99945",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "bekatra",
        fokontany: "ambalafary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.066667,
          lng: 47.6666669,
        },
        _id: "64f75285ef8f606073e99946",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "bekatra",
        fokontany: "andrairay",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1046612,
          lng: 47.67012829999999,
        },
        _id: "64f75285ef8f606073e99947",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "bekatra",
        fokontany: "mahavelokely",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1044083,
          lng: 47.6996772,
        },
        _id: "64f75285ef8f606073e99948",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "bekatra",
        fokontany: "ambinanindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1169094,
          lng: 47.7391014,
        },
        _id: "64f75285ef8f606073e99949",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "bekatra",
        fokontany: "soamiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1220489,
          lng: 47.7021403,
        },
        _id: "64f75285ef8f606073e9994a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "bekatra",
        fokontany: "marokoriaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1283352,
          lng: 47.7181536,
        },
        _id: "64f75285ef8f606073e9994b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "bekatra",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1297383,
          lng: 47.7415664,
        },
        _id: "64f75285ef8f606073e9994c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "bekatra",
        fokontany: "seranatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.016667,
          lng: 47.73333299999999,
        },
        _id: "64f75285ef8f606073e9994d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vinanitelo",
        fokontany: "vinanitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0425599,
          lng: 47.7859558,
        },
        _id: "64f75285ef8f606073e9994e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vinanitelo",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0171275,
          lng: 47.7588245,
        },
        _id: "64f75285ef8f606073e9994f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vinanitelo",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0143095,
          lng: 47.7193856,
        },
        _id: "64f75285ef8f606073e99950",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vinanitelo",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0301016,
          lng: 47.74649669999999,
        },
        _id: "64f75285ef8f606073e99951",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vinanitelo",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0463306,
          lng: 47.7292426,
        },
        _id: "64f75285ef8f606073e99952",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vinanitelo",
        fokontany: "marolanana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0624826,
          lng: 47.7193856,
        },
        _id: "64f75285ef8f606073e99953",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vinanitelo",
        fokontany: "ambodiara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.740959,
          lng: 48.04199999999999,
        },
        _id: "64f75285ef8f606073e99954",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ampasimanjeva",
        fokontany: "ampasimanjeva",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6631552,
          lng: 48.01589269999999,
        },
        _id: "64f75285ef8f606073e99955",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ampasimanjeva",
        fokontany: "andramora",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6499508,
          lng: 48.0357184,
        },
        _id: "64f75285ef8f606073e99956",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ampasimanjeva",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6657293,
          lng: 48.0555514,
        },
        _id: "64f75285ef8f606073e99957",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ampasimanjeva",
        fokontany: "ankazondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.714052,
          lng: 48.0555514,
        },
        _id: "64f75285ef8f606073e99958",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ampasimanjeva",
        fokontany: "sarandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7225351,
          lng: 48.0282829,
        },
        _id: "64f75285ef8f606073e99959",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ampasimanjeva",
        fokontany: "taimby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7404399,
          lng: 48.01589269999999,
        },
        _id: "64f75285ef8f606073e9995a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ampasimanjeva",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7516093,
          lng: 48.0484443,
        },
        _id: "64f75285ef8f606073e9995b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambotaka",
        fokontany: "ambotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7497598,
          lng: 48.0381971,
        },
        _id: "64f75285ef8f606073e9995c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambotaka",
        fokontany: "maronosy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7660168,
          lng: 48.0282829,
        },
        _id: "64f75285ef8f606073e9995d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambotaka",
        fokontany: "tsiananara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7823803,
          lng: 48.0109374,
        },
        _id: "64f75285ef8f606073e9995e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambotaka",
        fokontany: "ankarimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7637983,
          lng: 48.0679507,
        },
        _id: "64f75285ef8f606073e9995f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambotaka",
        fokontany: "mahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0848069,
          lng: 47.7366365,
        },
        _id: "64f75285ef8f606073e99960",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "nihaonana",
        fokontany: "nihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7394677,
          lng: 47.9737873,
        },
        _id: "64f75285ef8f606073e99961",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "nihaonana",
        fokontany: "tarabe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8025566,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e99962",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mavorano",
        fokontany: "mavorano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7671183,
          lng: 47.9539844,
        },
        _id: "64f75285ef8f606073e99963",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mavorano",
        fokontany: "volafotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7732675,
          lng: 47.9737873,
        },
        _id: "64f75285ef8f606073e99964",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mavorano",
        fokontany: "ampasimahanoro",
        __v: 0,
      },
      {
        coords: {
          lat: -21.762506,
          lng: 47.9391371,
        },
        _id: "64f75285ef8f606073e99965",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mavorano",
        fokontany: "vohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7771975,
          lng: 47.9242939,
        },
        _id: "64f75285ef8f606073e99966",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mavorano",
        fokontany: "vohitrevo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7767736,
          lng: 47.9539844,
        },
        _id: "64f75285ef8f606073e99967",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mavorano",
        fokontany: "isoto",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7926893,
          lng: 47.9663604,
        },
        _id: "64f75285ef8f606073e99968",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mavorano",
        fokontany: "ankarimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8031761,
          lng: 47.906982,
        },
        _id: "64f75285ef8f606073e99969",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mavorano",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8124191,
          lng: 47.93666289999999,
        },
        _id: "64f75285ef8f606073e9996a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mavorano",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8150825,
          lng: 47.9762632,
        },
        _id: "64f75285ef8f606073e9996b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mavorano",
        fokontany: "marohana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7482298,
          lng: 48.1349565,
        },
        _id: "64f75285ef8f606073e9996c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasina nord",
        fokontany: "seranambary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7583802,
          lng: 48.1051659,
        },
        _id: "64f75285ef8f606073e9996d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasina nord",
        fokontany: "masindrota",
        __v: 0,
      },
      {
        coords: {
          lat: -21.780732,
          lng: 48.1188179,
        },
        _id: "64f75285ef8f606073e9996e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasina nord",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7869265,
          lng: 48.1349565,
        },
        _id: "64f75285ef8f606073e9996f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasina nord",
        fokontany: "manjarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7541121,
          lng: 48.169733,
        },
        _id: "64f75285ef8f606073e99970",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasina nord",
        fokontany: "andotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7921136,
          lng: 48.112612,
        },
        _id: "64f75285ef8f606073e99971",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasina sud",
        fokontany: "mangaika",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8008191,
          lng: 48.070431,
        },
        _id: "64f75285ef8f606073e99972",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasina sud",
        fokontany: "vatomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8260803,
          lng: 48.1051659,
        },
        _id: "64f75285ef8f606073e99973",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasina sud",
        fokontany: "ambato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8107635,
          lng: 48.1573102,
        },
        _id: "64f75285ef8f606073e99974",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasina sud",
        fokontany: "ambinany",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8579514,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e99975",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasina sud",
        fokontany: "marofototra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.919723,
          lng: 48.0952394,
        },
        _id: "64f75285ef8f606073e99976",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasina sud",
        fokontany: "ambohitrabo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8847543,
          lng: 48.0605108,
        },
        _id: "64f75285ef8f606073e99977",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohimasina sud",
        fokontany: "tsararano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7296082,
          lng: 48.0902768,
        },
        _id: "64f75285ef8f606073e99978",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "betampona",
        fokontany: "betampona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7227596,
          lng: 48.1150943,
        },
        _id: "64f75285ef8f606073e99979",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "betampona",
        fokontany: "aborano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.736448,
          lng: 48.0654706,
        },
        _id: "64f75285ef8f606073e9997a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "betampona",
        fokontany: "ankarimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7807957,
          lng: 47.897092,
        },
        _id: "64f75285ef8f606073e9997b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohilava",
        fokontany: "vohilava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7417711,
          lng: 47.9267674,
        },
        _id: "64f75285ef8f606073e9997c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohilava",
        fokontany: "seranambary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7652907,
          lng: 47.9131641,
        },
        _id: "64f75285ef8f606073e9997d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohilava",
        fokontany: "ankarimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7807957,
          lng: 47.897092,
        },
        _id: "64f75285ef8f606073e9997e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohilava",
        fokontany: "nihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7970134,
          lng: 47.88720379999999,
        },
        _id: "64f75285ef8f606073e9997f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohilava",
        fokontany: "sahasondry",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7908501,
          lng: 47.867433,
        },
        _id: "64f75285ef8f606073e99980",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohilava",
        fokontany: "ambalatenina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8228752,
          lng: 47.8773175,
        },
        _id: "64f75285ef8f606073e99981",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "vohilava",
        fokontany: "sahakevo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7681243,
          lng: 47.773579,
        },
        _id: "64f75285ef8f606073e99982",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "fenomby",
        fokontany: "fenomby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7342975,
          lng: 47.7711552,
        },
        _id: "64f75285ef8f606073e99983",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "fenomby",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7340992,
          lng: 47.7859558,
        },
        _id: "64f75285ef8f606073e99984",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "fenomby",
        fokontany: "tanambao III",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7584348,
          lng: 47.7674557,
        },
        _id: "64f75285ef8f606073e99985",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "fenomby",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7565532,
          lng: 47.78842299999999,
        },
        _id: "64f75285ef8f606073e99986",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "fenomby",
        fokontany: "tsarakianja",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7673946,
          lng: 47.7575916,
        },
        _id: "64f75285ef8f606073e99987",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "fenomby",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7747618,
          lng: 47.74649669999999,
        },
        _id: "64f75285ef8f606073e99988",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "fenomby",
        fokontany: "tanambao II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7823814,
          lng: 47.778555,
        },
        _id: "64f75285ef8f606073e99989",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "fenomby",
        fokontany: "ambohimahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7922998,
          lng: 47.7563588,
        },
        _id: "64f75285ef8f606073e9998a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "fenomby",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8021367,
          lng: 47.7829789,
        },
        _id: "64f75285ef8f606073e9998b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambalavero",
        fokontany: "ambalavero",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7819966,
          lng: 47.8081645,
        },
        _id: "64f75285ef8f606073e9998c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambalavero",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8021367,
          lng: 47.7829789,
        },
        _id: "64f75285ef8f606073e9998d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambalavero",
        fokontany: "ambodilaza",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8078304,
          lng: 47.7982928,
        },
        _id: "64f75285ef8f606073e9998e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambalavero",
        fokontany: "antanantanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8474511,
          lng: 47.7095305,
        },
        _id: "64f75285ef8f606073e9998f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambalavero",
        fokontany: "ambalakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8117517,
          lng: 47.7415664,
        },
        _id: "64f75285ef8f606073e99990",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambalavero",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8308402,
          lng: 47.7563588,
        },
        _id: "64f75285ef8f606073e99991",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambalavero",
        fokontany: "sahaherotra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8305682,
          lng: 47.778555,
        },
        _id: "64f75285ef8f606073e99992",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambalavero",
        fokontany: "ambohitsara B",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8468402,
          lng: 47.7612905,
        },
        _id: "64f75285ef8f606073e99993",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambalavero",
        fokontany: "ampandramanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.833333,
          lng: 47.75,
        },
        _id: "64f75285ef8f606073e99994",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ambalavero",
        fokontany: "lavakianja",
        __v: 0,
      },
      {
        coords: {
          lat: -21.922819,
          lng: 47.774021,
        },
        _id: "64f75285ef8f606073e99995",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "amborondra",
        fokontany: "amborondra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8914947,
          lng: 47.78842299999999,
        },
        _id: "64f75285ef8f606073e99996",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "amborondra",
        fokontany: "anaviavimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8918391,
          lng: 47.7588245,
        },
        _id: "64f75285ef8f606073e99997",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "amborondra",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9331562,
          lng: 47.7982928,
        },
        _id: "64f75285ef8f606073e99998",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "amborondra",
        fokontany: "maromandia",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9589765,
          lng: 47.78842299999999,
        },
        _id: "64f75285ef8f606073e99999",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "amborondra",
        fokontany: "sahasambo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.755039,
          lng: 47.711151,
        },
        _id: "64f75285ef8f606073e9999a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mahabako",
        fokontany: "mahabako",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6893001,
          lng: 47.7736217,
        },
        _id: "64f75285ef8f606073e9999b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mahabako",
        fokontany: "maromandia valokianja",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7249584,
          lng: 47.7489621,
        },
        _id: "64f75285ef8f606073e9999c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mahabako",
        fokontany: "andemaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.71716,
          lng: 47.7317071,
        },
        _id: "64f75285ef8f606073e9999d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mahabako",
        fokontany: "madiolamba",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7287156,
          lng: 47.707067,
        },
        _id: "64f75285ef8f606073e9999e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mahabako",
        fokontany: "vatomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7476904,
          lng: 47.7292426,
        },
        _id: "64f75285ef8f606073e9999f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mahabako",
        fokontany: "morotenina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7353539,
          lng: 47.6898257,
        },
        _id: "64f75285ef8f606073e999a0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mahabako",
        fokontany: "antaranivola",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7704102,
          lng: 47.7095305,
        },
        _id: "64f75285ef8f606073e999a1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mahabako",
        fokontany: "benihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8056599,
          lng: 47.7144578,
        },
        _id: "64f75285ef8f606073e999a2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mahabako",
        fokontany: "ampotamandihy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7010563,
          lng: 47.6110802,
        },
        _id: "64f75285ef8f606073e999a3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "onilahy",
        fokontany: "ionilahy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6976048,
          lng: 47.6307555,
        },
        _id: "64f75285ef8f606073e999a4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "onilahy",
        fokontany: "mahavanona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7068533,
          lng: 47.6602824,
        },
        _id: "64f75285ef8f606073e999a5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "onilahy",
        fokontany: "ambandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7486156,
          lng: 47.6553601,
        },
        _id: "64f75285ef8f606073e999a6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "onilahy",
        fokontany: "ambohitsara be",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7393348,
          lng: 47.872375,
        },
        _id: "64f75285ef8f606073e999a7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mahamaibe",
        fokontany: "mahamaibe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7132536,
          lng: 47.897092,
        },
        _id: "64f75285ef8f606073e999a8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mahamaibe",
        fokontany: "anorombato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7294768,
          lng: 47.88720379999999,
        },
        _id: "64f75285ef8f606073e999a9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mahamaibe",
        fokontany: "tsokoborano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7469177,
          lng: 47.9045093,
        },
        _id: "64f75285ef8f606073e999aa",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mahamaibe",
        fokontany: "mahamaikely",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7588328,
          lng: 47.8575503,
        },
        _id: "64f75285ef8f606073e999ab",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "mahamaibe",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7333329,
          lng: 47.816667,
        },
        _id: "64f75285ef8f606073e999ac",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "kianjanomby",
        fokontany: "kianjanomby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7048811,
          lng: 47.8081645,
        },
        _id: "64f75285ef8f606073e999ad",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "kianjanomby",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.927765,
          lng: 47.7046036,
        },
        _id: "64f75285ef8f606073e999ae",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ampasimpotsy sud",
        fokontany: "ampasimpotsy sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8957691,
          lng: 47.6947512,
        },
        _id: "64f75285ef8f606073e999af",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ampasimpotsy sud",
        fokontany: "ambalavoangy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.93429,
          lng: 47.6947512,
        },
        _id: "64f75285ef8f606073e999b0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ampasimpotsy sud",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9565062,
          lng: 47.7193856,
        },
        _id: "64f75285ef8f606073e999b1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ampasimpotsy sud",
        fokontany: "ampasimpotsy toby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9610898,
          lng: 47.7415664,
        },
        _id: "64f75285ef8f606073e999b2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ampasimpotsy sud",
        fokontany: "betampona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9722799,
          lng: 47.74649669999999,
        },
        _id: "64f75285ef8f606073e999b3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ampasimpotsy sud",
        fokontany: "bebaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9882127,
          lng: 47.7588245,
        },
        _id: "64f75285ef8f606073e999b4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ampasimpotsy sud",
        fokontany: "mahatsaratsara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6726224,
          lng: 47.8438771,
        },
        _id: "64f75285ef8f606073e999b5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anteza",
        fokontany: "anteza",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6049903,
          lng: 47.8279133,
        },
        _id: "64f75285ef8f606073e999b6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anteza",
        fokontany: "ankeba est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6054374,
          lng: 47.7982928,
        },
        _id: "64f75285ef8f606073e999b7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anteza",
        fokontany: "ankeba ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6294189,
          lng: 47.8056964,
        },
        _id: "64f75285ef8f606073e999b8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anteza",
        fokontany: "vohilava II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6369743,
          lng: 47.8377905,
        },
        _id: "64f75285ef8f606073e999b9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anteza",
        fokontany: "ankerana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6428817,
          lng: 47.872375,
        },
        _id: "64f75285ef8f606073e999ba",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anteza",
        fokontany: "lavakianja",
        __v: 0,
      },
      {
        coords: {
          lat: -21.640481,
          lng: 47.81803800000001,
        },
        _id: "64f75285ef8f606073e999bb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anteza",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6571146,
          lng: 47.778555,
        },
        _id: "64f75285ef8f606073e999bc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anteza",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6578993,
          lng: 47.835321,
        },
        _id: "64f75285ef8f606073e999bd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anteza",
        fokontany: "sahasambo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6576805,
          lng: 47.8501395,
        },
        _id: "64f75285ef8f606073e999be",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anteza",
        fokontany: "ambodivakoka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.675104,
          lng: 47.867433,
        },
        _id: "64f75285ef8f606073e999bf",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anteza",
        fokontany: "mahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6843095,
          lng: 47.897092,
        },
        _id: "64f75285ef8f606073e999c0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anteza",
        fokontany: "ampatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6759655,
          lng: 47.8081645,
        },
        _id: "64f75285ef8f606073e999c1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "anteza",
        fokontany: "andranomangatsiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.666667,
          lng: 47.683333,
        },
        _id: "64f75285ef8f606073e999c2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "saharefo",
        fokontany: "saharefo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6425699,
          lng: 47.67012829999999,
        },
        _id: "64f75285ef8f606073e999c3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "saharefo",
        fokontany: "amboaifito",
        __v: 0,
      },
      {
        coords: {
          lat: -21.666667,
          lng: 47.683333,
        },
        _id: "64f75285ef8f606073e999c4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "saharefo",
        fokontany: "manditonana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6610164,
          lng: 47.7292426,
        },
        _id: "64f75285ef8f606073e999c5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "saharefo",
        fokontany: "ambohitsara II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6937679,
          lng: 47.6799761,
        },
        _id: "64f75285ef8f606073e999c6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "saharefo",
        fokontany: "ambalatambina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6718255,
          lng: 47.640596,
        },
        _id: "64f75285ef8f606073e999c7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ampasimboraka",
        fokontany: "ampasimboraka sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6398883,
          lng: 47.6307555,
        },
        _id: "64f75285ef8f606073e999c8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ampasimboraka",
        fokontany: "ambalavoatavo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6509491,
          lng: 47.6430564,
        },
        _id: "64f75285ef8f606073e999c9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ampasimboraka",
        fokontany: "ampasimboraka nord",
        __v: 0,
      },
      {
        coords: {
          lat: -21.662586,
          lng: 47.6110802,
        },
        _id: "64f75285ef8f606073e999ca",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "manakara atsimo",
        commune: "ampasimboraka",
        fokontany: "ambohitsara I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8779141,
          lng: 47.43494630000001,
        },
        _id: "64f75285ef8f606073e999cb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8150619,
          lng: 47.4392352,
        },
        _id: "64f75285ef8f606073e999cc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "anorombatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8318301,
          lng: 47.4429117,
        },
        _id: "64f75285ef8f606073e999cd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "saharamy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8399046,
          lng: 47.5177229,
        },
        _id: "64f75285ef8f606073e999ce",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "maromandia",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8468375,
          lng: 47.4637499,
        },
        _id: "64f75285ef8f606073e999cf",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "amboangisay",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7897942,
          lng: 47.404934,
        },
        _id: "64f75285ef8f606073e999d0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "anorombatotelo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.847771,
          lng: 47.3608657,
        },
        _id: "64f75285ef8f606073e999d1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "ambalagoavy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8667713,
          lng: 47.3828952,
        },
        _id: "64f75285ef8f606073e999d2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "antsatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8093971,
          lng: 47.3608657,
        },
        _id: "64f75285ef8f606073e999d3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "antekoho",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8713144,
          lng: 47.4122824,
        },
        _id: "64f75285ef8f606073e999d4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "sahavondrony",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8058827,
          lng: 47.3951378,
        },
        _id: "64f75285ef8f606073e999d5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "ambohimahasoa nord",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8565112,
          lng: 47.4563943,
        },
        _id: "64f75285ef8f606073e999d6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "papango",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8546784,
          lng: 47.4809171,
        },
        _id: "64f75285ef8f606073e999d7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8724732,
          lng: 47.461298,
        },
        _id: "64f75285ef8f606073e999d8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "mahazendana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.85,
          lng: 47.383333,
        },
        _id: "64f75285ef8f606073e999d9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "vohitsivalana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.879065,
          lng: 47.4404607,
        },
        _id: "64f75285ef8f606073e999da",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "mangarivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9115762,
          lng: 47.380447,
        },
        _id: "64f75285ef8f606073e999db",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "ambodiara dihy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8920083,
          lng: 47.4245319,
        },
        _id: "64f75285ef8f606073e999dc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8894464,
          lng: 47.4429117,
        },
        _id: "64f75285ef8f606073e999dd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "voninkazo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8862016,
          lng: 47.4478141,
        },
        _id: "64f75285ef8f606073e999de",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "antavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8849863,
          lng: 47.4931828,
        },
        _id: "64f75285ef8f606073e999df",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "andromba",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8845652,
          lng: 47.5373633,
        },
        _id: "64f75285ef8f606073e999e0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "nanarena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8969997,
          lng: 47.5766661,
        },
        _id: "64f75285ef8f606073e999e1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "ambohitsara est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.909432,
          lng: 47.6159983,
        },
        _id: "64f75285ef8f606073e999e2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "ambodilazavivy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9421117,
          lng: 47.5520984,
        },
        _id: "64f75285ef8f606073e999e3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "marovitsika",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9327671,
          lng: 47.5226323,
        },
        _id: "64f75285ef8f606073e999e4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "ambodimanga est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8916832,
          lng: 47.461298,
        },
        _id: "64f75285ef8f606073e999e5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "ambalavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9044029,
          lng: 47.4711066,
        },
        _id: "64f75285ef8f606073e999e6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "ambohimiary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9234212,
          lng: 47.4931828,
        },
        _id: "64f75285ef8f606073e999e7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "sahavandra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.920563,
          lng: 47.4539427,
        },
        _id: "64f75285ef8f606073e999e8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "tsaratanteraka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9457572,
          lng: 47.5029975,
        },
        _id: "64f75285ef8f606073e999e9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "volarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.977956,
          lng: 47.48337,
        },
        _id: "64f75285ef8f606073e999ea",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "tsarakianja est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9807037,
          lng: 47.5373633,
        },
        _id: "64f75285ef8f606073e999eb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "sahakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9479205,
          lng: 47.6159983,
        },
        _id: "64f75285ef8f606073e999ec",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ikongo",
        fokontany: "sahanihary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.95,
          lng: 47.433333,
        },
        _id: "64f75285ef8f606073e999ed",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambolomadinika",
        fokontany: "ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9272948,
          lng: 47.41473209999999,
        },
        _id: "64f75285ef8f606073e999ee",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambolomadinika",
        fokontany: "ankarefobe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9287633,
          lng: 47.4306578,
        },
        _id: "64f75285ef8f606073e999ef",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambolomadinika",
        fokontany: "sahanimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -21.95,
          lng: 47.433333,
        },
        _id: "64f75285ef8f606073e999f0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambolomadinika",
        fokontany: "ambodilazabe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9185036,
          lng: 47.3119448,
        },
        _id: "64f75285ef8f606073e999f1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambolomadinika",
        fokontany: "antarehimamy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9758413,
          lng: 47.3412918,
        },
        _id: "64f75285ef8f606073e999f2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambolomadinika",
        fokontany: "tsianivoha",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9562128,
          lng: 47.4000357,
        },
        _id: "64f75285ef8f606073e999f3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambolomadinika",
        fokontany: "tsarakianja sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9592207,
          lng: 47.4245319,
        },
        _id: "64f75285ef8f606073e999f4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambolomadinika",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9972327,
          lng: 47.4760116,
        },
        _id: "64f75285ef8f606073e999f5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambolomadinika",
        fokontany: "ampasimalemy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0008686,
          lng: 47.4196318,
        },
        _id: "64f75285ef8f606073e999f6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambolomadinika",
        fokontany: "antaranjaha",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0070123,
          lng: 47.4539427,
        },
        _id: "64f75285ef8f606073e999f7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambolomadinika",
        fokontany: "androrangavola",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0139171,
          lng: 47.3853435,
        },
        _id: "64f75285ef8f606073e999f8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambolomadinika",
        fokontany: "sahamazava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.76248,
          lng: 47.493488,
        },
        _id: "64f75285ef8f606073e999f9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambatofotsy",
        fokontany: "ambatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7250028,
          lng: 47.4809171,
        },
        _id: "64f75285ef8f606073e999fa",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambatofotsy",
        fokontany: "tandrokomby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7411164,
          lng: 47.4711066,
        },
        _id: "64f75285ef8f606073e999fb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambatofotsy",
        fokontany: "ambodivona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7408771,
          lng: 47.4931828,
        },
        _id: "64f75285ef8f606073e999fc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambatofotsy",
        fokontany: "tsarakianja B",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7320598,
          lng: 47.4196318,
        },
        _id: "64f75285ef8f606073e999fd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambatofotsy",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7773615,
          lng: 47.52508719999999,
        },
        _id: "64f75285ef8f606073e999fe",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambatofotsy",
        fokontany: "fozamena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7730274,
          lng: 47.4809171,
        },
        _id: "64f75285ef8f606073e999ff",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambatofotsy",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7951098,
          lng: 47.512814,
        },
        _id: "64f75285ef8f606073e99a00",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambatofotsy",
        fokontany: "ambodiara sakorihy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.79261,
          lng: 47.4441373,
        },
        _id: "64f75285ef8f606073e99a01",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambatofotsy",
        fokontany: "marohita",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7985921,
          lng: 47.485823,
        },
        _id: "64f75285ef8f606073e99a02",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambatofotsy",
        fokontany: "ampiatsaha",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8019186,
          lng: 47.4735591,
        },
        _id: "64f75285ef8f606073e99a03",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambatofotsy",
        fokontany: "ambalatenina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7780471,
          lng: 47.6073919,
        },
        _id: "64f75285ef8f606073e99a04",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "belemoka",
        fokontany: "belemoka centre",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7395292,
          lng: 47.6110802,
        },
        _id: "64f75285ef8f606073e99a05",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "belemoka",
        fokontany: "sahalava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7618575,
          lng: 47.62091700000001,
        },
        _id: "64f75285ef8f606073e99a06",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "belemoka",
        fokontany: "tsarafonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.788282,
          lng: 47.5520984,
        },
        _id: "64f75285ef8f606073e99a07",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "belemoka",
        fokontany: "anahipisaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7783406,
          lng: 47.581581,
        },
        _id: "64f75285ef8f606073e99a08",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "belemoka",
        fokontany: "ambodilaza est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.783569,
          lng: 47.61049939999999,
        },
        _id: "64f75285ef8f606073e99a09",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "belemoka",
        fokontany: "ambohimahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7903762,
          lng: 47.6504382,
        },
        _id: "64f75285ef8f606073e99a0a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "belemoka",
        fokontany: "anahibita",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8125469,
          lng: 47.675052,
        },
        _id: "64f75285ef8f606073e99a0b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "belemoka",
        fokontany: "tsifenokataka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.794148,
          lng: 47.6012453,
        },
        _id: "64f75285ef8f606073e99a0c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "belemoka",
        fokontany: "mandrosolaza",
        __v: 0,
      },
      {
        coords: {
          lat: -21.845501,
          lng: 47.59632850000001,
        },
        _id: "64f75285ef8f606073e99a0d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "belemoka",
        fokontany: "marokaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7182423,
          lng: 47.512814,
        },
        _id: "64f75285ef8f606073e99a0e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "maromiandra",
        fokontany: "maromiandra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.666667,
          lng: 47.466667,
        },
        _id: "64f75285ef8f606073e99a0f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "maromiandra",
        fokontany: "ambohimahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6930997,
          lng: 47.4711066,
        },
        _id: "64f75285ef8f606073e99a10",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "maromiandra",
        fokontany: "anaviavy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7009549,
          lng: 47.5533265,
        },
        _id: "64f75285ef8f606073e99a11",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "maromiandra",
        fokontany: "vohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.695938,
          lng: 47.5029975,
        },
        _id: "64f75285ef8f606073e99a12",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "maromiandra",
        fokontany: "ambahimarana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7089681,
          lng: 47.48337,
        },
        _id: "64f75285ef8f606073e99a13",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "maromiandra",
        fokontany: "ambodiara sakavazoho",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7275154,
          lng: 47.5422745,
        },
        _id: "64f75285ef8f606073e99a14",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "maromiandra",
        fokontany: "ambohitsara b",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7532551,
          lng: 47.5324525,
        },
        _id: "64f75285ef8f606073e99a15",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "maromiandra",
        fokontany: "sandranantakely",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7374582,
          lng: 47.512814,
        },
        _id: "64f75285ef8f606073e99a16",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "maromiandra",
        fokontany: "sakevo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.66646,
          lng: 47.583778,
        },
        _id: "64f75285ef8f606073e99a17",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "manampatrana",
        fokontany: "manampatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6183498,
          lng: 47.5619241,
        },
        _id: "64f75285ef8f606073e99a18",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "manampatrana",
        fokontany: "amboanjobe gara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.617967,
          lng: 47.5914122,
        },
        _id: "64f75285ef8f606073e99a19",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "manampatrana",
        fokontany: "bezamana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6405583,
          lng: 47.57912349999999,
        },
        _id: "64f75285ef8f606073e99a1a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "manampatrana",
        fokontany: "ambaraholona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.637196,
          lng: 47.5914122,
        },
        _id: "64f75285ef8f606073e99a1b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "manampatrana",
        fokontany: "amboanjobe centre",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6431948,
          lng: 47.5606957,
        },
        _id: "64f75285ef8f606073e99a1c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "manampatrana",
        fokontany: "ambohimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6536227,
          lng: 47.5594675,
        },
        _id: "64f75285ef8f606073e99a1d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "manampatrana",
        fokontany: "ambatofaritana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.666667,
          lng: 47.466667,
        },
        _id: "64f75285ef8f606073e99a1e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "manampatrana",
        fokontany: "ambohimahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6997849,
          lng: 47.5840386,
        },
        _id: "64f75285ef8f606073e99a1f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "manampatrana",
        fokontany: "manambato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7398776,
          lng: 47.581581,
        },
        _id: "64f75285ef8f606073e99a20",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "manampatrana",
        fokontany: "ambohitsara manambato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6353975,
          lng: 47.4784643,
        },
        _id: "64f75285ef8f606073e99a21",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambinanitromby",
        fokontany: "ambinanitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6121947,
          lng: 47.5422745,
        },
        _id: "64f75285ef8f606073e99a22",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambinanitromby",
        fokontany: "ambodiara ihomby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6314131,
          lng: 47.5422745,
        },
        _id: "64f75285ef8f606073e99a23",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambinanitromby",
        fokontany: "tetezandraikitra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.633333,
          lng: 47.516667,
        },
        _id: "64f75285ef8f606073e99a24",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambinanitromby",
        fokontany: "antanivelona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6640338,
          lng: 47.4931828,
        },
        _id: "64f75285ef8f606073e99a25",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambinanitromby",
        fokontany: "ambohinamboarina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6730256,
          lng: 47.5447303,
        },
        _id: "64f75285ef8f606073e99a26",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambinanitromby",
        fokontany: "manarinjato",
        __v: 0,
      },
      {
        coords: {
          lat: -22.19269,
          lng: 47.468979,
        },
        _id: "64f75285ef8f606073e99a27",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ifanirea",
        fokontany: "ifanirea",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1058113,
          lng: 47.5226323,
        },
        _id: "64f75285ef8f606073e99a28",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ifanirea",
        fokontany: "tambanihara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1158267,
          lng: 47.4637499,
        },
        _id: "64f75285ef8f606073e99a29",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ifanirea",
        fokontany: "vinany",
        __v: 0,
      },
      {
        coords: {
          lat: -22.122005,
          lng: 47.4980899,
        },
        _id: "64f75285ef8f606073e99a2a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ifanirea",
        fokontany: "ambohimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1574054,
          lng: 47.4735591,
        },
        _id: "64f75285ef8f606073e99a2b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ifanirea",
        fokontany: "andemaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1667393,
          lng: 47.5177229,
        },
        _id: "64f75285ef8f606073e99a2c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ifanirea",
        fokontany: "ihevo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1726212,
          lng: 47.59632850000001,
        },
        _id: "64f75285ef8f606073e99a2d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ifanirea",
        fokontany: "analamary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1766699,
          lng: 47.4662021,
        },
        _id: "64f75285ef8f606073e99a2e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ifanirea",
        fokontany: "ampandramana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2,
          lng: 47.583333,
        },
        _id: "64f75285ef8f606073e99a2f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ifanirea",
        fokontany: "ambatofisaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2307281,
          lng: 47.5373633,
        },
        _id: "64f75285ef8f606073e99a30",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ifanirea",
        fokontany: "vohibola",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2432274,
          lng: 47.5914122,
        },
        _id: "64f75285ef8f606073e99a31",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ifanirea",
        fokontany: "sahahofika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0580703,
          lng: 47.4784643,
        },
        _id: "64f75285ef8f606073e99a32",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tanakambana",
        fokontany: "tanakambana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0099084,
          lng: 47.4931828,
        },
        _id: "64f75285ef8f606073e99a33",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tanakambana",
        fokontany: "fanjakasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0260018,
          lng: 47.48337,
        },
        _id: "64f75285ef8f606073e99a34",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tanakambana",
        fokontany: "vohilava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.033333,
          lng: 47.466667,
        },
        _id: "64f75285ef8f606073e99a35",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tanakambana",
        fokontany: "nanarena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.057739,
          lng: 47.5226323,
        },
        _id: "64f75285ef8f606073e99a36",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tanakambana",
        fokontany: "sahampaly",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0738361,
          lng: 47.512814,
        },
        _id: "64f75285ef8f606073e99a37",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tanakambana",
        fokontany: "vohiboanjo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0901366,
          lng: 47.4735591,
        },
        _id: "64f75285ef8f606073e99a38",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tanakambana",
        fokontany: "vohiteny",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0999426,
          lng: 47.4441373,
        },
        _id: "64f75285ef8f606073e99a39",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tanakambana",
        fokontany: "mafitoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0828864,
          lng: 47.58649639999999,
        },
        _id: "64f75285ef8f606073e99a3a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "sahalanona",
        fokontany: "sahalanona",
        __v: 0,
      },
      {
        coords: {
          lat: -22.016667,
          lng: 47.616667,
        },
        _id: "64f75285ef8f606073e99a3b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "sahalanona",
        fokontany: "mahaly",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0377785,
          lng: 47.6110802,
        },
        _id: "64f75285ef8f606073e99a3c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "sahalanona",
        fokontany: "anilobe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0349062,
          lng: 47.5717516,
        },
        _id: "64f75285ef8f606073e99a3d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "sahalanona",
        fokontany: "sahamamy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0321409,
          lng: 47.5177229,
        },
        _id: "64f75285ef8f606073e99a3e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "sahalanona",
        fokontany: "ambinanindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.10688,
          lng: 47.623268,
        },
        _id: "64f75285ef8f606073e99a3f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "sahalanona",
        fokontany: "tsaralanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1130281,
          lng: 47.6282957,
        },
        _id: "64f75285ef8f606073e99a40",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "sahalanona",
        fokontany: "ambalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1530443,
          lng: 47.640596,
        },
        _id: "64f75285ef8f606073e99a41",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "sahalanona",
        fokontany: "anivorano II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1532664,
          lng: 47.6110802,
        },
        _id: "64f75285ef8f606073e99a42",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "sahalanona",
        fokontany: "ambodimangavola",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5737601,
          lng: 47.5422745,
        },
        _id: "64f75285ef8f606073e99a43",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tolongoina",
        fokontany: "tolongoina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4117359,
          lng: 47.4539427,
        },
        _id: "64f75285ef8f606073e99a44",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tolongoina",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4564508,
          lng: 47.4588461,
        },
        _id: "64f75285ef8f606073e99a45",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tolongoina",
        fokontany: "ambalakizitina",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5073118,
          lng: 47.48337,
        },
        _id: "64f75285ef8f606073e99a46",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tolongoina",
        fokontany: "ambohimahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4887043,
          lng: 47.4392352,
        },
        _id: "64f75285ef8f606073e99a47",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tolongoina",
        fokontany: "andrambovato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5462821,
          lng: 47.4392352,
        },
        _id: "64f75285ef8f606073e99a48",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tolongoina",
        fokontany: "ambodivanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5230517,
          lng: 47.5029975,
        },
        _id: "64f75285ef8f606073e99a49",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tolongoina",
        fokontany: "iladitra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5522441,
          lng: 47.4735591,
        },
        _id: "64f75285ef8f606073e99a4a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tolongoina",
        fokontany: "madiorano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5480058,
          lng: 47.5520984,
        },
        _id: "64f75285ef8f606073e99a4b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tolongoina",
        fokontany: "ambinanindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5500599,
          lng: 47.504391,
        },
        _id: "64f75285ef8f606073e99a4c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tolongoina",
        fokontany: "tsimbahambo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5572147,
          lng: 47.581581,
        },
        _id: "64f75285ef8f606073e99a4d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tolongoina",
        fokontany: "ambodiroranga",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5874894,
          lng: 47.5938703,
        },
        _id: "64f75285ef8f606073e99a4e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tolongoina",
        fokontany: "ambatovory",
        __v: 0,
      },
      {
        coords: {
          lat: -21.590091,
          lng: 47.5177229,
        },
        _id: "64f75285ef8f606073e99a4f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tolongoina",
        fokontany: "mandriandry",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5829793,
          lng: 47.5717516,
        },
        _id: "64f75285ef8f606073e99a50",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tolongoina",
        fokontany: "ambohimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6069123,
          lng: 47.57912349999999,
        },
        _id: "64f75285ef8f606073e99a51",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "tolongoina",
        fokontany: "ambalavero bas",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5101274,
          lng: 47.5262788,
        },
        _id: "64f75285ef8f606073e99a52",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambohimisafy",
        fokontany: "ambohimisafy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4396899,
          lng: 47.512814,
        },
        _id: "64f75285ef8f606073e99a53",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambohimisafy",
        fokontany: "ambohimahavelo menatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4748318,
          lng: 47.5177229,
        },
        _id: "64f75285ef8f606073e99a54",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambohimisafy",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4742016,
          lng: 47.5619241,
        },
        _id: "64f75285ef8f606073e99a55",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambohimisafy",
        fokontany: "ambohimanana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4785103,
          lng: 47.48337,
        },
        _id: "64f75285ef8f606073e99a56",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambohimisafy",
        fokontany: "ambohibory",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4680796,
          lng: 47.5422745,
        },
        _id: "64f75285ef8f606073e99a57",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambohimisafy",
        fokontany: "andefapony",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5026077,
          lng: 47.5914122,
        },
        _id: "64f75285ef8f606073e99a58",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambohimisafy",
        fokontany: "lavakianja",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5377847,
          lng: 47.59632850000001,
        },
        _id: "64f75285ef8f606073e99a59",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambohimisafy",
        fokontany: "ambodipaiso",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5101274,
          lng: 47.5262788,
        },
        _id: "64f75285ef8f606073e99a5a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ambohimisafy",
        fokontany: "vakoanina",
        __v: 0,
      },
      {
        coords: {
          lat: -22.137501,
          lng: 47.3302989,
        },
        _id: "64f75285ef8f606073e99a5b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ankarimbelo",
        fokontany: "ankarimbelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1038784,
          lng: 47.3217253,
        },
        _id: "64f75285ef8f606073e99a5c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ankarimbelo",
        fokontany: "faliarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1195138,
          lng: 47.3853435,
        },
        _id: "64f75285ef8f606073e99a5d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ankarimbelo",
        fokontany: "ambalahosy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1492109,
          lng: 47.1947232,
        },
        _id: "64f75285ef8f606073e99a5e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ankarimbelo",
        fokontany: "iarinomby",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1680172,
          lng: 47.2826144,
        },
        _id: "64f75285ef8f606073e99a5f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ankarimbelo",
        fokontany: "tsialamaha",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1260932,
          lng: 47.3535246,
        },
        _id: "64f75285ef8f606073e99a60",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ankarimbelo",
        fokontany: "anjorozoro",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1419829,
          lng: 47.37310309999999,
        },
        _id: "64f75285ef8f606073e99a61",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ankarimbelo",
        fokontany: "andrairay",
        __v: 0,
      },
      {
        coords: {
          lat: -22.137501,
          lng: 47.3302989,
        },
        _id: "64f75285ef8f606073e99a62",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ankarimbelo",
        fokontany: "ambatombitro",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1837138,
          lng: 47.3461846,
        },
        _id: "64f75285ef8f606073e99a63",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "ankarimbelo",
        fokontany: "ambohimaneva",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0586817,
          lng: 47.3902404,
        },
        _id: "64f75285ef8f606073e99a64",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "antodinga",
        fokontany: "antodinga",
        __v: 0,
      },
      {
        coords: {
          lat: -22.016667,
          lng: 47.333333,
        },
        _id: "64f75285ef8f606073e99a65",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "antodinga",
        fokontany: "mamolifoly",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0459468,
          lng: 47.380447,
        },
        _id: "64f75285ef8f606073e99a66",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "antodinga",
        fokontany: "ambolomboro",
        __v: 0,
      },
      {
        coords: {
          lat: -22.053734,
          lng: 47.4122824,
        },
        _id: "64f75285ef8f606073e99a67",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "antodinga",
        fokontany: "maromana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0648546,
          lng: 47.4245319,
        },
        _id: "64f75285ef8f606073e99a68",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "antodinga",
        fokontany: "ankazoharaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0843435,
          lng: 47.380447,
        },
        _id: "64f75285ef8f606073e99a69",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "antodinga",
        fokontany: "anivorano I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1065946,
          lng: 47.404934,
        },
        _id: "64f75285ef8f606073e99a6a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "antodinga",
        fokontany: "vohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1287624,
          lng: 47.4441373,
        },
        _id: "64f75285ef8f606073e99a6b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "antodinga",
        fokontany: "manolotrony",
        __v: 0,
      },
      {
        coords: {
          lat: -22.12248,
          lng: 47.4245319,
        },
        _id: "64f75285ef8f606073e99a6c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "antodinga",
        fokontany: "tsarakianja",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1579166,
          lng: 47.3853435,
        },
        _id: "64f75285ef8f606073e99a6d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "antodinga",
        fokontany: "behazavana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.170336,
          lng: 47.4539427,
        },
        _id: "64f75285ef8f606073e99a6e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "antodinga",
        fokontany: "mitremaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1673579,
          lng: 47.41473209999999,
        },
        _id: "64f75285ef8f606073e99a6f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "antodinga",
        fokontany: "andasy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2120778,
          lng: 47.4343337,
        },
        _id: "64f75285ef8f606073e99a70",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "antodinga",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.293011,
          lng: 47.31284,
        },
        _id: "64f75285ef8f606073e99a71",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "kalafotsy",
        fokontany: "kalafotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1898704,
          lng: 47.3951378,
        },
        _id: "64f75285ef8f606073e99a72",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "kalafotsy",
        fokontany: "ambalavary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2123706,
          lng: 47.37555099999999,
        },
        _id: "64f75285ef8f606073e99a73",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "kalafotsy",
        fokontany: "voena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2183399,
          lng: 47.461298,
        },
        _id: "64f75285ef8f606073e99a74",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "kalafotsy",
        fokontany: "tanambao II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2190092,
          lng: 47.3217253,
        },
        _id: "64f75285ef8f606073e99a75",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "kalafotsy",
        fokontany: "ambahaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2186782,
          lng: 47.3951378,
        },
        _id: "64f75285ef8f606073e99a76",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "kalafotsy",
        fokontany: "mahafiango",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2249852,
          lng: 47.41473209999999,
        },
        _id: "64f75285ef8f606073e99a77",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "kalafotsy",
        fokontany: "akondromainty",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2375976,
          lng: 47.4539427,
        },
        _id: "64f75285ef8f606073e99a78",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "kalafotsy",
        fokontany: "marozahitra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2507741,
          lng: 47.37555099999999,
        },
        _id: "64f75285ef8f606073e99a79",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "kalafotsy",
        fokontany: "ihazomena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2763576,
          lng: 47.380447,
        },
        _id: "64f75285ef8f606073e99a7a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "ikongo",
        commune: "kalafotsy",
        fokontany: "ambalateny",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3523173,
          lng: 47.8501395,
        },
        _id: "64f75285ef8f606073e99a7b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohipeno",
        fokontany: "vohipeno centre",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3220099,
          lng: 47.8155695,
        },
        _id: "64f75285ef8f606073e99a7c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohipeno",
        fokontany: "vohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3283793,
          lng: 47.8242099,
        },
        _id: "64f75285ef8f606073e99a7d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohipeno",
        fokontany: "ambohitsara II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3344154,
          lng: 47.8773175,
        },
        _id: "64f75285ef8f606073e99a7e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohipeno",
        fokontany: "ambolosy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3348033,
          lng: 47.8254443,
        },
        _id: "64f75285ef8f606073e99a7f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohipeno",
        fokontany: "vatolapana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3509554,
          lng: 47.8155695,
        },
        _id: "64f75285ef8f606073e99a80",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohipeno",
        fokontany: "tsimalazo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3410927,
          lng: 47.8451996,
        },
        _id: "64f75285ef8f606073e99a81",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohipeno",
        fokontany: "maromby",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3635429,
          lng: 47.8550799,
        },
        _id: "64f75285ef8f606073e99a82",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohipeno",
        fokontany: "vatomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3620488,
          lng: 47.8390253,
        },
        _id: "64f75285ef8f606073e99a83",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohipeno",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3750022,
          lng: 47.8266788,
        },
        _id: "64f75285ef8f606073e99a84",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohitrindry",
        fokontany: "vohitrindry",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3991034,
          lng: 47.8303825,
        },
        _id: "64f75285ef8f606073e99a85",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohitrindry",
        fokontany: "fenoarivo be",
        __v: 0,
      },
      {
        coords: {
          lat: -22.380081,
          lng: 47.848287,
        },
        _id: "64f75285ef8f606073e99a86",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohitrindry",
        fokontany: "andranovolo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3845264,
          lng: 47.8451996,
        },
        _id: "64f75285ef8f606073e99a87",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohitrindry",
        fokontany: "karimbelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3908507,
          lng: 47.86125610000001,
        },
        _id: "64f75285ef8f606073e99a88",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohitrindry",
        fokontany: "antanantsara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.397329,
          lng: 47.8550799,
        },
        _id: "64f75285ef8f606073e99a89",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohitrindry",
        fokontany: "tsarinetso",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4004536,
          lng: 47.8686685,
        },
        _id: "64f75285ef8f606073e99a8a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohitrindry",
        fokontany: "tanihady",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4152978,
          lng: 47.813101,
        },
        _id: "64f75285ef8f606073e99a8b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohitrindry",
        fokontany: "amboafandra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4535329,
          lng: 47.872375,
        },
        _id: "64f75285ef8f606073e99a8c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohindava",
        fokontany: "vohindava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4085829,
          lng: 47.8563151,
        },
        _id: "64f75285ef8f606073e99a8d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohindava",
        fokontany: "seranambe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4183095,
          lng: 47.8451996,
        },
        _id: "64f75285ef8f606073e99a8e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohindava",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4284815,
          lng: 47.8884397,
        },
        _id: "64f75285ef8f606073e99a8f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohindava",
        fokontany: "vohitramba",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4326932,
          lng: 47.86002080000001,
        },
        _id: "64f75285ef8f606073e99a90",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohindava",
        fokontany: "nohona",
        __v: 0,
      },
      {
        coords: {
          lat: -22.460123,
          lng: 47.84766949999999,
        },
        _id: "64f75285ef8f606073e99a91",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohindava",
        fokontany: "mainty",
        __v: 0,
      },
      {
        coords: {
          lat: -22.446934,
          lng: 47.897092,
        },
        _id: "64f75285ef8f606073e99a92",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohindava",
        fokontany: "ambinany",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3876662,
          lng: 47.8673636,
        },
        _id: "64f75285ef8f606073e99a93",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ivato",
        fokontany: "ivato",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3716221,
          lng: 47.8501395,
        },
        _id: "64f75285ef8f606073e99a94",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ivato",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3795966,
          lng: 47.86002080000001,
        },
        _id: "64f75285ef8f606073e99a95",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ivato",
        fokontany: "voasary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.383333,
          lng: 47.9,
        },
        _id: "64f75285ef8f606073e99a96",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "savana",
        fokontany: "savana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3694452,
          lng: 47.9267674,
        },
        _id: "64f75285ef8f606073e99a97",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "savana",
        fokontany: "ampasimeloka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4001645,
          lng: 47.9094547,
        },
        _id: "64f75285ef8f606073e99a98",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "savana",
        fokontany: "ambohitsara I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.398697,
          lng: 47.8896757,
        },
        _id: "64f75285ef8f606073e99a99",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "savana",
        fokontany: "seranambary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3535567,
          lng: 47.89956429999999,
        },
        _id: "64f75285ef8f606073e99a9a",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "anoloka",
        fokontany: "lazamasy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3553532,
          lng: 47.8748462,
        },
        _id: "64f75285ef8f606073e99a9b",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "anoloka",
        fokontany: "ampasimasay",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3552417,
          lng: 47.8896757,
        },
        _id: "64f75285ef8f606073e99a9c",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "anoloka",
        fokontany: "mahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3746633,
          lng: 47.8748462,
        },
        _id: "64f75285ef8f606073e99a9d",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "anoloka",
        fokontany: "irotra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3809565,
          lng: 47.8946198,
        },
        _id: "64f75285ef8f606073e99a9e",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "anoloka",
        fokontany: "vohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3874396,
          lng: 47.8884397,
        },
        _id: "64f75285ef8f606073e99a9f",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "anoloka",
        fokontany: "vohitsivalana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3907203,
          lng: 47.8797889,
        },
        _id: "64f75285ef8f606073e99aa0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "anoloka",
        fokontany: "vohitramba",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3205448,
          lng: 47.7958252,
        },
        _id: "64f75285ef8f606073e99aa1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahabo",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3077414,
          lng: 47.7878062,
        },
        _id: "64f75285ef8f606073e99aa2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahabo",
        fokontany: "sahora nord",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3133368,
          lng: 47.792124,
        },
        _id: "64f75285ef8f606073e99aa3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahabo",
        fokontany: "sahora",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3397337,
          lng: 47.8106327,
        },
        _id: "64f75285ef8f606073e99aa4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahabo",
        fokontany: "vohilava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3350658,
          lng: 47.78842299999999,
        },
        _id: "64f75285ef8f606073e99aa5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahabo",
        fokontany: "vohipatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3512083,
          lng: 47.778555,
        },
        _id: "64f75285ef8f606073e99aa6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahabo",
        fokontany: "maka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.433029,
          lng: 47.88028,
        },
        _id: "64f75285ef8f606073e99aa7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ankarimbary",
        fokontany: "ankarimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2964568,
          lng: 47.792124,
        },
        _id: "64f75285ef8f606073e99aa8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ankarimbary",
        fokontany: "ambaibo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.299762,
          lng: 47.7797884,
        },
        _id: "64f75285ef8f606073e99aa9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ankarimbary",
        fokontany: "langisay",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2995577,
          lng: 47.8075475,
        },
        _id: "64f75285ef8f606073e99aaa",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ankarimbary",
        fokontany: "vohibolo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2999226,
          lng: 47.8124839,
        },
        _id: "64f75285ef8f606073e99aab",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ankarimbary",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3044768,
          lng: 47.79459139999999,
        },
        _id: "64f75285ef8f606073e99aac",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ankarimbary",
        fokontany: "ifaho",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3075375,
          lng: 47.8155695,
        },
        _id: "64f75285ef8f606073e99aad",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ankarimbary",
        fokontany: "vohibolobe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3147462,
          lng: 47.81927229999999,
        },
        _id: "64f75285ef8f606073e99aae",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ankarimbary",
        fokontany: "maroakanjo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3159823,
          lng: 47.7588245,
        },
        _id: "64f75285ef8f606073e99aaf",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ankarimbary",
        fokontany: "ambohimasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2802205,
          lng: 47.813101,
        },
        _id: "64f75285ef8f606073e99ab0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "lanivo",
        fokontany: "lanivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2561609,
          lng: 47.8056964,
        },
        _id: "64f75285ef8f606073e99ab1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "lanivo",
        fokontany: "fanovia",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2771939,
          lng: 47.78842299999999,
        },
        _id: "64f75285ef8f606073e99ab2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "lanivo",
        fokontany: "farafasy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2882697,
          lng: 47.81186690000001,
        },
        _id: "64f75285ef8f606073e99ab3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "lanivo",
        fokontany: "tsarary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2802776,
          lng: 47.8056964,
        },
        _id: "64f75285ef8f606073e99ab4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "lanivo",
        fokontany: "anosy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2913815,
          lng: 47.8254443,
        },
        _id: "64f75285ef8f606073e99ab5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "lanivo",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3034709,
          lng: 47.821741,
        },
        _id: "64f75285ef8f606073e99ab6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "lanivo",
        fokontany: "vohimasy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2601843,
          lng: 47.9020367,
        },
        _id: "64f75285ef8f606073e99ab7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "nato",
        fokontany: "nato",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2503958,
          lng: 47.9168738,
        },
        _id: "64f75285ef8f606073e99ab8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "nato",
        fokontany: "fanoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2604391,
          lng: 47.872375,
        },
        _id: "64f75285ef8f606073e99ab9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "nato",
        fokontany: "amborobe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2922908,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e99aba",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "nato",
        fokontany: "salohy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3009167,
          lng: 47.84026009999999,
        },
        _id: "64f75285ef8f606073e99abb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "nato",
        fokontany: "ambohitrovakely",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3137088,
          lng: 47.8501395,
        },
        _id: "64f75285ef8f606073e99abc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "nato",
        fokontany: "ntaninary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2893862,
          lng: 47.769922,
        },
        _id: "64f75285ef8f606073e99abd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahasoabe",
        fokontany: "mahasoabe ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -22.255692,
          lng: 47.7625235,
        },
        _id: "64f75285ef8f606073e99abe",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahasoabe",
        fokontany: "andranotsara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2692872,
          lng: 47.7711552,
        },
        _id: "64f75285ef8f606073e99abf",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahasoabe",
        fokontany: "mahasoabe atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2805011,
          lng: 47.7760883,
        },
        _id: "64f75285ef8f606073e99ac0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahasoabe",
        fokontany: "antsiranatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2860811,
          lng: 47.7822553,
        },
        _id: "64f75285ef8f606073e99ac1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahasoabe",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.293355,
          lng: 47.776705,
        },
        _id: "64f75285ef8f606073e99ac2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahasoabe",
        fokontany: "maromby",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4070773,
          lng: 47.8998542,
        },
        _id: "64f75285ef8f606073e99ac3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "onjatsy",
        fokontany: "onjatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4163328,
          lng: 47.89956429999999,
        },
        _id: "64f75285ef8f606073e99ac4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "onjatsy",
        fokontany: "ambaro",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4194828,
          lng: 47.9094547,
        },
        _id: "64f75285ef8f606073e99ac5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "onjatsy",
        fokontany: "vatanio",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4116586,
          lng: 47.8773175,
        },
        _id: "64f75285ef8f606073e99ac6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohilany",
        fokontany: "vohilany",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4084824,
          lng: 47.8711395,
        },
        _id: "64f75285ef8f606073e99ac7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohilany",
        fokontany: "mahasoa andohanosy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4060175,
          lng: 47.8785532,
        },
        _id: "64f75285ef8f606073e99ac8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "vohilany",
        fokontany: "foroforo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.266667,
          lng: 47.766667,
        },
        _id: "64f75285ef8f606073e99ac9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "andemaka",
        fokontany: "andemaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2630062,
          lng: 47.7514275,
        },
        _id: "64f75285ef8f606073e99aca",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "andemaka",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2648451,
          lng: 47.7193856,
        },
        _id: "64f75285ef8f606073e99acb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "andemaka",
        fokontany: "tenimasy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2786661,
          lng: 47.7523521,
        },
        _id: "64f75285ef8f606073e99acc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "andemaka",
        fokontany: "marohanka I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2806903,
          lng: 47.7501948,
        },
        _id: "64f75285ef8f606073e99acd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "andemaka",
        fokontany: "marohanka II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2775475,
          lng: 47.7403339,
        },
        _id: "64f75285ef8f606073e99ace",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "andemaka",
        fokontany: "vohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3002494,
          lng: 47.7095305,
        },
        _id: "64f75285ef8f606073e99acf",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "andemaka",
        fokontany: "todia",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3000495,
          lng: 47.7391014,
        },
        _id: "64f75285ef8f606073e99ad0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "andemaka",
        fokontany: "mahanoro",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3954999,
          lng: 47.73799899999999,
        },
        _id: "64f75285ef8f606073e99ad1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ifatsy",
        fokontany: "ifatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3610437,
          lng: 47.7489621,
        },
        _id: "64f75285ef8f606073e99ad2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ifatsy",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3708652,
          lng: 47.7193856,
        },
        _id: "64f75285ef8f606073e99ad3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ifatsy",
        fokontany: "mahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3769908,
          lng: 47.7686889,
        },
        _id: "64f75285ef8f606073e99ad4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ifatsy",
        fokontany: "karimbelo Z",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3768486,
          lng: 47.7908903,
        },
        _id: "64f75285ef8f606073e99ad5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ifatsy",
        fokontany: "voanaly",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4109592,
          lng: 47.7317071,
        },
        _id: "64f75285ef8f606073e99ad6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ifatsy",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4124539,
          lng: 47.7514275,
        },
        _id: "64f75285ef8f606073e99ad7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ifatsy",
        fokontany: "beanana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4608562,
          lng: 47.7144578,
        },
        _id: "64f75285ef8f606073e99ad8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "antananabo",
        fokontany: "antananabo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4142811,
          lng: 47.7119941,
        },
        _id: "64f75285ef8f606073e99ad9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "antananabo",
        fokontany: "anambotaky",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4351775,
          lng: 47.7095305,
        },
        _id: "64f75285ef8f606073e99ada",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "antananabo",
        fokontany: "analabo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4446626,
          lng: 47.7391014,
        },
        _id: "64f75285ef8f606073e99adb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "antananabo",
        fokontany: "saharevo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4608562,
          lng: 47.7144578,
        },
        _id: "64f75285ef8f606073e99adc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "antananabo",
        fokontany: "amboromaneno",
        __v: 0,
      },
      {
        coords: {
          lat: -22.338978,
          lng: 47.6799761,
        },
        _id: "64f75285ef8f606073e99add",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ilakatra",
        fokontany: "sakavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3008032,
          lng: 47.62091700000001,
        },
        _id: "64f75285ef8f606073e99ade",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ilakatra",
        fokontany: "mita",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3262798,
          lng: 47.6553601,
        },
        _id: "64f75285ef8f606073e99adf",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ilakatra",
        fokontany: "ampandramana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3228601,
          lng: 47.6898257,
        },
        _id: "64f75285ef8f606073e99ae0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ilakatra",
        fokontany: "ambohitsara III",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3265318,
          lng: 47.6110802,
        },
        _id: "64f75285ef8f606073e99ae1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ilakatra",
        fokontany: "tanambao I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3291608,
          lng: 47.7095305,
        },
        _id: "64f75285ef8f606073e99ae2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ilakatra",
        fokontany: "havohavo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3678813,
          lng: 47.6799761,
        },
        _id: "64f75285ef8f606073e99ae3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ilakatra",
        fokontany: "ranomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3839926,
          lng: 47.67012829999999,
        },
        _id: "64f75285ef8f606073e99ae4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ilakatra",
        fokontany: "iarimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3520996,
          lng: 47.6307555,
        },
        _id: "64f75285ef8f606073e99ae5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ilakatra",
        fokontany: "vohimary I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3522548,
          lng: 47.6012453,
        },
        _id: "64f75285ef8f606073e99ae6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ilakatra",
        fokontany: "vatorao",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3683469,
          lng: 47.5914122,
        },
        _id: "64f75285ef8f606073e99ae7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ilakatra",
        fokontany: "ambohitsara I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3713963,
          lng: 47.6233764,
        },
        _id: "64f75285ef8f606073e99ae8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ilakatra",
        fokontany: "ambodiara I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3907603,
          lng: 47.6012453,
        },
        _id: "64f75285ef8f606073e99ae9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ilakatra",
        fokontany: "manatotsikora",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3970131,
          lng: 47.6356755,
        },
        _id: "64f75285ef8f606073e99aea",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ilakatra",
        fokontany: "tanambao II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4160557,
          lng: 47.6799761,
        },
        _id: "64f75285ef8f606073e99aeb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ilakatra",
        fokontany: "ilaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4195411,
          lng: 47.6233764,
        },
        _id: "64f75285ef8f606073e99aec",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ilakatra",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3909602,
          lng: 47.557011,
        },
        _id: "64f75285ef8f606073e99aed",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ilakatra",
        fokontany: "mahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4229335,
          lng: 47.581581,
        },
        _id: "64f75285ef8f606073e99aee",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ilakatra",
        fokontany: "tsaraharena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4324341,
          lng: 47.6110802,
        },
        _id: "64f75285ef8f606073e99aef",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ilakatra",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4354673,
          lng: 47.6504382,
        },
        _id: "64f75285ef8f606073e99af0",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "ilakatra",
        fokontany: "amboangibe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.266667,
          lng: 47.65,
        },
        _id: "64f75285ef8f606073e99af1",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "sahalava",
        fokontany: "sahalava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2011853,
          lng: 47.640596,
        },
        _id: "64f75285ef8f606073e99af2",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "sahalava",
        fokontany: "ambalateny",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2267253,
          lng: 47.6602824,
        },
        _id: "64f75285ef8f606073e99af3",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "sahalava",
        fokontany: "iampa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2522738,
          lng: 47.6799761,
        },
        _id: "64f75285ef8f606073e99af4",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "sahalava",
        fokontany: "vatolava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2811742,
          lng: 47.6799761,
        },
        _id: "64f75285ef8f606073e99af5",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "sahalava",
        fokontany: "tsimialonjafy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3332306,
          lng: 47.557011,
        },
        _id: "64f75285ef8f606073e99af6",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahazoarivo",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2758934,
          lng: 47.48337,
        },
        _id: "64f75285ef8f606073e99af7",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahazoarivo",
        fokontany: "ambohitsara II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2761037,
          lng: 47.4392352,
        },
        _id: "64f75285ef8f606073e99af8",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahazoarivo",
        fokontany: "mahasoa II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2692009,
          lng: 47.5373633,
        },
        _id: "64f75285ef8f606073e99af9",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahazoarivo",
        fokontany: "ankaboba",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2850438,
          lng: 47.5717516,
        },
        _id: "64f75285ef8f606073e99afa",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahazoarivo",
        fokontany: "tamboholava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2848745,
          lng: 47.6012453,
        },
        _id: "64f75285ef8f606073e99afb",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahazoarivo",
        fokontany: "kelimamoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3047324,
          lng: 47.48337,
        },
        _id: "64f75285ef8f606073e99afc",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahazoarivo",
        fokontany: "mahavanona",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3013255,
          lng: 47.52508719999999,
        },
        _id: "64f75285ef8f606073e99afd",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahazoarivo",
        fokontany: "felefika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3335078,
          lng: 47.4980899,
        },
        _id: "64f75285ef8f606073e99afe",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahazoarivo",
        fokontany: "mahasoa I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3433062,
          lng: 47.4539427,
        },
        _id: "64f75285ef8f606073e99aff",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahazoarivo",
        fokontany: "fatsikala",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3782999,
          lng: 47.5177229,
        },
        _id: "64f75285ef8f606073e99b00",
        province: "fianarantsoa",
        region: "vatovavy fitovinany",
        district: "vohipeno",
        commune: "mahazoarivo",
        fokontany: "ambalangavo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4110751,
          lng: 46.1195455,
        },
        _id: "64f75285ef8f606073e99b01",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ihosy",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3724809,
          lng: 46.127902,
        },
        _id: "64f75285ef8f606073e99b02",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ihosy",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3899749,
          lng: 46.1446194,
        },
        _id: "64f75285ef8f606073e99b03",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ihosy",
        fokontany: "soanatao",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3898596,
          lng: 46.1302899,
        },
        _id: "64f75285ef8f606073e99b04",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ihosy",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4017842,
          lng: 46.1410366,
        },
        _id: "64f75285ef8f606073e99b05",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ihosy",
        fokontany: "tanakaompania",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3977408,
          lng: 46.1290959,
        },
        _id: "64f75285ef8f606073e99b06",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ihosy",
        fokontany: "fanjakamandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4008577,
          lng: 46.1243205,
        },
        _id: "64f75285ef8f606073e99b07",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ihosy",
        fokontany: "andrefantsena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4070595,
          lng: 46.1111904,
        },
        _id: "64f75285ef8f606073e99b08",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ihosy",
        fokontany: "ambaniandrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4088278,
          lng: 46.1338719,
        },
        _id: "64f75285ef8f606073e99b09",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ihosy",
        fokontany: "anarafanja",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4074158,
          lng: 46.15417480000001,
        },
        _id: "64f75285ef8f606073e99b0a",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ihosy",
        fokontany: "ankadilanambe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4100264,
          lng: 46.135663,
        },
        _id: "64f75285ef8f606073e99b0b",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ihosy",
        fokontany: "manjakandrianombana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4135327,
          lng: 46.1302899,
        },
        _id: "64f75285ef8f606073e99b0c",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ihosy",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3858257,
          lng: 46.094096,
        },
        _id: "64f75285ef8f606073e99b0d",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ankily",
        fokontany: "ankily",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3214726,
          lng: 46.06109,
        },
        _id: "64f75285ef8f606073e99b0e",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ankily",
        fokontany: "ankatonia",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3403943,
          lng: 46.06109,
        },
        _id: "64f75285ef8f606073e99b0f",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ankily",
        fokontany: "amparambatovita",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3562615,
          lng: 46.0730139,
        },
        _id: "64f75285ef8f606073e99b10",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ankily",
        fokontany: "vatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.350202,
          lng: 46.10402999999999,
        },
        _id: "64f75285ef8f606073e99b11",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ankily",
        fokontany: "andranomanitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3877006,
          lng: 46.06109,
        },
        _id: "64f75285ef8f606073e99b12",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ankily",
        fokontany: "ampandratokana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4068755,
          lng: 46.0897124,
        },
        _id: "64f75285ef8f606073e99b13",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ankily",
        fokontany: "anadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3508478,
          lng: 46.1900242,
        },
        _id: "64f75285ef8f606073e99b14",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ambia",
        fokontany: "ambia",
        __v: 0,
      },
      {
        coords: {
          lat: -22.283333,
          lng: 46.216667,
        },
        _id: "64f75285ef8f606073e99b15",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ambia",
        fokontany: "marofivango",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3029789,
          lng: 46.1183518,
        },
        _id: "64f75285ef8f606073e99b16",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ambia",
        fokontany: "sambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3260204,
          lng: 46.25701369999999,
        },
        _id: "64f75285ef8f606073e99b17",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ambia",
        fokontany: "ambararata avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3379408,
          lng: 46.1517858,
        },
        _id: "64f75285ef8f606073e99b18",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ambia",
        fokontany: "bemandresy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3765602,
          lng: 46.2522257,
        },
        _id: "64f75285ef8f606073e99b19",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ambia",
        fokontany: "andranoboaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4583194,
          lng: 46.1995885,
        },
        _id: "64f75285ef8f606073e99b1a",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ambia",
        fokontany: "manamisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4801431,
          lng: 46.1661217,
        },
        _id: "64f75285ef8f606073e99b1b",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "tolohomiady",
        fokontany: "tolohomiady",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4579549,
          lng: 46.15656389999999,
        },
        _id: "64f75285ef8f606073e99b1c",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "tolohomiady",
        fokontany: "androtsy sud",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4864378,
          lng: 46.1637321,
        },
        _id: "64f75285ef8f606073e99b1d",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "tolohomiady",
        fokontany: "andranomangatsiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5023359,
          lng: 46.1756813,
        },
        _id: "64f75285ef8f606073e99b1e",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "tolohomiady",
        fokontany: "benato",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5212867,
          lng: 46.1756813,
        },
        _id: "64f75285ef8f606073e99b1f",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "tolohomiady",
        fokontany: "ankazotsifantatra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4306809,
          lng: 46.10582,
        },
        _id: "64f75285ef8f606073e99b20",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "irina",
        fokontany: "irina",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4450561,
          lng: 46.12551430000001,
        },
        _id: "64f75285ef8f606073e99b21",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "irina",
        fokontany: "ihivoka II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4592601,
          lng: 46.12551430000001,
        },
        _id: "64f75285ef8f606073e99b22",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "irina",
        fokontany: "ihivoka I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.495173,
          lng: 46.0849408,
        },
        _id: "64f75285ef8f606073e99b23",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "irina",
        fokontany: "namodila",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4704796,
          lng: 46.1446194,
        },
        _id: "64f75285ef8f606073e99b24",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "irina",
        fokontany: "iaborano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5112747,
          lng: 46.1183518,
        },
        _id: "64f75285ef8f606073e99b25",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "irina",
        fokontany: "ianaboro",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4826617,
          lng: 46.2785656,
        },
        _id: "64f75285ef8f606073e99b26",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "sahambano",
        fokontany: "sahambano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3643202,
          lng: 46.3145064,
        },
        _id: "64f75285ef8f606073e99b27",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "sahambano",
        fokontany: "andranomasy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4277123,
          lng: 46.3336857,
        },
        _id: "64f75285ef8f606073e99b28",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "sahambano",
        fokontany: "andakana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4333462,
          lng: 46.2378645,
        },
        _id: "64f75285ef8f606073e99b29",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "sahambano",
        fokontany: "ambatosia",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5793438,
          lng: 46.3049196,
        },
        _id: "64f75285ef8f606073e99b2a",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "sahambano",
        fokontany: "beadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7828395,
          lng: 46.400872,
        },
        _id: "64f75285ef8f606073e99b2b",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "sahambano",
        fokontany: "bemahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8064669,
          lng: 46.2474381,
        },
        _id: "64f75285ef8f606073e99b2c",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "analaliry",
        fokontany: "analaliry",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5559387,
          lng: 46.1661217,
        },
        _id: "64f75285ef8f606073e99b2d",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "analaliry",
        fokontany: "marohazo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5406359,
          lng: 46.2187227,
        },
        _id: "64f75285ef8f606073e99b2e",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "analaliry",
        fokontany: "ambaho",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5910135,
          lng: 46.1995885,
        },
        _id: "64f75285ef8f606073e99b2f",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "analaliry",
        fokontany: "vondrony",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7809485,
          lng: 46.2282927,
        },
        _id: "64f75285ef8f606073e99b30",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "analaliry",
        fokontany: "sakamana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8830728,
          lng: 46.3049196,
        },
        _id: "64f75285ef8f606073e99b31",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "analaliry",
        fokontany: "amanda",
        __v: 0,
      },
      {
        coords: {
          lat: -22.197969,
          lng: 46.090309,
        },
        _id: "64f75285ef8f606073e99b32",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "mahasoa",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8193463,
          lng: 45.79002089999999,
        },
        _id: "64f75285ef8f606073e99b33",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "mahasoa",
        fokontany: "sakena toby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.916667,
          lng: 45.98333299999999,
        },
        _id: "64f75285ef8f606073e99b34",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "mahasoa",
        fokontany: "ampandrasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9960704,
          lng: 45.92298659999999,
        },
        _id: "64f75285ef8f606073e99b35",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "mahasoa",
        fokontany: "lohavondro",
        __v: 0,
      },
      {
        coords: {
          lat: -22.066667,
          lng: 46.05,
        },
        _id: "64f75285ef8f606073e99b36",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "mahasoa",
        fokontany: "anaviavy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1416568,
          lng: 46.0467851,
        },
        _id: "64f75285ef8f606073e99b37",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "mahasoa",
        fokontany: "manamby",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1851317,
          lng: 45.9515272,
        },
        _id: "64f75285ef8f606073e99b38",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "mahasoa",
        fokontany: "ankazovinelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1485495,
          lng: 46.1517858,
        },
        _id: "64f75285ef8f606073e99b39",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "mahasoa",
        fokontany: "mikaiky",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1733289,
          lng: 46.0706289,
        },
        _id: "64f75285ef8f606073e99b3a",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "mahasoa",
        fokontany: "beronono",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1983038,
          lng: 46.0324844,
        },
        _id: "64f75285ef8f606073e99b3b",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "mahasoa",
        fokontany: "beadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2362308,
          lng: 46.0467851,
        },
        _id: "64f75285ef8f606073e99b3c",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "mahasoa",
        fokontany: "ihazoroa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2305542,
          lng: 46.1422309,
        },
        _id: "64f75285ef8f606073e99b3d",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "mahasoa",
        fokontany: "salaza",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2684287,
          lng: 46.1422309,
        },
        _id: "64f75285ef8f606073e99b3e",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "mahasoa",
        fokontany: "tsiloakarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2863007,
          lng: 45.9991326,
        },
        _id: "64f75285ef8f606073e99b3f",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "mahasoa",
        fokontany: "bekifafa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4833329,
          lng: 45.833333,
        },
        _id: "64f75285ef8f606073e99b40",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ambatolahy",
        fokontany: "ambatolahy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3489854,
          lng: 45.9610446,
        },
        _id: "64f75285ef8f606073e99b41",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ambatolahy",
        fokontany: "antsoha maromiandra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3986236,
          lng: 45.8849587,
        },
        _id: "64f75285ef8f606073e99b42",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ambatolahy",
        fokontany: "ambinanitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4437813,
          lng: 45.98960779999999,
        },
        _id: "64f75285ef8f606073e99b43",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ambatolahy",
        fokontany: "kelivondraka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4354382,
          lng: 45.7995062,
        },
        _id: "64f75285ef8f606073e99b44",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ambatolahy",
        fokontany: "kibanivato",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5366634,
          lng: 45.84696100000001,
        },
        _id: "64f75285ef8f606073e99b45",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ambatolahy",
        fokontany: "ivaro est",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5290451,
          lng: 45.7426226,
        },
        _id: "64f75285ef8f606073e99b46",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ambatolahy",
        fokontany: "ivaro ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6121859,
          lng: 45.84696100000001,
        },
        _id: "64f75285ef8f606073e99b47",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ambatolahy",
        fokontany: "antaly ampandra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6290715,
          lng: 45.704738,
        },
        _id: "64f75285ef8f606073e99b48",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ambatolahy",
        fokontany: "ampandrabe sud",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6860568,
          lng: 45.73314860000001,
        },
        _id: "64f75285ef8f606073e99b49",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ambatolahy",
        fokontany: "mahasoa amberomena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.703581,
          lng: 45.6479676,
        },
        _id: "64f75285ef8f606073e99b4a",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ambatolahy",
        fokontany: "maromiandra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6521538,
          lng: 46.01818799999999,
        },
        _id: "64f75285ef8f606073e99b4b",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "soamatasy",
        fokontany: "soamatasy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.57995,
          lng: 45.988461,
        },
        _id: "64f75285ef8f606073e99b4c",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "soamatasy",
        fokontany: "mangona",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6906975,
          lng: 46.0753991,
        },
        _id: "64f75285ef8f606073e99b4d",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "soamatasy",
        fokontany: "ambararata",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6603472,
          lng: 45.9182314,
        },
        _id: "64f75285ef8f606073e99b4e",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "soamatasy",
        fokontany: "ambinany antaly",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6789597,
          lng: 46.1517858,
        },
        _id: "64f75285ef8f606073e99b4f",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "soamatasy",
        fokontany: "andrera",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7733525,
          lng: 46.1231267,
        },
        _id: "64f75285ef8f606073e99b50",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "soamatasy",
        fokontany: "vohibato tritriva",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2158732,
          lng: 46.3600693,
        },
        _id: "64f75285ef8f606073e99b51",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "zazafotsy",
        fokontany: "zazafotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8457722,
          lng: 46.2665911,
        },
        _id: "64f75285ef8f606073e99b52",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "zazafotsy",
        fokontany: "itremo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9216868,
          lng: 46.3528724,
        },
        _id: "64f75285ef8f606073e99b53",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "zazafotsy",
        fokontany: "vonje",
        __v: 0,
      },
      {
        coords: {
          lat: -21.908739,
          lng: 46.295551,
        },
        _id: "64f75285ef8f606073e99b54",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "zazafotsy",
        fokontany: "mahabodo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9659831,
          lng: 46.3720666,
        },
        _id: "64f75285ef8f606073e99b55",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "zazafotsy",
        fokontany: "fandana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9842338,
          lng: 46.4281927,
        },
        _id: "64f75285ef8f606073e99b56",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "zazafotsy",
        fokontany: "mahasoa sud",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0355378,
          lng: 46.3528724,
        },
        _id: "64f75285ef8f606073e99b57",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "zazafotsy",
        fokontany: "voatavo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1181076,
          lng: 46.4873892,
        },
        _id: "64f75285ef8f606073e99b58",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "zazafotsy",
        fokontany: "ambalamahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2757133,
          lng: 46.3049196,
        },
        _id: "64f75285ef8f606073e99b59",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "zazafotsy",
        fokontany: "ankazobetroka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9213518,
          lng: 46.1517858,
        },
        _id: "64f75285ef8f606073e99b5a",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "antsoha",
        fokontany: "andranoboaky est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.754271,
          lng: 46.06374,
        },
        _id: "64f75285ef8f606073e99b5b",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "antsoha",
        fokontany: "ampandramamaha",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7888854,
          lng: 46.1804618,
        },
        _id: "64f75285ef8f606073e99b5c",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "antsoha",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9588158,
          lng: 46.0372508,
        },
        _id: "64f75285ef8f606073e99b5d",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "antsoha",
        fokontany: "benonoky",
        __v: 0,
      },
      {
        coords: {
          lat: -21.965658,
          lng: 46.1995885,
        },
        _id: "64f75285ef8f606073e99b5e",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "antsoha",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0122732,
          lng: 46.0134234,
        },
        _id: "64f75285ef8f606073e99b5f",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "antsoha",
        fokontany: "matsinjorea",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3271057,
          lng: 46.4585333,
        },
        _id: "64f75285ef8f606073e99b60",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "sakalalina",
        fokontany: "bekijoly",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3719405,
          lng: 46.5644206,
        },
        _id: "64f75285ef8f606073e99b61",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "sakalalina",
        fokontany: "mahatsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2638883,
          lng: 46.4970116,
        },
        _id: "64f75285ef8f606073e99b62",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "sakalalina",
        fokontany: "andemaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4287494,
          lng: 46.5066359,
        },
        _id: "64f75285ef8f606073e99b63",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "sakalalina",
        fokontany: "iangaty",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4282631,
          lng: 46.4200849,
        },
        _id: "64f75285ef8f606073e99b64",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "sakalalina",
        fokontany: "soaserana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.335239,
          lng: 45.707901,
        },
        _id: "64f75285ef8f606073e99b65",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "satrokala",
        fokontany: "satrokala",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8181087,
          lng: 45.5629394,
        },
        _id: "64f75285ef8f606073e99b66",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "satrokala",
        fokontany: "bevaho ambararatabe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.066667,
          lng: 45.616667,
        },
        _id: "64f75285ef8f606073e99b67",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "satrokala",
        fokontany: "bemelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0198748,
          lng: 45.7142063,
        },
        _id: "64f75285ef8f606073e99b68",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "satrokala",
        fokontany: "vohimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2346434,
          lng: 45.84696100000001,
        },
        _id: "64f75285ef8f606073e99b69",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "satrokala",
        fokontany: "miary malama",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1395975,
          lng: 45.76157629999999,
        },
        _id: "64f75285ef8f606073e99b6a",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "satrokala",
        fokontany: "anja soatanimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.171345,
          lng: 45.7995062,
        },
        _id: "64f75285ef8f606073e99b6b",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "satrokala",
        fokontany: "mihery malama",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1885942,
          lng: 45.6290592,
        },
        _id: "64f75285ef8f606073e99b6c",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "satrokala",
        fokontany: "fanatolizamiary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2206473,
          lng: 45.6952715,
        },
        _id: "64f75285ef8f606073e99b6d",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "satrokala",
        fokontany: "mandarano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2194585,
          lng: 45.5818214,
        },
        _id: "64f75285ef8f606073e99b6e",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "satrokala",
        fokontany: "ambohitsimamory",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2974632,
          lng: 45.83746619999999,
        },
        _id: "64f75285ef8f606073e99b6f",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "satrokala",
        fokontany: "manambaroa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3726526,
          lng: 45.8089934,
        },
        _id: "64f75285ef8f606073e99b70",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "satrokala",
        fokontany: "antrobika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5213497,
          lng: 45.6385125,
        },
        _id: "64f75285ef8f606073e99b71",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "andiolava",
        fokontany: "andiolava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3815332,
          lng: 45.506339,
        },
        _id: "64f75285ef8f606073e99b72",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "andiolava",
        fokontany: "vatambe nanarena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4,
          lng: 45.566667,
        },
        _id: "64f75285ef8f606073e99b73",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "andiolava",
        fokontany: "vohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5405836,
          lng: 45.6668835,
        },
        _id: "64f75285ef8f606073e99b74",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "andiolava",
        fokontany: "ambatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.520128,
          lng: 45.5535013,
        },
        _id: "64f75285ef8f606073e99b75",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "andiolava",
        fokontany: "ankazoabokely",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5712975,
          lng: 45.6196079,
        },
        _id: "64f75285ef8f606073e99b76",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "andiolava",
        fokontany: "amboloando",
        __v: 0,
      },
      {
        coords: {
          lat: -22.666667,
          lng: 45.583333,
        },
        _id: "64f75285ef8f606073e99b77",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "andiolava",
        fokontany: "ambondrobe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5508228,
          lng: 46.5090422,
        },
        _id: "64f75285ef8f606073e99b78",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "analavoka",
        fokontany: "analavoka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5056265,
          lng: 46.6512239,
        },
        _id: "64f75285ef8f606073e99b79",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "analavoka",
        fokontany: "mahatsinjo isifotra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5720154,
          lng: 46.3744664,
        },
        _id: "64f75285ef8f606073e99b7a",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "analavoka",
        fokontany: "ambinda",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5869914,
          lng: 46.4681501,
        },
        _id: "64f75285ef8f606073e99b7b",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "analavoka",
        fokontany: "anosibe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6193888,
          lng: 46.5644206,
        },
        _id: "64f75285ef8f606073e99b7c",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "analavoka",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6693157,
          lng: 46.4585333,
        },
        _id: "64f75285ef8f606073e99b7d",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "analavoka",
        fokontany: "misonjy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5808852,
          lng: 45.4309775,
        },
        _id: "64f75285ef8f606073e99b7e",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ranohira",
        fokontany: "ranohira ville",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2174717,
          lng: 45.4121561,
        },
        _id: "64f75285ef8f606073e99b7f",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ranohira",
        fokontany: "fandranarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3419943,
          lng: 45.3651355,
        },
        _id: "64f75285ef8f606073e99b80",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ranohira",
        fokontany: "andriamanero",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4305824,
          lng: 45.4309775,
        },
        _id: "64f75285ef8f606073e99b81",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ranohira",
        fokontany: "ranohira basse",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5404561,
          lng: 45.2618568,
        },
        _id: "64f75285ef8f606073e99b82",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ranohira",
        fokontany: "ankiliabo malio",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5378971,
          lng: 45.1213916,
        },
        _id: "64f75285ef8f606073e99b83",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ranohira",
        fokontany: "bereketa malio",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5695705,
          lng: 45.506339,
        },
        _id: "64f75285ef8f606073e99b84",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ranohira",
        fokontany: "andasy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6375564,
          lng: 45.1120424,
        },
        _id: "64f75285ef8f606073e99b85",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ranohira",
        fokontany: "mandabe malio",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6428622,
          lng: 45.3933422,
        },
        _id: "64f75285ef8f606073e99b86",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ranohira",
        fokontany: "mariana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6813726,
          lng: 45.2759268,
        },
        _id: "64f75285ef8f606073e99b87",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ranohira",
        fokontany: "andohan'ilakaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6862759,
          lng: 45.2067905,
        },
        _id: "64f75285ef8f606073e99b88",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ranohira",
        fokontany: "mangarahara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7000241,
          lng: 45.2108889,
        },
        _id: "64f75285ef8f606073e99b89",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ranohira",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7099154,
          lng: 45.2173301,
        },
        _id: "64f75285ef8f606073e99b8a",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ranohira",
        fokontany: "ambalafary satranabo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6981677,
          lng: 45.5110531,
        },
        _id: "64f75285ef8f606073e99b8b",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ranohira",
        fokontany: "andriatomily",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7692118,
          lng: 45.1400956,
        },
        _id: "64f75285ef8f606073e99b8c",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ranohira",
        fokontany: "sakaviro mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6958567,
          lng: 45.2173301,
        },
        _id: "64f75285ef8f606073e99b8d",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ilakaka",
        fokontany: "ilakaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7004803,
          lng: 45.4639333,
        },
        _id: "64f75285ef8f606073e99b8e",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ilakaka",
        fokontany: "sakavatoy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7219225,
          lng: 45.2712363,
        },
        _id: "64f75285ef8f606073e99b8f",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ilakaka",
        fokontany: "bemanga",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7539305,
          lng: 45.3087733,
        },
        _id: "64f75285ef8f606073e99b90",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ilakaka",
        fokontany: "mitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.783333,
          lng: 45.4,
        },
        _id: "64f75285ef8f606073e99b91",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ilakaka",
        fokontany: "mandaboaly",
        __v: 0,
      },
      {
        coords: {
          lat: -22.783333,
          lng: 45.516667,
        },
        _id: "64f75285ef8f606073e99b92",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ilakaka",
        fokontany: "andravindahy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8047303,
          lng: 45.3463405,
        },
        _id: "64f75285ef8f606073e99b93",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ilakaka",
        fokontany: "mandatany",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9155589,
          lng: 45.2618568,
        },
        _id: "64f75285ef8f606073e99b94",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ilakaka",
        fokontany: "saheny II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9491648,
          lng: 45.0933497,
        },
        _id: "64f75285ef8f606073e99b95",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ilakaka",
        fokontany: "vohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9173878,
          lng: 45.3463405,
        },
        _id: "64f75285ef8f606073e99b96",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ilakaka",
        fokontany: "tanandava ilola",
        __v: 0,
      },
      {
        coords: {
          lat: -23.01228,
          lng: 45.3165819,
        },
        _id: "64f75285ef8f606073e99b97",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "ilakaka",
        fokontany: "sakalama",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9669906,
          lng: 45.3933422,
        },
        _id: "64f75285ef8f606073e99b98",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "menamaty iloto",
        fokontany: "iloto",
        __v: 0,
      },
      {
        coords: {
          lat: -21.72032,
          lng: 45.446079,
        },
        _id: "64f75285ef8f606073e99b99",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "menamaty iloto",
        fokontany: "matambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8296977,
          lng: 45.4309775,
        },
        _id: "64f75285ef8f606073e99b9a",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "menamaty iloto",
        fokontany: "famofo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9176125,
          lng: 45.4686431,
        },
        _id: "64f75285ef8f606073e99b9b",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "menamaty iloto",
        fokontany: "vohimanao",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9283692,
          lng: 45.2806177,
        },
        _id: "64f75285ef8f606073e99b9c",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "menamaty iloto",
        fokontany: "ambalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9869417,
          lng: 45.5204827,
        },
        _id: "64f75285ef8f606073e99b9d",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "menamaty iloto",
        fokontany: "fatanadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0431897,
          lng: 45.506339,
        },
        _id: "64f75285ef8f606073e99b9e",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "menamaty iloto",
        fokontany: "tsiloakarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0795883,
          lng: 45.3933422,
        },
        _id: "64f75285ef8f606073e99b9f",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "menamaty iloto",
        fokontany: "iantoha",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0537363,
          lng: 45.3181623,
        },
        _id: "64f75285ef8f606073e99ba0",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ihosy",
        commune: "menamaty iloto",
        fokontany: "dangovato",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5001736,
          lng: 46.8931368,
        },
        _id: "64f75285ef8f606073e99ba1",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivohibe",
        fokontany: "ivohibe atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4301056,
          lng: 46.8834379,
        },
        _id: "64f75285ef8f606073e99ba2",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivohibe",
        fokontany: "andongy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4361483,
          lng: 46.7575221,
        },
        _id: "64f75285ef8f606073e99ba3",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivohibe",
        fokontany: "andreamalama",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5124505,
          lng: 46.7575221,
        },
        _id: "64f75285ef8f606073e99ba4",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivohibe",
        fokontany: "ivaky",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5442239,
          lng: 46.7526854,
        },
        _id: "64f75285ef8f606073e99ba5",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivohibe",
        fokontany: "antondrotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4935785,
          lng: 46.815598,
        },
        _id: "64f75285ef8f606073e99ba6",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivohibe",
        fokontany: "andreabe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4747554,
          lng: 46.9173921,
        },
        _id: "64f75285ef8f606073e99ba7",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivohibe",
        fokontany: "ivohibe avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5575831,
          lng: 46.9222445,
        },
        _id: "64f75285ef8f606073e99ba8",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivohibe",
        fokontany: "sandranavy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6277016,
          lng: 46.9319509,
        },
        _id: "64f75285ef8f606073e99ba9",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivohibe",
        fokontany: "vohitrakanga",
        __v: 0,
      },
      {
        coords: {
          lat: -22.666667,
          lng: 46.90000000000001,
        },
        _id: "64f75285ef8f606073e99baa",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivohibe",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6370832,
          lng: 46.8882871,
        },
        _id: "64f75285ef8f606073e99bab",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivohibe",
        fokontany: "antaramena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6082445,
          lng: 46.8446611,
        },
        _id: "64f75285ef8f606073e99bac",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivohibe",
        fokontany: "ankazobe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5952118,
          lng: 46.7768732,
        },
        _id: "64f75285ef8f606073e99bad",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivohibe",
        fokontany: "bekininy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6328936,
          lng: 46.6898516,
        },
        _id: "64f75285ef8f606073e99bae",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivohibe",
        fokontany: "longoza",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6971008,
          lng: 46.7962319,
        },
        _id: "64f75285ef8f606073e99baf",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivohibe",
        fokontany: "ranomena bekininy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.31678,
          lng: 46.77277,
        },
        _id: "64f75285ef8f606073e99bb0",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "antambohobe",
        fokontany: "antambohobe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1372656,
          lng: 46.574058,
        },
        _id: "64f75285ef8f606073e99bb1",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "antambohobe",
        fokontany: "vohimary II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1754701,
          lng: 46.6898516,
        },
        _id: "64f75285ef8f606073e99bb2",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "antambohobe",
        fokontany: "mahatsinjoroa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2961329,
          lng: 46.6801919,
        },
        _id: "64f75285ef8f606073e99bb3",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "antambohobe",
        fokontany: "ivolo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2390475,
          lng: 46.7381783,
        },
        _id: "64f75285ef8f606073e99bb4",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "antambohobe",
        fokontany: "lapay",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2642297,
          lng: 46.612626,
        },
        _id: "64f75285ef8f606073e99bb5",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "antambohobe",
        fokontany: "vohitrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3532127,
          lng: 46.6512239,
        },
        _id: "64f75285ef8f606073e99bb6",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "antambohobe",
        fokontany: "kotipa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2773071,
          lng: 46.8834379,
        },
        _id: "64f75285ef8f606073e99bb7",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "antambohobe",
        fokontany: "iatongo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3074949,
          lng: 46.8228622,
        },
        _id: "64f75285ef8f606073e99bb8",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "antambohobe",
        fokontany: "befondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3663135,
          lng: 46.805914,
        },
        _id: "64f75285ef8f606073e99bb9",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "antambohobe",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3473457,
          lng: 46.8931368,
        },
        _id: "64f75285ef8f606073e99bba",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "antambohobe",
        fokontany: "fierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.712179,
          lng: 46.958408,
        },
        _id: "64f75285ef8f606073e99bbb",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "maropaika",
        fokontany: "maropaika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6407817,
          lng: 47.0291162,
        },
        _id: "64f75285ef8f606073e99bbc",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "maropaika",
        fokontany: "marolaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6984819,
          lng: 47.11672369999999,
        },
        _id: "64f75285ef8f606073e99bbd",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "maropaika",
        fokontany: "anivorano est",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6977062,
          lng: 46.91254010000001,
        },
        _id: "64f75285ef8f606073e99bbe",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "maropaika",
        fokontany: "ampatsinakoho",
        __v: 0,
      },
      {
        coords: {
          lat: -22.73562,
          lng: 46.8543525,
        },
        _id: "64f75285ef8f606073e99bbf",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "maropaika",
        fokontany: "amboza",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7327719,
          lng: 46.9173921,
        },
        _id: "64f75285ef8f606073e99bc0",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "maropaika",
        fokontany: "antanandava sud",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7643579,
          lng: 46.8688931,
        },
        _id: "64f75285ef8f606073e99bc1",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "maropaika",
        fokontany: "ambatopaika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7871182,
          lng: 46.9513691,
        },
        _id: "64f75285ef8f606073e99bc2",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "maropaika",
        fokontany: "mahamanina",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7932753,
          lng: 46.91254010000001,
        },
        _id: "64f75285ef8f606073e99bc3",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "maropaika",
        fokontany: "mavogisa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8571162,
          lng: 46.9319509,
        },
        _id: "64f75285ef8f606073e99bc4",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "maropaika",
        fokontany: "beadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8900622,
          lng: 47.11672369999999,
        },
        _id: "64f75285ef8f606073e99bc5",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "maropaika",
        fokontany: "iabofotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9276846,
          lng: 46.9999471,
        },
        _id: "64f75285ef8f606073e99bc6",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "maropaika",
        fokontany: "maroangaty",
        __v: 0,
      },
      {
        coords: {
          lat: -22.496241,
          lng: 47.034019,
        },
        _id: "64f75285ef8f606073e99bc7",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivongo",
        fokontany: "ivongo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2772767,
          lng: 46.9999471,
        },
        _id: "64f75285ef8f606073e99bc8",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivongo",
        fokontany: "ambahatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.35,
          lng: 47.05,
        },
        _id: "64f75285ef8f606073e99bc9",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivongo",
        fokontany: "anaviavy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.379251,
          lng: 46.9610809,
        },
        _id: "64f75285ef8f606073e99bca",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivongo",
        fokontany: "sakaroa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3920232,
          lng: 47.11672369999999,
        },
        _id: "64f75285ef8f606073e99bcb",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivongo",
        fokontany: "ambinaninony",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5197317,
          lng: 47.1557087,
        },
        _id: "64f75285ef8f606073e99bcc",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivongo",
        fokontany: "andranolava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5578786,
          lng: 47.0388429,
        },
        _id: "64f75285ef8f606073e99bcd",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "ivohibe",
        commune: "ivongo",
        fokontany: "andranonjanahary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.107531,
          lng: 46.650089,
        },
        _id: "64f75285ef8f606073e99bce",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "iakora",
        fokontany: "iakora",
        __v: 0,
      },
      {
        coords: {
          lat: -22.88565,
          lng: 46.5355198,
        },
        _id: "64f75285ef8f606073e99bcf",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "iakora",
        fokontany: "marotoko",
        __v: 0,
      },
      {
        coords: {
          lat: -22.933333,
          lng: 46.616667,
        },
        _id: "64f75285ef8f606073e99bd0",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "iakora",
        fokontany: "beadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0142405,
          lng: 46.6898516,
        },
        _id: "64f75285ef8f606073e99bd1",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "iakora",
        fokontany: "menambolo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0660967,
          lng: 46.7865516,
        },
        _id: "64f75285ef8f606073e99bd2",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "iakora",
        fokontany: "andranata",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9851971,
          lng: 46.6463975,
        },
        _id: "64f75285ef8f606073e99bd3",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "iakora",
        fokontany: "iketsa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0109165,
          lng: 46.400872,
        },
        _id: "64f75285ef8f606073e99bd4",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "iakora",
        fokontany: "andriamora",
        __v: 0,
      },
      {
        coords: {
          lat: -23.038937,
          lng: 46.6222727,
        },
        _id: "64f75285ef8f606073e99bd5",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "iakora",
        fokontany: "reafatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.076015,
          lng: 46.5355198,
        },
        _id: "64f75285ef8f606073e99bd6",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "iakora",
        fokontany: "ampanihy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0903607,
          lng: 46.6753627,
        },
        _id: "64f75285ef8f606073e99bd7",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "iakora",
        fokontany: "tainakanga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1291562,
          lng: 46.7333436,
        },
        _id: "64f75285ef8f606073e99bd8",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "iakora",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.133333,
          lng: 46.4,
        },
        _id: "64f75285ef8f606073e99bd9",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "iakora",
        fokontany: "andranomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1671492,
          lng: 46.7188421,
        },
        _id: "64f75285ef8f606073e99bda",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "iakora",
        fokontany: "ambia",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1781894,
          lng: 46.5836972,
        },
        _id: "64f75285ef8f606073e99bdb",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "iakora",
        fokontany: "andamaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2053051,
          lng: 46.7188421,
        },
        _id: "64f75285ef8f606073e99bdc",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "iakora",
        fokontany: "anarabe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2796492,
          lng: 46.574058,
        },
        _id: "64f75285ef8f606073e99bdd",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "iakora",
        fokontany: "nanarena bekofafa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2559339,
          lng: 46.6995133,
        },
        _id: "64f75285ef8f606073e99bde",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "iakora",
        fokontany: "rarazy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.766667,
          lng: 46.633333,
        },
        _id: "64f75285ef8f606073e99bdf",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "ranotsara nord",
        fokontany: "ranotsara nord",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7083078,
          lng: 46.574058,
        },
        _id: "64f75285ef8f606073e99be0",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "ranotsara nord",
        fokontany: "bekatra tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7378543,
          lng: 46.7043448,
        },
        _id: "64f75285ef8f606073e99be1",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "ranotsara nord",
        fokontany: "manitsy fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.75,
          lng: 46.566667,
        },
        _id: "64f75285ef8f606073e99be2",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "ranotsara nord",
        fokontany: "mandabe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7735689,
          lng: 46.763561,
        },
        _id: "64f75285ef8f606073e99be3",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "ranotsara nord",
        fokontany: "andranombao",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7854183,
          lng: 46.6898516,
        },
        _id: "64f75285ef8f606073e99be4",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "ranotsara nord",
        fokontany: "ranotsarabe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8151795,
          lng: 46.8495065,
        },
        _id: "64f75285ef8f606073e99be5",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "ranotsara nord",
        fokontany: "tsanerena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8493701,
          lng: 46.7381783,
        },
        _id: "64f75285ef8f606073e99be6",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "ranotsara nord",
        fokontany: "ankazovinelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8563233,
          lng: 46.815598,
        },
        _id: "64f75285ef8f606073e99be7",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "ranotsara nord",
        fokontany: "ambotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8616881,
          lng: 46.6898516,
        },
        _id: "64f75285ef8f606073e99be8",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "ranotsara nord",
        fokontany: "beadabo manatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8823471,
          lng: 46.8931368,
        },
        _id: "64f75285ef8f606073e99be9",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "ranotsara nord",
        fokontany: "morafeno II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9010286,
          lng: 46.83497149999999,
        },
        _id: "64f75285ef8f606073e99bea",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "ranotsara nord",
        fokontany: "masopasy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4972488,
          lng: 46.6801919,
        },
        _id: "64f75285ef8f606073e99beb",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "begogo",
        fokontany: "begogo centre",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2827128,
          lng: 46.805914,
        },
        _id: "64f75285ef8f606073e99bec",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "begogo",
        fokontany: "kiremba",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3,
          lng: 46.7,
        },
        _id: "64f75285ef8f606073e99bed",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "begogo",
        fokontany: "ampangorina",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4222232,
          lng: 46.7671967,
        },
        _id: "64f75285ef8f606073e99bee",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "begogo",
        fokontany: "anketsihetsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4567998,
          lng: 46.5355198,
        },
        _id: "64f75285ef8f606073e99bef",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "begogo",
        fokontany: "anezandava I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4990124,
          lng: 46.7962319,
        },
        _id: "64f75285ef8f606073e99bf0",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "begogo",
        fokontany: "beapombo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5616038,
          lng: 46.7285093,
        },
        _id: "64f75285ef8f606073e99bf1",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "begogo",
        fokontany: "antamenaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5882794,
          lng: 46.805914,
        },
        _id: "64f75285ef8f606073e99bf2",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "begogo",
        fokontany: "mahanoro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5596898,
          lng: 46.612626,
        },
        _id: "64f75285ef8f606073e99bf3",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "begogo",
        fokontany: "ambondro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6183633,
          lng: 46.6995133,
        },
        _id: "64f75285ef8f606073e99bf4",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "begogo",
        fokontany: "nanarena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6240597,
          lng: 46.660878,
        },
        _id: "64f75285ef8f606073e99bf5",
        province: "fianarantsoa",
        region: "ihorombe",
        district: "iakora",
        commune: "begogo",
        fokontany: "ambatohirika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8214024,
          lng: 47.8340863,
        },
        _id: "64f75285ef8f606073e99bf6",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "fenoarivo atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8069364,
          lng: 47.8266788,
        },
        _id: "64f75285ef8f606073e99bf7",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "andranomakoko",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8045464,
          lng: 47.8155695,
        },
        _id: "64f75285ef8f606073e99bf8",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "mahatsara avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8129739,
          lng: 47.8248271,
        },
        _id: "64f75285ef8f606073e99bf9",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8109404,
          lng: 47.835321,
        },
        _id: "64f75285ef8f606073e99bfa",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "nosinakoho",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8110148,
          lng: 47.7982928,
        },
        _id: "64f75285ef8f606073e99bfb",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "mahatsara atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8157871,
          lng: 47.8260616,
        },
        _id: "64f75285ef8f606073e99bfc",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "impitiny",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8169786,
          lng: 47.833469,
        },
        _id: "64f75285ef8f606073e99bfd",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "fenoarivo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -22.818208,
          lng: 47.8223582,
        },
        _id: "64f75285ef8f606073e99bfe",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "ambalafary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8181987,
          lng: 47.8269874,
        },
        _id: "64f75285ef8f606073e99bff",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "marofototra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8202143,
          lng: 47.8248271,
        },
        _id: "64f75285ef8f606073e99c00",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "ambalanomby",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8210138,
          lng: 47.8272961,
        },
        _id: "64f75285ef8f606073e99c01",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8228221,
          lng: 47.828222,
        },
        _id: "64f75285ef8f606073e99c02",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "vangaindranokely",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8278851,
          lng: 47.80939859999999,
        },
        _id: "64f75285ef8f606073e99c03",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "ambalakininy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8250412,
          lng: 47.8248271,
        },
        _id: "64f75285ef8f606073e99c04",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "mahafasa andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8244324,
          lng: 47.82760469999999,
        },
        _id: "64f75285ef8f606073e99c05",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "mahafasa avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8254356,
          lng: 47.8288392,
        },
        _id: "64f75285ef8f606073e99c06",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8242252,
          lng: 47.8306911,
        },
        _id: "64f75285ef8f606073e99c07",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "ambahibe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8254265,
          lng: 47.833469,
        },
        _id: "64f75285ef8f606073e99c08",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8258188,
          lng: 47.8384079,
        },
        _id: "64f75285ef8f606073e99c09",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "amboanio",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8282615,
          lng: 47.8235927,
        },
        _id: "64f75285ef8f606073e99c0a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "mahafasa atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8399188,
          lng: 47.8279133,
        },
        _id: "64f75285ef8f606073e99c0b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "farafangana",
        fokontany: "ambohitrabo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.816667,
          lng: 47.75,
        },
        _id: "64f75285ef8f606073e99c0c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohimasy",
        fokontany: "vohimasy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7372619,
          lng: 47.6898257,
        },
        _id: "64f75285ef8f606073e99c0d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohimasy",
        fokontany: "ambohitrova",
        __v: 0,
      },
      {
        coords: {
          lat: -22.751709,
          lng: 47.6972141,
        },
        _id: "64f75285ef8f606073e99c0e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohimasy",
        fokontany: "iandraina",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7661795,
          lng: 47.6898257,
        },
        _id: "64f75285ef8f606073e99c0f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohimasy",
        fokontany: "vohibano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7822566,
          lng: 47.6799761,
        },
        _id: "64f75285ef8f606073e99c10",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohimasy",
        fokontany: "vohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8207993,
          lng: 47.6947512,
        },
        _id: "64f75285ef8f606073e99c11",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohimasy",
        fokontany: "tsararano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.74305,
          lng: 47.782269,
        },
        _id: "64f75285ef8f606073e99c12",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosivelo",
        fokontany: "anosivelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7235188,
          lng: 47.7329394,
        },
        _id: "64f75285ef8f606073e99c13",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosivelo",
        fokontany: "enifapa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7275178,
          lng: 47.7415664,
        },
        _id: "64f75285ef8f606073e99c14",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosivelo",
        fokontany: "volotara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7298114,
          lng: 47.7896566,
        },
        _id: "64f75285ef8f606073e99c15",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosivelo",
        fokontany: "marolava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7387903,
          lng: 47.7317071,
        },
        _id: "64f75285ef8f606073e99c16",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosivelo",
        fokontany: "ranolava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7418733,
          lng: 47.7896566,
        },
        _id: "64f75285ef8f606073e99c17",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosivelo",
        fokontany: "fiherena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7274471,
          lng: 47.7711552,
        },
        _id: "64f75285ef8f606073e99c18",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosivelo",
        fokontany: "ibakaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7307747,
          lng: 47.7218497,
        },
        _id: "64f75285ef8f606073e99c19",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosivelo",
        fokontany: "vohilava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7307102,
          lng: 47.7514275,
        },
        _id: "64f75285ef8f606073e99c1a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosivelo",
        fokontany: "tombatafika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7451458,
          lng: 47.7662226,
        },
        _id: "64f75285ef8f606073e99c1b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosivelo",
        fokontany: "anakatrika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7467755,
          lng: 47.7563588,
        },
        _id: "64f75285ef8f606073e99c1c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosivelo",
        fokontany: "mandatsa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7468068,
          lng: 47.7415664,
        },
        _id: "64f75285ef8f606073e99c1d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosivelo",
        fokontany: "mazavatsiotra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7821496,
          lng: 47.7538931,
        },
        _id: "64f75285ef8f606073e99c1e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosivelo",
        fokontany: "anapoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7692168,
          lng: 47.78842299999999,
        },
        _id: "64f75285ef8f606073e99c1f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosivelo",
        fokontany: "ambalavotaky",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7852577,
          lng: 47.8081645,
        },
        _id: "64f75285ef8f606073e99c20",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosivelo",
        fokontany: "lokandambo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7691655,
          lng: 47.8106327,
        },
        _id: "64f75285ef8f606073e99c21",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosivelo",
        fokontany: "manamboatra avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7209065,
          lng: 47.8106327,
        },
        _id: "64f75285ef8f606073e99c22",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosy tsararafa",
        fokontany: "tsararafa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.616667,
          lng: 47.866667,
        },
        _id: "64f75285ef8f606073e99c23",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosy tsararafa",
        fokontany: "loharano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6469037,
          lng: 47.813101,
        },
        _id: "64f75285ef8f606073e99c24",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosy tsararafa",
        fokontany: "ivazako",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6821704,
          lng: 47.84766949999999,
        },
        _id: "64f75285ef8f606073e99c25",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosy tsararafa",
        fokontany: "anosikely",
        __v: 0,
      },
      {
        coords: {
          lat: -22.698394,
          lng: 47.8081645,
        },
        _id: "64f75285ef8f606073e99c26",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosy tsararafa",
        fokontany: "ivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7144977,
          lng: 47.8019945,
        },
        _id: "64f75285ef8f606073e99c27",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosy tsararafa",
        fokontany: "enivoay",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7434596,
          lng: 47.7982928,
        },
        _id: "64f75285ef8f606073e99c28",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosy tsararafa",
        fokontany: "sahafoza",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7240723,
          lng: 47.8279133,
        },
        _id: "64f75285ef8f606073e99c29",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosy tsararafa",
        fokontany: "anosy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7240023,
          lng: 47.8501395,
        },
        _id: "64f75285ef8f606073e99c2a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosy tsararafa",
        fokontany: "andranolava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7562238,
          lng: 47.8377905,
        },
        _id: "64f75285ef8f606073e99c2b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "anosy tsararafa",
        fokontany: "antavia",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8721448,
          lng: 47.7933577,
        },
        _id: "64f75285ef8f606073e99c2c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohitromby",
        fokontany: "vohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8415572,
          lng: 47.8106327,
        },
        _id: "64f75285ef8f606073e99c2d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohitromby",
        fokontany: "mahabo ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8528431,
          lng: 47.7933577,
        },
        _id: "64f75285ef8f606073e99c2e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohitromby",
        fokontany: "iaborano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.866667,
          lng: 47.783333,
        },
        _id: "64f75285ef8f606073e99c2f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohitromby",
        fokontany: "vohilava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8753317,
          lng: 47.81803800000001,
        },
        _id: "64f75285ef8f606073e99c30",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohitromby",
        fokontany: "andranoboaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8770233,
          lng: 47.7267782,
        },
        _id: "64f75285ef8f606073e99c31",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ivandrika",
        fokontany: "ivandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8400435,
          lng: 47.7391014,
        },
        _id: "64f75285ef8f606073e99c32",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ivandrika",
        fokontany: "ambalolo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8561264,
          lng: 47.7292426,
        },
        _id: "64f75285ef8f606073e99c33",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ivandrika",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8657885,
          lng: 47.6922884,
        },
        _id: "64f75285ef8f606073e99c34",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ivandrika",
        fokontany: "bekaraoky andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8898836,
          lng: 47.7218497,
        },
        _id: "64f75285ef8f606073e99c35",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ivandrika",
        fokontany: "bevinany",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9557896,
          lng: 47.7834888,
        },
        _id: "64f75285ef8f606073e99c36",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "manambotra atsimo",
        fokontany: "manambotra atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9010844,
          lng: 47.8081645,
        },
        _id: "64f75285ef8f606073e99c37",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "manambotra atsimo",
        fokontany: "maroroka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9364934,
          lng: 47.7686889,
        },
        _id: "64f75285ef8f606073e99c38",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "manambotra atsimo",
        fokontany: "bekaraoka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9686562,
          lng: 47.7933577,
        },
        _id: "64f75285ef8f606073e99c39",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "manambotra atsimo",
        fokontany: "mahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9686519,
          lng: 47.7489621,
        },
        _id: "64f75285ef8f606073e99c3a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "manambotra atsimo",
        fokontany: "maropanahy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.70509,
          lng: 47.7095305,
        },
        _id: "64f75285ef8f606073e99c3b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahavelo",
        fokontany: "mahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.708345,
          lng: 47.6898257,
        },
        _id: "64f75285ef8f606073e99c3c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahavelo",
        fokontany: "analafiha",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6922749,
          lng: 47.6922884,
        },
        _id: "64f75285ef8f606073e99c3d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahavelo",
        fokontany: "vohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.713086,
          lng: 47.7267782,
        },
        _id: "64f75285ef8f606073e99c3e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahavelo",
        fokontany: "marofarihy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7179394,
          lng: 47.7119941,
        },
        _id: "64f75285ef8f606073e99c3f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahavelo",
        fokontany: "anivotsara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.684179,
          lng: 47.66832,
        },
        _id: "64f75285ef8f606073e99c40",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahafasa centre",
        fokontany: "mahafasa centre",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6587586,
          lng: 47.5889542,
        },
        _id: "64f75285ef8f606073e99c41",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahafasa centre",
        fokontany: "ambanimary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6619514,
          lng: 47.5987868,
        },
        _id: "64f75285ef8f606073e99c42",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahafasa centre",
        fokontany: "ampisorona",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6537757,
          lng: 47.67012829999999,
        },
        _id: "64f75285ef8f606073e99c43",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahafasa centre",
        fokontany: "vohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6667267,
          lng: 47.62091700000001,
        },
        _id: "64f75285ef8f606073e99c44",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahafasa centre",
        fokontany: "manambodala",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6891436,
          lng: 47.6528991,
        },
        _id: "64f75285ef8f606073e99c45",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahafasa centre",
        fokontany: "manatsaha",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6731105,
          lng: 47.640596,
        },
        _id: "64f75285ef8f606073e99c46",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahafasa centre",
        fokontany: "ambalavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7019585,
          lng: 47.67012829999999,
        },
        _id: "64f75285ef8f606073e99c47",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahafasa centre",
        fokontany: "anandaza",
        __v: 0,
      },
      {
        coords: {
          lat: -22.466667,
          lng: 47.8,
        },
        _id: "64f75285ef8f606073e99c48",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "amporoforo",
        fokontany: "amporoforo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4539808,
          lng: 47.7982928,
        },
        _id: "64f75285ef8f606073e99c49",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "amporoforo",
        fokontany: "anakona",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5182017,
          lng: 47.81803800000001,
        },
        _id: "64f75285ef8f606073e99c4a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "amporoforo",
        fokontany: "ankazomahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5279994,
          lng: 47.78842299999999,
        },
        _id: "64f75285ef8f606073e99c4b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "amporoforo",
        fokontany: "maiakoho",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4921566,
          lng: 47.872375,
        },
        _id: "64f75285ef8f606073e99c4c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "amporoforo",
        fokontany: "marosiky",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5083902,
          lng: 47.84766949999999,
        },
        _id: "64f75285ef8f606073e99c4d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "amporoforo",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5630712,
          lng: 47.8526097,
        },
        _id: "64f75285ef8f606073e99c4e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "amporoforo",
        fokontany: "andranamby",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5471541,
          lng: 47.81803800000001,
        },
        _id: "64f75285ef8f606073e99c4f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "amporoforo",
        fokontany: "amboahangimasy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5697631,
          lng: 47.7982928,
        },
        _id: "64f75285ef8f606073e99c50",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "amporoforo",
        fokontany: "tsiroboy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6051834,
          lng: 47.78842299999999,
        },
        _id: "64f75285ef8f606073e99c51",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "amporoforo",
        fokontany: "amibasy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8842598,
          lng: 47.7175376,
        },
        _id: "64f75285ef8f606073e99c52",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "iabohazo",
        fokontany: "iabohazo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9107671,
          lng: 47.6602824,
        },
        _id: "64f75285ef8f606073e99c53",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "iabohazo",
        fokontany: "ankarimbelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.877037,
          lng: 47.6824383,
        },
        _id: "64f75285ef8f606073e99c54",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "iabohazo",
        fokontany: "tsanofoha",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8778408,
          lng: 47.6664359,
        },
        _id: "64f75285ef8f606073e99c55",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "iabohazo",
        fokontany: "ambalateny",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8914957,
          lng: 47.6972141,
        },
        _id: "64f75285ef8f606073e99c56",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "iabohazo",
        fokontany: "fenoarivo II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8898894,
          lng: 47.6812072,
        },
        _id: "64f75285ef8f606073e99c57",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "iabohazo",
        fokontany: "iandray",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9011362,
          lng: 47.6898257,
        },
        _id: "64f75285ef8f606073e99c58",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "iabohazo",
        fokontany: "anambakaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9268397,
          lng: 47.6799761,
        },
        _id: "64f75285ef8f606073e99c59",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "iabohazo",
        fokontany: "amboangisay",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9429215,
          lng: 47.7144578,
        },
        _id: "64f75285ef8f606073e99c5a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "iabohazo",
        fokontany: "mahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9685911,
          lng: 47.6602824,
        },
        _id: "64f75285ef8f606073e99c5b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "iabohazo",
        fokontany: "mahazaza",
        __v: 0,
      },
      {
        coords: {
          lat: -23.00073,
          lng: 47.67012829999999,
        },
        _id: "64f75285ef8f606073e99c5c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "iabohazo",
        fokontany: "marovandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0135254,
          lng: 47.6356755,
        },
        _id: "64f75285ef8f606073e99c5d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "iabohazo",
        fokontany: "marovary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6889978,
          lng: 47.7193856,
        },
        _id: "64f75285ef8f606073e99c5e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tangainony",
        fokontany: "ankazosay",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6228628,
          lng: 47.7908903,
        },
        _id: "64f75285ef8f606073e99c5f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tangainony",
        fokontany: "namohora",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6310525,
          lng: 47.7489621,
        },
        _id: "64f75285ef8f606073e99c60",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tangainony",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6277688,
          lng: 47.7686889,
        },
        _id: "64f75285ef8f606073e99c61",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tangainony",
        fokontany: "andramena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6502442,
          lng: 47.778555,
        },
        _id: "64f75285ef8f606073e99c62",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tangainony",
        fokontany: "antsiragna",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6631709,
          lng: 47.7588245,
        },
        _id: "64f75285ef8f606073e99c63",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tangainony",
        fokontany: "andranomangatsiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6568014,
          lng: 47.7391014,
        },
        _id: "64f75285ef8f606073e99c64",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tangainony",
        fokontany: "marohaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6721538,
          lng: 47.7082987,
        },
        _id: "64f75285ef8f606073e99c65",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tangainony",
        fokontany: "vohibitro",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6824625,
          lng: 47.7588245,
        },
        _id: "64f75285ef8f606073e99c66",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tangainony",
        fokontany: "ekavy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7082105,
          lng: 47.7489621,
        },
        _id: "64f75285ef8f606073e99c67",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tangainony",
        fokontany: "tangainony centre",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6984836,
          lng: 47.778555,
        },
        _id: "64f75285ef8f606073e99c68",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tangainony",
        fokontany: "marozano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7053079,
          lng: 47.5766661,
        },
        _id: "64f75285ef8f606073e99c69",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "beretra bevoay",
        fokontany: "ankarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6764062,
          lng: 47.5914122,
        },
        _id: "64f75285ef8f606073e99c6a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "beretra bevoay",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.692524,
          lng: 47.5226323,
        },
        _id: "64f75285ef8f606073e99c6b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "beretra bevoay",
        fokontany: "songotoloho",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6957429,
          lng: 47.5029975,
        },
        _id: "64f75285ef8f606073e99c6c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "beretra bevoay",
        fokontany: "mahasoa sariheva",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6924988,
          lng: 47.5520984,
        },
        _id: "64f75285ef8f606073e99c6d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "beretra bevoay",
        fokontany: "anandoby",
        __v: 0,
      },
      {
        coords: {
          lat: -22.716667,
          lng: 47.616667,
        },
        _id: "64f75285ef8f606073e99c6e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "beretra bevoay",
        fokontany: "beretra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7197832,
          lng: 47.52508719999999,
        },
        _id: "64f75285ef8f606073e99c6f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "beretra bevoay",
        fokontany: "nosibe marotoko",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7294049,
          lng: 47.52508719999999,
        },
        _id: "64f75285ef8f606073e99c70",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "beretra bevoay",
        fokontany: "lohavala",
        __v: 0,
      },
      {
        coords: {
          lat: -22.766667,
          lng: 47.583333,
        },
        _id: "64f75285ef8f606073e99c71",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambohigogo",
        fokontany: "ambohigogo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7438163,
          lng: 47.5766661,
        },
        _id: "64f75285ef8f606073e99c72",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambohigogo",
        fokontany: "tsimelahy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7727022,
          lng: 47.5619241,
        },
        _id: "64f75285ef8f606073e99c73",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambohigogo",
        fokontany: "iabovolo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.766667,
          lng: 47.583333,
        },
        _id: "64f75285ef8f606073e99c74",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambohigogo",
        fokontany: "esatra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.772704,
          lng: 47.5324525,
        },
        _id: "64f75285ef8f606073e99c75",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambohigogo",
        fokontany: "manolosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7887445,
          lng: 47.5447303,
        },
        _id: "64f75285ef8f606073e99c76",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambohigogo",
        fokontany: "tsiririmiempo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7935523,
          lng: 47.6037038,
        },
        _id: "64f75285ef8f606073e99c77",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambohigogo",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7967664,
          lng: 47.5545546,
        },
        _id: "64f75285ef8f606073e99c78",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambohigogo",
        fokontany: "amboroboaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8047901,
          lng: 47.5717516,
        },
        _id: "64f75285ef8f606073e99c79",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambohigogo",
        fokontany: "namahora",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8047841,
          lng: 47.5422745,
        },
        _id: "64f75285ef8f606073e99c7a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambohigogo",
        fokontany: "lohatranambo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8176004,
          lng: 47.5152684,
        },
        _id: "64f75285ef8f606073e99c7b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambohigogo",
        fokontany: "ambohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.824021,
          lng: 47.5238597,
        },
        _id: "64f75285ef8f606073e99c7c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambohigogo",
        fokontany: "ambalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7502031,
          lng: 47.6184576,
        },
        _id: "64f75285ef8f606073e99c7d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "maheriraty",
        fokontany: "loharano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7244755,
          lng: 47.6504382,
        },
        _id: "64f75285ef8f606073e99c7e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "maheriraty",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7309558,
          lng: 47.6037038,
        },
        _id: "64f75285ef8f606073e99c7f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "maheriraty",
        fokontany: "berondra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7437634,
          lng: 47.6356755,
        },
        _id: "64f75285ef8f606073e99c80",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "maheriraty",
        fokontany: "maheriraty",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7533804,
          lng: 47.6504382,
        },
        _id: "64f75285ef8f606073e99c81",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "maheriraty",
        fokontany: "ampatsitoka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7742826,
          lng: 47.6184576,
        },
        _id: "64f75285ef8f606073e99c82",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "maheriraty",
        fokontany: "tsatoro",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7758792,
          lng: 47.6307555,
        },
        _id: "64f75285ef8f606073e99c83",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "maheriraty",
        fokontany: "ambatalany",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1903406,
          lng: 47.6824383,
        },
        _id: "64f75285ef8f606073e99c84",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahabo mananivo",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1197075,
          lng: 47.7095305,
        },
        _id: "64f75285ef8f606073e99c85",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahabo mananivo",
        fokontany: "lohagisy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1229763,
          lng: 47.7341718,
        },
        _id: "64f75285ef8f606073e99c86",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahabo mananivo",
        fokontany: "ankarimbelo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.145472,
          lng: 47.7292426,
        },
        _id: "64f75285ef8f606073e99c87",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahabo mananivo",
        fokontany: "baboaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1679248,
          lng: 47.7095305,
        },
        _id: "64f75285ef8f606073e99c88",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahabo mananivo",
        fokontany: "iabomary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1533986,
          lng: 47.6873631,
        },
        _id: "64f75285ef8f606073e99c89",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahabo mananivo",
        fokontany: "anateza",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1775017,
          lng: 47.6873631,
        },
        _id: "64f75285ef8f606073e99c8a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahabo mananivo",
        fokontany: "vohimasy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2080493,
          lng: 47.6922884,
        },
        _id: "64f75285ef8f606073e99c8b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahabo mananivo",
        fokontany: "iabotako",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2064992,
          lng: 47.7095305,
        },
        _id: "64f75285ef8f606073e99c8c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahabo mananivo",
        fokontany: "nosiala",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1808527,
          lng: 47.7341718,
        },
        _id: "64f75285ef8f606073e99c8d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "mahabo mananivo",
        fokontany: "rorobe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.747046,
          lng: 47.512814,
        },
        _id: "64f75285ef8f606073e99c8e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambohimandroso",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7246006,
          lng: 47.5029975,
        },
        _id: "64f75285ef8f606073e99c8f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambohimandroso",
        fokontany: "anosilava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7406321,
          lng: 47.485823,
        },
        _id: "64f75285ef8f606073e99c90",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambohimandroso",
        fokontany: "ambohimahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7598698,
          lng: 47.4931828,
        },
        _id: "64f75285ef8f606073e99c91",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambohimandroso",
        fokontany: "andonabe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7791071,
          lng: 47.4931828,
        },
        _id: "64f75285ef8f606073e99c92",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambohimandroso",
        fokontany: "iaborefo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8015577,
          lng: 47.5029975,
        },
        _id: "64f75285ef8f606073e99c93",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambohimandroso",
        fokontany: "iapombo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.08823,
          lng: 47.62801,
        },
        _id: "64f75285ef8f606073e99c94",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ankarana miraihina",
        fokontany: "ankarana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0328981,
          lng: 47.6947512,
        },
        _id: "64f75285ef8f606073e99c95",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ankarana miraihina",
        fokontany: "manombo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0715605,
          lng: 47.7538931,
        },
        _id: "64f75285ef8f606073e99c96",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ankarana miraihina",
        fokontany: "takoandra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.064847,
          lng: 47.6086213,
        },
        _id: "64f75285ef8f606073e99c97",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ankarana miraihina",
        fokontany: "eroka I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0649019,
          lng: 47.6307555,
        },
        _id: "64f75285ef8f606073e99c98",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ankarana miraihina",
        fokontany: "eroka II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0744595,
          lng: 47.6012453,
        },
        _id: "64f75285ef8f606073e99c99",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ankarana miraihina",
        fokontany: "beteny",
        __v: 0,
      },
      {
        coords: {
          lat: -23.090438,
          lng: 47.5766661,
        },
        _id: "64f75285ef8f606073e99c9a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ankarana miraihina",
        fokontany: "manara",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0970831,
          lng: 47.6553601,
        },
        _id: "64f75285ef8f606073e99c9b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ankarana miraihina",
        fokontany: "anambotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0905479,
          lng: 47.6135392,
        },
        _id: "64f75285ef8f606073e99c9c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ankarana miraihina",
        fokontany: "vohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.606159,
          lng: 47.7110709,
        },
        _id: "64f75285ef8f606073e99c9d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "evato",
        fokontany: "evato",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5831425,
          lng: 47.6602824,
        },
        _id: "64f75285ef8f606073e99c9e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "evato",
        fokontany: "anambotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5910072,
          lng: 47.7107623,
        },
        _id: "64f75285ef8f606073e99c9f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "evato",
        fokontany: "vohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5862508,
          lng: 47.6922884,
        },
        _id: "64f75285ef8f606073e99ca0",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "evato",
        fokontany: "ambatomena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.589298,
          lng: 47.7391014,
        },
        _id: "64f75285ef8f606073e99ca1",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "evato",
        fokontany: "anakizo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6006597,
          lng: 47.707067,
        },
        _id: "64f75285ef8f606073e99ca2",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "evato",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6246891,
          lng: 47.7292426,
        },
        _id: "64f75285ef8f606073e99ca3",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "evato",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6279652,
          lng: 47.7095305,
        },
        _id: "64f75285ef8f606073e99ca4",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "evato",
        fokontany: "emena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6408949,
          lng: 47.6824383,
        },
        _id: "64f75285ef8f606073e99ca5",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "evato",
        fokontany: "samboritra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6489002,
          lng: 47.6922884,
        },
        _id: "64f75285ef8f606073e99ca6",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "evato",
        fokontany: "miteno",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6439962,
          lng: 47.7218497,
        },
        _id: "64f75285ef8f606073e99ca7",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "evato",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6600932,
          lng: 47.7119941,
        },
        _id: "64f75285ef8f606073e99ca8",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "evato",
        fokontany: "vohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.891371,
          lng: 47.58812,
        },
        _id: "64f75285ef8f606073e99ca9",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "etrotroka atsimo",
        fokontany: "etrotroka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8368432,
          lng: 47.5152684,
        },
        _id: "64f75285ef8f606073e99caa",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "etrotroka atsimo",
        fokontany: "marofody",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8496665,
          lng: 47.5103597,
        },
        _id: "64f75285ef8f606073e99cab",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "etrotroka atsimo",
        fokontany: "anaviavy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.846491,
          lng: 47.5447303,
        },
        _id: "64f75285ef8f606073e99cac",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "etrotroka atsimo",
        fokontany: "kidiamonta",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8545298,
          lng: 47.56929450000001,
        },
        _id: "64f75285ef8f606073e99cad",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "etrotroka atsimo",
        fokontany: "vohitsidy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8609407,
          lng: 47.5594675,
        },
        _id: "64f75285ef8f606073e99cae",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "etrotroka atsimo",
        fokontany: "antondabe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8689862,
          lng: 47.5914122,
        },
        _id: "64f75285ef8f606073e99caf",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "etrotroka atsimo",
        fokontany: "maroroka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8657167,
          lng: 47.5226323,
        },
        _id: "64f75285ef8f606073e99cb0",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "etrotroka atsimo",
        fokontany: "fandana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8400923,
          lng: 47.5766661,
        },
        _id: "64f75285ef8f606073e99cb1",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "etrotroka atsimo",
        fokontany: "iarindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8849985,
          lng: 47.5520984,
        },
        _id: "64f75285ef8f606073e99cb2",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "etrotroka atsimo",
        fokontany: "mahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8752978,
          lng: 47.4931828,
        },
        _id: "64f75285ef8f606073e99cb3",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "etrotroka atsimo",
        fokontany: "bemangevo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9009315,
          lng: 47.48337,
        },
        _id: "64f75285ef8f606073e99cb4",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "etrotroka atsimo",
        fokontany: "sarinosy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9170537,
          lng: 47.5324525,
        },
        _id: "64f75285ef8f606073e99cb5",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "etrotroka atsimo",
        fokontany: "ihakia",
        __v: 0,
      },
      {
        coords: {
          lat: -22.916667,
          lng: 47.466667,
        },
        _id: "64f75285ef8f606073e99cb6",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "etrotroka atsimo",
        fokontany: "amborobe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9459253,
          lng: 47.5324525,
        },
        _id: "64f75285ef8f606073e99cb7",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "etrotroka atsimo",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9490596,
          lng: 47.4980899,
        },
        _id: "64f75285ef8f606073e99cb8",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "etrotroka atsimo",
        fokontany: "iabovarigna",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6347776,
          lng: 47.5373633,
        },
        _id: "64f75285ef8f606073e99cb9",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalavato nord",
        fokontany: "ambalatraka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5995558,
          lng: 47.5029975,
        },
        _id: "64f75285ef8f606073e99cba",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalavato nord",
        fokontany: "ambanimaso",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5962893,
          lng: 47.5373633,
        },
        _id: "64f75285ef8f606073e99cbb",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalavato nord",
        fokontany: "ambalavary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6316049,
          lng: 47.512814,
        },
        _id: "64f75285ef8f606073e99cbc",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalavato nord",
        fokontany: "telolonaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6348499,
          lng: 47.4784643,
        },
        _id: "64f75285ef8f606073e99cbd",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalavato nord",
        fokontany: "fenolaza",
        __v: 0,
      },
      {
        coords: {
          lat: -22.662891,
          lng: 47.4870496,
        },
        _id: "64f75285ef8f606073e99cbe",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalavato nord",
        fokontany: "berombazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6732819,
          lng: 47.5226323,
        },
        _id: "64f75285ef8f606073e99cbf",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalavato nord",
        fokontany: "ambalavato",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6604311,
          lng: 47.5422745,
        },
        _id: "64f75285ef8f606073e99cc0",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalavato nord",
        fokontany: "ananara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6668859,
          lng: 47.5029975,
        },
        _id: "64f75285ef8f606073e99cc1",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalavato nord",
        fokontany: "antanakidy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.657291,
          lng: 47.4735591,
        },
        _id: "64f75285ef8f606073e99cc2",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalavato nord",
        fokontany: "mahebo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6555887,
          lng: 47.5643808,
        },
        _id: "64f75285ef8f606073e99cc3",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalavato nord",
        fokontany: "bemandresy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1162289,
          lng: 47.6110802,
        },
        _id: "64f75285ef8f606073e99cc4",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "efatsy",
        fokontany: "anandroza",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1081,
          lng: 47.57912349999999,
        },
        _id: "64f75285ef8f606073e99cc5",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "efatsy",
        fokontany: "ambalafatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1097687,
          lng: 47.5987868,
        },
        _id: "64f75285ef8f606073e99cc6",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "efatsy",
        fokontany: "ambalavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1353663,
          lng: 47.5742088,
        },
        _id: "64f75285ef8f606073e99cc7",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "efatsy",
        fokontany: "ambalamanga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1418833,
          lng: 47.6012453,
        },
        _id: "64f75285ef8f606073e99cc8",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "efatsy",
        fokontany: "sofiramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1323471,
          lng: 47.6307555,
        },
        _id: "64f75285ef8f606073e99cc9",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "efatsy",
        fokontany: "voaseva vololena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1643118,
          lng: 47.5889542,
        },
        _id: "64f75285ef8f606073e99cca",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "efatsy",
        fokontany: "maroroka anivotsara",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1611476,
          lng: 47.6012453,
        },
        _id: "64f75285ef8f606073e99ccb",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "efatsy",
        fokontany: "tanambe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9203563,
          lng: 47.6012453,
        },
        _id: "64f75285ef8f606073e99ccc",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tovona",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8015567,
          lng: 47.6504382,
        },
        _id: "64f75285ef8f606073e99ccd",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tovona",
        fokontany: "sarahambendra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.816667,
          lng: 47.633333,
        },
        _id: "64f75285ef8f606073e99cce",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tovona",
        fokontany: "sahafatra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8304633,
          lng: 47.6504382,
        },
        _id: "64f75285ef8f606073e99ccf",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tovona",
        fokontany: "bemahala",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8529415,
          lng: 47.675052,
        },
        _id: "64f75285ef8f606073e99cd0",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tovona",
        fokontany: "anaviavy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9182617,
          lng: 47.6340279,
        },
        _id: "64f75285ef8f606073e99cd1",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tovona",
        fokontany: "tovona",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9620837,
          lng: 47.59632850000001,
        },
        _id: "64f75285ef8f606073e99cd2",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tovona",
        fokontany: "anatsirika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9459792,
          lng: 47.5619241,
        },
        _id: "64f75285ef8f606073e99cd3",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tovona",
        fokontany: "berahodaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9732309,
          lng: 47.5496422,
        },
        _id: "64f75285ef8f606073e99cd4",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tovona",
        fokontany: "anovihazo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9893173,
          lng: 47.56929450000001,
        },
        _id: "64f75285ef8f606073e99cd5",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "tovona",
        fokontany: "ambohitrevo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.543631,
          lng: 47.585609,
        },
        _id: "64f75285ef8f606073e99cd6",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalatany",
        fokontany: "ambalatany",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5415964,
          lng: 47.5987868,
        },
        _id: "64f75285ef8f606073e99cd7",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalatany",
        fokontany: "mahamanina",
        __v: 0,
      },
      {
        coords: {
          lat: -22.564134,
          lng: 47.5717516,
        },
        _id: "64f75285ef8f606073e99cd8",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalatany",
        fokontany: "vohidahy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5705002,
          lng: 47.5914122,
        },
        _id: "64f75285ef8f606073e99cd9",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalatany",
        fokontany: "bemitsanga",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5770136,
          lng: 47.5520984,
        },
        _id: "64f75285ef8f606073e99cda",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalatany",
        fokontany: "matsakaria",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5929933,
          lng: 47.57912349999999,
        },
        _id: "64f75285ef8f606073e99cdb",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalatany",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5912907,
          lng: 47.6184576,
        },
        _id: "64f75285ef8f606073e99cdc",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalatany",
        fokontany: "ambalamanga",
        __v: 0,
      },
      {
        coords: {
          lat: -22.616667,
          lng: 47.633333,
        },
        _id: "64f75285ef8f606073e99cdd",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "namohora iaborano",
        fokontany: "namohora iaborano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6057364,
          lng: 47.6184576,
        },
        _id: "64f75285ef8f606073e99cde",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "namohora iaborano",
        fokontany: "mahalava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6154497,
          lng: 47.581581,
        },
        _id: "64f75285ef8f606073e99cdf",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "namohora iaborano",
        fokontany: "mahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6346442,
          lng: 47.6110802,
        },
        _id: "64f75285ef8f606073e99ce0",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "namohora iaborano",
        fokontany: "marofody",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6474162,
          lng: 47.6430564,
        },
        _id: "64f75285ef8f606073e99ce1",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "namohora iaborano",
        fokontany: "maroakova",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5160091,
          lng: 47.5717516,
        },
        _id: "64f75285ef8f606073e99ce2",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "sahamadio",
        fokontany: "sahamadio",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4613772,
          lng: 47.59632850000001,
        },
        _id: "64f75285ef8f606073e99ce3",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "sahamadio",
        fokontany: "manakololo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4837759,
          lng: 47.6135392,
        },
        _id: "64f75285ef8f606073e99ce4",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "sahamadio",
        fokontany: "matsakaria",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4966847,
          lng: 47.5938703,
        },
        _id: "64f75285ef8f606073e99ce5",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "sahamadio",
        fokontany: "ambalahivoka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5094617,
          lng: 47.6110802,
        },
        _id: "64f75285ef8f606073e99ce6",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "sahamadio",
        fokontany: "vohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5254684,
          lng: 47.6233764,
        },
        _id: "64f75285ef8f606073e99ce7",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "sahamadio",
        fokontany: "masomiza",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5288777,
          lng: 47.5594675,
        },
        _id: "64f75285ef8f606073e99ce8",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "sahamadio",
        fokontany: "fandrambaky",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5400918,
          lng: 47.5643808,
        },
        _id: "64f75285ef8f606073e99ce9",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "sahamadio",
        fokontany: "ambalahady",
        __v: 0,
      },
      {
        coords: {
          lat: -22.532139,
          lng: 47.5398188,
        },
        _id: "64f75285ef8f606073e99cea",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "sahamadio",
        fokontany: "morondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5481442,
          lng: 47.5520984,
        },
        _id: "64f75285ef8f606073e99ceb",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "sahamadio",
        fokontany: "morasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1096463,
          lng: 47.5619241,
        },
        _id: "64f75285ef8f606073e99cec",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "fenoarivo",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0580764,
          lng: 47.4931828,
        },
        _id: "64f75285ef8f606073e99ced",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "fenoarivo",
        fokontany: "vohidroy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0742818,
          lng: 47.5422745,
        },
        _id: "64f75285ef8f606073e99cee",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "fenoarivo",
        fokontany: "beringiritra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0853438,
          lng: 47.4956363,
        },
        _id: "64f75285ef8f606073e99cef",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "fenoarivo",
        fokontany: "anapala",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0902903,
          lng: 47.5324525,
        },
        _id: "64f75285ef8f606073e99cf0",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "fenoarivo",
        fokontany: "emikaiky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.106179,
          lng: 47.4931828,
        },
        _id: "64f75285ef8f606073e99cf1",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "fenoarivo",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1191646,
          lng: 47.5324525,
        },
        _id: "64f75285ef8f606073e99cf2",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "fenoarivo",
        fokontany: "lohasay",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1193762,
          lng: 47.5914122,
        },
        _id: "64f75285ef8f606073e99cf3",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "fenoarivo",
        fokontany: "tsararano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1336882,
          lng: 47.5545546,
        },
        _id: "64f75285ef8f606073e99cf4",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "fenoarivo",
        fokontany: "sambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1289817,
          lng: 47.5840386,
        },
        _id: "64f75285ef8f606073e99cf5",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "fenoarivo",
        fokontany: "matangivola",
        __v: 0,
      },
      {
        coords: {
          lat: -23.153001,
          lng: 47.56929450000001,
        },
        _id: "64f75285ef8f606073e99cf6",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "fenoarivo",
        fokontany: "maheriteny",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1549094,
          lng: 47.6553601,
        },
        _id: "64f75285ef8f606073e99cf7",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "antseranambe",
        fokontany: "antseranambe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1404304,
          lng: 47.6479775,
        },
        _id: "64f75285ef8f606073e99cf8",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "antseranambe",
        fokontany: "iabomary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1356762,
          lng: 47.67012829999999,
        },
        _id: "64f75285ef8f606073e99cf9",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "antseranambe",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1700895,
          lng: 47.63198550000001,
        },
        _id: "64f75285ef8f606073e99cfa",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "antseranambe",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1677062,
          lng: 47.6393658,
        },
        _id: "64f75285ef8f606073e99cfb",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "antseranambe",
        fokontany: "ankazobaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1773554,
          lng: 47.6430564,
        },
        _id: "64f75285ef8f606073e99cfc",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "antseranambe",
        fokontany: "manatondrondra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1838483,
          lng: 47.66274370000001,
        },
        _id: "64f75285ef8f606073e99cfd",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "antseranambe",
        fokontany: "anambahy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0163,
          lng: 47.535881,
        },
        _id: "64f75285ef8f606073e99cfe",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ihorombe",
        fokontany: "ihorombe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9875805,
          lng: 47.512814,
        },
        _id: "64f75285ef8f606073e99cff",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ihorombe",
        fokontany: "ambohimaranitra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0052279,
          lng: 47.5152684,
        },
        _id: "64f75285ef8f606073e99d00",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ihorombe",
        fokontany: "ambohimanany",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0005104,
          lng: 47.5520984,
        },
        _id: "64f75285ef8f606073e99d01",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ihorombe",
        fokontany: "iharonga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0260241,
          lng: 47.4980899,
        },
        _id: "64f75285ef8f606073e99d02",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ihorombe",
        fokontany: "madinikora",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0294652,
          lng: 47.581581,
        },
        _id: "64f75285ef8f606073e99d03",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ihorombe",
        fokontany: "lohavala",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0134378,
          lng: 47.5914122,
        },
        _id: "64f75285ef8f606073e99d04",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ihorombe",
        fokontany: "beretra I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0293896,
          lng: 47.5520984,
        },
        _id: "64f75285ef8f606073e99d05",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ihorombe",
        fokontany: "vohiposa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0486426,
          lng: 47.5520984,
        },
        _id: "64f75285ef8f606073e99d06",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ihorombe",
        fokontany: "vohibolo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0391659,
          lng: 47.6110802,
        },
        _id: "64f75285ef8f606073e99d07",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ihorombe",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9938389,
          lng: 47.4588461,
        },
        _id: "64f75285ef8f606073e99d08",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalavato antevato",
        fokontany: "ambalavato antevato",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9745621,
          lng: 47.4441373,
        },
        _id: "64f75285ef8f606073e99d09",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalavato antevato",
        fokontany: "marovato kapity",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9874792,
          lng: 47.4760116,
        },
        _id: "64f75285ef8f606073e99d0a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalavato antevato",
        fokontany: "anezandava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0066448,
          lng: 47.4539427,
        },
        _id: "64f75285ef8f606073e99d0b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalavato antevato",
        fokontany: "beretra II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0291168,
          lng: 47.4637499,
        },
        _id: "64f75285ef8f606073e99d0c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "ambalavato antevato",
        fokontany: "sindrakatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5395897,
          lng: 47.7119941,
        },
        _id: "64f75285ef8f606073e99d0d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohilengo",
        fokontany: "vohilengo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5,
          lng: 47.716667,
        },
        _id: "64f75285ef8f606073e99d0e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohilengo",
        fokontany: "ambolomena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5121902,
          lng: 47.7317071,
        },
        _id: "64f75285ef8f606073e99d0f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohilengo",
        fokontany: "kazomasy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5313758,
          lng: 47.7538931,
        },
        _id: "64f75285ef8f606073e99d10",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohilengo",
        fokontany: "ampamolana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5187558,
          lng: 47.6996772,
        },
        _id: "64f75285ef8f606073e99d11",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohilengo",
        fokontany: "varary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5507266,
          lng: 47.7391014,
        },
        _id: "64f75285ef8f606073e99d12",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohilengo",
        fokontany: "ambodimanara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.544578,
          lng: 47.6676666,
        },
        _id: "64f75285ef8f606073e99d13",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohilengo",
        fokontany: "enisira",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5476422,
          lng: 47.707067,
        },
        _id: "64f75285ef8f606073e99d14",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohilengo",
        fokontany: "anambotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5493421,
          lng: 47.6824383,
        },
        _id: "64f75285ef8f606073e99d15",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohilengo",
        fokontany: "etsidity",
        __v: 0,
      },
      {
        coords: {
          lat: -22.563771,
          lng: 47.6898257,
        },
        _id: "64f75285ef8f606073e99d16",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohilengo",
        fokontany: "mahazony",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5636611,
          lng: 47.7193856,
        },
        _id: "64f75285ef8f606073e99d17",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohilengo",
        fokontany: "karimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5781494,
          lng: 47.7119941,
        },
        _id: "64f75285ef8f606073e99d18",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohilengo",
        fokontany: "kazoaraka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5667176,
          lng: 47.7588245,
        },
        _id: "64f75285ef8f606073e99d19",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "vohilengo",
        fokontany: "betaikaomby",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5179489,
          lng: 47.6659345,
        },
        _id: "64f75285ef8f606073e99d1a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "marovandrika",
        fokontany: "marovandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.45,
          lng: 47.65,
        },
        _id: "64f75285ef8f606073e99d1b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "marovandrika",
        fokontany: "ambalarano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4659161,
          lng: 47.66274370000001,
        },
        _id: "64f75285ef8f606073e99d1c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "marovandrika",
        fokontany: "amborolava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4835041,
          lng: 47.6799761,
        },
        _id: "64f75285ef8f606073e99d1d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "marovandrika",
        fokontany: "etsingilo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4868305,
          lng: 47.6528991,
        },
        _id: "64f75285ef8f606073e99d1e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "marovandrika",
        fokontany: "vohimanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4772887,
          lng: 47.6307555,
        },
        _id: "64f75285ef8f606073e99d1f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "marovandrika",
        fokontany: "ambatomalama",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5317127,
          lng: 47.6725901,
        },
        _id: "64f75285ef8f606073e99d20",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "marovandrika",
        fokontany: "firifiry",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5317945,
          lng: 47.6504382,
        },
        _id: "64f75285ef8f606073e99d21",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "marovandrika",
        fokontany: "manambodala",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5511101,
          lng: 47.6356755,
        },
        _id: "64f75285ef8f606073e99d22",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "farafangana",
        commune: "marovandrika",
        fokontany: "haramy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3482273,
          lng: 47.6098507,
        },
        _id: "64f75285ef8f606073e99d23",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vangaindrano",
        fokontany: "vangaindrano be",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3232261,
          lng: 47.5889542,
        },
        _id: "64f75285ef8f606073e99d24",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vangaindrano",
        fokontany: "nanasana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3281237,
          lng: 47.6037038,
        },
        _id: "64f75285ef8f606073e99d25",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vangaindrano",
        fokontany: "sahafero",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3393066,
          lng: 47.5938703,
        },
        _id: "64f75285ef8f606073e99d26",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vangaindrano",
        fokontany: "manombo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3465834,
          lng: 47.6030892,
        },
        _id: "64f75285ef8f606073e99d27",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vangaindrano",
        fokontany: "ampahatelo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3473261,
          lng: 47.5926413,
        },
        _id: "64f75285ef8f606073e99d28",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vangaindrano",
        fokontany: "vohitrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.352142,
          lng: 47.5926413,
        },
        _id: "64f75285ef8f606073e99d29",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vangaindrano",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3634302,
          lng: 47.6012453,
        },
        _id: "64f75285ef8f606073e99d2a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vangaindrano",
        fokontany: "ampasy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.362008,
          lng: 47.6332155,
        },
        _id: "64f75285ef8f606073e99d2b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vangaindrano",
        fokontany: "manasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3604967,
          lng: 47.6504382,
        },
        _id: "64f75285ef8f606073e99d2c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vangaindrano",
        fokontany: "tsipanga II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3670283,
          lng: 47.67012829999999,
        },
        _id: "64f75285ef8f606073e99d2d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vangaindrano",
        fokontany: "betoafo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.37001,
          lng: 47.6282957,
        },
        _id: "64f75285ef8f606073e99d2e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vangaindrano",
        fokontany: "tanimena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3757498,
          lng: 47.6492079,
        },
        _id: "64f75285ef8f606073e99d2f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vangaindrano",
        fokontany: "tanibe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3863083,
          lng: 47.67012829999999,
        },
        _id: "64f75285ef8f606073e99d30",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vangaindrano",
        fokontany: "mahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3925078,
          lng: 47.6307555,
        },
        _id: "64f75285ef8f606073e99d31",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vangaindrano",
        fokontany: "taratasy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3404508,
          lng: 47.5177229,
        },
        _id: "64f75285ef8f606073e99d32",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ampasimalemy",
        fokontany: "ampasimalemy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3307333,
          lng: 47.5029975,
        },
        _id: "64f75285ef8f606073e99d33",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ampasimalemy",
        fokontany: "ampivangoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3292612,
          lng: 47.5238597,
        },
        _id: "64f75285ef8f606073e99d34",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ampasimalemy",
        fokontany: "ampasindava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3501695,
          lng: 47.5324525,
        },
        _id: "64f75285ef8f606073e99d35",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ampasimalemy",
        fokontany: "iabomary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3327082,
          lng: 47.5631524,
        },
        _id: "64f75285ef8f606073e99d36",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ampasimalemy",
        fokontany: "vapaky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3271625,
          lng: 47.5754374,
        },
        _id: "64f75285ef8f606073e99d37",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ampasimalemy",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3503081,
          lng: 47.5545546,
        },
        _id: "64f75285ef8f606073e99d38",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ampasimalemy",
        fokontany: "ambalaoraky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3397033,
          lng: 47.5263146,
        },
        _id: "64f75285ef8f606073e99d39",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ampasimalemy",
        fokontany: "tsararano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3532506,
          lng: 47.512814,
        },
        _id: "64f75285ef8f606073e99d3a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ampasimalemy",
        fokontany: "amboazokibo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3794247,
          lng: 47.5914122,
        },
        _id: "64f75285ef8f606073e99d3b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ampasimalemy",
        fokontany: "vohilava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3954149,
          lng: 47.581581,
        },
        _id: "64f75285ef8f606073e99d3c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ampasimalemy",
        fokontany: "ditona",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3552128,
          lng: 47.56929450000001,
        },
        _id: "64f75285ef8f606073e99d3d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ampasimalemy",
        fokontany: "mariany",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4067305,
          lng: 47.5938703,
        },
        _id: "64f75285ef8f606073e99d3e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ampasimalemy",
        fokontany: "vohibamitsa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4176523,
          lng: 47.5471862,
        },
        _id: "64f75285ef8f606073e99d3f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "tsianofana",
        fokontany: "tsianofana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3696569,
          lng: 47.56929450000001,
        },
        _id: "64f75285ef8f606073e99d40",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "tsianofana",
        fokontany: "tatamo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3726936,
          lng: 47.5422745,
        },
        _id: "64f75285ef8f606073e99d41",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "tsianofana",
        fokontany: "vohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4181419,
          lng: 47.62091700000001,
        },
        _id: "64f75285ef8f606073e99d42",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "tsianofana",
        fokontany: "vohipanany",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4523073,
          lng: 47.4637499,
        },
        _id: "64f75285ef8f606073e99d43",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "tsianofana",
        fokontany: "sahavia",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4694942,
          lng: 47.6159983,
        },
        _id: "64f75285ef8f606073e99d44",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "bekaraoky",
        fokontany: "bekaraoky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4503233,
          lng: 47.6307555,
        },
        _id: "64f75285ef8f606073e99d45",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "bekaraoky",
        fokontany: "analatsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3027395,
          lng: 47.66520509999999,
        },
        _id: "64f75285ef8f606073e99d46",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "tsiately",
        fokontany: "tsiately",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2770775,
          lng: 47.675052,
        },
        _id: "64f75285ef8f606073e99d47",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "tsiately",
        fokontany: "tsararano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3124483,
          lng: 47.6799761,
        },
        _id: "64f75285ef8f606073e99d48",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "tsiately",
        fokontany: "mahamany",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3286094,
          lng: 47.6996772,
        },
        _id: "64f75285ef8f606073e99d49",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "tsiately",
        fokontany: "andranovato",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3477484,
          lng: 47.67012829999999,
        },
        _id: "64f75285ef8f606073e99d4a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "tsiately",
        fokontany: "anapatra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3460008,
          lng: 47.6430564,
        },
        _id: "64f75285ef8f606073e99d4b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "tsiately",
        fokontany: "anambotaka II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3442883,
          lng: 47.6233764,
        },
        _id: "64f75285ef8f606073e99d4c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "tsiately",
        fokontany: "editsaky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2767725,
          lng: 47.6086213,
        },
        _id: "64f75285ef8f606073e99d4d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "soamanova",
        fokontany: "mahela",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2284393,
          lng: 47.5717516,
        },
        _id: "64f75285ef8f606073e99d4e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "soamanova",
        fokontany: "maronosy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2662846,
          lng: 47.597865,
        },
        _id: "64f75285ef8f606073e99d4f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "soamanova",
        fokontany: "mavogisy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2871323,
          lng: 47.593563,
        },
        _id: "64f75285ef8f606073e99d50",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "soamanova",
        fokontany: "sanasemba",
        __v: 0,
      },
      {
        coords: {
          lat: -23.283255,
          lng: 47.62091700000001,
        },
        _id: "64f75285ef8f606073e99d51",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "soamanova",
        fokontany: "madioandro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3040414,
          lng: 47.6037038,
        },
        _id: "64f75285ef8f606073e99d52",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "soamanova",
        fokontany: "maroroka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2991083,
          lng: 47.581581,
        },
        _id: "64f75285ef8f606073e99d53",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "soamanova",
        fokontany: "eteny",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1983388,
          lng: 47.6719746,
        },
        _id: "64f75285ef8f606073e99d54",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lopary",
        fokontany: "lopary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1532152,
          lng: 47.6282957,
        },
        _id: "64f75285ef8f606073e99d55",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lopary",
        fokontany: "amboangibe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1706639,
          lng: 47.5717516,
        },
        _id: "64f75285ef8f606073e99d56",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lopary",
        fokontany: "manasity",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1772757,
          lng: 47.62091700000001,
        },
        _id: "64f75285ef8f606073e99d57",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lopary",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1931425,
          lng: 47.5742088,
        },
        _id: "64f75285ef8f606073e99d58",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lopary",
        fokontany: "betsiraha",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1980203,
          lng: 47.5889542,
        },
        _id: "64f75285ef8f606073e99d59",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lopary",
        fokontany: "soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1916398,
          lng: 47.5987868,
        },
        _id: "64f75285ef8f606073e99d5a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lopary",
        fokontany: "iabomora",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2140934,
          lng: 47.5938703,
        },
        _id: "64f75285ef8f606073e99d5b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lopary",
        fokontany: "anarabe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2061788,
          lng: 47.62091700000001,
        },
        _id: "64f75285ef8f606073e99d5c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lopary",
        fokontany: "soatsirana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2238522,
          lng: 47.6233764,
        },
        _id: "64f75285ef8f606073e99d5d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lopary",
        fokontany: "maroangaty",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2258606,
          lng: 47.7317071,
        },
        _id: "64f75285ef8f606073e99d5e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lopary",
        fokontany: "marolambo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2419289,
          lng: 47.7292426,
        },
        _id: "64f75285ef8f606073e99d5f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lopary",
        fokontany: "mahabe I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.254718,
          lng: 47.7095305,
        },
        _id: "64f75285ef8f606073e99d60",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lopary",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2321809,
          lng: 47.6996772,
        },
        _id: "64f75285ef8f606073e99d61",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lopary",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2562003,
          lng: 47.677514,
        },
        _id: "64f75285ef8f606073e99d62",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lopary",
        fokontany: "anandrotry I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1479769,
          lng: 47.5177229,
        },
        _id: "64f75285ef8f606073e99d63",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "bema",
        fokontany: "bema",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1431962,
          lng: 47.52508719999999,
        },
        _id: "64f75285ef8f606073e99d64",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "bema",
        fokontany: "ambohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1528203,
          lng: 47.52508719999999,
        },
        _id: "64f75285ef8f606073e99d65",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "bema",
        fokontany: "anakoho",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1607193,
          lng: 47.4980899,
        },
        _id: "64f75285ef8f606073e99d66",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "bema",
        fokontany: "ambalavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1673211,
          lng: 47.5398188,
        },
        _id: "64f75285ef8f606073e99d67",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "bema",
        fokontany: "sarianala",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1897925,
          lng: 47.5422745,
        },
        _id: "64f75285ef8f606073e99d68",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "bema",
        fokontany: "anandrotry II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2635005,
          lng: 47.52508719999999,
        },
        _id: "64f75285ef8f606073e99d69",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohitrambo",
        fokontany: "vohitrambo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2219272,
          lng: 47.5520984,
        },
        _id: "64f75285ef8f606073e99d6a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohitrambo",
        fokontany: "fitary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2410304,
          lng: 47.5226323,
        },
        _id: "64f75285ef8f606073e99d6b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohitrambo",
        fokontany: "ambatomiraviravy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2441327,
          lng: 47.5029975,
        },
        _id: "64f75285ef8f606073e99d6c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohitrambo",
        fokontany: "manilo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.269735,
          lng: 47.4931828,
        },
        _id: "64f75285ef8f606073e99d6d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohitrambo",
        fokontany: "morabe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2858868,
          lng: 47.512814,
        },
        _id: "64f75285ef8f606073e99d6e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohitrambo",
        fokontany: "ambohimary I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3084006,
          lng: 47.5226323,
        },
        _id: "64f75285ef8f606073e99d6f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohitrambo",
        fokontany: "vohitraomby",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2893187,
          lng: 47.5520984,
        },
        _id: "64f75285ef8f606073e99d70",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohitrambo",
        fokontany: "fonilaza",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3245165,
          lng: 47.5349078,
        },
        _id: "64f75285ef8f606073e99d71",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohitrambo",
        fokontany: "menagnaranavony",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3197913,
          lng: 47.5496422,
        },
        _id: "64f75285ef8f606073e99d72",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohitrambo",
        fokontany: "sahavoangy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3183281,
          lng: 47.5742088,
        },
        _id: "64f75285ef8f606073e99d73",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohitrambo",
        fokontany: "tsamoro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.199909,
          lng: 47.4805599,
        },
        _id: "64f75285ef8f606073e99d74",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "anilobe",
        fokontany: "anilobe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.167016,
          lng: 47.4735591,
        },
        _id: "64f75285ef8f606073e99d75",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "anilobe",
        fokontany: "ambohimahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.191025,
          lng: 47.4662021,
        },
        _id: "64f75285ef8f606073e99d76",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "anilobe",
        fokontany: "rorobe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1927662,
          lng: 47.4931828,
        },
        _id: "64f75285ef8f606073e99d77",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "anilobe",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2151105,
          lng: 47.4735591,
        },
        _id: "64f75285ef8f606073e99d78",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "anilobe",
        fokontany: "vohimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2057178,
          lng: 47.5177229,
        },
        _id: "64f75285ef8f606073e99d79",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "anilobe",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2280301,
          lng: 47.4907295,
        },
        _id: "64f75285ef8f606073e99d7a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "anilobe",
        fokontany: "morafeno II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2498655,
          lng: 47.3902404,
        },
        _id: "64f75285ef8f606073e99d7b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lohafary",
        fokontany: "lohafary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2177701,
          lng: 47.380447,
        },
        _id: "64f75285ef8f606073e99d7c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lohafary",
        fokontany: "ambalavandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1955506,
          lng: 47.41473209999999,
        },
        _id: "64f75285ef8f606073e99d7d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lohafary",
        fokontany: "fotsivavo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.211686,
          lng: 47.4343337,
        },
        _id: "64f75285ef8f606073e99d7e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lohafary",
        fokontany: "vohimangidy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.237442,
          lng: 47.4539427,
        },
        _id: "64f75285ef8f606073e99d7f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lohafary",
        fokontany: "ambohipeno",
        __v: 0,
      },
      {
        coords: {
          lat: -23.259941,
          lng: 47.4637499,
        },
        _id: "64f75285ef8f606073e99d80",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "lohafary",
        fokontany: "manambarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2333329,
          lng: 47.716667,
        },
        _id: "64f75285ef8f606073e99d81",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ampataka",
        fokontany: "ampataka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3015879,
          lng: 47.4588461,
        },
        _id: "64f75285ef8f606073e99d82",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ampataka",
        fokontany: "vohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2953311,
          lng: 47.48337,
        },
        _id: "64f75285ef8f606073e99d83",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ampataka",
        fokontany: "nosy ambo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3755697,
          lng: 47.4931828,
        },
        _id: "64f75285ef8f606073e99d84",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ampataka",
        fokontany: "mahela II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3781977,
          lng: 47.41473209999999,
        },
        _id: "64f75285ef8f606073e99d85",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ampataka",
        fokontany: "ankarefo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4104373,
          lng: 47.4392352,
        },
        _id: "64f75285ef8f606073e99d86",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ampataka",
        fokontany: "tanambao II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5251899,
          lng: 47.55386,
        },
        _id: "64f75285ef8f606073e99d87",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "matanga",
        fokontany: "matanga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.47394,
          lng: 47.5643808,
        },
        _id: "64f75285ef8f606073e99d88",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "matanga",
        fokontany: "ambatomitio",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4559395,
          lng: 47.5177229,
        },
        _id: "64f75285ef8f606073e99d89",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "matanga",
        fokontany: "mandritsara",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4843622,
          lng: 47.5631524,
        },
        _id: "64f75285ef8f606073e99d8a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "matanga",
        fokontany: "bevalevy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.491723,
          lng: 47.581581,
        },
        _id: "64f75285ef8f606073e99d8b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "matanga",
        fokontany: "vohitrapaly",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4826642,
          lng: 47.5508703,
        },
        _id: "64f75285ef8f606073e99d8c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "matanga",
        fokontany: "vohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4944953,
          lng: 47.52508719999999,
        },
        _id: "64f75285ef8f606073e99d8d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "matanga",
        fokontany: "tavivola",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4947841,
          lng: 47.5619241,
        },
        _id: "64f75285ef8f606073e99d8e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "matanga",
        fokontany: "vohitsirika",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5043264,
          lng: 47.5508703,
        },
        _id: "64f75285ef8f606073e99d8f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "matanga",
        fokontany: "ambatsipaky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5127946,
          lng: 47.6086213,
        },
        _id: "64f75285ef8f606073e99d90",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "matanga",
        fokontany: "ambalengo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5234312,
          lng: 47.5324525,
        },
        _id: "64f75285ef8f606073e99d91",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "matanga",
        fokontany: "marofototra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4886571,
          lng: 47.6012453,
        },
        _id: "64f75285ef8f606073e99d92",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "matanga",
        fokontany: "mahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5139834,
          lng: 47.5545546,
        },
        _id: "64f75285ef8f606073e99d93",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "matanga",
        fokontany: "anezandava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5239023,
          lng: 47.5914122,
        },
        _id: "64f75285ef8f606073e99d94",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "matanga",
        fokontany: "vohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.536499,
          lng: 47.5606957,
        },
        _id: "64f75285ef8f606073e99d95",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "matanga",
        fokontany: "tanatana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5490157,
          lng: 47.5226323,
        },
        _id: "64f75285ef8f606073e99d96",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "matanga",
        fokontany: "fapira",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5819362,
          lng: 47.62091700000001,
        },
        _id: "64f75285ef8f606073e99d97",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "masianaka",
        fokontany: "ankatafa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5241263,
          lng: 47.62091700000001,
        },
        _id: "64f75285ef8f606073e99d98",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "masianaka",
        fokontany: "antavimalahay",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5432243,
          lng: 47.5987868,
        },
        _id: "64f75285ef8f606073e99d99",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "masianaka",
        fokontany: "manasara",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5624299,
          lng: 47.5914122,
        },
        _id: "64f75285ef8f606073e99d9a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "masianaka",
        fokontany: "foromia",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5530312,
          lng: 47.62091700000001,
        },
        _id: "64f75285ef8f606073e99d9b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "masianaka",
        fokontany: "nosy omby",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5653539,
          lng: 47.557011,
        },
        _id: "64f75285ef8f606073e99d9c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "masianaka",
        fokontany: "antanamboro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5926556,
          lng: 47.5594675,
        },
        _id: "64f75285ef8f606073e99d9d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "masianaka",
        fokontany: "besavoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.596204,
          lng: 47.5987868,
        },
        _id: "64f75285ef8f606073e99d9e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "masianaka",
        fokontany: "nosi be",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6199588,
          lng: 47.5619241,
        },
        _id: "64f75285ef8f606073e99d9f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "masianaka",
        fokontany: "behovitra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6168591,
          lng: 47.5742088,
        },
        _id: "64f75285ef8f606073e99da0",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "masianaka",
        fokontany: "vodivolo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6363183,
          lng: 47.59632850000001,
        },
        _id: "64f75285ef8f606073e99da1",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "masianaka",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5488016,
          lng: 47.5000771,
        },
        _id: "64f75285ef8f606073e99da2",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohipaho",
        fokontany: "vohipaho",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4423274,
          lng: 47.4196318,
        },
        _id: "64f75285ef8f606073e99da3",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohipaho",
        fokontany: "menaretry",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4741349,
          lng: 47.3926891,
        },
        _id: "64f75285ef8f606073e99da4",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohipaho",
        fokontany: "volosy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5068547,
          lng: 47.4686543,
        },
        _id: "64f75285ef8f606073e99da5",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohipaho",
        fokontany: "lavalanga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5376819,
          lng: 47.5103597,
        },
        _id: "64f75285ef8f606073e99da6",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohipaho",
        fokontany: "manambotra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5708897,
          lng: 47.4588461,
        },
        _id: "64f75285ef8f606073e99da7",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohipaho",
        fokontany: "volomborony",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5585759,
          lng: 47.5152684,
        },
        _id: "64f75285ef8f606073e99da8",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohipaho",
        fokontany: "anandria",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5698475,
          lng: 47.5201775,
        },
        _id: "64f75285ef8f606073e99da9",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohipaho",
        fokontany: "kaboky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5680012,
          lng: 47.4931828,
        },
        _id: "64f75285ef8f606073e99daa",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohipaho",
        fokontany: "anezandava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6363183,
          lng: 47.59632850000001,
        },
        _id: "64f75285ef8f606073e99dab",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohipaho",
        fokontany: "mahatsinjo I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5935665,
          lng: 47.48337,
        },
        _id: "64f75285ef8f606073e99dac",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohipaho",
        fokontany: "mahajanga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5875808,
          lng: 47.5299973,
        },
        _id: "64f75285ef8f606073e99dad",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohipaho",
        fokontany: "vohidravy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6034648,
          lng: 47.512814,
        },
        _id: "64f75285ef8f606073e99dae",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohipaho",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6132941,
          lng: 47.5349078,
        },
        _id: "64f75285ef8f606073e99daf",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohipaho",
        fokontany: "mahatsinjo II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6248381,
          lng: 47.404934,
        },
        _id: "64f75285ef8f606073e99db0",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohipaho",
        fokontany: "sahakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6616369,
          lng: 47.557011,
        },
        _id: "64f75285ef8f606073e99db1",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "marokibo",
        fokontany: "marokibo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6356381,
          lng: 47.5226323,
        },
        _id: "64f75285ef8f606073e99db2",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "marokibo",
        fokontany: "vohimary II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6515832,
          lng: 47.512814,
        },
        _id: "64f75285ef8f606073e99db3",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "marokibo",
        fokontany: "vasia",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6617764,
          lng: 47.5717516,
        },
        _id: "64f75285ef8f606073e99db4",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "marokibo",
        fokontany: "ambanato",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6873603,
          lng: 47.5619241,
        },
        _id: "64f75285ef8f606073e99db5",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "marokibo",
        fokontany: "manarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7036019,
          lng: 47.581581,
        },
        _id: "64f75285ef8f606073e99db6",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "marokibo",
        fokontany: "karinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.42342,
          lng: 47.283859,
        },
        _id: "64f75285ef8f606073e99db7",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ranomena",
        fokontany: "ranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3267491,
          lng: 47.3902404,
        },
        _id: "64f75285ef8f606073e99db8",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ranomena",
        fokontany: "lalangy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4133583,
          lng: 47.2264444,
        },
        _id: "64f75285ef8f606073e99db9",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ranomena",
        fokontany: "beroroha",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4028744,
          lng: 47.3021661,
        },
        _id: "64f75285ef8f606073e99dba",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ranomena",
        fokontany: "ambalateny",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4098353,
          lng: 47.3657603,
        },
        _id: "64f75285ef8f606073e99dbb",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ranomena",
        fokontany: "tanambao matirano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5057758,
          lng: 47.3510778,
        },
        _id: "64f75285ef8f606073e99dbc",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ranomena",
        fokontany: "matavay",
        __v: 0,
      },
      {
        coords: {
          lat: -23.550507,
          lng: 47.3412918,
        },
        _id: "64f75285ef8f606073e99dbd",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ranomena",
        fokontany: "hazomena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5955427,
          lng: 47.3608657,
        },
        _id: "64f75285ef8f606073e99dbe",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ranomena",
        fokontany: "vohitrapanefy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4444507,
          lng: 47.2642028,
        },
        _id: "64f75285ef8f606073e99dbf",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ambongo",
        fokontany: "ambongo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4240802,
          lng: 47.1800893,
        },
        _id: "64f75285ef8f606073e99dc0",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ambongo",
        fokontany: "anapotatra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4243795,
          lng: 47.2093614,
        },
        _id: "64f75285ef8f606073e99dc1",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ambongo",
        fokontany: "mahamany",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4407842,
          lng: 47.2508586,
        },
        _id: "64f75285ef8f606073e99dc2",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ambongo",
        fokontany: "tsahalana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4850746,
          lng: 47.2044815,
        },
        _id: "64f75285ef8f606073e99dc3",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ambongo",
        fokontany: "ambalakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4984379,
          lng: 47.2581852,
        },
        _id: "64f75285ef8f606073e99dc4",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ambongo",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4922912,
          lng: 47.2826144,
        },
        _id: "64f75285ef8f606073e99dc5",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ambongo",
        fokontany: "mandrosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.566667,
          lng: 47.3,
        },
        _id: "64f75285ef8f606073e99dc6",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ambatolava",
        fokontany: "ambatolava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5302147,
          lng: 47.2386501,
        },
        _id: "64f75285ef8f606073e99dc7",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ambatolava",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5498883,
          lng: 47.2826144,
        },
        _id: "64f75285ef8f606073e99dc8",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ambatolava",
        fokontany: "tsaralera",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6132539,
          lng: 47.22888529999999,
        },
        _id: "64f75285ef8f606073e99dc9",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ambatolava",
        fokontany: "imambe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6333117,
          lng: 47.3021661,
        },
        _id: "64f75285ef8f606073e99dca",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "ambatolava",
        fokontany: "manararoba",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1345438,
          lng: 47.3902404,
        },
        _id: "64f75285ef8f606073e99dcb",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "iara",
        fokontany: "iara",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0514823,
          lng: 47.4441373,
        },
        _id: "64f75285ef8f606073e99dcc",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "iara",
        fokontany: "lovaela",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1156733,
          lng: 47.4637499,
        },
        _id: "64f75285ef8f606073e99dcd",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "iara",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0864239,
          lng: 47.37555099999999,
        },
        _id: "64f75285ef8f606073e99dce",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "iara",
        fokontany: "amboloabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1087408,
          lng: 47.3559715,
        },
        _id: "64f75285ef8f606073e99dcf",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "iara",
        fokontany: "ampefivato",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1077111,
          lng: 47.4760116,
        },
        _id: "64f75285ef8f606073e99dd0",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "iara",
        fokontany: "befotaky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.153513,
          lng: 47.3461846,
        },
        _id: "64f75285ef8f606073e99dd1",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "iara",
        fokontany: "tsararano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1213572,
          lng: 47.3217253,
        },
        _id: "64f75285ef8f606073e99dd2",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "iara",
        fokontany: "beretry",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1473304,
          lng: 47.3853435,
        },
        _id: "64f75285ef8f606073e99dd3",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "iara",
        fokontany: "ambohipeno",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1476367,
          lng: 47.4441373,
        },
        _id: "64f75285ef8f606073e99dd4",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "iara",
        fokontany: "fenoarivo II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.166667,
          lng: 47.433333,
        },
        _id: "64f75285ef8f606073e99dd5",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "iara",
        fokontany: "vohibola",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2833458,
          lng: 47.2833445,
        },
        _id: "64f75285ef8f606073e99dd6",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "bevata",
        fokontany: "bevata",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2684563,
          lng: 47.3021661,
        },
        _id: "64f75285ef8f606073e99dd7",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "bevata",
        fokontany: "amboangimamy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2743154,
          lng: 47.2337675,
        },
        _id: "64f75285ef8f606073e99dd8",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "bevata",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2940403,
          lng: 47.2997218,
        },
        _id: "64f75285ef8f606073e99dd9",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "bevata",
        fokontany: "voronabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3134173,
          lng: 47.3217253,
        },
        _id: "64f75285ef8f606073e99dda",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "bevata",
        fokontany: "manambaro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3578995,
          lng: 47.2826144,
        },
        _id: "64f75285ef8f606073e99ddb",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "bevata",
        fokontany: "mahaly",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2106436,
          lng: 47.2728413,
        },
        _id: "64f75285ef8f606073e99ddc",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "karimbary",
        fokontany: "karimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1307391,
          lng: 47.285058,
        },
        _id: "64f75285ef8f606073e99ddd",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "karimbary",
        fokontany: "anezandava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1982755,
          lng: 47.3363995,
        },
        _id: "64f75285ef8f606073e99dde",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "karimbary",
        fokontany: "anandretry",
        __v: 0,
      },
      {
        coords: {
          lat: -23.165919,
          lng: 47.2826144,
        },
        _id: "64f75285ef8f606073e99ddf",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "karimbary",
        fokontany: "mahafasa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.216971,
          lng: 47.2630701,
        },
        _id: "64f75285ef8f606073e99de0",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "karimbary",
        fokontany: "ranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7850645,
          lng: 47.5447303,
        },
        _id: "64f75285ef8f606073e99de1",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "manambondro",
        fokontany: "manambondro central",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7324942,
          lng: 47.581581,
        },
        _id: "64f75285ef8f606073e99de2",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "manambondro",
        fokontany: "kidilanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7418209,
          lng: 47.5520984,
        },
        _id: "64f75285ef8f606073e99de3",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "manambondro",
        fokontany: "mahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7613095,
          lng: 47.5742088,
        },
        _id: "64f75285ef8f606073e99de4",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "manambondro",
        fokontany: "vohilava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7983218,
          lng: 47.5465722,
        },
        _id: "64f75285ef8f606073e99de5",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "manambondro",
        fokontany: "vohindava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7838056,
          lng: 47.5766661,
        },
        _id: "64f75285ef8f606073e99de6",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "manambondro",
        fokontany: "matsinjoriaky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8012994,
          lng: 47.5438093,
        },
        _id: "64f75285ef8f606073e99de7",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "manambondro",
        fokontany: "ambanihampy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8107911,
          lng: 47.5496422,
        },
        _id: "64f75285ef8f606073e99de8",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "manambondro",
        fokontany: "ambatovanda",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8664484,
          lng: 47.5079055,
        },
        _id: "64f75285ef8f606073e99de9",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "fenoambany",
        fokontany: "fenoambany",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8,
          lng: 47.48333299999999,
        },
        _id: "64f75285ef8f606073e99dea",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "fenoambany",
        fokontany: "lavay",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8024086,
          lng: 47.5177229,
        },
        _id: "64f75285ef8f606073e99deb",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "fenoambany",
        fokontany: "befeno",
        __v: 0,
      },
      {
        coords: {
          lat: -23.847724,
          lng: 47.5520984,
        },
        _id: "64f75285ef8f606073e99dec",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "fenoambany",
        fokontany: "ambatosarotra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8440538,
          lng: 47.512814,
        },
        _id: "64f75285ef8f606073e99ded",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "fenoambany",
        fokontany: "isatra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9497156,
          lng: 47.4980899,
        },
        _id: "64f75285ef8f606073e99dee",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "fenoambany",
        fokontany: "somisiky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.883333,
          lng: 47.433333,
        },
        _id: "64f75285ef8f606073e99def",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "fenoambany",
        fokontany: "ankazomasy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.724871,
          lng: 47.3572879,
        },
        _id: "64f75285ef8f606073e99df0",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "isahara",
        fokontany: "isahara",
        __v: 0,
      },
      {
        coords: {
          lat: -23.65,
          lng: 47.333333,
        },
        _id: "64f75285ef8f606073e99df1",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "isahara",
        fokontany: "ambalasavoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6531893,
          lng: 47.3608657,
        },
        _id: "64f75285ef8f606073e99df2",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "isahara",
        fokontany: "marosalala",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6881309,
          lng: 47.3363995,
        },
        _id: "64f75285ef8f606073e99df3",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "isahara",
        fokontany: "anezandava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7293273,
          lng: 47.3021661,
        },
        _id: "64f75285ef8f606073e99df4",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "isahara",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7433322,
          lng: 47.4000357,
        },
        _id: "64f75285ef8f606073e99df5",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "isahara",
        fokontany: "ampasitsiriry",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7865444,
          lng: 47.2728413,
        },
        _id: "64f75285ef8f606073e99df6",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "isahara",
        fokontany: "andrarezo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7123662,
          lng: 47.4472725,
        },
        _id: "64f75285ef8f606073e99df7",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohimalaza",
        fokontany: "vohimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6478339,
          lng: 47.4588461,
        },
        _id: "64f75285ef8f606073e99df8",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohimalaza",
        fokontany: "bezavo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6863059,
          lng: 47.4588461,
        },
        _id: "64f75285ef8f606073e99df9",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohimalaza",
        fokontany: "saharoanga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7126354,
          lng: 47.5226323,
        },
        _id: "64f75285ef8f606073e99dfa",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohimalaza",
        fokontany: "sarilasy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7329836,
          lng: 47.4760116,
        },
        _id: "64f75285ef8f606073e99dfb",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohimalaza",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7639126,
          lng: 47.5177229,
        },
        _id: "64f75285ef8f606073e99dfc",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohimalaza",
        fokontany: "nosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8076544,
          lng: 47.4196318,
        },
        _id: "64f75285ef8f606073e99dfd",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vohimalaza",
        fokontany: "ambia",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6608529,
          lng: 47.1996022,
        },
        _id: "64f75285ef8f606073e99dfe",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vatanato",
        fokontany: "marindonaky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6318972,
          lng: 47.1849668,
        },
        _id: "64f75285ef8f606073e99dff",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vatanato",
        fokontany: "tsararano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6584468,
          lng: 47.2630701,
        },
        _id: "64f75285ef8f606073e99e00",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vatanato",
        fokontany: "iabolaly",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6894326,
          lng: 47.1849668,
        },
        _id: "64f75285ef8f606073e99e01",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vatanato",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.731317,
          lng: 47.2093614,
        },
        _id: "64f75285ef8f606073e99e02",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "vatanato",
        fokontany: "ambalafoly",
        __v: 0,
      },
      {
        coords: {
          lat: -23.971901,
          lng: 47.32410000000001,
        },
        _id: "64f75285ef8f606073e99e03",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "amparihy est",
        fokontany: "amparihy est",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9159878,
          lng: 47.1605839,
        },
        _id: "64f75285ef8f606073e99e04",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "amparihy est",
        fokontany: "andemobe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.849542,
          lng: 47.2044815,
        },
        _id: "64f75285ef8f606073e99e05",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "amparihy est",
        fokontany: "sahafata",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9017231,
          lng: 47.2728413,
        },
        _id: "64f75285ef8f606073e99e06",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "amparihy est",
        fokontany: "ambalarano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9512166,
          lng: 47.1654595,
        },
        _id: "64f75285ef8f606073e99e07",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "amparihy est",
        fokontany: "amboangy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.971105,
          lng: 47.2093614,
        },
        _id: "64f75285ef8f606073e99e08",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "amparihy est",
        fokontany: "mavorano",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0106546,
          lng: 47.2826144,
        },
        _id: "64f75285ef8f606073e99e09",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "amparihy est",
        fokontany: "amboatsila",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0370266,
          lng: 47.4367844,
        },
        _id: "64f75285ef8f606073e99e0a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "sandravinany",
        fokontany: "sandravinany I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0057554,
          lng: 47.380447,
        },
        _id: "64f75285ef8f606073e99e0b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "sandravinany",
        fokontany: "mahavoriky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9551979,
          lng: 47.4294326,
        },
        _id: "64f75285ef8f606073e99e0c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "sandravinany",
        fokontany: "befasy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0499879,
          lng: 47.3412918,
        },
        _id: "64f75285ef8f606073e99e0d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "sandravinany",
        fokontany: "tsihary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0396996,
          lng: 47.4551685,
        },
        _id: "64f75285ef8f606073e99e0e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "sandravinany",
        fokontany: "sandravinany II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0893394,
          lng: 47.4000357,
        },
        _id: "64f75285ef8f606073e99e0f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vangaindrano",
        commune: "sandravinany",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6307575,
          lng: 47.0972424,
        },
        _id: "64f75285ef8f606073e99e10",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "nosifeno",
        fokontany: "nosifeno",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5345013,
          lng: 47.0607349,
        },
        _id: "64f75285ef8f606073e99e11",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "nosifeno",
        fokontany: "bekofafa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5476699,
          lng: 47.0923732,
        },
        _id: "64f75285ef8f606073e99e12",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "nosifeno",
        fokontany: "nanarena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5584417,
          lng: 47.0607349,
        },
        _id: "64f75285ef8f606073e99e13",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "nosifeno",
        fokontany: "milahila",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5701459,
          lng: 47.102112,
        },
        _id: "64f75285ef8f606073e99e14",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "nosifeno",
        fokontany: "ambonihasy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5646719,
          lng: 46.9319509,
        },
        _id: "64f75285ef8f606073e99e15",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "nosifeno",
        fokontany: "manombo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5988875,
          lng: 47.102112,
        },
        _id: "64f75285ef8f606073e99e16",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "nosifeno",
        fokontany: "beharena I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.60958,
          lng: 46.9513691,
        },
        _id: "64f75285ef8f606073e99e17",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "nosifeno",
        fokontany: "ankarinoro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6167556,
          lng: 47.0072378,
        },
        _id: "64f75285ef8f606073e99e18",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "nosifeno",
        fokontany: "morondava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6297566,
          lng: 47.0242535,
        },
        _id: "64f75285ef8f606073e99e19",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "nosifeno",
        fokontany: "vohimanoro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6565688,
          lng: 47.11672369999999,
        },
        _id: "64f75285ef8f606073e99e1a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "nosifeno",
        fokontany: "maroangaty",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6932108,
          lng: 46.9999471,
        },
        _id: "64f75285ef8f606073e99e1b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "ankazovelo",
        fokontany: "ankazovelo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6537914,
          lng: 47.0315477,
        },
        _id: "64f75285ef8f606073e99e1c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "ankazovelo",
        fokontany: "manatotsokora",
        __v: 0,
      },
      {
        coords: {
          lat: -23.66762,
          lng: 46.9950872,
        },
        _id: "64f75285ef8f606073e99e1d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "ankazovelo",
        fokontany: "voanana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6602972,
          lng: 46.9319509,
        },
        _id: "64f75285ef8f606073e99e1e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "ankazovelo",
        fokontany: "ankazomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6635729,
          lng: 47.0461392,
        },
        _id: "64f75285ef8f606073e99e1f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "ankazovelo",
        fokontany: "ambasohihy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6742855,
          lng: 47.01452949999999,
        },
        _id: "64f75285ef8f606073e99e20",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "ankazovelo",
        fokontany: "telorano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.706978,
          lng: 47.0680343,
        },
        _id: "64f75285ef8f606073e99e21",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "ankazovelo",
        fokontany: "bemahala",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7181305,
          lng: 46.9610809,
        },
        _id: "64f75285ef8f606073e99e22",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "ankazovelo",
        fokontany: "mahazoarivo atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.450294,
          lng: 46.9610809,
        },
        _id: "64f75285ef8f606073e99e23",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "andranolalina",
        fokontany: "andranolalina",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3605579,
          lng: 46.9222445,
        },
        _id: "64f75285ef8f606073e99e24",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "andranolalina",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4182894,
          lng: 46.9513691,
        },
        _id: "64f75285ef8f606073e99e25",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "andranolalina",
        fokontany: "ampatranila",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4442195,
          lng: 46.9853688,
        },
        _id: "64f75285ef8f606073e99e26",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "andranolalina",
        fokontany: "ambararata",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4499222,
          lng: 46.9319509,
        },
        _id: "64f75285ef8f606073e99e27",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "andranolalina",
        fokontany: "haramanga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5135394,
          lng: 46.9222445,
        },
        _id: "64f75285ef8f606073e99e28",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "andranolalina",
        fokontany: "mahasoa analatelo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.517829,
          lng: 47.0048074,
        },
        _id: "64f75285ef8f606073e99e29",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "andranolalina",
        fokontany: "ampatramary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3173203,
          lng: 47.0485715,
        },
        _id: "64f75285ef8f606073e99e2a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "maliorano",
        fokontany: "zaraha",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3297233,
          lng: 47.01452949999999,
        },
        _id: "64f75285ef8f606073e99e2b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "maliorano",
        fokontany: "mikaiky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3615721,
          lng: 47.0096682,
        },
        _id: "64f75285ef8f606073e99e2c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "maliorano",
        fokontany: "sahanety",
        __v: 0,
      },
      {
        coords: {
          lat: -23.375085,
          lng: 47.0777685,
        },
        _id: "64f75285ef8f606073e99e2d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "maliorano",
        fokontany: "mahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4011519,
          lng: 47.1264672,
        },
        _id: "64f75285ef8f606073e99e2e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "maliorano",
        fokontany: "marovovo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.426276,
          lng: 47.08750449999999,
        },
        _id: "64f75285ef8f606073e99e2f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "maliorano",
        fokontany: "tsararano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4064604,
          lng: 47.0291162,
        },
        _id: "64f75285ef8f606073e99e30",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "maliorano",
        fokontany: "ambodisahy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.452689,
          lng: 47.1654595,
        },
        _id: "64f75285ef8f606073e99e31",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "maliorano",
        fokontany: "ankalatany",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4638964,
          lng: 47.0291162,
        },
        _id: "64f75285ef8f606073e99e32",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "maliorano",
        fokontany: "beharenade",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5032558,
          lng: 47.11672369999999,
        },
        _id: "64f75285ef8f606073e99e33",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "maliorano",
        fokontany: "bevao",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2840692,
          lng: 46.9222445,
        },
        _id: "64f75285ef8f606073e99e34",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "ivondro",
        fokontany: "lavaraty",
        __v: 0,
      },
      {
        coords: {
          lat: -23.169049,
          lng: 46.8931368,
        },
        _id: "64f75285ef8f606073e99e35",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "ivondro",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1473537,
          lng: 46.9562248,
        },
        _id: "64f75285ef8f606073e99e36",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "ivondro",
        fokontany: "benonoky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1318894,
          lng: 47.0096682,
        },
        _id: "64f75285ef8f606073e99e37",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "ivondro",
        fokontany: "sahatsoro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2087219,
          lng: 47.0388429,
        },
        _id: "64f75285ef8f606073e99e38",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "ivondro",
        fokontany: "marozano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2138553,
          lng: 46.91254010000001,
        },
        _id: "64f75285ef8f606073e99e39",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "ivondro",
        fokontany: "ankarinoro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2732919,
          lng: 47.11672369999999,
        },
        _id: "64f75285ef8f606073e99e3a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "ivondro",
        fokontany: "analaiva",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3,
          lng: 46.98333299999999,
        },
        _id: "64f75285ef8f606073e99e3b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "ivondro",
        fokontany: "ampasy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.029439,
          lng: 46.9610809,
        },
        _id: "64f75285ef8f606073e99e3c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "soakibany",
        fokontany: "soakibany",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9518007,
          lng: 46.815598,
        },
        _id: "64f75285ef8f606073e99e3d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "soakibany",
        fokontany: "mahasoa ikiro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0172683,
          lng: 47.0388429,
        },
        _id: "64f75285ef8f606073e99e3e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "soakibany",
        fokontany: "bearahotra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9904915,
          lng: 46.8737409,
        },
        _id: "64f75285ef8f606073e99e3f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "soakibany",
        fokontany: "ambodijoho",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0546163,
          lng: 46.9222445,
        },
        _id: "64f75285ef8f606073e99e40",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "soakibany",
        fokontany: "antaramihery",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0478194,
          lng: 46.8737409,
        },
        _id: "64f75285ef8f606073e99e41",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "soakibany",
        fokontany: "amboangy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1117049,
          lng: 46.8931368,
        },
        _id: "64f75285ef8f606073e99e42",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "midongy-atsimo",
        commune: "soakibany",
        fokontany: "fasikendry",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8109177,
          lng: 47.3302847,
        },
        _id: "64f75285ef8f606073e99e43",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "vondrozo",
        fokontany: "vondrozo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8013239,
          lng: 47.3339535,
        },
        _id: "64f75285ef8f606073e99e44",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "vondrozo",
        fokontany: "analavaky",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8395903,
          lng: 47.2826144,
        },
        _id: "64f75285ef8f606073e99e45",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "vondrozo",
        fokontany: "antevongo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.82214,
          lng: 47.3388456,
        },
        _id: "64f75285ef8f606073e99e46",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "vondrozo",
        fokontany: "masitafika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7372177,
          lng: 47.2777276,
        },
        _id: "64f75285ef8f606073e99e47",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "manambidala",
        fokontany: "antsoro I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.692359,
          lng: 47.22888529999999,
        },
        _id: "64f75285ef8f606073e99e48",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "manambidala",
        fokontany: "vohimarina nord",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6860485,
          lng: 47.2826144,
        },
        _id: "64f75285ef8f606073e99e49",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "manambidala",
        fokontany: "lohanosy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.711596,
          lng: 47.2581852,
        },
        _id: "64f75285ef8f606073e99e4a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "manambidala",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -22.714859,
          lng: 47.2972775,
        },
        _id: "64f75285ef8f606073e99e4b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "manambidala",
        fokontany: "antondabe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7277132,
          lng: 47.3363995,
        },
        _id: "64f75285ef8f606073e99e4c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "manambidala",
        fokontany: "vohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7434955,
          lng: 47.2240036,
        },
        _id: "64f75285ef8f606073e99e4d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "manambidala",
        fokontany: "vohilava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.766005,
          lng: 47.2777276,
        },
        _id: "64f75285ef8f606073e99e4e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "manambidala",
        fokontany: "antsoro II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7660667,
          lng: 47.3070552,
        },
        _id: "64f75285ef8f606073e99e4f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "manambidala",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7597352,
          lng: 47.3461846,
        },
        _id: "64f75285ef8f606073e99e50",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "manambidala",
        fokontany: "antsoro est",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7565813,
          lng: 47.380447,
        },
        _id: "64f75285ef8f606073e99e51",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "manambidala",
        fokontany: "manotronotro",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7883057,
          lng: 47.24353319999999,
        },
        _id: "64f75285ef8f606073e99e52",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "manambidala",
        fokontany: "madiorano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9039963,
          lng: 47.4196318,
        },
        _id: "64f75285ef8f606073e99e53",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "anandravy",
        fokontany: "nandrotona",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8781643,
          lng: 47.3412918,
        },
        _id: "64f75285ef8f606073e99e54",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "anandravy",
        fokontany: "ifoitry",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9357858,
          lng: 47.3412918,
        },
        _id: "64f75285ef8f606073e99e55",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "anandravy",
        fokontany: "matsinamalona",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9616683,
          lng: 47.4196318,
        },
        _id: "64f75285ef8f606073e99e56",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "anandravy",
        fokontany: "salalangy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8151352,
          lng: 47.4527169,
        },
        _id: "64f75285ef8f606073e99e57",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahatsinjo",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6957544,
          lng: 47.4735591,
        },
        _id: "64f75285ef8f606073e99e58",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahatsinjo",
        fokontany: "anandrea",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7230001,
          lng: 47.4760116,
        },
        _id: "64f75285ef8f606073e99e59",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahatsinjo",
        fokontany: "ferena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7438274,
          lng: 47.4514912,
        },
        _id: "64f75285ef8f606073e99e5a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahatsinjo",
        fokontany: "ambalabe mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7566517,
          lng: 47.461298,
        },
        _id: "64f75285ef8f606073e99e5b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahatsinjo",
        fokontany: "sarilasy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7758496,
          lng: 47.4245319,
        },
        _id: "64f75285ef8f606073e99e5c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahatsinjo",
        fokontany: "tsiloakarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7790892,
          lng: 47.4637499,
        },
        _id: "64f75285ef8f606073e99e5d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahatsinjo",
        fokontany: "andranomavo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8078091,
          lng: 47.37555099999999,
        },
        _id: "64f75285ef8f606073e99e5e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahatsinjo",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8271858,
          lng: 47.4784643,
        },
        _id: "64f75285ef8f606073e99e5f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahatsinjo",
        fokontany: "ampataka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8527704,
          lng: 47.4392352,
        },
        _id: "64f75285ef8f606073e99e60",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahatsinjo",
        fokontany: "ianakasy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8398521,
          lng: 47.3853435,
        },
        _id: "64f75285ef8f606073e99e61",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahatsinjo",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8687429,
          lng: 47.41473209999999,
        },
        _id: "64f75285ef8f606073e99e62",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahatsinjo",
        fokontany: "mangaikarea",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8718664,
          lng: 47.380447,
        },
        _id: "64f75285ef8f606073e99e63",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahatsinjo",
        fokontany: "volojatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.659571,
          lng: 47.2924,
        },
        _id: "64f75285ef8f606073e99e64",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahazoarivo",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6060961,
          lng: 47.3461846,
        },
        _id: "64f75285ef8f606073e99e65",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahazoarivo",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6092862,
          lng: 47.3853435,
        },
        _id: "64f75285ef8f606073e99e66",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahazoarivo",
        fokontany: "mahasoa I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6284879,
          lng: 47.41473209999999,
        },
        _id: "64f75285ef8f606073e99e67",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahazoarivo",
        fokontany: "ambalakibo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6188967,
          lng: 47.3266162,
        },
        _id: "64f75285ef8f606073e99e68",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahazoarivo",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6348843,
          lng: 47.4343337,
        },
        _id: "64f75285ef8f606073e99e69",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahazoarivo",
        fokontany: "alohavala",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6316703,
          lng: 47.2777276,
        },
        _id: "64f75285ef8f606073e99e6a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahazoarivo",
        fokontany: "tamboholava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6348366,
          lng: 47.24353319999999,
        },
        _id: "64f75285ef8f606073e99e6b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahazoarivo",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.654111,
          lng: 47.3608657,
        },
        _id: "64f75285ef8f606073e99e6c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahazoarivo",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6925522,
          lng: 47.4196318,
        },
        _id: "64f75285ef8f606073e99e6d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "iamonta",
        fokontany: "iamonta",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6605072,
          lng: 47.4539427,
        },
        _id: "64f75285ef8f606073e99e6e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "iamonta",
        fokontany: "vohimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6765351,
          lng: 47.41473209999999,
        },
        _id: "64f75285ef8f606073e99e6f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "iamonta",
        fokontany: "vohibe II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6861454,
          lng: 47.4441373,
        },
        _id: "64f75285ef8f606073e99e70",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "iamonta",
        fokontany: "anaviavy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7181661,
          lng: 47.3951378,
        },
        _id: "64f75285ef8f606073e99e71",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "iamonta",
        fokontany: "mahasoa II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7149743,
          lng: 47.41473209999999,
        },
        _id: "64f75285ef8f606073e99e72",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "iamonta",
        fokontany: "mariandry",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9867279,
          lng: 47.2777276,
        },
        _id: "64f75285ef8f606073e99e73",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "vohimary",
        fokontany: "ratsimanahy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9035347,
          lng: 47.2728413,
        },
        _id: "64f75285ef8f606073e99e74",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "vohimary",
        fokontany: "antesonjo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9863625,
          lng: 47.2044815,
        },
        _id: "64f75285ef8f606073e99e75",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "vohimary",
        fokontany: "bemahala",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0123451,
          lng: 47.2826144,
        },
        _id: "64f75285ef8f606073e99e76",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "vohimary",
        fokontany: "zafinivola",
        __v: 0,
      },
      {
        coords: {
          lat: -23.120419,
          lng: 47.1752123,
        },
        _id: "64f75285ef8f606073e99e77",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "vohimary",
        fokontany: "vohitsidy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0062566,
          lng: 47.3510778,
        },
        _id: "64f75285ef8f606073e99e78",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "antokonala",
        fokontany: "antemanara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9534943,
          lng: 47.3694316,
        },
        _id: "64f75285ef8f606073e99e79",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "antokonala",
        fokontany: "bemandresy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9711293,
          lng: 47.37555099999999,
        },
        _id: "64f75285ef8f606073e99e7a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "antokonala",
        fokontany: "zafindramana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0321397,
          lng: 47.41473209999999,
        },
        _id: "64f75285ef8f606073e99e7b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "antokonala",
        fokontany: "andriamareo fitakana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0575314,
          lng: 47.3608657,
        },
        _id: "64f75285ef8f606073e99e7c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "antokonala",
        fokontany: "sahalava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5581132,
          lng: 47.2728413,
        },
        _id: "64f75285ef8f606073e99e7d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "ambohimana",
        fokontany: "ambohimana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.516667,
          lng: 47.333333,
        },
        _id: "64f75285ef8f606073e99e7e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "ambohimana",
        fokontany: "ambarimafaitra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5325273,
          lng: 47.2826144,
        },
        _id: "64f75285ef8f606073e99e7f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "ambohimana",
        fokontany: "anivorano II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5676696,
          lng: 47.37555099999999,
        },
        _id: "64f75285ef8f606073e99e80",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "ambohimana",
        fokontany: "ferena I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5772911,
          lng: 47.24353319999999,
        },
        _id: "64f75285ef8f606073e99e81",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "ambohimana",
        fokontany: "fenaomby",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6188876,
          lng: 47.2972775,
        },
        _id: "64f75285ef8f606073e99e82",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "ambohimana",
        fokontany: "mandritany",
        __v: 0,
      },
      {
        coords: {
          lat: -22.43297,
          lng: 47.36887,
        },
        _id: "64f75285ef8f606073e99e83",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "karianga",
        fokontany: "karianga",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3914096,
          lng: 47.4392352,
        },
        _id: "64f75285ef8f606073e99e84",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "karianga",
        fokontany: "elomaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.404479,
          lng: 47.3461846,
        },
        _id: "64f75285ef8f606073e99e85",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "karianga",
        fokontany: "esongy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4202815,
          lng: 47.4245319,
        },
        _id: "64f75285ef8f606073e99e86",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "karianga",
        fokontany: "mandritsara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4139318,
          lng: 47.404934,
        },
        _id: "64f75285ef8f606073e99e87",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "karianga",
        fokontany: "marovandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4556879,
          lng: 47.3412918,
        },
        _id: "64f75285ef8f606073e99e88",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "karianga",
        fokontany: "sosobahy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4682887,
          lng: 47.4392352,
        },
        _id: "64f75285ef8f606073e99e89",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "karianga",
        fokontany: "karimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4844659,
          lng: 47.3559715,
        },
        _id: "64f75285ef8f606073e99e8a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "karianga",
        fokontany: "mahatsinjobe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4684182,
          lng: 47.380447,
        },
        _id: "64f75285ef8f606073e99e8b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "karianga",
        fokontany: "vohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4391261,
          lng: 47.5422745,
        },
        _id: "64f75285ef8f606073e99e8c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "manato",
        fokontany: "nanarena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.39453,
          lng: 47.4637499,
        },
        _id: "64f75285ef8f606073e99e8d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "manato",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4104317,
          lng: 47.4980899,
        },
        _id: "64f75285ef8f606073e99e8e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "manato",
        fokontany: "manato",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4585568,
          lng: 47.48337,
        },
        _id: "64f75285ef8f606073e99e8f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "manato",
        fokontany: "ambolomena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4873206,
          lng: 47.512814,
        },
        _id: "64f75285ef8f606073e99e90",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "manato",
        fokontany: "marakibo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4839604,
          lng: 47.5619241,
        },
        _id: "64f75285ef8f606073e99e91",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "manato",
        fokontany: "mahasoa I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5064746,
          lng: 47.5422745,
        },
        _id: "64f75285ef8f606073e99e92",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "manato",
        fokontany: "vohimary II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.416658,
          lng: 47.271085,
        },
        _id: "64f75285ef8f606073e99e93",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "ivato",
        fokontany: "ivato",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3854973,
          lng: 47.24353319999999,
        },
        _id: "64f75285ef8f606073e99e94",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "ivato",
        fokontany: "emita",
        __v: 0,
      },
      {
        coords: {
          lat: -22.416667,
          lng: 47.2,
        },
        _id: "64f75285ef8f606073e99e95",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "ivato",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4749769,
          lng: 47.2679555,
        },
        _id: "64f75285ef8f606073e99e96",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "ivato",
        fokontany: "nosivelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4718092,
          lng: 47.22888529999999,
        },
        _id: "64f75285ef8f606073e99e97",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "ivato",
        fokontany: "ferena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4429555,
          lng: 47.3021661,
        },
        _id: "64f75285ef8f606073e99e98",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "ivato",
        fokontany: "iandray",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4909483,
          lng: 47.2875016,
        },
        _id: "64f75285ef8f606073e99e99",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "ivato",
        fokontany: "mahasoa II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5005738,
          lng: 47.22888529999999,
        },
        _id: "64f75285ef8f606073e99e9a",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "ivato",
        fokontany: "amboangy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3531767,
          lng: 47.380447,
        },
        _id: "64f75285ef8f606073e99e9b",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahavelo",
        fokontany: "mahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2538005,
          lng: 47.41473209999999,
        },
        _id: "64f75285ef8f606073e99e9c",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahavelo",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2890616,
          lng: 47.404934,
        },
        _id: "64f75285ef8f606073e99e9d",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahavelo",
        fokontany: "ivato II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3145973,
          lng: 47.4245319,
        },
        _id: "64f75285ef8f606073e99e9e",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahavelo",
        fokontany: "fenahomby",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3370864,
          lng: 47.404934,
        },
        _id: "64f75285ef8f606073e99e9f",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahavelo",
        fokontany: "antenimary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3596951,
          lng: 47.3412918,
        },
        _id: "64f75285ef8f606073e99ea0",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahavelo",
        fokontany: "ampasindava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3594656,
          lng: 47.41473209999999,
        },
        _id: "64f75285ef8f606073e99ea1",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "mahavelo",
        fokontany: "tsararivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5155863,
          lng: 47.453924,
        },
        _id: "64f75285ef8f606073e99ea2",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "andakana",
        fokontany: "andakana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5195805,
          lng: 47.4196318,
        },
        _id: "64f75285ef8f606073e99ea3",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "andakana",
        fokontany: "mahasoa III",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5324414,
          lng: 47.3853435,
        },
        _id: "64f75285ef8f606073e99ea4",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "andakana",
        fokontany: "andemaka II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.56554,
          lng: 47.52702,
        },
        _id: "64f75285ef8f606073e99ea5",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "andakana",
        fokontany: "ambalakazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5772346,
          lng: 47.4196318,
        },
        _id: "64f75285ef8f606073e99ea6",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "andakana",
        fokontany: "iabomary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5900031,
          lng: 47.4588461,
        },
        _id: "64f75285ef8f606073e99ea7",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "andakana",
        fokontany: "mahatsara II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2974609,
          lng: 47.179531,
        },
        _id: "64f75285ef8f606073e99ea8",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "moroteza",
        fokontany: "moroteza",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2069472,
          lng: 47.1069821,
        },
        _id: "64f75285ef8f606073e99ea9",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "moroteza",
        fokontany: "bemahala",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2514371,
          lng: 47.1849668,
        },
        _id: "64f75285ef8f606073e99eaa",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "moroteza",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2641071,
          lng: 47.2240036,
        },
        _id: "64f75285ef8f606073e99eab",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "moroteza",
        fokontany: "ivato I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2959947,
          lng: 47.2484167,
        },
        _id: "64f75285ef8f606073e99eac",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "moroteza",
        fokontany: "emita",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3089244,
          lng: 47.1849668,
        },
        _id: "64f75285ef8f606073e99ead",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "moroteza",
        fokontany: "iapombo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3343139,
          lng: 47.2630701,
        },
        _id: "64f75285ef8f606073e99eae",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "vohiboreka",
        fokontany: "vohiboreka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3215145,
          lng: 47.2679555,
        },
        _id: "64f75285ef8f606073e99eaf",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "vohiboreka",
        fokontany: "anara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3471435,
          lng: 47.24353319999999,
        },
        _id: "64f75285ef8f606073e99eb0",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "vohiboreka",
        fokontany: "maroangira",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3565624,
          lng: 47.3168348,
        },
        _id: "64f75285ef8f606073e99eb1",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "vondrozo",
        commune: "vohiboreka",
        fokontany: "nangananga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8249609,
          lng: 46.97843899999999,
        },
        _id: "64f75285ef8f606073e99eb2",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "befotaka sud",
        fokontany: "befotaka sud",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8195064,
          lng: 46.9222445,
        },
        _id: "64f75285ef8f606073e99eb3",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "befotaka sud",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8468067,
          lng: 47.0291162,
        },
        _id: "64f75285ef8f606073e99eb4",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "befotaka sud",
        fokontany: "ambondro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8841008,
          lng: 46.9707947,
        },
        _id: "64f75285ef8f606073e99eb5",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "befotaka sud",
        fokontany: "andasy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8866937,
          lng: 46.9368047,
        },
        _id: "64f75285ef8f606073e99eb6",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "befotaka sud",
        fokontany: "ambohimahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8696337,
          lng: 46.8737409,
        },
        _id: "64f75285ef8f606073e99eb7",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "befotaka sud",
        fokontany: "belenalena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8918422,
          lng: 46.8688931,
        },
        _id: "64f75285ef8f606073e99eb8",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "befotaka sud",
        fokontany: "soabonaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9269699,
          lng: 46.8737409,
        },
        _id: "64f75285ef8f606073e99eb9",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "befotaka sud",
        fokontany: "soavarina",
        __v: 0,
      },
      {
        coords: {
          lat: -23.848208,
          lng: 47.11672369999999,
        },
        _id: "64f75285ef8f606073e99eba",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "antondabe",
        fokontany: "antondabe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8098806,
          lng: 47.11672369999999,
        },
        _id: "64f75285ef8f606073e99ebb",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "antondabe",
        fokontany: "ambohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8579043,
          lng: 47.1240312,
        },
        _id: "64f75285ef8f606073e99ebc",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "antondabe",
        fokontany: "vorombe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9109542,
          lng: 47.0485715,
        },
        _id: "64f75285ef8f606073e99ebd",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "antondabe",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8927656,
          lng: 47.1069821,
        },
        _id: "64f75285ef8f606073e99ebe",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "antondabe",
        fokontany: "masombola",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8745225,
          lng: 47.1654595,
        },
        _id: "64f75285ef8f606073e99ebf",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "antondabe",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8910284,
          lng: 47.1996022,
        },
        _id: "64f75285ef8f606073e99ec0",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "antondabe",
        fokontany: "ampisopiso",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1131015,
          lng: 46.941659,
        },
        _id: "64f75285ef8f606073e99ec1",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "marovitsika sud",
        fokontany: "marovitsika sud",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9702404,
          lng: 46.805914,
        },
        _id: "64f75285ef8f606073e99ec2",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "marovitsika sud",
        fokontany: "antokoboritelo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.015774,
          lng: 46.8543525,
        },
        _id: "64f75285ef8f606073e99ec3",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "marovitsika sud",
        fokontany: "belenalena atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0330959,
          lng: 46.7671967,
        },
        _id: "64f75285ef8f606073e99ec4",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "marovitsika sud",
        fokontany: "mikaikarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0624795,
          lng: 46.9610809,
        },
        _id: "64f75285ef8f606073e99ec5",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "marovitsika sud",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.116667,
          lng: 46.95,
        },
        _id: "64f75285ef8f606073e99ec6",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "marovitsika sud",
        fokontany: "amboraka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.235571,
          lng: 46.903999,
        },
        _id: "64f75285ef8f606073e99ec7",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "marovitsika sud",
        fokontany: "ankazolahy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9870269,
          lng: 47.0193913,
        },
        _id: "64f75285ef8f606073e99ec8",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "antaninarenina",
        fokontany: "antaninarenina",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9120195,
          lng: 46.9270975,
        },
        _id: "64f75285ef8f606073e99ec9",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "antaninarenina",
        fokontany: "amboropotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9221062,
          lng: 46.9562248,
        },
        _id: "64f75285ef8f606073e99eca",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "antaninarenina",
        fokontany: "betamotamo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9468993,
          lng: 46.9173921,
        },
        _id: "64f75285ef8f606073e99ecb",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "antaninarenina",
        fokontany: "fanjahira nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9870269,
          lng: 47.0193913,
        },
        _id: "64f75285ef8f606073e99ecc",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "antaninarenina",
        fokontany: "beharena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7008522,
          lng: 46.6898516,
        },
        _id: "64f75285ef8f606073e99ecd",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "ranotsara sud",
        fokontany: "ranotsara sud",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6225226,
          lng: 46.574058,
        },
        _id: "64f75285ef8f606073e99ece",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "ranotsara sud",
        fokontany: "besakoa vatovoay",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6646728,
          lng: 46.805914,
        },
        _id: "64f75285ef8f606073e99ecf",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "ranotsara sud",
        fokontany: "inosy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6812622,
          lng: 46.660878,
        },
        _id: "64f75285ef8f606073e99ed0",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "ranotsara sud",
        fokontany: "belenalena ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7488025,
          lng: 46.7043448,
        },
        _id: "64f75285ef8f606073e99ed1",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "ranotsara sud",
        fokontany: "ampatramary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8,
          lng: 46.716667,
        },
        _id: "64f75285ef8f606073e99ed2",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "ranotsara sud",
        fokontany: "ambinany",
        __v: 0,
      },
      {
        coords: {
          lat: -23.85,
          lng: 46.833333,
        },
        _id: "64f75285ef8f606073e99ed3",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "beharena",
        fokontany: "beharena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7673705,
          lng: 46.8543525,
        },
        _id: "64f75285ef8f606073e99ed4",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "beharena",
        fokontany: "ianakoty",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7787444,
          lng: 46.7768732,
        },
        _id: "64f75285ef8f606073e99ed5",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "beharena",
        fokontany: "ankarapotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8303672,
          lng: 46.815598,
        },
        _id: "64f75285ef8f606073e99ed6",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "beharena",
        fokontany: "ankira",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8803824,
          lng: 46.7671967,
        },
        _id: "64f75285ef8f606073e99ed7",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "beharena",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.861099,
          lng: 46.8422385,
        },
        _id: "64f75285ef8f606073e99ed8",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "beharena",
        fokontany: "ifasy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8253169,
          lng: 46.5547852,
        },
        _id: "64f75285ef8f606073e99ed9",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "bekofafa sud",
        fokontany: "bekofafa sud",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7099202,
          lng: 46.4970116,
        },
        _id: "64f75285ef8f606073e99eda",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "bekofafa sud",
        fokontany: "andria",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7933808,
          lng: 46.5451516,
        },
        _id: "64f75285ef8f606073e99edb",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "bekofafa sud",
        fokontany: "ankazovelo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7819294,
          lng: 46.6078033,
        },
        _id: "64f75285ef8f606073e99edc",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "bekofafa sud",
        fokontany: "andranovory",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8106147,
          lng: 46.4585333,
        },
        _id: "64f75285ef8f606073e99edd",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "bekofafa sud",
        fokontany: "soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8522894,
          lng: 46.6319212,
        },
        _id: "64f75285ef8f606073e99ede",
        province: "fianarantsoa",
        region: "atsimo atsinanana",
        district: "befotaka",
        commune: "bekofafa sud",
        fokontany: "andioteny",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1565009,
          lng: 49.4129294,
        },
        _id: "64f75285ef8f606073e99edf",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ambodimanga",
        fokontany: "centre ville bazaribe 32/24",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1462465,
          lng: 49.41546990000001,
        },
        _id: "64f75285ef8f606073e99ee0",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ambodimanga",
        fokontany: "cite dupleix 32/12",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1492351,
          lng: 49.4129294,
        },
        _id: "64f75285ef8f606073e99ee1",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ambodimanga",
        fokontany: "cite nouvelle ville 32/11",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1494797,
          lng: 49.40911879999999,
        },
        _id: "64f75285ef8f606073e99ee2",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ambodimanga",
        fokontany: "cite marabout 32/13",
        __v: 0,
      },
      {
        coords: {
          lat: -18.150996,
          lng: 49.4043559,
        },
        _id: "64f75285ef8f606073e99ee3",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ambodimanga",
        fokontany: "beryl rose 13/64",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1529344,
          lng: 49.4024508,
        },
        _id: "64f75285ef8f606073e99ee4",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ambodimanga",
        fokontany: "beryl rose 13/63",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1531345,
          lng: 49.4056259,
        },
        _id: "64f75285ef8f606073e99ee5",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ambodimanga",
        fokontany: "cite procoops/bazarikely 31/22",
        __v: 0,
      },
      {
        coords: {
          lat: -18.155212,
          lng: 49.4078486,
        },
        _id: "64f75285ef8f606073e99ee6",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ambodimanga",
        fokontany: "tanambao I 31/21",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1534346,
          lng: 49.4103889,
        },
        _id: "64f75285ef8f606073e99ee7",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ambodimanga",
        fokontany: "cite des douanes 32/23",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1531902,
          lng: 49.4141996,
        },
        _id: "64f75285ef8f606073e99ee8",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ambodimanga",
        fokontany: "cite des manguiers 32/21-22",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1558121,
          lng: 49.4173754,
        },
        _id: "64f75285ef8f606073e99ee9",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ambodimanga",
        fokontany: "ampasimazava ouest 32/43-44",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1562837,
          lng: 49.40372079999999,
        },
        _id: "64f75285ef8f606073e99eea",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ambodimanga",
        fokontany: "tanambao II 31/12",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1553561,
          lng: 49.4024508,
        },
        _id: "64f75285ef8f606073e99eeb",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ambodimanga",
        fokontany: "tanambao II 31/13",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1571723,
          lng: 49.4024508,
        },
        _id: "64f75285ef8f606073e99eec",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ambodimanga",
        fokontany: "tanambao II 31/14",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1582002,
          lng: 49.4053084,
        },
        _id: "64f75285ef8f606073e99eed",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ambodimanga",
        fokontany: "tanambao II 31/11",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1595113,
          lng: 49.406896,
        },
        _id: "64f75285ef8f606073e99eee",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ambodimanga",
        fokontany: "anjoma lava 23/31",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1610226,
          lng: 49.4116591,
        },
        _id: "64f75285ef8f606073e99eef",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ambodimanga",
        fokontany: "anjoma/mpf sud 32/32",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1600118,
          lng: 49.4148348,
        },
        _id: "64f75285ef8f606073e99ef0",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ambodimanga",
        fokontany: "anjoma/mpf est 32/31",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1595231,
          lng: 49.4224569,
        },
        _id: "64f75285ef8f606073e99ef1",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ambodimanga",
        fokontany: "ampasimazava est 32/41-42",
        __v: 0,
      },
      {
        coords: {
          lat: -18.163266,
          lng: 49.40499089999999,
        },
        _id: "64f75285ef8f606073e99ef2",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "22/13 androranga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.161998,
          lng: 49.3964186,
        },
        _id: "64f75285ef8f606073e99ef3",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "22/31 tanamborizano andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1587577,
          lng: 49.3997521,
        },
        _id: "64f75285ef8f606073e99ef4",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "22/32 tanamborizano andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1580609,
          lng: 49.4011808,
        },
        _id: "64f75285ef8f606073e99ef5",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "22/33 tanamborizano andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1590191,
          lng: 49.4019746,
        },
        _id: "64f75285ef8f606073e99ef6",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "22/21 tanamborizano atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1600971,
          lng: 49.4001093,
        },
        _id: "64f75285ef8f606073e99ef7",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "22/22 tanamborizano atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.16126,
          lng: 49.4000696,
        },
        _id: "64f75285ef8f606073e99ef8",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "22/23 tanamborizano atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1597051,
          lng: 49.4022921,
        },
        _id: "64f75285ef8f606073e99ef9",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "22/11 androranga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1641761,
          lng: 49.40022829999999,
        },
        _id: "64f75285ef8f606073e99efa",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "22/12 androranga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1608834,
          lng: 49.4075311,
        },
        _id: "64f75285ef8f606073e99efb",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "23/25 anjoma",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1613668,
          lng: 49.4094363,
        },
        _id: "64f75285ef8f606073e99efc",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "23/21 anjoma",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1624471,
          lng: 49.4083249,
        },
        _id: "64f75285ef8f606073e99efd",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "23/22 anjoma",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1628914,
          lng: 49.4076899,
        },
        _id: "64f75285ef8f606073e99efe",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "23/23 anjoma",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1635884,
          lng: 49.40626100000001,
        },
        _id: "64f75285ef8f606073e99eff",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "23/24 anjoma",
        __v: 0,
      },
      {
        coords: {
          lat: -18.163828,
          lng: 49.4119767,
        },
        _id: "64f75285ef8f606073e99f00",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "23/11 cite canada",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1652219,
          lng: 49.40911879999999,
        },
        _id: "64f75285ef8f606073e99f01",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "23/12 cite canada",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1666767,
          lng: 49.4053084,
        },
        _id: "64f75285ef8f606073e99f02",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "23/41 depot analakininina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1665764,
          lng: 49.40372079999999,
        },
        _id: "64f75285ef8f606073e99f03",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "23/45 depot analakininina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1682316,
          lng: 49.4030858,
        },
        _id: "64f75285ef8f606073e99f04",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "23/42 depot analakininina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1681312,
          lng: 49.4014983,
        },
        _id: "64f75285ef8f606073e99f05",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "23/43 depot analakininina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1819786,
          lng: 49.3868953,
        },
        _id: "64f75285ef8f606073e99f06",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "anjoma",
        fokontany: "23/44 depot analakininina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1563274,
          lng: 49.3967361,
        },
        _id: "64f75285ef8f606073e99f07",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/14 morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1474344,
          lng: 49.384356,
        },
        _id: "64f75285ef8f606073e99f08",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/53 mangarivotra sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1506003,
          lng: 49.3884824,
        },
        _id: "64f75285ef8f606073e99f09",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/52 mangarivotra sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1527995,
          lng: 49.3887998,
        },
        _id: "64f75285ef8f606073e99f0a",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/51 mangarivotra sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1519809,
          lng: 49.3921329,
        },
        _id: "64f75285ef8f606073e99f0b",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/42 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1536662,
          lng: 49.3910218,
        },
        _id: "64f75285ef8f606073e99f0c",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/43 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1528279,
          lng: 49.39308519999999,
        },
        _id: "64f75285ef8f606073e99f0d",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/41 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1532613,
          lng: 49.3941963,
        },
        _id: "64f75285ef8f606073e99f0e",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/31 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1540386,
          lng: 49.39308519999999,
        },
        _id: "64f75285ef8f606073e99f0f",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/32 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1550076,
          lng: 49.3921329,
        },
        _id: "64f75285ef8f606073e99f10",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/33 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.155552,
          lng: 49.39308519999999,
        },
        _id: "64f75285ef8f606073e99f11",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/24 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1535835,
          lng: 49.39546619999999,
        },
        _id: "64f75285ef8f606073e99f12",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/23 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1545112,
          lng: 49.3967361,
        },
        _id: "64f75285ef8f606073e99f13",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/21 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1558439,
          lng: 49.3948313,
        },
        _id: "64f75285ef8f606073e99f14",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/22 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1560138,
          lng: 49.3984822,
        },
        _id: "64f75285ef8f606073e99f15",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/12 morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1579824,
          lng: 49.3961011,
        },
        _id: "64f75285ef8f606073e99f16",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/13 morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1578605,
          lng: 49.398006,
        },
        _id: "64f75285ef8f606073e99f17",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/11 morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1601424,
          lng: 49.3938789,
        },
        _id: "64f75285ef8f606073e99f18",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/15 analakininina morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1584983,
          lng: 49.39276779999999,
        },
        _id: "64f75285ef8f606073e99f19",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/63/64 ambalakisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1576207,
          lng: 49.3922916,
        },
        _id: "64f75285ef8f606073e99f1a",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/62 ambalakisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1560876,
          lng: 49.3910218,
        },
        _id: "64f75285ef8f606073e99f1b",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/61 ambalakisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1561091,
          lng: 49.3875301,
        },
        _id: "64f75285ef8f606073e99f1c",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/72 tanambao verrerie",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1558295,
          lng: 49.3792777,
        },
        _id: "64f75285ef8f606073e99f1d",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/54 mangarivotra sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1613167,
          lng: 49.3818168,
        },
        _id: "64f75285ef8f606073e99f1e",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/74 tanambao verrerie",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1639389,
          lng: 49.3849908,
        },
        _id: "64f75285ef8f606073e99f1f",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/73 tanambao verrerie",
        __v: 0,
      },
      {
        coords: {
          lat: -18.165673,
          lng: 49.3894347,
        },
        _id: "64f75285ef8f606073e99f20",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "morarano",
        fokontany: "21/71 tanambao verrerie",
        __v: 0,
      },
      {
        coords: {
          lat: -18.149663,
          lng: 49.40626100000001,
        },
        _id: "64f75285ef8f606073e99f21",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/93 cite beryl rouge",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1418151,
          lng: 49.4027683,
        },
        _id: "64f75285ef8f606073e99f22",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "12/11 tanamakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1418924,
          lng: 49.4078486,
        },
        _id: "64f75285ef8f606073e99f23",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "12/14 tanamakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1459693,
          lng: 49.4072136,
        },
        _id: "64f75285ef8f606073e99f24",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "12/13 tanamakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1424592,
          lng: 49.4053084,
        },
        _id: "64f75285ef8f606073e99f25",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "12/12 tanamakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1444199,
          lng: 49.39991089999999,
        },
        _id: "64f75285ef8f606073e99f26",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/81 tanambao V",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1461139,
          lng: 49.40181579999999,
        },
        _id: "64f75285ef8f606073e99f27",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/82-83 tanambao V",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1470414,
          lng: 49.4030858,
        },
        _id: "64f75285ef8f606073e99f28",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/91 tanambao V",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1485744,
          lng: 49.4043559,
        },
        _id: "64f75285ef8f606073e99f29",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/92 cite beryl rouge",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1500684,
          lng: 49.4030858,
        },
        _id: "64f75285ef8f606073e99f2a",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/62 cite beryl rose",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1493325,
          lng: 49.4019746,
        },
        _id: "64f75285ef8f606073e99f2b",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/76 cite provinciale",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1483744,
          lng: 49.4011808,
        },
        _id: "64f75285ef8f606073e99f2c",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/75 tanambao V",
        __v: 0,
      },
      {
        coords: {
          lat: -18.149263,
          lng: 49.39991089999999,
        },
        _id: "64f75285ef8f606073e99f2d",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/74 tanambao V",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1483049,
          lng: 49.3991171,
        },
        _id: "64f75285ef8f606073e99f2e",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/73 tanambao V",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1471247,
          lng: 49.3986409,
        },
        _id: "64f75285ef8f606073e99f2f",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/72 tanambao V",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1468025,
          lng: 49.397371,
        },
        _id: "64f75285ef8f606073e99f30",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/71 tanambao V",
        __v: 0,
      },
      {
        coords: {
          lat: -18.148549,
          lng: 49.3953075,
        },
        _id: "64f75285ef8f606073e99f31",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/44 ambolomadinika la poudre",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1495071,
          lng: 49.3961011,
        },
        _id: "64f75285ef8f606073e99f32",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/43 ambolomadinika la poudre",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1498598,
          lng: 49.3968948,
        },
        _id: "64f75285ef8f606073e99f33",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/34 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1508484,
          lng: 49.3972123,
        },
        _id: "64f75285ef8f606073e99f34",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/36 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1500405,
          lng: 49.3987997,
        },
        _id: "64f75285ef8f606073e99f35",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/51-52 tanambao V",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1510791,
          lng: 49.39991089999999,
        },
        _id: "64f75285ef8f606073e99f36",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/53-54 tanambao V",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1520067,
          lng: 49.4011808,
        },
        _id: "64f75285ef8f606073e99f37",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/61 cite immobiliere",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1550338,
          lng: 49.4011808,
        },
        _id: "64f75285ef8f606073e99f38",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/11 cite adventiste",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1543783,
          lng: 49.4003871,
        },
        _id: "64f75285ef8f606073e99f39",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/12 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1535007,
          lng: 49.39991089999999,
        },
        _id: "64f75285ef8f606073e99f3a",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/21 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1530674,
          lng: 49.3987997,
        },
        _id: "64f75285ef8f606073e99f3b",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/22 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.152523,
          lng: 49.3978472,
        },
        _id: "64f75285ef8f606073e99f3c",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/23-24 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1513928,
          lng: 49.3981647,
        },
        _id: "64f75285ef8f606073e99f3d",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/35 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1525034,
          lng: 49.3965773,
        },
        _id: "64f75285ef8f606073e99f3e",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/31 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1520396,
          lng: 49.3959424,
        },
        _id: "64f75285ef8f606073e99f3f",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/32 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1512731,
          lng: 49.3953075,
        },
        _id: "64f75285ef8f606073e99f40",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/33 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.151112,
          lng: 49.39467250000001,
        },
        _id: "64f75285ef8f606073e99f41",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/45-46 ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1506787,
          lng: 49.3935614,
        },
        _id: "64f75285ef8f606073e99f42",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "tanambao V",
        fokontany: "13/41-42 ambolomadinika la pou",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1399826,
          lng: 49.3967361,
        },
        _id: "64f75285ef8f606073e99f43",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/58 ankirihiry avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1086071,
          lng: 49.3983234,
        },
        _id: "64f75285ef8f606073e99f44",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "14/11 ampanalana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.118812,
          lng: 49.4027683,
        },
        _id: "64f75285ef8f606073e99f45",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "14/12 ampanalana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1235318,
          lng: 49.4046734,
        },
        _id: "64f75285ef8f606073e99f46",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "14/21 salazamay",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1270872,
          lng: 49.3995934,
        },
        _id: "64f75285ef8f606073e99f47",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "14/31 ambohijafy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1185366,
          lng: 49.3945138,
        },
        _id: "64f75285ef8f606073e99f48",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/43 andranomadio",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1505861,
          lng: 49.3729306,
        },
        _id: "64f75285ef8f606073e99f49",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/47 mangarano II",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1312859,
          lng: 49.3970535,
        },
        _id: "64f75285ef8f606073e99f4a",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/41 andranomadio",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1318073,
          lng: 49.4014983,
        },
        _id: "64f75285ef8f606073e99f4b",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "14/32 ambohijafy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1305508,
          lng: 49.4084837,
        },
        _id: "64f75285ef8f606073e99f4c",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "14/22 salazamay",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1382144,
          lng: 49.4148348,
        },
        _id: "64f75285ef8f606073e99f4d",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "12/21-22 hopitalibe toby miara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1372941,
          lng: 49.4040384,
        },
        _id: "64f75285ef8f606073e99f4e",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "14/33 morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1342287,
          lng: 49.4014983,
        },
        _id: "64f75285ef8f606073e99f4f",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/62 cite valpinson",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1363281,
          lng: 49.40022829999999,
        },
        _id: "64f75285ef8f606073e99f50",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/61 cite valpinson",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1365728,
          lng: 49.3964186,
        },
        _id: "64f75285ef8f606073e99f51",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/42 andranomadio",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1361509,
          lng: 49.3935614,
        },
        _id: "64f75285ef8f606073e99f52",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/44 andranomadio",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1388942,
          lng: 49.3948313,
        },
        _id: "64f75285ef8f606073e99f53",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/33 cite haras ankirihiry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1389717,
          lng: 49.39991089999999,
        },
        _id: "64f75285ef8f606073e99f54",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/51 ankirihiry avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1396157,
          lng: 49.4024508,
        },
        _id: "64f75285ef8f606073e99f55",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/52 ankirihiry avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1407877,
          lng: 49.39991089999999,
        },
        _id: "64f75285ef8f606073e99f56",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/55 ankirihiry avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1409406,
          lng: 49.3975297,
        },
        _id: "64f75285ef8f606073e99f57",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/56 ankirihiry avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.142043,
          lng: 49.39292649999999,
        },
        _id: "64f75285ef8f606073e99f58",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/32 cite haras ankirihiry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1405102,
          lng: 49.3916567,
        },
        _id: "64f75285ef8f606073e99f59",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/45 bis andranomadio",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1383944,
          lng: 49.3868953,
        },
        _id: "64f75285ef8f606073e99f5a",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/46 mangarano I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1431146,
          lng: 49.3887998,
        },
        _id: "64f75285ef8f606073e99f5b",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/45 andranomadio",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1423651,
          lng: 49.3941963,
        },
        _id: "64f75285ef8f606073e99f5c",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/24 ankirihiry atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1424539,
          lng: 49.3975297,
        },
        _id: "64f75285ef8f606073e99f5d",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/53 ankirihiry avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.141868,
          lng: 49.3987997,
        },
        _id: "64f75285ef8f606073e99f5e",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/57 ankirihiry avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1426344,
          lng: 49.3994346,
        },
        _id: "64f75285ef8f606073e99f5f",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/54 ankirihiry avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1439368,
          lng: 49.398006,
        },
        _id: "64f75285ef8f606073e99f60",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/59 ankirihiry avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1454307,
          lng: 49.3967361,
        },
        _id: "64f75285ef8f606073e99f61",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/21 ankirihiry atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1440089,
          lng: 49.3953075,
        },
        _id: "64f75285ef8f606073e99f62",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/22 ankirihiry atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1435452,
          lng: 49.39467250000001,
        },
        _id: "64f75285ef8f606073e99f63",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/23 ankirihiry atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1450305,
          lng: 49.390387,
        },
        _id: "64f75285ef8f606073e99f64",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/31 cite haras ankirihiry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1453527,
          lng: 49.3916567,
        },
        _id: "64f75285ef8f606073e99f65",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/11 mangarivotra avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1471687,
          lng: 49.3916567,
        },
        _id: "64f75285ef8f606073e99f66",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/12 mangarivotra avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1470772,
          lng: 49.39308519999999,
        },
        _id: "64f75285ef8f606073e99f67",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/13 mangarivotra avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1482573,
          lng: 49.3935614,
        },
        _id: "64f75285ef8f606073e99f68",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina I",
        commune: "ankirihiry",
        fokontany: "11/14 mangarivotra avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8200534,
          lng: 49.0652963,
        },
        _id: "64f75285ef8f606073e99f69",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "brickaville",
        fokontany: "brickaville",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7644255,
          lng: 49.0059852,
        },
        _id: "64f75285ef8f606073e99f6a",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "brickaville",
        fokontany: "ampasimaneva",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7808663,
          lng: 49.0589836,
        },
        _id: "64f75285ef8f606073e99f6b",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "brickaville",
        fokontany: "lohomby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7965378,
          lng: 49.0085078,
        },
        _id: "64f75285ef8f606073e99f6c",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "brickaville",
        fokontany: "ambodivandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7911279,
          lng: 49.10697709999999,
        },
        _id: "64f75285ef8f606073e99f6d",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "brickaville",
        fokontany: "ambodifaho",
        __v: 0,
      },
      {
        coords: {
          lat: -18.793427,
          lng: 49.0652963,
        },
        _id: "64f75285ef8f606073e99f6e",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "brickaville",
        fokontany: "ambodivoangy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.766264,
          lng: 49.0312164,
        },
        _id: "64f75285ef8f606073e99f6f",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "brickaville",
        fokontany: "sahamorona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8014731,
          lng: 49.036264,
        },
        _id: "64f75285ef8f606073e99f70",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "brickaville",
        fokontany: "maromamy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8064056,
          lng: 49.0640337,
        },
        _id: "64f75285ef8f606073e99f71",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "brickaville",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8127913,
          lng: 49.0652963,
        },
        _id: "64f75285ef8f606073e99f72",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "brickaville",
        fokontany: "ambodiampaly",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8175147,
          lng: 49.09686989999999,
        },
        _id: "64f75285ef8f606073e99f73",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "brickaville",
        fokontany: "menagisy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8342122,
          lng: 49.0867644,
        },
        _id: "64f75285ef8f606073e99f74",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "brickaville",
        fokontany: "cinzano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8290675,
          lng: 49.0627711,
        },
        _id: "64f75285ef8f606073e99f75",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "brickaville",
        fokontany: "ambodiara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8375139,
          lng: 49.0261692,
        },
        _id: "64f75285ef8f606073e99f76",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "brickaville",
        fokontany: "nierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8428714,
          lng: 49.0463605,
        },
        _id: "64f75285ef8f606073e99f77",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "brickaville",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8509065,
          lng: 49.0766607,
        },
        _id: "64f75285ef8f606073e99f78",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "brickaville",
        fokontany: "avilona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8622361,
          lng: 49.0463605,
        },
        _id: "64f75285ef8f606073e99f79",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "brickaville",
        fokontany: "tanandava I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8675977,
          lng: 49.0665589,
        },
        _id: "64f75285ef8f606073e99f7a",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "brickaville",
        fokontany: "sahatakoly",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6978013,
          lng: 49.1019233,
        },
        _id: "64f75285ef8f606073e99f7b",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "vohitranivona",
        fokontany: "vohitranivona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6872646,
          lng: 49.1170862,
        },
        _id: "64f75285ef8f606073e99f7c",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "vohitranivona",
        fokontany: "sahamandrevo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6484636,
          lng: 49.0615086,
        },
        _id: "64f75285ef8f606073e99f7d",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "vohitranivona",
        fokontany: "sarotriva",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7206752,
          lng: 49.09686989999999,
        },
        _id: "64f75285ef8f606073e99f7e",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "vohitranivona",
        fokontany: "ampasimbe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7328899,
          lng: 49.0514094,
        },
        _id: "64f75285ef8f606073e99f7f",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "vohitranivona",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7542053,
          lng: 49.1322531,
        },
        _id: "64f75285ef8f606073e99f80",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "vohitranivona",
        fokontany: "manambato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7751898,
          lng: 49.0741351,
        },
        _id: "64f75285ef8f606073e99f81",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "vohitranivona",
        fokontany: "namahoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.737801,
          lng: 48.977379,
        },
        _id: "64f75285ef8f606073e99f82",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "anivorano est",
        fokontany: "anivorano est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6887273,
          lng: 48.9757225,
        },
        _id: "64f75285ef8f606073e99f83",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "anivorano est",
        fokontany: "ambodimolaina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7028836,
          lng: 49.0110305,
        },
        _id: "64f75285ef8f606073e99f84",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "anivorano est",
        fokontany: "tanambao sahaniveno",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7255645,
          lng: 48.9505159,
        },
        _id: "64f75285ef8f606073e99f85",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "anivorano est",
        fokontany: "andapa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7720491,
          lng: 48.92532060000001,
        },
        _id: "64f75285ef8f606073e99f86",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "anivorano est",
        fokontany: "sandraka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8037287,
          lng: 48.9353974,
        },
        _id: "64f75285ef8f606073e99f87",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "anivorano est",
        fokontany: "ambalatenina sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8082683,
          lng: 48.9706803,
        },
        _id: "64f75285ef8f606073e99f88",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "anivorano est",
        fokontany: "antseranambe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9297526,
          lng: 48.99085179999999,
        },
        _id: "64f75285ef8f606073e99f89",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "mahatsara",
        fokontany: "isokatra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8501981,
          lng: 48.9102087,
        },
        _id: "64f75285ef8f606073e99f8a",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "mahatsara",
        fokontany: "sahivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8824184,
          lng: 49.0312164,
        },
        _id: "64f75285ef8f606073e99f8b",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "mahatsara",
        fokontany: "ranomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -18.45022,
          lng: 48.97678,
        },
        _id: "64f75285ef8f606073e99f8c",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "mahatsara",
        fokontany: "ambodiriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8856619,
          lng: 48.9706803,
        },
        _id: "64f75285ef8f606073e99f8d",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "mahatsara",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9122757,
          lng: 49.01607629999999,
        },
        _id: "64f75285ef8f606073e99f8e",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "mahatsara",
        fokontany: "valavahatra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9352727,
          lng: 49.0387879,
        },
        _id: "64f75285ef8f606073e99f8f",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "mahatsara",
        fokontany: "maromandia",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9360791,
          lng: 49.0236458,
        },
        _id: "64f75285ef8f606073e99f90",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "mahatsara",
        fokontany: "vohiboazo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9581403,
          lng: 49.0337401,
        },
        _id: "64f75285ef8f606073e99f91",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "mahatsara",
        fokontany: "maroamboka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9684755,
          lng: 48.99085179999999,
        },
        _id: "64f75285ef8f606073e99f92",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "mahatsara",
        fokontany: "maromby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.974079,
          lng: 48.92538099999999,
        },
        _id: "64f75285ef8f606073e99f93",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "mahatsara",
        fokontany: "manambonitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9836265,
          lng: 48.9479959,
        },
        _id: "64f75285ef8f606073e99f94",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "mahatsara",
        fokontany: "antsapanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0126069,
          lng: 49.0110305,
        },
        _id: "64f75285ef8f606073e99f95",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "mahatsara",
        fokontany: "menarano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0068623,
          lng: 48.9353974,
        },
        _id: "64f75285ef8f606073e99f96",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "mahatsara",
        fokontany: "ampitabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0792624,
          lng: 48.9706803,
        },
        _id: "64f75285ef8f606073e99f97",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "mahatsara",
        fokontany: "anivoranokely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1187707,
          lng: 48.9555563,
        },
        _id: "64f75285ef8f606073e99f98",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "mahatsara",
        fokontany: "ambinanitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9549753,
          lng: 49.1082407,
        },
        _id: "64f75285ef8f606073e99f99",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "andovoranto",
        fokontany: "andovoranto",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7780647,
          lng: 49.1676582,
        },
        _id: "64f75285ef8f606073e99f9a",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "andovoranto",
        fokontany: "vavony",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8584101,
          lng: 49.1461596,
        },
        _id: "64f75285ef8f606073e99f9b",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "andovoranto",
        fokontany: "ambila lemaitso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8933711,
          lng: 49.127197,
        },
        _id: "64f75285ef8f606073e99f9c",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "andovoranto",
        fokontany: "andavakimena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8791429,
          lng: 49.0918169,
        },
        _id: "64f75285ef8f606073e99f9d",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "andovoranto",
        fokontany: "ambodivoara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8939707,
          lng: 49.05645879999999,
        },
        _id: "64f75285ef8f606073e99f9e",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "andovoranto",
        fokontany: "ianakonitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.91975,
          lng: 49.055309,
        },
        _id: "64f75285ef8f606073e99f9f",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "andovoranto",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.931091,
          lng: 49.0867644,
        },
        _id: "64f75285ef8f606073e99fa0",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "andovoranto",
        fokontany: "manerinerina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9520895,
          lng: 49.05645879999999,
        },
        _id: "64f75285ef8f606073e99fa1",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "andovoranto",
        fokontany: "manakambahiny",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9592216,
          lng: 49.0741351,
        },
        _id: "64f75285ef8f606073e99fa2",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "andovoranto",
        fokontany: "manarantsandry",
        __v: 0,
      },
      {
        coords: {
          lat: -19.04279,
          lng: 49.0514094,
        },
        _id: "64f75285ef8f606073e99fa3",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "andovoranto",
        fokontany: "sondrara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1482439,
          lng: 49.0110305,
        },
        _id: "64f75285ef8f606073e99fa4",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "andovoranto",
        fokontany: "vohitrampasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.598339,
          lng: 49.114849,
        },
        _id: "64f75285ef8f606073e99fa5",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambinaninony",
        fokontany: "ambinaninony",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4435494,
          lng: 49.147424,
        },
        _id: "64f75285ef8f606073e99fa6",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambinaninony",
        fokontany: "ambodivandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.479562,
          lng: 49.1929609,
        },
        _id: "64f75285ef8f606073e99fa7",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambinaninony",
        fokontany: "ambodizarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4637939,
          lng: 49.1322531,
        },
        _id: "64f75285ef8f606073e99fa8",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambinaninony",
        fokontany: "ampasimadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.50709,
          lng: 49.22917899999999,
        },
        _id: "64f75285ef8f606073e99fa9",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambinaninony",
        fokontany: "ambalahasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5016032,
          lng: 49.147424,
        },
        _id: "64f75285ef8f606073e99faa",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambinaninony",
        fokontany: "ambodisovoka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5005775,
          lng: 49.2740045,
        },
        _id: "64f75285ef8f606073e99fab",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambinaninony",
        fokontany: "tampina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.516667,
          lng: 49.133333,
        },
        _id: "64f75285ef8f606073e99fac",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambinaninony",
        fokontany: "vohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5227278,
          lng: 49.1170862,
        },
        _id: "64f75285ef8f606073e99fad",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambinaninony",
        fokontany: "marofody",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5614307,
          lng: 49.1170862,
        },
        _id: "64f75285ef8f606073e99fae",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambinaninony",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5763919,
          lng: 49.1929609,
        },
        _id: "64f75285ef8f606073e99faf",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambinaninony",
        fokontany: "ampantany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5992694,
          lng: 49.1322531,
        },
        _id: "64f75285ef8f606073e99fb0",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambinaninony",
        fokontany: "sahavalaina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6001632,
          lng: 49.2284034,
        },
        _id: "64f75285ef8f606073e99fb1",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambinaninony",
        fokontany: "andranokoditra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6194986,
          lng: 49.1170862,
        },
        _id: "64f75285ef8f606073e99fb2",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambinaninony",
        fokontany: "ambodirafia",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6292183,
          lng: 49.1727178,
        },
        _id: "64f75285ef8f606073e99fb3",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambinaninony",
        fokontany: "ambodivontaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6873396,
          lng: 49.1727178,
        },
        _id: "64f75285ef8f606073e99fb4",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambinaninony",
        fokontany: "ampanotoamaizina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6446031,
          lng: 48.9001364,
        },
        _id: "64f75285ef8f606073e99fb5",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "fetraomby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5630847,
          lng: 48.9757225,
        },
        _id: "64f75285ef8f606073e99fb6",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "sahamamy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5954744,
          lng: 48.9152456,
        },
        _id: "64f75285ef8f606073e99fb7",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "naharovana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6125534,
          lng: 48.8976186,
        },
        _id: "64f75285ef8f606073e99fb8",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "ambalatenina avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.609171,
          lng: 48.9580767,
        },
        _id: "64f75285ef8f606073e99fb9",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "ambalakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6128264,
          lng: 48.8347102,
        },
        _id: "64f75285ef8f606073e99fba",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "ambodimantaly",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6217189,
          lng: 48.87748029999999,
        },
        _id: "64f75285ef8f606073e99fbb",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "sahanosy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5867692,
          lng: 48.9555563,
        },
        _id: "64f75285ef8f606073e99fbc",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "ambodiambilazona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6007834,
          lng: 48.9353974,
        },
        _id: "64f75285ef8f606073e99fbd",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6061854,
          lng: 49.0110305,
        },
        _id: "64f75285ef8f606073e99fbe",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "ambalafary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6283676,
          lng: 48.90265429999999,
        },
        _id: "64f75285ef8f606073e99fbf",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "saharefina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6465257,
          lng: 48.9668989,
        },
        _id: "64f75285ef8f606073e99fc0",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "sandrananjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6458561,
          lng: 48.87748029999999,
        },
        _id: "64f75285ef8f606073e99fc1",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "mahalava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6442555,
          lng: 48.7894596,
        },
        _id: "64f75285ef8f606073e99fc2",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "ambinanifanasana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6480342,
          lng: 48.95429619999999,
        },
        _id: "64f75285ef8f606073e99fc3",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "pakambo avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6409464,
          lng: 48.84980179999999,
        },
        _id: "64f75285ef8f606073e99fc4",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "sahalampona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6733985,
          lng: 48.8447708,
        },
        _id: "64f75285ef8f606073e99fc5",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "ambodikily",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6675721,
          lng: 48.9505159,
        },
        _id: "64f75285ef8f606073e99fc6",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "ambodizarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6850713,
          lng: 48.92532060000001,
        },
        _id: "64f75285ef8f606073e99fc7",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "fandriambarika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6953757,
          lng: 48.8548333,
        },
        _id: "64f75285ef8f606073e99fc8",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "ambalatenina atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6902301,
          lng: 48.8900659,
        },
        _id: "64f75285ef8f606073e99fc9",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fetraomby",
        fokontany: "ambohimazava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.771312,
          lng: 48.8793679,
        },
        _id: "64f75285ef8f606073e99fca",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "vohipeno razanaka",
        fokontany: "razanaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7374915,
          lng: 48.84980179999999,
        },
        _id: "64f75285ef8f606073e99fcb",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "vohipeno razanaka",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7481906,
          lng: 48.8900659,
        },
        _id: "64f75285ef8f606073e99fcc",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "vohipeno razanaka",
        fokontany: "marimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7796107,
          lng: 48.8447708,
        },
        _id: "64f75285ef8f606073e99fcd",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "vohipeno razanaka",
        fokontany: "ambodikily",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7956907,
          lng: 48.9051723,
        },
        _id: "64f75285ef8f606073e99fce",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "vohipeno razanaka",
        fokontany: "marofody V",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8008033,
          lng: 48.86993030000001,
        },
        _id: "64f75285ef8f606073e99fcf",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "vohipeno razanaka",
        fokontany: "ambodiaviavy sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9585519,
          lng: 48.8409979,
        },
        _id: "64f75285ef8f606073e99fd0",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ranomafana est",
        fokontany: "ranomafana est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8842883,
          lng: 48.8749635,
        },
        _id: "64f75285ef8f606073e99fd1",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ranomafana est",
        fokontany: "serananomby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9059885,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e99fd2",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ranomafana est",
        fokontany: "ampasimpotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9091177,
          lng: 48.76936,
        },
        _id: "64f75285ef8f606073e99fd3",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ranomafana est",
        fokontany: "ankorabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9427168,
          lng: 48.8045391,
        },
        _id: "64f75285ef8f606073e99fd4",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ranomafana est",
        fokontany: "marovola",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9863774,
          lng: 48.76936,
        },
        _id: "64f75285ef8f606073e99fd5",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ranomafana est",
        fokontany: "antongobato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0099789,
          lng: 48.8749635,
        },
        _id: "64f75285ef8f606073e99fd6",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ranomafana est",
        fokontany: "ambodimanga III",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0208055,
          lng: 48.9152456,
        },
        _id: "64f75285ef8f606073e99fd7",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ranomafana est",
        fokontany: "manjaoka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.027383,
          lng: 48.84980179999999,
        },
        _id: "64f75285ef8f606073e99fd8",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ranomafana est",
        fokontany: "marosava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.971359,
          lng: 48.6813999,
        },
        _id: "64f75285ef8f606073e99fd9",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ampasimbe",
        fokontany: "ampasimbe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8959954,
          lng: 48.6489134,
        },
        _id: "64f75285ef8f606073e99fda",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ampasimbe",
        fokontany: "ambalaforona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.916667,
          lng: 48.716667,
        },
        _id: "64f75285ef8f606073e99fdb",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ampasimbe",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0280403,
          lng: 48.7091042,
        },
        _id: "64f75285ef8f606073e99fdc",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ampasimbe",
        fokontany: "ahevy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0364718,
          lng: 48.6689697,
        },
        _id: "64f75285ef8f606073e99fdd",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ampasimbe",
        fokontany: "ampitsahana II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0389069,
          lng: 48.74926749999999,
        },
        _id: "64f75285ef8f606073e99fde",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ampasimbe",
        fokontany: "ifasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0914225,
          lng: 48.7291822,
        },
        _id: "64f75285ef8f606073e99fdf",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ampasimbe",
        fokontany: "ambodimanga benavony",
        __v: 0,
      },
      {
        coords: {
          lat: -19.112725,
          lng: 48.7542899,
        },
        _id: "64f75285ef8f606073e99fe0",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ampasimbe",
        fokontany: "vohitraomby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7986494,
          lng: 48.7894596,
        },
        _id: "64f75285ef8f606073e99fe1",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fanasana",
        fokontany: "fanasana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7267901,
          lng: 48.8095665,
        },
        _id: "64f75285ef8f606073e99fe2",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fanasana",
        fokontany: "lanonana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7688879,
          lng: 48.8045391,
        },
        _id: "64f75285ef8f606073e99fe3",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fanasana",
        fokontany: "hanahana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7844254,
          lng: 48.7542899,
        },
        _id: "64f75285ef8f606073e99fe4",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fanasana",
        fokontany: "sandrakazomena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8093895,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e99fe5",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fanasana",
        fokontany: "mangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8372651,
          lng: 48.7894596,
        },
        _id: "64f75285ef8f606073e99fe6",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "fanasana",
        fokontany: "ambodihazoambo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4831805,
          lng: 49.0211225,
        },
        _id: "64f75285ef8f606073e99fe7",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambalarondra",
        fokontany: "ambalarondra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4058375,
          lng: 49.0766607,
        },
        _id: "64f75285ef8f606073e99fe8",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambalarondra",
        fokontany: "tanambe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4041082,
          lng: 49.0514094,
        },
        _id: "64f75285ef8f606073e99fe9",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambalarondra",
        fokontany: "lanivolo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4392437,
          lng: 49.05645879999999,
        },
        _id: "64f75285ef8f606073e99fea",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambalarondra",
        fokontany: "manampotatra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4410294,
          lng: 48.9706803,
        },
        _id: "64f75285ef8f606073e99feb",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambalarondra",
        fokontany: "sahalambo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4568261,
          lng: 49.0312164,
        },
        _id: "64f75285ef8f606073e99fec",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambalarondra",
        fokontany: "ambatovelona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4532788,
          lng: 49.0918169,
        },
        _id: "64f75285ef8f606073e99fed",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambalarondra",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4805393,
          lng: 49.0665589,
        },
        _id: "64f75285ef8f606073e99fee",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambalarondra",
        fokontany: "farihy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4893303,
          lng: 49.0261692,
        },
        _id: "64f75285ef8f606073e99fef",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambalarondra",
        fokontany: "ambalafatakana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5051469,
          lng: 49.0867644,
        },
        _id: "64f75285ef8f606073e99ff0",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambalarondra",
        fokontany: "seranantsara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5350419,
          lng: 49.0993965,
        },
        _id: "64f75285ef8f606073e99ff1",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambalarondra",
        fokontany: "andrafianjavatra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.53084,
          lng: 49.0363619,
        },
        _id: "64f75285ef8f606073e99ff2",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambalarondra",
        fokontany: "ambilamaina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5481877,
          lng: 49.0110305,
        },
        _id: "64f75285ef8f606073e99ff3",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambalarondra",
        fokontany: "andovokabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5781045,
          lng: 49.0514094,
        },
        _id: "64f75285ef8f606073e99ff4",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambalarondra",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0060067,
          lng: 48.7830992,
        },
        _id: "64f75285ef8f606073e99ff5",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "maroseranana",
        fokontany: "maroseranana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3357451,
          lng: 48.6790007,
        },
        _id: "64f75285ef8f606073e99ff6",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "maroseranana",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4514227,
          lng: 48.7894596,
        },
        _id: "64f75285ef8f606073e99ff7",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "maroseranana",
        fokontany: "ambodilendemy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.453269,
          lng: 48.86993030000001,
        },
        _id: "64f75285ef8f606073e99ff8",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "maroseranana",
        fokontany: "bezono",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5410431,
          lng: 48.9102087,
        },
        _id: "64f75285ef8f606073e99ff9",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "maroseranana",
        fokontany: "ambodivoangibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5742322,
          lng: 48.8347102,
        },
        _id: "64f75285ef8f606073e99ffa",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "maroseranana",
        fokontany: "andranomiditra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5891939,
          lng: 48.8548333,
        },
        _id: "64f75285ef8f606073e99ffb",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "maroseranana",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6084957,
          lng: 48.8548333,
        },
        _id: "64f75285ef8f606073e99ffc",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "maroseranana",
        fokontany: "tanambao sahaviavy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5805102,
          lng: 48.8951009,
        },
        _id: "64f75285ef8f606073e99ffd",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "maroseranana",
        fokontany: "sahavanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.782829,
          lng: 48.67424,
        },
        _id: "64f75285ef8f606073e99ffe",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "lohariandava",
        fokontany: "lohariandava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6946723,
          lng: 48.6890334,
        },
        _id: "64f75285ef8f606073e99fff",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "lohariandava",
        fokontany: "ampasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7160864,
          lng: 48.76936,
        },
        _id: "64f75285ef8f606073e9a000",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "lohariandava",
        fokontany: "andonabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7581627,
          lng: 48.7643362,
        },
        _id: "64f75285ef8f606073e9a001",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "lohariandava",
        fokontany: "ambodiatafana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7367057,
          lng: 48.68401679999999,
        },
        _id: "64f75285ef8f606073e9a002",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "lohariandava",
        fokontany: "fierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7363985,
          lng: 48.6288642,
        },
        _id: "64f75285ef8f606073e9a003",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "lohariandava",
        fokontany: "leokasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7860145,
          lng: 48.7241621,
        },
        _id: "64f75285ef8f606073e9a004",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "lohariandava",
        fokontany: "ampihananana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8304249,
          lng: 48.67398499999999,
        },
        _id: "64f75285ef8f606073e9a005",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "lohariandava",
        fokontany: "antsirakaomby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.855104,
          lng: 48.69405039999999,
        },
        _id: "64f75285ef8f606073e9a006",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "lohariandava",
        fokontany: "tanambao sanjaviavy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8334623,
          lng: 48.739224,
        },
        _id: "64f75285ef8f606073e9a007",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "lohariandava",
        fokontany: "marofisokina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7133435,
          lng: 48.5787728,
        },
        _id: "64f75285ef8f606073e9a008",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "andekaleka",
        fokontany: "andekaleka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7149126,
          lng: 48.5487397,
        },
        _id: "64f75285ef8f606073e9a009",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "andekaleka",
        fokontany: "ambalatenina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6571767,
          lng: 48.5487397,
        },
        _id: "64f75285ef8f606073e9a00a",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "andekaleka",
        fokontany: "maromitety",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7703356,
          lng: 48.5937955,
        },
        _id: "64f75285ef8f606073e9a00b",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "andekaleka",
        fokontany: "ampirarazana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7965366,
          lng: 48.5837799,
        },
        _id: "64f75285ef8f606073e9a00c",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "andekaleka",
        fokontany: "ambinanindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4684974,
          lng: 48.598804,
        },
        _id: "64f75285ef8f606073e9a00d",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambohimanana",
        fokontany: "ambohimanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4055054,
          lng: 48.5787728,
        },
        _id: "64f75285ef8f606073e9a00e",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambohimanana",
        fokontany: "manankasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4862351,
          lng: 48.68401679999999,
        },
        _id: "64f75285ef8f606073e9a00f",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambohimanana",
        fokontany: "ambodifanto",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5245704,
          lng: 48.6288642,
        },
        _id: "64f75285ef8f606073e9a010",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambohimanana",
        fokontany: "ambodialampona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.583333,
          lng: 48.683333,
        },
        _id: "64f75285ef8f606073e9a011",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambohimanana",
        fokontany: "asindro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6194343,
          lng: 48.714123,
        },
        _id: "64f75285ef8f606073e9a012",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambohimanana",
        fokontany: "ambodinonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6335884,
          lng: 48.74926749999999,
        },
        _id: "64f75285ef8f606073e9a013",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "ambohimanana",
        fokontany: "bezamba",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3217426,
          lng: 48.9202828,
        },
        _id: "64f75285ef8f606073e9a014",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "anjahamana",
        fokontany: "anjahamana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.220102,
          lng: 48.9001364,
        },
        _id: "64f75285ef8f606073e9a015",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "anjahamana",
        fokontany: "andranoambolava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3235205,
          lng: 48.8900659,
        },
        _id: "64f75285ef8f606073e9a016",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "anjahamana",
        fokontany: "tsarizana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3568141,
          lng: 48.92532060000001,
        },
        _id: "64f75285ef8f606073e9a017",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "anjahamana",
        fokontany: "beovirandrana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.35,
          lng: 48.883333,
        },
        _id: "64f75285ef8f606073e9a018",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "anjahamana",
        fokontany: "anivoranokely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3778513,
          lng: 48.9505159,
        },
        _id: "64f75285ef8f606073e9a019",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "anjahamana",
        fokontany: "seranantsara I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.409439,
          lng: 48.9051723,
        },
        _id: "64f75285ef8f606073e9a01a",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "anjahamana",
        fokontany: "ambatohambana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4445255,
          lng: 48.9102087,
        },
        _id: "64f75285ef8f606073e9a01b",
        province: "toamasina",
        region: "atsinanana",
        district: "brickaville",
        commune: "anjahamana",
        fokontany: "afasimpotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3372162,
          lng: 48.9795044,
        },
        _id: "64f75285ef8f606073e9a01c",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "vatomandry",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3017307,
          lng: 48.9782438,
        },
        _id: "64f75285ef8f606073e9a01d",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "vatomandry",
        fokontany: "ambilakely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.319565,
          lng: 48.9769831,
        },
        _id: "64f75285ef8f606073e9a01e",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "vatomandry",
        fokontany: "lanijadona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3225813,
          lng: 48.9813955,
        },
        _id: "64f75285ef8f606073e9a01f",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "vatomandry",
        fokontany: "antanambahiny",
        __v: 0,
      },
      {
        coords: {
          lat: -19.325578,
          lng: 48.9779286,
        },
        _id: "64f75285ef8f606073e9a020",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "vatomandry",
        fokontany: "antanantsaripaty",
        __v: 0,
      },
      {
        coords: {
          lat: -19.328825,
          lng: 48.9776134,
        },
        _id: "64f75285ef8f606073e9a021",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "vatomandry",
        fokontany: "bemasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3308141,
          lng: 48.9782438,
        },
        _id: "64f75285ef8f606073e9a022",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "vatomandry",
        fokontany: "centre ville",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3264672,
          lng: 48.9845474,
        },
        _id: "64f75285ef8f606073e9a023",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "vatomandry",
        fokontany: "marofototra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.332118,
          lng: 48.9763528,
        },
        _id: "64f75285ef8f606073e9a024",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "vatomandry",
        fokontany: "ampasimandrevo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.337492,
          lng: 48.9738316,
        },
        _id: "64f75285ef8f606073e9a025",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "vatomandry",
        fokontany: "ampandranety",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3455406,
          lng: 48.97446189999999,
        },
        _id: "64f75285ef8f606073e9a026",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "vatomandry",
        fokontany: "vohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3838582,
          lng: 48.9505159,
        },
        _id: "64f75285ef8f606073e9a027",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "vatomandry",
        fokontany: "ampanalana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3410515,
          lng: 48.9001364,
        },
        _id: "64f75285ef8f606073e9a028",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambodivoananto",
        fokontany: "ambodivoananto",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3348354,
          lng: 48.8951009,
        },
        _id: "64f75285ef8f606073e9a029",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambodivoananto",
        fokontany: "antsahalalina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.333536,
          lng: 48.8548333,
        },
        _id: "64f75285ef8f606073e9a02a",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambodivoananto",
        fokontany: "andasibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3625844,
          lng: 48.8548333,
        },
        _id: "64f75285ef8f606073e9a02b",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambodivoananto",
        fokontany: "andranoampotatra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3725688,
          lng: 48.8825142,
        },
        _id: "64f75285ef8f606073e9a02c",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambodivoananto",
        fokontany: "androrangavola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3819519,
          lng: 48.8548333,
        },
        _id: "64f75285ef8f606073e9a02d",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambodivoananto",
        fokontany: "ambodirotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4283814,
          lng: 48.9316183,
        },
        _id: "64f75285ef8f606073e9a02e",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "maintinandry",
        fokontany: "maintinandry",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4012165,
          lng: 48.92532060000001,
        },
        _id: "64f75285ef8f606073e9a02f",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "maintinandry",
        fokontany: "andranofolo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4663114,
          lng: 48.9152456,
        },
        _id: "64f75285ef8f606073e9a030",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "maintinandry",
        fokontany: "ambodisakoana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.422803,
          lng: 48.8095665,
        },
        _id: "64f75285ef8f606073e9a031",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tanambao vahatrakaka",
        fokontany: "tanambao vahatrakaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3996274,
          lng: 48.8221369,
        },
        _id: "64f75285ef8f606073e9a032",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tanambao vahatrakaka",
        fokontany: "sahafisaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.423503,
          lng: 48.7944857,
        },
        _id: "64f75285ef8f606073e9a033",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tanambao vahatrakaka",
        fokontany: "vohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4276186,
          lng: 48.8447708,
        },
        _id: "64f75285ef8f606073e9a034",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tanambao vahatrakaka",
        fokontany: "ampasimaventy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4459733,
          lng: 48.7969989,
        },
        _id: "64f75285ef8f606073e9a035",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tanambao vahatrakaka",
        fokontany: "antaramanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4393585,
          lng: 48.86993030000001,
        },
        _id: "64f75285ef8f606073e9a036",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tsarasambo",
        fokontany: "tsarasambo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4026501,
          lng: 48.8951009,
        },
        _id: "64f75285ef8f606073e9a037",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tsarasambo",
        fokontany: "ambodivontaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4127381,
          lng: 48.8523175,
        },
        _id: "64f75285ef8f606073e9a038",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tsarasambo",
        fokontany: "vanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4068304,
          lng: 48.8749635,
        },
        _id: "64f75285ef8f606073e9a039",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tsarasambo",
        fokontany: "mangiboka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4086033,
          lng: 48.8372252,
        },
        _id: "64f75285ef8f606073e9a03a",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tsarasambo",
        fokontany: "sahatalevana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4383315,
          lng: 48.8573491,
        },
        _id: "64f75285ef8f606073e9a03b",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tsarasambo",
        fokontany: "marofaria",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4649602,
          lng: 48.8749635,
        },
        _id: "64f75285ef8f606073e9a03c",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tsarasambo",
        fokontany: "ampaho",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2694072,
          lng: 48.9127271,
        },
        _id: "64f75285ef8f606073e9a03d",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "sahamatevina",
        fokontany: "sahamatevina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2182526,
          lng: 48.90265429999999,
        },
        _id: "64f75285ef8f606073e9a03e",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "sahamatevina",
        fokontany: "ankaraina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.231164,
          lng: 48.91902349999999,
        },
        _id: "64f75285ef8f606073e9a03f",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "sahamatevina",
        fokontany: "anosimanasa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2327771,
          lng: 48.90265429999999,
        },
        _id: "64f75285ef8f606073e9a040",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "sahamatevina",
        fokontany: "antanambao anosimanasa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2287997,
          lng: 48.9505159,
        },
        _id: "64f75285ef8f606073e9a041",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "sahamatevina",
        fokontany: "marovintsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2461892,
          lng: 48.92532060000001,
        },
        _id: "64f75285ef8f606073e9a042",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "sahamatevina",
        fokontany: "andraratranina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2009494,
          lng: 48.99085179999999,
        },
        _id: "64f75285ef8f606073e9a043",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "sahamatevina",
        fokontany: "manakambahiny",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2598522,
          lng: 48.9757225,
        },
        _id: "64f75285ef8f606073e9a044",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "sahamatevina",
        fokontany: "ivato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2617151,
          lng: 48.9379168,
        },
        _id: "64f75285ef8f606073e9a045",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "sahamatevina",
        fokontany: "vatolava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2640773,
          lng: 48.9555563,
        },
        _id: "64f75285ef8f606073e9a046",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "sahamatevina",
        fokontany: "ambodiemboka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2643023,
          lng: 48.88503129999999,
        },
        _id: "64f75285ef8f606073e9a047",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "sahamatevina",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2779858,
          lng: 48.9353974,
        },
        _id: "64f75285ef8f606073e9a048",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "sahamatevina",
        fokontany: "niarovana II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2906114,
          lng: 48.8749635,
        },
        _id: "64f75285ef8f606073e9a049",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "sahamatevina",
        fokontany: "bengimena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.319309,
          lng: 48.79637899999999,
        },
        _id: "64f75285ef8f606073e9a04a",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "antanambao mahatsara",
        fokontany: "antanambao mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2520743,
          lng: 48.8045391,
        },
        _id: "64f75285ef8f606073e9a04b",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "antanambao mahatsara",
        fokontany: "ampanoafananina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2741642,
          lng: 48.8145943,
        },
        _id: "64f75285ef8f606073e9a04c",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "antanambao mahatsara",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2688146,
          lng: 48.7241621,
        },
        _id: "64f75285ef8f606073e9a04d",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "antanambao mahatsara",
        fokontany: "androranga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2763314,
          lng: 48.76936,
        },
        _id: "64f75285ef8f606073e9a04e",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "antanambao mahatsara",
        fokontany: "ampitakivolo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.300563,
          lng: 48.7342029,
        },
        _id: "64f75285ef8f606073e9a04f",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "antanambao mahatsara",
        fokontany: "ambohipeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3019814,
          lng: 48.70408579999999,
        },
        _id: "64f75285ef8f606073e9a050",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "antanambao mahatsara",
        fokontany: "sahamoranandro I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.299004,
          lng: 48.8347102,
        },
        _id: "64f75285ef8f606073e9a051",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "antanambao mahatsara",
        fokontany: "ambodibonara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3088083,
          lng: 48.7643362,
        },
        _id: "64f75285ef8f606073e9a052",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "antanambao mahatsara",
        fokontany: "beangivy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3254709,
          lng: 48.68401679999999,
        },
        _id: "64f75285ef8f606073e9a053",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "antanambao mahatsara",
        fokontany: "ambinaninony",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3252948,
          lng: 48.8246514,
        },
        _id: "64f75285ef8f606073e9a054",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "antanambao mahatsara",
        fokontany: "amboditandroho",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3474985,
          lng: 48.7643362,
        },
        _id: "64f75285ef8f606073e9a055",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "antanambao mahatsara",
        fokontany: "ambodinonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3426973,
          lng: 48.7291822,
        },
        _id: "64f75285ef8f606073e9a056",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "antanambao mahatsara",
        fokontany: "ankorabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3668458,
          lng: 48.7643362,
        },
        _id: "64f75285ef8f606073e9a057",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "antanambao mahatsara",
        fokontany: "ambodisaina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3924111,
          lng: 48.76936,
        },
        _id: "64f75285ef8f606073e9a058",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "iamborano",
        fokontany: "iamborano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3806198,
          lng: 48.8145943,
        },
        _id: "64f75285ef8f606073e9a059",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "iamborano",
        fokontany: "maroleona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3847884,
          lng: 48.7944857,
        },
        _id: "64f75285ef8f606073e9a05a",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "iamborano",
        fokontany: "ambohimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.383333,
          lng: 48.75,
        },
        _id: "64f75285ef8f606073e9a05b",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "iamborano",
        fokontany: "maintimbato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.398954,
          lng: 48.8020256,
        },
        _id: "64f75285ef8f606073e9a05c",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "iamborano",
        fokontany: "betainomby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4359562,
          lng: 48.7342029,
        },
        _id: "64f75285ef8f606073e9a05d",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "iamborano",
        fokontany: "ambohipeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.43181,
          lng: 48.7542899,
        },
        _id: "64f75285ef8f606073e9a05e",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "iamborano",
        fokontany: "tetezambolo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2197657,
          lng: 48.739224,
        },
        _id: "64f75285ef8f606073e9a05f",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambalavolo",
        fokontany: "ambalavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1825644,
          lng: 48.7091042,
        },
        _id: "64f75285ef8f606073e9a060",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambalavolo",
        fokontany: "ampasimpotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2049675,
          lng: 48.8447708,
        },
        _id: "64f75285ef8f606073e9a061",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambalavolo",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2037104,
          lng: 48.8045391,
        },
        _id: "64f75285ef8f606073e9a062",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambalavolo",
        fokontany: "mahambo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1989756,
          lng: 48.76936,
        },
        _id: "64f75285ef8f606073e9a063",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambalavolo",
        fokontany: "andranonabidy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2286782,
          lng: 48.8875486,
        },
        _id: "64f75285ef8f606073e9a064",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambalavolo",
        fokontany: "antanandava I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2325224,
          lng: 48.8749635,
        },
        _id: "64f75285ef8f606073e9a065",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambalavolo",
        fokontany: "androrangavola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.246022,
          lng: 48.7291822,
        },
        _id: "64f75285ef8f606073e9a066",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambalavolo",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2354646,
          lng: 48.8145943,
        },
        _id: "64f75285ef8f606073e9a067",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambalavolo",
        fokontany: "andonabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2727095,
          lng: 48.8447708,
        },
        _id: "64f75285ef8f606073e9a068",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambalavolo",
        fokontany: "ambalafandrana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.180861,
          lng: 48.881321,
        },
        _id: "64f75285ef8f606073e9a069",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "amboditavolo",
        fokontany: "amboditavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1404248,
          lng: 48.9102087,
        },
        _id: "64f75285ef8f606073e9a06a",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "amboditavolo",
        fokontany: "lavakorana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1434434,
          lng: 48.84980179999999,
        },
        _id: "64f75285ef8f606073e9a06b",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "amboditavolo",
        fokontany: "afaho",
        __v: 0,
      },
      {
        coords: {
          lat: -19.147825,
          lng: 48.9555563,
        },
        _id: "64f75285ef8f606073e9a06c",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "amboditavolo",
        fokontany: "ambodivontaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1464303,
          lng: 48.7894596,
        },
        _id: "64f75285ef8f606073e9a06d",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "amboditavolo",
        fokontany: "ambalarongana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1813975,
          lng: 48.8648975,
        },
        _id: "64f75285ef8f606073e9a06e",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "amboditavolo",
        fokontany: "ambodifotatra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1865695,
          lng: 48.9555563,
        },
        _id: "64f75285ef8f606073e9a06f",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "amboditavolo",
        fokontany: "iasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1913193,
          lng: 48.8925834,
        },
        _id: "64f75285ef8f606073e9a070",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "amboditavolo",
        fokontany: "marovatana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2004904,
          lng: 48.9353974,
        },
        _id: "64f75285ef8f606073e9a071",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "amboditavolo",
        fokontany: "ambalatenina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2062133,
          lng: 48.88503129999999,
        },
        _id: "64f75285ef8f606073e9a072",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "amboditavolo",
        fokontany: "nosimpary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.073139,
          lng: 48.888699,
        },
        _id: "64f75285ef8f606073e9a073",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "niherenana",
        fokontany: "niherenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0595122,
          lng: 48.9152456,
        },
        _id: "64f75285ef8f606073e9a074",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "niherenana",
        fokontany: "vohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0668255,
          lng: 48.8347102,
        },
        _id: "64f75285ef8f606073e9a075",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "niherenana",
        fokontany: "lomboka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0938707,
          lng: 48.8095665,
        },
        _id: "64f75285ef8f606073e9a076",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "niherenana",
        fokontany: "ambalavary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1191062,
          lng: 48.88503129999999,
        },
        _id: "64f75285ef8f606073e9a077",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "niherenana",
        fokontany: "ambodivandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5466351,
          lng: 48.8548333,
        },
        _id: "64f75285ef8f606073e9a078",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ilaka est",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4725859,
          lng: 48.84980179999999,
        },
        _id: "64f75285ef8f606073e9a079",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ilaka est",
        fokontany: "andranoambia",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4708656,
          lng: 48.8171084,
        },
        _id: "64f75285ef8f606073e9a07a",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ilaka est",
        fokontany: "langilava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4691382,
          lng: 48.784434,
        },
        _id: "64f75285ef8f606073e9a07b",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ilaka est",
        fokontany: "ambodilahoaty",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5002624,
          lng: 48.8095665,
        },
        _id: "64f75285ef8f606073e9a07c",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ilaka est",
        fokontany: "andranomadio",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5296573,
          lng: 48.8020256,
        },
        _id: "64f75285ef8f606073e9a07d",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ilaka est",
        fokontany: "ivato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5161789,
          lng: 48.88503129999999,
        },
        _id: "64f75285ef8f606073e9a07e",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ilaka est",
        fokontany: "antseranandavitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5244851,
          lng: 48.8447708,
        },
        _id: "64f75285ef8f606073e9a07f",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ilaka est",
        fokontany: "ambalakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5450699,
          lng: 48.8359677,
        },
        _id: "64f75285ef8f606073e9a080",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ilaka est",
        fokontany: "ambodibakoly",
        __v: 0,
      },
      {
        coords: {
          lat: -19.541612,
          lng: 48.8409979,
        },
        _id: "64f75285ef8f606073e9a081",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ilaka est",
        fokontany: "centre ville",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5701559,
          lng: 48.8347102,
        },
        _id: "64f75285ef8f606073e9a082",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ilaka est",
        fokontany: "vohitrinoro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5757077,
          lng: 48.8548333,
        },
        _id: "64f75285ef8f606073e9a083",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ilaka est",
        fokontany: "ambodivandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5978693,
          lng: 48.8648975,
        },
        _id: "64f75285ef8f606073e9a084",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ilaka est",
        fokontany: "marosiky",
        __v: 0,
      },
      {
        coords: {
          lat: -19.585996,
          lng: 48.76936,
        },
        _id: "64f75285ef8f606073e9a085",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "niarovana caroline",
        fokontany: "niarovana caroline",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5050903,
          lng: 48.7743842,
        },
        _id: "64f75285ef8f606073e9a086",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "niarovana caroline",
        fokontany: "ampirarazana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5452306,
          lng: 48.8145943,
        },
        _id: "64f75285ef8f606073e9a087",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "niarovana caroline",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5438147,
          lng: 48.7743842,
        },
        _id: "64f75285ef8f606073e9a088",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "niarovana caroline",
        fokontany: "ambinanindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5690726,
          lng: 48.7869468,
        },
        _id: "64f75285ef8f606073e9a089",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "niarovana caroline",
        fokontany: "bonaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.579429,
          lng: 48.77187199999999,
        },
        _id: "64f75285ef8f606073e9a08a",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "niarovana caroline",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5867564,
          lng: 48.8246514,
        },
        _id: "64f75285ef8f606073e9a08b",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "niarovana caroline",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6046947,
          lng: 48.784434,
        },
        _id: "64f75285ef8f606073e9a08c",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "niarovana caroline",
        fokontany: "ambalamangahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5838827,
          lng: 48.7442455,
        },
        _id: "64f75285ef8f606073e9a08d",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "niarovana caroline",
        fokontany: "sahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6073534,
          lng: 48.7241621,
        },
        _id: "64f75285ef8f606073e9a08e",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "niarovana caroline",
        fokontany: "ambalasakay",
        __v: 0,
      },
      {
        coords: {
          lat: -19.656561,
          lng: 48.78051,
        },
        _id: "64f75285ef8f606073e9a08f",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tsivangiana",
        fokontany: "tsivangiana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6268505,
          lng: 48.7944857,
        },
        _id: "64f75285ef8f606073e9a090",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tsivangiana",
        fokontany: "ampasimavo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6255139,
          lng: 48.8246514,
        },
        _id: "64f75285ef8f606073e9a091",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tsivangiana",
        fokontany: "analatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6403197,
          lng: 48.7819214,
        },
        _id: "64f75285ef8f606073e9a092",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tsivangiana",
        fokontany: "ampasimbe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6516524,
          lng: 48.7442455,
        },
        _id: "64f75285ef8f606073e9a093",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tsivangiana",
        fokontany: "ambandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6338626,
          lng: 48.8548333,
        },
        _id: "64f75285ef8f606073e9a094",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tsivangiana",
        fokontany: "tanambao I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6590322,
          lng: 48.7969989,
        },
        _id: "64f75285ef8f606073e9a095",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tsivangiana",
        fokontany: "fanovelona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6596927,
          lng: 48.7819214,
        },
        _id: "64f75285ef8f606073e9a096",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tsivangiana",
        fokontany: "marofody",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6728278,
          lng: 48.7768965,
        },
        _id: "64f75285ef8f606073e9a097",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tsivangiana",
        fokontany: "ambodiaramy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.668641,
          lng: 48.76182439999999,
        },
        _id: "64f75285ef8f606073e9a098",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tsivangiana",
        fokontany: "ambodilaoranjy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6775826,
          lng: 48.74173469999999,
        },
        _id: "64f75285ef8f606073e9a099",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "tsivangiana",
        fokontany: "niarovana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.833333,
          lng: 48.633333,
        },
        _id: "64f75285ef8f606073e9a09a",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ampasimazava",
        fokontany: "ampasimazava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6273685,
          lng: 48.7091042,
        },
        _id: "64f75285ef8f606073e9a09b",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ampasimazava",
        fokontany: "androrangavola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6695262,
          lng: 48.70408579999999,
        },
        _id: "64f75285ef8f606073e9a09c",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ampasimazava",
        fokontany: "ambatomalady",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6860884,
          lng: 48.69405039999999,
        },
        _id: "64f75285ef8f606073e9a09d",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ampasimazava",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.910089,
          lng: 48.533482,
        },
        _id: "64f75285ef8f606073e9a09e",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ampasimadinika",
        fokontany: "ampasimadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4746534,
          lng: 48.7342029,
        },
        _id: "64f75285ef8f606073e9a09f",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ampasimadinika",
        fokontany: "tetezambolo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5,
          lng: 48.75,
        },
        _id: "64f75285ef8f606073e9a0a0",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ampasimadinika",
        fokontany: "ambodinonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4967088,
          lng: 48.67398499999999,
        },
        _id: "64f75285ef8f606073e9a0a1",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ampasimadinika",
        fokontany: "sahamenara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5132712,
          lng: 48.66395499999999,
        },
        _id: "64f75285ef8f606073e9a0a2",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ampasimadinika",
        fokontany: "sosobahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5202577,
          lng: 48.7241621,
        },
        _id: "64f75285ef8f606073e9a0a3",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ampasimadinika",
        fokontany: "iamboloha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5394991,
          lng: 48.6539268,
        },
        _id: "64f75285ef8f606073e9a0a4",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ampasimadinika",
        fokontany: "ambatoharanana I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5548439,
          lng: 48.7442455,
        },
        _id: "64f75285ef8f606073e9a0a5",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ampasimadinika",
        fokontany: "tetezambato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5354864,
          lng: 48.7442455,
        },
        _id: "64f75285ef8f606073e9a0a6",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ampasimadinika",
        fokontany: "antiona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5506224,
          lng: 48.69405039999999,
        },
        _id: "64f75285ef8f606073e9a0a7",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ampasimadinika",
        fokontany: "niarovana sandranavana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5712923,
          lng: 48.66395499999999,
        },
        _id: "64f75285ef8f606073e9a0a8",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ampasimadinika",
        fokontany: "beakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5755358,
          lng: 48.714123,
        },
        _id: "64f75285ef8f606073e9a0a9",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ampasimadinika",
        fokontany: "besambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6044018,
          lng: 48.6439004,
        },
        _id: "64f75285ef8f606073e9a0aa",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ampasimadinika",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6030955,
          lng: 48.67398499999999,
        },
        _id: "64f75285ef8f606073e9a0ab",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ampasimadinika",
        fokontany: "marofinaritra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4021056,
          lng: 48.6990679,
        },
        _id: "64f75285ef8f606073e9a0ac",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina I",
        fokontany: "ifasina I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3444505,
          lng: 48.6915418,
        },
        _id: "64f75285ef8f606073e9a0ad",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina I",
        fokontany: "vohibahoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.36413,
          lng: 48.68401679999999,
        },
        _id: "64f75285ef8f606073e9a0ae",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina I",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3724054,
          lng: 48.714123,
        },
        _id: "64f75285ef8f606073e9a0af",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina I",
        fokontany: "andravoravo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3751781,
          lng: 48.6539268,
        },
        _id: "64f75285ef8f606073e9a0b0",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina I",
        fokontany: "tetezampaho",
        __v: 0,
      },
      {
        coords: {
          lat: -19.40449,
          lng: 48.6113271,
        },
        _id: "64f75285ef8f606073e9a0b1",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina I",
        fokontany: "maizinandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3976114,
          lng: 48.6564337,
        },
        _id: "64f75285ef8f606073e9a0b2",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina I",
        fokontany: "mahafaina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4041757,
          lng: 48.7241621,
        },
        _id: "64f75285ef8f606073e9a0b3",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina I",
        fokontany: "ampasimaneva",
        __v: 0,
      },
      {
        coords: {
          lat: -19.416667,
          lng: 48.7,
        },
        _id: "64f75285ef8f606073e9a0b4",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina I",
        fokontany: "ampitamalandy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4165983,
          lng: 48.66395499999999,
        },
        _id: "64f75285ef8f606073e9a0b5",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina I",
        fokontany: "ilamaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.417962,
          lng: 48.6338758,
        },
        _id: "64f75285ef8f606073e9a0b6",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina I",
        fokontany: "ampitabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4373311,
          lng: 48.70408579999999,
        },
        _id: "64f75285ef8f606073e9a0b7",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina I",
        fokontany: "ambodivoahangy I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4428268,
          lng: 48.6539268,
        },
        _id: "64f75285ef8f606073e9a0b8",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina I",
        fokontany: "vatovaky",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4690516,
          lng: 48.6439004,
        },
        _id: "64f75285ef8f606073e9a0b9",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina I",
        fokontany: "sandrakazo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.221491,
          lng: 48.62976099999999,
        },
        _id: "64f75285ef8f606073e9a0ba",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambalabe",
        fokontany: "ambalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1765218,
          lng: 48.6338758,
        },
        _id: "64f75285ef8f606073e9a0bb",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambalabe",
        fokontany: "ambinanindrano II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2295654,
          lng: 48.6689697,
        },
        _id: "64f75285ef8f606073e9a0bc",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambalabe",
        fokontany: "sahanintsina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1938012,
          lng: 48.6088222,
        },
        _id: "64f75285ef8f606073e9a0bd",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambalabe",
        fokontany: "sahanihonaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2537566,
          lng: 48.6338758,
        },
        _id: "64f75285ef8f606073e9a0be",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ambalabe",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.316667,
          lng: 48.616667,
        },
        _id: "64f75285ef8f606073e9a0bf",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina ii",
        fokontany: "ifasina II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2358604,
          lng: 48.6038129,
        },
        _id: "64f75285ef8f606073e9a0c0",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina ii",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2786194,
          lng: 48.5837799,
        },
        _id: "64f75285ef8f606073e9a0c1",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina ii",
        fokontany: "ambilona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2840685,
          lng: 48.67398499999999,
        },
        _id: "64f75285ef8f606073e9a0c2",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina ii",
        fokontany: "ambatomaronono",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3434334,
          lng: 48.6439004,
        },
        _id: "64f75285ef8f606073e9a0c3",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina ii",
        fokontany: "ampetraka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3372218,
          lng: 48.56876,
        },
        _id: "64f75285ef8f606073e9a0c4",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina ii",
        fokontany: "vohitrambana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3296244,
          lng: 48.66395499999999,
        },
        _id: "64f75285ef8f606073e9a0c5",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina ii",
        fokontany: "ambodivoangy II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3448157,
          lng: 48.613832,
        },
        _id: "64f75285ef8f606073e9a0c6",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina ii",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3489513,
          lng: 48.5937955,
        },
        _id: "64f75285ef8f606073e9a0c7",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina ii",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3765527,
          lng: 48.623853,
        },
        _id: "64f75285ef8f606073e9a0c8",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina ii",
        fokontany: "morombe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3779193,
          lng: 48.5937955,
        },
        _id: "64f75285ef8f606073e9a0c9",
        province: "toamasina",
        region: "atsinanana",
        district: "vatomandry",
        commune: "ifasina ii",
        fokontany: "ranolalina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9015853,
          lng: 48.8108234,
        },
        _id: "64f75285ef8f606073e9a0ca",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "ambalamangahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6961441,
          lng: 48.8347102,
        },
        _id: "64f75285ef8f606073e9a0cb",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "ambohimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7584097,
          lng: 48.8145943,
        },
        _id: "64f75285ef8f606073e9a0cc",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "tandroroho",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8103276,
          lng: 48.8095665,
        },
        _id: "64f75285ef8f606073e9a0cd",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "ambilabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8066184,
          lng: 48.7442455,
        },
        _id: "64f75285ef8f606073e9a0ce",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "androrangambo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8216222,
          lng: 48.69405039999999,
        },
        _id: "64f75285ef8f606073e9a0cf",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "ambodiriana lohariana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8356841,
          lng: 48.7442455,
        },
        _id: "64f75285ef8f606073e9a0d0",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "maintimbato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8734762,
          lng: 48.6890334,
        },
        _id: "64f75285ef8f606073e9a0d1",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "ambinanisasika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8606912,
          lng: 48.7643362,
        },
        _id: "64f75285ef8f606073e9a0d2",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "maroahitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8588113,
          lng: 48.7316925,
        },
        _id: "64f75285ef8f606073e9a0d3",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "bemangahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8594371,
          lng: 48.7944857,
        },
        _id: "64f75285ef8f606073e9a0d4",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "miakara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9022805,
          lng: 48.7743842,
        },
        _id: "64f75285ef8f606073e9a0d5",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "vohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8793751,
          lng: 48.8007689,
        },
        _id: "64f75285ef8f606073e9a0d6",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "ampampanambo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9006958,
          lng: 48.7342029,
        },
        _id: "64f75285ef8f606073e9a0d7",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "sahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8896193,
          lng: 48.8070527,
        },
        _id: "64f75285ef8f606073e9a0d8",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "ambalakininina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8967362,
          lng: 48.8108234,
        },
        _id: "64f75285ef8f606073e9a0d9",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "tanamborozano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8995319,
          lng: 48.80673849999999,
        },
        _id: "64f75285ef8f606073e9a0da",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "ankadirano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8994729,
          lng: 48.8032823,
        },
        _id: "64f75285ef8f606073e9a0db",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "tanambao II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9172544,
          lng: 48.7241621,
        },
        _id: "64f75285ef8f606073e9a0dc",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9135528,
          lng: 48.8145943,
        },
        _id: "64f75285ef8f606073e9a0dd",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "androhomanasa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9463251,
          lng: 48.7241621,
        },
        _id: "64f75285ef8f606073e9a0de",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "mahanoro",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.961281,
          lng: 48.74935199999999,
        },
        _id: "64f75285ef8f606073e9a0df",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "betsizaraina",
        fokontany: "betsizaraina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9,
          lng: 48.59999999999999,
        },
        _id: "64f75285ef8f606073e9a0e0",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "betsizaraina",
        fokontany: "andovolalina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9178976,
          lng: 48.5462377,
        },
        _id: "64f75285ef8f606073e9a0e1",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "betsizaraina",
        fokontany: "ambinaniango",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9382381,
          lng: 48.7643362,
        },
        _id: "64f75285ef8f606073e9a0e2",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "betsizaraina",
        fokontany: "niarovanivolo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9259373,
          lng: 48.6689697,
        },
        _id: "64f75285ef8f606073e9a0e3",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "betsizaraina",
        fokontany: "betamotamo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.930565,
          lng: 48.6338758,
        },
        _id: "64f75285ef8f606073e9a0e4",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "betsizaraina",
        fokontany: "tratramarina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9629633,
          lng: 48.6714773,
        },
        _id: "64f75285ef8f606073e9a0e5",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "betsizaraina",
        fokontany: "menagisy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.956405,
          lng: 48.7944857,
        },
        _id: "64f75285ef8f606073e9a0e6",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "betsizaraina",
        fokontany: "tsangambato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9721237,
          lng: 48.6439004,
        },
        _id: "64f75285ef8f606073e9a0e7",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "betsizaraina",
        fokontany: "ambodivoangy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9895187,
          lng: 48.7850622,
        },
        _id: "64f75285ef8f606073e9a0e8",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "betsizaraina",
        fokontany: "salehy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0331174,
          lng: 48.56876,
        },
        _id: "64f75285ef8f606073e9a0e9",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "betsizaraina",
        fokontany: "ampasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0547358,
          lng: 48.5087209,
        },
        _id: "64f75285ef8f606073e9a0ea",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "betsizaraina",
        fokontany: "beanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7504086,
          lng: 48.6990679,
        },
        _id: "64f75285ef8f606073e9a0eb",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "tsaravinany",
        fokontany: "tsaravinany",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6890819,
          lng: 48.7743842,
        },
        _id: "64f75285ef8f606073e9a0ec",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "tsaravinany",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7051928,
          lng: 48.623853,
        },
        _id: "64f75285ef8f606073e9a0ed",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "tsaravinany",
        fokontany: "ambolomalemy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7110439,
          lng: 48.714123,
        },
        _id: "64f75285ef8f606073e9a0ee",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "tsaravinany",
        fokontany: "sahamanandra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7105123,
          lng: 48.57376619999999,
        },
        _id: "64f75285ef8f606073e9a0ef",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "tsaravinany",
        fokontany: "sahanomby I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7129633,
          lng: 48.6689697,
        },
        _id: "64f75285ef8f606073e9a0f0",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "tsaravinany",
        fokontany: "andranonaomby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7360094,
          lng: 48.7342029,
        },
        _id: "64f75285ef8f606073e9a0f1",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "tsaravinany",
        fokontany: "tanjombe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7271845,
          lng: 48.7894596,
        },
        _id: "64f75285ef8f606073e9a0f2",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "tsaravinany",
        fokontany: "ampitakihosy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7441661,
          lng: 48.69405039999999,
        },
        _id: "64f75285ef8f606073e9a0f3",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "tsaravinany",
        fokontany: "marosondry",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7734763,
          lng: 48.7643362,
        },
        _id: "64f75285ef8f606073e9a0f4",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "tsaravinany",
        fokontany: "ambodimanga II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.75,
          lng: 48.583333,
        },
        _id: "64f75285ef8f606073e9a0f5",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "tsaravinany",
        fokontany: "maintimbato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7507485,
          lng: 48.613832,
        },
        _id: "64f75285ef8f606073e9a0f6",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "tsaravinany",
        fokontany: "ampasimadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7747504,
          lng: 48.7342029,
        },
        _id: "64f75285ef8f606073e9a0f7",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "tsaravinany",
        fokontany: "ambodihazomamy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7800832,
          lng: 48.68401679999999,
        },
        _id: "64f75285ef8f606073e9a0f8",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "tsaravinany",
        fokontany: "taviranambo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0132311,
          lng: 48.768104,
        },
        _id: "64f75285ef8f606073e9a0f9",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodiharina",
        fokontany: "ambodiharina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9463526,
          lng: 48.6426472,
        },
        _id: "64f75285ef8f606073e9a0fa",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodiharina",
        fokontany: "andrara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9567766,
          lng: 48.623853,
        },
        _id: "64f75285ef8f606073e9a0fb",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodiharina",
        fokontany: "andranomanerina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9504481,
          lng: 48.6614478,
        },
        _id: "64f75285ef8f606073e9a0fc",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodiharina",
        fokontany: "tanambao III",
        __v: 0,
      },
      {
        coords: {
          lat: -19.976598,
          lng: 48.69405039999999,
        },
        _id: "64f75285ef8f606073e9a0fd",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodiharina",
        fokontany: "vohilava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9750977,
          lng: 48.7316925,
        },
        _id: "64f75285ef8f606073e9a0fe",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodiharina",
        fokontany: "ankarimbelona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0016423,
          lng: 48.714123,
        },
        _id: "64f75285ef8f606073e9a0ff",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodiharina",
        fokontany: "androrangapetraka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9967885,
          lng: 48.7549178,
        },
        _id: "64f75285ef8f606073e9a100",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodiharina",
        fokontany: "ifasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9907515,
          lng: 48.7442455,
        },
        _id: "64f75285ef8f606073e9a101",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodiharina",
        fokontany: "tanambao ifasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0308103,
          lng: 48.6288642,
        },
        _id: "64f75285ef8f606073e9a102",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodiharina",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0153549,
          lng: 48.69405039999999,
        },
        _id: "64f75285ef8f606073e9a103",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodiharina",
        fokontany: "ambarimantsina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0301844,
          lng: 48.686525,
        },
        _id: "64f75285ef8f606073e9a104",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodiharina",
        fokontany: "niarovana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0398199,
          lng: 48.7291822,
        },
        _id: "64f75285ef8f606073e9a105",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodiharina",
        fokontany: "benavony",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0507546,
          lng: 48.613832,
        },
        _id: "64f75285ef8f606073e9a106",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodiharina",
        fokontany: "mandroalina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.05546,
          lng: 48.688419,
        },
        _id: "64f75285ef8f606073e9a107",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodiharina",
        fokontany: "ambodisovoka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0672743,
          lng: 48.6038129,
        },
        _id: "64f75285ef8f606073e9a108",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodiharina",
        fokontany: "mahamavo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1025886,
          lng: 48.6088222,
        },
        _id: "64f75285ef8f606073e9a109",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodiharina",
        fokontany: "ambatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0980318,
          lng: 48.6439004,
        },
        _id: "64f75285ef8f606073e9a10a",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodiharina",
        fokontany: "maromitety",
        __v: 0,
      },
      {
        coords: {
          lat: -20.12854,
          lng: 48.724805,
        },
        _id: "64f75285ef8f606073e9a10b",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodiharina",
        fokontany: "andranotsara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1390683,
          lng: 48.6689697,
        },
        _id: "64f75285ef8f606073e9a10c",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodiharina",
        fokontany: "andranambomaro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.80743,
          lng: 48.574699,
        },
        _id: "64f75285ef8f606073e9a10d",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "manjakandriana",
        fokontany: "manjakandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7263188,
          lng: 48.4237772,
        },
        _id: "64f75285ef8f606073e9a10e",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "manjakandriana",
        fokontany: "ambatomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.75,
          lng: 48.433333,
        },
        _id: "64f75285ef8f606073e9a10f",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "manjakandriana",
        fokontany: "ampitakilaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7590988,
          lng: 48.4887224,
        },
        _id: "64f75285ef8f606073e9a110",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "manjakandriana",
        fokontany: "ampitabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.80743,
          lng: 48.574699,
        },
        _id: "64f75285ef8f606073e9a111",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "manjakandriana",
        fokontany: "ampasimazava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7847688,
          lng: 48.6489134,
        },
        _id: "64f75285ef8f606073e9a112",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "manjakandriana",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8,
          lng: 48.516667,
        },
        _id: "64f75285ef8f606073e9a113",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "manjakandriana",
        fokontany: "famoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8394077,
          lng: 48.6539268,
        },
        _id: "64f75285ef8f606073e9a114",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "manjakandriana",
        fokontany: "ambodivoangy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8333897,
          lng: 48.5637543,
        },
        _id: "64f75285ef8f606073e9a115",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "manjakandriana",
        fokontany: "ankazovelona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8702695,
          lng: 48.6088222,
        },
        _id: "64f75285ef8f606073e9a116",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "manjakandriana",
        fokontany: "ankarabo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8470847,
          lng: 48.5437358,
        },
        _id: "64f75285ef8f606073e9a117",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "manjakandriana",
        fokontany: "ambodimankaranana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.881559,
          lng: 48.6489134,
        },
        _id: "64f75285ef8f606073e9a118",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "manjakandriana",
        fokontany: "ambodifanaharana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.282821,
          lng: 48.6251058,
        },
        _id: "64f75285ef8f606073e9a119",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "masomeloka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1139591,
          lng: 48.5637543,
        },
        _id: "64f75285ef8f606073e9a11a",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "ampasimadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0991327,
          lng: 48.5287267,
        },
        _id: "64f75285ef8f606073e9a11b",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "tanambao soketaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1287113,
          lng: 48.5137217,
        },
        _id: "64f75285ef8f606073e9a11c",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "androrangavola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1361743,
          lng: 48.57376619999999,
        },
        _id: "64f75285ef8f606073e9a11d",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "ambodisana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1372842,
          lng: 48.5437358,
        },
        _id: "64f75285ef8f606073e9a11e",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "ambodivandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1527185,
          lng: 48.6063175,
        },
        _id: "64f75285ef8f606073e9a11f",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "andranofanendry",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1629943,
          lng: 48.6338758,
        },
        _id: "64f75285ef8f606073e9a120",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "vohitrakoho",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1648212,
          lng: 48.496221,
        },
        _id: "64f75285ef8f606073e9a121",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "ampasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1709372,
          lng: 48.5937955,
        },
        _id: "64f75285ef8f606073e9a122",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "ambodimangamaro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1635133,
          lng: 48.5762695,
        },
        _id: "64f75285ef8f606073e9a123",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "vohimaro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1577365,
          lng: 48.5137217,
        },
        _id: "64f75285ef8f606073e9a124",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "ambodisadifitra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1663196,
          lng: 48.5437358,
        },
        _id: "64f75285ef8f606073e9a125",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "namirana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1817223,
          lng: 48.5637543,
        },
        _id: "64f75285ef8f606073e9a126",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "iamborano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.196441,
          lng: 48.5137217,
        },
        _id: "64f75285ef8f606073e9a127",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "sahavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1999915,
          lng: 48.5937955,
        },
        _id: "64f75285ef8f606073e9a128",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "lemaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1988892,
          lng: 48.623853,
        },
        _id: "64f75285ef8f606073e9a129",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "manakana ankaranila",
        __v: 0,
      },
      {
        coords: {
          lat: -20.201788,
          lng: 48.6764928,
        },
        _id: "64f75285ef8f606073e9a12a",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "ampanotoana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1975164,
          lng: 48.4837239,
        },
        _id: "64f75285ef8f606073e9a12b",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "menagisy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2297171,
          lng: 48.66395499999999,
        },
        _id: "64f75285ef8f606073e9a12c",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "sohihy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2319142,
          lng: 48.6038129,
        },
        _id: "64f75285ef8f606073e9a12d",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "marovahy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2382875,
          lng: 48.5162223,
        },
        _id: "64f75285ef8f606073e9a12e",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "sahatelo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2291432,
          lng: 48.5012206,
        },
        _id: "64f75285ef8f606073e9a12f",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "antseranambato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2372683,
          lng: 48.4537424,
        },
        _id: "64f75285ef8f606073e9a130",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "ambodiara masora",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2209918,
          lng: 48.5487397,
        },
        _id: "64f75285ef8f606073e9a131",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "sahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2473347,
          lng: 48.623853,
        },
        _id: "64f75285ef8f606073e9a132",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "valokianja",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2512893,
          lng: 48.6038129,
        },
        _id: "64f75285ef8f606073e9a133",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "lokia",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2576398,
          lng: 48.65141999999999,
        },
        _id: "64f75285ef8f606073e9a134",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "antaniambo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2707457,
          lng: 48.6464068,
        },
        _id: "64f75285ef8f606073e9a135",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "analila",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2829976,
          lng: 48.6426472,
        },
        _id: "64f75285ef8f606073e9a136",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "masomeloka",
        fokontany: "manonilaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9035139,
          lng: 48.4187846,
        },
        _id: "64f75285ef8f606073e9a137",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanidilana",
        fokontany: "ambinanidilana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7842347,
          lng: 48.4237772,
        },
        _id: "64f75285ef8f606073e9a138",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanidilana",
        fokontany: "anosibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8063664,
          lng: 48.43376380000001,
        },
        _id: "64f75285ef8f606073e9a139",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanidilana",
        fokontany: "ambodiala",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8363867,
          lng: 48.4887224,
        },
        _id: "64f75285ef8f606073e9a13a",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanidilana",
        fokontany: "tanambao tratranaingitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8296211,
          lng: 48.4562402,
        },
        _id: "64f75285ef8f606073e9a13b",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanidilana",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.833333,
          lng: 48.433333,
        },
        _id: "64f75285ef8f606073e9a13c",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanidilana",
        fokontany: "ampitamalandy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8461596,
          lng: 48.4038094,
        },
        _id: "64f75285ef8f606073e9a13d",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanidilana",
        fokontany: "mahadio",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8598034,
          lng: 48.383849,
        },
        _id: "64f75285ef8f606073e9a13e",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanidilana",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8734388,
          lng: 48.36389579999999,
        },
        _id: "64f75285ef8f606073e9a13f",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanidilana",
        fokontany: "tanambao namantoana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8817443,
          lng: 48.5637543,
        },
        _id: "64f75285ef8f606073e9a140",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanidilana",
        fokontany: "ambodihara sahanary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8693802,
          lng: 48.4687311,
        },
        _id: "64f75285ef8f606073e9a141",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanidilana",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8910221,
          lng: 48.4088007,
        },
        _id: "64f75285ef8f606073e9a142",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanidilana",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8864537,
          lng: 48.4437522,
        },
        _id: "64f75285ef8f606073e9a143",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanidilana",
        fokontany: "ampasimaneva",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9194179,
          lng: 48.33896439999999,
        },
        _id: "64f75285ef8f606073e9a144",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanidilana",
        fokontany: "ambodimanga namantoana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.910089,
          lng: 48.533482,
        },
        _id: "64f75285ef8f606073e9a145",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanidilana",
        fokontany: "ampasimadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.922488,
          lng: 48.5112213,
        },
        _id: "64f75285ef8f606073e9a146",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanidilana",
        fokontany: "sahatona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.912597,
          lng: 48.43376380000001,
        },
        _id: "64f75285ef8f606073e9a147",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanidilana",
        fokontany: "ambalafatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9330564,
          lng: 48.4038094,
        },
        _id: "64f75285ef8f606073e9a148",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanidilana",
        fokontany: "ambodinivato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9756521,
          lng: 48.383849,
        },
        _id: "64f75285ef8f606073e9a149",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanidilana",
        fokontany: "tanjomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9841219,
          lng: 48.3289951,
        },
        _id: "64f75285ef8f606073e9a14a",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanidilana",
        fokontany: "ampasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2995019,
          lng: 48.5187229,
        },
        _id: "64f75285ef8f606073e9a14b",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodibonara",
        fokontany: "ambodibonara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2534422,
          lng: 48.5437358,
        },
        _id: "64f75285ef8f606073e9a14c",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodibonara",
        fokontany: "maroandrina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2545068,
          lng: 48.5137217,
        },
        _id: "64f75285ef8f606073e9a14d",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodibonara",
        fokontany: "beloa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2620546,
          lng: 48.57376619999999,
        },
        _id: "64f75285ef8f606073e9a14e",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodibonara",
        fokontany: "satrana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.259488,
          lng: 48.4637344,
        },
        _id: "64f75285ef8f606073e9a14f",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodibonara",
        fokontany: "voampoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2709894,
          lng: 48.5037206,
        },
        _id: "64f75285ef8f606073e9a150",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodibonara",
        fokontany: "tratramarina II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2848516,
          lng: 48.476227,
        },
        _id: "64f75285ef8f606073e9a151",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodibonara",
        fokontany: "ankalampona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3026086,
          lng: 48.4287702,
        },
        _id: "64f75285ef8f606073e9a152",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodibonara",
        fokontany: "tanambao II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3243254,
          lng: 48.4537424,
        },
        _id: "64f75285ef8f606073e9a153",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodibonara",
        fokontany: "marosika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3292426,
          lng: 48.4038094,
        },
        _id: "64f75285ef8f606073e9a154",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodibonara",
        fokontany: "vohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3358592,
          lng: 48.4937213,
        },
        _id: "64f75285ef8f606073e9a155",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodibonara",
        fokontany: "betampona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.35178,
          lng: 48.575291,
        },
        _id: "64f75285ef8f606073e9a156",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodibonara",
        fokontany: "ambalavontaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3412951,
          lng: 48.4287702,
        },
        _id: "64f75285ef8f606073e9a157",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodibonara",
        fokontany: "ambodirotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3643861,
          lng: 48.5087209,
        },
        _id: "64f75285ef8f606073e9a158",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodibonara",
        fokontany: "ankaramaniona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3553613,
          lng: 48.3938283,
        },
        _id: "64f75285ef8f606073e9a159",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodibonara",
        fokontany: "ampitabe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.363025,
          lng: 48.4537424,
        },
        _id: "64f75285ef8f606073e9a15a",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodibonara",
        fokontany: "ambalakondro I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3871458,
          lng: 48.5037206,
        },
        _id: "64f75285ef8f606073e9a15b",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodibonara",
        fokontany: "kianjavato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3915511,
          lng: 48.4687311,
        },
        _id: "64f75285ef8f606073e9a15c",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambodibonara",
        fokontany: "analatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.964864,
          lng: 48.4987208,
        },
        _id: "64f75285ef8f606073e9a15d",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ankazotsifantatra",
        fokontany: "ankazotsifantatra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9260953,
          lng: 48.5837799,
        },
        _id: "64f75285ef8f606073e9a15e",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ankazotsifantatra",
        fokontany: "maroariana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.948944,
          lng: 48.4937213,
        },
        _id: "64f75285ef8f606073e9a15f",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ankazotsifantatra",
        fokontany: "mizahamiorika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9426602,
          lng: 48.5312279,
        },
        _id: "64f75285ef8f606073e9a160",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ankazotsifantatra",
        fokontany: "andihona II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9512361,
          lng: 48.43376380000001,
        },
        _id: "64f75285ef8f606073e9a161",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ankazotsifantatra",
        fokontany: "ampasimadinika II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9596675,
          lng: 48.5912914,
        },
        _id: "64f75285ef8f606073e9a162",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ankazotsifantatra",
        fokontany: "tanambao I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9522888,
          lng: 48.57376619999999,
        },
        _id: "64f75285ef8f606073e9a163",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ankazotsifantatra",
        fokontany: "ambodilalona I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.966667,
          lng: 48.45,
        },
        _id: "64f75285ef8f606073e9a164",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ankazotsifantatra",
        fokontany: "ambatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0183079,
          lng: 48.448747,
        },
        _id: "64f75285ef8f606073e9a165",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ankazotsifantatra",
        fokontany: "ampasimadinika I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0592641,
          lng: 48.4737282,
        },
        _id: "64f75285ef8f606073e9a166",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ankazotsifantatra",
        fokontany: "ampitabe I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.094219,
          lng: 48.37704799999999,
        },
        _id: "64f75285ef8f606073e9a167",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "ambinanindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.022181,
          lng: 48.3439498,
        },
        _id: "64f75285ef8f606073e9a168",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "sahamanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0205329,
          lng: 48.3888384,
        },
        _id: "64f75285ef8f606073e9a169",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "ambodivoangy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0191737,
          lng: 48.24930579999999,
        },
        _id: "64f75285ef8f606073e9a16a",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "ampontsiambo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0306033,
          lng: 48.2891359,
        },
        _id: "64f75285ef8f606073e9a16b",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "ambodiara I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0520245,
          lng: 48.2294017,
        },
        _id: "64f75285ef8f606073e9a16c",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "bemana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0529102,
          lng: 48.383849,
        },
        _id: "64f75285ef8f606073e9a16d",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "mahadio",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0675923,
          lng: 48.3339796,
        },
        _id: "64f75285ef8f606073e9a16e",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "ampasimaniona I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0848614,
          lng: 48.2095048,
        },
        _id: "64f75285ef8f606073e9a16f",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "ankodona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0711373,
          lng: 48.41379240000001,
        },
        _id: "64f75285ef8f606073e9a170",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "niarovana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.083333,
          lng: 48.283333,
        },
        _id: "64f75285ef8f606073e9a171",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "ambodisovoka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0926234,
          lng: 48.3539219,
        },
        _id: "64f75285ef8f606073e9a172",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "ambodifanaharana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1013457,
          lng: 48.4687311,
        },
        _id: "64f75285ef8f606073e9a173",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "ampasimadinika I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.096555,
          lng: 48.3339796,
        },
        _id: "64f75285ef8f606073e9a174",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "vohimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.132834,
          lng: 48.3090619,
        },
        _id: "64f75285ef8f606073e9a175",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "ambatomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1092468,
          lng: 48.4287702,
        },
        _id: "64f75285ef8f606073e9a176",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "sahavatoana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1283866,
          lng: 48.3439498,
        },
        _id: "64f75285ef8f606073e9a177",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "ambodivanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1301884,
          lng: 48.383849,
        },
        _id: "64f75285ef8f606073e9a178",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "ambodimanga I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.133333,
          lng: 48.4166669,
        },
        _id: "64f75285ef8f606073e9a179",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "vohitrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1366195,
          lng: 48.4737282,
        },
        _id: "64f75285ef8f606073e9a17a",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "ampasinambo II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1620007,
          lng: 48.2095048,
        },
        _id: "64f75285ef8f606073e9a17b",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "ambatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.153437,
          lng: 48.36389579999999,
        },
        _id: "64f75285ef8f606073e9a17c",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1792573,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e9a17d",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "manakana III",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1792435,
          lng: 48.4537424,
        },
        _id: "64f75285ef8f606073e9a17e",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "ambinanindrano",
        fokontany: "madiolamba",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2283602,
          lng: 48.33896439999999,
        },
        _id: "64f75285ef8f606073e9a17f",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "befotaka",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2022581,
          lng: 48.3489356,
        },
        _id: "64f75285ef8f606073e9a180",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "befotaka",
        fokontany: "tanambao I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2001703,
          lng: 48.4088007,
        },
        _id: "64f75285ef8f606073e9a181",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "befotaka",
        fokontany: "mandemoka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2178616,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e9a182",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "befotaka",
        fokontany: "ambodilalona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2142752,
          lng: 48.3738715,
        },
        _id: "64f75285ef8f606073e9a183",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "befotaka",
        fokontany: "manakana II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2278466,
          lng: 48.3539219,
        },
        _id: "64f75285ef8f606073e9a184",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "befotaka",
        fokontany: "niarovana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2486944,
          lng: 48.3090619,
        },
        _id: "64f75285ef8f606073e9a185",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "befotaka",
        fokontany: "ambinanindrano I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2525587,
          lng: 48.4312669,
        },
        _id: "64f75285ef8f606073e9a186",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "befotaka",
        fokontany: "andravoanjo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2490203,
          lng: 48.3938283,
        },
        _id: "64f75285ef8f606073e9a187",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "befotaka",
        fokontany: "manambatana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2507009,
          lng: 48.24930579999999,
        },
        _id: "64f75285ef8f606073e9a188",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "befotaka",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2809001,
          lng: 48.4038094,
        },
        _id: "64f75285ef8f606073e9a189",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "befotaka",
        fokontany: "vohidava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2945906,
          lng: 48.2841556,
        },
        _id: "64f75285ef8f606073e9a18a",
        province: "toamasina",
        region: "atsinanana",
        district: "mahanoro",
        commune: "befotaka",
        fokontany: "anovibe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0471945,
          lng: 48.1321629,
        },
        _id: "64f75285ef8f606073e9a18b",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "vohidiavolana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9,
          lng: 48.066667,
        },
        _id: "64f75285ef8f606073e9a18c",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "ambodiala",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9326087,
          lng: 48.0654706,
        },
        _id: "64f75285ef8f606073e9a18d",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "maromitety",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9411764,
          lng: 48.0952394,
        },
        _id: "64f75285ef8f606073e9a18e",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "antaramasoandro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9463565,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e9a18f",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "ambinaninkosy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9476039,
          lng: 48.184644,
        },
        _id: "64f75285ef8f606073e9a190",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9754357,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e9a191",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "tsivalana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9743561,
          lng: 48.24432909999999,
        },
        _id: "64f75285ef8f606073e9a192",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "ambatomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0014938,
          lng: 48.2045317,
        },
        _id: "64f75285ef8f606073e9a193",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "ambanja",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0098545,
          lng: 48.14985799999999,
        },
        _id: "64f75285ef8f606073e9a194",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "ankosy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9710949,
          lng: 48.0654706,
        },
        _id: "64f75285ef8f606073e9a195",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0038554,
          lng: 48.045634,
        },
        _id: "64f75285ef8f606073e9a196",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "androrangavola tsienta",
        __v: 0,
      },
      {
        coords: {
          lat: -20.005691,
          lng: 48.0853146,
        },
        _id: "64f75285ef8f606073e9a197",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "tsaratampona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0278087,
          lng: 48.0952394,
        },
        _id: "64f75285ef8f606073e9a198",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "ambatolomakana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9921636,
          lng: 48.1051659,
        },
        _id: "64f75285ef8f606073e9a199",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "ambalafarihy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0201315,
          lng: 48.177188,
        },
        _id: "64f75285ef8f606073e9a19a",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "mahadio",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0450026,
          lng: 48.154826,
        },
        _id: "64f75285ef8f606073e9a19b",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "ampasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0733908,
          lng: 48.169733,
        },
        _id: "64f75285ef8f606073e9a19c",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "vohitromby II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.05913,
          lng: 48.13327,
        },
        _id: "64f75285ef8f606073e9a19d",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -20.049932,
          lng: 48.1051659,
        },
        _id: "64f75285ef8f606073e9a19e",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "maroleotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0413268,
          lng: 48.0753917,
        },
        _id: "64f75285ef8f606073e9a19f",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "anosy dika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0269822,
          lng: 48.0258046,
        },
        _id: "64f75285ef8f606073e9a1a0",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.070205,
          lng: 48.0753917,
        },
        _id: "64f75285ef8f606073e9a1a1",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "ambalaherana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0941959,
          lng: 48.12502449999999,
        },
        _id: "64f75285ef8f606073e9a1a2",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "ambohitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0774603,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e9a1a3",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "mahalanga I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1022117,
          lng: 48.0778723,
        },
        _id: "64f75285ef8f606073e9a1a4",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "sambiaravo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.116667,
          lng: 48.09999999999999,
        },
        _id: "64f75285ef8f606073e9a1a5",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "marofatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1413592,
          lng: 48.154826,
        },
        _id: "64f75285ef8f606073e9a1a6",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "lavajiro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1423625,
          lng: 48.12502449999999,
        },
        _id: "64f75285ef8f606073e9a1a7",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "marolambo",
        fokontany: "andranambolava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.183333,
          lng: 48.066667,
        },
        _id: "64f75285ef8f606073e9a1a8",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "betampona",
        fokontany: "betampona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1679458,
          lng: 48.03076129999999,
        },
        _id: "64f75285ef8f606073e9a1a9",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "betampona",
        fokontany: "ambalamena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1443458,
          lng: 48.0654706,
        },
        _id: "64f75285ef8f606073e9a1aa",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "betampona",
        fokontany: "mahavelombady",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1626206,
          lng: 48.0952394,
        },
        _id: "64f75285ef8f606073e9a1ab",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "betampona",
        fokontany: "vohidamba",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1915167,
          lng: 48.0952394,
        },
        _id: "64f75285ef8f606073e9a1ac",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "betampona",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1967969,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e9a1ad",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "betampona",
        fokontany: "lavakianja",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2194414,
          lng: 48.0258046,
        },
        _id: "64f75285ef8f606073e9a1ae",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "betampona",
        fokontany: "andravoravo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2310088,
          lng: 48.0654706,
        },
        _id: "64f75285ef8f606073e9a1af",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "betampona",
        fokontany: "mahadonaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2357995,
          lng: 48.01589269999999,
        },
        _id: "64f75285ef8f606073e9a1b0",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "betampona",
        fokontany: "amboditavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2295663,
          lng: 48.1101299,
        },
        _id: "64f75285ef8f606073e9a1b1",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "betampona",
        fokontany: "beranomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2637409,
          lng: 48.045634,
        },
        _id: "64f75285ef8f606073e9a1b2",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "betampona",
        fokontany: "ampasinambo II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.263925,
          lng: 47.9886442,
        },
        _id: "64f75285ef8f606073e9a1b3",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "betampona",
        fokontany: "mazavalanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2646773,
          lng: 48.01589269999999,
        },
        _id: "64f75285ef8f606073e9a1b4",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "betampona",
        fokontany: "anosy rahindy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2995914,
          lng: 48.0282829,
        },
        _id: "64f75285ef8f606073e9a1b5",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "betampona",
        fokontany: "ambalatenina I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3008607,
          lng: 48.0902768,
        },
        _id: "64f75285ef8f606073e9a1b6",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "betampona",
        fokontany: "ambodiaviavy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.379766,
          lng: 48.03076129999999,
        },
        _id: "64f75285ef8f606073e9a1b7",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "tanambao rabemanana",
        fokontany: "tanambao rabemanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3294725,
          lng: 48.0481132,
        },
        _id: "64f75285ef8f606073e9a1b8",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "tanambao rabemanana",
        fokontany: "ambodiara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3528667,
          lng: 48.070431,
        },
        _id: "64f75285ef8f606073e9a1b9",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "tanambao rabemanana",
        fokontany: "ampasinambo I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3936468,
          lng: 47.9960743,
        },
        _id: "64f75285ef8f606073e9a1ba",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "tanambao rabemanana",
        fokontany: "vohitrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4241243,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e9a1bb",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "tanambao rabemanana",
        fokontany: "ampasimadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.92679,
          lng: 48.199551,
        },
        _id: "64f75285ef8f606073e9a1bc",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "andonabe sud",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8071005,
          lng: 48.24930579999999,
        },
        _id: "64f75285ef8f606073e9a1bd",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "salemanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8088,
          lng: 48.2045317,
        },
        _id: "64f75285ef8f606073e9a1be",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "maromitety I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.817299,
          lng: 48.2766859,
        },
        _id: "64f75285ef8f606073e9a1bf",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8266364,
          lng: 48.3265031,
        },
        _id: "64f75285ef8f606073e9a1c0",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "ampatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8413688,
          lng: 48.3614021,
        },
        _id: "64f75285ef8f606073e9a1c1",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "ampasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.855181,
          lng: 48.334518,
        },
        _id: "64f75285ef8f606073e9a1c2",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "sakalava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8467838,
          lng: 48.3040797,
        },
        _id: "64f75285ef8f606073e9a1c3",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "ambodiala",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8345923,
          lng: 48.2866457,
        },
        _id: "64f75285ef8f606073e9a1c4",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "maromitety II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8280635,
          lng: 48.2045317,
        },
        _id: "64f75285ef8f606073e9a1c5",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "ambalaomby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8223891,
          lng: 48.184644,
        },
        _id: "64f75285ef8f606073e9a1c6",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "ambinanifatranonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8362634,
          lng: 48.1784306,
        },
        _id: "64f75285ef8f606073e9a1c7",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "ambohimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8359698,
          lng: 48.1647635,
        },
        _id: "64f75285ef8f606073e9a1c8",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "ambalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8433718,
          lng: 48.2244268,
        },
        _id: "64f75285ef8f606073e9a1c9",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "ambatolava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8618037,
          lng: 48.2468174,
        },
        _id: "64f75285ef8f606073e9a1ca",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "androrangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8762791,
          lng: 48.2891359,
        },
        _id: "64f75285ef8f606073e9a1cb",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8785162,
          lng: 48.2294017,
        },
        _id: "64f75285ef8f606073e9a1cc",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.886412,
          lng: 48.1896152,
        },
        _id: "64f75285ef8f606073e9a1cd",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "ambatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.86365,
          lng: 48.1101299,
        },
        _id: "64f75285ef8f606073e9a1ce",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "andovolava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9055327,
          lng: 48.1051659,
        },
        _id: "64f75285ef8f606073e9a1cf",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "ambodiara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9,
          lng: 48.133333,
        },
        _id: "64f75285ef8f606073e9a1d0",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "ambalaherana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9011859,
          lng: 48.2244268,
        },
        _id: "64f75285ef8f606073e9a1d1",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "amboangy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9142633,
          lng: 48.26174959999999,
        },
        _id: "64f75285ef8f606073e9a1d2",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.934141,
          lng: 48.2891359,
        },
        _id: "64f75285ef8f606073e9a1d3",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "andavaranga II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9363407,
          lng: 48.2294017,
        },
        _id: "64f75285ef8f606073e9a1d4",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "ambatolahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9664743,
          lng: 48.2841556,
        },
        _id: "64f75285ef8f606073e9a1d5",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "andonabe sud",
        fokontany: "andavaranga I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0297764,
          lng: 47.8946198,
        },
        _id: "64f75285ef8f606073e9a1d6",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambalapaiso II",
        fokontany: "ambalapaiso II mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.966667,
          lng: 47.9166669,
        },
        _id: "64f75285ef8f606073e9a1d7",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambalapaiso II",
        fokontany: "ambohimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9894231,
          lng: 47.9045093,
        },
        _id: "64f75285ef8f606073e9a1d8",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambalapaiso II",
        fokontany: "ambatomitsangana I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.011755,
          lng: 47.906982,
        },
        _id: "64f75285ef8f606073e9a1d9",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambalapaiso II",
        fokontany: "ambatomitsangana II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0415511,
          lng: 47.8773175,
        },
        _id: "64f75285ef8f606073e9a1da",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambalapaiso II",
        fokontany: "ambatomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9518528,
          lng: 47.7834888,
        },
        _id: "64f75285ef8f606073e9a1db",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambodivoangy",
        fokontany: "ambodivoangy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8741253,
          lng: 47.813101,
        },
        _id: "64f75285ef8f606073e9a1dc",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambodivoangy",
        fokontany: "ambinanindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9393908,
          lng: 47.7736217,
        },
        _id: "64f75285ef8f606073e9a1dd",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambodivoangy",
        fokontany: "ambodinonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.983504,
          lng: 47.7933577,
        },
        _id: "64f75285ef8f606073e9a1de",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambodivoangy",
        fokontany: "ampasimadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9916408,
          lng: 47.8377905,
        },
        _id: "64f75285ef8f606073e9a1df",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambodivoangy",
        fokontany: "marosanjo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0031723,
          lng: 47.778555,
        },
        _id: "64f75285ef8f606073e9a1e0",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambodivoangy",
        fokontany: "toakasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0410718,
          lng: 47.7933577,
        },
        _id: "64f75285ef8f606073e9a1e1",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambodivoangy",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.93318,
          lng: 47.9758489,
        },
        _id: "64f75285ef8f606073e9a1e2",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambohimilanja",
        fokontany: "ambohimilanja",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8243283,
          lng: 47.95645940000001,
        },
        _id: "64f75285ef8f606073e9a1e3",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambohimilanja",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8259108,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e9a1e4",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambohimilanja",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8928627,
          lng: 48.0109374,
        },
        _id: "64f75285ef8f606073e9a1e5",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambohimilanja",
        fokontany: "ambataria",
        __v: 0,
      },
      {
        coords: {
          lat: -19.882488,
          lng: 47.9416114,
        },
        _id: "64f75285ef8f606073e9a1e6",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambohimilanja",
        fokontany: "ambohimiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9123521,
          lng: 48.0035053,
        },
        _id: "64f75285ef8f606073e9a1e7",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambohimilanja",
        fokontany: "ambalafandrana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9364218,
          lng: 47.8624914,
        },
        _id: "64f75285ef8f606073e9a1e8",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambohimilanja",
        fokontany: "sahanavo I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9559748,
          lng: 47.9465602,
        },
        _id: "64f75285ef8f606073e9a1e9",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambohimilanja",
        fokontany: "ambodiara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9640568,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e9a1ea",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambohimilanja",
        fokontany: "ambohimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9848035,
          lng: 47.9465602,
        },
        _id: "64f75285ef8f606073e9a1eb",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambohimilanja",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0040242,
          lng: 47.9465602,
        },
        _id: "64f75285ef8f606073e9a1ec",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambohimilanja",
        fokontany: "maroariana I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0095762,
          lng: 47.9242939,
        },
        _id: "64f75285ef8f606073e9a1ed",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambohimilanja",
        fokontany: "mandroalina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0160063,
          lng: 47.9713115,
        },
        _id: "64f75285ef8f606073e9a1ee",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambohimilanja",
        fokontany: "maroariana III",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1053588,
          lng: 47.7834888,
        },
        _id: "64f75285ef8f606073e9a1ef",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambatofisaka II",
        fokontany: "ambatofisaka II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0367195,
          lng: 47.9267674,
        },
        _id: "64f75285ef8f606073e9a1f0",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambatofisaka II",
        fokontany: "ambinanimangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0588168,
          lng: 47.93666289999999,
        },
        _id: "64f75285ef8f606073e9a1f1",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambatofisaka II",
        fokontany: "anosy sandranamby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0811633,
          lng: 47.9391371,
        },
        _id: "64f75285ef8f606073e9a1f2",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambatofisaka II",
        fokontany: "ambalafarihy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0674847,
          lng: 47.867433,
        },
        _id: "64f75285ef8f606073e9a1f3",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambatofisaka II",
        fokontany: "vohilambo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0876436,
          lng: 47.8377905,
        },
        _id: "64f75285ef8f606073e9a1f4",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambatofisaka II",
        fokontany: "ampasimazava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0674433,
          lng: 47.7686889,
        },
        _id: "64f75285ef8f606073e9a1f5",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambatofisaka II",
        fokontany: "anosiato I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1321685,
          lng: 47.7440315,
        },
        _id: "64f75285ef8f606073e9a1f6",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambatofisaka II",
        fokontany: "ankiboka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1164506,
          lng: 47.8377905,
        },
        _id: "64f75285ef8f606073e9a1f7",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambatofisaka II",
        fokontany: "sahanambo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1155103,
          lng: 47.867433,
        },
        _id: "64f75285ef8f606073e9a1f8",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambatofisaka II",
        fokontany: "anakalotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0962991,
          lng: 47.867433,
        },
        _id: "64f75285ef8f606073e9a1f9",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambatofisaka II",
        fokontany: "ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1003881,
          lng: 47.8896757,
        },
        _id: "64f75285ef8f606073e9a1fa",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambatofisaka II",
        fokontany: "antanjomanga I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1246591,
          lng: 47.9812152,
        },
        _id: "64f75285ef8f606073e9a1fb",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "androrangavola",
        fokontany: "androrangavola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0517839,
          lng: 48.0035053,
        },
        _id: "64f75285ef8f606073e9a1fc",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "androrangavola",
        fokontany: "morafeno I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0794487,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e9a1fd",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "androrangavola",
        fokontany: "ambinanindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0907758,
          lng: 47.9391371,
        },
        _id: "64f75285ef8f606073e9a1fe",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "androrangavola",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0999044,
          lng: 47.9539844,
        },
        _id: "64f75285ef8f606073e9a1ff",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "androrangavola",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1328317,
          lng: 47.9267674,
        },
        _id: "64f75285ef8f606073e9a200",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "androrangavola",
        fokontany: "maromitety I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1193751,
          lng: 47.9465602,
        },
        _id: "64f75285ef8f606073e9a201",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "androrangavola",
        fokontany: "maromitety II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1337811,
          lng: 47.897092,
        },
        _id: "64f75285ef8f606073e9a202",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "androrangavola",
        fokontany: "ambohitranala",
        __v: 0,
      },
      {
        coords: {
          lat: -20.116667,
          lng: 48.033333,
        },
        _id: "64f75285ef8f606073e9a203",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "androrangavola",
        fokontany: "sahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2169229,
          lng: 47.9020367,
        },
        _id: "64f75285ef8f606073e9a204",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "anosiarivo I",
        fokontany: "anosiarivo I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.166667,
          lng: 47.833333,
        },
        _id: "64f75285ef8f606073e9a205",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "anosiarivo I",
        fokontany: "beanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1827592,
          lng: 47.867433,
        },
        _id: "64f75285ef8f606073e9a206",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "anosiarivo I",
        fokontany: "maintimbato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2070086,
          lng: 47.86002080000001,
        },
        _id: "64f75285ef8f606073e9a207",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "anosiarivo I",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2269932,
          lng: 47.88720379999999,
        },
        _id: "64f75285ef8f606073e9a208",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "anosiarivo I",
        fokontany: "ambalapaiso I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2333329,
          lng: 47.9,
        },
        _id: "64f75285ef8f606073e9a209",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "anosiarivo I",
        fokontany: "ambalasavoka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7910565,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e9a20a",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "amboasary",
        fokontany: "amboasary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7570801,
          lng: 47.9459416,
        },
        _id: "64f75285ef8f606073e9a20b",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "amboasary",
        fokontany: "mandroalina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7734618,
          lng: 47.9465602,
        },
        _id: "64f75285ef8f606073e9a20c",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "amboasary",
        fokontany: "andranambomaro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7735326,
          lng: 47.9886442,
        },
        _id: "64f75285ef8f606073e9a20d",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "amboasary",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7758289,
          lng: 48.01341499999999,
        },
        _id: "64f75285ef8f606073e9a20e",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "amboasary",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7791469,
          lng: 47.9663604,
        },
        _id: "64f75285ef8f606073e9a20f",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "amboasary",
        fokontany: "tolandravina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7902442,
          lng: 48.01341499999999,
        },
        _id: "64f75285ef8f606073e9a210",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "amboasary",
        fokontany: "bevahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8058112,
          lng: 48.0258046,
        },
        _id: "64f75285ef8f606073e9a211",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "amboasary",
        fokontany: "mahavangana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8076962,
          lng: 47.9737873,
        },
        _id: "64f75285ef8f606073e9a212",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "amboasary",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8153457,
          lng: 47.98369150000001,
        },
        _id: "64f75285ef8f606073e9a213",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "amboasary",
        fokontany: "marobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8331386,
          lng: 47.9787391,
        },
        _id: "64f75285ef8f606073e9a214",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "amboasary",
        fokontany: "salehy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8320714,
          lng: 48.0084599,
        },
        _id: "64f75285ef8f606073e9a215",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "amboasary",
        fokontany: "ampasimazava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8484457,
          lng: 47.99855119999999,
        },
        _id: "64f75285ef8f606073e9a216",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "amboasary",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8126976,
          lng: 48.1002024,
        },
        _id: "64f75285ef8f606073e9a217",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "lohavanana",
        fokontany: "lohavanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7374235,
          lng: 48.0555514,
        },
        _id: "64f75285ef8f606073e9a218",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "lohavanana",
        fokontany: "sandrangivy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.769928,
          lng: 48.0431549,
        },
        _id: "64f75285ef8f606073e9a219",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "lohavanana",
        fokontany: "andonabe ranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8059163,
          lng: 48.1101299,
        },
        _id: "64f75285ef8f606073e9a21a",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "lohavanana",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.834233,
          lng: 48.12502449999999,
        },
        _id: "64f75285ef8f606073e9a21b",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "lohavanana",
        fokontany: "ambohimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8398022,
          lng: 48.0605108,
        },
        _id: "64f75285ef8f606073e9a21c",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "lohavanana",
        fokontany: "androrangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.866667,
          lng: 48.016667,
        },
        _id: "64f75285ef8f606073e9a21d",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "lohavanana",
        fokontany: "ambodirotra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3601889,
          lng: 47.80843,
        },
        _id: "64f75285ef8f606073e9a21e",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambodinonoka",
        fokontany: "ambodinonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2740458,
          lng: 47.7046036,
        },
        _id: "64f75285ef8f606073e9a21f",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambodinonoka",
        fokontany: "sakavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.315263,
          lng: 47.8279133,
        },
        _id: "64f75285ef8f606073e9a220",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambodinonoka",
        fokontany: "lapatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3245167,
          lng: 47.7292426,
        },
        _id: "64f75285ef8f606073e9a221",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambodinonoka",
        fokontany: "ambodivoara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3294588,
          lng: 47.835321,
        },
        _id: "64f75285ef8f606073e9a222",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambodinonoka",
        fokontany: "mazavalanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3691712,
          lng: 47.7341718,
        },
        _id: "64f75285ef8f606073e9a223",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambodinonoka",
        fokontany: "lavakianja",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3448986,
          lng: 47.8550799,
        },
        _id: "64f75285ef8f606073e9a224",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambodinonoka",
        fokontany: "andranovory",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3846201,
          lng: 47.8106327,
        },
        _id: "64f75285ef8f606073e9a225",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambodinonoka",
        fokontany: "marojiro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4243103,
          lng: 47.8229754,
        },
        _id: "64f75285ef8f606073e9a226",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambodinonoka",
        fokontany: "ankazomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3716314,
          lng: 47.872375,
        },
        _id: "64f75285ef8f606073e9a227",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambodinonoka",
        fokontany: "madiofasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.421778,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e9a228",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "ambodinonoka",
        fokontany: "andranovakoana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.266667,
          lng: 47.84999999999999,
        },
        _id: "64f75285ef8f606073e9a229",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "sahakevo",
        fokontany: "sahakevo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1888406,
          lng: 47.7736217,
        },
        _id: "64f75285ef8f606073e9a22a",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "sahakevo",
        fokontany: "vohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2267928,
          lng: 47.78842299999999,
        },
        _id: "64f75285ef8f606073e9a22b",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "sahakevo",
        fokontany: "manakana II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2312555,
          lng: 47.8526097,
        },
        _id: "64f75285ef8f606073e9a22c",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "sahakevo",
        fokontany: "tsingarivary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2871254,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e9a22d",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "sahakevo",
        fokontany: "ampasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2795061,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e9a22e",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "sahakevo",
        fokontany: "vatanambora",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2625375,
          lng: 47.8773175,
        },
        _id: "64f75285ef8f606073e9a22f",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "sahakevo",
        fokontany: "ambodirafia",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2714551,
          lng: 47.7933577,
        },
        _id: "64f75285ef8f606073e9a230",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "sahakevo",
        fokontany: "tombohantrova",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3103658,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e9a231",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "sahakevo",
        fokontany: "ambatomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3139504,
          lng: 47.872375,
        },
        _id: "64f75285ef8f606073e9a232",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "sahakevo",
        fokontany: "ambodimamanona",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3448268,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e9a233",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "sahakevo",
        fokontany: "manirikina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3757204,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e9a234",
        province: "toamasina",
        region: "atsinanana",
        district: "marolambo",
        commune: "sahakevo",
        fokontany: "andambinana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.100398,
          lng: 49.3754694,
        },
        _id: "64f75285ef8f606073e9a235",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "toamasina suburbaine",
        fokontany: "ambalamanasy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0221651,
          lng: 49.23853390000001,
        },
        _id: "64f75285ef8f606073e9a236",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "toamasina suburbaine",
        fokontany: "ampasimazava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0279089,
          lng: 49.2993541,
        },
        _id: "64f75285ef8f606073e9a237",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "toamasina suburbaine",
        fokontany: "sahandahatra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0162648,
          lng: 49.3297882,
        },
        _id: "64f75285ef8f606073e9a238",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "toamasina suburbaine",
        fokontany: "ampangarinantelo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0427152,
          lng: 49.3196417,
        },
        _id: "64f75285ef8f606073e9a239",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "toamasina suburbaine",
        fokontany: "ambodimandresy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0604695,
          lng: 49.2942833,
        },
        _id: "64f75285ef8f606073e9a23a",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "toamasina suburbaine",
        fokontany: "antseranambe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0643657,
          lng: 49.2334685,
        },
        _id: "64f75285ef8f606073e9a23b",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "toamasina suburbaine",
        fokontany: "anjahamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0601832,
          lng: 49.1980228,
        },
        _id: "64f75285ef8f606073e9a23c",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "toamasina suburbaine",
        fokontany: "ranomena II",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1043083,
          lng: 49.3145691,
        },
        _id: "64f75285ef8f606073e9a23d",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "toamasina suburbaine",
        fokontany: "ampihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1413971,
          lng: 49.2892129,
        },
        _id: "64f75285ef8f606073e9a23e",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "toamasina suburbaine",
        fokontany: "vohibolo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1339715,
          lng: 49.3551622,
        },
        _id: "64f75285ef8f606073e9a23f",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "toamasina suburbaine",
        fokontany: "ambodisaina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1675331,
          lng: 49.3348621,
        },
        _id: "64f75285ef8f606073e9a240",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "toamasina suburbaine",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.01371,
          lng: 49.402111,
        },
        _id: "64f75285ef8f606073e9a241",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antetezambaro",
        fokontany: "antetezambaro",
        __v: 0,
      },
      {
        coords: {
          lat: -17.893119,
          lng: 49.3399364,
        },
        _id: "64f75285ef8f606073e9a242",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antetezambaro",
        fokontany: "vohitrandiana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.893009,
          lng: 49.444851,
        },
        _id: "64f75285ef8f606073e9a243",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antetezambaro",
        fokontany: "vohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8788791,
          lng: 49.3602383,
        },
        _id: "64f75285ef8f606073e9a244",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antetezambaro",
        fokontany: "sahantaha",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9135044,
          lng: 49.4211865,
        },
        _id: "64f75285ef8f606073e9a245",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antetezambaro",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9124535,
          lng: 49.3399364,
        },
        _id: "64f75285ef8f606073e9a246",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antetezambaro",
        fokontany: "ampasina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.922657,
          lng: 49.3805473,
        },
        _id: "64f75285ef8f606073e9a247",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antetezambaro",
        fokontany: "ambodisatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9557762,
          lng: 49.41610499999999,
        },
        _id: "64f75285ef8f606073e9a248",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antetezambaro",
        fokontany: "ambodiatafana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9750853,
          lng: 49.3678533,
        },
        _id: "64f75285ef8f606073e9a249",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antetezambaro",
        fokontany: "antanetilava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.974025,
          lng: 49.3348621,
        },
        _id: "64f75285ef8f606073e9a24a",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antetezambaro",
        fokontany: "marotandrazana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0117192,
          lng: 49.3500865,
        },
        _id: "64f75285ef8f606073e9a24b",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antetezambaro",
        fokontany: "vohilava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0529866,
          lng: 49.3602383,
        },
        _id: "64f75285ef8f606073e9a24c",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antetezambaro",
        fokontany: "ambonivato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0510081,
          lng: 49.3907044,
        },
        _id: "64f75285ef8f606073e9a24d",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antetezambaro",
        fokontany: "vohidrotra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0774815,
          lng: 49.3805473,
        },
        _id: "64f75285ef8f606073e9a24e",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antetezambaro",
        fokontany: "analamalotra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.285879,
          lng: 49.121841,
        },
        _id: "64f75285ef8f606073e9a24f",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "fanandrana",
        fokontany: "fanandrana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1365286,
          lng: 49.2132111,
        },
        _id: "64f75285ef8f606073e9a250",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "fanandrana",
        fokontany: "amboasana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1803753,
          lng: 49.2334685,
        },
        _id: "64f75285ef8f606073e9a251",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "fanandrana",
        fokontany: "ambodikily",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2211811,
          lng: 49.2511995,
        },
        _id: "64f75285ef8f606073e9a252",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "fanandrana",
        fokontany: "ampasinambo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2185877,
          lng: 49.24106680000001,
        },
        _id: "64f75285ef8f606073e9a253",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "fanandrana",
        fokontany: "vohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1959069,
          lng: 49.2942833,
        },
        _id: "64f75285ef8f606073e9a254",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "fanandrana",
        fokontany: "antananambo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2386257,
          lng: 49.1777779,
        },
        _id: "64f75285ef8f606073e9a255",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "fanandrana",
        fokontany: "zafindramanoro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2832417,
          lng: 49.23853390000001,
        },
        _id: "64f75285ef8f606073e9a256",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "fanandrana",
        fokontany: "ambatorao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2825062,
          lng: 49.1980228,
        },
        _id: "64f75285ef8f606073e9a257",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "fanandrana",
        fokontany: "antsirakambo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3422319,
          lng: 49.22333889999999,
        },
        _id: "64f75285ef8f606073e9a258",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "fanandrana",
        fokontany: "ambodibonara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1442811,
          lng: 49.3957836,
        },
        _id: "64f75285ef8f606073e9a259",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ambodiriana",
        fokontany: "ambodiriana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8650732,
          lng: 49.2284034,
        },
        _id: "64f75285ef8f606073e9a25a",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ambodiriana",
        fokontany: "antaranarina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8635998,
          lng: 49.2993541,
        },
        _id: "64f75285ef8f606073e9a25b",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ambodiriana",
        fokontany: "ampasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8823813,
          lng: 49.2588002,
        },
        _id: "64f75285ef8f606073e9a25c",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ambodiriana",
        fokontany: "analamangahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8996957,
          lng: 49.2892129,
        },
        _id: "64f75285ef8f606073e9a25d",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ambodiriana",
        fokontany: "ambodivoapaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9036917,
          lng: 49.2284034,
        },
        _id: "64f75285ef8f606073e9a25e",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ambodiriana",
        fokontany: "andratambe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9352195,
          lng: 49.23853390000001,
        },
        _id: "64f75285ef8f606073e9a25f",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ambodiriana",
        fokontany: "fontsimavo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9809529,
          lng: 49.2284034,
        },
        _id: "64f75285ef8f606073e9a260",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ambodiriana",
        fokontany: "antandrokaomby",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9831201,
          lng: 49.2942833,
        },
        _id: "64f75285ef8f606073e9a261",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ambodiriana",
        fokontany: "sahasandana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3968944,
          lng: 49.1727178,
        },
        _id: "64f75285ef8f606073e9a262",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampasimadinika manambolo",
        fokontany: "ambarimilambana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3759423,
          lng: 49.0918169,
        },
        _id: "64f75285ef8f606073e9a263",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampasimadinika manambolo",
        fokontany: "ambodikininina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4027768,
          lng: 49.2892129,
        },
        _id: "64f75285ef8f606073e9a264",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampasimadinika manambolo",
        fokontany: "mahatsara II",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4178368,
          lng: 49.2537329,
        },
        _id: "64f75285ef8f606073e9a265",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampasimadinika manambolo",
        fokontany: "vohimanasa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.446994,
          lng: 49.1980228,
        },
        _id: "64f75285ef8f606073e9a266",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampasimadinika manambolo",
        fokontany: "antsirakambo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4451803,
          lng: 49.2284034,
        },
        _id: "64f75285ef8f606073e9a267",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampasimadinika manambolo",
        fokontany: "tanambao tarosana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1442811,
          lng: 49.3957836,
        },
        _id: "64f75285ef8f606073e9a268",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "amboditandroroho",
        fokontany: "amboditandroroho",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2149814,
          lng: 49.3500865,
        },
        _id: "64f75285ef8f606073e9a269",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "amboditandroroho",
        fokontany: "ambokarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.216201,
          lng: 49.3691226,
        },
        _id: "64f75285ef8f606073e9a26a",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "amboditandroroho",
        fokontany: "ambodisaina ivondro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2485368,
          lng: 49.3297882,
        },
        _id: "64f75285ef8f606073e9a26b",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "amboditandroroho",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2749939,
          lng: 49.3196417,
        },
        _id: "64f75285ef8f606073e9a26c",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "amboditandroroho",
        fokontany: "antsirakimetatra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.277464,
          lng: 49.3577002,
        },
        _id: "64f75285ef8f606073e9a26d",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "amboditandroroho",
        fokontany: "ambodisiny",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3111325,
          lng: 49.309497,
        },
        _id: "64f75285ef8f606073e9a26e",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "amboditandroroho",
        fokontany: "ambavarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3189364,
          lng: 49.3399364,
        },
        _id: "64f75285ef8f606073e9a26f",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "amboditandroroho",
        fokontany: "ambalasaona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3517393,
          lng: 49.2790736,
        },
        _id: "64f75285ef8f606073e9a270",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "amboditandroroho",
        fokontany: "ampasimazava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3737176,
          lng: 49.2892129,
        },
        _id: "64f75285ef8f606073e9a271",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "amboditandroroho",
        fokontany: "ambinany manambolo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.408199,
          lng: 49.32571,
        },
        _id: "64f75285ef8f606073e9a272",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "amboditandroroho",
        fokontany: "ankarefo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6814255,
          lng: 49.514001,
        },
        _id: "64f75285ef8f606073e9a273",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "mahavelona (foulpointe)",
        fokontany: "foulpointe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.65958,
          lng: 49.411024,
        },
        _id: "64f75285ef8f606073e9a274",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "mahavelona (foulpointe)",
        fokontany: "ambodivoarabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6742771,
          lng: 49.4313508,
        },
        _id: "64f75285ef8f606073e9a275",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "mahavelona (foulpointe)",
        fokontany: "bongabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6675231,
          lng: 49.4821986,
        },
        _id: "64f75285ef8f606073e9a276",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "mahavelona (foulpointe)",
        fokontany: "marofaria",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7144098,
          lng: 49.4567691,
        },
        _id: "64f75285ef8f606073e9a277",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "mahavelona (foulpointe)",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7570939,
          lng: 49.4923735,
        },
        _id: "64f75285ef8f606073e9a278",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "mahavelona (foulpointe)",
        fokontany: "antaratasy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7760067,
          lng: 49.4516846,
        },
        _id: "64f75285ef8f606073e9a279",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "mahavelona (foulpointe)",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7907395,
          lng: 49.4720255,
        },
        _id: "64f75285ef8f606073e9a27a",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "mahavelona (foulpointe)",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8345999,
          lng: 49.3957836,
        },
        _id: "64f75285ef8f606073e9a27b",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "mahavelona (foulpointe)",
        fokontany: "saranaina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8447547,
          lng: 49.4364335,
        },
        _id: "64f75285ef8f606073e9a27c",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "mahavelona (foulpointe)",
        fokontany: "vohitamboro",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8732817,
          lng: 49.3957836,
        },
        _id: "64f75285ef8f606073e9a27d",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "mahavelona (foulpointe)",
        fokontany: "antenina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9774999,
          lng: 49.0817124,
        },
        _id: "64f75285ef8f606073e9a27e",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "sahambala",
        fokontany: "sahambala",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8740028,
          lng: 49.0918169,
        },
        _id: "64f75285ef8f606073e9a27f",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "sahambala",
        fokontany: "antilikambo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8974206,
          lng: 49.127197,
        },
        _id: "64f75285ef8f606073e9a280",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "sahambala",
        fokontany: "andratambazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9164584,
          lng: 49.0312164,
        },
        _id: "64f75285ef8f606073e9a281",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "sahambala",
        fokontany: "andratanaomby",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9050971,
          lng: 49.1575402,
        },
        _id: "64f75285ef8f606073e9a282",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "sahambala",
        fokontany: "manamby",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9031207,
          lng: 49.1878994,
        },
        _id: "64f75285ef8f606073e9a283",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "sahambala",
        fokontany: "ambodirafia",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9460022,
          lng: 49.0716096,
        },
        _id: "64f75285ef8f606073e9a284",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "sahambala",
        fokontany: "ambodiara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9573762,
          lng: 49.1448952,
        },
        _id: "64f75285ef8f606073e9a285",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "sahambala",
        fokontany: "antenina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9676377,
          lng: 49.1853689,
        },
        _id: "64f75285ef8f606073e9a286",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "sahambala",
        fokontany: "manamandrozana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9900035,
          lng: 49.1878994,
        },
        _id: "64f75285ef8f606073e9a287",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "sahambala",
        fokontany: "ambalatenina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0019358,
          lng: 49.1019233,
        },
        _id: "64f75285ef8f606073e9a288",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "sahambala",
        fokontany: "sahavongo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0189735,
          lng: 49.1878994,
        },
        _id: "64f75285ef8f606073e9a289",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "sahambala",
        fokontany: "ambalakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.092409,
          lng: 49.14727000000001,
        },
        _id: "64f75285ef8f606073e9a28a",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ambodilazana",
        fokontany: "ambodilazana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0579164,
          lng: 49.1322531,
        },
        _id: "64f75285ef8f606073e9a28b",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ambodilazana",
        fokontany: "ampitarafana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0861999,
          lng: 49.0918169,
        },
        _id: "64f75285ef8f606073e9a28c",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ambodilazana",
        fokontany: "ambodimanjatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0758627,
          lng: 49.0514094,
        },
        _id: "64f75285ef8f606073e9a28d",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ambodilazana",
        fokontany: "ambodinonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.120859,
          lng: 49.075119,
        },
        _id: "64f75285ef8f606073e9a28e",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ambodilazana",
        fokontany: "ankosibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1916506,
          lng: 49.0514094,
        },
        _id: "64f75285ef8f606073e9a28f",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ambodilazana",
        fokontany: "amboditeza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0927082,
          lng: 49.1929609,
        },
        _id: "64f75285ef8f606073e9a290",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ambodilazana",
        fokontany: "andranompony",
        __v: 0,
      },
      {
        coords: {
          lat: -18.126189,
          lng: 49.1727178,
        },
        _id: "64f75285ef8f606073e9a291",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ambodilazana",
        fokontany: "ambodimanga volobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1702615,
          lng: 49.1373096,
        },
        _id: "64f75285ef8f606073e9a292",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ambodilazana",
        fokontany: "lomboka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1806294,
          lng: 49.1777779,
        },
        _id: "64f75285ef8f606073e9a293",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ambodilazana",
        fokontany: "sandrangato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2124369,
          lng: 49.1322531,
        },
        _id: "64f75285ef8f606073e9a294",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ambodilazana",
        fokontany: "andasibe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6361535,
          lng: 49.3773735,
        },
        _id: "64f75285ef8f606073e9a295",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampasimbe onibe",
        fokontany: "ampasimbe onibe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5840506,
          lng: 49.3399364,
        },
        _id: "64f75285ef8f606073e9a296",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampasimbe onibe",
        fokontany: "vohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5948383,
          lng: 49.4618542,
        },
        _id: "64f75285ef8f606073e9a297",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampasimbe onibe",
        fokontany: "ambalahasina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5775742,
          lng: 49.2942833,
        },
        _id: "64f75285ef8f606073e9a298",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampasimbe onibe",
        fokontany: "maromandia",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6012352,
          lng: 49.37039189999999,
        },
        _id: "64f75285ef8f606073e9a299",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampasimbe onibe",
        fokontany: "ambodiampalibe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6161576,
          lng: 49.2942833,
        },
        _id: "64f75285ef8f606073e9a29a",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampasimbe onibe",
        fokontany: "mahatsara II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6331108,
          lng: 49.4211865,
        },
        _id: "64f75285ef8f606073e9a29b",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampasimbe onibe",
        fokontany: "hotsika",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6238304,
          lng: 49.4618542,
        },
        _id: "64f75285ef8f606073e9a29c",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampasimbe onibe",
        fokontany: "mahatsara I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6486648,
          lng: 49.2892129,
        },
        _id: "64f75285ef8f606073e9a29d",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampasimbe onibe",
        fokontany: "vohibato",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6555995,
          lng: 49.3754694,
        },
        _id: "64f75285ef8f606073e9a29e",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampasimbe onibe",
        fokontany: "sahafamafa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6679622,
          lng: 49.2892129,
        },
        _id: "64f75285ef8f606073e9a29f",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampasimbe onibe",
        fokontany: "antenina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7624701,
          lng: 49.22333889999999,
        },
        _id: "64f75285ef8f606073e9a2a0",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "mangabe",
        fokontany: "mangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7081509,
          lng: 49.2182748,
        },
        _id: "64f75285ef8f606073e9a2a1",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "mangabe",
        fokontany: "ambodinonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7228602,
          lng: 49.23853390000001,
        },
        _id: "64f75285ef8f606073e9a2a2",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "mangabe",
        fokontany: "mahanoro",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7629712,
          lng: 49.1676582,
        },
        _id: "64f75285ef8f606073e9a2a3",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "mangabe",
        fokontany: "ambinanisahavahy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7878611,
          lng: 49.2284034,
        },
        _id: "64f75285ef8f606073e9a2a4",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "mangabe",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8066327,
          lng: 49.1878994,
        },
        _id: "64f75285ef8f606073e9a2a5",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "mangabe",
        fokontany: "tanamena",
        __v: 0,
      },
      {
        coords: {
          lat: -17.832562,
          lng: 49.2334685,
        },
        _id: "64f75285ef8f606073e9a2a6",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "mangabe",
        fokontany: "andasibe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8192823,
          lng: 49.1423666,
        },
        _id: "64f75285ef8f606073e9a2a7",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "amporoforo",
        fokontany: "amporoforo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7878131,
          lng: 49.1322531,
        },
        _id: "64f75285ef8f606073e9a2a8",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "amporoforo",
        fokontany: "ankadimpary",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8157353,
          lng: 49.147424,
        },
        _id: "64f75285ef8f606073e9a2a9",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "amporoforo",
        fokontany: "anjirovato",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8126302,
          lng: 49.09686989999999,
        },
        _id: "64f75285ef8f606073e9a2aa",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "amporoforo",
        fokontany: "ambodiampaly",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8405536,
          lng: 49.1120314,
        },
        _id: "64f75285ef8f606073e9a2ab",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "amporoforo",
        fokontany: "amboniampanga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8169814,
          lng: 48.9807651,
        },
        _id: "64f75285ef8f606073e9a2ac",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antenina",
        fokontany: "antenina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7466408,
          lng: 49.0261692,
        },
        _id: "64f75285ef8f606073e9a2ad",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antenina",
        fokontany: "bongabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7548176,
          lng: 49.09686989999999,
        },
        _id: "64f75285ef8f606073e9a2ae",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antenina",
        fokontany: "ifasina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.766667,
          lng: 48.9,
        },
        _id: "64f75285ef8f606073e9a2af",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antenina",
        fokontany: "mitanonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8228198,
          lng: 48.8900659,
        },
        _id: "64f75285ef8f606073e9a2b0",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antenina",
        fokontany: "fotsialanana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7774993,
          lng: 48.99589580000001,
        },
        _id: "64f75285ef8f606073e9a2b1",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antenina",
        fokontany: "manambato",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8272032,
          lng: 49.0211225,
        },
        _id: "64f75285ef8f606073e9a2b2",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "antenina",
        fokontany: "sahavazina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7665289,
          lng: 49.375969,
        },
        _id: "64f75285ef8f606073e9a2b3",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andondabe",
        fokontany: "andondabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6911886,
          lng: 49.3729306,
        },
        _id: "64f75285ef8f606073e9a2b4",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andondabe",
        fokontany: "ambalatenina nord",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6902321,
          lng: 49.3399364,
        },
        _id: "64f75285ef8f606073e9a2b5",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andondabe",
        fokontany: "ambodiampaly mahony",
        __v: 0,
      },
      {
        coords: {
          lat: -17.689043,
          lng: 49.2866779,
        },
        _id: "64f75285ef8f606073e9a2b6",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andondabe",
        fokontany: "ampasimbazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7186042,
          lng: 49.3957836,
        },
        _id: "64f75285ef8f606073e9a2b7",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andondabe",
        fokontany: "analabemazava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7288594,
          lng: 49.3399364,
        },
        _id: "64f75285ef8f606073e9a2b8",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andondabe",
        fokontany: "andapa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7603675,
          lng: 49.3500865,
        },
        _id: "64f75285ef8f606073e9a2b9",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andondabe",
        fokontany: "ambodiriana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7776271,
          lng: 49.3805473,
        },
        _id: "64f75285ef8f606073e9a2ba",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andondabe",
        fokontany: "ambodivoanio",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7760747,
          lng: 49.4034033,
        },
        _id: "64f75285ef8f606073e9a2bb",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andondabe",
        fokontany: "tolohofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.771468,
          lng: 49.4237274,
        },
        _id: "64f75285ef8f606073e9a2bc",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andondabe",
        fokontany: "ambodihazomamy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7868163,
          lng: 49.3399364,
        },
        _id: "64f75285ef8f606073e9a2bd",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andondabe",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7949643,
          lng: 49.3145691,
        },
        _id: "64f75285ef8f606073e9a2be",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andondabe",
        fokontany: "rokalava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8040874,
          lng: 49.37039189999999,
        },
        _id: "64f75285ef8f606073e9a2bf",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andondabe",
        fokontany: "ambalatenina sud",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8203834,
          lng: 49.3196417,
        },
        _id: "64f75285ef8f606073e9a2c0",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andondabe",
        fokontany: "antsiradranobe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8641212,
          lng: 49.3399364,
        },
        _id: "64f75285ef8f606073e9a2c1",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andondabe",
        fokontany: "andilambola I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7512668,
          lng: 49.2942833,
        },
        _id: "64f75285ef8f606073e9a2c2",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampisokina",
        fokontany: "ampisokina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6923128,
          lng: 49.309497,
        },
        _id: "64f75285ef8f606073e9a2c3",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampisokina",
        fokontany: "andonaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.740583,
          lng: 49.309497,
        },
        _id: "64f75285ef8f606073e9a2c4",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampisokina",
        fokontany: "andratambe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7553034,
          lng: 49.3297882,
        },
        _id: "64f75285ef8f606073e9a2c5",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampisokina",
        fokontany: "antsirabolo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7980234,
          lng: 49.268936,
        },
        _id: "64f75285ef8f606073e9a2c6",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampisokina",
        fokontany: "ambinanitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.816667,
          lng: 49.283333,
        },
        _id: "64f75285ef8f606073e9a2c7",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "ampisokina",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3004626,
          lng: 49.0615086,
        },
        _id: "64f75285ef8f606073e9a2c8",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andranobolaha",
        fokontany: "andranobolaha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.240972,
          lng: 48.9807651,
        },
        _id: "64f75285ef8f606073e9a2c9",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andranobolaha",
        fokontany: "andranovaky",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2557015,
          lng: 49.05645879999999,
        },
        _id: "64f75285ef8f606073e9a2ca",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andranobolaha",
        fokontany: "ambinany sahatsanda",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2756234,
          lng: 49.1524819,
        },
        _id: "64f75285ef8f606073e9a2cb",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andranobolaha",
        fokontany: "manolantanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2984877,
          lng: 49.147424,
        },
        _id: "64f75285ef8f606073e9a2cc",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andranobolaha",
        fokontany: "sandrantsoha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2845289,
          lng: 49.1120314,
        },
        _id: "64f75285ef8f606073e9a2cd",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andranobolaha",
        fokontany: "andapa tamboro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3409066,
          lng: 49.0312164,
        },
        _id: "64f75285ef8f606073e9a2ce",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andranobolaha",
        fokontany: "ambodibonara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3336319,
          lng: 49.1524819,
        },
        _id: "64f75285ef8f606073e9a2cf",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "andranobolaha",
        fokontany: "ampitabe iambovinany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0772563,
          lng: 48.92532060000001,
        },
        _id: "64f75285ef8f606073e9a2d0",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "fito",
        fokontany: "saviavy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9926947,
          lng: 48.8397403,
        },
        _id: "64f75285ef8f606073e9a2d1",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "fito",
        fokontany: "amboangy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0730031,
          lng: 48.8900659,
        },
        _id: "64f75285ef8f606073e9a2d2",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "fito",
        fokontany: "ambodiara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0632738,
          lng: 48.9177641,
        },
        _id: "64f75285ef8f606073e9a2d3",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "fito",
        fokontany: "tanambolamena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0517717,
          lng: 48.9479959,
        },
        _id: "64f75285ef8f606073e9a2d4",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "fito",
        fokontany: "namolazana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0692789,
          lng: 48.9505159,
        },
        _id: "64f75285ef8f606073e9a2d5",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "fito",
        fokontany: "horifatra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0603526,
          lng: 48.99085179999999,
        },
        _id: "64f75285ef8f606073e9a2d6",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "fito",
        fokontany: "satrandroy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1005653,
          lng: 49.01607629999999,
        },
        _id: "64f75285ef8f606073e9a2d7",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "fito",
        fokontany: "kitraomba",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1638117,
          lng: 48.9807651,
        },
        _id: "64f75285ef8f606073e9a2d8",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "fito",
        fokontany: "antohidava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.116667,
          lng: 48.9166669,
        },
        _id: "64f75285ef8f606073e9a2d9",
        province: "toamasina",
        region: "atsinanana",
        district: "toamasina II",
        commune: "fito",
        fokontany: "ambinanivavony",
        __v: 0,
      },
      {
        coords: {
          lat: -19.48768,
          lng: 48.570862,
        },
        _id: "64f75285ef8f606073e9a2da",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "antanambao manampontsy",
        fokontany: "antanambao manampontsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.326177,
          lng: 48.5287267,
        },
        _id: "64f75285ef8f606073e9a2db",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "antanambao manampontsy",
        fokontany: "ambinanindrano II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3492786,
          lng: 48.5512419,
        },
        _id: "64f75285ef8f606073e9a2dc",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "antanambao manampontsy",
        fokontany: "andovomena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4,
          lng: 48.55,
        },
        _id: "64f75285ef8f606073e9a2dd",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "antanambao manampontsy",
        fokontany: "betoko I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4006782,
          lng: 48.5887875,
        },
        _id: "64f75285ef8f606073e9a2de",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "antanambao manampontsy",
        fokontany: "sahanandronana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4288855,
          lng: 48.5337293,
        },
        _id: "64f75285ef8f606073e9a2df",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "antanambao manampontsy",
        fokontany: "ampasimena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4592932,
          lng: 48.57376619999999,
        },
        _id: "64f75285ef8f606073e9a2e0",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "antanambao manampontsy",
        fokontany: "ambodivandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4828317,
          lng: 48.623853,
        },
        _id: "64f75285ef8f606073e9a2e1",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "antanambao manampontsy",
        fokontany: "ampasinambo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5309961,
          lng: 48.5537441,
        },
        _id: "64f75285ef8f606073e9a2e2",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "antanambao manampontsy",
        fokontany: "ampasimadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5552362,
          lng: 48.5887875,
        },
        _id: "64f75285ef8f606073e9a2e3",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "antanambao manampontsy",
        fokontany: "analabe I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4266341,
          lng: 48.4387577,
        },
        _id: "64f75285ef8f606073e9a2e4",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "mahela",
        fokontany: "mahela",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3729951,
          lng: 48.4887224,
        },
        _id: "64f75285ef8f606073e9a2e5",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "mahela",
        fokontany: "ampasimpotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3632461,
          lng: 48.4187846,
        },
        _id: "64f75285ef8f606073e9a2e6",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "mahela",
        fokontany: "maromitety",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4122413,
          lng: 48.4737282,
        },
        _id: "64f75285ef8f606073e9a2e7",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "mahela",
        fokontany: "manaratsandry",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4334974,
          lng: 48.4287702,
        },
        _id: "64f75285ef8f606073e9a2e8",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "mahela",
        fokontany: "ambodimerana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4501696,
          lng: 48.4887224,
        },
        _id: "64f75285ef8f606073e9a2e9",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "mahela",
        fokontany: "andranonomby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4664979,
          lng: 48.4088007,
        },
        _id: "64f75285ef8f606073e9a2ea",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "mahela",
        fokontany: "anosikely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.466667,
          lng: 48.45,
        },
        _id: "64f75285ef8f606073e9a2eb",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "mahela",
        fokontany: "betoko II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.481244,
          lng: 48.5137217,
        },
        _id: "64f75285ef8f606073e9a2ec",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "mahela",
        fokontany: "ambodinonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5460287,
          lng: 48.5037206,
        },
        _id: "64f75285ef8f606073e9a2ed",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "mahela",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5162163,
          lng: 48.448747,
        },
        _id: "64f75285ef8f606073e9a2ee",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "mahela",
        fokontany: "ambatonoho",
        __v: 0,
      },
      {
        coords: {
          lat: -19.67149,
          lng: 48.4919889,
        },
        _id: "64f75285ef8f606073e9a2ef",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "saivaza",
        fokontany: "saivaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5812073,
          lng: 48.6213476,
        },
        _id: "64f75285ef8f606073e9a2f0",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "saivaza",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5917803,
          lng: 48.6376348,
        },
        _id: "64f75285ef8f606073e9a2f1",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "saivaza",
        fokontany: "ampitabe II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5957084,
          lng: 48.6213476,
        },
        _id: "64f75285ef8f606073e9a2f2",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "saivaza",
        fokontany: "namelarana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6188051,
          lng: 48.6088222,
        },
        _id: "64f75285ef8f606073e9a2f3",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "saivaza",
        fokontany: "beorana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6076329,
          lng: 48.56876,
        },
        _id: "64f75285ef8f606073e9a2f4",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "saivaza",
        fokontany: "ampitabe I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.615775,
          lng: 48.5287267,
        },
        _id: "64f75285ef8f606073e9a2f5",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "saivaza",
        fokontany: "ambodimanga I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6176711,
          lng: 48.4837239,
        },
        _id: "64f75285ef8f606073e9a2f6",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "saivaza",
        fokontany: "ambohimiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6494368,
          lng: 48.4937213,
        },
        _id: "64f75285ef8f606073e9a2f7",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "saivaza",
        fokontany: "volove",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6484472,
          lng: 48.5937955,
        },
        _id: "64f75285ef8f606073e9a2f8",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "saivaza",
        fokontany: "ambatoharanana II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6631412,
          lng: 48.4737282,
        },
        _id: "64f75285ef8f606073e9a2f9",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "saivaza",
        fokontany: "antsiraka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6718476,
          lng: 48.57376619999999,
        },
        _id: "64f75285ef8f606073e9a2fa",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "saivaza",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7020955,
          lng: 48.5437358,
        },
        _id: "64f75285ef8f606073e9a2fb",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "saivaza",
        fokontany: "ampasimaneva",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6961439,
          lng: 48.4537424,
        },
        _id: "64f75285ef8f606073e9a2fc",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "saivaza",
        fokontany: "sahananty",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7067636,
          lng: 48.5087209,
        },
        _id: "64f75285ef8f606073e9a2fd",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "saivaza",
        fokontany: "ambohimiarina I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6357006,
          lng: 48.3589086,
        },
        _id: "64f75285ef8f606073e9a2fe",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "antanandehibe",
        fokontany: "antanandehibe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5567014,
          lng: 48.4038094,
        },
        _id: "64f75285ef8f606073e9a2ff",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "antanandehibe",
        fokontany: "siranana analatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.562,
          lng: 48.3539219,
        },
        _id: "64f75285ef8f606073e9a300",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "antanandehibe",
        fokontany: "beanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5983673,
          lng: 48.3289951,
        },
        _id: "64f75285ef8f606073e9a301",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "antanandehibe",
        fokontany: "vohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5934005,
          lng: 48.448747,
        },
        _id: "64f75285ef8f606073e9a302",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "antanandehibe",
        fokontany: "amboatrotroka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6117659,
          lng: 48.3938283,
        },
        _id: "64f75285ef8f606073e9a303",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "antanandehibe",
        fokontany: "tsaravinany",
        __v: 0,
      },
      {
        coords: {
          lat: -19.65,
          lng: 48.433333,
        },
        _id: "64f75285ef8f606073e9a304",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "antanandehibe",
        fokontany: "ampitabe I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6481501,
          lng: 48.36888339999999,
        },
        _id: "64f75285ef8f606073e9a305",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "antanandehibe",
        fokontany: "tsaratampona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.768379,
          lng: 48.3643,
        },
        _id: "64f75285ef8f606073e9a306",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "manakana",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.705413,
          lng: 48.383849,
        },
        _id: "64f75285ef8f606073e9a307",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "manakana",
        fokontany: "antanambao ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7287197,
          lng: 48.36389579999999,
        },
        _id: "64f75285ef8f606073e9a308",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "manakana",
        fokontany: "ampasinambo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7395508,
          lng: 48.3339796,
        },
        _id: "64f75285ef8f606073e9a309",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "manakana",
        fokontany: "ampitabe II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7440025,
          lng: 48.383849,
        },
        _id: "64f75285ef8f606073e9a30a",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "manakana",
        fokontany: "ambohimiarina II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7865982,
          lng: 48.36389579999999,
        },
        _id: "64f75285ef8f606073e9a30b",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "manakana",
        fokontany: "ambodimampay",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7718899,
          lng: 48.3289951,
        },
        _id: "64f75285ef8f606073e9a30c",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "manakana",
        fokontany: "ambatomizaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8014005,
          lng: 48.31404449999999,
        },
        _id: "64f75285ef8f606073e9a30d",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "manakana",
        fokontany: "namahoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8058938,
          lng: 48.36389579999999,
        },
        _id: "64f75285ef8f606073e9a30e",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "manakana",
        fokontany: "betampona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8214894,
          lng: 48.3763657,
        },
        _id: "64f75285ef8f606073e9a30f",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "manakana",
        fokontany: "ampasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8155767,
          lng: 48.3215193,
        },
        _id: "64f75285ef8f606073e9a310",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "manakana",
        fokontany: "anosiarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8388259,
          lng: 48.3439498,
        },
        _id: "64f75285ef8f606073e9a311",
        province: "toamasina",
        region: "atsinanana",
        district: "antanambao manampontsy",
        commune: "manakana",
        fokontany: "ambodiara II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.99824,
          lng: 49.85258899999999,
        },
        _id: "64f75285ef8f606073e9a312",
        province: "toamasina",
        region: "analanjirofo",
        district: "sainte marie",
        commune: "sainte marie",
        fokontany: "ambodifotatra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7189018,
          lng: 50.0033677,
        },
        _id: "64f75285ef8f606073e9a313",
        province: "toamasina",
        region: "analanjirofo",
        district: "sainte marie",
        commune: "sainte marie",
        fokontany: "ambodiatafana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7528193,
          lng: 49.9828436,
        },
        _id: "64f75285ef8f606073e9a314",
        province: "toamasina",
        region: "analanjirofo",
        district: "sainte marie",
        commune: "sainte marie",
        fokontany: "ambatorao",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8060618,
          lng: 49.9623265,
        },
        _id: "64f75285ef8f606073e9a315",
        province: "toamasina",
        region: "analanjirofo",
        district: "sainte marie",
        commune: "sainte marie",
        fokontany: "ifotatra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8266516,
          lng: 49.9469432,
        },
        _id: "64f75285ef8f606073e9a316",
        province: "toamasina",
        region: "analanjirofo",
        district: "sainte marie",
        commune: "sainte marie",
        fokontany: "agnivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8689664,
          lng: 49.9418164,
        },
        _id: "64f75285ef8f606073e9a317",
        province: "toamasina",
        region: "analanjirofo",
        district: "sainte marie",
        commune: "sainte marie",
        fokontany: "sahasifotra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.87145,
          lng: 49.9110643,
        },
        _id: "64f75285ef8f606073e9a318",
        province: "toamasina",
        region: "analanjirofo",
        district: "sainte marie",
        commune: "sainte marie",
        fokontany: "loukintsy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9125865,
          lng: 49.8803281,
        },
        _id: "64f75285ef8f606073e9a319",
        province: "toamasina",
        region: "analanjirofo",
        district: "sainte marie",
        commune: "sainte marie",
        fokontany: "maromandia tafondro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.910123,
          lng: 49.9110643,
        },
        _id: "64f75285ef8f606073e9a31a",
        province: "toamasina",
        region: "analanjirofo",
        district: "sainte marie",
        commune: "sainte marie",
        fokontany: "agnafiafy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9530516,
          lng: 49.8982557,
        },
        _id: "64f75285ef8f606073e9a31b",
        province: "toamasina",
        region: "analanjirofo",
        district: "sainte marie",
        commune: "sainte marie",
        fokontany: "ankobahoba",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9458102,
          lng: 49.8880107,
        },
        _id: "64f75285ef8f606073e9a31c",
        province: "toamasina",
        region: "analanjirofo",
        district: "sainte marie",
        commune: "sainte marie",
        fokontany: "ambohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9464423,
          lng: 49.85984610000001,
        },
        _id: "64f75285ef8f606073e9a31d",
        province: "toamasina",
        region: "analanjirofo",
        district: "sainte marie",
        commune: "sainte marie",
        fokontany: "agnalaradzy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0086803,
          lng: 49.867526,
        },
        _id: "64f75285ef8f606073e9a31e",
        province: "toamasina",
        region: "analanjirofo",
        district: "sainte marie",
        commune: "sainte marie",
        fokontany: "saint joseph",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0286271,
          lng: 49.839371,
        },
        _id: "64f75285ef8f606073e9a31f",
        province: "toamasina",
        region: "analanjirofo",
        district: "sainte marie",
        commune: "sainte marie",
        fokontany: "mahavelou",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0389037,
          lng: 49.8521671,
        },
        _id: "64f75285ef8f606073e9a320",
        province: "toamasina",
        region: "analanjirofo",
        district: "sainte marie",
        commune: "sainte marie",
        fokontany: "ambodiforaha",
        __v: 0,
      },
      {
        coords: {
          lat: -17.07218,
          lng: 49.82243,
        },
        _id: "64f75285ef8f606073e9a321",
        province: "toamasina",
        region: "analanjirofo",
        district: "sainte marie",
        commune: "sainte marie",
        fokontany: "vohilava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1101959,
          lng: 49.80611409999999,
        },
        _id: "64f75285ef8f606073e9a322",
        province: "toamasina",
        region: "analanjirofo",
        district: "sainte marie",
        commune: "sainte marie",
        fokontany: "agniribe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4318454,
          lng: 49.7434881,
        },
        _id: "64f75285ef8f606073e9a323",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "maroantsetra",
        fokontany: "ankiakalava I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4120021,
          lng: 49.7345469,
        },
        _id: "64f75285ef8f606073e9a324",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "maroantsetra",
        fokontany: "amboditsoha",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4229938,
          lng: 49.7192223,
        },
        _id: "64f75285ef8f606073e9a325",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "maroantsetra",
        fokontany: "ampantsona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4219104,
          lng: 49.7307153,
        },
        _id: "64f75285ef8f606073e9a326",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "maroantsetra",
        fokontany: "mangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4189933,
          lng: 49.7447655,
        },
        _id: "64f75285ef8f606073e9a327",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "maroantsetra",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4221276,
          lng: 49.7537082,
        },
        _id: "64f75285ef8f606073e9a328",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "maroantsetra",
        fokontany: "antaimby",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4325677,
          lng: 49.73582409999999,
        },
        _id: "64f75285ef8f606073e9a329",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "maroantsetra",
        fokontany: "ankiaka andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4270709,
          lng: 49.7434881,
        },
        _id: "64f75285ef8f606073e9a32a",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "maroantsetra",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4244367,
          lng: 49.70390159999999,
        },
        _id: "64f75285ef8f606073e9a32b",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "maroantsetra",
        fokontany: "ambodivoafaho",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4298445,
          lng: 49.7562635,
        },
        _id: "64f75285ef8f606073e9a32c",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "maroantsetra",
        fokontany: "anjahamarina I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4319421,
          lng: 49.7466817,
        },
        _id: "64f75285ef8f606073e9a32d",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "maroantsetra",
        fokontany: "ankiakalava II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4364524,
          lng: 49.7537082,
        },
        _id: "64f75285ef8f606073e9a32e",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "maroantsetra",
        fokontany: "ankiakabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4365319,
          lng: 49.69369,
        },
        _id: "64f75285ef8f606073e9a32f",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "maroantsetra",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4384522,
          lng: 49.74093329999999,
        },
        _id: "64f75285ef8f606073e9a330",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "maroantsetra",
        fokontany: "androkaroka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.437175,
          lng: 49.7460429,
        },
        _id: "64f75285ef8f606073e9a331",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "maroantsetra",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4395346,
          lng: 49.7294382,
        },
        _id: "64f75285ef8f606073e9a332",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "maroantsetra",
        fokontany: "ambatomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4486593,
          lng: 49.7000721,
        },
        _id: "64f75285ef8f606073e9a333",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "maroantsetra",
        fokontany: "varingohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3790681,
          lng: 49.7505142,
        },
        _id: "64f75285ef8f606073e9a334",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjanazana",
        fokontany: "anjanazana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3278993,
          lng: 49.6656173,
        },
        _id: "64f75285ef8f606073e9a335",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjanazana",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3323175,
          lng: 49.6860325,
        },
        _id: "64f75285ef8f606073e9a336",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjanazana",
        fokontany: "madiotsifafana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3469665,
          lng: 49.6656173,
        },
        _id: "64f75285ef8f606073e9a337",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjanazana",
        fokontany: "andrakanana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3517693,
          lng: 49.6987956,
        },
        _id: "64f75285ef8f606073e9a338",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjanazana",
        fokontany: "maranina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3660367,
          lng: 49.6656173,
        },
        _id: "64f75285ef8f606073e9a339",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjanazana",
        fokontany: "sahanama",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3499851,
          lng: 49.7677638,
        },
        _id: "64f75285ef8f606073e9a33a",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjanazana",
        fokontany: "andasibe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.377106,
          lng: 49.7166685,
        },
        _id: "64f75285ef8f606073e9a33b",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjanazana",
        fokontany: "maroantsafa",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3822186,
          lng: 49.6962428,
        },
        _id: "64f75285ef8f606073e9a33c",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjanazana",
        fokontany: "sahasindro",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3646561,
          lng: 49.7473204,
        },
        _id: "64f75285ef8f606073e9a33d",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjanazana",
        fokontany: "tanambao anjanazana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3940178,
          lng: 49.739656,
        },
        _id: "64f75285ef8f606073e9a33e",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjanazana",
        fokontany: "ankadimbazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3942427,
          lng: 49.7792663,
        },
        _id: "64f75285ef8f606073e9a33f",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjanazana",
        fokontany: "ambodivoanio",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4043094,
          lng: 49.7485979,
        },
        _id: "64f75285ef8f606073e9a340",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjanazana",
        fokontany: "vohidravina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.40727,
          lng: 49.7677638,
        },
        _id: "64f75285ef8f606073e9a341",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjanazana",
        fokontany: "anteviala",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4359224,
          lng: 49.7677638,
        },
        _id: "64f75285ef8f606073e9a342",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjanazana",
        fokontany: "ambodimandrorofo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2859787,
          lng: 49.7103819,
        },
        _id: "64f75285ef8f606073e9a343",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "manambolo",
        fokontany: "manambolo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1051747,
          lng: 49.67072049999999,
        },
        _id: "64f75285ef8f606073e9a344",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "manambolo",
        fokontany: "maintimbato",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2582577,
          lng: 49.6962428,
        },
        _id: "64f75285ef8f606073e9a345",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "manambolo",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2732838,
          lng: 49.7549858,
        },
        _id: "64f75285ef8f606073e9a346",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "manambolo",
        fokontany: "andranomahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2743886,
          lng: 49.72688410000001,
        },
        _id: "64f75285ef8f606073e9a347",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "manambolo",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2875915,
          lng: 49.7549858,
        },
        _id: "64f75285ef8f606073e9a348",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "manambolo",
        fokontany: "sahavary",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2982317,
          lng: 49.7600967,
        },
        _id: "64f75285ef8f606073e9a349",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "manambolo",
        fokontany: "andongona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.31033,
          lng: 49.7166685,
        },
        _id: "64f75285ef8f606073e9a34a",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "manambolo",
        fokontany: "sahabosiny",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3191485,
          lng: 49.7575412,
        },
        _id: "64f75285ef8f606073e9a34b",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "manambolo",
        fokontany: "sahajinja",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3352784,
          lng: 49.7217761,
        },
        _id: "64f75285ef8f606073e9a34c",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "manambolo",
        fokontany: "tanambao ampano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4284375,
          lng: 49.80483539999999,
        },
        _id: "64f75285ef8f606073e9a34d",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "andranofotsy",
        fokontany: "andranofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3749774,
          lng: 49.80611409999999,
        },
        _id: "64f75285ef8f606073e9a34e",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "andranofotsy",
        fokontany: "antsangambato",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3639488,
          lng: 49.7882142,
        },
        _id: "64f75285ef8f606073e9a34f",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "andranofotsy",
        fokontany: "ankazomandroko",
        __v: 0,
      },
      {
        coords: {
          lat: -15.383776,
          lng: 49.7805445,
        },
        _id: "64f75285ef8f606073e9a350",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "andranofotsy",
        fokontany: "ivato",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4036343,
          lng: 49.80611409999999,
        },
        _id: "64f75285ef8f606073e9a351",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "andranofotsy",
        fokontany: "ambohimoko",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3931574,
          lng: 49.7990814,
        },
        _id: "64f75285ef8f606073e9a352",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "andranofotsy",
        fokontany: "ampafatra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.421253,
          lng: 49.7882142,
        },
        _id: "64f75285ef8f606073e9a353",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "andranofotsy",
        fokontany: "matsotarihina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2075949,
          lng: 49.79332789999999,
        },
        _id: "64f75285ef8f606073e9a354",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "antakotako",
        fokontany: "antakotako",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1533736,
          lng: 49.7626523,
        },
        _id: "64f75285ef8f606073e9a355",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "antakotako",
        fokontany: "ampoahantsatroka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2545202,
          lng: 49.83425339999999,
        },
        _id: "64f75285ef8f606073e9a356",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "antakotako",
        fokontany: "somisika",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2846354,
          lng: 49.818903,
        },
        _id: "64f75285ef8f606073e9a357",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "antakotako",
        fokontany: "sakatihina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.283333,
          lng: 49.816667,
        },
        _id: "64f75285ef8f606073e9a358",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "antakotako",
        fokontany: "sahambatra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3081272,
          lng: 49.839371,
        },
        _id: "64f75285ef8f606073e9a359",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "antakotako",
        fokontany: "sahameloka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3279636,
          lng: 49.8316947,
        },
        _id: "64f75285ef8f606073e9a35a",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "antakotako",
        fokontany: "sahavary",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3420138,
          lng: 49.8221007,
        },
        _id: "64f75285ef8f606073e9a35b",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "antakotako",
        fokontany: "ampoafamboay",
        __v: 0,
      },
      {
        coords: {
          lat: -15.349266,
          lng: 49.8086717,
        },
        _id: "64f75285ef8f606073e9a35c",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "antakotako",
        fokontany: "ampanobe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.353678,
          lng: 49.8291362,
        },
        _id: "64f75285ef8f606073e9a35d",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "antakotako",
        fokontany: "marofototra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3628591,
          lng: 49.816345,
        },
        _id: "64f75285ef8f606073e9a35e",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "antakotako",
        fokontany: "ankadibe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3804929,
          lng: 49.8150661,
        },
        _id: "64f75285ef8f606073e9a35f",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "antakotako",
        fokontany: "takoly",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3964383,
          lng: 49.6481485,
        },
        _id: "64f75285ef8f606073e9a360",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ankofa",
        fokontany: "ankofa",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3645451,
          lng: 49.6477598,
        },
        _id: "64f75285ef8f606073e9a361",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ankofa",
        fokontany: "benavony",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3755,
          lng: 49.62175,
        },
        _id: "64f75285ef8f606073e9a362",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ankofa",
        fokontany: "fampanambo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3788467,
          lng: 49.6477598,
        },
        _id: "64f75285ef8f606073e9a363",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ankofa",
        fokontany: "ampatakana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3946475,
          lng: 49.6656173,
        },
        _id: "64f75285ef8f606073e9a364",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ankofa",
        fokontany: "vinanimbidy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4122235,
          lng: 49.6477598,
        },
        _id: "64f75285ef8f606073e9a365",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ankofa",
        fokontany: "talanaro",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4155548,
          lng: 49.66306590000001,
        },
        _id: "64f75285ef8f606073e9a366",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ankofa",
        fokontany: "mahajinavola",
        __v: 0,
      },
      {
        coords: {
          lat: -15.416667,
          lng: 49.6666669,
        },
        _id: "64f75285ef8f606073e9a367",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ankofa",
        fokontany: "ambodiadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4262055,
          lng: 49.6681689,
        },
        _id: "64f75285ef8f606073e9a368",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ankofa",
        fokontany: "sadikamonjo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4275692,
          lng: 49.619709,
        },
        _id: "64f75285ef8f606073e9a369",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ankofa",
        fokontany: "ambalarano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3525,
          lng: 49.569241,
        },
        _id: "64f75285ef8f606073e9a36a",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambinanitelo",
        fokontany: "ambinanitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1608866,
          lng: 49.4872858,
        },
        _id: "64f75285ef8f606073e9a36b",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambinanitelo",
        fokontany: "sahantaha",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2,
          lng: 49.59999999999999,
        },
        _id: "64f75285ef8f606073e9a36c",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambinanitelo",
        fokontany: "andaparaty",
        __v: 0,
      },
      {
        coords: {
          lat: -15.25,
          lng: 49.65,
        },
        _id: "64f75285ef8f606073e9a36d",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambinanitelo",
        fokontany: "ambodihazomamy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2875695,
          lng: 49.6554124,
        },
        _id: "64f75285ef8f606073e9a36e",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambinanitelo",
        fokontany: "ambodiaramy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2882749,
          lng: 49.61461019999999,
        },
        _id: "64f75285ef8f606073e9a36f",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambinanitelo",
        fokontany: "ambodivoangy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3139506,
          lng: 49.6452092,
        },
        _id: "64f75285ef8f606073e9a370",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambinanitelo",
        fokontany: "anantonambilany",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3094218,
          lng: 49.6050513,
        },
        _id: "64f75285ef8f606073e9a371",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambinanitelo",
        fokontany: "maharovaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.310603,
          lng: 49.5967682,
        },
        _id: "64f75285ef8f606073e9a372",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambinanitelo",
        fokontany: "rantavatohely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2983979,
          lng: 49.5076392,
        },
        _id: "64f75285ef8f606073e9a373",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambinanitelo",
        fokontany: "marovovonana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3378796,
          lng: 49.5853012,
        },
        _id: "64f75285ef8f606073e9a374",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambinanitelo",
        fokontany: "rantavatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3432212,
          lng: 49.6044141,
        },
        _id: "64f75285ef8f606073e9a375",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambinanitelo",
        fokontany: "sahamadio",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3285896,
          lng: 49.6248081,
        },
        _id: "64f75285ef8f606073e9a376",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambinanitelo",
        fokontany: "valambahoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3425428,
          lng: 49.6452092,
        },
        _id: "64f75285ef8f606073e9a377",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambinanitelo",
        fokontany: "mariarano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3736355,
          lng: 49.6018654,
        },
        _id: "64f75285ef8f606073e9a378",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambinanitelo",
        fokontany: "andranasana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3481951,
          lng: 49.5178185,
        },
        _id: "64f75285ef8f606073e9a379",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambinanitelo",
        fokontany: "ambalamahogo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3996117,
          lng: 49.5789316,
        },
        _id: "64f75285ef8f606073e9a37a",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambinanitelo",
        fokontany: "antanambao andranasana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4353987,
          lng: 49.7392575,
        },
        _id: "64f75285ef8f606073e9a37b",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjahana",
        fokontany: "anjahana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3580895,
          lng: 49.8496077,
        },
        _id: "64f75285ef8f606073e9a37c",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjahana",
        fokontany: "sahafotra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3132465,
          lng: 49.8854497,
        },
        _id: "64f75285ef8f606073e9a37d",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjahana",
        fokontany: "mahafidina II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.380501,
          lng: 49.8316947,
        },
        _id: "64f75285ef8f606073e9a37e",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjahana",
        fokontany: "antsanofia",
        __v: 0,
      },
      {
        coords: {
          lat: -15.403659,
          lng: 49.839371,
        },
        _id: "64f75285ef8f606073e9a37f",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjahana",
        fokontany: "anjomo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3764707,
          lng: 49.8905718,
        },
        _id: "64f75285ef8f606073e9a380",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjahana",
        fokontany: "mahafidina I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4080826,
          lng: 49.85984610000001,
        },
        _id: "64f75285ef8f606073e9a381",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anjahana",
        fokontany: "navana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6081158,
          lng: 49.9546343,
        },
        _id: "64f75285ef8f606073e9a382",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambanizana",
        fokontany: "rantabe ambanizana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5752979,
          lng: 49.9469432,
        },
        _id: "64f75285ef8f606073e9a383",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambanizana",
        fokontany: "nandrahanana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.58571,
          lng: 49.9300799,
        },
        _id: "64f75285ef8f606073e9a384",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambanizana",
        fokontany: "ambanizana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4368075,
          lng: 49.92643839999999,
        },
        _id: "64f75285ef8f606073e9a385",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "mahalevona",
        fokontany: "mahalevona sud",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3117011,
          lng: 49.9674551,
        },
        _id: "64f75285ef8f606073e9a386",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "mahalevona",
        fokontany: "fizono",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3794098,
          lng: 49.92643839999999,
        },
        _id: "64f75285ef8f606073e9a387",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "mahalevona",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4066346,
          lng: 49.9418164,
        },
        _id: "64f75285ef8f606073e9a388",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "mahalevona",
        fokontany: "mahalevona nord",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4942318,
          lng: 49.92643839999999,
        },
        _id: "64f75285ef8f606073e9a389",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "mahalevona",
        fokontany: "masindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.57751,
          lng: 49.604191,
        },
        _id: "64f75285ef8f606073e9a38a",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "voloina",
        fokontany: "voloina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.516667,
          lng: 49.5,
        },
        _id: "64f75285ef8f606073e9a38b",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "voloina",
        fokontany: "ambinanindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5427581,
          lng: 49.612061,
        },
        _id: "64f75285ef8f606073e9a38c",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "voloina",
        fokontany: "ambodipaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.553582,
          lng: 49.5636473,
        },
        _id: "64f75285ef8f606073e9a38d",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "voloina",
        fokontany: "vodiriana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5507667,
          lng: 49.5942198,
        },
        _id: "64f75285ef8f606073e9a38e",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "voloina",
        fokontany: "ambodibaro",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5942164,
          lng: 49.4669396,
        },
        _id: "64f75285ef8f606073e9a38f",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "voloina",
        fokontany: "ambodihazomamy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5749077,
          lng: 49.5738364,
        },
        _id: "64f75285ef8f606073e9a390",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "voloina",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -15.575044,
          lng: 49.606963,
        },
        _id: "64f75285ef8f606073e9a391",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "voloina",
        fokontany: "tanantsara",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6035377,
          lng: 49.5738364,
        },
        _id: "64f75285ef8f606073e9a392",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "voloina",
        fokontany: "vodivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6198319,
          lng: 49.6044141,
        },
        _id: "64f75285ef8f606073e9a393",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "voloina",
        fokontany: "nandrasana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5095427,
          lng: 49.5585535,
        },
        _id: "64f75285ef8f606073e9a394",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ankofabe",
        fokontany: "ankofabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4592052,
          lng: 49.6554124,
        },
        _id: "64f75285ef8f606073e9a395",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ankofabe",
        fokontany: "analalava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4789117,
          lng: 49.61461019999999,
        },
        _id: "64f75285ef8f606073e9a396",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ankofabe",
        fokontany: "sahajinja",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4782906,
          lng: 49.6554124,
        },
        _id: "64f75285ef8f606073e9a397",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ankofabe",
        fokontany: "antoraka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5009352,
          lng: 49.6171596,
        },
        _id: "64f75285ef8f606073e9a398",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ankofabe",
        fokontany: "anjiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5217302,
          lng: 49.5814793,
        },
        _id: "64f75285ef8f606073e9a399",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ankofabe",
        fokontany: "andrangazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5191979,
          lng: 49.5916715,
        },
        _id: "64f75285ef8f606073e9a39a",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ankofabe",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5095427,
          lng: 49.5585535,
        },
        _id: "64f75285ef8f606073e9a39b",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ankofabe",
        fokontany: "anjahamarina II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5138289,
          lng: 49.6324577,
        },
        _id: "64f75285ef8f606073e9a39c",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ankofabe",
        fokontany: "manambia",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4832763,
          lng: 49.6018654,
        },
        _id: "64f75285ef8f606073e9a39d",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "antsirabe sahatany",
        fokontany: "antsirabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4116417,
          lng: 49.2435998,
        },
        _id: "64f75285ef8f606073e9a39e",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "antsirabe sahatany",
        fokontany: "andongona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.45,
          lng: 49.616667,
        },
        _id: "64f75285ef8f606073e9a39f",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "antsirabe sahatany",
        fokontany: "maromby",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4739984,
          lng: 49.4618542,
        },
        _id: "64f75285ef8f606073e9a3a0",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "antsirabe sahatany",
        fokontany: "vinanibe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4714058,
          lng: 49.5585535,
        },
        _id: "64f75285ef8f606073e9a3a1",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "antsirabe sahatany",
        fokontany: "antaravato",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4978849,
          lng: 49.5814793,
        },
        _id: "64f75285ef8f606073e9a3a2",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "antsirabe sahatany",
        fokontany: "sahatany",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7032406,
          lng: 49.6413835,
        },
        _id: "64f75285ef8f606073e9a3a3",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "rantabe",
        fokontany: "rantabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6456868,
          lng: 49.6350078,
        },
        _id: "64f75285ef8f606073e9a3a4",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "rantabe",
        fokontany: "rantohely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6846049,
          lng: 49.6273579,
        },
        _id: "64f75285ef8f606073e9a3a5",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "rantabe",
        fokontany: "sarahandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6811707,
          lng: 49.6388331,
        },
        _id: "64f75285ef8f606073e9a3a6",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "rantabe",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -15.698505,
          lng: 49.61461019999999,
        },
        _id: "64f75285ef8f606073e9a3a7",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "rantabe",
        fokontany: "ampasimbe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7226491,
          lng: 49.5942198,
        },
        _id: "64f75285ef8f606073e9a3a8",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "rantabe",
        fokontany: "malamavato",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7457901,
          lng: 49.6554124,
        },
        _id: "64f75285ef8f606073e9a3a9",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "rantabe",
        fokontany: "tenina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4189933,
          lng: 49.7447655,
        },
        _id: "64f75285ef8f606073e9a3aa",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "morafeno",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7348,
          lng: 49.455849,
        },
        _id: "64f75285ef8f606073e9a3ab",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "morafeno",
        fokontany: "beanana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7354461,
          lng: 49.5229088,
        },
        _id: "64f75285ef8f606073e9a3ac",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "morafeno",
        fokontany: "antsambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7167942,
          lng: 49.5356365,
        },
        _id: "64f75285ef8f606073e9a3ad",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "morafeno",
        fokontany: "tanambao morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7625932,
          lng: 49.1976616,
        },
        _id: "64f75285ef8f606073e9a3ae",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "androndrono",
        fokontany: "androndrono",
        __v: 0,
      },
      {
        coords: {
          lat: -15.633333,
          lng: 49.133333,
        },
        _id: "64f75285ef8f606073e9a3af",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "androndrono",
        fokontany: "antsahamilemaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7286499,
          lng: 49.3856256,
        },
        _id: "64f75285ef8f606073e9a3b0",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "androndrono",
        fokontany: "ambodimanga nandihizana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6922704,
          lng: 49.22333889999999,
        },
        _id: "64f75285ef8f606073e9a3b1",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "androndrono",
        fokontany: "ambatrabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7485661,
          lng: 49.3044253,
        },
        _id: "64f75285ef8f606073e9a3b2",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "androndrono",
        fokontany: "antsirabe nandihizana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8262288,
          lng: 49.1423666,
        },
        _id: "64f75285ef8f606073e9a3b3",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "androndrono",
        fokontany: "ampanobe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8339576,
          lng: 49.34501119999999,
        },
        _id: "64f75285ef8f606073e9a3b4",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "androndrono",
        fokontany: "nanoharana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.883333,
          lng: 49.2,
        },
        _id: "64f75285ef8f606073e9a3b5",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "androndrono",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5671839,
          lng: 49.34501119999999,
        },
        _id: "64f75285ef8f606073e9a3b6",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambodimanga rantabe",
        fokontany: "ambodimanga rantabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6622061,
          lng: 49.5585535,
        },
        _id: "64f75285ef8f606073e9a3b7",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambodimanga rantabe",
        fokontany: "andratambe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5671839,
          lng: 49.34501119999999,
        },
        _id: "64f75285ef8f606073e9a3b8",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambodimanga rantabe",
        fokontany: "sahajinja manonga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.65,
          lng: 49.583333,
        },
        _id: "64f75285ef8f606073e9a3b9",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambodimanga rantabe",
        fokontany: "mafaipoza",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6748336,
          lng: 49.6209837,
        },
        _id: "64f75285ef8f606073e9a3ba",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambodimanga rantabe",
        fokontany: "ambodivaseva ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6775939,
          lng: 49.6037769,
        },
        _id: "64f75285ef8f606073e9a3bb",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambodimanga rantabe",
        fokontany: "tanambao ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6921625,
          lng: 49.5967682,
        },
        _id: "64f75285ef8f606073e9a3bc",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "ambodimanga rantabe",
        fokontany: "ambodibaro",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8214198,
          lng: 49.6299077,
        },
        _id: "64f75285ef8f606073e9a3bd",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anandrivola",
        fokontany: "anandrivola",
        __v: 0,
      },
      {
        coords: {
          lat: -15.78979,
          lng: 49.672298,
        },
        _id: "64f75285ef8f606073e9a3be",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anandrivola",
        fokontany: "maintimbato",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7713277,
          lng: 49.619709,
        },
        _id: "64f75285ef8f606073e9a3bf",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anandrivola",
        fokontany: "ankoba",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8296158,
          lng: 49.6452092,
        },
        _id: "64f75285ef8f606073e9a3c0",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anandrivola",
        fokontany: "antsoraka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8432868,
          lng: 49.5993167,
        },
        _id: "64f75285ef8f606073e9a3c1",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anandrivola",
        fokontany: "anena",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8678476,
          lng: 49.5381824,
        },
        _id: "64f75285ef8f606073e9a3c2",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anandrivola",
        fokontany: "antsirabe anena",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8952512,
          lng: 49.6605146,
        },
        _id: "64f75285ef8f606073e9a3c3",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anandrivola",
        fokontany: "tanambao ranolalina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.89074,
          lng: 49.705399,
        },
        _id: "64f75285ef8f606073e9a3c4",
        province: "toamasina",
        region: "analanjirofo",
        district: "maroantsetra",
        commune: "anandrivola",
        fokontany: "fananehana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1686091,
          lng: 49.76456899999999,
        },
        _id: "64f75285ef8f606073e9a3c5",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "mananara centre",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1636132,
          lng: 49.7345469,
        },
        _id: "64f75285ef8f606073e9a3c6",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.149981,
          lng: 49.71637,
        },
        _id: "64f75285ef8f606073e9a3c7",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "tampolo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1285446,
          lng: 49.6962428,
        },
        _id: "64f75285ef8f606073e9a3c8",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "aniribe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1527037,
          lng: 49.6758241,
        },
        _id: "64f75285ef8f606073e9a3c9",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "ambatokintana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1715635,
          lng: 49.7166685,
        },
        _id: "64f75285ef8f606073e9a3ca",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "soavinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2167501,
          lng: 49.69369,
        },
        _id: "64f75285ef8f606073e9a3cb",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "vodivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2076502,
          lng: 49.7064548,
        },
        _id: "64f75285ef8f606073e9a3cc",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "antanantsara",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2048277,
          lng: 49.6834802,
        },
        _id: "64f75285ef8f606073e9a3cd",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "ambodiatafana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1864613,
          lng: 49.7294382,
        },
        _id: "64f75285ef8f606073e9a3ce",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "andilamboangy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2122917,
          lng: 49.72688410000001,
        },
        _id: "64f75285ef8f606073e9a3cf",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "sandravazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -16.222397,
          lng: 49.739656,
        },
        _id: "64f75285ef8f606073e9a3d0",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "andity",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2338249,
          lng: 49.7371014,
        },
        _id: "64f75285ef8f606073e9a3d1",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "antsirabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2073261,
          lng: 49.7473204,
        },
        _id: "64f75285ef8f606073e9a3d2",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "ambodirotro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1887785,
          lng: 49.739656,
        },
        _id: "64f75285ef8f606073e9a3d3",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "ambatotsimahatamana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1719566,
          lng: 49.77670999999999,
        },
        _id: "64f75285ef8f606073e9a3d4",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "andraokaraoka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1798278,
          lng: 49.7690417,
        },
        _id: "64f75285ef8f606073e9a3d5",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1730433,
          lng: 49.7549858,
        },
        _id: "64f75285ef8f606073e9a3d6",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "analanampotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1667301,
          lng: 49.790771,
        },
        _id: "64f75285ef8f606073e9a3d7",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "ambitsika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1650072,
          lng: 49.76456899999999,
        },
        _id: "64f75285ef8f606073e9a3d8",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "antanankoro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.195729,
          lng: 49.77031969999999,
        },
        _id: "64f75285ef8f606073e9a3d9",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "antetezambe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2089822,
          lng: 49.765208,
        },
        _id: "64f75285ef8f606073e9a3da",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "maditona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2200818,
          lng: 49.7664859,
        },
        _id: "64f75285ef8f606073e9a3db",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "antsiratenina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2330047,
          lng: 49.765208,
        },
        _id: "64f75285ef8f606073e9a3dc",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "sahave",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2210748,
          lng: 49.7549858,
        },
        _id: "64f75285ef8f606073e9a3dd",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "androrangavola",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2490828,
          lng: 49.7831009,
        },
        _id: "64f75285ef8f606073e9a3de",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "andasibe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.224221,
          lng: 49.8112293,
        },
        _id: "64f75285ef8f606073e9a3df",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "ambatomilona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.21916,
          lng: 49.792122,
        },
        _id: "64f75285ef8f606073e9a3e0",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "ambatofitarafana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1996964,
          lng: 49.7984421,
        },
        _id: "64f75285ef8f606073e9a3e1",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "ankorabe ambodivohangy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1836327,
          lng: 49.7805445,
        },
        _id: "64f75285ef8f606073e9a3e2",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "mahambolona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1822997,
          lng: 49.7958849,
        },
        _id: "64f75285ef8f606073e9a3e3",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "mananara avaratra",
        fokontany: "amboditangena",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1797843,
          lng: 49.6624675,
        },
        _id: "64f75285ef8f606073e9a3e4",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodivoanio",
        fokontany: "ambodivoanio",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2067704,
          lng: 49.4923735,
        },
        _id: "64f75285ef8f606073e9a3e5",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodivoanio",
        fokontany: "analanana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1694797,
          lng: 49.5178185,
        },
        _id: "64f75285ef8f606073e9a3e6",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodivoanio",
        fokontany: "befoza",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2088329,
          lng: 49.5432745,
        },
        _id: "64f75285ef8f606073e9a3e7",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodivoanio",
        fokontany: "ambodisatrana II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2009943,
          lng: 49.6350078,
        },
        _id: "64f75285ef8f606073e9a3e8",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodivoanio",
        fokontany: "ambodimanga sahantsihanaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1723925,
          lng: 49.6885849,
        },
        _id: "64f75285ef8f606073e9a3e9",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodivoanio",
        fokontany: "analalava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1530323,
          lng: 49.6350078,
        },
        _id: "64f75285ef8f606073e9a3ea",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodivoanio",
        fokontany: "tsaravato",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1843088,
          lng: 49.6987956,
        },
        _id: "64f75285ef8f606073e9a3eb",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodivoanio",
        fokontany: "ambodibaro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2049829,
          lng: 49.66306590000001,
        },
        _id: "64f75285ef8f606073e9a3ec",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodivoanio",
        fokontany: "ambodiadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2155324,
          lng: 49.61461019999999,
        },
        _id: "64f75285ef8f606073e9a3ed",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodivoanio",
        fokontany: "lahony",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2,
          lng: 49.616667,
        },
        _id: "64f75285ef8f606073e9a3ee",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodivoanio",
        fokontany: "ambavala",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1989654,
          lng: 49.5840272,
        },
        _id: "64f75285ef8f606073e9a3ef",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodivoanio",
        fokontany: "antsirandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1746363,
          lng: 49.5509135,
        },
        _id: "64f75285ef8f606073e9a3f0",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodivoanio",
        fokontany: "sanikoa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.18992,
          lng: 49.472778,
        },
        _id: "64f75285ef8f606073e9a3f1",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodivoanio",
        fokontany: "ambatelo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2158164,
          lng: 49.5738364,
        },
        _id: "64f75285ef8f606073e9a3f2",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodivoanio",
        fokontany: "manombo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2583731,
          lng: 49.8240194,
        },
        _id: "64f75285ef8f606073e9a3f3",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "imorona",
        fokontany: "imorona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1851023,
          lng: 49.818903,
        },
        _id: "64f75285ef8f606073e9a3f4",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "imorona",
        fokontany: "antsirakivolo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2185751,
          lng: 49.839371,
        },
        _id: "64f75285ef8f606073e9a3f5",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "imorona",
        fokontany: "vohitralanana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.24683,
          lng: 49.839161,
        },
        _id: "64f75285ef8f606073e9a3f6",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "imorona",
        fokontany: "seranambe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2620206,
          lng: 49.818903,
        },
        _id: "64f75285ef8f606073e9a3f7",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "imorona",
        fokontany: "ambodivondrozona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2684905,
          lng: 49.8368121,
        },
        _id: "64f75285ef8f606073e9a3f8",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "imorona",
        fokontany: "hoalampano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2875394,
          lng: 49.7831009,
        },
        _id: "64f75285ef8f606073e9a3f9",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "imorona",
        fokontany: "ivontaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2502768,
          lng: 49.619709,
        },
        _id: "64f75285ef8f606073e9a3fa",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambaobe",
        fokontany: "antanambaobe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2414116,
          lng: 49.6860325,
        },
        _id: "64f75285ef8f606073e9a3fb",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambaobe",
        fokontany: "antanetilava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2806163,
          lng: 49.6579635,
        },
        _id: "64f75285ef8f606073e9a3fc",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambaobe",
        fokontany: "vohimanja",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2448454,
          lng: 49.5330908,
        },
        _id: "64f75285ef8f606073e9a3fd",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambaobe",
        fokontany: "makadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2661019,
          lng: 49.5840272,
        },
        _id: "64f75285ef8f606073e9a3fe",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambaobe",
        fokontany: "maromandia",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2344328,
          lng: 49.6554124,
        },
        _id: "64f75285ef8f606073e9a3ff",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambaobe",
        fokontany: "vohipatakana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2877501,
          lng: 49.6681689,
        },
        _id: "64f75285ef8f606073e9a400",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambaobe",
        fokontany: "antanambaohely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2974867,
          lng: 49.6477598,
        },
        _id: "64f75285ef8f606073e9a401",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambaobe",
        fokontany: "ambodiravina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.262436,
          lng: 49.6834802,
        },
        _id: "64f75285ef8f606073e9a402",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambaobe",
        fokontany: "bandabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2549366,
          lng: 49.6401083,
        },
        _id: "64f75285ef8f606073e9a403",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambaobe",
        fokontany: "ambodisatrana I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2515787,
          lng: 49.6044141,
        },
        _id: "64f75285ef8f606073e9a404",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambaobe",
        fokontany: "andilambe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.266667,
          lng: 49.616667,
        },
        _id: "64f75285ef8f606073e9a405",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambaobe",
        fokontany: "antsiraka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2927878,
          lng: 49.7217761,
        },
        _id: "64f75285ef8f606073e9a406",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanananivo",
        fokontany: "antanananivo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2590074,
          lng: 49.7422107,
        },
        _id: "64f75285ef8f606073e9a407",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanananivo",
        fokontany: "vohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.29682,
          lng: 49.726749,
        },
        _id: "64f75285ef8f606073e9a408",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanananivo",
        fokontany: "andratamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2990367,
          lng: 49.6860325,
        },
        _id: "64f75285ef8f606073e9a409",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanananivo",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2765152,
          lng: 49.6873087,
        },
        _id: "64f75285ef8f606073e9a40a",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanananivo",
        fokontany: "antenina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2725508,
          lng: 49.6962428,
        },
        _id: "64f75285ef8f606073e9a40b",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanananivo",
        fokontany: "antanamirantina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3265586,
          lng: 49.7013486,
        },
        _id: "64f75285ef8f606073e9a40c",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanananivo",
        fokontany: "inara",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0478099,
          lng: 49.66596999999999,
        },
        _id: "64f75285ef8f606073e9a40d",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "manambolosy",
        fokontany: "manambolosy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0915042,
          lng: 49.6809281,
        },
        _id: "64f75285ef8f606073e9a40e",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "manambolosy",
        fokontany: "fahambahy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9992707,
          lng: 49.6758241,
        },
        _id: "64f75285ef8f606073e9a40f",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "manambolosy",
        fokontany: "fontsimaro",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9794277,
          lng: 49.6834802,
        },
        _id: "64f75285ef8f606073e9a410",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "manambolosy",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -15.95,
          lng: 49.7,
        },
        _id: "64f75285ef8f606073e9a411",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "manambolosy",
        fokontany: "anoromby",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9235428,
          lng: 49.7013486,
        },
        _id: "64f75285ef8f606073e9a412",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "manambolosy",
        fokontany: "antanambao II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9048388,
          lng: 49.7141149,
        },
        _id: "64f75285ef8f606073e9a413",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "manambolosy",
        fokontany: "tanjona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0350209,
          lng: 49.6324577,
        },
        _id: "64f75285ef8f606073e9a414",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "manambolosy",
        fokontany: "ambodimandresy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9343879,
          lng: 49.5789316,
        },
        _id: "64f75285ef8f606073e9a415",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "manambolosy",
        fokontany: "belondo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0165283,
          lng: 49.6248081,
        },
        _id: "64f75285ef8f606073e9a416",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "manambolosy",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9973696,
          lng: 49.6248081,
        },
        _id: "64f75285ef8f606073e9a417",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "manambolosy",
        fokontany: "ambodiatafana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0215061,
          lng: 49.6044141,
        },
        _id: "64f75285ef8f606073e9a418",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "manambolosy",
        fokontany: "ambodibonara",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9872188,
          lng: 49.5585535,
        },
        _id: "64f75285ef8f606073e9a419",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "manambolosy",
        fokontany: "lampeny",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0584926,
          lng: 49.619709,
        },
        _id: "64f75285ef8f606073e9a41a",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "manambolosy",
        fokontany: "andilambe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0624826,
          lng: 49.5738364,
        },
        _id: "64f75285ef8f606073e9a41b",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "manambolosy",
        fokontany: "andapihely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.02548,
          lng: 49.536579,
        },
        _id: "64f75285ef8f606073e9a41c",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "manambolosy",
        fokontany: "folahanteza",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9527139,
          lng: 49.6605146,
        },
        _id: "64f75285ef8f606073e9a41d",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "manambolosy",
        fokontany: "banda",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0840942,
          lng: 49.637558,
        },
        _id: "64f75285ef8f606073e9a41e",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "manambolosy",
        fokontany: "sahana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1186436,
          lng: 49.5891233,
        },
        _id: "64f75285ef8f606073e9a41f",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodiampana",
        fokontany: "ambodiampana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1216103,
          lng: 49.6656173,
        },
        _id: "64f75285ef8f606073e9a420",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodiampana",
        fokontany: "ambodimanga I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1265763,
          lng: 49.6452092,
        },
        _id: "64f75285ef8f606073e9a421",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodiampana",
        fokontany: "antanambao I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0973192,
          lng: 49.6324577,
        },
        _id: "64f75285ef8f606073e9a422",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodiampana",
        fokontany: "ampasina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1034262,
          lng: 49.6541369,
        },
        _id: "64f75285ef8f606073e9a423",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodiampana",
        fokontany: "ambodirotro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.124593,
          lng: 49.5942198,
        },
        _id: "64f75285ef8f606073e9a424",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodiampana",
        fokontany: "ankoba I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1325199,
          lng: 49.5763839,
        },
        _id: "64f75285ef8f606073e9a425",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodiampana",
        fokontany: "antenina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.09124,
          lng: 49.570381,
        },
        _id: "64f75285ef8f606073e9a426",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodiampana",
        fokontany: "mahalina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0747081,
          lng: 49.5432745,
        },
        _id: "64f75285ef8f606073e9a427",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodiampana",
        fokontany: "ambodisatrana II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0778442,
          lng: 49.5254541,
        },
        _id: "64f75285ef8f606073e9a428",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodiampana",
        fokontany: "andolana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.113016,
          lng: 49.5432745,
        },
        _id: "64f75285ef8f606073e9a429",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodiampana",
        fokontany: "andapanapombo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1311665,
          lng: 49.5178185,
        },
        _id: "64f75285ef8f606073e9a42a",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodiampana",
        fokontany: "maroantsoro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1513348,
          lng: 49.5432745,
        },
        _id: "64f75285ef8f606073e9a42b",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodiampana",
        fokontany: "ambodihintsina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1559758,
          lng: 49.5636473,
        },
        _id: "64f75285ef8f606073e9a42c",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambodiampana",
        fokontany: "anjahamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.347691,
          lng: 49.612389,
        },
        _id: "64f75285ef8f606073e9a42d",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "sandrakatsy",
        fokontany: "sandrakatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2334182,
          lng: 49.4415168,
        },
        _id: "64f75285ef8f606073e9a42e",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "sandrakatsy",
        fokontany: "ambodihatafana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2368015,
          lng: 49.4771118,
        },
        _id: "64f75285ef8f606073e9a42f",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "sandrakatsy",
        fokontany: "ambahikarabo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2715051,
          lng: 49.4821986,
        },
        _id: "64f75285ef8f606073e9a430",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "sandrakatsy",
        fokontany: "vatolava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2891604,
          lng: 49.5381824,
        },
        _id: "64f75285ef8f606073e9a431",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "sandrakatsy",
        fokontany: "andaparatibe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3003275,
          lng: 49.5763839,
        },
        _id: "64f75285ef8f606073e9a432",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "sandrakatsy",
        fokontany: "antaramahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3074638,
          lng: 49.5865752,
        },
        _id: "64f75285ef8f606073e9a433",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "sandrakatsy",
        fokontany: "badredra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3358381,
          lng: 49.55346,
        },
        _id: "64f75285ef8f606073e9a434",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "sandrakatsy",
        fokontany: "andaparatihely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3260141,
          lng: 49.5942198,
        },
        _id: "64f75285ef8f606073e9a435",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "sandrakatsy",
        fokontany: "ambalahasina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3524678,
          lng: 49.5840272,
        },
        _id: "64f75285ef8f606073e9a436",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "sandrakatsy",
        fokontany: "fasina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3550291,
          lng: 49.55346,
        },
        _id: "64f75285ef8f606073e9a437",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "sandrakatsy",
        fokontany: "ambodimanga tolongoina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3838204,
          lng: 49.55346,
        },
        _id: "64f75285ef8f606073e9a438",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "sandrakatsy",
        fokontany: "anantarambarahina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.311495,
          lng: 49.61461019999999,
        },
        _id: "64f75285ef8f606073e9a439",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "sandrakatsy",
        fokontany: "mahamamo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3467954,
          lng: 49.6324577,
        },
        _id: "64f75285ef8f606073e9a43a",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "sandrakatsy",
        fokontany: "andapatenina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3691041,
          lng: 49.61461019999999,
        },
        _id: "64f75285ef8f606073e9a43b",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "sandrakatsy",
        fokontany: "ambodihintsina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3761397,
          lng: 49.6452092,
        },
        _id: "64f75285ef8f606073e9a43c",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "sandrakatsy",
        fokontany: "varary",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3520036,
          lng: 49.6656173,
        },
        _id: "64f75285ef8f606073e9a43d",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "sandrakatsy",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3185086,
          lng: 49.6452092,
        },
        _id: "64f75285ef8f606073e9a43e",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "sandrakatsy",
        fokontany: "andravolasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4168769,
          lng: 49.3714279,
        },
        _id: "64f75285ef8f606073e9a43f",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "tanibe",
        fokontany: "tanibe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3040851,
          lng: 49.4364335,
        },
        _id: "64f75285ef8f606073e9a440",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "tanibe",
        fokontany: "ampisokina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3098422,
          lng: 49.4821986,
        },
        _id: "64f75285ef8f606073e9a441",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "tanibe",
        fokontany: "ambalafefy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3283772,
          lng: 49.4898296,
        },
        _id: "64f75285ef8f606073e9a442",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "tanibe",
        fokontany: "antanambao II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3857775,
          lng: 49.3754694,
        },
        _id: "64f75285ef8f606073e9a443",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "tanibe",
        fokontany: "fahitrosy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3214228,
          lng: 49.3044253,
        },
        _id: "64f75285ef8f606073e9a444",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "tanibe",
        fokontany: "ambamaho",
        __v: 0,
      },
      {
        coords: {
          lat: -16.422853,
          lng: 49.3907044,
        },
        _id: "64f75285ef8f606073e9a445",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "tanibe",
        fokontany: "antanambehely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4899546,
          lng: 49.3907044,
        },
        _id: "64f75285ef8f606073e9a446",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "tanibe",
        fokontany: "analanambola",
        __v: 0,
      },
      {
        coords: {
          lat: -16.476638,
          lng: 49.4364335,
        },
        _id: "64f75285ef8f606073e9a447",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "tanibe",
        fokontany: "sahafaray",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3948742,
          lng: 49.4974616,
        },
        _id: "64f75285ef8f606073e9a448",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "tanibe",
        fokontany: "anteninkely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3673676,
          lng: 49.4821986,
        },
        _id: "64f75285ef8f606073e9a449",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "tanibe",
        fokontany: "ambodirotro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3503363,
          lng: 49.5330908,
        },
        _id: "64f75285ef8f606073e9a44a",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "tanibe",
        fokontany: "ambodivoandrozana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.433333,
          lng: 49.583333,
        },
        _id: "64f75285ef8f606073e9a44b",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambatoharanana",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4294629,
          lng: 49.5432745,
        },
        _id: "64f75285ef8f606073e9a44c",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambatoharanana",
        fokontany: "ambodimampay",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4618869,
          lng: 49.5381824,
        },
        _id: "64f75285ef8f606073e9a44d",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambatoharanana",
        fokontany: "ampasina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.393219,
          lng: 49.5942198,
        },
        _id: "64f75285ef8f606073e9a44e",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambatoharanana",
        fokontany: "ambodimangatelo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5097595,
          lng: 49.5789316,
        },
        _id: "64f75285ef8f606073e9a44f",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambatoharanana",
        fokontany: "anjiafotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5529405,
          lng: 49.5993167,
        },
        _id: "64f75285ef8f606073e9a450",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambatoharanana",
        fokontany: "sahasoa tsaravato",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4100744,
          lng: 49.5840272,
        },
        _id: "64f75285ef8f606073e9a451",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambatoharanana",
        fokontany: "ambodisambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5339805,
          lng: 49.5178185,
        },
        _id: "64f75285ef8f606073e9a452",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambatoharanana",
        fokontany: "savarandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4861041,
          lng: 49.4771118,
        },
        _id: "64f75285ef8f606073e9a453",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambatoharanana",
        fokontany: "namoloana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5626815,
          lng: 49.5585535,
        },
        _id: "64f75285ef8f606073e9a454",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambatoharanana",
        fokontany: "fotsialanana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4568307,
          lng: 49.5993167,
        },
        _id: "64f75285ef8f606073e9a455",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "ambatoharanana",
        fokontany: "saharango",
        __v: 0,
      },
      {
        coords: {
          lat: -16.444097,
          lng: 49.8470483,
        },
        _id: "64f75285ef8f606073e9a456",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "antanambe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4705695,
          lng: 49.7422107,
        },
        _id: "64f75285ef8f606073e9a457",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "ambatorao",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5308657,
          lng: 49.7115614,
        },
        _id: "64f75285ef8f606073e9a458",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "ambavala",
        __v: 0,
      },
      {
        coords: {
          lat: -16.37575,
          lng: 49.796101,
        },
        _id: "64f75285ef8f606073e9a459",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4066124,
          lng: 49.77798809999999,
        },
        _id: "64f75285ef8f606073e9a45a",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "ampasina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.480399,
          lng: 49.816345,
        },
        _id: "64f75285ef8f606073e9a45b",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "ambodihazovola",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5606631,
          lng: 49.77798809999999,
        },
        _id: "64f75285ef8f606073e9a45c",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "ambohimitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4649862,
          lng: 49.6554124,
        },
        _id: "64f75285ef8f606073e9a45d",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "andranombazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6088374,
          lng: 49.77798809999999,
        },
        _id: "64f75285ef8f606073e9a45e",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "anove nord",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5188246,
          lng: 49.8368121,
        },
        _id: "64f75285ef8f606073e9a45f",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "antanambao mandrisy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.566561,
          lng: 49.785709,
        },
        _id: "64f75285ef8f606073e9a460",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "antanetilava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.41357,
          lng: 49.72736,
        },
        _id: "64f75285ef8f606073e9a461",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "antevialabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.533333,
          lng: 49.833333,
        },
        _id: "64f75285ef8f606073e9a462",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "manambato",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5479129,
          lng: 49.6605146,
        },
        _id: "64f75285ef8f606073e9a463",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "mandemoka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5050143,
          lng: 49.8291362,
        },
        _id: "64f75285ef8f606073e9a464",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "mandrisy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5810244,
          lng: 49.8035566,
        },
        _id: "64f75285ef8f606073e9a465",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "malotrandro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3176994,
          lng: 49.7677638,
        },
        _id: "64f75285ef8f606073e9a466",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "marotoko",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3174067,
          lng: 49.8086717,
        },
        _id: "64f75285ef8f606073e9a467",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "menatany",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3558926,
          lng: 49.8086717,
        },
        _id: "64f75285ef8f606073e9a468",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "sahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4641313,
          lng: 49.818903,
        },
        _id: "64f75285ef8f606073e9a469",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "sarahandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4827497,
          lng: 49.8265777,
        },
        _id: "64f75285ef8f606073e9a46a",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4040147,
          lng: 49.8086717,
        },
        _id: "64f75285ef8f606073e9a46b",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "vahibe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5813634,
          lng: 49.7217761,
        },
        _id: "64f75285ef8f606073e9a46c",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "vodiriana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5184984,
          lng: 49.7831009,
        },
        _id: "64f75285ef8f606073e9a46d",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "antanambe",
        fokontany: "vohitrampongy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.15,
          lng: 49.316667,
        },
        _id: "64f75285ef8f606073e9a46e",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "saromaona",
        fokontany: "saromaona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.12122,
          lng: 49.267632,
        },
        _id: "64f75285ef8f606073e9a46f",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "saromaona",
        fokontany: "ankoetrika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.083333,
          lng: 49.216667,
        },
        _id: "64f75285ef8f606073e9a470",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "saromaona",
        fokontany: "antsirabato",
        __v: 0,
      },
      {
        coords: {
          lat: -16.17494,
          lng: 49.245861,
        },
        _id: "64f75285ef8f606073e9a471",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "saromaona",
        fokontany: "fitana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1462325,
          lng: 49.41610499999999,
        },
        _id: "64f75285ef8f606073e9a472",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "saromaona",
        fokontany: "sahazono",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1596737,
          lng: 49.37039189999999,
        },
        _id: "64f75285ef8f606073e9a473",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "saromaona",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0094689,
          lng: 49.1524819,
        },
        _id: "64f75285ef8f606073e9a474",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "saromaona",
        fokontany: "antsira",
        __v: 0,
      },
      {
        coords: {
          lat: -16.218122,
          lng: 49.3957836,
        },
        _id: "64f75285ef8f606073e9a475",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "saromaona",
        fokontany: "ambodirotro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1554807,
          lng: 49.268936,
        },
        _id: "64f75285ef8f606073e9a476",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "saromaona",
        fokontany: "anjiahely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0424552,
          lng: 49.2132111,
        },
        _id: "64f75285ef8f606073e9a477",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "saromaona",
        fokontany: "anjinjamihoatra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2279111,
          lng: 49.3551622,
        },
        _id: "64f75285ef8f606073e9a478",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "saromaona",
        fokontany: "antanantsara",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2283145,
          lng: 49.2740045,
        },
        _id: "64f75285ef8f606073e9a479",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "saromaona",
        fokontany: "marovatana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1687288,
          lng: 49.4516846,
        },
        _id: "64f75285ef8f606073e9a47a",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "saromaona",
        fokontany: "vakoanina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1085117,
          lng: 49.3348621,
        },
        _id: "64f75285ef8f606073e9a47b",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "saromaona",
        fokontany: "sahasoa II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9629399,
          lng: 49.3247147,
        },
        _id: "64f75285ef8f606073e9a47c",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "vanono",
        fokontany: "vanono",
        __v: 0,
      },
      {
        coords: {
          lat: -16.011193,
          lng: 49.4847422,
        },
        _id: "64f75285ef8f606073e9a47d",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "vanono",
        fokontany: "ankoba II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9965301,
          lng: 49.4516846,
        },
        _id: "64f75285ef8f606073e9a47e",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "vanono",
        fokontany: "anjiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.974754,
          lng: 49.4821986,
        },
        _id: "64f75285ef8f606073e9a47f",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "vanono",
        fokontany: "ambodimanga II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9984853,
          lng: 49.50255019999999,
        },
        _id: "64f75285ef8f606073e9a480",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "vanono",
        fokontany: "antanambao sahavalanina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8877752,
          lng: 49.4567691,
        },
        _id: "64f75285ef8f606073e9a481",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "vanono",
        fokontany: "madiorano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9194707,
          lng: 49.3856256,
        },
        _id: "64f75285ef8f606073e9a482",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "vanono",
        fokontany: "ambodihazovola",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0248463,
          lng: 49.34501119999999,
        },
        _id: "64f75285ef8f606073e9a483",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "vanono",
        fokontany: "mahanoro",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9378463,
          lng: 49.4669396,
        },
        _id: "64f75285ef8f606073e9a484",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "vanono",
        fokontany: "analanantsiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0255383,
          lng: 49.411024,
        },
        _id: "64f75285ef8f606073e9a485",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "vanono",
        fokontany: "marovoara",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9898613,
          lng: 49.2334685,
        },
        _id: "64f75285ef8f606073e9a486",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "vanono",
        fokontany: "mangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0324679,
          lng: 49.4415168,
        },
        _id: "64f75285ef8f606073e9a487",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "vanono",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0499536,
          lng: 49.4974616,
        },
        _id: "64f75285ef8f606073e9a488",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "vanono",
        fokontany: "fanorahana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0550658,
          lng: 49.5305451,
        },
        _id: "64f75285ef8f606073e9a489",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "vanono",
        fokontany: "sahavia",
        __v: 0,
      },
      {
        coords: {
          lat: -15.933333,
          lng: 49.23333299999999,
        },
        _id: "64f75285ef8f606073e9a48a",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "vanono",
        fokontany: "ambalafontsy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0815942,
          lng: 49.4262684,
        },
        _id: "64f75285ef8f606073e9a48b",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "andasibe i",
        fokontany: "andasibe I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0684066,
          lng: 49.4313508,
        },
        _id: "64f75285ef8f606073e9a48c",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "andasibe i",
        fokontany: "andasibe II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0987652,
          lng: 49.4491425,
        },
        _id: "64f75285ef8f606073e9a48d",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "andasibe i",
        fokontany: "sahivo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1136242,
          lng: 49.4618542,
        },
        _id: "64f75285ef8f606073e9a48e",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "andasibe i",
        fokontany: "ambodisatrana I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1086905,
          lng: 49.4821986,
        },
        _id: "64f75285ef8f606073e9a48f",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "andasibe i",
        fokontany: "ankorabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0865773,
          lng: 49.4796552,
        },
        _id: "64f75285ef8f606073e9a490",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "andasibe i",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.066667,
          lng: 49.5,
        },
        _id: "64f75285ef8f606073e9a491",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "andasibe i",
        fokontany: "amboditodinga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0842043,
          lng: 49.3957836,
        },
        _id: "64f75285ef8f606073e9a492",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "andasibe i",
        fokontany: "ambinanibaoka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0656541,
          lng: 49.3145691,
        },
        _id: "64f75285ef8f606073e9a493",
        province: "toamasina",
        region: "analanjirofo",
        district: "mananara-avaratra",
        commune: "andasibe i",
        fokontany: "sahavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3822077,
          lng: 49.4141996,
        },
        _id: "64f75285ef8f606073e9a494",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "fenerive est",
        fokontany: "mahavelonkely ambatary",
        __v: 0,
      },
      {
        coords: {
          lat: -17.377691,
          lng: 49.393244,
        },
        _id: "64f75285ef8f606073e9a495",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "fenerive est",
        fokontany: "andranofeno",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3717691,
          lng: 49.4084837,
        },
        _id: "64f75285ef8f606073e9a496",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "fenerive est",
        fokontany: "sahavola marabout",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3939472,
          lng: 49.3907044,
        },
        _id: "64f75285ef8f606073e9a497",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "fenerive est",
        fokontany: "amparatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4014001,
          lng: 49.4211865,
        },
        _id: "64f75285ef8f606073e9a498",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "fenerive est",
        fokontany: "ampasimpotsy fenoarivo doany",
        __v: 0,
      },
      {
        coords: {
          lat: -17.36269,
          lng: 49.358059,
        },
        _id: "64f75285ef8f606073e9a499",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "ambodimanga II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2780064,
          lng: 49.3500865,
        },
        _id: "64f75285ef8f606073e9a49a",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "ankorabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2969755,
          lng: 49.3983234,
        },
        _id: "64f75285ef8f606073e9a49b",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "andapa II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3,
          lng: 49.4,
        },
        _id: "64f75285ef8f606073e9a49c",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "mahavanona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3118598,
          lng: 49.37039189999999,
        },
        _id: "64f75285ef8f606073e9a49d",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "marojomana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.297827,
          lng: 49.3424738,
        },
        _id: "64f75285ef8f606073e9a49e",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "anarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3115909,
          lng: 49.3297882,
        },
        _id: "64f75285ef8f606073e9a49f",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "tanambiavy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3326473,
          lng: 49.32725139999999,
        },
        _id: "64f75285ef8f606073e9a4a0",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "ambonivato",
        __v: 0,
      },
      {
        coords: {
          lat: -17.335817,
          lng: 49.3500865,
        },
        _id: "64f75285ef8f606073e9a4a1",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "mananarahely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3336135,
          lng: 49.3805473,
        },
        _id: "64f75285ef8f606073e9a4a2",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "manakambahiny",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3217593,
          lng: 49.411024,
        },
        _id: "64f75285ef8f606073e9a4a3",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "antetezampafana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3553741,
          lng: 49.3907044,
        },
        _id: "64f75285ef8f606073e9a4a4",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "amboakarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3595519,
          lng: 49.3578588,
        },
        _id: "64f75285ef8f606073e9a4a5",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "maromitety",
        __v: 0,
      },
      {
        coords: {
          lat: -17.369695,
          lng: 49.37039189999999,
        },
        _id: "64f75285ef8f606073e9a4a6",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "marofinaritra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3961341,
          lng: 49.3602383,
        },
        _id: "64f75285ef8f606073e9a4a7",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "betampona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3881302,
          lng: 49.3373992,
        },
        _id: "64f75285ef8f606073e9a4a8",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "marovato I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4175907,
          lng: 49.3297882,
        },
        _id: "64f75285ef8f606073e9a4a9",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "vohitrambo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4343779,
          lng: 49.3196417,
        },
        _id: "64f75285ef8f606073e9a4aa",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "antanetilava II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4315091,
          lng: 49.3373992,
        },
        _id: "64f75285ef8f606073e9a4ab",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "marovato III",
        __v: 0,
      },
      {
        coords: {
          lat: -17.433333,
          lng: 49.34999999999999,
        },
        _id: "64f75285ef8f606073e9a4ac",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "ambonilaitra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4371949,
          lng: 49.37039189999999,
        },
        _id: "64f75285ef8f606073e9a4ad",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "ampasimpotsy II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4132372,
          lng: 49.3907044,
        },
        _id: "64f75285ef8f606073e9a4ae",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "antanetilava I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4325293,
          lng: 49.3907044,
        },
        _id: "64f75285ef8f606073e9a4af",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "ambodimanga I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4328343,
          lng: 49.4313508,
        },
        _id: "64f75285ef8f606073e9a4b0",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "ambinanin'iazafo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4518237,
          lng: 49.3907044,
        },
        _id: "64f75285ef8f606073e9a4b1",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambodimanga II",
        fokontany: "vohipenohely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.486891,
          lng: 49.455669,
        },
        _id: "64f75285ef8f606073e9a4b2",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahambo",
        fokontany: "mahambo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4521372,
          lng: 49.4313508,
        },
        _id: "64f75285ef8f606073e9a4b3",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahambo",
        fokontany: "antsikafoka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4807694,
          lng: 49.3907044,
        },
        _id: "64f75285ef8f606073e9a4b4",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahambo",
        fokontany: "sambolaza",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4768574,
          lng: 49.3551622,
        },
        _id: "64f75285ef8f606073e9a4b5",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahambo",
        fokontany: "fotsialanana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4897237,
          lng: 49.309497,
        },
        _id: "64f75285ef8f606073e9a4b6",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahambo",
        fokontany: "ambalafary",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4754371,
          lng: 49.3297882,
        },
        _id: "64f75285ef8f606073e9a4b7",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahambo",
        fokontany: "ambodigavo benavony",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5,
          lng: 49.333333,
        },
        _id: "64f75285ef8f606073e9a4b8",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahambo",
        fokontany: "ambodivoanio",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5032755,
          lng: 49.4135645,
        },
        _id: "64f75285ef8f606073e9a4b9",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahambo",
        fokontany: "androka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5140072,
          lng: 49.3983234,
        },
        _id: "64f75285ef8f606073e9a4ba",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahambo",
        fokontany: "ambodiforaha",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5340255,
          lng: 49.411024,
        },
        _id: "64f75285ef8f606073e9a4bb",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahambo",
        fokontany: "tanambao tanambazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5150835,
          lng: 49.3830864,
        },
        _id: "64f75285ef8f606073e9a4bc",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahambo",
        fokontany: "ambodiatafana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5311672,
          lng: 49.3602383,
        },
        _id: "64f75285ef8f606073e9a4bd",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahambo",
        fokontany: "ambodihasina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5282937,
          lng: 49.309497,
        },
        _id: "64f75285ef8f606073e9a4be",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahambo",
        fokontany: "antsiradava II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5590504,
          lng: 49.3754694,
        },
        _id: "64f75285ef8f606073e9a4bf",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahambo",
        fokontany: "lakandavaina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.570142,
          lng: 49.4008633,
        },
        _id: "64f75285ef8f606073e9a4c0",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahambo",
        fokontany: "mahadilo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5705048,
          lng: 49.4415168,
        },
        _id: "64f75285ef8f606073e9a4c1",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahambo",
        fokontany: "namahoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.21636,
          lng: 49.4146,
        },
        _id: "64f75285ef8f606073e9a4c2",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasina maningory",
        fokontany: "ampasina maningory",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1482894,
          lng: 49.411024,
        },
        _id: "64f75285ef8f606073e9a4c3",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasina maningory",
        fokontany: "marovato I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1677368,
          lng: 49.4516846,
        },
        _id: "64f75285ef8f606073e9a4c4",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasina maningory",
        fokontany: "ampasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1692529,
          lng: 49.388165,
        },
        _id: "64f75285ef8f606073e9a4c5",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasina maningory",
        fokontany: "anjahamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1552765,
          lng: 49.3602383,
        },
        _id: "64f75285ef8f606073e9a4c6",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasina maningory",
        fokontany: "ambinanindranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1817009,
          lng: 49.3500865,
        },
        _id: "64f75285ef8f606073e9a4c7",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasina maningory",
        fokontany: "mahatera",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1915388,
          lng: 49.3907044,
        },
        _id: "64f75285ef8f606073e9a4c8",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasina maningory",
        fokontany: "marotrano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1892798,
          lng: 49.4211865,
        },
        _id: "64f75285ef8f606073e9a4c9",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasina maningory",
        fokontany: "ambull dozer",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1960846,
          lng: 49.4593116,
        },
        _id: "64f75285ef8f606073e9a4ca",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasina maningory",
        fokontany: "tanambao I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2200861,
          lng: 49.4389751,
        },
        _id: "64f75285ef8f606073e9a4cb",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasina maningory",
        fokontany: "takobola",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2251451,
          lng: 49.37039189999999,
        },
        _id: "64f75285ef8f606073e9a4cc",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasina maningory",
        fokontany: "ronkonina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1979347,
          lng: 49.3475488,
        },
        _id: "64f75285ef8f606073e9a4cd",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasina maningory",
        fokontany: "andapabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2177516,
          lng: 49.3399364,
        },
        _id: "64f75285ef8f606073e9a4ce",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasina maningory",
        fokontany: "ambatomitrozona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2466405,
          lng: 49.3399364,
        },
        _id: "64f75285ef8f606073e9a4cf",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasina maningory",
        fokontany: "anorimbato",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2515769,
          lng: 49.3602383,
        },
        _id: "64f75285ef8f606073e9a4d0",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasina maningory",
        fokontany: "ambodimanga II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2475518,
          lng: 49.393244,
        },
        _id: "64f75285ef8f606073e9a4d1",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasina maningory",
        fokontany: "amboditononona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2495683,
          lng: 49.4313508,
        },
        _id: "64f75285ef8f606073e9a4d2",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasina maningory",
        fokontany: "rantolava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2807246,
          lng: 49.4008633,
        },
        _id: "64f75285ef8f606073e9a4d3",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasina maningory",
        fokontany: "tanambao tampolo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2661484,
          lng: 49.3805473,
        },
        _id: "64f75285ef8f606073e9a4d4",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasina maningory",
        fokontany: "antanambao II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3077353,
          lng: 49.2942833,
        },
        _id: "64f75285ef8f606073e9a4d5",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambatoharanana",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2367659,
          lng: 49.2993541,
        },
        _id: "64f75285ef8f606073e9a4d6",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambatoharanana",
        fokontany: "soberaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2518863,
          lng: 49.31203300000001,
        },
        _id: "64f75285ef8f606073e9a4d7",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambatoharanana",
        fokontany: "ambodivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2631728,
          lng: 49.2892129,
        },
        _id: "64f75285ef8f606073e9a4d8",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambatoharanana",
        fokontany: "saharina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2681179,
          lng: 49.309497,
        },
        _id: "64f75285ef8f606073e9a4d9",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambatoharanana",
        fokontany: "ambodihazinina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.301682,
          lng: 49.2892129,
        },
        _id: "64f75285ef8f606073e9a4da",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambatoharanana",
        fokontany: "mangoandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3241114,
          lng: 49.31203300000001,
        },
        _id: "64f75285ef8f606073e9a4db",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambatoharanana",
        fokontany: "anjahamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3305698,
          lng: 49.2892129,
        },
        _id: "64f75285ef8f606073e9a4dc",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambatoharanana",
        fokontany: "tsaratampona I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3448638,
          lng: 49.268936,
        },
        _id: "64f75285ef8f606073e9a4dd",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambatoharanana",
        fokontany: "beampy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3596916,
          lng: 49.24106680000001,
        },
        _id: "64f75285ef8f606073e9a4de",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambatoharanana",
        fokontany: "ambodimanga mahalena",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3855447,
          lng: 49.23853390000001,
        },
        _id: "64f75285ef8f606073e9a4df",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ambatoharanana",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2785741,
          lng: 49.2537329,
        },
        _id: "64f75285ef8f606073e9a4e0",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohilengo",
        fokontany: "vohilengo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2197367,
          lng: 49.268936,
        },
        _id: "64f75285ef8f606073e9a4e1",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohilengo",
        fokontany: "fandalazina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2436599,
          lng: 49.2486661,
        },
        _id: "64f75285ef8f606073e9a4e2",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohilengo",
        fokontany: "ambodiampaly",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2483342,
          lng: 49.2284034,
        },
        _id: "64f75285ef8f606073e9a4e3",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohilengo",
        fokontany: "vohipenohely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3060579,
          lng: 49.2284034,
        },
        _id: "64f75285ef8f606073e9a4e4",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohilengo",
        fokontany: "maroaomby",
        __v: 0,
      },
      {
        coords: {
          lat: -17.301096,
          lng: 49.2081479,
        },
        _id: "64f75285ef8f606073e9a4e5",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohilengo",
        fokontany: "behalo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3176175,
          lng: 49.2461329,
        },
        _id: "64f75285ef8f606073e9a4e6",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohilengo",
        fokontany: "ambodivoapaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3228204,
          lng: 49.2182748,
        },
        _id: "64f75285ef8f606073e9a4e7",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohilengo",
        fokontany: "ampasimbola mahatsara II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3346081,
          lng: 49.1878994,
        },
        _id: "64f75285ef8f606073e9a4e8",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohilengo",
        fokontany: "anorombato",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3588256,
          lng: 49.2081479,
        },
        _id: "64f75285ef8f606073e9a4e9",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohilengo",
        fokontany: "vohilava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3445516,
          lng: 49.2284034,
        },
        _id: "64f75285ef8f606073e9a4ea",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohilengo",
        fokontany: "ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3780732,
          lng: 49.2081479,
        },
        _id: "64f75285ef8f606073e9a4eb",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohilengo",
        fokontany: "tanambao doany",
        __v: 0,
      },
      {
        coords: {
          lat: -17.284463,
          lng: 49.184636,
        },
        _id: "64f75285ef8f606073e9a4ec",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "saranambana",
        fokontany: "saranambana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0005721,
          lng: 48.9404364,
        },
        _id: "64f75285ef8f606073e9a4ed",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "saranambana",
        fokontany: "ambilona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0323509,
          lng: 48.8586079,
        },
        _id: "64f75285ef8f606073e9a4ee",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "saranambana",
        fokontany: "beangaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0749912,
          lng: 48.9706803,
        },
        _id: "64f75285ef8f606073e9a4ef",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "saranambana",
        fokontany: "ambolozatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.153825,
          lng: 48.9404364,
        },
        _id: "64f75285ef8f606073e9a4f0",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "saranambana",
        fokontany: "bekotro",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1560899,
          lng: 49.0867644,
        },
        _id: "64f75285ef8f606073e9a4f1",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "saranambana",
        fokontany: "ambahavala",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1664946,
          lng: 49.0312164,
        },
        _id: "64f75285ef8f606073e9a4f2",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "saranambana",
        fokontany: "ampangamena",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1944886,
          lng: 49.0867644,
        },
        _id: "64f75285ef8f606073e9a4f3",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "saranambana",
        fokontany: "andongoza",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2246822,
          lng: 49.1120314,
        },
        _id: "64f75285ef8f606073e9a4f4",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "saranambana",
        fokontany: "andranomiditra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.21948,
          lng: 49.2284034,
        },
        _id: "64f75285ef8f606073e9a4f5",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "saranambana",
        fokontany: "ihazomena",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2334905,
          lng: 49.1676582,
        },
        _id: "64f75285ef8f606073e9a4f6",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "saranambana",
        fokontany: "ambodilaitra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2601447,
          lng: 49.1980228,
        },
        _id: "64f75285ef8f606073e9a4f7",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "saranambana",
        fokontany: "ambodihasina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.293337,
          lng: 49.1373096,
        },
        _id: "64f75285ef8f606073e9a4f8",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "saranambana",
        fokontany: "ampandrana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.284463,
          lng: 49.184636,
        },
        _id: "64f75285ef8f606073e9a4f9",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "saranambana",
        fokontany: "ambatrabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.097601,
          lng: 49.485569,
        },
        _id: "64f75285ef8f606073e9a4fa",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasimbe manantsatrana",
        fokontany: "ampasimbe manantsatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0581641,
          lng: 49.4567691,
        },
        _id: "64f75285ef8f606073e9a4fb",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasimbe manantsatrana",
        fokontany: "manakambahiny",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0690797,
          lng: 49.4821986,
        },
        _id: "64f75285ef8f606073e9a4fc",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasimbe manantsatrana",
        fokontany: "ambatotsondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1227274,
          lng: 49.4949175,
        },
        _id: "64f75285ef8f606073e9a4fd",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasimbe manantsatrana",
        fokontany: "anjahambe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0924528,
          lng: 49.4491425,
        },
        _id: "64f75285ef8f606073e9a4fe",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasimbe manantsatrana",
        fokontany: "antsara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0761376,
          lng: 49.4313508,
        },
        _id: "64f75285ef8f606073e9a4ff",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasimbe manantsatrana",
        fokontany: "marovovonana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0923032,
          lng: 49.4084837,
        },
        _id: "64f75285ef8f606073e9a500",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasimbe manantsatrana",
        fokontany: "anjahamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.080724,
          lng: 49.37039189999999,
        },
        _id: "64f75285ef8f606073e9a501",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasimbe manantsatrana",
        fokontany: "andongozabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0952425,
          lng: 49.3907044,
        },
        _id: "64f75285ef8f606073e9a502",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasimbe manantsatrana",
        fokontany: "antsiradava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.092953,
          lng: 49.4211865,
        },
        _id: "64f75285ef8f606073e9a503",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasimbe manantsatrana",
        fokontany: "ambodisatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1092727,
          lng: 49.4389751,
        },
        _id: "64f75285ef8f606073e9a504",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasimbe manantsatrana",
        fokontany: "ambodiampaly",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1352338,
          lng: 49.4567691,
        },
        _id: "64f75285ef8f606073e9a505",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasimbe manantsatrana",
        fokontany: "ambodibonara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.121845,
          lng: 49.4211865,
        },
        _id: "64f75285ef8f606073e9a506",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasimbe manantsatrana",
        fokontany: "ambanja",
        __v: 0,
      },
      {
        coords: {
          lat: -17.131302,
          lng: 49.3805473,
        },
        _id: "64f75285ef8f606073e9a507",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasimbe manantsatrana",
        fokontany: "marokiso",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1167724,
          lng: 49.3602383,
        },
        _id: "64f75285ef8f606073e9a508",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "ampasimbe manantsatrana",
        fokontany: "antenina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1747264,
          lng: 49.1828385,
        },
        _id: "64f75285ef8f606073e9a509",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "miorimivalana",
        fokontany: "miorimivalana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1615447,
          lng: 49.1878994,
        },
        _id: "64f75285ef8f606073e9a50a",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "miorimivalana",
        fokontany: "sahavontsira",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1045375,
          lng: 49.0918169,
        },
        _id: "64f75285ef8f606073e9a50b",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "miorimivalana",
        fokontany: "ambarimbalavo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1785507,
          lng: 49.2182748,
        },
        _id: "64f75285ef8f606073e9a50c",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "miorimivalana",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1955632,
          lng: 49.2486661,
        },
        _id: "64f75285ef8f606073e9a50d",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "miorimivalana",
        fokontany: "antsiradava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2095993,
          lng: 49.1878994,
        },
        _id: "64f75285ef8f606073e9a50e",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "miorimivalana",
        fokontany: "ambinanivolosy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.167601,
          lng: 49.288219,
        },
        _id: "64f75285ef8f606073e9a50f",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohipeno",
        fokontany: "vohipeno",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0911254,
          lng: 49.2740045,
        },
        _id: "64f75285ef8f606073e9a510",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohipeno",
        fokontany: "ampasimbola II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0852785,
          lng: 49.309497,
        },
        _id: "64f75285ef8f606073e9a511",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohipeno",
        fokontany: "ambohimanakana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0926262,
          lng: 49.3399364,
        },
        _id: "64f75285ef8f606073e9a512",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohipeno",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1394123,
          lng: 49.3145691,
        },
        _id: "64f75285ef8f606073e9a513",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohipeno",
        fokontany: "ampasimahatera",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1331669,
          lng: 49.268936,
        },
        _id: "64f75285ef8f606073e9a514",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohipeno",
        fokontany: "anamborano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0956277,
          lng: 49.2132111,
        },
        _id: "64f75285ef8f606073e9a515",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohipeno",
        fokontany: "antanetilava I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1304894,
          lng: 49.2182748,
        },
        _id: "64f75285ef8f606073e9a516",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohipeno",
        fokontany: "ambatoharanana III",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1503857,
          lng: 49.2309359,
        },
        _id: "64f75285ef8f606073e9a517",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohipeno",
        fokontany: "ambodiara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1456921,
          lng: 49.2511995,
        },
        _id: "64f75285ef8f606073e9a518",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohipeno",
        fokontany: "ambinanisahonotro",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1839453,
          lng: 49.3196417,
        },
        _id: "64f75285ef8f606073e9a519",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohipeno",
        fokontany: "maromandia",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2078915,
          lng: 49.2993541,
        },
        _id: "64f75285ef8f606073e9a51a",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohipeno",
        fokontany: "ankobahoba",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1926637,
          lng: 49.2664018,
        },
        _id: "64f75285ef8f606073e9a51b",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "vohipeno",
        fokontany: "sahandray",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9870129,
          lng: 49.207058,
        },
        _id: "64f75285ef8f606073e9a51c",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "antsiatsiaka",
        fokontany: "antsiatsiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9773941,
          lng: 49.0817124,
        },
        _id: "64f75285ef8f606073e9a51d",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "antsiatsiaka",
        fokontany: "vohirandranina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9995755,
          lng: 49.2132111,
        },
        _id: "64f75285ef8f606073e9a51e",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "antsiatsiaka",
        fokontany: "manakambahiny II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.047779,
          lng: 49.2537329,
        },
        _id: "64f75285ef8f606073e9a51f",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "antsiatsiaka",
        fokontany: "ambodimanga II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9998911,
          lng: 49.2942833,
        },
        _id: "64f75285ef8f606073e9a520",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "antsiatsiaka",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9892635,
          lng: 49.3500865,
        },
        _id: "64f75285ef8f606073e9a521",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "antsiatsiaka",
        fokontany: "ambakoana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0014395,
          lng: 49.4008633,
        },
        _id: "64f75285ef8f606073e9a522",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "antsiatsiaka",
        fokontany: "ambodihazinina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.048405,
          lng: 49.41610499999999,
        },
        _id: "64f75285ef8f606073e9a523",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "antsiatsiaka",
        fokontany: "vohimafaitra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0252918,
          lng: 49.3399364,
        },
        _id: "64f75285ef8f606073e9a524",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "antsiatsiaka",
        fokontany: "andranomiditra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0468127,
          lng: 49.309497,
        },
        _id: "64f75285ef8f606073e9a525",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "antsiatsiaka",
        fokontany: "ambohangy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0565899,
          lng: 49.3500865,
        },
        _id: "64f75285ef8f606073e9a526",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "antsiatsiaka",
        fokontany: "tananambo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.070604,
          lng: 49.3983234,
        },
        _id: "64f75285ef8f606073e9a527",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "antsiatsiaka",
        fokontany: "ambodirotra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3512226,
          lng: 49.3145691,
        },
        _id: "64f75285ef8f606073e9a528",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahanoro",
        fokontany: "mahanoro",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3492858,
          lng: 49.29681859999999,
        },
        _id: "64f75285ef8f606073e9a529",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahanoro",
        fokontany: "ambalabe II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3626444,
          lng: 49.31203300000001,
        },
        _id: "64f75285ef8f606073e9a52a",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahanoro",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3619474,
          lng: 49.2993541,
        },
        _id: "64f75285ef8f606073e9a52b",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahanoro",
        fokontany: "tanetilava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3660653,
          lng: 49.2866779,
        },
        _id: "64f75285ef8f606073e9a52c",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahanoro",
        fokontany: "vohitsoa nord",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3735217,
          lng: 49.31710529999999,
        },
        _id: "64f75285ef8f606073e9a52d",
        province: "toamasina",
        region: "analanjirofo",
        district: "fenerive est",
        commune: "mahanoro",
        fokontany: "andratanimoina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4652462,
          lng: 49.2005539,
        },
        _id: "64f75285ef8f606073e9a52e",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "ankazomianko",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4069489,
          lng: 49.2081479,
        },
        _id: "64f75285ef8f606073e9a52f",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "marofinaritra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4212084,
          lng: 49.1878994,
        },
        _id: "64f75285ef8f606073e9a530",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "ambalarongana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4329602,
          lng: 49.1575402,
        },
        _id: "64f75285ef8f606073e9a531",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "tsaramainandro",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4408252,
          lng: 49.2284034,
        },
        _id: "64f75285ef8f606073e9a532",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "vohilengo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4689489,
          lng: 49.147424,
        },
        _id: "64f75285ef8f606073e9a533",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "mahanoro",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4613008,
          lng: 49.16512849999999,
        },
        _id: "64f75285ef8f606073e9a534",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "ambolofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4556179,
          lng: 49.2005539,
        },
        _id: "64f75285ef8f606073e9a535",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "ampasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4634123,
          lng: 49.1347813,
        },
        _id: "64f75285ef8f606073e9a536",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "tanambiavy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4831818,
          lng: 49.127197,
        },
        _id: "64f75285ef8f606073e9a537",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "bongarano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4747203,
          lng: 49.2486661,
        },
        _id: "64f75285ef8f606073e9a538",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.496111,
          lng: 49.2182748,
        },
        _id: "64f75285ef8f606073e9a539",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "mahatera",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4932106,
          lng: 49.1676582,
        },
        _id: "64f75285ef8f606073e9a53a",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "ambodihasina I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4885953,
          lng: 49.1878994,
        },
        _id: "64f75285ef8f606073e9a53b",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "antoby",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5099558,
          lng: 49.1575402,
        },
        _id: "64f75285ef8f606073e9a53c",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "ambonihoraka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.512869,
          lng: 49.2081479,
        },
        _id: "64f75285ef8f606073e9a53d",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "tanamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3015427,
          lng: 49.1120314,
        },
        _id: "64f75285ef8f606073e9a53e",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.533333,
          lng: 49.133333,
        },
        _id: "64f75285ef8f606073e9a53f",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "vohitsivalana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5271141,
          lng: 49.1878994,
        },
        _id: "64f75285ef8f606073e9a540",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "ambodivato II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5358936,
          lng: 49.10697709999999,
        },
        _id: "64f75285ef8f606073e9a541",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "ambalatenina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5574755,
          lng: 49.2132111,
        },
        _id: "64f75285ef8f606073e9a542",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "vohibarihely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5606113,
          lng: 49.1676582,
        },
        _id: "64f75285ef8f606073e9a543",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "vavatenina",
        fokontany: "ambalakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4652462,
          lng: 49.2005539,
        },
        _id: "64f75285ef8f606073e9a544",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "maromitety",
        fokontany: "maromitety",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4001557,
          lng: 49.2588002,
        },
        _id: "64f75285ef8f606073e9a545",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "maromitety",
        fokontany: "ambatohasana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4511624,
          lng: 49.309497,
        },
        _id: "64f75285ef8f606073e9a546",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "maromitety",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4599145,
          lng: 49.276539,
        },
        _id: "64f75285ef8f606073e9a547",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "maromitety",
        fokontany: "ankorovana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.474549,
          lng: 49.29681859999999,
        },
        _id: "64f75285ef8f606073e9a548",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "maromitety",
        fokontany: "antsirakoraka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4725851,
          lng: 49.2790736,
        },
        _id: "64f75285ef8f606073e9a549",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "maromitety",
        fokontany: "vohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4815059,
          lng: 49.2664018,
        },
        _id: "64f75285ef8f606073e9a54a",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "maromitety",
        fokontany: "ambalakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4943624,
          lng: 49.2892129,
        },
        _id: "64f75285ef8f606073e9a54b",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "maromitety",
        fokontany: "ambodimanga sahavaviana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4911427,
          lng: 49.2664018,
        },
        _id: "64f75285ef8f606073e9a54c",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "maromitety",
        fokontany: "antsirabe sud",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5061299,
          lng: 49.2588002,
        },
        _id: "64f75285ef8f606073e9a54d",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "maromitety",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5207783,
          lng: 49.2790736,
        },
        _id: "64f75285ef8f606073e9a54e",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "maromitety",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5482472,
          lng: 49.2537329,
        },
        _id: "64f75285ef8f606073e9a54f",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "maromitety",
        fokontany: "vavazahana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.44095,
          lng: 49.131981,
        },
        _id: "64f75285ef8f606073e9a550",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambohibe",
        fokontany: "ambohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4133426,
          lng: 49.1170862,
        },
        _id: "64f75285ef8f606073e9a551",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambohibe",
        fokontany: "analanandiana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4200678,
          lng: 49.0665589,
        },
        _id: "64f75285ef8f606073e9a552",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambohibe",
        fokontany: "andakazera",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4306034,
          lng: 49.0993965,
        },
        _id: "64f75285ef8f606073e9a553",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambohibe",
        fokontany: "andapavola",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4406109,
          lng: 49.139838,
        },
        _id: "64f75285ef8f606073e9a554",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambohibe",
        fokontany: "ankoraka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4249303,
          lng: 49.1347813,
        },
        _id: "64f75285ef8f606073e9a555",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambohibe",
        fokontany: "saranonjana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4556952,
          lng: 49.0842383,
        },
        _id: "64f75285ef8f606073e9a556",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambohibe",
        fokontany: "ambohimahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4595655,
          lng: 49.0514094,
        },
        _id: "64f75285ef8f606073e9a557",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambohibe",
        fokontany: "anjiro",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4589286,
          lng: 49.10697709999999,
        },
        _id: "64f75285ef8f606073e9a558",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambohibe",
        fokontany: "ambohimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4852773,
          lng: 49.09686989999999,
        },
        _id: "64f75285ef8f606073e9a559",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambohibe",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4929947,
          lng: 49.0312164,
        },
        _id: "64f75285ef8f606073e9a55a",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambohibe",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5222811,
          lng: 49.0716096,
        },
        _id: "64f75285ef8f606073e9a55b",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambohibe",
        fokontany: "ampasina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5557192,
          lng: 49.0514094,
        },
        _id: "64f75285ef8f606073e9a55c",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambohibe",
        fokontany: "ambolomadinika I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.55,
          lng: 49.016667,
        },
        _id: "64f75285ef8f606073e9a55d",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambohibe",
        fokontany: "ambatomanana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.671847,
          lng: 49.1449217,
        },
        _id: "64f75285ef8f606073e9a55e",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ampasimazava",
        fokontany: "ampasimazava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3803538,
          lng: 49.2664018,
        },
        _id: "64f75285ef8f606073e9a55f",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ampasimazava",
        fokontany: "ambodihazovola",
        __v: 0,
      },
      {
        coords: {
          lat: -17.37927,
          lng: 49.2816082,
        },
        _id: "64f75285ef8f606073e9a560",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ampasimazava",
        fokontany: "ampahibe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3854885,
          lng: 49.3069611,
        },
        _id: "64f75285ef8f606073e9a561",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ampasimazava",
        fokontany: "ampasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3940378,
          lng: 49.3221781,
        },
        _id: "64f75285ef8f606073e9a562",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ampasimazava",
        fokontany: "ambohitsaralaza",
        __v: 0,
      },
      {
        coords: {
          lat: -17.419215,
          lng: 49.3069611,
        },
        _id: "64f75285ef8f606073e9a563",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ampasimazava",
        fokontany: "vohibirimo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4251133,
          lng: 49.291748,
        },
        _id: "64f75285ef8f606073e9a564",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ampasimazava",
        fokontany: "ambatomipaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3850764,
          lng: 49.131621,
        },
        _id: "64f75285ef8f606073e9a565",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "anjahambe",
        fokontany: "anjahambe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3128859,
          lng: 49.1777779,
        },
        _id: "64f75285ef8f606073e9a566",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "anjahambe",
        fokontany: "marangibato",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3218013,
          lng: 49.16512849999999,
        },
        _id: "64f75285ef8f606073e9a567",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "anjahambe",
        fokontany: "ambodivoahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3488713,
          lng: 49.1676582,
        },
        _id: "64f75285ef8f606073e9a568",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "anjahambe",
        fokontany: "ambodimanga I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3627625,
          lng: 49.1752478,
        },
        _id: "64f75285ef8f606073e9a569",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "anjahambe",
        fokontany: "ambodimangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3807574,
          lng: 49.17018789999999,
        },
        _id: "64f75285ef8f606073e9a56a",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "anjahambe",
        fokontany: "ankorabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.379503,
          lng: 49.09686989999999,
        },
        _id: "64f75285ef8f606073e9a56b",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "anjahambe",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3887701,
          lng: 49.1246691,
        },
        _id: "64f75285ef8f606073e9a56c",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "anjahambe",
        fokontany: "ambohimanjato",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3944756,
          lng: 49.1575402,
        },
        _id: "64f75285ef8f606073e9a56d",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "anjahambe",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4142487,
          lng: 49.1499529,
        },
        _id: "64f75285ef8f606073e9a56e",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "anjahambe",
        fokontany: "ambodinonoka I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3293015,
          lng: 49.127197,
        },
        _id: "64f75285ef8f606073e9a56f",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambatoharanana",
        fokontany: "ambatoharanana I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.277358,
          lng: 49.0918169,
        },
        _id: "64f75285ef8f606073e9a570",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambatoharanana",
        fokontany: "vohitsivalana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3051047,
          lng: 49.10697709999999,
        },
        _id: "64f75285ef8f606073e9a571",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambatoharanana",
        fokontany: "ambodinonoka II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3132655,
          lng: 49.1499529,
        },
        _id: "64f75285ef8f606073e9a572",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambatoharanana",
        fokontany: "manakambahiny",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3360594,
          lng: 49.1448952,
        },
        _id: "64f75285ef8f606073e9a573",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambatoharanana",
        fokontany: "mahambo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3339358,
          lng: 49.10697709999999,
        },
        _id: "64f75285ef8f606073e9a574",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambatoharanana",
        fokontany: "vatove",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3552912,
          lng: 49.1448952,
        },
        _id: "64f75285ef8f606073e9a575",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambatoharanana",
        fokontany: "antsirabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3551166,
          lng: 49.1246691,
        },
        _id: "64f75285ef8f606073e9a576",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambatoharanana",
        fokontany: "ambalabe I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3338527,
          lng: 48.9013464,
        },
        _id: "64f75285ef8f606073e9a577",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "andasibe",
        fokontany: "andasibe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2305072,
          lng: 48.9404364,
        },
        _id: "64f75285ef8f606073e9a578",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "andasibe",
        fokontany: "antsirabe II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3338527,
          lng: 48.9013464,
        },
        _id: "64f75285ef8f606073e9a579",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "andasibe",
        fokontany: "tabonanaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2574881,
          lng: 49.0110305,
        },
        _id: "64f75285ef8f606073e9a57a",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "andasibe",
        fokontany: "ampatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2674241,
          lng: 49.0514094,
        },
        _id: "64f75285ef8f606073e9a57b",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "andasibe",
        fokontany: "sakana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3189954,
          lng: 49.0463605,
        },
        _id: "64f75285ef8f606073e9a57c",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "andasibe",
        fokontany: "ambodisatrana II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3718823,
          lng: 48.9303587,
        },
        _id: "64f75285ef8f606073e9a57d",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "andasibe",
        fokontany: "ambatoharanana mandiamanga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3108113,
          lng: 49.0716096,
        },
        _id: "64f75285ef8f606073e9a57e",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "andasibe",
        fokontany: "ambinanisalohy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3645256,
          lng: 49.036264,
        },
        _id: "64f75285ef8f606073e9a57f",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "andasibe",
        fokontany: "vohibinany",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3376927,
          lng: 48.86993030000001,
        },
        _id: "64f75285ef8f606073e9a580",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "andasibe",
        fokontany: "bemanasy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3968981,
          lng: 49.0312164,
        },
        _id: "64f75285ef8f606073e9a581",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "andasibe",
        fokontany: "anjahamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6473082,
          lng: 49.1676582,
        },
        _id: "64f75285ef8f606073e9a582",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "miarinarivo",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5692832,
          lng: 49.1347813,
        },
        _id: "64f75285ef8f606073e9a583",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "miarinarivo",
        fokontany: "sahafara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6010557,
          lng: 49.2334685,
        },
        _id: "64f75285ef8f606073e9a584",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "miarinarivo",
        fokontany: "ampasimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6041779,
          lng: 49.1878994,
        },
        _id: "64f75285ef8f606073e9a585",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "miarinarivo",
        fokontany: "antenimbe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5951308,
          lng: 49.1322531,
        },
        _id: "64f75285ef8f606073e9a586",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "miarinarivo",
        fokontany: "androrangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.623545,
          lng: 49.0918169,
        },
        _id: "64f75285ef8f606073e9a587",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "miarinarivo",
        fokontany: "ambodiampaly",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6467375,
          lng: 49.22333889999999,
        },
        _id: "64f75285ef8f606073e9a588",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "miarinarivo",
        fokontany: "sahavatana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7015773,
          lng: 49.1727178,
        },
        _id: "64f75285ef8f606073e9a589",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "miarinarivo",
        fokontany: "bongabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6934759,
          lng: 49.1019233,
        },
        _id: "64f75285ef8f606073e9a58a",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "miarinarivo",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6913521,
          lng: 48.9404364,
        },
        _id: "64f75285ef8f606073e9a58b",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "miarinarivo",
        fokontany: "anamborano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4492729,
          lng: 48.9939843,
        },
        _id: "64f75285ef8f606073e9a58c",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "sahatavy",
        fokontany: "sahatavy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4071434,
          lng: 48.9757225,
        },
        _id: "64f75285ef8f606073e9a58d",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "sahatavy",
        fokontany: "ambodimanga moratelo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4317782,
          lng: 49.036264,
        },
        _id: "64f75285ef8f606073e9a58e",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "sahatavy",
        fokontany: "sazomena",
        __v: 0,
      },
      {
        coords: {
          lat: -17.452562,
          lng: 49.0135533,
        },
        _id: "64f75285ef8f606073e9a58f",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "sahatavy",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4678583,
          lng: 48.9303587,
        },
        _id: "64f75285ef8f606073e9a590",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "sahatavy",
        fokontany: "ambavala",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4406635,
          lng: 48.8598651,
        },
        _id: "64f75285ef8f606073e9a591",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "sahatavy",
        fokontany: "tsarasambo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4672751,
          lng: 48.9858082,
        },
        _id: "64f75285ef8f606073e9a592",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "sahatavy",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5941953,
          lng: 48.8598651,
        },
        _id: "64f75285ef8f606073e9a593",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambodimangavalo",
        fokontany: "ambodimangavalo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4839743,
          lng: 48.9757225,
        },
        _id: "64f75285ef8f606073e9a594",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambodimangavalo",
        fokontany: "vohiboangy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5057042,
          lng: 48.9858082,
        },
        _id: "64f75285ef8f606073e9a595",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambodimangavalo",
        fokontany: "andratambe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.505294,
          lng: 48.84980179999999,
        },
        _id: "64f75285ef8f606073e9a596",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambodimangavalo",
        fokontany: "ambodirafia II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5628749,
          lng: 48.9454759,
        },
        _id: "64f75285ef8f606073e9a597",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambodimangavalo",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5769987,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9a598",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambodimangavalo",
        fokontany: "antevibe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6215338,
          lng: 48.9303587,
        },
        _id: "64f75285ef8f606073e9a599",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambodimangavalo",
        fokontany: "manakambahiny I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6122912,
          lng: 48.7794089,
        },
        _id: "64f75285ef8f606073e9a59a",
        province: "toamasina",
        region: "analanjirofo",
        district: "vavatenina",
        commune: "ambodimangavalo",
        fokontany: "ambodivoangy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9214298,
          lng: 49.5853012,
        },
        _id: "64f75285ef8f606073e9a59b",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "antsiragavo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9054566,
          lng: 49.5636473,
        },
        _id: "64f75285ef8f606073e9a59c",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "anamborano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8849728,
          lng: 49.5381824,
        },
        _id: "64f75285ef8f606073e9a59d",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "ambodivoanio",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9214298,
          lng: 49.5853012,
        },
        _id: "64f75285ef8f606073e9a59e",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "tanambao matsokely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9133002,
          lng: 49.5865752,
        },
        _id: "64f75285ef8f606073e9a59f",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "ambinany",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9367777,
          lng: 49.5738364,
        },
        _id: "64f75285ef8f606073e9a5a0",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "andatsadrano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9234943,
          lng: 49.5381824,
        },
        _id: "64f75285ef8f606073e9a5a1",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "vohilava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9432159,
          lng: 49.542638,
        },
        _id: "64f75285ef8f606073e9a5a2",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "andilankely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9559968,
          lng: 49.5330908,
        },
        _id: "64f75285ef8f606073e9a5a3",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "anjahamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.956409,
          lng: 49.51979799999999,
        },
        _id: "64f75285ef8f606073e9a5a4",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "ampasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9541301,
          lng: 49.4949175,
        },
        _id: "64f75285ef8f606073e9a5a5",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "sahamalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -16.960843,
          lng: 49.55346,
        },
        _id: "64f75285ef8f606073e9a5a6",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "menatany",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9751898,
          lng: 49.4923735,
        },
        _id: "64f75285ef8f606073e9a5a7",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "ankobalava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9824717,
          lng: 49.5229088,
        },
        _id: "64f75285ef8f606073e9a5a8",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "antsaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9831308,
          lng: 49.5560067,
        },
        _id: "64f75285ef8f606073e9a5a9",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "andavaniobe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9969618,
          lng: 49.5432745,
        },
        _id: "64f75285ef8f606073e9a5aa",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "sahaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.990049,
          lng: 49.47884,
        },
        _id: "64f75285ef8f606073e9a5ab",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "ambinanisakana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9919251,
          lng: 49.4415168,
        },
        _id: "64f75285ef8f606073e9a5ac",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "andratambe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0161468,
          lng: 49.50255019999999,
        },
        _id: "64f75285ef8f606073e9a5ad",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "sahazahana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0208049,
          lng: 49.4415168,
        },
        _id: "64f75285ef8f606073e9a5ae",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "beankora",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0256755,
          lng: 49.4618542,
        },
        _id: "64f75285ef8f606073e9a5af",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "andranomiditra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.024028,
          lng: 49.5254541,
        },
        _id: "64f75285ef8f606073e9a5b0",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "manankinany",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0401783,
          lng: 49.4821986,
        },
        _id: "64f75285ef8f606073e9a5b1",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "tsirarafana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0571228,
          lng: 49.5127286,
        },
        _id: "64f75285ef8f606073e9a5b2",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "soanierana ivongo",
        fokontany: "manakatafana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.92617,
          lng: 49.354771,
        },
        _id: "64f75285ef8f606073e9a5b3",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "fotsialanana",
        fokontany: "fotsialanana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8404287,
          lng: 49.4923735,
        },
        _id: "64f75285ef8f606073e9a5b4",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "fotsialanana",
        fokontany: "antsirabeniseva",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8572601,
          lng: 49.4821986,
        },
        _id: "64f75285ef8f606073e9a5b5",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "fotsialanana",
        fokontany: "antevialaniseva",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8837503,
          lng: 49.5127286,
        },
        _id: "64f75285ef8f606073e9a5b6",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "fotsialanana",
        fokontany: "sahafary",
        __v: 0,
      },
      {
        coords: {
          lat: -16.898169,
          lng: 49.4923735,
        },
        _id: "64f75285ef8f606073e9a5b7",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "fotsialanana",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8981174,
          lng: 49.4516846,
        },
        _id: "64f75285ef8f606073e9a5b8",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "fotsialanana",
        fokontany: "vohidromba",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9204585,
          lng: 49.5152735,
        },
        _id: "64f75285ef8f606073e9a5b9",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "fotsialanana",
        fokontany: "fandrianomby",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9336296,
          lng: 49.4694825,
        },
        _id: "64f75285ef8f606073e9a5ba",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "fotsialanana",
        fokontany: "tanambao fotsialanana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9582807,
          lng: 49.4618542,
        },
        _id: "64f75285ef8f606073e9a5bb",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "fotsialanana",
        fokontany: "ambodibonara",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8741615,
          lng: 49.6350078,
        },
        _id: "64f75285ef8f606073e9a5bc",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antanifotsy",
        fokontany: "antanifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7826437,
          lng: 49.61461019999999,
        },
        _id: "64f75285ef8f606073e9a5bd",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antanifotsy",
        fokontany: "ampasimazava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7934547,
          lng: 49.6401083,
        },
        _id: "64f75285ef8f606073e9a5be",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antanifotsy",
        fokontany: "antanetilava II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8223247,
          lng: 49.6809281,
        },
        _id: "64f75285ef8f606073e9a5bf",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antanifotsy",
        fokontany: "manjato",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8181494,
          lng: 49.6324577,
        },
        _id: "64f75285ef8f606073e9a5c0",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antanifotsy",
        fokontany: "ambodibonara II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8211712,
          lng: 49.61461019999999,
        },
        _id: "64f75285ef8f606073e9a5c1",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antanifotsy",
        fokontany: "sahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8476668,
          lng: 49.6044141,
        },
        _id: "64f75285ef8f606073e9a5c2",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antanifotsy",
        fokontany: "namantoana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8452486,
          lng: 49.6350078,
        },
        _id: "64f75285ef8f606073e9a5c3",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antanifotsy",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8475437,
          lng: 49.7677638,
        },
        _id: "64f75285ef8f606073e9a5c4",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antanifotsy",
        fokontany: "antsiraka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.863184,
          lng: 49.668688,
        },
        _id: "64f75285ef8f606073e9a5c5",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antanifotsy",
        fokontany: "andrangazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8693397,
          lng: 49.5738364,
        },
        _id: "64f75285ef8f606073e9a5c6",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antanifotsy",
        fokontany: "marovinanto",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8675373,
          lng: 49.5967682,
        },
        _id: "64f75285ef8f606073e9a5c7",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antanifotsy",
        fokontany: "marogisy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8091559,
          lng: 49.5636473,
        },
        _id: "64f75285ef8f606073e9a5c8",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambodiampana",
        fokontany: "ambodiampana I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.797091,
          lng: 49.6001509,
        },
        _id: "64f75285ef8f606073e9a5c9",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambodiampana",
        fokontany: "ambatoifatra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8181724,
          lng: 49.5916715,
        },
        _id: "64f75285ef8f606073e9a5ca",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambodiampana",
        fokontany: "ambodibonara I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8235992,
          lng: 49.5432745,
        },
        _id: "64f75285ef8f606073e9a5cb",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambodiampana",
        fokontany: "ambodiriana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8338259,
          lng: 49.5763839,
        },
        _id: "64f75285ef8f606073e9a5cc",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambodiampana",
        fokontany: "ambodiampana II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.916076,
          lng: 49.3941931,
        },
        _id: "64f75285ef8f606073e9a5cd",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "andapafito",
        fokontany: "andapafito",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8004769,
          lng: 49.22333889999999,
        },
        _id: "64f75285ef8f606073e9a5ce",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "andapafito",
        fokontany: "iampirano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7852411,
          lng: 48.9202828,
        },
        _id: "64f75285ef8f606073e9a5cf",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "andapafito",
        fokontany: "sahamirary",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8000759,
          lng: 49.0615086,
        },
        _id: "64f75285ef8f606073e9a5d0",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "andapafito",
        fokontany: "ambodiamontana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8605535,
          lng: 49.2740045,
        },
        _id: "64f75285ef8f606073e9a5d1",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "andapafito",
        fokontany: "amberomanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8739841,
          lng: 49.3907044,
        },
        _id: "64f75285ef8f606073e9a5d2",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "andapafito",
        fokontany: "andranopoza",
        __v: 0,
      },
      {
        coords: {
          lat: -16.888996,
          lng: 49.1524819,
        },
        _id: "64f75285ef8f606073e9a5d3",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "andapafito",
        fokontany: "antara",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8938344,
          lng: 49.4034033,
        },
        _id: "64f75285ef8f606073e9a5d4",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "andapafito",
        fokontany: "ambatobe I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.893278,
          lng: 49.4313508,
        },
        _id: "64f75285ef8f606073e9a5d5",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "andapafito",
        fokontany: "ambatobe II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9473131,
          lng: 49.3957836,
        },
        _id: "64f75285ef8f606073e9a5d6",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "andapafito",
        fokontany: "ambodisambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9197127,
          lng: 49.4211865,
        },
        _id: "64f75285ef8f606073e9a5d7",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "andapafito",
        fokontany: "marolambo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9123547,
          lng: 49.3500865,
        },
        _id: "64f75285ef8f606073e9a5d8",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "andapafito",
        fokontany: "bevovonana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9146845,
          lng: 49.3196417,
        },
        _id: "64f75285ef8f606073e9a5d9",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "andapafito",
        fokontany: "sahafiana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9438039,
          lng: 49.4415168,
        },
        _id: "64f75285ef8f606073e9a5da",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "andapafito",
        fokontany: "ambodinanto",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9373727,
          lng: 49.2740045,
        },
        _id: "64f75285ef8f606073e9a5db",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "andapafito",
        fokontany: "andratambe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9579863,
          lng: 49.3399364,
        },
        _id: "64f75285ef8f606073e9a5dc",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "andapafito",
        fokontany: "sihanabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6901721,
          lng: 49.4872858,
        },
        _id: "64f75285ef8f606073e9a5dd",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambahoabe",
        fokontany: "ambahoabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5725908,
          lng: 49.4364335,
        },
        _id: "64f75285ef8f606073e9a5de",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambahoabe",
        fokontany: "antanambao sahateza",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5868623,
          lng: 49.4974616,
        },
        _id: "64f75285ef8f606073e9a5df",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambahoabe",
        fokontany: "mandoakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6468186,
          lng: 49.5483671,
        },
        _id: "64f75285ef8f606073e9a5e0",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambahoabe",
        fokontany: "menara",
        __v: 0,
      },
      {
        coords: {
          lat: -16.621751,
          lng: 49.4618542,
        },
        _id: "64f75285ef8f606073e9a5e1",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambahoabe",
        fokontany: "sasomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7118527,
          lng: 49.3754694,
        },
        _id: "64f75285ef8f606073e9a5e2",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambahoabe",
        fokontany: "ambinaniroa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7345964,
          lng: 49.5330908,
        },
        _id: "64f75285ef8f606073e9a5e3",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambahoabe",
        fokontany: "maromandia II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7046076,
          lng: 49.4669396,
        },
        _id: "64f75285ef8f606073e9a5e4",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambahoabe",
        fokontany: "ambarimay",
        __v: 0,
      },
      {
        coords: {
          lat: -16.758631,
          lng: 49.55346,
        },
        _id: "64f75285ef8f606073e9a5e5",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambahoabe",
        fokontany: "ambatolalaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7634836,
          lng: 49.4516846,
        },
        _id: "64f75285ef8f606073e9a5e6",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambahoabe",
        fokontany: "ambodirafia",
        __v: 0,
      },
      {
        coords: {
          lat: -16.772301,
          lng: 49.428669,
        },
        _id: "64f75285ef8f606073e9a5e7",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambahoabe",
        fokontany: "ambavala",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7676861,
          lng: 49.4796552,
        },
        _id: "64f75285ef8f606073e9a5e8",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambahoabe",
        fokontany: "ambahikarabo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7851134,
          lng: 49.50255019999999,
        },
        _id: "64f75285ef8f606073e9a5e9",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambahoabe",
        fokontany: "antanambao I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7842025,
          lng: 49.5343636,
        },
        _id: "64f75285ef8f606073e9a5ea",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambahoabe",
        fokontany: "antanetilava I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8,
          lng: 49.366667,
        },
        _id: "64f75285ef8f606073e9a5eb",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambahoabe",
        fokontany: "vatomora",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8067583,
          lng: 49.5127286,
        },
        _id: "64f75285ef8f606073e9a5ec",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambahoabe",
        fokontany: "antseranamatso",
        __v: 0,
      },
      {
        coords: {
          lat: -16.813962,
          lng: 49.4618542,
        },
        _id: "64f75285ef8f606073e9a5ed",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambahoabe",
        fokontany: "amboditolongo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.842732,
          lng: 49.3805473,
        },
        _id: "64f75285ef8f606073e9a5ee",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambahoabe",
        fokontany: "ambalafary",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8559933,
          lng: 49.41610499999999,
        },
        _id: "64f75285ef8f606073e9a5ef",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "ambahoabe",
        fokontany: "antsirabe sandrangato",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6830069,
          lng: 49.7549858,
        },
        _id: "64f75285ef8f606073e9a5f0",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "manompana",
        fokontany: "manompana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6153902,
          lng: 49.619709,
        },
        _id: "64f75285ef8f606073e9a5f1",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "manompana",
        fokontany: "ankoriraika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6473882,
          lng: 49.77798809999999,
        },
        _id: "64f75285ef8f606073e9a5f2",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "manompana",
        fokontany: "anove sud",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6343973,
          lng: 49.7013486,
        },
        _id: "64f75285ef8f606073e9a5f3",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "manompana",
        fokontany: "moronivo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7116254,
          lng: 49.619709,
        },
        _id: "64f75285ef8f606073e9a5f4",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "manompana",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7561486,
          lng: 49.6248081,
        },
        _id: "64f75285ef8f606073e9a5f5",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "manompana",
        fokontany: "vohijiny",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7933483,
          lng: 49.7217761,
        },
        _id: "64f75285ef8f606073e9a5f6",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "manompana",
        fokontany: "tanambao ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4651003,
          lng: 49.2638678,
        },
        _id: "64f75285ef8f606073e9a5f7",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antenina",
        fokontany: "sahavalanina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4461146,
          lng: 49.1828385,
        },
        _id: "64f75285ef8f606073e9a5f8",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antenina",
        fokontany: "antsimanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4876831,
          lng: 49.3399364,
        },
        _id: "64f75285ef8f606073e9a5f9",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antenina",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6087231,
          lng: 49.0615086,
        },
        _id: "64f75285ef8f606073e9a5fa",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antenina",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5310322,
          lng: 49.1575402,
        },
        _id: "64f75285ef8f606073e9a5fb",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antenina",
        fokontany: "antevialabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.55442,
          lng: 49.220901,
        },
        _id: "64f75285ef8f606073e9a5fc",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antenina",
        fokontany: "sahateza",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5896367,
          lng: 49.1423666,
        },
        _id: "64f75285ef8f606073e9a5fd",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antenina",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5607621,
          lng: 49.34501119999999,
        },
        _id: "64f75285ef8f606073e9a5fe",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antenina",
        fokontany: "kiaraby",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6015472,
          lng: 49.3145691,
        },
        _id: "64f75285ef8f606073e9a5ff",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antenina",
        fokontany: "antanambazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6398424,
          lng: 49.3957836,
        },
        _id: "64f75285ef8f606073e9a600",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antenina",
        fokontany: "antsiradava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6758196,
          lng: 49.1828385,
        },
        _id: "64f75285ef8f606073e9a601",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antenina",
        fokontany: "antananambo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6351166,
          lng: 49.2942833,
        },
        _id: "64f75285ef8f606073e9a602",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antenina",
        fokontany: "sahajinja",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6782679,
          lng: 49.3145691,
        },
        _id: "64f75285ef8f606073e9a603",
        province: "toamasina",
        region: "analanjirofo",
        district: "soanierana ivongo",
        commune: "antenina",
        fokontany: "mangebokeboka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5872806,
          lng: 48.2219395,
        },
        _id: "64f75285ef8f606073e9a604",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amparafaravola",
        fokontany: "amparafaravola",
        __v: 0,
      },
      {
        coords: {
          lat: -17.499688,
          lng: 48.14985799999999,
        },
        _id: "64f75285ef8f606073e9a605",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amparafaravola",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5203521,
          lng: 48.1747029,
        },
        _id: "64f75285ef8f606073e9a606",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amparafaravola",
        fokontany: "ambalamirahona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5872806,
          lng: 48.2219395,
        },
        _id: "64f75285ef8f606073e9a607",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amparafaravola",
        fokontany: "ambondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5458841,
          lng: 48.2741962,
        },
        _id: "64f75285ef8f606073e9a608",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amparafaravola",
        fokontany: "morarano ivakaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5735572,
          lng: 48.2891359,
        },
        _id: "64f75285ef8f606073e9a609",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amparafaravola",
        fokontany: "andilana atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5572897,
          lng: 48.24432909999999,
        },
        _id: "64f75285ef8f606073e9a60a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amparafaravola",
        fokontany: "antsakoana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5661238,
          lng: 48.2045317,
        },
        _id: "64f75285ef8f606073e9a60b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amparafaravola",
        fokontany: "analamiranga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5516941,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e9a60c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amparafaravola",
        fokontany: "ampanobe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5979847,
          lng: 48.254283,
        },
        _id: "64f75285ef8f606073e9a60d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amparafaravola",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5521787,
          lng: 48.2244268,
        },
        _id: "64f75285ef8f606073e9a60e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amparafaravola",
        fokontany: "ambodihasina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5878185,
          lng: 48.2228722,
        },
        _id: "64f75285ef8f606073e9a60f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amparafaravola",
        fokontany: "besarety",
        __v: 0,
      },
      {
        coords: {
          lat: -17.593327,
          lng: 48.2269142,
        },
        _id: "64f75285ef8f606073e9a610",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amparafaravola",
        fokontany: "ambalafarisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5872806,
          lng: 48.2219395,
        },
        _id: "64f75285ef8f606073e9a611",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amparafaravola",
        fokontany: "ampasimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5991172,
          lng: 48.184644,
        },
        _id: "64f75285ef8f606073e9a612",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amparafaravola",
        fokontany: "maritampona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6048336,
          lng: 48.2206959,
        },
        _id: "64f75285ef8f606073e9a613",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amparafaravola",
        fokontany: "ambatovola",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6098305,
          lng: 48.2169653,
        },
        _id: "64f75285ef8f606073e9a614",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amparafaravola",
        fokontany: "ambohimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.625203,
          lng: 48.37886,
        },
        _id: "64f75285ef8f606073e9a615",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amparafaravola",
        fokontany: "ampilahoana sud",
        __v: 0,
      },
      {
        coords: {
          lat: -17.471001,
          lng: 48.017891,
        },
        _id: "64f75285ef8f606073e9a616",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "bedidy",
        fokontany: "bedidy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4324024,
          lng: 48.1101299,
        },
        _id: "64f75285ef8f606073e9a617",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "bedidy",
        fokontany: "ambaito",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4692587,
          lng: 47.9762632,
        },
        _id: "64f75285ef8f606073e9a618",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "bedidy",
        fokontany: "antsapandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.480583,
          lng: 47.9465602,
        },
        _id: "64f75285ef8f606073e9a619",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "bedidy",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5209721,
          lng: 48.0109374,
        },
        _id: "64f75285ef8f606073e9a61a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "bedidy",
        fokontany: "marofody",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5312142,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e9a61b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "bedidy",
        fokontany: "anosiala",
        __v: 0,
      },
      {
        coords: {
          lat: -17.505606,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e9a61c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "bedidy",
        fokontany: "ambohimandroso II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5452667,
          lng: 47.9762632,
        },
        _id: "64f75285ef8f606073e9a61d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "bedidy",
        fokontany: "anosimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5872806,
          lng: 48.2219395,
        },
        _id: "64f75285ef8f606073e9a61e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohimandroso",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5846496,
          lng: 48.1101299,
        },
        _id: "64f75285ef8f606073e9a61f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohimandroso",
        fokontany: "amparihimaina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6284374,
          lng: 48.2244268,
        },
        _id: "64f75285ef8f606073e9a620",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohimandroso",
        fokontany: "ambohipeno",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6380628,
          lng: 48.197073,
        },
        _id: "64f75285ef8f606073e9a621",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohimandroso",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6435644,
          lng: 48.2368649,
        },
        _id: "64f75285ef8f606073e9a622",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohimandroso",
        fokontany: "marotaolana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.469919,
          lng: 48.2194523,
        },
        _id: "64f75285ef8f606073e9a623",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "sahamamy",
        fokontany: "sahamamy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3525163,
          lng: 48.169733,
        },
        _id: "64f75285ef8f606073e9a624",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "sahamamy",
        fokontany: "soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4388048,
          lng: 48.2095048,
        },
        _id: "64f75285ef8f606073e9a625",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "sahamamy",
        fokontany: "lohafasimbe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5010453,
          lng: 48.2294017,
        },
        _id: "64f75285ef8f606073e9a626",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "sahamamy",
        fokontany: "ambondroala",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5018844,
          lng: 48.316536,
        },
        _id: "64f75285ef8f606073e9a627",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "sahamamy",
        fokontany: "ambohivorikely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6752862,
          lng: 48.2119915,
        },
        _id: "64f75285ef8f606073e9a628",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambatomainty",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6640095,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e9a629",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambatomainty",
        fokontany: "analakininina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.675909,
          lng: 48.2213177,
        },
        _id: "64f75285ef8f606073e9a62a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambatomainty",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6777745,
          lng: 48.24930579999999,
        },
        _id: "64f75285ef8f606073e9a62b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambatomainty",
        fokontany: "amparamanina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.691354,
          lng: 48.33896439999999,
        },
        _id: "64f75285ef8f606073e9a62c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambatomainty",
        fokontany: "sampananefatra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6752862,
          lng: 48.2119915,
        },
        _id: "64f75285ef8f606073e9a62d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambatomainty",
        fokontany: "mahakary",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6314896,
          lng: 48.070431,
        },
        _id: "64f75285ef8f606073e9a62e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ampasikely",
        fokontany: "ampasikely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5728787,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e9a62f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ampasikely",
        fokontany: "ampandriatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.68573,
          lng: 48.197073,
        },
        _id: "64f75285ef8f606073e9a630",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andrebakely sud",
        fokontany: "andrebakely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6747205,
          lng: 48.0902768,
        },
        _id: "64f75285ef8f606073e9a631",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andrebakely sud",
        fokontany: "andilambarika",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6676436,
          lng: 48.154826,
        },
        _id: "64f75285ef8f606073e9a632",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andrebakely sud",
        fokontany: "ambodifarihy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6815516,
          lng: 48.1349565,
        },
        _id: "64f75285ef8f606073e9a633",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andrebakely sud",
        fokontany: "andranombainga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6888273,
          lng: 48.1722179,
        },
        _id: "64f75285ef8f606073e9a634",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andrebakely sud",
        fokontany: "antanimalalaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6987449,
          lng: 48.19210100000001,
        },
        _id: "64f75285ef8f606073e9a635",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andrebakely sud",
        fokontany: "ambongabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.479546,
          lng: 48.3551685,
        },
        _id: "64f75285ef8f606073e9a636",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohitrarivo",
        fokontany: "ambohitrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4346567,
          lng: 48.3240111,
        },
        _id: "64f75285ef8f606073e9a637",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohitrarivo",
        fokontany: "sahamaloto",
        __v: 0,
      },
      {
        coords: {
          lat: -17.410868,
          lng: 48.24930579999999,
        },
        _id: "64f75285ef8f606073e9a638",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohitrarivo",
        fokontany: "beranalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.453726,
          lng: 48.3240111,
        },
        _id: "64f75285ef8f606073e9a639",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohitrarivo",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4522849,
          lng: 48.3464426,
        },
        _id: "64f75285ef8f606073e9a63a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohitrarivo",
        fokontany: "andramosabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4782011,
          lng: 48.2891359,
        },
        _id: "64f75285ef8f606073e9a63b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohitrarivo",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4925033,
          lng: 48.36389579999999,
        },
        _id: "64f75285ef8f606073e9a63c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohitrarivo",
        fokontany: "ambohijoky",
        __v: 0,
      },
      {
        coords: {
          lat: -17.51577,
          lng: 48.429371,
        },
        _id: "64f75285ef8f606073e9a63d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "anororo",
        fokontany: "anororo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4716707,
          lng: 48.3663895,
        },
        _id: "64f75285ef8f606073e9a63e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "anororo",
        fokontany: "sampanimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4670257,
          lng: 48.41379240000001,
        },
        _id: "64f75285ef8f606073e9a63f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "anororo",
        fokontany: "ambalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4684752,
          lng: 48.3913333,
        },
        _id: "64f75285ef8f606073e9a640",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "anororo",
        fokontany: "ampaitany",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4845417,
          lng: 48.3888384,
        },
        _id: "64f75285ef8f606073e9a641",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "anororo",
        fokontany: "ambohimahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5198456,
          lng: 48.43376380000001,
        },
        _id: "64f75285ef8f606073e9a642",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "anororo",
        fokontany: "anororo betafo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7820923,
          lng: 48.1002024,
        },
        _id: "64f75285ef8f606073e9a643",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "morarano chrome",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7083428,
          lng: 48.1647635,
        },
        _id: "64f75285ef8f606073e9a644",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "maharidaza",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7307703,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e9a645",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "ambatomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7163596,
          lng: 48.03076129999999,
        },
        _id: "64f75285ef8f606073e9a646",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "antetezantany",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7299893,
          lng: 48.1747029,
        },
        _id: "64f75285ef8f606073e9a647",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "antanimena",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7454692,
          lng: 48.23437699999999,
        },
        _id: "64f75285ef8f606073e9a648",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "ambohidrony",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7231417,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e9a649",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "manakambahinikely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7581497,
          lng: 48.18215850000001,
        },
        _id: "64f75285ef8f606073e9a64a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "ambaiboa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.756092,
          lng: 48.1101299,
        },
        _id: "64f75285ef8f606073e9a64b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "morarano andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7631121,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e9a64c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "andoharano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7689309,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e9a64d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.772456,
          lng: 48.18215850000001,
        },
        _id: "64f75285ef8f606073e9a64e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7647958,
          lng: 48.070431,
        },
        _id: "64f75285ef8f606073e9a64f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "maheriara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7807009,
          lng: 48.177188,
        },
        _id: "64f75285ef8f606073e9a650",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7723001,
          lng: 48.2642387,
        },
        _id: "64f75285ef8f606073e9a651",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "antsahamanga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7880143,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e9a652",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "anosiboribory",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7863572,
          lng: 48.162279,
        },
        _id: "64f75285ef8f606073e9a653",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.788926,
          lng: 48.1858868,
        },
        _id: "64f75285ef8f606073e9a654",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "ankoririka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8271426,
          lng: 47.9812152,
        },
        _id: "64f75285ef8f606073e9a655",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "antanimafy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.801923,
          lng: 48.194587,
        },
        _id: "64f75285ef8f606073e9a656",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "ambodirano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7942134,
          lng: 48.1101299,
        },
        _id: "64f75285ef8f606073e9a657",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "ambohidehilahy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8106412,
          lng: 48.154826,
        },
        _id: "64f75285ef8f606073e9a658",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "moratelo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8283966,
          lng: 48.177188,
        },
        _id: "64f75285ef8f606073e9a659",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "ambodiatafana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8392548,
          lng: 48.154826,
        },
        _id: "64f75285ef8f606073e9a65a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "morarano chrome",
        fokontany: "andranofasika",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8650788,
          lng: 48.1190399,
        },
        _id: "64f75285ef8f606073e9a65b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ranomainty",
        fokontany: "ranomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8410167,
          lng: 48.070431,
        },
        _id: "64f75285ef8f606073e9a65c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ranomainty",
        fokontany: "amparihivola",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8557357,
          lng: 48.1448904,
        },
        _id: "64f75285ef8f606073e9a65d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ranomainty",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8650788,
          lng: 48.1190399,
        },
        _id: "64f75285ef8f606073e9a65e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ranomainty",
        fokontany: "ranofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8869544,
          lng: 48.154826,
        },
        _id: "64f75285ef8f606073e9a65f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ranomainty",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9008309,
          lng: 48.1349565,
        },
        _id: "64f75285ef8f606073e9a660",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ranomainty",
        fokontany: "ambohimanatrika",
        __v: 0,
      },
      {
        coords: {
          lat: -17.407819,
          lng: 48.3826017,
        },
        _id: "64f75285ef8f606073e9a661",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohijanahary",
        fokontany: "ambohijanahary",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2484741,
          lng: 48.2095048,
        },
        _id: "64f75285ef8f606073e9a662",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohijanahary",
        fokontany: "tanjokambana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3276935,
          lng: 48.2592606,
        },
        _id: "64f75285ef8f606073e9a663",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohijanahary",
        fokontany: "ambohipasika",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3513977,
          lng: 48.3339796,
        },
        _id: "64f75285ef8f606073e9a664",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohijanahary",
        fokontany: "ambatoharanana I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3762906,
          lng: 48.3663895,
        },
        _id: "64f75285ef8f606073e9a665",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohijanahary",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3689037,
          lng: 48.3090619,
        },
        _id: "64f75285ef8f606073e9a666",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohijanahary",
        fokontany: "bedamena",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3788178,
          lng: 48.3763657,
        },
        _id: "64f75285ef8f606073e9a667",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohijanahary",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3825202,
          lng: 48.3439498,
        },
        _id: "64f75285ef8f606073e9a668",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohijanahary",
        fokontany: "vohitsivalana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3760863,
          lng: 48.3938283,
        },
        _id: "64f75285ef8f606073e9a669",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohijanahary",
        fokontany: "ambatovola",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3959248,
          lng: 48.2841556,
        },
        _id: "64f75285ef8f606073e9a66a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohijanahary",
        fokontany: "ambatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3931231,
          lng: 48.3763657,
        },
        _id: "64f75285ef8f606073e9a66b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohijanahary",
        fokontany: "tanambaonalaina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4023679,
          lng: 48.3564152,
        },
        _id: "64f75285ef8f606073e9a66c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohijanahary",
        fokontany: "sodeca",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4115155,
          lng: 48.4112965,
        },
        _id: "64f75285ef8f606073e9a66d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohijanahary",
        fokontany: "ambolomborona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4047032,
          lng: 48.3938283,
        },
        _id: "64f75285ef8f606073e9a66e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohijanahary",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.414436,
          lng: 48.3663895,
        },
        _id: "64f75285ef8f606073e9a66f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohijanahary",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4103398,
          lng: 48.3314873,
        },
        _id: "64f75285ef8f606073e9a670",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohijanahary",
        fokontany: "antamenaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4257249,
          lng: 48.36389579999999,
        },
        _id: "64f75285ef8f606073e9a671",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohijanahary",
        fokontany: "ampaibary",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4479357,
          lng: 48.41379240000001,
        },
        _id: "64f75285ef8f606073e9a672",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohijanahary",
        fokontany: "sahanavily",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4465523,
          lng: 48.3614021,
        },
        _id: "64f75285ef8f606073e9a673",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohijanahary",
        fokontany: "ankaiafo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4468543,
          lng: 48.3813544,
        },
        _id: "64f75285ef8f606073e9a674",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambohijanahary",
        fokontany: "andranobe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3836968,
          lng: 48.4474983,
        },
        _id: "64f75285ef8f606073e9a675",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "tanambe",
        fokontany: "tanambe atsimondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3751676,
          lng: 48.4562402,
        },
        _id: "64f75285ef8f606073e9a676",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "tanambe",
        fokontany: "tanambe avaradrano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3789524,
          lng: 48.4712296,
        },
        _id: "64f75285ef8f606073e9a677",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "tanambe",
        fokontany: "antsiraka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3894854,
          lng: 48.4562402,
        },
        _id: "64f75285ef8f606073e9a678",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "tanambe",
        fokontany: "ambodiadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3902238,
          lng: 48.4450008,
        },
        _id: "64f75285ef8f606073e9a679",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "tanambe",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3977681,
          lng: 48.4512446,
        },
        _id: "64f75285ef8f606073e9a67a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "tanambe",
        fokontany: "ambohitsiampiana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3957328,
          lng: 48.43376380000001,
        },
        _id: "64f75285ef8f606073e9a67b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "tanambe",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4098414,
          lng: 48.4612362,
        },
        _id: "64f75285ef8f606073e9a67c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "tanambe",
        fokontany: "tananasondrotra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4339114,
          lng: 48.43376380000001,
        },
        _id: "64f75285ef8f606073e9a67d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "tanambe",
        fokontany: "ambohitria",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4319568,
          lng: 48.4637344,
        },
        _id: "64f75285ef8f606073e9a67e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "tanambe",
        fokontany: "amborompotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.466667,
          lng: 48.466667,
        },
        _id: "64f75285ef8f606073e9a67f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "tanambe",
        fokontany: "ambodiamontana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3736787,
          lng: 48.4332918,
        },
        _id: "64f75285ef8f606073e9a680",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "beanana",
        fokontany: "beanana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2014592,
          lng: 48.24930579999999,
        },
        _id: "64f75285ef8f606073e9a681",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "beanana",
        fokontany: "bekarangy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2405804,
          lng: 48.3289951,
        },
        _id: "64f75285ef8f606073e9a682",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "beanana",
        fokontany: "antsiradava I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3194022,
          lng: 48.43376380000001,
        },
        _id: "64f75285ef8f606073e9a683",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "beanana",
        fokontany: "marogoro",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3359619,
          lng: 48.4237772,
        },
        _id: "64f75285ef8f606073e9a684",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "beanana",
        fokontany: "ampasika mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3198557,
          lng: 48.37886,
        },
        _id: "64f75285ef8f606073e9a685",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "beanana",
        fokontany: "antsiradava II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3570689,
          lng: 48.4412549,
        },
        _id: "64f75285ef8f606073e9a686",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "beanana",
        fokontany: "ampananganana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3650739,
          lng: 48.4162884,
        },
        _id: "64f75285ef8f606073e9a687",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "beanana",
        fokontany: "ankitsika",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3733516,
          lng: 48.4112965,
        },
        _id: "64f75285ef8f606073e9a688",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "beanana",
        fokontany: "ambalavary",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3854178,
          lng: 48.4212808,
        },
        _id: "64f75285ef8f606073e9a689",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "beanana",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3713827,
          lng: 48.4412549,
        },
        _id: "64f75285ef8f606073e9a68a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "beanana",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4058959,
          lng: 48.5212237,
        },
        _id: "64f75285ef8f606073e9a68b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "vohitsara",
        fokontany: "vohitsara ville",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4179153,
          lng: 48.4837239,
        },
        _id: "64f75285ef8f606073e9a68c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "vohitsara",
        fokontany: "amboahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3943148,
          lng: 48.5037206,
        },
        _id: "64f75285ef8f606073e9a68d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "vohitsara",
        fokontany: "ambohimilamina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4082026,
          lng: 48.5043456,
        },
        _id: "64f75285ef8f606073e9a68e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "vohitsara",
        fokontany: "ambohimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.40729,
          lng: 48.497459,
        },
        _id: "64f75285ef8f606073e9a68f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "vohitsara",
        fokontany: "ambohidrano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4576732,
          lng: 48.5087209,
        },
        _id: "64f75285ef8f606073e9a690",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "vohitsara",
        fokontany: "manorita",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2872706,
          lng: 48.5337293,
        },
        _id: "64f75285ef8f606073e9a691",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amboavory",
        fokontany: "amboavory atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.133333,
          lng: 48.216667,
        },
        _id: "64f75285ef8f606073e9a692",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amboavory",
        fokontany: "amboasary",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0993545,
          lng: 48.2592606,
        },
        _id: "64f75285ef8f606073e9a693",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amboavory",
        fokontany: "marojao",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1539029,
          lng: 48.2966073,
        },
        _id: "64f75285ef8f606073e9a694",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amboavory",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1503752,
          lng: 48.3489356,
        },
        _id: "64f75285ef8f606073e9a695",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amboavory",
        fokontany: "betsivato",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2075052,
          lng: 48.3489356,
        },
        _id: "64f75285ef8f606073e9a696",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amboavory",
        fokontany: "ambalabako",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2155162,
          lng: 48.4187846,
        },
        _id: "64f75285ef8f606073e9a697",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amboavory",
        fokontany: "ambodifano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2235057,
          lng: 48.4887224,
        },
        _id: "64f75285ef8f606073e9a698",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amboavory",
        fokontany: "ankokabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.258301,
          lng: 48.391811,
        },
        _id: "64f75285ef8f606073e9a699",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amboavory",
        fokontany: "bemahia",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2807514,
          lng: 48.4887224,
        },
        _id: "64f75285ef8f606073e9a69a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amboavory",
        fokontany: "ambohidehilahy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2958141,
          lng: 48.4537424,
        },
        _id: "64f75285ef8f606073e9a69b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amboavory",
        fokontany: "tsinjoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3101373,
          lng: 48.5012206,
        },
        _id: "64f75285ef8f606073e9a69c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amboavory",
        fokontany: "amboavory andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3116452,
          lng: 48.5262256,
        },
        _id: "64f75285ef8f606073e9a69d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amboavory",
        fokontany: "ambodivoarafolaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3189314,
          lng: 48.5124715,
        },
        _id: "64f75285ef8f606073e9a69e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amboavory",
        fokontany: "amboavorikely est",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3210628,
          lng: 48.4924716,
        },
        _id: "64f75285ef8f606073e9a69f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amboavory",
        fokontany: "antsiatsiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3279721,
          lng: 48.496221,
        },
        _id: "64f75285ef8f606073e9a6a0",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "amboavory",
        fokontany: "ambohitrandriamanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3531183,
          lng: 48.5487397,
        },
        _id: "64f75285ef8f606073e9a6a1",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andilana nord",
        fokontany: "andilana nord",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3013328,
          lng: 48.5612516,
        },
        _id: "64f75285ef8f606073e9a6a2",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andilana nord",
        fokontany: "ampasika",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3209412,
          lng: 48.5537441,
        },
        _id: "64f75285ef8f606073e9a6a3",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andilana nord",
        fokontany: "morarano mandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3357722,
          lng: 48.5462377,
        },
        _id: "64f75285ef8f606073e9a6a4",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andilana nord",
        fokontany: "vohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3420517,
          lng: 48.5237246,
        },
        _id: "64f75285ef8f606073e9a6a5",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andilana nord",
        fokontany: "andratsiolona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3453246,
          lng: 48.5462377,
        },
        _id: "64f75285ef8f606073e9a6a6",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andilana nord",
        fokontany: "analavondro",
        __v: 0,
      },
      {
        coords: {
          lat: -17.391336,
          lng: 48.5487397,
        },
        _id: "64f75285ef8f606073e9a6a7",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andilana nord",
        fokontany: "antendrombohilava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5872806,
          lng: 48.2219395,
        },
        _id: "64f75285ef8f606073e9a6a8",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambodimanga",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3503151,
          lng: 48.4712296,
        },
        _id: "64f75285ef8f606073e9a6a9",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambodimanga",
        fokontany: "ambatomitovona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3435203,
          lng: 48.4537424,
        },
        _id: "64f75285ef8f606073e9a6aa",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "ambodimanga",
        fokontany: "ampilahoana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.237356,
          lng: 48.6589406,
        },
        _id: "64f75285ef8f606073e9a6ab",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "vohimena",
        fokontany: "vohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1907482,
          lng: 48.7794089,
        },
        _id: "64f75285ef8f606073e9a6ac",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "vohimena",
        fokontany: "antohodava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2333329,
          lng: 48.716667,
        },
        _id: "64f75285ef8f606073e9a6ad",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "vohimena",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.243641,
          lng: 48.54696999999999,
        },
        _id: "64f75285ef8f606073e9a6ae",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "vohimena",
        fokontany: "anony",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2807062,
          lng: 48.5837799,
        },
        _id: "64f75285ef8f606073e9a6af",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "vohimena",
        fokontany: "antsiradava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2826509,
          lng: 48.6489134,
        },
        _id: "64f75285ef8f606073e9a6b0",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "vohimena",
        fokontany: "ambohimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3078053,
          lng: 48.6539268,
        },
        _id: "64f75285ef8f606073e9a6b1",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "vohimena",
        fokontany: "ambodisakoana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3048291,
          lng: 48.6038129,
        },
        _id: "64f75285ef8f606073e9a6b2",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "vohimena",
        fokontany: "vohitraivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3309889,
          lng: 48.5937955,
        },
        _id: "64f75285ef8f606073e9a6b3",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "vohimena",
        fokontany: "vohimenabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3410348,
          lng: 48.6338758,
        },
        _id: "64f75285ef8f606073e9a6b4",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "vohimena",
        fokontany: "andranofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3576455,
          lng: 48.623853,
        },
        _id: "64f75285ef8f606073e9a6b5",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "vohimena",
        fokontany: "analanomby",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3056073,
          lng: 48.7794089,
        },
        _id: "64f75285ef8f606073e9a6b6",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andrebakely nord",
        fokontany: "andrebakely nord",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1723248,
          lng: 48.8598651,
        },
        _id: "64f75285ef8f606073e9a6b7",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andrebakely nord",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2885344,
          lng: 48.74926749999999,
        },
        _id: "64f75285ef8f606073e9a6b8",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andrebakely nord",
        fokontany: "antanambe savaina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3146099,
          lng: 48.755661,
        },
        _id: "64f75285ef8f606073e9a6b9",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andrebakely nord",
        fokontany: "sahafary",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3176842,
          lng: 48.7894596,
        },
        _id: "64f75285ef8f606073e9a6ba",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andrebakely nord",
        fokontany: "ambodihasina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3178291,
          lng: 48.69405039999999,
        },
        _id: "64f75285ef8f606073e9a6bb",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andrebakely nord",
        fokontany: "befody anava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3545175,
          lng: 48.7643362,
        },
        _id: "64f75285ef8f606073e9a6bc",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andrebakely nord",
        fokontany: "ambendrana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3747518,
          lng: 48.6539268,
        },
        _id: "64f75285ef8f606073e9a6bd",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andrebakely nord",
        fokontany: "ampisarahana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3450406,
          lng: 48.6689697,
        },
        _id: "64f75285ef8f606073e9a6be",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andrebakely nord",
        fokontany: "ambalahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3736712,
          lng: 48.7643362,
        },
        _id: "64f75285ef8f606073e9a6bf",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andrebakely nord",
        fokontany: "ambinanindranofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3923515,
          lng: 48.7241621,
        },
        _id: "64f75285ef8f606073e9a6c0",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "amparafaravola",
        commune: "andrebakely nord",
        fokontany: "ankarongana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8255332,
          lng: 48.43064280000001,
        },
        _id: "64f75285ef8f606073e9a6c1",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatondrazaka",
        fokontany: "avaradrova atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7958355,
          lng: 48.40131400000001,
        },
        _id: "64f75285ef8f606073e9a6c2",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatondrazaka",
        fokontany: "anosindrafilo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8186055,
          lng: 48.4200327,
        },
        _id: "64f75285ef8f606073e9a6c3",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatondrazaka",
        fokontany: "antanifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8197064,
          lng: 48.4150404,
        },
        _id: "64f75285ef8f606073e9a6c4",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatondrazaka",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8227241,
          lng: 48.4244013,
        },
        _id: "64f75285ef8f606073e9a6c5",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatondrazaka",
        fokontany: "madiotsifafana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8218315,
          lng: 48.4325153,
        },
        _id: "64f75285ef8f606073e9a6c6",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatondrazaka",
        fokontany: "avaradrova avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.831399,
          lng: 48.4325153,
        },
        _id: "64f75285ef8f606073e9a6c7",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatondrazaka",
        fokontany: "atsimondrova",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8288171,
          lng: 48.422529,
        },
        _id: "64f75285ef8f606073e9a6c8",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatondrazaka",
        fokontany: "ampasambazimba",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8390298,
          lng: 48.4250254,
        },
        _id: "64f75285ef8f606073e9a6c9",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatondrazaka",
        fokontany: "ambohimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8336363,
          lng: 48.4088007,
        },
        _id: "64f75285ef8f606073e9a6ca",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "feramanga nord",
        fokontany: "feramanga nord",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7697854,
          lng: 48.5137217,
        },
        _id: "64f75285ef8f606073e9a6cb",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "feramanga nord",
        fokontany: "analabehasina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7837865,
          lng: 48.4937213,
        },
        _id: "64f75285ef8f606073e9a6cc",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "feramanga nord",
        fokontany: "antokazo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7982857,
          lng: 48.4524935,
        },
        _id: "64f75285ef8f606073e9a6cd",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "feramanga nord",
        fokontany: "ambararata",
        __v: 0,
      },
      {
        coords: {
          lat: -17.80145,
          lng: 48.41379240000001,
        },
        _id: "64f75285ef8f606073e9a6ce",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "feramanga nord",
        fokontany: "ambohimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8008163,
          lng: 48.476227,
        },
        _id: "64f75285ef8f606073e9a6cf",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "feramanga nord",
        fokontany: "avaradrano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8073953,
          lng: 48.4599872,
        },
        _id: "64f75285ef8f606073e9a6d0",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "feramanga nord",
        fokontany: "mangalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8091862,
          lng: 48.4437522,
        },
        _id: "64f75285ef8f606073e9a6d1",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "feramanga nord",
        fokontany: "ampatakana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8160911,
          lng: 48.4612362,
        },
        _id: "64f75285ef8f606073e9a6d2",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "feramanga nord",
        fokontany: "begoavy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8296111,
          lng: 48.5787728,
        },
        _id: "64f75285ef8f606073e9a6d3",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "feramanga nord",
        fokontany: "bejabora",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8269513,
          lng: 48.4662327,
        },
        _id: "64f75285ef8f606073e9a6d4",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatondrazaka suburbaine",
        fokontany: "andingadingana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8327983,
          lng: 48.3963234,
        },
        _id: "64f75285ef8f606073e9a6d5",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatondrazaka suburbaine",
        fokontany: "antanambehivavy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8225528,
          lng: 48.4075528,
        },
        _id: "64f75285ef8f606073e9a6d6",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatondrazaka suburbaine",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8286265,
          lng: 48.41254439999999,
        },
        _id: "64f75285ef8f606073e9a6d7",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatondrazaka suburbaine",
        fokontany: "andrarabary",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8596164,
          lng: 48.4537424,
        },
        _id: "64f75285ef8f606073e9a6d8",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatondrazaka suburbaine",
        fokontany: "ambohitranjakana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8400179,
          lng: 48.4612362,
        },
        _id: "64f75285ef8f606073e9a6d9",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatondrazaka suburbaine",
        fokontany: "ambodivoara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8483879,
          lng: 48.4219049,
        },
        _id: "64f75285ef8f606073e9a6da",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatondrazaka suburbaine",
        fokontany: "ambalabako",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8484376,
          lng: 48.40131400000001,
        },
        _id: "64f75285ef8f606073e9a6db",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatondrazaka suburbaine",
        fokontany: "antanambehivavikely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8552114,
          lng: 48.4737282,
        },
        _id: "64f75285ef8f606073e9a6dc",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatondrazaka suburbaine",
        fokontany: "ambongabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.883333,
          lng: 48.5,
        },
        _id: "64f75285ef8f606073e9a6dd",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatondrazaka suburbaine",
        fokontany: "ambolomborona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7707126,
          lng: 48.4406306,
        },
        _id: "64f75285ef8f606073e9a6de",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambandrika",
        fokontany: "ambandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7592422,
          lng: 48.4262736,
        },
        _id: "64f75285ef8f606073e9a6df",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambandrika",
        fokontany: "ambodiatafana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7723002,
          lng: 48.4212808,
        },
        _id: "64f75285ef8f606073e9a6e0",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambandrika",
        fokontany: "ambaniala",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7717444,
          lng: 48.4562402,
        },
        _id: "64f75285ef8f606073e9a6e1",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambandrika",
        fokontany: "miadampahonina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.77768,
          lng: 48.4375092,
        },
        _id: "64f75285ef8f606073e9a6e2",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambandrika",
        fokontany: "betoloho",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7659482,
          lng: 48.36888339999999,
        },
        _id: "64f75285ef8f606073e9a6e3",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ampitatsimo",
        fokontany: "ampitatsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7519821,
          lng: 48.3888384,
        },
        _id: "64f75285ef8f606073e9a6e4",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ampitatsimo",
        fokontany: "ambohitanibe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7599627,
          lng: 48.3364719,
        },
        _id: "64f75285ef8f606073e9a6e5",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ampitatsimo",
        fokontany: "ampandrianosy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7829403,
          lng: 48.3514287,
        },
        _id: "64f75285ef8f606073e9a6e6",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ampitatsimo",
        fokontany: "ambonivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7950776,
          lng: 48.3614021,
        },
        _id: "64f75285ef8f606073e9a6e7",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ampitatsimo",
        fokontany: "ambohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8009219,
          lng: 48.376197,
        },
        _id: "64f75285ef8f606073e9a6e8",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ampitatsimo",
        fokontany: "ambohiboatavo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8116124,
          lng: 48.3514287,
        },
        _id: "64f75285ef8f606073e9a6e9",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ampitatsimo",
        fokontany: "ampahatra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8319593,
          lng: 48.383849,
        },
        _id: "64f75285ef8f606073e9a6ea",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ampitatsimo",
        fokontany: "ambohiboromanga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6993037,
          lng: 48.4674819,
        },
        _id: "64f75285ef8f606073e9a6eb",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambohitsilaozana",
        fokontany: "ambohitsilaozana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7002241,
          lng: 48.5037206,
        },
        _id: "64f75285ef8f606073e9a6ec",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambohitsilaozana",
        fokontany: "ambohimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6848485,
          lng: 48.4437522,
        },
        _id: "64f75285ef8f606073e9a6ed",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambohitsilaozana",
        fokontany: "ambodivoara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7202983,
          lng: 48.4887224,
        },
        _id: "64f75285ef8f606073e9a6ee",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambohitsilaozana",
        fokontany: "menaloha",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7109673,
          lng: 48.43376380000001,
        },
        _id: "64f75285ef8f606073e9a6ef",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambohitsilaozana",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7248456,
          lng: 48.4412549,
        },
        _id: "64f75285ef8f606073e9a6f0",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambohitsilaozana",
        fokontany: "ambohitriesana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7344088,
          lng: 48.4412549,
        },
        _id: "64f75285ef8f606073e9a6f1",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambohitsilaozana",
        fokontany: "ambohipasika",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7466456,
          lng: 48.4237772,
        },
        _id: "64f75285ef8f606073e9a6f2",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambohitsilaozana",
        fokontany: "mangalahala",
        __v: 0,
      },
      {
        coords: {
          lat: -17.75,
          lng: 48.48333299999999,
        },
        _id: "64f75285ef8f606073e9a6f3",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambohitsilaozana",
        fokontany: "antandrokomby",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7569311,
          lng: 48.4637344,
        },
        _id: "64f75285ef8f606073e9a6f4",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambohitsilaozana",
        fokontany: "tanambao jiapasika",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8788015,
          lng: 48.4059487,
        },
        _id: "64f75285ef8f606073e9a6f5",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ilafy",
        fokontany: "ilafy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8250469,
          lng: 48.3663895,
        },
        _id: "64f75285ef8f606073e9a6f6",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ilafy",
        fokontany: "ampamohalambo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8484992,
          lng: 48.3738715,
        },
        _id: "64f75285ef8f606073e9a6f7",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ilafy",
        fokontany: "feramanga atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8627864,
          lng: 48.40131400000001,
        },
        _id: "64f75285ef8f606073e9a6f8",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ilafy",
        fokontany: "ankazotsaravolo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8937255,
          lng: 48.36389579999999,
        },
        _id: "64f75285ef8f606073e9a6f9",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ilafy",
        fokontany: "marianina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8788015,
          lng: 48.4059487,
        },
        _id: "64f75285ef8f606073e9a6fa",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ilafy",
        fokontany: "mangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9128144,
          lng: 48.3913333,
        },
        _id: "64f75285ef8f606073e9a6fb",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ilafy",
        fokontany: "amparihitody",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9144635,
          lng: 48.4437522,
        },
        _id: "64f75285ef8f606073e9a6fc",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ilafy",
        fokontany: "ambohimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.943263,
          lng: 48.3888384,
        },
        _id: "64f75285ef8f606073e9a6fd",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ilafy",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.929223,
          lng: 48.4637344,
        },
        _id: "64f75285ef8f606073e9a6fe",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ilafy",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9588479,
          lng: 48.448747,
        },
        _id: "64f75285ef8f606073e9a6ff",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ilafy",
        fokontany: "antanifotsy bevava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9815437,
          lng: 48.3888384,
        },
        _id: "64f75285ef8f606073e9a700",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ilafy",
        fokontany: "manjakatsiahoatra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8673501,
          lng: 48.2717067,
        },
        _id: "64f75285ef8f606073e9a701",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny andrefana",
        fokontany: "manakambahiny atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7591294,
          lng: 48.3240111,
        },
        _id: "64f75285ef8f606073e9a702",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny andrefana",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7852076,
          lng: 48.31404449999999,
        },
        _id: "64f75285ef8f606073e9a703",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny andrefana",
        fokontany: "antanimena",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8043141,
          lng: 48.31404449999999,
        },
        _id: "64f75285ef8f606073e9a704",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny andrefana",
        fokontany: "ambohidehilahy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8311803,
          lng: 48.3439498,
        },
        _id: "64f75285ef8f606073e9a705",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny andrefana",
        fokontany: "andilanomby",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8360134,
          lng: 48.316536,
        },
        _id: "64f75285ef8f606073e9a706",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny andrefana",
        fokontany: "ambaiboa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8417298,
          lng: 48.2741962,
        },
        _id: "64f75285ef8f606073e9a707",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny andrefana",
        fokontany: "anjirobaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9085511,
          lng: 48.3289951,
        },
        _id: "64f75285ef8f606073e9a708",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny andrefana",
        fokontany: "ambalavato",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8565016,
          lng: 48.26672790000001,
        },
        _id: "64f75285ef8f606073e9a709",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny andrefana",
        fokontany: "manakambahiny avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8789787,
          lng: 48.3439498,
        },
        _id: "64f75285ef8f606073e9a70a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny andrefana",
        fokontany: "miaramanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8725367,
          lng: 48.2916263,
        },
        _id: "64f75285ef8f606073e9a70b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny andrefana",
        fokontany: "ambodivoara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8908161,
          lng: 48.2517943,
        },
        _id: "64f75285ef8f606073e9a70c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny andrefana",
        fokontany: "vohidiala",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8920899,
          lng: 48.2841556,
        },
        _id: "64f75285ef8f606073e9a70d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny andrefana",
        fokontany: "ambongabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9142403,
          lng: 48.2866457,
        },
        _id: "64f75285ef8f606073e9a70e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny andrefana",
        fokontany: "manaratsandry",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8217331,
          lng: 48.2891359,
        },
        _id: "64f75285ef8f606073e9a70f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "antsangasanga",
        fokontany: "antsangasanga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8079044,
          lng: 48.254283,
        },
        _id: "64f75285ef8f606073e9a710",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "antsangasanga",
        fokontany: "amparatsiraka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7965627,
          lng: 48.2841556,
        },
        _id: "64f75285ef8f606073e9a711",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "antsangasanga",
        fokontany: "tsarasokitra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8230512,
          lng: 48.2804206,
        },
        _id: "64f75285ef8f606073e9a712",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "antsangasanga",
        fokontany: "maritampona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5457498,
          lng: 48.5262256,
        },
        _id: "64f75285ef8f606073e9a713",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatosoratra",
        fokontany: "ambatosoratra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5280818,
          lng: 48.5037206,
        },
        _id: "64f75285ef8f606073e9a714",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatosoratra",
        fokontany: "amparihimpony",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5382431,
          lng: 48.5437358,
        },
        _id: "64f75285ef8f606073e9a715",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatosoratra",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5542159,
          lng: 48.4937213,
        },
        _id: "64f75285ef8f606073e9a716",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatosoratra",
        fokontany: "ambohidava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5745665,
          lng: 48.57376619999999,
        },
        _id: "64f75285ef8f606073e9a717",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatosoratra",
        fokontany: "andrangorona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5688659,
          lng: 48.5137217,
        },
        _id: "64f75285ef8f606073e9a718",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatosoratra",
        fokontany: "ambatomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5905386,
          lng: 48.5237246,
        },
        _id: "64f75285ef8f606073e9a719",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatosoratra",
        fokontany: "vohidrazana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6080057,
          lng: 48.6489134,
        },
        _id: "64f75285ef8f606073e9a71a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatosoratra",
        fokontany: "ambatomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6198672,
          lng: 48.5637543,
        },
        _id: "64f75285ef8f606073e9a71b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatosoratra",
        fokontany: "lohafasika",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6385167,
          lng: 48.496221,
        },
        _id: "64f75285ef8f606073e9a71c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatosoratra",
        fokontany: "andreba gara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.650637,
          lng: 48.5062207,
        },
        _id: "64f75285ef8f606073e9a71d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatosoratra",
        fokontany: "ambohidavakely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6715217,
          lng: 48.5037206,
        },
        _id: "64f75285ef8f606073e9a71e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "ambatosoratra",
        fokontany: "angoja",
        __v: 0,
      },
      {
        coords: {
          lat: -17.93631,
          lng: 48.236591,
        },
        _id: "64f75285ef8f606073e9a71f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "andilanatoby",
        fokontany: "andilanatoby",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9155804,
          lng: 48.2095048,
        },
        _id: "64f75285ef8f606073e9a720",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "andilanatoby",
        fokontany: "ambodinonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9095089,
          lng: 48.177188,
        },
        _id: "64f75285ef8f606073e9a721",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "andilanatoby",
        fokontany: "ambodifiakarana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9511458,
          lng: 48.1448904,
        },
        _id: "64f75285ef8f606073e9a722",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "andilanatoby",
        fokontany: "ambohimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9329301,
          lng: 48.26672790000001,
        },
        _id: "64f75285ef8f606073e9a723",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "andilanatoby",
        fokontany: "fierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9303346,
          lng: 48.1747029,
        },
        _id: "64f75285ef8f606073e9a724",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "andilanatoby",
        fokontany: "ambohimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.967622,
          lng: 48.1349565,
        },
        _id: "64f75285ef8f606073e9a725",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "andilanatoby",
        fokontany: "ankasina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9746313,
          lng: 48.2891359,
        },
        _id: "64f75285ef8f606073e9a726",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "andilanatoby",
        fokontany: "mangatany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0214273,
          lng: 48.1399232,
        },
        _id: "64f75285ef8f606073e9a727",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "andilanatoby",
        fokontany: "sahanidingana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0163195,
          lng: 48.2294017,
        },
        _id: "64f75285ef8f606073e9a728",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "andilanatoby",
        fokontany: "andasibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.033333,
          lng: 48.333333,
        },
        _id: "64f75285ef8f606073e9a729",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "andilanatoby",
        fokontany: "andranomadio",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0206878,
          lng: 48.2642387,
        },
        _id: "64f75285ef8f606073e9a72a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "andilanatoby",
        fokontany: "ranofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0702582,
          lng: 48.2891359,
        },
        _id: "64f75285ef8f606073e9a72b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "andilanatoby",
        fokontany: "andranokobaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0806013,
          lng: 48.2194523,
        },
        _id: "64f75285ef8f606073e9a72c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "andilanatoby",
        fokontany: "antsapanimahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8355108,
          lng: 48.2182088,
        },
        _id: "64f75285ef8f606073e9a72d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "bejofo",
        fokontany: "bejofo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8400906,
          lng: 48.194587,
        },
        _id: "64f75285ef8f606073e9a72e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "bejofo",
        fokontany: "betambako",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8548227,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e9a72f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "bejofo",
        fokontany: "anandrobe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8739154,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e9a730",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "bejofo",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8756682,
          lng: 48.184644,
        },
        _id: "64f75285ef8f606073e9a731",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "bejofo",
        fokontany: "ambohitrandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.890411,
          lng: 48.2045317,
        },
        _id: "64f75285ef8f606073e9a732",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "bejofo",
        fokontany: "tsinjoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9225174,
          lng: 48.1448904,
        },
        _id: "64f75285ef8f606073e9a733",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "bejofo",
        fokontany: "ambodiaviavy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1818879,
          lng: 48.6790007,
        },
        _id: "64f75285ef8f606073e9a734",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "didy",
        fokontany: "ambohijanahary",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9360985,
          lng: 48.5087209,
        },
        _id: "64f75285ef8f606073e9a735",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "didy",
        fokontany: "amboarabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.959999,
          lng: 48.51638,
        },
        _id: "64f75285ef8f606073e9a736",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "didy",
        fokontany: "antsevabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9814903,
          lng: 48.4437522,
        },
        _id: "64f75285ef8f606073e9a737",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "didy",
        fokontany: "ambodivolosy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0213787,
          lng: 48.5787728,
        },
        _id: "64f75285ef8f606073e9a738",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "didy",
        fokontany: "bedabo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0111062,
          lng: 48.4287702,
        },
        _id: "64f75285ef8f606073e9a739",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "didy",
        fokontany: "manakambahinikely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0563867,
          lng: 48.4187846,
        },
        _id: "64f75285ef8f606073e9a73a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "didy",
        fokontany: "fierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0563248,
          lng: 48.5287267,
        },
        _id: "64f75285ef8f606073e9a73b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "didy",
        fokontany: "sahafasenina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0981789,
          lng: 48.5237246,
        },
        _id: "64f75285ef8f606073e9a73c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "didy",
        fokontany: "vohidrazana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1539865,
          lng: 48.4987208,
        },
        _id: "64f75285ef8f606073e9a73d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "didy",
        fokontany: "ambohimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.15,
          lng: 48.4,
        },
        _id: "64f75285ef8f606073e9a73e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "didy",
        fokontany: "ambohimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2375107,
          lng: 48.37886,
        },
        _id: "64f75285ef8f606073e9a73f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "didy",
        fokontany: "tsaratampona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2691223,
          lng: 48.4987208,
        },
        _id: "64f75285ef8f606073e9a740",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "didy",
        fokontany: "fierenana ambohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4283445,
          lng: 48.5912914,
        },
        _id: "64f75285ef8f606073e9a741",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "imerimandroso",
        fokontany: "imerimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4099826,
          lng: 48.6038129,
        },
        _id: "64f75285ef8f606073e9a742",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "imerimandroso",
        fokontany: "ambaniala",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4049398,
          lng: 48.5837799,
        },
        _id: "64f75285ef8f606073e9a743",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "imerimandroso",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4203073,
          lng: 48.6163371,
        },
        _id: "64f75285ef8f606073e9a744",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "imerimandroso",
        fokontany: "antanifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4308686,
          lng: 48.6013084,
        },
        _id: "64f75285ef8f606073e9a745",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "imerimandroso",
        fokontany: "andranomandeha",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4323951,
          lng: 48.62635849999999,
        },
        _id: "64f75285ef8f606073e9a746",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "imerimandroso",
        fokontany: "ankasina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4462467,
          lng: 48.6338758,
        },
        _id: "64f75285ef8f606073e9a747",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "imerimandroso",
        fokontany: "ambohitrapirana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4376363,
          lng: 48.57126299999999,
        },
        _id: "64f75285ef8f606073e9a748",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "imerimandroso",
        fokontany: "vohitsivalana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4476917,
          lng: 48.5637543,
        },
        _id: "64f75285ef8f606073e9a749",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "imerimandroso",
        fokontany: "vohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4673674,
          lng: 48.6038129,
        },
        _id: "64f75285ef8f606073e9a74a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "imerimandroso",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4675855,
          lng: 48.5762695,
        },
        _id: "64f75285ef8f606073e9a74b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "imerimandroso",
        fokontany: "ambohijanaharikely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4757199,
          lng: 48.6464068,
        },
        _id: "64f75285ef8f606073e9a74c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "imerimandroso",
        fokontany: "amboasarimasina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4845222,
          lng: 48.6338758,
        },
        _id: "64f75285ef8f606073e9a74d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "imerimandroso",
        fokontany: "ambodinonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.533361,
          lng: 48.6188423,
        },
        _id: "64f75285ef8f606073e9a74e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "amparihintsokatra",
        fokontany: "amparihintsokatra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4869191,
          lng: 48.5487397,
        },
        _id: "64f75285ef8f606073e9a74f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "amparihintsokatra",
        fokontany: "madiorano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4985993,
          lng: 48.613832,
        },
        _id: "64f75285ef8f606073e9a750",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "amparihintsokatra",
        fokontany: "betsianjava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.513109,
          lng: 48.64809,
        },
        _id: "64f75285ef8f606073e9a751",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "amparihintsokatra",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5505442,
          lng: 48.6489134,
        },
        _id: "64f75285ef8f606073e9a752",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "amparihintsokatra",
        fokontany: "antsahalemaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5212551,
          lng: 48.6088222,
        },
        _id: "64f75285ef8f606073e9a753",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "amparihintsokatra",
        fokontany: "antsiradava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5165795,
          lng: 48.5337293,
        },
        _id: "64f75285ef8f606073e9a754",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "amparihintsokatra",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4521923,
          lng: 48.6663119,
        },
        _id: "64f75285ef8f606073e9a755",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "antanandava",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4053862,
          lng: 48.8145943,
        },
        _id: "64f75285ef8f606073e9a756",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "antanandava",
        fokontany: "antsirabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4084524,
          lng: 48.76936,
        },
        _id: "64f75285ef8f606073e9a757",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "antanandava",
        fokontany: "ambodirotra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4367007,
          lng: 48.7291822,
        },
        _id: "64f75285ef8f606073e9a758",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "antanandava",
        fokontany: "ambongabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4346782,
          lng: 48.66395499999999,
        },
        _id: "64f75285ef8f606073e9a759",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "antanandava",
        fokontany: "bekatsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.470984,
          lng: 48.7894596,
        },
        _id: "64f75285ef8f606073e9a75a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "antanandava",
        fokontany: "manambato",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5082789,
          lng: 48.7091042,
        },
        _id: "64f75285ef8f606073e9a75b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "antanandava",
        fokontany: "ambodiapaly",
        __v: 0,
      },
      {
        coords: {
          lat: -17.41288,
          lng: 48.63839,
        },
        _id: "64f75285ef8f606073e9a75c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "andromba",
        fokontany: "andromba",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4135238,
          lng: 48.69405039999999,
        },
        _id: "64f75285ef8f606073e9a75d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "andromba",
        fokontany: "ambohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -17.425866,
          lng: 48.6764928,
        },
        _id: "64f75285ef8f606073e9a75e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "andromba",
        fokontany: "ampananganana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.76693,
          lng: 48.659229,
        },
        _id: "64f75285ef8f606073e9a75f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny antsinanana",
        fokontany: "manakambahiny antsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6642159,
          lng: 48.56876,
        },
        _id: "64f75285ef8f606073e9a760",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny antsinanana",
        fokontany: "antokazo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6603833,
          lng: 48.6288642,
        },
        _id: "64f75285ef8f606073e9a761",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny antsinanana",
        fokontany: "andranomalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7216644,
          lng: 48.56876,
        },
        _id: "64f75285ef8f606073e9a762",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny antsinanana",
        fokontany: "analavory",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7089303,
          lng: 48.6689697,
        },
        _id: "64f75285ef8f606073e9a763",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny antsinanana",
        fokontany: "anosivola",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8221183,
          lng: 48.6990679,
        },
        _id: "64f75285ef8f606073e9a764",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny antsinanana",
        fokontany: "andemademaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7415275,
          lng: 48.7593128,
        },
        _id: "64f75285ef8f606073e9a765",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "manakambahiny antsinanana",
        fokontany: "sahamalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.016667,
          lng: 48.066667,
        },
        _id: "64f75285ef8f606073e9a766",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "soalazaina",
        fokontany: "soalazaina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8997779,
          lng: 47.931715,
        },
        _id: "64f75285ef8f606073e9a767",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "soalazaina",
        fokontany: "andranobe sahafoy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8903337,
          lng: 47.9861678,
        },
        _id: "64f75285ef8f606073e9a768",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "soalazaina",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8860667,
          lng: 48.0605108,
        },
        _id: "64f75285ef8f606073e9a769",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "soalazaina",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.977847,
          lng: 48.0109374,
        },
        _id: "64f75285ef8f606073e9a76a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "soalazaina",
        fokontany: "vohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.10404,
          lng: 48.053501,
        },
        _id: "64f75285ef8f606073e9a76b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "tanambao besakay",
        fokontany: "tanambao besakay",
        __v: 0,
      },
      {
        coords: {
          lat: -18.162047,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e9a76c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "tanambao besakay",
        fokontany: "andriambe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1791281,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e9a76d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "tanambao besakay",
        fokontany: "anjiro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2677079,
          lng: 48.0605108,
        },
        _id: "64f75285ef8f606073e9a76e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "ambatondrazaka",
        commune: "tanambao besakay",
        fokontany: "mandanivatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9467556,
          lng: 48.227536,
        },
        _id: "64f75285ef8f606073e9a76f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "moramanga",
        fokontany: "moramanga ville",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9255569,
          lng: 48.24432909999999,
        },
        _id: "64f75285ef8f606073e9a770",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "moramanga",
        fokontany: "ambohitranjavidy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9384178,
          lng: 48.243085,
        },
        _id: "64f75285ef8f606073e9a771",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "moramanga",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9300575,
          lng: 48.2169653,
        },
        _id: "64f75285ef8f606073e9a772",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "moramanga",
        fokontany: "tsarafasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9407127,
          lng: 48.194587,
        },
        _id: "64f75285ef8f606073e9a773",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "moramanga",
        fokontany: "ambarilava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9414756,
          lng: 48.2334441,
        },
        _id: "64f75285ef8f606073e9a774",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "moramanga",
        fokontany: "tsaralalana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9440144,
          lng: 48.2262923,
        },
        _id: "64f75285ef8f606073e9a775",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "moramanga",
        fokontany: "ambohimadera",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9443432,
          lng: 48.2362429,
        },
        _id: "64f75285ef8f606073e9a776",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "moramanga",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.949255,
          lng: 48.2169653,
        },
        _id: "64f75285ef8f606073e9a777",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "moramanga",
        fokontany: "camp des maries",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9478425,
          lng: 48.2468174,
        },
        _id: "64f75285ef8f606073e9a778",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "moramanga",
        fokontany: "moramanga ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9537796,
          lng: 48.23126740000001,
        },
        _id: "64f75285ef8f606073e9a779",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "moramanga",
        fokontany: "antanamandroso est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9588544,
          lng: 48.2169653,
        },
        _id: "64f75285ef8f606073e9a77a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "moramanga",
        fokontany: "antanamandroso ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9639645,
          lng: 48.24432909999999,
        },
        _id: "64f75285ef8f606073e9a77b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "moramanga",
        fokontany: "ambodiakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9063557,
          lng: 48.24432909999999,
        },
        _id: "64f75285ef8f606073e9a77c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambohibary",
        fokontany: "analalava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7866852,
          lng: 48.1399232,
        },
        _id: "64f75285ef8f606073e9a77d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambohibary",
        fokontany: "ambohitrakanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8398891,
          lng: 48.2294017,
        },
        _id: "64f75285ef8f606073e9a77e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambohibary",
        fokontany: "ampitambe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8699545,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e9a77f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambohibary",
        fokontany: "befotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8702324,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e9a780",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambohibary",
        fokontany: "ankarahara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9140397,
          lng: 48.14985799999999,
        },
        _id: "64f75285ef8f606073e9a781",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambohibary",
        fokontany: "antsirinala",
        __v: 0,
      },
      {
        coords: {
          lat: -18.952405,
          lng: 48.14985799999999,
        },
        _id: "64f75285ef8f606073e9a782",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambohibary",
        fokontany: "soavinorona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9695039,
          lng: 48.194587,
        },
        _id: "64f75285ef8f606073e9a783",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambohibary",
        fokontany: "ambohimanatrika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0126796,
          lng: 48.2294017,
        },
        _id: "64f75285ef8f606073e9a784",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambohibary",
        fokontany: "sahafitahana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0099654,
          lng: 48.14985799999999,
        },
        _id: "64f75285ef8f606073e9a785",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambohibary",
        fokontany: "antsily",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1292972,
          lng: 48.19955909999999,
        },
        _id: "64f75285ef8f606073e9a786",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambohibary",
        fokontany: "ampahitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0812351,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e9a787",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambohibary",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.965401,
          lng: 48.337349,
        },
        _id: "64f75285ef8f606073e9a788",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ampasimpotsy gara",
        fokontany: "ampasimpotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9522327,
          lng: 48.2891359,
        },
        _id: "64f75285ef8f606073e9a789",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ampasimpotsy gara",
        fokontany: "tsiazompody",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9794988,
          lng: 48.3888384,
        },
        _id: "64f75285ef8f606073e9a78a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ampasimpotsy gara",
        fokontany: "amparafara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0153502,
          lng: 48.3090619,
        },
        _id: "64f75285ef8f606073e9a78b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ampasimpotsy gara",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.031019,
          lng: 48.339951,
        },
        _id: "64f75285ef8f606073e9a78c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ampasimpotsy gara",
        fokontany: "ambodiriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9210634,
          lng: 48.4038094,
        },
        _id: "64f75285ef8f606073e9a78d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andasibe",
        fokontany: "andasibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8586803,
          lng: 48.36888339999999,
        },
        _id: "64f75285ef8f606073e9a78e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andasibe",
        fokontany: "menalamba",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8241585,
          lng: 48.4187846,
        },
        _id: "64f75285ef8f606073e9a78f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andasibe",
        fokontany: "falierana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9126908,
          lng: 48.4437522,
        },
        _id: "64f75285ef8f606073e9a790",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andasibe",
        fokontany: "andasifahatelo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9381053,
          lng: 48.448747,
        },
        _id: "64f75285ef8f606073e9a791",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andasibe",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9766016,
          lng: 48.448747,
        },
        _id: "64f75285ef8f606073e9a792",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andasibe",
        fokontany: "ampangalantsary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9182165,
          lng: 48.0605108,
        },
        _id: "64f75285ef8f606073e9a793",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "anosibe ifody",
        fokontany: "anosibe ifody",
        __v: 0,
      },
      {
        coords: {
          lat: -18.866667,
          lng: 48.083333,
        },
        _id: "64f75285ef8f606073e9a794",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "anosibe ifody",
        fokontany: "ankarefo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8867449,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e9a795",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "anosibe ifody",
        fokontany: "ambodinifody",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9305324,
          lng: 48.070431,
        },
        _id: "64f75285ef8f606073e9a796",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "anosibe ifody",
        fokontany: "tsaramiafara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.166667,
          lng: 48.566667,
        },
        _id: "64f75285ef8f606073e9a797",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "vodiriana",
        fokontany: "vodiriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9668091,
          lng: 48.045634,
        },
        _id: "64f75285ef8f606073e9a798",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "vodiriana",
        fokontany: "berano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9808259,
          lng: 48.0183705,
        },
        _id: "64f75285ef8f606073e9a799",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "vodiriana",
        fokontany: "andreba",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0099008,
          lng: 48.0109374,
        },
        _id: "64f75285ef8f606073e9a79a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "vodiriana",
        fokontany: "antananjaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0126953,
          lng: 48.0902768,
        },
        _id: "64f75285ef8f606073e9a79b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "vodiriana",
        fokontany: "andranobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0208878,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e9a79c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "vodiriana",
        fokontany: "fatakana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6992489,
          lng: 48.269699,
        },
        _id: "64f75285ef8f606073e9a79d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "morarano gara",
        fokontany: "morarano gara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6,
          lng: 48.3,
        },
        _id: "64f75285ef8f606073e9a79e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "morarano gara",
        fokontany: "ambohidray",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6717106,
          lng: 48.1399232,
        },
        _id: "64f75285ef8f606073e9a79f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "morarano gara",
        fokontany: "marofody",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7349449,
          lng: 48.4088007,
        },
        _id: "64f75285ef8f606073e9a7a0",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "morarano gara",
        fokontany: "sakalava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7577233,
          lng: 48.2095048,
        },
        _id: "64f75285ef8f606073e9a7a1",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "morarano gara",
        fokontany: "androfia",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7655847,
          lng: 48.3090619,
        },
        _id: "64f75285ef8f606073e9a7a2",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "morarano gara",
        fokontany: "ambohibolakely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.793158,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e9a7a3",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "morarano gara",
        fokontany: "marovoay gara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8333409,
          lng: 47.9997883,
        },
        _id: "64f75285ef8f606073e9a7a4",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "belavabary",
        fokontany: "belavabary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8351204,
          lng: 48.0282829,
        },
        _id: "64f75285ef8f606073e9a7a5",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "belavabary",
        fokontany: "tsarafangitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8317311,
          lng: 47.99855119999999,
        },
        _id: "64f75285ef8f606073e9a7a6",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "belavabary",
        fokontany: "ampasika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8484334,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e9a7a7",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "belavabary",
        fokontany: "marovitsika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8696719,
          lng: 48.00598249999999,
        },
        _id: "64f75285ef8f606073e9a7a8",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "belavabary",
        fokontany: "antatabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8925848,
          lng: 47.9589345,
        },
        _id: "64f75285ef8f606073e9a7a9",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "sabotsy anjiro",
        fokontany: "sabotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.89646,
          lng: 47.97301100000001,
        },
        _id: "64f75285ef8f606073e9a7aa",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "sabotsy anjiro",
        fokontany: "anjiro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8956599,
          lng: 47.9960743,
        },
        _id: "64f75285ef8f606073e9a7ab",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "sabotsy anjiro",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9175432,
          lng: 48.00598249999999,
        },
        _id: "64f75285ef8f606073e9a7ac",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "sabotsy anjiro",
        fokontany: "miakadaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9230022,
          lng: 47.95645940000001,
        },
        _id: "64f75285ef8f606073e9a7ad",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "sabotsy anjiro",
        fokontany: "mahasoa miara miasa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9606149,
          lng: 47.9713115,
        },
        _id: "64f75285ef8f606073e9a7ae",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "sabotsy anjiro",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7541126,
          lng: 48.0208484,
        },
        _id: "64f75285ef8f606073e9a7af",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambohidronono",
        fokontany: "ambohidronono",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7255435,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e9a7b0",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambohidronono",
        fokontany: "ambohimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7288338,
          lng: 48.01589269999999,
        },
        _id: "64f75285ef8f606073e9a7b1",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambohidronono",
        fokontany: "ambodiranon'ambilona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.765806,
          lng: 47.9762632,
        },
        _id: "64f75285ef8f606073e9a7b2",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambohidronono",
        fokontany: "ambohimarina II",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8020449,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e9a7b3",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambohidronono",
        fokontany: "mangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7635376,
          lng: 48.0902768,
        },
        _id: "64f75285ef8f606073e9a7b4",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambohidronono",
        fokontany: "ambodirano mangoro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9694762,
          lng: 48.5775211,
        },
        _id: "64f75285ef8f606073e9a7b5",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "beforona",
        fokontany: "beforona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8605003,
          lng: 48.5887875,
        },
        _id: "64f75285ef8f606073e9a7b6",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "beforona",
        fokontany: "ankeniheny",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9044559,
          lng: 48.6088222,
        },
        _id: "64f75285ef8f606073e9a7b7",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "beforona",
        fokontany: "antandrokomby II",
        __v: 0,
      },
      {
        coords: {
          lat: -18.943014,
          lng: 48.6088222,
        },
        _id: "64f75285ef8f606073e9a7b8",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "beforona",
        fokontany: "ambinanisahavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9563883,
          lng: 48.5337293,
        },
        _id: "64f75285ef8f606073e9a7b9",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "beforona",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9452648,
          lng: 48.5637543,
        },
        _id: "64f75285ef8f606073e9a7ba",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "beforona",
        fokontany: "fierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9711875,
          lng: 48.623853,
        },
        _id: "64f75285ef8f606073e9a7bb",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "beforona",
        fokontany: "marozevo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0063001,
          lng: 48.6288642,
        },
        _id: "64f75285ef8f606073e9a7bc",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "beforona",
        fokontany: "antsakarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9983802,
          lng: 48.5287267,
        },
        _id: "64f75285ef8f606073e9a7bd",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "beforona",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.058728,
          lng: 48.6088222,
        },
        _id: "64f75285ef8f606073e9a7be",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "beforona",
        fokontany: "sahanonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0492843,
          lng: 48.5387323,
        },
        _id: "64f75285ef8f606073e9a7bf",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "beforona",
        fokontany: "ambodilaingo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1082315,
          lng: 48.6489134,
        },
        _id: "64f75285ef8f606073e9a7c0",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "beforona",
        fokontany: "ampasimazava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1413723,
          lng: 48.6288642,
        },
        _id: "64f75285ef8f606073e9a7c1",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "beforona",
        fokontany: "ambodiara I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8566166,
          lng: 48.5387323,
        },
        _id: "64f75285ef8f606073e9a7c2",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambatovola",
        fokontany: "ambatovola",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7665046,
          lng: 48.5437358,
        },
        _id: "64f75285ef8f606073e9a7c3",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambatovola",
        fokontany: "andonaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7688168,
          lng: 48.4987208,
        },
        _id: "64f75285ef8f606073e9a7c4",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambatovola",
        fokontany: "vohibazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.828493,
          lng: 48.5237246,
        },
        _id: "64f75285ef8f606073e9a7c5",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambatovola",
        fokontany: "antsiramihanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8462265,
          lng: 48.5537441,
        },
        _id: "64f75285ef8f606073e9a7c6",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambatovola",
        fokontany: "volove",
        __v: 0,
      },
      {
        coords: {
          lat: -18.867002,
          lng: 48.5237246,
        },
        _id: "64f75285ef8f606073e9a7c7",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambatovola",
        fokontany: "ankondromorona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9351521,
          lng: 48.5087209,
        },
        _id: "64f75285ef8f606073e9a7c8",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ambatovola",
        fokontany: "fanovana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1865099,
          lng: 48.43856,
        },
        _id: "64f75285ef8f606073e9a7c9",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "lakato",
        fokontany: "lakato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0935451,
          lng: 48.4187846,
        },
        _id: "64f75285ef8f606073e9a7ca",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "lakato",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2187186,
          lng: 48.4887224,
        },
        _id: "64f75285ef8f606073e9a7cb",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "lakato",
        fokontany: "mahafiaina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1649632,
          lng: 48.5387323,
        },
        _id: "64f75285ef8f606073e9a7cc",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "lakato",
        fokontany: "vohibolo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1980829,
          lng: 48.37886,
        },
        _id: "64f75285ef8f606073e9a7cd",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "lakato",
        fokontany: "ambatomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2738063,
          lng: 48.5487397,
        },
        _id: "64f75285ef8f606073e9a7ce",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "lakato",
        fokontany: "marofody",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2434626,
          lng: 48.36888339999999,
        },
        _id: "64f75285ef8f606073e9a7cf",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "lakato",
        fokontany: "ambodivarongy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2930294,
          lng: 48.4088007,
        },
        _id: "64f75285ef8f606073e9a7d0",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "lakato",
        fokontany: "ampasimazava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2586502,
          lng: 48.4587382,
        },
        _id: "64f75285ef8f606073e9a7d1",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "lakato",
        fokontany: "sahamadio",
        __v: 0,
      },
      {
        coords: {
          lat: -19.31513,
          lng: 48.4887224,
        },
        _id: "64f75285ef8f606073e9a7d2",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "lakato",
        fokontany: "sahavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.391689,
          lng: 48.270939,
        },
        _id: "64f75285ef8f606073e9a7d3",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "amboasary",
        fokontany: "amboasary gara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3742003,
          lng: 48.154826,
        },
        _id: "64f75285ef8f606073e9a7d4",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "amboasary",
        fokontany: "antanifasina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3826751,
          lng: 48.1150943,
        },
        _id: "64f75285ef8f606073e9a7d5",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "amboasary",
        fokontany: "antanifotsy sandranety",
        __v: 0,
      },
      {
        coords: {
          lat: -18.386714,
          lng: 48.2194523,
        },
        _id: "64f75285ef8f606073e9a7d6",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "amboasary",
        fokontany: "amboanjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3574319,
          lng: 48.2891359,
        },
        _id: "64f75285ef8f606073e9a7d7",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "amboasary",
        fokontany: "ambohidava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4034753,
          lng: 48.2642387,
        },
        _id: "64f75285ef8f606073e9a7d8",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "amboasary",
        fokontany: "ambohimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4105838,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e9a7d9",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "amboasary",
        fokontany: "marotsipoy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4447365,
          lng: 48.3289951,
        },
        _id: "64f75285ef8f606073e9a7da",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "amboasary",
        fokontany: "fanafana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.49093,
          lng: 48.1796732,
        },
        _id: "64f75285ef8f606073e9a7db",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "amboasary",
        fokontany: "antananambony",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4862506,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e9a7dc",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "amboasary",
        fokontany: "ampangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.391689,
          lng: 48.270939,
        },
        _id: "64f75285ef8f606073e9a7dd",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "amboasary",
        fokontany: "ambohibola",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5522127,
          lng: 48.2294017,
        },
        _id: "64f75285ef8f606073e9a7de",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "amboasary",
        fokontany: "ambohibary sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.547215,
          lng: 48.2642387,
        },
        _id: "64f75285ef8f606073e9a7df",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "amboasary",
        fokontany: "analabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4869,
          lng: 48.40153100000001,
        },
        _id: "64f75285ef8f606073e9a7e0",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "fierenana",
        fokontany: "fierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3631399,
          lng: 48.4187846,
        },
        _id: "64f75285ef8f606073e9a7e1",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "fierenana",
        fokontany: "ambohitranjakana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.419895,
          lng: 48.43376380000001,
        },
        _id: "64f75285ef8f606073e9a7e2",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "fierenana",
        fokontany: "amparihivola",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4327365,
          lng: 48.3738715,
        },
        _id: "64f75285ef8f606073e9a7e3",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "fierenana",
        fokontany: "mankary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4368403,
          lng: 48.4162884,
        },
        _id: "64f75285ef8f606073e9a7e4",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "fierenana",
        fokontany: "amboanjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4529707,
          lng: 48.41379240000001,
        },
        _id: "64f75285ef8f606073e9a7e5",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "fierenana",
        fokontany: "manakambahiny",
        __v: 0,
      },
      {
        coords: {
          lat: -18.513231,
          lng: 48.4237772,
        },
        _id: "64f75285ef8f606073e9a7e6",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "fierenana",
        fokontany: "ampatakana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5513385,
          lng: 48.36888339999999,
        },
        _id: "64f75285ef8f606073e9a7e7",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "fierenana",
        fokontany: "ambohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5343056,
          lng: 48.448747,
        },
        _id: "64f75285ef8f606073e9a7e8",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "fierenana",
        fokontany: "sahanomana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5780758,
          lng: 48.4687311,
        },
        _id: "64f75285ef8f606073e9a7e9",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "fierenana",
        fokontany: "ankailava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.608089,
          lng: 48.027962,
        },
        _id: "64f75285ef8f606073e9a7ea",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "mandialaza",
        fokontany: "mandialaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4932095,
          lng: 48.0109374,
        },
        _id: "64f75285ef8f606073e9a7eb",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "mandialaza",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -18.533333,
          lng: 48.033333,
        },
        _id: "64f75285ef8f606073e9a7ec",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "mandialaza",
        fokontany: "ambongabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5647536,
          lng: 48.045634,
        },
        _id: "64f75285ef8f606073e9a7ed",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "mandialaza",
        fokontany: "manakambahiny",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5696758,
          lng: 48.0109374,
        },
        _id: "64f75285ef8f606073e9a7ee",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "mandialaza",
        fokontany: "ambongatsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5949252,
          lng: 48.01589269999999,
        },
        _id: "64f75285ef8f606073e9a7ef",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "mandialaza",
        fokontany: "fierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5912136,
          lng: 48.0902768,
        },
        _id: "64f75285ef8f606073e9a7f0",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "mandialaza",
        fokontany: "ankokalava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6152734,
          lng: 48.0555514,
        },
        _id: "64f75285ef8f606073e9a7f1",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "mandialaza",
        fokontany: "ambohimiarimbe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6216446,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e9a7f2",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "mandialaza",
        fokontany: "antseva",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4588176,
          lng: 48.0605108,
        },
        _id: "64f75285ef8f606073e9a7f3",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "antaniditra",
        fokontany: "antaniditra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.47163,
          lng: 48.105251,
        },
        _id: "64f75285ef8f606073e9a7f4",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "antaniditra",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4588176,
          lng: 48.0605108,
        },
        _id: "64f75285ef8f606073e9a7f5",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "antaniditra",
        fokontany: "ambilombe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6898425,
          lng: 48.03076129999999,
        },
        _id: "64f75285ef8f606073e9a7f6",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ampasipotsy mandialaza",
        fokontany: "ampasipotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.652304,
          lng: 48.01589269999999,
        },
        _id: "64f75285ef8f606073e9a7f7",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ampasipotsy mandialaza",
        fokontany: "ambohibato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6508438,
          lng: 48.045634,
        },
        _id: "64f75285ef8f606073e9a7f8",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ampasipotsy mandialaza",
        fokontany: "antsinanambohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6927228,
          lng: 47.9713115,
        },
        _id: "64f75285ef8f606073e9a7f9",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ampasipotsy mandialaza",
        fokontany: "miadana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6815241,
          lng: 48.070431,
        },
        _id: "64f75285ef8f606073e9a7fa",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "ampasipotsy mandialaza",
        fokontany: "ambinanindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.094749,
          lng: 47.9562,
        },
        _id: "64f75285ef8f606073e9a7fb",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "antanandava",
        fokontany: "sahalemaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0584658,
          lng: 47.9960743,
        },
        _id: "64f75285ef8f606073e9a7fc",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "antanandava",
        fokontany: "ambodiakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0448082,
          lng: 48.01589269999999,
        },
        _id: "64f75285ef8f606073e9a7fd",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "antanandava",
        fokontany: "ampasimpotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.050861,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e9a7fe",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "antanandava",
        fokontany: "ambodinivongo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0592291,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e9a7ff",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "antanandava",
        fokontany: "tsarafangitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.066726,
          lng: 48.0258046,
        },
        _id: "64f75285ef8f606073e9a800",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "antanandava",
        fokontany: "andobotelo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0810395,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e9a801",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "antanandava",
        fokontany: "andakakely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0765049,
          lng: 47.9490349,
        },
        _id: "64f75285ef8f606073e9a802",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "antanandava",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1027959,
          lng: 47.931715,
        },
        _id: "64f75285ef8f606073e9a803",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "antanandava",
        fokontany: "ambolomborona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1049114,
          lng: 47.95645940000001,
        },
        _id: "64f75285ef8f606073e9a804",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "antanandava",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1755831,
          lng: 48.0208484,
        },
        _id: "64f75285ef8f606073e9a805",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "mangarivotra",
        fokontany: "mangarivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1112366,
          lng: 48.03076129999999,
        },
        _id: "64f75285ef8f606073e9a806",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "mangarivotra",
        fokontany: "ambalahorona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.193584,
          lng: 47.9737873,
        },
        _id: "64f75285ef8f606073e9a807",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "mangarivotra",
        fokontany: "ambohimiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2062568,
          lng: 47.9762632,
        },
        _id: "64f75285ef8f606073e9a808",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "mangarivotra",
        fokontany: "ankazondandy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2282011,
          lng: 47.9861678,
        },
        _id: "64f75285ef8f606073e9a809",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "mangarivotra",
        fokontany: "ambohimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2318445,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e9a80a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "mangarivotra",
        fokontany: "manakana est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2671731,
          lng: 47.9713115,
        },
        _id: "64f75285ef8f606073e9a80b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "mangarivotra",
        fokontany: "larobia andapa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2233637,
          lng: 48.2891359,
        },
        _id: "64f75285ef8f606073e9a80c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andaingo",
        fokontany: "andaingo I",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1337552,
          lng: 48.2941167,
        },
        _id: "64f75285ef8f606073e9a80d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andaingo",
        fokontany: "moratelo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.156269,
          lng: 48.2420199,
        },
        _id: "64f75285ef8f606073e9a80e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andaingo",
        fokontany: "ambohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1631807,
          lng: 48.2244268,
        },
        _id: "64f75285ef8f606073e9a80f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andaingo",
        fokontany: "antanimafy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1711944,
          lng: 48.3090619,
        },
        _id: "64f75285ef8f606073e9a810",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andaingo",
        fokontany: "ambodiampalibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1796727,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e9a811",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andaingo",
        fokontany: "ambodirano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2108271,
          lng: 48.169733,
        },
        _id: "64f75285ef8f606073e9a812",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andaingo",
        fokontany: "ampasika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2171844,
          lng: 48.25677169999999,
        },
        _id: "64f75285ef8f606073e9a813",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andaingo",
        fokontany: "andaingo II",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2335963,
          lng: 48.2194523,
        },
        _id: "64f75285ef8f606073e9a814",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andaingo",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2529098,
          lng: 48.2741962,
        },
        _id: "64f75285ef8f606073e9a815",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andaingo",
        fokontany: "mandrota",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2887628,
          lng: 48.2605051,
        },
        _id: "64f75285ef8f606073e9a816",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andaingo",
        fokontany: "bembary",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3060749,
          lng: 48.2941167,
        },
        _id: "64f75285ef8f606073e9a817",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andaingo",
        fokontany: "andranomadio",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3255768,
          lng: 48.169733,
        },
        _id: "64f75285ef8f606073e9a818",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andaingo",
        fokontany: "ambatomanolotra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3364324,
          lng: 48.2642387,
        },
        _id: "64f75285ef8f606073e9a819",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andaingo",
        fokontany: "ankodahoda",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3321589,
          lng: 48.2841556,
        },
        _id: "64f75285ef8f606073e9a81a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andaingo",
        fokontany: "amboasarikely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3353932,
          lng: 48.2244268,
        },
        _id: "64f75285ef8f606073e9a81b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andaingo",
        fokontany: "ambohimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3441293,
          lng: 48.0605108,
        },
        _id: "64f75285ef8f606073e9a81c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "moramanga",
        commune: "andaingo",
        fokontany: "moratsiazo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.015571,
          lng: 48.5537441,
        },
        _id: "64f75285ef8f606073e9a81d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "andilamena",
        fokontany: "andilamena III",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8031782,
          lng: 48.6790007,
        },
        _id: "64f75285ef8f606073e9a81e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "andilamena",
        fokontany: "antsirabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9481017,
          lng: 48.6088222,
        },
        _id: "64f75285ef8f606073e9a81f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "andilamena",
        fokontany: "amparatsiraka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9514517,
          lng: 48.739224,
        },
        _id: "64f75285ef8f606073e9a820",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "andilamena",
        fokontany: "behorefo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.982441,
          lng: 48.622318,
        },
        _id: "64f75285ef8f606073e9a821",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "andilamena",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0018008,
          lng: 48.5462377,
        },
        _id: "64f75285ef8f606073e9a822",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "andilamena",
        fokontany: "ampamohokely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0102626,
          lng: 48.6288642,
        },
        _id: "64f75285ef8f606073e9a823",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "andilamena",
        fokontany: "andilamena II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0254632,
          lng: 48.5937955,
        },
        _id: "64f75285ef8f606073e9a824",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "andilamena",
        fokontany: "andilamena I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0349509,
          lng: 48.7291822,
        },
        _id: "64f75285ef8f606073e9a825",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "andilamena",
        fokontany: "antsiradava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0420659,
          lng: 48.5837799,
        },
        _id: "64f75285ef8f606073e9a826",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "andilamena",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1247992,
          lng: 48.6288642,
        },
        _id: "64f75285ef8f606073e9a827",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "andilamena",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -17.051291,
          lng: 48.646439,
        },
        _id: "64f75285ef8f606073e9a828",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "andilamena",
        fokontany: "sahavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0537099,
          lng: 48.5537441,
        },
        _id: "64f75285ef8f606073e9a829",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "andilamena",
        fokontany: "maromandia",
        __v: 0,
      },
      {
        coords: {
          lat: -17.102836,
          lng: 48.5787728,
        },
        _id: "64f75285ef8f606073e9a82a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "andilamena",
        fokontany: "ampamoho",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0651723,
          lng: 48.5119772,
        },
        _id: "64f75285ef8f606073e9a82b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "bemaitso",
        fokontany: "bemaitso",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9989251,
          lng: 48.4287702,
        },
        _id: "64f75285ef8f606073e9a82c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "bemaitso",
        fokontany: "ambodifamotsotra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0419689,
          lng: 48.448747,
        },
        _id: "64f75285ef8f606073e9a82d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "bemaitso",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0247356,
          lng: 48.5137217,
        },
        _id: "64f75285ef8f606073e9a82e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "bemaitso",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.033333,
          lng: 48.316667,
        },
        _id: "64f75285ef8f606073e9a82f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "bemaitso",
        fokontany: "ambatomalama",
        __v: 0,
      },
      {
        coords: {
          lat: -16.99098,
          lng: 48.2047079,
        },
        _id: "64f75285ef8f606073e9a830",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "bemaitso",
        fokontany: "betalevana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1492623,
          lng: 48.4587382,
        },
        _id: "64f75285ef8f606073e9a831",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "bemaitso",
        fokontany: "antsomangana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9367067,
          lng: 48.4899721,
        },
        _id: "64f75285ef8f606073e9a832",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "antanimenabaka",
        fokontany: "antanimenabaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7145643,
          lng: 48.5887875,
        },
        _id: "64f75285ef8f606073e9a833",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "antanimenabaka",
        fokontany: "ambodilaitra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7880307,
          lng: 48.5387323,
        },
        _id: "64f75285ef8f606073e9a834",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "antanimenabaka",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -16.891376,
          lng: 48.5137217,
        },
        _id: "64f75285ef8f606073e9a835",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "antanimenabaka",
        fokontany: "ankaiafo atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9,
          lng: 48.3,
        },
        _id: "64f75285ef8f606073e9a836",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "antanimenabaka",
        fokontany: "mangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9134712,
          lng: 48.19955909999999,
        },
        _id: "64f75285ef8f606073e9a837",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "antanimenabaka",
        fokontany: "antanifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9248685,
          lng: 48.5337293,
        },
        _id: "64f75285ef8f606073e9a838",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "antanimenabaka",
        fokontany: "vodivato",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9432339,
          lng: 48.4537424,
        },
        _id: "64f75285ef8f606073e9a839",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "antanimenabaka",
        fokontany: "ambohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -16.966667,
          lng: 48.55,
        },
        _id: "64f75285ef8f606073e9a83a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "antanimenabaka",
        fokontany: "andranotsara",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9707384,
          lng: 48.4687311,
        },
        _id: "64f75285ef8f606073e9a83b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "antanimenabaka",
        fokontany: "ankisaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8009937,
          lng: 48.3589086,
        },
        _id: "64f75285ef8f606073e9a83c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "tanananifololahy",
        fokontany: "tanananifololahy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7355927,
          lng: 48.3439498,
        },
        _id: "64f75285ef8f606073e9a83d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "tanananifololahy",
        fokontany: "amparihilava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7841364,
          lng: 48.3289951,
        },
        _id: "64f75285ef8f606073e9a83e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "tanananifololahy",
        fokontany: "ambohimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8330157,
          lng: 48.3539219,
        },
        _id: "64f75285ef8f606073e9a83f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "tanananifololahy",
        fokontany: "maroantova",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8601154,
          lng: 48.3289951,
        },
        _id: "64f75285ef8f606073e9a840",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "tanananifololahy",
        fokontany: "tsaratanimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6359698,
          lng: 48.3783012,
        },
        _id: "64f75285ef8f606073e9a841",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "maroadabo",
        fokontany: "maroadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.523357,
          lng: 48.3489356,
        },
        _id: "64f75285ef8f606073e9a842",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "maroadabo",
        fokontany: "andranofasina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5873055,
          lng: 48.33896439999999,
        },
        _id: "64f75285ef8f606073e9a843",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "maroadabo",
        fokontany: "amboasaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6358277,
          lng: 48.5387323,
        },
        _id: "64f75285ef8f606073e9a844",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "maroadabo",
        fokontany: "ankaiafo avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6614888,
          lng: 48.448747,
        },
        _id: "64f75285ef8f606073e9a845",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "maroadabo",
        fokontany: "bevoay",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6848689,
          lng: 48.3888384,
        },
        _id: "64f75285ef8f606073e9a846",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "maroadabo",
        fokontany: "ampondravelona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.46693,
          lng: 48.41426800000001,
        },
        _id: "64f75285ef8f606073e9a847",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "marovato",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4285888,
          lng: 48.3489356,
        },
        _id: "64f75285ef8f606073e9a848",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "marovato",
        fokontany: "berahotra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4837843,
          lng: 48.5387323,
        },
        _id: "64f75285ef8f606073e9a849",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "marovato",
        fokontany: "ambodisatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5694462,
          lng: 48.5787728,
        },
        _id: "64f75285ef8f606073e9a84a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "marovato",
        fokontany: "marofany",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6015,
          lng: 48.22085999999999,
        },
        _id: "64f75285ef8f606073e9a84b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "miarinarivo",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4921193,
          lng: 48.2592606,
        },
        _id: "64f75285ef8f606073e9a84c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "miarinarivo",
        fokontany: "ambatovoay sud",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5290297,
          lng: 48.1002024,
        },
        _id: "64f75285ef8f606073e9a84d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "miarinarivo",
        fokontany: "ampondrabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.604679,
          lng: 48.1002024,
        },
        _id: "64f75285ef8f606073e9a84e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "miarinarivo",
        fokontany: "antsevakely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.718228,
          lng: 48.1002024,
        },
        _id: "64f75285ef8f606073e9a84f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "miarinarivo",
        fokontany: "manopy sud",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8038303,
          lng: 48.1399232,
        },
        _id: "64f75285ef8f606073e9a850",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "miarinarivo",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6015,
          lng: 48.22085999999999,
        },
        _id: "64f75285ef8f606073e9a851",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "miarinarivo",
        fokontany: "ambodiakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4252885,
          lng: 48.1399232,
        },
        _id: "64f75285ef8f606073e9a852",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "maitsokely",
        fokontany: "ambalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4079729,
          lng: 47.81803800000001,
        },
        _id: "64f75285ef8f606073e9a853",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "maitsokely",
        fokontany: "ankiripika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3445235,
          lng: 48.0406759,
        },
        _id: "64f75285ef8f606073e9a854",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "maitsokely",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3265852,
          lng: 48.2791757,
        },
        _id: "64f75285ef8f606073e9a855",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "maitsokely",
        fokontany: "makoeny",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4282662,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e9a856",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "maitsokely",
        fokontany: "ambalavia",
        __v: 0,
      },
      {
        coords: {
          lat: -16.490152,
          lng: 47.9416114,
        },
        _id: "64f75285ef8f606073e9a857",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "andilamena",
        commune: "maitsokely",
        fokontany: "ambatomiankina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.43598,
          lng: 48.202862,
        },
        _id: "64f75285ef8f606073e9a858",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "anosibe an'ala",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2582659,
          lng: 48.1796732,
        },
        _id: "64f75285ef8f606073e9a859",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "fihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3556303,
          lng: 48.14985799999999,
        },
        _id: "64f75285ef8f606073e9a85a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "mahela",
        __v: 0,
      },
      {
        coords: {
          lat: -19.39623,
          lng: 48.1747029,
        },
        _id: "64f75285ef8f606073e9a85b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "analamazava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3586529,
          lng: 48.2294017,
        },
        _id: "64f75285ef8f606073e9a85c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "faravohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3937076,
          lng: 48.23437699999999,
        },
        _id: "64f75285ef8f606073e9a85d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "ambodivona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4269282,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e9a85e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "ankoraka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4169929,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e9a85f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "andranotapaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.437937,
          lng: 48.2119915,
        },
        _id: "64f75285ef8f606073e9a860",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "anosibekely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4631802,
          lng: 48.1051659,
        },
        _id: "64f75285ef8f606073e9a861",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.455468,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e9a862",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "vohibola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4573254,
          lng: 48.169733,
        },
        _id: "64f75285ef8f606073e9a863",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "maromby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4843282,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e9a864",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "andranambolava I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4976089,
          lng: 48.12502449999999,
        },
        _id: "64f75285ef8f606073e9a865",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "tsaratampona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5131917,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e9a866",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "ampasimadinika I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5144132,
          lng: 48.184644,
        },
        _id: "64f75285ef8f606073e9a867",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "anjafindreninato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5392561,
          lng: 48.2045317,
        },
        _id: "64f75285ef8f606073e9a868",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "andranomaria",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5286487,
          lng: 48.14985799999999,
        },
        _id: "64f75285ef8f606073e9a869",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "ampasimazava andobo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.576907,
          lng: 48.3040797,
        },
        _id: "64f75285ef8f606073e9a86a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5566791,
          lng: 48.24930579999999,
        },
        _id: "64f75285ef8f606073e9a86b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "ambodimerana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.616667,
          lng: 48.283333,
        },
        _id: "64f75285ef8f606073e9a86c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6331083,
          lng: 48.2642387,
        },
        _id: "64f75285ef8f606073e9a86d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "anosibe an'ala",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.37805,
          lng: 48.06945,
        },
        _id: "64f75285ef8f606073e9a86e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ampasimaneva",
        fokontany: "ampasimaneva",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2974757,
          lng: 48.0109374,
        },
        _id: "64f75285ef8f606073e9a86f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ampasimaneva",
        fokontany: "andranotsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3414188,
          lng: 48.03076129999999,
        },
        _id: "64f75285ef8f606073e9a870",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ampasimaneva",
        fokontany: "antanambao ifasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3822358,
          lng: 47.9713115,
        },
        _id: "64f75285ef8f606073e9a871",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ampasimaneva",
        fokontany: "manarintsoa II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3684804,
          lng: 48.299098,
        },
        _id: "64f75285ef8f606073e9a872",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ampandroantraka",
        fokontany: "ampandroantraka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2914916,
          lng: 48.299098,
        },
        _id: "64f75285ef8f606073e9a873",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ampandroantraka",
        fokontany: "bevanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3368329,
          lng: 48.2891359,
        },
        _id: "64f75285ef8f606073e9a874",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ampandroantraka",
        fokontany: "ampasimaneva",
        __v: 0,
      },
      {
        coords: {
          lat: -19.35169,
          lng: 48.349609,
        },
        _id: "64f75285ef8f606073e9a875",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ampandroantraka",
        fokontany: "bekobaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.383333,
          lng: 48.283333,
        },
        _id: "64f75285ef8f606073e9a876",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ampandroantraka",
        fokontany: "ampasimpotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4112601,
          lng: 48.3489356,
        },
        _id: "64f75285ef8f606073e9a877",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ampandroantraka",
        fokontany: "ambodivolafotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4411808,
          lng: 48.24930579999999,
        },
        _id: "64f75285ef8f606073e9a878",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ampandroantraka",
        fokontany: "antenimbe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4951728,
          lng: 48.33896439999999,
        },
        _id: "64f75285ef8f606073e9a879",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "tratramarina",
        fokontany: "tratramarina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4746331,
          lng: 48.36888339999999,
        },
        _id: "64f75285ef8f606073e9a87a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "tratramarina",
        fokontany: "ambinanindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5138131,
          lng: 48.3539219,
        },
        _id: "64f75285ef8f606073e9a87b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "tratramarina",
        fokontany: "ambonihiboka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5153403,
          lng: 48.3938283,
        },
        _id: "64f75285ef8f606073e9a87c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "tratramarina",
        fokontany: "maroandiana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.504514,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e9a87d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "tratramarina",
        fokontany: "andongotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5865432,
          lng: 47.9812152,
        },
        _id: "64f75285ef8f606073e9a87e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "antandrokomby",
        fokontany: "antandrokomby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.566667,
          lng: 48.066667,
        },
        _id: "64f75285ef8f606073e9a87f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "antandrokomby",
        fokontany: "sahampasina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.583333,
          lng: 48.00000000000001,
        },
        _id: "64f75285ef8f606073e9a880",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "antandrokomby",
        fokontany: "andrangaranga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.581671,
          lng: 47.940762,
        },
        _id: "64f75285ef8f606073e9a881",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "antandrokomby",
        fokontany: "ampitamalandy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5881719,
          lng: 48.0629907,
        },
        _id: "64f75285ef8f606073e9a882",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "antandrokomby",
        fokontany: "androrangavola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5983598,
          lng: 48.0481132,
        },
        _id: "64f75285ef8f606073e9a883",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "antandrokomby",
        fokontany: "sahataolana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6006678,
          lng: 47.9465602,
        },
        _id: "64f75285ef8f606073e9a884",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "antandrokomby",
        fokontany: "sahasomangana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6255096,
          lng: 47.9663604,
        },
        _id: "64f75285ef8f606073e9a885",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "antandrokomby",
        fokontany: "morafeno II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6079933,
          lng: 48.00598249999999,
        },
        _id: "64f75285ef8f606073e9a886",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "antandrokomby",
        fokontany: "ambohimadera",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6368043,
          lng: 48.00598249999999,
        },
        _id: "64f75285ef8f606073e9a887",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "antandrokomby",
        fokontany: "andranomahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6407368,
          lng: 48.070431,
        },
        _id: "64f75285ef8f606073e9a888",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "antandrokomby",
        fokontany: "ambatomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6517738,
          lng: 48.0332398,
        },
        _id: "64f75285ef8f606073e9a889",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "antandrokomby",
        fokontany: "ankorabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.661947,
          lng: 48.0183705,
        },
        _id: "64f75285ef8f606073e9a88a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "antandrokomby",
        fokontany: "belay",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6709897,
          lng: 48.0332398,
        },
        _id: "64f75285ef8f606073e9a88b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "antandrokomby",
        fokontany: "maromitety",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6811605,
          lng: 48.0183705,
        },
        _id: "64f75285ef8f606073e9a88c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "antandrokomby",
        fokontany: "ankoraka I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.686535,
          lng: 48.045634,
        },
        _id: "64f75285ef8f606073e9a88d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "antandrokomby",
        fokontany: "sandranora",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6904342,
          lng: 47.9416114,
        },
        _id: "64f75285ef8f606073e9a88e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ambalaomby",
        fokontany: "ambalaomby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.65,
          lng: 47.966667,
        },
        _id: "64f75285ef8f606073e9a88f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ambalaomby",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6791018,
          lng: 47.9020367,
        },
        _id: "64f75285ef8f606073e9a890",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ambalaomby",
        fokontany: "androrangavola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6944356,
          lng: 48.00598249999999,
        },
        _id: "64f75285ef8f606073e9a891",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ambalaomby",
        fokontany: "ambalatenina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7091228,
          lng: 47.99855119999999,
        },
        _id: "64f75285ef8f606073e9a892",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ambalaomby",
        fokontany: "fanovana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7193139,
          lng: 48.0258046,
        },
        _id: "64f75285ef8f606073e9a893",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ambalaomby",
        fokontany: "ambatolampy I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7526267,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e9a894",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ambalaomby",
        fokontany: "sahatompo I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7491142,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e9a895",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ambalaomby",
        fokontany: "sahofika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5254284,
          lng: 48.070431,
        },
        _id: "64f75285ef8f606073e9a896",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "niarovana marosampanana",
        fokontany: "niarovana marosampanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4870037,
          lng: 48.070431,
        },
        _id: "64f75285ef8f606073e9a897",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "niarovana marosampanana",
        fokontany: "androrangabe II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5502612,
          lng: 48.0902768,
        },
        _id: "64f75285ef8f606073e9a898",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "niarovana marosampanana",
        fokontany: "ambohimanakana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.471373,
          lng: 47.9812152,
        },
        _id: "64f75285ef8f606073e9a899",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "tsaravinany",
        fokontany: "tsaravinany",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4819955,
          lng: 48.0357184,
        },
        _id: "64f75285ef8f606073e9a89a",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "tsaravinany",
        fokontany: "ambohimandiny",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4639871,
          lng: 48.00598249999999,
        },
        _id: "64f75285ef8f606073e9a89b",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "tsaravinany",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5109225,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e9a89c",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "tsaravinany",
        fokontany: "andonabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.524549,
          lng: 48.01041,
        },
        _id: "64f75285ef8f606073e9a89d",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "tsaravinany",
        fokontany: "ambodihazovelona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.751591,
          lng: 48.208111,
        },
        _id: "64f75285ef8f606073e9a89e",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "longozabe",
        fokontany: "longozabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6535605,
          lng: 48.23437699999999,
        },
        _id: "64f75285ef8f606073e9a89f",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "longozabe",
        fokontany: "ambohimiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7028753,
          lng: 48.2045317,
        },
        _id: "64f75285ef8f606073e9a8a0",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "longozabe",
        fokontany: "ankorak'i dodo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7040329,
          lng: 48.1747029,
        },
        _id: "64f75285ef8f606073e9a8a1",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "longozabe",
        fokontany: "ambodiara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.696135,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e9a8a2",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "longozabe",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7163984,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e9a8a3",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "longozabe",
        fokontany: "niarovana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7379067,
          lng: 48.2941167,
        },
        _id: "64f75285ef8f606073e9a8a4",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "longozabe",
        fokontany: "morarano est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7425305,
          lng: 48.0902768,
        },
        _id: "64f75285ef8f606073e9a8a5",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "longozabe",
        fokontany: "vohitrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.749868,
          lng: 48.23437699999999,
        },
        _id: "64f75285ef8f606073e9a8a6",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "longozabe",
        fokontany: "ambodiarabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7600852,
          lng: 48.1349565,
        },
        _id: "64f75285ef8f606073e9a8a7",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "longozabe",
        fokontany: "vohibola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7589556,
          lng: 48.1647635,
        },
        _id: "64f75285ef8f606073e9a8a8",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "longozabe",
        fokontany: "betoko",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7838728,
          lng: 48.184644,
        },
        _id: "64f75285ef8f606073e9a8a9",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "longozabe",
        fokontany: "ambodinivato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7691336,
          lng: 48.23437699999999,
        },
        _id: "64f75285ef8f606073e9a8aa",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "longozabe",
        fokontany: "ambodiarakely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7708093,
          lng: 48.2741962,
        },
        _id: "64f75285ef8f606073e9a8ab",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "longozabe",
        fokontany: "andonaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7946247,
          lng: 48.154826,
        },
        _id: "64f75285ef8f606073e9a8ac",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "longozabe",
        fokontany: "ambalarangotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7895379,
          lng: 48.2045317,
        },
        _id: "64f75285ef8f606073e9a8ad",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "longozabe",
        fokontany: "ambodinonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8068073,
          lng: 48.1722179,
        },
        _id: "64f75285ef8f606073e9a8ae",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "longozabe",
        fokontany: "ambinanisasaty",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6778613,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e9a8af",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "longozabe",
        fokontany: "ambodivoara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6316237,
          lng: 48.1399232,
        },
        _id: "64f75285ef8f606073e9a8b0",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ambatoharanana",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5909305,
          lng: 48.1150943,
        },
        _id: "64f75285ef8f606073e9a8b1",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ambatoharanana",
        fokontany: "antsiraka II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.607019,
          lng: 48.221699,
        },
        _id: "64f75285ef8f606073e9a8b2",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ambatoharanana",
        fokontany: "mahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -19.614613,
          lng: 48.1647635,
        },
        _id: "64f75285ef8f606073e9a8b3",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ambatoharanana",
        fokontany: "ambinanifanalazana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.655329,
          lng: 48.1896152,
        },
        _id: "64f75285ef8f606073e9a8b4",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ambatoharanana",
        fokontany: "ambohipeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6293897,
          lng: 48.1150943,
        },
        _id: "64f75285ef8f606073e9a8b5",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ambatoharanana",
        fokontany: "sahafasenina",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6378414,
          lng: 48.1448904,
        },
        _id: "64f75285ef8f606073e9a8b6",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ambatoharanana",
        fokontany: "befoza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.666667,
          lng: 48.116667,
        },
        _id: "64f75285ef8f606073e9a8b7",
        province: "toamasina",
        region: "alaotra mangoro",
        district: "anosibe-an'ala",
        commune: "ambatoharanana",
        fokontany: "andonabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7215338,
          lng: 46.3085144,
        },
        _id: "64f75285ef8f606073e9a8b8",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahajanga",
        fokontany: "mahajanga ville",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7154465,
          lng: 46.3187012,
        },
        _id: "64f75285ef8f606073e9a8b9",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahajanga",
        fokontany: "manga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7200466,
          lng: 46.3187012,
        },
        _id: "64f75285ef8f606073e9a8ba",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahajanga",
        fokontany: "ambovoalanana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7082484,
          lng: 46.3175027,
        },
        _id: "64f75285ef8f606073e9a8bb",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahajanga",
        fokontany: "tsaramandroso ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7102499,
          lng: 46.3163042,
        },
        _id: "64f75285ef8f606073e9a8bc",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahajanga",
        fokontany: "tsaramandroso ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7043297,
          lng: 46.313308,
        },
        _id: "64f75285ef8f606073e9a8bd",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahajanga",
        fokontany: "mahavoky atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6992199,
          lng: 46.3204991,
        },
        _id: "64f75285ef8f606073e9a8be",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahajanga",
        fokontany: "mahavoky avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7195374,
          lng: 46.3258932,
        },
        _id: "64f75285ef8f606073e9a8bf",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahajanga",
        fokontany: "manjarisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7160434,
          lng: 46.3210984,
        },
        _id: "64f75285ef8f606073e9a8c0",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahajanga",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7137434,
          lng: 46.3210984,
        },
        _id: "64f75285ef8f606073e9a8c1",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahajanga",
        fokontany: "mahabibokely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7197921,
          lng: 46.3222971,
        },
        _id: "64f75285ef8f606073e9a8c2",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahajanga",
        fokontany: "abattoir marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7154858,
          lng: 46.3073161,
        },
        _id: "64f75285ef8f606073e9a8c3",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahajanga",
        fokontany: "mangarivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7240108,
          lng: 46.3276913,
        },
        _id: "64f75285ef8f606073e9a8c4",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahajanga",
        fokontany: "aranta",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7238437,
          lng: 46.3408798,
        },
        _id: "64f75285ef8f606073e9a8c5",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahabibo",
        fokontany: "antanimasaja",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7125957,
          lng: 46.33728259999999,
        },
        _id: "64f75285ef8f606073e9a8c6",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahabibo",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7228232,
          lng: 46.3552713,
        },
        _id: "64f75285ef8f606073e9a8c7",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahabibo",
        fokontany: "tanambao sotema",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7078243,
          lng: 46.35047360000001,
        },
        _id: "64f75285ef8f606073e9a8c8",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahabibo",
        fokontany: "ambohimandamina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7050951,
          lng: 46.3672674,
        },
        _id: "64f75285ef8f606073e9a8c9",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahabibo",
        fokontany: "antanimalandy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6916371,
          lng: 46.3408798,
        },
        _id: "64f75285ef8f606073e9a8ca",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahabibo",
        fokontany: "ambondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7171098,
          lng: 46.3276913,
        },
        _id: "64f75285ef8f606073e9a8cb",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahabibo",
        fokontany: "fiofio",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7143402,
          lng: 46.3234958,
        },
        _id: "64f75285ef8f606073e9a8cc",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahabibo",
        fokontany: "ambalavola",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7102934,
          lng: 46.3210984,
        },
        _id: "64f75285ef8f606073e9a8cd",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahabibo",
        fokontany: "tanambao ambalavato",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7030534,
          lng: 46.3312878,
        },
        _id: "64f75285ef8f606073e9a8ce",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahabibo",
        fokontany: "tsararano ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7062901,
          lng: 46.3234958,
        },
        _id: "64f75285ef8f606073e9a8cf",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahabibo",
        fokontany: "tsararano nosikely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7102091,
          lng: 46.3276913,
        },
        _id: "64f75285ef8f606073e9a8d0",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahabibo",
        fokontany: "tsararano ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6703422,
          lng: 46.33848159999999,
        },
        _id: "64f75285ef8f606073e9a8d1",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga I",
        commune: "mahabibo",
        fokontany: "amborovy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0973613,
          lng: 45.3486895,
        },
        _id: "64f75285ef8f606073e9a8d2",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "soalala",
        fokontany: "soalala",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9924667,
          lng: 45.4969122,
        },
        _id: "64f75285ef8f606073e9a8d3",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "soalala",
        fokontany: "antamboho",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0043992,
          lng: 45.4027482,
        },
        _id: "64f75285ef8f606073e9a8d4",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "soalala",
        fokontany: "anjiamaloto",
        __v: 0,
      },
      {
        coords: {
          lat: -16.066667,
          lng: 45.266667,
        },
        _id: "64f75285ef8f606073e9a8d5",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "soalala",
        fokontany: "baly",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0953795,
          lng: 45.506339,
        },
        _id: "64f75285ef8f606073e9a8d6",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "soalala",
        fokontany: "ambarinanahary",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1054948,
          lng: 45.4403911,
        },
        _id: "64f75285ef8f606073e9a8d7",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "soalala",
        fokontany: "antsakoamileka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2050624,
          lng: 45.506339,
        },
        _id: "64f75285ef8f606073e9a8d8",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "soalala",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1387653,
          lng: 45.3933422,
        },
        _id: "64f75285ef8f606073e9a8d9",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "soalala",
        fokontany: "belafika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1819566,
          lng: 45.2806177,
        },
        _id: "64f75285ef8f606073e9a8da",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "soalala",
        fokontany: "amboroka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2134662,
          lng: 45.3651355,
        },
        _id: "64f75285ef8f606073e9a8db",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "soalala",
        fokontany: "bekomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2682675,
          lng: 45.4686431,
        },
        _id: "64f75285ef8f606073e9a8dc",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "soalala",
        fokontany: "madiromileka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.32567,
          lng: 45.621811,
        },
        _id: "64f75285ef8f606073e9a8dd",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "soalala",
        fokontany: "antanimavo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3379347,
          lng: 45.3181623,
        },
        _id: "64f75285ef8f606073e9a8de",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "soalala",
        fokontany: "bebao",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4288409,
          lng: 44.971531,
        },
        _id: "64f75285ef8f606073e9a8df",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "ambohipaky",
        fokontany: "ambohipaky",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2333329,
          lng: 45.1,
        },
        _id: "64f75285ef8f606073e9a8e0",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "ambohipaky",
        fokontany: "bemena",
        __v: 0,
      },
      {
        coords: {
          lat: -16.23078,
          lng: 45.041328,
        },
        _id: "64f75285ef8f606073e9a8e1",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "ambohipaky",
        fokontany: "maroaboaly",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2445574,
          lng: 44.9626829,
        },
        _id: "64f75285ef8f606073e9a8e2",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "ambohipaky",
        fokontany: "ambatolafia",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3769693,
          lng: 45.0559871,
        },
        _id: "64f75285ef8f606073e9a8e3",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "ambohipaky",
        fokontany: "soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.417307,
          lng: 45.2056196,
        },
        _id: "64f75285ef8f606073e9a8e4",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "ambohipaky",
        fokontany: "andrafiatoka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5328902,
          lng: 45.0933497,
        },
        _id: "64f75285ef8f606073e9a8e5",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "ambohipaky",
        fokontany: "tsinjorano II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.578819,
          lng: 45.56411929999999,
        },
        _id: "64f75285ef8f606073e9a8e6",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "andranomavo",
        fokontany: "andranomavo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4,
          lng: 45.283333,
        },
        _id: "64f75285ef8f606073e9a8e7",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "andranomavo",
        fokontany: "namoroka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.425211,
          lng: 45.714199,
        },
        _id: "64f75285ef8f606073e9a8e8",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "andranomavo",
        fokontany: "andrafiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4331249,
          lng: 45.5723794,
        },
        _id: "64f75285ef8f606073e9a8e9",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "andranomavo",
        fokontany: "ambalamanga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4298425,
          lng: 45.6290592,
        },
        _id: "64f75285ef8f606073e9a8ea",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "andranomavo",
        fokontany: "komihevitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4247073,
          lng: 45.506339,
        },
        _id: "64f75285ef8f606073e9a8eb",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "andranomavo",
        fokontany: "ambanjabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4944798,
          lng: 45.4333307,
        },
        _id: "64f75285ef8f606073e9a8ec",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "andranomavo",
        fokontany: "vilanandro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.566667,
          lng: 45.883333,
        },
        _id: "64f75285ef8f606073e9a8ed",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "andranomavo",
        fokontany: "soromaray",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6155397,
          lng: 45.8089934,
        },
        _id: "64f75285ef8f606073e9a8ee",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "andranomavo",
        fokontany: "andrafiamajera",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6153205,
          lng: 45.704738,
        },
        _id: "64f75285ef8f606073e9a8ef",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "andranomavo",
        fokontany: "behena",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5969166,
          lng: 45.2618568,
        },
        _id: "64f75285ef8f606073e9a8f0",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "andranomavo",
        fokontany: "tsarabanja",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6384678,
          lng: 45.6196079,
        },
        _id: "64f75285ef8f606073e9a8f1",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "andranomavo",
        fokontany: "amboromihanto",
        __v: 0,
      },
      {
        coords: {
          lat: -16.633333,
          lng: 45.35,
        },
        _id: "64f75285ef8f606073e9a8f2",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "andranomavo",
        fokontany: "befatika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.718069,
          lng: 45.366249,
        },
        _id: "64f75285ef8f606073e9a8f3",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "andranomavo",
        fokontany: "amboloando",
        __v: 0,
      },
      {
        coords: {
          lat: -16.722019,
          lng: 45.678669,
        },
        _id: "64f75285ef8f606073e9a8f4",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "andranomavo",
        fokontany: "tsaboahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7843121,
          lng: 45.4121561,
        },
        _id: "64f75285ef8f606073e9a8f5",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "andranomavo",
        fokontany: "andoharano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8325745,
          lng: 45.6574246,
        },
        _id: "64f75285ef8f606073e9a8f6",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "andranomavo",
        fokontany: "antsirabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.85,
          lng: 45.533333,
        },
        _id: "64f75285ef8f606073e9a8f7",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "andranomavo",
        fokontany: "ambodifano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.934669,
          lng: 45.551849,
        },
        _id: "64f75285ef8f606073e9a8f8",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "andranomavo",
        fokontany: "ambonara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1935192,
          lng: 45.5629394,
        },
        _id: "64f75285ef8f606073e9a8f9",
        province: "mahajanga",
        region: "boeny",
        district: "soalala",
        commune: "andranomavo",
        fokontany: "ankazomborona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4708895,
          lng: 46.7164256,
        },
        _id: "64f75285ef8f606073e9a8fa",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambato ambarimay",
        fokontany: "ambato boeny",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4637658,
          lng: 46.7067607,
        },
        _id: "64f75285ef8f606073e9a8fb",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambato ambarimay",
        fokontany: "ambato avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4621842,
          lng: 46.75510370000001,
        },
        _id: "64f75285ef8f606073e9a8fc",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambato ambarimay",
        fokontany: "ankaraobato",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4814265,
          lng: 46.7212587,
        },
        _id: "64f75285ef8f606073e9a8fd",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambato ambarimay",
        fokontany: "ambato atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4589798,
          lng: 46.660878,
        },
        _id: "64f75285ef8f606073e9a8fe",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambato ambarimay",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5038865,
          lng: 46.6367462,
        },
        _id: "64f75285ef8f606073e9a8ff",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambato ambarimay",
        fokontany: "ankarambilo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5026635,
          lng: 46.7043448,
        },
        _id: "64f75285ef8f606073e9a900",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambato ambarimay",
        fokontany: "betongoa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5181462,
          lng: 46.65605069999999,
        },
        _id: "64f75285ef8f606073e9a901",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambato ambarimay",
        fokontany: "maroelatra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5255971,
          lng: 46.6850215,
        },
        _id: "64f75285ef8f606073e9a902",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambato ambarimay",
        fokontany: "masiadokitra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.523689,
          lng: 46.852089,
        },
        _id: "64f75285ef8f606073e9a903",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambato ambarimay",
        fokontany: "ambesisika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5519265,
          lng: 46.660878,
        },
        _id: "64f75285ef8f606073e9a904",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambato ambarimay",
        fokontany: "anosikely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5159946,
          lng: 46.7381783,
        },
        _id: "64f75285ef8f606073e9a905",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambato ambarimay",
        fokontany: "ankifatry",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5358364,
          lng: 46.815598,
        },
        _id: "64f75285ef8f606073e9a906",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambato ambarimay",
        fokontany: "madirovalokely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5681267,
          lng: 46.7962319,
        },
        _id: "64f75285ef8f606073e9a907",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambato ambarimay",
        fokontany: "beritra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5758807,
          lng: 46.7720347,
        },
        _id: "64f75285ef8f606073e9a908",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambato ambarimay",
        fokontany: "maromandalo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5904463,
          lng: 46.7381783,
        },
        _id: "64f75285ef8f606073e9a909",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambato ambarimay",
        fokontany: "ambarimay",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5741644,
          lng: 46.60298119999999,
        },
        _id: "64f75285ef8f606073e9a90a",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambato ambarimay",
        fokontany: "anosikary",
        __v: 0,
      },
      {
        coords: {
          lat: -16.533333,
          lng: 46.7,
        },
        _id: "64f75285ef8f606073e9a90b",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambato ambarimay",
        fokontany: "amboroabo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6077245,
          lng: 46.660878,
        },
        _id: "64f75285ef8f606073e9a90c",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambato ambarimay",
        fokontany: "betaramahamay",
        __v: 0,
      },
      {
        coords: {
          lat: -16.59993,
          lng: 46.707611,
        },
        _id: "64f75285ef8f606073e9a90d",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambato ambarimay",
        fokontany: "marolaona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4074173,
          lng: 46.7865516,
        },
        _id: "64f75285ef8f606073e9a90e",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ankijabe",
        fokontany: "ankijabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3770541,
          lng: 46.7768732,
        },
        _id: "64f75285ef8f606073e9a90f",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ankijabe",
        fokontany: "belalitra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.36117,
          lng: 46.64157160000001,
        },
        _id: "64f75285ef8f606073e9a910",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ankijabe",
        fokontany: "bealana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3710228,
          lng: 46.6801919,
        },
        _id: "64f75285ef8f606073e9a911",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ankijabe",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3994616,
          lng: 46.7188421,
        },
        _id: "64f75285ef8f606073e9a912",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ankijabe",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3952385,
          lng: 46.83012739999999,
        },
        _id: "64f75285ef8f606073e9a913",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ankijabe",
        fokontany: "betaimborona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4377937,
          lng: 46.7962319,
        },
        _id: "64f75285ef8f606073e9a914",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ankijabe",
        fokontany: "soaniaviana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4449972,
          lng: 46.7333436,
        },
        _id: "64f75285ef8f606073e9a915",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ankijabe",
        fokontany: "manongarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4662859,
          lng: 46.83497149999999,
        },
        _id: "64f75285ef8f606073e9a916",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ankijabe",
        fokontany: "tsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4467101,
          lng: 46.8495065,
        },
        _id: "64f75285ef8f606073e9a917",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ankijabe",
        fokontany: "mangaroa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4820595,
          lng: 46.8785892,
        },
        _id: "64f75285ef8f606073e9a918",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ankijabe",
        fokontany: "andakavaky",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3761819,
          lng: 46.8834379,
        },
        _id: "64f75285ef8f606073e9a919",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "andranofasika",
        fokontany: "andranofasika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3494592,
          lng: 46.8422385,
        },
        _id: "64f75285ef8f606073e9a91a",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "andranofasika",
        fokontany: "bemailaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3697808,
          lng: 46.839816,
        },
        _id: "64f75285ef8f606073e9a91b",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "andranofasika",
        fokontany: "ambalambakisiny",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3497289,
          lng: 46.9076886,
        },
        _id: "64f75285ef8f606073e9a91c",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "andranofasika",
        fokontany: "ambongamaranitra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3928652,
          lng: 46.91254010000001,
        },
        _id: "64f75285ef8f606073e9a91d",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "andranofasika",
        fokontany: "mahatazantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4202799,
          lng: 46.8737409,
        },
        _id: "64f75285ef8f606073e9a91e",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "andranofasika",
        fokontany: "belalanda",
        __v: 0,
      },
      {
        coords: {
          lat: -16.44684,
          lng: 46.544239,
        },
        _id: "64f75285ef8f606073e9a91f",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "madirovalo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3693036,
          lng: 46.4200849,
        },
        _id: "64f75285ef8f606073e9a920",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "ambanjabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3316092,
          lng: 46.52589,
        },
        _id: "64f75285ef8f606073e9a921",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "antoboro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3732798,
          lng: 46.5981595,
        },
        _id: "64f75285ef8f606073e9a922",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "antanamifafy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4088601,
          lng: 46.574058,
        },
        _id: "64f75285ef8f606073e9a923",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "tsianaboa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3818211,
          lng: 46.5138553,
        },
        _id: "64f75285ef8f606073e9a924",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "ankonatra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4243188,
          lng: 46.52589,
        },
        _id: "64f75285ef8f606073e9a925",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "ampanofora",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4438417,
          lng: 46.5409374,
        },
        _id: "64f75285ef8f606073e9a926",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "ampasinabo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4346851,
          lng: 46.5571938,
        },
        _id: "64f75285ef8f606073e9a927",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4831079,
          lng: 46.574058,
        },
        _id: "64f75285ef8f606073e9a928",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "amboanio",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4765776,
          lng: 46.5307047,
        },
        _id: "64f75285ef8f606073e9a929",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "amboromaika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4766425,
          lng: 46.5174654,
        },
        _id: "64f75285ef8f606073e9a92a",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "antafia bemarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4640349,
          lng: 46.5090422,
        },
        _id: "64f75285ef8f606073e9a92b",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "antanambao tsararivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4759199,
          lng: 46.4922002,
        },
        _id: "64f75285ef8f606073e9a92c",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "madirohambana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4501729,
          lng: 46.4104775,
        },
        _id: "64f75285ef8f606073e9a92d",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "mailaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4941997,
          lng: 46.34807499999999,
        },
        _id: "64f75285ef8f606073e9a92e",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "andoharano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5007803,
          lng: 46.3912683,
        },
        _id: "64f75285ef8f606073e9a92f",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "manaribevitsika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5428349,
          lng: 46.4104775,
        },
        _id: "64f75285ef8f606073e9a930",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "beseva",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5306723,
          lng: 46.5066359,
        },
        _id: "64f75285ef8f606073e9a931",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "ankeliroy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.566667,
          lng: 46.566667,
        },
        _id: "64f75285ef8f606073e9a932",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "ambahavaha",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6,
          lng: 46.51666700000001,
        },
        _id: "64f75285ef8f606073e9a933",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "antsohihy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6405761,
          lng: 46.4296942,
        },
        _id: "64f75285ef8f606073e9a934",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "bemarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6087334,
          lng: 46.3432781,
        },
        _id: "64f75285ef8f606073e9a935",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "madirovalo",
        fokontany: "anjiakely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4483345,
          lng: 46.9659376,
        },
        _id: "64f75285ef8f606073e9a936",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "anjiajia",
        fokontany: "anjiajia centre",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3713056,
          lng: 46.9562248,
        },
        _id: "64f75285ef8f606073e9a937",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "anjiajia",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4061257,
          lng: 46.9465138,
        },
        _id: "64f75285ef8f606073e9a938",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "anjiajia",
        fokontany: "ambalakazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4311743,
          lng: 46.9902277,
        },
        _id: "64f75285ef8f606073e9a939",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "anjiajia",
        fokontany: "mangatelo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4272264,
          lng: 46.9562248,
        },
        _id: "64f75285ef8f606073e9a93a",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "anjiajia",
        fokontany: "madiroabo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.449727,
          lng: 46.89798690000001,
        },
        _id: "64f75285ef8f606073e9a93b",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "anjiajia",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -16.438483,
          lng: 46.9270975,
        },
        _id: "64f75285ef8f606073e9a93c",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "anjiajia",
        fokontany: "tsilakanina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4591072,
          lng: 46.9440864,
        },
        _id: "64f75285ef8f606073e9a93d",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "anjiajia",
        fokontany: "anjiajia lagara",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4860457,
          lng: 46.91254010000001,
        },
        _id: "64f75285ef8f606073e9a93e",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "anjiajia",
        fokontany: "betanatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4807022,
          lng: 46.9465138,
        },
        _id: "64f75285ef8f606073e9a93f",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "anjiajia",
        fokontany: "antsoherinakanga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5450392,
          lng: 46.9610809,
        },
        _id: "64f75285ef8f606073e9a940",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "anjiajia",
        fokontany: "ampasitapaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3671148,
          lng: 47.0412749,
        },
        _id: "64f75285ef8f606073e9a941",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "tsaramandroso",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2926536,
          lng: 47.1069821,
        },
        _id: "64f75285ef8f606073e9a942",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "tsaramandroso",
        fokontany: "andranomiditra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3325254,
          lng: 47.0242535,
        },
        _id: "64f75285ef8f606073e9a943",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "tsaramandroso",
        fokontany: "ambatoloaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3330512,
          lng: 46.9707947,
        },
        _id: "64f75285ef8f606073e9a944",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "tsaramandroso",
        fokontany: "andranomandevy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3673093,
          lng: 47.1069821,
        },
        _id: "64f75285ef8f606073e9a945",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "tsaramandroso",
        fokontany: "maevarano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4017055,
          lng: 47.104547,
        },
        _id: "64f75285ef8f606073e9a946",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "tsaramandroso",
        fokontany: "amborondolo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4508499,
          lng: 47.0680343,
        },
        _id: "64f75285ef8f606073e9a947",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "tsaramandroso",
        fokontany: "antanimalandy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3799826,
          lng: 47.1947232,
        },
        _id: "64f75285ef8f606073e9a948",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambondromamy",
        fokontany: "ambondromamy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.309349,
          lng: 47.188751,
        },
        _id: "64f75285ef8f606073e9a949",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambondromamy",
        fokontany: "beronono",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3210704,
          lng: 47.1459597,
        },
        _id: "64f75285ef8f606073e9a94a",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambondromamy",
        fokontany: "betaramahamay",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3316365,
          lng: 47.2630701,
        },
        _id: "64f75285ef8f606073e9a94b",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambondromamy",
        fokontany: "ambaliha",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3779691,
          lng: 47.2240036,
        },
        _id: "64f75285ef8f606073e9a94c",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambondromamy",
        fokontany: "ampombikely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3957761,
          lng: 47.1459597,
        },
        _id: "64f75285ef8f606073e9a94d",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambondromamy",
        fokontany: "mangarivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4582109,
          lng: 47.1898448,
        },
        _id: "64f75285ef8f606073e9a94e",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambondromamy",
        fokontany: "andavadrere",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4631413,
          lng: 47.16302160000001,
        },
        _id: "64f75285ef8f606073e9a94f",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ambondromamy",
        fokontany: "andasite kamoro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.774851,
          lng: 46.44273,
        },
        _id: "64f75285ef8f606073e9a950",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ankirihitra",
        fokontany: "ankirihitra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6063447,
          lng: 46.5836972,
        },
        _id: "64f75285ef8f606073e9a951",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ankirihitra",
        fokontany: "bemadiro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7940581,
          lng: 46.5547852,
        },
        _id: "64f75285ef8f606073e9a952",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ankirihitra",
        fokontany: "ambatomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7671803,
          lng: 46.38166649999999,
        },
        _id: "64f75285ef8f606073e9a953",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ankirihitra",
        fokontany: "mariarano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.83279,
          lng: 46.22356,
        },
        _id: "64f75285ef8f606073e9a954",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ankirihitra",
        fokontany: "antorilava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8684281,
          lng: 46.3432781,
        },
        _id: "64f75285ef8f606073e9a955",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ankirihitra",
        fokontany: "andranomamy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0510068,
          lng: 46.400872,
        },
        _id: "64f75285ef8f606073e9a956",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "ankirihitra",
        fokontany: "kamakama",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5349494,
          lng: 47.1142882,
        },
        _id: "64f75285ef8f606073e9a957",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "andranomamy",
        fokontany: "andranomamy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4980555,
          lng: 47.1069821,
        },
        _id: "64f75285ef8f606073e9a958",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "andranomamy",
        fokontany: "tsinjorano i",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4882335,
          lng: 47.1605839,
        },
        _id: "64f75285ef8f606073e9a959",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "andranomamy",
        fokontany: "ambohimanatrika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4783416,
          lng: 47.0291162,
        },
        _id: "64f75285ef8f606073e9a95a",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "andranomamy",
        fokontany: "maizimazava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5343542,
          lng: 47.0291162,
        },
        _id: "64f75285ef8f606073e9a95b",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "andranomamy",
        fokontany: "antsohikely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5431294,
          lng: 47.2020418,
        },
        _id: "64f75285ef8f606073e9a95c",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "andranomamy",
        fokontany: "ankaraobato",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5689556,
          lng: 47.1654595,
        },
        _id: "64f75285ef8f606073e9a95d",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "andranomamy",
        fokontany: "andranovaky",
        __v: 0,
      },
      {
        coords: {
          lat: -16.555093,
          lng: 47.0923732,
        },
        _id: "64f75285ef8f606073e9a95e",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "andranomamy",
        fokontany: "antsakoamilaika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6242435,
          lng: 47.2728413,
        },
        _id: "64f75285ef8f606073e9a95f",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "andranomamy",
        fokontany: "ambohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -16.608669,
          lng: 47.19117,
        },
        _id: "64f75285ef8f606073e9a960",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "andranomamy",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2970471,
          lng: 47.3119448,
        },
        _id: "64f75285ef8f606073e9a961",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "manerinerina",
        fokontany: "manerinerina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.216667,
          lng: 47.266667,
        },
        _id: "64f75285ef8f606073e9a962",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "manerinerina",
        fokontany: "ambinanibe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2471585,
          lng: 47.2240036,
        },
        _id: "64f75285ef8f606073e9a963",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "manerinerina",
        fokontany: "andranotomendry",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2512643,
          lng: 47.1654595,
        },
        _id: "64f75285ef8f606073e9a964",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "manerinerina",
        fokontany: "bevazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2737724,
          lng: 47.1996022,
        },
        _id: "64f75285ef8f606073e9a965",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "manerinerina",
        fokontany: "ampombilava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3029384,
          lng: 47.3168348,
        },
        _id: "64f75285ef8f606073e9a966",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "manerinerina",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3167805,
          lng: 47.2972775,
        },
        _id: "64f75285ef8f606073e9a967",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "manerinerina",
        fokontany: "tsinjorano II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.35,
          lng: 47.4166669,
        },
        _id: "64f75285ef8f606073e9a968",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "manerinerina",
        fokontany: "ambahary",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3372727,
          lng: 47.3608657,
        },
        _id: "64f75285ef8f606073e9a969",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "manerinerina",
        fokontany: "ampasitapaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3893464,
          lng: 47.4196318,
        },
        _id: "64f75285ef8f606073e9a96a",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "manerinerina",
        fokontany: "andranolava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.67787,
          lng: 46.109699,
        },
        _id: "64f75285ef8f606073e9a96b",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "sitampiky",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5922563,
          lng: 45.9991326,
        },
        _id: "64f75285ef8f606073e9a96c",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "mahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6056766,
          lng: 46.0849408,
        },
        _id: "64f75285ef8f606073e9a96d",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "mangatsiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5955556,
          lng: 46.1517858,
        },
        _id: "64f75285ef8f606073e9a96e",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "ampamofo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5853877,
          lng: 46.2187227,
        },
        _id: "64f75285ef8f606073e9a96f",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "mandanivatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6728142,
          lng: 46.3049196,
        },
        _id: "64f75285ef8f606073e9a970",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "besely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7166367,
          lng: 46.1900242,
        },
        _id: "64f75285ef8f606073e9a971",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "antafia",
        __v: 0,
      },
      {
        coords: {
          lat: -16.656063,
          lng: 46.0658592,
        },
        _id: "64f75285ef8f606073e9a972",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "antsoherikely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.666667,
          lng: 45.95,
        },
        _id: "64f75285ef8f606073e9a973",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "marobongo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6825009,
          lng: 45.834438,
        },
        _id: "64f75285ef8f606073e9a974",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6858759,
          lng: 45.8137377,
        },
        _id: "64f75285ef8f606073e9a975",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "ambalavary",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6857324,
          lng: 45.76157629999999,
        },
        _id: "64f75285ef8f606073e9a976",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "manongarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7460717,
          lng: 45.7805375,
        },
        _id: "64f75285ef8f606073e9a977",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "bejabora",
        __v: 0,
      },
      {
        coords: {
          lat: -16.784941,
          lng: 45.889511,
        },
        _id: "64f75285ef8f606073e9a978",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "antsoha",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7415814,
          lng: 45.97056380000001,
        },
        _id: "64f75285ef8f606073e9a979",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "ambalasatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.721951,
          lng: 46.0263599,
        },
        _id: "64f75285ef8f606073e9a97a",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "sarodrano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9287328,
          lng: 46.1517858,
        },
        _id: "64f75285ef8f606073e9a97b",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "majeramanga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.802084,
          lng: 45.98960779999999,
        },
        _id: "64f75285ef8f606073e9a97c",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "ambararata",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9074029,
          lng: 45.8659561,
        },
        _id: "64f75285ef8f606073e9a97d",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "nosi be",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9314939,
          lng: 45.98960779999999,
        },
        _id: "64f75285ef8f606073e9a97e",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "tondraka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.854352,
          lng: 46.0467851,
        },
        _id: "64f75285ef8f606073e9a97f",
        province: "mahajanga",
        region: "boeny",
        district: "ambato boeni",
        commune: "sitampiky",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1205327,
          lng: 46.6343336,
        },
        _id: "64f75285ef8f606073e9a980",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay ville",
        fokontany: "tsimahajao",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0735689,
          lng: 46.7333436,
        },
        _id: "64f75285ef8f606073e9a981",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay ville",
        fokontany: "mandrosoa 12km",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1082383,
          lng: 46.7236754,
        },
        _id: "64f75285ef8f606073e9a982",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay ville",
        fokontany: "bevovoka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1126575,
          lng: 46.7043448,
        },
        _id: "64f75285ef8f606073e9a983",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay ville",
        fokontany: "amparihilava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0985328,
          lng: 46.6850215,
        },
        _id: "64f75285ef8f606073e9a984",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay ville",
        fokontany: "antanimora",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1122143,
          lng: 46.6657058,
        },
        _id: "64f75285ef8f606073e9a985",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay ville",
        fokontany: "antsatramira",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1056472,
          lng: 46.6488106,
        },
        _id: "64f75285ef8f606073e9a986",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay ville",
        fokontany: "soaniadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1109544,
          lng: 46.6445877,
        },
        _id: "64f75285ef8f606073e9a987",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay ville",
        fokontany: "itandrava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0917497,
          lng: 46.6488106,
        },
        _id: "64f75285ef8f606073e9a988",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay ville",
        fokontany: "ambovomavo diego kely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1029712,
          lng: 46.642778,
        },
        _id: "64f75285ef8f606073e9a989",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay ville",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1283199,
          lng: 46.65605069999999,
        },
        _id: "64f75285ef8f606073e9a98a",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay ville",
        fokontany: "fihaonana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0932357,
          lng: 46.6270967,
        },
        _id: "64f75285ef8f606073e9a98b",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay ville",
        fokontany: "marovoay andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1273655,
          lng: 46.62468459999999,
        },
        _id: "64f75285ef8f606073e9a98c",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay ville",
        fokontany: "ankingabe ambalamanga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.99636,
          lng: 46.618969,
        },
        _id: "64f75285ef8f606073e9a98d",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay banlieue",
        fokontany: "miadana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9449436,
          lng: 46.4970116,
        },
        _id: "64f75285ef8f606073e9a98e",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay banlieue",
        fokontany: "andakalaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9808427,
          lng: 46.5905253,
        },
        _id: "64f75285ef8f606073e9a98f",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay banlieue",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9704718,
          lng: 46.61744909999999,
        },
        _id: "64f75285ef8f606073e9a990",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay banlieue",
        fokontany: "ambovokatrakatraka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0080675,
          lng: 46.5644206,
        },
        _id: "64f75285ef8f606073e9a991",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay banlieue",
        fokontany: "ambanjabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0370891,
          lng: 46.6801919,
        },
        _id: "64f75285ef8f606073e9a992",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay banlieue",
        fokontany: "lakovola",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0420898,
          lng: 46.6078033,
        },
        _id: "64f75285ef8f606073e9a993",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay banlieue",
        fokontany: "maromiandra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0791207,
          lng: 46.6078033,
        },
        _id: "64f75285ef8f606073e9a994",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay banlieue",
        fokontany: "anosikobondro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0844137,
          lng: 46.6657058,
        },
        _id: "64f75285ef8f606073e9a995",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay banlieue",
        fokontany: "marosakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0710142,
          lng: 46.6584643,
        },
        _id: "64f75285ef8f606073e9a996",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay banlieue",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.141989,
          lng: 46.6367462,
        },
        _id: "64f75285ef8f606073e9a997",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marovoay banlieue",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -16,
          lng: 46.683333,
        },
        _id: "64f75285ef8f606073e9a998",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "antanambao andranolava",
        fokontany: "antanambao andranolava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9,
          lng: 46.583333,
        },
        _id: "64f75285ef8f606073e9a999",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "antanambao andranolava",
        fokontany: "berivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9307753,
          lng: 46.6995133,
        },
        _id: "64f75285ef8f606073e9a99a",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "antanambao andranolava",
        fokontany: "analanabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9533645,
          lng: 46.64157160000001,
        },
        _id: "64f75285ef8f606073e9a99b",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "antanambao andranolava",
        fokontany: "mangapaika",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9716108,
          lng: 46.7333436,
        },
        _id: "64f75285ef8f606073e9a99c",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "antanambao andranolava",
        fokontany: "marobanty",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0018209,
          lng: 46.7430136,
        },
        _id: "64f75285ef8f606073e9a99d",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "antanambao andranolava",
        fokontany: "antsakoafaly",
        __v: 0,
      },
      {
        coords: {
          lat: -16.001048,
          lng: 46.6657058,
        },
        _id: "64f75285ef8f606073e9a99e",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "antanambao andranolava",
        fokontany: "andriambato",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0062645,
          lng: 46.7236754,
        },
        _id: "64f75285ef8f606073e9a99f",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "antanambao andranolava",
        fokontany: "betaolo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2234196,
          lng: 46.574058,
        },
        _id: "64f75285ef8f606073e9a9a0",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "anosinalainolona",
        fokontany: "anosinalainolona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.166667,
          lng: 46.616667,
        },
        _id: "64f75285ef8f606073e9a9a1",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "anosinalainolona",
        fokontany: "ambohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1600411,
          lng: 46.5981595,
        },
        _id: "64f75285ef8f606073e9a9a2",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "anosinalainolona",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -16.183937,
          lng: 46.6102146,
        },
        _id: "64f75285ef8f606073e9a9a3",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "anosinalainolona",
        fokontany: "anosimandrava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1853937,
          lng: 46.58851749999999,
        },
        _id: "64f75285ef8f606073e9a9a4",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "anosinalainolona",
        fokontany: "anjobajoba",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1897727,
          lng: 46.5692391,
        },
        _id: "64f75285ef8f606073e9a9a5",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "anosinalainolona",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2112561,
          lng: 46.61744909999999,
        },
        _id: "64f75285ef8f606073e9a9a6",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "anosinalainolona",
        fokontany: "madiromibata",
        __v: 0,
      },
      {
        coords: {
          lat: -16.235134,
          lng: 46.5836972,
        },
        _id: "64f75285ef8f606073e9a9a7",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "anosinalainolona",
        fokontany: "marolambo andohanosy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.272209,
          lng: 46.5836972,
        },
        _id: "64f75285ef8f606073e9a9a8",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "anosinalainolona",
        fokontany: "ambatobevomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3244,
          lng: 46.558769,
        },
        _id: "64f75285ef8f606073e9a9a9",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "anosinalainolona",
        fokontany: "ambalabongo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1653267,
          lng: 46.7018007,
        },
        _id: "64f75285ef8f606073e9a9aa",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ambolomoty",
        fokontany: "ambolomoty",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1605051,
          lng: 46.6826067,
        },
        _id: "64f75285ef8f606073e9a9ab",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ambolomoty",
        fokontany: "amboronazy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1297138,
          lng: 46.7260923,
        },
        _id: "64f75285ef8f606073e9a9ac",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ambolomoty",
        fokontany: "ampijoroa avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.149291,
          lng: 46.6657058,
        },
        _id: "64f75285ef8f606073e9a9ad",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ambolomoty",
        fokontany: "anosifisaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1583161,
          lng: 46.66932689999999,
        },
        _id: "64f75285ef8f606073e9a9ae",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ambolomoty",
        fokontany: "anosikapika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1361999,
          lng: 46.7103848,
        },
        _id: "64f75285ef8f606073e9a9af",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ambolomoty",
        fokontany: "anosikely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1751426,
          lng: 46.6946822,
        },
        _id: "64f75285ef8f606073e9a9b0",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ambolomoty",
        fokontany: "andranondaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1436283,
          lng: 46.7260923,
        },
        _id: "64f75285ef8f606073e9a9b1",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ambolomoty",
        fokontany: "antsingy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2010114,
          lng: 46.7236754,
        },
        _id: "64f75285ef8f606073e9a9b2",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ambolomoty",
        fokontany: "tsiandrarafa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1458351,
          lng: 46.7164256,
        },
        _id: "64f75285ef8f606073e9a9b3",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ambolomoty",
        fokontany: "antanisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1824516,
          lng: 46.7236754,
        },
        _id: "64f75285ef8f606073e9a9b4",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ambolomoty",
        fokontany: "maintimaso",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1380228,
          lng: 46.71763379999999,
        },
        _id: "64f75285ef8f606073e9a9b5",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ambolomoty",
        fokontany: "ambalavondro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1526784,
          lng: 46.7067607,
        },
        _id: "64f75285ef8f606073e9a9b6",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ambolomoty",
        fokontany: "antanamenjika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1796217,
          lng: 46.7538945,
        },
        _id: "64f75285ef8f606073e9a9b7",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marosakoa",
        fokontany: "marosakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1796217,
          lng: 46.7538945,
        },
        _id: "64f75285ef8f606073e9a9b8",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marosakoa",
        fokontany: "manonga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1796217,
          lng: 46.7538945,
        },
        _id: "64f75285ef8f606073e9a9b9",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marosakoa",
        fokontany: "ambatomasaja",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1796217,
          lng: 46.7538945,
        },
        _id: "64f75285ef8f606073e9a9ba",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marosakoa",
        fokontany: "antanamivony",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1796217,
          lng: 46.7538945,
        },
        _id: "64f75285ef8f606073e9a9bb",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marosakoa",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1796217,
          lng: 46.7538945,
        },
        _id: "64f75285ef8f606073e9a9bc",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marosakoa",
        fokontany: "manaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1796217,
          lng: 46.7538945,
        },
        _id: "64f75285ef8f606073e9a9bd",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marosakoa",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1796217,
          lng: 46.7538945,
        },
        _id: "64f75285ef8f606073e9a9be",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marosakoa",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1796217,
          lng: 46.7538945,
        },
        _id: "64f75285ef8f606073e9a9bf",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marosakoa",
        fokontany: "befotoana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1796217,
          lng: 46.7538945,
        },
        _id: "64f75285ef8f606073e9a9c0",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marosakoa",
        fokontany: "tanambao ampasikabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1796217,
          lng: 46.7538945,
        },
        _id: "64f75285ef8f606073e9a9c1",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marosakoa",
        fokontany: "ambarindahy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1796217,
          lng: 46.7538945,
        },
        _id: "64f75285ef8f606073e9a9c2",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marosakoa",
        fokontany: "ambikakely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1796217,
          lng: 46.7538945,
        },
        _id: "64f75285ef8f606073e9a9c3",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marosakoa",
        fokontany: "ampombolava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1796217,
          lng: 46.7538945,
        },
        _id: "64f75285ef8f606073e9a9c4",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marosakoa",
        fokontany: "ampijoroan'ala",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1796217,
          lng: 46.7538945,
        },
        _id: "64f75285ef8f606073e9a9c5",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "marosakoa",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1780793,
          lng: 46.6741555,
        },
        _id: "64f75285ef8f606073e9a9c6",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "tsararano",
        fokontany: "tsararano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1624702,
          lng: 46.6536372,
        },
        _id: "64f75285ef8f606073e9a9c7",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "tsararano",
        fokontany: "bepako",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1775951,
          lng: 46.6584643,
        },
        _id: "64f75285ef8f606073e9a9c8",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "tsararano",
        fokontany: "madirokely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1917475,
          lng: 46.654844,
        },
        _id: "64f75285ef8f606073e9a9c9",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "tsararano",
        fokontany: "ambanjabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1946692,
          lng: 46.7031368,
        },
        _id: "64f75285ef8f606073e9a9ca",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "tsararano",
        fokontany: "bevary",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2024844,
          lng: 46.65605069999999,
        },
        _id: "64f75285ef8f606073e9a9cb",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "tsararano",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2098087,
          lng: 46.6850215,
        },
        _id: "64f75285ef8f606073e9a9cc",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "tsararano",
        fokontany: "bekalila",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2429969,
          lng: 46.6512239,
        },
        _id: "64f75285ef8f606073e9a9cd",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "tsararano",
        fokontany: "betaramahamay",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2264137,
          lng: 46.71400920000001,
        },
        _id: "64f75285ef8f606073e9a9ce",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "tsararano",
        fokontany: "tsiasesy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2879813,
          lng: 46.7188421,
        },
        _id: "64f75285ef8f606073e9a9cf",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "tsararano",
        fokontany: "andranomangatsiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1010987,
          lng: 46.6813993,
        },
        _id: "64f75285ef8f606073e9a9d0",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankazomborona",
        fokontany: "ankazomborona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9061706,
          lng: 47.1264672,
        },
        _id: "64f75285ef8f606073e9a9d1",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankazomborona",
        fokontany: "malaza",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9587867,
          lng: 46.9999471,
        },
        _id: "64f75285ef8f606073e9a9d2",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankazomborona",
        fokontany: "tanambao bemikimbo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9671514,
          lng: 46.8834379,
        },
        _id: "64f75285ef8f606073e9a9d3",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankazomborona",
        fokontany: "ankazomahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1876467,
          lng: 47.0972424,
        },
        _id: "64f75285ef8f606073e9a9d4",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankazomborona",
        fokontany: "belinta",
        __v: 0,
      },
      {
        coords: {
          lat: -15.98637,
          lng: 47.151058,
        },
        _id: "64f75285ef8f606073e9a9d5",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankazomborona",
        fokontany: "beronono",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0716519,
          lng: 47.1557087,
        },
        _id: "64f75285ef8f606073e9a9d6",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankazomborona",
        fokontany: "marofototra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1330706,
          lng: 46.9902277,
        },
        _id: "64f75285ef8f606073e9a9d7",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankazomborona",
        fokontany: "ampombobe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0902859,
          lng: 46.8931368,
        },
        _id: "64f75285ef8f606073e9a9d8",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankazomborona",
        fokontany: "ambolodia",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0757791,
          lng: 46.83497149999999,
        },
        _id: "64f75285ef8f606073e9a9d9",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankazomborona",
        fokontany: "morafeno barazy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9967124,
          lng: 46.815598,
        },
        _id: "64f75285ef8f606073e9a9da",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankazomborona",
        fokontany: "betaramahamay",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0320444,
          lng: 46.7526854,
        },
        _id: "64f75285ef8f606073e9a9db",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankazomborona",
        fokontany: "besaonjo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0501874,
          lng: 46.71400920000001,
        },
        _id: "64f75285ef8f606073e9a9dc",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankazomborona",
        fokontany: "mahabibo rn4",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0612711,
          lng: 46.7768732,
        },
        _id: "64f75285ef8f606073e9a9dd",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankazomborona",
        fokontany: "ambondromamy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0929386,
          lng: 46.8107557,
        },
        _id: "64f75285ef8f606073e9a9de",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankazomborona",
        fokontany: "maevarano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1,
          lng: 46.816667,
        },
        _id: "64f75285ef8f606073e9a9df",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankazomborona",
        fokontany: "ambonara",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1460469,
          lng: 46.8931368,
        },
        _id: "64f75285ef8f606073e9a9e0",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankazomborona",
        fokontany: "mahatazana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0876869,
          lng: 46.7526854,
        },
        _id: "64f75285ef8f606073e9a9e1",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankazomborona",
        fokontany: "madiromiongana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1110897,
          lng: 46.7720347,
        },
        _id: "64f75285ef8f606073e9a9e2",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankazomborona",
        fokontany: "madirovalo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1320776,
          lng: 46.7817122,
        },
        _id: "64f75285ef8f606073e9a9e3",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankazomborona",
        fokontany: "amboromalandikely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.18898,
          lng: 46.50803,
        },
        _id: "64f75285ef8f606073e9a9e4",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "manaratsandry",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0601603,
          lng: 46.5692391,
        },
        _id: "64f75285ef8f606073e9a9e5",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "ambinanitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.009171,
          lng: 46.4585333,
        },
        _id: "64f75285ef8f606073e9a9e6",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "ambondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.10523,
          lng: 46.582729,
        },
        _id: "64f75285ef8f606073e9a9e7",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "ankaboka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1015722,
          lng: 46.5499681,
        },
        _id: "64f75285ef8f606073e9a9e8",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "antsingimbato",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0723671,
          lng: 46.52589,
        },
        _id: "64f75285ef8f606073e9a9e9",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "antanimora",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1061777,
          lng: 46.5957488,
        },
        _id: "64f75285ef8f606073e9a9ea",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "antafia",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1390795,
          lng: 46.58851749999999,
        },
        _id: "64f75285ef8f606073e9a9eb",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "ambarimamy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1283473,
          lng: 46.4729592,
        },
        _id: "64f75285ef8f606073e9a9ec",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "amboanjo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.15,
          lng: 46.55,
        },
        _id: "64f75285ef8f606073e9a9ed",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "anorombato",
        __v: 0,
      },
      {
        coords: {
          lat: -16.153651,
          lng: 46.4633415,
        },
        _id: "64f75285ef8f606073e9a9ee",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "trangabitika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1497917,
          lng: 46.5210757,
        },
        _id: "64f75285ef8f606073e9a9ef",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "ambararatafaly",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1677858,
          lng: 46.4825787,
        },
        _id: "64f75285ef8f606073e9a9f0",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "adabomirafy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1811841,
          lng: 46.4897946,
        },
        _id: "64f75285ef8f606073e9a9f1",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "angaramahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1751149,
          lng: 46.5114487,
        },
        _id: "64f75285ef8f606073e9a9f2",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.18898,
          lng: 46.50803,
        },
        _id: "64f75285ef8f606073e9a9f3",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1810749,
          lng: 46.5030265,
        },
        _id: "64f75285ef8f606073e9a9f4",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "ambodijambarao",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1908094,
          lng: 46.4958087,
        },
        _id: "64f75285ef8f606073e9a9f5",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "soamandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1996917,
          lng: 46.4897946,
        },
        _id: "64f75285ef8f606073e9a9f6",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "beanamamy nord",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1974525,
          lng: 46.4537256,
        },
        _id: "64f75285ef8f606073e9a9f7",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "anosy tapaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2152037,
          lng: 46.4417085,
        },
        _id: "64f75285ef8f606073e9a9f8",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "manaratsandry",
        fokontany: "antananabo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2377089,
          lng: 46.5093968,
        },
        _id: "64f75285ef8f606073e9a9f9",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "antanimasaka",
        fokontany: "antanimasaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2338558,
          lng: 46.5331122,
        },
        _id: "64f75285ef8f606073e9a9fa",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "antanimasaka",
        fokontany: "maroala",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2472723,
          lng: 46.5403355,
        },
        _id: "64f75285ef8f606073e9a9fb",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "antanimasaka",
        fokontany: "marosakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2304855,
          lng: 46.4789712,
        },
        _id: "64f75285ef8f606073e9a9fc",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "antanimasaka",
        fokontany: "ampijoroa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2437122,
          lng: 46.4537256,
        },
        _id: "64f75285ef8f606073e9a9fd",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "antanimasaka",
        fokontany: "antsakoamanera",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2779065,
          lng: 46.4970116,
        },
        _id: "64f75285ef8f606073e9a9fe",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "antanimasaka",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2699576,
          lng: 46.5475598,
        },
        _id: "64f75285ef8f606073e9a9ff",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "antanimasaka",
        fokontany: "beanamamy sud",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2935978,
          lng: 46.5403355,
        },
        _id: "64f75285ef8f606073e9aa00",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "antanimasaka",
        fokontany: "anaborano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.14132,
          lng: 46.3240951,
        },
        _id: "64f75285ef8f606073e9aa01",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "bemaharivo",
        fokontany: "bemaharivo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0004905,
          lng: 46.3145064,
        },
        _id: "64f75285ef8f606073e9aa02",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "bemaharivo",
        fokontany: "angoroda",
        __v: 0,
      },
      {
        coords: {
          lat: -16.015049,
          lng: 46.3720666,
        },
        _id: "64f75285ef8f606073e9aa03",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "bemaharivo",
        fokontany: "kandrany",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0616924,
          lng: 46.4104775,
        },
        _id: "64f75285ef8f606073e9aa04",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "bemaharivo",
        fokontany: "bemoramba",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0986,
          lng: 46.286449,
        },
        _id: "64f75285ef8f606073e9aa05",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "bemaharivo",
        fokontany: "ambohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1044786,
          lng: 46.415281,
        },
        _id: "64f75285ef8f606073e9aa06",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "bemaharivo",
        fokontany: "ambanjabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0922937,
          lng: 46.3672674,
        },
        _id: "64f75285ef8f606073e9aa07",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "bemaharivo",
        fokontany: "andrafiamadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1224369,
          lng: 46.3768663,
        },
        _id: "64f75285ef8f606073e9aa08",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "bemaharivo",
        fokontany: "marojoby",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1278376,
          lng: 46.4344996,
        },
        _id: "64f75285ef8f606073e9aa09",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "bemaharivo",
        fokontany: "antanamifafy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1555702,
          lng: 46.4344996,
        },
        _id: "64f75285ef8f606073e9aa0a",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "bemaharivo",
        fokontany: "bemahogo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1808619,
          lng: 46.4248894,
        },
        _id: "64f75285ef8f606073e9aa0b",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "bemaharivo",
        fokontany: "ankerana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.210495,
          lng: 46.3960699,
        },
        _id: "64f75285ef8f606073e9aa0c",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "bemaharivo",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2016282,
          lng: 46.3432781,
        },
        _id: "64f75285ef8f606073e9aa0d",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "bemaharivo",
        fokontany: "androimitsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2650394,
          lng: 46.4104775,
        },
        _id: "64f75285ef8f606073e9aa0e",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "bemaharivo",
        fokontany: "antanambao betsimisaraka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.966667,
          lng: 46.8,
        },
        _id: "64f75285ef8f606073e9aa0f",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankaraobato",
        fokontany: "ankaraobato",
        __v: 0,
      },
      {
        coords: {
          lat: -16.05817,
          lng: 46.266201,
        },
        _id: "64f75285ef8f606073e9aa10",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankaraobato",
        fokontany: "mavozaza",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0684461,
          lng: 46.3097128,
        },
        _id: "64f75285ef8f606073e9aa11",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankaraobato",
        fokontany: "anjiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1353078,
          lng: 46.2282927,
        },
        _id: "64f75285ef8f606073e9aa12",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankaraobato",
        fokontany: "antambara",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2011074,
          lng: 46.1613426,
        },
        _id: "64f75285ef8f606073e9aa13",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankaraobato",
        fokontany: "tsianaloka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2293905,
          lng: 46.1995885,
        },
        _id: "64f75285ef8f606073e9aa14",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankaraobato",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2441594,
          lng: 46.25701369999999,
        },
        _id: "64f75285ef8f606073e9aa15",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankaraobato",
        fokontany: "beanamamy malao",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2982614,
          lng: 46.1804618,
        },
        _id: "64f75285ef8f606073e9aa16",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankaraobato",
        fokontany: "ambalavia",
        __v: 0,
      },
      {
        coords: {
          lat: -16.376613,
          lng: 46.3049196,
        },
        _id: "64f75285ef8f606073e9aa17",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankaraobato",
        fokontany: "bemahogo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.433333,
          lng: 46.183333,
        },
        _id: "64f75285ef8f606073e9aa18",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankaraobato",
        fokontany: "androtra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4008507,
          lng: 46.1135774,
        },
        _id: "64f75285ef8f606073e9aa19",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankaraobato",
        fokontany: "betsikiry",
        __v: 0,
      },
      {
        coords: {
          lat: -16.50992,
          lng: 46.152149,
        },
        _id: "64f75285ef8f606073e9aa1a",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankaraobato",
        fokontany: "bekoratsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5146437,
          lng: 46.2665911,
        },
        _id: "64f75285ef8f606073e9aa1b",
        province: "mahajanga",
        region: "boeny",
        district: "marovoay",
        commune: "ankaraobato",
        fokontany: "ambatolafia",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0140114,
          lng: 45.8540833,
        },
        _id: "64f75285ef8f606073e9aa1c",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "mitsinjo",
        fokontany: "mitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9771679,
          lng: 45.8802073,
        },
        _id: "64f75285ef8f606073e9aa1d",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "mitsinjo",
        fokontany: "analamamy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0820711,
          lng: 45.9134768,
        },
        _id: "64f75285ef8f606073e9aa1e",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "mitsinjo",
        fokontany: "bemahazaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0891221,
          lng: 45.99437,
        },
        _id: "64f75285ef8f606073e9aa1f",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "mitsinjo",
        fokontany: "antanamanakana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.118111,
          lng: 45.810661,
        },
        _id: "64f75285ef8f606073e9aa20",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "mitsinjo",
        fokontany: "anaborengy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0857111,
          lng: 45.8564576,
        },
        _id: "64f75285ef8f606073e9aa21",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "mitsinjo",
        fokontany: "marofandroboka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1027456,
          lng: 45.7805375,
        },
        _id: "64f75285ef8f606073e9aa22",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "mitsinjo",
        fokontany: "ratinavia",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1598587,
          lng: 45.6479676,
        },
        _id: "64f75285ef8f606073e9aa23",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "mitsinjo",
        fokontany: "antongomena betsina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1612966,
          lng: 45.7236765,
        },
        _id: "64f75285ef8f606073e9aa24",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "mitsinjo",
        fokontany: "betsina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2081294,
          lng: 45.6574246,
        },
        _id: "64f75285ef8f606073e9aa25",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "mitsinjo",
        fokontany: "andranomavokely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.22049,
          lng: 45.862431,
        },
        _id: "64f75285ef8f606073e9aa26",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "antseza",
        fokontany: "antseza",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1591345,
          lng: 45.8564576,
        },
        _id: "64f75285ef8f606073e9aa27",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "antseza",
        fokontany: "antongomenabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.15184,
          lng: 45.92292,
        },
        _id: "64f75285ef8f606073e9aa28",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "antseza",
        fokontany: "manisakomby",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1788467,
          lng: 45.9324982,
        },
        _id: "64f75285ef8f606073e9aa29",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "antseza",
        fokontany: "ambararatabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2276518,
          lng: 45.83746619999999,
        },
        _id: "64f75285ef8f606073e9aa2a",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "antseza",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -16.240841,
          lng: 45.92831,
        },
        _id: "64f75285ef8f606073e9aa2b",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "antseza",
        fokontany: "marosakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2979015,
          lng: 45.79002089999999,
        },
        _id: "64f75285ef8f606073e9aa2c",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "antseza",
        fokontany: "belakoko",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9577146,
          lng: 45.9206089,
        },
        _id: "64f75285ef8f606073e9aa2d",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "antongomena bevary",
        fokontany: "antongomena bevary",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8069284,
          lng: 45.9134768,
        },
        _id: "64f75285ef8f606073e9aa2e",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "antongomena bevary",
        fokontany: "kingany",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8638057,
          lng: 45.8849587,
        },
        _id: "64f75285ef8f606073e9aa2f",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "antongomena bevary",
        fokontany: "anjiamaloto",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8629312,
          lng: 45.98960779999999,
        },
        _id: "64f75285ef8f606073e9aa30",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "antongomena bevary",
        fokontany: "boeni aranta",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9169127,
          lng: 45.9134768,
        },
        _id: "64f75285ef8f606073e9aa31",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "antongomena bevary",
        fokontany: "boeni doany",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8996328,
          lng: 45.98960779999999,
        },
        _id: "64f75285ef8f606073e9aa32",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "antongomena bevary",
        fokontany: "bemokotra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9577146,
          lng: 45.9206089,
        },
        _id: "64f75285ef8f606073e9aa33",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "antongomena bevary",
        fokontany: "bevary",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9661368,
          lng: 45.9087226,
        },
        _id: "64f75285ef8f606073e9aa34",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "antongomena bevary",
        fokontany: "mangatsiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0269942,
          lng: 45.9134768,
        },
        _id: "64f75285ef8f606073e9aa35",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "antongomena bevary",
        fokontany: "ambato mahavavy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9848223,
          lng: 45.7142063,
        },
        _id: "64f75285ef8f606073e9aa36",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "matsakabanja",
        fokontany: "matsakabanja",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8086369,
          lng: 45.704738,
        },
        _id: "64f75285ef8f606073e9aa37",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "matsakabanja",
        fokontany: "ampitsopitsoka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9141184,
          lng: 45.8398398,
        },
        _id: "64f75285ef8f606073e9aa38",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "matsakabanja",
        fokontany: "namakia",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9226275,
          lng: 45.8730812,
        },
        _id: "64f75285ef8f606073e9aa39",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "matsakabanja",
        fokontany: "belalanda",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8616171,
          lng: 45.73314860000001,
        },
        _id: "64f75285ef8f606073e9aa3a",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "matsakabanja",
        fokontany: "anjiakely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.916667,
          lng: 45.65,
        },
        _id: "64f75285ef8f606073e9aa3b",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "matsakabanja",
        fokontany: "andramy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9280847,
          lng: 45.7426226,
        },
        _id: "64f75285ef8f606073e9aa3c",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "matsakabanja",
        fokontany: "ampiranga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.92893,
          lng: 45.7565,
        },
        _id: "64f75285ef8f606073e9aa3d",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "matsakabanja",
        fokontany: "benetsy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9438596,
          lng: 45.8754564,
        },
        _id: "64f75285ef8f606073e9aa3e",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "matsakabanja",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0178133,
          lng: 45.7710559,
        },
        _id: "64f75285ef8f606073e9aa3f",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "matsakabanja",
        fokontany: "mangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9606867,
          lng: 45.851709,
        },
        _id: "64f75285ef8f606073e9aa40",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "matsakabanja",
        fokontany: "marogidro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0250301,
          lng: 45.6574246,
        },
        _id: "64f75285ef8f606073e9aa41",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "matsakabanja",
        fokontany: "antsakoamanera",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7645654,
          lng: 46.24145439999999,
        },
        _id: "64f75285ef8f606073e9aa42",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "katsepy",
        fokontany: "katsepy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7410467,
          lng: 46.1613426,
        },
        _id: "64f75285ef8f606073e9aa43",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "katsepy",
        fokontany: "antrema",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8705995,
          lng: 46.2378645,
        },
        _id: "64f75285ef8f606073e9aa44",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "katsepy",
        fokontany: "sankoany",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9578105,
          lng: 46.2187227,
        },
        _id: "64f75285ef8f606073e9aa45",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "katsepy",
        fokontany: "ambondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8619806,
          lng: 46.0944845,
        },
        _id: "64f75285ef8f606073e9aa46",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "katsepy",
        fokontany: "analatelo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0660996,
          lng: 46.0658592,
        },
        _id: "64f75285ef8f606073e9aa47",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "katsepy",
        fokontany: "beantsiva",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1073,
          lng: 46.0332719,
        },
        _id: "64f75285ef8f606073e9aa48",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "katsepy",
        fokontany: "tsiandrarafa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.256559,
          lng: 46.12009,
        },
        _id: "64f75285ef8f606073e9aa49",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "bekipay",
        fokontany: "bekipay",
        __v: 0,
      },
      {
        coords: {
          lat: -16.136326,
          lng: 46.0706289,
        },
        _id: "64f75285ef8f606073e9aa4a",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "bekipay",
        fokontany: "ankoakala",
        __v: 0,
      },
      {
        coords: {
          lat: -16.154425,
          lng: 46.1231267,
        },
        _id: "64f75285ef8f606073e9aa4b",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "bekipay",
        fokontany: "antanimalandy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1433679,
          lng: 46.0086594,
        },
        _id: "64f75285ef8f606073e9aa4c",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "bekipay",
        fokontany: "bemaratoly",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2084155,
          lng: 46.0467851,
        },
        _id: "64f75285ef8f606073e9aa4d",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "bekipay",
        fokontany: "ankevo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2587171,
          lng: 46.0277184,
        },
        _id: "64f75285ef8f606073e9aa4e",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "bekipay",
        fokontany: "ampasitanatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3175039,
          lng: 45.97056380000001,
        },
        _id: "64f75285ef8f606073e9aa4f",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "bekipay",
        fokontany: "madirotoka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.35,
          lng: 46.016667,
        },
        _id: "64f75285ef8f606073e9aa50",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "bekipay",
        fokontany: "marorata",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5,
          lng: 46,
        },
        _id: "64f75285ef8f606073e9aa51",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "ambarimaninga",
        fokontany: "ambarimaninga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4347198,
          lng: 45.9610446,
        },
        _id: "64f75285ef8f606073e9aa52",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "ambarimaninga",
        fokontany: "anjiakely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.40292,
          lng: 45.841999,
        },
        _id: "64f75285ef8f606073e9aa53",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "ambarimaninga",
        fokontany: "ambinda",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5217739,
          lng: 46.0467851,
        },
        _id: "64f75285ef8f606073e9aa54",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "ambarimaninga",
        fokontany: "ampanihy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4984025,
          lng: 45.92298659999999,
        },
        _id: "64f75285ef8f606073e9aa55",
        province: "mahajanga",
        region: "boeny",
        district: "mitsinjo",
        commune: "ambarimaninga",
        fokontany: "befamonty",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6921494,
          lng: 46.3336857,
        },
        _id: "64f75285ef8f606073e9aa56",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "belobaka",
        fokontany: "belobaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6590806,
          lng: 46.3672674,
        },
        _id: "64f75285ef8f606073e9aa57",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "belobaka",
        fokontany: "ampitolova",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7412405,
          lng: 46.4200849,
        },
        _id: "64f75285ef8f606073e9aa58",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "belobaka",
        fokontany: "amparemahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6154555,
          lng: 46.3768663,
        },
        _id: "64f75285ef8f606073e9aa59",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "belobaka",
        fokontany: "ampahazony",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6375628,
          lng: 46.4104775,
        },
        _id: "64f75285ef8f606073e9aa5a",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "belobaka",
        fokontany: "ankazomenavony",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8341488,
          lng: 46.4970116,
        },
        _id: "64f75285ef8f606073e9aa5b",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "belobaka",
        fokontany: "besely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6702302,
          lng: 46.4681501,
        },
        _id: "64f75285ef8f606073e9aa5c",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "belobaka",
        fokontany: "antsaboaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5881329,
          lng: 46.415281,
        },
        _id: "64f75285ef8f606073e9aa5d",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "belobaka",
        fokontany: "antsanitia",
        __v: 0,
      },
      {
        coords: {
          lat: -15.576825,
          lng: 46.4441117,
        },
        _id: "64f75285ef8f606073e9aa5e",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "belobaka",
        fokontany: "ladigy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.83365,
          lng: 46.31567,
        },
        _id: "64f75285ef8f606073e9aa5f",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "boanamary",
        fokontany: "boanamary",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7722952,
          lng: 46.415281,
        },
        _id: "64f75285ef8f606073e9aa60",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "boanamary",
        fokontany: "maromiandra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7753463,
          lng: 46.3720666,
        },
        _id: "64f75285ef8f606073e9aa61",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "boanamary",
        fokontany: "ambatomalama",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8066996,
          lng: 46.3408798,
        },
        _id: "64f75285ef8f606073e9aa62",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "boanamary",
        fokontany: "befotaka ambalatany",
        __v: 0,
      },
      {
        coords: {
          lat: -15.814709,
          lng: 46.33608359999999,
        },
        _id: "64f75285ef8f606073e9aa63",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "boanamary",
        fokontany: "tsararivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8296153,
          lng: 46.3864672,
        },
        _id: "64f75285ef8f606073e9aa64",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "boanamary",
        fokontany: "amboanio",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8887409,
          lng: 46.4200849,
        },
        _id: "64f75285ef8f606073e9aa65",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "boanamary",
        fokontany: "mandrosoa morahariva",
        __v: 0,
      },
      {
        coords: {
          lat: -15.716667,
          lng: 46.533333,
        },
        _id: "64f75285ef8f606073e9aa66",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "ambalakida",
        fokontany: "ambalakida",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7,
          lng: 46.7,
        },
        _id: "64f75285ef8f606073e9aa67",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "ambalakida",
        fokontany: "mahamavo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7689947,
          lng: 46.7188421,
        },
        _id: "64f75285ef8f606073e9aa68",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "ambalakida",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7520405,
          lng: 46.612626,
        },
        _id: "64f75285ef8f606073e9aa69",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "ambalakida",
        fokontany: "ankoby",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6981729,
          lng: 46.5066359,
        },
        _id: "64f75285ef8f606073e9aa6a",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "ambalakida",
        fokontany: "antanamifafy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8163781,
          lng: 46.574058,
        },
        _id: "64f75285ef8f606073e9aa6b",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "ambalakida",
        fokontany: "ankivonjy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.835516,
          lng: 46.6512239,
        },
        _id: "64f75285ef8f606073e9aa6c",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "ambalakida",
        fokontany: "manarenja",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8920296,
          lng: 46.6367462,
        },
        _id: "64f75285ef8f606073e9aa6d",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "ambalakida",
        fokontany: "ambovondramanesy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5854629,
          lng: 46.5355198,
        },
        _id: "64f75285ef8f606073e9aa6e",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "betsako",
        fokontany: "betsako",
        __v: 0,
      },
      {
        coords: {
          lat: -15.631795,
          lng: 46.574058,
        },
        _id: "64f75285ef8f606073e9aa6f",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "betsako",
        fokontany: "antsiatsiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5597214,
          lng: 46.4681501,
        },
        _id: "64f75285ef8f606073e9aa70",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "betsako",
        fokontany: "ambalika",
        __v: 0,
      },
      {
        coords: {
          lat: -15.532332,
          lng: 46.5066359,
        },
        _id: "64f75285ef8f606073e9aa71",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "betsako",
        fokontany: "ankilahila",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5580401,
          lng: 46.574058,
        },
        _id: "64f75285ef8f606073e9aa72",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "betsako",
        fokontany: "miadana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.47908,
          lng: 46.695919,
        },
        _id: "64f75285ef8f606073e9aa73",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mariarano",
        fokontany: "mariarano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4667515,
          lng: 46.805914,
        },
        _id: "64f75285ef8f606073e9aa74",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mariarano",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5594291,
          lng: 46.8834379,
        },
        _id: "64f75285ef8f606073e9aa75",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mariarano",
        fokontany: "ambondrobe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.47375,
          lng: 46.929001,
        },
        _id: "64f75285ef8f606073e9aa76",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mariarano",
        fokontany: "ampasimaleotra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4115039,
          lng: 46.8834379,
        },
        _id: "64f75285ef8f606073e9aa77",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mariarano",
        fokontany: "mitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.365404,
          lng: 46.9999471,
        },
        _id: "64f75285ef8f606073e9aa78",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mariarano",
        fokontany: "mataibory",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3099262,
          lng: 46.9222445,
        },
        _id: "64f75285ef8f606073e9aa79",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mariarano",
        fokontany: "ambenja",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2914362,
          lng: 46.8446611,
        },
        _id: "64f75285ef8f606073e9aa7a",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mariarano",
        fokontany: "tsianikira",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4224838,
          lng: 46.660878,
        },
        _id: "64f75285ef8f606073e9aa7b",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mariarano",
        fokontany: "marosakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4911934,
          lng: 46.5644206,
        },
        _id: "64f75285ef8f606073e9aa7c",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mariarano",
        fokontany: "komany",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5957529,
          lng: 46.7285093,
        },
        _id: "64f75285ef8f606073e9aa7d",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mariarano",
        fokontany: "ambavan'i mariarano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8325083,
          lng: 46.8640458,
        },
        _id: "64f75285ef8f606073e9aa7e",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "bekobay",
        fokontany: "bekobay",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7943702,
          lng: 46.8785892,
        },
        _id: "64f75285ef8f606073e9aa7f",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "bekobay",
        fokontany: "ambovoatango",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8107777,
          lng: 46.9076886,
        },
        _id: "64f75285ef8f606073e9aa80",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "bekobay",
        fokontany: "anosikely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8367158,
          lng: 46.9756523,
        },
        _id: "64f75285ef8f606073e9aa81",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "bekobay",
        fokontany: "ampano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8664264,
          lng: 46.9076886,
        },
        _id: "64f75285ef8f606073e9aa82",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "bekobay",
        fokontany: "ambalavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.863245,
          lng: 46.9513691,
        },
        _id: "64f75285ef8f606073e9aa83",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "bekobay",
        fokontany: "antananabo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8642202,
          lng: 46.7671967,
        },
        _id: "64f75285ef8f606073e9aa84",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "bekobay",
        fokontany: "ampapamena",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8462754,
          lng: 46.8446611,
        },
        _id: "64f75285ef8f606073e9aa85",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "bekobay",
        fokontany: "tsilakanina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9008521,
          lng: 46.8591989,
        },
        _id: "64f75285ef8f606073e9aa86",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "bekobay",
        fokontany: "ambodiadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9129324,
          lng: 46.7768732,
        },
        _id: "64f75285ef8f606073e9aa87",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "bekobay",
        fokontany: "marofiatsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.64476,
          lng: 46.888851,
        },
        _id: "64f75285ef8f606073e9aa88",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "andranoboka",
        fokontany: "andranoboka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6152497,
          lng: 46.9610809,
        },
        _id: "64f75285ef8f606073e9aa89",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "andranoboka",
        fokontany: "antafiamirango",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6577838,
          lng: 46.8882871,
        },
        _id: "64f75285ef8f606073e9aa8a",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "andranoboka",
        fokontany: "labandy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6915657,
          lng: 46.9319509,
        },
        _id: "64f75285ef8f606073e9aa8b",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "andranoboka",
        fokontany: "ambodiala",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6959088,
          lng: 46.8737409,
        },
        _id: "64f75285ef8f606073e9aa8c",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "andranoboka",
        fokontany: "mangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7605125,
          lng: 46.83497149999999,
        },
        _id: "64f75285ef8f606073e9aa8d",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "andranoboka",
        fokontany: "anjobajoba",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7329559,
          lng: 46.8737409,
        },
        _id: "64f75285ef8f606073e9aa8e",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "andranoboka",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6805837,
          lng: 47.0777685,
        },
        _id: "64f75285ef8f606073e9aa8f",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mahajamba usine",
        fokontany: "mahajamba usine",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5321658,
          lng: 47.0777685,
        },
        _id: "64f75285ef8f606073e9aa90",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mahajamba usine",
        fokontany: "mahajambakely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6352975,
          lng: 47.0631679,
        },
        _id: "64f75285ef8f606073e9aa91",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mahajamba usine",
        fokontany: "ambodipamba",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6875087,
          lng: 47.0680343,
        },
        _id: "64f75285ef8f606073e9aa92",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mahajamba usine",
        fokontany: "fenoarivo besakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6818308,
          lng: 47.102112,
        },
        _id: "64f75285ef8f606073e9aa93",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mahajamba usine",
        fokontany: "amboaniokely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.732985,
          lng: 47.12159519999999,
        },
        _id: "64f75285ef8f606073e9aa94",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mahajamba usine",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7293633,
          lng: 47.08750449999999,
        },
        _id: "64f75285ef8f606073e9aa95",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mahajamba usine",
        fokontany: "antanimbaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7539313,
          lng: 47.1313396,
        },
        _id: "64f75285ef8f606073e9aa96",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mahajamba usine",
        fokontany: "antanambao andolobe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7723435,
          lng: 47.0923732,
        },
        _id: "64f75285ef8f606073e9aa97",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mahajamba usine",
        fokontany: "mangatoka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.769338,
          lng: 47.07046769999999,
        },
        _id: "64f75285ef8f606073e9aa98",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mahajamba usine",
        fokontany: "ambombolava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8386842,
          lng: 47.11672369999999,
        },
        _id: "64f75285ef8f606073e9aa99",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "mahajamba usine",
        fokontany: "tsarazazamandimby",
        __v: 0,
      },
      {
        coords: {
          lat: -15.78237,
          lng: 46.954239,
        },
        _id: "64f75285ef8f606073e9aa9a",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "ambalabe befanjava",
        fokontany: "ambalabe befanjava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7104656,
          lng: 47.0096682,
        },
        _id: "64f75285ef8f606073e9aa9b",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "ambalabe befanjava",
        fokontany: "antananabo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7381074,
          lng: 46.9707947,
        },
        _id: "64f75285ef8f606073e9aa9c",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "ambalabe befanjava",
        fokontany: "antafiakarana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7551538,
          lng: 46.9076886,
        },
        _id: "64f75285ef8f606073e9aa9d",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "ambalabe befanjava",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7736923,
          lng: 46.9076886,
        },
        _id: "64f75285ef8f606073e9aa9e",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "ambalabe befanjava",
        fokontany: "antanimalandy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7993647,
          lng: 46.9368047,
        },
        _id: "64f75285ef8f606073e9aa9f",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "ambalabe befanjava",
        fokontany: "ampananirabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.863743,
          lng: 47.0291162,
        },
        _id: "64f75285ef8f606073e9aaa0",
        province: "mahajanga",
        region: "boeny",
        district: "mahajanga II",
        commune: "ambalabe befanjava",
        fokontany: "analanamitambatra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5794602,
          lng: 47.6184576,
        },
        _id: "64f75285ef8f606073e9aaa1",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge",
        fokontany: "ankorovaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5607948,
          lng: 47.6184576,
        },
        _id: "64f75285ef8f606073e9aaa2",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge",
        fokontany: "amboangisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5634113,
          lng: 47.6246062,
        },
        _id: "64f75285ef8f606073e9aaa3",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge",
        fokontany: "mahasalama",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5657572,
          lng: 47.6147688,
        },
        _id: "64f75285ef8f606073e9aaa4",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge",
        fokontany: "tsarasaotra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5670594,
          lng: 47.6227615,
        },
        _id: "64f75285ef8f606073e9aaa5",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge",
        fokontany: "tsararivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5674847,
          lng: 47.63198550000001,
        },
        _id: "64f75285ef8f606073e9aaa6",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge",
        fokontany: "andohomby",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5800047,
          lng: 47.6504382,
        },
        _id: "64f75285ef8f606073e9aaa7",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge",
        fokontany: "ampakobe II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.58645,
          lng: 47.6282957,
        },
        _id: "64f75285ef8f606073e9aaa8",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge",
        fokontany: "ambohimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5692626,
          lng: 47.6098507,
        },
        _id: "64f75285ef8f606073e9aaa9",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge",
        fokontany: "manarimaevaloka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.64412,
          lng: 47.703911,
        },
        _id: "64f75285ef8f606073e9aaaa",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambanjabe",
        fokontany: "ambanjabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5004132,
          lng: 47.7489621,
        },
        _id: "64f75285ef8f606073e9aaab",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambanjabe",
        fokontany: "analasarotra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5191982,
          lng: 47.7095305,
        },
        _id: "64f75285ef8f606073e9aaac",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambanjabe",
        fokontany: "ambodimanga I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5682881,
          lng: 47.6799761,
        },
        _id: "64f75285ef8f606073e9aaad",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambanjabe",
        fokontany: "antanatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5793519,
          lng: 47.6972141,
        },
        _id: "64f75285ef8f606073e9aaae",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambanjabe",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6091601,
          lng: 47.675052,
        },
        _id: "64f75285ef8f606073e9aaaf",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambanjabe",
        fokontany: "ambalavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6313533,
          lng: 47.67012829999999,
        },
        _id: "64f75285ef8f606073e9aab0",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambanjabe",
        fokontany: "matsaborilemby",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6500407,
          lng: 47.67012829999999,
        },
        _id: "64f75285ef8f606073e9aab1",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambanjabe",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6850892,
          lng: 47.6602824,
        },
        _id: "64f75285ef8f606073e9aab2",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambanjabe",
        fokontany: "ampasimanera",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7306482,
          lng: 47.6356755,
        },
        _id: "64f75285ef8f606073e9aab3",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambanjabe",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6487173,
          lng: 47.8498813,
        },
        _id: "64f75285ef8f606073e9aab4",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsaratanana I",
        fokontany: "tsaratanana I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5505512,
          lng: 47.7834888,
        },
        _id: "64f75285ef8f606073e9aab5",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsaratanana I",
        fokontany: "ambendrana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5773029,
          lng: 47.8377905,
        },
        _id: "64f75285ef8f606073e9aab6",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsaratanana I",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6007423,
          lng: 47.81803800000001,
        },
        _id: "64f75285ef8f606073e9aab7",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsaratanana I",
        fokontany: "ambohimahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6171371,
          lng: 47.8081645,
        },
        _id: "64f75285ef8f606073e9aab8",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsaratanana I",
        fokontany: "ampakobe I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6651651,
          lng: 47.7538931,
        },
        _id: "64f75285ef8f606073e9aab9",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsaratanana I",
        fokontany: "andilamihoatra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6836692,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e9aaba",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsaratanana I",
        fokontany: "zafindrazaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7212576,
          lng: 47.8328517,
        },
        _id: "64f75285ef8f606073e9aabb",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsaratanana I",
        fokontany: "anjiajia",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7657205,
          lng: 47.9020367,
        },
        _id: "64f75285ef8f606073e9aabc",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsaratanana I",
        fokontany: "ankazobe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7624262,
          lng: 47.5778948,
        },
        _id: "64f75285ef8f606073e9aabd",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsarahasina",
        fokontany: "tsarahasina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6594064,
          lng: 47.5914122,
        },
        _id: "64f75285ef8f606073e9aabe",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsarahasina",
        fokontany: "marosely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6979184,
          lng: 47.5766661,
        },
        _id: "64f75285ef8f606073e9aabf",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsarahasina",
        fokontany: "bemilolo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6780841,
          lng: 47.6307555,
        },
        _id: "64f75285ef8f606073e9aac0",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsarahasina",
        fokontany: "madirofafaly",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7177799,
          lng: 47.6012453,
        },
        _id: "64f75285ef8f606073e9aac1",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsarahasina",
        fokontany: "antsambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7351781,
          lng: 47.4980899,
        },
        _id: "64f75285ef8f606073e9aac2",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsarahasina",
        fokontany: "bejahoa",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7539581,
          lng: 47.5766661,
        },
        _id: "64f75285ef8f606073e9aac3",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsarahasina",
        fokontany: "besisika",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7819223,
          lng: 47.5373633,
        },
        _id: "64f75285ef8f606073e9aac4",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsarahasina",
        fokontany: "ambodimanga II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8018864,
          lng: 47.6012453,
        },
        _id: "64f75285ef8f606073e9aac5",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsarahasina",
        fokontany: "antsakoamilaika",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8158737,
          lng: 47.581581,
        },
        _id: "64f75285ef8f606073e9aac6",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsarahasina",
        fokontany: "anjialava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8215537,
          lng: 47.5079055,
        },
        _id: "64f75285ef8f606073e9aac7",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsarahasina",
        fokontany: "betainkilotra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.880085,
          lng: 47.557011,
        },
        _id: "64f75285ef8f606073e9aac8",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsarahasina",
        fokontany: "miadana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8890454,
          lng: 47.4392352,
        },
        _id: "64f75285ef8f606073e9aac9",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsarahasina",
        fokontany: "tsirepoko",
        __v: 0,
      },
      {
        coords: {
          lat: -15.695601,
          lng: 47.6849007,
        },
        _id: "64f75285ef8f606073e9aaca",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "tsiningia",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4814241,
          lng: 47.4559755,
        },
        _id: "64f75285ef8f606073e9aacb",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "ambevongo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4464703,
          lng: 47.3021661,
        },
        _id: "64f75285ef8f606073e9aacc",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "antangena",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4777541,
          lng: 47.41473209999999,
        },
        _id: "64f75285ef8f606073e9aacd",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "anjiameva",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4497245,
          lng: 47.4931828,
        },
        _id: "64f75285ef8f606073e9aace",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "ambodibonara",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4834077,
          lng: 47.5373633,
        },
        _id: "64f75285ef8f606073e9aacf",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "ambarimanjevo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4907987,
          lng: 47.4551685,
        },
        _id: "64f75285ef8f606073e9aad0",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5067764,
          lng: 47.4784643,
        },
        _id: "64f75285ef8f606073e9aad1",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "ambodimabibo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5206822,
          lng: 47.5373633,
        },
        _id: "64f75285ef8f606073e9aad2",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "andranomena II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5324298,
          lng: 47.3902404,
        },
        _id: "64f75285ef8f606073e9aad3",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "andrafialatsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4697028,
          lng: 47.24353319999999,
        },
        _id: "64f75285ef8f606073e9aad4",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "andampy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5579682,
          lng: 47.5373633,
        },
        _id: "64f75285ef8f606073e9aad5",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "ampombibe III",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5638229,
          lng: 47.3461846,
        },
        _id: "64f75285ef8f606073e9aad6",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "ambodimanga I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.583333,
          lng: 47.34999999999999,
        },
        _id: "64f75285ef8f606073e9aad7",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "amberoverobe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.576644,
          lng: 47.4588461,
        },
        _id: "64f75285ef8f606073e9aad8",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "ampitilimaika",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6325514,
          lng: 47.4588461,
        },
        _id: "64f75285ef8f606073e9aad9",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "andranomena I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.632488,
          lng: 47.380447,
        },
        _id: "64f75285ef8f606073e9aada",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "marovantaza",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6836775,
          lng: 47.3608657,
        },
        _id: "64f75285ef8f606073e9aadb",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "ankadirano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7024432,
          lng: 47.4392352,
        },
        _id: "64f75285ef8f606073e9aadc",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "besarandrana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.73392,
          lng: 47.38938900000001,
        },
        _id: "64f75285ef8f606073e9aadd",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "analakonjy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7862772,
          lng: 47.4000357,
        },
        _id: "64f75285ef8f606073e9aade",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsiningia",
        fokontany: "tsaratanimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -15.80014,
          lng: 47.766659,
        },
        _id: "64f75285ef8f606073e9aadf",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "andranomeva",
        fokontany: "andranomeva",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7306505,
          lng: 47.7933577,
        },
        _id: "64f75285ef8f606073e9aae0",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "andranomeva",
        fokontany: "bemakamba",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7692827,
          lng: 47.778555,
        },
        _id: "64f75285ef8f606073e9aae1",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "andranomeva",
        fokontany: "maroandambana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.762249,
          lng: 47.8279133,
        },
        _id: "64f75285ef8f606073e9aae2",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "andranomeva",
        fokontany: "anjialava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7757212,
          lng: 47.8155695,
        },
        _id: "64f75285ef8f606073e9aae3",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "andranomeva",
        fokontany: "marovitsika bas",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8055924,
          lng: 47.872375,
        },
        _id: "64f75285ef8f606073e9aae4",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "andranomeva",
        fokontany: "ambohimandrirana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5114968,
          lng: 47.2630701,
        },
        _id: "64f75285ef8f606073e9aae5",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "andranomeva",
        fokontany: "ampondrabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8254864,
          lng: 47.778555,
        },
        _id: "64f75285ef8f606073e9aae6",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "andranomeva",
        fokontany: "andranotsiritra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8512446,
          lng: 47.7686889,
        },
        _id: "64f75285ef8f606073e9aae7",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "andranomeva",
        fokontany: "tsaratanana II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8676776,
          lng: 47.7982928,
        },
        _id: "64f75285ef8f606073e9aae8",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "andranomeva",
        fokontany: "maroaboaly",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8689041,
          lng: 47.8624914,
        },
        _id: "64f75285ef8f606073e9aae9",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "andranomeva",
        fokontany: "ambatomilahatrano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8618915,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e9aaea",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "andranomeva",
        fokontany: "marovitsika haut",
        __v: 0,
      },
      {
        coords: {
          lat: -15.933333,
          lng: 47.98333299999999,
        },
        _id: "64f75285ef8f606073e9aaeb",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "andranomeva",
        fokontany: "angirony",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9840793,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e9aaec",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "andranomeva",
        fokontany: "ambalamaivanaty",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9745094,
          lng: 47.872375,
        },
        _id: "64f75285ef8f606073e9aaed",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "andranomeva",
        fokontany: "anahidrano I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.938042,
          lng: 47.81803800000001,
        },
        _id: "64f75285ef8f606073e9aaee",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "andranomeva",
        fokontany: "ambiahely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7528893,
          lng: 47.7095305,
        },
        _id: "64f75285ef8f606073e9aaef",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "amparihy",
        fokontany: "amparihibe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7166334,
          lng: 47.7341718,
        },
        _id: "64f75285ef8f606073e9aaf0",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "amparihy",
        fokontany: "ambodimadiro",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7388569,
          lng: 47.7292426,
        },
        _id: "64f75285ef8f606073e9aaf1",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "amparihy",
        fokontany: "ambohimahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7213134,
          lng: 47.675052,
        },
        _id: "64f75285ef8f606073e9aaf2",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "amparihy",
        fokontany: "ambahibe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7487641,
          lng: 47.6430564,
        },
        _id: "64f75285ef8f606073e9aaf3",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "amparihy",
        fokontany: "maromalandy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7563962,
          lng: 47.7046036,
        },
        _id: "64f75285ef8f606073e9aaf4",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "amparihy",
        fokontany: "antremahely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7645694,
          lng: 47.6799761,
        },
        _id: "64f75285ef8f606073e9aaf5",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "amparihy",
        fokontany: "ambodimanga II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7669327,
          lng: 47.7292426,
        },
        _id: "64f75285ef8f606073e9aaf6",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "amparihy",
        fokontany: "ambodiadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8067251,
          lng: 47.7391014,
        },
        _id: "64f75285ef8f606073e9aaf7",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "amparihy",
        fokontany: "ambalabanty",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7879735,
          lng: 47.6996772,
        },
        _id: "64f75285ef8f606073e9aaf8",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "amparihy",
        fokontany: "bepapango",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7996416,
          lng: 47.67012829999999,
        },
        _id: "64f75285ef8f606073e9aaf9",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "amparihy",
        fokontany: "antsaronalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8025891,
          lng: 47.6922884,
        },
        _id: "64f75285ef8f606073e9aafa",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "amparihy",
        fokontany: "beagoago",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8113902,
          lng: 47.7193856,
        },
        _id: "64f75285ef8f606073e9aafb",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "amparihy",
        fokontany: "amparihikely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8278045,
          lng: 47.7489621,
        },
        _id: "64f75285ef8f606073e9aafc",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "amparihy",
        fokontany: "benavony",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8476918,
          lng: 47.7341718,
        },
        _id: "64f75285ef8f606073e9aafd",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "amparihy",
        fokontany: "motretry",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8160506,
          lng: 47.6996772,
        },
        _id: "64f75285ef8f606073e9aafe",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "amparihy",
        fokontany: "beanandraimavo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8569997,
          lng: 47.6947512,
        },
        _id: "64f75285ef8f606073e9aaff",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "amparihy",
        fokontany: "antsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5288806,
          lng: 47.9713115,
        },
        _id: "64f75285ef8f606073e9ab00",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "ambalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4546487,
          lng: 47.813101,
        },
        _id: "64f75285ef8f606073e9ab01",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "betsitindry",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4396358,
          lng: 47.7686889,
        },
        _id: "64f75285ef8f606073e9ab02",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.466667,
          lng: 47.75,
        },
        _id: "64f75285ef8f606073e9ab03",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "angodona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4784438,
          lng: 47.675052,
        },
        _id: "64f75285ef8f606073e9ab04",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "ankatoko atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4689979,
          lng: 47.7144578,
        },
        _id: "64f75285ef8f606073e9ab05",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "beromba",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5027119,
          lng: 48.0605108,
        },
        _id: "64f75285ef8f606073e9ab06",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "berotra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5025994,
          lng: 47.7982928,
        },
        _id: "64f75285ef8f606073e9ab07",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "ambodibonara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8851268,
          lng: 47.9960743,
        },
        _id: "64f75285ef8f606073e9ab08",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "antsohihibe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.51656,
          lng: 47.81803800000001,
        },
        _id: "64f75285ef8f606073e9ab09",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "antafiandakana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5150653,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e9ab0a",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "andohomby",
        __v: 0,
      },
      {
        coords: {
          lat: -15.55,
          lng: 47.933333,
        },
        _id: "64f75285ef8f606073e9ab0b",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "antsangambato",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5538547,
          lng: 47.8575503,
        },
        _id: "64f75285ef8f606073e9ab0c",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "ambalafary",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5420633,
          lng: 47.88720379999999,
        },
        _id: "64f75285ef8f606073e9ab0d",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "antsiradrano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5654038,
          lng: 47.906982,
        },
        _id: "64f75285ef8f606073e9ab0e",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "ambarionadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5846812,
          lng: 47.91934699999999,
        },
        _id: "64f75285ef8f606073e9ab0f",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "ambalantsotry",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6,
          lng: 48.066667,
        },
        _id: "64f75285ef8f606073e9ab10",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "maevaranohely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.57933,
          lng: 47.86284999999999,
        },
        _id: "64f75285ef8f606073e9ab11",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "bemanevika",
        __v: 0,
      },
      {
        coords: {
          lat: -15.604075,
          lng: 47.8921477,
        },
        _id: "64f75285ef8f606073e9ab12",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "marosakoana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.633286,
          lng: 47.9168738,
        },
        _id: "64f75285ef8f606073e9ab13",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "begoroga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6193749,
          lng: 47.8575503,
        },
        _id: "64f75285ef8f606073e9ab14",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "marosely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6342874,
          lng: 47.9812152,
        },
        _id: "64f75285ef8f606073e9ab15",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "sakaravabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6473857,
          lng: 47.897092,
        },
        _id: "64f75285ef8f606073e9ab16",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "leanja",
        fokontany: "antsaronala",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4269165,
          lng: 47.9960743,
        },
        _id: "64f75285ef8f606073e9ab17",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodisakoana",
        fokontany: "antananivo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3645637,
          lng: 47.84766949999999,
        },
        _id: "64f75285ef8f606073e9ab18",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodisakoana",
        fokontany: "antsabanera",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3780539,
          lng: 47.835321,
        },
        _id: "64f75285ef8f606073e9ab19",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodisakoana",
        fokontany: "anjiamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3878236,
          lng: 47.867433,
        },
        _id: "64f75285ef8f606073e9ab1a",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodisakoana",
        fokontany: "ambojobe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.387927,
          lng: 47.9589345,
        },
        _id: "64f75285ef8f606073e9ab1b",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodisakoana",
        fokontany: "bevahivahy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3973565,
          lng: 47.8279133,
        },
        _id: "64f75285ef8f606073e9ab1c",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodisakoana",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3921939,
          lng: 47.9267674,
        },
        _id: "64f75285ef8f606073e9ab1d",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodisakoana",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4090314,
          lng: 47.9490349,
        },
        _id: "64f75285ef8f606073e9ab1e",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodisakoana",
        fokontany: "ambodihazambo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4377247,
          lng: 47.9416114,
        },
        _id: "64f75285ef8f606073e9ab1f",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodisakoana",
        fokontany: "ambodisakoana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4310334,
          lng: 47.872375,
        },
        _id: "64f75285ef8f606073e9ab20",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodisakoana",
        fokontany: "ankazomena",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4265001,
          lng: 47.9638849,
        },
        _id: "64f75285ef8f606073e9ab21",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodisakoana",
        fokontany: "bemikonga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4726858,
          lng: 47.9713115,
        },
        _id: "64f75285ef8f606073e9ab22",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodisakoana",
        fokontany: "ambarijeby",
        __v: 0,
      },
      {
        coords: {
          lat: -15.629975,
          lng: 47.2533007,
        },
        _id: "64f75285ef8f606073e9ab23",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsinjomitondraka",
        fokontany: "tsinjomitondraka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5114968,
          lng: 47.2630701,
        },
        _id: "64f75285ef8f606073e9ab24",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsinjomitondraka",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -15.55,
          lng: 47.3,
        },
        _id: "64f75285ef8f606073e9ab25",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsinjomitondraka",
        fokontany: "andongoza",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6323844,
          lng: 47.3021661,
        },
        _id: "64f75285ef8f606073e9ab26",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsinjomitondraka",
        fokontany: "marolopotra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5601927,
          lng: 47.1947232,
        },
        _id: "64f75285ef8f606073e9ab27",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsinjomitondraka",
        fokontany: "bekapila",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5902368,
          lng: 47.1264672,
        },
        _id: "64f75285ef8f606073e9ab28",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsinjomitondraka",
        fokontany: "tsinjoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6754276,
          lng: 47.3070552,
        },
        _id: "64f75285ef8f606073e9ab29",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsinjomitondraka",
        fokontany: "antanamalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6694232,
          lng: 47.2240036,
        },
        _id: "64f75285ef8f606073e9ab2a",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsinjomitondraka",
        fokontany: "betohatra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7006707,
          lng: 47.1800893,
        },
        _id: "64f75285ef8f606073e9ab2b",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsinjomitondraka",
        fokontany: "miadana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6912511,
          lng: 47.1410858,
        },
        _id: "64f75285ef8f606073e9ab2c",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsinjomitondraka",
        fokontany: "tsimiasara",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7207403,
          lng: 47.2826144,
        },
        _id: "64f75285ef8f606073e9ab2d",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsinjomitondraka",
        fokontany: "ambalakitata",
        __v: 0,
      },
      {
        coords: {
          lat: -15.75,
          lng: 47.183333,
        },
        _id: "64f75285ef8f606073e9ab2e",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsinjomitondraka",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7554852,
          lng: 47.2337675,
        },
        _id: "64f75285ef8f606073e9ab2f",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsinjomitondraka",
        fokontany: "amboangy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7854503,
          lng: 47.1654595,
        },
        _id: "64f75285ef8f606073e9ab30",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "tsinjomitondraka",
        fokontany: "andohamboay",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8792414,
          lng: 47.3217253,
        },
        _id: "64f75285ef8f606073e9ab31",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "maevaranohely",
        fokontany: "maevaranohely I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7487811,
          lng: 47.3217253,
        },
        _id: "64f75285ef8f606073e9ab32",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "maevaranohely",
        fokontany: "ankoby I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7952214,
          lng: 47.2826144,
        },
        _id: "64f75285ef8f606073e9ab33",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "maevaranohely",
        fokontany: "ankoby II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8722906,
          lng: 47.33150759999999,
        },
        _id: "64f75285ef8f606073e9ab34",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "maevaranohely",
        fokontany: "ankisompy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8412585,
          lng: 47.1654595,
        },
        _id: "64f75285ef8f606073e9ab35",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "maevaranohely",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8542,
          lng: 47.20932,
        },
        _id: "64f75285ef8f606073e9ab36",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "maevaranohely",
        fokontany: "ampombinivango",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8914603,
          lng: 47.1996022,
        },
        _id: "64f75285ef8f606073e9ab37",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "maevaranohely",
        fokontany: "sinjeny",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8985994,
          lng: 47.22888529999999,
        },
        _id: "64f75285ef8f606073e9ab38",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "maevaranohely",
        fokontany: "bilomano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9070259,
          lng: 47.2826144,
        },
        _id: "64f75285ef8f606073e9ab39",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "maevaranohely",
        fokontany: "maevaranohely II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9494797,
          lng: 47.380447,
        },
        _id: "64f75285ef8f606073e9ab3a",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "maevaranohely",
        fokontany: "mandrimandria",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9614302,
          lng: 47.2191224,
        },
        _id: "64f75285ef8f606073e9ab3b",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "maevaranohely",
        fokontany: "ankamay",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9568865,
          lng: 47.2386501,
        },
        _id: "64f75285ef8f606073e9ab3c",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "maevaranohely",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.95,
          lng: 47.28333300000001,
        },
        _id: "64f75285ef8f606073e9ab3d",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "maevaranohely",
        fokontany: "ankinaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.78292,
          lng: 48.085819,
        },
        _id: "64f75285ef8f606073e9ab3e",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "marovato",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7468629,
          lng: 47.9812152,
        },
        _id: "64f75285ef8f606073e9ab3f",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "marovato",
        fokontany: "andromitsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7620473,
          lng: 48.0258046,
        },
        _id: "64f75285ef8f606073e9ab40",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "marovato",
        fokontany: "antsakay I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8195974,
          lng: 48.0109374,
        },
        _id: "64f75285ef8f606073e9ab41",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "marovato",
        fokontany: "antsakay II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8253741,
          lng: 48.0952394,
        },
        _id: "64f75285ef8f606073e9ab42",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "marovato",
        fokontany: "ankijanifotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8841163,
          lng: 48.1448904,
        },
        _id: "64f75285ef8f606073e9ab43",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "marovato",
        fokontany: "anantaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.78292,
          lng: 48.085819,
        },
        _id: "64f75285ef8f606073e9ab44",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "marovato",
        fokontany: "betsitindry",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8793316,
          lng: 48.2045317,
        },
        _id: "64f75285ef8f606073e9ab45",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "marovato",
        fokontany: "antsirasira",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8723116,
          lng: 48.1747029,
        },
        _id: "64f75285ef8f606073e9ab46",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "marovato",
        fokontany: "andongona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8806684,
          lng: 48.03076129999999,
        },
        _id: "64f75285ef8f606073e9ab47",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "marovato",
        fokontany: "maizina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9088679,
          lng: 48.070431,
        },
        _id: "64f75285ef8f606073e9ab48",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "marovato",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.91443,
          lng: 48.17083,
        },
        _id: "64f75285ef8f606073e9ab49",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "marovato",
        fokontany: "maromotso",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9276575,
          lng: 48.14985799999999,
        },
        _id: "64f75285ef8f606073e9ab4a",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "marovato",
        fokontany: "ampondrabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9570921,
          lng: 48.1349565,
        },
        _id: "64f75285ef8f606073e9ab4b",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "marovato",
        fokontany: "andrafiahely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9559171,
          lng: 48.1101299,
        },
        _id: "64f75285ef8f606073e9ab4c",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "marovato",
        fokontany: "ampondravelona II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0029732,
          lng: 48.070431,
        },
        _id: "64f75285ef8f606073e9ab4d",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "marovato",
        fokontany: "ampondravelona I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.78292,
          lng: 48.085819,
        },
        _id: "64f75285ef8f606073e9ab4e",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "marovato",
        fokontany: "belalandana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1421844,
          lng: 48.2194523,
        },
        _id: "64f75285ef8f606073e9ab4f",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "marovato",
        fokontany: "sahalentina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7284,
          lng: 48.12529,
        },
        _id: "64f75285ef8f606073e9ab50",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodimahabibo",
        fokontany: "ambodimahabibo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.65,
          lng: 48.09999999999999,
        },
        _id: "64f75285ef8f606073e9ab51",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodimahabibo",
        fokontany: "ampombovaloharona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7162156,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e9ab52",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodimahabibo",
        fokontany: "ankorakely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.74784,
          lng: 48.0853146,
        },
        _id: "64f75285ef8f606073e9ab53",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodimahabibo",
        fokontany: "ampondrahely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7533941,
          lng: 48.2095048,
        },
        _id: "64f75285ef8f606073e9ab54",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodimahabibo",
        fokontany: "tsarafotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7582622,
          lng: 48.14985799999999,
        },
        _id: "64f75285ef8f606073e9ab55",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodimahabibo",
        fokontany: "ambodimanary sud",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7677583,
          lng: 48.1101299,
        },
        _id: "64f75285ef8f606073e9ab56",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodimahabibo",
        fokontany: "antsahampanota",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7994995,
          lng: 48.1051659,
        },
        _id: "64f75285ef8f606073e9ab57",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodimahabibo",
        fokontany: "antanimbaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7713718,
          lng: 48.0654706,
        },
        _id: "64f75285ef8f606073e9ab58",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodimahabibo",
        fokontany: "maromena",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7812648,
          lng: 48.3289951,
        },
        _id: "64f75285ef8f606073e9ab59",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodimahabibo",
        fokontany: "ambodisatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7673309,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e9ab5a",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodimahabibo",
        fokontany: "antanambaofaly",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8061157,
          lng: 48.2941167,
        },
        _id: "64f75285ef8f606073e9ab5b",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodimahabibo",
        fokontany: "ankijanibe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7991352,
          lng: 48.2642387,
        },
        _id: "64f75285ef8f606073e9ab5c",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodimahabibo",
        fokontany: "antanambaovao ihobaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7875984,
          lng: 48.1747029,
        },
        _id: "64f75285ef8f606073e9ab5d",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodimahabibo",
        fokontany: "antsaloana II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8275757,
          lng: 48.184644,
        },
        _id: "64f75285ef8f606073e9ab5e",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodimahabibo",
        fokontany: "antsaloana I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8064987,
          lng: 48.1349565,
        },
        _id: "64f75285ef8f606073e9ab5f",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodimahabibo",
        fokontany: "ambararatabe I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.819192,
          lng: 48.24930579999999,
        },
        _id: "64f75285ef8f606073e9ab60",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodimahabibo",
        fokontany: "ambararatamafaika",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8229079,
          lng: 48.1647635,
        },
        _id: "64f75285ef8f606073e9ab61",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodimahabibo",
        fokontany: "andranotsiritra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8370582,
          lng: 48.1448904,
        },
        _id: "64f75285ef8f606073e9ab62",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodimahabibo",
        fokontany: "anahidrano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7284,
          lng: 48.12529,
        },
        _id: "64f75285ef8f606073e9ab63",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodimahabibo",
        fokontany: "antsakoambe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.695601,
          lng: 47.6849007,
        },
        _id: "64f75285ef8f606073e9ab64",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodivongo",
        fokontany: "ambodivongo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5844864,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e9ab65",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodivongo",
        fokontany: "madiorano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6359005,
          lng: 48.1896152,
        },
        _id: "64f75285ef8f606073e9ab66",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodivongo",
        fokontany: "ambodiadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.695601,
          lng: 47.6849007,
        },
        _id: "64f75285ef8f606073e9ab67",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodivongo",
        fokontany: "andongona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6408355,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e9ab68",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodivongo",
        fokontany: "ampandrakely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6344856,
          lng: 48.24432909999999,
        },
        _id: "64f75285ef8f606073e9ab69",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodivongo",
        fokontany: "ambodimantalio",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6685643,
          lng: 48.24930579999999,
        },
        _id: "64f75285ef8f606073e9ab6a",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodivongo",
        fokontany: "vatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7120681,
          lng: 48.254283,
        },
        _id: "64f75285ef8f606073e9ab6b",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodivongo",
        fokontany: "tsarabanja",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6830479,
          lng: 48.14985799999999,
        },
        _id: "64f75285ef8f606073e9ab6c",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodivongo",
        fokontany: "ambodimanary I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7261149,
          lng: 48.2741962,
        },
        _id: "64f75285ef8f606073e9ab6d",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "ambodivongo",
        fokontany: "antananambo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5607371,
          lng: 47.6578212,
        },
        _id: "64f75285ef8f606073e9ab6e",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge II",
        fokontany: "port berge taloha",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4413463,
          lng: 47.59632850000001,
        },
        _id: "64f75285ef8f606073e9ab6f",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge II",
        fokontany: "mahadera",
        __v: 0,
      },
      {
        coords: {
          lat: -15.467557,
          lng: 47.5987868,
        },
        _id: "64f75285ef8f606073e9ab70",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge II",
        fokontany: "ambovomamy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5019057,
          lng: 47.6159983,
        },
        _id: "64f75285ef8f606073e9ab71",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge II",
        fokontany: "anahidrano II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.507643,
          lng: 47.6602824,
        },
        _id: "64f75285ef8f606073e9ab72",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge II",
        fokontany: "tsinjorano II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5356437,
          lng: 47.6602824,
        },
        _id: "64f75285ef8f606073e9ab73",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge II",
        fokontany: "besatramirina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5532427,
          lng: 47.59632850000001,
        },
        _id: "64f75285ef8f606073e9ab74",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge II",
        fokontany: "andribavontsona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5760133,
          lng: 47.5643808,
        },
        _id: "64f75285ef8f606073e9ab75",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge II",
        fokontany: "ambalavary",
        __v: 0,
      },
      {
        coords: {
          lat: -15.651233,
          lng: 47.5373633,
        },
        _id: "64f75285ef8f606073e9ab76",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge II",
        fokontany: "ampombibe I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6062842,
          lng: 47.6332155,
        },
        _id: "64f75285ef8f606073e9ab77",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge II",
        fokontany: "antanankova",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6266984,
          lng: 47.6504382,
        },
        _id: "64f75285ef8f606073e9ab78",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge II",
        fokontany: "labandy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6127056,
          lng: 47.6307555,
        },
        _id: "64f75285ef8f606073e9ab79",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge II",
        fokontany: "ampombibitika",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6608539,
          lng: 47.65166869999999,
        },
        _id: "64f75285ef8f606073e9ab7a",
        province: "mahajanga",
        region: "sofia",
        district: "port-berge(boriziny-vaovao)",
        commune: "port berge II",
        fokontany: "ambario",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8431605,
          lng: 48.8290519,
        },
        _id: "64f75285ef8f606073e9ab7b",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "mandritsara",
        fokontany: "mandritsara vaovao",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8279103,
          lng: 48.8271659,
        },
        _id: "64f75285ef8f606073e9ab7c",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "mandritsara",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8352801,
          lng: 48.8334527,
        },
        _id: "64f75285ef8f606073e9ab7d",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "mandritsara",
        fokontany: "antsahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.829826,
          lng: 48.8045391,
        },
        _id: "64f75285ef8f606073e9ab7e",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "mandritsara",
        fokontany: "maroamboka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8402424,
          lng: 48.8215084,
        },
        _id: "64f75285ef8f606073e9ab7f",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "mandritsara",
        fokontany: "andohomby atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8406875,
          lng: 48.8260625,
        },
        _id: "64f75285ef8f606073e9ab80",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "mandritsara",
        fokontany: "antanantsimihety",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8429705,
          lng: 48.8359677,
        },
        _id: "64f75285ef8f606073e9ab81",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "mandritsara",
        fokontany: "ambalanantsotry",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8454618,
          lng: 48.8158514,
        },
        _id: "64f75285ef8f606073e9ab82",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "mandritsara",
        fokontany: "mandritsara ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8449537,
          lng: 48.8265373,
        },
        _id: "64f75285ef8f606073e9ab83",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "mandritsara",
        fokontany: "antanandrainivelo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8455615,
          lng: 48.82402279999999,
        },
        _id: "64f75285ef8f606073e9ab84",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "mandritsara",
        fokontany: "ambalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8413725,
          lng: 48.8548333,
        },
        _id: "64f75285ef8f606073e9ab85",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "mandritsara",
        fokontany: "ambohimahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8539297,
          lng: 48.8372252,
        },
        _id: "64f75285ef8f606073e9ab86",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "mandritsara",
        fokontany: "ambalakompania",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8552043,
          lng: 48.8221369,
        },
        _id: "64f75285ef8f606073e9ab87",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "mandritsara",
        fokontany: "ampitatsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ab88",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antanandava",
        fokontany: "andidimiady",
        __v: 0,
      },
      {
        coords: {
          lat: -15.85782,
          lng: 48.81442999999999,
        },
        _id: "64f75285ef8f606073e9ab89",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antanandava",
        fokontany: "ambalavato",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8635317,
          lng: 48.8171084,
        },
        _id: "64f75285ef8f606073e9ab8a",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antanandava",
        fokontany: "andovondava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8677962,
          lng: 48.8976186,
        },
        _id: "64f75285ef8f606073e9ab8b",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antanandava",
        fokontany: "antsahanibakoko",
        __v: 0,
      },
      {
        coords: {
          lat: -15.856119,
          lng: 48.86741379999999,
        },
        _id: "64f75285ef8f606073e9ab8c",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antanandava",
        fokontany: "andilamavo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8584928,
          lng: 48.7643362,
        },
        _id: "64f75285ef8f606073e9ab8d",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antanandava",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.877009,
          lng: 48.8447708,
        },
        _id: "64f75285ef8f606073e9ab8e",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antanandava",
        fokontany: "andilatomendry",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ab8f",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antanandava",
        fokontany: "maroandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8915666,
          lng: 48.784434,
        },
        _id: "64f75285ef8f606073e9ab90",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antanandava",
        fokontany: "ambodisatrana manjola",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ab91",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antanandava",
        fokontany: "ambodibonara I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ab92",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antanandava",
        fokontany: "ambodimadiro sud",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ab93",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antanandava",
        fokontany: "antsiradrano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8066411,
          lng: 48.8171084,
        },
        _id: "64f75285ef8f606073e9ab94",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsoha",
        fokontany: "antsoha",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6158251,
          lng: 48.9051723,
        },
        _id: "64f75285ef8f606073e9ab95",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsoha",
        fokontany: "ambinanitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6599514,
          lng: 48.86993030000001,
        },
        _id: "64f75285ef8f606073e9ab96",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsoha",
        fokontany: "ambohimarina fango",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6449349,
          lng: 48.8246514,
        },
        _id: "64f75285ef8f606073e9ab97",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsoha",
        fokontany: "ambohimena fango",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6919715,
          lng: 48.8648975,
        },
        _id: "64f75285ef8f606073e9ab98",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsoha",
        fokontany: "ambinany fango",
        __v: 0,
      },
      {
        coords: {
          lat: -15.707641,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ab99",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsoha",
        fokontany: "tsaralalana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7455413,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ab9a",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsoha",
        fokontany: "ambodiamontana I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7403582,
          lng: 48.8900659,
        },
        _id: "64f75285ef8f606073e9ab9b",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsoha",
        fokontany: "antsatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7880577,
          lng: 48.84980179999999,
        },
        _id: "64f75285ef8f606073e9ab9c",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsoha",
        fokontany: "antsahameloka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7788477,
          lng: 48.8095665,
        },
        _id: "64f75285ef8f606073e9ab9d",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsoha",
        fokontany: "ambalasatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8152222,
          lng: 48.8648975,
        },
        _id: "64f75285ef8f606073e9ab9e",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsoha",
        fokontany: "ambohitsara nord",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8134208,
          lng: 48.7743842,
        },
        _id: "64f75285ef8f606073e9ab9f",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsoha",
        fokontany: "andilana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8398319,
          lng: 48.9102087,
        },
        _id: "64f75285ef8f606073e9aba0",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsoha",
        fokontany: "andranohorefo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8465039,
          lng: 48.8875486,
        },
        _id: "64f75285ef8f606073e9aba1",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsoha",
        fokontany: "antanjano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9aba2",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "tsaratanana",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9aba3",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "tsaratanana",
        fokontany: "ambohitsara sud",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9409122,
          lng: 48.8749635,
        },
        _id: "64f75285ef8f606073e9aba4",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "tsaratanana",
        fokontany: "ambodimangahely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.952027,
          lng: 48.8508583,
        },
        _id: "64f75285ef8f606073e9aba5",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "tsaratanana",
        fokontany: "ankady",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9aba6",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "tsaratanana",
        fokontany: "maitsomalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9814161,
          lng: 48.8447708,
        },
        _id: "64f75285ef8f606073e9aba7",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "tsaratanana",
        fokontany: "ambalamadiro",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9aba8",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "tsaratanana",
        fokontany: "betampiana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9aba9",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "tsaratanana",
        fokontany: "bevoay",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0541036,
          lng: 48.76936,
        },
        _id: "64f75285ef8f606073e9abaa",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "tsaratanana",
        fokontany: "ankarahara",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1098431,
          lng: 48.784434,
        },
        _id: "64f75285ef8f606073e9abab",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "tsaratanana",
        fokontany: "ambalaben'analalava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abac",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "kalandy",
        fokontany: "kalandy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5959407,
          lng: 48.5887875,
        },
        _id: "64f75285ef8f606073e9abad",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "kalandy",
        fokontany: "ampandrana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.62073,
          lng: 48.56486899999999,
        },
        _id: "64f75285ef8f606073e9abae",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "kalandy",
        fokontany: "antsatramalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6525909,
          lng: 48.5887875,
        },
        _id: "64f75285ef8f606073e9abaf",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "kalandy",
        fokontany: "andapihely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abb0",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "kalandy",
        fokontany: "antsahamelokabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.690372,
          lng: 48.5887875,
        },
        _id: "64f75285ef8f606073e9abb1",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "kalandy",
        fokontany: "maevahalana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7,
          lng: 48.683333,
        },
        _id: "64f75285ef8f606073e9abb2",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "kalandy",
        fokontany: "vohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6806688,
          lng: 48.6288642,
        },
        _id: "64f75285ef8f606073e9abb3",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "kalandy",
        fokontany: "ambodisaina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7268959,
          lng: 48.6038129,
        },
        _id: "64f75285ef8f606073e9abb4",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "kalandy",
        fokontany: "farara",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abb5",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "kalandy",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.75883,
          lng: 48.64012899999999,
        },
        _id: "64f75285ef8f606073e9abb6",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "kalandy",
        fokontany: "maetsamena",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abb7",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "kalandy",
        fokontany: "masiakomby",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7872724,
          lng: 48.6915418,
        },
        _id: "64f75285ef8f606073e9abb8",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "kalandy",
        fokontany: "anjobany",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abb9",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "kalandy",
        fokontany: "ambalafary",
        __v: 0,
      },
      {
        coords: {
          lat: -15.95892,
          lng: 48.961349,
        },
        _id: "64f75285ef8f606073e9abba",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsirabe centre",
        fokontany: "antsirabe centre",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9263315,
          lng: 48.9353974,
        },
        _id: "64f75285ef8f606073e9abbb",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsirabe centre",
        fokontany: "ambahivahy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9332816,
          lng: 48.9656385,
        },
        _id: "64f75285ef8f606073e9abbc",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsirabe centre",
        fokontany: "ambarikapa",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9389499,
          lng: 49.0110305,
        },
        _id: "64f75285ef8f606073e9abbd",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsirabe centre",
        fokontany: "ambalafarihy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9584285,
          lng: 48.9303587,
        },
        _id: "64f75285ef8f606073e9abbe",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsirabe centre",
        fokontany: "andrikarika",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9867294,
          lng: 48.9706803,
        },
        _id: "64f75285ef8f606073e9abbf",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsirabe centre",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.01996,
          lng: 48.986099,
        },
        _id: "64f75285ef8f606073e9abc0",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsirabe centre",
        fokontany: "ambodimandresy II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0674899,
          lng: 48.99085179999999,
        },
        _id: "64f75285ef8f606073e9abc1",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsirabe centre",
        fokontany: "behota",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abc2",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsiatsiaka",
        fokontany: "antsiatsiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0073977,
          lng: 49.1019233,
        },
        _id: "64f75285ef8f606073e9abc3",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsiatsiaka",
        fokontany: "ambohimazava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.090387,
          lng: 49.1727178,
        },
        _id: "64f75285ef8f606073e9abc4",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsiatsiaka",
        fokontany: "ankarany",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1217399,
          lng: 49.1019233,
        },
        _id: "64f75285ef8f606073e9abc5",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsiatsiaka",
        fokontany: "anjiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1522973,
          lng: 49.1929609,
        },
        _id: "64f75285ef8f606073e9abc6",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsiatsiaka",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abc7",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "amboaboa",
        fokontany: "amboaboa",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abc8",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "amboaboa",
        fokontany: "antolantsivy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abc9",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "amboaboa",
        fokontany: "samotretry",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9031323,
          lng: 48.7216521,
        },
        _id: "64f75285ef8f606073e9abca",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "amboaboa",
        fokontany: "mazava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.916056,
          lng: 48.6038129,
        },
        _id: "64f75285ef8f606073e9abcb",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "amboaboa",
        fokontany: "ankiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9202073,
          lng: 48.7442455,
        },
        _id: "64f75285ef8f606073e9abcc",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "amboaboa",
        fokontany: "ankevaheva",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9455502,
          lng: 48.6288642,
        },
        _id: "64f75285ef8f606073e9abcd",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "amboaboa",
        fokontany: "valambana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abce",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "amboaboa",
        fokontany: "anjialava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9604624,
          lng: 48.7542899,
        },
        _id: "64f75285ef8f606073e9abcf",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "amboaboa",
        fokontany: "romiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9359284,
          lng: 48.6689697,
        },
        _id: "64f75285ef8f606073e9abd0",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "amboaboa",
        fokontany: "antananambo I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abd1",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "manampaneva",
        fokontany: "manampaneva",
        __v: 0,
      },
      {
        coords: {
          lat: -16.06193,
          lng: 48.618992,
        },
        _id: "64f75285ef8f606073e9abd2",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "manampaneva",
        fokontany: "antanimalandy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0637182,
          lng: 48.7291822,
        },
        _id: "64f75285ef8f606073e9abd3",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "manampaneva",
        fokontany: "antsirabe II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0797915,
          lng: 48.706595,
        },
        _id: "64f75285ef8f606073e9abd4",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "manampaneva",
        fokontany: "ambodimangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1018563,
          lng: 48.6489134,
        },
        _id: "64f75285ef8f606073e9abd5",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "manampaneva",
        fokontany: "bemotso",
        __v: 0,
      },
      {
        coords: {
          lat: -16.144357,
          lng: 48.74926749999999,
        },
        _id: "64f75285ef8f606073e9abd6",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "manampaneva",
        fokontany: "andravinkazo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.180053,
          lng: 48.6990679,
        },
        _id: "64f75285ef8f606073e9abd7",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "manampaneva",
        fokontany: "ambavaranobe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abd8",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambarikorano",
        fokontany: "ambarikorano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abd9",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambarikorano",
        fokontany: "ambodisely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abda",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambarikorano",
        fokontany: "ambodimadirohely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abdb",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "andratamarina",
        fokontany: "andratamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8478417,
          lng: 49.0387879,
        },
        _id: "64f75285ef8f606073e9abdc",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "andratamarina",
        fokontany: "antangana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8386822,
          lng: 49.0716096,
        },
        _id: "64f75285ef8f606073e9abdd",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "andratamarina",
        fokontany: "marofandrana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8767238,
          lng: 49.0716096,
        },
        _id: "64f75285ef8f606073e9abde",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "andratamarina",
        fokontany: "antanambaon'ambalavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9338071,
          lng: 49.0716096,
        },
        _id: "64f75285ef8f606073e9abdf",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "andratamarina",
        fokontany: "anohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abe0",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambaripaika",
        fokontany: "ambaripaika",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7076558,
          lng: 48.9757225,
        },
        _id: "64f75285ef8f606073e9abe1",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambaripaika",
        fokontany: "andramanalana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7312138,
          lng: 48.99589580000001,
        },
        _id: "64f75285ef8f606073e9abe2",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambaripaika",
        fokontany: "ampombilava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abe3",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambaripaika",
        fokontany: "ankisaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abe4",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambaripaika",
        fokontany: "manja",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abe5",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambaripaika",
        fokontany: "ankotsohotso",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abe6",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambodiamontana kianga",
        fokontany: "ambodiamontana kianga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7102811,
          lng: 48.9454759,
        },
        _id: "64f75285ef8f606073e9abe7",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambodiamontana kianga",
        fokontany: "ambararatibe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.731054,
          lng: 48.9429561,
        },
        _id: "64f75285ef8f606073e9abe8",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambodiamontana kianga",
        fokontany: "antsiradrano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abe9",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambodiamontana kianga",
        fokontany: "bedinta",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8081265,
          lng: 48.9479959,
        },
        _id: "64f75285ef8f606073e9abea",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambodiamontana kianga",
        fokontany: "ambalavelona kianga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8005764,
          lng: 48.92532060000001,
        },
        _id: "64f75285ef8f606073e9abeb",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambodiamontana kianga",
        fokontany: "ambalantsotry",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8218626,
          lng: 48.9353974,
        },
        _id: "64f75285ef8f606073e9abec",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambodiamontana kianga",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abed",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambalakirajy",
        fokontany: "ambalakirajy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6958876,
          lng: 48.448747,
        },
        _id: "64f75285ef8f606073e9abee",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambalakirajy",
        fokontany: "antsomiky II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6719701,
          lng: 48.5087209,
        },
        _id: "64f75285ef8f606073e9abef",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambalakirajy",
        fokontany: "androndry",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abf0",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambalakirajy",
        fokontany: "antsahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7086768,
          lng: 48.4837239,
        },
        _id: "64f75285ef8f606073e9abf1",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambalakirajy",
        fokontany: "ampotamainty",
        __v: 0,
      },
      {
        coords: {
          lat: -15.739495,
          lng: 48.4537424,
        },
        _id: "64f75285ef8f606073e9abf2",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambalakirajy",
        fokontany: "ambalovalo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7508496,
          lng: 48.5437358,
        },
        _id: "64f75285ef8f606073e9abf3",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambalakirajy",
        fokontany: "ambalavary",
        __v: 0,
      },
      {
        coords: {
          lat: -15.733333,
          lng: 48.38333300000001,
        },
        _id: "64f75285ef8f606073e9abf4",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambalakirajy",
        fokontany: "maroaboaly",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7656599,
          lng: 48.4038094,
        },
        _id: "64f75285ef8f606073e9abf5",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambalakirajy",
        fokontany: "antsarika II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8094149,
          lng: 48.36888339999999,
        },
        _id: "64f75285ef8f606073e9abf6",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambalakirajy",
        fokontany: "antsarika I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7791919,
          lng: 48.5437358,
        },
        _id: "64f75285ef8f606073e9abf7",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambalakirajy",
        fokontany: "anandrobato",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7902437,
          lng: 48.448747,
        },
        _id: "64f75285ef8f606073e9abf8",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambalakirajy",
        fokontany: "ampasimbelona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7770667,
          lng: 48.4937213,
        },
        _id: "64f75285ef8f606073e9abf9",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambalakirajy",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8087892,
          lng: 48.5287267,
        },
        _id: "64f75285ef8f606073e9abfa",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambalakirajy",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9abfb",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "tsarajomoka",
        fokontany: "tsarajomoka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7905488,
          lng: 48.6338758,
        },
        _id: "64f75285ef8f606073e9abfc",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "tsarajomoka",
        fokontany: "lengoahitra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8037828,
          lng: 48.5887875,
        },
        _id: "64f75285ef8f606073e9abfd",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "tsarajomoka",
        fokontany: "marangibato",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8307064,
          lng: 48.6439004,
        },
        _id: "64f75285ef8f606073e9abfe",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "tsarajomoka",
        fokontany: "antakabana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8651703,
          lng: 48.6088222,
        },
        _id: "64f75285ef8f606073e9abff",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "tsarajomoka",
        fokontany: "belefitra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ac00",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsatramidola",
        fokontany: "antsatramidola",
        __v: 0,
      },
      {
        coords: {
          lat: -15.533333,
          lng: 48.9166669,
        },
        _id: "64f75285ef8f606073e9ac01",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsatramidola",
        fokontany: "antsitoly",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5419113,
          lng: 48.9555563,
        },
        _id: "64f75285ef8f606073e9ac02",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsatramidola",
        fokontany: "linta",
        __v: 0,
      },
      {
        coords: {
          lat: -15.587961,
          lng: 48.9706803,
        },
        _id: "64f75285ef8f606073e9ac03",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsatramidola",
        fokontany: "ankosibe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5907598,
          lng: 49.0463605,
        },
        _id: "64f75285ef8f606073e9ac04",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsatramidola",
        fokontany: "ampombihely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.610052,
          lng: 48.9530361,
        },
        _id: "64f75285ef8f606073e9ac05",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsatramidola",
        fokontany: "ambodimandresy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.617348,
          lng: 48.99589580000001,
        },
        _id: "64f75285ef8f606073e9ac06",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsatramidola",
        fokontany: "ambalahidambo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.649409,
          lng: 48.99085179999999,
        },
        _id: "64f75285ef8f606073e9ac07",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsatramidola",
        fokontany: "andranokazany",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6782258,
          lng: 48.9505159,
        },
        _id: "64f75285ef8f606073e9ac08",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antsatramidola",
        fokontany: "mahambolona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6775063,
          lng: 49.0312164,
        },
        _id: "64f75285ef8f606073e9ac09",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ankiakabe-fonoko",
        fokontany: "ankiakabe fonoko",
        __v: 0,
      },
      {
        coords: {
          lat: -15.633333,
          lng: 49.033333,
        },
        _id: "64f75285ef8f606073e9ac0a",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ankiakabe-fonoko",
        fokontany: "andongokely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6689642,
          lng: 49.05645879999999,
        },
        _id: "64f75285ef8f606073e9ac0b",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ankiakabe-fonoko",
        fokontany: "ankevaheva",
        __v: 0,
      },
      {
        coords: {
          lat: -15.67162,
          lng: 49.0261692,
        },
        _id: "64f75285ef8f606073e9ac0c",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ankiakabe-fonoko",
        fokontany: "ambohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6955311,
          lng: 49.0059852,
        },
        _id: "64f75285ef8f606073e9ac0d",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ankiakabe-fonoko",
        fokontany: "ambohimalemy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7154832,
          lng: 49.0312164,
        },
        _id: "64f75285ef8f606073e9ac0e",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ankiakabe-fonoko",
        fokontany: "ambiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ac0f",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "marotandrano",
        fokontany: "marotandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.044155,
          lng: 48.8900659,
        },
        _id: "64f75285ef8f606073e9ac10",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "marotandrano",
        fokontany: "ambohimenan'ifasina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0681432,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ac11",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "marotandrano",
        fokontany: "ambalaben'ifasina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.091534,
          lng: 48.9303587,
        },
        _id: "64f75285ef8f606073e9ac12",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "marotandrano",
        fokontany: "anjiro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1108372,
          lng: 48.84980179999999,
        },
        _id: "64f75285ef8f606073e9ac13",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "marotandrano",
        fokontany: "lampibe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1604835,
          lng: 48.9404364,
        },
        _id: "64f75285ef8f606073e9ac14",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "marotandrano",
        fokontany: "ambodimadiro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1553515,
          lng: 48.86741379999999,
        },
        _id: "64f75285ef8f606073e9ac15",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "marotandrano",
        fokontany: "ampandrana I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1591835,
          lng: 48.8787387,
        },
        _id: "64f75285ef8f606073e9ac16",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "marotandrano",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1713536,
          lng: 48.88503129999999,
        },
        _id: "64f75285ef8f606073e9ac17",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "marotandrano",
        fokontany: "ambodifano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1441642,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ac18",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "marotandrano",
        fokontany: "amparihibe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ac19",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "marotandrano",
        fokontany: "ambohimahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1994601,
          lng: 48.8120804,
        },
        _id: "64f75285ef8f606073e9ac1a",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "marotandrano",
        fokontany: "ambohipierenana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2272219,
          lng: 48.9001364,
        },
        _id: "64f75285ef8f606073e9ac1b",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "marotandrano",
        fokontany: "ambodisatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2166521,
          lng: 48.8347102,
        },
        _id: "64f75285ef8f606073e9ac1c",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "marotandrano",
        fokontany: "antsiraka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2582757,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ac1d",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "marotandrano",
        fokontany: "ambalavary",
        __v: 0,
      },
      {
        coords: {
          lat: -16.30304,
          lng: 48.855282,
        },
        _id: "64f75285ef8f606073e9ac1e",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "marotandrano",
        fokontany: "antsiatsiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ac1f",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambilombe",
        fokontany: "ambilombe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4778499,
          lng: 49.0716096,
        },
        _id: "64f75285ef8f606073e9ac20",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambilombe",
        fokontany: "antsandrahana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5099126,
          lng: 49.0665589,
        },
        _id: "64f75285ef8f606073e9ac21",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambilombe",
        fokontany: "bandabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5085404,
          lng: 48.9757225,
        },
        _id: "64f75285ef8f606073e9ac22",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambilombe",
        fokontany: "andrankazo II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5211913,
          lng: 49.0110305,
        },
        _id: "64f75285ef8f606073e9ac23",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambilombe",
        fokontany: "ambodimadiro",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5509737,
          lng: 48.99589580000001,
        },
        _id: "64f75285ef8f606073e9ac24",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambilombe",
        fokontany: "andrankazo I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5550776,
          lng: 49.05645879999999,
        },
        _id: "64f75285ef8f606073e9ac25",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambilombe",
        fokontany: "ambodiadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ac26",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "andohajango",
        fokontany: "andohajango",
        __v: 0,
      },
      {
        coords: {
          lat: -15.816667,
          lng: 48.4,
        },
        _id: "64f75285ef8f606073e9ac27",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "andohajango",
        fokontany: "ampombilava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8739949,
          lng: 48.4637344,
        },
        _id: "64f75285ef8f606073e9ac28",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "andohajango",
        fokontany: "sahantona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8372991,
          lng: 48.4887224,
        },
        _id: "64f75285ef8f606073e9ac29",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "andohajango",
        fokontany: "antsiradrano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8750921,
          lng: 48.4887224,
        },
        _id: "64f75285ef8f606073e9ac2a",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "andohajango",
        fokontany: "bekilandy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.911788,
          lng: 48.4637344,
        },
        _id: "64f75285ef8f606073e9ac2b",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "andohajango",
        fokontany: "maroadabohely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.926893,
          lng: 48.5487397,
        },
        _id: "64f75285ef8f606073e9ac2c",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "andohajango",
        fokontany: "antanantanana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9791646,
          lng: 48.448747,
        },
        _id: "64f75285ef8f606073e9ac2d",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "andohajango",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9886877,
          lng: 48.4088007,
        },
        _id: "64f75285ef8f606073e9ac2e",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "andohajango",
        fokontany: "andrangeza",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1186786,
          lng: 48.3589086,
        },
        _id: "64f75285ef8f606073e9ac2f",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "andohajango",
        fokontany: "ambodimanga seranana",
        __v: 0,
      },
      {
        coords: {
          lat: -16,
          lng: 48.516667,
        },
        _id: "64f75285ef8f606073e9ac30",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "andohajango",
        fokontany: "bemanondro",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ac31",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "anjiabe",
        fokontany: "anjiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5928936,
          lng: 48.3289951,
        },
        _id: "64f75285ef8f606073e9ac32",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "anjiabe",
        fokontany: "ambohimahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6198748,
          lng: 48.3439498,
        },
        _id: "64f75285ef8f606073e9ac33",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "anjiabe",
        fokontany: "antsatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6255144,
          lng: 48.3888384,
        },
        _id: "64f75285ef8f606073e9ac34",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "anjiabe",
        fokontany: "ambarika",
        __v: 0,
      },
      {
        coords: {
          lat: -15.616667,
          lng: 48.266667,
        },
        _id: "64f75285ef8f606073e9ac35",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "anjiabe",
        fokontany: "ambongabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.677436,
          lng: 48.36888339999999,
        },
        _id: "64f75285ef8f606073e9ac36",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "anjiabe",
        fokontany: "mahialambo II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6719434,
          lng: 48.2841556,
        },
        _id: "64f75285ef8f606073e9ac37",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "anjiabe",
        fokontany: "maetsamifafy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7247238,
          lng: 48.3289951,
        },
        _id: "64f75285ef8f606073e9ac38",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "anjiabe",
        fokontany: "mahialambo I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ac39",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambohisoa",
        fokontany: "ambohisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8706823,
          lng: 48.3888384,
        },
        _id: "64f75285ef8f606073e9ac3a",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambohisoa",
        fokontany: "ambaky",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9177874,
          lng: 48.4287702,
        },
        _id: "64f75285ef8f606073e9ac3b",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambohisoa",
        fokontany: "ambodisaina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0006469,
          lng: 48.2592606,
        },
        _id: "64f75285ef8f606073e9ac3c",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambohisoa",
        fokontany: "amboangy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8897478,
          lng: 48.3090619,
        },
        _id: "64f75285ef8f606073e9ac3d",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambohisoa",
        fokontany: "bedinta",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8566918,
          lng: 48.3289951,
        },
        _id: "64f75285ef8f606073e9ac3e",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambohisoa",
        fokontany: "bebana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8957744,
          lng: 48.23437699999999,
        },
        _id: "64f75285ef8f606073e9ac3f",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambohisoa",
        fokontany: "analanambe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.946442,
          lng: 48.2294017,
        },
        _id: "64f75285ef8f606073e9ac40",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambohisoa",
        fokontany: "lovia lampy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ac41",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ampatakamaroreny",
        fokontany: "ampatakamaroreny",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3986655,
          lng: 48.8196226,
        },
        _id: "64f75285ef8f606073e9ac42",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ampatakamaroreny",
        fokontany: "sahavoay",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4630028,
          lng: 48.8900659,
        },
        _id: "64f75285ef8f606073e9ac43",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ampatakamaroreny",
        fokontany: "ambodirafia",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5131289,
          lng: 48.9807651,
        },
        _id: "64f75285ef8f606073e9ac44",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ampatakamaroreny",
        fokontany: "sahavia",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6084852,
          lng: 48.9001364,
        },
        _id: "64f75285ef8f606073e9ac45",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ampatakamaroreny",
        fokontany: "beranimbo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.593839,
          lng: 48.7593128,
        },
        _id: "64f75285ef8f606073e9ac46",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ampatakamaroreny",
        fokontany: "masora",
        __v: 0,
      },
      {
        coords: {
          lat: -15.60777,
          lng: 48.732361,
        },
        _id: "64f75285ef8f606073e9ac47",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ankiabe-salohy",
        fokontany: "ankiabe salohy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4615671,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ac48",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ankiabe-salohy",
        fokontany: "antanambaon'ampanihy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4623736,
          lng: 48.74926749999999,
        },
        _id: "64f75285ef8f606073e9ac49",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ankiabe-salohy",
        fokontany: "betsihavasana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5451466,
          lng: 48.739224,
        },
        _id: "64f75285ef8f606073e9ac4a",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ankiabe-salohy",
        fokontany: "ambinanindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5434695,
          lng: 48.7944857,
        },
        _id: "64f75285ef8f606073e9ac4b",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ankiabe-salohy",
        fokontany: "ambalamadiro",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5290956,
          lng: 48.8145943,
        },
        _id: "64f75285ef8f606073e9ac4c",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ankiabe-salohy",
        fokontany: "misorobe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5228454,
          lng: 48.84980179999999,
        },
        _id: "64f75285ef8f606073e9ac4d",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ankiabe-salohy",
        fokontany: "antsahalava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5887286,
          lng: 48.8900659,
        },
        _id: "64f75285ef8f606073e9ac4e",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ankiabe-salohy",
        fokontany: "belobaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5940081,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ac4f",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ankiabe-salohy",
        fokontany: "bemahetsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6325276,
          lng: 48.74926749999999,
        },
        _id: "64f75285ef8f606073e9ac50",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ankiabe-salohy",
        fokontany: "ambalagavo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.57309,
          lng: 48.687881,
        },
        _id: "64f75285ef8f606073e9ac51",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ankiabe-salohy",
        fokontany: "ambohimalandy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6331376,
          lng: 48.6689697,
        },
        _id: "64f75285ef8f606073e9ac52",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ankiabe-salohy",
        fokontany: "matsaboribe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ac53",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "pont sofia",
        fokontany: "pont sofia",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4676703,
          lng: 48.6890334,
        },
        _id: "64f75285ef8f606073e9ac54",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "pont sofia",
        fokontany: "ampondramotso",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4904962,
          lng: 48.6439004,
        },
        _id: "64f75285ef8f606073e9ac55",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "pont sofia",
        fokontany: "ambodivoarabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5197779,
          lng: 48.6689697,
        },
        _id: "64f75285ef8f606073e9ac56",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "pont sofia",
        fokontany: "ambodibonara",
        __v: 0,
      },
      {
        coords: {
          lat: -15.543876,
          lng: 48.6088222,
        },
        _id: "64f75285ef8f606073e9ac57",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "pont sofia",
        fokontany: "ankasina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.183333,
          lng: 48.583333,
        },
        _id: "64f75285ef8f606073e9ac58",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambodiadabo",
        fokontany: "ambodiadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2489189,
          lng: 48.5487397,
        },
        _id: "64f75285ef8f606073e9ac59",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambodiadabo",
        fokontany: "ankijanilava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2548601,
          lng: 48.6338758,
        },
        _id: "64f75285ef8f606073e9ac5a",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambodiadabo",
        fokontany: "sahovy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2643302,
          lng: 48.714123,
        },
        _id: "64f75285ef8f606073e9ac5b",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambodiadabo",
        fokontany: "anorimbato",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3272286,
          lng: 48.598804,
        },
        _id: "64f75285ef8f606073e9ac5c",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambodiadabo",
        fokontany: "bekaraba",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3700634,
          lng: 48.6990679,
        },
        _id: "64f75285ef8f606073e9ac5d",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "ambodiadabo",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8593096,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ac5e",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "amborondolo",
        fokontany: "amborondolo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0548097,
          lng: 48.448747,
        },
        _id: "64f75285ef8f606073e9ac5f",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "amborondolo",
        fokontany: "ambodidimaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1398935,
          lng: 48.56876,
        },
        _id: "64f75285ef8f606073e9ac60",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "amborondolo",
        fokontany: "betalevana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2653827,
          lng: 48.4187846,
        },
        _id: "64f75285ef8f606073e9ac61",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "amborondolo",
        fokontany: "andrafiatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -16.41139,
          lng: 49.103088,
        },
        _id: "64f75285ef8f606073e9ac62",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antanambaon'amberina",
        fokontany: "antanambaon'amberina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2095752,
          lng: 49.1929609,
        },
        _id: "64f75285ef8f606073e9ac63",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antanambaon'amberina",
        fokontany: "ambodivoangibe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1908282,
          lng: 49.1120314,
        },
        _id: "64f75285ef8f606073e9ac64",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antanambaon'amberina",
        fokontany: "ambohimahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2173739,
          lng: 49.0211225,
        },
        _id: "64f75285ef8f606073e9ac65",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antanambaon'amberina",
        fokontany: "ambatambaky",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3223257,
          lng: 48.9807651,
        },
        _id: "64f75285ef8f606073e9ac66",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antanambaon'amberina",
        fokontany: "antambotsona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3772015,
          lng: 48.9303587,
        },
        _id: "64f75285ef8f606073e9ac67",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antanambaon'amberina",
        fokontany: "ambodivato",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4081196,
          lng: 49.0211225,
        },
        _id: "64f75285ef8f606073e9ac68",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antanambaon'amberina",
        fokontany: "sahakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4534063,
          lng: 49.0918169,
        },
        _id: "64f75285ef8f606073e9ac69",
        province: "mahajanga",
        region: "sofia",
        district: "mandritsara",
        commune: "antanambaon'amberina",
        fokontany: "antsahamaloto",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6293452,
          lng: 47.7501948,
        },
        _id: "64f75285ef8f606073e9ac6a",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "analalava",
        fokontany: "central",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5500455,
          lng: 47.59632850000001,
        },
        _id: "64f75285ef8f606073e9ac6b",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "analalava",
        fokontany: "nosy lava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6191894,
          lng: 47.7514275,
        },
        _id: "64f75285ef8f606073e9ac6c",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "analalava",
        fokontany: "ampasikely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6359198,
          lng: 47.7458804,
        },
        _id: "64f75285ef8f606073e9ac6d",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "analalava",
        fokontany: "fongony ambalahonko",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6377312,
          lng: 47.7514275,
        },
        _id: "64f75285ef8f606073e9ac6e",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "analalava",
        fokontany: "befitina anjialava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6790354,
          lng: 47.7391014,
        },
        _id: "64f75285ef8f606073e9ac6f",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "analalava",
        fokontany: "ovarikely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6827395,
          lng: 47.9020367,
        },
        _id: "64f75285ef8f606073e9ac70",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "analalava",
        fokontany: "ambarijeby est",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6652103,
          lng: 47.8229754,
        },
        _id: "64f75285ef8f606073e9ac71",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "analalava",
        fokontany: "antsatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7103985,
          lng: 47.7341718,
        },
        _id: "64f75285ef8f606073e9ac72",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "analalava",
        fokontany: "androiavy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7215917,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e9ac73",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "analalava",
        fokontany: "bevoay",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7386854,
          lng: 47.6947512,
        },
        _id: "64f75285ef8f606073e9ac74",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "analalava",
        fokontany: "marotaolana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7447496,
          lng: 47.867433,
        },
        _id: "64f75285ef8f606073e9ac75",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "analalava",
        fokontany: "anovilava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7678227,
          lng: 47.7834888,
        },
        _id: "64f75285ef8f606073e9ac76",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "analalava",
        fokontany: "ankaramikely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7872034,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e9ac77",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "analalava",
        fokontany: "ambalavary",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7872779,
          lng: 47.7046036,
        },
        _id: "64f75285ef8f606073e9ac78",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "analalava",
        fokontany: "tsaradokitra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.93211,
          lng: 47.680099,
        },
        _id: "64f75285ef8f606073e9ac79",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambarijeby sud",
        fokontany: "ambarijeby sud",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8615369,
          lng: 47.7046036,
        },
        _id: "64f75285ef8f606073e9ac7a",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambarijeby sud",
        fokontany: "bekofafa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.85,
          lng: 47.616667,
        },
        _id: "64f75285ef8f606073e9ac7b",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambarijeby sud",
        fokontany: "ampasindava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8782658,
          lng: 47.6553601,
        },
        _id: "64f75285ef8f606073e9ac7c",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambarijeby sud",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9064721,
          lng: 47.6159983,
        },
        _id: "64f75285ef8f606073e9ac7d",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambarijeby sud",
        fokontany: "ambolikily",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9845522,
          lng: 47.7144578,
        },
        _id: "64f75285ef8f606073e9ac7e",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambarijeby sud",
        fokontany: "marotomendry",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9621632,
          lng: 47.6159983,
        },
        _id: "64f75285ef8f606073e9ac7f",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambarijeby sud",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.42078,
          lng: 47.800468,
        },
        _id: "64f75285ef8f606073e9ac80",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "ambolobozo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1665999,
          lng: 47.8081645,
        },
        _id: "64f75285ef8f606073e9ac81",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "nosy valiha",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1692378,
          lng: 47.9713115,
        },
        _id: "64f75285ef8f606073e9ac82",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "lanamena",
        __v: 0,
      },
      {
        coords: {
          lat: -14.275379,
          lng: 47.9267674,
        },
        _id: "64f75285ef8f606073e9ac83",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "befanivana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2718231,
          lng: 47.867433,
        },
        _id: "64f75285ef8f606073e9ac84",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "ankatafa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2604228,
          lng: 47.7933577,
        },
        _id: "64f75285ef8f606073e9ac85",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "andaveno III",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2960262,
          lng: 47.872375,
        },
        _id: "64f75285ef8f606073e9ac86",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "ampanitoa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3315024,
          lng: 47.7588245,
        },
        _id: "64f75285ef8f606073e9ac87",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "ampasipitily",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3273874,
          lng: 47.931715,
        },
        _id: "64f75285ef8f606073e9ac88",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "betsimipoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3386895,
          lng: 47.813101,
        },
        _id: "64f75285ef8f606073e9ac89",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "ankingabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3408995,
          lng: 47.88720379999999,
        },
        _id: "64f75285ef8f606073e9ac8a",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "ambinda",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3472776,
          lng: 47.8526097,
        },
        _id: "64f75285ef8f606073e9ac8b",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "antafiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3627915,
          lng: 47.7538931,
        },
        _id: "64f75285ef8f606073e9ac8c",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3954749,
          lng: 47.7341718,
        },
        _id: "64f75285ef8f606073e9ac8d",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "ampasimirehoko",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3644512,
          lng: 47.931715,
        },
        _id: "64f75285ef8f606073e9ac8e",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "andaveno II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4509821,
          lng: 47.7341718,
        },
        _id: "64f75285ef8f606073e9ac8f",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "ambalahonko",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4726322,
          lng: 47.8328517,
        },
        _id: "64f75285ef8f606073e9ac90",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "ankorabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5108641,
          lng: 47.7538931,
        },
        _id: "64f75285ef8f606073e9ac91",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "andavaravina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5424386,
          lng: 47.813101,
        },
        _id: "64f75285ef8f606073e9ac92",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "anjango",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5443712,
          lng: 47.7588245,
        },
        _id: "64f75285ef8f606073e9ac93",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "ankatakatabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5849779,
          lng: 47.7538931,
        },
        _id: "64f75285ef8f606073e9ac94",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambolobozo",
        fokontany: "andronjana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5296627,
          lng: 48.0245655,
        },
        _id: "64f75285ef8f606073e9ac95",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "befotaka nord",
        fokontany: "befotaka nord",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4065713,
          lng: 47.9762632,
        },
        _id: "64f75285ef8f606073e9ac96",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "befotaka nord",
        fokontany: "antanambao manambaro",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4329949,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e9ac97",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "befotaka nord",
        fokontany: "ampohara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4658039,
          lng: 47.9713115,
        },
        _id: "64f75285ef8f606073e9ac98",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "befotaka nord",
        fokontany: "antanimbaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4601813,
          lng: 48.03076129999999,
        },
        _id: "64f75285ef8f606073e9ac99",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "befotaka nord",
        fokontany: "andrafiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4545294,
          lng: 48.0902768,
        },
        _id: "64f75285ef8f606073e9ac9a",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "befotaka nord",
        fokontany: "ambapaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4674472,
          lng: 48.14985799999999,
        },
        _id: "64f75285ef8f606073e9ac9b",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "befotaka nord",
        fokontany: "antsahafaly",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4974343,
          lng: 48.0952394,
        },
        _id: "64f75285ef8f606073e9ac9c",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "befotaka nord",
        fokontany: "ambinaninandrotro",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5324648,
          lng: 48.0853146,
        },
        _id: "64f75285ef8f606073e9ac9d",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "befotaka nord",
        fokontany: "ambalatsotry",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5467313,
          lng: 48.0654706,
        },
        _id: "64f75285ef8f606073e9ac9e",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "befotaka nord",
        fokontany: "ambondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.507384,
          lng: 48.0555514,
        },
        _id: "64f75285ef8f606073e9ac9f",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "befotaka nord",
        fokontany: "tsiningia",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5258361,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e9aca0",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "befotaka nord",
        fokontany: "andampy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.516667,
          lng: 47.95,
        },
        _id: "64f75285ef8f606073e9aca1",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "befotaka nord",
        fokontany: "antsariheza",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5780198,
          lng: 47.9960743,
        },
        _id: "64f75285ef8f606073e9aca2",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "befotaka nord",
        fokontany: "misorobe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5714995,
          lng: 47.9663604,
        },
        _id: "64f75285ef8f606073e9aca3",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "befotaka nord",
        fokontany: "antsohobolona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5456216,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e9aca4",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "befotaka nord",
        fokontany: "komaronga",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6,
          lng: 47.84999999999999,
        },
        _id: "64f75285ef8f606073e9aca5",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "befotaka nord",
        fokontany: "ambendrana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.52916,
          lng: 48.22049,
        },
        _id: "64f75285ef8f606073e9aca6",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambaliha",
        fokontany: "ambaliha",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4143697,
          lng: 48.37886,
        },
        _id: "64f75285ef8f606073e9aca7",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambaliha",
        fokontany: "bemanasy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4717774,
          lng: 48.299098,
        },
        _id: "64f75285ef8f606073e9aca8",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambaliha",
        fokontany: "ambaibo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.416667,
          lng: 48.25,
        },
        _id: "64f75285ef8f606073e9aca9",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambaliha",
        fokontany: "betolongo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4832396,
          lng: 48.24432909999999,
        },
        _id: "64f75285ef8f606073e9acaa",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambaliha",
        fokontany: "antananambo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4724812,
          lng: 48.194587,
        },
        _id: "64f75285ef8f606073e9acab",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambaliha",
        fokontany: "andrafiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5118669,
          lng: 48.2045317,
        },
        _id: "64f75285ef8f606073e9acac",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambaliha",
        fokontany: "andranofeda",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5119085,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e9acad",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambaliha",
        fokontany: "antranobe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5821416,
          lng: 48.24930579999999,
        },
        _id: "64f75285ef8f606073e9acae",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambaliha",
        fokontany: "ambodidimaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6136854,
          lng: 48.24432909999999,
        },
        _id: "64f75285ef8f606073e9acaf",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ambaliha",
        fokontany: "ankiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.203229,
          lng: 48.08159329999999,
        },
        _id: "64f75285ef8f606073e9acb0",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "maromandia",
        fokontany: "maromandia",
        __v: 0,
      },
      {
        coords: {
          lat: -14.15,
          lng: 48.066667,
        },
        _id: "64f75285ef8f606073e9acb1",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "maromandia",
        fokontany: "bevoay",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2359851,
          lng: 48.1101299,
        },
        _id: "64f75285ef8f606073e9acb2",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "maromandia",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2444752,
          lng: 48.14985799999999,
        },
        _id: "64f75285ef8f606073e9acb3",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "maromandia",
        fokontany: "ampomaventy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2737721,
          lng: 48.1349565,
        },
        _id: "64f75285ef8f606073e9acb4",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "maromandia",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.316667,
          lng: 48.116667,
        },
        _id: "64f75285ef8f606073e9acb5",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "maromandia",
        fokontany: "amparihibe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.31519,
          lng: 48.043011,
        },
        _id: "64f75285ef8f606073e9acb6",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "maromandia",
        fokontany: "mahitsihazo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3487988,
          lng: 48.03076129999999,
        },
        _id: "64f75285ef8f606073e9acb7",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "maromandia",
        fokontany: "maropapango",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3887853,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e9acb8",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "maromandia",
        fokontany: "anketrakabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3429694,
          lng: 48.2194523,
        },
        _id: "64f75285ef8f606073e9acb9",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "maromandia",
        fokontany: "ambodisaina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.239938,
          lng: 48.2592606,
        },
        _id: "64f75285ef8f606073e9acba",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "maromandia",
        fokontany: "antanamazava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.283333,
          lng: 48.283333,
        },
        _id: "64f75285ef8f606073e9acbb",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "maromandia",
        fokontany: "ambodigavo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3257293,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e9acbc",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "maromandia",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.23384,
          lng: 48.328579,
        },
        _id: "64f75285ef8f606073e9acbd",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "maromandia",
        fokontany: "bejofo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2107581,
          lng: 48.4287702,
        },
        _id: "64f75285ef8f606073e9acbe",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "maromandia",
        fokontany: "ambaibo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2134794,
          lng: 48.4637344,
        },
        _id: "64f75285ef8f606073e9acbf",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "maromandia",
        fokontany: "anketrakabe sandrakota",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2581571,
          lng: 48.3888384,
        },
        _id: "64f75285ef8f606073e9acc0",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "maromandia",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2625894,
          lng: 48.3439498,
        },
        _id: "64f75285ef8f606073e9acc1",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "maromandia",
        fokontany: "ambinanibefiana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2896,
          lng: 48.3587,
        },
        _id: "64f75285ef8f606073e9acc2",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "maromandia",
        fokontany: "anefitrabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3398326,
          lng: 48.37886,
        },
        _id: "64f75285ef8f606073e9acc3",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "maromandia",
        fokontany: "andranomazava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1923334,
          lng: 47.6424546,
        },
        _id: "64f75285ef8f606073e9acc4",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "marovatolena",
        fokontany: "marovatolena",
        __v: 0,
      },
      {
        coords: {
          lat: -15.033333,
          lng: 47.6666669,
        },
        _id: "64f75285ef8f606073e9acc5",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "marovatolena",
        fokontany: "ampanisara",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0534795,
          lng: 47.67012829999999,
        },
        _id: "64f75285ef8f606073e9acc6",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "marovatolena",
        fokontany: "andrafiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.08547,
          lng: 47.573559,
        },
        _id: "64f75285ef8f606073e9acc7",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "marovatolena",
        fokontany: "anesibato",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1028794,
          lng: 47.649823,
        },
        _id: "64f75285ef8f606073e9acc8",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "marovatolena",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1319098,
          lng: 47.58649639999999,
        },
        _id: "64f75285ef8f606073e9acc9",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "marovatolena",
        fokontany: "bemafaika ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1678476,
          lng: 47.6012453,
        },
        _id: "64f75285ef8f606073e9acca",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "marovatolena",
        fokontany: "andromba",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1856308,
          lng: 47.5373633,
        },
        _id: "64f75285ef8f606073e9accb",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "marovatolena",
        fokontany: "bemafaika ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1992636,
          lng: 47.59632850000001,
        },
        _id: "64f75285ef8f606073e9accc",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "marovatolena",
        fokontany: "mevalafika",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2596436,
          lng: 47.6159983,
        },
        _id: "64f75285ef8f606073e9accd",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "marovatolena",
        fokontany: "marovato sud",
        __v: 0,
      },
      {
        coords: {
          lat: -15.233333,
          lng: 47.616667,
        },
        _id: "64f75285ef8f606073e9acce",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "marovatolena",
        fokontany: "antrema",
        __v: 0,
      },
      {
        coords: {
          lat: -15.39058,
          lng: 47.502892,
        },
        _id: "64f75285ef8f606073e9accf",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "andribavontsona",
        fokontany: "andribavontsona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3112359,
          lng: 47.5177229,
        },
        _id: "64f75285ef8f606073e9acd0",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "andribavontsona",
        fokontany: "belinta",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3392677,
          lng: 47.4784643,
        },
        _id: "64f75285ef8f606073e9acd1",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "andribavontsona",
        fokontany: "ankorabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.383261,
          lng: 47.5471862,
        },
        _id: "64f75285ef8f606073e9acd2",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "andribavontsona",
        fokontany: "andampy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4184517,
          lng: 47.4196318,
        },
        _id: "64f75285ef8f606073e9acd3",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "andribavontsona",
        fokontany: "antsira",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3401,
          lng: 47.41292199999999,
        },
        _id: "64f75285ef8f606073e9acd4",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "angoaka sud",
        fokontany: "angoaka sud",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2523976,
          lng: 47.404934,
        },
        _id: "64f75285ef8f606073e9acd5",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "angoaka sud",
        fokontany: "ambalavia",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2778338,
          lng: 47.4343337,
        },
        _id: "64f75285ef8f606073e9acd6",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "angoaka sud",
        fokontany: "ampako",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3245006,
          lng: 47.3559715,
        },
        _id: "64f75285ef8f606073e9acd7",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "angoaka sud",
        fokontany: "andranoampango",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3685539,
          lng: 47.3853435,
        },
        _id: "64f75285ef8f606073e9acd8",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "angoaka sud",
        fokontany: "antanamifafy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3674417,
          lng: 47.3608657,
        },
        _id: "64f75285ef8f606073e9acd9",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "angoaka sud",
        fokontany: "ambatofalio",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4023465,
          lng: 47.3119448,
        },
        _id: "64f75285ef8f606073e9acda",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "angoaka sud",
        fokontany: "antsakoabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3845875,
          lng: 47.6564401,
        },
        _id: "64f75285ef8f606073e9acdb",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "marovantaza",
        fokontany: "marovantaza",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3443526,
          lng: 47.6799761,
        },
        _id: "64f75285ef8f606073e9acdc",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "marovantaza",
        fokontany: "andavakaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.35,
          lng: 47.65,
        },
        _id: "64f75285ef8f606073e9acdd",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "marovantaza",
        fokontany: "mangaoka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3341332,
          lng: 47.6159983,
        },
        _id: "64f75285ef8f606073e9acde",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "marovantaza",
        fokontany: "ambodimabibo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3726115,
          lng: 47.6012453,
        },
        _id: "64f75285ef8f606073e9acdf",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "marovantaza",
        fokontany: "andranomatana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3943378,
          lng: 47.7144578,
        },
        _id: "64f75285ef8f606073e9ace0",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "marovantaza",
        fokontany: "andrevorevo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.416667,
          lng: 47.616667,
        },
        _id: "64f75285ef8f606073e9ace1",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "marovantaza",
        fokontany: "ambalafomby",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4376084,
          lng: 47.6799761,
        },
        _id: "64f75285ef8f606073e9ace2",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "marovantaza",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4377283,
          lng: 47.640596,
        },
        _id: "64f75285ef8f606073e9ace3",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "marovantaza",
        fokontany: "betsimipoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0602447,
          lng: 48.19955909999999,
        },
        _id: "64f75285ef8f606073e9ace4",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ankaramy",
        fokontany: "ankaramy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9023738,
          lng: 48.2642387,
        },
        _id: "64f75285ef8f606073e9ace5",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ankaramy",
        fokontany: "anjiabory",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9239367,
          lng: 48.23437699999999,
        },
        _id: "64f75285ef8f606073e9ace6",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ankaramy",
        fokontany: "ambodimangatelo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.901271,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e9ace7",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ankaramy",
        fokontany: "ambalabao",
        __v: 0,
      },
      {
        coords: {
          lat: -13.933333,
          lng: 48.216667,
        },
        _id: "64f75285ef8f606073e9ace8",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ankaramy",
        fokontany: "bekaraka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9415046,
          lng: 48.2741962,
        },
        _id: "64f75285ef8f606073e9ace9",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ankaramy",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9443387,
          lng: 48.154826,
        },
        _id: "64f75285ef8f606073e9acea",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ankaramy",
        fokontany: "antsahabeminko",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9586806,
          lng: 48.1349565,
        },
        _id: "64f75285ef8f606073e9aceb",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ankaramy",
        fokontany: "ambalamananty",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9626798,
          lng: 48.3090619,
        },
        _id: "64f75285ef8f606073e9acec",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ankaramy",
        fokontany: "anjialava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9765982,
          lng: 48.1101299,
        },
        _id: "64f75285ef8f606073e9aced",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ankaramy",
        fokontany: "ambaliha",
        __v: 0,
      },
      {
        coords: {
          lat: -14.029149,
          lng: 48.1399232,
        },
        _id: "64f75285ef8f606073e9acee",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ankaramy",
        fokontany: "ankaramihely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0171699,
          lng: 48.2592606,
        },
        _id: "64f75285ef8f606073e9acef",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ankaramy",
        fokontany: "beraty",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0494071,
          lng: 48.0605108,
        },
        _id: "64f75285ef8f606073e9acf0",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ankaramy",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0656337,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e9acf1",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ankaramy",
        fokontany: "analanantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1284934,
          lng: 48.2592606,
        },
        _id: "64f75285ef8f606073e9acf2",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ankaramy",
        fokontany: "antafiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1289533,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e9acf3",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ankaramy",
        fokontany: "ambodiaviavy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1774178,
          lng: 48.1399232,
        },
        _id: "64f75285ef8f606073e9acf4",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "ankaramy",
        fokontany: "katsory",
        __v: 0,
      },
      {
        coords: {
          lat: -15.12188,
          lng: 47.392849,
        },
        _id: "64f75285ef8f606073e9acf5",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "antonibe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7508354,
          lng: 47.4196318,
        },
        _id: "64f75285ef8f606073e9acf6",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "antsanifera",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8081877,
          lng: 47.4686543,
        },
        _id: "64f75285ef8f606073e9acf7",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "komajara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.79171,
          lng: 47.35207,
        },
        _id: "64f75285ef8f606073e9acf8",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "ampitily",
        __v: 0,
      },
      {
        coords: {
          lat: -14.91924,
          lng: 47.3841589,
        },
        _id: "64f75285ef8f606073e9acf9",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "ambalatsingy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9964815,
          lng: 47.3608657,
        },
        _id: "64f75285ef8f606073e9acfa",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "ampasindavakely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0034221,
          lng: 47.5324525,
        },
        _id: "64f75285ef8f606073e9acfb",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "ampasibe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9903287,
          lng: 47.5766661,
        },
        _id: "64f75285ef8f606073e9acfc",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "antsamala",
        __v: 0,
      },
      {
        coords: {
          lat: -15.047528,
          lng: 47.3412918,
        },
        _id: "64f75285ef8f606073e9acfd",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "ankinganomby",
        __v: 0,
      },
      {
        coords: {
          lat: -15.03205,
          lng: 47.22522,
        },
        _id: "64f75285ef8f606073e9acfe",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "anjajavy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.033333,
          lng: 47.55,
        },
        _id: "64f75285ef8f606073e9acff",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "anjiajia",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0460354,
          lng: 47.5766661,
        },
        _id: "64f75285ef8f606073e9ad00",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "tsaratanimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0654126,
          lng: 47.2386501,
        },
        _id: "64f75285ef8f606073e9ad01",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "antsangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.096487,
          lng: 47.2728413,
        },
        _id: "64f75285ef8f606073e9ad02",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "amboaboaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1116779,
          lng: 47.2386501,
        },
        _id: "64f75285ef8f606073e9ad03",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1022557,
          lng: 47.4980899,
        },
        _id: "64f75285ef8f606073e9ad04",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "anantaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.115324,
          lng: 47.1947232,
        },
        _id: "64f75285ef8f606073e9ad05",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "andovoka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.167451,
          lng: 47.4588461,
        },
        _id: "64f75285ef8f606073e9ad06",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "antantiloky",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1705708,
          lng: 47.2728413,
        },
        _id: "64f75285ef8f606073e9ad07",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "tsianifera",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2079179,
          lng: 47.4931828,
        },
        _id: "64f75285ef8f606073e9ad08",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "ambodibonara",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2124716,
          lng: 47.512814,
        },
        _id: "64f75285ef8f606073e9ad09",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "ampondrabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2463095,
          lng: 47.4784643,
        },
        _id: "64f75285ef8f606073e9ad0a",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "angoaka nord",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2049233,
          lng: 47.380447,
        },
        _id: "64f75285ef8f606073e9ad0b",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "antonibe",
        fokontany: "miadana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.30934,
          lng: 47.28582,
        },
        _id: "64f75285ef8f606073e9ad0c",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "mahadrodroka",
        fokontany: "mahadrodroka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.242694,
          lng: 47.1459597,
        },
        _id: "64f75285ef8f606073e9ad0d",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "mahadrodroka",
        fokontany: "manara",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2791716,
          lng: 47.380447,
        },
        _id: "64f75285ef8f606073e9ad0e",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "mahadrodroka",
        fokontany: "andrafianijingo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.279721,
          lng: 47.1459597,
        },
        _id: "64f75285ef8f606073e9ad0f",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "mahadrodroka",
        fokontany: "antanamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2885681,
          lng: 47.3412918,
        },
        _id: "64f75285ef8f606073e9ad10",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "mahadrodroka",
        fokontany: "ambalabanty",
        __v: 0,
      },
      {
        coords: {
          lat: -15.321368,
          lng: 47.1654595,
        },
        _id: "64f75285ef8f606073e9ad11",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "mahadrodroka",
        fokontany: "langany",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3444068,
          lng: 47.2630701,
        },
        _id: "64f75285ef8f606073e9ad12",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "mahadrodroka",
        fokontany: "anengitra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.40715,
          lng: 47.188431,
        },
        _id: "64f75285ef8f606073e9ad13",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "mahadrodroka",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4,
          lng: 47.25,
        },
        _id: "64f75285ef8f606073e9ad14",
        province: "mahajanga",
        region: "sofia",
        district: "analalava",
        commune: "mahadrodroka",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2479509,
          lng: 48.5449867,
        },
        _id: "64f75285ef8f606073e9ad15",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "befandriana nord",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2532044,
          lng: 48.5212237,
        },
        _id: "64f75285ef8f606073e9ad16",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "befandriana nord",
        fokontany: "antanambola cite",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2545537,
          lng: 48.5324786,
        },
        _id: "64f75285ef8f606073e9ad17",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "befandriana nord",
        fokontany: "manongarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2462686,
          lng: 48.5374815,
        },
        _id: "64f75285ef8f606073e9ad18",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "befandriana nord",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2514246,
          lng: 48.5499908,
        },
        _id: "64f75285ef8f606073e9ad19",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "befandriana nord",
        fokontany: "ambalanomby",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2646429,
          lng: 48.5512419,
        },
        _id: "64f75285ef8f606073e9ad1a",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "befandriana nord",
        fokontany: "tsararivotra II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2615024,
          lng: 48.5424849,
        },
        _id: "64f75285ef8f606073e9ad1b",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "befandriana nord",
        fokontany: "tsararivotra I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2560132,
          lng: 48.5402959,
        },
        _id: "64f75285ef8f606073e9ad1c",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "befandriana nord",
        fokontany: "ambatolahy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2603799,
          lng: 48.5374815,
        },
        _id: "64f75285ef8f606073e9ad1d",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "befandriana nord",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.483333,
          lng: 48.2,
        },
        _id: "64f75285ef8f606073e9ad1e",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -15.133333,
          lng: 48.283333,
        },
        _id: "64f75285ef8f606073e9ad1f",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "ankobakobaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0857386,
          lng: 48.3289951,
        },
        _id: "64f75285ef8f606073e9ad20",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "andebodatsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.17011,
          lng: 48.34037,
        },
        _id: "64f75285ef8f606073e9ad21",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "ambodimotso bas",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2072309,
          lng: 48.36888339999999,
        },
        _id: "64f75285ef8f606073e9ad22",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "ambodimotso haut",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1455737,
          lng: 48.4287702,
        },
        _id: "64f75285ef8f606073e9ad23",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "beromba",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1276845,
          lng: 48.4537424,
        },
        _id: "64f75285ef8f606073e9ad24",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "ambarivato ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -15.183333,
          lng: 48.466667,
        },
        _id: "64f75285ef8f606073e9ad25",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "amboronalina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.233333,
          lng: 48.45,
        },
        _id: "64f75285ef8f606073e9ad26",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "antsirasira",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2153573,
          lng: 48.4887224,
        },
        _id: "64f75285ef8f606073e9ad27",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "andranomanintsy bas",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1996931,
          lng: 48.5237246,
        },
        _id: "64f75285ef8f606073e9ad28",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "ambohimanakana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.166667,
          lng: 48.566667,
        },
        _id: "64f75285ef8f606073e9ad29",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "tsianontoana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2391067,
          lng: 48.57376619999999,
        },
        _id: "64f75285ef8f606073e9ad2a",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "maroantety",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2425768,
          lng: 48.552493,
        },
        _id: "64f75285ef8f606073e9ad2b",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "zafilambaina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2330546,
          lng: 48.5362308,
        },
        _id: "64f75285ef8f606073e9ad2c",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "ampozavondraka haut",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2355155,
          lng: 48.5262256,
        },
        _id: "64f75285ef8f606073e9ad2d",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "ampozavondraka bas",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2659406,
          lng: 48.4837239,
        },
        _id: "64f75285ef8f606073e9ad2e",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "antananambo II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2794258,
          lng: 48.5437358,
        },
        _id: "64f75285ef8f606073e9ad2f",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2633045,
          lng: 48.56625709999999,
        },
        _id: "64f75285ef8f606073e9ad30",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "antanambe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2754148,
          lng: 48.5887875,
        },
        _id: "64f75285ef8f606073e9ad31",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "ambodiamontana ankazambo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3049968,
          lng: 48.57376619999999,
        },
        _id: "64f75285ef8f606073e9ad32",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "ambodimanga ankazambo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3327136,
          lng: 48.5087209,
        },
        _id: "64f75285ef8f606073e9ad33",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "ambilomavo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3048802,
          lng: 48.4687311,
        },
        _id: "64f75285ef8f606073e9ad34",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "ambahivahy bas",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2635587,
          lng: 48.36888339999999,
        },
        _id: "64f75285ef8f606073e9ad35",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "ambahivahy haut",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3373979,
          lng: 48.383849,
        },
        _id: "64f75285ef8f606073e9ad36",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "tsinjomorona zafindravoay",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3772604,
          lng: 48.3938283,
        },
        _id: "64f75285ef8f606073e9ad37",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "androtro",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3487025,
          lng: 48.43376380000001,
        },
        _id: "64f75285ef8f606073e9ad38",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "antanambao sahavoanina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3483361,
          lng: 48.4737282,
        },
        _id: "64f75285ef8f606073e9ad39",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "ambodiharahara",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3837104,
          lng: 48.4637344,
        },
        _id: "64f75285ef8f606073e9ad3a",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "ambalantsotry haut",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4087088,
          lng: 48.4287702,
        },
        _id: "64f75285ef8f606073e9ad3b",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "morafeno",
        fokontany: "ambalantsotry bas",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4189829,
          lng: 48.598804,
        },
        _id: "64f75285ef8f606073e9ad3c",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambodimotso sud",
        fokontany: "ambodimotso sud",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3332465,
          lng: 48.57376619999999,
        },
        _id: "64f75285ef8f606073e9ad3d",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambodimotso sud",
        fokontany: "ampotamainty",
        __v: 0,
      },
      {
        coords: {
          lat: -15.375825,
          lng: 48.5537441,
        },
        _id: "64f75285ef8f606073e9ad3e",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambodimotso sud",
        fokontany: "marolampy haut",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3748834,
          lng: 48.5287267,
        },
        _id: "64f75285ef8f606073e9ad3f",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambodimotso sud",
        fokontany: "marolampy bas",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3964537,
          lng: 48.5512419,
        },
        _id: "64f75285ef8f606073e9ad40",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambodimotso sud",
        fokontany: "marohazo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3871223,
          lng: 48.6038129,
        },
        _id: "64f75285ef8f606073e9ad41",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambodimotso sud",
        fokontany: "fanimana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4118186,
          lng: 48.6088222,
        },
        _id: "64f75285ef8f606073e9ad42",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambodimotso sud",
        fokontany: "antsambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4313336,
          lng: 48.6013084,
        },
        _id: "64f75285ef8f606073e9ad43",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambodimotso sud",
        fokontany: "ankazomirafy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4407622,
          lng: 48.6013084,
        },
        _id: "64f75285ef8f606073e9ad44",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambodimotso sud",
        fokontany: "benara",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4683972,
          lng: 48.6088222,
        },
        _id: "64f75285ef8f606073e9ad45",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambodimotso sud",
        fokontany: "mazava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4826992,
          lng: 48.6251058,
        },
        _id: "64f75285ef8f606073e9ad46",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambodimotso sud",
        fokontany: "tsifohana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5009289,
          lng: 48.5962997,
        },
        _id: "64f75285ef8f606073e9ad47",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambodimotso sud",
        fokontany: "pont sofia avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.983888,
          lng: 48.4187846,
        },
        _id: "64f75285ef8f606073e9ad48",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsiamalao",
        fokontany: "tsiamalao",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9193704,
          lng: 48.5087209,
        },
        _id: "64f75285ef8f606073e9ad49",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsiamalao",
        fokontany: "andahono",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9160918,
          lng: 48.4088007,
        },
        _id: "64f75285ef8f606073e9ad4a",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsiamalao",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9661847,
          lng: 48.33896439999999,
        },
        _id: "64f75285ef8f606073e9ad4b",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsiamalao",
        fokontany: "antafiambe bejofo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9753558,
          lng: 48.4437522,
        },
        _id: "64f75285ef8f606073e9ad4c",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsiamalao",
        fokontany: "ambodibonara nord",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9654388,
          lng: 48.4837239,
        },
        _id: "64f75285ef8f606073e9ad4d",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsiamalao",
        fokontany: "antaly",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9626796,
          lng: 48.5137217,
        },
        _id: "64f75285ef8f606073e9ad4e",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsiamalao",
        fokontany: "marerano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9687239,
          lng: 48.41379240000001,
        },
        _id: "64f75285ef8f606073e9ad4f",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsiamalao",
        fokontany: "andrasarasa sud",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0072164,
          lng: 48.3339796,
        },
        _id: "64f75285ef8f606073e9ad50",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsiamalao",
        fokontany: "rangodona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0097941,
          lng: 48.4088007,
        },
        _id: "64f75285ef8f606073e9ad51",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsiamalao",
        fokontany: "ankarakaraka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0571558,
          lng: 48.36888339999999,
        },
        _id: "64f75285ef8f606073e9ad52",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsiamalao",
        fokontany: "ambatomarako",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0530962,
          lng: 48.41379240000001,
        },
        _id: "64f75285ef8f606073e9ad53",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsiamalao",
        fokontany: "ambodibonara sud",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0606239,
          lng: 48.4687311,
        },
        _id: "64f75285ef8f606073e9ad54",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsiamalao",
        fokontany: "bemarambonga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.103573,
          lng: 48.4088007,
        },
        _id: "64f75285ef8f606073e9ad55",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsiamalao",
        fokontany: "analaila",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0698224,
          lng: 48.4362607,
        },
        _id: "64f75285ef8f606073e9ad56",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsiamalao",
        fokontany: "antanampizina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0968128,
          lng: 48.4837239,
        },
        _id: "64f75285ef8f606073e9ad57",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsiamalao",
        fokontany: "antanamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1258589,
          lng: 48.5087209,
        },
        _id: "64f75285ef8f606073e9ad58",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsiamalao",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1634419,
          lng: 48.5087209,
        },
        _id: "64f75285ef8f606073e9ad59",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsiamalao",
        fokontany: "andranovo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2861907,
          lng: 48.6790007,
        },
        _id: "64f75285ef8f606073e9ad5a",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "maroamalona",
        fokontany: "maroamalona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2843941,
          lng: 48.6288642,
        },
        _id: "64f75285ef8f606073e9ad5b",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "maroamalona",
        fokontany: "antananambo I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3220791,
          lng: 48.6288642,
        },
        _id: "64f75285ef8f606073e9ad5c",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "maroamalona",
        fokontany: "ambodimadiro",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2512003,
          lng: 48.6489134,
        },
        _id: "64f75285ef8f606073e9ad5d",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "maroamalona",
        fokontany: "miandrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2834906,
          lng: 48.7091042,
        },
        _id: "64f75285ef8f606073e9ad5e",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "maroamalona",
        fokontany: "samotretry bas",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3158105,
          lng: 48.76936,
        },
        _id: "64f75285ef8f606073e9ad5f",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "maroamalona",
        fokontany: "maetsamena",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3684965,
          lng: 48.72541709999999,
        },
        _id: "64f75285ef8f606073e9ad60",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "maroamalona",
        fokontany: "ambalafotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4110406,
          lng: 48.6890334,
        },
        _id: "64f75285ef8f606073e9ad61",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "maroamalona",
        fokontany: "soamiangona bas",
        __v: 0,
      },
      {
        coords: {
          lat: -15.47878,
          lng: 48.46764,
        },
        _id: "64f75285ef8f606073e9ad62",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3927666,
          lng: 48.5037206,
        },
        _id: "64f75285ef8f606073e9ad63",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "betaimby",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4223206,
          lng: 48.4887224,
        },
        _id: "64f75285ef8f606073e9ad64",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "malainkolemby",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4313934,
          lng: 48.5287267,
        },
        _id: "64f75285ef8f606073e9ad65",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "ambohimitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4580376,
          lng: 48.5112213,
        },
        _id: "64f75285ef8f606073e9ad66",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "antsatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4564157,
          lng: 48.4937213,
        },
        _id: "64f75285ef8f606073e9ad67",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "ambodivoarahely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4417989,
          lng: 48.4088007,
        },
        _id: "64f75285ef8f606073e9ad68",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "ambato",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4601418,
          lng: 48.541234,
        },
        _id: "64f75285ef8f606073e9ad69",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "farafanga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4772034,
          lng: 48.5437358,
        },
        _id: "64f75285ef8f606073e9ad6a",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "ambiky",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4719584,
          lng: 48.3863436,
        },
        _id: "64f75285ef8f606073e9ad6b",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "antananambo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5125215,
          lng: 48.3888384,
        },
        _id: "64f75285ef8f606073e9ad6c",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "andrafiatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -15.483333,
          lng: 48.366667,
        },
        _id: "64f75285ef8f606073e9ad6d",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "betsitindry",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5028256,
          lng: 48.4287702,
        },
        _id: "64f75285ef8f606073e9ad6e",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "andambalo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5038196,
          lng: 48.4537424,
        },
        _id: "64f75285ef8f606073e9ad6f",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "ambodisely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.510038,
          lng: 48.5637543,
        },
        _id: "64f75285ef8f606073e9ad70",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "antsahalemaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5067808,
          lng: 48.5287267,
        },
        _id: "64f75285ef8f606073e9ad71",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "maevahiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5106608,
          lng: 48.4837239,
        },
        _id: "64f75285ef8f606073e9ad72",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "antsakoamivanditra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5249401,
          lng: 48.4637344,
        },
        _id: "64f75285ef8f606073e9ad73",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "ambovobe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5289001,
          lng: 48.5637543,
        },
        _id: "64f75285ef8f606073e9ad74",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "ambatomironkona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5258643,
          lng: 48.4162884,
        },
        _id: "64f75285ef8f606073e9ad75",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "ambobaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5326221,
          lng: 48.3738715,
        },
        _id: "64f75285ef8f606073e9ad76",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "ambodimanarihely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5386362,
          lng: 48.5237246,
        },
        _id: "64f75285ef8f606073e9ad77",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "antsahono",
        __v: 0,
      },
      {
        coords: {
          lat: -15.539484,
          lng: 48.4038094,
        },
        _id: "64f75285ef8f606073e9ad78",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "ambalihabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5562779,
          lng: 48.3539219,
        },
        _id: "64f75285ef8f606073e9ad79",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "beronono",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5360025,
          lng: 48.4812248,
        },
        _id: "64f75285ef8f606073e9ad7a",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "andranovato",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5631516,
          lng: 48.383849,
        },
        _id: "64f75285ef8f606073e9ad7b",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "anantaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5738366,
          lng: 48.4612362,
        },
        _id: "64f75285ef8f606073e9ad7c",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "betomorona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5593304,
          lng: 48.4287702,
        },
        _id: "64f75285ef8f606073e9ad7d",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "ambalafary",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5633517,
          lng: 48.5287267,
        },
        _id: "64f75285ef8f606073e9ad7e",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "beriana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.592288,
          lng: 48.5212237,
        },
        _id: "64f75285ef8f606073e9ad7f",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "antanambao haut",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5931122,
          lng: 48.5299773,
        },
        _id: "64f75285ef8f606073e9ad80",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "antanambao bas beriana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6016021,
          lng: 48.448747,
        },
        _id: "64f75285ef8f606073e9ad81",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "ambohitsara II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5860583,
          lng: 48.4837239,
        },
        _id: "64f75285ef8f606073e9ad82",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "ambodimotso belo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6066988,
          lng: 48.4812248,
        },
        _id: "64f75285ef8f606073e9ad83",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "belo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.633333,
          lng: 48.5,
        },
        _id: "64f75285ef8f606073e9ad84",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "bejabora",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6352492,
          lng: 48.5337293,
        },
        _id: "64f75285ef8f606073e9ad85",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "tsarahonenana",
        fokontany: "anketrakabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4216221,
          lng: 48.2791757,
        },
        _id: "64f75285ef8f606073e9ad86",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakanalabe",
        fokontany: "antsakanalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2410362,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e9ad87",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakanalabe",
        fokontany: "ambodimandresy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.283333,
          lng: 48.3,
        },
        _id: "64f75285ef8f606073e9ad88",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakanalabe",
        fokontany: "ambarijeby",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3053979,
          lng: 48.2841556,
        },
        _id: "64f75285ef8f606073e9ad89",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakanalabe",
        fokontany: "andohaniambala",
        __v: 0,
      },
      {
        coords: {
          lat: -15.311556,
          lng: 48.24930579999999,
        },
        _id: "64f75285ef8f606073e9ad8a",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakanalabe",
        fokontany: "maevalakana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3529689,
          lng: 48.3489356,
        },
        _id: "64f75285ef8f606073e9ad8b",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakanalabe",
        fokontany: "anengitra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4002639,
          lng: 48.3090619,
        },
        _id: "64f75285ef8f606073e9ad8c",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakanalabe",
        fokontany: "ankijanibe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4201566,
          lng: 48.14985799999999,
        },
        _id: "64f75285ef8f606073e9ad8d",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakanalabe",
        fokontany: "ambodisakoana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.426735,
          lng: 48.2194523,
        },
        _id: "64f75285ef8f606073e9ad8e",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakanalabe",
        fokontany: "antsanira",
        __v: 0,
      },
      {
        coords: {
          lat: -15.466667,
          lng: 48.09999999999999,
        },
        _id: "64f75285ef8f606073e9ad8f",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakanalabe",
        fokontany: "analanambe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5018535,
          lng: 48.2194523,
        },
        _id: "64f75285ef8f606073e9ad90",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakanalabe",
        fokontany: "ambalavelombe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5272784,
          lng: 48.2891359,
        },
        _id: "64f75285ef8f606073e9ad91",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakanalabe",
        fokontany: "mahalina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0520024,
          lng: 48.5462377,
        },
        _id: "64f75285ef8f606073e9ad92",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambararata",
        fokontany: "ambararata I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0131829,
          lng: 48.5087209,
        },
        _id: "64f75285ef8f606073e9ad93",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambararata",
        fokontany: "ambalamanary",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0240964,
          lng: 48.5262256,
        },
        _id: "64f75285ef8f606073e9ad94",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambararata",
        fokontany: "andilamboay",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9902783,
          lng: 48.5537441,
        },
        _id: "64f75285ef8f606073e9ad95",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambararata",
        fokontany: "antanambe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.04432,
          lng: 48.54284999999999,
        },
        _id: "64f75285ef8f606073e9ad96",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambararata",
        fokontany: "ambararata II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9745284,
          lng: 48.5887875,
        },
        _id: "64f75285ef8f606073e9ad97",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambararata",
        fokontany: "ambodimadiro nord",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0438717,
          lng: 48.5837799,
        },
        _id: "64f75285ef8f606073e9ad98",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambararata",
        fokontany: "betaimboraka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9993606,
          lng: 48.6589406,
        },
        _id: "64f75285ef8f606073e9ad99",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambararata",
        fokontany: "ambingivato",
        __v: 0,
      },
      {
        coords: {
          lat: -15.02527,
          lng: 48.734791,
        },
        _id: "64f75285ef8f606073e9ad9a",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambararata",
        fokontany: "andampimena",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0582504,
          lng: 48.7342029,
        },
        _id: "64f75285ef8f606073e9ad9b",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambararata",
        fokontany: "antsoriabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0969941,
          lng: 48.6539268,
        },
        _id: "64f75285ef8f606073e9ad9c",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambararata",
        fokontany: "ankofio haut",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0729067,
          lng: 48.6088222,
        },
        _id: "64f75285ef8f606073e9ad9d",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambararata",
        fokontany: "ambodimadiro est",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0676256,
          lng: 48.5637543,
        },
        _id: "64f75285ef8f606073e9ad9e",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambararata",
        fokontany: "mahadera",
        __v: 0,
      },
      {
        coords: {
          lat: -15.098543,
          lng: 48.5337293,
        },
        _id: "64f75285ef8f606073e9ad9f",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambararata",
        fokontany: "berentsina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1204245,
          lng: 48.56876,
        },
        _id: "64f75285ef8f606073e9ada0",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambararata",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.11496,
          lng: 48.6288642,
        },
        _id: "64f75285ef8f606073e9ada1",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambararata",
        fokontany: "ankofio bas",
        __v: 0,
      },
      {
        coords: {
          lat: -15.09435,
          lng: 48.795898,
        },
        _id: "64f75285ef8f606073e9ada2",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambolidibe est",
        fokontany: "ambolidibe est",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9128956,
          lng: 48.7794089,
        },
        _id: "64f75285ef8f606073e9ada3",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambolidibe est",
        fokontany: "beanala",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8650065,
          lng: 48.6890334,
        },
        _id: "64f75285ef8f606073e9ada4",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambolidibe est",
        fokontany: "benara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9622723,
          lng: 48.8548333,
        },
        _id: "64f75285ef8f606073e9ada5",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambolidibe est",
        fokontany: "mangankana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0274218,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9ada6",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambolidibe est",
        fokontany: "maetsamainty",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0897773,
          lng: 48.8347102,
        },
        _id: "64f75285ef8f606073e9ada7",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambolidibe est",
        fokontany: "ambilany",
        __v: 0,
      },
      {
        coords: {
          lat: -15.098795,
          lng: 48.8221369,
        },
        _id: "64f75285ef8f606073e9ada8",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambolidibe est",
        fokontany: "ambalakofafa",
        __v: 0,
      },
      {
        coords: {
          lat: -15.130817,
          lng: 48.7643362,
        },
        _id: "64f75285ef8f606073e9ada9",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambolidibe est",
        fokontany: "bemanevika haut",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0899955,
          lng: 48.9001364,
        },
        _id: "64f75285ef8f606073e9adaa",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambolidibe est",
        fokontany: "ambodisikidy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1139034,
          lng: 48.7091042,
        },
        _id: "64f75285ef8f606073e9adab",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambolidibe est",
        fokontany: "sahantona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1682654,
          lng: 48.86993030000001,
        },
        _id: "64f75285ef8f606073e9adac",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambolidibe est",
        fokontany: "sahalafa haut",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1604634,
          lng: 48.74926749999999,
        },
        _id: "64f75285ef8f606073e9adad",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambolidibe est",
        fokontany: "ankosibe bas",
        __v: 0,
      },
      {
        coords: {
          lat: -15.19317,
          lng: 48.696541,
        },
        _id: "64f75285ef8f606073e9adae",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambolidibe est",
        fokontany: "ambodiamontana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2115301,
          lng: 48.8095665,
        },
        _id: "64f75285ef8f606073e9adaf",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ambolidibe est",
        fokontany: "ambinaninantakotako",
        __v: 0,
      },
      {
        coords: {
          lat: -15.416667,
          lng: 48.9166669,
        },
        _id: "64f75285ef8f606073e9adb0",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "ankarongana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2410232,
          lng: 48.9001364,
        },
        _id: "64f75285ef8f606073e9adb1",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "antsatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2371557,
          lng: 49.0110305,
        },
        _id: "64f75285ef8f606073e9adb2",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "ambatonaisodrano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2749887,
          lng: 49.0110305,
        },
        _id: "64f75285ef8f606073e9adb3",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "antsiradrano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2660738,
          lng: 48.9706803,
        },
        _id: "64f75285ef8f606073e9adb4",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "ambodifamba",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2734804,
          lng: 48.8548333,
        },
        _id: "64f75285ef8f606073e9adb5",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "ambaladera",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2739803,
          lng: 48.8145943,
        },
        _id: "64f75285ef8f606073e9adb6",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "maroadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3164479,
          lng: 49.0059852,
        },
        _id: "64f75285ef8f606073e9adb7",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "ambohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3093953,
          lng: 48.9102087,
        },
        _id: "64f75285ef8f606073e9adb8",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "lohaniromba",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3309007,
          lng: 48.9858082,
        },
        _id: "64f75285ef8f606073e9adb9",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3183426,
          lng: 48.9505159,
        },
        _id: "64f75285ef8f606073e9adba",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "antanantanana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.329269,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9adbb",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "marivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.338627,
          lng: 48.9353974,
        },
        _id: "64f75285ef8f606073e9adbc",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "ambohimahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3620264,
          lng: 48.9555563,
        },
        _id: "64f75285ef8f606073e9adbd",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "tsarabajina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3582244,
          lng: 48.9278396,
        },
        _id: "64f75285ef8f606073e9adbe",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "mainampango",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3859197,
          lng: 48.9353974,
        },
        _id: "64f75285ef8f606073e9adbf",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "malamamaina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3724471,
          lng: 48.76936,
        },
        _id: "64f75285ef8f606073e9adc0",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "anjozoro",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3985129,
          lng: 48.9706803,
        },
        _id: "64f75285ef8f606073e9adc1",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "andilambe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3805386,
          lng: 48.8900659,
        },
        _id: "64f75285ef8f606073e9adc2",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "fandrakotra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4093556,
          lng: 48.84980179999999,
        },
        _id: "64f75285ef8f606073e9adc3",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "ambodimadiro sud",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4102197,
          lng: 48.76936,
        },
        _id: "64f75285ef8f606073e9adc4",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "maromandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4318763,
          lng: 48.9505159,
        },
        _id: "64f75285ef8f606073e9adc5",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "ambodirengitra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4521454,
          lng: 48.8825142,
        },
        _id: "64f75285ef8f606073e9adc6",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "ambinanikiajobo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4674655,
          lng: 48.8875486,
        },
        _id: "64f75285ef8f606073e9adc7",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "matahitiry",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4841645,
          lng: 48.9303587,
        },
        _id: "64f75285ef8f606073e9adc8",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "ankarongana",
        fokontany: "ankijanilava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.04375,
          lng: 48.931622,
        },
        _id: "64f75285ef8f606073e9adc9",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakabary",
        fokontany: "antsakabary",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9712282,
          lng: 49.0261692,
        },
        _id: "64f75285ef8f606073e9adca",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakabary",
        fokontany: "ankosilava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9783883,
          lng: 48.9505159,
        },
        _id: "64f75285ef8f606073e9adcb",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakabary",
        fokontany: "bezono",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0279571,
          lng: 49.0918169,
        },
        _id: "64f75285ef8f606073e9adcc",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakabary",
        fokontany: "ambendrana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0846619,
          lng: 49.0918169,
        },
        _id: "64f75285ef8f606073e9adcd",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakabary",
        fokontany: "andrafia",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0590345,
          lng: 48.99589580000001,
        },
        _id: "64f75285ef8f606073e9adce",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakabary",
        fokontany: "ambodifinesy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1017399,
          lng: 48.9429561,
        },
        _id: "64f75285ef8f606073e9adcf",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakabary",
        fokontany: "ambinanindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0970909,
          lng: 49.127197,
        },
        _id: "64f75285ef8f606073e9add0",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakabary",
        fokontany: "ambodisikidy II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0873605,
          lng: 48.99589580000001,
        },
        _id: "64f75285ef8f606073e9add1",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakabary",
        fokontany: "ankisingy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1561698,
          lng: 48.932878,
        },
        _id: "64f75285ef8f606073e9add2",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakabary",
        fokontany: "ambalavary",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1401189,
          lng: 49.1727178,
        },
        _id: "64f75285ef8f606073e9add3",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakabary",
        fokontany: "ambohimitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2004722,
          lng: 48.9303587,
        },
        _id: "64f75285ef8f606073e9add4",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakabary",
        fokontany: "ankazomeva",
        __v: 0,
      },
      {
        coords: {
          lat: -15.128146,
          lng: 49.0312164,
        },
        _id: "64f75285ef8f606073e9add5",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakabary",
        fokontany: "sinjabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.18664,
          lng: 48.97145099999999,
        },
        _id: "64f75285ef8f606073e9add6",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakabary",
        fokontany: "marovotry",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2073501,
          lng: 49.2638678,
        },
        _id: "64f75285ef8f606073e9add7",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakabary",
        fokontany: "lavavozona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2009666,
          lng: 49.0615086,
        },
        _id: "64f75285ef8f606073e9add8",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakabary",
        fokontany: "andranonahoatra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.221515,
          lng: 49.1120314,
        },
        _id: "64f75285ef8f606073e9add9",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakabary",
        fokontany: "antafiambe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2944749,
          lng: 49.1423666,
        },
        _id: "64f75285ef8f606073e9adda",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "antsakabary",
        fokontany: "andapanomby",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8519428,
          lng: 49.284143,
        },
        _id: "64f75285ef8f606073e9addb",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "matsondakana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.77077,
          lng: 49.257549,
        },
        _id: "64f75285ef8f606073e9addc",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "antetezantany",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8187144,
          lng: 49.1727178,
        },
        _id: "64f75285ef8f606073e9addd",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "ambodimanga bemarambonga",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7716157,
          lng: 49.3957836,
        },
        _id: "64f75285ef8f606073e9adde",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "nosimbendrana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.770333,
          lng: 49.34501119999999,
        },
        _id: "64f75285ef8f606073e9addf",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "antanibe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.811305,
          lng: 49.2486661,
        },
        _id: "64f75285ef8f606073e9ade0",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7802029,
          lng: 49.4364335,
        },
        _id: "64f75285ef8f606073e9ade1",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "manandriana station",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8812251,
          lng: 49.1120314,
        },
        _id: "64f75285ef8f606073e9ade2",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "antsahabe L",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8549074,
          lng: 49.2537329,
        },
        _id: "64f75285ef8f606073e9ade3",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "ambalapaiso",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8722123,
          lng: 49.3348621,
        },
        _id: "64f75285ef8f606073e9ade4",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "belalona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8516307,
          lng: 49.41610499999999,
        },
        _id: "64f75285ef8f606073e9ade5",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "ambavala",
        __v: 0,
      },
      {
        coords: {
          lat: -14.903448,
          lng: 49.37039189999999,
        },
        _id: "64f75285ef8f606073e9ade6",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "marojao",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8651468,
          lng: 49.2132111,
        },
        _id: "64f75285ef8f606073e9ade7",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "antananivo ambatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9215819,
          lng: 49.411024,
        },
        _id: "64f75285ef8f606073e9ade8",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "amparihy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9131521,
          lng: 49.1727178,
        },
        _id: "64f75285ef8f606073e9ade9",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "ambalarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9421768,
          lng: 49.2638678,
        },
        _id: "64f75285ef8f606073e9adea",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "antanambaobe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9916553,
          lng: 49.1423666,
        },
        _id: "64f75285ef8f606073e9adeb",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "ambohitsara I",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9566186,
          lng: 49.3754694,
        },
        _id: "64f75285ef8f606073e9adec",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "bemanevika bas",
        __v: 0,
      },
      {
        coords: {
          lat: -15.05,
          lng: 49.183333,
        },
        _id: "64f75285ef8f606073e9aded",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "analavory",
        __v: 0,
      },
      {
        coords: {
          lat: -15.051384,
          lng: 49.3754694,
        },
        _id: "64f75285ef8f606073e9adee",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0921225,
          lng: 49.2132111,
        },
        _id: "64f75285ef8f606073e9adef",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "lanjarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1286452,
          lng: 49.2942833,
        },
        _id: "64f75285ef8f606073e9adf0",
        province: "mahajanga",
        region: "sofia",
        district: "befandriana nord",
        commune: "matsondakana",
        fokontany: "ambodisikidy I",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8792505,
          lng: 47.9880251,
        },
        _id: "64f75285ef8f606073e9adf1",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsohihy",
        fokontany: "haute ville",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8721529,
          lng: 47.9849296,
        },
        _id: "64f75285ef8f606073e9adf2",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsohihy",
        fokontany: "antafiantsivakina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8664899,
          lng: 47.9960743,
        },
        _id: "64f75285ef8f606073e9adf3",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsohihy",
        fokontany: "ankiririky nord",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8778139,
          lng: 47.9737873,
        },
        _id: "64f75285ef8f606073e9adf4",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsohihy",
        fokontany: "ambalakida",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8873417,
          lng: 48.00598249999999,
        },
        _id: "64f75285ef8f606073e9adf5",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsohihy",
        fokontany: "ankiririky sud",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8973305,
          lng: 47.99855119999999,
        },
        _id: "64f75285ef8f606073e9adf6",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsohihy",
        fokontany: "ambalabe est",
        __v: 0,
      },
      {
        coords: {
          lat: -14.898665,
          lng: 47.98369150000001,
        },
        _id: "64f75285ef8f606073e9adf7",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsohihy",
        fokontany: "ambalabe ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -14.97155,
          lng: 47.868309,
        },
        _id: "64f75285ef8f606073e9adf8",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ankerika",
        fokontany: "ankerika centre",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8315114,
          lng: 47.9020367,
        },
        _id: "64f75285ef8f606073e9adf9",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ankerika",
        fokontany: "ambiky",
        __v: 0,
      },
      {
        coords: {
          lat: -14.856762,
          lng: 47.8279133,
        },
        _id: "64f75285ef8f606073e9adfa",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ankerika",
        fokontany: "ampanihira",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8758262,
          lng: 47.8921477,
        },
        _id: "64f75285ef8f606073e9adfb",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ankerika",
        fokontany: "antanimbaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9,
          lng: 47.933333,
        },
        _id: "64f75285ef8f606073e9adfc",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ankerika",
        fokontany: "antsaonjo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9227822,
          lng: 47.8526097,
        },
        _id: "64f75285ef8f606073e9adfd",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ankerika",
        fokontany: "androibe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9206254,
          lng: 47.9465602,
        },
        _id: "64f75285ef8f606073e9adfe",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ankerika",
        fokontany: "anjiajia",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9512185,
          lng: 47.9168738,
        },
        _id: "64f75285ef8f606073e9adff",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ankerika",
        fokontany: "antafiandakana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9445379,
          lng: 47.88720379999999,
        },
        _id: "64f75285ef8f606073e9ae00",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ankerika",
        fokontany: "ankerika nord",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9510936,
          lng: 47.813101,
        },
        _id: "64f75285ef8f606073e9ae01",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ankerika",
        fokontany: "ankerika sud",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9850616,
          lng: 47.8501395,
        },
        _id: "64f75285ef8f606073e9ae02",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ankerika",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9940908,
          lng: 47.81803800000001,
        },
        _id: "64f75285ef8f606073e9ae03",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ankerika",
        fokontany: "anjobony",
        __v: 0,
      },
      {
        coords: {
          lat: -14.993047,
          lng: 48.0406759,
        },
        _id: "64f75285ef8f606073e9ae04",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ampandriankilandy",
        fokontany: "ampandriankilandy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8606239,
          lng: 48.0952394,
        },
        _id: "64f75285ef8f606073e9ae05",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ampandriankilandy",
        fokontany: "ankazobetsihay",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8814918,
          lng: 48.1051659,
        },
        _id: "64f75285ef8f606073e9ae06",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ampandriankilandy",
        fokontany: "antanalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8935484,
          lng: 48.02332639999999,
        },
        _id: "64f75285ef8f606073e9ae07",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ampandriankilandy",
        fokontany: "mahialambo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9059278,
          lng: 48.1101299,
        },
        _id: "64f75285ef8f606073e9ae08",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ampandriankilandy",
        fokontany: "andrafia",
        __v: 0,
      },
      {
        coords: {
          lat: -14.934388,
          lng: 47.9663604,
        },
        _id: "64f75285ef8f606073e9ae09",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ampandriankilandy",
        fokontany: "matsaboribe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9339536,
          lng: 48.00598249999999,
        },
        _id: "64f75285ef8f606073e9ae0a",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ampandriankilandy",
        fokontany: "marosampanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9406169,
          lng: 48.0357184,
        },
        _id: "64f75285ef8f606073e9ae0b",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ampandriankilandy",
        fokontany: "ambodisatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9663798,
          lng: 48.0258046,
        },
        _id: "64f75285ef8f606073e9ae0c",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ampandriankilandy",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9637215,
          lng: 48.0555514,
        },
        _id: "64f75285ef8f606073e9ae0d",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ampandriankilandy",
        fokontany: "ambinaninandranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9703914,
          lng: 48.0853146,
        },
        _id: "64f75285ef8f606073e9ae0e",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ampandriankilandy",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9668045,
          lng: 47.9861678,
        },
        _id: "64f75285ef8f606073e9ae0f",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ampandriankilandy",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9974157,
          lng: 47.95645940000001,
        },
        _id: "64f75285ef8f606073e9ae10",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ampandriankilandy",
        fokontany: "amboroho ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0046094,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e9ae11",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ampandriankilandy",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0330184,
          lng: 48.0109374,
        },
        _id: "64f75285ef8f606073e9ae12",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ampandriankilandy",
        fokontany: "antanambao sud",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0464235,
          lng: 48.070431,
        },
        _id: "64f75285ef8f606073e9ae13",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ampandriankilandy",
        fokontany: "ampombinimakoalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.016667,
          lng: 48.183333,
        },
        _id: "64f75285ef8f606073e9ae14",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjalazala",
        fokontany: "anjalazala",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8831774,
          lng: 48.2916263,
        },
        _id: "64f75285ef8f606073e9ae15",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjalazala",
        fokontany: "antafiambe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9192082,
          lng: 48.169733,
        },
        _id: "64f75285ef8f606073e9ae16",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjalazala",
        fokontany: "andavadoko",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9022353,
          lng: 48.2194523,
        },
        _id: "64f75285ef8f606073e9ae17",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjalazala",
        fokontany: "betangerina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9306231,
          lng: 48.2841556,
        },
        _id: "64f75285ef8f606073e9ae18",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjalazala",
        fokontany: "ambalahady haut",
        __v: 0,
      },
      {
        coords: {
          lat: -14.96545,
          lng: 48.2095048,
        },
        _id: "64f75285ef8f606073e9ae19",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjalazala",
        fokontany: "andampinarosy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.025078,
          lng: 48.3090619,
        },
        _id: "64f75285ef8f606073e9ae1a",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjalazala",
        fokontany: "antafiandakana II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0349091,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e9ae1b",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjalazala",
        fokontany: "antsatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0585016,
          lng: 48.1448904,
        },
        _id: "64f75285ef8f606073e9ae1c",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjalazala",
        fokontany: "ankotikana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0634387,
          lng: 48.2294017,
        },
        _id: "64f75285ef8f606073e9ae1d",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjalazala",
        fokontany: "ampombilava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0906131,
          lng: 48.2045317,
        },
        _id: "64f75285ef8f606073e9ae1e",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjalazala",
        fokontany: "anjiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0550418,
          lng: 47.9020367,
        },
        _id: "64f75285ef8f606073e9ae1f",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anahidrano",
        fokontany: "anahidrano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.986257,
          lng: 47.906982,
        },
        _id: "64f75285ef8f606073e9ae20",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anahidrano",
        fokontany: "antanimora haut",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9911089,
          lng: 47.88720379999999,
        },
        _id: "64f75285ef8f606073e9ae21",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anahidrano",
        fokontany: "antanimora bas",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0300372,
          lng: 47.8328517,
        },
        _id: "64f75285ef8f606073e9ae22",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anahidrano",
        fokontany: "bevahona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.033333,
          lng: 47.95,
        },
        _id: "64f75285ef8f606073e9ae23",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anahidrano",
        fokontany: "ambiahely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0538407,
          lng: 47.96883589999999,
        },
        _id: "64f75285ef8f606073e9ae24",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anahidrano",
        fokontany: "ambodimangasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0627035,
          lng: 47.95645940000001,
        },
        _id: "64f75285ef8f606073e9ae25",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anahidrano",
        fokontany: "antanambao II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0730782,
          lng: 47.8377905,
        },
        _id: "64f75285ef8f606073e9ae26",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anahidrano",
        fokontany: "betomendry",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0739046,
          lng: 48.00598249999999,
        },
        _id: "64f75285ef8f606073e9ae27",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anahidrano",
        fokontany: "befanivana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0951835,
          lng: 47.9762632,
        },
        _id: "64f75285ef8f606073e9ae28",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anahidrano",
        fokontany: "anjiajia",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0897492,
          lng: 47.931715,
        },
        _id: "64f75285ef8f606073e9ae29",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anahidrano",
        fokontany: "ambalarano doroa",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1405699,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e9ae2a",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anahidrano",
        fokontany: "antatatra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1489512,
          lng: 47.9663604,
        },
        _id: "64f75285ef8f606073e9ae2b",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anahidrano",
        fokontany: "anjiamangotroka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.783333,
          lng: 48.116667,
        },
        _id: "64f75285ef8f606073e9ae2c",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimandresy",
        fokontany: "ambodimandresy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6912286,
          lng: 48.045634,
        },
        _id: "64f75285ef8f606073e9ae2d",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimandresy",
        fokontany: "andampy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.743533,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e9ae2e",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimandresy",
        fokontany: "antanambao nord",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7372354,
          lng: 48.0853146,
        },
        _id: "64f75285ef8f606073e9ae2f",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimandresy",
        fokontany: "zafibelaza",
        __v: 0,
      },
      {
        coords: {
          lat: -14.733333,
          lng: 48.016667,
        },
        _id: "64f75285ef8f606073e9ae30",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimandresy",
        fokontany: "andamoty",
        __v: 0,
      },
      {
        coords: {
          lat: -14.750938,
          lng: 48.1051659,
        },
        _id: "64f75285ef8f606073e9ae31",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimandresy",
        fokontany: "antanamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.770114,
          lng: 48.0654706,
        },
        _id: "64f75285ef8f606073e9ae32",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimandresy",
        fokontany: "andrafialava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.807174,
          lng: 48.169733,
        },
        _id: "64f75285ef8f606073e9ae33",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimandresy",
        fokontany: "antombodriha",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8255215,
          lng: 48.1051659,
        },
        _id: "64f75285ef8f606073e9ae34",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimandresy",
        fokontany: "ambohimandririna",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8392561,
          lng: 48.12502449999999,
        },
        _id: "64f75285ef8f606073e9ae35",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimandresy",
        fokontany: "antambohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8207682,
          lng: 48.0208484,
        },
        _id: "64f75285ef8f606073e9ae36",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimandresy",
        fokontany: "ambalarano nord",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8587124,
          lng: 48.01341499999999,
        },
        _id: "64f75285ef8f606073e9ae37",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimandresy",
        fokontany: "ambalahonko",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8489124,
          lng: 48.1896152,
        },
        _id: "64f75285ef8f606073e9ae38",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimandresy",
        fokontany: "ambodimangan'i bora",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8739952,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e9ae39",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimandresy",
        fokontany: "mangaoka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.167,
          lng: 47.778481,
        },
        _id: "64f75285ef8f606073e9ae3a",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjiamangirana",
        fokontany: "anjiamangirana I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1135704,
          lng: 47.872375,
        },
        _id: "64f75285ef8f606073e9ae3b",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjiamangirana",
        fokontany: "ankarabato",
        __v: 0,
      },
      {
        coords: {
          lat: -15.094284,
          lng: 47.8081645,
        },
        _id: "64f75285ef8f606073e9ae3c",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjiamangirana",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.096846,
          lng: 47.778555,
        },
        _id: "64f75285ef8f606073e9ae3d",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjiamangirana",
        fokontany: "ambodimanga I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.123209,
          lng: 47.8328517,
        },
        _id: "64f75285ef8f606073e9ae3e",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjiamangirana",
        fokontany: "ambodibonara",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1408731,
          lng: 47.8081645,
        },
        _id: "64f75285ef8f606073e9ae3f",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjiamangirana",
        fokontany: "antanambao I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1557138,
          lng: 47.9242939,
        },
        _id: "64f75285ef8f606073e9ae40",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjiamangirana",
        fokontany: "maroadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1634338,
          lng: 47.906982,
        },
        _id: "64f75285ef8f606073e9ae41",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjiamangirana",
        fokontany: "antanambao II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.165015,
          lng: 47.8526097,
        },
        _id: "64f75285ef8f606073e9ae42",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjiamangirana",
        fokontany: "anjiamangirana II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.22211,
          lng: 47.912239,
        },
        _id: "64f75285ef8f606073e9ae43",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjiamangirana",
        fokontany: "andalirano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2356087,
          lng: 47.7538931,
        },
        _id: "64f75285ef8f606073e9ae44",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjiamangirana",
        fokontany: "ambodivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2492345,
          lng: 47.813101,
        },
        _id: "64f75285ef8f606073e9ae45",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjiamangirana",
        fokontany: "ambodimabibo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2487224,
          lng: 47.8921477,
        },
        _id: "64f75285ef8f606073e9ae46",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjiamangirana",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.305641,
          lng: 47.7341718,
        },
        _id: "64f75285ef8f606073e9ae47",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "anjiamangirana",
        fokontany: "andavaboay",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2353184,
          lng: 48.1200592,
        },
        _id: "64f75285ef8f606073e9ae48",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimanary",
        fokontany: "ambodimanary",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0873438,
          lng: 48.0654706,
        },
        _id: "64f75285ef8f606073e9ae49",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimanary",
        fokontany: "ampondralava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1131337,
          lng: 48.0555514,
        },
        _id: "64f75285ef8f606073e9ae4a",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimanary",
        fokontany: "anjialava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.133333,
          lng: 48.033333,
        },
        _id: "64f75285ef8f606073e9ae4b",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimanary",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1088158,
          lng: 48.1399232,
        },
        _id: "64f75285ef8f606073e9ae4c",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimanary",
        fokontany: "marokoera",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1655063,
          lng: 48.2045317,
        },
        _id: "64f75285ef8f606073e9ae4d",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimanary",
        fokontany: "anjaro",
        __v: 0,
      },
      {
        coords: {
          lat: -15.16059,
          lng: 48.16267,
        },
        _id: "64f75285ef8f606073e9ae4e",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimanary",
        fokontany: "ambatofohy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2004769,
          lng: 48.0902768,
        },
        _id: "64f75285ef8f606073e9ae4f",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimanary",
        fokontany: "androimenabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1869,
          lng: 48.03498099999999,
        },
        _id: "64f75285ef8f606073e9ae50",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimanary",
        fokontany: "solimpana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1965786,
          lng: 48.1349565,
        },
        _id: "64f75285ef8f606073e9ae51",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimanary",
        fokontany: "ambararatilava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2087802,
          lng: 48.2095048,
        },
        _id: "64f75285ef8f606073e9ae52",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimanary",
        fokontany: "antanimbaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.216667,
          lng: 48.183333,
        },
        _id: "64f75285ef8f606073e9ae53",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimanary",
        fokontany: "antarameloka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.27702,
          lng: 48.00843,
        },
        _id: "64f75285ef8f606073e9ae54",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimanary",
        fokontany: "amborokambo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3037154,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e9ae55",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimanary",
        fokontany: "ankazomahita",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2353184,
          lng: 48.1200592,
        },
        _id: "64f75285ef8f606073e9ae56",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimanary",
        fokontany: "doroa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8105196,
          lng: 48.3863436,
        },
        _id: "64f75285ef8f606073e9ae57",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsahabe",
        fokontany: "antsahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6466359,
          lng: 48.2244268,
        },
        _id: "64f75285ef8f606073e9ae58",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsahabe",
        fokontany: "amporingamena",
        __v: 0,
      },
      {
        coords: {
          lat: -14.62095,
          lng: 48.299098,
        },
        _id: "64f75285ef8f606073e9ae59",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsahabe",
        fokontany: "ankarankely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.693265,
          lng: 48.2244268,
        },
        _id: "64f75285ef8f606073e9ae5a",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsahabe",
        fokontany: "bemanondro",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6531587,
          lng: 48.254283,
        },
        _id: "64f75285ef8f606073e9ae5b",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsahabe",
        fokontany: "antsirabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6884545,
          lng: 48.3090619,
        },
        _id: "64f75285ef8f606073e9ae5c",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsahabe",
        fokontany: "ambodimanga II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6998068,
          lng: 48.254283,
        },
        _id: "64f75285ef8f606073e9ae5d",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsahabe",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7138981,
          lng: 48.169733,
        },
        _id: "64f75285ef8f606073e9ae5e",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsahabe",
        fokontany: "bemarambonga",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7065354,
          lng: 48.1150943,
        },
        _id: "64f75285ef8f606073e9ae5f",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsahabe",
        fokontany: "bemakamba",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7283968,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e9ae60",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsahabe",
        fokontany: "ankobandronina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7410608,
          lng: 48.1448904,
        },
        _id: "64f75285ef8f606073e9ae61",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsahabe",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7536225,
          lng: 48.24432909999999,
        },
        _id: "64f75285ef8f606073e9ae62",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsahabe",
        fokontany: "andengondroy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7569581,
          lng: 48.1747029,
        },
        _id: "64f75285ef8f606073e9ae63",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsahabe",
        fokontany: "ankobabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7901536,
          lng: 48.2194523,
        },
        _id: "64f75285ef8f606073e9ae64",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsahabe",
        fokontany: "betsipotika",
        __v: 0,
      },
      {
        coords: {
          lat: -14.776165,
          lng: 48.1349565,
        },
        _id: "64f75285ef8f606073e9ae65",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsahabe",
        fokontany: "ambario",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7631722,
          lng: 48.3090619,
        },
        _id: "64f75285ef8f606073e9ae66",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsahabe",
        fokontany: "amboangisay",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8280213,
          lng: 48.3489356,
        },
        _id: "64f75285ef8f606073e9ae67",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsahabe",
        fokontany: "antanambao haut",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8455732,
          lng: 48.4287702,
        },
        _id: "64f75285ef8f606073e9ae68",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsahabe",
        fokontany: "antsalovana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8291513,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e9ae69",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "antsahabe",
        fokontany: "ambalavelona bas",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6060981,
          lng: 48.0605108,
        },
        _id: "64f75285ef8f606073e9ae6a",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimadiro",
        fokontany: "ambodimadiro",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5389563,
          lng: 48.1150943,
        },
        _id: "64f75285ef8f606073e9ae6b",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimadiro",
        fokontany: "ankorera",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5532308,
          lng: 48.0952394,
        },
        _id: "64f75285ef8f606073e9ae6c",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimadiro",
        fokontany: "ambodimanga I",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5454473,
          lng: 48.1448904,
        },
        _id: "64f75285ef8f606073e9ae6d",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimadiro",
        fokontany: "amboangy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.565329,
          lng: 48.0654706,
        },
        _id: "64f75285ef8f606073e9ae6e",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimadiro",
        fokontany: "tsarabanja",
        __v: 0,
      },
      {
        coords: {
          lat: -14.616667,
          lng: 48.016667,
        },
        _id: "64f75285ef8f606073e9ae6f",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimadiro",
        fokontany: "ankinganivalaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6076629,
          lng: 48.1101299,
        },
        _id: "64f75285ef8f606073e9ae70",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimadiro",
        fokontany: "sahavalanina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6397518,
          lng: 48.0654706,
        },
        _id: "64f75285ef8f606073e9ae71",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimadiro",
        fokontany: "ambodisakoana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6603169,
          lng: 48.0109374,
        },
        _id: "64f75285ef8f606073e9ae72",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimadiro",
        fokontany: "ambolobozo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6627288,
          lng: 48.0853146,
        },
        _id: "64f75285ef8f606073e9ae73",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimadiro",
        fokontany: "anjialava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.683333,
          lng: 48.066667,
        },
        _id: "64f75285ef8f606073e9ae74",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "ambodimadiro",
        fokontany: "ambalasakoana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6321915,
          lng: 48.1796732,
        },
        _id: "64f75285ef8f606073e9ae75",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "andreba",
        fokontany: "andreba",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5519376,
          lng: 48.1747029,
        },
        _id: "64f75285ef8f606073e9ae76",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "andreba",
        fokontany: "ampondralava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5491137,
          lng: 48.2045317,
        },
        _id: "64f75285ef8f606073e9ae77",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "andreba",
        fokontany: "ankozany",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5792229,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e9ae78",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "andreba",
        fokontany: "ankorefo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.626446,
          lng: 48.1747029,
        },
        _id: "64f75285ef8f606073e9ae79",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "andreba",
        fokontany: "betainomby",
        __v: 0,
      },
      {
        coords: {
          lat: -14.65,
          lng: 48.116667,
        },
        _id: "64f75285ef8f606073e9ae7a",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "andreba",
        fokontany: "tsilokivary",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6536145,
          lng: 48.14985799999999,
        },
        _id: "64f75285ef8f606073e9ae7b",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "andreba",
        fokontany: "antanambao bas",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6623262,
          lng: 48.1896152,
        },
        _id: "64f75285ef8f606073e9ae7c",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "andreba",
        fokontany: "ambalihabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3537609,
          lng: 47.9020367,
        },
        _id: "64f75285ef8f606073e9ae7d",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "maroala",
        fokontany: "maroala",
        __v: 0,
      },
      {
        coords: {
          lat: -15.33976,
          lng: 47.886631,
        },
        _id: "64f75285ef8f606073e9ae7e",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "maroala",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3520908,
          lng: 47.7736217,
        },
        _id: "64f75285ef8f606073e9ae7f",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "maroala",
        fokontany: "ambalafaminty",
        __v: 0,
      },
      {
        coords: {
          lat: -15.314334,
          lng: 47.8526097,
        },
        _id: "64f75285ef8f606073e9ae80",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "maroala",
        fokontany: "antsakoamirantina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3333255,
          lng: 47.9960743,
        },
        _id: "64f75285ef8f606073e9ae81",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "maroala",
        fokontany: "anompaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.309311,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e9ae82",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "maroala",
        fokontany: "antanambao m",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3503903,
          lng: 47.9787391,
        },
        _id: "64f75285ef8f606073e9ae83",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "maroala",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.371207,
          lng: 47.9168738,
        },
        _id: "64f75285ef8f606073e9ae84",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "maroala",
        fokontany: "ampotamandrevo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3429262,
          lng: 47.95645940000001,
        },
        _id: "64f75285ef8f606073e9ae85",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "maroala",
        fokontany: "antananambo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3638965,
          lng: 48.14985799999999,
        },
        _id: "64f75285ef8f606073e9ae86",
        province: "mahajanga",
        region: "sofia",
        district: "antsohihy",
        commune: "maroala",
        fokontany: "antombokazo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5460011,
          lng: 48.7542899,
        },
        _id: "64f75285ef8f606073e9ae87",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "bealanana III",
        __v: 0,
      },
      {
        coords: {
          lat: -14.478084,
          lng: 48.8095665,
        },
        _id: "64f75285ef8f606073e9ae88",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "ambalavelombe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.466667,
          lng: 48.783333,
        },
        _id: "64f75285ef8f606073e9ae89",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "marofamara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4793555,
          lng: 48.8598651,
        },
        _id: "64f75285ef8f606073e9ae8a",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "antambato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5127913,
          lng: 48.7743842,
        },
        _id: "64f75285ef8f606073e9ae8b",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "antsiradava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5256967,
          lng: 48.8020256,
        },
        _id: "64f75285ef8f606073e9ae8c",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "ambinanindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5335855,
          lng: 48.745501,
        },
        _id: "64f75285ef8f606073e9ae8d",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "antanimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -14.533333,
          lng: 48.833333,
        },
        _id: "64f75285ef8f606073e9ae8e",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "marolambo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5336744,
          lng: 48.784434,
        },
        _id: "64f75285ef8f606073e9ae8f",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "ambodihitsakintsana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5476417,
          lng: 48.745501,
        },
        _id: "64f75285ef8f606073e9ae90",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "bealanana II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5449642,
          lng: 48.7329477,
        },
        _id: "64f75285ef8f606073e9ae91",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "bealanana I",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5660856,
          lng: 48.8045391,
        },
        _id: "64f75285ef8f606073e9ae92",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "ambodisatrana est",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5575163,
          lng: 48.7643362,
        },
        _id: "64f75285ef8f606073e9ae93",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5583152,
          lng: 48.7241621,
        },
        _id: "64f75285ef8f606073e9ae94",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "anandrobato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5706166,
          lng: 48.69405039999999,
        },
        _id: "64f75285ef8f606073e9ae95",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "ambatoria ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5813499,
          lng: 48.7442455,
        },
        _id: "64f75285ef8f606073e9ae96",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "ambalatany",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5659274,
          lng: 48.86993030000001,
        },
        _id: "64f75285ef8f606073e9ae97",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "antetikala",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5921386,
          lng: 48.76182439999999,
        },
        _id: "64f75285ef8f606073e9ae98",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "antanagnivo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5936526,
          lng: 48.714123,
        },
        _id: "64f75285ef8f606073e9ae99",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "antanambola",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6144054,
          lng: 48.8221369,
        },
        _id: "64f75285ef8f606073e9ae9a",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "bepaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6050787,
          lng: 48.7894596,
        },
        _id: "64f75285ef8f606073e9ae9b",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "beangezoka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6210044,
          lng: 48.7542899,
        },
        _id: "64f75285ef8f606073e9ae9c",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "bealanana",
        fokontany: "andilandalina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4841113,
          lng: 48.6791911,
        },
        _id: "64f75285ef8f606073e9ae9d",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "beandrarezona",
        fokontany: "beandrarezona II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.483333,
          lng: 48.633333,
        },
        _id: "64f75285ef8f606073e9ae9e",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "beandrarezona",
        fokontany: "amberivery",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4935628,
          lng: 48.68401679999999,
        },
        _id: "64f75285ef8f606073e9ae9f",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "beandrarezona",
        fokontany: "beandrarezona I",
        __v: 0,
      },
      {
        coords: {
          lat: -14.46104,
          lng: 48.7291822,
        },
        _id: "64f75285ef8f606073e9aea0",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "beandrarezona",
        fokontany: "ambalapaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5138169,
          lng: 48.6363818,
        },
        _id: "64f75285ef8f606073e9aea1",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "beandrarezona",
        fokontany: "andraratranina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5273988,
          lng: 48.6890334,
        },
        _id: "64f75285ef8f606073e9aea2",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "beandrarezona",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4859267,
          lng: 48.5387323,
        },
        _id: "64f75285ef8f606073e9aea3",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "beandrarezona",
        fokontany: "ambodisatrankely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5245836,
          lng: 48.6539268,
        },
        _id: "64f75285ef8f606073e9aea4",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "beandrarezona",
        fokontany: "ankisaka nord",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4197255,
          lng: 48.5787728,
        },
        _id: "64f75285ef8f606073e9aea5",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "beandrarezona",
        fokontany: "ambodisatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.533333,
          lng: 48.466667,
        },
        _id: "64f75285ef8f606073e9aea6",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "beandrarezona",
        fokontany: "ambodirafia",
        __v: 0,
      },
      {
        coords: {
          lat: -14.583333,
          lng: 48.333333,
        },
        _id: "64f75285ef8f606073e9aea7",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "beandrarezona",
        fokontany: "sandrakotahely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.57223,
          lng: 48.4187846,
        },
        _id: "64f75285ef8f606073e9aea8",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "beandrarezona",
        fokontany: "anaboriana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.233333,
          lng: 48.5,
        },
        _id: "64f75285ef8f606073e9aea9",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "antananivo haut",
        fokontany: "antananivo haut",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2083794,
          lng: 48.4837239,
        },
        _id: "64f75285ef8f606073e9aeaa",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "antananivo haut",
        fokontany: "antanambao sandrakota",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2324395,
          lng: 48.5537441,
        },
        _id: "64f75285ef8f606073e9aeab",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "antananivo haut",
        fokontany: "ambodivavandrika",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2757868,
          lng: 48.6489134,
        },
        _id: "64f75285ef8f606073e9aeac",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "antananivo haut",
        fokontany: "ambodimadiro",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2703523,
          lng: 48.5787728,
        },
        _id: "64f75285ef8f606073e9aead",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "antananivo haut",
        fokontany: "manirenjavao",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2621619,
          lng: 48.4737282,
        },
        _id: "64f75285ef8f606073e9aeae",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "antananivo haut",
        fokontany: "antolongo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3450125,
          lng: 48.5787728,
        },
        _id: "64f75285ef8f606073e9aeaf",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "antananivo haut",
        fokontany: "ambinanindrano sandrakota",
        __v: 0,
      },
      {
        coords: {
          lat: -14.583333,
          lng: 48.633333,
        },
        _id: "64f75285ef8f606073e9aeb0",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "antsamaka",
        fokontany: "antsamaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5584994,
          lng: 48.5937955,
        },
        _id: "64f75285ef8f606073e9aeb1",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "antsamaka",
        fokontany: "ambalatsiraka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5750616,
          lng: 48.5837799,
        },
        _id: "64f75285ef8f606073e9aeb2",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "antsamaka",
        fokontany: "farafangahely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.60163,
          lng: 48.56279,
        },
        _id: "64f75285ef8f606073e9aeb3",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "antsamaka",
        fokontany: "ambodivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5908765,
          lng: 48.613832,
        },
        _id: "64f75285ef8f606073e9aeb4",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "antsamaka",
        fokontany: "ankisaka sud",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6124938,
          lng: 48.5837799,
        },
        _id: "64f75285ef8f606073e9aeb5",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "antsamaka",
        fokontany: "antsoriamena",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6470518,
          lng: 48.5487397,
        },
        _id: "64f75285ef8f606073e9aeb6",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "antsamaka",
        fokontany: "ambodikakazo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6930377,
          lng: 48.4587382,
        },
        _id: "64f75285ef8f606073e9aeb7",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "antsamaka",
        fokontany: "antsalonjo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6746199,
          lng: 48.6601942,
        },
        _id: "64f75285ef8f606073e9aeb8",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatosia",
        fokontany: "ambatosia",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6295024,
          lng: 48.8598651,
        },
        _id: "64f75285ef8f606073e9aeb9",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatosia",
        fokontany: "andasinimaro",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6462246,
          lng: 48.784434,
        },
        _id: "64f75285ef8f606073e9aeba",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatosia",
        fokontany: "ambararatabe centre",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6505318,
          lng: 48.8045391,
        },
        _id: "64f75285ef8f606073e9aebb",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatosia",
        fokontany: "ampaminty",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6996807,
          lng: 48.68401679999999,
        },
        _id: "64f75285ef8f606073e9aebc",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatosia",
        fokontany: "ankijanimavo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7292723,
          lng: 48.7342029,
        },
        _id: "64f75285ef8f606073e9aebd",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatosia",
        fokontany: "andranotakatra bas",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6902862,
          lng: 48.74926749999999,
        },
        _id: "64f75285ef8f606073e9aebe",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatosia",
        fokontany: "anjohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7191642,
          lng: 48.7091042,
        },
        _id: "64f75285ef8f606073e9aebf",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatosia",
        fokontany: "andranotakatra haut",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6787599,
          lng: 48.6088222,
        },
        _id: "64f75285ef8f606073e9aec0",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatosia",
        fokontany: "ambalabe M",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7997144,
          lng: 48.5187229,
        },
        _id: "64f75285ef8f606073e9aec1",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatosia",
        fokontany: "antanambaon'ampandrana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7,
          lng: 48.633333,
        },
        _id: "64f75285ef8f606073e9aec2",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatosia",
        fokontany: "ambohimitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8099892,
          lng: 48.6088222,
        },
        _id: "64f75285ef8f606073e9aec3",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatosia",
        fokontany: "beanatsindra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6355071,
          lng: 48.6689697,
        },
        _id: "64f75285ef8f606073e9aec4",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiampana",
        fokontany: "ambodiampana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6217721,
          lng: 48.714123,
        },
        _id: "64f75285ef8f606073e9aec5",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiampana",
        fokontany: "betaikankana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6484681,
          lng: 48.69655909999999,
        },
        _id: "64f75285ef8f606073e9aec6",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiampana",
        fokontany: "andriana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6484447,
          lng: 48.7291822,
        },
        _id: "64f75285ef8f606073e9aec7",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiampana",
        fokontany: "bemololo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6420092,
          lng: 48.6338758,
        },
        _id: "64f75285ef8f606073e9aec8",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiampana",
        fokontany: "anjanaborona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6412944,
          lng: 48.6088222,
        },
        _id: "64f75285ef8f606073e9aec9",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiampana",
        fokontany: "antanankely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5035316,
          lng: 48.9476504,
        },
        _id: "64f75285ef8f606073e9aeca",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatoriha est",
        fokontany: "ambatoriha est",
        __v: 0,
      },
      {
        coords: {
          lat: -14.409807,
          lng: 48.9303587,
        },
        _id: "64f75285ef8f606073e9aecb",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatoriha est",
        fokontany: "antafiandakana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.433333,
          lng: 48.95,
        },
        _id: "64f75285ef8f606073e9aecc",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatoriha est",
        fokontany: "beroitra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4848664,
          lng: 48.9303587,
        },
        _id: "64f75285ef8f606073e9aecd",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatoriha est",
        fokontany: "ambalagavo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.508523,
          lng: 48.9757225,
        },
        _id: "64f75285ef8f606073e9aece",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatoriha est",
        fokontany: "ambalajia",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5166558,
          lng: 48.92532060000001,
        },
        _id: "64f75285ef8f606073e9aecf",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatoriha est",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5243034,
          lng: 49.0059852,
        },
        _id: "64f75285ef8f606073e9aed0",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatoriha est",
        fokontany: "antsambalahibe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5309413,
          lng: 48.9706803,
        },
        _id: "64f75285ef8f606073e9aed1",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatoriha est",
        fokontany: "antanamarina antsambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5448232,
          lng: 48.92532060000001,
        },
        _id: "64f75285ef8f606073e9aed2",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatoriha est",
        fokontany: "antanatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5657428,
          lng: 48.9353974,
        },
        _id: "64f75285ef8f606073e9aed3",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatoriha est",
        fokontany: "antelopolo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5742909,
          lng: 48.9757225,
        },
        _id: "64f75285ef8f606073e9aed4",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambatoriha est",
        fokontany: "ambalatany sud",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3715652,
          lng: 49.0615086,
        },
        _id: "64f75285ef8f606073e9aed5",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambovonomby",
        fokontany: "ambovonomby",
        __v: 0,
      },
      {
        coords: {
          lat: -14.333333,
          lng: 49.033333,
        },
        _id: "64f75285ef8f606073e9aed6",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambovonomby",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3566267,
          lng: 49.0236458,
        },
        _id: "64f75285ef8f606073e9aed7",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambovonomby",
        fokontany: "antsahamahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3121497,
          lng: 49.0918169,
        },
        _id: "64f75285ef8f606073e9aed8",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambovonomby",
        fokontany: "anesika",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3834378,
          lng: 49.0059852,
        },
        _id: "64f75285ef8f606073e9aed9",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambovonomby",
        fokontany: "antanimbaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.365617,
          lng: 48.9656385,
        },
        _id: "64f75285ef8f606073e9aeda",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambovonomby",
        fokontany: "ankiakabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.39296,
          lng: 49.03350100000001,
        },
        _id: "64f75285ef8f606073e9aedb",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambovonomby",
        fokontany: "ambodipaiso",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3916448,
          lng: 48.9555563,
        },
        _id: "64f75285ef8f606073e9aedc",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambovonomby",
        fokontany: "ambohimitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4758144,
          lng: 49.0211225,
        },
        _id: "64f75285ef8f606073e9aedd",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "analila",
        fokontany: "analila",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4390025,
          lng: 49.0135533,
        },
        _id: "64f75285ef8f606073e9aede",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "analila",
        fokontany: "antanamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4367214,
          lng: 49.036264,
        },
        _id: "64f75285ef8f606073e9aedf",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "analila",
        fokontany: "antilongo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4491522,
          lng: 49.0059852,
        },
        _id: "64f75285ef8f606073e9aee0",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "analila",
        fokontany: "antsambalahimaro",
        __v: 0,
      },
      {
        coords: {
          lat: -14.463692,
          lng: 48.9858082,
        },
        _id: "64f75285ef8f606073e9aee1",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "analila",
        fokontany: "ankodohodo I",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4803489,
          lng: 48.9757225,
        },
        _id: "64f75285ef8f606073e9aee2",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "analila",
        fokontany: "ankodohodo II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4786936,
          lng: 49.0236458,
        },
        _id: "64f75285ef8f606073e9aee3",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "analila",
        fokontany: "ambodimandresy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4961156,
          lng: 49.0059852,
        },
        _id: "64f75285ef8f606073e9aee4",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "analila",
        fokontany: "bekofafa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5,
          lng: 49.05,
        },
        _id: "64f75285ef8f606073e9aee5",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "analila",
        fokontany: "ambalamotraka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.434462,
          lng: 49.1828385,
        },
        _id: "64f75285ef8f606073e9aee6",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "anjozoromadosy",
        fokontany: "anjozoromadosy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4400167,
          lng: 49.09686989999999,
        },
        _id: "64f75285ef8f606073e9aee7",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "anjozoromadosy",
        fokontany: "antsahonjo II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4705675,
          lng: 49.1980228,
        },
        _id: "64f75285ef8f606073e9aee8",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "anjozoromadosy",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.434462,
          lng: 49.1828385,
        },
        _id: "64f75285ef8f606073e9aee9",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "anjozoromadosy",
        fokontany: "antsahonjo I",
        __v: 0,
      },
      {
        coords: {
          lat: -14.483333,
          lng: 49.116667,
        },
        _id: "64f75285ef8f606073e9aeea",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "anjozoromadosy",
        fokontany: "ambohimitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4975675,
          lng: 49.0855014,
        },
        _id: "64f75285ef8f606073e9aeeb",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "anjozoromadosy",
        fokontany: "antsirakilambo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4867242,
          lng: 49.0842383,
        },
        _id: "64f75285ef8f606073e9aeec",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "anjozoromadosy",
        fokontany: "marobilahy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5117472,
          lng: 49.0690842,
        },
        _id: "64f75285ef8f606073e9aeed",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "anjozoromadosy",
        fokontany: "soatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.27138,
          lng: 48.95097000000001,
        },
        _id: "64f75285ef8f606073e9aeee",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "mangindrano",
        fokontany: "mangindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2472667,
          lng: 48.86993030000001,
        },
        _id: "64f75285ef8f606073e9aeef",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "mangindrano",
        fokontany: "ambodinonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2694172,
          lng: 48.8976186,
        },
        _id: "64f75285ef8f606073e9aef0",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "mangindrano",
        fokontany: "analobato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.276614,
          lng: 49.0110305,
        },
        _id: "64f75285ef8f606073e9aef1",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "mangindrano",
        fokontany: "soatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2819309,
          lng: 48.9127271,
        },
        _id: "64f75285ef8f606073e9aef2",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "mangindrano",
        fokontany: "beanampatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2861306,
          lng: 48.932878,
        },
        _id: "64f75285ef8f606073e9aef3",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "mangindrano",
        fokontany: "ambalatany nord",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2939655,
          lng: 48.9479959,
        },
        _id: "64f75285ef8f606073e9aef4",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "mangindrano",
        fokontany: "ampitolovana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3118487,
          lng: 48.9102087,
        },
        _id: "64f75285ef8f606073e9aef5",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "mangindrano",
        fokontany: "ambohimanakana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3116574,
          lng: 48.9429561,
        },
        _id: "64f75285ef8f606073e9aef6",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "mangindrano",
        fokontany: "ampondrabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3093577,
          lng: 48.9656385,
        },
        _id: "64f75285ef8f606073e9aef7",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "mangindrano",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3390128,
          lng: 48.9505159,
        },
        _id: "64f75285ef8f606073e9aef8",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "mangindrano",
        fokontany: "analalatsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3459048,
          lng: 49.0059852,
        },
        _id: "64f75285ef8f606073e9aef9",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "mangindrano",
        fokontany: "ankazotokana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.338433,
          lng: 48.92532060000001,
        },
        _id: "64f75285ef8f606073e9aefa",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "mangindrano",
        fokontany: "tsaralasy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.38638,
          lng: 48.81879,
        },
        _id: "64f75285ef8f606073e9aefb",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambararatabe nord",
        fokontany: "ambararatabe nord",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2919656,
          lng: 48.8598651,
        },
        _id: "64f75285ef8f606073e9aefc",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambararatabe nord",
        fokontany: "ambondrona nord",
        __v: 0,
      },
      {
        coords: {
          lat: -14.33016,
          lng: 48.88361,
        },
        _id: "64f75285ef8f606073e9aefd",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambararatabe nord",
        fokontany: "matsaborimadio",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3825937,
          lng: 48.8900659,
        },
        _id: "64f75285ef8f606073e9aefe",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambararatabe nord",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3632546,
          lng: 48.8648975,
        },
        _id: "64f75285ef8f606073e9aeff",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambararatabe nord",
        fokontany: "besangaratra nord",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3282333,
          lng: 48.8095665,
        },
        _id: "64f75285ef8f606073e9af00",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambararatabe nord",
        fokontany: "ambahivahy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3986157,
          lng: 48.8548333,
        },
        _id: "64f75285ef8f606073e9af01",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambararatabe nord",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4361028,
          lng: 48.8548333,
        },
        _id: "64f75285ef8f606073e9af02",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambararatabe nord",
        fokontany: "antsatrana nord",
        __v: 0,
      },
      {
        coords: {
          lat: -14.887013,
          lng: 48.9202828,
        },
        _id: "64f75285ef8f606073e9af03",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodisikidy",
        fokontany: "ambodisikidy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.75,
          lng: 49.016667,
        },
        _id: "64f75285ef8f606073e9af04",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodisikidy",
        fokontany: "andranomadio",
        __v: 0,
      },
      {
        coords: {
          lat: -14.81918,
          lng: 49.022301,
        },
        _id: "64f75285ef8f606073e9af05",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodisikidy",
        fokontany: "ambohimisondrotra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.823273,
          lng: 48.9303587,
        },
        _id: "64f75285ef8f606073e9af06",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodisikidy",
        fokontany: "ambodiara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8174595,
          lng: 48.99085179999999,
        },
        _id: "64f75285ef8f606073e9af07",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodisikidy",
        fokontany: "ambodihalampona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8145851,
          lng: 48.8900659,
        },
        _id: "64f75285ef8f606073e9af08",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodisikidy",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8558683,
          lng: 49.01607629999999,
        },
        _id: "64f75285ef8f606073e9af09",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodisikidy",
        fokontany: "ambararatabe sud",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8573166,
          lng: 48.9353974,
        },
        _id: "64f75285ef8f606073e9af0a",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodisikidy",
        fokontany: "maroamalona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8609466,
          lng: 48.99589580000001,
        },
        _id: "64f75285ef8f606073e9af0b",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodisikidy",
        fokontany: "ankijaninomby",
        __v: 0,
      },
      {
        coords: {
          lat: -14.866667,
          lng: 48.95,
        },
        _id: "64f75285ef8f606073e9af0c",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodisikidy",
        fokontany: "ankiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.898614,
          lng: 48.9303587,
        },
        _id: "64f75285ef8f606073e9af0d",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodisikidy",
        fokontany: "ampomborejy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8869535,
          lng: 48.8548333,
        },
        _id: "64f75285ef8f606073e9af0e",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodisikidy",
        fokontany: "anaboriana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9133853,
          lng: 48.8573491,
        },
        _id: "64f75285ef8f606073e9af0f",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodisikidy",
        fokontany: "antsiradrano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9072864,
          lng: 48.9051723,
        },
        _id: "64f75285ef8f606073e9af10",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodisikidy",
        fokontany: "ambalarano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9463944,
          lng: 48.8900659,
        },
        _id: "64f75285ef8f606073e9af11",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodisikidy",
        fokontany: "ambinaniankofa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.80671,
          lng: 48.8313289,
        },
        _id: "64f75285ef8f606073e9af12",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambararata sofia",
        fokontany: "ambararata sofia",
        __v: 0,
      },
      {
        coords: {
          lat: -14.766667,
          lng: 48.866667,
        },
        _id: "64f75285ef8f606073e9af13",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambararata sofia",
        fokontany: "ambatosoratra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8001075,
          lng: 48.8447708,
        },
        _id: "64f75285ef8f606073e9af14",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambararata sofia",
        fokontany: "anketsahely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7509411,
          lng: 48.76936,
        },
        _id: "64f75285ef8f606073e9af15",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambararata sofia",
        fokontany: "ankosihosy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8203559,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9af16",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambararata sofia",
        fokontany: "ankosihosibe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8333474,
          lng: 48.7919726,
        },
        _id: "64f75285ef8f606073e9af17",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambararata sofia",
        fokontany: "ambohimanakana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8448931,
          lng: 48.76936,
        },
        _id: "64f75285ef8f606073e9af18",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambararata sofia",
        fokontany: "matsamena",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6572015,
          lng: 48.9908143,
        },
        _id: "64f75285ef8f606073e9af19",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "marotolana",
        fokontany: "marotolana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5516247,
          lng: 49.0463605,
        },
        _id: "64f75285ef8f606073e9af1a",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "marotolana",
        fokontany: "andranovaky",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5382734,
          lng: 49.0387879,
        },
        _id: "64f75285ef8f606073e9af1b",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "marotolana",
        fokontany: "ambondrona sud",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5491797,
          lng: 49.0236458,
        },
        _id: "64f75285ef8f606073e9af1c",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "marotolana",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5303789,
          lng: 49.0236458,
        },
        _id: "64f75285ef8f606073e9af1d",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "marotolana",
        fokontany: "andilantsara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5580177,
          lng: 49.0766607,
        },
        _id: "64f75285ef8f606073e9af1e",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "marotolana",
        fokontany: "lohanisofia",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5712994,
          lng: 49.0059852,
        },
        _id: "64f75285ef8f606073e9af1f",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "marotolana",
        fokontany: "marofamara est",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5892464,
          lng: 49.0463605,
        },
        _id: "64f75285ef8f606073e9af20",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "marotolana",
        fokontany: "antilongo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6206337,
          lng: 48.9505159,
        },
        _id: "64f75285ef8f606073e9af21",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "marotolana",
        fokontany: "andampy sofia",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6582383,
          lng: 48.9505159,
        },
        _id: "64f75285ef8f606073e9af22",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "marotolana",
        fokontany: "antsatrana sofia",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6572015,
          lng: 48.9908143,
        },
        _id: "64f75285ef8f606073e9af23",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "marotolana",
        fokontany: "soatanana sud",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6812083,
          lng: 49.036264,
        },
        _id: "64f75285ef8f606073e9af24",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "marotolana",
        fokontany: "ambohimitsinjo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7008421,
          lng: 48.99589580000001,
        },
        _id: "64f75285ef8f606073e9af25",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "marotolana",
        fokontany: "andengilengy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7045753,
          lng: 48.92532060000001,
        },
        _id: "64f75285ef8f606073e9af26",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "marotolana",
        fokontany: "beangezoka sofia",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7088875,
          lng: 48.9454759,
        },
        _id: "64f75285ef8f606073e9af27",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "marotolana",
        fokontany: "ankerambe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7523055,
          lng: 48.9505159,
        },
        _id: "64f75285ef8f606073e9af28",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "marotolana",
        fokontany: "andasibe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7327909,
          lng: 48.92532060000001,
        },
        _id: "64f75285ef8f606073e9af29",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "marotolana",
        fokontany: "ankijanibe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6896259,
          lng: 49.1423666,
        },
        _id: "64f75285ef8f606073e9af2a",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiadabo m",
        fokontany: "ambodiadabo M",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5866107,
          lng: 49.1676582,
        },
        _id: "64f75285ef8f606073e9af2b",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiadabo m",
        fokontany: "antsomangana besahono",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6054517,
          lng: 49.1676582,
        },
        _id: "64f75285ef8f606073e9af2c",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiadabo m",
        fokontany: "ankarakaraka besahono",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6157502,
          lng: 49.127197,
        },
        _id: "64f75285ef8f606073e9af2d",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiadabo m",
        fokontany: "anamboriana bas",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6482881,
          lng: 49.147424,
        },
        _id: "64f75285ef8f606073e9af2e",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiadabo m",
        fokontany: "antsoriamena",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6433648,
          lng: 49.1019233,
        },
        _id: "64f75285ef8f606073e9af2f",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiadabo m",
        fokontany: "andebodatsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.666386,
          lng: 49.15501099999999,
        },
        _id: "64f75285ef8f606073e9af30",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiadabo m",
        fokontany: "ambalafony",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6574819,
          lng: 49.2132111,
        },
        _id: "64f75285ef8f606073e9af31",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiadabo m",
        fokontany: "antsiradava III",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6937086,
          lng: 49.2284034,
        },
        _id: "64f75285ef8f606073e9af32",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiadabo m",
        fokontany: "ambatomikintsana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6981577,
          lng: 49.1992883,
        },
        _id: "64f75285ef8f606073e9af33",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiadabo m",
        fokontany: "antsiradava II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7018511,
          lng: 49.1777779,
        },
        _id: "64f75285ef8f606073e9af34",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiadabo m",
        fokontany: "antsiradava I",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6926102,
          lng: 49.1120314,
        },
        _id: "64f75285ef8f606073e9af35",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiadabo m",
        fokontany: "antsinjomeva",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6881309,
          lng: 49.1575402,
        },
        _id: "64f75285ef8f606073e9af36",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiadabo m",
        fokontany: "antsatrankely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7140797,
          lng: 49.2132111,
        },
        _id: "64f75285ef8f606073e9af37",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiadabo m",
        fokontany: "tsararano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7303078,
          lng: 49.1120314,
        },
        _id: "64f75285ef8f606073e9af38",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambodiadabo m",
        fokontany: "antanambao A",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5679148,
          lng: 49.1347813,
        },
        _id: "64f75285ef8f606073e9af39",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ankazotokana",
        fokontany: "ankazotokana atsimondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5474186,
          lng: 49.1828385,
        },
        _id: "64f75285ef8f606073e9af3a",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ankazotokana",
        fokontany: "ankazotokana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5756416,
          lng: 49.1044502,
        },
        _id: "64f75285ef8f606073e9af3b",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ankazotokana",
        fokontany: "antsahanifotry",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5878119,
          lng: 49.139838,
        },
        _id: "64f75285ef8f606073e9af3c",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ankazotokana",
        fokontany: "antanambao besahono",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5875001,
          lng: 49.127197,
        },
        _id: "64f75285ef8f606073e9af3d",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ankazotokana",
        fokontany: "ambohimitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.594152,
          lng: 49.0918169,
        },
        _id: "64f75285ef8f606073e9af3e",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ankazotokana",
        fokontany: "anamboriana haut",
        __v: 0,
      },
      {
        coords: {
          lat: -14.666667,
          lng: 49.333333,
        },
        _id: "64f75285ef8f606073e9af3f",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambalaromba",
        fokontany: "ambalaromba",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6283008,
          lng: 49.2537329,
        },
        _id: "64f75285ef8f606073e9af40",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambalaromba",
        fokontany: "ankiakalava haut",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5351907,
          lng: 49.3044253,
        },
        _id: "64f75285ef8f606073e9af41",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambalaromba",
        fokontany: "ankiakalava bas",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6307422,
          lng: 49.3551622,
        },
        _id: "64f75285ef8f606073e9af42",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambalaromba",
        fokontany: "ambalavao",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6898217,
          lng: 49.2993541,
        },
        _id: "64f75285ef8f606073e9af43",
        province: "mahajanga",
        region: "sofia",
        district: "bealanana",
        commune: "ambalaromba",
        fokontany: "antanambao M",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0880679,
          lng: 47.6332155,
        },
        _id: "64f75285ef8f606073e9af44",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony I",
        fokontany: "mahiagogo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0798898,
          lng: 47.63813570000001,
        },
        _id: "64f75285ef8f606073e9af45",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony I",
        fokontany: "betsingala",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0846664,
          lng: 47.6578212,
        },
        _id: "64f75285ef8f606073e9af46",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony I",
        fokontany: "tsaramandroso III",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0859313,
          lng: 47.646132,
        },
        _id: "64f75285ef8f606073e9af47",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony I",
        fokontany: "tsaramandroso I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.082002,
          lng: 47.65166869999999,
        },
        _id: "64f75285ef8f606073e9af48",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony I",
        fokontany: "tsaramandroso II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0944093,
          lng: 47.64244129999999,
        },
        _id: "64f75285ef8f606073e9af49",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony I",
        fokontany: "mandrosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0954665,
          lng: 47.6492079,
        },
        _id: "64f75285ef8f606073e9af4a",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony I",
        fokontany: "tsararivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.04598,
          lng: 47.6528991,
        },
        _id: "64f75285ef8f606073e9af4b",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "ambalafety",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0412133,
          lng: 47.6332155,
        },
        _id: "64f75285ef8f606073e9af4c",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "tsinjofary",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0479213,
          lng: 47.6270659,
        },
        _id: "64f75285ef8f606073e9af4d",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "ampampamena",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0545404,
          lng: 47.6012453,
        },
        _id: "64f75285ef8f606073e9af4e",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "ambodisakoana avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0664716,
          lng: 47.6504382,
        },
        _id: "64f75285ef8f606073e9af4f",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "ankijanimadiro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0684973,
          lng: 47.581581,
        },
        _id: "64f75285ef8f606073e9af50",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "ambodisakoana I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0920126,
          lng: 47.6012453,
        },
        _id: "64f75285ef8f606073e9af51",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -16.106716,
          lng: 47.6135392,
        },
        _id: "64f75285ef8f606073e9af52",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "ampijoroana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1046625,
          lng: 47.557011,
        },
        _id: "64f75285ef8f606073e9af53",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "miadanasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0679091,
          lng: 47.7144578,
        },
        _id: "64f75285ef8f606073e9af54",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "amparihimanonga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1661237,
          lng: 47.6553601,
        },
        _id: "64f75285ef8f606073e9af55",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "antanambao II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1043254,
          lng: 47.7292426,
        },
        _id: "64f75285ef8f606073e9af56",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1436629,
          lng: 47.62091700000001,
        },
        _id: "64f75285ef8f606073e9af57",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "ambatomay",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1608673,
          lng: 47.557011,
        },
        _id: "64f75285ef8f606073e9af58",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1704756,
          lng: 47.59632850000001,
        },
        _id: "64f75285ef8f606073e9af59",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "maevatananakely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1558515,
          lng: 47.7095305,
        },
        _id: "64f75285ef8f606073e9af5a",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "antsirasira I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.226734,
          lng: 47.59632850000001,
        },
        _id: "64f75285ef8f606073e9af5b",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "ambalavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2511139,
          lng: 47.5619241,
        },
        _id: "64f75285ef8f606073e9af5c",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "ampondralava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2717623,
          lng: 47.66520509999999,
        },
        _id: "64f75285ef8f606073e9af5d",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "analabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.365055,
          lng: 47.58649639999999,
        },
        _id: "64f75285ef8f606073e9af5e",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3136094,
          lng: 47.778555,
        },
        _id: "64f75285ef8f606073e9af5f",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "matsaborimena",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4415274,
          lng: 47.7440315,
        },
        _id: "64f75285ef8f606073e9af60",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "antanambao radadiny",
        __v: 0,
      },
      {
        coords: {
          lat: -16.333333,
          lng: 47.73333299999999,
        },
        _id: "64f75285ef8f606073e9af61",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "bekobany",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3689733,
          lng: 47.7933577,
        },
        _id: "64f75285ef8f606073e9af62",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "mampikony II",
        fokontany: "ambodisakoana II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0149127,
          lng: 47.6488903,
        },
        _id: "64f75285ef8f606073e9af63",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "ambohitoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8440723,
          lng: 47.6602824,
        },
        _id: "64f75285ef8f606073e9af64",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "tsangambato nord",
        __v: 0,
      },
      {
        coords: {
          lat: -15.852823,
          lng: 47.6479775,
        },
        _id: "64f75285ef8f606073e9af65",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "mahasoanitratsy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8767869,
          lng: 47.640596,
        },
        _id: "64f75285ef8f606073e9af66",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "andongona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.889544,
          lng: 47.59632850000001,
        },
        _id: "64f75285ef8f606073e9af67",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "bekidony",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9094922,
          lng: 47.62091700000001,
        },
        _id: "64f75285ef8f606073e9af68",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "andranomadio",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9229666,
          lng: 47.6282957,
        },
        _id: "64f75285ef8f606073e9af69",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "betaimborona",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9357622,
          lng: 47.6037038,
        },
        _id: "64f75285ef8f606073e9af6a",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "andongona zamiava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9384137,
          lng: 47.6098507,
        },
        _id: "64f75285ef8f606073e9af6b",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "tsararivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9493024,
          lng: 47.6307555,
        },
        _id: "64f75285ef8f606073e9af6c",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "tanetilava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9589775,
          lng: 47.6369056,
        },
        _id: "64f75285ef8f606073e9af6d",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "andilambe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.956979,
          lng: 47.6528991,
        },
        _id: "64f75285ef8f606073e9af6e",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "ankijanimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9768324,
          lng: 47.63813570000001,
        },
        _id: "64f75285ef8f606073e9af6f",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "ampandroangisa",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9751495,
          lng: 47.6602824,
        },
        _id: "64f75285ef8f606073e9af70",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "ambodisatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9920692,
          lng: 47.6430564,
        },
        _id: "64f75285ef8f606073e9af71",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "labandikely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9986378,
          lng: 47.6799761,
        },
        _id: "64f75285ef8f606073e9af72",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "antsirasira II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.016667,
          lng: 47.59999999999999,
        },
        _id: "64f75285ef8f606073e9af73",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "tsimijaly",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0149127,
          lng: 47.6488903,
        },
        _id: "64f75285ef8f606073e9af74",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "ankijanibe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0168903,
          lng: 47.707067,
        },
        _id: "64f75285ef8f606073e9af75",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "andranomiditra I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0361283,
          lng: 47.6799761,
        },
        _id: "64f75285ef8f606073e9af76",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "soberaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0237428,
          lng: 47.6578212,
        },
        _id: "64f75285ef8f606073e9af77",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambohitoaka",
        fokontany: "antsohikely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8241505,
          lng: 47.6356755,
        },
        _id: "64f75285ef8f606073e9af78",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ankiririky",
        fokontany: "ankiririky",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8089444,
          lng: 47.6307555,
        },
        _id: "64f75285ef8f606073e9af79",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ankiririky",
        fokontany: "ankaraobato",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8013691,
          lng: 47.6479775,
        },
        _id: "64f75285ef8f606073e9af7a",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ankiririky",
        fokontany: "sarodrano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8230043,
          lng: 47.6504382,
        },
        _id: "64f75285ef8f606073e9af7b",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ankiririky",
        fokontany: "bemakamba",
        __v: 0,
      },
      {
        coords: {
          lat: -16.10758,
          lng: 47.49065,
        },
        _id: "64f75285ef8f606073e9af7c",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "bekoratsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0631837,
          lng: 47.2728413,
        },
        _id: "64f75285ef8f606073e9af7d",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "marosakoana II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0310403,
          lng: 47.581581,
        },
        _id: "64f75285ef8f606073e9af7e",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "andilamihoatra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.04081,
          lng: 47.506531,
        },
        _id: "64f75285ef8f606073e9af7f",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "befanihy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.023888,
          lng: 47.5520984,
        },
        _id: "64f75285ef8f606073e9af80",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "ambodimadiro IV",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0763634,
          lng: 47.5177229,
        },
        _id: "64f75285ef8f606073e9af81",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "andraijoro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0428634,
          lng: 47.380447,
        },
        _id: "64f75285ef8f606073e9af82",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "ampombimanangy I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0620076,
          lng: 47.4588461,
        },
        _id: "64f75285ef8f606073e9af83",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1002532,
          lng: 47.404934,
        },
        _id: "64f75285ef8f606073e9af84",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "ampombimanangy II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1004993,
          lng: 47.4441373,
        },
        _id: "64f75285ef8f606073e9af85",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "ankazomibaboka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1162828,
          lng: 47.3559715,
        },
        _id: "64f75285ef8f606073e9af86",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1234944,
          lng: 47.3853435,
        },
        _id: "64f75285ef8f606073e9af87",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "tsaratananakely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.130963,
          lng: 47.4539427,
        },
        _id: "64f75285ef8f606073e9af88",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "andrafiakely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1309285,
          lng: 47.2826144,
        },
        _id: "64f75285ef8f606073e9af89",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "antanivaky",
        __v: 0,
      },
      {
        coords: {
          lat: -16.141991,
          lng: 47.40273999999999,
        },
        _id: "64f75285ef8f606073e9af8a",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1737133,
          lng: 47.380447,
        },
        _id: "64f75285ef8f606073e9af8b",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "antanambao I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1779372,
          lng: 47.3217253,
        },
        _id: "64f75285ef8f606073e9af8c",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "antanimora",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2046039,
          lng: 47.2972775,
        },
        _id: "64f75285ef8f606073e9af8d",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1956801,
          lng: 47.5079055,
        },
        _id: "64f75285ef8f606073e9af8e",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "ambodimadiro II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2352762,
          lng: 47.4784643,
        },
        _id: "64f75285ef8f606073e9af8f",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "amboronazy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2291768,
          lng: 47.3021661,
        },
        _id: "64f75285ef8f606073e9af90",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "bekoratsaka",
        fokontany: "antsatramanera",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3359142,
          lng: 47.4686543,
        },
        _id: "64f75285ef8f606073e9af91",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "malakialina",
        fokontany: "malakialina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2298316,
          lng: 47.380447,
        },
        _id: "64f75285ef8f606073e9af92",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "malakialina",
        fokontany: "maroambanja",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2602017,
          lng: 47.5226323,
        },
        _id: "64f75285ef8f606073e9af93",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "malakialina",
        fokontany: "mahiagogo II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2737993,
          lng: 47.4637499,
        },
        _id: "64f75285ef8f606073e9af94",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "malakialina",
        fokontany: "ambodimadiro III",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2623207,
          lng: 47.4931828,
        },
        _id: "64f75285ef8f606073e9af95",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "malakialina",
        fokontany: "mahiagogo I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2703685,
          lng: 47.4024848,
        },
        _id: "64f75285ef8f606073e9af96",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "malakialina",
        fokontany: "ambodirafia",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2683002,
          lng: 47.3657603,
        },
        _id: "64f75285ef8f606073e9af97",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "malakialina",
        fokontany: "mahatazana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2868341,
          lng: 47.4122824,
        },
        _id: "64f75285ef8f606073e9af98",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "malakialina",
        fokontany: "morafeno bepatso",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3150759,
          lng: 47.4980899,
        },
        _id: "64f75285ef8f606073e9af99",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "malakialina",
        fokontany: "tsimahajao",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2946302,
          lng: 47.4343337,
        },
        _id: "64f75285ef8f606073e9af9a",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "malakialina",
        fokontany: "marosakoana I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3046926,
          lng: 47.380447,
        },
        _id: "64f75285ef8f606073e9af9b",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "malakialina",
        fokontany: "labandy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3713303,
          lng: 47.4980899,
        },
        _id: "64f75285ef8f606073e9af9c",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "malakialina",
        fokontany: "ampisaraha",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3803474,
          lng: 47.4588461,
        },
        _id: "64f75285ef8f606073e9af9d",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "malakialina",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9710744,
          lng: 47.5079055,
        },
        _id: "64f75285ef8f606073e9af9e",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "betaramahamay",
        fokontany: "betaramahamay",
        __v: 0,
      },
      {
        coords: {
          lat: -15.922227,
          lng: 47.5766661,
        },
        _id: "64f75285ef8f606073e9af9f",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "betaramahamay",
        fokontany: "antsangabitika",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8985269,
          lng: 47.4784643,
        },
        _id: "64f75285ef8f606073e9afa0",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "betaramahamay",
        fokontany: "anjiamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9127477,
          lng: 47.5373633,
        },
        _id: "64f75285ef8f606073e9afa1",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "betaramahamay",
        fokontany: "ambodiriana I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9325638,
          lng: 47.5226323,
        },
        _id: "64f75285ef8f606073e9afa2",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "betaramahamay",
        fokontany: "ankirajibe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9776837,
          lng: 47.4196318,
        },
        _id: "64f75285ef8f606073e9afa3",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "betaramahamay",
        fokontany: "antanambao III",
        __v: 0,
      },
      {
        coords: {
          lat: -15.960766,
          lng: 47.5619241,
        },
        _id: "64f75285ef8f606073e9afa4",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "betaramahamay",
        fokontany: "bemikondry",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9889898,
          lng: 47.6012453,
        },
        _id: "64f75285ef8f606073e9afa5",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "betaramahamay",
        fokontany: "ambahatra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9967464,
          lng: 47.4980899,
        },
        _id: "64f75285ef8f606073e9afa6",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "betaramahamay",
        fokontany: "ampombimihantona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.198601,
          lng: 47.78651,
        },
        _id: "64f75285ef8f606073e9afa7",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "komajia",
        fokontany: "komajia",
        __v: 0,
      },
      {
        coords: {
          lat: -16.085453,
          lng: 47.78128599999999,
        },
        _id: "64f75285ef8f606073e9afa8",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "komajia",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.152545,
          lng: 47.7538931,
        },
        _id: "64f75285ef8f606073e9afa9",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "komajia",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1904926,
          lng: 47.8328517,
        },
        _id: "64f75285ef8f606073e9afaa",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "komajia",
        fokontany: "ambohimiandra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1982007,
          lng: 47.7292426,
        },
        _id: "64f75285ef8f606073e9afab",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "komajia",
        fokontany: "andranomiditra II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2937168,
          lng: 47.7933577,
        },
        _id: "64f75285ef8f606073e9afac",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "komajia",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2381748,
          lng: 47.7391014,
        },
        _id: "64f75285ef8f606073e9afad",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "komajia",
        fokontany: "andembinialifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2377051,
          lng: 47.872375,
        },
        _id: "64f75285ef8f606073e9afae",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "komajia",
        fokontany: "ambatomiankinkely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3033586,
          lng: 47.8328517,
        },
        _id: "64f75285ef8f606073e9afaf",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "komajia",
        fokontany: "ankisompy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3038013,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e9afb0",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "komajia",
        fokontany: "tsararano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9302632,
          lng: 47.7366365,
        },
        _id: "64f75285ef8f606073e9afb1",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ampasimatera",
        fokontany: "ambodisaina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8903014,
          lng: 47.6676666,
        },
        _id: "64f75285ef8f606073e9afb2",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ampasimatera",
        fokontany: "belaingondroy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8856612,
          lng: 47.6873631,
        },
        _id: "64f75285ef8f606073e9afb3",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ampasimatera",
        fokontany: "antsiraka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8892735,
          lng: 47.7415664,
        },
        _id: "64f75285ef8f606073e9afb4",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ampasimatera",
        fokontany: "amporingamena",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9073225,
          lng: 47.6898257,
        },
        _id: "64f75285ef8f606073e9afb5",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ampasimatera",
        fokontany: "beagoago",
        __v: 0,
      },
      {
        coords: {
          lat: -15.8933142,
          lng: 47.7095305,
        },
        _id: "64f75285ef8f606073e9afb6",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ampasimatera",
        fokontany: "anjiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9160249,
          lng: 47.6578212,
        },
        _id: "64f75285ef8f606073e9afb7",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ampasimatera",
        fokontany: "ambodimanga I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9215021,
          lng: 47.7489621,
        },
        _id: "64f75285ef8f606073e9afb8",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ampasimatera",
        fokontany: "ambodisakoana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9208488,
          lng: 47.7169217,
        },
        _id: "64f75285ef8f606073e9afb9",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ampasimatera",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9284212,
          lng: 47.6996772,
        },
        _id: "64f75285ef8f606073e9afba",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ampasimatera",
        fokontany: "antsiradrano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9403324,
          lng: 47.78842299999999,
        },
        _id: "64f75285ef8f606073e9afbb",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ampasimatera",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9400538,
          lng: 47.67012829999999,
        },
        _id: "64f75285ef8f606073e9afbc",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ampasimatera",
        fokontany: "antsakoamaro",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9352341,
          lng: 47.7329394,
        },
        _id: "64f75285ef8f606073e9afbd",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ampasimatera",
        fokontany: "ampasimatera sud",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9541596,
          lng: 47.6898257,
        },
        _id: "64f75285ef8f606073e9afbe",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ampasimatera",
        fokontany: "anjiamavo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0001231,
          lng: 47.7834888,
        },
        _id: "64f75285ef8f606073e9afbf",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ampasimatera",
        fokontany: "ampanihy I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0261185,
          lng: 47.8526097,
        },
        _id: "64f75285ef8f606073e9afc0",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ampasimatera",
        fokontany: "ambodiriana II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.053261,
          lng: 47.91498199999999,
        },
        _id: "64f75285ef8f606073e9afc1",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambodihazoambo",
        fokontany: "ambodihazoambo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0767139,
          lng: 47.88720379999999,
        },
        _id: "64f75285ef8f606073e9afc2",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambodihazoambo",
        fokontany: "ambolobe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9969476,
          lng: 47.9465602,
        },
        _id: "64f75285ef8f606073e9afc3",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambodihazoambo",
        fokontany: "antanimbaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0215574,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e9afc4",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambodihazoambo",
        fokontany: "androihavitra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.016667,
          lng: 47.98333299999999,
        },
        _id: "64f75285ef8f606073e9afc5",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambodihazoambo",
        fokontany: "maromaniry",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0534427,
          lng: 47.9861678,
        },
        _id: "64f75285ef8f606073e9afc6",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambodihazoambo",
        fokontany: "beamalona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.0502743,
          lng: 47.8847321,
        },
        _id: "64f75285ef8f606073e9afc7",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambodihazoambo",
        fokontany: "ambodimanga II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.092222,
          lng: 47.9713115,
        },
        _id: "64f75285ef8f606073e9afc8",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambodihazoambo",
        fokontany: "andranofasika I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1012761,
          lng: 47.8526097,
        },
        _id: "64f75285ef8f606073e9afc9",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambodihazoambo",
        fokontany: "ampombotsihanaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1041921,
          lng: 48.0605108,
        },
        _id: "64f75285ef8f606073e9afca",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambodihazoambo",
        fokontany: "andranofasika II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1298657,
          lng: 47.9713115,
        },
        _id: "64f75285ef8f606073e9afcb",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambodihazoambo",
        fokontany: "anovilava nord",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1449129,
          lng: 47.897092,
        },
        _id: "64f75285ef8f606073e9afcc",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambodihazoambo",
        fokontany: "bebakoly",
        __v: 0,
      },
      {
        coords: {
          lat: -16.151951,
          lng: 47.977291,
        },
        _id: "64f75285ef8f606073e9afcd",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambodihazoambo",
        fokontany: "anovilava sud",
        __v: 0,
      },
      {
        coords: {
          lat: -16.151161,
          lng: 47.9181104,
        },
        _id: "64f75285ef8f606073e9afce",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambodihazoambo",
        fokontany: "andrafiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.162746,
          lng: 47.95150959999999,
        },
        _id: "64f75285ef8f606073e9afcf",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambodihazoambo",
        fokontany: "ambalavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.1731318,
          lng: 47.897092,
        },
        _id: "64f75285ef8f606073e9afd0",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambodihazoambo",
        fokontany: "antafiangita",
        __v: 0,
      },
      {
        coords: {
          lat: -16.216667,
          lng: 48.016667,
        },
        _id: "64f75285ef8f606073e9afd1",
        province: "mahajanga",
        region: "sofia",
        district: "mampikony",
        commune: "ambodihazoambo",
        fokontany: "andranolava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9489839,
          lng: 46.828311,
        },
        _id: "64f75285ef8f606073e9afd2",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana I",
        fokontany: "andranomangatsiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9355936,
          lng: 46.8373937,
        },
        _id: "64f75285ef8f606073e9afd3",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana I",
        fokontany: "antaninandro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9424029,
          lng: 46.8277055,
        },
        _id: "64f75285ef8f606073e9afd4",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana I",
        fokontany: "anosikely avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9449363,
          lng: 46.8373937,
        },
        _id: "64f75285ef8f606073e9afd5",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana I",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9498255,
          lng: 46.8337604,
        },
        _id: "64f75285ef8f606073e9afd6",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana I",
        fokontany: "morafenokely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9538809,
          lng: 46.81801919999999,
        },
        _id: "64f75285ef8f606073e9afd7",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana I",
        fokontany: "ambatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9659758,
          lng: 46.824073,
        },
        _id: "64f75285ef8f606073e9afd8",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana I",
        fokontany: "anosy kely atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9410948,
          lng: 46.8495065,
        },
        _id: "64f75285ef8f606073e9afd9",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana I",
        fokontany: "ambalarano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9657886,
          lng: 46.8010727,
        },
        _id: "64f75285ef8f606073e9afda",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana I",
        fokontany: "ambodimanary",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9759384,
          lng: 46.839816,
        },
        _id: "64f75285ef8f606073e9afdb",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana I",
        fokontany: "androfiamadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9780868,
          lng: 46.81680859999999,
        },
        _id: "64f75285ef8f606073e9afdc",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana I",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0583715,
          lng: 46.815598,
        },
        _id: "64f75285ef8f606073e9afdd",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "beanana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.900756,
          lng: 46.8470837,
        },
        _id: "64f75285ef8f606073e9afde",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "manganoro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8902082,
          lng: 46.815598,
        },
        _id: "64f75285ef8f606073e9afdf",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9393444,
          lng: 46.8785892,
        },
        _id: "64f75285ef8f606073e9afe0",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "andramy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9358816,
          lng: 46.9101143,
        },
        _id: "64f75285ef8f606073e9afe1",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "ambodiroka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9372512,
          lng: 46.8616223,
        },
        _id: "64f75285ef8f606073e9afe2",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "ambatokely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8954443,
          lng: 46.7285093,
        },
        _id: "64f75285ef8f606073e9afe3",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "antetezana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9198778,
          lng: 46.839816,
        },
        _id: "64f75285ef8f606073e9afe4",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "antsakoabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9172976,
          lng: 46.8567756,
        },
        _id: "64f75285ef8f606073e9afe5",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "mahatsinjo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9119229,
          lng: 46.9707947,
        },
        _id: "64f75285ef8f606073e9afe6",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "ambodimanga betsiboka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9445742,
          lng: 46.7913915,
        },
        _id: "64f75285ef8f606073e9afe7",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "bemaharivo andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9480548,
          lng: 46.6801919,
        },
        _id: "64f75285ef8f606073e9afe8",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "andrafiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.983647,
          lng: 46.7623591,
        },
        _id: "64f75285ef8f606073e9afe9",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "marofototra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9802303,
          lng: 46.7938116,
        },
        _id: "64f75285ef8f606073e9afea",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "maroaboaly",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9963533,
          lng: 46.8107557,
        },
        _id: "64f75285ef8f606073e9afeb",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "antsakoamamy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.99637,
          lng: 46.7841318,
        },
        _id: "64f75285ef8f606073e9afec",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "bemoroma",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0150519,
          lng: 46.7841318,
        },
        _id: "64f75285ef8f606073e9afed",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "antsirasira",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0260738,
          lng: 46.83497149999999,
        },
        _id: "64f75285ef8f606073e9afee",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "antsatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0105807,
          lng: 46.9902277,
        },
        _id: "64f75285ef8f606073e9afef",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "bemaharivo est",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0286389,
          lng: 46.7913915,
        },
        _id: "64f75285ef8f606073e9aff0",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "androfiakely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0239717,
          lng: 46.7647779,
        },
        _id: "64f75285ef8f606073e9aff1",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "antafia",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0583287,
          lng: 46.9222445,
        },
        _id: "64f75285ef8f606073e9aff2",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "ambalaranobe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0716886,
          lng: 47.11672369999999,
        },
        _id: "64f75285ef8f606073e9aff3",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "antanimbaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1008749,
          lng: 46.83497149999999,
        },
        _id: "64f75285ef8f606073e9aff4",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maevatanana II",
        fokontany: "andranobevava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8780835,
          lng: 46.91254010000001,
        },
        _id: "64f75285ef8f606073e9aff5",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "beratsimanina",
        fokontany: "beratsimanina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8423183,
          lng: 46.9368047,
        },
        _id: "64f75285ef8f606073e9aff6",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "beratsimanina",
        fokontany: "ankaramainty",
        __v: 0,
      },
      {
        coords: {
          lat: -16.839079,
          lng: 46.8882871,
        },
        _id: "64f75285ef8f606073e9aff7",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "beratsimanina",
        fokontany: "andavakakoho",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8527239,
          lng: 46.8688931,
        },
        _id: "64f75285ef8f606073e9aff8",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "beratsimanina",
        fokontany: "marosakoa nord",
        __v: 0,
      },
      {
        coords: {
          lat: -16.85,
          lng: 46.9166669,
        },
        _id: "64f75285ef8f606073e9aff9",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "beratsimanina",
        fokontany: "ambavahadiala",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8619899,
          lng: 46.8955618,
        },
        _id: "64f75285ef8f606073e9affa",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "beratsimanina",
        fokontany: "bemangahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8967787,
          lng: 46.91254010000001,
        },
        _id: "64f75285ef8f606073e9affb",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "beratsimanina",
        fokontany: "ambanjabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8668039,
          lng: 46.8422385,
        },
        _id: "64f75285ef8f606073e9affc",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "beratsimanina",
        fokontany: "maroandravina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8782246,
          lng: 46.8591989,
        },
        _id: "64f75285ef8f606073e9affd",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "beratsimanina",
        fokontany: "ambalatevana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0345616,
          lng: 46.6898516,
        },
        _id: "64f75285ef8f606073e9affe",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "bemokotra",
        fokontany: "bemokotra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9938312,
          lng: 46.64157160000001,
        },
        _id: "64f75285ef8f606073e9afff",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "bemokotra",
        fokontany: "mahatsinjo komadio",
        __v: 0,
      },
      {
        coords: {
          lat: -17.017602,
          lng: 46.71400920000001,
        },
        _id: "64f75285ef8f606073e9b000",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "bemokotra",
        fokontany: "mavoroa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.03248,
          lng: 46.747639,
        },
        _id: "64f75285ef8f606073e9b001",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "bemokotra",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0345205,
          lng: 46.6367462,
        },
        _id: "64f75285ef8f606073e9b002",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "bemokotra",
        fokontany: "ambodisakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0439212,
          lng: 46.7430136,
        },
        _id: "64f75285ef8f606073e9b003",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "bemokotra",
        fokontany: "maevarano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0421789,
          lng: 46.6657058,
        },
        _id: "64f75285ef8f606073e9b004",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "bemokotra",
        fokontany: "anosikely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0804419,
          lng: 46.7575221,
        },
        _id: "64f75285ef8f606073e9b005",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "bemokotra",
        fokontany: "anjiakely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1126987,
          lng: 46.7381783,
        },
        _id: "64f75285ef8f606073e9b006",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "bemokotra",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.16942,
          lng: 46.569832,
        },
        _id: "64f75285ef8f606073e9b007",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahazoma",
        fokontany: "mahazoma",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0309995,
          lng: 46.5355198,
        },
        _id: "64f75285ef8f606073e9b008",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahazoma",
        fokontany: "bokarano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.146591,
          lng: 46.6898516,
        },
        _id: "64f75285ef8f606073e9b009",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahazoma",
        fokontany: "tsaratananakely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1055378,
          lng: 46.5355198,
        },
        _id: "64f75285ef8f606073e9b00a",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahazoma",
        fokontany: "mahatsinjo valala",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1379622,
          lng: 46.6222727,
        },
        _id: "64f75285ef8f606073e9b00b",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahazoma",
        fokontany: "bemangoraka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1310732,
          lng: 46.57887729999999,
        },
        _id: "64f75285ef8f606073e9b00c",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahazoma",
        fokontany: "ankadibe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1444263,
          lng: 46.5066359,
        },
        _id: "64f75285ef8f606073e9b00d",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahazoma",
        fokontany: "maromavo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1793139,
          lng: 46.5499681,
        },
        _id: "64f75285ef8f606073e9b00e",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahazoma",
        fokontany: "maroboa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1817061,
          lng: 46.5066359,
        },
        _id: "64f75285ef8f606073e9b00f",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahazoma",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2531495,
          lng: 46.5644206,
        },
        _id: "64f75285ef8f606073e9b010",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahazoma",
        fokontany: "marosakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2483816,
          lng: 46.6512239,
        },
        _id: "64f75285ef8f606073e9b011",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahazoma",
        fokontany: "ankaboka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2979999,
          lng: 46.59417,
        },
        _id: "64f75285ef8f606073e9b012",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahazoma",
        fokontany: "anahidrano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4457357,
          lng: 46.6898516,
        },
        _id: "64f75285ef8f606073e9b013",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahazoma",
        fokontany: "antanimbaritsara sud",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4787746,
          lng: 46.5355198,
        },
        _id: "64f75285ef8f606073e9b014",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahazoma",
        fokontany: "tsimandaingo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3047037,
          lng: 46.9550108,
        },
        _id: "64f75285ef8f606073e9b015",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antsiafabositra",
        fokontany: "antsiafabositra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.18063,
          lng: 47.00948,
        },
        _id: "64f75285ef8f606073e9b016",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antsiafabositra",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2389259,
          lng: 47.0388429,
        },
        _id: "64f75285ef8f606073e9b017",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antsiafabositra",
        fokontany: "bemanevika",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2302933,
          lng: 46.9173921,
        },
        _id: "64f75285ef8f606073e9b018",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antsiafabositra",
        fokontany: "marotaolana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.286301,
          lng: 47.183998,
        },
        _id: "64f75285ef8f606073e9b019",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antsiafabositra",
        fokontany: "manolomanana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3086649,
          lng: 46.91254010000001,
        },
        _id: "64f75285ef8f606073e9b01a",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antsiafabositra",
        fokontany: "marovotry",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3684606,
          lng: 46.9610809,
        },
        _id: "64f75285ef8f606073e9b01b",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antsiafabositra",
        fokontany: "begisa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.383333,
          lng: 46.9166669,
        },
        _id: "64f75285ef8f606073e9b01c",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antsiafabositra",
        fokontany: "marokoloy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4267046,
          lng: 47.0388429,
        },
        _id: "64f75285ef8f606073e9b01d",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antsiafabositra",
        fokontany: "anosipotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3629673,
          lng: 46.83497149999999,
        },
        _id: "64f75285ef8f606073e9b01e",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antsiafabositra",
        fokontany: "ampandrianakanga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.406349,
          lng: 47.1808509,
        },
        _id: "64f75285ef8f606073e9b01f",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antsiafabositra",
        fokontany: "antanetibe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4243781,
          lng: 46.8543525,
        },
        _id: "64f75285ef8f606073e9b020",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antsiafabositra",
        fokontany: "ampotaka andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4223427,
          lng: 46.7768732,
        },
        _id: "64f75285ef8f606073e9b021",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antsiafabositra",
        fokontany: "tsaratanimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4435385,
          lng: 46.9610809,
        },
        _id: "64f75285ef8f606073e9b022",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antsiafabositra",
        fokontany: "ampisavankaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.487406,
          lng: 46.8446611,
        },
        _id: "64f75285ef8f606073e9b023",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antsiafabositra",
        fokontany: "ambatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4531066,
          lng: 47.01452949999999,
        },
        _id: "64f75285ef8f606073e9b024",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antsiafabositra",
        fokontany: "bekaratsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4718944,
          lng: 47.01452949999999,
        },
        _id: "64f75285ef8f606073e9b025",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antsiafabositra",
        fokontany: "tsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.184134,
          lng: 46.85132369999999,
        },
        _id: "64f75285ef8f606073e9b026",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antanimbary",
        fokontany: "antanimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1450246,
          lng: 46.7720347,
        },
        _id: "64f75285ef8f606073e9b027",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antanimbary",
        fokontany: "marosakoa est",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2248499,
          lng: 46.7381783,
        },
        _id: "64f75285ef8f606073e9b028",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antanimbary",
        fokontany: "antanimbaritsara G",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2896634,
          lng: 46.805914,
        },
        _id: "64f75285ef8f606073e9b029",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "antanimbary",
        fokontany: "jaomavo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -17.314697,
          lng: 46.3432781,
        },
        _id: "64f75285ef8f606073e9b02a",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "tsararano",
        fokontany: "tsararano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.667013,
          lng: 46.9999471,
        },
        _id: "64f75285ef8f606073e9b02b",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "tsararano",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -16.690028,
          lng: 46.946442,
        },
        _id: "64f75285ef8f606073e9b02c",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "tsararano",
        fokontany: "bevilany",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6962577,
          lng: 46.9319509,
        },
        _id: "64f75285ef8f606073e9b02d",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "tsararano",
        fokontany: "antsohery",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7190773,
          lng: 46.9525829,
        },
        _id: "64f75285ef8f606073e9b02e",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "tsararano",
        fokontany: "antongobe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.728353,
          lng: 46.9659376,
        },
        _id: "64f75285ef8f606073e9b02f",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "tsararano",
        fokontany: "begogo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7525051,
          lng: 46.9780812,
        },
        _id: "64f75285ef8f606073e9b030",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "tsararano",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7725707,
          lng: 46.9562248,
        },
        _id: "64f75285ef8f606073e9b031",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "tsararano",
        fokontany: "bekarara",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8193034,
          lng: 46.9562248,
        },
        _id: "64f75285ef8f606073e9b032",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "tsararano",
        fokontany: "anosikely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8558001,
          lng: 46.9707947,
        },
        _id: "64f75285ef8f606073e9b033",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "tsararano",
        fokontany: "antsampanana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8169835,
          lng: 46.8931368,
        },
        _id: "64f75285ef8f606073e9b034",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "ambalajia",
        fokontany: "anjiakely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7755084,
          lng: 46.8591989,
        },
        _id: "64f75285ef8f606073e9b035",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "ambalajia",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7834705,
          lng: 46.9030533,
        },
        _id: "64f75285ef8f606073e9b036",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "ambalajia",
        fokontany: "ambalajia",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7819075,
          lng: 46.9064758,
        },
        _id: "64f75285ef8f606073e9b037",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "ambalajia",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -16.789662,
          lng: 46.9319509,
        },
        _id: "64f75285ef8f606073e9b038",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "ambalajia",
        fokontany: "amparihibe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7923773,
          lng: 46.8882871,
        },
        _id: "64f75285ef8f606073e9b039",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "ambalajia",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6980043,
          lng: 47.0753348,
        },
        _id: "64f75285ef8f606073e9b03a",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "ambalanjanakomby",
        fokontany: "ambalanjanakomby",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5751399,
          lng: 47.0242535,
        },
        _id: "64f75285ef8f606073e9b03b",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "ambalanjanakomby",
        fokontany: "manaribevato",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6210048,
          lng: 47.0850703,
        },
        _id: "64f75285ef8f606073e9b03c",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "ambalanjanakomby",
        fokontany: "maromalandy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6227903,
          lng: 47.0096682,
        },
        _id: "64f75285ef8f606073e9b03d",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "ambalanjanakomby",
        fokontany: "antsohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6675002,
          lng: 47.1849668,
        },
        _id: "64f75285ef8f606073e9b03e",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "ambalanjanakomby",
        fokontany: "andranofasika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7221544,
          lng: 47.01452949999999,
        },
        _id: "64f75285ef8f606073e9b03f",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "ambalanjanakomby",
        fokontany: "betaikilotra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7227902,
          lng: 47.0534365,
        },
        _id: "64f75285ef8f606073e9b040",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "ambalanjanakomby",
        fokontany: "belahasa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7438144,
          lng: 47.0169603,
        },
        _id: "64f75285ef8f606073e9b041",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "ambalanjanakomby",
        fokontany: "anosindambo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.75,
          lng: 47,
        },
        _id: "64f75285ef8f606073e9b042",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "ambalanjanakomby",
        fokontany: "madirotelo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7736371,
          lng: 47.08750449999999,
        },
        _id: "64f75285ef8f606073e9b043",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "ambalanjanakomby",
        fokontany: "ambalabongo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8640544,
          lng: 47.0388429,
        },
        _id: "64f75285ef8f606073e9b044",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "ambalanjanakomby",
        fokontany: "ambodibonara",
        __v: 0,
      },
      {
        coords: {
          lat: -16.933333,
          lng: 47.25,
        },
        _id: "64f75285ef8f606073e9b045",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "marokoro",
        fokontany: "marokoro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8493028,
          lng: 47.2728413,
        },
        _id: "64f75285ef8f606073e9b046",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "marokoro",
        fokontany: "tsiandrarafa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.921657,
          lng: 47.11672369999999,
        },
        _id: "64f75285ef8f606073e9b047",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "marokoro",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9601234,
          lng: 47.3021661,
        },
        _id: "64f75285ef8f606073e9b048",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "marokoro",
        fokontany: "ankerika",
        __v: 0,
      },
      {
        coords: {
          lat: -17.28335,
          lng: 46.432419,
        },
        _id: "64f75285ef8f606073e9b049",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maria",
        fokontany: "maria",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2376428,
          lng: 46.5066359,
        },
        _id: "64f75285ef8f606073e9b04a",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maria",
        fokontany: "belavenona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2221351,
          lng: 46.4489184,
        },
        _id: "64f75285ef8f606073e9b04b",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maria",
        fokontany: "ankaraobato",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2645873,
          lng: 46.4681501,
        },
        _id: "64f75285ef8f606073e9b04c",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maria",
        fokontany: "anosikely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2935999,
          lng: 46.5066359,
        },
        _id: "64f75285ef8f606073e9b04d",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maria",
        fokontany: "ampanaovato",
        __v: 0,
      },
      {
        coords: {
          lat: -17.314697,
          lng: 46.3432781,
        },
        _id: "64f75285ef8f606073e9b04e",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maria",
        fokontany: "tsararano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3742935,
          lng: 46.3912683,
        },
        _id: "64f75285ef8f606073e9b04f",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "maria",
        fokontany: "bemokotra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6013552,
          lng: 46.9368047,
        },
        _id: "64f75285ef8f606073e9b050",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "andriba",
        fokontany: "mangarivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5130496,
          lng: 46.9489414,
        },
        _id: "64f75285ef8f606073e9b051",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "andriba",
        fokontany: "malatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5394778,
          lng: 47.0388429,
        },
        _id: "64f75285ef8f606073e9b052",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "andriba",
        fokontany: "kamolandy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.52362,
          lng: 46.9270975,
        },
        _id: "64f75285ef8f606073e9b053",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "andriba",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5577856,
          lng: 46.9319509,
        },
        _id: "64f75285ef8f606073e9b054",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "andriba",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5661183,
          lng: 46.8931368,
        },
        _id: "64f75285ef8f606073e9b055",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "andriba",
        fokontany: "ambohitromby",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5682241,
          lng: 46.9707947,
        },
        _id: "64f75285ef8f606073e9b056",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "andriba",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5978186,
          lng: 46.914966,
        },
        _id: "64f75285ef8f606073e9b057",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "andriba",
        fokontany: "mangasoavina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6163681,
          lng: 46.9489414,
        },
        _id: "64f75285ef8f606073e9b058",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "andriba",
        fokontany: "maroharona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.603005,
          lng: 46.7865516,
        },
        _id: "64f75285ef8f606073e9b059",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "andriba",
        fokontany: "antsitabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6105966,
          lng: 46.9101143,
        },
        _id: "64f75285ef8f606073e9b05a",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "andriba",
        fokontany: "ankerana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6329255,
          lng: 46.9319509,
        },
        _id: "64f75285ef8f606073e9b05b",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "andriba",
        fokontany: "fanjavarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.616667,
          lng: 47.016667,
        },
        _id: "64f75285ef8f606073e9b05c",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "andriba",
        fokontany: "ambatovaky",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6973926,
          lng: 46.6512239,
        },
        _id: "64f75285ef8f606073e9b05d",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "andriba",
        fokontany: "antobinimadama",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6148799,
          lng: 46.5547852,
        },
        _id: "64f75285ef8f606073e9b05e",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "andriba",
        fokontany: "beankana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.736845,
          lng: 47.0120988,
        },
        _id: "64f75285ef8f606073e9b05f",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahatsinjo",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6319506,
          lng: 47.0680343,
        },
        _id: "64f75285ef8f606073e9b060",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahatsinjo",
        fokontany: "mamoriarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6614342,
          lng: 46.9853688,
        },
        _id: "64f75285ef8f606073e9b061",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahatsinjo",
        fokontany: "manjakavaradrano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.670481,
          lng: 47.0301509,
        },
        _id: "64f75285ef8f606073e9b062",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahatsinjo",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6936593,
          lng: 47.08750449999999,
        },
        _id: "64f75285ef8f606073e9b063",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahatsinjo",
        fokontany: "andranovelona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.683947,
          lng: 47.0339793,
        },
        _id: "64f75285ef8f606073e9b064",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahatsinjo",
        fokontany: "sahavoara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7080993,
          lng: 46.9319509,
        },
        _id: "64f75285ef8f606073e9b065",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahatsinjo",
        fokontany: "antanambao borikely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7242127,
          lng: 47.043707,
        },
        _id: "64f75285ef8f606073e9b066",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahatsinjo",
        fokontany: "ambatofotsy avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7294661,
          lng: 47.0631679,
        },
        _id: "64f75285ef8f606073e9b067",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahatsinjo",
        fokontany: "ambohitsaratelo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.78257,
          lng: 47.0680343,
        },
        _id: "64f75285ef8f606073e9b068",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahatsinjo",
        fokontany: "miantsoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7885844,
          lng: 46.9513691,
        },
        _id: "64f75285ef8f606073e9b069",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mahatsinjo",
        fokontany: "ampotaka atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.653061,
          lng: 47.23613,
        },
        _id: "64f75285ef8f606073e9b06a",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "morafeno",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5450653,
          lng: 47.1654595,
        },
        _id: "64f75285ef8f606073e9b06b",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "morafeno",
        fokontany: "londokomana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4888124,
          lng: 47.2728413,
        },
        _id: "64f75285ef8f606073e9b06c",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "morafeno",
        fokontany: "antsahalava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6019359,
          lng: 47.2728413,
        },
        _id: "64f75285ef8f606073e9b06d",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "morafeno",
        fokontany: "amboasary",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6914494,
          lng: 47.3608657,
        },
        _id: "64f75285ef8f606073e9b06e",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "morafeno",
        fokontany: "ambohimiandra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7256009,
          lng: 47.3119448,
        },
        _id: "64f75285ef8f606073e9b06f",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "morafeno",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.694937,
          lng: 46.8543525,
        },
        _id: "64f75285ef8f606073e9b070",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mangabe",
        fokontany: "mangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6413157,
          lng: 46.7188421,
        },
        _id: "64f75285ef8f606073e9b071",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mangabe",
        fokontany: "kapingo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6612966,
          lng: 46.7962319,
        },
        _id: "64f75285ef8f606073e9b072",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mangabe",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -16.697188,
          lng: 46.7188421,
        },
        _id: "64f75285ef8f606073e9b073",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mangabe",
        fokontany: "madirobe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7172281,
          lng: 46.7962319,
        },
        _id: "64f75285ef8f606073e9b074",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mangabe",
        fokontany: "merimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7835539,
          lng: 46.7285093,
        },
        _id: "64f75285ef8f606073e9b075",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mangabe",
        fokontany: "kamotro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.731091,
          lng: 46.878681,
        },
        _id: "64f75285ef8f606073e9b076",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mangabe",
        fokontany: "ambinanikely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7443014,
          lng: 46.8107557,
        },
        _id: "64f75285ef8f606073e9b077",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "mangabe",
        fokontany: "anosimiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.83959,
          lng: 46.83744000000001,
        },
        _id: "64f75285ef8f606073e9b078",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "madiromirafy",
        fokontany: "madiromirafy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7866404,
          lng: 46.83012739999999,
        },
        _id: "64f75285ef8f606073e9b079",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "madiromirafy",
        fokontany: "mahalavolona",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8291571,
          lng: 46.7962319,
        },
        _id: "64f75285ef8f606073e9b07a",
        province: "mahajanga",
        region: "betsiboka",
        district: "maevatanana",
        commune: "madiromirafy",
        fokontany: "anjiakely ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -16.793336,
          lng: 47.6492079,
        },
        _id: "64f75285ef8f606073e9b07b",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsaratanana",
        fokontany: "tsaratanana I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7348434,
          lng: 47.6086213,
        },
        _id: "64f75285ef8f606073e9b07c",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsaratanana",
        fokontany: "soamiafara",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7338491,
          lng: 47.6233764,
        },
        _id: "64f75285ef8f606073e9b07d",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsaratanana",
        fokontany: "ambalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7524293,
          lng: 47.6504382,
        },
        _id: "64f75285ef8f606073e9b07e",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsaratanana",
        fokontany: "betanatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7249628,
          lng: 47.8229754,
        },
        _id: "64f75285ef8f606073e9b07f",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsaratanana",
        fokontany: "andoharano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7638331,
          lng: 47.62091700000001,
        },
        _id: "64f75285ef8f606073e9b080",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsaratanana",
        fokontany: "befanoro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7826739,
          lng: 47.6442866,
        },
        _id: "64f75285ef8f606073e9b081",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsaratanana",
        fokontany: "manjarisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7891194,
          lng: 47.6418262,
        },
        _id: "64f75285ef8f606073e9b082",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsaratanana",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8015069,
          lng: 47.62091700000001,
        },
        _id: "64f75285ef8f606073e9b083",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsaratanana",
        fokontany: "anosikely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7849067,
          lng: 47.6578212,
        },
        _id: "64f75285ef8f606073e9b084",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsaratanana",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7997822,
          lng: 47.6467472,
        },
        _id: "64f75285ef8f606073e9b085",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsaratanana",
        fokontany: "antsahavola",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7975531,
          lng: 47.6565906,
        },
        _id: "64f75285ef8f606073e9b086",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsaratanana",
        fokontany: "tsaratanana II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7684989,
          lng: 47.462158,
        },
        _id: "64f75285ef8f606073e9b087",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsaratanana",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.818382,
          lng: 47.6504382,
        },
        _id: "64f75285ef8f606073e9b088",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsaratanana",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8158771,
          lng: 47.8279133,
        },
        _id: "64f75285ef8f606073e9b089",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsaratanana",
        fokontany: "marofatika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7918481,
          lng: 47.6479775,
        },
        _id: "64f75285ef8f606073e9b08a",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsaratanana",
        fokontany: "ambatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8501723,
          lng: 47.8328517,
        },
        _id: "64f75285ef8f606073e9b08b",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsaratanana",
        fokontany: "amparihimaina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8470972,
          lng: 47.59632850000001,
        },
        _id: "64f75285ef8f606073e9b08c",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsaratanana",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9297447,
          lng: 47.9614096,
        },
        _id: "64f75285ef8f606073e9b08d",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsaratanana",
        fokontany: "betafo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7412809,
          lng: 47.5594675,
        },
        _id: "64f75285ef8f606073e9b08e",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "bekapaika",
        fokontany: "bekapaika I",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6667974,
          lng: 47.4784643,
        },
        _id: "64f75285ef8f606073e9b08f",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "bekapaika",
        fokontany: "tanambao ambohibola",
        __v: 0,
      },
      {
        coords: {
          lat: -16.65489,
          lng: 47.5619241,
        },
        _id: "64f75285ef8f606073e9b090",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "bekapaika",
        fokontany: "andranomangatsa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6915138,
          lng: 47.5766661,
        },
        _id: "64f75285ef8f606073e9b091",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "bekapaika",
        fokontany: "tsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7276623,
          lng: 47.5520984,
        },
        _id: "64f75285ef8f606073e9b092",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "bekapaika",
        fokontany: "ambodivala",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6954866,
          lng: 47.5177229,
        },
        _id: "64f75285ef8f606073e9b093",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "bekapaika",
        fokontany: "mahareraka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7281393,
          lng: 47.4980899,
        },
        _id: "64f75285ef8f606073e9b094",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "bekapaika",
        fokontany: "ampasindava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7576193,
          lng: 47.5496422,
        },
        _id: "64f75285ef8f606073e9b095",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "bekapaika",
        fokontany: "bekapaika II",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7954801,
          lng: 47.5226323,
        },
        _id: "64f75285ef8f606073e9b096",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "bekapaika",
        fokontany: "ambalafary",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7667951,
          lng: 47.5766661,
        },
        _id: "64f75285ef8f606073e9b097",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "bekapaika",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7375699,
          lng: 47.5914122,
        },
        _id: "64f75285ef8f606073e9b098",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "bekapaika",
        fokontany: "ambalabao",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7865013,
          lng: 47.4686543,
        },
        _id: "64f75285ef8f606073e9b099",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "bekapaika",
        fokontany: "bemakamba",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8317589,
          lng: 47.3510778,
        },
        _id: "64f75285ef8f606073e9b09a",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "bekapaika",
        fokontany: "antsira",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8044503,
          lng: 47.5766661,
        },
        _id: "64f75285ef8f606073e9b09b",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "bekapaika",
        fokontany: "tetezambato",
        __v: 0,
      },
      {
        coords: {
          lat: -16.85,
          lng: 47.433333,
        },
        _id: "64f75285ef8f606073e9b09c",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "bekapaika",
        fokontany: "ampitetika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6437654,
          lng: 47.7243139,
        },
        _id: "64f75285ef8f606073e9b09d",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsararova",
        fokontany: "tsararova",
        __v: 0,
      },
      {
        coords: {
          lat: -16.58773,
          lng: 47.588749,
        },
        _id: "64f75285ef8f606073e9b09e",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsararova",
        fokontany: "matsitso",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6103401,
          lng: 47.5717516,
        },
        _id: "64f75285ef8f606073e9b09f",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsararova",
        fokontany: "ambatomboromahery",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5921564,
          lng: 47.7440315,
        },
        _id: "64f75285ef8f606073e9b0a0",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsararova",
        fokontany: "androfiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6345138,
          lng: 47.6307555,
        },
        _id: "64f75285ef8f606073e9b0a1",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsararova",
        fokontany: "mandanilasiray",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6400064,
          lng: 47.59632850000001,
        },
        _id: "64f75285ef8f606073e9b0a2",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsararova",
        fokontany: "ambohimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6376032,
          lng: 47.813101,
        },
        _id: "64f75285ef8f606073e9b0a3",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsararova",
        fokontany: "tsitiasofina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6672247,
          lng: 47.6110802,
        },
        _id: "64f75285ef8f606073e9b0a4",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsararova",
        fokontany: "ambarijeby",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7048691,
          lng: 47.6110802,
        },
        _id: "64f75285ef8f606073e9b0a5",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsararova",
        fokontany: "miadandririnina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7271117,
          lng: 47.6996772,
        },
        _id: "64f75285ef8f606073e9b0a6",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsararova",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7360649,
          lng: 47.6602824,
        },
        _id: "64f75285ef8f606073e9b0a7",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "tsararova",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.03689,
          lng: 47.549129,
        },
        _id: "64f75285ef8f606073e9b0a8",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "betrandraka",
        fokontany: "betrandraka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9401323,
          lng: 47.5177229,
        },
        _id: "64f75285ef8f606073e9b0a9",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "betrandraka",
        fokontany: "antsirasira",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9569852,
          lng: 47.5606957,
        },
        _id: "64f75285ef8f606073e9b0aa",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "betrandraka",
        fokontany: "mahatazana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0806289,
          lng: 47.79657,
        },
        _id: "64f75285ef8f606073e9b0ab",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "betrandraka",
        fokontany: "andakana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9839187,
          lng: 47.56929450000001,
        },
        _id: "64f75285ef8f606073e9b0ac",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "betrandraka",
        fokontany: "andabonimaholy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0066999,
          lng: 47.557011,
        },
        _id: "64f75285ef8f606073e9b0ad",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "betrandraka",
        fokontany: "ambodiadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9807888,
          lng: 47.5201775,
        },
        _id: "64f75285ef8f606073e9b0ae",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "betrandraka",
        fokontany: "ampanasandamba",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9903267,
          lng: 47.4196318,
        },
        _id: "64f75285ef8f606073e9b0af",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "betrandraka",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0192229,
          lng: 47.4588461,
        },
        _id: "64f75285ef8f606073e9b0b0",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "betrandraka",
        fokontany: "ankisatra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0559664,
          lng: 47.4735591,
        },
        _id: "64f75285ef8f606073e9b0b1",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "betrandraka",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1115779,
          lng: 47.4882762,
        },
        _id: "64f75285ef8f606073e9b0b2",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "betrandraka",
        fokontany: "amboniakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1211878,
          lng: 47.6356755,
        },
        _id: "64f75285ef8f606073e9b0b3",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "betrandraka",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -17.120279,
          lng: 47.7597809,
        },
        _id: "64f75285ef8f606073e9b0b4",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "betrandraka",
        fokontany: "ambolomborona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0210776,
          lng: 47.7243139,
        },
        _id: "64f75285ef8f606073e9b0b5",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "betrandraka",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.266667,
          lng: 47.6666669,
        },
        _id: "64f75285ef8f606073e9b0b6",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "keliloha",
        fokontany: "keliloha",
        __v: 0,
      },
      {
        coords: {
          lat: -17.176081,
          lng: 47.70061099999999,
        },
        _id: "64f75285ef8f606073e9b0b7",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "keliloha",
        fokontany: "ambatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1935027,
          lng: 47.58649639999999,
        },
        _id: "64f75285ef8f606073e9b0b8",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "keliloha",
        fokontany: "amparihitsiritra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2137904,
          lng: 47.66520509999999,
        },
        _id: "64f75285ef8f606073e9b0b9",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "keliloha",
        fokontany: "ankisatra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.269044,
          lng: 47.58649639999999,
        },
        _id: "64f75285ef8f606073e9b0ba",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "keliloha",
        fokontany: "ambatomandondona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2747725,
          lng: 47.6725901,
        },
        _id: "64f75285ef8f606073e9b0bb",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "keliloha",
        fokontany: "ambodivoara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2926756,
          lng: 47.7144578,
        },
        _id: "64f75285ef8f606073e9b0bc",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "keliloha",
        fokontany: "tsaravoatra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3392418,
          lng: 47.675052,
        },
        _id: "64f75285ef8f606073e9b0bd",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "keliloha",
        fokontany: "ambanja",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3188278,
          lng: 47.59632850000001,
        },
        _id: "64f75285ef8f606073e9b0be",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "keliloha",
        fokontany: "ampasiria",
        __v: 0,
      },
      {
        coords: {
          lat: -16.466921,
          lng: 47.323132,
        },
        _id: "64f75285ef8f606073e9b0bf",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sarobaratra",
        fokontany: "sarobaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3649666,
          lng: 47.3217253,
        },
        _id: "64f75285ef8f606073e9b0c0",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sarobaratra",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4113429,
          lng: 47.2826144,
        },
        _id: "64f75285ef8f606073e9b0c1",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sarobaratra",
        fokontany: "fiadanana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4170618,
          lng: 47.380447,
        },
        _id: "64f75285ef8f606073e9b0c2",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sarobaratra",
        fokontany: "maroadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4176676,
          lng: 47.3266162,
        },
        _id: "64f75285ef8f606073e9b0c3",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sarobaratra",
        fokontany: "ambohimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4380888,
          lng: 47.6159983,
        },
        _id: "64f75285ef8f606073e9b0c4",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sarobaratra",
        fokontany: "ambohibengy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.441288,
          lng: 47.3461846,
        },
        _id: "64f75285ef8f606073e9b0c5",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sarobaratra",
        fokontany: "betaramahamay",
        __v: 0,
      },
      {
        coords: {
          lat: -16.551193,
          lng: 47.4294326,
        },
        _id: "64f75285ef8f606073e9b0c6",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sarobaratra",
        fokontany: "androtra est",
        __v: 0,
      },
      {
        coords: {
          lat: -16.465908,
          lng: 47.5766661,
        },
        _id: "64f75285ef8f606073e9b0c7",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sarobaratra",
        fokontany: "morafenokely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5,
          lng: 47.516667,
        },
        _id: "64f75285ef8f606073e9b0c8",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sarobaratra",
        fokontany: "mandanivatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.51898,
          lng: 47.191971,
        },
        _id: "64f75285ef8f606073e9b0c9",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sarobaratra",
        fokontany: "manonga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.542401,
          lng: 47.2826144,
        },
        _id: "64f75285ef8f606073e9b0ca",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sarobaratra",
        fokontany: "morarano mitsakisa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.525118,
          lng: 47.625836,
        },
        _id: "64f75285ef8f606073e9b0cb",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sarobaratra",
        fokontany: "tsarabanja",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5598666,
          lng: 47.5766661,
        },
        _id: "64f75285ef8f606073e9b0cc",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sarobaratra",
        fokontany: "mahiagogo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.557185,
          lng: 47.2484167,
        },
        _id: "64f75285ef8f606073e9b0cd",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sarobaratra",
        fokontany: "masokoamena",
        __v: 0,
      },
      {
        coords: {
          lat: -16.579497,
          lng: 47.6553601,
        },
        _id: "64f75285ef8f606073e9b0ce",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sarobaratra",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6914766,
          lng: 47.3902404,
        },
        _id: "64f75285ef8f606073e9b0cf",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sarobaratra",
        fokontany: "mandiamaiva",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3127,
          lng: 47.836811,
        },
        _id: "64f75285ef8f606073e9b0d0",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ampandrana",
        fokontany: "ampandrana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2145948,
          lng: 47.8526097,
        },
        _id: "64f75285ef8f606073e9b0d1",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ampandrana",
        fokontany: "andranofasika",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3199495,
          lng: 47.7834888,
        },
        _id: "64f75285ef8f606073e9b0d2",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ampandrana",
        fokontany: "anosimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3153366,
          lng: 47.8575503,
        },
        _id: "64f75285ef8f606073e9b0d3",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ampandrana",
        fokontany: "miarikafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3601818,
          lng: 47.84766949999999,
        },
        _id: "64f75285ef8f606073e9b0d4",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ampandrana",
        fokontany: "antsohihy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3411125,
          lng: 47.8748462,
        },
        _id: "64f75285ef8f606073e9b0d5",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ampandrana",
        fokontany: "antanimalandy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2847586,
          lng: 47.9416114,
        },
        _id: "64f75285ef8f606073e9b0d6",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ampandrana",
        fokontany: "antaniditra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.38999,
          lng: 47.89394,
        },
        _id: "64f75285ef8f606073e9b0d7",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ampandrana",
        fokontany: "andranovelona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4580546,
          lng: 47.58649639999999,
        },
        _id: "64f75285ef8f606073e9b0d8",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "andriamena",
        fokontany: "andriamena I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0710961,
          lng: 47.33150759999999,
        },
        _id: "64f75285ef8f606073e9b0d9",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "andriamena",
        fokontany: "anadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1191705,
          lng: 47.2630701,
        },
        _id: "64f75285ef8f606073e9b0da",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "andriamena",
        fokontany: "isinko",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1295707,
          lng: 47.1947232,
        },
        _id: "64f75285ef8f606073e9b0db",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "andriamena",
        fokontany: "soamiarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2149526,
          lng: 47.2337675,
        },
        _id: "64f75285ef8f606073e9b0dc",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "andriamena",
        fokontany: "antsahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2136409,
          lng: 47.4637499,
        },
        _id: "64f75285ef8f606073e9b0dd",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "andriamena",
        fokontany: "ambodiketsa avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2024094,
          lng: 47.4931828,
        },
        _id: "64f75285ef8f606073e9b0de",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "andriamena",
        fokontany: "ambodirotra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.265381,
          lng: 47.509411,
        },
        _id: "64f75285ef8f606073e9b0df",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "andriamena",
        fokontany: "bemavo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3174624,
          lng: 47.4098328,
        },
        _id: "64f75285ef8f606073e9b0e0",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "andriamena",
        fokontany: "ambolodia",
        __v: 0,
      },
      {
        coords: {
          lat: -17.383333,
          lng: 47.48333299999999,
        },
        _id: "64f75285ef8f606073e9b0e1",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "andriamena",
        fokontany: "andravainafo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3806503,
          lng: 47.6159983,
        },
        _id: "64f75285ef8f606073e9b0e2",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "andriamena",
        fokontany: "betanatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4125912,
          lng: 47.5029975,
        },
        _id: "64f75285ef8f606073e9b0e3",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "andriamena",
        fokontany: "anjokozoko",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4340743,
          lng: 47.4588461,
        },
        _id: "64f75285ef8f606073e9b0e4",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "andriamena",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.406633,
          lng: 47.3902404,
        },
        _id: "64f75285ef8f606073e9b0e5",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "andriamena",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5198533,
          lng: 47.3902404,
        },
        _id: "64f75285ef8f606073e9b0e6",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "andriamena",
        fokontany: "ambongamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4709855,
          lng: 47.4735591,
        },
        _id: "64f75285ef8f606073e9b0e7",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "andriamena",
        fokontany: "andriamena II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4881637,
          lng: 47.5029975,
        },
        _id: "64f75285ef8f606073e9b0e8",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "andriamena",
        fokontany: "ambodibonara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.540542,
          lng: 47.4686543,
        },
        _id: "64f75285ef8f606073e9b0e9",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "andriamena",
        fokontany: "ambalabako",
        __v: 0,
      },
      {
        coords: {
          lat: -17.530167,
          lng: 47.53920489999999,
        },
        _id: "64f75285ef8f606073e9b0ea",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "andriamena",
        fokontany: "telomita",
        __v: 0,
      },
      {
        coords: {
          lat: -17.548332,
          lng: 47.5520984,
        },
        _id: "64f75285ef8f606073e9b0eb",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "andriamena",
        fokontany: "manakambahiny",
        __v: 0,
      },
      {
        coords: {
          lat: -17.611145,
          lng: 47.8279133,
        },
        _id: "64f75285ef8f606073e9b0ec",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "betafo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.366667,
          lng: 47.783333,
        },
        _id: "64f75285ef8f606073e9b0ed",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4,
          lng: 47.833333,
        },
        _id: "64f75285ef8f606073e9b0ee",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "andravola",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5163572,
          lng: 47.7736217,
        },
        _id: "64f75285ef8f606073e9b0ef",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5179377,
          lng: 47.8526097,
        },
        _id: "64f75285ef8f606073e9b0f0",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "ambodiamontana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5516364,
          lng: 47.81803800000001,
        },
        _id: "64f75285ef8f606073e9b0f1",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "ambohimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5523304,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e9b0f2",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "ambodivoara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6057622,
          lng: 47.9713115,
        },
        _id: "64f75285ef8f606073e9b0f3",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "ampandriantsara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.591391,
          lng: 47.92223,
        },
        _id: "64f75285ef8f606073e9b0f4",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "sahevo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5978127,
          lng: 47.7791717,
        },
        _id: "64f75285ef8f606073e9b0f5",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "ambohidronono",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5818921,
          lng: 47.7341718,
        },
        _id: "64f75285ef8f606073e9b0f6",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "antanimbaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6465276,
          lng: 47.7095305,
        },
        _id: "64f75285ef8f606073e9b0f7",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "maromaniry",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6258465,
          lng: 47.7391014,
        },
        _id: "64f75285ef8f606073e9b0f8",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "mahamavo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6991613,
          lng: 47.7834888,
        },
        _id: "64f75285ef8f606073e9b0f9",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "ambolosy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.622589,
          lng: 47.84124,
        },
        _id: "64f75285ef8f606073e9b0fa",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "ambakireny",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6162427,
          lng: 47.9020367,
        },
        _id: "64f75285ef8f606073e9b0fb",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "manorotoro",
        __v: 0,
      },
      {
        coords: {
          lat: -17.716667,
          lng: 47.933333,
        },
        _id: "64f75285ef8f606073e9b0fc",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "andranonampanga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7199028,
          lng: 47.8624914,
        },
        _id: "64f75285ef8f606073e9b0fd",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "sahatalelaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8184453,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e9b0fe",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "ambohimahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8551525,
          lng: 47.8797889,
        },
        _id: "64f75285ef8f606073e9b0ff",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "nosibe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.86973,
          lng: 47.867859,
        },
        _id: "64f75285ef8f606073e9b100",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "ambakireny",
        fokontany: "betenina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6776947,
          lng: 47.6344455,
        },
        _id: "64f75285ef8f606073e9b101",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "brieville",
        fokontany: "brieville I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3743848,
          lng: 47.7193856,
        },
        _id: "64f75285ef8f606073e9b102",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "brieville",
        fokontany: "bemololo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4441106,
          lng: 47.7144578,
        },
        _id: "64f75285ef8f606073e9b103",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "brieville",
        fokontany: "mahabe I",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4338567,
          lng: 47.675052,
        },
        _id: "64f75285ef8f606073e9b104",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "brieville",
        fokontany: "beherana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5,
          lng: 47.716667,
        },
        _id: "64f75285ef8f606073e9b105",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "brieville",
        fokontany: "maroadabokely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.516667,
          lng: 47.65,
        },
        _id: "64f75285ef8f606073e9b106",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "brieville",
        fokontany: "antanimbaritsara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5853548,
          lng: 47.675052,
        },
        _id: "64f75285ef8f606073e9b107",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "brieville",
        fokontany: "tombakatsa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6,
          lng: 47.59999999999999,
        },
        _id: "64f75285ef8f606073e9b108",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "brieville",
        fokontany: "ambalanirana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6352244,
          lng: 47.5766661,
        },
        _id: "64f75285ef8f606073e9b109",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "brieville",
        fokontany: "begisa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7334528,
          lng: 47.625836,
        },
        _id: "64f75285ef8f606073e9b10a",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "brieville",
        fokontany: "malamamaina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6568171,
          lng: 47.640596,
        },
        _id: "64f75285ef8f606073e9b10b",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "brieville",
        fokontany: "ambohitranivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.65,
          lng: 47.683333,
        },
        _id: "64f75285ef8f606073e9b10c",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "brieville",
        fokontany: "antsampandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6740747,
          lng: 47.67012829999999,
        },
        _id: "64f75285ef8f606073e9b10d",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "brieville",
        fokontany: "brieville II",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7180284,
          lng: 47.675052,
        },
        _id: "64f75285ef8f606073e9b10e",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "brieville",
        fokontany: "ambodiketsa atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.199591,
          lng: 48.033699,
        },
        _id: "64f75285ef8f606073e9b10f",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sakoamadinika",
        fokontany: "sakoamadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -17.08017,
          lng: 47.887501,
        },
        _id: "64f75285ef8f606073e9b110",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sakoamadinika",
        fokontany: "ambodifiakarana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0973557,
          lng: 48.1002024,
        },
        _id: "64f75285ef8f606073e9b111",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sakoamadinika",
        fokontany: "ambalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0932374,
          lng: 47.9713115,
        },
        _id: "64f75285ef8f606073e9b112",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sakoamadinika",
        fokontany: "beanambalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0773307,
          lng: 48.0208484,
        },
        _id: "64f75285ef8f606073e9b113",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sakoamadinika",
        fokontany: "ambodiadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1763555,
          lng: 48.14985799999999,
        },
        _id: "64f75285ef8f606073e9b114",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sakoamadinika",
        fokontany: "ambatomisakantsaha",
        __v: 0,
      },
      {
        coords: {
          lat: -17.188228,
          lng: 48.0654706,
        },
        _id: "64f75285ef8f606073e9b115",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sakoamadinika",
        fokontany: "anosimiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2042973,
          lng: 48.1101299,
        },
        _id: "64f75285ef8f606073e9b116",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sakoamadinika",
        fokontany: "beandrarezokely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1930649,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e9b117",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sakoamadinika",
        fokontany: "tsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1806799,
          lng: 48.0357184,
        },
        _id: "64f75285ef8f606073e9b118",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sakoamadinika",
        fokontany: "ambinany",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2752445,
          lng: 48.0902768,
        },
        _id: "64f75285ef8f606073e9b119",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sakoamadinika",
        fokontany: "behoraka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3151534,
          lng: 48.0605108,
        },
        _id: "64f75285ef8f606073e9b11a",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "sakoamadinika",
        fokontany: "bevoay",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7684989,
          lng: 47.462158,
        },
        _id: "64f75285ef8f606073e9b11b",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "manakana",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.65111,
          lng: 47.403309,
        },
        _id: "64f75285ef8f606073e9b11c",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "manakana",
        fokontany: "antsatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6797186,
          lng: 47.4588461,
        },
        _id: "64f75285ef8f606073e9b11d",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "manakana",
        fokontany: "amparihimbavy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7071059,
          lng: 47.4196318,
        },
        _id: "64f75285ef8f606073e9b11e",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "manakana",
        fokontany: "antaniditra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7563783,
          lng: 47.3828952,
        },
        _id: "64f75285ef8f606073e9b11f",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "manakana",
        fokontany: "ankerana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7449184,
          lng: 47.4196318,
        },
        _id: "64f75285ef8f606073e9b120",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "manakana",
        fokontany: "vohitrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7279772,
          lng: 47.4980899,
        },
        _id: "64f75285ef8f606073e9b121",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "manakana",
        fokontany: "mandribe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.788365,
          lng: 47.5471862,
        },
        _id: "64f75285ef8f606073e9b122",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "manakana",
        fokontany: "tsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8415559,
          lng: 47.4980899,
        },
        _id: "64f75285ef8f606073e9b123",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "manakana",
        fokontany: "ambohidray",
        __v: 0,
      },
      {
        coords: {
          lat: -17.87464,
          lng: 47.58649639999999,
        },
        _id: "64f75285ef8f606073e9b124",
        province: "mahajanga",
        region: "betsiboka",
        district: "tsaratanana",
        commune: "manakana",
        fokontany: "mahambo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.484221,
          lng: 46.090611,
        },
        _id: "64f75285ef8f606073e9b125",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "kandreho",
        fokontany: "kandreho",
        __v: 0,
      },
      {
        coords: {
          lat: -17.439425,
          lng: 46.0849408,
        },
        _id: "64f75285ef8f606073e9b126",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "kandreho",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5463385,
          lng: 46.0515529,
        },
        _id: "64f75285ef8f606073e9b127",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "kandreho",
        fokontany: "ambanjabe ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -17.560918,
          lng: 46.0706289,
        },
        _id: "64f75285ef8f606073e9b128",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "kandreho",
        fokontany: "antsakoamanera",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5323476,
          lng: 46.0849408,
        },
        _id: "64f75285ef8f606073e9b129",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "kandreho",
        fokontany: "andebadeba",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4485161,
          lng: 46.1517858,
        },
        _id: "64f75285ef8f606073e9b12a",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "kandreho",
        fokontany: "ambalajia",
        __v: 0,
      },
      {
        coords: {
          lat: -17.45,
          lng: 46,
        },
        _id: "64f75285ef8f606073e9b12b",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "ambaliha",
        fokontany: "ambaliha",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3998499,
          lng: 46.0086594,
        },
        _id: "64f75285ef8f606073e9b12c",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "ambaliha",
        fokontany: "bemakamba",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4178691,
          lng: 45.9562857,
        },
        _id: "64f75285ef8f606073e9b12d",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "ambaliha",
        fokontany: "tsaramakaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4364179,
          lng: 45.9562857,
        },
        _id: "64f75285ef8f606073e9b12e",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "ambaliha",
        fokontany: "andebadeba",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4933497,
          lng: 45.99437,
        },
        _id: "64f75285ef8f606073e9b12f",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "ambaliha",
        fokontany: "antafia",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5550205,
          lng: 45.9991326,
        },
        _id: "64f75285ef8f606073e9b130",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "ambaliha",
        fokontany: "sarodrano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4771382,
          lng: 46.3240951,
        },
        _id: "64f75285ef8f606073e9b131",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "behazomaty",
        fokontany: "behazomaty",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4245869,
          lng: 46.3193005,
        },
        _id: "64f75285ef8f606073e9b132",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "behazomaty",
        fokontany: "ambalasatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -17.446289,
          lng: 46.287201,
        },
        _id: "64f75285ef8f606073e9b133",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "behazomaty",
        fokontany: "tsiazonaloka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4981209,
          lng: 46.400872,
        },
        _id: "64f75285ef8f606073e9b134",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "behazomaty",
        fokontany: "tsaratananakely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3179821,
          lng: 46.09925699999999,
        },
        _id: "64f75285ef8f606073e9b135",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "antanimbaribe",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -17.18718,
          lng: 46.042919,
        },
        _id: "64f75285ef8f606073e9b136",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "antanimbaribe",
        fokontany: "bemonto",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3037095,
          lng: 45.8279734,
        },
        _id: "64f75285ef8f606073e9b137",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "antanimbaribe",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2329049,
          lng: 46.2474381,
        },
        _id: "64f75285ef8f606073e9b138",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "antanimbaribe",
        fokontany: "mahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1973235,
          lng: 45.9800848,
        },
        _id: "64f75285ef8f606073e9b139",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "antanimbaribe",
        fokontany: "antanimbaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3474292,
          lng: 46.1900242,
        },
        _id: "64f75285ef8f606073e9b13a",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "antanimbaribe",
        fokontany: "antsatrabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.77071,
          lng: 46.132061,
        },
        _id: "64f75285ef8f606073e9b13b",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "betaimboay",
        fokontany: "betaimboay",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7036753,
          lng: 45.9991326,
        },
        _id: "64f75285ef8f606073e9b13c",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "betaimboay",
        fokontany: "amparihisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8259726,
          lng: 46.0372508,
        },
        _id: "64f75285ef8f606073e9b13d",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "betaimboay",
        fokontany: "betaimboraka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7769272,
          lng: 46.1613426,
        },
        _id: "64f75285ef8f606073e9b13e",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "betaimboay",
        fokontany: "andrafiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8846758,
          lng: 46.1135774,
        },
        _id: "64f75285ef8f606073e9b13f",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "betaimboay",
        fokontany: "androtra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.660311,
          lng: 46.24398,
        },
        _id: "64f75285ef8f606073e9b140",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "andasibe",
        fokontany: "andasibe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5545385,
          lng: 46.2665911,
        },
        _id: "64f75285ef8f606073e9b141",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "andasibe",
        fokontany: "menavavakely",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6474409,
          lng: 46.400872,
        },
        _id: "64f75285ef8f606073e9b142",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "andasibe",
        fokontany: "mahatsinjo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7516134,
          lng: 46.3049196,
        },
        _id: "64f75285ef8f606073e9b143",
        province: "mahajanga",
        region: "betsiboka",
        district: "kandreho",
        commune: "andasibe",
        fokontany: "ambanjabe sud",
        __v: 0,
      },
      {
        coords: {
          lat: -16.691606,
          lng: 44.455935,
        },
        _id: "64f75285ef8f606073e9b144",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "besalampy",
        fokontany: "besalampy ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -16.683333,
          lng: 44.65000000000001,
        },
        _id: "64f75285ef8f606073e9b145",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "besalampy",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6304834,
          lng: 44.6818377,
        },
        _id: "64f75285ef8f606073e9b146",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "besalampy",
        fokontany: "ankasakasakely",
        __v: 0,
      },
      {
        coords: {
          lat: -16.662069,
          lng: 44.582088,
        },
        _id: "64f75285ef8f606073e9b147",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "besalampy",
        fokontany: "antsakoazato",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6462607,
          lng: 44.5219358,
        },
        _id: "64f75285ef8f606073e9b148",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "besalampy",
        fokontany: "anosimboalavo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7452924,
          lng: 44.4865897,
        },
        _id: "64f75285ef8f606073e9b149",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "besalampy",
        fokontany: "besalampy ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7608442,
          lng: 44.6066578,
        },
        _id: "64f75285ef8f606073e9b14a",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "besalampy",
        fokontany: "ambotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8283257,
          lng: 44.493661,
        },
        _id: "64f75285ef8f606073e9b14b",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "besalampy",
        fokontany: "ambalatany",
        __v: 0,
      },
      {
        coords: {
          lat: -16.755051,
          lng: 44.815632,
        },
        _id: "64f75285ef8f606073e9b14c",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ampako",
        fokontany: "ampako",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6898547,
          lng: 44.8832833,
        },
        _id: "64f75285ef8f606073e9b14d",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ampako",
        fokontany: "ampanangana",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6978666,
          lng: 44.9206649,
        },
        _id: "64f75285ef8f606073e9b14e",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ampako",
        fokontany: "ankilahila",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6832689,
          lng: 44.7568965,
        },
        _id: "64f75285ef8f606073e9b14f",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ampako",
        fokontany: "betsotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7519429,
          lng: 44.9066503,
        },
        _id: "64f75285ef8f606073e9b150",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ampako",
        fokontany: "antsakoamaro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7161998,
          lng: 44.8037467,
        },
        _id: "64f75285ef8f606073e9b151",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ampako",
        fokontany: "bezeky",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0309931,
          lng: 44.6254642,
        },
        _id: "64f75285ef8f606073e9b152",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "marovoay sud",
        fokontany: "marovoay sud",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8540172,
          lng: 44.6348645,
        },
        _id: "64f75285ef8f606073e9b153",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "marovoay sud",
        fokontany: "ankilibe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.933333,
          lng: 44.45,
        },
        _id: "64f75285ef8f606073e9b154",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "marovoay sud",
        fokontany: "malandiandro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.95,
          lng: 44.716667,
        },
        _id: "64f75285ef8f606073e9b155",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "marovoay sud",
        fokontany: "anosibe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.081051,
          lng: 44.770821,
        },
        _id: "64f75285ef8f606073e9b156",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "marovoay sud",
        fokontany: "soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.15,
          lng: 44.65000000000001,
        },
        _id: "64f75285ef8f606073e9b157",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "marovoay sud",
        fokontany: "tsiamelara",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5,
          lng: 44.6,
        },
        _id: "64f75285ef8f606073e9b158",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "soanenga",
        fokontany: "soanenga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.2024,
          lng: 44.485432,
        },
        _id: "64f75285ef8f606073e9b159",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "soanenga",
        fokontany: "vilamatsa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3181673,
          lng: 44.4464987,
        },
        _id: "64f75285ef8f606073e9b15a",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "soanenga",
        fokontany: "ankorosy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3514514,
          lng: 44.493661,
        },
        _id: "64f75285ef8f606073e9b15b",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "soanenga",
        fokontany: "ankelilaly",
        __v: 0,
      },
      {
        coords: {
          lat: -16.466667,
          lng: 44.73333299999999,
        },
        _id: "64f75285ef8f606073e9b15c",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "soanenga",
        fokontany: "andranojongy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.548241,
          lng: 44.647491,
        },
        _id: "64f75285ef8f606073e9b15d",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "soanenga",
        fokontany: "sambaokofa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.584611,
          lng: 44.51251269999999,
        },
        _id: "64f75285ef8f606073e9b15e",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "soanenga",
        fokontany: "andranonakanga",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9712457,
          lng: 45.1032792,
        },
        _id: "64f75285ef8f606073e9b15f",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "bekodoka",
        fokontany: "bekodoka",
        __v: 0,
      },
      {
        coords: {
          lat: -16.60545,
          lng: 45.07198,
        },
        _id: "64f75285ef8f606073e9b160",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "bekodoka",
        fokontany: "bevary",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6993732,
          lng: 45.168166,
        },
        _id: "64f75285ef8f606073e9b161",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "bekodoka",
        fokontany: "tsitanandro",
        __v: 0,
      },
      {
        coords: {
          lat: -16.749281,
          lng: 45.112511,
        },
        _id: "64f75285ef8f606073e9b162",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "bekodoka",
        fokontany: "sahondra",
        __v: 0,
      },
      {
        coords: {
          lat: -16.7926865,
          lng: 44.9906736,
        },
        _id: "64f75285ef8f606073e9b163",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "bekodoka",
        fokontany: "ambohibengy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.819395,
          lng: 45.2056196,
        },
        _id: "64f75285ef8f606073e9b164",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "bekodoka",
        fokontany: "antsoha",
        __v: 0,
      },
      {
        coords: {
          lat: -16.883333,
          lng: 45.03333300000001,
        },
        _id: "64f75285ef8f606073e9b165",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "bekodoka",
        fokontany: "ambahivahy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8981712,
          lng: 44.9066503,
        },
        _id: "64f75285ef8f606073e9b166",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "bekodoka",
        fokontany: "ampanihy belela",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9071526,
          lng: 45.1588073,
        },
        _id: "64f75285ef8f606073e9b167",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "bekodoka",
        fokontany: "bekoky",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9,
          lng: 45.1,
        },
        _id: "64f75285ef8f606073e9b168",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "bekodoka",
        fokontany: "mahitsihazo",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9658986,
          lng: 45.2056196,
        },
        _id: "64f75285ef8f606073e9b169",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "bekodoka",
        fokontany: "bevitsika",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9570785,
          lng: 45.0093264,
        },
        _id: "64f75285ef8f606073e9b16a",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "bekodoka",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -16.9667145,
          lng: 45.1260669,
        },
        _id: "64f75285ef8f606073e9b16b",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "bekodoka",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0079417,
          lng: 44.9066503,
        },
        _id: "64f75285ef8f606073e9b16c",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "bekodoka",
        fokontany: "mangoboka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0707083,
          lng: 44.9440129,
        },
        _id: "64f75285ef8f606073e9b16d",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "bekodoka",
        fokontany: "ambalatany",
        __v: 0,
      },
      {
        coords: {
          lat: -17.033333,
          lng: 45.066667,
        },
        _id: "64f75285ef8f606073e9b16e",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "bekodoka",
        fokontany: "antanimbaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0569829,
          lng: 45.0233196,
        },
        _id: "64f75285ef8f606073e9b16f",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "bekodoka",
        fokontany: "marofototra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.083333,
          lng: 45.15,
        },
        _id: "64f75285ef8f606073e9b170",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "bekodoka",
        fokontany: "ambinda",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0903072,
          lng: 45.027985,
        },
        _id: "64f75285ef8f606073e9b171",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "bekodoka",
        fokontany: "betsingilo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2407882,
          lng: 44.92533539999999,
        },
        _id: "64f75285ef8f606073e9b172",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ambolodia sud",
        fokontany: "ambolodia sud",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1216599,
          lng: 45.0093264,
        },
        _id: "64f75285ef8f606073e9b173",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ambolodia sud",
        fokontany: "ambararata",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1859335,
          lng: 44.7943804,
        },
        _id: "64f75285ef8f606073e9b174",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ambolodia sud",
        fokontany: "bevoalavo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1544321,
          lng: 45.0933497,
        },
        _id: "64f75285ef8f606073e9b175",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ambolodia sud",
        fokontany: "ambakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2488112,
          lng: 44.831834,
        },
        _id: "64f75285ef8f606073e9b176",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ambolodia sud",
        fokontany: "antanambao ankazomizoa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.283333,
          lng: 45.05,
        },
        _id: "64f75285ef8f606073e9b177",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ambolodia sud",
        fokontany: "ambaravarandrafia",
        __v: 0,
      },
      {
        coords: {
          lat: -17.175831,
          lng: 44.9814,
        },
        _id: "64f75285ef8f606073e9b178",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ambolodia sud",
        fokontany: "antsirabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3613972,
          lng: 44.8879576,
        },
        _id: "64f75285ef8f606073e9b179",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ambolodia sud",
        fokontany: "andolamoso",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4371895,
          lng: 45.0559871,
        },
        _id: "64f75285ef8f606073e9b17a",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ambolodia sud",
        fokontany: "bemoky",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3512099,
          lng: 44.865059,
        },
        _id: "64f75285ef8f606073e9b17b",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ankasakasa tsibiray",
        fokontany: "ankasakasa tsibiray",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3246804,
          lng: 44.6630541,
        },
        _id: "64f75285ef8f606073e9b17c",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ankasakasa tsibiray",
        fokontany: "makadany",
        __v: 0,
      },
      {
        coords: {
          lat: -16.3258121,
          lng: 44.89730489999999,
        },
        _id: "64f75285ef8f606073e9b17d",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ankasakasa tsibiray",
        fokontany: "andolobe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.4351371,
          lng: 44.89730489999999,
        },
        _id: "64f75285ef8f606073e9b17e",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ankasakasa tsibiray",
        fokontany: "ambarabahy",
        __v: 0,
      },
      {
        coords: {
          lat: -16.417307,
          lng: 44.7943804,
        },
        _id: "64f75285ef8f606073e9b17f",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ankasakasa tsibiray",
        fokontany: "betongoa",
        __v: 0,
      },
      {
        coords: {
          lat: -16.5167042,
          lng: 44.831834,
        },
        _id: "64f75285ef8f606073e9b180",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ankasakasa tsibiray",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -16.6058686,
          lng: 44.9066503,
        },
        _id: "64f75285ef8f606073e9b181",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "ankasakasa tsibiray",
        fokontany: "antanandava bejofo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1126661,
          lng: 45.411274,
        },
        _id: "64f75285ef8f606073e9b182",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "mahabe",
        fokontany: "mahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -16.8973041,
          lng: 45.3557371,
        },
        _id: "64f75285ef8f606073e9b183",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "mahabe",
        fokontany: "mahiagidro",
        __v: 0,
      },
      {
        coords: {
          lat: -17,
          lng: 45.35,
        },
        _id: "64f75285ef8f606073e9b184",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "mahabe",
        fokontany: "sambaokofa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1184599,
          lng: 45.1447728,
        },
        _id: "64f75285ef8f606073e9b185",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "mahabe",
        fokontany: "andabozato",
        __v: 0,
      },
      {
        coords: {
          lat: -17.133333,
          lng: 45.216667,
        },
        _id: "64f75285ef8f606073e9b186",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "mahabe",
        fokontany: "ambonara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1453035,
          lng: 45.1588073,
        },
        _id: "64f75285ef8f606073e9b187",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "mahabe",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1702016,
          lng: 45.2806177,
        },
        _id: "64f75285ef8f606073e9b188",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "mahabe",
        fokontany: "ambalakazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2488112,
          lng: 45.168166,
        },
        _id: "64f75285ef8f606073e9b189",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "mahabe",
        fokontany: "andriabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2121326,
          lng: 45.4309775,
        },
        _id: "64f75285ef8f606073e9b18a",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "mahabe",
        fokontany: "anjiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.316667,
          lng: 45.466667,
        },
        _id: "64f75285ef8f606073e9b18b",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "mahabe",
        fokontany: "ampoza",
        __v: 0,
      },
      {
        coords: {
          lat: -17.316667,
          lng: 45.266667,
        },
        _id: "64f75285ef8f606073e9b18c",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "mahabe",
        fokontany: "antsiradrano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4462733,
          lng: 45.4121561,
        },
        _id: "64f75285ef8f606073e9b18d",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "mahabe",
        fokontany: "ambonarabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4062943,
          lng: 45.2056196,
        },
        _id: "64f75285ef8f606073e9b18e",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "mahabe",
        fokontany: "antsirasira",
        __v: 0,
      },
      {
        coords: {
          lat: -17.448771,
          lng: 45.218319,
        },
        _id: "64f75285ef8f606073e9b18f",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "mahabe",
        fokontany: "andrafialava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5019779,
          lng: 45.3322493,
        },
        _id: "64f75285ef8f606073e9b190",
        province: "mahajanga",
        region: "melaky",
        district: "besalampy",
        commune: "mahabe",
        fokontany: "andrafiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.749809,
          lng: 45.77288799999999,
        },
        _id: "64f75285ef8f606073e9b191",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "ambatomainty",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -17.633333,
          lng: 45.65,
        },
        _id: "64f75285ef8f606073e9b192",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "ambatomainty",
        fokontany: "doany",
        __v: 0,
      },
      {
        coords: {
          lat: -17.67456,
          lng: 45.6810754,
        },
        _id: "64f75285ef8f606073e9b193",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "ambatomainty",
        fokontany: "ampetsapetsa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6151474,
          lng: 45.4874873,
        },
        _id: "64f75285ef8f606073e9b194",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "ambatomainty",
        fokontany: "ankihonala",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6340623,
          lng: 45.6196079,
        },
        _id: "64f75285ef8f606073e9b195",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "ambatomainty",
        fokontany: "ambahivahy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7115954,
          lng: 45.6810754,
        },
        _id: "64f75285ef8f606073e9b196",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "ambatomainty",
        fokontany: "ampiakarandrafito",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7220432,
          lng: 45.8659561,
        },
        _id: "64f75285ef8f606073e9b197",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "ambatomainty",
        fokontany: "bongolava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5311638,
          lng: 45.7142063,
        },
        _id: "64f75285ef8f606073e9b198",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "bemarivo",
        fokontany: "bemarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.444941,
          lng: 45.50161,
        },
        _id: "64f75285ef8f606073e9b199",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "bemarivo",
        fokontany: "ambarinanahary",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4994451,
          lng: 45.8659561,
        },
        _id: "64f75285ef8f606073e9b19a",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "bemarivo",
        fokontany: "bedamba",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5179593,
          lng: 45.73314860000001,
        },
        _id: "64f75285ef8f606073e9b19b",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "bemarivo",
        fokontany: "besakay",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6,
          lng: 45.566667,
        },
        _id: "64f75285ef8f606073e9b19c",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "bemarivo",
        fokontany: "ambalarano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8538375,
          lng: 45.6763443,
        },
        _id: "64f75285ef8f606073e9b19d",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "sarodrano",
        fokontany: "sarodrano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.748409,
          lng: 45.641319,
        },
        _id: "64f75285ef8f606073e9b19e",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "sarodrano",
        fokontany: "andebadeba",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7128682,
          lng: 45.506339,
        },
        _id: "64f75285ef8f606073e9b19f",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "sarodrano",
        fokontany: "ankilitoka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9233296,
          lng: 45.79002089999999,
        },
        _id: "64f75285ef8f606073e9b1a0",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "sarodrano",
        fokontany: "andoharano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8584916,
          lng: 45.5629394,
        },
        _id: "64f75285ef8f606073e9b1a1",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "sarodrano",
        fokontany: "bory",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9879399,
          lng: 45.585892,
        },
        _id: "64f75285ef8f606073e9b1a2",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "sarodrano",
        fokontany: "makaraingo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.79925,
          lng: 45.3759919,
        },
        _id: "64f75285ef8f606073e9b1a3",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "marotsialeha",
        fokontany: "marotsialeha",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6246588,
          lng: 45.2618568,
        },
        _id: "64f75285ef8f606073e9b1a4",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "marotsialeha",
        fokontany: "bekarara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6437565,
          lng: 45.3933422,
        },
        _id: "64f75285ef8f606073e9b1a5",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "marotsialeha",
        fokontany: "ambavaninara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8652711,
          lng: 45.3933422,
        },
        _id: "64f75285ef8f606073e9b1a6",
        province: "mahajanga",
        region: "melaky",
        district: "ambatomainty",
        commune: "marotsialeha",
        fokontany: "andranovorimpanjava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7162044,
          lng: 44.5784342,
        },
        _id: "64f75285ef8f606073e9b1a7",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "antsalova",
        fokontany: "antsalova centre",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4368781,
          lng: 44.7381432,
        },
        _id: "64f75285ef8f606073e9b1a8",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "antsalova",
        fokontany: "soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5,
          lng: 44.616667,
        },
        _id: "64f75285ef8f606073e9b1a9",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "antsalova",
        fokontany: "ambonara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.566667,
          lng: 44.633333,
        },
        _id: "64f75285ef8f606073e9b1aa",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "antsalova",
        fokontany: "ambalavondro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6177883,
          lng: 44.5972518,
        },
        _id: "64f75285ef8f606073e9b1ab",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "antsalova",
        fokontany: "anjiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6652869,
          lng: 44.53135690000001,
        },
        _id: "64f75285ef8f606073e9b1ac",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "antsalova",
        fokontany: "ankotrofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6674052,
          lng: 44.6019551,
        },
        _id: "64f75285ef8f606073e9b1ad",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "antsalova",
        fokontany: "ambolatera",
        __v: 0,
      },
      {
        coords: {
          lat: -18.691222,
          lng: 44.6231138,
        },
        _id: "64f75285ef8f606073e9b1ae",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "antsalova",
        fokontany: "ambalakazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6573976,
          lng: 44.6818377,
        },
        _id: "64f75285ef8f606073e9b1af",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "antsalova",
        fokontany: "antsalovabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.683333,
          lng: 44.866667,
        },
        _id: "64f75285ef8f606073e9b1b0",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "antsalova",
        fokontany: "ambondro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.742652,
          lng: 44.8599044,
        },
        _id: "64f75285ef8f606073e9b1b1",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "antsalova",
        fokontany: "mahadengy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7830736,
          lng: 44.7568965,
        },
        _id: "64f75285ef8f606073e9b1b2",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "antsalova",
        fokontany: "berano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8284128,
          lng: 44.6066578,
        },
        _id: "64f75285ef8f606073e9b1b3",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "antsalova",
        fokontany: "arindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7508623,
          lng: 44.493661,
        },
        _id: "64f75285ef8f606073e9b1b4",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "antsalova",
        fokontany: "saririaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7799007,
          lng: 44.4276206,
        },
        _id: "64f75285ef8f606073e9b1b5",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "antsalova",
        fokontany: "ampamoty",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8624177,
          lng: 44.493661,
        },
        _id: "64f75285ef8f606073e9b1b6",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "antsalova",
        fokontany: "masama",
        __v: 0,
      },
      {
        coords: {
          lat: -18.74287,
          lng: 44.903381,
        },
        _id: "64f75285ef8f606073e9b1b7",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "antsalova",
        fokontany: "tsiandro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8743249,
          lng: 44.9440129,
        },
        _id: "64f75285ef8f606073e9b1b8",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "antsalova",
        fokontany: "bevero",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6228178,
          lng: 44.3614875,
        },
        _id: "64f75285ef8f606073e9b1b9",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "soahany",
        fokontany: "soahany",
        __v: 0,
      },
      {
        coords: {
          lat: -18.536326,
          lng: 44.3709408,
        },
        _id: "64f75285ef8f606073e9b1ba",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "soahany",
        fokontany: "betakilotra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5584833,
          lng: 44.4208344,
        },
        _id: "64f75285ef8f606073e9b1bb",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "soahany",
        fokontany: "bemonto",
        __v: 0,
      },
      {
        coords: {
          lat: -18.642719,
          lng: 44.477039,
        },
        _id: "64f75285ef8f606073e9b1bc",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "soahany",
        fokontany: "tsianaloka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.687349,
          lng: 44.4016189,
        },
        _id: "64f75285ef8f606073e9b1bd",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "soahany",
        fokontany: "mokotibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.716667,
          lng: 44.266667,
        },
        _id: "64f75285ef8f606073e9b1be",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "soahany",
        fokontany: "besara ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7587256,
          lng: 44.3425754,
        },
        _id: "64f75285ef8f606073e9b1bf",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "soahany",
        fokontany: "bemia",
        __v: 0,
      },
      {
        coords: {
          lat: -19.03035,
          lng: 44.34016,
        },
        _id: "64f75285ef8f606073e9b1c0",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "masoarivo",
        fokontany: "masoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8130409,
          lng: 44.3945656,
        },
        _id: "64f75285ef8f606073e9b1c1",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "masoarivo",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8324844,
          lng: 44.4228998,
        },
        _id: "64f75285ef8f606073e9b1c2",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "masoarivo",
        fokontany: "ambondro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8786119,
          lng: 44.4087348,
        },
        _id: "64f75285ef8f606073e9b1c3",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "masoarivo",
        fokontany: "ambalakazaha fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8463,
          lng: 44.322609,
        },
        _id: "64f75285ef8f606073e9b1c4",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "masoarivo",
        fokontany: "bemamba",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9595678,
          lng: 44.4181786,
        },
        _id: "64f75285ef8f606073e9b1c5",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "masoarivo",
        fokontany: "ambereny",
        __v: 0,
      },
      {
        coords: {
          lat: -19.005058,
          lng: 44.2668514,
        },
        _id: "64f75285ef8f606073e9b1c6",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "masoarivo",
        fokontany: "benjavilo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0648776,
          lng: 44.4087348,
        },
        _id: "64f75285ef8f606073e9b1c7",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "masoarivo",
        fokontany: "ambalamanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1120987,
          lng: 44.314193,
        },
        _id: "64f75285ef8f606073e9b1c8",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "masoarivo",
        fokontany: "besara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.145161,
          lng: 44.3898416,
        },
        _id: "64f75285ef8f606073e9b1c9",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "masoarivo",
        fokontany: "bevoay",
        __v: 0,
      },
      {
        coords: {
          lat: -19.12779,
          lng: 44.5517619,
        },
        _id: "64f75285ef8f606073e9b1ca",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "trangahy",
        fokontany: "trangahy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9254489,
          lng: 44.53135690000001,
        },
        _id: "64f75285ef8f606073e9b1cb",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "trangahy",
        fokontany: "antsahalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -18.956051,
          lng: 44.60944,
        },
        _id: "64f75285ef8f606073e9b1cc",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "trangahy",
        fokontany: "berongony",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0484781,
          lng: 44.493661,
        },
        _id: "64f75285ef8f606073e9b1cd",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "trangahy",
        fokontany: "bejea",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0649198,
          lng: 44.6536595,
        },
        _id: "64f75285ef8f606073e9b1ce",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "trangahy",
        fokontany: "fenoarivo bara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0978823,
          lng: 44.4842324,
        },
        _id: "64f75285ef8f606073e9b1cf",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "trangahy",
        fokontany: "soatana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1408631,
          lng: 44.4653693,
        },
        _id: "64f75285ef8f606073e9b1d0",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "trangahy",
        fokontany: "ankilijoria",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1078235,
          lng: 44.6348645,
        },
        _id: "64f75285ef8f606073e9b1d1",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "trangahy",
        fokontany: "mafiovy korao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.133333,
          lng: 44.616667,
        },
        _id: "64f75285ef8f606073e9b1d2",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "trangahy",
        fokontany: "bejangoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.14677,
          lng: 44.792141,
        },
        _id: "64f75285ef8f606073e9b1d3",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "bekopaka",
        fokontany: "bekopaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8863912,
          lng: 44.6912267,
        },
        _id: "64f75285ef8f606073e9b1d4",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "bekopaka",
        fokontany: "ankilisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9249557,
          lng: 44.7475208,
        },
        _id: "64f75285ef8f606073e9b1d5",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "bekopaka",
        fokontany: "begara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9826708,
          lng: 44.831834,
        },
        _id: "64f75285ef8f606073e9b1d6",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "bekopaka",
        fokontany: "bereketa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0797858,
          lng: 44.7568965,
        },
        _id: "64f75285ef8f606073e9b1d7",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "bekopaka",
        fokontany: "beapinga atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1322647,
          lng: 44.7522089,
        },
        _id: "64f75285ef8f606073e9b1d8",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "bekopaka",
        fokontany: "ambalakazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1591474,
          lng: 44.709999,
        },
        _id: "64f75285ef8f606073e9b1d9",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "bekopaka",
        fokontany: "antsaraolo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1796178,
          lng: 44.7943804,
        },
        _id: "64f75285ef8f606073e9b1da",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "bekopaka",
        fokontany: "ankilitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1847622,
          lng: 44.7475208,
        },
        _id: "64f75285ef8f606073e9b1db",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "bekopaka",
        fokontany: "manavony",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2173423,
          lng: 44.8224735,
        },
        _id: "64f75285ef8f606073e9b1dc",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "bekopaka",
        fokontany: "andranolava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2677938,
          lng: 44.8692573,
        },
        _id: "64f75285ef8f606073e9b1dd",
        province: "mahajanga",
        region: "melaky",
        district: "antsalova",
        commune: "bekopaka",
        fokontany: "antsarona",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0621973,
          lng: 44.0264611,
        },
        _id: "64f75285ef8f606073e9b1de",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "maintirano",
        fokontany: "marodoka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9905687,
          lng: 44.03895540000001,
        },
        _id: "64f75285ef8f606073e9b1df",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "maintirano",
        fokontany: "mangotroky",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0529592,
          lng: 44.0282462,
        },
        _id: "64f75285ef8f606073e9b1e0",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "maintirano",
        fokontany: "tanambao nord",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0530152,
          lng: 44.0484728,
        },
        _id: "64f75285ef8f606073e9b1e1",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "maintirano",
        fokontany: "tanambao SPM",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0578804,
          lng: 44.025271,
        },
        _id: "64f75285ef8f606073e9b1e2",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "maintirano",
        fokontany: "andranovoribe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0510146,
          lng: 44.0187249,
        },
        _id: "64f75285ef8f606073e9b1e3",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "maintirano",
        fokontany: "namela",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0902548,
          lng: 44.0484728,
        },
        _id: "64f75285ef8f606073e9b1e4",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "maintirano",
        fokontany: "ampasimandroro",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9952946,
          lng: 44.153039,
        },
        _id: "64f75285ef8f606073e9b1e5",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "mafaijijo",
        fokontany: "mafaijijo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9473063,
          lng: 44.1910066,
        },
        _id: "64f75285ef8f606073e9b1e6",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "mafaijijo",
        fokontany: "antsohamaliniky",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0307346,
          lng: 44.11028950000001,
        },
        _id: "64f75285ef8f606073e9b1e7",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "mafaijijo",
        fokontany: "amboloando",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0390567,
          lng: 44.1625338,
        },
        _id: "64f75285ef8f606073e9b1e8",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "mafaijijo",
        fokontany: "antanandava antandroy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0625086,
          lng: 44.12929399999999,
        },
        _id: "64f75285ef8f606073e9b1e9",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "mafaijijo",
        fokontany: "ambonara nord",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1078783,
          lng: 44.10078439999999,
        },
        _id: "64f75285ef8f606073e9b1ea",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "mafaijijo",
        fokontany: "amboanio",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0969925,
          lng: 44.1387934,
        },
        _id: "64f75285ef8f606073e9b1eb",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andabotoka",
        fokontany: "miarinarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.122173,
          lng: 44.148291,
        },
        _id: "64f75285ef8f606073e9b1ec",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andabotoka",
        fokontany: "miandritsara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0981259,
          lng: 44.1672804,
        },
        _id: "64f75285ef8f606073e9b1ed",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andabotoka",
        fokontany: "antsakoalamoty",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0768106,
          lng: 44.1767723,
        },
        _id: "64f75285ef8f606073e9b1ee",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andabotoka",
        fokontany: "ambalamanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1189267,
          lng: 44.18389,
        },
        _id: "64f75285ef8f606073e9b1ef",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andabotoka",
        fokontany: "kimazimazy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0992523,
          lng: 44.1957505,
        },
        _id: "64f75285ef8f606073e9b1f0",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andabotoka",
        fokontany: "mandrosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0314849,
          lng: 44.2052367,
        },
        _id: "64f75285ef8f606073e9b1f1",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andabotoka",
        fokontany: "ankara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0632863,
          lng: 44.3047285,
        },
        _id: "64f75285ef8f606073e9b1f2",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andabotoka",
        fokontany: "mahavelo bebozaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.133333,
          lng: 44.45,
        },
        _id: "64f75285ef8f606073e9b1f3",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andabotoka",
        fokontany: "belinta",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1134071,
          lng: 44.4842324,
        },
        _id: "64f75285ef8f606073e9b1f4",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andabotoka",
        fokontany: "mangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2,
          lng: 44.533333,
        },
        _id: "64f75285ef8f606073e9b1f5",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andabotoka",
        fokontany: "ampasimena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2942298,
          lng: 44.53135690000001,
        },
        _id: "64f75285ef8f606073e9b1f6",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andabotoka",
        fokontany: "andrafiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.170344,
          lng: 44.1910066,
        },
        _id: "64f75285ef8f606073e9b1f7",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "betanatanana",
        fokontany: "betanatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.14848,
          lng: 44.2668514,
        },
        _id: "64f75285ef8f606073e9b1f8",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "betanatanana",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1396535,
          lng: 44.1197927,
        },
        _id: "64f75285ef8f606073e9b1f9",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "betanatanana",
        fokontany: "andranomalio",
        __v: 0,
      },
      {
        coords: {
          lat: -18.164312,
          lng: 44.15541289999999,
        },
        _id: "64f75285ef8f606073e9b1fa",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "betanatanana",
        fokontany: "mahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1927617,
          lng: 44.12929399999999,
        },
        _id: "64f75285ef8f606073e9b1fb",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "betanatanana",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1955069,
          lng: 44.2004938,
        },
        _id: "64f75285ef8f606073e9b1fc",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "betanatanana",
        fokontany: "ambonarabe mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1856307,
          lng: 44.1055372,
        },
        _id: "64f75285ef8f606073e9b1fd",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "betanatanana",
        fokontany: "ampanea",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1779297,
          lng: 44.0675018,
        },
        _id: "64f75285ef8f606073e9b1fe",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "betanatanana",
        fokontany: "manombo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2293608,
          lng: 44.2763235,
        },
        _id: "64f75285ef8f606073e9b1ff",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "betanatanana",
        fokontany: "andrafialava",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7963099,
          lng: 44.13404389999999,
        },
        _id: "64f75285ef8f606073e9b200",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andrea",
        fokontany: "andrea",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7195013,
          lng: 44.0770134,
        },
        _id: "64f75285ef8f606073e9b201",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andrea",
        fokontany: "ampandramalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -17.774853,
          lng: 44.20997910000001,
        },
        _id: "64f75285ef8f606073e9b202",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andrea",
        fokontany: "tetezavato",
        __v: 0,
      },
      {
        coords: {
          lat: -17.890394,
          lng: 44.1625338,
        },
        _id: "64f75285ef8f606073e9b203",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andrea",
        fokontany: "antaboaboaky",
        __v: 0,
      },
      {
        coords: {
          lat: -17.862247,
          lng: 44.2289441,
        },
        _id: "64f75285ef8f606073e9b204",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andrea",
        fokontany: "mandroatsy bara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8309888,
          lng: 44.0770134,
        },
        _id: "64f75285ef8f606073e9b205",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andrea",
        fokontany: "bemena",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9305527,
          lng: 44.08652319999999,
        },
        _id: "64f75285ef8f606073e9b206",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andrea",
        fokontany: "ampandra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2282439,
          lng: 44.14823459999999,
        },
        _id: "64f75285ef8f606073e9b207",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "ankisatra",
        fokontany: "ankisatra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2360184,
          lng: 44.1245436,
        },
        _id: "64f75285ef8f606073e9b208",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "ankisatra",
        fokontany: "ampatifaty",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2333329,
          lng: 44.216667,
        },
        _id: "64f75285ef8f606073e9b209",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "ankisatra",
        fokontany: "bevatry",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2622869,
          lng: 44.1625338,
        },
        _id: "64f75285ef8f606073e9b20a",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "ankisatra",
        fokontany: "tanambao befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3784778,
          lng: 44.1150413,
        },
        _id: "64f75285ef8f606073e9b20b",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "ankisatra",
        fokontany: "ankazomanambaho",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3,
          lng: 44.25,
        },
        _id: "64f75285ef8f606073e9b20c",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "ankisatra",
        fokontany: "ambondro",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3278222,
          lng: 44.2573774,
        },
        _id: "64f75285ef8f606073e9b20d",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "ankisatra",
        fokontany: "nosibe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3826125,
          lng: 44.2289441,
        },
        _id: "64f75285ef8f606073e9b20e",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "ankisatra",
        fokontany: "ankilifito",
        __v: 0,
      },
      {
        coords: {
          lat: -17.666667,
          lng: 44.333333,
        },
        _id: "64f75285ef8f606073e9b20f",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "marohazo",
        fokontany: "marohazo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6233825,
          lng: 44.4181786,
        },
        _id: "64f75285ef8f606073e9b210",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "marohazo",
        fokontany: "antanimavo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6183932,
          lng: 44.3047285,
        },
        _id: "64f75285ef8f606073e9b211",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "marohazo",
        fokontany: "betaboara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.645861,
          lng: 44.51824999999999,
        },
        _id: "64f75285ef8f606073e9b212",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "marohazo",
        fokontany: "ambonara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6973488,
          lng: 44.4181786,
        },
        _id: "64f75285ef8f606073e9b213",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "marohazo",
        fokontany: "morima",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7025378,
          lng: 44.2526397,
        },
        _id: "64f75285ef8f606073e9b214",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "marohazo",
        fokontany: "namoasy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7043963,
          lng: 44.29526200000001,
        },
        _id: "64f75285ef8f606073e9b215",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "marohazo",
        fokontany: "ambatovaky",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7492451,
          lng: 44.3331165,
        },
        _id: "64f75285ef8f606073e9b216",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "marohazo",
        fokontany: "beteno ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7713486,
          lng: 44.4181786,
        },
        _id: "64f75285ef8f606073e9b217",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "marohazo",
        fokontany: "antsakoabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7599786,
          lng: 44.29526200000001,
        },
        _id: "64f75285ef8f606073e9b218",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "marohazo",
        fokontany: "madosomby",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3996868,
          lng: 44.3614875,
        },
        _id: "64f75285ef8f606073e9b219",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "antsondrodava",
        fokontany: "antsondrodava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3012794,
          lng: 44.38039209999999,
        },
        _id: "64f75285ef8f606073e9b21a",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "antsondrodava",
        fokontany: "antsonjo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3670502,
          lng: 44.314193,
        },
        _id: "64f75285ef8f606073e9b21b",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "antsondrodava",
        fokontany: "filatsaha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.366667,
          lng: 44.4166669,
        },
        _id: "64f75285ef8f606073e9b21c",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "antsondrodava",
        fokontany: "ambararata",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4508517,
          lng: 44.4087348,
        },
        _id: "64f75285ef8f606073e9b21d",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "antsondrodava",
        fokontany: "andramy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4544763,
          lng: 44.3331165,
        },
        _id: "64f75285ef8f606073e9b21e",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "antsondrodava",
        fokontany: "andranovorikolo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4339119,
          lng: 44.2763235,
        },
        _id: "64f75285ef8f606073e9b21f",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "antsondrodava",
        fokontany: "ankidondo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5093132,
          lng: 44.3047285,
        },
        _id: "64f75285ef8f606073e9b220",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "antsondrodava",
        fokontany: "tondrolo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9325459,
          lng: 44.4370606,
        },
        _id: "64f75285ef8f606073e9b221",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "belitsaky",
        fokontany: "belitsaky",
        __v: 0,
      },
      {
        coords: {
          lat: -17.860371,
          lng: 44.3771289,
        },
        _id: "64f75285ef8f606073e9b222",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "belitsaky",
        fokontany: "antsory",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8715942,
          lng: 44.455935,
        },
        _id: "64f75285ef8f606073e9b223",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "belitsaky",
        fokontany: "maperabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9782015,
          lng: 44.3425754,
        },
        _id: "64f75285ef8f606073e9b224",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "belitsaky",
        fokontany: "amborokontsy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9268365,
          lng: 44.29526200000001,
        },
        _id: "64f75285ef8f606073e9b225",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "belitsaky",
        fokontany: "beantsiva",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0088187,
          lng: 44.493661,
        },
        _id: "64f75285ef8f606073e9b226",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "belitsaky",
        fokontany: "ankilimanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9873453,
          lng: 44.2621146,
        },
        _id: "64f75285ef8f606073e9b227",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "belitsaky",
        fokontany: "mandrevomby",
        __v: 0,
      },
      {
        coords: {
          lat: -17.413154,
          lng: 44.0103922,
        },
        _id: "64f75285ef8f606073e9b228",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "tambohorano",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4397394,
          lng: 44.0484728,
        },
        _id: "64f75285ef8f606073e9b229",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "tambohorano",
        fokontany: "mafaitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5338565,
          lng: 44.0770134,
        },
        _id: "64f75285ef8f606073e9b22a",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "tambohorano",
        fokontany: "ambolamena",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5550205,
          lng: 44.0008674,
        },
        _id: "64f75285ef8f606073e9b22b",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "tambohorano",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4804486,
          lng: 44.2064223,
        },
        _id: "64f75285ef8f606073e9b22c",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "veromanga",
        fokontany: "veromanga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4174858,
          lng: 44.2289441,
        },
        _id: "64f75285ef8f606073e9b22d",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "veromanga",
        fokontany: "kimanambolo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4756278,
          lng: 44.153039,
        },
        _id: "64f75285ef8f606073e9b22e",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "veromanga",
        fokontany: "tetezananahary",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5762726,
          lng: 44.297529,
        },
        _id: "64f75285ef8f606073e9b22f",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "maromavo",
        fokontany: "maromavo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5705509,
          lng: 44.343632,
        },
        _id: "64f75285ef8f606073e9b230",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "maromavo",
        fokontany: "andimaky",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6026546,
          lng: 44.2289441,
        },
        _id: "64f75285ef8f606073e9b231",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "maromavo",
        fokontany: "bemoky ambalatany",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6452179,
          lng: 44.0770134,
        },
        _id: "64f75285ef8f606073e9b232",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andranovao",
        fokontany: "andranovao",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5974677,
          lng: 44.1150413,
        },
        _id: "64f75285ef8f606073e9b233",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andranovao",
        fokontany: "manapape",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6623119,
          lng: 44.1815176,
        },
        _id: "64f75285ef8f606073e9b234",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andranovao",
        fokontany: "ambalamanga",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6704064,
          lng: 44.08652319999999,
        },
        _id: "64f75285ef8f606073e9b235",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andranovao",
        fokontany: "nivakiambakoly",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6293309,
          lng: 44.0008674,
        },
        _id: "64f75285ef8f606073e9b236",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "andranovao",
        fokontany: "ankitara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4462733,
          lng: 44.5878439,
        },
        _id: "64f75285ef8f606073e9b237",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "antsaidoha bebao",
        fokontany: "antsaidoha bebao",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2818162,
          lng: 44.6160619,
        },
        _id: "64f75285ef8f606073e9b238",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "antsaidoha bebao",
        fokontany: "betsako",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2594491,
          lng: 44.53135690000001,
        },
        _id: "64f75285ef8f606073e9b239",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "antsaidoha bebao",
        fokontany: "ambanja",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3514349,
          lng: 44.6630541,
        },
        _id: "64f75285ef8f606073e9b23a",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "antsaidoha bebao",
        fokontany: "tsimirandra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4228139,
          lng: 44.3425754,
        },
        _id: "64f75285ef8f606073e9b23b",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "antsaidoha bebao",
        fokontany: "bekinoly",
        __v: 0,
      },
      {
        coords: {
          lat: -17.4331465,
          lng: 44.5690225,
        },
        _id: "64f75285ef8f606073e9b23c",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "antsaidoha bebao",
        fokontany: "ambahivahy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.516667,
          lng: 44.4,
        },
        _id: "64f75285ef8f606073e9b23d",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "antsaidoha bebao",
        fokontany: "ampoza",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5913436,
          lng: 44.53135690000001,
        },
        _id: "64f75285ef8f606073e9b23e",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "antsaidoha bebao",
        fokontany: "bereketa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.223339,
          lng: 44.29230099999999,
        },
        _id: "64f75285ef8f606073e9b23f",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "berevo/ranobe",
        fokontany: "berevo sur ranobe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2462987,
          lng: 44.1957505,
        },
        _id: "64f75285ef8f606073e9b240",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "berevo/ranobe",
        fokontany: "ambiky",
        __v: 0,
      },
      {
        coords: {
          lat: -17.235851,
          lng: 44.2336841,
        },
        _id: "64f75285ef8f606073e9b241",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "berevo/ranobe",
        fokontany: "ankerabe nord",
        __v: 0,
      },
      {
        coords: {
          lat: -17.275036,
          lng: 44.3425754,
        },
        _id: "64f75285ef8f606073e9b242",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "berevo/ranobe",
        fokontany: "andimaky",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3329751,
          lng: 44.2668514,
        },
        _id: "64f75285ef8f606073e9b243",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "berevo/ranobe",
        fokontany: "anketrabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1962561,
          lng: 44.493661,
        },
        _id: "64f75285ef8f606073e9b244",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "bebaboky sud",
        fokontany: "bebaboka sud",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0138462,
          lng: 44.2857937,
        },
        _id: "64f75285ef8f606073e9b245",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "bebaboky sud",
        fokontany: "tsamandira",
        __v: 0,
      },
      {
        coords: {
          lat: -17.0697436,
          lng: 44.4181786,
        },
        _id: "64f75285ef8f606073e9b246",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "bebaboky sud",
        fokontany: "amberobe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.131626,
          lng: 44.4276206,
        },
        _id: "64f75285ef8f606073e9b247",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "bebaboky sud",
        fokontany: "andrafiamivony",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1320601,
          lng: 44.1910066,
        },
        _id: "64f75285ef8f606073e9b248",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "bebaboky sud",
        fokontany: "onara",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1482129,
          lng: 44.2668514,
        },
        _id: "64f75285ef8f606073e9b249",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "bebaboky sud",
        fokontany: "bereketa",
        __v: 0,
      },
      {
        coords: {
          lat: -17.1642963,
          lng: 44.3425754,
        },
        _id: "64f75285ef8f606073e9b24a",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "bebaboky sud",
        fokontany: "bemokotra nord",
        __v: 0,
      },
      {
        coords: {
          lat: -17.21581,
          lng: 44.3898416,
        },
        _id: "64f75285ef8f606073e9b24b",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "bebaboky sud",
        fokontany: "andranoboka",
        __v: 0,
      },
      {
        coords: {
          lat: -17.166667,
          lng: 44.333333,
        },
        _id: "64f75285ef8f606073e9b24c",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "bemokotra sud",
        fokontany: "bemokotra sud",
        __v: 0,
      },
      {
        coords: {
          lat: -17.22216,
          lng: 44.1441919,
        },
        _id: "64f75285ef8f606073e9b24d",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "bemokotra sud",
        fokontany: "antsafy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2638716,
          lng: 44.1150413,
        },
        _id: "64f75285ef8f606073e9b24e",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "bemokotra sud",
        fokontany: "sarodrano",
        __v: 0,
      },
      {
        coords: {
          lat: -17.2918397,
          lng: 44.1815176,
        },
        _id: "64f75285ef8f606073e9b24f",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "bemokotra sud",
        fokontany: "ankerabe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3606098,
          lng: 44.2004938,
        },
        _id: "64f75285ef8f606073e9b250",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "bemokotra sud",
        fokontany: "ankoromokoty",
        __v: 0,
      },
      {
        coords: {
          lat: -17.3868821,
          lng: 44.1055372,
        },
        _id: "64f75285ef8f606073e9b251",
        province: "mahajanga",
        region: "melaky",
        district: "maintirano",
        commune: "bemokotra sud",
        fokontany: "amborokontsy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.818621,
          lng: 44.91876999999999,
        },
        _id: "64f75285ef8f606073e9b252",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "morafenobe",
        fokontany: "morafenobe",
        __v: 0,
      },
      {
        coords: {
          lat: -17.6246588,
          lng: 44.7381432,
        },
        _id: "64f75285ef8f606073e9b253",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "morafenobe",
        fokontany: "ankiranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -17.5603037,
          lng: 44.9626829,
        },
        _id: "64f75285ef8f606073e9b254",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "morafenobe",
        fokontany: "belalitra",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7288292,
          lng: 45.1120424,
        },
        _id: "64f75285ef8f606073e9b255",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "morafenobe",
        fokontany: "besahona",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8219336,
          lng: 44.7568965,
        },
        _id: "64f75285ef8f606073e9b256",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "morafenobe",
        fokontany: "betainkena",
        __v: 0,
      },
      {
        coords: {
          lat: -17.88455,
          lng: 45.151119,
        },
        _id: "64f75285ef8f606073e9b257",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "morafenobe",
        fokontany: "bebokoy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.883333,
          lng: 44.783333,
        },
        _id: "64f75285ef8f606073e9b258",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "morafenobe",
        fokontany: "berohay",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8934072,
          lng: 44.8599044,
        },
        _id: "64f75285ef8f606073e9b259",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "morafenobe",
        fokontany: "andranomamorery",
        __v: 0,
      },
      {
        coords: {
          lat: -17.93856,
          lng: 45.026218,
        },
        _id: "64f75285ef8f606073e9b25a",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "morafenobe",
        fokontany: "ambahivahy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9517146,
          lng: 44.9440129,
        },
        _id: "64f75285ef8f606073e9b25b",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "morafenobe",
        fokontany: "bekodoka nord",
        __v: 0,
      },
      {
        coords: {
          lat: -17.96669,
          lng: 44.78228,
        },
        _id: "64f75285ef8f606073e9b25c",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "andramy",
        fokontany: "andramy",
        __v: 0,
      },
      {
        coords: {
          lat: -17.7140516,
          lng: 44.5219358,
        },
        _id: "64f75285ef8f606073e9b25d",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "andramy",
        fokontany: "bemandavo",
        __v: 0,
      },
      {
        coords: {
          lat: -17.8152674,
          lng: 44.5878439,
        },
        _id: "64f75285ef8f606073e9b25e",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "andramy",
        fokontany: "antranokoaky",
        __v: 0,
      },
      {
        coords: {
          lat: -18.015217,
          lng: 44.6630541,
        },
        _id: "64f75285ef8f606073e9b25f",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "andramy",
        fokontany: "antsingilitoka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.13072,
          lng: 44.843048,
        },
        _id: "64f75285ef8f606073e9b260",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "andramy",
        fokontany: "tsianaloka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.172714,
          lng: 44.9440129,
        },
        _id: "64f75285ef8f606073e9b261",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "andramy",
        fokontany: "soatana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.2686114,
          lng: 44.8692573,
        },
        _id: "64f75285ef8f606073e9b262",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "andramy",
        fokontany: "ampany bara",
        __v: 0,
      },
      {
        coords: {
          lat: -18.1630835,
          lng: 44.6630541,
        },
        _id: "64f75285ef8f606073e9b263",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "andramy",
        fokontany: "mampatsa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.179487,
          lng: 45.2196725,
        },
        _id: "64f75285ef8f606073e9b264",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "beravina",
        fokontany: "beravina",
        __v: 0,
      },
      {
        coords: {
          lat: -17.9174281,
          lng: 45.3181623,
        },
        _id: "64f75285ef8f606073e9b265",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "beravina",
        fokontany: "miarinarivo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -18.016667,
          lng: 45.35,
        },
        _id: "64f75285ef8f606073e9b266",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "beravina",
        fokontany: "analabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.0452943,
          lng: 45.186889,
        },
        _id: "64f75285ef8f606073e9b267",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "beravina",
        fokontany: "ambodivato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.183333,
          lng: 45.016667,
        },
        _id: "64f75285ef8f606073e9b268",
        province: "mahajanga",
        region: "melaky",
        district: "morafenobe",
        commune: "beravina",
        fokontany: "ankalalo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3534103,
          lng: 43.6735074,
        },
        _id: "64f75285ef8f606073e9b269",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "tanambao I",
        fokontany: "tanambao I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3478908,
          lng: 43.67230869999999,
        },
        _id: "64f75285ef8f606073e9b26a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "tanambao I",
        fokontany: "tanambao morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3543056,
          lng: 43.6675133,
        },
        _id: "64f75285ef8f606073e9b26b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "tanambao I",
        fokontany: "toliara centre",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3542122,
          lng: 43.6729081,
        },
        _id: "64f75285ef8f606073e9b26c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "tanambao I",
        fokontany: "tsianengea",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3495314,
          lng: 43.6803998,
        },
        _id: "64f75285ef8f606073e9b26d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "tanambao II TSF Nord",
        fokontany: "amborogony tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3513589,
          lng: 43.6777029,
        },
        _id: "64f75285ef8f606073e9b26e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "tanambao II TSF Nord",
        fokontany: "amborogony",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3508486,
          lng: 43.6842951,
        },
        _id: "64f75285ef8f606073e9b26f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "tanambao II TSF Nord",
        fokontany: "ampasikibo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.356878,
          lng: 43.6789016,
        },
        _id: "64f75285ef8f606073e9b270",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "tanambao II TSF Nord",
        fokontany: "sanfily",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3563358,
          lng: 43.6872912,
        },
        _id: "64f75285ef8f606073e9b271",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "tanambao II TSF Nord",
        fokontany: "tanambao II TSF nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3603765,
          lng: 43.6824973,
        },
        _id: "64f75285ef8f606073e9b272",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "tanambao II TSF Nord",
        fokontany: "ankatsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3626876,
          lng: 43.6860928,
        },
        _id: "64f75285ef8f606073e9b273",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "tanambao II TSF Nord",
        fokontany: "konkasera",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3649667,
          lng: 43.6914856,
        },
        _id: "64f75285ef8f606073e9b274",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "tanambao II TSF Nord",
        fokontany: "andabizy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3667602,
          lng: 43.6795009,
        },
        _id: "64f75285ef8f606073e9b275",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "mahavatse I",
        fokontany: "mahavatse I tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3697145,
          lng: 43.6914856,
        },
        _id: "64f75285ef8f606073e9b276",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "mahavatse I",
        fokontany: "tanambao motombe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3730175,
          lng: 43.6836958,
        },
        _id: "64f75285ef8f606073e9b277",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "mahavatse I",
        fokontany: "mahavatse I est",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3738512,
          lng: 43.6812988,
        },
        _id: "64f75285ef8f606073e9b278",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "mahavatse I",
        fokontany: "mahavatse I ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3723422,
          lng: 43.6771036,
        },
        _id: "64f75285ef8f606073e9b279",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "mahavatse I",
        fokontany: "ankiembe bas",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3799801,
          lng: 43.6926839,
        },
        _id: "64f75285ef8f606073e9b27a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "mahavatse I",
        fokontany: "ankiembe haut",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3602107,
          lng: 43.66931160000001,
        },
        _id: "64f75285ef8f606073e9b27b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "mahavatse II",
        fokontany: "tsimenatse III",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3593775,
          lng: 43.6717093,
        },
        _id: "64f75285ef8f606073e9b27c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "mahavatse II",
        fokontany: "tsimenatse I ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3589297,
          lng: 43.6747062,
        },
        _id: "64f75285ef8f606073e9b27d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "mahavatse II",
        fokontany: "tsimenatse I est",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3642597,
          lng: 43.686692,
        },
        _id: "64f75285ef8f606073e9b27e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "mahavatse II",
        fokontany: "tsimenatse II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3640636,
          lng: 43.6753055,
        },
        _id: "64f75285ef8f606073e9b27f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "mahavatse II",
        fokontany: "mahavatse II est ambararata",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3672714,
          lng: 43.6729081,
        },
        _id: "64f75285ef8f606073e9b280",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "mahavatse II",
        fokontany: "mahavatse II ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3405816,
          lng: 43.6830966,
        },
        _id: "64f75285ef8f606073e9b281",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "betania",
        fokontany: "betania centre",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3399349,
          lng: 43.6747062,
        },
        _id: "64f75285ef8f606073e9b282",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "betania",
        fokontany: "andaboly",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3413834,
          lng: 43.6824973,
        },
        _id: "64f75285ef8f606073e9b283",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "betania",
        fokontany: "mangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3446525,
          lng: 43.6765042,
        },
        _id: "64f75285ef8f606073e9b284",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "betania",
        fokontany: "betania ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3477975,
          lng: 43.6777029,
        },
        _id: "64f75285ef8f606073e9b285",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "betania",
        fokontany: "betania ankilifaly",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3461005,
          lng: 43.6842951,
        },
        _id: "64f75285ef8f606073e9b286",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "betania",
        fokontany: "betania tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3425253,
          lng: 43.6621179,
        },
        _id: "64f75285ef8f606073e9b287",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "besakoa",
        fokontany: "antaninarenina",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3143597,
          lng: 43.6423298,
        },
        _id: "64f75285ef8f606073e9b288",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "besakoa",
        fokontany: "sakabera",
        __v: 0,
      },
      {
        coords: {
          lat: -23.32849,
          lng: 43.6495264,
        },
        _id: "64f75285ef8f606073e9b289",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "besakoa",
        fokontany: "tsongobory",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3396546,
          lng: 43.64472869999999,
        },
        _id: "64f75285ef8f606073e9b28a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "besakoa",
        fokontany: "anketraka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3344768,
          lng: 43.669911,
        },
        _id: "64f75285ef8f606073e9b28b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "besakoa",
        fokontany: "antaravay salimo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3408909,
          lng: 43.6651154,
        },
        _id: "64f75285ef8f606073e9b28c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "besakoa",
        fokontany: "anketa bas",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3440056,
          lng: 43.66811269999999,
        },
        _id: "64f75285ef8f606073e9b28d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "besakoa",
        fokontany: "anketa haut",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3489401,
          lng: 43.65732149999999,
        },
        _id: "64f75285ef8f606073e9b28e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "besakoa",
        fokontany: "ambohitsabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3454474,
          lng: 43.6648157,
        },
        _id: "64f75285ef8f606073e9b28f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "besakoa",
        fokontany: "betaritarika",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3269469,
          lng: 43.6471276,
        },
        _id: "64f75285ef8f606073e9b290",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "besakoa",
        fokontany: "besakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3479838,
          lng: 43.6669138,
        },
        _id: "64f75285ef8f606073e9b291",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-I",
        commune: "besakoa",
        fokontany: "tsianaloka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6557858,
          lng: 45.1541287,
        },
        _id: "64f75285ef8f606073e9b292",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "beroroha",
        fokontany: "beroroha ville I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2918136,
          lng: 45.3369459,
        },
        _id: "64f75285ef8f606073e9b293",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "beroroha",
        fokontany: "tsivoko",
        __v: 0,
      },
      {
        coords: {
          lat: -21.479561,
          lng: 45.3548509,
        },
        _id: "64f75285ef8f606073e9b294",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "beroroha",
        fokontany: "makaikely",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4670117,
          lng: 45.4403911,
        },
        _id: "64f75285ef8f606073e9b295",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "beroroha",
        fokontany: "ambalavato nord",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4982016,
          lng: 45.4215658,
        },
        _id: "64f75285ef8f606073e9b296",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "beroroha",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2913312,
          lng: 45.168166,
        },
        _id: "64f75285ef8f606073e9b297",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "beroroha",
        fokontany: "beronono",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4163722,
          lng: 45.2618568,
        },
        _id: "64f75285ef8f606073e9b298",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "beroroha",
        fokontany: "ampolotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4655693,
          lng: 45.1307427,
        },
        _id: "64f75285ef8f606073e9b299",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "beroroha",
        fokontany: "ankazoabokely",
        __v: 0,
      },
      {
        coords: {
          lat: -21.534521,
          lng: 45.1962533,
        },
        _id: "64f75285ef8f606073e9b29a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "beroroha",
        fokontany: "volambita",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5289741,
          lng: 45.3181623,
        },
        _id: "64f75285ef8f606073e9b29b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "beroroha",
        fokontany: "sakananjy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5911906,
          lng: 45.2806177,
        },
        _id: "64f75285ef8f606073e9b29c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "beroroha",
        fokontany: "bevilo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5777063,
          lng: 45.1307427,
        },
        _id: "64f75285ef8f606073e9b29d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "beroroha",
        fokontany: "bemokarana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6280538,
          lng: 45.1962533,
        },
        _id: "64f75285ef8f606073e9b29e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "beroroha",
        fokontany: "soatana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6528176,
          lng: 45.172846,
        },
        _id: "64f75285ef8f606073e9b29f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "beroroha",
        fokontany: "maromiandra nord",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6348228,
          lng: 45.2712363,
        },
        _id: "64f75285ef8f606073e9b2a0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "beroroha",
        fokontany: "iarinogny",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6719623,
          lng: 45.2290435,
        },
        _id: "64f75285ef8f606073e9b2a1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "beroroha",
        fokontany: "beroroha est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6776226,
          lng: 45.1564679,
        },
        _id: "64f75285ef8f606073e9b2a2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "beroroha",
        fokontany: "beroroha ville",
        __v: 0,
      },
      {
        coords: {
          lat: -21.639709,
          lng: 45.0933497,
        },
        _id: "64f75285ef8f606073e9b2a3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "beroroha",
        fokontany: "betorabato",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6290799,
          lng: 45.37487,
        },
        _id: "64f75285ef8f606073e9b2a4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "bemavo",
        fokontany: "bemavo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5171728,
          lng: 45.4780642,
        },
        _id: "64f75285ef8f606073e9b2a5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "bemavo",
        fokontany: "fiolea",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5859038,
          lng: 45.4592239,
        },
        _id: "64f75285ef8f606073e9b2a6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "bemavo",
        fokontany: "soarano ankavia",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6609705,
          lng: 45.4592239,
        },
        _id: "64f75285ef8f606073e9b2a7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "bemavo",
        fokontany: "maromiandra sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6726686,
          lng: 45.3275531,
        },
        _id: "64f75285ef8f606073e9b2a8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "bemavo",
        fokontany: "andasibe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7092848,
          lng: 45.21498769999999,
        },
        _id: "64f75285ef8f606073e9b2a9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "bemavo",
        fokontany: "ipingo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7908111,
          lng: 45.2618568,
        },
        _id: "64f75285ef8f606073e9b2aa",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "bemavo",
        fokontany: "tandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6944833,
          lng: 45.1237292,
        },
        _id: "64f75285ef8f606073e9b2ab",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "fanjakana",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7261899,
          lng: 44.98134520000001,
        },
        _id: "64f75285ef8f606073e9b2ac",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "fanjakana",
        fokontany: "manamby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7328782,
          lng: 45.0653249,
        },
        _id: "64f75285ef8f606073e9b2ad",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "fanjakana",
        fokontany: "soatana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6819392,
          lng: 45.1143795,
        },
        _id: "64f75285ef8f606073e9b2ae",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "fanjakana",
        fokontany: "marokoazy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6774462,
          lng: 45.1354189,
        },
        _id: "64f75285ef8f606073e9b2af",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "fanjakana",
        fokontany: "besatra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7025384,
          lng: 45.1541287,
        },
        _id: "64f75285ef8f606073e9b2b0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "fanjakana",
        fokontany: "bemana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7649258,
          lng: 45.1588073,
        },
        _id: "64f75285ef8f606073e9b2b1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "fanjakana",
        fokontany: "ilaza",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8891287,
          lng: 45.1120424,
        },
        _id: "64f75285ef8f606073e9b2b2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "behisatsy",
        fokontany: "behisatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8461795,
          lng: 45.1775265,
        },
        _id: "64f75285ef8f606073e9b2b3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "behisatsy",
        fokontany: "ambolody I et II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9503105,
          lng: 44.98134520000001,
        },
        _id: "64f75285ef8f606073e9b2b4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "behisatsy",
        fokontany: "ampandra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7364832,
          lng: 45.1167167,
        },
        _id: "64f75285ef8f606073e9b2b5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "behisatsy",
        fokontany: "ampihamy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9513516,
          lng: 45.1026951,
        },
        _id: "64f75285ef8f606073e9b2b6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "behisatsy",
        fokontany: "tsiaripioky",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9771825,
          lng: 45.1775265,
        },
        _id: "64f75285ef8f606073e9b2b7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "behisatsy",
        fokontany: "beteny haranila",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0394591,
          lng: 45.168166,
        },
        _id: "64f75285ef8f606073e9b2b8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "behisatsy",
        fokontany: "maharivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7145878,
          lng: 45.1073685,
        },
        _id: "64f75285ef8f606073e9b2b9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "behisatsy",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.832735,
          lng: 45.0840062,
        },
        _id: "64f75285ef8f606073e9b2ba",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "behisatsy",
        fokontany: "sirave",
        __v: 0,
      },
      {
        coords: {
          lat: -21.88686,
          lng: 45.0641589,
        },
        _id: "64f75285ef8f606073e9b2bb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "behisatsy",
        fokontany: "soafasy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9023197,
          lng: 45.1775265,
        },
        _id: "64f75285ef8f606073e9b2bc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "behisatsy",
        fokontany: "tanandava sarodrano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3806,
          lng: 44.858139,
        },
        _id: "64f75285ef8f606073e9b2bd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "marerano",
        fokontany: "marerano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.25,
          lng: 44.866667,
        },
        _id: "64f75285ef8f606073e9b2be",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "marerano",
        fokontany: "soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4898627,
          lng: 44.9626829,
        },
        _id: "64f75285ef8f606073e9b2bf",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "marerano",
        fokontany: "antanimbaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.416667,
          lng: 44.95,
        },
        _id: "64f75285ef8f606073e9b2c0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "marerano",
        fokontany: "ambondrobe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.724911,
          lng: 44.77203,
        },
        _id: "64f75285ef8f606073e9b2c1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "marerano",
        fokontany: "bemarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.47885,
          lng: 44.7193823,
        },
        _id: "64f75285ef8f606073e9b2c2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "marerano",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4408604,
          lng: 44.831834,
        },
        _id: "64f75285ef8f606073e9b2c3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "marerano",
        fokontany: "bezavo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.341207,
          lng: 44.8224735,
        },
        _id: "64f75285ef8f606073e9b2c4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "marerano",
        fokontany: "vohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.347726,
          lng: 44.7475208,
        },
        _id: "64f75285ef8f606073e9b2c5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "marerano",
        fokontany: "mahavatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3094039,
          lng: 44.972015,
        },
        _id: "64f75285ef8f606073e9b2c6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "marerano",
        fokontany: "ambahobe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3655346,
          lng: 45.0559871,
        },
        _id: "64f75285ef8f606073e9b2c7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "marerano",
        fokontany: "betse",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2916023,
          lng: 44.7475208,
        },
        _id: "64f75285ef8f606073e9b2c8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "marerano",
        fokontany: "belemboka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.667391,
          lng: 44.94146,
        },
        _id: "64f75285ef8f606073e9b2c9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "marerano",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1010252,
          lng: 45.6526959,
        },
        _id: "64f75285ef8f606073e9b2ca",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "mandronarivo",
        fokontany: "mandronarivo I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0793886,
          lng: 45.544065,
        },
        _id: "64f75285ef8f606073e9b2cb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "mandronarivo",
        fokontany: "mandronarivo II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1666907,
          lng: 45.6952715,
        },
        _id: "64f75285ef8f606073e9b2cc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "mandronarivo",
        fokontany: "bevoay II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2106616,
          lng: 45.6290592,
        },
        _id: "64f75285ef8f606073e9b2cd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "mandronarivo",
        fokontany: "bevoay I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1544416,
          lng: 45.544065,
        },
        _id: "64f75285ef8f606073e9b2ce",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "mandronarivo",
        fokontany: "soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.292055,
          lng: 45.506339,
        },
        _id: "64f75285ef8f606073e9b2cf",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "tanamary",
        fokontany: "tanamary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3795631,
          lng: 45.4733534,
        },
        _id: "64f75285ef8f606073e9b2d0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "tanamary",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3546622,
          lng: 45.5535013,
        },
        _id: "64f75285ef8f606073e9b2d1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "tanamary",
        fokontany: "tsihiriky",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4317847,
          lng: 45.4901378,
        },
        _id: "64f75285ef8f606073e9b2d2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "tanamary",
        fokontany: "ambatovory",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0163267,
          lng: 45.6952715,
        },
        _id: "64f75285ef8f606073e9b2d3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "sakena",
        fokontany: "sakena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9410212,
          lng: 45.7236765,
        },
        _id: "64f75285ef8f606073e9b2d4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "sakena",
        fokontany: "tevanigara",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0167416,
          lng: 45.5818214,
        },
        _id: "64f75285ef8f606073e9b2d5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "sakena",
        fokontany: "bekinagna",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0476117,
          lng: 45.704738,
        },
        _id: "64f75285ef8f606073e9b2d6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "beroroha",
        commune: "sakena",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7447078,
          lng: 43.357222,
        },
        _id: "64f75285ef8f606073e9b2d7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "cu morombe",
        fokontany: "tsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6559798,
          lng: 43.5895225,
        },
        _id: "64f75285ef8f606073e9b2d8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "cu morombe",
        fokontany: "isosa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6558081,
          lng: 43.5318499,
        },
        _id: "64f75285ef8f606073e9b2d9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "cu morombe",
        fokontany: "belitsake",
        __v: 0,
      },
      {
        coords: {
          lat: -21.67977,
          lng: 43.520458,
        },
        _id: "64f75285ef8f606073e9b2da",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "cu morombe",
        fokontany: "mangolovolo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7442631,
          lng: 43.4644802,
        },
        _id: "64f75285ef8f606073e9b2db",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "cu morombe",
        fokontany: "morafeno mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.809429,
          lng: 43.4638599,
        },
        _id: "64f75285ef8f606073e9b2dc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "cu morombe",
        fokontany: "mamono",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7345024,
          lng: 43.3849625,
        },
        _id: "64f75285ef8f606073e9b2dd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "cu morombe",
        fokontany: "avaradrova",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7599223,
          lng: 43.4018405,
        },
        _id: "64f75285ef8f606073e9b2de",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "cu morombe",
        fokontany: "tsihake",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7883242,
          lng: 43.3584284,
        },
        _id: "64f75285ef8f606073e9b2df",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "cu morombe",
        fokontany: "ambohitse",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1468934,
          lng: 43.30372190000001,
        },
        _id: "64f75285ef8f606073e9b2e0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "befandefa",
        fokontany: "befandefa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9216049,
          lng: 43.387374,
        },
        _id: "64f75285ef8f606073e9b2e1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "befandefa",
        fokontany: "kotapike",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9055556,
          lng: 43.3005556,
        },
        _id: "64f75285ef8f606073e9b2e2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "befandefa",
        fokontany: "bevato",
        __v: 0,
      },
      {
        coords: {
          lat: -22.074869,
          lng: 43.242371,
        },
        _id: "64f75285ef8f606073e9b2e3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "befandefa",
        fokontany: "andavadoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0484759,
          lng: 43.34877609999999,
        },
        _id: "64f75285ef8f606073e9b2e4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "befandefa",
        fokontany: "ambalorao",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1755023,
          lng: 43.2448963,
        },
        _id: "64f75285ef8f606073e9b2e5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "befandefa",
        fokontany: "lamboara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2261518,
          lng: 43.387374,
        },
        _id: "64f75285ef8f606073e9b2e6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "befandefa",
        fokontany: "vatoavo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2581135,
          lng: 43.2618217,
        },
        _id: "64f75285ef8f606073e9b2e7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "befandefa",
        fokontany: "tampolove",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3151136,
          lng: 43.34877609999999,
        },
        _id: "64f75285ef8f606073e9b2e8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "befandefa",
        fokontany: "ambohitsabo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4,
          lng: 43.266667,
        },
        _id: "64f75285ef8f606073e9b2e9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "befandefa",
        fokontany: "ambatomilo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6056385,
          lng: 43.6783022,
        },
        _id: "64f75285ef8f606073e9b2ea",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "ambahikily",
        fokontany: "ambahikily",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5041901,
          lng: 43.5895225,
        },
        _id: "64f75285ef8f606073e9b2eb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "ambahikily",
        fokontany: "beadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.52548,
          lng: 43.628941,
        },
        _id: "64f75285ef8f606073e9b2ec",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "ambahikily",
        fokontany: "ambalamoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5550551,
          lng: 43.6663143,
        },
        _id: "64f75285ef8f606073e9b2ed",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "ambahikily",
        fokontany: "tsianihy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6117637,
          lng: 43.6087317,
        },
        _id: "64f75285ef8f606073e9b2ee",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "ambahikily",
        fokontany: "namatoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6151506,
          lng: 43.6926839,
        },
        _id: "64f75285ef8f606073e9b2ef",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "ambahikily",
        fokontany: "tongoarahamba",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6310073,
          lng: 43.72382959999999,
        },
        _id: "64f75285ef8f606073e9b2f0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "ambahikily",
        fokontany: "andranomanintsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6577958,
          lng: 43.6926839,
        },
        _id: "64f75285ef8f606073e9b2f1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "ambahikily",
        fokontany: "antanivao tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6594492,
          lng: 43.73819779999999,
        },
        _id: "64f75285ef8f606073e9b2f2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "ambahikily",
        fokontany: "ankilimahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6846856,
          lng: 43.7190392,
        },
        _id: "64f75285ef8f606073e9b2f3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "ambahikily",
        fokontany: "mampanarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7005137,
          lng: 43.7573489,
        },
        _id: "64f75285ef8f606073e9b2f4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "ambahikily",
        fokontany: "tanandava station",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6847195,
          lng: 43.7477743,
        },
        _id: "64f75285ef8f606073e9b2f5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "ambahikily",
        fokontany: "soavary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7383926,
          lng: 43.74298630000001,
        },
        _id: "64f75285ef8f606073e9b2f6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "ambahikily",
        fokontany: "tanandava village",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4727526,
          lng: 43.6279334,
        },
        _id: "64f75285ef8f606073e9b2f7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "antongo vaovao",
        fokontany: "antongo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3898482,
          lng: 43.5029884,
        },
        _id: "64f75285ef8f606073e9b2f8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "antongo vaovao",
        fokontany: "ambohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4155614,
          lng: 43.5703058,
        },
        _id: "64f75285ef8f606073e9b2f9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "antongo vaovao",
        fokontany: "kondy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.491163,
          lng: 43.5126108,
        },
        _id: "64f75285ef8f606073e9b2fa",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "antongo vaovao",
        fokontany: "bekoropoka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5797356,
          lng: 43.5029884,
        },
        _id: "64f75285ef8f606073e9b2fb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "antongo vaovao",
        fokontany: "ankilifolo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9213518,
          lng: 43.8482142,
        },
        _id: "64f75285ef8f606073e9b2fc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "nosy ambositra",
        fokontany: "ankatsankatsa sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8204489,
          lng: 43.8099758,
        },
        _id: "64f75285ef8f606073e9b2fd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "nosy ambositra",
        fokontany: "tantalavalo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9716744,
          lng: 43.89597,
        },
        _id: "64f75285ef8f606073e9b2fe",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "nosy ambositra",
        fokontany: "tanamanitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9224934,
          lng: 43.9676041,
        },
        _id: "64f75285ef8f606073e9b2ff",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "nosy ambositra",
        fokontany: "nosy ambositra",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8822703,
          lng: 44.1910066,
        },
        _id: "64f75285ef8f606073e9b300",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "nosy ambositra",
        fokontany: "soatanimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.973789,
          lng: 44.157452,
        },
        _id: "64f75285ef8f606073e9b301",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "nosy ambositra",
        fokontany: "bejangoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0587752,
          lng: 44.1150413,
        },
        _id: "64f75285ef8f606073e9b302",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "nosy ambositra",
        fokontany: "ankilimionga",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2987474,
          lng: 44.0199152,
        },
        _id: "64f75285ef8f606073e9b303",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "befandriana sud",
        fokontany: "befandriana sud",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0098259,
          lng: 43.8099758,
        },
        _id: "64f75285ef8f606073e9b304",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "befandriana sud",
        fokontany: "manoy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0539865,
          lng: 43.8195382,
        },
        _id: "64f75285ef8f606073e9b305",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "befandriana sud",
        fokontany: "betsingilo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0573336,
          lng: 43.7669217,
        },
        _id: "64f75285ef8f606073e9b306",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "befandriana sud",
        fokontany: "andranoteraka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.123607,
          lng: 43.7812773,
        },
        _id: "64f75285ef8f606073e9b307",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "befandriana sud",
        fokontany: "betaimbala",
        __v: 0,
      },
      {
        coords: {
          lat: -22.167916,
          lng: 43.76213550000001,
        },
        _id: "64f75285ef8f606073e9b308",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "befandriana sud",
        fokontany: "bekimpay",
        __v: 0,
      },
      {
        coords: {
          lat: -22.222225,
          lng: 44.13404389999999,
        },
        _id: "64f75285ef8f606073e9b309",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "befandriana sud",
        fokontany: "lohosy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.365141,
          lng: 44.05106,
        },
        _id: "64f75285ef8f606073e9b30a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "befandriana sud",
        fokontany: "ampilokely",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2460532,
          lng: 43.7729037,
        },
        _id: "64f75285ef8f606073e9b30b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "antanimieva",
        fokontany: "antanimieva",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1800847,
          lng: 43.8529919,
        },
        _id: "64f75285ef8f606073e9b30c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "antanimieva",
        fokontany: "mangotroka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1803889,
          lng: 43.7956286,
        },
        _id: "64f75285ef8f606073e9b30d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "antanimieva",
        fokontany: "beparasy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2084899,
          lng: 43.8529919,
        },
        _id: "64f75285ef8f606073e9b30e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "antanimieva",
        fokontany: "amborondolo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2621782,
          lng: 43.8482142,
        },
        _id: "64f75285ef8f606073e9b30f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "antanimieva",
        fokontany: "manombika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3256464,
          lng: 43.8004115,
        },
        _id: "64f75285ef8f606073e9b310",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "antanimieva",
        fokontany: "analatelo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2947648,
          lng: 43.6806995,
        },
        _id: "64f75285ef8f606073e9b311",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "antanimieva",
        fokontany: "andranomena nord",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3328019,
          lng: 43.6663143,
        },
        _id: "64f75285ef8f606073e9b312",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "antanimieva",
        fokontany: "andranomena sud",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3887492,
          lng: 43.8099758,
        },
        _id: "64f75285ef8f606073e9b313",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "antanimieva",
        fokontany: "andranovorindregataka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1794119,
          lng: 43.6603193,
        },
        _id: "64f75285ef8f606073e9b314",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "basibasy",
        fokontany: "basibasy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.86725,
          lng: 43.66851,
        },
        _id: "64f75285ef8f606073e9b315",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "basibasy",
        fokontany: "maharihy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0103382,
          lng: 43.5799151,
        },
        _id: "64f75285ef8f606073e9b316",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "basibasy",
        fokontany: "andohasakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.085779,
          lng: 43.627499,
        },
        _id: "64f75285ef8f606073e9b317",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "basibasy",
        fokontany: "marolinta",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1996942,
          lng: 43.72382959999999,
        },
        _id: "64f75285ef8f606073e9b318",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "basibasy",
        fokontany: "bemoka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.212515,
          lng: 43.6854936,
        },
        _id: "64f75285ef8f606073e9b319",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "basibasy",
        fokontany: "ampasilava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2565915,
          lng: 43.72382959999999,
        },
        _id: "64f75285ef8f606073e9b31a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "basibasy",
        fokontany: "adabomalinika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2347618,
          lng: 43.6615184,
        },
        _id: "64f75285ef8f606073e9b31b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "basibasy",
        fokontany: "tsiloakarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2762623,
          lng: 43.5799151,
        },
        _id: "64f75285ef8f606073e9b31c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "morombe",
        commune: "basibasy",
        fokontany: "iaborao",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3124773,
          lng: 44.5030878,
        },
        _id: "64f75285ef8f606073e9b31d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "atsimon-dalana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3075054,
          lng: 44.5242912,
        },
        _id: "64f75285ef8f606073e9b31e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "ankalirano tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2539036,
          lng: 44.6724469,
        },
        _id: "64f75285ef8f606073e9b31f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "ambondro somory",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1685855,
          lng: 44.4842324,
        },
        _id: "64f75285ef8f606073e9b320",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "ampandralava centre",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2904952,
          lng: 44.5072115,
        },
        _id: "64f75285ef8f606073e9b321",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "ankazoabo anivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2854609,
          lng: 44.5337119,
        },
        _id: "64f75285ef8f606073e9b322",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "ankerereake",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2562577,
          lng: 44.4889469,
        },
        _id: "64f75285ef8f606073e9b323",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "ankitohy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2979394,
          lng: 44.6536595,
        },
        _id: "64f75285ef8f606073e9b324",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "betsinefo est",
        __v: 0,
      },
      {
        coords: {
          lat: -22.44526,
          lng: 44.4181786,
        },
        _id: "64f75285ef8f606073e9b325",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "miary centre",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3180637,
          lng: 44.5549014,
        },
        _id: "64f75285ef8f606073e9b326",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2676661,
          lng: 44.5784342,
        },
        _id: "64f75285ef8f606073e9b327",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "tandroka ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2914195,
          lng: 44.5572552,
        },
        _id: "64f75285ef8f606073e9b328",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "besavoa morondava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2394077,
          lng: 44.5854917,
        },
        _id: "64f75285ef8f606073e9b329",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "ankilimasy I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2747073,
          lng: 44.5172245,
        },
        _id: "64f75285ef8f606073e9b32a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "ankalirano mahafaly",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3025321,
          lng: 44.545485,
        },
        _id: "64f75285ef8f606073e9b32b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "ambalamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2750571,
          lng: 44.4889469,
        },
        _id: "64f75285ef8f606073e9b32c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "ampisopiso",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2272545,
          lng: 44.5560784,
        },
        _id: "64f75285ef8f606073e9b32d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "ankilimasy II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.343031,
          lng: 44.7241519,
        },
        _id: "64f75285ef8f606073e9b32e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "ampoza",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2650461,
          lng: 44.5384215,
        },
        _id: "64f75285ef8f606073e9b32f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "analambarika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2959299,
          lng: 44.5713756,
        },
        _id: "64f75285ef8f606073e9b330",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "besavoa manandaza",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2194585,
          lng: 44.4181786,
        },
        _id: "64f75285ef8f606073e9b331",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "andranofotsy soaserana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2526226,
          lng: 44.5301793,
        },
        _id: "64f75285ef8f606073e9b332",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "andranovory betsinefo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2915572,
          lng: 44.5466622,
        },
        _id: "64f75285ef8f606073e9b333",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "tanambao II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1697712,
          lng: 44.3709408,
        },
        _id: "64f75285ef8f606073e9b334",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "makiala bas",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4337069,
          lng: 44.3425754,
        },
        _id: "64f75285ef8f606073e9b335",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "besalampy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.307709,
          lng: 44.38039209999999,
        },
        _id: "64f75285ef8f606073e9b336",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "betsinefo ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3815332,
          lng: 44.493661,
        },
        _id: "64f75285ef8f606073e9b337",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "mandramba",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3419943,
          lng: 44.6348645,
        },
        _id: "64f75285ef8f606073e9b338",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "morafeno tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3487202,
          lng: 44.6019551,
        },
        _id: "64f75285ef8f606073e9b339",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "ankilifolo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3180187,
          lng: 44.5872559,
        },
        _id: "64f75285ef8f606073e9b33a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "morafeno beraketa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2261872,
          lng: 44.5148687,
        },
        _id: "64f75285ef8f606073e9b33b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "ankilimiavotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2773308,
          lng: 44.5572552,
        },
        _id: "64f75285ef8f606073e9b33c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "tanambao soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3224746,
          lng: 44.5760814,
        },
        _id: "64f75285ef8f606073e9b33d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ankazoabo sud",
        fokontany: "morafeno I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.466835,
          lng: 44.6536595,
        },
        _id: "64f75285ef8f606073e9b33e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "fotivolo",
        fokontany: "fotivolo centre",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4563315,
          lng: 44.5219358,
        },
        _id: "64f75285ef8f606073e9b33f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "fotivolo",
        fokontany: "fotivolo mitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4708886,
          lng: 44.5949,
        },
        _id: "64f75285ef8f606073e9b340",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "fotivolo",
        fokontany: "fotivolo manombo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3868835,
          lng: 44.5596089,
        },
        _id: "64f75285ef8f606073e9b341",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "fotivolo",
        fokontany: "andranoteraky tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4996142,
          lng: 44.5596089,
        },
        _id: "64f75285ef8f606073e9b342",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "fotivolo",
        fokontany: "mamampaha",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4044194,
          lng: 44.6442629,
        },
        _id: "64f75285ef8f606073e9b343",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "fotivolo",
        fokontany: "bekitohy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3588983,
          lng: 44.545485,
        },
        _id: "64f75285ef8f606073e9b344",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "fotivolo",
        fokontany: "amberomena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4120014,
          lng: 44.5549014,
        },
        _id: "64f75285ef8f606073e9b345",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "fotivolo",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -22.09008,
          lng: 44.63942,
        },
        _id: "64f75285ef8f606073e9b346",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "tandrano",
        fokontany: "tandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1672915,
          lng: 44.5972518,
        },
        _id: "64f75285ef8f606073e9b347",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "tandrano",
        fokontany: "ampandramitsetaky",
        __v: 0,
      },
      {
        coords: {
          lat: -21.874149,
          lng: 44.829861,
        },
        _id: "64f75285ef8f606073e9b348",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "tandrano",
        fokontany: "analabo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.105438,
          lng: 44.53135690000001,
        },
        _id: "64f75285ef8f606073e9b349",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "tandrano",
        fokontany: "soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0462772,
          lng: 44.7850123,
        },
        _id: "64f75285ef8f606073e9b34a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "tandrano",
        fokontany: "andranomanintsy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2041259,
          lng: 44.6536595,
        },
        _id: "64f75285ef8f606073e9b34b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "tandrano",
        fokontany: "ankapoaky",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9787386,
          lng: 44.6818377,
        },
        _id: "64f75285ef8f606073e9b34c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "tandrano",
        fokontany: "ankeriky",
        __v: 0,
      },
      {
        coords: {
          lat: -21.933333,
          lng: 44.8,
        },
        _id: "64f75285ef8f606073e9b34d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "tandrano",
        fokontany: "antsoha",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9232941,
          lng: 44.5972518,
        },
        _id: "64f75285ef8f606073e9b34e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "tandrano",
        fokontany: "beamalo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8164368,
          lng: 44.6630541,
        },
        _id: "64f75285ef8f606073e9b34f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "tandrano",
        fokontany: "betabika",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8681021,
          lng: 44.455935,
        },
        _id: "64f75285ef8f606073e9b350",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "tandrano",
        fokontany: "haminy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9680268,
          lng: 44.493661,
        },
        _id: "64f75285ef8f606073e9b351",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "tandrano",
        fokontany: "ankilivondraky",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1777662,
          lng: 44.7568965,
        },
        _id: "64f75285ef8f606073e9b352",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "tandrano",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1413614,
          lng: 44.6724469,
        },
        _id: "64f75285ef8f606073e9b353",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "tandrano",
        fokontany: "mikaiky",
        __v: 0,
      },
      {
        coords: {
          lat: -21.811138,
          lng: 44.5407761,
        },
        _id: "64f75285ef8f606073e9b354",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "tandrano",
        fokontany: "tsitanandro",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0045207,
          lng: 44.6066578,
        },
        _id: "64f75285ef8f606073e9b355",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "tandrano",
        fokontany: "angavo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.04966,
          lng: 44.337559,
        },
        _id: "64f75285ef8f606073e9b356",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "andranomafana",
        fokontany: "andranomafana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.9441435,
          lng: 44.3425754,
        },
        _id: "64f75285ef8f606073e9b357",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "andranomafana",
        fokontany: "bedo maintirano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0325047,
          lng: 44.2763235,
        },
        _id: "64f75285ef8f606073e9b358",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "andranomafana",
        fokontany: "mariany",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2088089,
          lng: 44.2289441,
        },
        _id: "64f75285ef8f606073e9b359",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "andranomafana",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.316667,
          lng: 44.25,
        },
        _id: "64f75285ef8f606073e9b35a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "andranomafana",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3475949,
          lng: 44.1815176,
        },
        _id: "64f75285ef8f606073e9b35b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "andranomafana",
        fokontany: "tamotamo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4220833,
          lng: 44.2668514,
        },
        _id: "64f75285ef8f606073e9b35c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "andranomafana",
        fokontany: "ambatolahy sarodrano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4233921,
          lng: 44.153039,
        },
        _id: "64f75285ef8f606073e9b35d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "andranomafana",
        fokontany: "ambatolahy soaserana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1877692,
          lng: 45.0699945,
        },
        _id: "64f75285ef8f606073e9b35e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "berenty",
        fokontany: "berenty I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0887511,
          lng: 44.8786084,
        },
        _id: "64f75285ef8f606073e9b35f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "berenty",
        fokontany: "ipetsa ankoabe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0441065,
          lng: 45.0466511,
        },
        _id: "64f75285ef8f606073e9b360",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "berenty",
        fokontany: "mandabe II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.0966915,
          lng: 45.2524792,
        },
        _id: "64f75285ef8f606073e9b361",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "berenty",
        fokontany: "morafeno tsitakadahy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1647688,
          lng: 45.2056196,
        },
        _id: "64f75285ef8f606073e9b362",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "berenty",
        fokontany: "iaborano I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1718322,
          lng: 45.2665464,
        },
        _id: "64f75285ef8f606073e9b363",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "berenty",
        fokontany: "fandranarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1009666,
          lng: 45.1026951,
        },
        _id: "64f75285ef8f606073e9b364",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "berenty",
        fokontany: "ankilimaro",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1501645,
          lng: 44.9440129,
        },
        _id: "64f75285ef8f606073e9b365",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "berenty",
        fokontany: "sahanory",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1806064,
          lng: 44.9906736,
        },
        _id: "64f75285ef8f606073e9b366",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "berenty",
        fokontany: "berenty bara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1738896,
          lng: 45.0638745,
        },
        _id: "64f75285ef8f606073e9b367",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "berenty",
        fokontany: "berenty II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.1513792,
          lng: 45.1400956,
        },
        _id: "64f75285ef8f606073e9b368",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "berenty",
        fokontany: "tsihiriky",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2040585,
          lng: 45.1167167,
        },
        _id: "64f75285ef8f606073e9b369",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "berenty",
        fokontany: "antanimbaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2249504,
          lng: 45.0559871,
        },
        _id: "64f75285ef8f606073e9b36a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "berenty",
        fokontany: "ambabaky",
        __v: 0,
      },
      {
        coords: {
          lat: -22.216667,
          lng: 44.98333299999999,
        },
        _id: "64f75285ef8f606073e9b36b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "berenty",
        fokontany: "ankilivalobe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2623466,
          lng: 44.9440129,
        },
        _id: "64f75285ef8f606073e9b36c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "berenty",
        fokontany: "ankilivalokely",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2992769,
          lng: 45.027985,
        },
        _id: "64f75285ef8f606073e9b36d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "berenty",
        fokontany: "tsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.2758595,
          lng: 45.1213916,
        },
        _id: "64f75285ef8f606073e9b36e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "berenty",
        fokontany: "mandarano I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.310909,
          lng: 45.159039,
        },
        _id: "64f75285ef8f606073e9b36f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "berenty",
        fokontany: "iaborano II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.333333,
          lng: 44.9166669,
        },
        _id: "64f75285ef8f606073e9b370",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "berenty",
        fokontany: "bemandresy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4492,
          lng: 45.11122899999999,
        },
        _id: "64f75285ef8f606073e9b371",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ilemby",
        fokontany: "ilemby",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3745476,
          lng: 45.0559871,
        },
        _id: "64f75285ef8f606073e9b372",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ilemby",
        fokontany: "ankerika besavoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3876681,
          lng: 44.9066503,
        },
        _id: "64f75285ef8f606073e9b373",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ilemby",
        fokontany: "ankaboka bara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4131868,
          lng: 45.1260669,
        },
        _id: "64f75285ef8f606073e9b374",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ilemby",
        fokontany: "fandoma",
        __v: 0,
      },
      {
        coords: {
          lat: -22.3764537,
          lng: 45.168166,
        },
        _id: "64f75285ef8f606073e9b375",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ilemby",
        fokontany: "marovitsika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4589624,
          lng: 45.1564679,
        },
        _id: "64f75285ef8f606073e9b376",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ankazoabo",
        commune: "ilemby",
        fokontany: "anja",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7158718,
          lng: 44.3768481,
        },
        _id: "64f75285ef8f606073e9b377",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "betioky centre",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7485121,
          lng: 44.2763235,
        },
        _id: "64f75285ef8f606073e9b378",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "ampakabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7280857,
          lng: 44.3331165,
        },
        _id: "64f75285ef8f606073e9b379",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "ranonda",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7148618,
          lng: 44.3567602,
        },
        _id: "64f75285ef8f606073e9b37a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "betioky II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7525589,
          lng: 44.3567602,
        },
        _id: "64f75285ef8f606073e9b37b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "ankilivalo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7457419,
          lng: 44.3756667,
        },
        _id: "64f75285ef8f606073e9b37c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "ampasimandroake",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7270966,
          lng: 44.36857759999999,
        },
        _id: "64f75285ef8f606073e9b37d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "antsakoamasy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6952274,
          lng: 44.3851171,
        },
        _id: "64f75285ef8f606073e9b37e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "anketrake",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7166776,
          lng: 44.4040122,
        },
        _id: "64f75285ef8f606073e9b37f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "ambalahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7357175,
          lng: 44.3969274,
        },
        _id: "64f75285ef8f606073e9b380",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "ankilimiangatse",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7407103,
          lng: 44.4417799,
        },
        _id: "64f75285ef8f606073e9b381",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "ankilimalangy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7663666,
          lng: 44.4228998,
        },
        _id: "64f75285ef8f606073e9b382",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "antsakoandahy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7749552,
          lng: 44.4512171,
        },
        _id: "64f75285ef8f606073e9b383",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "ambalabey beroy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8006177,
          lng: 44.43234080000001,
        },
        _id: "64f75285ef8f606073e9b384",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "andranomena mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8241689,
          lng: 44.4842324,
        },
        _id: "64f75285ef8f606073e9b385",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "ranofoty",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7953006,
          lng: 44.5054442,
        },
        _id: "64f75285ef8f606073e9b386",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "fenoarivo behisatse",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7647091,
          lng: 44.4795173,
        },
        _id: "64f75285ef8f606073e9b387",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7458872,
          lng: 44.4795173,
        },
        _id: "64f75285ef8f606073e9b388",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "ankilifolo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7570366,
          lng: 44.5266466,
        },
        _id: "64f75285ef8f606073e9b389",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "besavoa bevoay",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7967893,
          lng: 44.5596089,
        },
        _id: "64f75285ef8f606073e9b38a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "besavoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6941647,
          lng: 44.309461,
        },
        _id: "64f75285ef8f606073e9b38b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "besavoa antsakoamahity",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8326279,
          lng: 44.6160619,
        },
        _id: "64f75285ef8f606073e9b38c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "tsiborake",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8958745,
          lng: 44.5972518,
        },
        _id: "64f75285ef8f606073e9b38d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "belalitse",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9730972,
          lng: 44.6301646,
        },
        _id: "64f75285ef8f606073e9b38e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "ambatofotsy est",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9314015,
          lng: 44.5643159,
        },
        _id: "64f75285ef8f606073e9b38f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "ankazomanga behere",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9570836,
          lng: 44.545485,
        },
        _id: "64f75285ef8f606073e9b390",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "betioky atsimo",
        fokontany: "ankazomanga est",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8970224,
          lng: 44.658357,
        },
        _id: "64f75285ef8f606073e9b391",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "sakamasay",
        fokontany: "sakamasay centre",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7488652,
          lng: 44.6912267,
        },
        _id: "64f75285ef8f606073e9b392",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "sakamasay",
        fokontany: "besaihatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7565893,
          lng: 44.6442629,
        },
        _id: "64f75285ef8f606073e9b393",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "sakamasay",
        fokontany: "beamalo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7868632,
          lng: 44.6771425,
        },
        _id: "64f75285ef8f606073e9b394",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "sakamasay",
        fokontany: "antamotamo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7530407,
          lng: 44.7568965,
        },
        _id: "64f75285ef8f606073e9b395",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "sakamasay",
        fokontany: "vohitolia",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8081708,
          lng: 44.6959205,
        },
        _id: "64f75285ef8f606073e9b396",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "sakamasay",
        fokontany: "ampisopiso",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8312828,
          lng: 44.658357,
        },
        _id: "64f75285ef8f606073e9b397",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "sakamasay",
        fokontany: "beanike centre",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8683904,
          lng: 44.6724469,
        },
        _id: "64f75285ef8f606073e9b398",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "sakamasay",
        fokontany: "beora centre",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8864866,
          lng: 44.6454376,
        },
        _id: "64f75285ef8f606073e9b399",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "sakamasay",
        fokontany: "sakamasay haut",
        __v: 0,
      },
      {
        coords: {
          lat: -23.89082,
          lng: 44.7041334,
        },
        _id: "64f75285ef8f606073e9b39a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "sakamasay",
        fokontany: "beora II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8916335,
          lng: 44.6794902,
        },
        _id: "64f75285ef8f606073e9b39b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "sakamasay",
        fokontany: "beora tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8979423,
          lng: 44.6301646,
        },
        _id: "64f75285ef8f606073e9b39c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "sakamasay",
        fokontany: "lazarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9005567,
          lng: 44.6935736,
        },
        _id: "64f75285ef8f606073e9b39d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "sakamasay",
        fokontany: "beora ankiliabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9191292,
          lng: 44.652485,
        },
        _id: "64f75285ef8f606073e9b39e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "sakamasay",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9320788,
          lng: 44.6395639,
        },
        _id: "64f75285ef8f606073e9b39f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "sakamasay",
        fokontany: "ankililimy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9395923,
          lng: 44.6959205,
        },
        _id: "64f75285ef8f606073e9b3a0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "sakamasay",
        fokontany: "betaimbala",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9499258,
          lng: 44.6677507,
        },
        _id: "64f75285ef8f606073e9b3a1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "sakamasay",
        fokontany: "ampanimaro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9799875,
          lng: 44.6113601,
        },
        _id: "64f75285ef8f606073e9b3a2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "sakamasay",
        fokontany: "ambatofotsy mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8522938,
          lng: 44.4099154,
        },
        _id: "64f75285ef8f606073e9b3a3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ambatry mitsinjo",
        fokontany: "ambatry mitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8258677,
          lng: 44.4276206,
        },
        _id: "64f75285ef8f606073e9b3a4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ambatry mitsinjo",
        fokontany: "andranokototo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.859681,
          lng: 44.4512171,
        },
        _id: "64f75285ef8f606073e9b3a5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ambatry mitsinjo",
        fokontany: "andranotantely",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8819354,
          lng: 44.4417799,
        },
        _id: "64f75285ef8f606073e9b3a6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ambatry mitsinjo",
        fokontany: "ambatry manory",
        __v: 0,
      },
      {
        coords: {
          lat: -23.905919,
          lng: 44.3756667,
        },
        _id: "64f75285ef8f606073e9b3a7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ambatry mitsinjo",
        fokontany: "tsilavondrivotse",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8840754,
          lng: 44.3709408,
        },
        _id: "64f75285ef8f606073e9b3a8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ambatry mitsinjo",
        fokontany: "anadabolava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.862886,
          lng: 44.3969274,
        },
        _id: "64f75285ef8f606073e9b3a9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ambatry mitsinjo",
        fokontany: "ambatry mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8500449,
          lng: 44.40637359999999,
        },
        _id: "64f75285ef8f606073e9b3aa",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ambatry mitsinjo",
        fokontany: "ambatry ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8590387,
          lng: 44.4205393,
        },
        _id: "64f75285ef8f606073e9b3ab",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ambatry mitsinjo",
        fokontany: "ambatry tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -23.66296,
          lng: 44.13476199999999,
        },
        _id: "64f75285ef8f606073e9b3ac",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazomanga ouest",
        fokontany: "ankazomanga ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6086013,
          lng: 44.08652319999999,
        },
        _id: "64f75285ef8f606073e9b3ad",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazomanga ouest",
        fokontany: "vovomena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.616667,
          lng: 44.033333,
        },
        _id: "64f75285ef8f606073e9b3ae",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazomanga ouest",
        fokontany: "miogo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6460653,
          lng: 44.10078439999999,
        },
        _id: "64f75285ef8f606073e9b3af",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazomanga ouest",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6193405,
          lng: 44.1625338,
        },
        _id: "64f75285ef8f606073e9b3b0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazomanga ouest",
        fokontany: "andrahavia",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6663718,
          lng: 44.0437143,
        },
        _id: "64f75285ef8f606073e9b3b1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazomanga ouest",
        fokontany: "tokoendolo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6729849,
          lng: 44.1577866,
        },
        _id: "64f75285ef8f606073e9b3b2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazomanga ouest",
        fokontany: "vonje",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7697042,
          lng: 44.1910066,
        },
        _id: "64f75285ef8f606073e9b3b3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazomanga ouest",
        fokontany: "bevala",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6811967,
          lng: 44.0817686,
        },
        _id: "64f75285ef8f606073e9b3b4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazomanga ouest",
        fokontany: "vombao",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7273731,
          lng: 44.1245436,
        },
        _id: "64f75285ef8f606073e9b3b5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazomanga ouest",
        fokontany: "ankandy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.804701,
          lng: 44.0938299,
        },
        _id: "64f75285ef8f606073e9b3b6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "maroarivo ankazomanga",
        fokontany: "maroarivo ankazomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7241823,
          lng: 44.0008674,
        },
        _id: "64f75285ef8f606073e9b3b7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "maroarivo ankazomanga",
        fokontany: "andranamy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7473752,
          lng: 44.0817686,
        },
        _id: "64f75285ef8f606073e9b3b8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "maroarivo ankazomanga",
        fokontany: "ambatofoty ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7870263,
          lng: 44.0103922,
        },
        _id: "64f75285ef8f606073e9b3b9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "maroarivo ankazomanga",
        fokontany: "analalentike",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8301888,
          lng: 44.0484728,
        },
        _id: "64f75285ef8f606073e9b3ba",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "maroarivo ankazomanga",
        fokontany: "maroarivo tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8512726,
          lng: 44.2004938,
        },
        _id: "64f75285ef8f606073e9b3bb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "maroarivo ankazomanga",
        fokontany: "antsakoamaro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9674317,
          lng: 44.10078439999999,
        },
        _id: "64f75285ef8f606073e9b3bc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "maroarivo ankazomanga",
        fokontany: "ankilimihada",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9647072,
          lng: 44.141168,
        },
        _id: "64f75285ef8f606073e9b3bd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "maroarivo ankazomanga",
        fokontany: "ambory",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9846954,
          lng: 44.1577866,
        },
        _id: "64f75285ef8f606073e9b3be",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "maroarivo ankazomanga",
        fokontany: "andremihory",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9572999,
          lng: 44.2336841,
        },
        _id: "64f75285ef8f606073e9b3bf",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "maroarivo ankazomanga",
        fokontany: "andremba centre II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9268015,
          lng: 44.2004938,
        },
        _id: "64f75285ef8f606073e9b3c0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "maroarivo ankazomanga",
        fokontany: "andremba centre I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9796014,
          lng: 44.22420349999999,
        },
        _id: "64f75285ef8f606073e9b3c1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "maroarivo ankazomanga",
        fokontany: "andremba mihary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0615284,
          lng: 44.1150413,
        },
        _id: "64f75285ef8f606073e9b3c2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "maroarivo ankazomanga",
        fokontany: "andremba mihaga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.766667,
          lng: 44.333333,
        },
        _id: "64f75285ef8f606073e9b3c3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "beantake",
        fokontany: "anontsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7800181,
          lng: 44.3851171,
        },
        _id: "64f75285ef8f606073e9b3c4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "beantake",
        fokontany: "ankiliarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7920307,
          lng: 44.4040122,
        },
        _id: "64f75285ef8f606073e9b3c5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "beantake",
        fokontany: "besatra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8116974,
          lng: 44.3756667,
        },
        _id: "64f75285ef8f606073e9b3c6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "beantake",
        fokontany: "anjambalo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8789148,
          lng: 44.3331165,
        },
        _id: "64f75285ef8f606073e9b3c7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "beantake",
        fokontany: "ampasindava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.852806,
          lng: 44.3662144,
        },
        _id: "64f75285ef8f606073e9b3c8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "beantake",
        fokontany: "befaha",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8994396,
          lng: 44.2763235,
        },
        _id: "64f75285ef8f606073e9b3c9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "beantake",
        fokontany: "ereteke",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8287752,
          lng: 44.3283863,
        },
        _id: "64f75285ef8f606073e9b3ca",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "beantake",
        fokontany: "ankotoboke soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7468286,
          lng: 44.3088694,
        },
        _id: "64f75285ef8f606073e9b3cb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "beantake",
        fokontany: "beantake",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7816385,
          lng: 44.3283863,
        },
        _id: "64f75285ef8f606073e9b3cc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "beantake",
        fokontany: "ankililaza",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7858474,
          lng: 44.2905281,
        },
        _id: "64f75285ef8f606073e9b3cd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "beantake",
        fokontany: "mikaikarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8985676,
          lng: 44.51251269999999,
        },
        _id: "64f75285ef8f606073e9b3ce",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "masiaboay",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8600599,
          lng: 44.5407761,
        },
        _id: "64f75285ef8f606073e9b3cf",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "beara",
        __v: 0,
      },
      {
        coords: {
          lat: -23.874212,
          lng: 44.4889469,
        },
        _id: "64f75285ef8f606073e9b3d0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "bekily",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8896017,
          lng: 44.4983747,
        },
        _id: "64f75285ef8f606073e9b3d1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "ambatokapike nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8922146,
          lng: 44.413457,
        },
        _id: "64f75285ef8f606073e9b3d2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "besakoa nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9078391,
          lng: 44.4158179,
        },
        _id: "64f75285ef8f606073e9b3d3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "besakoa sud",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9238979,
          lng: 44.4040122,
        },
        _id: "64f75285ef8f606073e9b3d4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "anjamena mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9358731,
          lng: 44.4228998,
        },
        _id: "64f75285ef8f606073e9b3d5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "anjamena nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9401773,
          lng: 44.3851171,
        },
        _id: "64f75285ef8f606073e9b3d6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "anjamena sud",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9929047,
          lng: 44.3047285,
        },
        _id: "64f75285ef8f606073e9b3d7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "tanantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9971462,
          lng: 44.3709408,
        },
        _id: "64f75285ef8f606073e9b3d8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "andranotakatse",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9519351,
          lng: 44.411096,
        },
        _id: "64f75285ef8f606073e9b3d9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "anjamena anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9769762,
          lng: 44.413457,
        },
        _id: "64f75285ef8f606073e9b3da",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "andamilamy II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0088921,
          lng: 44.3969274,
        },
        _id: "64f75285ef8f606073e9b3db",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "ankamena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9953638,
          lng: 44.4276206,
        },
        _id: "64f75285ef8f606073e9b3dc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "andamilamy I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0123744,
          lng: 44.4842324,
        },
        _id: "64f75285ef8f606073e9b3dd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "antsifitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.959798,
          lng: 44.4606524,
        },
        _id: "64f75285ef8f606073e9b3de",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "bevotake",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9370929,
          lng: 44.4842324,
        },
        _id: "64f75285ef8f606073e9b3df",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "ambatokapike sud",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9184939,
          lng: 44.4771596,
        },
        _id: "64f75285ef8f606073e9b3e0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "ankalirano anjandroe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9045421,
          lng: 44.5219358,
        },
        _id: "64f75285ef8f606073e9b3e1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "ambalatsiefa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9485886,
          lng: 44.5172245,
        },
        _id: "64f75285ef8f606073e9b3e2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "bemanda",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9786405,
          lng: 44.5572552,
        },
        _id: "64f75285ef8f606073e9b3e3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "antararaty",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9892998,
          lng: 44.5666693,
        },
        _id: "64f75285ef8f606073e9b3e4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "ankatrakatraka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9394776,
          lng: 44.4582937,
        },
        _id: "64f75285ef8f606073e9b3e5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "masiaboay",
        fokontany: "marohira",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5642746,
          lng: 44.4382404,
        },
        _id: "64f75285ef8f606073e9b3e6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "antohabato",
        fokontany: "antohabato",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5339189,
          lng: 44.545485,
        },
        _id: "64f75285ef8f606073e9b3e7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "antohabato",
        fokontany: "bekifonka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5471049,
          lng: 44.5219358,
        },
        _id: "64f75285ef8f606073e9b3e8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "antohabato",
        fokontany: "betakilotse",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5493804,
          lng: 44.4960179,
        },
        _id: "64f75285ef8f606073e9b3e9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "antohabato",
        fokontany: "ankazoabokely",
        __v: 0,
      },
      {
        coords: {
          lat: -23.55146,
          lng: 44.4771596,
        },
        _id: "64f75285ef8f606073e9b3ea",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "antohabato",
        fokontany: "andranomanintsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5764916,
          lng: 44.4795173,
        },
        _id: "64f75285ef8f606073e9b3eb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "antohabato",
        fokontany: "behelo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5915247,
          lng: 44.5030878,
        },
        _id: "64f75285ef8f606073e9b3ec",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "antohabato",
        fokontany: "fenoagnivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5471345,
          lng: 44.4040122,
        },
        _id: "64f75285ef8f606073e9b3ed",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "antohabato",
        fokontany: "andranolava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5667206,
          lng: 44.3756667,
        },
        _id: "64f75285ef8f606073e9b3ee",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "antohabato",
        fokontany: "tsitakabalala",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6563696,
          lng: 44.4276206,
        },
        _id: "64f75285ef8f606073e9b3ef",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "antohabato",
        fokontany: "tongay",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5474344,
          lng: 44.3011788,
        },
        _id: "64f75285ef8f606073e9b3f0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tameantsoa",
        fokontany: "tameantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5414231,
          lng: 44.3212902,
        },
        _id: "64f75285ef8f606073e9b3f1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tameantsoa",
        fokontany: "androvakely",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5742373,
          lng: 44.3283863,
        },
        _id: "64f75285ef8f606073e9b3f2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tameantsoa",
        fokontany: "ranomay",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5655391,
          lng: 44.2999955,
        },
        _id: "64f75285ef8f606073e9b3f3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tameantsoa",
        fokontany: "tanambao bemoky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.575514,
          lng: 44.2786912,
        },
        _id: "64f75285ef8f606073e9b3f4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tameantsoa",
        fokontany: "bekotika",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6367874,
          lng: 44.2194625,
        },
        _id: "64f75285ef8f606073e9b3f5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tameantsoa",
        fokontany: "leobondro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6232296,
          lng: 44.2573774,
        },
        _id: "64f75285ef8f606073e9b3f6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tameantsoa",
        fokontany: "eboro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5747982,
          lng: 44.1815176,
        },
        _id: "64f75285ef8f606073e9b3f7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tameantsoa",
        fokontany: "ankavola",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6032604,
          lng: 44.2999955,
        },
        _id: "64f75285ef8f606073e9b3f8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tameantsoa",
        fokontany: "lazarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.666667,
          lng: 44.35,
        },
        _id: "64f75285ef8f606073e9b3f9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tameantsoa",
        fokontany: "andohasatra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6805648,
          lng: 44.3473041,
        },
        _id: "64f75285ef8f606073e9b3fa",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tameantsoa",
        fokontany: "ankilimary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.666256,
          lng: 44.29526200000001,
        },
        _id: "64f75285ef8f606073e9b3fb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tameantsoa",
        fokontany: "laniry",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7055054,
          lng: 44.2384237,
        },
        _id: "64f75285ef8f606073e9b3fc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tameantsoa",
        fokontany: "anakatsamby",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5303938,
          lng: 44.322473,
        },
        _id: "64f75285ef8f606073e9b3fd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tongobory",
        fokontany: "tongobory",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4675548,
          lng: 44.1910066,
        },
        _id: "64f75285ef8f606073e9b3fe",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tongobory",
        fokontany: "ifanato",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5609218,
          lng: 44.2336841,
        },
        _id: "64f75285ef8f606073e9b3ff",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tongobory",
        fokontany: "anaralava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5386672,
          lng: 44.2431628,
        },
        _id: "64f75285ef8f606073e9b400",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tongobory",
        fokontany: "manasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5221214,
          lng: 44.2763235,
        },
        _id: "64f75285ef8f606073e9b401",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tongobory",
        fokontany: "ampoezy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5057553,
          lng: 44.302362,
        },
        _id: "64f75285ef8f606073e9b402",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tongobory",
        fokontany: "ampandramaro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4934742,
          lng: 44.2905281,
        },
        _id: "64f75285ef8f606073e9b403",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tongobory",
        fokontany: "ambatomitsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4761259,
          lng: 44.2928951,
        },
        _id: "64f75285ef8f606073e9b404",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tongobory",
        fokontany: "belitsaky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4028628,
          lng: 44.2668514,
        },
        _id: "64f75285ef8f606073e9b405",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tongobory",
        fokontany: "tamia",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4000063,
          lng: 44.3212902,
        },
        _id: "64f75285ef8f606073e9b406",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tongobory",
        fokontany: "ampasinivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4139772,
          lng: 44.3283863,
        },
        _id: "64f75285ef8f606073e9b407",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tongobory",
        fokontany: "fenoanivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4483107,
          lng: 44.3378462,
        },
        _id: "64f75285ef8f606073e9b408",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tongobory",
        fokontany: "antevamena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4612862,
          lng: 44.3212902,
        },
        _id: "64f75285ef8f606073e9b409",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tongobory",
        fokontany: "fenoatsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4829905,
          lng: 44.3331165,
        },
        _id: "64f75285ef8f606073e9b40a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tongobory",
        fokontany: "besatra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5071686,
          lng: 44.3709408,
        },
        _id: "64f75285ef8f606073e9b40b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tongobory",
        fokontany: "vohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5425712,
          lng: 44.3378462,
        },
        _id: "64f75285ef8f606073e9b40c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tongobory",
        fokontany: "satrapotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5142928,
          lng: 44.3378462,
        },
        _id: "64f75285ef8f606073e9b40d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tongobory",
        fokontany: "ihotry",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5229248,
          lng: 44.30709479999999,
        },
        _id: "64f75285ef8f606073e9b40e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tongobory",
        fokontany: "andraketa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3866682,
          lng: 44.4181786,
        },
        _id: "64f75285ef8f606073e9b40f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "besely",
        fokontany: "besely",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2572049,
          lng: 44.4512171,
        },
        _id: "64f75285ef8f606073e9b410",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "besely",
        fokontany: "beherike",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2578634,
          lng: 44.4228998,
        },
        _id: "64f75285ef8f606073e9b411",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "besely",
        fokontany: "analamipetraka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2724803,
          lng: 44.4016508,
        },
        _id: "64f75285ef8f606073e9b412",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "besely",
        fokontany: "soaserana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2845975,
          lng: 44.4205393,
        },
        _id: "64f75285ef8f606073e9b413",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "besely",
        fokontany: "manambaro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2867726,
          lng: 44.3945656,
        },
        _id: "64f75285ef8f606073e9b414",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "besely",
        fokontany: "sakoamaro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.305611,
          lng: 44.3945656,
        },
        _id: "64f75285ef8f606073e9b415",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "besely",
        fokontany: "ankiliroa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3173862,
          lng: 44.4276206,
        },
        _id: "64f75285ef8f606073e9b416",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "besely",
        fokontany: "ampikolova",
        __v: 0,
      },
      {
        coords: {
          lat: -23.358073,
          lng: 44.43234080000001,
        },
        _id: "64f75285ef8f606073e9b417",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "besely",
        fokontany: "helokahita",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3628063,
          lng: 44.3662144,
        },
        _id: "64f75285ef8f606073e9b418",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "besely",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4559662,
          lng: 44.4087348,
        },
        _id: "64f75285ef8f606073e9b419",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "besely",
        fokontany: "bebaria",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4317896,
          lng: 44.3709408,
        },
        _id: "64f75285ef8f606073e9b41a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "besely",
        fokontany: "ananavy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2896627,
          lng: 44.2668514,
        },
        _id: "64f75285ef8f606073e9b41b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "vatolatsaka",
        fokontany: "vatolatsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2558055,
          lng: 44.3756667,
        },
        _id: "64f75285ef8f606073e9b41c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "vatolatsaka",
        fokontany: "besavoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2617089,
          lng: 44.3922037,
        },
        _id: "64f75285ef8f606073e9b41d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "vatolatsaka",
        fokontany: "mavozaza",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2513126,
          lng: 44.29526200000001,
        },
        _id: "64f75285ef8f606073e9b41e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "vatolatsaka",
        fokontany: "antanile",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2877513,
          lng: 44.3520324,
        },
        _id: "64f75285ef8f606073e9b41f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "vatolatsaka",
        fokontany: "marosavoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3102835,
          lng: 44.3283863,
        },
        _id: "64f75285ef8f606073e9b420",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "vatolatsaka",
        fokontany: "ampihamy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3304332,
          lng: 44.2715876,
        },
        _id: "64f75285ef8f606073e9b421",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "vatolatsaka",
        fokontany: "ankorohotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3553704,
          lng: 44.2810588,
        },
        _id: "64f75285ef8f606073e9b422",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "vatolatsaka",
        fokontany: "adabodo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3103973,
          lng: 44.1815176,
        },
        _id: "64f75285ef8f606073e9b423",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "vatolatsaka",
        fokontany: "ambahivahy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3574165,
          lng: 44.3283863,
        },
        _id: "64f75285ef8f606073e9b424",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "vatolatsaka",
        fokontany: "satria",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3375682,
          lng: 44.3709408,
        },
        _id: "64f75285ef8f606073e9b425",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "vatolatsaka",
        fokontany: "andranolava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3511945,
          lng: 44.3922037,
        },
        _id: "64f75285ef8f606073e9b426",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "vatolatsaka",
        fokontany: "sevalava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3399232,
          lng: 44.4040122,
        },
        _id: "64f75285ef8f606073e9b427",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "vatolatsaka",
        fokontany: "anavoha",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6481607,
          lng: 44.709999,
        },
        _id: "64f75285ef8f606073e9b428",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazombalala",
        fokontany: "beavoha",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6037995,
          lng: 44.72876369999999,
        },
        _id: "64f75285ef8f606073e9b429",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazombalala",
        fokontany: "mahaty",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6058899,
          lng: 44.658357,
        },
        _id: "64f75285ef8f606073e9b42a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazombalala",
        fokontany: "ambararata morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -23.596742,
          lng: 44.5407761,
        },
        _id: "64f75285ef8f606073e9b42b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazombalala",
        fokontany: "milomboke",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6120266,
          lng: 44.609009,
        },
        _id: "64f75285ef8f606073e9b42c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazombalala",
        fokontany: "manasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.629889,
          lng: 44.6407387,
        },
        _id: "64f75285ef8f606073e9b42d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazombalala",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6052672,
          lng: 44.6794902,
        },
        _id: "64f75285ef8f606073e9b42e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazombalala",
        fokontany: "bevato",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6176462,
          lng: 44.6841851,
        },
        _id: "64f75285ef8f606073e9b42f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazombalala",
        fokontany: "beza mahafaly",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6304445,
          lng: 44.6747948,
        },
        _id: "64f75285ef8f606073e9b430",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazombalala",
        fokontany: "bejio",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6805934,
          lng: 44.6724469,
        },
        _id: "64f75285ef8f606073e9b431",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazombalala",
        fokontany: "analafaly",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6818696,
          lng: 44.6301646,
        },
        _id: "64f75285ef8f606073e9b432",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazombalala",
        fokontany: "ambinda",
        __v: 0,
      },
      {
        coords: {
          lat: -23.704475,
          lng: 44.709999,
        },
        _id: "64f75285ef8f606073e9b433",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazombalala",
        fokontany: "betalaha",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6905769,
          lng: 44.5478393,
        },
        _id: "64f75285ef8f606073e9b434",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazombalala",
        fokontany: "miary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6804139,
          lng: 44.4653693,
        },
        _id: "64f75285ef8f606073e9b435",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazombalala",
        fokontany: "antsakoamaro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7090572,
          lng: 44.4512171,
        },
        _id: "64f75285ef8f606073e9b436",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazombalala",
        fokontany: "besely",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7228261,
          lng: 44.5172245,
        },
        _id: "64f75285ef8f606073e9b437",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazombalala",
        fokontany: "ampasindava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7267094,
          lng: 44.5972518,
        },
        _id: "64f75285ef8f606073e9b438",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankazombalala",
        fokontany: "ampanihy antarabory",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5010185,
          lng: 44.5013205,
        },
        _id: "64f75285ef8f606073e9b439",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "bezaha I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4519098,
          lng: 44.5054442,
        },
        _id: "64f75285ef8f606073e9b43a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "tanambao I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4541532,
          lng: 44.4795173,
        },
        _id: "64f75285ef8f606073e9b43b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "besakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4729742,
          lng: 44.4795173,
        },
        _id: "64f75285ef8f606073e9b43c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4754291,
          lng: 44.5054442,
        },
        _id: "64f75285ef8f606073e9b43d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "ampihamy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5297449,
          lng: 44.5831393,
        },
        _id: "64f75285ef8f606073e9b43e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "sakamalio",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5256303,
          lng: 44.5619625,
        },
        _id: "64f75285ef8f606073e9b43f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "sakalaly",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5117208,
          lng: 44.5549014,
        },
        _id: "64f75285ef8f606073e9b440",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "saloavaratsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5030832,
          lng: 44.5266466,
        },
        _id: "64f75285ef8f606073e9b441",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "mandiso",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4921733,
          lng: 44.5242912,
        },
        _id: "64f75285ef8f606073e9b442",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "besely",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4925511,
          lng: 44.5101567,
        },
        _id: "64f75285ef8f606073e9b443",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "bezaha IV",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4929272,
          lng: 44.4960179,
        },
        _id: "64f75285ef8f606073e9b444",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "bezaha II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5054408,
          lng: 44.4971963,
        },
        _id: "64f75285ef8f606073e9b445",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "bezaha III",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5130602,
          lng: 44.5054442,
        },
        _id: "64f75285ef8f606073e9b446",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "ampasindava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5166386,
          lng: 44.4889469,
        },
        _id: "64f75285ef8f606073e9b447",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "fenoanala",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4951809,
          lng: 44.4700858,
        },
        _id: "64f75285ef8f606073e9b448",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "andranolava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5219101,
          lng: 44.4677276,
        },
        _id: "64f75285ef8f606073e9b449",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "anja",
        __v: 0,
      },
      {
        coords: {
          lat: -23.530193,
          lng: 44.4512171,
        },
        _id: "64f75285ef8f606073e9b44a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "behisatra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5019517,
          lng: 44.4512171,
        },
        _id: "64f75285ef8f606073e9b44b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "misay",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5275539,
          lng: 44.43234080000001,
        },
        _id: "64f75285ef8f606073e9b44c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "behabobo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.512476,
          lng: 44.4087348,
        },
        _id: "64f75285ef8f606073e9b44d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "bezaha",
        fokontany: "ampasimaiky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4505117,
          lng: 44.5584321,
        },
        _id: "64f75285ef8f606073e9b44e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "manalobe",
        fokontany: "manalobe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3720476,
          lng: 44.6231138,
        },
        _id: "64f75285ef8f606073e9b44f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "manalobe",
        fokontany: "ankiliabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3812556,
          lng: 44.6301646,
        },
        _id: "64f75285ef8f606073e9b450",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "manalobe",
        fokontany: "mihaiky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4,
          lng: 44.633333,
        },
        _id: "64f75285ef8f606073e9b451",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "manalobe",
        fokontany: "sakaravy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.416667,
          lng: 44.566667,
        },
        _id: "64f75285ef8f606073e9b452",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "manalobe",
        fokontany: "ankilimasy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4244595,
          lng: 44.5360667,
        },
        _id: "64f75285ef8f606073e9b453",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "manalobe",
        fokontany: "ankazotsivany",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4387513,
          lng: 44.5290018,
        },
        _id: "64f75285ef8f606073e9b454",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "manalobe",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4511671,
          lng: 44.5337119,
        },
        _id: "64f75285ef8f606073e9b455",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "manalobe",
        fokontany: "morafenobe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4579377,
          lng: 44.5295906,
        },
        _id: "64f75285ef8f606073e9b456",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "manalobe",
        fokontany: "soamidriso",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4716691,
          lng: 44.5290018,
        },
        _id: "64f75285ef8f606073e9b457",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "manalobe",
        fokontany: "ankilitelo I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5135584,
          lng: 44.6019551,
        },
        _id: "64f75285ef8f606073e9b458",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "manalobe",
        fokontany: "ankilitelo atsimondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3596974,
          lng: 44.493661,
        },
        _id: "64f75285ef8f606073e9b459",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "andranomangatsiaka",
        fokontany: "andranomangatsiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.316667,
          lng: 44.5,
        },
        _id: "64f75285ef8f606073e9b45a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "andranomangatsiaka",
        fokontany: "andoharano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3362732,
          lng: 44.5525475,
        },
        _id: "64f75285ef8f606073e9b45b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "andranomangatsiaka",
        fokontany: "fenoambony",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3473617,
          lng: 44.5478393,
        },
        _id: "64f75285ef8f606073e9b45c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "andranomangatsiaka",
        fokontany: "bevaro bas",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3605604,
          lng: 44.5831393,
        },
        _id: "64f75285ef8f606073e9b45d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "andranomangatsiaka",
        fokontany: "mahabo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3861138,
          lng: 44.5643159,
        },
        _id: "64f75285ef8f606073e9b45e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "andranomangatsiaka",
        fokontany: "analamanitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3525989,
          lng: 44.5266466,
        },
        _id: "64f75285ef8f606073e9b45f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "andranomangatsiaka",
        fokontany: "kiliarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3934147,
          lng: 44.5242912,
        },
        _id: "64f75285ef8f606073e9b460",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "andranomangatsiaka",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3973877,
          lng: 44.5525475,
        },
        _id: "64f75285ef8f606073e9b461",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "andranomangatsiaka",
        fokontany: "besatra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4058338,
          lng: 44.5290018,
        },
        _id: "64f75285ef8f606073e9b462",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "andranomangatsiaka",
        fokontany: "mibay",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3415767,
          lng: 44.4653693,
        },
        _id: "64f75285ef8f606073e9b463",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "andranomangatsiaka",
        fokontany: "ambario",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3914765,
          lng: 44.4771596,
        },
        _id: "64f75285ef8f606073e9b464",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "andranomangatsiaka",
        fokontany: "mitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4095756,
          lng: 44.5054442,
        },
        _id: "64f75285ef8f606073e9b465",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "andranomangatsiaka",
        fokontany: "tanambao II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4259218,
          lng: 44.4795173,
        },
        _id: "64f75285ef8f606073e9b466",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "andranomangatsiaka",
        fokontany: "fenoanivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4406271,
          lng: 44.5172245,
        },
        _id: "64f75285ef8f606073e9b467",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "andranomangatsiaka",
        fokontany: "manantsa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2816498,
          lng: 44.6066578,
        },
        _id: "64f75285ef8f606073e9b468",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankilivalo",
        fokontany: "ankilivalo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2437174,
          lng: 44.6207633,
        },
        _id: "64f75285ef8f606073e9b469",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankilivalo",
        fokontany: "mandabe II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2430148,
          lng: 44.6489614,
        },
        _id: "64f75285ef8f606073e9b46a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankilivalo",
        fokontany: "beroanga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.253982,
          lng: 44.709999,
        },
        _id: "64f75285ef8f606073e9b46b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankilivalo",
        fokontany: "bevaro haut",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2820031,
          lng: 44.59254809999999,
        },
        _id: "64f75285ef8f606073e9b46c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankilivalo",
        fokontany: "vohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2205972,
          lng: 44.5407761,
        },
        _id: "64f75285ef8f606073e9b46d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankilivalo",
        fokontany: "antsavoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.23403,
          lng: 44.5030878,
        },
        _id: "64f75285ef8f606073e9b46e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankilivalo",
        fokontany: "ankaboka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2659494,
          lng: 44.4795173,
        },
        _id: "64f75285ef8f606073e9b46f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankilivalo",
        fokontany: "tonga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3298055,
          lng: 44.6818377,
        },
        _id: "64f75285ef8f606073e9b470",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankilivalo",
        fokontany: "tanambao elapa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3350152,
          lng: 44.6019551,
        },
        _id: "64f75285ef8f606073e9b471",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "ankilivalo",
        fokontany: "ankilimivory",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8813994,
          lng: 44.7522089,
        },
        _id: "64f75285ef8f606073e9b472",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "soamanonga II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8054303,
          lng: 44.7803275,
        },
        _id: "64f75285ef8f606073e9b473",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "manintsy II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.83403,
          lng: 44.7662703,
        },
        _id: "64f75285ef8f606073e9b474",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "behomby",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8454425,
          lng: 44.7990638,
        },
        _id: "64f75285ef8f606073e9b475",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "bevinda",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8570744,
          lng: 44.82481380000001,
        },
        _id: "64f75285ef8f606073e9b476",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "bevato",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8657299,
          lng: 44.8458713,
        },
        _id: "64f75285ef8f606073e9b477",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "betsirevo bas",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8714151,
          lng: 44.8622428,
        },
        _id: "64f75285ef8f606073e9b478",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "sakoamaro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8800269,
          lng: 44.8388532,
        },
        _id: "64f75285ef8f606073e9b479",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "betsirevo haut",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8716114,
          lng: 44.8107701,
        },
        _id: "64f75285ef8f606073e9b47a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "besakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8626372,
          lng: 44.7522089,
        },
        _id: "64f75285ef8f606073e9b47b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "soamanonga I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8856734,
          lng: 44.8107701,
        },
        _id: "64f75285ef8f606073e9b47c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "ampipitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8972888,
          lng: 44.8365136,
        },
        _id: "64f75285ef8f606073e9b47d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "ambotaky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8901532,
          lng: 44.8622428,
        },
        _id: "64f75285ef8f606073e9b47e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "vohimariry",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8938052,
          lng: 44.8014053,
        },
        _id: "64f75285ef8f606073e9b47f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "beroanga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9041383,
          lng: 44.7732994,
        },
        _id: "64f75285ef8f606073e9b480",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "sakoambe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9135555,
          lng: 44.8177925,
        },
        _id: "64f75285ef8f606073e9b481",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "ambatomainty haut",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9214127,
          lng: 44.7709565,
        },
        _id: "64f75285ef8f606073e9b482",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "beroanga I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9011058,
          lng: 44.7240732,
        },
        _id: "64f75285ef8f606073e9b483",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "vohipotsy avaradrano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9173931,
          lng: 44.7164502,
        },
        _id: "64f75285ef8f606073e9b484",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "sakoatelo bis",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9184897,
          lng: 44.7070663,
        },
        _id: "64f75285ef8f606073e9b485",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "sakoatelo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9268848,
          lng: 44.8388532,
        },
        _id: "64f75285ef8f606073e9b486",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "ambatomainty II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9317507,
          lng: 44.7428322,
        },
        _id: "64f75285ef8f606073e9b487",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "vohipotsy sud I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9475995,
          lng: 44.82715400000001,
        },
        _id: "64f75285ef8f606073e9b488",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "ambatomainty I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9426512,
          lng: 44.7896966,
        },
        _id: "64f75285ef8f606073e9b489",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "amboasary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9296141,
          lng: 44.7135179,
        },
        _id: "64f75285ef8f606073e9b48a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "vohipotsy II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9480264,
          lng: 44.7240732,
        },
        _id: "64f75285ef8f606073e9b48b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "marofototsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9564725,
          lng: 44.7967222,
        },
        _id: "64f75285ef8f606073e9b48c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soamanonga",
        fokontany: "amboasary mitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.547791,
          lng: 44.676201,
        },
        _id: "64f75285ef8f606073e9b48d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "fenoandala",
        fokontany: "fenoandala",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5017898,
          lng: 44.6865325,
        },
        _id: "64f75285ef8f606073e9b48e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "fenoandala",
        fokontany: "mahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5025869,
          lng: 44.658357,
        },
        _id: "64f75285ef8f606073e9b48f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "fenoandala",
        fokontany: "soamidriso",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5271608,
          lng: 44.6747948,
        },
        _id: "64f75285ef8f606073e9b490",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "fenoandala",
        fokontany: "fanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5533217,
          lng: 44.6900532,
        },
        _id: "64f75285ef8f606073e9b491",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "fenoandala",
        fokontany: "bekapiky I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5555325,
          lng: 44.6677507,
        },
        _id: "64f75285ef8f606073e9b492",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "fenoandala",
        fokontany: "ankilimirafy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5695406,
          lng: 44.6160619,
        },
        _id: "64f75285ef8f606073e9b493",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "fenoandala",
        fokontany: "soamanonga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5717271,
          lng: 44.6489614,
        },
        _id: "64f75285ef8f606073e9b494",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "fenoandala",
        fokontany: "besahatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8105718,
          lng: 44.9066503,
        },
        _id: "64f75285ef8f606073e9b495",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soaserana",
        fokontany: "soaserana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7567106,
          lng: 44.8365136,
        },
        _id: "64f75285ef8f606073e9b496",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soaserana",
        fokontany: "ihela bas",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8016834,
          lng: 44.8926315,
        },
        _id: "64f75285ef8f606073e9b497",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soaserana",
        fokontany: "ankotika II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8135336,
          lng: 44.91132229999999,
        },
        _id: "64f75285ef8f606073e9b498",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soaserana",
        fokontany: "fanatera",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8421076,
          lng: 44.89730489999999,
        },
        _id: "64f75285ef8f606073e9b499",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soaserana",
        fokontany: "ambatomitikitsy II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8731624,
          lng: 44.9019778,
        },
        _id: "64f75285ef8f606073e9b49a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soaserana",
        fokontany: "antanile",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8702057,
          lng: 44.9860096,
        },
        _id: "64f75285ef8f606073e9b49b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soaserana",
        fokontany: "kalambo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8899215,
          lng: 44.9136581,
        },
        _id: "64f75285ef8f606073e9b49c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soaserana",
        fokontany: "ampanonona",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9032273,
          lng: 44.8902946,
        },
        _id: "64f75285ef8f606073e9b49d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soaserana",
        fokontany: "belamoty",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9106211,
          lng: 44.9019778,
        },
        _id: "64f75285ef8f606073e9b49e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soaserana",
        fokontany: "belamoty andafindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9113603,
          lng: 44.8809459,
        },
        _id: "64f75285ef8f606073e9b49f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soaserana",
        fokontany: "marosavoa haut",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9328033,
          lng: 44.8926315,
        },
        _id: "64f75285ef8f606073e9b4a0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soaserana",
        fokontany: "mitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.939709,
          lng: 44.8739331,
        },
        _id: "64f75285ef8f606073e9b4a1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soaserana",
        fokontany: "ambatomitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7854425,
          lng: 44.91132229999999,
        },
        _id: "64f75285ef8f606073e9b4a2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "soaserana",
        fokontany: "ankotika",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8756153,
          lng: 44.8762708,
        },
        _id: "64f75285ef8f606073e9b4a3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "marosavoa",
        fokontany: "marosavoa bas",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7576286,
          lng: 44.8084291,
        },
        _id: "64f75285ef8f606073e9b4a4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "marosavoa",
        fokontany: "ihela bas",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7798105,
          lng: 44.7990638,
        },
        _id: "64f75285ef8f606073e9b4a5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "marosavoa",
        fokontany: "ihela haut",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8179282,
          lng: 44.8739331,
        },
        _id: "64f75285ef8f606073e9b4a6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "marosavoa",
        fokontany: "ampandrandava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8257521,
          lng: 44.82715400000001,
        },
        _id: "64f75285ef8f606073e9b4a7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "marosavoa",
        fokontany: "manintsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8381453,
          lng: 44.8762708,
        },
        _id: "64f75285ef8f606073e9b4a8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "marosavoa",
        fokontany: "ampasy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.852677,
          lng: 44.8622428,
        },
        _id: "64f75285ef8f606073e9b4a9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "marosavoa",
        fokontany: "ankazomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8645254,
          lng: 44.8809459,
        },
        _id: "64f75285ef8f606073e9b4aa",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "marosavoa",
        fokontany: "ambatomitikitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8839994,
          lng: 44.8821146,
        },
        _id: "64f75285ef8f606073e9b4ab",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "marosavoa",
        fokontany: "ankilimilitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8896666,
          lng: 44.8762708,
        },
        _id: "64f75285ef8f606073e9b4ac",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "marosavoa",
        fokontany: "maditsikamalo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9153004,
          lng: 44.8575658,
        },
        _id: "64f75285ef8f606073e9b4ad",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "marosavoa",
        fokontany: "ankaratsokaky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8,
          lng: 44.833333,
        },
        _id: "64f75285ef8f606073e9b4ae",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "marosavoa",
        fokontany: "betampy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5265013,
          lng: 44.7105201,
        },
        _id: "64f75285ef8f606073e9b4af",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "salobe",
        fokontany: "salobe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4790212,
          lng: 44.7170366,
        },
        _id: "64f75285ef8f606073e9b4b0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "salobe",
        fokontany: "manasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5118674,
          lng: 44.7170366,
        },
        _id: "64f75285ef8f606073e9b4b1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "salobe",
        fokontany: "saloanivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5131605,
          lng: 44.7264185,
        },
        _id: "64f75285ef8f606073e9b4b2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "salobe",
        fokontany: "andromasy II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5259444,
          lng: 44.7170366,
        },
        _id: "64f75285ef8f606073e9b4b3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "salobe",
        fokontany: "mahazoamoro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5239034,
          lng: 44.7064797,
        },
        _id: "64f75285ef8f606073e9b4b4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "salobe",
        fokontany: "saloambany",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5297511,
          lng: 44.6935736,
        },
        _id: "64f75285ef8f606073e9b4b5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "salobe",
        fokontany: "mitsinjo saloambany",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5283554,
          lng: 44.6877061,
        },
        _id: "64f75285ef8f606073e9b4b6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "salobe",
        fokontany: "tanambao bas",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5451241,
          lng: 44.7029603,
        },
        _id: "64f75285ef8f606073e9b4b7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "salobe",
        fokontany: "bekapiky II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5572956,
          lng: 44.7146908,
        },
        _id: "64f75285ef8f606073e9b4b8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "salobe",
        fokontany: "fenoarivo II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.509455,
          lng: 44.7463487,
        },
        _id: "64f75285ef8f606073e9b4b9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tanambao haut",
        fokontany: "befamoty",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5143504,
          lng: 44.7393155,
        },
        _id: "64f75285ef8f606073e9b4ba",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tanambao haut",
        fokontany: "betaly",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5255356,
          lng: 44.7311087,
        },
        _id: "64f75285ef8f606073e9b4bb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tanambao haut",
        fokontany: "andromasy I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5379091,
          lng: 44.7357985,
        },
        _id: "64f75285ef8f606073e9b4bc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tanambao haut",
        fokontany: "tanambao ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5312075,
          lng: 44.7510369,
        },
        _id: "64f75285ef8f606073e9b4bd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tanambao haut",
        fokontany: "besatra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5217236,
          lng: 44.7545527,
        },
        _id: "64f75285ef8f606073e9b4be",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tanambao haut",
        fokontany: "beadabo II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5353794,
          lng: 44.7686135,
        },
        _id: "64f75285ef8f606073e9b4bf",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tanambao haut",
        fokontany: "beadabo I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5590368,
          lng: 44.76158359999999,
        },
        _id: "64f75285ef8f606073e9b4c0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tanambao haut",
        fokontany: "marevokasara",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5566745,
          lng: 44.7357985,
        },
        _id: "64f75285ef8f606073e9b4c1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tanambao haut",
        fokontany: "fenoarivo I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.568941,
          lng: 44.7440044,
        },
        _id: "64f75285ef8f606073e9b4c2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tanambao haut",
        fokontany: "ankilimaroanaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5711654,
          lng: 44.7217278,
        },
        _id: "64f75285ef8f606073e9b4c3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "tanambao haut",
        fokontany: "tanambao satrapotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5622477,
          lng: 44.8107701,
        },
        _id: "64f75285ef8f606073e9b4c4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "belamoty",
        fokontany: "belamoty ankilitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5202718,
          lng: 44.8037467,
        },
        _id: "64f75285ef8f606073e9b4c5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "belamoty",
        fokontany: "betamenaky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5366858,
          lng: 44.8294941,
        },
        _id: "64f75285ef8f606073e9b4c6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "belamoty",
        fokontany: "ambondrompaly",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5434987,
          lng: 44.8107701,
        },
        _id: "64f75285ef8f606073e9b4c7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "belamoty",
        fokontany: "mihaiky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5401886,
          lng: 44.7908676,
        },
        _id: "64f75285ef8f606073e9b4c8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "belamoty",
        fokontany: "morafeno II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5480725,
          lng: 44.7885255,
        },
        _id: "64f75285ef8f606073e9b4c9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "belamoty",
        fokontany: "ankilimary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5529791,
          lng: 44.8072585,
        },
        _id: "64f75285ef8f606073e9b4ca",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "belamoty",
        fokontany: "behisatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5658641,
          lng: 44.8458713,
        },
        _id: "64f75285ef8f606073e9b4cb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "belamoty",
        fokontany: "soaserana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5377522,
          lng: 44.8458713,
        },
        _id: "64f75285ef8f606073e9b4cc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "belamoty",
        fokontany: "sakoapolo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5616027,
          lng: 44.7803275,
        },
        _id: "64f75285ef8f606073e9b4cd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "belamoty",
        fokontany: "soatamia",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5711943,
          lng: 44.82481380000001,
        },
        _id: "64f75285ef8f606073e9b4ce",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "belamoty",
        fokontany: "savazy I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6131547,
          lng: 44.831834,
        },
        _id: "64f75285ef8f606073e9b4cf",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "belamoty",
        fokontany: "savazy II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6583618,
          lng: 44.7850123,
        },
        _id: "64f75285ef8f606073e9b4d0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "belamoty",
        fokontany: "savazy IV",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7059709,
          lng: 44.8599044,
        },
        _id: "64f75285ef8f606073e9b4d1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "belamoty",
        fokontany: "bemelo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2205972,
          lng: 44.5407761,
        },
        _id: "64f75285ef8f606073e9b4d2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "antsavoa",
        fokontany: "antsavoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5413538,
          lng: 44.7779849,
        },
        _id: "64f75285ef8f606073e9b4d3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "antsavoa",
        fokontany: "morafeno antsavoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4588518,
          lng: 44.7662703,
        },
        _id: "64f75285ef8f606073e9b4d4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "montifeno",
        fokontany: "montifeno",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4281172,
          lng: 44.7475208,
        },
        _id: "64f75285ef8f606073e9b4d5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "montifeno",
        fokontany: "efontsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4797832,
          lng: 44.7990638,
        },
        _id: "64f75285ef8f606073e9b4d6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "montifeno",
        fokontany: "mantora",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5087369,
          lng: 44.7709565,
        },
        _id: "64f75285ef8f606073e9b4d7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "montifeno",
        fokontany: "kiliso",
        __v: 0,
      },
      {
        coords: {
          lat: -23.90074,
          lng: 44.9529799,
        },
        _id: "64f75285ef8f606073e9b4d8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "lazarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6561811,
          lng: 45.0489849,
        },
        _id: "64f75285ef8f606073e9b4d9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "androangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.671703,
          lng: 44.95334889999999,
        },
        _id: "64f75285ef8f606073e9b4da",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "andoharano vohiboro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6579946,
          lng: 44.9906736,
        },
        _id: "64f75285ef8f606073e9b4db",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "ankazondringitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6666845,
          lng: 45.0840062,
        },
        _id: "64f75285ef8f606073e9b4dc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "ankilimarovahatse",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7022766,
          lng: 45.027985,
        },
        _id: "64f75285ef8f606073e9b4dd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "bohaky haut",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6983756,
          lng: 44.99533700000001,
        },
        _id: "64f75285ef8f606073e9b4de",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "soarano ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7173211,
          lng: 44.98834170000001,
        },
        _id: "64f75285ef8f606073e9b4df",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "andakato",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7160012,
          lng: 44.9346751,
        },
        _id: "64f75285ef8f606073e9b4e0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "beseva",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7223975,
          lng: 45.0699945,
        },
        _id: "64f75285ef8f606073e9b4e1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "iaborotsy haut",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7484554,
          lng: 45.1026951,
        },
        _id: "64f75285ef8f606073e9b4e2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "tsanarena sud",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7702536,
          lng: 45.0093264,
        },
        _id: "64f75285ef8f606073e9b4e3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "ampiha haut",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7489214,
          lng: 44.8832833,
        },
        _id: "64f75285ef8f606073e9b4e4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "vohipotsy nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7657689,
          lng: 44.9393443,
        },
        _id: "64f75285ef8f606073e9b4e5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "vohipotsy centre",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7928886,
          lng: 44.96734920000001,
        },
        _id: "64f75285ef8f606073e9b4e6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "vohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7820049,
          lng: 45.07933509999999,
        },
        _id: "64f75285ef8f606073e9b4e7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "tsanarena nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8027295,
          lng: 45.0466511,
        },
        _id: "64f75285ef8f606073e9b4e8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "ankilivao",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8145606,
          lng: 44.972015,
        },
        _id: "64f75285ef8f606073e9b4e9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "ambararata sud",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8465683,
          lng: 44.94868109999999,
        },
        _id: "64f75285ef8f606073e9b4ea",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "lambosy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8381808,
          lng: 44.9206649,
        },
        _id: "64f75285ef8f606073e9b4eb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "mibay tanimandry",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8613431,
          lng: 44.9276705,
        },
        _id: "64f75285ef8f606073e9b4ec",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "mandarano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8551898,
          lng: 44.9696822,
        },
        _id: "64f75285ef8f606073e9b4ed",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "mandahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8480465,
          lng: 45.004663,
        },
        _id: "64f75285ef8f606073e9b4ee",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "maroara",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8261152,
          lng: 45.068827,
        },
        _id: "64f75285ef8f606073e9b4ef",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "ankily haut",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8470567,
          lng: 45.0653249,
        },
        _id: "64f75285ef8f606073e9b4f0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "anaviavy sud",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8776019,
          lng: 45.0466511,
        },
        _id: "64f75285ef8f606073e9b4f1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "vohitoka nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8948187,
          lng: 44.99533700000001,
        },
        _id: "64f75285ef8f606073e9b4f2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "lazarivo II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8909066,
          lng: 44.9300055,
        },
        _id: "64f75285ef8f606073e9b4f3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "beantaky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9098782,
          lng: 44.9230002,
        },
        _id: "64f75285ef8f606073e9b4f4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "farezy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9224444,
          lng: 44.9206649,
        },
        _id: "64f75285ef8f606073e9b4f5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9369598,
          lng: 44.9510151,
        },
        _id: "64f75285ef8f606073e9b4f6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "betaly ankaranabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9169829,
          lng: 45.0139904,
        },
        _id: "64f75285ef8f606073e9b4f7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "vohitsevo I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9128333,
          lng: 45.0723295,
        },
        _id: "64f75285ef8f606073e9b4f8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "antsonjo I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9433676,
          lng: 45.0536529,
        },
        _id: "64f75285ef8f606073e9b4f9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "andoharano nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9574072,
          lng: 45.0536529,
        },
        _id: "64f75285ef8f606073e9b4fa",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "andoharano sud",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9468241,
          lng: 44.9370098,
        },
        _id: "64f75285ef8f606073e9b4fb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "betaly I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9478293,
          lng: 44.9089864,
        },
        _id: "64f75285ef8f606073e9b4fc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "betaly II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9667669,
          lng: 44.9463471,
        },
        _id: "64f75285ef8f606073e9b4fd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "andoakaolo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9485075,
          lng: 44.9766804,
        },
        _id: "64f75285ef8f606073e9b4fe",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "andranomavo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9696536,
          lng: 45.004663,
        },
        _id: "64f75285ef8f606073e9b4ff",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "vohitsevo haut",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9751606,
          lng: 45.0699945,
        },
        _id: "64f75285ef8f606073e9b500",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "antohabato",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9751029,
          lng: 44.9743477,
        },
        _id: "64f75285ef8f606073e9b501",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "mandafotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7397046,
          lng: 44.972015,
        },
        _id: "64f75285ef8f606073e9b502",
        province: "toliary",
        region: "atsimo andrefana",
        district: "betioky atsimo",
        commune: "lazarivo",
        fokontany: "bekopay",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6845311,
          lng: 44.76158359999999,
        },
        _id: "64f75285ef8f606073e9b503",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "ampanihy centre",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5614317,
          lng: 44.8645811,
        },
        _id: "64f75285ef8f606073e9b504",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "reambohitse bas",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6428055,
          lng: 44.8599044,
        },
        _id: "64f75285ef8f606073e9b505",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "ambalavato",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7014582,
          lng: 44.8037467,
        },
        _id: "64f75285ef8f606073e9b506",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "lafibato",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6918867,
          lng: 44.7357985,
        },
        _id: "64f75285ef8f606073e9b507",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "andranomamy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7177319,
          lng: 44.8599044,
        },
        _id: "64f75285ef8f606073e9b508",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "antanimainty",
        __v: 0,
      },
      {
        coords: {
          lat: -24.689091,
          lng: 44.7463487,
        },
        _id: "64f75285ef8f606073e9b509",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "ambohimahatazana",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7032839,
          lng: 44.76158359999999,
        },
        _id: "64f75285ef8f606073e9b50a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "ambalatsiefa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7059554,
          lng: 44.7357985,
        },
        _id: "64f75285ef8f606073e9b50b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "belaza",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7572332,
          lng: 44.7428322,
        },
        _id: "64f75285ef8f606073e9b50c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "tokove",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7301878,
          lng: 44.7896966,
        },
        _id: "64f75285ef8f606073e9b50d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "gorogoda",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7762216,
          lng: 44.5172245,
        },
        _id: "64f75285ef8f606073e9b50e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "ankilitoka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7926532,
          lng: 44.8599044,
        },
        _id: "64f75285ef8f606073e9b50f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "antsaridava",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7893407,
          lng: 44.7943804,
        },
        _id: "64f75285ef8f606073e9b510",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "betsibaroke",
        __v: 0,
      },
      {
        coords: {
          lat: -24.813219,
          lng: 44.5368,
        },
        _id: "64f75285ef8f606073e9b511",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "etrobeke",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8171988,
          lng: 44.658357,
        },
        _id: "64f75285ef8f606073e9b512",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "etanjo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.877439,
          lng: 44.59045,
        },
        _id: "64f75285ef8f606073e9b513",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "sihanamitohy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.905623,
          lng: 44.709999,
        },
        _id: "64f75285ef8f606073e9b514",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "terahanaombitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.883333,
          lng: 44.65000000000001,
        },
        _id: "64f75285ef8f606073e9b515",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "sihananilambo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9017984,
          lng: 44.7943804,
        },
        _id: "64f75285ef8f606073e9b516",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "ambatolahinaory",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9527827,
          lng: 44.6348645,
        },
        _id: "64f75285ef8f606073e9b517",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "lavaposa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9664351,
          lng: 44.7475208,
        },
        _id: "64f75285ef8f606073e9b518",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "besakoa sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9948843,
          lng: 44.6724469,
        },
        _id: "64f75285ef8f606073e9b519",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "evazy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0479428,
          lng: 44.6066578,
        },
        _id: "64f75285ef8f606073e9b51a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "firanga",
        __v: 0,
      },
      {
        coords: {
          lat: -25.131492,
          lng: 44.5596089,
        },
        _id: "64f75285ef8f606073e9b51b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ampanihy ouest",
        fokontany: "andranovaho",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6699372,
          lng: 44.6630541,
        },
        _id: "64f75285ef8f606073e9b51c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankiliabo",
        fokontany: "ankiliabo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.588992,
          lng: 44.8037467,
        },
        _id: "64f75285ef8f606073e9b51d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankiliabo",
        fokontany: "betaimbala",
        __v: 0,
      },
      {
        coords: {
          lat: -24.613791,
          lng: 44.766651,
        },
        _id: "64f75285ef8f606073e9b51e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankiliabo",
        fokontany: "betioky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6048327,
          lng: 44.7240732,
        },
        _id: "64f75285ef8f606073e9b51f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankiliabo",
        fokontany: "befamata",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6167564,
          lng: 44.4276206,
        },
        _id: "64f75285ef8f606073e9b520",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankiliabo",
        fokontany: "tsijobony",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6452264,
          lng: 44.8037467,
        },
        _id: "64f75285ef8f606073e9b521",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankiliabo",
        fokontany: "vohitany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6499886,
          lng: 44.6912267,
        },
        _id: "64f75285ef8f606073e9b522",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankiliabo",
        fokontany: "behavandra III",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6528768,
          lng: 44.7709565,
        },
        _id: "64f75285ef8f606073e9b523",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankiliabo",
        fokontany: "anosa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6111709,
          lng: 44.5690225,
        },
        _id: "64f75285ef8f606073e9b524",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankiliabo",
        fokontany: "behavandra I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6827669,
          lng: 44.5030878,
        },
        _id: "64f75285ef8f606073e9b525",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankiliabo",
        fokontany: "etakake",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7333329,
          lng: 44.533333,
        },
        _id: "64f75285ef8f606073e9b526",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankiliabo",
        fokontany: "andamoty",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7035888,
          lng: 44.89730489999999,
        },
        _id: "64f75285ef8f606073e9b527",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "amborompotsy I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5397147,
          lng: 45.0653249,
        },
        _id: "64f75285ef8f606073e9b528",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "ambararata haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.516667,
          lng: 45.016667,
        },
        _id: "64f75285ef8f606073e9b529",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "ambahivahy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6004687,
          lng: 44.972015,
        },
        _id: "64f75285ef8f606073e9b52a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "ambatomanambahatse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5613498,
          lng: 44.9393443,
        },
        _id: "64f75285ef8f606073e9b52b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "motombe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5873554,
          lng: 44.9510151,
        },
        _id: "64f75285ef8f606073e9b52c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "bealango I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5868113,
          lng: 44.9276705,
        },
        _id: "64f75285ef8f606073e9b52d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "bealango II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6197946,
          lng: 45.0419839,
        },
        _id: "64f75285ef8f606073e9b52e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "belalitse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5747072,
          lng: 45.0093264,
        },
        _id: "64f75285ef8f606073e9b52f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "ankilimanondro nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6378848,
          lng: 45.027985,
        },
        _id: "64f75285ef8f606073e9b530",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "beronono",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6403583,
          lng: 44.9159938,
        },
        _id: "64f75285ef8f606073e9b531",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "ankilimanondro sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6378848,
          lng: 44.972015,
        },
        _id: "64f75285ef8f606073e9b532",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "ankisakisake",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6949512,
          lng: 44.9510151,
        },
        _id: "64f75285ef8f606073e9b533",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "amborompotsy II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.68693,
          lng: 45.0093264,
        },
        _id: "64f75285ef8f606073e9b534",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "ankazoabo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.73142,
          lng: 45.027985,
        },
        _id: "64f75285ef8f606073e9b535",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "anaramalinike",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7385045,
          lng: 44.95334889999999,
        },
        _id: "64f75285ef8f606073e9b536",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "bemoita nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7430375,
          lng: 44.9906736,
        },
        _id: "64f75285ef8f606073e9b537",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "tsotsama",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7684831,
          lng: 44.91132229999999,
        },
        _id: "64f75285ef8f606073e9b538",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "bemoita sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.783211,
          lng: 44.914021,
        },
        _id: "64f75285ef8f606073e9b539",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "antanimavo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8391557,
          lng: 44.9346751,
        },
        _id: "64f75285ef8f606073e9b53a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "analamisaka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8604662,
          lng: 44.8786084,
        },
        _id: "64f75285ef8f606073e9b53b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "besavoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8908031,
          lng: 44.89730489999999,
        },
        _id: "64f75285ef8f606073e9b53c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "belamoty",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9056753,
          lng: 44.8458713,
        },
        _id: "64f75285ef8f606073e9b53d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "amborompotsy",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5174626,
          lng: 44.7193823,
        },
        _id: "64f75285ef8f606073e9b53e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilizato",
        fokontany: "ankilizato",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4577694,
          lng: 44.8037467,
        },
        _id: "64f75285ef8f606073e9b53f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilizato",
        fokontany: "analamateza bas",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5,
          lng: 44.7,
        },
        _id: "64f75285ef8f606073e9b540",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilizato",
        fokontany: "bevoay",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5068387,
          lng: 44.6724469,
        },
        _id: "64f75285ef8f606073e9b541",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilizato",
        fokontany: "ampanorita",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5180996,
          lng: 44.7803275,
        },
        _id: "64f75285ef8f606073e9b542",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilizato",
        fokontany: "analamateza bey",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5584213,
          lng: 44.6348645,
        },
        _id: "64f75285ef8f606073e9b543",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilizato",
        fokontany: "bekako nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5538345,
          lng: 44.7475208,
        },
        _id: "64f75285ef8f606073e9b544",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilizato",
        fokontany: "anjidava",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5661003,
          lng: 44.6771425,
        },
        _id: "64f75285ef8f606073e9b545",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilizato",
        fokontany: "sakamasay",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5907456,
          lng: 44.6865325,
        },
        _id: "64f75285ef8f606073e9b546",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilizato",
        fokontany: "bekako sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.484742,
          lng: 44.9066503,
        },
        _id: "64f75285ef8f606073e9b547",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "maniry",
        fokontany: "maniry",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3689423,
          lng: 44.9906736,
        },
        _id: "64f75285ef8f606073e9b548",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "maniry",
        fokontany: "besaly",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4443664,
          lng: 44.9019778,
        },
        _id: "64f75285ef8f606073e9b549",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "maniry",
        fokontany: "pinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.456751,
          lng: 44.859131,
        },
        _id: "64f75285ef8f606073e9b54a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "maniry",
        fokontany: "ankilimihamy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4771337,
          lng: 44.8645811,
        },
        _id: "64f75285ef8f606073e9b54b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "maniry",
        fokontany: "betsako",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4718678,
          lng: 44.9159938,
        },
        _id: "64f75285ef8f606073e9b54c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "maniry",
        fokontany: "bokonake",
        __v: 0,
      },
      {
        coords: {
          lat: -24.516344,
          lng: 44.89730489999999,
        },
        _id: "64f75285ef8f606073e9b54d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "maniry",
        fokontany: "remengoke",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5365536,
          lng: 44.8622428,
        },
        _id: "64f75285ef8f606073e9b54e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "maniry",
        fokontany: "reambohitse haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5576139,
          lng: 44.8809459,
        },
        _id: "64f75285ef8f606073e9b54f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "maniry",
        fokontany: "tsiasimo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5567162,
          lng: 44.9019778,
        },
        _id: "64f75285ef8f606073e9b550",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "maniry",
        fokontany: "vohidrakitse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5532508,
          lng: 44.8365136,
        },
        _id: "64f75285ef8f606073e9b551",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "maniry",
        fokontany: "ambahotera",
        __v: 0,
      },
      {
        coords: {
          lat: -24.583333,
          lng: 44.883333,
        },
        _id: "64f75285ef8f606073e9b552",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "maniry",
        fokontany: "efoty",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5827858,
          lng: 44.8762708,
        },
        _id: "64f75285ef8f606073e9b553",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "maniry",
        fokontany: "begorago",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6153101,
          lng: 44.8458713,
        },
        _id: "64f75285ef8f606073e9b554",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "maniry",
        fokontany: "bekily",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3824578,
          lng: 44.3984035,
        },
        _id: "64f75285ef8f606073e9b555",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "antaly",
        fokontany: "antaly",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2893823,
          lng: 44.95334889999999,
        },
        _id: "64f75285ef8f606073e9b556",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "antaly",
        fokontany: "bedeboke",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3448697,
          lng: 44.8926315,
        },
        _id: "64f75285ef8f606073e9b557",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "antaly",
        fokontany: "belamoty",
        __v: 0,
      },
      {
        coords: {
          lat: -24.338297,
          lng: 44.8224735,
        },
        _id: "64f75285ef8f606073e9b558",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "antaly",
        fokontany: "beavake",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3375568,
          lng: 44.76158359999999,
        },
        _id: "64f75285ef8f606073e9b559",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "antaly",
        fokontany: "bevaro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3455295,
          lng: 44.95334889999999,
        },
        _id: "64f75285ef8f606073e9b55a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "antaly",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -24.383333,
          lng: 44.766667,
        },
        _id: "64f75285ef8f606073e9b55b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "antaly",
        fokontany: "beteny",
        __v: 0,
      },
      {
        coords: {
          lat: -24.38053,
          lng: 44.8599044,
        },
        _id: "64f75285ef8f606073e9b55c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "antaly",
        fokontany: "marovahatse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4085262,
          lng: 44.7850123,
        },
        _id: "64f75285ef8f606073e9b55d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "antaly",
        fokontany: "beara",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3763445,
          lng: 44.8084291,
        },
        _id: "64f75285ef8f606073e9b55e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "antaly",
        fokontany: "bevato II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4043179,
          lng: 44.7334536,
        },
        _id: "64f75285ef8f606073e9b55f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "antaly",
        fokontany: "beara boko",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4127048,
          lng: 44.8365136,
        },
        _id: "64f75285ef8f606073e9b560",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "antaly",
        fokontany: "bevato I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3899687,
          lng: 44.9346751,
        },
        _id: "64f75285ef8f606073e9b561",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "antaly",
        fokontany: "vohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.417428,
          lng: 44.8739331,
        },
        _id: "64f75285ef8f606073e9b562",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "antaly",
        fokontany: "pinjo II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4100993,
          lng: 44.8622428,
        },
        _id: "64f75285ef8f606073e9b563",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "antaly",
        fokontany: "tanamaeva",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4364481,
          lng: 44.8669193,
        },
        _id: "64f75285ef8f606073e9b564",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "antaly",
        fokontany: "pinjo I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4192189,
          lng: 45.0186548,
        },
        _id: "64f75285ef8f606073e9b565",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilimivory",
        fokontany: "ankilimivory",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2770916,
          lng: 45.0513189,
        },
        _id: "64f75285ef8f606073e9b566",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilimivory",
        fokontany: "anjamarotea",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3297434,
          lng: 45.0419839,
        },
        _id: "64f75285ef8f606073e9b567",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilimivory",
        fokontany: "betrandrake",
        __v: 0,
      },
      {
        coords: {
          lat: -24.383333,
          lng: 45.05,
        },
        _id: "64f75285ef8f606073e9b568",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilimivory",
        fokontany: "votovoatavo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.406356,
          lng: 44.9906736,
        },
        _id: "64f75285ef8f606073e9b569",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilimivory",
        fokontany: "marohola",
        __v: 0,
      },
      {
        coords: {
          lat: -24.426823,
          lng: 44.94868109999999,
        },
        _id: "64f75285ef8f606073e9b56a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilimivory",
        fokontany: "vohimiroro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4391045,
          lng: 45.0466511,
        },
        _id: "64f75285ef8f606073e9b56b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilimivory",
        fokontany: "sarotsetay ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.416667,
          lng: 45,
        },
        _id: "64f75285ef8f606073e9b56c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilimivory",
        fokontany: "andrakia",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4560416,
          lng: 45.004663,
        },
        _id: "64f75285ef8f606073e9b56d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilimivory",
        fokontany: "anjabotretreke",
        __v: 0,
      },
      {
        coords: {
          lat: -24.460741,
          lng: 45.0419839,
        },
        _id: "64f75285ef8f606073e9b56e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilimivory",
        fokontany: "ambatolahitsirara",
        __v: 0,
      },
      {
        coords: {
          lat: -24.466667,
          lng: 44.98333299999999,
        },
        _id: "64f75285ef8f606073e9b56f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilimivory",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -24.487616,
          lng: 45.0139904,
        },
        _id: "64f75285ef8f606073e9b570",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilimivory",
        fokontany: "amboaido",
        __v: 0,
      },
      {
        coords: {
          lat: -24.535592,
          lng: 44.9580161,
        },
        _id: "64f75285ef8f606073e9b571",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ankilimivory",
        fokontany: "masiadily",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3510493,
          lng: 44.5290018,
        },
        _id: "64f75285ef8f606073e9b572",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "ejeda centre",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0105255,
          lng: 44.5407761,
        },
        _id: "64f75285ef8f606073e9b573",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "andoharano",
        __v: 0,
      },
      {
        coords: {
          lat: -24.033333,
          lng: 44.516667,
        },
        _id: "64f75285ef8f606073e9b574",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "tsikoakahitse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0531437,
          lng: 44.5784342,
        },
        _id: "64f75285ef8f606073e9b575",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "tranomboro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.071462,
          lng: 44.59254809999999,
        },
        _id: "64f75285ef8f606073e9b576",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "ambatokapike",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0996218,
          lng: 44.5478393,
        },
        _id: "64f75285ef8f606073e9b577",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "behazondregnetse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1065705,
          lng: 44.5737286,
        },
        _id: "64f75285ef8f606073e9b578",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "tananondaty",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1560151,
          lng: 44.5030878,
        },
        _id: "64f75285ef8f606073e9b579",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "behatravy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1867192,
          lng: 44.5219358,
        },
        _id: "64f75285ef8f606073e9b57a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "anjatoka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.213187,
          lng: 44.5713756,
        },
        _id: "64f75285ef8f606073e9b57b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "ambatovaky tanantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2109712,
          lng: 44.545485,
        },
        _id: "64f75285ef8f606073e9b57c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "ambatovaky centre",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2213715,
          lng: 44.5172245,
        },
        _id: "64f75285ef8f606073e9b57d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "tsizarae",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2377126,
          lng: 44.4983747,
        },
        _id: "64f75285ef8f606073e9b57e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "kilimidega",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2550192,
          lng: 44.5407761,
        },
        _id: "64f75285ef8f606073e9b57f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "ankozohozo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2530586,
          lng: 44.50780049999999,
        },
        _id: "64f75285ef8f606073e9b580",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "marovahatse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.283333,
          lng: 44.433333,
        },
        _id: "64f75285ef8f606073e9b581",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "farafatse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.28245,
          lng: 44.504292,
        },
        _id: "64f75285ef8f606073e9b582",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2837377,
          lng: 44.5266466,
        },
        _id: "64f75285ef8f606073e9b583",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3194558,
          lng: 44.314193,
        },
        _id: "64f75285ef8f606073e9b584",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "esifake",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3119512,
          lng: 44.5266466,
        },
        _id: "64f75285ef8f606073e9b585",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "sakanay nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3143998,
          lng: 44.545485,
        },
        _id: "64f75285ef8f606073e9b586",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "enosy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3323006,
          lng: 44.4842324,
        },
        _id: "64f75285ef8f606073e9b587",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "bekinagna",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3420334,
          lng: 44.5184023,
        },
        _id: "64f75285ef8f606073e9b588",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "ejeda nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3298881,
          lng: 44.3756667,
        },
        _id: "64f75285ef8f606073e9b589",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "beviro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3508274,
          lng: 44.5136907,
        },
        _id: "64f75285ef8f606073e9b58a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "ejeda sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3426067,
          lng: 44.545485,
        },
        _id: "64f75285ef8f606073e9b58b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "antsevaseva",
        __v: 0,
      },
      {
        coords: {
          lat: -24.357928,
          lng: 44.5549014,
        },
        _id: "64f75285ef8f606073e9b58c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "antsevaseva befoe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3609736,
          lng: 44.5148687,
        },
        _id: "64f75285ef8f606073e9b58d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3753424,
          lng: 44.50780049999999,
        },
        _id: "64f75285ef8f606073e9b58e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "besakoa liambositse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3670664,
          lng: 44.3898416,
        },
        _id: "64f75285ef8f606073e9b58f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "mirafy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3940535,
          lng: 44.5525475,
        },
        _id: "64f75285ef8f606073e9b590",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "beholiva",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3931031,
          lng: 44.5360667,
        },
        _id: "64f75285ef8f606073e9b591",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "beara",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4070462,
          lng: 44.4983747,
        },
        _id: "64f75285ef8f606073e9b592",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "mitsoriake",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3824578,
          lng: 44.3984035,
        },
        _id: "64f75285ef8f606073e9b593",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "antaly",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4167542,
          lng: 44.5737286,
        },
        _id: "64f75285ef8f606073e9b594",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "lambomaty",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4446736,
          lng: 44.4983747,
        },
        _id: "64f75285ef8f606073e9b595",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "matave",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4376942,
          lng: 44.5172245,
        },
        _id: "64f75285ef8f606073e9b596",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "ambinda",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4366262,
          lng: 44.545485,
        },
        _id: "64f75285ef8f606073e9b597",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "bedeboke",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4521669,
          lng: 44.6301646,
        },
        _id: "64f75285ef8f606073e9b598",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "ampozoke",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4602519,
          lng: 44.5831393,
        },
        _id: "64f75285ef8f606073e9b599",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "manakaralahy nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.49729,
          lng: 44.5972518,
        },
        _id: "64f75285ef8f606073e9b59a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "manakaralahy sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4840191,
          lng: 44.5760814,
        },
        _id: "64f75285ef8f606073e9b59b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "mandopake II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.480681,
          lng: 44.5407761,
        },
        _id: "64f75285ef8f606073e9b59c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "etory",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4929972,
          lng: 44.38039209999999,
        },
        _id: "64f75285ef8f606073e9b59d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "sakoantovo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4828364,
          lng: 44.4842324,
        },
        _id: "64f75285ef8f606073e9b59e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "ankazota",
        __v: 0,
      },
      {
        coords: {
          lat: -24.562901,
          lng: 44.5219358,
        },
        _id: "64f75285ef8f606073e9b59f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "mandopake I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5278643,
          lng: 44.6160619,
        },
        _id: "64f75285ef8f606073e9b5a0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "manakaravavy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5414486,
          lng: 44.4276206,
        },
        _id: "64f75285ef8f606073e9b5a1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "bekily",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5748068,
          lng: 44.3756667,
        },
        _id: "64f75285ef8f606073e9b5a2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "ejeda",
        fokontany: "fentsifake",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3201398,
          lng: 44.6442629,
        },
        _id: "64f75285ef8f606073e9b5a3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "belafike haut",
        fokontany: "belafike haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2252992,
          lng: 44.5831393,
        },
        _id: "64f75285ef8f606073e9b5a4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "belafike haut",
        fokontany: "ambatovaky ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2133574,
          lng: 44.6536595,
        },
        _id: "64f75285ef8f606073e9b5a5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "belafike haut",
        fokontany: "beadala haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2375363,
          lng: 44.6771425,
        },
        _id: "64f75285ef8f606073e9b5a6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "belafike haut",
        fokontany: "beadala ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.30611,
          lng: 44.5958789,
        },
        _id: "64f75285ef8f606073e9b5a7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "belafike haut",
        fokontany: "ampamata",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2430751,
          lng: 44.6113601,
        },
        _id: "64f75285ef8f606073e9b5a8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "belafike haut",
        fokontany: "tratsiotsekandy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2717777,
          lng: 44.5972518,
        },
        _id: "64f75285ef8f606073e9b5a9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "belafike haut",
        fokontany: "belafike ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2716095,
          lng: 44.6865325,
        },
        _id: "64f75285ef8f606073e9b5aa",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "belafike haut",
        fokontany: "ankilimasy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3324848,
          lng: 44.6489614,
        },
        _id: "64f75285ef8f606073e9b5ab",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "belafike haut",
        fokontany: "andakato",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3093651,
          lng: 44.5972518,
        },
        _id: "64f75285ef8f606073e9b5ac",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "belafike haut",
        fokontany: "mikaikarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.334598,
          lng: 44.59254809999999,
        },
        _id: "64f75285ef8f606073e9b5ad",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "belafike haut",
        fokontany: "ankilitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3587728,
          lng: 44.6160619,
        },
        _id: "64f75285ef8f606073e9b5ae",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "belafike haut",
        fokontany: "namelomampia ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -24.366667,
          lng: 44.583333,
        },
        _id: "64f75285ef8f606073e9b5af",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "belafike haut",
        fokontany: "namelomampia ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4081544,
          lng: 44.6348645,
        },
        _id: "64f75285ef8f606073e9b5b0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "belafike haut",
        fokontany: "vinanitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1584173,
          lng: 44.43234080000001,
        },
        _id: "64f75285ef8f606073e9b5b1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "beahitse centre",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0343866,
          lng: 44.3851171,
        },
        _id: "64f75285ef8f606073e9b5b2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "ankaratihy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0429008,
          lng: 44.413457,
        },
        _id: "64f75285ef8f606073e9b5b3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0445123,
          lng: 44.4606524,
        },
        _id: "64f75285ef8f606073e9b5b4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "ranoabo haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.055509,
          lng: 44.50780049999999,
        },
        _id: "64f75285ef8f606073e9b5b5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "ankasy I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0702313,
          lng: 44.4417799,
        },
        _id: "64f75285ef8f606073e9b5b6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "ranoabo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.059589,
          lng: 44.424641,
        },
        _id: "64f75285ef8f606073e9b5b7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "sainta",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0485939,
          lng: 44.3331165,
        },
        _id: "64f75285ef8f606073e9b5b8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "agnena centre",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0890604,
          lng: 44.4417799,
        },
        _id: "64f75285ef8f606073e9b5b9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "ranoabo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1,
          lng: 44.466667,
        },
        _id: "64f75285ef8f606073e9b5ba",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "bevala",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0956388,
          lng: 44.5266466,
        },
        _id: "64f75285ef8f606073e9b5bb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "ankilibeharitse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.118862,
          lng: 44.4889469,
        },
        _id: "64f75285ef8f606073e9b5bc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "ankilitoka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1232653,
          lng: 44.4512171,
        },
        _id: "64f75285ef8f606073e9b5bd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "marovotse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1102138,
          lng: 44.3709408,
        },
        _id: "64f75285ef8f606073e9b5be",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "agnena sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1437757,
          lng: 44.3047285,
        },
        _id: "64f75285ef8f606073e9b5bf",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "marofototse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1851486,
          lng: 44.1910066,
        },
        _id: "64f75285ef8f606073e9b5c0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "behombe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.186663,
          lng: 44.43234080000001,
        },
        _id: "64f75285ef8f606073e9b5c1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "ankilindrehamelo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1938203,
          lng: 44.40637359999999,
        },
        _id: "64f75285ef8f606073e9b5c2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "marosaho",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1951102,
          lng: 44.4606524,
        },
        _id: "64f75285ef8f606073e9b5c3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "analasarotse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2084944,
          lng: 44.4818749,
        },
        _id: "64f75285ef8f606073e9b5c4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "belamoty nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.238704,
          lng: 44.4700858,
        },
        _id: "64f75285ef8f606073e9b5c5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "belamoty sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2430812,
          lng: 44.3425754,
        },
        _id: "64f75285ef8f606073e9b5c6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "besely",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2585164,
          lng: 44.4417799,
        },
        _id: "64f75285ef8f606073e9b5c7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beahitse",
        fokontany: "talimbola",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2670748,
          lng: 44.8060879,
        },
        _id: "64f75285ef8f606073e9b5c8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "gogogogo ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1744283,
          lng: 44.8599044,
        },
        _id: "64f75285ef8f606073e9b5c9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "lohaosy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2006579,
          lng: 44.82715400000001,
        },
        _id: "64f75285ef8f606073e9b5ca",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "betonake",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2092015,
          lng: 44.7662703,
        },
        _id: "64f75285ef8f606073e9b5cb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "ankilimamondro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1871229,
          lng: 44.6865325,
        },
        _id: "64f75285ef8f606073e9b5cc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "belambo bas",
        __v: 0,
      },
      {
        coords: {
          lat: -24.224214,
          lng: 44.8645811,
        },
        _id: "64f75285ef8f606073e9b5cd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "ankilindrehozoke",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2629743,
          lng: 44.91132229999999,
        },
        _id: "64f75285ef8f606073e9b5ce",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "satrapotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.265511,
          lng: 44.801128,
        },
        _id: "64f75285ef8f606073e9b5cf",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "gogogogo ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2475183,
          lng: 44.82715400000001,
        },
        _id: "64f75285ef8f606073e9b5d0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "ankilimasy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2389225,
          lng: 44.7240732,
        },
        _id: "64f75285ef8f606073e9b5d1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "beadala haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2413471,
          lng: 44.7428322,
        },
        _id: "64f75285ef8f606073e9b5d2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "taolambositra",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2969312,
          lng: 44.9206649,
        },
        _id: "64f75285ef8f606073e9b5d3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "satrapotsy milanja",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2769319,
          lng: 44.8739331,
        },
        _id: "64f75285ef8f606073e9b5d4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "ambalatany beavoha",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3109052,
          lng: 44.8832833,
        },
        _id: "64f75285ef8f606073e9b5d5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "amborinabo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3002594,
          lng: 44.8365136,
        },
        _id: "64f75285ef8f606073e9b5d6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "befasy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.265511,
          lng: 44.801128,
        },
        _id: "64f75285ef8f606073e9b5d7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "gogogogo I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2585073,
          lng: 44.7850123,
        },
        _id: "64f75285ef8f606073e9b5d8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "bekompitse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2676016,
          lng: 44.709999,
        },
        _id: "64f75285ef8f606073e9b5d9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "anamonta",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3169354,
          lng: 44.72876369999999,
        },
        _id: "64f75285ef8f606073e9b5da",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "tsimaipaosa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3308823,
          lng: 44.6912267,
        },
        _id: "64f75285ef8f606073e9b5db",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "ankilimivory",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4247441,
          lng: 44.6912267,
        },
        _id: "64f75285ef8f606073e9b5dc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "ranohandatse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4523442,
          lng: 44.7053066,
        },
        _id: "64f75285ef8f606073e9b5dd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "gogogogo",
        fokontany: "ambondrokily",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9547914,
          lng: 44.0627452,
        },
        _id: "64f75285ef8f606073e9b5de",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "androka vao",
        __v: 0,
      },
      {
        coords: {
          lat: -24.939113,
          lng: 44.2194625,
        },
        _id: "64f75285ef8f606073e9b5df",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "tsiarindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9025178,
          lng: 44.1910066,
        },
        _id: "64f75285ef8f606073e9b5e0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "mandevy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9049912,
          lng: 44.4370606,
        },
        _id: "64f75285ef8f606073e9b5e1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "ampasy nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9957067,
          lng: 44.2194625,
        },
        _id: "64f75285ef8f606073e9b5e2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "beharahake",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9998348,
          lng: 44.2715876,
        },
        _id: "64f75285ef8f606073e9b5e3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "evanga",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0041444,
          lng: 44.0889004,
        },
        _id: "64f75285ef8f606073e9b5e4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "belambo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9837006,
          lng: 44.0484728,
        },
        _id: "64f75285ef8f606073e9b5e5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "antsakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0452168,
          lng: 44.2384237,
        },
        _id: "64f75285ef8f606073e9b5e6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "tsiankeva haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9980504,
          lng: 44.314193,
        },
        _id: "64f75285ef8f606073e9b5e7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "kalangasa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0230764,
          lng: 44.1269188,
        },
        _id: "64f75285ef8f606073e9b5e8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "tsimafaitse",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0481277,
          lng: 44.0912774,
        },
        _id: "64f75285ef8f606073e9b5e9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "androka ela",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0296173,
          lng: 44.455935,
        },
        _id: "64f75285ef8f606073e9b5ea",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "kilimirimbo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.059845,
          lng: 44.3378462,
        },
        _id: "64f75285ef8f606073e9b5eb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "kilibory II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0357838,
          lng: 44.1625338,
        },
        _id: "64f75285ef8f606073e9b5ec",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "manera",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1040026,
          lng: 44.3331165,
        },
        _id: "64f75285ef8f606073e9b5ed",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "kilibory I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0670177,
          lng: 44.0912774,
        },
        _id: "64f75285ef8f606073e9b5ee",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "ambohibola",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1035945,
          lng: 44.4842324,
        },
        _id: "64f75285ef8f606073e9b5ef",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "ambolosarike",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1709658,
          lng: 44.38039209999999,
        },
        _id: "64f75285ef8f606073e9b5f0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "beavoha sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1671335,
          lng: 44.4653693,
        },
        _id: "64f75285ef8f606073e9b5f1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "andrapidrapike",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1976111,
          lng: 44.4842324,
        },
        _id: "64f75285ef8f606073e9b5f2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "lahitsitele",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2138196,
          lng: 44.4040122,
        },
        _id: "64f75285ef8f606073e9b5f3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "bevoalavo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2540153,
          lng: 44.4842324,
        },
        _id: "64f75285ef8f606073e9b5f4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "mahatsandry",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2521053,
          lng: 44.3898416,
        },
        _id: "64f75285ef8f606073e9b5f5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "androka",
        fokontany: "ankobay",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1441899,
          lng: 44.767879,
        },
        _id: "64f75285ef8f606073e9b5f6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "vohitany",
        fokontany: "vohitany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0659798,
          lng: 44.8365136,
        },
        _id: "64f75285ef8f606073e9b5f7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "vohitany",
        fokontany: "betaratse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0827938,
          lng: 44.8037467,
        },
        _id: "64f75285ef8f606073e9b5f8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "vohitany",
        fokontany: "bevahy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0966538,
          lng: 44.7662703,
        },
        _id: "64f75285ef8f606073e9b5f9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "vohitany",
        fokontany: "bebalahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1184469,
          lng: 44.6818377,
        },
        _id: "64f75285ef8f606073e9b5fa",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "vohitany",
        fokontany: "ambatofotsy I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.075455,
          lng: 44.658357,
        },
        _id: "64f75285ef8f606073e9b5fb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "vohitany",
        fokontany: "soamanonga ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1149934,
          lng: 44.6019551,
        },
        _id: "64f75285ef8f606073e9b5fc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "vohitany",
        fokontany: "analoalo andindo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1402167,
          lng: 44.5972518,
        },
        _id: "64f75285ef8f606073e9b5fd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "vohitany",
        fokontany: "ankilimanintsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2020232,
          lng: 44.6207633,
        },
        _id: "64f75285ef8f606073e9b5fe",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "vohitany",
        fokontany: "tamentsoa ambatovaky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1718543,
          lng: 44.6325146,
        },
        _id: "64f75285ef8f606073e9b5ff",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "vohitany",
        fokontany: "belolo haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1790079,
          lng: 44.6513105,
        },
        _id: "64f75285ef8f606073e9b600",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "vohitany",
        fokontany: "belolo antanimena",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1666541,
          lng: 44.6466122,
        },
        _id: "64f75285ef8f606073e9b601",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "vohitany",
        fokontany: "belolo bas",
        __v: 0,
      },
      {
        coords: {
          lat: -24.16474,
          lng: 44.7850123,
        },
        _id: "64f75285ef8f606073e9b602",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "vohitany",
        fokontany: "amborovoro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1316626,
          lng: 44.7920386,
        },
        _id: "64f75285ef8f606073e9b603",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "vohitany",
        fokontany: "ambatokapika",
        __v: 0,
      },
      {
        coords: {
          lat: -24.115864,
          lng: 44.7967222,
        },
        _id: "64f75285ef8f606073e9b604",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "vohitany",
        fokontany: "andranomilitse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1232466,
          lng: 44.8084291,
        },
        _id: "64f75285ef8f606073e9b605",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "vohitany",
        fokontany: "andranomilitse est",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1374785,
          lng: 44.8458713,
        },
        _id: "64f75285ef8f606073e9b606",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "vohitany",
        fokontany: "soamanonga est",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1441708,
          lng: 44.5737286,
        },
        _id: "64f75285ef8f606073e9b607",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "vohitany",
        fokontany: "evovo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1723706,
          lng: 44.5737286,
        },
        _id: "64f75285ef8f606073e9b608",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "vohitany",
        fokontany: "evovo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1856915,
          lng: 44.6395639,
        },
        _id: "64f75285ef8f606073e9b609",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "vohitany",
        fokontany: "ankilimanintsy ambijy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0156961,
          lng: 44.7568965,
        },
        _id: "64f75285ef8f606073e9b60a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beroy atsimo",
        fokontany: "beroy atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.981635,
          lng: 44.8365136,
        },
        _id: "64f75285ef8f606073e9b60b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beroy atsimo",
        fokontany: "ampandrambe centre",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0147061,
          lng: 44.7850123,
        },
        _id: "64f75285ef8f606073e9b60c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beroy atsimo",
        fokontany: "besaroy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9667946,
          lng: 44.7240732,
        },
        _id: "64f75285ef8f606073e9b60d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beroy atsimo",
        fokontany: "ankilibory I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9620509,
          lng: 44.6794902,
        },
        _id: "64f75285ef8f606073e9b60e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beroy atsimo",
        fokontany: "antaly vohipisaky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9771409,
          lng: 44.6959205,
        },
        _id: "64f75285ef8f606073e9b60f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beroy atsimo",
        fokontany: "ankilibory II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9874866,
          lng: 44.6677507,
        },
        _id: "64f75285ef8f606073e9b610",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beroy atsimo",
        fokontany: "beroy avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0200685,
          lng: 44.6301646,
        },
        _id: "64f75285ef8f606073e9b611",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beroy atsimo",
        fokontany: "belobaka nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0354046,
          lng: 44.6395639,
        },
        _id: "64f75285ef8f606073e9b612",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beroy atsimo",
        fokontany: "amboniarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0215946,
          lng: 44.6771425,
        },
        _id: "64f75285ef8f606073e9b613",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beroy atsimo",
        fokontany: "anadranto ankatrafay",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0053021,
          lng: 44.6959205,
        },
        _id: "64f75285ef8f606073e9b614",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beroy atsimo",
        fokontany: "ampanagnira satramaro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9979125,
          lng: 44.72876369999999,
        },
        _id: "64f75285ef8f606073e9b615",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beroy atsimo",
        fokontany: "andranogiso",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0233437,
          lng: 44.7170366,
        },
        _id: "64f75285ef8f606073e9b616",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beroy atsimo",
        fokontany: "mitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0492719,
          lng: 44.6912267,
        },
        _id: "64f75285ef8f606073e9b617",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beroy atsimo",
        fokontany: "tsikombo ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0512504,
          lng: 44.7240732,
        },
        _id: "64f75285ef8f606073e9b618",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beroy atsimo",
        fokontany: "tsikombo anivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0596377,
          lng: 44.7522089,
        },
        _id: "64f75285ef8f606073e9b619",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beroy atsimo",
        fokontany: "tsikombo haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.024526,
          lng: 44.8599044,
        },
        _id: "64f75285ef8f606073e9b61a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "beroy atsimo",
        fokontany: "ampandrambe anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -24.05265,
          lng: 45.019081,
        },
        _id: "64f75285ef8f606073e9b61b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "fotadrevo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9165143,
          lng: 45.04431750000001,
        },
        _id: "64f75285ef8f606073e9b61c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "asonjo II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9357594,
          lng: 45.1026951,
        },
        _id: "64f75285ef8f606073e9b61d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "antaratsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9308342,
          lng: 45.1400956,
        },
        _id: "64f75285ef8f606073e9b61e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "beamalo ankazobe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9672203,
          lng: 45.0233196,
        },
        _id: "64f75285ef8f606073e9b61f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "fotadrevo est",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9643561,
          lng: 44.8832833,
        },
        _id: "64f75285ef8f606073e9b620",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "ankatrafay",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9747236,
          lng: 44.85522719999999,
        },
        _id: "64f75285ef8f606073e9b621",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "ampandrambe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9790762,
          lng: 44.9510151,
        },
        _id: "64f75285ef8f606073e9b622",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "anavoha ambonirano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.957621,
          lng: 45.12109,
        },
        _id: "64f75285ef8f606073e9b623",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "beamalo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9896528,
          lng: 45.0396504,
        },
        _id: "64f75285ef8f606073e9b624",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "antohabato sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0022163,
          lng: 44.9580161,
        },
        _id: "64f75285ef8f606073e9b625",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "analafantsika",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9879822,
          lng: 44.9206649,
        },
        _id: "64f75285ef8f606073e9b626",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "anavoha centre",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0160696,
          lng: 44.9206649,
        },
        _id: "64f75285ef8f606073e9b627",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "anavoha sud",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9983625,
          lng: 45.1073685,
        },
        _id: "64f75285ef8f606073e9b628",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "soaserana",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0273452,
          lng: 45.0466511,
        },
        _id: "64f75285ef8f606073e9b629",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "analamasy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0149531,
          lng: 44.9930054,
        },
        _id: "64f75285ef8f606073e9b62a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "belinjo I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0011811,
          lng: 44.9860096,
        },
        _id: "64f75285ef8f606073e9b62b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "belinjo II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0397342,
          lng: 44.9136581,
        },
        _id: "64f75285ef8f606073e9b62c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0195329,
          lng: 45.0886777,
        },
        _id: "64f75285ef8f606073e9b62d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "ambararata I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0392887,
          lng: 44.8832833,
        },
        _id: "64f75285ef8f606073e9b62e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "mandabe ankilimaro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0599343,
          lng: 44.9159938,
        },
        _id: "64f75285ef8f606073e9b62f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "ambondromena",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0460628,
          lng: 44.95334889999999,
        },
        _id: "64f75285ef8f606073e9b630",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "beteza bas",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0631961,
          lng: 45.004663,
        },
        _id: "64f75285ef8f606073e9b631",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "fotadrevo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0677245,
          lng: 45.0419839,
        },
        _id: "64f75285ef8f606073e9b632",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "andranomiboa bebakaka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0668698,
          lng: 45.1026951,
        },
        _id: "64f75285ef8f606073e9b633",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "bevaro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0736111,
          lng: 44.96734920000001,
        },
        _id: "64f75285ef8f606073e9b634",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "ankopia",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0819043,
          lng: 44.99533700000001,
        },
        _id: "64f75285ef8f606073e9b635",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "beteza haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0989574,
          lng: 44.8739331,
        },
        _id: "64f75285ef8f606073e9b636",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "andranomilitse II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0979112,
          lng: 44.9019778,
        },
        _id: "64f75285ef8f606073e9b637",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "andranomilitse I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1145154,
          lng: 45.0419839,
        },
        _id: "64f75285ef8f606073e9b638",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "seta II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1021668,
          lng: 45.1284047,
        },
        _id: "64f75285ef8f606073e9b639",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "ankilimalangy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1040883,
          lng: 45.0139904,
        },
        _id: "64f75285ef8f606073e9b63a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "seta I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.109693,
          lng: 44.9206649,
        },
        _id: "64f75285ef8f606073e9b63b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -24.099997,
          lng: 44.8458713,
        },
        _id: "64f75285ef8f606073e9b63c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "tsikoakahitse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.132219,
          lng: 44.89082,
        },
        _id: "64f75285ef8f606073e9b63d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "besakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1286833,
          lng: 44.9136581,
        },
        _id: "64f75285ef8f606073e9b63e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "andamaky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1257367,
          lng: 44.9906736,
        },
        _id: "64f75285ef8f606073e9b63f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "satranarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.133333,
          lng: 45.083333,
        },
        _id: "64f75285ef8f606073e9b640",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1308266,
          lng: 45.0606557,
        },
        _id: "64f75285ef8f606073e9b641",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "edera",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1437914,
          lng: 44.88562049999999,
        },
        _id: "64f75285ef8f606073e9b642",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "sakoanabo bas",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1770836,
          lng: 44.95334889999999,
        },
        _id: "64f75285ef8f606073e9b643",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "analabe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1599823,
          lng: 44.91132229999999,
        },
        _id: "64f75285ef8f606073e9b644",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "beapemba",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1845899,
          lng: 44.9206649,
        },
        _id: "64f75285ef8f606073e9b645",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "andranotsiriry nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1847979,
          lng: 44.99533700000001,
        },
        _id: "64f75285ef8f606073e9b646",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "bekiseny",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2027687,
          lng: 45.0653249,
        },
        _id: "64f75285ef8f606073e9b647",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "ankilimamy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1832626,
          lng: 45.1260669,
        },
        _id: "64f75285ef8f606073e9b648",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "anivorano I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1730559,
          lng: 45.0233196,
        },
        _id: "64f75285ef8f606073e9b649",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "ambilany haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2119464,
          lng: 45.0209871,
        },
        _id: "64f75285ef8f606073e9b64a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "ankilibe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.226257,
          lng: 44.972015,
        },
        _id: "64f75285ef8f606073e9b64b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2011874,
          lng: 44.8949683,
        },
        _id: "64f75285ef8f606073e9b64c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "ankiletsidiro haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2157767,
          lng: 44.8809459,
        },
        _id: "64f75285ef8f606073e9b64d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "ankilitsidiro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2161602,
          lng: 44.91132229999999,
        },
        _id: "64f75285ef8f606073e9b64e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "andranotsiriry sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2257018,
          lng: 45.0139904,
        },
        _id: "64f75285ef8f606073e9b64f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "tanantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2255231,
          lng: 45.0886777,
        },
        _id: "64f75285ef8f606073e9b650",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "beteny",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2566967,
          lng: 45.0093264,
        },
        _id: "64f75285ef8f606073e9b651",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "ranomasy II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2559951,
          lng: 45.0699945,
        },
        _id: "64f75285ef8f606073e9b652",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "fotadrevo",
        fokontany: "anarabemoko",
        __v: 0,
      },
      {
        coords: {
          lat: -24.685459,
          lng: 43.95061099999999,
        },
        _id: "64f75285ef8f606073e9b653",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "itampolo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2632692,
          lng: 43.6854936,
        },
        _id: "64f75285ef8f606073e9b654",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "tariboly",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3069011,
          lng: 43.7094571,
        },
        _id: "64f75285ef8f606073e9b655",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "ankilibory nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3250589,
          lng: 43.73819779999999,
        },
        _id: "64f75285ef8f606073e9b656",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "tanindranto",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3323514,
          lng: 43.70466529999999,
        },
        _id: "64f75285ef8f606073e9b657",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "kaikarivo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3500834,
          lng: 43.7477743,
        },
        _id: "64f75285ef8f606073e9b658",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "ankilitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3703221,
          lng: 43.70466529999999,
        },
        _id: "64f75285ef8f606073e9b659",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "ankiririsa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3947162,
          lng: 43.73580339999999,
        },
        _id: "64f75285ef8f606073e9b65a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "beroy antsaro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4016102,
          lng: 43.7166438,
        },
        _id: "64f75285ef8f606073e9b65b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "lavasy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3906268,
          lng: 43.7669217,
        },
        _id: "64f75285ef8f606073e9b65c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "anja belitsake",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4255524,
          lng: 43.76213550000001,
        },
        _id: "64f75285ef8f606073e9b65d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "anja namakia",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3983743,
          lng: 43.8243187,
        },
        _id: "64f75285ef8f606073e9b65e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "nanohofa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4565846,
          lng: 43.7812773,
        },
        _id: "64f75285ef8f606073e9b65f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "besasavy nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4362922,
          lng: 43.8243187,
        },
        _id: "64f75285ef8f606073e9b660",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "vombe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4806787,
          lng: 43.8195382,
        },
        _id: "64f75285ef8f606073e9b661",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "bemanateza",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5206841,
          lng: 43.8529919,
        },
        _id: "64f75285ef8f606073e9b662",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "andranovao",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5042777,
          lng: 43.872098,
        },
        _id: "64f75285ef8f606073e9b663",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "andranomasintsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5429104,
          lng: 43.8977599,
        },
        _id: "64f75285ef8f606073e9b664",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "ankazoabo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4174459,
          lng: 44.0199152,
        },
        _id: "64f75285ef8f606073e9b665",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "ankitekiteke",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5756402,
          lng: 43.9102876,
        },
        _id: "64f75285ef8f606073e9b666",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "malangeriake",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5647935,
          lng: 44.13404389999999,
        },
        _id: "64f75285ef8f606073e9b667",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "voroja",
        __v: 0,
      },
      {
        coords: {
          lat: -24.685459,
          lng: 43.95061099999999,
        },
        _id: "64f75285ef8f606073e9b668",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "berovontsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6022517,
          lng: 44.314193,
        },
        _id: "64f75285ef8f606073e9b669",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "ampitanake",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7350851,
          lng: 43.9532149,
        },
        _id: "64f75285ef8f606073e9b66a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "zoenarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7569947,
          lng: 43.9579823,
        },
        _id: "64f75285ef8f606073e9b66b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "tongaenoro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7881033,
          lng: 43.9698986,
        },
        _id: "64f75285ef8f606073e9b66c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "besasavy sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7973034,
          lng: 43.97704700000001,
        },
        _id: "64f75285ef8f606073e9b66d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "ambalabe beavoha",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8124569,
          lng: 43.9937225,
        },
        _id: "64f75285ef8f606073e9b66e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "tsiandriona nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7159074,
          lng: 44.13404389999999,
        },
        _id: "64f75285ef8f606073e9b66f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "ambolisono",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8290893,
          lng: 44.0127731,
        },
        _id: "64f75285ef8f606073e9b670",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "nisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.828132,
          lng: 43.9961042,
        },
        _id: "64f75285ef8f606073e9b671",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "sakariake",
        __v: 0,
      },
      {
        coords: {
          lat: -24.841519,
          lng: 44.0175346,
        },
        _id: "64f75285ef8f606073e9b672",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "besely nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7892783,
          lng: 44.1910066,
        },
        _id: "64f75285ef8f606073e9b673",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "befengoke",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8554333,
          lng: 44.0246759,
        },
        _id: "64f75285ef8f606073e9b674",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "befolotse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8802832,
          lng: 44.0341961,
        },
        _id: "64f75285ef8f606073e9b675",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "andoharano",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8717728,
          lng: 44.0508518,
        },
        _id: "64f75285ef8f606073e9b676",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "androipano",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9080939,
          lng: 44.0484728,
        },
        _id: "64f75285ef8f606073e9b677",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "kaikarivo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9194051,
          lng: 44.0817686,
        },
        _id: "64f75285ef8f606073e9b678",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "ankilibory sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9312575,
          lng: 44.10078439999999,
        },
        _id: "64f75285ef8f606073e9b679",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "ambalatsimiviky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9528268,
          lng: 44.1126654,
        },
        _id: "64f75285ef8f606073e9b67a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "ankamena I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9457061,
          lng: 44.0936543,
        },
        _id: "64f75285ef8f606073e9b67b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "ankamena II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9587454,
          lng: 44.1221682,
        },
        _id: "64f75285ef8f606073e9b67c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "betratratra",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9646619,
          lng: 44.131669,
        },
        _id: "64f75285ef8f606073e9b67d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "ambaladoda",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9725635,
          lng: 44.0912774,
        },
        _id: "64f75285ef8f606073e9b67e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "tsiandriona sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9717144,
          lng: 44.1126654,
        },
        _id: "64f75285ef8f606073e9b67f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "ankiliambany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9938445,
          lng: 44.11028950000001,
        },
        _id: "64f75285ef8f606073e9b680",
        province: "toliary",
        region: "atsimo andrefana",
        district: "ampanihy ouest",
        commune: "itampolo",
        fokontany: "besely sud",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9221922,
          lng: 44.5737286,
        },
        _id: "64f75285ef8f606073e9b681",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "sakaraha bas",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7403492,
          lng: 44.5831393,
        },
        _id: "64f75285ef8f606073e9b682",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "soaserana manamboay",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7693261,
          lng: 44.5407761,
        },
        _id: "64f75285ef8f606073e9b683",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "soaserana akomaky",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8174357,
          lng: 44.6442629,
        },
        _id: "64f75285ef8f606073e9b684",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "beba manamboay",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8511588,
          lng: 44.5219358,
        },
        _id: "64f75285ef8f606073e9b685",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "boka vohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.880995,
          lng: 44.5972518,
        },
        _id: "64f75285ef8f606073e9b686",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "boka ankilivalo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9115169,
          lng: 44.520758,
        },
        _id: "64f75285ef8f606073e9b687",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "sakaraha haut",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9040699,
          lng: 44.6912267,
        },
        _id: "64f75285ef8f606073e9b688",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "andranomaintso",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9013536,
          lng: 44.5195802,
        },
        _id: "64f75285ef8f606073e9b689",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "travaux tandroy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.930705,
          lng: 44.6160619,
        },
        _id: "64f75285ef8f606073e9b68a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "andalamengoky",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9067217,
          lng: 44.5643159,
        },
        _id: "64f75285ef8f606073e9b68b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "antsokay",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8916704,
          lng: 44.5337119,
        },
        _id: "64f75285ef8f606073e9b68c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "andranovory tandroy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.90869,
          lng: 44.4653693,
        },
        _id: "64f75285ef8f606073e9b68d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "kiliarivo sakaraha",
        __v: 0,
      },
      {
        coords: {
          lat: -22.983387,
          lng: 44.493661,
        },
        _id: "64f75285ef8f606073e9b68e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "teninde",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9153876,
          lng: 44.5231135,
        },
        _id: "64f75285ef8f606073e9b68f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "besavoa soaserana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9154831,
          lng: 44.5378328,
        },
        _id: "64f75285ef8f606073e9b690",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "andasy tanala",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9385346,
          lng: 44.5407761,
        },
        _id: "64f75285ef8f606073e9b691",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "ampandra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9517992,
          lng: 44.7943804,
        },
        _id: "64f75285ef8f606073e9b692",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "tanambao mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9603772,
          lng: 44.6912267,
        },
        _id: "64f75285ef8f606073e9b693",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "mahasoabe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9915545,
          lng: 44.6959205,
        },
        _id: "64f75285ef8f606073e9b694",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "andakazera mikaiky",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9943418,
          lng: 44.5690225,
        },
        _id: "64f75285ef8f606073e9b695",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "analamary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0446744,
          lng: 44.5596089,
        },
        _id: "64f75285ef8f606073e9b696",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "antanimbaribe mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8923673,
          lng: 44.4983747,
        },
        _id: "64f75285ef8f606073e9b697",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "sakaraha",
        fokontany: "andriabe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1574766,
          lng: 44.5596089,
        },
        _id: "64f75285ef8f606073e9b698",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "miary taheza",
        fokontany: "ranovao",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0267881,
          lng: 44.7943804,
        },
        _id: "64f75285ef8f606073e9b699",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "miary taheza",
        fokontany: "tanandava ifantso",
        __v: 0,
      },
      {
        coords: {
          lat: -23.083333,
          lng: 44.616667,
        },
        _id: "64f75285ef8f606073e9b69a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "miary taheza",
        fokontany: "ampasikibo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0924336,
          lng: 44.6630541,
        },
        _id: "64f75285ef8f606073e9b69b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "miary taheza",
        fokontany: "miary taheza",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1293137,
          lng: 44.6912267,
        },
        _id: "64f75285ef8f606073e9b69c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "miary taheza",
        fokontany: "soaserana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2004465,
          lng: 44.5972518,
        },
        _id: "64f75285ef8f606073e9b69d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "miary taheza",
        fokontany: "bemelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8085017,
          lng: 44.455935,
        },
        _id: "64f75285ef8f606073e9b69e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "miary lamatihy",
        fokontany: "miary lamatihy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7845743,
          lng: 44.3898416,
        },
        _id: "64f75285ef8f606073e9b69f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "miary lamatihy",
        fokontany: "bevoalavo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7703586,
          lng: 44.4842324,
        },
        _id: "64f75285ef8f606073e9b6a0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "miary lamatihy",
        fokontany: "nosiarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8105397,
          lng: 44.4299807,
        },
        _id: "64f75285ef8f606073e9b6a1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "miary lamatihy",
        fokontany: "belamoty",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8143413,
          lng: 44.4795173,
        },
        _id: "64f75285ef8f606073e9b6a2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "miary lamatihy",
        fokontany: "ankilibe lamatihy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8156272,
          lng: 44.4087348,
        },
        _id: "64f75285ef8f606073e9b6a3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "miary lamatihy",
        fokontany: "tanambao betsileo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8397791,
          lng: 44.4606524,
        },
        _id: "64f75285ef8f606073e9b6a4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "miary lamatihy",
        fokontany: "ambahimalitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.898439,
          lng: 44.33717,
        },
        _id: "64f75285ef8f606073e9b6a5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mahaboboka",
        fokontany: "mahaboboka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6745912,
          lng: 44.1910066,
        },
        _id: "64f75285ef8f606073e9b6a6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mahaboboka",
        fokontany: "marotsiraka betsileo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8291037,
          lng: 44.3567602,
        },
        _id: "64f75285ef8f606073e9b6a7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mahaboboka",
        fokontany: "ampatika fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8540562,
          lng: 44.3662144,
        },
        _id: "64f75285ef8f606073e9b6a8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mahaboboka",
        fokontany: "betsinefo I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7878437,
          lng: 44.1910066,
        },
        _id: "64f75285ef8f606073e9b6a9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mahaboboka",
        fokontany: "ankilitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8572143,
          lng: 44.1815176,
        },
        _id: "64f75285ef8f606073e9b6aa",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mahaboboka",
        fokontany: "anapaly",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8687713,
          lng: 44.2431628,
        },
        _id: "64f75285ef8f606073e9b6ab",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mahaboboka",
        fokontany: "beroroha I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8873926,
          lng: 44.2573774,
        },
        _id: "64f75285ef8f606073e9b6ac",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mahaboboka",
        fokontany: "soaserana manera",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9184957,
          lng: 44.2763235,
        },
        _id: "64f75285ef8f606073e9b6ad",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mahaboboka",
        fokontany: "soatanimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8988768,
          lng: 44.3189246,
        },
        _id: "64f75285ef8f606073e9b6ae",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mahaboboka",
        fokontany: "manera bara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5966884,
          lng: 44.3614875,
        },
        _id: "64f75285ef8f606073e9b6af",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "amboronabo",
        fokontany: "amboronabo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7219563,
          lng: 44.38039209999999,
        },
        _id: "64f75285ef8f606073e9b6b0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "amboronabo",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6404931,
          lng: 44.3709408,
        },
        _id: "64f75285ef8f606073e9b6b1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "amboronabo",
        fokontany: "ampihamy nord",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5905495,
          lng: 44.3520324,
        },
        _id: "64f75285ef8f606073e9b6b2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "amboronabo",
        fokontany: "besavoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.578469,
          lng: 44.3189246,
        },
        _id: "64f75285ef8f606073e9b6b3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "amboronabo",
        fokontany: "sarodrano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.535205,
          lng: 44.2668514,
        },
        _id: "64f75285ef8f606073e9b6b4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "amboronabo",
        fokontany: "mitia est",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6483416,
          lng: 44.2668514,
        },
        _id: "64f75285ef8f606073e9b6b5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "amboronabo",
        fokontany: "ambararata besavoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5581857,
          lng: 44.4181786,
        },
        _id: "64f75285ef8f606073e9b6b6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "amboronabo",
        fokontany: "tsinjoravy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6447972,
          lng: 44.493661,
        },
        _id: "64f75285ef8f606073e9b6b7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "amboronabo",
        fokontany: "antevamena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6885203,
          lng: 44.5030878,
        },
        _id: "64f75285ef8f606073e9b6b8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "amboronabo",
        fokontany: "ambivy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7333329,
          lng: 44.45,
        },
        _id: "64f75285ef8f606073e9b6b9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "amboronabo",
        fokontany: "andoba",
        __v: 0,
      },
      {
        coords: {
          lat: -23.116037,
          lng: 44.4018676,
        },
        _id: "64f75285ef8f606073e9b6ba",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "bereketa",
        fokontany: "bereketa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.933333,
          lng: 44.4166669,
        },
        _id: "64f75285ef8f606073e9b6bb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "bereketa",
        fokontany: "amparambato",
        __v: 0,
      },
      {
        coords: {
          lat: -23.034643,
          lng: 44.4417799,
        },
        _id: "64f75285ef8f606073e9b6bc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "bereketa",
        fokontany: "mihaiky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0154417,
          lng: 44.38275470000001,
        },
        _id: "64f75285ef8f606073e9b6bd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "bereketa",
        fokontany: "anapaly",
        __v: 0,
      },
      {
        coords: {
          lat: -23.011049,
          lng: 44.383381,
        },
        _id: "64f75285ef8f606073e9b6be",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "bereketa",
        fokontany: "sarodrano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0859386,
          lng: 44.3898416,
        },
        _id: "64f75285ef8f606073e9b6bf",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "bereketa",
        fokontany: "adabofolo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0750305,
          lng: 44.4606524,
        },
        _id: "64f75285ef8f606073e9b6c0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "bereketa",
        fokontany: "tsangora",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0835227,
          lng: 44.5030878,
        },
        _id: "64f75285ef8f606073e9b6c1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "bereketa",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.138803,
          lng: 44.413457,
        },
        _id: "64f75285ef8f606073e9b6c2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "bereketa",
        fokontany: "mandarano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1302947,
          lng: 44.3709408,
        },
        _id: "64f75285ef8f606073e9b6c3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "bereketa",
        fokontany: "soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1746683,
          lng: 44.3520324,
        },
        _id: "64f75285ef8f606073e9b6c4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "bereketa",
        fokontany: "ankilimitraboky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2190592,
          lng: 44.3331165,
        },
        _id: "64f75285ef8f606073e9b6c5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "bereketa",
        fokontany: "besavoa avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2111031,
          lng: 44.4087348,
        },
        _id: "64f75285ef8f606073e9b6c6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "bereketa",
        fokontany: "soamanonga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1788717,
          lng: 44.4464987,
        },
        _id: "64f75285ef8f606073e9b6c7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "bereketa",
        fokontany: "beampombo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1587747,
          lng: 44.5030878,
        },
        _id: "64f75285ef8f606073e9b6c8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "bereketa",
        fokontany: "miary ranotsara",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0255532,
          lng: 44.2668514,
        },
        _id: "64f75285ef8f606073e9b6c9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "andamasiny vineta",
        fokontany: "andamasiny vineta",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9745474,
          lng: 44.3047285,
        },
        _id: "64f75285ef8f606073e9b6ca",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "andamasiny vineta",
        fokontany: "ambararata goudron",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0560658,
          lng: 44.314193,
        },
        _id: "64f75285ef8f606073e9b6cb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "andamasiny vineta",
        fokontany: "andravindahy II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.020502,
          lng: 44.2004938,
        },
        _id: "64f75285ef8f606073e9b6cc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "andamasiny vineta",
        fokontany: "bevato sakoamaro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0879631,
          lng: 44.2905281,
        },
        _id: "64f75285ef8f606073e9b6cd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "andamasiny vineta",
        fokontany: "ambahatsy II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0485526,
          lng: 44.2147211,
        },
        _id: "64f75285ef8f606073e9b6ce",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "andamasiny vineta",
        fokontany: "behena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.070754,
          lng: 44.2052367,
        },
        _id: "64f75285ef8f606073e9b6cf",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "andamasiny vineta",
        fokontany: "tsianaloka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1151699,
          lng: 44.1862623,
        },
        _id: "64f75285ef8f606073e9b6d0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "andamasiny vineta",
        fokontany: "vatovanda zafimagnana-draza",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9003147,
          lng: 44.1332425,
        },
        _id: "64f75285ef8f606073e9b6d1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mihavatsy",
        fokontany: "mihavatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.93749,
          lng: 44.201019,
        },
        _id: "64f75285ef8f606073e9b6d2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mihavatsy",
        fokontany: "andakato",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9204693,
          lng: 44.1625338,
        },
        _id: "64f75285ef8f606073e9b6d3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mihavatsy",
        fokontany: "besavoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8959122,
          lng: 44.1245436,
        },
        _id: "64f75285ef8f606073e9b6d4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mihavatsy",
        fokontany: "mitsinjobe boka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.983752,
          lng: 44.1435424,
        },
        _id: "64f75285ef8f606073e9b6d5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mihavatsy",
        fokontany: "belalitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.651711,
          lng: 44.682652,
        },
        _id: "64f75285ef8f606073e9b6d6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "andranolava",
        fokontany: "andranolava betsileo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5282812,
          lng: 44.7193823,
        },
        _id: "64f75285ef8f606073e9b6d7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "andranolava",
        fokontany: "ankilibory II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5297109,
          lng: 44.6348645,
        },
        _id: "64f75285ef8f606073e9b6d8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "andranolava",
        fokontany: "vanaoky",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5564391,
          lng: 44.53135690000001,
        },
        _id: "64f75285ef8f606073e9b6d9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "andranolava",
        fokontany: "ambia betsileo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6809344,
          lng: 44.5784342,
        },
        _id: "64f75285ef8f606073e9b6da",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "andranolava",
        fokontany: "agnalatelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6184651,
          lng: 44.5690225,
        },
        _id: "64f75285ef8f606073e9b6db",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "andranolava",
        fokontany: "iaborano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5972225,
          lng: 44.709999,
        },
        _id: "64f75285ef8f606073e9b6dc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "andranolava",
        fokontany: "andranovory tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6636667,
          lng: 44.6677507,
        },
        _id: "64f75285ef8f606073e9b6dd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "andranolava",
        fokontany: "ankabokara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6468967,
          lng: 44.72876369999999,
        },
        _id: "64f75285ef8f606073e9b6de",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "andranolava",
        fokontany: "ranotsara",
        __v: 0,
      },
      {
        coords: {
          lat: -22.678869,
          lng: 44.6912267,
        },
        _id: "64f75285ef8f606073e9b6df",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "andranolava",
        fokontany: "berobontsy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7047763,
          lng: 44.6442629,
        },
        _id: "64f75285ef8f606073e9b6e0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "andranolava",
        fokontany: "lambonakandro I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7550264,
          lng: 44.6348645,
        },
        _id: "64f75285ef8f606073e9b6e1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "andranolava",
        fokontany: "ampandrabe II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7245253,
          lng: 44.9066503,
        },
        _id: "64f75285ef8f606073e9b6e2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "ambinany",
        fokontany: "morahariva II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.683333,
          lng: 44.73333299999999,
        },
        _id: "64f75285ef8f606073e9b6e3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "ambinany",
        fokontany: "ambinany",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5362824,
          lng: 44.9626829,
        },
        _id: "64f75285ef8f606073e9b6e4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "ambinany",
        fokontany: "beroroha I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.639218,
          lng: 44.8037467,
        },
        _id: "64f75285ef8f606073e9b6e5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "ambinany",
        fokontany: "maromiandra betsileo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.835557,
          lng: 44.9626829,
        },
        _id: "64f75285ef8f606073e9b6e6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "ambinany",
        fokontany: "maninday I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7201267,
          lng: 44.08652319999999,
        },
        _id: "64f75285ef8f606073e9b6e7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mikoboka",
        fokontany: "soatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6568844,
          lng: 44.1055372,
        },
        _id: "64f75285ef8f606073e9b6e8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mikoboka",
        fokontany: "milenaky",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5874948,
          lng: 44.1150413,
        },
        _id: "64f75285ef8f606073e9b6e9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mikoboka",
        fokontany: "ankomaka sud",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5002026,
          lng: 44.03895540000001,
        },
        _id: "64f75285ef8f606073e9b6ea",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mikoboka",
        fokontany: "andafiha",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5268207,
          lng: 43.9055155,
        },
        _id: "64f75285ef8f606073e9b6eb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mikoboka",
        fokontany: "andaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.829805,
          lng: 43.9055155,
        },
        _id: "64f75285ef8f606073e9b6ec",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mitsinjo",
        fokontany: "mitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6397676,
          lng: 43.9627492,
        },
        _id: "64f75285ef8f606073e9b6ed",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mitsinjo",
        fokontany: "mahiamaly",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7285636,
          lng: 43.92460090000001,
        },
        _id: "64f75285ef8f606073e9b6ee",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mitsinjo",
        fokontany: "soatanimbary II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8212046,
          lng: 44.0675018,
        },
        _id: "64f75285ef8f606073e9b6ef",
        province: "toliary",
        region: "atsimo andrefana",
        district: "sakaraha",
        commune: "mitsinjo",
        fokontany: "ankilibemanoy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3262137,
          lng: 43.6902872,
        },
        _id: "64f75285ef8f606073e9b6f0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "mitsinjo betanimena",
        fokontany: "mitsinjo betanimena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3142828,
          lng: 43.6938821,
        },
        _id: "64f75285ef8f606073e9b6f1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "mitsinjo betanimena",
        fokontany: "andalavy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3210943,
          lng: 43.7118528,
        },
        _id: "64f75285ef8f606073e9b6f2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "mitsinjo betanimena",
        fokontany: "befanamy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3230073,
          lng: 43.6926839,
        },
        _id: "64f75285ef8f606073e9b6f3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "mitsinjo betanimena",
        fokontany: "belemboka androvakely",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3215888,
          lng: 43.6830966,
        },
        _id: "64f75285ef8f606073e9b6f4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "mitsinjo betanimena",
        fokontany: "analatsimavo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3201691,
          lng: 43.6735074,
        },
        _id: "64f75285ef8f606073e9b6f5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "mitsinjo betanimena",
        fokontany: "tanandava mikambana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3339191,
          lng: 43.7022693,
        },
        _id: "64f75285ef8f606073e9b6f6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "mitsinjo betanimena",
        fokontany: "ampasinabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3385929,
          lng: 43.6836958,
        },
        _id: "64f75285ef8f606073e9b6f7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "mitsinjo betanimena",
        fokontany: "andamasiny tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3427686,
          lng: 43.6938821,
        },
        _id: "64f75285ef8f606073e9b6f8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "mitsinjo betanimena",
        fokontany: "besasavy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3516191,
          lng: 43.6854936,
        },
        _id: "64f75285ef8f606073e9b6f9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "belalanda",
        fokontany: "belalanda",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0799522,
          lng: 43.6471276,
        },
        _id: "64f75285ef8f606073e9b6fa",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "belalanda",
        fokontany: "amboaboaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1245591,
          lng: 43.6279334,
        },
        _id: "64f75285ef8f606073e9b6fb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "belalanda",
        fokontany: "mangily",
        __v: 0,
      },
      {
        coords: {
          lat: -23.149329,
          lng: 43.6663143,
        },
        _id: "64f75285ef8f606073e9b6fc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "belalanda",
        fokontany: "tsivonoe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.14532,
          lng: 43.6159332,
        },
        _id: "64f75285ef8f606073e9b6fd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "belalanda",
        fokontany: "ifaty",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1780408,
          lng: 43.651925,
        },
        _id: "64f75285ef8f606073e9b6fe",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "belalanda",
        fokontany: "ambalaboy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1999085,
          lng: 43.6711099,
        },
        _id: "64f75285ef8f606073e9b6ff",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "belalanda",
        fokontany: "beravy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2222202,
          lng: 43.6615184,
        },
        _id: "64f75285ef8f606073e9b700",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "belalanda",
        fokontany: "tsongeritelo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2507154,
          lng: 43.6615184,
        },
        _id: "64f75285ef8f606073e9b701",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "belalanda",
        fokontany: "ambotsibotsike",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2730347,
          lng: 43.651925,
        },
        _id: "64f75285ef8f606073e9b702",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "belalanda",
        fokontany: "belitsake tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3028375,
          lng: 43.66871219999999,
        },
        _id: "64f75285ef8f606073e9b703",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "belalanda",
        fokontany: "tsinjoriaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2978479,
          lng: 43.6830966,
        },
        _id: "64f75285ef8f606073e9b704",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "belalanda",
        fokontany: "bekoake",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3447011,
          lng: 43.7190392,
        },
        _id: "64f75285ef8f606073e9b705",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "betsinjaka",
        fokontany: "betsinjaka ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3466831,
          lng: 43.6962786,
        },
        _id: "64f75285ef8f606073e9b706",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "betsinjaka",
        fokontany: "ambolofoty",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3465573,
          lng: 43.7034673,
        },
        _id: "64f75285ef8f606073e9b707",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "betsinjaka",
        fokontany: "samotelahy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3529711,
          lng: 43.698675,
        },
        _id: "64f75285ef8f606073e9b708",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "betsinjaka",
        fokontany: "betsingilo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3558562,
          lng: 43.6920847,
        },
        _id: "64f75285ef8f606073e9b709",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "betsinjaka",
        fokontany: "andakoro I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.359417,
          lng: 43.6920847,
        },
        _id: "64f75285ef8f606073e9b70a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "betsinjaka",
        fokontany: "andakoro II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3560516,
          lng: 43.7034673,
        },
        _id: "64f75285ef8f606073e9b70b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "betsinjaka",
        fokontany: "antsihanaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3616324,
          lng: 43.7010712,
        },
        _id: "64f75285ef8f606073e9b70c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "betsinjaka",
        fokontany: "toby betela",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3686254,
          lng: 43.7082592,
        },
        _id: "64f75285ef8f606073e9b70d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "betsinjaka",
        fokontany: "antanimikodoy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3439318,
          lng: 43.76213550000001,
        },
        _id: "64f75285ef8f606073e9b70e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "betsinjaka",
        fokontany: "ankaiasy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3659947,
          lng: 43.7004721,
        },
        _id: "64f75285ef8f606073e9b70f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "betsinjaka",
        fokontany: "motombe est",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3766441,
          lng: 43.7022693,
        },
        _id: "64f75285ef8f606073e9b710",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "betsinjaka",
        fokontany: "motombe west",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3763864,
          lng: 43.7166438,
        },
        _id: "64f75285ef8f606073e9b711",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "betsinjaka",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3941768,
          lng: 43.7812773,
        },
        _id: "64f75285ef8f606073e9b712",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "betsinjaka",
        fokontany: "ankoronga andatabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2967428,
          lng: 43.7477743,
        },
        _id: "64f75285ef8f606073e9b713",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "miary ambohibola",
        fokontany: "miary ambohibola",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2934133,
          lng: 43.7573489,
        },
        _id: "64f75285ef8f606073e9b714",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "miary ambohibola",
        fokontany: "ankotsaobihia",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2944478,
          lng: 43.7884535,
        },
        _id: "64f75285ef8f606073e9b715",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "miary ambohibola",
        fokontany: "antaikoake",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3002139,
          lng: 43.7752963,
        },
        _id: "64f75285ef8f606073e9b716",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "miary ambohibola",
        fokontany: "mandrosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.304441,
          lng: 43.7597423,
        },
        _id: "64f75285ef8f606073e9b717",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "miary ambohibola",
        fokontany: "anolaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.301861,
          lng: 43.72622459999999,
        },
        _id: "64f75285ef8f606073e9b718",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "miary ambohibola",
        fokontany: "miary bereketa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3016747,
          lng: 43.73700059999999,
        },
        _id: "64f75285ef8f606073e9b719",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "miary ambohibola",
        fokontany: "miary betsileo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3082095,
          lng: 43.7250271,
        },
        _id: "64f75285ef8f606073e9b71a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "miary ambohibola",
        fokontany: "miary ville",
        __v: 0,
      },
      {
        coords: {
          lat: -23.316161,
          lng: 43.722632,
        },
        _id: "64f75285ef8f606073e9b71b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "miary ambohibola",
        fokontany: "miary ankasy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3021078,
          lng: 43.7118528,
        },
        _id: "64f75285ef8f606073e9b71c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "miary ambohibola",
        fokontany: "miary ankoronga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3040166,
          lng: 43.6926839,
        },
        _id: "64f75285ef8f606073e9b71d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "miary ambohibola",
        fokontany: "miary belemboka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.266259,
          lng: 43.8625459,
        },
        _id: "64f75285ef8f606073e9b71e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "behompy",
        fokontany: "behompy mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0050649,
          lng: 44.0008674,
        },
        _id: "64f75285ef8f606073e9b71f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "behompy",
        fokontany: "maroata",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1318824,
          lng: 43.9627492,
        },
        _id: "64f75285ef8f606073e9b720",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "behompy",
        fokontany: "anjamala",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1712479,
          lng: 43.8768733,
        },
        _id: "64f75285ef8f606073e9b721",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "behompy",
        fokontany: "beantsy soakija",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1845345,
          lng: 43.8386574,
        },
        _id: "64f75285ef8f606073e9b722",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "behompy",
        fokontany: "ampihalia",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1978198,
          lng: 43.8004115,
        },
        _id: "64f75285ef8f606073e9b723",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "behompy",
        fokontany: "ampasy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.248315,
          lng: 43.8051939,
        },
        _id: "64f75285ef8f606073e9b724",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "behompy",
        fokontany: "marohala",
        __v: 0,
      },
      {
        coords: {
          lat: -23.280225,
          lng: 43.83268349999999,
        },
        _id: "64f75285ef8f606073e9b725",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "behompy",
        fokontany: "vorondreo tanindraza",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2644538,
          lng: 43.7860615,
        },
        _id: "64f75285ef8f606073e9b726",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "behompy",
        fokontany: "behera",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3049545,
          lng: 43.8195382,
        },
        _id: "64f75285ef8f606073e9b727",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "behompy",
        fokontany: "ambolokira",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2736282,
          lng: 43.7118528,
        },
        _id: "64f75285ef8f606073e9b728",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "maromiandra",
        fokontany: "maromiandra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9574583,
          lng: 43.8099758,
        },
        _id: "64f75285ef8f606073e9b729",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "maromiandra",
        fokontany: "mamery",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1100532,
          lng: 43.7525619,
        },
        _id: "64f75285ef8f606073e9b72a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "maromiandra",
        fokontany: "magnamby",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2659166,
          lng: 43.6998731,
        },
        _id: "64f75285ef8f606073e9b72b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "maromiandra",
        fokontany: "antsary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2641351,
          lng: 43.7118528,
        },
        _id: "64f75285ef8f606073e9b72c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "maromiandra",
        fokontany: "ambovonosy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2837704,
          lng: 43.7202368,
        },
        _id: "64f75285ef8f606073e9b72d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "maromiandra",
        fokontany: "ambalaviro bas",
        __v: 0,
      },
      {
        coords: {
          lat: -23.283303,
          lng: 43.7010712,
        },
        _id: "64f75285ef8f606073e9b72e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "maromiandra",
        fokontany: "mitsinjo mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.291012,
          lng: 43.7130506,
        },
        _id: "64f75285ef8f606073e9b72f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "maromiandra",
        fokontany: "antevamena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2911339,
          lng: 43.7058633,
        },
        _id: "64f75285ef8f606073e9b730",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "maromiandra",
        fokontany: "ankoro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2883515,
          lng: 43.6830966,
        },
        _id: "64f75285ef8f606073e9b731",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "maromiandra",
        fokontany: "marofatika",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2367241,
          lng: 43.74298630000001,
        },
        _id: "64f75285ef8f606073e9b732",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "maromiandra",
        fokontany: "ambohitsabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.44619,
          lng: 43.894329,
        },
        _id: "64f75285ef8f606073e9b733",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambohimahavelona",
        fokontany: "ambohimahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4499636,
          lng: 43.8386574,
        },
        _id: "64f75285ef8f606073e9b734",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambohimahavelona",
        fokontany: "maroamalo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4451575,
          lng: 43.9198303,
        },
        _id: "64f75285ef8f606073e9b735",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambohimahavelona",
        fokontany: "ambika",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4641001,
          lng: 43.9198303,
        },
        _id: "64f75285ef8f606073e9b736",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambohimahavelona",
        fokontany: "antainosy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.474495,
          lng: 43.95083109999999,
        },
        _id: "64f75285ef8f606073e9b737",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambohimahavelona",
        fokontany: "antsarongaza",
        __v: 0,
      },
      {
        coords: {
          lat: -23.460142,
          lng: 43.9579823,
        },
        _id: "64f75285ef8f606073e9b738",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambohimahavelona",
        fokontany: "tolikisy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4644184,
          lng: 43.9794296,
        },
        _id: "64f75285ef8f606073e9b739",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambohimahavelona",
        fokontany: "bevoay",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4784612,
          lng: 43.98657660000001,
        },
        _id: "64f75285ef8f606073e9b73a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambohimahavelona",
        fokontany: "tanandava mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4940265,
          lng: 43.9961042,
        },
        _id: "64f75285ef8f606073e9b73b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambohimahavelona",
        fokontany: "ankotrofoty",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4829842,
          lng: 44.0675018,
        },
        _id: "64f75285ef8f606073e9b73c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambohimahavelona",
        fokontany: "maropia",
        __v: 0,
      },
      {
        coords: {
          lat: -23.488425,
          lng: 44.1055372,
        },
        _id: "64f75285ef8f606073e9b73d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambohimahavelona",
        fokontany: "mahaleotse",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4182715,
          lng: 44.1435424,
        },
        _id: "64f75285ef8f606073e9b73e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambohimahavelona",
        fokontany: "agnahibe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3516191,
          lng: 43.6854936,
        },
        _id: "64f75285ef8f606073e9b73f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranohinaly",
        fokontany: "andranohinaly",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2569044,
          lng: 44.0246759,
        },
        _id: "64f75285ef8f606073e9b740",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranohinaly",
        fokontany: "sareriake",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2819409,
          lng: 44.0341961,
        },
        _id: "64f75285ef8f606073e9b741",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranohinaly",
        fokontany: "masiakampy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2585401,
          lng: 43.93891,
        },
        _id: "64f75285ef8f606073e9b742",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranohinaly",
        fokontany: "antsakoamileka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.299204,
          lng: 43.9579823,
        },
        _id: "64f75285ef8f606073e9b743",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranohinaly",
        fokontany: "ankazotrano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4079541,
          lng: 44.03895540000001,
        },
        _id: "64f75285ef8f606073e9b744",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranohinaly",
        fokontany: "analamitivala",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3030918,
          lng: 43.9198303,
        },
        _id: "64f75285ef8f606073e9b745",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranohinaly",
        fokontany: "befoly tsimiola",
        __v: 0,
      },
      {
        coords: {
          lat: -23.35,
          lng: 43.95,
        },
        _id: "64f75285ef8f606073e9b746",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranohinaly",
        fokontany: "ampamata",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3479366,
          lng: 43.8864226,
        },
        _id: "64f75285ef8f606073e9b747",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranohinaly",
        fokontany: "ankiliberengy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5478557,
          lng: 43.7657252,
        },
        _id: "64f75285ef8f606073e9b748",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "saint augustin",
        fokontany: "ianantsono",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3516191,
          lng: 43.6854936,
        },
        _id: "64f75285ef8f606073e9b749",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "saint augustin",
        fokontany: "ankilibe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3516191,
          lng: 43.6854936,
        },
        _id: "64f75285ef8f606073e9b74a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "saint augustin",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5080111,
          lng: 43.7812773,
        },
        _id: "64f75285ef8f606073e9b74b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "saint augustin",
        fokontany: "sarodrano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5288647,
          lng: 43.8434361,
        },
        _id: "64f75285ef8f606073e9b74c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "saint augustin",
        fokontany: "manoroka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5389428,
          lng: 43.8147572,
        },
        _id: "64f75285ef8f606073e9b74d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "saint augustin",
        fokontany: "ankerereaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5523791,
          lng: 43.7764925,
        },
        _id: "64f75285ef8f606073e9b74e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "saint augustin",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5491514,
          lng: 43.7603406,
        },
        _id: "64f75285ef8f606073e9b74f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "saint augustin",
        fokontany: "ampasinihita",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5684378,
          lng: 43.7645287,
        },
        _id: "64f75285ef8f606073e9b750",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "saint augustin",
        fokontany: "lovokampy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6028748,
          lng: 43.7812773,
        },
        _id: "64f75285ef8f606073e9b751",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "saint augustin",
        fokontany: "lavenombato",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6114377,
          lng: 43.8243187,
        },
        _id: "64f75285ef8f606073e9b752",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "saint augustin",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5875796,
          lng: 43.8314886,
        },
        _id: "64f75285ef8f606073e9b753",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "saint augustin",
        fokontany: "marobika",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5949114,
          lng: 43.8577691,
        },
        _id: "64f75285ef8f606073e9b754",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "saint augustin",
        fokontany: "ambohibory",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6812377,
          lng: 43.6663143,
        },
        _id: "64f75285ef8f606073e9b755",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "anakao",
        fokontany: "anakao haut",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6498217,
          lng: 43.6543235,
        },
        _id: "64f75285ef8f606073e9b756",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "anakao",
        fokontany: "anakao bas",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8060298,
          lng: 43.6609188,
        },
        _id: "64f75285ef8f606073e9b757",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "anakao",
        fokontany: "maromena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8269397,
          lng: 43.6639164,
        },
        _id: "64f75285ef8f606073e9b758",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "anakao",
        fokontany: "befasy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6211759,
          lng: 43.6615184,
        },
        _id: "64f75285ef8f606073e9b759",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "soalara sud",
        fokontany: "soalara bas",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6099052,
          lng: 43.7477743,
        },
        _id: "64f75285ef8f606073e9b75a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "soalara sud",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6089701,
          lng: 43.7166438,
        },
        _id: "64f75285ef8f606073e9b75b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "soalara sud",
        fokontany: "soalara haut",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6542946,
          lng: 43.74298630000001,
        },
        _id: "64f75285ef8f606073e9b75c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "soalara sud",
        fokontany: "tsirafaly",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7269357,
          lng: 43.8864226,
        },
        _id: "64f75285ef8f606073e9b75d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "soalara sud",
        fokontany: "ankaranila",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7627138,
          lng: 43.70466529999999,
        },
        _id: "64f75285ef8f606073e9b75e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "soalara sud",
        fokontany: "androtohoke",
        __v: 0,
      },
      {
        coords: {
          lat: -23.803796,
          lng: 43.67230869999999,
        },
        _id: "64f75285ef8f606073e9b75f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "soalara sud",
        fokontany: "ankilimivony maromainty",
        __v: 0,
      },
      {
        coords: {
          lat: -23.533333,
          lng: 43.866667,
        },
        _id: "64f75285ef8f606073e9b760",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambolofoty",
        fokontany: "ambolofoty",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4744638,
          lng: 43.8768733,
        },
        _id: "64f75285ef8f606073e9b761",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambolofoty",
        fokontany: "manantsofy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5031903,
          lng: 43.8625459,
        },
        _id: "64f75285ef8f606073e9b762",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambolofoty",
        fokontany: "beraketa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4926508,
          lng: 43.8756795,
        },
        _id: "64f75285ef8f606073e9b763",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambolofoty",
        fokontany: "befiha",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4915877,
          lng: 43.88880959999999,
        },
        _id: "64f75285ef8f606073e9b764",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambolofoty",
        fokontany: "mitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5059503,
          lng: 43.8816482,
        },
        _id: "64f75285ef8f606073e9b765",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambolofoty",
        fokontany: "belavenoka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5203147,
          lng: 43.8744857,
        },
        _id: "64f75285ef8f606073e9b766",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambolofoty",
        fokontany: "ampihamy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5276527,
          lng: 43.900743,
        },
        _id: "64f75285ef8f606073e9b767",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambolofoty",
        fokontany: "lavainaly",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5560732,
          lng: 43.900743,
        },
        _id: "64f75285ef8f606073e9b768",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambolofoty",
        fokontany: "androtsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5600558,
          lng: 43.8625459,
        },
        _id: "64f75285ef8f606073e9b769",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambolofoty",
        fokontany: "tongah",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5727442,
          lng: 43.8601576,
        },
        _id: "64f75285ef8f606073e9b76a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ambolofoty",
        fokontany: "berehoka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9527265,
          lng: 43.564299,
        },
        _id: "64f75285ef8f606073e9b76b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankilimalinike",
        fokontany: "ankilimalinike",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9192437,
          lng: 43.5823171,
        },
        _id: "64f75285ef8f606073e9b76c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankilimalinike",
        fokontany: "saririaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9443032,
          lng: 43.6063309,
        },
        _id: "64f75285ef8f606073e9b76d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankilimalinike",
        fokontany: "ampasamalinike",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9303893,
          lng: 43.57751289999999,
        },
        _id: "64f75285ef8f606073e9b76e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankilimalinike",
        fokontany: "benetse",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9446481,
          lng: 43.57751289999999,
        },
        _id: "64f75285ef8f606073e9b76f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankilimalinike",
        fokontany: "ampototse atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9849628,
          lng: 43.6471276,
        },
        _id: "64f75285ef8f606073e9b770",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankilimalinike",
        fokontany: "ankatrakatraka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9653022,
          lng: 43.5727083,
        },
        _id: "64f75285ef8f606073e9b771",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankilimalinike",
        fokontany: "tanambe manirisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9698804,
          lng: 43.5871208,
        },
        _id: "64f75285ef8f606073e9b772",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankilimalinike",
        fokontany: "antapoake",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9718709,
          lng: 43.55348499999999,
        },
        _id: "64f75285ef8f606073e9b773",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankilimalinike",
        fokontany: "tanandava metaye",
        __v: 0,
      },
      {
        coords: {
          lat: -22.984491,
          lng: 43.5582915,
        },
        _id: "64f75285ef8f606073e9b774",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankilimalinike",
        fokontany: "andrevo haut",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9938221,
          lng: 43.5727083,
        },
        _id: "64f75285ef8f606073e9b775",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankilimalinike",
        fokontany: "andombiry",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0295465,
          lng: 43.6279334,
        },
        _id: "64f75285ef8f606073e9b776",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankilimalinike",
        fokontany: "ranobe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0067955,
          lng: 43.548678,
        },
        _id: "64f75285ef8f606073e9b777",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankilimalinike",
        fokontany: "belemboka andohateva",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0130159,
          lng: 43.5582915,
        },
        _id: "64f75285ef8f606073e9b778",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankilimalinike",
        fokontany: "sakabera sikily",
        __v: 0,
      },
      {
        coords: {
          lat: -23.53282,
          lng: 43.98986800000001,
        },
        _id: "64f75285ef8f606073e9b779",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "antanimena onilahy",
        fokontany: "antanimena onilahy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5596175,
          lng: 44.0246759,
        },
        _id: "64f75285ef8f606073e9b77a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "antanimena onilahy",
        fokontany: "ampihamy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5217771,
          lng: 44.0246759,
        },
        _id: "64f75285ef8f606073e9b77b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "antanimena onilahy",
        fokontany: "belamaky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5211313,
          lng: 44.05323070000001,
        },
        _id: "64f75285ef8f606073e9b77c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "antanimena onilahy",
        fokontany: "voaifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5397167,
          lng: 44.0675018,
        },
        _id: "64f75285ef8f606073e9b77d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "antanimena onilahy",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5572836,
          lng: 44.1245436,
        },
        _id: "64f75285ef8f606073e9b77e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "antanimena onilahy",
        fokontany: "anatsakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3516191,
          lng: 43.6854936,
        },
        _id: "64f75285ef8f606073e9b77f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manorofify",
        fokontany: "manorofify",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4952646,
          lng: 43.93891,
        },
        _id: "64f75285ef8f606073e9b780",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manorofify",
        fokontany: "andranovaky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5270361,
          lng: 43.9293711,
        },
        _id: "64f75285ef8f606073e9b781",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manorofify",
        fokontany: "ambatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5197615,
          lng: 43.973473,
        },
        _id: "64f75285ef8f606073e9b782",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manorofify",
        fokontany: "ambararata",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5195252,
          lng: 43.98419440000001,
        },
        _id: "64f75285ef8f606073e9b783",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manorofify",
        fokontany: "antaliabo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8912859,
          lng: 43.5318499,
        },
        _id: "64f75285ef8f606073e9b784",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "marofoty",
        fokontany: "marofoty",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8788092,
          lng: 43.5126108,
        },
        _id: "64f75285ef8f606073e9b785",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "marofoty",
        fokontany: "beroroha",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9104653,
          lng: 43.5174213,
        },
        _id: "64f75285ef8f606073e9b786",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "marofoty",
        fokontany: "antsonomarify",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9258857,
          lng: 43.5558883,
        },
        _id: "64f75285ef8f606073e9b787",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "marofoty",
        fokontany: "ankadobarika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9390019,
          lng: 43.5174213,
        },
        _id: "64f75285ef8f606073e9b788",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "marofoty",
        fokontany: "antanimahery",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9435117,
          lng: 43.5390627,
        },
        _id: "64f75285ef8f606073e9b789",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "marofoty",
        fokontany: "androtsy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9579438,
          lng: 43.5246361,
        },
        _id: "64f75285ef8f606073e9b78a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "marofoty",
        fokontany: "antandroka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.951381,
          lng: 43.5438706,
        },
        _id: "64f75285ef8f606073e9b78b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "marofoty",
        fokontany: "antanimena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9281477,
          lng: 43.6327326,
        },
        _id: "64f75285ef8f606073e9b78c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "tsianisiha",
        fokontany: "tsianisiha II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8295566,
          lng: 43.6711099,
        },
        _id: "64f75285ef8f606073e9b78d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "tsianisiha",
        fokontany: "bevala",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8421197,
          lng: 43.6795009,
        },
        _id: "64f75285ef8f606073e9b78e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "tsianisiha",
        fokontany: "andoharano morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8455195,
          lng: 43.6591202,
        },
        _id: "64f75285ef8f606073e9b78f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "tsianisiha",
        fokontany: "antevamena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8505318,
          lng: 43.6705104,
        },
        _id: "64f75285ef8f606073e9b790",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "tsianisiha",
        fokontany: "andoharano tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8564873,
          lng: 43.66871219999999,
        },
        _id: "64f75285ef8f606073e9b791",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "tsianisiha",
        fokontany: "andoharano I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8740934,
          lng: 43.651925,
        },
        _id: "64f75285ef8f606073e9b792",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "tsianisiha",
        fokontany: "tsiafanoka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8599303,
          lng: 43.64472869999999,
        },
        _id: "64f75285ef8f606073e9b793",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "tsianisiha",
        fokontany: "antanimena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8504323,
          lng: 43.64472869999999,
        },
        _id: "64f75285ef8f606073e9b794",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "tsianisiha",
        fokontany: "behompy ambarobe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8617324,
          lng: 43.6255336,
        },
        _id: "64f75285ef8f606073e9b795",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "tsianisiha",
        fokontany: "betakilotse",
        __v: 0,
      },
      {
        coords: {
          lat: -22.867956,
          lng: 43.6351321,
        },
        _id: "64f75285ef8f606073e9b796",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "tsianisiha",
        fokontany: "soaloka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.897923,
          lng: 43.64472869999999,
        },
        _id: "64f75285ef8f606073e9b797",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "tsianisiha",
        fokontany: "beravy antsoity",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8759826,
          lng: 43.6255336,
        },
        _id: "64f75285ef8f606073e9b798",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "tsianisiha",
        fokontany: "beravy haut",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8808981,
          lng: 43.6111323,
        },
        _id: "64f75285ef8f606073e9b799",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "tsianisiha",
        fokontany: "beravy bas",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8842566,
          lng: 43.5943255,
        },
        _id: "64f75285ef8f606073e9b79a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "tsianisiha",
        fokontany: "antanimikodoy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8980936,
          lng: 43.6303331,
        },
        _id: "64f75285ef8f606073e9b79b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "tsianisiha",
        fokontany: "beravy ambala",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9000685,
          lng: 43.5967268,
        },
        _id: "64f75285ef8f606073e9b79c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "tsianisiha",
        fokontany: "tsianisiha I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9471183,
          lng: 43.5005825,
        },
        _id: "64f75285ef8f606073e9b79d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manombo sud",
        fokontany: "manombo I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4547773,
          lng: 43.3584284,
        },
        _id: "64f75285ef8f606073e9b79e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manombo sud",
        fokontany: "andravona",
        __v: 0,
      },
      {
        coords: {
          lat: -22.48443,
          lng: 43.29126,
        },
        _id: "64f75285ef8f606073e9b79f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manombo sud",
        fokontany: "bekodoy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5374325,
          lng: 43.339122,
        },
        _id: "64f75285ef8f606073e9b7a0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manombo sud",
        fokontany: "salary nord II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6199539,
          lng: 43.34877609999999,
        },
        _id: "64f75285ef8f606073e9b7a1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manombo sud",
        fokontany: "salary nord I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7213019,
          lng: 43.387374,
        },
        _id: "64f75285ef8f606073e9b7a2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manombo sud",
        fokontany: "tsandamba",
        __v: 0,
      },
      {
        coords: {
          lat: -22.830351,
          lng: 43.36637899999999,
        },
        _id: "64f75285ef8f606073e9b7a3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manombo sud",
        fokontany: "tsifota",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8603645,
          lng: 43.4548484,
        },
        _id: "64f75285ef8f606073e9b7a4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manombo sud",
        fokontany: "fiherenamasay",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8918962,
          lng: 43.47411,
        },
        _id: "64f75285ef8f606073e9b7a5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manombo sud",
        fokontany: "andranomavo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9363598,
          lng: 43.4692953,
        },
        _id: "64f75285ef8f606073e9b7a6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manombo sud",
        fokontany: "karimela mamiratra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9473185,
          lng: 43.4825346,
        },
        _id: "64f75285ef8f606073e9b7a7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manombo sud",
        fokontany: "avaradrova mikamba",
        __v: 0,
      },
      {
        coords: {
          lat: -22.951806,
          lng: 43.4711009,
        },
        _id: "64f75285ef8f606073e9b7a8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manombo sud",
        fokontany: "manombo II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9551548,
          lng: 43.4909578,
        },
        _id: "64f75285ef8f606073e9b7a9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manombo sud",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9929629,
          lng: 43.5126108,
        },
        _id: "64f75285ef8f606073e9b7aa",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manombo sud",
        fokontany: "tsihake",
        __v: 0,
      },
      {
        coords: {
          lat: -22.966472,
          lng: 43.4717027,
        },
        _id: "64f75285ef8f606073e9b7ab",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manombo sud",
        fokontany: "fitsitike",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0336783,
          lng: 43.55348499999999,
        },
        _id: "64f75285ef8f606073e9b7ac",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manombo sud",
        fokontany: "andrevo bas",
        __v: 0,
      },
      {
        coords: {
          lat: -23.05,
          lng: 43.6,
        },
        _id: "64f75285ef8f606073e9b7ad",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manombo sud",
        fokontany: "ambolomailaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0592132,
          lng: 43.6003286,
        },
        _id: "64f75285ef8f606073e9b7ae",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manombo sud",
        fokontany: "betsibaroka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0710448,
          lng: 43.6039301,
        },
        _id: "64f75285ef8f606073e9b7af",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "manombo sud",
        fokontany: "madiorano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1473807,
          lng: 44.148291,
        },
        _id: "64f75285ef8f606073e9b7b0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "andranovory centre",
        __v: 0,
      },
      {
        coords: {
          lat: -22.915581,
          lng: 44.06266,
        },
        _id: "64f75285ef8f606073e9b7b1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "ankoratsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0468053,
          lng: 44.1387934,
        },
        _id: "64f75285ef8f606073e9b7b2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "fotivolo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0670672,
          lng: 44.1921926,
        },
        _id: "64f75285ef8f606073e9b7b3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "sambaindefo betsinefo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0462866,
          lng: 44.1672804,
        },
        _id: "64f75285ef8f606073e9b7b4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "anjaraday",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0284194,
          lng: 44.11028950000001,
        },
        _id: "64f75285ef8f606073e9b7b5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "ambarimanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0163973,
          lng: 44.0770134,
        },
        _id: "64f75285ef8f606073e9b7b6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "ankatsadava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1165221,
          lng: 44.1150413,
        },
        _id: "64f75285ef8f606073e9b7b7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "ankilimalangy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0904426,
          lng: 44.1625338,
        },
        _id: "64f75285ef8f606073e9b7b8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "vorondreo vaovao",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1271074,
          lng: 44.2194625,
        },
        _id: "64f75285ef8f606073e9b7b9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "ambatovanda centre",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1764682,
          lng: 44.2668514,
        },
        _id: "64f75285ef8f606073e9b7ba",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "ankilimaro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1127723,
          lng: 44.30709479999999,
        },
        _id: "64f75285ef8f606073e9b7bb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "anketa ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -23.113419,
          lng: 44.27513949999999,
        },
        _id: "64f75285ef8f606073e9b7bc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "anketa anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.119802,
          lng: 44.27040359999999,
        },
        _id: "64f75285ef8f606073e9b7bd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "anketa tanindraza",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1290178,
          lng: 44.2810588,
        },
        _id: "64f75285ef8f606073e9b7be",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "tanambao andranovory",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2092918,
          lng: 44.2004938,
        },
        _id: "64f75285ef8f606073e9b7bf",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "andamoty mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1607081,
          lng: 44.11028950000001,
        },
        _id: "64f75285ef8f606073e9b7c0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "andalanabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1796073,
          lng: 44.11028950000001,
        },
        _id: "64f75285ef8f606073e9b7c1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "sakavilany",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1492656,
          lng: 44.0484728,
        },
        _id: "64f75285ef8f606073e9b7c2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "satramafana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1873593,
          lng: 44.0341961,
        },
        _id: "64f75285ef8f606073e9b7c3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "anjaposa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.217967,
          lng: 44.0817686,
        },
        _id: "64f75285ef8f606073e9b7c4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "andaromihomaky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2279768,
          lng: 44.05323070000001,
        },
        _id: "64f75285ef8f606073e9b7c5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "anjampirahalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2671239,
          lng: 44.1435424,
        },
        _id: "64f75285ef8f606073e9b7c6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "anjambaky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2616024,
          lng: 44.1055372,
        },
        _id: "64f75285ef8f606073e9b7c7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "antsanira",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3561097,
          lng: 44.1055372,
        },
        _id: "64f75285ef8f606073e9b7c8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "andranovory",
        fokontany: "anjakiriky lavasadia",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8270815,
          lng: 43.6087317,
        },
        _id: "64f75285ef8f606073e9b7c9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "milenaka",
        fokontany: "milenaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7839483,
          lng: 43.64472869999999,
        },
        _id: "64f75285ef8f606073e9b7ca",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "milenaka",
        fokontany: "andambolo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7968673,
          lng: 43.6207336,
        },
        _id: "64f75285ef8f606073e9b7cb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "milenaka",
        fokontany: "ankaraobato",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7981167,
          lng: 43.651925,
        },
        _id: "64f75285ef8f606073e9b7cc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "milenaka",
        fokontany: "antateza",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8203822,
          lng: 43.6423298,
        },
        _id: "64f75285ef8f606073e9b7cd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "milenaka",
        fokontany: "antranolahatse",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8206955,
          lng: 43.6135328,
        },
        _id: "64f75285ef8f606073e9b7ce",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "milenaka",
        fokontany: "ampoizy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8177328,
          lng: 43.5943255,
        },
        _id: "64f75285ef8f606073e9b7cf",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "milenaka",
        fokontany: "ambatolily",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8278359,
          lng: 43.5366585,
        },
        _id: "64f75285ef8f606073e9b7d0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "milenaka",
        fokontany: "andranodehoka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8509153,
          lng: 43.6015291,
        },
        _id: "64f75285ef8f606073e9b7d1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "milenaka",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -22.841255,
          lng: 43.6159332,
        },
        _id: "64f75285ef8f606073e9b7d2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "milenaka",
        fokontany: "antanimena rendaitse",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8593307,
          lng: 43.5558883,
        },
        _id: "64f75285ef8f606073e9b7d3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "milenaka",
        fokontany: "ampihamy sud",
        __v: 0,
      },
      {
        coords: {
          lat: -22.852709,
          lng: 43.5823171,
        },
        _id: "64f75285ef8f606073e9b7d4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "milenaka",
        fokontany: "tsiosy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8638504,
          lng: 43.57751289999999,
        },
        _id: "64f75285ef8f606073e9b7d5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "milenaka",
        fokontany: "belavenoka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.874993,
          lng: 43.5727083,
        },
        _id: "64f75285ef8f606073e9b7d6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "milenaka",
        fokontany: "moralonaka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7826917,
          lng: 43.6135328,
        },
        _id: "64f75285ef8f606073e9b7d7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "ankililoaka I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.591562,
          lng: 43.74298630000001,
        },
        _id: "64f75285ef8f606073e9b7d8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "antanilehibe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6334315,
          lng: 43.6615184,
        },
        _id: "64f75285ef8f606073e9b7d9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "antseva",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6374286,
          lng: 43.5655004,
        },
        _id: "64f75285ef8f606073e9b7da",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "ankilimiriorio",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6626924,
          lng: 43.5751106,
        },
        _id: "64f75285ef8f606073e9b7db",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "amboribositse",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6875537,
          lng: 43.6279334,
        },
        _id: "64f75285ef8f606073e9b7dc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "ampihamy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6671499,
          lng: 43.7717073,
        },
        _id: "64f75285ef8f606073e9b7dd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "andranolava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7308932,
          lng: 43.72382959999999,
        },
        _id: "64f75285ef8f606073e9b7de",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "antanimena maikandro",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7156948,
          lng: 43.6639164,
        },
        _id: "64f75285ef8f606073e9b7df",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "aborano",
        __v: 0,
      },
      {
        coords: {
          lat: -22.725406,
          lng: 43.6423298,
        },
        _id: "64f75285ef8f606073e9b7e0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "ankorondamoty",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7476637,
          lng: 43.6327326,
        },
        _id: "64f75285ef8f606073e9b7e1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "antanandava mandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7473694,
          lng: 43.6615184,
        },
        _id: "64f75285ef8f606073e9b7e2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "amboboka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7677639,
          lng: 43.6783022,
        },
        _id: "64f75285ef8f606073e9b7e3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "andranokova",
        __v: 0,
      },
      {
        coords: {
          lat: -22.772665,
          lng: 43.6639164,
        },
        _id: "64f75285ef8f606073e9b7e4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "andranomanintsy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7713352,
          lng: 43.6399307,
        },
        _id: "64f75285ef8f606073e9b7e5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "anosy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7707052,
          lng: 43.6243336,
        },
        _id: "64f75285ef8f606073e9b7e6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "ambararatafaly",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7636903,
          lng: 43.6135328,
        },
        _id: "64f75285ef8f606073e9b7e7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "ankililoaka II",
        __v: 0,
      },
      {
        coords: {
          lat: -22.74171,
          lng: 43.5943255,
        },
        _id: "64f75285ef8f606073e9b7e8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "ankilimaro",
        __v: 0,
      },
      {
        coords: {
          lat: -22.6823147,
          lng: 43.5029884,
        },
        _id: "64f75285ef8f606073e9b7e9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "ankatepoky",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7770274,
          lng: 43.5462744,
        },
        _id: "64f75285ef8f606073e9b7ea",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "ankiliabo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7927792,
          lng: 43.5558883,
        },
        _id: "64f75285ef8f606073e9b7eb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8147654,
          lng: 43.5751106,
        },
        _id: "64f75285ef8f606073e9b7ec",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "ampagnolora",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8178463,
          lng: 43.5835181,
        },
        _id: "64f75285ef8f606073e9b7ed",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "ankililoaka",
        fokontany: "ambondro",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4788084,
          lng: 43.59912800000001,
        },
        _id: "64f75285ef8f606073e9b7ee",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "analamisampy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.464121,
          lng: 43.67775,
        },
        _id: "64f75285ef8f606073e9b7ef",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "soahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4027307,
          lng: 43.6135328,
        },
        _id: "64f75285ef8f606073e9b7f0",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "andabotoka",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4267664,
          lng: 43.7956286,
        },
        _id: "64f75285ef8f606073e9b7f1",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "andaboro",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4448234,
          lng: 43.7070613,
        },
        _id: "64f75285ef8f606073e9b7f2",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "ankililaly",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4654157,
          lng: 43.7022693,
        },
        _id: "64f75285ef8f606073e9b7f3",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "mandatsa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4623418,
          lng: 43.6902872,
        },
        _id: "64f75285ef8f606073e9b7f4",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "ankilitiahena",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4160196,
          lng: 43.5029884,
        },
        _id: "64f75285ef8f606073e9b7f5",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "analodolo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5430657,
          lng: 43.4644802,
        },
        _id: "64f75285ef8f606073e9b7f6",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "anjabetrongo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5328826,
          lng: 43.5655004,
        },
        _id: "64f75285ef8f606073e9b7f7",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "analabo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4787104,
          lng: 43.6135328,
        },
        _id: "64f75285ef8f606073e9b7f8",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "belitsaka nord",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5105416,
          lng: 43.5895225,
        },
        _id: "64f75285ef8f606073e9b7f9",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "belitsaka sud",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5129724,
          lng: 43.68789049999999,
        },
        _id: "64f75285ef8f606073e9b7fa",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "ankaray sud",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4908104,
          lng: 43.6902872,
        },
        _id: "64f75285ef8f606073e9b7fb",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "ankaray nord",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4304364,
          lng: 43.7286194,
        },
        _id: "64f75285ef8f606073e9b7fc",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "betsioky somotse",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4583194,
          lng: 43.8004115,
        },
        _id: "64f75285ef8f606073e9b7fd",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "betsipotika",
        __v: 0,
      },
      {
        coords: {
          lat: -22.4902359,
          lng: 43.76213550000001,
        },
        _id: "64f75285ef8f606073e9b7fe",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "ankilimbositra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5312831,
          lng: 43.7669217,
        },
        _id: "64f75285ef8f606073e9b7ff",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "ambovomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5354795,
          lng: 43.6423298,
        },
        _id: "64f75285ef8f606073e9b800",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "ampasikibo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5476299,
          lng: 43.70466529999999,
        },
        _id: "64f75285ef8f606073e9b801",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "ambovotsiritsy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5546968,
          lng: 43.6135328,
        },
        _id: "64f75285ef8f606073e9b802",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "ambahija",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5934716,
          lng: 43.5126108,
        },
        _id: "64f75285ef8f606073e9b803",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "matsa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.5800654,
          lng: 43.6087317,
        },
        _id: "64f75285ef8f606073e9b804",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "analamisampy",
        fokontany: "namaboha",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8820672,
          lng: 43.74298630000001,
        },
        _id: "64f75285ef8f606073e9b805",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "beheloka",
        fokontany: "beheloka bas",
        __v: 0,
      },
      {
        coords: {
          lat: -23.80398,
          lng: 43.684799,
        },
        _id: "64f75285ef8f606073e9b806",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "beheloka",
        fokontany: "ankilimivony",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8027367,
          lng: 43.8864226,
        },
        _id: "64f75285ef8f606073e9b807",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "beheloka",
        fokontany: "ambahivahy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8437666,
          lng: 43.7573489,
        },
        _id: "64f75285ef8f606073e9b808",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "beheloka",
        fokontany: "vatolalake",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9132345,
          lng: 43.76213550000001,
        },
        _id: "64f75285ef8f606073e9b809",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "beheloka",
        fokontany: "beheloka haut",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9028219,
          lng: 43.92460090000001,
        },
        _id: "64f75285ef8f606073e9b80a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "beheloka",
        fokontany: "ampotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8785638,
          lng: 44.1245436,
        },
        _id: "64f75285ef8f606073e9b80b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "beheloka",
        fokontany: "miarintsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9231911,
          lng: 44.1055372,
        },
        _id: "64f75285ef8f606073e9b80c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "beheloka",
        fokontany: "tomboina",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8933271,
          lng: 44.1031608,
        },
        _id: "64f75285ef8f606073e9b80d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "beheloka",
        fokontany: "behalintany",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9482685,
          lng: 43.8768733,
        },
        _id: "64f75285ef8f606073e9b80e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "beheloka",
        fokontany: "ankalindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9865491,
          lng: 43.8625459,
        },
        _id: "64f75285ef8f606073e9b80f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "beheloka",
        fokontany: "ankilibory",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0038364,
          lng: 43.6806995,
        },
        _id: "64f75285ef8f606073e9b810",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "beheloka",
        fokontany: "besambay",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0269874,
          lng: 44.0008674,
        },
        _id: "64f75285ef8f606073e9b811",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "beheloka",
        fokontany: "montelime",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0672446,
          lng: 43.91505919999999,
        },
        _id: "64f75285ef8f606073e9b812",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "beheloka",
        fokontany: "marofijery",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0893244,
          lng: 43.8004115,
        },
        _id: "64f75285ef8f606073e9b813",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "beheloka",
        fokontany: "efoetse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0893062,
          lng: 43.6806995,
        },
        _id: "64f75285ef8f606073e9b814",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "beheloka",
        fokontany: "ambola",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1200213,
          lng: 43.83387829999999,
        },
        _id: "64f75285ef8f606073e9b815",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "beheloka",
        fokontany: "maromitilike",
        __v: 0,
      },
      {
        coords: {
          lat: -24.133333,
          lng: 43.7,
        },
        _id: "64f75285ef8f606073e9b816",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "beheloka",
        fokontany: "behazomby",
        __v: 0,
      },
      {
        coords: {
          lat: -24.203126,
          lng: 43.8004115,
        },
        _id: "64f75285ef8f606073e9b817",
        province: "toliary",
        region: "atsimo andrefana",
        district: "toliary-II",
        commune: "beheloka",
        fokontany: "manasy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4541,
          lng: 45.0806619,
        },
        _id: "64f75285ef8f606073e9b818",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "benenitra",
        fokontany: "benenitra I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1138129,
          lng: 45.186889,
        },
        _id: "64f75285ef8f606073e9b819",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "benenitra",
        fokontany: "antsoaravy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2757693,
          lng: 45.168166,
        },
        _id: "64f75285ef8f606073e9b81a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "benenitra",
        fokontany: "antsokaka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3463047,
          lng: 45.2337297,
        },
        _id: "64f75285ef8f606073e9b81b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "benenitra",
        fokontany: "ambondrobe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3942071,
          lng: 45.1588073,
        },
        _id: "64f75285ef8f606073e9b81c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "benenitra",
        fokontany: "amparimiaky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.396388,
          lng: 45.1260669,
        },
        _id: "64f75285ef8f606073e9b81d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "benenitra",
        fokontany: "ambohitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4185173,
          lng: 45.1354189,
        },
        _id: "64f75285ef8f606073e9b81e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "benenitra",
        fokontany: "berevo bekily",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4436366,
          lng: 45.1400956,
        },
        _id: "64f75285ef8f606073e9b81f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "benenitra",
        fokontany: "beraketa est",
        __v: 0,
      },
      {
        coords: {
          lat: -23.437919,
          lng: 45.1050317,
        },
        _id: "64f75285ef8f606073e9b820",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "benenitra",
        fokontany: "tanapiso",
        __v: 0,
      },
      {
        coords: {
          lat: -23.361104,
          lng: 45.0933497,
        },
        _id: "64f75285ef8f606073e9b821",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "benenitra",
        fokontany: "miary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4370851,
          lng: 45.0769998,
        },
        _id: "64f75285ef8f606073e9b822",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "benenitra",
        fokontany: "andranotsara",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4913391,
          lng: 45.0653249,
        },
        _id: "64f75285ef8f606073e9b823",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "benenitra",
        fokontany: "benenitra II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4283755,
          lng: 45.0466511,
        },
        _id: "64f75285ef8f606073e9b824",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "benenitra",
        fokontany: "maromiandra besavoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3624564,
          lng: 45.0326508,
        },
        _id: "64f75285ef8f606073e9b825",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "benenitra",
        fokontany: "ankilivalo II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.35865,
          lng: 45.0093264,
        },
        _id: "64f75285ef8f606073e9b826",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "benenitra",
        fokontany: "mitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4147749,
          lng: 44.9906736,
        },
        _id: "64f75285ef8f606073e9b827",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "benenitra",
        fokontany: "andranomanintsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4521922,
          lng: 44.9906736,
        },
        _id: "64f75285ef8f606073e9b828",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "benenitra",
        fokontany: "bevatry",
        __v: 0,
      },
      {
        coords: {
          lat: -23.508319,
          lng: 44.9906736,
        },
        _id: "64f75285ef8f606073e9b829",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "benenitra",
        fokontany: "mandro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6310169,
          lng: 45.1400956,
        },
        _id: "64f75285ef8f606073e9b82a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "sakalava ianapera",
        __v: 0,
      },
      {
        coords: {
          lat: -23.458558,
          lng: 45.1167167,
        },
        _id: "64f75285ef8f606073e9b82b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "andranata",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4832534,
          lng: 45.1073685,
        },
        _id: "64f75285ef8f606073e9b82c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "bekaraoky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4811119,
          lng: 45.1400956,
        },
        _id: "64f75285ef8f606073e9b82d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "ankara",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5322108,
          lng: 45.1775265,
        },
        _id: "64f75285ef8f606073e9b82e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "ankazomanga bas",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5620217,
          lng: 45.1307427,
        },
        _id: "64f75285ef8f606073e9b82f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "ambararata nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.616667,
          lng: 45.05,
        },
        _id: "64f75285ef8f606073e9b830",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "ankilimary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5475037,
          lng: 44.9346751,
        },
        _id: "64f75285ef8f606073e9b831",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "andranosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5917802,
          lng: 44.9159938,
        },
        _id: "64f75285ef8f606073e9b832",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "bevinda",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6479583,
          lng: 44.9159938,
        },
        _id: "64f75285ef8f606073e9b833",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "vohiboro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6087076,
          lng: 44.972015,
        },
        _id: "64f75285ef8f606073e9b834",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "vohidravy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6327751,
          lng: 45.1962533,
        },
        _id: "64f75285ef8f606073e9b835",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "ambondrolava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6839566,
          lng: 45.2337297,
        },
        _id: "64f75285ef8f606073e9b836",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "andranolava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.665871,
          lng: 45.140411,
        },
        _id: "64f75285ef8f606073e9b837",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "beambatry",
        __v: 0,
      },
      {
        coords: {
          lat: -23.70093,
          lng: 45.1775265,
        },
        _id: "64f75285ef8f606073e9b838",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "berety mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7571705,
          lng: 45.1775265,
        },
        _id: "64f75285ef8f606073e9b839",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "besakoa avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7176032,
          lng: 45.2103034,
        },
        _id: "64f75285ef8f606073e9b83a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "andohavoly",
        __v: 0,
      },
      {
        coords: {
          lat: -23.777821,
          lng: 45.20832100000001,
        },
        _id: "64f75285ef8f606073e9b83b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "liolava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7300896,
          lng: 45.3087733,
        },
        _id: "64f75285ef8f606073e9b83c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "andriabe andohaviana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7130417,
          lng: 45.3651355,
        },
        _id: "64f75285ef8f606073e9b83d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "fenoarivo anakataza",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7574598,
          lng: 45.3839381,
        },
        _id: "64f75285ef8f606073e9b83e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "fenoambony andasy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7946642,
          lng: 45.1775265,
        },
        _id: "64f75285ef8f606073e9b83f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "bevalay",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8252804,
          lng: 45.1588073,
        },
        _id: "64f75285ef8f606073e9b840",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "benonoky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8435468,
          lng: 45.1447728,
        },
        _id: "64f75285ef8f606073e9b841",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "antanimena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8421076,
          lng: 45.1026951,
        },
        _id: "64f75285ef8f606073e9b842",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "vatanalialy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.878611,
          lng: 45.10207,
        },
        _id: "64f75285ef8f606073e9b843",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "ankazotelo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8815086,
          lng: 45.1588073,
        },
        _id: "64f75285ef8f606073e9b844",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ianapera",
        fokontany: "antsohamamy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5431471,
          lng: 45.3369459,
        },
        _id: "64f75285ef8f606073e9b845",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ambalavato",
        fokontany: "ambalavato",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5079425,
          lng: 45.4215658,
        },
        _id: "64f75285ef8f606073e9b846",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ambalavato",
        fokontany: "andohaviana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5183718,
          lng: 45.3463405,
        },
        _id: "64f75285ef8f606073e9b847",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ambalavato",
        fokontany: "keliandray",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4943744,
          lng: 45.3839381,
        },
        _id: "64f75285ef8f606073e9b848",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ambalavato",
        fokontany: "ankazoabo ankeniheny",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5047854,
          lng: 45.3087733,
        },
        _id: "64f75285ef8f606073e9b849",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ambalavato",
        fokontany: "tsanerena soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5279085,
          lng: 45.2431035,
        },
        _id: "64f75285ef8f606073e9b84a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ambalavato",
        fokontany: "ankatrafay",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5974033,
          lng: 45.2665464,
        },
        _id: "64f75285ef8f606073e9b84b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ambalavato",
        fokontany: "ambalanira",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5679164,
          lng: 45.3275531,
        },
        _id: "64f75285ef8f606073e9b84c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ambalavato",
        fokontany: "angodogodo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6092962,
          lng: 45.3510386,
        },
        _id: "64f75285ef8f606073e9b84d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ambalavato",
        fokontany: "tantelisarotra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6332234,
          lng: 45.31346749999999,
        },
        _id: "64f75285ef8f606073e9b84e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ambalavato",
        fokontany: "mitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6413339,
          lng: 45.2712363,
        },
        _id: "64f75285ef8f606073e9b84f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ambalavato",
        fokontany: "befamata",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6314892,
          lng: 45.3604361,
        },
        _id: "64f75285ef8f606073e9b850",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ambalavato",
        fokontany: "bevindabe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6682155,
          lng: 45.3322493,
        },
        _id: "64f75285ef8f606073e9b851",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ambalavato",
        fokontany: "fenoarivo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6784562,
          lng: 45.3604361,
        },
        _id: "64f75285ef8f606073e9b852",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ambalavato",
        fokontany: "anadabofolo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6477011,
          lng: 45.3792367,
        },
        _id: "64f75285ef8f606073e9b853",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ambalavato",
        fokontany: "bevinda",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6771422,
          lng: 45.4215658,
        },
        _id: "64f75285ef8f606073e9b854",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ambalavato",
        fokontany: "vohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4834377,
          lng: 44.8341739,
        },
        _id: "64f75285ef8f606073e9b855",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ehara",
        fokontany: "ehara",
        __v: 0,
      },
      {
        coords: {
          lat: -23.378321,
          lng: 44.840111,
        },
        _id: "64f75285ef8f606073e9b856",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ehara",
        fokontany: "elonty",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2846187,
          lng: 44.9626829,
        },
        _id: "64f75285ef8f606073e9b857",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ehara",
        fokontany: "mandarano ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4061617,
          lng: 44.8599044,
        },
        _id: "64f75285ef8f606073e9b858",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ehara",
        fokontany: "ankilimary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4559902,
          lng: 44.8645811,
        },
        _id: "64f75285ef8f606073e9b859",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ehara",
        fokontany: "anadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4384807,
          lng: 44.8224735,
        },
        _id: "64f75285ef8f606073e9b85a",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ehara",
        fokontany: "eseva",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4224161,
          lng: 44.9440129,
        },
        _id: "64f75285ef8f606073e9b85b",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ehara",
        fokontany: "belamoty kely",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4913391,
          lng: 44.9346751,
        },
        _id: "64f75285ef8f606073e9b85c",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ehara",
        fokontany: "bekinana haut",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5079428,
          lng: 44.9019778,
        },
        _id: "64f75285ef8f606073e9b85d",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ehara",
        fokontany: "analamena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.493042,
          lng: 44.8786084,
        },
        _id: "64f75285ef8f606073e9b85e",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ehara",
        fokontany: "beapombo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5130449,
          lng: 44.8365136,
        },
        _id: "64f75285ef8f606073e9b85f",
        province: "toliary",
        region: "atsimo andrefana",
        district: "benenitra",
        commune: "ehara",
        fokontany: "tanandava bas",
        __v: 0,
      },
      {
        coords: {
          lat: -25.17017,
          lng: 45.0594885,
        },
        _id: "64f75285ef8f606073e9b860",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "beloha nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8365436,
          lng: 44.9906736,
        },
        _id: "64f75285ef8f606073e9b861",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "vondrote",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8759235,
          lng: 44.94868109999999,
        },
        _id: "64f75285ef8f606073e9b862",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "besomotse maheny",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9182234,
          lng: 44.91132229999999,
        },
        _id: "64f75285ef8f606073e9b863",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "marolava andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8523466,
          lng: 45.0139904,
        },
        _id: "64f75285ef8f606073e9b864",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "behorofa avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8910736,
          lng: 45.0419839,
        },
        _id: "64f75285ef8f606073e9b865",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "zambe avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9068833,
          lng: 44.95334889999999,
        },
        _id: "64f75285ef8f606073e9b866",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "besomotse antsanira",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9424794,
          lng: 44.8599044,
        },
        _id: "64f75285ef8f606073e9b867",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "tehodo riambe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9340406,
          lng: 45.0933497,
        },
        _id: "64f75285ef8f606073e9b868",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "marolava avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9772659,
          lng: 44.9159938,
        },
        _id: "64f75285ef8f606073e9b869",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "tefototse andrambale",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0318655,
          lng: 44.8832833,
        },
        _id: "64f75285ef8f606073e9b86a",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "antsaniria",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0262627,
          lng: 44.9346751,
        },
        _id: "64f75285ef8f606073e9b86b",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "hatakatake anjapohy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.930041,
          lng: 44.9906736,
        },
        _id: "64f75285ef8f606073e9b86c",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "ambindavato anjamalangy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0177536,
          lng: 45.0186548,
        },
        _id: "64f75285ef8f606073e9b86d",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "vazoa antaly",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0102491,
          lng: 45.1213916,
        },
        _id: "64f75285ef8f606073e9b86e",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "andranohazonahily",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0592647,
          lng: 45.1026951,
        },
        _id: "64f75285ef8f606073e9b86f",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "andriamameriarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0680769,
          lng: 44.972015,
        },
        _id: "64f75285ef8f606073e9b870",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "andramboro",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1341258,
          lng: 44.89730489999999,
        },
        _id: "64f75285ef8f606073e9b871",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "afondralambo ampatiolobe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.12285,
          lng: 44.87529,
        },
        _id: "64f75285ef8f606073e9b872",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "kirimosa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1198012,
          lng: 44.9346751,
        },
        _id: "64f75285ef8f606073e9b873",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "lapavaho ankilimiary",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1435751,
          lng: 44.9580161,
        },
        _id: "64f75285ef8f606073e9b874",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "tirimby",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1170069,
          lng: 44.9906736,
        },
        _id: "64f75285ef8f606073e9b875",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "sihanaboay tsitonta",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1061723,
          lng: 45.0419839,
        },
        _id: "64f75285ef8f606073e9b876",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "maliolagnitse angobigoby",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0967631,
          lng: 45.0723295,
        },
        _id: "64f75285ef8f606073e9b877",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "maliolagnitse andranolava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0905394,
          lng: 45.2337297,
        },
        _id: "64f75285ef8f606073e9b878",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "anatsosa beborodoke",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1317822,
          lng: 45.1822075,
        },
        _id: "64f75285ef8f606073e9b879",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "ambohimandroso antreaky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1787234,
          lng: 45.1213916,
        },
        _id: "64f75285ef8f606073e9b87a",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "sihanadaly",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1499527,
          lng: 45.1073685,
        },
        _id: "64f75285ef8f606073e9b87b",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "bevaro ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1334321,
          lng: 45.0886777,
        },
        _id: "64f75285ef8f606073e9b87c",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "bemonto bevaro patia",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1521866,
          lng: 45.0583213,
        },
        _id: "64f75285ef8f606073e9b87d",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "zambe beloha",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1702096,
          lng: 45.0139904,
        },
        _id: "64f75285ef8f606073e9b87e",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "avotsy zambe mifanitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1435751,
          lng: 45.0419839,
        },
        _id: "64f75285ef8f606073e9b87f",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "antakeloke zambe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1849184,
          lng: 45.0583213,
        },
        _id: "64f75285ef8f606073e9b880",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "beloha sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1805787,
          lng: 45.0805029,
        },
        _id: "64f75285ef8f606073e9b881",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "ankazomagnitse",
        __v: 0,
      },
      {
        coords: {
          lat: -25.199676,
          lng: 45.0419839,
        },
        _id: "64f75285ef8f606073e9b882",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "atsimondrova",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2046845,
          lng: 45.07933509999999,
        },
        _id: "64f75285ef8f606073e9b883",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "namandriha",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2262507,
          lng: 45.1050317,
        },
        _id: "64f75285ef8f606073e9b884",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "andombiry",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2198004,
          lng: 45.0699945,
        },
        _id: "64f75285ef8f606073e9b885",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "beloha",
        fokontany: "tetsiatreke amboroneoke",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2794938,
          lng: 45.0186548,
        },
        _id: "64f75285ef8f606073e9b886",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "barabay",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1593102,
          lng: 44.8926315,
        },
        _id: "64f75285ef8f606073e9b887",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "masombaho",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1627717,
          lng: 44.8224735,
        },
        _id: "64f75285ef8f606073e9b888",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "agnirinihamba",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1853127,
          lng: 44.99533700000001,
        },
        _id: "64f75285ef8f606073e9b889",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "laparoro",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1895641,
          lng: 44.91132229999999,
        },
        _id: "64f75285ef8f606073e9b88a",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "tsimilafika",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2002307,
          lng: 44.8224735,
        },
        _id: "64f75285ef8f606073e9b88b",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "benato",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2,
          lng: 44.966667,
        },
        _id: "64f75285ef8f606073e9b88c",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "ankilisinao",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2435464,
          lng: 45.0466511,
        },
        _id: "64f75285ef8f606073e9b88d",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "natomasy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2333329,
          lng: 44.883333,
        },
        _id: "64f75285ef8f606073e9b88e",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "angirazato",
        __v: 0,
      },
      {
        coords: {
          lat: -25.279511,
          lng: 45.07933509999999,
        },
        _id: "64f75285ef8f606073e9b88f",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "analalava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2910229,
          lng: 45.0910136,
        },
        _id: "64f75285ef8f606073e9b890",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "ankaboa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.317011,
          lng: 45.127899,
        },
        _id: "64f75285ef8f606073e9b891",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "andraseky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3053898,
          lng: 44.9626829,
        },
        _id: "64f75285ef8f606073e9b892",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "tranovaho",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3306055,
          lng: 45.1634864,
        },
        _id: "64f75285ef8f606073e9b893",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "ankilevalo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3543638,
          lng: 45.2009362,
        },
        _id: "64f75285ef8f606073e9b894",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "tramba",
        __v: 0,
      },
      {
        coords: {
          lat: -25.31131,
          lng: 44.814789,
        },
        _id: "64f75285ef8f606073e9b895",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "befamata",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4075677,
          lng: 45.0840062,
        },
        _id: "64f75285ef8f606073e9b896",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "ampihamy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.328447,
          lng: 44.8786084,
        },
        _id: "64f75285ef8f606073e9b897",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "betaimbolo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3990085,
          lng: 45.1588073,
        },
        _id: "64f75285ef8f606073e9b898",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "savilintotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3916395,
          lng: 45.0186548,
        },
        _id: "64f75285ef8f606073e9b899",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "ananademby",
        __v: 0,
      },
      {
        coords: {
          lat: -25.42926,
          lng: 44.94521,
        },
        _id: "64f75285ef8f606073e9b89a",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "lavanono",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4391132,
          lng: 44.96734920000001,
        },
        _id: "64f75285ef8f606073e9b89b",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "soamagnitse II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4599116,
          lng: 44.9860096,
        },
        _id: "64f75285ef8f606073e9b89c",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "soamagnitse I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4564426,
          lng: 45.0653249,
        },
        _id: "64f75285ef8f606073e9b89d",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "ambatomasy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4586843,
          lng: 45.1073685,
        },
        _id: "64f75285ef8f606073e9b89e",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "marovoro",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4728745,
          lng: 45.0233196,
        },
        _id: "64f75285ef8f606073e9b89f",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "sareriake",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4873529,
          lng: 45.0606557,
        },
        _id: "64f75285ef8f606073e9b8a0",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "agnalamare",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5083158,
          lng: 45.1026951,
        },
        _id: "64f75285ef8f606073e9b8a1",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranovaho",
        fokontany: "ambalanose",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2290216,
          lng: 45.19157089999999,
        },
        _id: "64f75285ef8f606073e9b8a2",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "tsinaha",
        __v: 0,
      },
      {
        coords: {
          lat: -25.036934,
          lng: 45.290001,
        },
        _id: "64f75285ef8f606073e9b8a3",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "tamonto II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0387847,
          lng: 45.1962533,
        },
        _id: "64f75285ef8f606073e9b8a4",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "ankilevoangy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0360004,
          lng: 45.17050589999999,
        },
        _id: "64f75285ef8f606073e9b8a5",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "befahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0922808,
          lng: 45.1400956,
        },
        _id: "64f75285ef8f606073e9b8a6",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "tamonto I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0733587,
          lng: 45.2009362,
        },
        _id: "64f75285ef8f606073e9b8a7",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "ampangidraty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0943179,
          lng: 45.1822075,
        },
        _id: "64f75285ef8f606073e9b8a8",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "sihanakapaha",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0770735,
          lng: 45.3463405,
        },
        _id: "64f75285ef8f606073e9b8a9",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "ampilofilo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1,
          lng: 45.283333,
        },
        _id: "64f75285ef8f606073e9b8aa",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "iotry",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1930619,
          lng: 45.1588073,
        },
        _id: "64f75285ef8f606073e9b8ab",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "afondravoatse anjandavo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1770929,
          lng: 45.21498769999999,
        },
        _id: "64f75285ef8f606073e9b8ac",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "afondravoatse nord",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2285579,
          lng: 45.2454473,
        },
        _id: "64f75285ef8f606073e9b8ad",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "tsinaha tratravaky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2663909,
          lng: 45.2524792,
        },
        _id: "64f75285ef8f606073e9b8ae",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "tamontopoty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2331746,
          lng: 45.2759268,
        },
        _id: "64f75285ef8f606073e9b8af",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "anjapoty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2691596,
          lng: 45.3087733,
        },
        _id: "64f75285ef8f606073e9b8b0",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "tamontondava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3066698,
          lng: 45.3087733,
        },
        _id: "64f75285ef8f606073e9b8b1",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "tamonto sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3110596,
          lng: 45.2712363,
        },
        _id: "64f75285ef8f606073e9b8b2",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "bevolombity",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2494979,
          lng: 45.2267006,
        },
        _id: "64f75285ef8f606073e9b8b3",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "tambanivaro avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2617573,
          lng: 45.2220151,
        },
        _id: "64f75285ef8f606073e9b8b4",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "tambanivaro",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2305112,
          lng: 45.1588073,
        },
        _id: "64f75285ef8f606073e9b8b5",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "afondravoatse sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.260774,
          lng: 45.1400956,
        },
        _id: "64f75285ef8f606073e9b8b6",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "tedreatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2895105,
          lng: 45.21498769999999,
        },
        _id: "64f75285ef8f606073e9b8b7",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "teza",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2895648,
          lng: 45.1541287,
        },
        _id: "64f75285ef8f606073e9b8b8",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "ambohimagnare",
        __v: 0,
      },
      {
        coords: {
          lat: -25.316667,
          lng: 45.2,
        },
        _id: "64f75285ef8f606073e9b8b9",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "kopoky",
        fokontany: "betsako",
        __v: 0,
      },
      {
        coords: {
          lat: -25.142491,
          lng: 44.59254809999999,
        },
        _id: "64f75285ef8f606073e9b8ba",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "mahafaly",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0284967,
          lng: 44.7568965,
        },
        _id: "64f75285ef8f606073e9b8bb",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "tanalave",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9689865,
          lng: 44.82715400000001,
        },
        _id: "64f75285ef8f606073e9b8bc",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "andraketalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0813793,
          lng: 44.82715400000001,
        },
        _id: "64f75285ef8f606073e9b8bd",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "marojela",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0432464,
          lng: 44.8411927,
        },
        _id: "64f75285ef8f606073e9b8be",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "mikaikarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0135811,
          lng: 44.8084291,
        },
        _id: "64f75285ef8f606073e9b8bf",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "tesomangy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1021385,
          lng: 44.7850123,
        },
        _id: "64f75285ef8f606073e9b8c0",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "tanalave be",
        __v: 0,
      },
      {
        coords: {
          lat: -25.058598,
          lng: 44.7146908,
        },
        _id: "64f75285ef8f606073e9b8c1",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "amborignabo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0887107,
          lng: 44.6724469,
        },
        _id: "64f75285ef8f606073e9b8c2",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "malebitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.102701,
          lng: 44.7404877,
        },
        _id: "64f75285ef8f606073e9b8c3",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "afondralambo mahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1552724,
          lng: 44.7193823,
        },
        _id: "64f75285ef8f606073e9b8c4",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "tambala karembola",
        __v: 0,
      },
      {
        coords: {
          lat: -25.101261,
          lng: 44.6548339,
        },
        _id: "64f75285ef8f606073e9b8c5",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1521441,
          lng: 44.6536595,
        },
        _id: "64f75285ef8f606073e9b8c6",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "sasavisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1807115,
          lng: 44.5784342,
        },
        _id: "64f75285ef8f606073e9b8c7",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "ankilisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2763297,
          lng: 44.6724469,
        },
        _id: "64f75285ef8f606073e9b8c8",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "lavaheloky ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2214028,
          lng: 44.6442629,
        },
        _id: "64f75285ef8f606073e9b8c9",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "hazohandatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2497171,
          lng: 44.7053066,
        },
        _id: "64f75285ef8f606073e9b8ca",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "anjedava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2548183,
          lng: 44.72876369999999,
        },
        _id: "64f75285ef8f606073e9b8cb",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "abolaza",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2895105,
          lng: 44.7850123,
        },
        _id: "64f75285ef8f606073e9b8cc",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "ankilivalo andovoky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2209272,
          lng: 44.5219358,
        },
        _id: "64f75285ef8f606073e9b8cd",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "tatimo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2674561,
          lng: 44.5972518,
        },
        _id: "64f75285ef8f606073e9b8ce",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2513611,
          lng: 44.5407761,
        },
        _id: "64f75285ef8f606073e9b8cf",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "marolinta",
        fokontany: "mendoravy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.71047,
          lng: 45.064281,
        },
        _id: "64f75285ef8f606073e9b8d0",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "maroraza tranoroa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6029178,
          lng: 45.0840062,
        },
        _id: "64f75285ef8f606073e9b8d1",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "jontany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6170217,
          lng: 45.1213916,
        },
        _id: "64f75285ef8f606073e9b8d2",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "ankaramanoy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6590781,
          lng: 45.0840062,
        },
        _id: "64f75285ef8f606073e9b8d3",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "vohiby",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6802693,
          lng: 45.1400956,
        },
        _id: "64f75285ef8f606073e9b8d4",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "koboary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7177319,
          lng: 45.1400956,
        },
        _id: "64f75285ef8f606073e9b8d5",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "tsotso",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7193997,
          lng: 45.1073685,
        },
        _id: "64f75285ef8f606073e9b8d6",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "vohibola",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7143621,
          lng: 45.2056196,
        },
        _id: "64f75285ef8f606073e9b8d7",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "bereha",
        __v: 0,
      },
      {
        coords: {
          lat: -24.754309,
          lng: 45.0513189,
        },
        _id: "64f75285ef8f606073e9b8d8",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "andranoherika",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7868907,
          lng: 45.0139904,
        },
        _id: "64f75285ef8f606073e9b8d9",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "rerango",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7784785,
          lng: 45.1026951,
        },
        _id: "64f75285ef8f606073e9b8da",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "antsifitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7905858,
          lng: 45.2337297,
        },
        _id: "64f75285ef8f606073e9b8db",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "koronga",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7625956,
          lng: 45.3087733,
        },
        _id: "64f75285ef8f606073e9b8dc",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "ambatotsivala",
        __v: 0,
      },
      {
        coords: {
          lat: -24.742104,
          lng: 45.341643,
        },
        _id: "64f75285ef8f606073e9b8dd",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "antanimiary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8188948,
          lng: 45.3087733,
        },
        _id: "64f75285ef8f606073e9b8de",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "bevontaky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8207353,
          lng: 45.3510386,
        },
        _id: "64f75285ef8f606073e9b8df",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "ankilivahary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8547441,
          lng: 45.341643,
        },
        _id: "64f75285ef8f606073e9b8e0",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "antanantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8391557,
          lng: 45.0653249,
        },
        _id: "64f75285ef8f606073e9b8e1",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "ankiliroa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8232709,
          lng: 45.09906,
        },
        _id: "64f75285ef8f606073e9b8e2",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "andamilamy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8555766,
          lng: 45.2196725,
        },
        _id: "64f75285ef8f606073e9b8e3",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "boboke marofane",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8443013,
          lng: 45.1775265,
        },
        _id: "64f75285ef8f606073e9b8e4",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "mahasira",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8746719,
          lng: 45.1588073,
        },
        _id: "64f75285ef8f606073e9b8e5",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "kerembola ankilegnano",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8759075,
          lng: 45.2548235,
        },
        _id: "64f75285ef8f606073e9b8e6",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "kerembola I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9076164,
          lng: 45.1962533,
        },
        _id: "64f75285ef8f606073e9b8e7",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "antsakoamitondritsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9237524,
          lng: 45.1400956,
        },
        _id: "64f75285ef8f606073e9b8e8",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "soronampela",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9641067,
          lng: 45.1354189,
        },
        _id: "64f75285ef8f606073e9b8e9",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "razambahoaka saboko",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9747917,
          lng: 45.1634864,
        },
        _id: "64f75285ef8f606073e9b8ea",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "tsimilofo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7910027,
          lng: 45.172846,
        },
        _id: "64f75285ef8f606073e9b8eb",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "tranoroa",
        fokontany: "marohery",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9302114,
          lng: 45.2806177,
        },
        _id: "64f75285ef8f606073e9b8ec",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "behabobo",
        fokontany: "behabobo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8551782,
          lng: 45.2806177,
        },
        _id: "64f75285ef8f606073e9b8ed",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "behabobo",
        fokontany: "sihanadrotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8699737,
          lng: 45.3322493,
        },
        _id: "64f75285ef8f606073e9b8ee",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "behabobo",
        fokontany: "behabobo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9198157,
          lng: 45.3275531,
        },
        _id: "64f75285ef8f606073e9b8ef",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "behabobo",
        fokontany: "analalava",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9542499,
          lng: 45.319969,
        },
        _id: "64f75285ef8f606073e9b8f0",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "behabobo",
        fokontany: "marobasia",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9735478,
          lng: 45.2712363,
        },
        _id: "64f75285ef8f606073e9b8f1",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "behabobo",
        fokontany: "famatalefo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0013101,
          lng: 45.1962533,
        },
        _id: "64f75285ef8f606073e9b8f2",
        province: "toliary",
        region: "androy",
        district: "beloha",
        commune: "behabobo",
        fokontany: "andranolava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3153093,
          lng: 45.4868983,
        },
        _id: "64f75285ef8f606073e9b8f3",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "tsihombe centre",
        __v: 0,
      },
      {
        coords: {
          lat: -25.107787,
          lng: 45.5393476,
        },
        _id: "64f75285ef8f606073e9b8f4",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "ambatomanoy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1340785,
          lng: 45.4969122,
        },
        _id: "64f75285ef8f606073e9b8f5",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "magnorikandro",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1751578,
          lng: 45.6432398,
        },
        _id: "64f75285ef8f606073e9b8f6",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "tsiboatsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.172619,
          lng: 45.586543,
        },
        _id: "64f75285ef8f606073e9b8f7",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "ankilivalo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1326861,
          lng: 45.6054344,
        },
        _id: "64f75285ef8f606073e9b8f8",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "ankilivalo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1437571,
          lng: 45.5723794,
        },
        _id: "64f75285ef8f606073e9b8f9",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1304697,
          lng: 45.3510386,
        },
        _id: "64f75285ef8f606073e9b8fa",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "ambatopilake",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1748899,
          lng: 45.4309775,
        },
        _id: "64f75285ef8f606073e9b8fb",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "agnara",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2728157,
          lng: 45.5157676,
        },
        _id: "64f75285ef8f606073e9b8fc",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "tambanditse ankarandoha",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2475505,
          lng: 45.6479676,
        },
        _id: "64f75285ef8f606073e9b8fd",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "erombaze",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2585951,
          lng: 45.6148829,
        },
        _id: "64f75285ef8f606073e9b8fe",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "tesogno nord",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2845587,
          lng: 45.6337856,
        },
        _id: "64f75285ef8f606073e9b8ff",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "tesogno sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2047465,
          lng: 45.5346307,
        },
        _id: "64f75285ef8f606073e9b900",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "tambanditsy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.258513,
          lng: 45.4780642,
        },
        _id: "64f75285ef8f606073e9b901",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "belemboka centre",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2629932,
          lng: 45.4403911,
        },
        _id: "64f75285ef8f606073e9b902",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "belemboka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2486799,
          lng: 45.4027482,
        },
        _id: "64f75285ef8f606073e9b903",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "ambatobe ankily",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2301087,
          lng: 45.341643,
        },
        _id: "64f75285ef8f606073e9b904",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "anjantsampa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.299365,
          lng: 45.3510386,
        },
        _id: "64f75285ef8f606073e9b905",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "tevoro",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2990045,
          lng: 45.4733534,
        },
        _id: "64f75285ef8f606073e9b906",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "taivo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3315474,
          lng: 45.4662881,
        },
        _id: "64f75285ef8f606073e9b907",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "sihanamena marolava ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3165101,
          lng: 45.479242,
        },
        _id: "64f75285ef8f606073e9b908",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "tsihombe I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3229989,
          lng: 45.4839534,
        },
        _id: "64f75285ef8f606073e9b909",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "tsihombe II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3127076,
          lng: 45.48159769999999,
        },
        _id: "64f75285ef8f606073e9b90a",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "tsihombe avaradrova",
        __v: 0,
      },
      {
        coords: {
          lat: -25.294267,
          lng: 45.5723794,
        },
        _id: "64f75285ef8f606073e9b90b",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "sakamasy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.331223,
          lng: 45.5582201,
        },
        _id: "64f75285ef8f606073e9b90c",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "marotana",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3473359,
          lng: 45.5016253,
        },
        _id: "64f75285ef8f606073e9b90d",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "sihanamena marolava est",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3005605,
          lng: 45.4403911,
        },
        _id: "64f75285ef8f606073e9b90e",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "sihanamena marobey",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3446145,
          lng: 45.4450986,
        },
        _id: "64f75285ef8f606073e9b90f",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "sihanamena marofohy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3150777,
          lng: 45.41686070000001,
        },
        _id: "64f75285ef8f606073e9b910",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "beavoha nord",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2859356,
          lng: 45.380412,
        },
        _id: "64f75285ef8f606073e9b911",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "afondralambo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3282024,
          lng: 45.3651355,
        },
        _id: "64f75285ef8f606073e9b912",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "ankililiry",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3600401,
          lng: 45.31346749999999,
        },
        _id: "64f75285ef8f606073e9b913",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "ambovo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3856071,
          lng: 45.5157676,
        },
        _id: "64f75285ef8f606073e9b914",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "sihanamena marofoty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3445409,
          lng: 45.57473969999999,
        },
        _id: "64f75285ef8f606073e9b915",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "beavoha tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3623442,
          lng: 45.5535013,
        },
        _id: "64f75285ef8f606073e9b916",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "tsidambo besara",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3231471,
          lng: 45.586543,
        },
        _id: "64f75285ef8f606073e9b917",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "zafindravala",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3701803,
          lng: 45.586543,
        },
        _id: "64f75285ef8f606073e9b918",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "marohira sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1505627,
          lng: 45.3792367,
        },
        _id: "64f75285ef8f606073e9b919",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "bevaro",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1753754,
          lng: 45.3087733,
        },
        _id: "64f75285ef8f606073e9b91a",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "anabovo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2086299,
          lng: 45.2853092,
        },
        _id: "64f75285ef8f606073e9b91b",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "anabovo ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1780568,
          lng: 45.3651355,
        },
        _id: "64f75285ef8f606073e9b91c",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "behazomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2126921,
          lng: 45.3698354,
        },
        _id: "64f75285ef8f606073e9b91d",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "anjatoka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2902864,
          lng: 45.3733607,
        },
        _id: "64f75285ef8f606073e9b91e",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "tamonto ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2985213,
          lng: 45.3980449,
        },
        _id: "64f75285ef8f606073e9b91f",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "anabovo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3137092,
          lng: 45.3886399,
        },
        _id: "64f75285ef8f606073e9b920",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "tsihombe",
        fokontany: "marohatake",
        __v: 0,
      },
      {
        coords: {
          lat: -25.387979,
          lng: 45.4356841,
        },
        _id: "64f75285ef8f606073e9b921",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "nikoly centre",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3620203,
          lng: 45.41686070000001,
        },
        _id: "64f75285ef8f606073e9b922",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "marakalo sihanadampy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4155368,
          lng: 45.3604361,
        },
        _id: "64f75285ef8f606073e9b923",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "tamonto nord",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4076333,
          lng: 45.3275531,
        },
        _id: "64f75285ef8f606073e9b924",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "ankilimigahy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3923851,
          lng: 45.3980449,
        },
        _id: "64f75285ef8f606073e9b925",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "marakalo ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4103514,
          lng: 45.4450986,
        },
        _id: "64f75285ef8f606073e9b926",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "namoia",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4001289,
          lng: 45.4921995,
        },
        _id: "64f75285ef8f606073e9b927",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "anatsosa centre",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4211301,
          lng: 45.4733534,
        },
        _id: "64f75285ef8f606073e9b928",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "anatsosa maromainty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4436137,
          lng: 45.4215658,
        },
        _id: "64f75285ef8f606073e9b929",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "marakalo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4479896,
          lng: 45.3839381,
        },
        _id: "64f75285ef8f606073e9b92a",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "tamonto sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3961603,
          lng: 45.6054344,
        },
        _id: "64f75285ef8f606073e9b92b",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "tampototse maromainty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3845075,
          lng: 45.6243333,
        },
        _id: "64f75285ef8f606073e9b92c",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "tampototse imonto",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3916705,
          lng: 45.6432398,
        },
        _id: "64f75285ef8f606073e9b92d",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "ambasia",
        __v: 0,
      },
      {
        coords: {
          lat: -25.408247,
          lng: 45.6621538,
        },
        _id: "64f75285ef8f606073e9b92e",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "behasy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4207968,
          lng: 45.5959878,
        },
        _id: "64f75285ef8f606073e9b92f",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "tampototse nord",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4293177,
          lng: 45.6432398,
        },
        _id: "64f75285ef8f606073e9b930",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "anja tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4431983,
          lng: 45.6054344,
        },
        _id: "64f75285ef8f606073e9b931",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "tampototse sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4606447,
          lng: 45.5771002,
        },
        _id: "64f75285ef8f606073e9b932",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "antanamiare",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4252544,
          lng: 45.5582201,
        },
        _id: "64f75285ef8f606073e9b933",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "analamisandratse",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4144869,
          lng: 45.52991420000001,
        },
        _id: "64f75285ef8f606073e9b934",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "tesingo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -25.440468,
          lng: 45.54878290000001,
        },
        _id: "64f75285ef8f606073e9b935",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "tesingo marofohe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4506325,
          lng: 45.5322724,
        },
        _id: "64f75285ef8f606073e9b936",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "tesingo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4628631,
          lng: 45.5582201,
        },
        _id: "64f75285ef8f606073e9b937",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "nikoly",
        fokontany: "ambanenonoke",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5674027,
          lng: 45.5181251,
        },
        _id: "64f75285ef8f606073e9b938",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "betanty (faux cap)",
        fokontany: "betanty (faux cap centre)",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4536008,
          lng: 45.4969122,
        },
        _id: "64f75285ef8f606073e9b939",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "betanty (faux cap)",
        fokontany: "antalahavalala",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4911814,
          lng: 45.4969122,
        },
        _id: "64f75285ef8f606073e9b93a",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "betanty (faux cap)",
        fokontany: "antavy nord",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4883651,
          lng: 45.4403911,
        },
        _id: "64f75285ef8f606073e9b93b",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "betanty (faux cap)",
        fokontany: "amanda II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5168205,
          lng: 45.508696,
        },
        _id: "64f75285ef8f606073e9b93c",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "betanty (faux cap)",
        fokontany: "ambory andraketalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5273428,
          lng: 45.4686431,
        },
        _id: "64f75285ef8f606073e9b93d",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "betanty (faux cap)",
        fokontany: "bema",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5490174,
          lng: 45.4639333,
        },
        _id: "64f75285ef8f606073e9b93e",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "betanty (faux cap)",
        fokontany: "nanerena sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5294655,
          lng: 45.5110531,
        },
        _id: "64f75285ef8f606073e9b93f",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "betanty (faux cap)",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5490348,
          lng: 45.4027482,
        },
        _id: "64f75285ef8f606073e9b940",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "betanty (faux cap)",
        fokontany: "ambotry",
        __v: 0,
      },
      {
        coords: {
          lat: -25.533333,
          lng: 45.433333,
        },
        _id: "64f75285ef8f606073e9b941",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "betanty (faux cap)",
        fokontany: "benonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5348232,
          lng: 45.5558606,
        },
        _id: "64f75285ef8f606073e9b942",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "betanty (faux cap)",
        fokontany: "ankilimiary",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5482568,
          lng: 45.5110531,
        },
        _id: "64f75285ef8f606073e9b943",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "betanty (faux cap)",
        fokontany: "anovy sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5504424,
          lng: 45.4921995,
        },
        _id: "64f75285ef8f606073e9b944",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "betanty (faux cap)",
        fokontany: "anja haut",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5526383,
          lng: 45.4427448,
        },
        _id: "64f75285ef8f606073e9b945",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "betanty (faux cap)",
        fokontany: "ankilimivory",
        __v: 0,
      },
      {
        coords: {
          lat: -25.489375,
          lng: 45.6526959,
        },
        _id: "64f75285ef8f606073e9b946",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "anjampaly",
        fokontany: "anjampaly centre",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4386155,
          lng: 45.67397889999999,
        },
        _id: "64f75285ef8f606073e9b947",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "anjampaly",
        fokontany: "andavakio",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4575515,
          lng: 45.6432398,
        },
        _id: "64f75285ef8f606073e9b948",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "anjampaly",
        fokontany: "anteny ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4566588,
          lng: 45.690539,
        },
        _id: "64f75285ef8f606073e9b949",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "anjampaly",
        fokontany: "ankara",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4603581,
          lng: 45.66924849999999,
        },
        _id: "64f75285ef8f606073e9b94a",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "anjampaly",
        fokontany: "ampengoky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.487143,
          lng: 45.67161369999999,
        },
        _id: "64f75285ef8f606073e9b94b",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "anjampaly",
        fokontany: "ambarobe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4888559,
          lng: 45.5771002,
        },
        _id: "64f75285ef8f606073e9b94c",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "anjampaly",
        fokontany: "ankatamboalavo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4970761,
          lng: 45.6172453,
        },
        _id: "64f75285ef8f606073e9b94d",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "anjampaly",
        fokontany: "marosarana",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5104214,
          lng: 45.6337856,
        },
        _id: "64f75285ef8f606073e9b94e",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "anjampaly",
        fokontany: "ampary",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4902327,
          lng: 45.6054344,
        },
        _id: "64f75285ef8f606073e9b94f",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "anjampaly",
        fokontany: "anday",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5004694,
          lng: 45.5582201,
        },
        _id: "64f75285ef8f606073e9b950",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "anjampaly",
        fokontany: "ambavatany",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5228702,
          lng: 45.5676592,
        },
        _id: "64f75285ef8f606073e9b951",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "anjampaly",
        fokontany: "andaza",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5394708,
          lng: 45.586543,
        },
        _id: "64f75285ef8f606073e9b952",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "anjampaly",
        fokontany: "tsitindroka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5278575,
          lng: 45.6054344,
        },
        _id: "64f75285ef8f606073e9b953",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "anjampaly",
        fokontany: "antanamanjary",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4667769,
          lng: 45.2618568,
        },
        _id: "64f75285ef8f606073e9b954",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "marovato",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4235284,
          lng: 45.2712363,
        },
        _id: "64f75285ef8f606073e9b955",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "marovato",
        fokontany: "tsinava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3903806,
          lng: 45.2337297,
        },
        _id: "64f75285ef8f606073e9b956",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "marovato",
        fokontany: "ambaro",
        __v: 0,
      },
      {
        coords: {
          lat: -25.472538,
          lng: 45.2524792,
        },
        _id: "64f75285ef8f606073e9b957",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "marovato",
        fokontany: "ambanikily volakira",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4898542,
          lng: 45.2853092,
        },
        _id: "64f75285ef8f606073e9b958",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "marovato",
        fokontany: "marolava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5142898,
          lng: 45.21498769999999,
        },
        _id: "64f75285ef8f606073e9b959",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "marovato",
        fokontany: "ambanikily ambanifaty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4761608,
          lng: 45.3228575,
        },
        _id: "64f75285ef8f606073e9b95a",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "marovato",
        fokontany: "betsingivy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5316861,
          lng: 45.3087733,
        },
        _id: "64f75285ef8f606073e9b95b",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "marovato",
        fokontany: "soramena",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5569525,
          lng: 45.3439917,
        },
        _id: "64f75285ef8f606073e9b95c",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "marovato",
        fokontany: "belitsaky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5078977,
          lng: 45.3322493,
        },
        _id: "64f75285ef8f606073e9b95d",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "marovato",
        fokontany: "amboandelaka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5302616,
          lng: 45.341643,
        },
        _id: "64f75285ef8f606073e9b95e",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "marovato",
        fokontany: "antseta",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5468614,
          lng: 45.3604361,
        },
        _id: "64f75285ef8f606073e9b95f",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "marovato",
        fokontany: "ambazoa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5222574,
          lng: 45.2477911,
        },
        _id: "64f75285ef8f606073e9b960",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "marovato",
        fokontany: "mantara",
        __v: 0,
      },
      {
        coords: {
          lat: -25.511316,
          lng: 45.1588073,
        },
        _id: "64f75285ef8f606073e9b961",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "marovato",
        fokontany: "mionjona",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5415051,
          lng: 45.1400956,
        },
        _id: "64f75285ef8f606073e9b962",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "marovato",
        fokontany: "vohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5437596,
          lng: 45.1822075,
        },
        _id: "64f75285ef8f606073e9b963",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "marovato",
        fokontany: "betaimboraka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5819532,
          lng: 45.1962533,
        },
        _id: "64f75285ef8f606073e9b964",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "marovato",
        fokontany: "befeha",
        __v: 0,
      },
      {
        coords: {
          lat: -25.5662191,
          lng: 45.2524792,
        },
        _id: "64f75285ef8f606073e9b965",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "marovato",
        fokontany: "bevala",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3949538,
          lng: 45.7562448,
        },
        _id: "64f75285ef8f606073e9b966",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "antaritarika centrale",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3110977,
          lng: 45.8042495,
        },
        _id: "64f75285ef8f606073e9b967",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "antsakoamanga",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3423467,
          lng: 45.6621538,
        },
        _id: "64f75285ef8f606073e9b968",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "tsianoha ihodo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3374551,
          lng: 45.6243333,
        },
        _id: "64f75285ef8f606073e9b969",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "ankilimikaiky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3475378,
          lng: 45.7757965,
        },
        _id: "64f75285ef8f606073e9b96a",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "ambavatany",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3521015,
          lng: 45.7378854,
        },
        _id: "64f75285ef8f606073e9b96b",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "anjira",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3709468,
          lng: 45.7378854,
        },
        _id: "64f75285ef8f606073e9b96c",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "androvamare",
        __v: 0,
      },
      {
        coords: {
          lat: -25.386215,
          lng: 45.7284124,
        },
        _id: "64f75285ef8f606073e9b96d",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "anakania",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3676722,
          lng: 45.6668835,
        },
        _id: "64f75285ef8f606073e9b96e",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "bemozotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3735444,
          lng: 45.72604440000001,
        },
        _id: "64f75285ef8f606073e9b96f",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "andraketalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3793891,
          lng: 45.7165737,
        },
        _id: "64f75285ef8f606073e9b970",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "anakanimo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.381325,
          lng: 45.690539,
        },
        _id: "64f75285ef8f606073e9b971",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "fanarano",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3906073,
          lng: 45.7556525,
        },
        _id: "64f75285ef8f606073e9b972",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "mokabe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4076728,
          lng: 45.78527889999999,
        },
        _id: "64f75285ef8f606073e9b973",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "ambaromanoy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4143309,
          lng: 45.7592067,
        },
        _id: "64f75285ef8f606073e9b974",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "antaritarika II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3946662,
          lng: 45.7414383,
        },
        _id: "64f75285ef8f606073e9b975",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "antaritarika maromainty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3997046,
          lng: 45.7485448,
        },
        _id: "64f75285ef8f606073e9b976",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "afotsifaly ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3888185,
          lng: 45.78527889999999,
        },
        _id: "64f75285ef8f606073e9b977",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "afotsifaly est",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4235685,
          lng: 45.7213088,
        },
        _id: "64f75285ef8f606073e9b978",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "ambonaivo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4108986,
          lng: 45.7189412,
        },
        _id: "64f75285ef8f606073e9b979",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "andranopoly",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4252162,
          lng: 45.7568372,
        },
        _id: "64f75285ef8f606073e9b97a",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "maroafo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4352103,
          lng: 45.7100637,
        },
        _id: "64f75285ef8f606073e9b97b",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "betsiporitse bas",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4287424,
          lng: 45.697638,
        },
        _id: "64f75285ef8f606073e9b97c",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "ambohitse",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4459908,
          lng: 45.7307804,
        },
        _id: "64f75285ef8f606073e9b97d",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "talaky haut",
        __v: 0,
      },
      {
        coords: {
          lat: -25.4376983,
          lng: 45.7213088,
        },
        _id: "64f75285ef8f606073e9b97e",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "antaritarika",
        fokontany: "talaky bas",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2955624,
          lng: 45.7378854,
        },
        _id: "64f75285ef8f606073e9b97f",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "imongy",
        fokontany: "imongy central",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2429782,
          lng: 45.685807,
        },
        _id: "64f75285ef8f606073e9b980",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "imongy",
        fokontany: "ankilibe nord",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2443176,
          lng: 45.5771002,
        },
        _id: "64f75285ef8f606073e9b981",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "imongy",
        fokontany: "tomily",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2758843,
          lng: 45.7544678,
        },
        _id: "64f75285ef8f606073e9b982",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "imongy",
        fokontany: "ambonaivo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2795574,
          lng: 45.8018778,
        },
        _id: "64f75285ef8f606073e9b983",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "imongy",
        fokontany: "antsakoamanga bas",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2851098,
          lng: 45.78527889999999,
        },
        _id: "64f75285ef8f606073e9b984",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "imongy",
        fokontany: "marobe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.263727,
          lng: 45.7284124,
        },
        _id: "64f75285ef8f606073e9b985",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "imongy",
        fokontany: "fekony",
        __v: 0,
      },
      {
        coords: {
          lat: -25.322208,
          lng: 45.6337856,
        },
        _id: "64f75285ef8f606073e9b986",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "imongy",
        fokontany: "andramirava tsianoha",
        __v: 0,
      },
      {
        coords: {
          lat: -25.295271,
          lng: 45.6621538,
        },
        _id: "64f75285ef8f606073e9b987",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "imongy",
        fokontany: "andramirava centre",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3067966,
          lng: 45.67397889999999,
        },
        _id: "64f75285ef8f606073e9b988",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "imongy",
        fokontany: "andramirava II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2848613,
          lng: 45.7094719,
        },
        _id: "64f75285ef8f606073e9b989",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "imongy",
        fokontany: "imongy I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3000842,
          lng: 45.7686858,
        },
        _id: "64f75285ef8f606073e9b98a",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "imongy",
        fokontany: "ambatovato centre",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3088733,
          lng: 45.7544678,
        },
        _id: "64f75285ef8f606073e9b98b",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "imongy",
        fokontany: "ambatovato makifaha",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3072156,
          lng: 45.7876499,
        },
        _id: "64f75285ef8f606073e9b98c",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "imongy",
        fokontany: "ankilimalangy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3307871,
          lng: 45.7876499,
        },
        _id: "64f75285ef8f606073e9b98d",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "imongy",
        fokontany: "antsakoamanga centre",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3215468,
          lng: 45.7568372,
        },
        _id: "64f75285ef8f606073e9b98e",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "imongy",
        fokontany: "andamoty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3066278,
          lng: 45.704738,
        },
        _id: "64f75285ef8f606073e9b98f",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "imongy",
        fokontany: "andratino",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3238326,
          lng: 45.7378854,
        },
        _id: "64f75285ef8f606073e9b990",
        province: "toliary",
        region: "androy",
        district: "tsihombe",
        commune: "imongy",
        fokontany: "ambaromanintsy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1801382,
          lng: 46.09567759999999,
        },
        _id: "64f75285ef8f606073e9b991",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "anjatoka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1687634,
          lng: 46.0873266,
        },
        _id: "64f75285ef8f606073e9b992",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "andaboly",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1819287,
          lng: 46.0813625,
        },
        _id: "64f75285ef8f606073e9b993",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "ambaro",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1767087,
          lng: 46.08881770000001,
        },
        _id: "64f75285ef8f606073e9b994",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "avaradrova",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1628361,
          lng: 46.0968707,
        },
        _id: "64f75285ef8f606073e9b995",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "beabo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1788421,
          lng: 46.06347450000001,
        },
        _id: "64f75285ef8f606073e9b996",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "esingo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1733269,
          lng: 46.1028367,
        },
        _id: "64f75285ef8f606073e9b997",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "antanambao III",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1768164,
          lng: 46.0915019,
        },
        _id: "64f75285ef8f606073e9b998",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "antanambao I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1763134,
          lng: 46.07897699999999,
        },
        _id: "64f75285ef8f606073e9b999",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "berary",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1955255,
          lng: 46.0861337,
        },
        _id: "64f75285ef8f606073e9b99a",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "mitsangana II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1936113,
          lng: 46.0777843,
        },
        _id: "64f75285ef8f606073e9b99b",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "mitsangana I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1869478,
          lng: 46.0885195,
        },
        _id: "64f75285ef8f606073e9b99c",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "mahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1882118,
          lng: 46.1398424,
        },
        _id: "64f75285ef8f606073e9b99d",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "amboasary II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1732681,
          lng: 46.1410366,
        },
        _id: "64f75285ef8f606073e9b99e",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "ambolomariagne",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2216361,
          lng: 46.1470081,
        },
        _id: "64f75285ef8f606073e9b99f",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "ambazoamirafy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2426664,
          lng: 46.1207392,
        },
        _id: "64f75285ef8f606073e9b9a0",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "esanta fototra",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2190349,
          lng: 46.08255519999999,
        },
        _id: "64f75285ef8f606073e9b9a1",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "ambaniavoha",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1799425,
          lng: 46.1302899,
        },
        _id: "64f75285ef8f606073e9b9a2",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "ebana",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1853005,
          lng: 46.1064167,
        },
        _id: "64f75285ef8f606073e9b9a3",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "ankilimafaitse bas",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2675236,
          lng: 46.0730139,
        },
        _id: "64f75285ef8f606073e9b9a4",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "beanike I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2855281,
          lng: 46.0134234,
        },
        _id: "64f75285ef8f606073e9b9a5",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "amborobe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2557,
          lng: 46.053937,
        },
        _id: "64f75285ef8f606073e9b9a6",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "beanike II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.249788,
          lng: 46.06347450000001,
        },
        _id: "64f75285ef8f606073e9b9a7",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "beroroha",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2087008,
          lng: 46.06109,
        },
        _id: "64f75285ef8f606073e9b9a8",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "antetebey",
        __v: 0,
      },
      {
        coords: {
          lat: -25.232039,
          lng: 46.0920984,
        },
        _id: "64f75285ef8f606073e9b9a9",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "tsirangoty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2214591,
          lng: 46.1034333,
        },
        _id: "64f75285ef8f606073e9b9aa",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "tsingivilahy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2284685,
          lng: 46.1207392,
        },
        _id: "64f75285ef8f606073e9b9ab",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "esanta maromainty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2678151,
          lng: 46.0420177,
        },
        _id: "64f75285ef8f606073e9b9ac",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "ambanefengoke",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2751979,
          lng: 46.0301014,
        },
        _id: "64f75285ef8f606073e9b9ad",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "bekokako",
        __v: 0,
      },
      {
        coords: {
          lat: -25.116667,
          lng: 46.1,
        },
        _id: "64f75285ef8f606073e9b9ae",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "sarihangy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1265754,
          lng: 46.1780715,
        },
        _id: "64f75285ef8f606073e9b9af",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "behabobo II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0916502,
          lng: 46.0515529,
        },
        _id: "64f75285ef8f606073e9b9b0",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "ankilifaly",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9502824,
          lng: 46.0658592,
        },
        _id: "64f75285ef8f606073e9b9b1",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "milahane fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8435397,
          lng: 45.99437,
        },
        _id: "64f75285ef8f606073e9b9b2",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "andamilamy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.886484,
          lng: 46.0467851,
        },
        _id: "64f75285ef8f606073e9b9b3",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "sarisanga",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0619755,
          lng: 46.09925699999999,
        },
        _id: "64f75285ef8f606073e9b9b4",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "varesoa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0630813,
          lng: 46.127902,
        },
        _id: "64f75285ef8f606073e9b9b5",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "marofanogne",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1347918,
          lng: 46.1458137,
        },
        _id: "64f75285ef8f606073e9b9b6",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "ankilivinonjy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1426361,
          lng: 46.1446194,
        },
        _id: "64f75285ef8f606073e9b9b7",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "antsatrahamasy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2453363,
          lng: 46.1088035,
        },
        _id: "64f75285ef8f606073e9b9b8",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "esalo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2179737,
          lng: 46.114771,
        },
        _id: "64f75285ef8f606073e9b9b9",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "anafondrakady",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2050919,
          lng: 46.127902,
        },
        _id: "64f75285ef8f606073e9b9ba",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "maromalay",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2286293,
          lng: 46.1052233,
        },
        _id: "64f75285ef8f606073e9b9bb",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "esanta III",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2319533,
          lng: 46.1094002,
        },
        _id: "64f75285ef8f606073e9b9bc",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "esanta marofoty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2354526,
          lng: 46.0598977,
        },
        _id: "64f75285ef8f606073e9b9bd",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "antseky nord",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2394452,
          lng: 46.0420177,
        },
        _id: "64f75285ef8f606073e9b9be",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "antseky sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.116667,
          lng: 46.1666669,
        },
        _id: "64f75285ef8f606073e9b9bf",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "tsianoriha",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1279692,
          lng: 46.1529803,
        },
        _id: "64f75285ef8f606073e9b9c0",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "behabobo I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1577581,
          lng: 46.127902,
        },
        _id: "64f75285ef8f606073e9b9c1",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "ankaka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1584411,
          lng: 46.0658592,
        },
        _id: "64f75285ef8f606073e9b9c2",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "ankaramena",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1959583,
          lng: 46.0968707,
        },
        _id: "64f75285ef8f606073e9b9c3",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "ambolobe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1949085,
          lng: 46.1099969,
        },
        _id: "64f75285ef8f606073e9b9c4",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "ankilimafaitse haut",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1833637,
          lng: 46.1171582,
        },
        _id: "64f75285ef8f606073e9b9c5",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "lavandrandra",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1913705,
          lng: 46.1004502,
        },
        _id: "64f75285ef8f606073e9b9c6",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "sevohipoty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1139496,
          lng: 46.1374541,
        },
        _id: "64f75285ef8f606073e9b9c7",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "tsialihe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0920405,
          lng: 46.1422309,
        },
        _id: "64f75285ef8f606073e9b9c8",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "bevato",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2438739,
          lng: 46.0730139,
        },
        _id: "64f75285ef8f606073e9b9c9",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "tranobe maromainty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2314553,
          lng: 46.0777843,
        },
        _id: "64f75285ef8f606073e9b9ca",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "ekonka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2063229,
          lng: 46.0801697,
        },
        _id: "64f75285ef8f606073e9b9cb",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "talaky centrale",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2071913,
          lng: 46.1016434,
        },
        _id: "64f75285ef8f606073e9b9cc",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "talaky marofoty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2173952,
          lng: 46.1004502,
        },
        _id: "64f75285ef8f606073e9b9cd",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "marolava",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9832752,
          lng: 46.10402999999999,
        },
        _id: "64f75285ef8f606073e9b9ce",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambovombe",
        fokontany: "botreoke",
        __v: 0,
      },
      {
        coords: {
          lat: -25.217091,
          lng: 46.024899,
        },
        _id: "64f75285ef8f606073e9b9cf",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "tsimananada",
        fokontany: "tsimananada",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2016169,
          lng: 46.0420177,
        },
        _id: "64f75285ef8f606073e9b9d0",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "tsimananada",
        fokontany: "tsimihevo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2323531,
          lng: 46.022953,
        },
        _id: "64f75285ef8f606073e9b9d1",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "tsimananada",
        fokontany: "marohafotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2406178,
          lng: 45.99437,
        },
        _id: "64f75285ef8f606073e9b9d2",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "tsimananada",
        fokontany: "mokofo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2477145,
          lng: 46.0134234,
        },
        _id: "64f75285ef8f606073e9b9d3",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "tsimananada",
        fokontany: "ankilirandro nord",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2798576,
          lng: 45.9539064,
        },
        _id: "64f75285ef8f606073e9b9d4",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "erada",
        fokontany: "erada II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2804154,
          lng: 45.9301201,
        },
        _id: "64f75285ef8f606073e9b9d5",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "erada",
        fokontany: "habohabo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2755666,
          lng: 45.9455799,
        },
        _id: "64f75285ef8f606073e9b9d6",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "erada",
        fokontany: "habohabo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2739641,
          lng: 45.9634242,
        },
        _id: "64f75285ef8f606073e9b9d7",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "erada",
        fokontany: "mitreaky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2568436,
          lng: 45.96818380000001,
        },
        _id: "64f75285ef8f606073e9b9d8",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "erada",
        fokontany: "ankaragnabo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2527204,
          lng: 45.9824654,
        },
        _id: "64f75285ef8f606073e9b9d9",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "erada",
        fokontany: "nisorona",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2698446,
          lng: 45.9777044,
        },
        _id: "64f75285ef8f606073e9b9da",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "erada",
        fokontany: "belaza",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2705978,
          lng: 45.9955606,
        },
        _id: "64f75285ef8f606073e9b9db",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "erada",
        fokontany: "vohibaoe I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2753235,
          lng: 45.9955606,
        },
        _id: "64f75285ef8f606073e9b9dc",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "erada",
        fokontany: "ambory I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2878749,
          lng: 45.99437,
        },
        _id: "64f75285ef8f606073e9b9dd",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "erada",
        fokontany: "ambory II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2785571,
          lng: 45.9788946,
        },
        _id: "64f75285ef8f606073e9b9de",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "erada",
        fokontany: "anketa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2952454,
          lng: 45.9824654,
        },
        _id: "64f75285ef8f606073e9b9df",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "erada",
        fokontany: "ankazomagnitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2993615,
          lng: 45.96818380000001,
        },
        _id: "64f75285ef8f606073e9b9e0",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "erada",
        fokontany: "belitsaky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3020019,
          lng: 45.9562857,
        },
        _id: "64f75285ef8f606073e9b9e1",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "erada",
        fokontany: "erada I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2903189,
          lng: 45.9408225,
        },
        _id: "64f75285ef8f606073e9b9e2",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "erada",
        fokontany: "erada III",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3291061,
          lng: 45.9277422,
        },
        _id: "64f75285ef8f606073e9b9e3",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "erada",
        fokontany: "ambanikily sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3173366,
          lng: 45.9467693,
        },
        _id: "64f75285ef8f606073e9b9e4",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "erada",
        fokontany: "ambanikily nord",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0235438,
          lng: 46.2522257,
        },
        _id: "64f75285ef8f606073e9b9e5",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "beanatara I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9659488,
          lng: 46.1470081,
        },
        _id: "64f75285ef8f606073e9b9e6",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9973403,
          lng: 46.1422309,
        },
        _id: "64f75285ef8f606073e9b9e7",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "tobodriha",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0049087,
          lng: 46.1756813,
        },
        _id: "64f75285ef8f606073e9b9e8",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "ankilivalo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0287269,
          lng: 46.1374541,
        },
        _id: "64f75285ef8f606073e9b9e9",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "anjeky ankilivalo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0323801,
          lng: 46.1924151,
        },
        _id: "64f75285ef8f606073e9b9ea",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "amboroky I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0418556,
          lng: 46.1924151,
        },
        _id: "64f75285ef8f606073e9b9eb",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "amboroke II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0771745,
          lng: 46.1661217,
        },
        _id: "64f75285ef8f606073e9b9ec",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "ambaliandro marobey I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0637834,
          lng: 46.1876334,
        },
        _id: "64f75285ef8f606073e9b9ed",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "marovaho",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0614828,
          lng: 46.2103506,
        },
        _id: "64f75285ef8f606073e9b9ee",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "marolava I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0474015,
          lng: 46.2139385,
        },
        _id: "64f75285ef8f606073e9b9ef",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "anjeky erada",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0259972,
          lng: 46.2330783,
        },
        _id: "64f75285ef8f606073e9b9f0",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "antanimiary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.997557,
          lng: 46.2330783,
        },
        _id: "64f75285ef8f606073e9b9f1",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "erada popiner",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0305699,
          lng: 46.2713806,
        },
        _id: "64f75285ef8f606073e9b9f2",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "analavelo bearivo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0593395,
          lng: 46.2480366,
        },
        _id: "64f75285ef8f606073e9b9f3",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "beanantara II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0641845,
          lng: 46.2402577,
        },
        _id: "64f75285ef8f606073e9b9f4",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "beanatara sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0587549,
          lng: 46.2641966,
        },
        _id: "64f75285ef8f606073e9b9f5",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "anjeky enikonty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0650932,
          lng: 46.2540211,
        },
        _id: "64f75285ef8f606073e9b9f6",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "ankilikira I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0934346,
          lng: 46.2618022,
        },
        _id: "64f75285ef8f606073e9b9f7",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "ankilikira sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0731883,
          lng: 46.248635,
        },
        _id: "64f75285ef8f606073e9b9f8",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "ankilikira II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0683862,
          lng: 46.2259,
        },
        _id: "64f75285ef8f606073e9b9f9",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "anjeky behevotse",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0743446,
          lng: 46.2163305,
        },
        _id: "64f75285ef8f606073e9b9fa",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "enjoty behabobo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0641906,
          lng: 46.19839289999999,
        },
        _id: "64f75285ef8f606073e9b9fb",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "marofoty avaradrova",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0214146,
          lng: 46.1948061,
        },
        _id: "64f75285ef8f606073e9b9fc",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "anjeky maroho",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0863947,
          lng: 46.2426511,
        },
        _id: "64f75285ef8f606073e9b9fd",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "analavelo ambaro",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0947881,
          lng: 46.2139385,
        },
        _id: "64f75285ef8f606073e9b9fe",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "anjeky ankilikira",
        fokontany: "marolava II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8973055,
          lng: 45.9991326,
        },
        _id: "64f75285ef8f606073e9b9ff",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambanisarika",
        fokontany: "ambanisarika",
        __v: 0,
      },
      {
        coords: {
          lat: -24.793607,
          lng: 45.92298659999999,
        },
        _id: "64f75285ef8f606073e9ba00",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambanisarika",
        fokontany: "retoka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7708632,
          lng: 45.98960779999999,
        },
        _id: "64f75285ef8f606073e9ba01",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambanisarika",
        fokontany: "ankilemara",
        __v: 0,
      },
      {
        coords: {
          lat: -25.018937,
          lng: 46.0467851,
        },
        _id: "64f75285ef8f606073e9ba02",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambanisarika",
        fokontany: "ankako",
        __v: 0,
      },
      {
        coords: {
          lat: -25.15,
          lng: 46.033333,
        },
        _id: "64f75285ef8f606073e9ba03",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambanisarika",
        fokontany: "androhondroho",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1543286,
          lng: 46.0420177,
        },
        _id: "64f75285ef8f606073e9ba04",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambanisarika",
        fokontany: "sifiry antanantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.185077,
          lng: 46.022953,
        },
        _id: "64f75285ef8f606073e9ba05",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambanisarika",
        fokontany: "antsakoamamy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1839051,
          lng: 45.99437,
        },
        _id: "64f75285ef8f606073e9ba06",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambanisarika",
        fokontany: "marofoty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2101927,
          lng: 45.9824654,
        },
        _id: "64f75285ef8f606073e9ba07",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambanisarika",
        fokontany: "ambolimoka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1985246,
          lng: 45.9669938,
        },
        _id: "64f75285ef8f606073e9ba08",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambanisarika",
        fokontany: "androvasoa mitreaky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2060517,
          lng: 45.958665,
        },
        _id: "64f75285ef8f606073e9ba09",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambanisarika",
        fokontany: "etsoha marofoty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2172844,
          lng: 46.0015141,
        },
        _id: "64f75285ef8f606073e9ba0a",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambanisarika",
        fokontany: "mahaloto",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2113717,
          lng: 45.9729438,
        },
        _id: "64f75285ef8f606073e9ba0b",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambanisarika",
        fokontany: "miarintsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1523132,
          lng: 45.9158541,
        },
        _id: "64f75285ef8f606073e9ba0c",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "analamary",
        fokontany: "ankazomagnitse",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0950394,
          lng: 45.9396332,
        },
        _id: "64f75285ef8f606073e9ba0d",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "analamary",
        fokontany: "analasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0640624,
          lng: 45.9134768,
        },
        _id: "64f75285ef8f606073e9ba0e",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "analamary",
        fokontany: "andoharano",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1387358,
          lng: 45.9301201,
        },
        _id: "64f75285ef8f606073e9ba0f",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "analamary",
        fokontany: "homelatse",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1543708,
          lng: 45.8897105,
        },
        _id: "64f75285ef8f606073e9ba10",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "analamary",
        fokontany: "ankilimalangy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1436063,
          lng: 45.9146654,
        },
        _id: "64f75285ef8f606073e9ba11",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "analamary",
        fokontany: "magnindra",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1700246,
          lng: 45.9253643,
        },
        _id: "64f75285ef8f606073e9ba12",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "analamary",
        fokontany: "ankilimivory",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1195533,
          lng: 45.8849587,
        },
        _id: "64f75285ef8f606073e9ba13",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "analamary",
        fokontany: "analamary",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1334238,
          lng: 45.9158541,
        },
        _id: "64f75285ef8f606073e9ba14",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "analamary",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1642674,
          lng: 45.9194202,
        },
        _id: "64f75285ef8f606073e9ba15",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "analamary",
        fokontany: "marolava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0941792,
          lng: 45.8802073,
        },
        _id: "64f75285ef8f606073e9ba16",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "analamary",
        fokontany: "anjamalinike",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1393261,
          lng: 45.9063457,
        },
        _id: "64f75285ef8f606073e9ba17",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "analamary",
        fokontany: "ankilitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1877385,
          lng: 45.9348765,
        },
        _id: "64f75285ef8f606073e9ba18",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "analamary",
        fokontany: "afondrakady",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2008807,
          lng: 45.9479587,
        },
        _id: "64f75285ef8f606073e9ba19",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "analamary",
        fokontany: "anafondravoay",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1177802,
          lng: 45.9182314,
        },
        _id: "64f75285ef8f606073e9ba1a",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "analamary",
        fokontany: "ankileroa marolava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1254523,
          lng: 45.8754564,
        },
        _id: "64f75285ef8f606073e9ba1b",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "analamary",
        fokontany: "ankilisana",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0991812,
          lng: 45.9253643,
        },
        _id: "64f75285ef8f606073e9ba1c",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "analamary",
        fokontany: "mahatomotse",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1224889,
          lng: 45.9562857,
        },
        _id: "64f75285ef8f606073e9ba1d",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambohimalaza",
        fokontany: "bevoalavo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1023614,
          lng: 46.0038958,
        },
        _id: "64f75285ef8f606073e9ba1e",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambohimalaza",
        fokontany: "taty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0474609,
          lng: 45.97056380000001,
        },
        _id: "64f75285ef8f606073e9ba1f",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambohimalaza",
        fokontany: "ampanolora",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0237561,
          lng: 46.0086594,
        },
        _id: "64f75285ef8f606073e9ba20",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambohimalaza",
        fokontany: "betioky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1307246,
          lng: 46.0038958,
        },
        _id: "64f75285ef8f606073e9ba21",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambohimalaza",
        fokontany: "ankaramena",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0752412,
          lng: 45.9562857,
        },
        _id: "64f75285ef8f606073e9ba22",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambohimalaza",
        fokontany: "ankatrafae mazava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1047461,
          lng: 45.9848461,
        },
        _id: "64f75285ef8f606073e9ba23",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambohimalaza",
        fokontany: "taviramongy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9306973,
          lng: 45.8849587,
        },
        _id: "64f75285ef8f606073e9ba24",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambohimalaza",
        fokontany: "sakavey",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1579182,
          lng: 45.97532409999999,
        },
        _id: "64f75285ef8f606073e9ba25",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambohimalaza",
        fokontany: "kobaimirafy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1331031,
          lng: 45.9848461,
        },
        _id: "64f75285ef8f606073e9ba26",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambohimalaza",
        fokontany: "androvasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1968856,
          lng: 45.9277422,
        },
        _id: "64f75285ef8f606073e9ba27",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambonaivo",
        fokontany: "nagnalo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.232321,
          lng: 45.9467693,
        },
        _id: "64f75285ef8f606073e9ba28",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambonaivo",
        fokontany: "beratro I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2379145,
          lng: 45.9301201,
        },
        _id: "64f75285ef8f606073e9ba29",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambonaivo",
        fokontany: "beratro II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2402544,
          lng: 45.9110996,
        },
        _id: "64f75285ef8f606073e9ba2a",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambonaivo",
        fokontany: "ankazoabo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2609172,
          lng: 45.9158541,
        },
        _id: "64f75285ef8f606073e9ba2b",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambonaivo",
        fokontany: "ankarandoha",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2691936,
          lng: 45.9253643,
        },
        _id: "64f75285ef8f606073e9ba2c",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambonaivo",
        fokontany: "ambokoka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2520818,
          lng: 45.9301201,
        },
        _id: "64f75285ef8f606073e9ba2d",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambonaivo",
        fokontany: "belaletsy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2633033,
          lng: 45.9348765,
        },
        _id: "64f75285ef8f606073e9ba2e",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambonaivo",
        fokontany: "marofohy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2668571,
          lng: 45.9443905,
        },
        _id: "64f75285ef8f606073e9ba2f",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambonaivo",
        fokontany: "analavao",
        __v: 0,
      },
      {
        coords: {
          lat: -25.25624,
          lng: 45.9539064,
        },
        _id: "64f75285ef8f606073e9ba30",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambonaivo",
        fokontany: "marolava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2450171,
          lng: 45.9491482,
        },
        _id: "64f75285ef8f606073e9ba31",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambonaivo",
        fokontany: "bealoka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2408954,
          lng: 45.9634242,
        },
        _id: "64f75285ef8f606073e9ba32",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambonaivo",
        fokontany: "nandrosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2320453,
          lng: 45.9777044,
        },
        _id: "64f75285ef8f606073e9ba33",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambonaivo",
        fokontany: "sihanamaie",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2267226,
          lng: 45.9634242,
        },
        _id: "64f75285ef8f606073e9ba34",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambonaivo",
        fokontany: "maromainty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2184501,
          lng: 45.9539064,
        },
        _id: "64f75285ef8f606073e9ba35",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambonaivo",
        fokontany: "marofoty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1950019,
          lng: 46.1918174,
        },
        _id: "64f75285ef8f606073e9ba36",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "erakoka atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1991112,
          lng: 46.1909208,
        },
        _id: "64f75285ef8f606073e9ba37",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "ambonaivo ampihamibe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2009581,
          lng: 46.192714,
        },
        _id: "64f75285ef8f606073e9ba38",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "zanavo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -25.188569,
          lng: 46.1888288,
        },
        _id: "64f75285ef8f606073e9ba39",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "erakoka atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2088575,
          lng: 46.1828522,
        },
        _id: "64f75285ef8f606073e9ba3a",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "ambolovohitse erako",
        __v: 0,
      },
      {
        coords: {
          lat: -25.197335,
          lng: 46.1909208,
        },
        _id: "64f75285ef8f606073e9ba3b",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "ankilitsimagnarito",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1855988,
          lng: 46.1936106,
        },
        _id: "64f75285ef8f606073e9ba3c",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "maroho",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1962749,
          lng: 46.18404750000001,
        },
        _id: "64f75285ef8f606073e9ba3d",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "ianakafy marosola",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1798766,
          lng: 46.1685114,
        },
        _id: "64f75285ef8f606073e9ba3e",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "antsomontsoy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1779686,
          lng: 46.1601479,
        },
        _id: "64f75285ef8f606073e9ba3f",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "analamitsetake",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1525794,
          lng: 46.1971973,
        },
        _id: "64f75285ef8f606073e9ba40",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "ankilimanintsy II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1564787,
          lng: 46.1756813,
        },
        _id: "64f75285ef8f606073e9ba41",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "betsimeda",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1804403,
          lng: 46.1828522,
        },
        _id: "64f75285ef8f606073e9ba42",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "ankilihogo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1923695,
          lng: 46.17508369999999,
        },
        _id: "64f75285ef8f606073e9ba43",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "ankilimiare",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2289995,
          lng: 46.1732912,
        },
        _id: "64f75285ef8f606073e9ba44",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "zanavo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2094877,
          lng: 46.1589532,
        },
        _id: "64f75285ef8f606073e9ba45",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "ankilimaroaloka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1988185,
          lng: 46.1685114,
        },
        _id: "64f75285ef8f606073e9ba46",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "vahavola centre",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2092826,
          lng: 46.1936106,
        },
        _id: "64f75285ef8f606073e9ba47",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "erakoka anjatoka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1982806,
          lng: 46.18987480000001,
        },
        _id: "64f75285ef8f606073e9ba48",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "ambaro mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1998006,
          lng: 46.1883805,
        },
        _id: "64f75285ef8f606073e9ba49",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "erakoka andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1998797,
          lng: 46.1954039,
        },
        _id: "64f75285ef8f606073e9ba4a",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "marobe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1525329,
          lng: 46.1858404,
        },
        _id: "64f75285ef8f606073e9ba4b",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "marolava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2183297,
          lng: 46.1828522,
        },
        _id: "64f75285ef8f606073e9ba4c",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "volakira ambatoabo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1905481,
          lng: 46.1589532,
        },
        _id: "64f75285ef8f606073e9ba4d",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "vahavola ampihamibe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1513707,
          lng: 46.206763,
        },
        _id: "64f75285ef8f606073e9ba4e",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "ankilimanintsy I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1407844,
          lng: 46.1780715,
        },
        _id: "64f75285ef8f606073e9ba4f",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "beabobo ampatiolotse",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1791695,
          lng: 46.15059129999999,
        },
        _id: "64f75285ef8f606073e9ba50",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "mareagne",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1865203,
          lng: 46.1768764,
        },
        _id: "64f75285ef8f606073e9ba51",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "radabetsimivaky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1588872,
          lng: 46.15656389999999,
        },
        _id: "64f75285ef8f606073e9ba52",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalomainty",
        fokontany: "vahavola ankilisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1738902,
          lng: 46.2582108,
        },
        _id: "64f75285ef8f606073e9ba53",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "amboasary I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1807239,
          lng: 46.2510288,
        },
        _id: "64f75285ef8f606073e9ba54",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "ambaro I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1566634,
          lng: 46.221115,
        },
        _id: "64f75285ef8f606073e9ba55",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "ampanavy fototsambo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1625081,
          lng: 46.2498319,
        },
        _id: "64f75285ef8f606073e9ba56",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "analamitsetake est",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1938688,
          lng: 46.2031756,
        },
        _id: "64f75285ef8f606073e9ba57",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "ankilimieva",
        __v: 0,
      },
      {
        coords: {
          lat: -25.191038,
          lng: 46.2115465,
        },
        _id: "64f75285ef8f606073e9ba58",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "ankilivinonjy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1119918,
          lng: 46.2510288,
        },
        _id: "64f75285ef8f606073e9ba59",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "ankobo analamizitse",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2099888,
          lng: 46.2115465,
        },
        _id: "64f75285ef8f606073e9ba5a",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "behanta beraketa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1837914,
          lng: 46.2079588,
        },
        _id: "64f75285ef8f606073e9ba5b",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "belemboka ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2064544,
          lng: 46.2019799,
        },
        _id: "64f75285ef8f606073e9ba5c",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "belemboka ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1731038,
          lng: 46.2480366,
        },
        _id: "64f75285ef8f606073e9ba5d",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "magneva",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1801185,
          lng: 46.2558167,
        },
        _id: "64f75285ef8f606073e9ba5e",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "maroalopoty I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.175048,
          lng: 46.2677884,
        },
        _id: "64f75285ef8f606073e9ba5f",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "maroalopoty II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1826775,
          lng: 46.2402577,
        },
        _id: "64f75285ef8f606073e9ba60",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "maropia ankilibe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1252557,
          lng: 46.2259,
        },
        _id: "64f75285ef8f606073e9ba61",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "maropia nord",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1424478,
          lng: 46.221115,
        },
        _id: "64f75285ef8f606073e9ba62",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "maropia ampisopiso",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1730602,
          lng: 46.2366679,
        },
        _id: "64f75285ef8f606073e9ba63",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "maropia sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1766586,
          lng: 46.2480366,
        },
        _id: "64f75285ef8f606073e9ba64",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "tanambao sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1263491,
          lng: 46.2546196,
        },
        _id: "64f75285ef8f606073e9ba65",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "soatsifa ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1258033,
          lng: 46.2402577,
        },
        _id: "64f75285ef8f606073e9ba66",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "soatsifa ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1715281,
          lng: 46.1971973,
        },
        _id: "64f75285ef8f606073e9ba67",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "savara tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1161233,
          lng: 46.2558167,
        },
        _id: "64f75285ef8f606073e9ba68",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "antseta ankilimanintsy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1241553,
          lng: 46.1971973,
        },
        _id: "64f75285ef8f606073e9ba69",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "soatamea",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1366036,
          lng: 46.1924151,
        },
        _id: "64f75285ef8f606073e9ba6a",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "vohimiare",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1179419,
          lng: 46.220517,
        },
        _id: "64f75285ef8f606073e9ba6b",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "tanambao nord",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1159739,
          lng: 46.2103506,
        },
        _id: "64f75285ef8f606073e9ba6c",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "tanambao I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2046075,
          lng: 46.23547130000001,
        },
        _id: "64f75285ef8f606073e9ba6d",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "ankororoke mahazosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1778469,
          lng: 46.2175266,
        },
        _id: "64f75285ef8f606073e9ba6e",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "antanisoa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.191598,
          lng: 46.2259,
        },
        _id: "64f75285ef8f606073e9ba6f",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "ankilivotro",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2047896,
          lng: 46.2199189,
        },
        _id: "64f75285ef8f606073e9ba70",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "ampihamibe mahazosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1661404,
          lng: 46.221115,
        },
        _id: "64f75285ef8f606073e9ba71",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "tsimikaboke",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1467994,
          lng: 46.2522257,
        },
        _id: "64f75285ef8f606073e9ba72",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "maroalopoty",
        fokontany: "marodoa ankilimasy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2203419,
          lng: 45.8137377,
        },
        _id: "64f75285ef8f606073e9ba73",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "ambondro anatirova",
        __v: 0,
      },
      {
        coords: {
          lat: -25.200973,
          lng: 45.8754564,
        },
        _id: "64f75285ef8f606073e9ba74",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "tsimanankiaraky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2648792,
          lng: 45.8256005,
        },
        _id: "64f75285ef8f606073e9ba75",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "lamitihy ampisandrata",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2453919,
          lng: 45.8113655,
        },
        _id: "64f75285ef8f606073e9ba76",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "lamitihy belanky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2368872,
          lng: 45.8327196,
        },
        _id: "64f75285ef8f606073e9ba77",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "marosy I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2360331,
          lng: 45.8493349,
        },
        _id: "64f75285ef8f606073e9ba78",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "marosy II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2687492,
          lng: 45.8422134,
        },
        _id: "64f75285ef8f606073e9ba79",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "ramagna magnefa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.287307,
          lng: 45.8350929,
        },
        _id: "64f75285ef8f606073e9ba7a",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "lamitihy ankasy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2944292,
          lng: 45.8540833,
        },
        _id: "64f75285ef8f606073e9ba7b",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "lamitihy ambario",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2867731,
          lng: 45.85883200000001,
        },
        _id: "64f75285ef8f606073e9ba7c",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "lamitihy tsibo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3150824,
          lng: 45.85883200000001,
        },
        _id: "64f75285ef8f606073e9ba7d",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "lamitihy atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3217927,
          lng: 45.8327196,
        },
        _id: "64f75285ef8f606073e9ba7e",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "antsotre be",
        __v: 0,
      },
      {
        coords: {
          lat: -25.310472,
          lng: 45.8434002,
        },
        _id: "64f75285ef8f606073e9ba7f",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "lamitihy ankitry",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2891735,
          lng: 45.8778318,
        },
        _id: "64f75285ef8f606073e9ba80",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "ankazoabo II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.272618,
          lng: 45.85883200000001,
        },
        _id: "64f75285ef8f606073e9ba81",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "ankiliromotse",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2804699,
          lng: 45.8766441,
        },
        _id: "64f75285ef8f606073e9ba82",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "antsotry faliakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2569926,
          lng: 45.8612066,
        },
        _id: "64f75285ef8f606073e9ba83",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "bebea",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2685173,
          lng: 45.8730812,
        },
        _id: "64f75285ef8f606073e9ba84",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "vazoa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2805061,
          lng: 45.895651,
        },
        _id: "64f75285ef8f606073e9ba85",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "andobaka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2617777,
          lng: 45.8992156,
        },
        _id: "64f75285ef8f606073e9ba86",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "betioky zanavo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.282746,
          lng: 45.9110996,
        },
        _id: "64f75285ef8f606073e9ba87",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "belay marolava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2240114,
          lng: 45.8992156,
        },
        _id: "64f75285ef8f606073e9ba88",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "terakabo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2324025,
          lng: 45.8018778,
        },
        _id: "64f75285ef8f606073e9ba89",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "andasary sud",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2752039,
          lng: 45.9004039,
        },
        _id: "64f75285ef8f606073e9ba8a",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "tsifahera",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3038652,
          lng: 45.8540833,
        },
        _id: "64f75285ef8f606073e9ba8b",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambondro",
        fokontany: "beraketa II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3010121,
          lng: 45.8968392,
        },
        _id: "64f75285ef8f606073e9ba8c",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambazoa",
        fokontany: "ambazoa I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3127697,
          lng: 45.8778318,
        },
        _id: "64f75285ef8f606073e9ba8d",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambazoa",
        fokontany: "ambazoa II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3239893,
          lng: 45.882583,
        },
        _id: "64f75285ef8f606073e9ba8e",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambazoa",
        fokontany: "ampiha",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3227192,
          lng: 45.9075341,
        },
        _id: "64f75285ef8f606073e9ba8f",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambazoa",
        fokontany: "halomboro",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3271287,
          lng: 45.9004039,
        },
        _id: "64f75285ef8f606073e9ba90",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambazoa",
        fokontany: "ampaipaika",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3373038,
          lng: 45.8992156,
        },
        _id: "64f75285ef8f606073e9ba91",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambazoa",
        fokontany: "malaindoza",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3066601,
          lng: 45.9182314,
        },
        _id: "64f75285ef8f606073e9ba92",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambazoa",
        fokontany: "satrie",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3490537,
          lng: 45.8802073,
        },
        _id: "64f75285ef8f606073e9ba93",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambazoa",
        fokontany: "ambanimantsake",
        __v: 0,
      },
      {
        coords: {
          lat: -25.351359,
          lng: 45.8612066,
        },
        _id: "64f75285ef8f606073e9ba94",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambazoa",
        fokontany: "ambasy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3289222,
          lng: 45.851709,
        },
        _id: "64f75285ef8f606073e9ba95",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambazoa",
        fokontany: "tanamalangy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3474761,
          lng: 45.8445871,
        },
        _id: "64f75285ef8f606073e9ba96",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambazoa",
        fokontany: "ambazoamazava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3663443,
          lng: 45.8445871,
        },
        _id: "64f75285ef8f606073e9ba97",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambazoa",
        fokontany: "amboriminendra",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3559563,
          lng: 45.8232277,
        },
        _id: "64f75285ef8f606073e9ba98",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambazoa",
        fokontany: "berehaky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3816412,
          lng: 45.8350929,
        },
        _id: "64f75285ef8f606073e9ba99",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambazoa",
        fokontany: "ikotoala",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3714755,
          lng: 45.8362795,
        },
        _id: "64f75285ef8f606073e9ba9a",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambazoa",
        fokontany: "ikotoala bemozotse",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3850729,
          lng: 45.8066214,
        },
        _id: "64f75285ef8f606073e9ba9b",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambazoa",
        fokontany: "antanimihery I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.3585681,
          lng: 45.8113655,
        },
        _id: "64f75285ef8f606073e9ba9c",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambazoa",
        fokontany: "antanimihery II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.364107,
          lng: 45.7947633,
        },
        _id: "64f75285ef8f606073e9ba9d",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ambazoa",
        fokontany: "antanimihery terabovo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2139907,
          lng: 45.74025400000001,
        },
        _id: "64f75285ef8f606073e9ba9e",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "terabovo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2473935,
          lng: 45.78527889999999,
        },
        _id: "64f75285ef8f606073e9ba9f",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2404843,
          lng: 45.73551699999999,
        },
        _id: "64f75285ef8f606073e9baa0",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "analamasy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2322113,
          lng: 45.72604440000001,
        },
        _id: "64f75285ef8f606073e9baa1",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "sihanamitohy marolava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2000698,
          lng: 45.7094719,
        },
        _id: "64f75285ef8f606073e9baa2",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "imontombe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1694905,
          lng: 45.7284124,
        },
        _id: "64f75285ef8f606073e9baa3",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "imantsaky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.221419,
          lng: 45.7663159,
        },
        _id: "64f75285ef8f606073e9baa4",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "ambohitse",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1790584,
          lng: 45.8042495,
        },
        _id: "64f75285ef8f606073e9baa5",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "analahova andranomaro",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1932641,
          lng: 45.8422134,
        },
        _id: "64f75285ef8f606073e9baa6",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "ianakafy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1979227,
          lng: 45.8042495,
        },
        _id: "64f75285ef8f606073e9baa7",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9921248,
          lng: 45.84696100000001,
        },
        _id: "64f75285ef8f606073e9baa8",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "ehavo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2612236,
          lng: 45.7781669,
        },
        _id: "64f75285ef8f606073e9baa9",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "miandra",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2499083,
          lng: 45.73551699999999,
        },
        _id: "64f75285ef8f606073e9baaa",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "manja soaloka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1875693,
          lng: 45.7829081,
        },
        _id: "64f75285ef8f606073e9baab",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "itondrake",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1822388,
          lng: 45.7686858,
        },
        _id: "64f75285ef8f606073e9baac",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "analaisoke",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1561204,
          lng: 45.8564576,
        },
        _id: "64f75285ef8f606073e9baad",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "belindo analahova",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0522966,
          lng: 45.7805375,
        },
        _id: "64f75285ef8f606073e9baae",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "agnorike",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0906201,
          lng: 45.7947633,
        },
        _id: "64f75285ef8f606073e9baaf",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "kotovelo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1708423,
          lng: 45.8327196,
        },
        _id: "64f75285ef8f606073e9bab0",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "analamanoy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1634392,
          lng: 45.8066214,
        },
        _id: "64f75285ef8f606073e9bab1",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "ankiliabo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1530939,
          lng: 45.78527889999999,
        },
        _id: "64f75285ef8f606073e9bab2",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "ankilimiharatse",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1283487,
          lng: 45.7947633,
        },
        _id: "64f75285ef8f606073e9bab3",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "benonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1518713,
          lng: 45.7568372,
        },
        _id: "64f75285ef8f606073e9bab4",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "silimosa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1707261,
          lng: 45.7568372,
        },
        _id: "64f75285ef8f606073e9bab5",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "andramanera",
        __v: 0,
      },
      {
        coords: {
          lat: -25.123064,
          lng: 45.8184824,
        },
        _id: "64f75285ef8f606073e9bab6",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "taranake",
        __v: 0,
      },
      {
        coords: {
          lat: -25.2191049,
          lng: 45.78527889999999,
        },
        _id: "64f75285ef8f606073e9bab7",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "eraho",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1980746,
          lng: 45.73551699999999,
        },
        _id: "64f75285ef8f606073e9bab8",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "ankazomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1259172,
          lng: 45.7378854,
        },
        _id: "64f75285ef8f606073e9bab9",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "sihanamaro",
        fokontany: "savilava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1140385,
          lng: 45.6810754,
        },
        _id: "64f75285ef8f606073e9baba",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "marovato befeno",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1282419,
          lng: 45.7189412,
        },
        _id: "64f75285ef8f606073e9babb",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "marovato befeno",
        fokontany: "namalaza I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1022965,
          lng: 45.7000045,
        },
        _id: "64f75285ef8f606073e9babc",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "marovato befeno",
        fokontany: "namalaza II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1157338,
          lng: 45.6479676,
        },
        _id: "64f75285ef8f606073e9babd",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "marovato befeno",
        fokontany: "lahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1540245,
          lng: 45.6621538,
        },
        _id: "64f75285ef8f606073e9babe",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "marovato befeno",
        fokontany: "tsiteke hazolava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1304079,
          lng: 45.6964547,
        },
        _id: "64f75285ef8f606073e9babf",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "marovato befeno",
        fokontany: "mitreaky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1435366,
          lng: 45.7094719,
        },
        _id: "64f75285ef8f606073e9bac0",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "marovato befeno",
        fokontany: "mitreaky marofoty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1799748,
          lng: 45.6810754,
        },
        _id: "64f75285ef8f606073e9bac1",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "marovato befeno",
        fokontany: "nandrasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.168464,
          lng: 45.66924849999999,
        },
        _id: "64f75285ef8f606073e9bac2",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "marovato befeno",
        fokontany: "miandrasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1697144,
          lng: 45.697638,
        },
        _id: "64f75285ef8f606073e9bac3",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "marovato befeno",
        fokontany: "amborokahake",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1988127,
          lng: 45.6810754,
        },
        _id: "64f75285ef8f606073e9bac4",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "marovato befeno",
        fokontany: "ambendrana",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7633854,
          lng: 45.79002089999999,
        },
        _id: "64f75285ef8f606073e9bac5",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "antanimora atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5358898,
          lng: 45.76157629999999,
        },
        _id: "64f75285ef8f606073e9bac6",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "analave fotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5443908,
          lng: 45.8232277,
        },
        _id: "64f75285ef8f606073e9bac7",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "amboasary tehalomboro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.594569,
          lng: 45.8184824,
        },
        _id: "64f75285ef8f606073e9bac8",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "ambararata haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5870709,
          lng: 45.78527889999999,
        },
        _id: "64f75285ef8f606073e9bac9",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "ambolokohy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5845455,
          lng: 45.7592067,
        },
        _id: "64f75285ef8f606073e9baca",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "ankileroa befeno",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5612198,
          lng: 45.7663159,
        },
        _id: "64f75285ef8f606073e9bacb",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "somangy ankileroa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5696147,
          lng: 45.7378854,
        },
        _id: "64f75285ef8f606073e9bacc",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "andraketa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.598336,
          lng: 45.7071049,
        },
        _id: "64f75285ef8f606073e9bacd",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "ankilimasy tedo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5944141,
          lng: 45.7284124,
        },
        _id: "64f75285ef8f606073e9bace",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "ankilimilopaka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6132743,
          lng: 45.7284124,
        },
        _id: "64f75285ef8f606073e9bacf",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "ambinany I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6534205,
          lng: 45.7094719,
        },
        _id: "64f75285ef8f606073e9bad0",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "horombe antsakoamiary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6391312,
          lng: 45.7473603,
        },
        _id: "64f75285ef8f606073e9bad1",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "bevinda",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6560925,
          lng: 45.7805375,
        },
        _id: "64f75285ef8f606073e9bad2",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "ambararata bas",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5966165,
          lng: 45.8754564,
        },
        _id: "64f75285ef8f606073e9bad3",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "antsakoamasy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5986364,
          lng: 45.9324982,
        },
        _id: "64f75285ef8f606073e9bad4",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6662312,
          lng: 45.8849587,
        },
        _id: "64f75285ef8f606073e9bad5",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "vohitsova",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7239602,
          lng: 45.9134768,
        },
        _id: "64f75285ef8f606073e9bad6",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "ankaramanoy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7402004,
          lng: 45.8422134,
        },
        _id: "64f75285ef8f606073e9bad7",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "soamiry analave mainty III",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7744562,
          lng: 45.8327196,
        },
        _id: "64f75285ef8f606073e9bad8",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "analave mainty I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8003562,
          lng: 45.851709,
        },
        _id: "64f75285ef8f606073e9bad9",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "analave mainty II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8506963,
          lng: 45.7710559,
        },
        _id: "64f75285ef8f606073e9bada",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "andemby analamaiky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7639307,
          lng: 45.8042495,
        },
        _id: "64f75285ef8f606073e9badb",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "soity bas",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7672806,
          lng: 45.7686858,
        },
        _id: "64f75285ef8f606073e9badc",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "betoko ambatosoavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7245594,
          lng: 45.76157629999999,
        },
        _id: "64f75285ef8f606073e9badd",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "ikonda ambatosoavolo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7476934,
          lng: 45.7094719,
        },
        _id: "64f75285ef8f606073e9bade",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "ikonda tsimiarihandry",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7789294,
          lng: 45.704738,
        },
        _id: "64f75285ef8f606073e9badf",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "ambingovingo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8095947,
          lng: 45.685807,
        },
        _id: "64f75285ef8f606073e9bae0",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "ankilifaly",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8232013,
          lng: 45.6337856,
        },
        _id: "64f75285ef8f606073e9bae1",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "andaboly",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8832367,
          lng: 45.6432398,
        },
        _id: "64f75285ef8f606073e9bae2",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "sakaomby",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9038275,
          lng: 45.685807,
        },
        _id: "64f75285ef8f606073e9bae3",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "andriamanarina avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8872845,
          lng: 45.7426226,
        },
        _id: "64f75285ef8f606073e9bae4",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "marolava antsatra",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9874629,
          lng: 45.73314860000001,
        },
        _id: "64f75285ef8f606073e9bae5",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "manavy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9344518,
          lng: 45.6668835,
        },
        _id: "64f75285ef8f606073e9bae6",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "andriamanarina imangory",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9650585,
          lng: 45.6479676,
        },
        _id: "64f75285ef8f606073e9bae7",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "androtsy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.031589,
          lng: 45.6621538,
        },
        _id: "64f75285ef8f606073e9bae8",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "andranogiso",
        __v: 0,
      },
      {
        coords: {
          lat: -25.034267,
          lng: 45.6881729,
        },
        _id: "64f75285ef8f606073e9bae9",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "andriamanarina atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0663277,
          lng: 45.6668835,
        },
        _id: "64f75285ef8f606073e9baea",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "ambaro antsagnira",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0363392,
          lng: 45.7000045,
        },
        _id: "64f75285ef8f606073e9baeb",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "ambaro loharoe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0339984,
          lng: 45.7189412,
        },
        _id: "64f75285ef8f606073e9baec",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "ankiliabo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.051479,
          lng: 45.73069,
        },
        _id: "64f75285ef8f606073e9baed",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "antanimora atsimo",
        fokontany: "laparoy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6758259,
          lng: 45.5983492,
        },
        _id: "64f75285ef8f606073e9baee",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "andalatanosy atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5226209,
          lng: 45.5723794,
        },
        _id: "64f75285ef8f606073e9baef",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "vohibanemba",
        __v: 0,
      },
      {
        coords: {
          lat: -24.55,
          lng: 45.583333,
        },
        _id: "64f75285ef8f606073e9baf0",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "ambatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.57373,
          lng: 45.5959878,
        },
        _id: "64f75285ef8f606073e9baf1",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "ambatomasy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5688842,
          lng: 45.6337856,
        },
        _id: "64f75285ef8f606073e9baf2",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "andalakaolo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5726463,
          lng: 45.5676592,
        },
        _id: "64f75285ef8f606073e9baf3",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "ambatobendretsiomby",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5651004,
          lng: 45.5346307,
        },
        _id: "64f75285ef8f606073e9baf4",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "manolodroa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5995633,
          lng: 45.6148829,
        },
        _id: "64f75285ef8f606073e9baf5",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "ankondria",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6232155,
          lng: 45.5771002,
        },
        _id: "64f75285ef8f606073e9baf6",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "bekopiky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6071042,
          lng: 45.6479676,
        },
        _id: "64f75285ef8f606073e9baf7",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "edasotse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6431851,
          lng: 45.6881729,
        },
        _id: "64f75285ef8f606073e9baf8",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "ankafoja",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6299892,
          lng: 45.67161369999999,
        },
        _id: "64f75285ef8f606073e9baf9",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "kelelemby ankilemasy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6418304,
          lng: 45.6526959,
        },
        _id: "64f75285ef8f606073e9bafa",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "bekapitsa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6894885,
          lng: 45.6668835,
        },
        _id: "64f75285ef8f606073e9bafb",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "koroma toby",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6878311,
          lng: 45.6243333,
        },
        _id: "64f75285ef8f606073e9bafc",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "behena",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6307736,
          lng: 45.6101584,
        },
        _id: "64f75285ef8f606073e9bafd",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "andalatanosy avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6503425,
          lng: 45.54878290000001,
        },
        _id: "64f75285ef8f606073e9bafe",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "andrambarotse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6304073,
          lng: 45.5204827,
        },
        _id: "64f75285ef8f606073e9baff",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "antaly",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6648067,
          lng: 45.5181251,
        },
        _id: "64f75285ef8f606073e9bb00",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "anjapito",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6123015,
          lng: 45.4592239,
        },
        _id: "64f75285ef8f606073e9bb01",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "modoioke",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6622484,
          lng: 45.454515,
        },
        _id: "64f75285ef8f606073e9bb02",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "vohitrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6633897,
          lng: 45.4827755,
        },
        _id: "64f75285ef8f606073e9bb03",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "manalihara",
        __v: 0,
      },
      {
        coords: {
          lat: -24.695129,
          lng: 45.47538,
        },
        _id: "64f75285ef8f606073e9bb04",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "ampihamy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6897968,
          lng: 45.5157676,
        },
        _id: "64f75285ef8f606073e9bb05",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "andemby",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7038557,
          lng: 45.5535013,
        },
        _id: "64f75285ef8f606073e9bb06",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "marofany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7256891,
          lng: 45.4733534,
        },
        _id: "64f75285ef8f606073e9bb07",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "analamahery",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6998481,
          lng: 45.454515,
        },
        _id: "64f75285ef8f606073e9bb08",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7362507,
          lng: 45.5016253,
        },
        _id: "64f75285ef8f606073e9bb09",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "vohimpandrany est",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7367418,
          lng: 45.5912652,
        },
        _id: "64f75285ef8f606073e9bb0a",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "ambondrombe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7814381,
          lng: 45.6101584,
        },
        _id: "64f75285ef8f606073e9bb0b",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "androvasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7384355,
          lng: 45.6337856,
        },
        _id: "64f75285ef8f606073e9bb0c",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "andranolaliky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7395562,
          lng: 45.6621538,
        },
        _id: "64f75285ef8f606073e9bb0d",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "andrambita",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7766773,
          lng: 45.6479676,
        },
        _id: "64f75285ef8f606073e9bb0e",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "ambaliandro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6732342,
          lng: 45.5723794,
        },
        _id: "64f75285ef8f606073e9bb0f",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "vohitraomby",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7123949,
          lng: 45.690539,
        },
        _id: "64f75285ef8f606073e9bb10",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "andalatanosy",
        fokontany: "belindo bas",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4593215,
          lng: 45.6432398,
        },
        _id: "64f75285ef8f606073e9bb11",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ampamata",
        fokontany: "antanimavo II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4513154,
          lng: 45.5959878,
        },
        _id: "64f75285ef8f606073e9bb12",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ampamata",
        fokontany: "manarena mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4989263,
          lng: 45.6101584,
        },
        _id: "64f75285ef8f606073e9bb13",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ampamata",
        fokontany: "antsihanamasy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5262285,
          lng: 45.6266962,
        },
        _id: "64f75285ef8f606073e9bb14",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ampamata",
        fokontany: "andalipito",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4821689,
          lng: 45.6668835,
        },
        _id: "64f75285ef8f606073e9bb15",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ampamata",
        fokontany: "ampanaperandrotsy I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5099199,
          lng: 45.6526959,
        },
        _id: "64f75285ef8f606073e9bb16",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ampamata",
        fokontany: "ampanaperandrotsy II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5213494,
          lng: 45.6645186,
        },
        _id: "64f75285ef8f606073e9bb17",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ampamata",
        fokontany: "mafilefy I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5427296,
          lng: 45.690539,
        },
        _id: "64f75285ef8f606073e9bb18",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ampamata",
        fokontany: "antafianampela I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5505743,
          lng: 45.6479676,
        },
        _id: "64f75285ef8f606073e9bb19",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ampamata",
        fokontany: "anjatelo I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5149675,
          lng: 45.704738,
        },
        _id: "64f75285ef8f606073e9bb1a",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ampamata",
        fokontany: "besatra soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5318884,
          lng: 45.7378854,
        },
        _id: "64f75285ef8f606073e9bb1b",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ampamata",
        fokontany: "befeno beropiteke I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5804351,
          lng: 45.690539,
        },
        _id: "64f75285ef8f606073e9bb1c",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "ampamata",
        fokontany: "antanimavo I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9155279,
          lng: 45.5157676,
        },
        _id: "64f75285ef8f606073e9bb1d",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "jafaro I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.776808,
          lng: 45.4969122,
        },
        _id: "64f75285ef8f606073e9bb1e",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "ankara andindo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.790907,
          lng: 45.5346307,
        },
        _id: "64f75285ef8f606073e9bb1f",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "ankilimiandra",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8050046,
          lng: 45.5723794,
        },
        _id: "64f75285ef8f606073e9bb20",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "ankilimiary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8291251,
          lng: 45.54878290000001,
        },
        _id: "64f75285ef8f606073e9bb21",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "tsiabetsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.84029,
          lng: 45.5157676,
        },
        _id: "64f75285ef8f606073e9bb22",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "anjakamba haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8073641,
          lng: 45.4780642,
        },
        _id: "64f75285ef8f606073e9bb23",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "ankarana anivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7738592,
          lng: 45.4262714,
        },
        _id: "64f75285ef8f606073e9bb24",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "antafianampela bas",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7618085,
          lng: 45.40039650000001,
        },
        _id: "64f75285ef8f606073e9bb25",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "antanantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7949849,
          lng: 45.4074519,
        },
        _id: "64f75285ef8f606073e9bb26",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "ankobo II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8031728,
          lng: 45.3792367,
        },
        _id: "64f75285ef8f606073e9bb27",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "analatsama",
        __v: 0,
      },
      {
        coords: {
          lat: -24.847199,
          lng: 45.3839381,
        },
        _id: "64f75285ef8f606073e9bb28",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "ampangindraoke",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8373017,
          lng: 45.4450986,
        },
        _id: "64f75285ef8f606073e9bb29",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "andahivozaka haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8684238,
          lng: 45.4403911,
        },
        _id: "64f75285ef8f606073e9bb2a",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "magnevotse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8779097,
          lng: 45.5157676,
        },
        _id: "64f75285ef8f606073e9bb2b",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "anjakamba bas",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8482375,
          lng: 45.5558606,
        },
        _id: "64f75285ef8f606073e9bb2c",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "androtoka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.873823,
          lng: 45.5676592,
        },
        _id: "64f75285ef8f606073e9bb2d",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "betapoaka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8379317,
          lng: 45.6101584,
        },
        _id: "64f75285ef8f606073e9bb2e",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "beteny",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9061837,
          lng: 45.5912652,
        },
        _id: "64f75285ef8f606073e9bb2f",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "afondratehaky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9043924,
          lng: 45.54878290000001,
        },
        _id: "64f75285ef8f606073e9bb30",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "andemby tanamasy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7556569,
          lng: 45.4403911,
        },
        _id: "64f75285ef8f606073e9bb31",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "antafianampela haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.957774,
          lng: 45.4780642,
        },
        _id: "64f75285ef8f606073e9bb32",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "besakoa bas",
        __v: 0,
      },
      {
        coords: {
          lat: -24.913092,
          lng: 45.4592239,
        },
        _id: "64f75285ef8f606073e9bb33",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "sovatry",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9223224,
          lng: 45.3839381,
        },
        _id: "64f75285ef8f606073e9bb34",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "ankobo I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9832253,
          lng: 45.3463405,
        },
        _id: "64f75285ef8f606073e9bb35",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "ambanditse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9699015,
          lng: 45.3980449,
        },
        _id: "64f75285ef8f606073e9bb36",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "anjandroe behevotre",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9999681,
          lng: 45.4403911,
        },
        _id: "64f75285ef8f606073e9bb37",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "ambory bas",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0095665,
          lng: 45.5157676,
        },
        _id: "64f75285ef8f606073e9bb38",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "mandily",
        __v: 0,
      },
      {
        coords: {
          lat: -24.975349,
          lng: 45.5643389,
        },
        _id: "64f75285ef8f606073e9bb39",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "antsomotsala",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9626575,
          lng: 45.5912652,
        },
        _id: "64f75285ef8f606073e9bb3a",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "anjeba",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9762138,
          lng: 45.6148829,
        },
        _id: "64f75285ef8f606073e9bb3b",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "antanimanoy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0191276,
          lng: 45.5912652,
        },
        _id: "64f75285ef8f606073e9bb3c",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "maliosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0550733,
          lng: 45.6243333,
        },
        _id: "64f75285ef8f606073e9bb3d",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "maregny",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0774517,
          lng: 45.6337856,
        },
        _id: "64f75285ef8f606073e9bb3e",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "tsimanaraka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0794556,
          lng: 45.6077963,
        },
        _id: "64f75285ef8f606073e9bb3f",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "bemonjola",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0714189,
          lng: 45.5676592,
        },
        _id: "64f75285ef8f606073e9bb40",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "betaranta",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0607619,
          lng: 45.5393476,
        },
        _id: "64f75285ef8f606073e9bb41",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "marosoritse",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0517673,
          lng: 45.4780642,
        },
        _id: "64f75285ef8f606073e9bb42",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "ankorokoroka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.033333,
          lng: 45.383333,
        },
        _id: "64f75285ef8f606073e9bb43",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "antsakoambalo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0997484,
          lng: 45.4309775,
        },
        _id: "64f75285ef8f606073e9bb44",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "ankotsobe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7573719,
          lng: 45.5582201,
        },
        _id: "64f75285ef8f606073e9bb45",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "jafaro",
        fokontany: "ampahejoloky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.439779,
          lng: 45.81792799999999,
        },
        _id: "64f75285ef8f606073e9bb46",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "imanombo",
        fokontany: "imanombo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2984414,
          lng: 45.8089934,
        },
        _id: "64f75285ef8f606073e9bb47",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "imanombo",
        fokontany: "mitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2823217,
          lng: 45.8944628,
        },
        _id: "64f75285ef8f606073e9bb48",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "imanombo",
        fokontany: "andranovola",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3679921,
          lng: 45.8184824,
        },
        _id: "64f75285ef8f606073e9bb49",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "imanombo",
        fokontany: "antehalomboro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3321021,
          lng: 45.8754564,
        },
        _id: "64f75285ef8f606073e9bb4a",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "imanombo",
        fokontany: "andemby",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3722298,
          lng: 45.9467693,
        },
        _id: "64f75285ef8f606073e9bb4b",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "imanombo",
        fokontany: "anafondravoay",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3849342,
          lng: 45.76157629999999,
        },
        _id: "64f75285ef8f606073e9bb4c",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "imanombo",
        fokontany: "iaborano",
        __v: 0,
      },
      {
        coords: {
          lat: -24.395719,
          lng: 45.8944628,
        },
        _id: "64f75285ef8f606073e9bb4d",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "imanombo",
        fokontany: "ambaninato II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3946492,
          lng: 45.8623939,
        },
        _id: "64f75285ef8f606073e9bb4e",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "imanombo",
        fokontany: "ambanenato mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4002671,
          lng: 45.8422134,
        },
        _id: "64f75285ef8f606073e9bb4f",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "imanombo",
        fokontany: "ambaninato I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.421655,
          lng: 45.8232277,
        },
        _id: "64f75285ef8f606073e9bb50",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "imanombo",
        fokontany: "antesomay",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4395396,
          lng: 45.704738,
        },
        _id: "64f75285ef8f606073e9bb51",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "imanombo",
        fokontany: "antsakoamalangy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4837102,
          lng: 45.7094719,
        },
        _id: "64f75285ef8f606073e9bb52",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "imanombo",
        fokontany: "kalady",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4872466,
          lng: 45.8089934,
        },
        _id: "64f75285ef8f606073e9bb53",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "imanombo",
        fokontany: "tsikolaky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4662998,
          lng: 45.9324982,
        },
        _id: "64f75285ef8f606073e9bb54",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "imanombo",
        fokontany: "esogno antanele",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4234398,
          lng: 45.97056380000001,
        },
        _id: "64f75285ef8f606073e9bb55",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "imanombo",
        fokontany: "finday",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4901186,
          lng: 45.9848461,
        },
        _id: "64f75285ef8f606073e9bb56",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "imanombo",
        fokontany: "ankilivohitse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4956909,
          lng: 45.870706,
        },
        _id: "64f75285ef8f606073e9bb57",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "imanombo",
        fokontany: "ambalantany I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5469023,
          lng: 45.8944628,
        },
        _id: "64f75285ef8f606073e9bb58",
        province: "toliary",
        region: "androy",
        district: "ambovombe-androy",
        commune: "imanombo",
        fokontany: "analavondrovey",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2225659,
          lng: 45.3093601,
        },
        _id: "64f75285ef8f606073e9bb59",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "morafeno bekily",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1864494,
          lng: 45.3815873,
        },
        _id: "64f75285ef8f606073e9bb5a",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "morafeno bekily",
        fokontany: "morafeno bas",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2257071,
          lng: 45.341643,
        },
        _id: "64f75285ef8f606073e9bb5b",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "morafeno bekily",
        fokontany: "mitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2108498,
          lng: 45.3205098,
        },
        _id: "64f75285ef8f606073e9bb5c",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "morafeno bekily",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2343881,
          lng: 45.2384164,
        },
        _id: "64f75285ef8f606073e9bb5d",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "morafeno bekily",
        fokontany: "ankilibe antsakoamasy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2461833,
          lng: 45.2196725,
        },
        _id: "64f75285ef8f606073e9bb5e",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "morafeno bekily",
        fokontany: "ambararata ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2708297,
          lng: 45.2103034,
        },
        _id: "64f75285ef8f606073e9bb5f",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "morafeno bekily",
        fokontany: "antsifitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2719046,
          lng: 45.2384164,
        },
        _id: "64f75285ef8f606073e9bb60",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "morafeno bekily",
        fokontany: "tahiembanga",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1950842,
          lng: 45.3604361,
        },
        _id: "64f75285ef8f606073e9bb61",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "morafeno bekily",
        fokontany: "sakaralava",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2371519,
          lng: 45.3980449,
        },
        _id: "64f75285ef8f606073e9bb62",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "morafeno bekily",
        fokontany: "bedona",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2326545,
          lng: 45.3604361,
        },
        _id: "64f75285ef8f606073e9bb63",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "morafeno bekily",
        fokontany: "ambondro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2296262,
          lng: 45.3205098,
        },
        _id: "64f75285ef8f606073e9bb64",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "morafeno bekily",
        fokontany: "andranomamy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3045713,
          lng: 45.1939121,
        },
        _id: "64f75285ef8f606073e9bb65",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "morafeno bekily",
        fokontany: "antsakoamasy nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2815238,
          lng: 45.3275531,
        },
        _id: "64f75285ef8f606073e9bb66",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "morafeno bekily",
        fokontany: "befangitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2217811,
          lng: 45.31992289999999,
        },
        _id: "64f75285ef8f606073e9bb67",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "morafeno bekily",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2364936,
          lng: 45.2946934,
        },
        _id: "64f75285ef8f606073e9bb68",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "morafeno bekily",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2022474,
          lng: 45.21498769999999,
        },
        _id: "64f75285ef8f606073e9bb69",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ankaranabo nord",
        fokontany: "ankaranabo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1641157,
          lng: 45.1564679,
        },
        _id: "64f75285ef8f606073e9bb6a",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ankaranabo nord",
        fokontany: "mikaikarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1871229,
          lng: 45.31346749999999,
        },
        _id: "64f75285ef8f606073e9bb6b",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ankaranabo nord",
        fokontany: "andranomanintsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1676917,
          lng: 45.2103034,
        },
        _id: "64f75285ef8f606073e9bb6c",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ankaranabo nord",
        fokontany: "anarabe I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1850591,
          lng: 45.2571678,
        },
        _id: "64f75285ef8f606073e9bb6d",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ankaranabo nord",
        fokontany: "andriabe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1883383,
          lng: 45.1775265,
        },
        _id: "64f75285ef8f606073e9bb6e",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ankaranabo nord",
        fokontany: "anarabe II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1831951,
          lng: 45.3345975,
        },
        _id: "64f75285ef8f606073e9bb6f",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ankaranabo nord",
        fokontany: "bevoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.199322,
          lng: 45.1377572,
        },
        _id: "64f75285ef8f606073e9bb70",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ankaranabo nord",
        fokontany: "ankilimasy haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1840172,
          lng: 45.2290435,
        },
        _id: "64f75285ef8f606073e9bb71",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ankaranabo nord",
        fokontany: "ankiliroa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2030701,
          lng: 45.1541287,
        },
        _id: "64f75285ef8f606073e9bb72",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ankaranabo nord",
        fokontany: "maroharo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2051969,
          lng: 45.2103034,
        },
        _id: "64f75285ef8f606073e9bb73",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ankaranabo nord",
        fokontany: "vohimary I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1972798,
          lng: 45.3345975,
        },
        _id: "64f75285ef8f606073e9bb74",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ankaranabo nord",
        fokontany: "ambinanivelo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2094644,
          lng: 45.2407599,
        },
        _id: "64f75285ef8f606073e9bb75",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ankaranabo nord",
        fokontany: "vohimary II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2166796,
          lng: 45.2665464,
        },
        _id: "64f75285ef8f606073e9bb76",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ankaranabo nord",
        fokontany: "ankilibe I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2306398,
          lng: 45.1400956,
        },
        _id: "64f75285ef8f606073e9bb77",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ankaranabo nord",
        fokontany: "antsohamamy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1549827,
          lng: 45.290001,
        },
        _id: "64f75285ef8f606073e9bb78",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "besakoa",
        fokontany: "besakoa centre",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1301859,
          lng: 45.2103034,
        },
        _id: "64f75285ef8f606073e9bb79",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "besakoa",
        fokontany: "andranomatavy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1572705,
          lng: 45.1822075,
        },
        _id: "64f75285ef8f606073e9bb7a",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "besakoa",
        fokontany: "vohitany mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1499739,
          lng: 45.2384164,
        },
        _id: "64f75285ef8f606073e9bb7b",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "besakoa",
        fokontany: "besohihy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1677737,
          lng: 45.2548235,
        },
        _id: "64f75285ef8f606073e9bb7c",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "besakoa",
        fokontany: "besohihy vohitany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1693635,
          lng: 45.341643,
        },
        _id: "64f75285ef8f606073e9bb7d",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "besakoa",
        fokontany: "fenoarivo mahatalaky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2729729,
          lng: 45.2665464,
        },
        _id: "64f75285ef8f606073e9bb7e",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "anja nord",
        fokontany: "anja nord centre",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2646494,
          lng: 45.2946934,
        },
        _id: "64f75285ef8f606073e9bb7f",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "anja nord",
        fokontany: "andranonaondry",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2714779,
          lng: 45.3099468,
        },
        _id: "64f75285ef8f606073e9bb80",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "anja nord",
        fokontany: "ankatsakatsa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.30514,
          lng: 45.290001,
        },
        _id: "64f75285ef8f606073e9bb81",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "anja nord",
        fokontany: "besandraha",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3163955,
          lng: 45.2571678,
        },
        _id: "64f75285ef8f606073e9bb82",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "anja nord",
        fokontany: "mikaikarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3114617,
          lng: 45.1307427,
        },
        _id: "64f75285ef8f606073e9bb83",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "antsakoamaro",
        fokontany: "antsakoamaro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2605885,
          lng: 45.1073685,
        },
        _id: "64f75285ef8f606073e9bb84",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "antsakoamaro",
        fokontany: "antanimary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2943777,
          lng: 45.172846,
        },
        _id: "64f75285ef8f606073e9bb85",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "antsakoamaro",
        fokontany: "ankazota",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3250157,
          lng: 45.07933509999999,
        },
        _id: "64f75285ef8f606073e9bb86",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "antsakoamaro",
        fokontany: "beparo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2838,
          lng: 45.128151,
        },
        _id: "64f75285ef8f606073e9bb87",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "antsakoamaro",
        fokontany: "bevala",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3103471,
          lng: 45.1026951,
        },
        _id: "64f75285ef8f606073e9bb88",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "antsakoamaro",
        fokontany: "ankilimalangy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3525301,
          lng: 45.0653249,
        },
        _id: "64f75285ef8f606073e9bb89",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "antsakoamaro",
        fokontany: "ankilimihamy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2534052,
          lng: 45.1634864,
        },
        _id: "64f75285ef8f606073e9bb8a",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "antsakoamaro",
        fokontany: "antaboara haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0526656,
          lng: 45.4074519,
        },
        _id: "64f75285ef8f606073e9bb8b",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambatosola",
        fokontany: "ambatosola mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9541013,
          lng: 45.4592239,
        },
        _id: "64f75285ef8f606073e9bb8c",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambatosola",
        fokontany: "ambalabe avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -24.07692,
          lng: 45.3839381,
        },
        _id: "64f75285ef8f606073e9bb8d",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambatosola",
        fokontany: "anadabolava vohidroa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0536201,
          lng: 45.4356841,
        },
        _id: "64f75285ef8f606073e9bb8e",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambatosola",
        fokontany: "ankily",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1095418,
          lng: 45.4215658,
        },
        _id: "64f75285ef8f606073e9bb8f",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambatosola",
        fokontany: "bemanondrotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0442753,
          lng: 45.390991,
        },
        _id: "64f75285ef8f606073e9bb90",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambatosola",
        fokontany: "fenoarivo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0086496,
          lng: 45.4027482,
        },
        _id: "64f75285ef8f606073e9bb91",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambatosola",
        fokontany: "mahabo sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.010059,
          lng: 45.4450986,
        },
        _id: "64f75285ef8f606073e9bb92",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambatosola",
        fokontany: "mandabe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1056055,
          lng: 45.3533878,
        },
        _id: "64f75285ef8f606073e9bb93",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambatosola",
        fokontany: "nagnarena",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0818795,
          lng: 45.3463405,
        },
        _id: "64f75285ef8f606073e9bb94",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambatosola",
        fokontany: "soaserana sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1451561,
          lng: 45.3651355,
        },
        _id: "64f75285ef8f606073e9bb95",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tsirandrany",
        fokontany: "tanambao tsirandrany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0897848,
          lng: 45.3040795,
        },
        _id: "64f75285ef8f606073e9bb96",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tsirandrany",
        fokontany: "tanambao soaserana",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1214092,
          lng: 45.31346749999999,
        },
        _id: "64f75285ef8f606073e9bb97",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tsirandrany",
        fokontany: "mitsinjo I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1189469,
          lng: 45.3322493,
        },
        _id: "64f75285ef8f606073e9bb98",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tsirandrany",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1243904,
          lng: 45.3980449,
        },
        _id: "64f75285ef8f606073e9bb99",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tsirandrany",
        fokontany: "temagnalo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1646944,
          lng: 45.3862889,
        },
        _id: "64f75285ef8f606073e9bb9a",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tsirandrany",
        fokontany: "morafeno tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1486244,
          lng: 45.41921319999999,
        },
        _id: "64f75285ef8f606073e9bb9b",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tsirandrany",
        fokontany: "mananantana beamotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3533709,
          lng: 45.2431035,
        },
        _id: "64f75285ef8f606073e9bb9c",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tsikolaky",
        fokontany: "tsikolaky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2879843,
          lng: 45.2501351,
        },
        _id: "64f75285ef8f606073e9bb9d",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tsikolaky",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3059328,
          lng: 45.2290435,
        },
        _id: "64f75285ef8f606073e9bb9e",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tsikolaky",
        fokontany: "antenendahy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3456252,
          lng: 45.2853092,
        },
        _id: "64f75285ef8f606073e9bb9f",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tsikolaky",
        fokontany: "belio haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3471125,
          lng: 45.1634864,
        },
        _id: "64f75285ef8f606073e9bba0",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tsikolaky",
        fokontany: "antsakoamalangy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3575975,
          lng: 45.19157089999999,
        },
        _id: "64f75285ef8f606073e9bba1",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tsikolaky",
        fokontany: "antsakoamiary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3684276,
          lng: 45.3087733,
        },
        _id: "64f75285ef8f606073e9bba2",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tsikolaky",
        fokontany: "ankilimilopaky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.378013,
          lng: 45.2337297,
        },
        _id: "64f75285ef8f606073e9bba3",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tsikolaky",
        fokontany: "anjantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3,
          lng: 45.383333,
        },
        _id: "64f75285ef8f606073e9bba4",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "manakompy",
        fokontany: "manakompy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2515028,
          lng: 45.4051,
        },
        _id: "64f75285ef8f606073e9bba5",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "manakompy",
        fokontany: "tambahy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.267785,
          lng: 45.3792367,
        },
        _id: "64f75285ef8f606073e9bba6",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "manakompy",
        fokontany: "analamahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2828343,
          lng: 45.3627857,
        },
        _id: "64f75285ef8f606073e9bba7",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "manakompy",
        fokontany: "befangitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2981383,
          lng: 45.3533878,
        },
        _id: "64f75285ef8f606073e9bba8",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "manakompy",
        fokontany: "antsakoamasy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2851668,
          lng: 45.4262714,
        },
        _id: "64f75285ef8f606073e9bba9",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "manakompy",
        fokontany: "andriabe est",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3005656,
          lng: 45.3345975,
        },
        _id: "64f75285ef8f606073e9bbaa",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "manakompy",
        fokontany: "miria II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3205543,
          lng: 45.3252052,
        },
        _id: "64f75285ef8f606073e9bbab",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "manakompy",
        fokontany: "miria I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3192839,
          lng: 45.41686070000001,
        },
        _id: "64f75285ef8f606073e9bbac",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "manakompy",
        fokontany: "ankazoabo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3191903,
          lng: 45.37218559999999,
        },
        _id: "64f75285ef8f606073e9bbad",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "manakompy",
        fokontany: "anaramanintsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3230937,
          lng: 45.3510386,
        },
        _id: "64f75285ef8f606073e9bbae",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "manakompy",
        fokontany: "ampilofilo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3369138,
          lng: 45.3439917,
        },
        _id: "64f75285ef8f606073e9bbaf",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "manakompy",
        fokontany: "anja ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3641454,
          lng: 45.3604361,
        },
        _id: "64f75285ef8f606073e9bbb0",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "manakompy",
        fokontany: "antanamary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3391887,
          lng: 45.3627857,
        },
        _id: "64f75285ef8f606073e9bbb1",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "manakompy",
        fokontany: "ankilisoa somangy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3442581,
          lng: 45.4145083,
        },
        _id: "64f75285ef8f606073e9bbb2",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "manakompy",
        fokontany: "maikandro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3796878,
          lng: 45.4403911,
        },
        _id: "64f75285ef8f606073e9bbb3",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "manakompy",
        fokontany: "ianato",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4008979,
          lng: 45.3392944,
        },
        _id: "64f75285ef8f606073e9bbb4",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "manakompy",
        fokontany: "ampihamy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0097703,
          lng: 45.2524792,
        },
        _id: "64f75285ef8f606073e9bbb5",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "ambahita centre",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8923223,
          lng: 45.2009362,
        },
        _id: "64f75285ef8f606073e9bbb6",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "antsomotsoy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.913839,
          lng: 45.249191,
        },
        _id: "64f75285ef8f606073e9bbb7",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "soakibany",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9197085,
          lng: 45.3651355,
        },
        _id: "64f75285ef8f606073e9bbb8",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "andriambola nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9515404,
          lng: 45.1962533,
        },
        _id: "64f75285ef8f606073e9bbb9",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "andakato",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9436187,
          lng: 45.2384164,
        },
        _id: "64f75285ef8f606073e9bbba",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "antaly",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9623787,
          lng: 45.2384164,
        },
        _id: "64f75285ef8f606073e9bbbb",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "anaviavy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.940345,
          lng: 45.4215658,
        },
        _id: "64f75285ef8f606073e9bbbc",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "andriambola est",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9539627,
          lng: 45.2665464,
        },
        _id: "64f75285ef8f606073e9bbbd",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "ampisopiso",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9741714,
          lng: 45.3087733,
        },
        _id: "64f75285ef8f606073e9bbbe",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "fangoala",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0077923,
          lng: 45.1962533,
        },
        _id: "64f75285ef8f606073e9bbbf",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "sakoambelomy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9760715,
          lng: 45.3651355,
        },
        _id: "64f75285ef8f606073e9bbc0",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "bevaho",
        __v: 0,
      },
      {
        coords: {
          lat: -24.036918,
          lng: 45.31346749999999,
        },
        _id: "64f75285ef8f606073e9bbc1",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "ankazomiheva",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0294627,
          lng: 45.3698354,
        },
        _id: "64f75285ef8f606073e9bbc2",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "fenoarivo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0487804,
          lng: 45.2946934,
        },
        _id: "64f75285ef8f606073e9bbc3",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "sakoampolo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0438273,
          lng: 45.3322493,
        },
        _id: "64f75285ef8f606073e9bbc4",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "bevato I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0413443,
          lng: 45.3510386,
        },
        _id: "64f75285ef8f606073e9bbc5",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "bevato II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0319541,
          lng: 45.172846,
        },
        _id: "64f75285ef8f606073e9bbc6",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "andoharano",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0474906,
          lng: 45.17050589999999,
        },
        _id: "64f75285ef8f606073e9bbc7",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "antaratsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0645498,
          lng: 45.2103034,
        },
        _id: "64f75285ef8f606073e9bbc8",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "ambatomainty haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0758635,
          lng: 45.1775265,
        },
        _id: "64f75285ef8f606073e9bbc9",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "androtsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.084821,
          lng: 45.2524792,
        },
        _id: "64f75285ef8f606073e9bbca",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "beza",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0981695,
          lng: 45.2759268,
        },
        _id: "64f75285ef8f606073e9bbcb",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "betioky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1044976,
          lng: 45.19157089999999,
        },
        _id: "64f75285ef8f606073e9bbcc",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "ampany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1297868,
          lng: 45.2853092,
        },
        _id: "64f75285ef8f606073e9bbcd",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "analabe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1315801,
          lng: 45.1634864,
        },
        _id: "64f75285ef8f606073e9bbce",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "vohipeno",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1287699,
          lng: 45.2571678,
        },
        _id: "64f75285ef8f606073e9bbcf",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "bemalo I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1361612,
          lng: 45.2454473,
        },
        _id: "64f75285ef8f606073e9bbd0",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "ambahita",
        fokontany: "bemalo II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.878429,
          lng: 45.2524792,
        },
        _id: "64f75285ef8f606073e9bbd1",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "maroviro",
        fokontany: "bedala maroviro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.882133,
          lng: 45.3651355,
        },
        _id: "64f75285ef8f606073e9bbd2",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "maroviro",
        fokontany: "andriambola nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.866667,
          lng: 45.283333,
        },
        _id: "64f75285ef8f606073e9bbd3",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "maroviro",
        fokontany: "belenalena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8409025,
          lng: 45.2524792,
        },
        _id: "64f75285ef8f606073e9bbd4",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "maroviro",
        fokontany: "ankonatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8488815,
          lng: 45.2103034,
        },
        _id: "64f75285ef8f606073e9bbd5",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "maroviro",
        fokontany: "tsanarena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.834789,
          lng: 45.33802,
        },
        _id: "64f75285ef8f606073e9bbd6",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "maroviro",
        fokontany: "betaimbala",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8141268,
          lng: 45.2946934,
        },
        _id: "64f75285ef8f606073e9bbd7",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "maroviro",
        fokontany: "andriabe nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7962525,
          lng: 45.3228575,
        },
        _id: "64f75285ef8f606073e9bbd8",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "maroviro",
        fokontany: "analamary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8296443,
          lng: 45.3886399,
        },
        _id: "64f75285ef8f606073e9bbd9",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "maroviro",
        fokontany: "bevinda",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8720066,
          lng: 45.4403911,
        },
        _id: "64f75285ef8f606073e9bbda",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "maroviro",
        fokontany: "iaborano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8514199,
          lng: 45.3839381,
        },
        _id: "64f75285ef8f606073e9bbdb",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "maroviro",
        fokontany: "morahariva",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8087439,
          lng: 45.4215658,
        },
        _id: "64f75285ef8f606073e9bbdc",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "maroviro",
        fokontany: "marobory",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4869692,
          lng: 45.41921319999999,
        },
        _id: "64f75285ef8f606073e9bbdd",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "mahasoabe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3746082,
          lng: 45.3886399,
        },
        _id: "64f75285ef8f606073e9bbde",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "androtsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4054745,
          lng: 45.3768862,
        },
        _id: "64f75285ef8f606073e9bbdf",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "ambonaivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4204957,
          lng: 45.3604361,
        },
        _id: "64f75285ef8f606073e9bbe0",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "antsakoandahy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4097736,
          lng: 45.4074519,
        },
        _id: "64f75285ef8f606073e9bbe1",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "ampanamperandrotsy I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4108446,
          lng: 45.4356841,
        },
        _id: "64f75285ef8f606073e9bbe2",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "ambatomanamana",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4153968,
          lng: 45.4733534,
        },
        _id: "64f75285ef8f606073e9bbe3",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "liolambo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4296441,
          lng: 45.4356841,
        },
        _id: "64f75285ef8f606073e9bbe4",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "andranovovo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3995714,
          lng: 45.3862889,
        },
        _id: "64f75285ef8f606073e9bbe5",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "ampanamperandrotsy II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4379635,
          lng: 45.4074519,
        },
        _id: "64f75285ef8f606073e9bbe6",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "ankily",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4401172,
          lng: 45.4639333,
        },
        _id: "64f75285ef8f606073e9bbe7",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "remanongo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4462701,
          lng: 45.3792367,
        },
        _id: "64f75285ef8f606073e9bbe8",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "antsakoamiary II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4501438,
          lng: 45.3580865,
        },
        _id: "64f75285ef8f606073e9bbe9",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "anarabe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4602519,
          lng: 45.41686070000001,
        },
        _id: "64f75285ef8f606073e9bbea",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "marorano anja",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4544896,
          lng: 45.4709982,
        },
        _id: "64f75285ef8f606073e9bbeb",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "anjatoana anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4704661,
          lng: 45.4380375,
        },
        _id: "64f75285ef8f606073e9bbec",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4759598,
          lng: 45.4180369,
        },
        _id: "64f75285ef8f606073e9bbed",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "mahasoa II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4650817,
          lng: 45.544065,
        },
        _id: "64f75285ef8f606073e9bbee",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "andranomavo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4836339,
          lng: 45.454515,
        },
        _id: "64f75285ef8f606073e9bbef",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "tanantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4838434,
          lng: 45.3792367,
        },
        _id: "64f75285ef8f606073e9bbf0",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "mahasoa I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5112891,
          lng: 45.4403911,
        },
        _id: "64f75285ef8f606073e9bbf1",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "ampanamperandrotsy sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5016524,
          lng: 45.5157676,
        },
        _id: "64f75285ef8f606073e9bbf2",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5129366,
          lng: 45.3627857,
        },
        _id: "64f75285ef8f606073e9bbf3",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "andranofoty",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5249194,
          lng: 45.3886399,
        },
        _id: "64f75285ef8f606073e9bbf4",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "ambasy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5292575,
          lng: 45.41921319999999,
        },
        _id: "64f75285ef8f606073e9bbf5",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "berohondroho",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5322854,
          lng: 45.4969122,
        },
        _id: "64f75285ef8f606073e9bbf6",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "marosaraka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.562347,
          lng: 45.4639333,
        },
        _id: "64f75285ef8f606073e9bbf7",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "vohidroa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5881622,
          lng: 45.4827755,
        },
        _id: "64f75285ef8f606073e9bbf8",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "belindo mahasoa",
        fokontany: "andranomihery",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4396664,
          lng: 45.1354189,
        },
        _id: "64f75285ef8f606073e9bbf9",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beteza",
        fokontany: "beteza centre",
        __v: 0,
      },
      {
        coords: {
          lat: -24.396977,
          lng: 45.0840062,
        },
        _id: "64f75285ef8f606073e9bbfa",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beteza",
        fokontany: "ankilivalo haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3577325,
          lng: 45.1167167,
        },
        _id: "64f75285ef8f606073e9bbfb",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beteza",
        fokontany: "androidroy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3775958,
          lng: 45.1447728,
        },
        _id: "64f75285ef8f606073e9bbfc",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beteza",
        fokontany: "antsakoampolo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3858283,
          lng: 45.1167167,
        },
        _id: "64f75285ef8f606073e9bbfd",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beteza",
        fokontany: "ankarenantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4067895,
          lng: 45.0571542,
        },
        _id: "64f75285ef8f606073e9bbfe",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beteza",
        fokontany: "ankilivalo bas",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4197832,
          lng: 45.1073685,
        },
        _id: "64f75285ef8f606073e9bbff",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beteza",
        fokontany: "reromotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4132721,
          lng: 45.1775265,
        },
        _id: "64f75285ef8f606073e9bc00",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beteza",
        fokontany: "antsosa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4560708,
          lng: 45.07933509999999,
        },
        _id: "64f75285ef8f606073e9bc01",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beteza",
        fokontany: "anjamivony",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4595549,
          lng: 45.1634864,
        },
        _id: "64f75285ef8f606073e9bc02",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beteza",
        fokontany: "antsakoambevotse",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4742052,
          lng: 45.1400956,
        },
        _id: "64f75285ef8f606073e9bc03",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beteza",
        fokontany: "marofototsy II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5216286,
          lng: 45.1541287,
        },
        _id: "64f75285ef8f606073e9bc04",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beteza",
        fokontany: "ambalatsaraky sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5649509,
          lng: 45.1447728,
        },
        _id: "64f75285ef8f606073e9bc05",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beteza",
        fokontany: "pelaraiky belamoty",
        __v: 0,
      },
      {
        coords: {
          lat: -24.523376,
          lng: 45.1213916,
        },
        _id: "64f75285ef8f606073e9bc06",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beteza",
        fokontany: "sarakaty",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2000809,
          lng: 45.5016253,
        },
        _id: "64f75285ef8f606073e9bc07",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "sarisambo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1302857,
          lng: 45.4780642,
        },
        _id: "64f75285ef8f606073e9bc08",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "sakara haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1510927,
          lng: 45.4450986,
        },
        _id: "64f75285ef8f606073e9bc09",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "sakara bas",
        __v: 0,
      },
      {
        coords: {
          lat: -24.162933,
          lng: 45.5157676,
        },
        _id: "64f75285ef8f606073e9bc0a",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "iabomary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1797927,
          lng: 45.4592239,
        },
        _id: "64f75285ef8f606073e9bc0b",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "anataky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1748402,
          lng: 45.4074519,
        },
        _id: "64f75285ef8f606073e9bc0c",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "morafeno ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2104714,
          lng: 45.4403911,
        },
        _id: "64f75285ef8f606073e9bc0d",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "ambatosola",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2485822,
          lng: 45.454515,
        },
        _id: "64f75285ef8f606073e9bc0e",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "analamary II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2337386,
          lng: 45.5676592,
        },
        _id: "64f75285ef8f606073e9bc0f",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "besakoa soaravy I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2079844,
          lng: 45.54878290000001,
        },
        _id: "64f75285ef8f606073e9bc10",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2401831,
          lng: 45.4827755,
        },
        _id: "64f75285ef8f606073e9bc11",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "analamary I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2585164,
          lng: 45.5582201,
        },
        _id: "64f75285ef8f606073e9bc12",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "besakoa soaravy II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2333329,
          lng: 45.4166669,
        },
        _id: "64f75285ef8f606073e9bc13",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "bedona",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2700972,
          lng: 45.4427448,
        },
        _id: "64f75285ef8f606073e9bc14",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "mahasoa bas",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2743332,
          lng: 45.4733534,
        },
        _id: "64f75285ef8f606073e9bc15",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "ambararata toby II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3025467,
          lng: 45.4733534,
        },
        _id: "64f75285ef8f606073e9bc16",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "ambararata toby I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2570278,
          lng: 45.5157676,
        },
        _id: "64f75285ef8f606073e9bc17",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "ambirimena I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2966705,
          lng: 45.5723794,
        },
        _id: "64f75285ef8f606073e9bc18",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "belamoty ikovy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.316667,
          lng: 45.55,
        },
        _id: "64f75285ef8f606073e9bc19",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -24.316667,
          lng: 45.55,
        },
        _id: "64f75285ef8f606073e9bc1a",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "belamoty",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3560112,
          lng: 45.4780642,
        },
        _id: "64f75285ef8f606073e9bc1b",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "anadabolava beakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3917157,
          lng: 45.5110531,
        },
        _id: "64f75285ef8f606073e9bc1c",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "soamarama",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4140173,
          lng: 45.5204827,
        },
        _id: "64f75285ef8f606073e9bc1d",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "tanandava",
        fokontany: "andromasy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5303536,
          lng: 45.290001,
        },
        _id: "64f75285ef8f606073e9bc1e",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "bekitro centre",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4247441,
          lng: 45.3087733,
        },
        _id: "64f75285ef8f606073e9bc1f",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "ambatomainty anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4225229,
          lng: 45.2524792,
        },
        _id: "64f75285ef8f606073e9bc20",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "voravy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4604552,
          lng: 45.341643,
        },
        _id: "64f75285ef8f606073e9bc21",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "antsakoamiary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4757327,
          lng: 45.3322493,
        },
        _id: "64f75285ef8f606073e9bc22",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "befangitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4758632,
          lng: 45.2571678,
        },
        _id: "64f75285ef8f606073e9bc23",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "miary toby",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4769934,
          lng: 45.2853092,
        },
        _id: "64f75285ef8f606073e9bc24",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "miary haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4835309,
          lng: 45.21498769999999,
        },
        _id: "64f75285ef8f606073e9bc25",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4992646,
          lng: 45.2946934,
        },
        _id: "64f75285ef8f606073e9bc26",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "ankaratsokake",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4960914,
          lng: 45.2548235,
        },
        _id: "64f75285ef8f606073e9bc27",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "soaloky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5097799,
          lng: 45.3228575,
        },
        _id: "64f75285ef8f606073e9bc28",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "ankaboa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5167898,
          lng: 45.341643,
        },
        _id: "64f75285ef8f606073e9bc29",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "bemoita",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5210318,
          lng: 45.21498769999999,
        },
        _id: "64f75285ef8f606073e9bc2a",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "marosakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5317772,
          lng: 45.3252052,
        },
        _id: "64f75285ef8f606073e9bc2b",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "malangiraho",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5435928,
          lng: 45.1939121,
        },
        _id: "64f75285ef8f606073e9bc2c",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "maroasara",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5473976,
          lng: 45.172846,
        },
        _id: "64f75285ef8f606073e9bc2d",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "marokily",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5418294,
          lng: 45.2267006,
        },
        _id: "64f75285ef8f606073e9bc2e",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "betakapaka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5684928,
          lng: 45.2290435,
        },
        _id: "64f75285ef8f606073e9bc2f",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "ankilimiary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5449563,
          lng: 45.341643,
        },
        _id: "64f75285ef8f606073e9bc30",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "manambahy I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5509012,
          lng: 45.2571678,
        },
        _id: "64f75285ef8f606073e9bc31",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "ambato",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5819532,
          lng: 45.1775265,
        },
        _id: "64f75285ef8f606073e9bc32",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "vohitsosy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5825549,
          lng: 45.2665464,
        },
        _id: "64f75285ef8f606073e9bc33",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "antsaha",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5886991,
          lng: 45.3017328,
        },
        _id: "64f75285ef8f606073e9bc34",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "ankilimanintsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5889611,
          lng: 45.3463405,
        },
        _id: "64f75285ef8f606073e9bc35",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "bevositra",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6546666,
          lng: 45.2712363,
        },
        _id: "64f75285ef8f606073e9bc36",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "sakoantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6023368,
          lng: 45.2349013,
        },
        _id: "64f75285ef8f606073e9bc37",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "ambaro abo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.621822,
          lng: 45.2337297,
        },
        _id: "64f75285ef8f606073e9bc38",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "andolobory I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6381756,
          lng: 45.1775265,
        },
        _id: "64f75285ef8f606073e9bc39",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "sarotrandro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6710251,
          lng: 45.21498769999999,
        },
        _id: "64f75285ef8f606073e9bc3a",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "andolobory II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6570295,
          lng: 45.3275531,
        },
        _id: "64f75285ef8f606073e9bc3b",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "marovaho",
        __v: 0,
      },
      {
        coords: {
          lat: -24.697505,
          lng: 45.3228575,
        },
        _id: "64f75285ef8f606073e9bc3c",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "ankiliarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6796192,
          lng: 45.3439917,
        },
        _id: "64f75285ef8f606073e9bc3d",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bekitro",
        fokontany: "beangily",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1810274,
          lng: 45.6810754,
        },
        _id: "64f75285ef8f606073e9bc3e",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "beraketa centre",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0473655,
          lng: 45.7284124,
        },
        _id: "64f75285ef8f606073e9bc3f",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2371161,
          lng: 45.6668835,
        },
        _id: "64f75285ef8f606073e9bc40",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "ambalasaraka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1361326,
          lng: 45.5582201,
        },
        _id: "64f75285ef8f606073e9bc41",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "ankatrafay",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2023977,
          lng: 45.6621538,
        },
        _id: "64f75285ef8f606073e9bc42",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "andriabe haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1566541,
          lng: 45.704738,
        },
        _id: "64f75285ef8f606073e9bc43",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "amboasary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1359575,
          lng: 45.6479676,
        },
        _id: "64f75285ef8f606073e9bc44",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "ambondrombe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0906339,
          lng: 45.5110531,
        },
        _id: "64f75285ef8f606073e9bc45",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "ankelivaho",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1223163,
          lng: 45.5204827,
        },
        _id: "64f75285ef8f606073e9bc46",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "antsely be",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1672151,
          lng: 45.6432398,
        },
        _id: "64f75285ef8f606073e9bc47",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "analasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2768285,
          lng: 45.6337856,
        },
        _id: "64f75285ef8f606073e9bc48",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "beadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1113403,
          lng: 45.5676592,
        },
        _id: "64f75285ef8f606073e9bc49",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "belamoty antsagnira",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1584751,
          lng: 45.76157629999999,
        },
        _id: "64f75285ef8f606073e9bc4a",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "besakoa ifarantsa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2067921,
          lng: 45.7000045,
        },
        _id: "64f75285ef8f606073e9bc4b",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "ifarantsa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1786779,
          lng: 45.6101584,
        },
        _id: "64f75285ef8f606073e9bc4c",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "fenoarivo analasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1152581,
          lng: 45.5912652,
        },
        _id: "64f75285ef8f606073e9bc4d",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "latsahambola",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2351963,
          lng: 45.6101584,
        },
        _id: "64f75285ef8f606073e9bc4e",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0246286,
          lng: 45.704738,
        },
        _id: "64f75285ef8f606073e9bc4f",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "mendoravy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0982609,
          lng: 45.6479676,
        },
        _id: "64f75285ef8f606073e9bc50",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2698133,
          lng: 45.704738,
        },
        _id: "64f75285ef8f606073e9bc51",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "tomboarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1009874,
          lng: 45.5393476,
        },
        _id: "64f75285ef8f606073e9bc52",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "beraketa",
        fokontany: "antsely toby antanantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0348358,
          lng: 45.6290592,
        },
        _id: "64f75285ef8f606073e9bc53",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "vohimanga",
        fokontany: "manakoliva",
        __v: 0,
      },
      {
        coords: {
          lat: -24.011208,
          lng: 45.5771002,
        },
        _id: "64f75285ef8f606073e9bc54",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "vohimanga",
        fokontany: "bevaho",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9731817,
          lng: 45.6668835,
        },
        _id: "64f75285ef8f606073e9bc55",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "vohimanga",
        fokontany: "manaravolo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0128325,
          lng: 45.52991420000001,
        },
        _id: "64f75285ef8f606073e9bc56",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "vohimanga",
        fokontany: "sakoapolo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0168495,
          lng: 45.508696,
        },
        _id: "64f75285ef8f606073e9bc57",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "vohimanga",
        fokontany: "vohimanga I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0174155,
          lng: 45.4780642,
        },
        _id: "64f75285ef8f606073e9bc58",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "vohimanga",
        fokontany: "mahatalaky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0518612,
          lng: 45.5723794,
        },
        _id: "64f75285ef8f606073e9bc59",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "vohimanga",
        fokontany: "besamanta",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0619372,
          lng: 45.4969122,
        },
        _id: "64f75285ef8f606073e9bc5a",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "vohimanga",
        fokontany: "vohimanga II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0857565,
          lng: 45.4592239,
        },
        _id: "64f75285ef8f606073e9bc5b",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "vohimanga",
        fokontany: "soakija",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6247345,
          lng: 45.3792367,
        },
        _id: "64f75285ef8f606073e9bc5c",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bevitiky",
        fokontany: "bevitiky centre",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5982633,
          lng: 45.4215658,
        },
        _id: "64f75285ef8f606073e9bc5d",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bevitiky",
        fokontany: "befamonty",
        __v: 0,
      },
      {
        coords: {
          lat: -24.734503,
          lng: 45.3839381,
        },
        _id: "64f75285ef8f606073e9bc5e",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bevitiky",
        fokontany: "betsintsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5965575,
          lng: 45.3792367,
        },
        _id: "64f75285ef8f606073e9bc5f",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bevitiky",
        fokontany: "anaviavy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5566074,
          lng: 45.3980449,
        },
        _id: "64f75285ef8f606073e9bc60",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bevitiky",
        fokontany: "tsioga vohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6411576,
          lng: 45.3980449,
        },
        _id: "64f75285ef8f606073e9bc61",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bevitiky",
        fokontany: "andaro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6851903,
          lng: 45.4027482,
        },
        _id: "64f75285ef8f606073e9bc62",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bevitiky",
        fokontany: "antsatra",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6711105,
          lng: 45.3651355,
        },
        _id: "64f75285ef8f606073e9bc63",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bevitiky",
        fokontany: "beangily",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5518349,
          lng: 45.4356841,
        },
        _id: "64f75285ef8f606073e9bc64",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "bevitiky",
        fokontany: "vatoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3561742,
          lng: 45.6574246,
        },
        _id: "64f75285ef8f606073e9bc65",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "anivorano mitsinjo",
        fokontany: "anivorano mitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.307577,
          lng: 45.6148829,
        },
        _id: "64f75285ef8f606073e9bc66",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "anivorano mitsinjo",
        fokontany: "ankilimivory esogno",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3006009,
          lng: 45.685807,
        },
        _id: "64f75285ef8f606073e9bc67",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "anivorano mitsinjo",
        fokontany: "tomboarivo betsiriry",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3879221,
          lng: 45.6668835,
        },
        _id: "64f75285ef8f606073e9bc68",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "anivorano mitsinjo",
        fokontany: "tsanerena",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3636145,
          lng: 45.690539,
        },
        _id: "64f75285ef8f606073e9bc69",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "anivorano mitsinjo",
        fokontany: "androtsabo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3611286,
          lng: 45.7094719,
        },
        _id: "64f75285ef8f606073e9bc6a",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "anivorano mitsinjo",
        fokontany: "antondritsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3933284,
          lng: 45.5983492,
        },
        _id: "64f75285ef8f606073e9bc6b",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "anivorano mitsinjo",
        fokontany: "vohitany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4186619,
          lng: 45.6479676,
        },
        _id: "64f75285ef8f606073e9bc6c",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "anivorano mitsinjo",
        fokontany: "lapeloha",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4101654,
          lng: 45.586543,
        },
        _id: "64f75285ef8f606073e9bc6d",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "anivorano mitsinjo",
        fokontany: "mikaikarivo haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3719908,
          lng: 45.5723794,
        },
        _id: "64f75285ef8f606073e9bc6e",
        province: "toliary",
        region: "androy",
        district: "bekily",
        commune: "anivorano mitsinjo",
        fokontany: "mikaikarivo bas",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0296448,
          lng: 46.9969096,
        },
        _id: "64f75285ef8f606073e9bc6f",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "fort-dauphin",
        fokontany: "bazaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0066703,
          lng: 46.9877982,
        },
        _id: "64f75285ef8f606073e9bc70",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "fort-dauphin",
        fokontany: "amparihy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0079764,
          lng: 46.9780812,
        },
        _id: "64f75285ef8f606073e9bc71",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "fort-dauphin",
        fokontany: "amboanato",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0249748,
          lng: 46.9871908,
        },
        _id: "64f75285ef8f606073e9bc72",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "fort-dauphin",
        fokontany: "esokaka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.027799,
          lng: 46.9884056,
        },
        _id: "64f75285ef8f606073e9bc73",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "fort-dauphin",
        fokontany: "ampasikabo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0278538,
          lng: 46.9768667,
        },
        _id: "64f75285ef8f606073e9bc74",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "fort-dauphin",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0312178,
          lng: 46.9562248,
        },
        _id: "64f75285ef8f606073e9bc75",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "fort-dauphin",
        fokontany: "ampamakiambato",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0341533,
          lng: 46.9744378,
        },
        _id: "64f75285ef8f606073e9bc76",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "fort-dauphin",
        fokontany: "ambinanikely",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0318183,
          lng: 46.9896204,
        },
        _id: "64f75285ef8f606073e9bc77",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "fort-dauphin",
        fokontany: "ampotatra",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0371953,
          lng: 46.9963021,
        },
        _id: "64f75285ef8f606073e9bc78",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "fort-dauphin",
        fokontany: "bazarikely",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0645986,
          lng: 46.94105219999999,
        },
        _id: "64f75285ef8f606073e9bc79",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "fort-dauphin",
        fokontany: "ambinanibe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9743072,
          lng: 46.9707947,
        },
        _id: "64f75285ef8f606073e9bc7a",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ampasy nahampoana",
        fokontany: "ampasy centre",
        __v: 0,
      },
      {
        coords: {
          lat: -24.926094,
          lng: 46.9562248,
        },
        _id: "64f75285ef8f606073e9bc7b",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ampasy nahampoana",
        fokontany: "ambaniala",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9238849,
          lng: 46.9902277,
        },
        _id: "64f75285ef8f606073e9bc7c",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ampasy nahampoana",
        fokontany: "mangaiky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9454898,
          lng: 47.0777685,
        },
        _id: "64f75285ef8f606073e9bc7d",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mandromondromotra",
        fokontany: "mandromondromotra",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9202389,
          lng: 47.08750449999999,
        },
        _id: "64f75285ef8f606073e9bc7e",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mandromondromotra",
        fokontany: "vatoroka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9585226,
          lng: 47.08750449999999,
        },
        _id: "64f75285ef8f606073e9bc7f",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mandromondromotra",
        fokontany: "hovatraha",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0014683,
          lng: 46.8821593,
        },
        _id: "64f75285ef8f606073e9bc80",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "soanierana",
        fokontany: "soanierana",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0224904,
          lng: 46.8785892,
        },
        _id: "64f75285ef8f606073e9bc81",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "soanierana",
        fokontany: "andramaka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9725844,
          lng: 46.91254010000001,
        },
        _id: "64f75285ef8f606073e9bc82",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "soanierana",
        fokontany: "analahova",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0233803,
          lng: 46.9076886,
        },
        _id: "64f75285ef8f606073e9bc83",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "soanierana",
        fokontany: "ankarefo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9990746,
          lng: 46.9465138,
        },
        _id: "64f75285ef8f606073e9bc84",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "soanierana",
        fokontany: "andranara",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0396693,
          lng: 46.9198183,
        },
        _id: "64f75285ef8f606073e9bc85",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "soanierana",
        fokontany: "lafiatsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8900746,
          lng: 46.9222445,
        },
        _id: "64f75285ef8f606073e9bc86",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ifarantsa",
        fokontany: "ifarantsa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.941755,
          lng: 46.839816,
        },
        _id: "64f75285ef8f606073e9bc87",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ifarantsa",
        fokontany: "fangeha",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9747487,
          lng: 46.8785892,
        },
        _id: "64f75285ef8f606073e9bc88",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ifarantsa",
        fokontany: "vatomivarina",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9018237,
          lng: 46.8882871,
        },
        _id: "64f75285ef8f606073e9bc89",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ifarantsa",
        fokontany: "fanjahira",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8792566,
          lng: 46.8785892,
        },
        _id: "64f75285ef8f606073e9bc8a",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ifarantsa",
        fokontany: "evonje",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8965843,
          lng: 46.9270975,
        },
        _id: "64f75285ef8f606073e9bc8b",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ifarantsa",
        fokontany: "andanivato",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9183113,
          lng: 46.9076886,
        },
        _id: "64f75285ef8f606073e9bc8c",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ifarantsa",
        fokontany: "ivolo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.874844,
          lng: 46.9465138,
        },
        _id: "64f75285ef8f606073e9bc8d",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ifarantsa",
        fokontany: "ankera",
        __v: 0,
      },
      {
        coords: {
          lat: -24.845762,
          lng: 46.9319509,
        },
        _id: "64f75285ef8f606073e9bc8e",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ifarantsa",
        fokontany: "ivorona",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8308798,
          lng: 46.8567756,
        },
        _id: "64f75285ef8f606073e9bc8f",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "isaka-ivondro",
        fokontany: "ihazoambo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8135136,
          lng: 46.8616223,
        },
        _id: "64f75285ef8f606073e9bc90",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "isaka-ivondro",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8037283,
          lng: 46.8548058,
        },
        _id: "64f75285ef8f606073e9bc91",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "isaka-ivondro",
        fokontany: "isaka ivondro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7937139,
          lng: 46.8931368,
        },
        _id: "64f75285ef8f606073e9bc92",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "isaka-ivondro",
        fokontany: "erara",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7920652,
          lng: 46.83497149999999,
        },
        _id: "64f75285ef8f606073e9bc93",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "isaka-ivondro",
        fokontany: "etsilesy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8317144,
          lng: 46.88586249999999,
        },
        _id: "64f75285ef8f606073e9bc94",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "isaka-ivondro",
        fokontany: "ianapasy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8564818,
          lng: 46.8616223,
        },
        _id: "64f75285ef8f606073e9bc95",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "isaka-ivondro",
        fokontany: "montifeno",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8479804,
          lng: 46.89798690000001,
        },
        _id: "64f75285ef8f606073e9bc96",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "isaka-ivondro",
        fokontany: "tamboro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8555918,
          lng: 46.88586249999999,
        },
        _id: "64f75285ef8f606073e9bc97",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "isaka-ivondro",
        fokontany: "amboalengo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8749375,
          lng: 46.839816,
        },
        _id: "64f75285ef8f606073e9bc98",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mandiso",
        fokontany: "mandiso atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8855709,
          lng: 46.8228622,
        },
        _id: "64f75285ef8f606073e9bc99",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mandiso",
        fokontany: "mandiso andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8545614,
          lng: 46.7962319,
        },
        _id: "64f75285ef8f606073e9bc9a",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mandiso",
        fokontany: "tranoambo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8977152,
          lng: 46.8034933,
        },
        _id: "64f75285ef8f606073e9bc9b",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mandiso",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9076942,
          lng: 46.81801919999999,
        },
        _id: "64f75285ef8f606073e9bc9c",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mandiso",
        fokontany: "soamanonga",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9020554,
          lng: 46.8422385,
        },
        _id: "64f75285ef8f606073e9bc9d",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mandiso",
        fokontany: "nagnarena",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0232612,
          lng: 46.8010727,
        },
        _id: "64f75285ef8f606073e9bc9e",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "manambaro",
        fokontany: "manambaro I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0458273,
          lng: 46.8107557,
        },
        _id: "64f75285ef8f606073e9bc9f",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "manambaro",
        fokontany: "manambaro II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0641659,
          lng: 46.8882871,
        },
        _id: "64f75285ef8f606073e9bca0",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "manambaro",
        fokontany: "ambovo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9738665,
          lng: 46.8495065,
        },
        _id: "64f75285ef8f606073e9bca1",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "manambaro",
        fokontany: "esalo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9291021,
          lng: 46.7381783,
        },
        _id: "64f75285ef8f606073e9bca2",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "manambaro",
        fokontany: "malio",
        __v: 0,
      },
      {
        coords: {
          lat: -24.956702,
          lng: 46.8083348,
        },
        _id: "64f75285ef8f606073e9bca3",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "manambaro",
        fokontany: "manalo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9990227,
          lng: 46.839816,
        },
        _id: "64f75285ef8f606073e9bca4",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "manambaro",
        fokontany: "nosibe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0662875,
          lng: 46.8543525,
        },
        _id: "64f75285ef8f606073e9bca5",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "manambaro",
        fokontany: "tsihary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8909635,
          lng: 46.7381783,
        },
        _id: "64f75285ef8f606073e9bca6",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "manambaro",
        fokontany: "elarina",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9720802,
          lng: 46.7913915,
        },
        _id: "64f75285ef8f606073e9bca7",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "manambaro",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0215931,
          lng: 46.8495065,
        },
        _id: "64f75285ef8f606073e9bca8",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "manambaro",
        fokontany: "antanamasy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0925912,
          lng: 46.7817122,
        },
        _id: "64f75285ef8f606073e9bca9",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "sarisambo",
        fokontany: "antsiranana",
        __v: 0,
      },
      {
        coords: {
          lat: -25.10118,
          lng: 46.7526854,
        },
        _id: "64f75285ef8f606073e9bcaa",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "sarisambo",
        fokontany: "amboavola",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1177058,
          lng: 46.7720347,
        },
        _id: "64f75285ef8f606073e9bcab",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "sarisambo",
        fokontany: "anjanatanimena I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1460716,
          lng: 46.7647779,
        },
        _id: "64f75285ef8f606073e9bcac",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "sarisambo",
        fokontany: "italy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1309762,
          lng: 46.7889715,
        },
        _id: "64f75285ef8f606073e9bcad",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "sarisambo",
        fokontany: "anjanatanimena II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1145899,
          lng: 46.8228622,
        },
        _id: "64f75285ef8f606073e9bcae",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "sarisambo",
        fokontany: "mokala",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0814272,
          lng: 46.83012739999999,
        },
        _id: "64f75285ef8f606073e9bcaf",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "sarisambo",
        fokontany: "loharano",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1033029,
          lng: 46.81801919999999,
        },
        _id: "64f75285ef8f606073e9bcb0",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "sarisambo",
        fokontany: "ankarinoro",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0804984,
          lng: 46.8010727,
        },
        _id: "64f75285ef8f606073e9bcb1",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "sarisambo",
        fokontany: "anena",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0342074,
          lng: 46.7454314,
        },
        _id: "64f75285ef8f606073e9bcb2",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ankaramena",
        fokontany: "ambolifasy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0128063,
          lng: 46.7720347,
        },
        _id: "64f75285ef8f606073e9bcb3",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ankaramena",
        fokontany: "analandravy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9723685,
          lng: 46.6995133,
        },
        _id: "64f75285ef8f606073e9bcb4",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ankaramena",
        fokontany: "andohavondro",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0544423,
          lng: 46.7817122,
        },
        _id: "64f75285ef8f606073e9bcb5",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ankaramena",
        fokontany: "androdava",
        __v: 0,
      },
      {
        coords: {
          lat: -25.016667,
          lng: 46.75,
        },
        _id: "64f75285ef8f606073e9bcb6",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ankaramena",
        fokontany: "ankaramena",
        __v: 0,
      },
      {
        coords: {
          lat: -25.066535,
          lng: 46.7623591,
        },
        _id: "64f75285ef8f606073e9bcb7",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ankaramena",
        fokontany: "ankilitsiarova",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0303676,
          lng: 46.7744539,
        },
        _id: "64f75285ef8f606073e9bcb8",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ankaramena",
        fokontany: "befeno",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0364166,
          lng: 46.7647779,
        },
        _id: "64f75285ef8f606073e9bcb9",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ankaramena",
        fokontany: "ebobaka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0655931,
          lng: 46.7333436,
        },
        _id: "64f75285ef8f606073e9bcba",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ankaramena",
        fokontany: "manisy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0368787,
          lng: 46.7297178,
        },
        _id: "64f75285ef8f606073e9bcbb",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ankaramena",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9737473,
          lng: 46.7430136,
        },
        _id: "64f75285ef8f606073e9bcbc",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ankaramena",
        fokontany: "taviala",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0332713,
          lng: 46.7164256,
        },
        _id: "64f75285ef8f606073e9bcbd",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ankaramena",
        fokontany: "tsiatoro",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0521377,
          lng: 46.686229,
        },
        _id: "64f75285ef8f606073e9bcbe",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranopiso",
        fokontany: "ranopiso",
        __v: 0,
      },
      {
        coords: {
          lat: -25.087198,
          lng: 46.71400920000001,
        },
        _id: "64f75285ef8f606073e9bcbf",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranopiso",
        fokontany: "andrebasy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0947922,
          lng: 46.65605069999999,
        },
        _id: "64f75285ef8f606073e9bcc0",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranopiso",
        fokontany: "ambario",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0767099,
          lng: 46.6850215,
        },
        _id: "64f75285ef8f606073e9bcc1",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranopiso",
        fokontany: "bemavoriky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0992618,
          lng: 46.6946822,
        },
        _id: "64f75285ef8f606073e9bcc2",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranopiso",
        fokontany: "fenoatsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1205975,
          lng: 46.6681199,
        },
        _id: "64f75285ef8f606073e9bcc3",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranopiso",
        fokontany: "ankilivalo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1255844,
          lng: 46.6983054,
        },
        _id: "64f75285ef8f606073e9bcc4",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranopiso",
        fokontany: "marovatoforiha",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1157872,
          lng: 46.71400920000001,
        },
        _id: "64f75285ef8f606073e9bcc5",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranopiso",
        fokontany: "midriso",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0185983,
          lng: 46.65605069999999,
        },
        _id: "64f75285ef8f606073e9bcc6",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranopiso",
        fokontany: "ankazofotsy I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9839864,
          lng: 46.6657058,
        },
        _id: "64f75285ef8f606073e9bcc7",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranopiso",
        fokontany: "ankazofotsy II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0662204,
          lng: 46.65605069999999,
        },
        _id: "64f75285ef8f606073e9bcc8",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranopiso",
        fokontany: "maromainty",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0401821,
          lng: 46.6367462,
        },
        _id: "64f75285ef8f606073e9bcc9",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranopiso",
        fokontany: "ampikazo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0592267,
          lng: 46.6367462,
        },
        _id: "64f75285ef8f606073e9bcca",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranopiso",
        fokontany: "lambova",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0747718,
          lng: 46.6270967,
        },
        _id: "64f75285ef8f606073e9bccb",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranopiso",
        fokontany: "bedaro",
        __v: 0,
      },
      {
        coords: {
          lat: -25.098236,
          lng: 46.7115929,
        },
        _id: "64f75285ef8f606073e9bccc",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranopiso",
        fokontany: "eloviky ampangalakongo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7670141,
          lng: 46.6319212,
        },
        _id: "64f75285ef8f606073e9bccd",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ambatoabo",
        fokontany: "ambatoabo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7167847,
          lng: 46.6512239,
        },
        _id: "64f75285ef8f606073e9bcce",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ambatoabo",
        fokontany: "ampahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7748187,
          lng: 46.6801919,
        },
        _id: "64f75285ef8f606073e9bccf",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ambatoabo",
        fokontany: "evasia",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8268148,
          lng: 46.7188421,
        },
        _id: "64f75285ef8f606073e9bcd0",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ambatoabo",
        fokontany: "talakifeno",
        __v: 0,
      },
      {
        coords: {
          lat: -24.73251,
          lng: 46.60574,
        },
        _id: "64f75285ef8f606073e9bcd1",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ambatoabo",
        fokontany: "marovotry",
        __v: 0,
      },
      {
        coords: {
          lat: -24.82024,
          lng: 46.6078033,
        },
        _id: "64f75285ef8f606073e9bcd2",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ambatoabo",
        fokontany: "betanimena",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7977239,
          lng: 46.5981595,
        },
        _id: "64f75285ef8f606073e9bcd3",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ambatoabo",
        fokontany: "maromena ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7661337,
          lng: 46.60298119999999,
        },
        _id: "64f75285ef8f606073e9bcd4",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ambatoabo",
        fokontany: "marotoko",
        __v: 0,
      },
      {
        coords: {
          lat: -25,
          lng: 46.6,
        },
        _id: "64f75285ef8f606073e9bcd5",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ankariera",
        fokontany: "bevilany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9896932,
          lng: 46.5499681,
        },
        _id: "64f75285ef8f606073e9bcd6",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ankariera",
        fokontany: "ranomainty anjamahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0903124,
          lng: 46.61744909999999,
        },
        _id: "64f75285ef8f606073e9bcd7",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ankariera",
        fokontany: "andrevavo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9314778,
          lng: 46.612626,
        },
        _id: "64f75285ef8f606073e9bcd8",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ankariera",
        fokontany: "tsimelahy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0447494,
          lng: 46.5836972,
        },
        _id: "64f75285ef8f606073e9bcd9",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ankariera",
        fokontany: "berongo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1723454,
          lng: 46.6958899,
        },
        _id: "64f75285ef8f606073e9bcda",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "analapatsy",
        fokontany: "analapatsy I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1714561,
          lng: 46.7164256,
        },
        _id: "64f75285ef8f606073e9bcdb",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "analapatsy",
        fokontany: "analapatsy II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1596838,
          lng: 46.6970977,
        },
        _id: "64f75285ef8f606073e9bcdc",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "analapatsy",
        fokontany: "maroaky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1574069,
          lng: 46.7236754,
        },
        _id: "64f75285ef8f606073e9bcdd",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "analapatsy",
        fokontany: "ankilimitraha",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1805008,
          lng: 46.65605069999999,
        },
        _id: "64f75285ef8f606073e9bcde",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "analapatsy",
        fokontany: "ambarobe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1401,
          lng: 46.737011,
        },
        _id: "64f75285ef8f606073e9bcdf",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "analapatsy",
        fokontany: "mitriaky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1297809,
          lng: 46.7526854,
        },
        _id: "64f75285ef8f606073e9bce0",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "analapatsy",
        fokontany: "agnapoly",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1719738,
          lng: 46.6850215,
        },
        _id: "64f75285ef8f606073e9bce1",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "analapatsy",
        fokontany: "namanona",
        __v: 0,
      },
      {
        coords: {
          lat: -25.146899,
          lng: 46.6946822,
        },
        _id: "64f75285ef8f606073e9bce2",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "analapatsy",
        fokontany: "andrivory",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1363896,
          lng: 46.6657058,
        },
        _id: "64f75285ef8f606073e9bce3",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "analapatsy",
        fokontany: "marovatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1749868,
          lng: 46.6343336,
        },
        _id: "64f75285ef8f606073e9bce4",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "analapatsy",
        fokontany: "belay ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1063495,
          lng: 46.5307047,
        },
        _id: "64f75285ef8f606073e9bce5",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "andranobory",
        fokontany: "andranobory I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1228761,
          lng: 46.5499681,
        },
        _id: "64f75285ef8f606073e9bce6",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "andranobory",
        fokontany: "andranobory centre",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1243488,
          lng: 46.5018235,
        },
        _id: "64f75285ef8f606073e9bce7",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "andranobory",
        fokontany: "ampasindava marosivaky",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0883303,
          lng: 46.5596027,
        },
        _id: "64f75285ef8f606073e9bce8",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "andranobory",
        fokontany: "anagnorasa",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1403997,
          lng: 46.5523766,
        },
        _id: "64f75285ef8f606073e9bce9",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "andranobory",
        fokontany: "andoharano",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1408788,
          lng: 46.5210757,
        },
        _id: "64f75285ef8f606073e9bcea",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "andranobory",
        fokontany: "ankazomalany",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1774609,
          lng: 46.5692391,
        },
        _id: "64f75285ef8f606073e9bceb",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "andranobory",
        fokontany: "andemby",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1704664,
          lng: 46.5957488,
        },
        _id: "64f75285ef8f606073e9bcec",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "andranobory",
        fokontany: "belay",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1469267,
          lng: 46.60298119999999,
        },
        _id: "64f75285ef8f606073e9bced",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "andranobory",
        fokontany: "ampihamy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1118626,
          lng: 46.5981595,
        },
        _id: "64f75285ef8f606073e9bcee",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "andranobory",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.154923,
          lng: 46.5596027,
        },
        _id: "64f75285ef8f606073e9bcef",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "andranobory",
        fokontany: "morafeno sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7670865,
          lng: 47.08750449999999,
        },
        _id: "64f75285ef8f606073e9bcf0",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mahatalaky",
        fokontany: "mahatalaky eroanga",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6330568,
          lng: 47.08750449999999,
        },
        _id: "64f75285ef8f606073e9bcf1",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mahatalaky",
        fokontany: "volobe nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6576174,
          lng: 47.0485715,
        },
        _id: "64f75285ef8f606073e9bcf2",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mahatalaky",
        fokontany: "volobe sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7049644,
          lng: 47.1557087,
        },
        _id: "64f75285ef8f606073e9bcf3",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mahatalaky",
        fokontany: "ebakika",
        __v: 0,
      },
      {
        coords: {
          lat: -24.769991,
          lng: 47.19593,
        },
        _id: "64f75285ef8f606073e9bcf4",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mahatalaky",
        fokontany: "sainte luce",
        __v: 0,
      },
      {
        coords: {
          lat: -24.86281,
          lng: 47.08750449999999,
        },
        _id: "64f75285ef8f606073e9bcf5",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mahatalaky",
        fokontany: "belavenoka andriambe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8351793,
          lng: 47.0096682,
        },
        _id: "64f75285ef8f606073e9bcf6",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mahatalaky",
        fokontany: "farafara vatambe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8214601,
          lng: 47.0923732,
        },
        _id: "64f75285ef8f606073e9bcf7",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mahatalaky",
        fokontany: "tsiharoa ampasy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7161532,
          lng: 47.0923732,
        },
        _id: "64f75285ef8f606073e9bcf8",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mahatalaky",
        fokontany: "mananara I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7831189,
          lng: 46.9707947,
        },
        _id: "64f75285ef8f606073e9bcf9",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mahatalaky",
        fokontany: "mananara II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7441218,
          lng: 47.0631679,
        },
        _id: "64f75285ef8f606073e9bcfa",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mahatalaky",
        fokontany: "tsanoriha",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8191651,
          lng: 47.1264672,
        },
        _id: "64f75285ef8f606073e9bcfb",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mahatalaky",
        fokontany: "tsiharoa ambondro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7783773,
          lng: 47.0315477,
        },
        _id: "64f75285ef8f606073e9bcfc",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mahatalaky",
        fokontany: "beandry",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7395342,
          lng: 47.0096682,
        },
        _id: "64f75285ef8f606073e9bcfd",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mahatalaky",
        fokontany: "tsihalagna",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7081546,
          lng: 47.0291162,
        },
        _id: "64f75285ef8f606073e9bcfe",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mahatalaky",
        fokontany: "andramanaka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8803509,
          lng: 47.0291162,
        },
        _id: "64f75285ef8f606073e9bcff",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "mahatalaky",
        fokontany: "mahialambo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.603675,
          lng: 47.1947232,
        },
        _id: "64f75285ef8f606073e9bd00",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "iaboakoho",
        fokontany: "iaboakoho",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5659926,
          lng: 47.2240036,
        },
        _id: "64f75285ef8f606073e9bd01",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "iaboakoho",
        fokontany: "antsotso",
        __v: 0,
      },
      {
        coords: {
          lat: -24.651119,
          lng: 47.215031,
        },
        _id: "64f75285ef8f606073e9bd02",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "iaboakoho",
        fokontany: "ambanihazo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.66618,
          lng: 47.192329,
        },
        _id: "64f75285ef8f606073e9bd03",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "iaboakoho",
        fokontany: "vatomirindry",
        __v: 0,
      },
      {
        coords: {
          lat: -24.65633,
          lng: 46.8750799,
        },
        _id: "64f75285ef8f606073e9bd04",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "enaniliha",
        fokontany: "enaniliha",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6056657,
          lng: 46.7671967,
        },
        _id: "64f75285ef8f606073e9bd05",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "enaniliha",
        fokontany: "baketra",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7331451,
          lng: 46.7768732,
        },
        _id: "64f75285ef8f606073e9bd06",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "enaniliha",
        fokontany: "eminiminy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6564143,
          lng: 46.7623591,
        },
        _id: "64f75285ef8f606073e9bd07",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "enaniliha",
        fokontany: "enosiary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6935498,
          lng: 46.839816,
        },
        _id: "64f75285ef8f606073e9bd08",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "enaniliha",
        fokontany: "emanindry",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6928412,
          lng: 46.8875467,
        },
        _id: "64f75285ef8f606073e9bd09",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "fenoevo-efita",
        fokontany: "fenoevo efita",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7119717,
          lng: 46.9319509,
        },
        _id: "64f75285ef8f606073e9bd0a",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "fenoevo-efita",
        fokontany: "beseva",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7436964,
          lng: 46.9270975,
        },
        _id: "64f75285ef8f606073e9bd0b",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "fenoevo-efita",
        fokontany: "mahanoro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7363987,
          lng: 46.8931368,
        },
        _id: "64f75285ef8f606073e9bd0c",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "fenoevo-efita",
        fokontany: "namanona",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6806072,
          lng: 46.8907119,
        },
        _id: "64f75285ef8f606073e9bd0d",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "fenoevo-efita",
        fokontany: "ranomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6087664,
          lng: 46.8834379,
        },
        _id: "64f75285ef8f606073e9bd0e",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "enakara-haut",
        fokontany: "enakara haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6095232,
          lng: 46.91254010000001,
        },
        _id: "64f75285ef8f606073e9bd0f",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "enakara-haut",
        fokontany: "analabe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6767998,
          lng: 46.9270975,
        },
        _id: "64f75285ef8f606073e9bd10",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "enakara-haut",
        fokontany: "fenoandala",
        __v: 0,
      },
      {
        coords: {
          lat: -24.654245,
          lng: 46.9173921,
        },
        _id: "64f75285ef8f606073e9bd11",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "enakara-haut",
        fokontany: "emoita",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5820273,
          lng: 46.83497149999999,
        },
        _id: "64f75285ef8f606073e9bd12",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "enakara-haut",
        fokontany: "androangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6385712,
          lng: 46.9270975,
        },
        _id: "64f75285ef8f606073e9bd13",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "enakara-haut",
        fokontany: "emalitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.56624,
          lng: 46.959209,
        },
        _id: "64f75285ef8f606073e9bd14",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranomafana",
        fokontany: "fenoevo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5716678,
          lng: 46.9270975,
        },
        _id: "64f75285ef8f606073e9bd15",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranomafana",
        fokontany: "marosavoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5399286,
          lng: 46.9319509,
        },
        _id: "64f75285ef8f606073e9bd16",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranomafana",
        fokontany: "ambolobe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5848427,
          lng: 46.9440864,
        },
        _id: "64f75285ef8f606073e9bd17",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranomafana",
        fokontany: "manombo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5384623,
          lng: 46.8737409,
        },
        _id: "64f75285ef8f606073e9bd18",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranomafana",
        fokontany: "ranofolo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5280205,
          lng: 46.9659376,
        },
        _id: "64f75285ef8f606073e9bd19",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranomafana",
        fokontany: "emaloto",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4695944,
          lng: 46.9222445,
        },
        _id: "64f75285ef8f606073e9bd1a",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranomafana",
        fokontany: "tananevo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5027551,
          lng: 46.9756523,
        },
        _id: "64f75285ef8f606073e9bd1b",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranomafana",
        fokontany: "enakasa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5539991,
          lng: 46.9853688,
        },
        _id: "64f75285ef8f606073e9bd1c",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranomafana",
        fokontany: "amboloavaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5777281,
          lng: 46.9780812,
        },
        _id: "64f75285ef8f606073e9bd1d",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ranomafana",
        fokontany: "mangatsiaky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6370094,
          lng: 46.9902277,
        },
        _id: "64f75285ef8f606073e9bd1e",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "emagnobo",
        fokontany: "emagnobo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6435091,
          lng: 46.9950872,
        },
        _id: "64f75285ef8f606073e9bd1f",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "emagnobo",
        fokontany: "ambahihy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6178812,
          lng: 46.9902277,
        },
        _id: "64f75285ef8f606073e9bd20",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "emagnobo",
        fokontany: "anonobe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5933396,
          lng: 47.0291162,
        },
        _id: "64f75285ef8f606073e9bd21",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "emagnobo",
        fokontany: "anataky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.668386,
          lng: 46.9707947,
        },
        _id: "64f75285ef8f606073e9bd22",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "emagnobo",
        fokontany: "soatamboro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4856833,
          lng: 47.0631679,
        },
        _id: "64f75285ef8f606073e9bd23",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "bevoay",
        fokontany: "fenoambany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4648443,
          lng: 46.9902277,
        },
        _id: "64f75285ef8f606073e9bd24",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "bevoay",
        fokontany: "ampaho",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5191644,
          lng: 46.9950872,
        },
        _id: "64f75285ef8f606073e9bd25",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "bevoay",
        fokontany: "ankily",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5082465,
          lng: 47.0729012,
        },
        _id: "64f75285ef8f606073e9bd26",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "bevoay",
        fokontany: "enekara bas",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5427723,
          lng: 47.0485715,
        },
        _id: "64f75285ef8f606073e9bd27",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "bevoay",
        fokontany: "soamanonga",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4477209,
          lng: 47.0777685,
        },
        _id: "64f75285ef8f606073e9bd28",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "bevoay",
        fokontany: "masiakena",
        __v: 0,
      },
      {
        coords: {
          lat: -24.306561,
          lng: 47.2085,
        },
        _id: "64f75285ef8f606073e9bd29",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ampasimena",
        fokontany: "ampasimena",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3674036,
          lng: 47.2044815,
        },
        _id: "64f75285ef8f606073e9bd2a",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ampasimena",
        fokontany: "amboangy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4100634,
          lng: 47.1069821,
        },
        _id: "64f75285ef8f606073e9bd2b",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ampasimena",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.411194,
          lng: 47.01452949999999,
        },
        _id: "64f75285ef8f606073e9bd2c",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ampasimena",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -24.304243,
          lng: 47.24353319999999,
        },
        _id: "64f75285ef8f606073e9bd2d",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ampasimena",
        fokontany: "tsivala",
        __v: 0,
      },
      {
        coords: {
          lat: -24.306561,
          lng: 47.2085,
        },
        _id: "64f75285ef8f606073e9bd2e",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ampasimena",
        fokontany: "ankaria",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4620119,
          lng: 47.1459597,
        },
        _id: "64f75285ef8f606073e9bd2f",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ampasimena",
        fokontany: "torondrehy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3575075,
          lng: 47.0388429,
        },
        _id: "64f75285ef8f606073e9bd30",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ampasimena",
        fokontany: "vinanitsaha",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4267503,
          lng: 46.9975171,
        },
        _id: "64f75285ef8f606073e9bd31",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ampasimena",
        fokontany: "ambotabe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3241143,
          lng: 46.9707947,
        },
        _id: "64f75285ef8f606073e9bd32",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ampasimena",
        fokontany: "ambalalangy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3548903,
          lng: 46.9222445,
        },
        _id: "64f75285ef8f606073e9bd33",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "ampasimena",
        fokontany: "andaza sakahala",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2700771,
          lng: 47.2972775,
        },
        _id: "64f75285ef8f606073e9bd34",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "manantenina",
        fokontany: "manantenina haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.516667,
          lng: 47.216667,
        },
        _id: "64f75285ef8f606073e9bd35",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "manantenina",
        fokontany: "antanitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -24.285892,
          lng: 47.2875016,
        },
        _id: "64f75285ef8f606073e9bd36",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "manantenina",
        fokontany: "manantenina bas",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5097175,
          lng: 47.2731467,
        },
        _id: "64f75285ef8f606073e9bd37",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "manantenina",
        fokontany: "manambato",
        __v: 0,
      },
      {
        coords: {
          lat: -24.366667,
          lng: 47.28333300000001,
        },
        _id: "64f75285ef8f606073e9bd38",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "manantenina",
        fokontany: "esama",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4453424,
          lng: 47.2630701,
        },
        _id: "64f75285ef8f606073e9bd39",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "manantenina",
        fokontany: "ankaramany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3245636,
          lng: 47.3021661,
        },
        _id: "64f75285ef8f606073e9bd3a",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "manantenina",
        fokontany: "ampasimasay",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2410118,
          lng: 47.2826144,
        },
        _id: "64f75285ef8f606073e9bd3b",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "manantenina",
        fokontany: "ambalateza",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2420731,
          lng: 47.3412918,
        },
        _id: "64f75285ef8f606073e9bd3c",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "manantenina",
        fokontany: "ankarefo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.24296,
          lng: 47.22271,
        },
        _id: "64f75285ef8f606073e9bd3d",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "analamary",
        fokontany: "analamary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1796021,
          lng: 47.0777685,
        },
        _id: "64f75285ef8f606073e9bd3e",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "analamary",
        fokontany: "soavala",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2945161,
          lng: 47.0777685,
        },
        _id: "64f75285ef8f606073e9bd3f",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "analamary",
        fokontany: "lapamena",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1445216,
          lng: 47.2533007,
        },
        _id: "64f75285ef8f606073e9bd40",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "soavary",
        fokontany: "soavary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1974242,
          lng: 47.3510778,
        },
        _id: "64f75285ef8f606073e9bd41",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "soavary",
        fokontany: "maroroy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1891212,
          lng: 47.24353319999999,
        },
        _id: "64f75285ef8f606073e9bd42",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "soavary",
        fokontany: "beventihazo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1429424,
          lng: 47.1654595,
        },
        _id: "64f75285ef8f606073e9bd43",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "soavary",
        fokontany: "andriambe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.092331,
          lng: 47.221519,
        },
        _id: "64f75285ef8f606073e9bd44",
        province: "toliary",
        region: "anosy",
        district: "taolagnaro",
        commune: "soavary",
        fokontany: "marokibo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2717445,
          lng: 46.0920984,
        },
        _id: "64f75285ef8f606073e9bd45",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "betroka",
        fokontany: "betroka centre",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2675348,
          lng: 46.1207392,
        },
        _id: "64f75285ef8f606073e9bd46",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "betroka",
        fokontany: "fiadanana morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2584355,
          lng: 46.0980638,
        },
        _id: "64f75285ef8f606073e9bd47",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "betroka",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2622726,
          lng: 46.0920984,
        },
        _id: "64f75285ef8f606073e9bd48",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "betroka",
        fokontany: "anjado",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2664063,
          lng: 46.1022401,
        },
        _id: "64f75285ef8f606073e9bd49",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "betroka",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2711098,
          lng: 46.1004502,
        },
        _id: "64f75285ef8f606073e9bd4a",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "betroka",
        fokontany: "tanakopania",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2812165,
          lng: 46.0920984,
        },
        _id: "64f75285ef8f606073e9bd4b",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "betroka",
        fokontany: "andakana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2774773,
          lng: 46.06109,
        },
        _id: "64f75285ef8f606073e9bd4c",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "betroka",
        fokontany: "morahariva",
        __v: 0,
      },
      {
        coords: {
          lat: -23.283333,
          lng: 45.933333,
        },
        _id: "64f75285ef8f606073e9bd4d",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "betroka",
        fokontany: "bekijoly",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3663254,
          lng: 46.0849408,
        },
        _id: "64f75285ef8f606073e9bd4e",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "betroka",
        fokontany: "akomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3827706,
          lng: 46.1183518,
        },
        _id: "64f75285ef8f606073e9bd4f",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "betroka",
        fokontany: "bevoay",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4050684,
          lng: 46.127902,
        },
        _id: "64f75285ef8f606073e9bd50",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "betroka",
        fokontany: "ianadroy nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2884713,
          lng: 46.3240951,
        },
        _id: "64f75285ef8f606073e9bd51",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "tsaraitso",
        fokontany: "tsaraitso",
        __v: 0,
      },
      {
        coords: {
          lat: -23.183333,
          lng: 46.35,
        },
        _id: "64f75285ef8f606073e9bd52",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "tsaraitso",
        fokontany: "taperapia",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2884713,
          lng: 46.3240951,
        },
        _id: "64f75285ef8f606073e9bd53",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "tsaraitso",
        fokontany: "ambondro II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2884713,
          lng: 46.3240951,
        },
        _id: "64f75285ef8f606073e9bd54",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "tsaraitso",
        fokontany: "bema",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2884713,
          lng: 46.3240951,
        },
        _id: "64f75285ef8f606073e9bd55",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "tsaraitso",
        fokontany: "analasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2884713,
          lng: 46.3240951,
        },
        _id: "64f75285ef8f606073e9bd56",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "tsaraitso",
        fokontany: "andrea",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2393378,
          lng: 46.0467851,
        },
        _id: "64f75285ef8f606073e9bd57",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "naninora",
        fokontany: "naninora",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2424045,
          lng: 46.0420177,
        },
        _id: "64f75285ef8f606073e9bd58",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "naninora",
        fokontany: "ambalavato bas",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2557381,
          lng: 46.0801697,
        },
        _id: "64f75285ef8f606073e9bd59",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "naninora",
        fokontany: "ambatovita",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2891841,
          lng: 46.0134234,
        },
        _id: "64f75285ef8f606073e9bd5a",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "naninora",
        fokontany: "vohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3086707,
          lng: 46.0420177,
        },
        _id: "64f75285ef8f606073e9bd5b",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "naninora",
        fokontany: "ambararata",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3217573,
          lng: 46.0658592,
        },
        _id: "64f75285ef8f606073e9bd5c",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "naninora",
        fokontany: "vondrony",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1382762,
          lng: 46.0420177,
        },
        _id: "64f75285ef8f606073e9bd5d",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ambalasoa",
        fokontany: "anaviavy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9442779,
          lng: 46.1517858,
        },
        _id: "64f75285ef8f606073e9bd5e",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ambalasoa",
        fokontany: "kelivaho",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0835773,
          lng: 46.1709012,
        },
        _id: "64f75285ef8f606073e9bd5f",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ambalasoa",
        fokontany: "iritsoka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0935308,
          lng: 46.0086594,
        },
        _id: "64f75285ef8f606073e9bd60",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ambalasoa",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0570957,
          lng: 46.0944845,
        },
        _id: "64f75285ef8f606073e9bd61",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ambalasoa",
        fokontany: "ambalasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.18346,
          lng: 45.97403,
        },
        _id: "64f75285ef8f606073e9bd62",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ambalasoa",
        fokontany: "fandranarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1694877,
          lng: 46.022953,
        },
        _id: "64f75285ef8f606073e9bd63",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ambalasoa",
        fokontany: "marovala atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2321335,
          lng: 45.9991326,
        },
        _id: "64f75285ef8f606073e9bd64",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ambalasoa",
        fokontany: "mahatsinjo soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.45956,
          lng: 46.165138,
        },
        _id: "64f75285ef8f606073e9bd65",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ivahona",
        fokontany: "ivahona",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4029502,
          lng: 46.3528724,
        },
        _id: "64f75285ef8f606073e9bd66",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ivahona",
        fokontany: "voromiantsa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3952413,
          lng: 46.2761704,
        },
        _id: "64f75285ef8f606073e9bd67",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ivahona",
        fokontany: "tsanirena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4245896,
          lng: 46.15656389999999,
        },
        _id: "64f75285ef8f606073e9bd68",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ivahona",
        fokontany: "ambalavato ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5325221,
          lng: 46.1804618,
        },
        _id: "64f75285ef8f606073e9bd69",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ivahona",
        fokontany: "amposaky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4907174,
          lng: 46.3049196,
        },
        _id: "64f75285ef8f606073e9bd6a",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ivahona",
        fokontany: "benonoka II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2323909,
          lng: 45.851709,
        },
        _id: "64f75285ef8f606073e9bd6b",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "analamary",
        fokontany: "analamary centre",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1892426,
          lng: 45.7426226,
        },
        _id: "64f75285ef8f606073e9bd6c",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "analamary",
        fokontany: "sambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1970902,
          lng: 45.8184824,
        },
        _id: "64f75285ef8f606073e9bd6d",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "analamary",
        fokontany: "ifataka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2391495,
          lng: 45.7236765,
        },
        _id: "64f75285ef8f606073e9bd6e",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "analamary",
        fokontany: "marora",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2835819,
          lng: 45.7426226,
        },
        _id: "64f75285ef8f606073e9bd6f",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "analamary",
        fokontany: "manombo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2592372,
          lng: 45.7805375,
        },
        _id: "64f75285ef8f606073e9bd70",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "analamary",
        fokontany: "bepia",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2793224,
          lng: 45.83746619999999,
        },
        _id: "64f75285ef8f606073e9bd71",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "analamary",
        fokontany: "iaborano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3280314,
          lng: 45.76157629999999,
        },
        _id: "64f75285ef8f606073e9bd72",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "analamary",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5092699,
          lng: 45.9800848,
        },
        _id: "64f75285ef8f606073e9bd73",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianabinda",
        fokontany: "ianabinda",
        __v: 0,
      },
      {
        coords: {
          lat: -23.416667,
          lng: 46.05,
        },
        _id: "64f75285ef8f606073e9bd74",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianabinda",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.396341,
          lng: 46.0086594,
        },
        _id: "64f75285ef8f606073e9bd75",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianabinda",
        fokontany: "ankaroka toby",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3640135,
          lng: 45.97056380000001,
        },
        _id: "64f75285ef8f606073e9bd76",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianabinda",
        fokontany: "saheny",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3811062,
          lng: 45.8849587,
        },
        _id: "64f75285ef8f606073e9bd77",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianabinda",
        fokontany: "sakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.466667,
          lng: 45.883333,
        },
        _id: "64f75285ef8f606073e9bd78",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianabinda",
        fokontany: "antanimainty",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5056612,
          lng: 45.8232277,
        },
        _id: "64f75285ef8f606073e9bd79",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianabinda",
        fokontany: "antatabe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5073275,
          lng: 45.8944628,
        },
        _id: "64f75285ef8f606073e9bd7a",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianabinda",
        fokontany: "voronkafotra toby",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5044255,
          lng: 46.0467851,
        },
        _id: "64f75285ef8f606073e9bd7b",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianabinda",
        fokontany: "sakasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5612324,
          lng: 46.0467851,
        },
        _id: "64f75285ef8f606073e9bd7c",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianabinda",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.57011,
          lng: 45.8849587,
        },
        _id: "64f75285ef8f606073e9bd7d",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianabinda",
        fokontany: "bemelo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6157019,
          lng: 45.9467693,
        },
        _id: "64f75285ef8f606073e9bd7e",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianabinda",
        fokontany: "bevaho",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6484798,
          lng: 45.9991326,
        },
        _id: "64f75285ef8f606073e9bd7f",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianabinda",
        fokontany: "amboliala",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0841506,
          lng: 45.85787,
        },
        _id: "64f75285ef8f606073e9bd80",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "benato toby",
        fokontany: "benato toby",
        __v: 0,
      },
      {
        coords: {
          lat: -22.988351,
          lng: 45.76157629999999,
        },
        _id: "64f75285ef8f606073e9bd81",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "benato toby",
        fokontany: "sambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9893788,
          lng: 45.8184824,
        },
        _id: "64f75285ef8f606073e9bd82",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "benato toby",
        fokontany: "ambinda sud",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9918273,
          lng: 45.9610446,
        },
        _id: "64f75285ef8f606073e9bd83",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "benato toby",
        fokontany: "bepeha",
        __v: 0,
      },
      {
        coords: {
          lat: -23.03938,
          lng: 45.7995062,
        },
        _id: "64f75285ef8f606073e9bd84",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "benato toby",
        fokontany: "ambatovita",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0960154,
          lng: 45.7995062,
        },
        _id: "64f75285ef8f606073e9bd85",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "benato toby",
        fokontany: "benonoky I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0858866,
          lng: 45.9324982,
        },
        _id: "64f75285ef8f606073e9bd86",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "benato toby",
        fokontany: "andriabe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1359548,
          lng: 45.9134768,
        },
        _id: "64f75285ef8f606073e9bd87",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "benato toby",
        fokontany: "itafia morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -23.167099,
          lng: 45.8944628,
        },
        _id: "64f75285ef8f606073e9bd88",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "benato toby",
        fokontany: "ivohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6682,
          lng: 46.13353,
        },
        _id: "64f75285ef8f606073e9bd89",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahabo",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5308881,
          lng: 46.4200849,
        },
        _id: "64f75285ef8f606073e9bd8a",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahabo",
        fokontany: "imotra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6683724,
          lng: 46.3240951,
        },
        _id: "64f75285ef8f606073e9bd8b",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahabo",
        fokontany: "miary iomby",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5887995,
          lng: 46.1517858,
        },
        _id: "64f75285ef8f606073e9bd8c",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahabo",
        fokontany: "ankeniheny",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6504813,
          lng: 46.0849408,
        },
        _id: "64f75285ef8f606073e9bd8d",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahabo",
        fokontany: "bekily",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6426213,
          lng: 46.15656389999999,
        },
        _id: "64f75285ef8f606073e9bd8e",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahabo",
        fokontany: "iaborano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6408206,
          lng: 46.2187227,
        },
        _id: "64f75285ef8f606073e9bd8f",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahabo",
        fokontany: "benonoka I",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7044889,
          lng: 46.2378645,
        },
        _id: "64f75285ef8f606073e9bd90",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahabo",
        fokontany: "andranomiforitra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7018903,
          lng: 46.1231267,
        },
        _id: "64f75285ef8f606073e9bd91",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahabo",
        fokontany: "mandabe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7005487,
          lng: 46.0658592,
        },
        _id: "64f75285ef8f606073e9bd92",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahabo",
        fokontany: "manevy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7056005,
          lng: 46.1470081,
        },
        _id: "64f75285ef8f606073e9bd93",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahabo",
        fokontany: "riandampy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7491792,
          lng: 45.98960779999999,
        },
        _id: "64f75285ef8f606073e9bd94",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahabo",
        fokontany: "ankarinoro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.745202,
          lng: 46.0849408,
        },
        _id: "64f75285ef8f606073e9bd95",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahabo",
        fokontany: "beadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8054763,
          lng: 46.2282927,
        },
        _id: "64f75285ef8f606073e9bd96",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahabo",
        fokontany: "ambatolahy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8270545,
          lng: 46.0753991,
        },
        _id: "64f75285ef8f606073e9bd97",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahabo",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0488389,
          lng: 45.6385125,
        },
        _id: "64f75285ef8f606073e9bd98",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "beampombo I",
        fokontany: "beampombo I",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9984062,
          lng: 45.6290592,
        },
        _id: "64f75285ef8f606073e9bd99",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "beampombo I",
        fokontany: "andranoboaky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.071994,
          lng: 45.544065,
        },
        _id: "64f75285ef8f606073e9bd9a",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "beampombo I",
        fokontany: "sakaharo ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0737697,
          lng: 45.6290592,
        },
        _id: "64f75285ef8f606073e9bd9b",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "beampombo I",
        fokontany: "anja nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1173897,
          lng: 45.7663159,
        },
        _id: "64f75285ef8f606073e9bd9c",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "beampombo I",
        fokontany: "tafia marovala",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1558218,
          lng: 45.6479676,
        },
        _id: "64f75285ef8f606073e9bd9d",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "beampombo I",
        fokontany: "anja sud",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1472867,
          lng: 45.544065,
        },
        _id: "64f75285ef8f606073e9bd9e",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "beampombo I",
        fokontany: "antanimary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.85,
          lng: 45.783333,
        },
        _id: "64f75285ef8f606073e9bd9f",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "jangany",
        fokontany: "jangany",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8553188,
          lng: 46.1135774,
        },
        _id: "64f75285ef8f606073e9bda0",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "jangany",
        fokontany: "ampety",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8,
          lng: 45.98333299999999,
        },
        _id: "64f75285ef8f606073e9bda1",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "jangany",
        fokontany: "ampandrabe",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8141915,
          lng: 45.8849587,
        },
        _id: "64f75285ef8f606073e9bda2",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "jangany",
        fokontany: "sakamahily est",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8710981,
          lng: 45.8992156,
        },
        _id: "64f75285ef8f606073e9bda3",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "jangany",
        fokontany: "ambalamary",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8496661,
          lng: 45.7426226,
        },
        _id: "64f75285ef8f606073e9bda4",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "jangany",
        fokontany: "manombo est",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8858851,
          lng: 45.6574246,
        },
        _id: "64f75285ef8f606073e9bda5",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "jangany",
        fokontany: "andriamandevy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.966667,
          lng: 45.716667,
        },
        _id: "64f75285ef8f606073e9bda6",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "jangany",
        fokontany: "ambondrolava",
        __v: 0,
      },
      {
        coords: {
          lat: -22.916667,
          lng: 45.766667,
        },
        _id: "64f75285ef8f606073e9bda7",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "jangany",
        fokontany: "ianapandra",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9011079,
          lng: 45.8089934,
        },
        _id: "64f75285ef8f606073e9bda8",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "jangany",
        fokontany: "anteny mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9223325,
          lng: 45.9515272,
        },
        _id: "64f75285ef8f606073e9bda9",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "jangany",
        fokontany: "reho mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9389189,
          lng: 45.54652,
        },
        _id: "64f75285ef8f606073e9bdaa",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahasoa est",
        fokontany: "mahasoa est",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3321694,
          lng: 45.6668835,
        },
        _id: "64f75285ef8f606073e9bdab",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahasoa est",
        fokontany: "ankatrafay antsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.349681,
          lng: 45.6101584,
        },
        _id: "64f75285ef8f606073e9bdac",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahasoa est",
        fokontany: "vohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4102524,
          lng: 45.7805375,
        },
        _id: "64f75285ef8f606073e9bdad",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahasoa est",
        fokontany: "farive",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4461903,
          lng: 45.5771002,
        },
        _id: "64f75285ef8f606073e9bdae",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahasoa est",
        fokontany: "andriabe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4597013,
          lng: 45.6148829,
        },
        _id: "64f75285ef8f606073e9bdaf",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahasoa est",
        fokontany: "soaserana atsinanana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4843904,
          lng: 45.7236765,
        },
        _id: "64f75285ef8f606073e9bdb0",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahasoa est",
        fokontany: "ampiriky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5139329,
          lng: 45.6479676,
        },
        _id: "64f75285ef8f606073e9bdb1",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahasoa est",
        fokontany: "benono",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4857628,
          lng: 45.7805375,
        },
        _id: "64f75285ef8f606073e9bdb2",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "mahasoa est",
        fokontany: "ampototsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7213172,
          lng: 45.8849587,
        },
        _id: "64f75285ef8f606073e9bdb3",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "bekorobo",
        fokontany: "manandrotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.688117,
          lng: 45.8184824,
        },
        _id: "64f75285ef8f606073e9bdb4",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "bekorobo",
        fokontany: "andalamby",
        __v: 0,
      },
      {
        coords: {
          lat: -23.737499,
          lng: 45.770729,
        },
        _id: "64f75285ef8f606073e9bdb5",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "bekorobo",
        fokontany: "mananovy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7833133,
          lng: 45.84696100000001,
        },
        _id: "64f75285ef8f606073e9bdb6",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "bekorobo",
        fokontany: "ambatomena",
        __v: 0,
      },
      {
        coords: {
          lat: -23.788059,
          lng: 45.94942899999999,
        },
        _id: "64f75285ef8f606073e9bdb7",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "bekorobo",
        fokontany: "itroho",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8668696,
          lng: 45.9087226,
        },
        _id: "64f75285ef8f606073e9bdb8",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "bekorobo",
        fokontany: "mahilivoro",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9134373,
          lng: 45.9991326,
        },
        _id: "64f75285ef8f606073e9bdb9",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "bekorobo",
        fokontany: "nanarena antondrotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2481206,
          lng: 45.5629394,
        },
        _id: "64f75285ef8f606073e9bdba",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "iaborotra",
        fokontany: "iaborotra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1858857,
          lng: 45.586543,
        },
        _id: "64f75285ef8f606073e9bdbb",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "iaborotra",
        fokontany: "ankeniheny",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1842917,
          lng: 45.5157676,
        },
        _id: "64f75285ef8f606073e9bdbc",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "iaborotra",
        fokontany: "antranotakatra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2245073,
          lng: 45.6290592,
        },
        _id: "64f75285ef8f606073e9bdbd",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "iaborotra",
        fokontany: "antsely anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.26873,
          lng: 45.575321,
        },
        _id: "64f75285ef8f606073e9bdbe",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "iaborotra",
        fokontany: "sahamandrevo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2786488,
          lng: 45.6621538,
        },
        _id: "64f75285ef8f606073e9bdbf",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "iaborotra",
        fokontany: "ankatrafay est",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8406372,
          lng: 45.7568372,
        },
        _id: "64f75285ef8f606073e9bdc0",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "isoanala",
        fokontany: "isoanala sud",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8251787,
          lng: 45.7106555,
        },
        _id: "64f75285ef8f606073e9bdc1",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "isoanala",
        fokontany: "isoanala nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7072585,
          lng: 45.5959878,
        },
        _id: "64f75285ef8f606073e9bdc2",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "isoanala",
        fokontany: "betaimbala",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7152552,
          lng: 45.6574246,
        },
        _id: "64f75285ef8f606073e9bdc3",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "isoanala",
        fokontany: "marovala toby",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7605655,
          lng: 45.704738,
        },
        _id: "64f75285ef8f606073e9bdc4",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "isoanala",
        fokontany: "andriabe haut",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7914682,
          lng: 45.685807,
        },
        _id: "64f75285ef8f606073e9bdc5",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "isoanala",
        fokontany: "lavalila",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8119306,
          lng: 45.7426226,
        },
        _id: "64f75285ef8f606073e9bdc6",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "isoanala",
        fokontany: "sarodrano",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8110162,
          lng: 45.6550602,
        },
        _id: "64f75285ef8f606073e9bdc7",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "isoanala",
        fokontany: "androka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8857567,
          lng: 45.685807,
        },
        _id: "64f75285ef8f606073e9bdc8",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "isoanala",
        fokontany: "vohitsevo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8343737,
          lng: 45.6479676,
        },
        _id: "64f75285ef8f606073e9bdc9",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "isoanala",
        fokontany: "tanandava sud",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8934262,
          lng: 45.73314860000001,
        },
        _id: "64f75285ef8f606073e9bdca",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "isoanala",
        fokontany: "beraketa sud",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9722384,
          lng: 45.84696100000001,
        },
        _id: "64f75285ef8f606073e9bdcb",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "isoanala",
        fokontany: "ambindamahery",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0074901,
          lng: 45.76157629999999,
        },
        _id: "64f75285ef8f606073e9bdcc",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "isoanala",
        fokontany: "miary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6247219,
          lng: 45.5629394,
        },
        _id: "64f75285ef8f606073e9bdcd",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "beampombo II",
        fokontany: "beampombo II",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5478762,
          lng: 45.506339,
        },
        _id: "64f75285ef8f606073e9bdce",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "beampombo II",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5847294,
          lng: 45.4780642,
        },
        _id: "64f75285ef8f606073e9bdcf",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "beampombo II",
        fokontany: "andriamero",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6471678,
          lng: 45.4686431,
        },
        _id: "64f75285ef8f606073e9bdd0",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "beampombo II",
        fokontany: "iaborano nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6752027,
          lng: 45.5723794,
        },
        _id: "64f75285ef8f606073e9bdd1",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "beampombo II",
        fokontany: "manera",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6050838,
          lng: 45.7710559,
        },
        _id: "64f75285ef8f606073e9bdd2",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "nanarena besakoa",
        fokontany: "nanarena besakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.607215,
          lng: 45.8564576,
        },
        _id: "64f75285ef8f606073e9bdd3",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "nanarena besakoa",
        fokontany: "soatana ambondrombe",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6503439,
          lng: 45.8184824,
        },
        _id: "64f75285ef8f606073e9bdd4",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "nanarena besakoa",
        fokontany: "ianakoty",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6179091,
          lng: 45.7805375,
        },
        _id: "64f75285ef8f606073e9bdd5",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "nanarena besakoa",
        fokontany: "ambararata nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.5545124,
          lng: 45.76157629999999,
        },
        _id: "64f75285ef8f606073e9bdd6",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "nanarena besakoa",
        fokontany: "tanandava nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.6149639,
          lng: 45.6668835,
        },
        _id: "64f75285ef8f606073e9bdd7",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "nanarena besakoa",
        fokontany: "marofatotra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.358259,
          lng: 45.446449,
        },
        _id: "64f75285ef8f606073e9bdd8",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianakafy",
        fokontany: "ianakafy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1944074,
          lng: 45.4121561,
        },
        _id: "64f75285ef8f606073e9bdd9",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianakafy",
        fokontany: "jangany",
        __v: 0,
      },
      {
        coords: {
          lat: -23.2890884,
          lng: 45.4403911,
        },
        _id: "64f75285ef8f606073e9bdda",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianakafy",
        fokontany: "ampandratolony",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3160209,
          lng: 45.5157676,
        },
        _id: "64f75285ef8f606073e9bddb",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianakafy",
        fokontany: "rehea",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3731833,
          lng: 45.544065,
        },
        _id: "64f75285ef8f606073e9bddc",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianakafy",
        fokontany: "ambatomihala",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3525989,
          lng: 45.4733534,
        },
        _id: "64f75285ef8f606073e9bddd",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianakafy",
        fokontany: "ankasy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3372961,
          lng: 45.3651355,
        },
        _id: "64f75285ef8f606073e9bdde",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianakafy",
        fokontany: "ankatrafay andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.386512,
          lng: 45.390991,
        },
        _id: "64f75285ef8f606073e9bddf",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianakafy",
        fokontany: "antanandava andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4289052,
          lng: 45.2806177,
        },
        _id: "64f75285ef8f606073e9bde0",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianakafy",
        fokontany: "ampandravelo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.416667,
          lng: 45.383333,
        },
        _id: "64f75285ef8f606073e9bde1",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianakafy",
        fokontany: "bekinana",
        __v: 0,
      },
      {
        coords: {
          lat: -23.3955149,
          lng: 45.4356841,
        },
        _id: "64f75285ef8f606073e9bde2",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianakafy",
        fokontany: "bekininy",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4033642,
          lng: 45.4969122,
        },
        _id: "64f75285ef8f606073e9bde3",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianakafy",
        fokontany: "ambongo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4545192,
          lng: 45.5346307,
        },
        _id: "64f75285ef8f606073e9bde4",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianakafy",
        fokontany: "ankilimary",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4237182,
          lng: 45.4356841,
        },
        _id: "64f75285ef8f606073e9bde5",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianakafy",
        fokontany: "bevaho",
        __v: 0,
      },
      {
        coords: {
          lat: -23.4838938,
          lng: 45.4592239,
        },
        _id: "64f75285ef8f606073e9bde6",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ianakafy",
        fokontany: "vohitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -22.755341,
          lng: 45.676949,
        },
        _id: "64f75285ef8f606073e9bde7",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "andriandampy",
        fokontany: "andriandampy",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7386204,
          lng: 45.8849587,
        },
        _id: "64f75285ef8f606073e9bde8",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "andriandampy",
        fokontany: "bekopay",
        __v: 0,
      },
      {
        coords: {
          lat: -22.7307881,
          lng: 45.7805375,
        },
        _id: "64f75285ef8f606073e9bde9",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "andriandampy",
        fokontany: "beangaty",
        __v: 0,
      },
      {
        coords: {
          lat: -22.8705123,
          lng: 45.506339,
        },
        _id: "64f75285ef8f606073e9bdea",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "andriandampy",
        fokontany: "mahamany",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9773089,
          lng: 45.5157676,
        },
        _id: "64f75285ef8f606073e9bdeb",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "sakamahily",
        fokontany: "sakamahily ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -22.9659926,
          lng: 45.5771002,
        },
        _id: "64f75285ef8f606073e9bdec",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "sakamahily",
        fokontany: "ambinany sud",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0319362,
          lng: 45.4309775,
        },
        _id: "64f75285ef8f606073e9bded",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "sakamahily",
        fokontany: "ambararata nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.0956486,
          lng: 45.4780642,
        },
        _id: "64f75285ef8f606073e9bdee",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "sakamahily",
        fokontany: "andoharano nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.1198749,
          lng: 45.4403911,
        },
        _id: "64f75285ef8f606073e9bdef",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "sakamahily",
        fokontany: "mahasoa nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8703813,
          lng: 45.5912652,
        },
        _id: "64f75285ef8f606073e9bdf0",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ambatomivary",
        fokontany: "ankatrakatraka",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7360623,
          lng: 45.506339,
        },
        _id: "64f75285ef8f606073e9bdf1",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ambatomivary",
        fokontany: "sevalava",
        __v: 0,
      },
      {
        coords: {
          lat: -23.7950326,
          lng: 45.5912652,
        },
        _id: "64f75285ef8f606073e9bdf2",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ambatomivary",
        fokontany: "vohitramboa",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8001991,
          lng: 45.5535013,
        },
        _id: "64f75285ef8f606073e9bdf3",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ambatomivary",
        fokontany: "antataky toby",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8173244,
          lng: 45.4969122,
        },
        _id: "64f75285ef8f606073e9bdf4",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ambatomivary",
        fokontany: "menarandra",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8994517,
          lng: 45.5157676,
        },
        _id: "64f75285ef8f606073e9bdf5",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ambatomivary",
        fokontany: "mitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9255785,
          lng: 45.54878290000001,
        },
        _id: "64f75285ef8f606073e9bdf6",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ambatomivary",
        fokontany: "iaborano sud",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9082715,
          lng: 45.5983492,
        },
        _id: "64f75285ef8f606073e9bdf7",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ambatomivary",
        fokontany: "ankilitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9696588,
          lng: 45.5535013,
        },
        _id: "64f75285ef8f606073e9bdf8",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ambatomivary",
        fokontany: "antanimary centre",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9536514,
          lng: 45.5936264,
        },
        _id: "64f75285ef8f606073e9bdf9",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ambatomivary",
        fokontany: "antanimary toby",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9406111,
          lng: 45.6290592,
        },
        _id: "64f75285ef8f606073e9bdfa",
        province: "toliary",
        region: "anosy",
        district: "betroka",
        commune: "ambatomivary",
        fokontany: "manampy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0427311,
          lng: 46.3888677,
        },
        _id: "64f75285ef8f606073e9bdfb",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "tanambe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0357764,
          lng: 46.5066359,
        },
        _id: "64f75285ef8f606073e9bdfc",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "ankilimiary",
        __v: 0,
      },
      {
        coords: {
          lat: -25.031353,
          lng: 46.3804664,
        },
        _id: "64f75285ef8f606073e9bdfd",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0339835,
          lng: 46.3876674,
        },
        _id: "64f75285ef8f606073e9bdfe",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -25.05,
          lng: 46.3,
        },
        _id: "64f75285ef8f606073e9bdff",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "bedaro",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0597093,
          lng: 46.3768663,
        },
        _id: "64f75285ef8f606073e9be00",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "S.P.S.M",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0520983,
          lng: 46.38526700000001,
        },
        _id: "64f75285ef8f606073e9be01",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "antsognognabo tanamahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0262556,
          lng: 46.415281,
        },
        _id: "64f75285ef8f606073e9be02",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "ankamena S.S.M",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0527764,
          lng: 46.4489184,
        },
        _id: "64f75285ef8f606073e9be03",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "anarabajy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0592868,
          lng: 46.2785656,
        },
        _id: "64f75285ef8f606073e9be04",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "belitsake",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0790544,
          lng: 46.3001269,
        },
        _id: "64f75285ef8f606073e9be05",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "magnaly",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0638595,
          lng: 46.3169034,
        },
        _id: "64f75285ef8f606073e9be06",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "ankilivalo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0741383,
          lng: 46.33848159999999,
        },
        _id: "64f75285ef8f606073e9be07",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "mantsake",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0680971,
          lng: 46.390068,
        },
        _id: "64f75285ef8f606073e9be08",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "behabobo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0807151,
          lng: 46.3888677,
        },
        _id: "64f75285ef8f606073e9be09",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "bevala ville",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0702402,
          lng: 46.4056745,
        },
        _id: "64f75285ef8f606073e9be0a",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "bevala gallois",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0601639,
          lng: 46.3456765,
        },
        _id: "64f75285ef8f606073e9be0b",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "limby",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0811659,
          lng: 46.3576702,
        },
        _id: "64f75285ef8f606073e9be0c",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "ianakafy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0896031,
          lng: 46.3288901,
        },
        _id: "64f75285ef8f606073e9be0d",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "ankitry",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0858217,
          lng: 46.3121095,
        },
        _id: "64f75285ef8f606073e9be0e",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "hovotsotse",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0927472,
          lng: 46.415281,
        },
        _id: "64f75285ef8f606073e9be0f",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "bevala D.P",
        __v: 0,
      },
      {
        coords: {
          lat: -25.088193,
          lng: 46.3768663,
        },
        _id: "64f75285ef8f606073e9be10",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "erombazy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1107678,
          lng: 46.4320968,
        },
        _id: "64f75285ef8f606073e9be11",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "berano gallois",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0122342,
          lng: 46.3768663,
        },
        _id: "64f75285ef8f606073e9be12",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "amboasary atsimo",
        fokontany: "ankamena tanantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9555734,
          lng: 46.3858671,
        },
        _id: "64f75285ef8f606073e9be13",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "behara poste II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8135665,
          lng: 46.4970116,
        },
        _id: "64f75285ef8f606073e9be14",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "esatra bevia",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9137182,
          lng: 46.4585333,
        },
        _id: "64f75285ef8f606073e9be15",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "andregnara",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8098018,
          lng: 46.38166649999999,
        },
        _id: "64f75285ef8f606073e9be16",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "analagna",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9347641,
          lng: 46.3336857,
        },
        _id: "64f75285ef8f606073e9be17",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "behara ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9942552,
          lng: 46.4056745,
        },
        _id: "64f75285ef8f606073e9be18",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "ankirikirika",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9600099,
          lng: 46.3996714,
        },
        _id: "64f75285ef8f606073e9be19",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "mieba",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9603845,
          lng: 46.3876674,
        },
        _id: "64f75285ef8f606073e9be1a",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "behara poste I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9621344,
          lng: 46.39246869999999,
        },
        _id: "64f75285ef8f606073e9be1b",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "anapemba",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9592525,
          lng: 46.3552713,
        },
        _id: "64f75285ef8f606073e9be1c",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "behara bas I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.965757,
          lng: 46.3828667,
        },
        _id: "64f75285ef8f606073e9be1d",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "behara bas II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9632565,
          lng: 46.4248894,
        },
        _id: "64f75285ef8f606073e9be1e",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "tanambao behara",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9717304,
          lng: 46.4873892,
        },
        _id: "64f75285ef8f606073e9be1f",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "taranty haut",
        __v: 0,
      },
      {
        coords: {
          lat: -24.855593,
          lng: 46.2402577,
        },
        _id: "64f75285ef8f606073e9be20",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "antsivavy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8638352,
          lng: 46.2953347,
        },
        _id: "64f75285ef8f606073e9be21",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "befaitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9192354,
          lng: 46.2522257,
        },
        _id: "64f75285ef8f606073e9be22",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "fandiova",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9511898,
          lng: 46.2618022,
        },
        _id: "64f75285ef8f606073e9be23",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "maromena",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9792275,
          lng: 46.33848159999999,
        },
        _id: "64f75285ef8f606073e9be24",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "afomanary bas",
        __v: 0,
      },
      {
        coords: {
          lat: -24.978203,
          lng: 46.3097128,
        },
        _id: "64f75285ef8f606073e9be25",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "andavabazaha haut",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0106938,
          lng: 46.3336857,
        },
        _id: "64f75285ef8f606073e9be26",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "berenty toby",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9896453,
          lng: 46.2761704,
        },
        _id: "64f75285ef8f606073e9be27",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "berenty officiel",
        __v: 0,
      },
      {
        coords: {
          lat: -24.976742,
          lng: 46.3576702,
        },
        _id: "64f75285ef8f606073e9be28",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "behara",
        fokontany: "helibondro",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1247566,
          lng: 46.4248894,
        },
        _id: "64f75285ef8f606073e9be29",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "tanandava sud I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.113423,
          lng: 46.3744664,
        },
        _id: "64f75285ef8f606073e9be2a",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "ankara berano",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1272239,
          lng: 46.4056745,
        },
        _id: "64f75285ef8f606073e9be2b",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "berano ville",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1296867,
          lng: 46.3864672,
        },
        _id: "64f75285ef8f606073e9be2c",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "berano anja",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1520876,
          lng: 46.35047360000001,
        },
        _id: "64f75285ef8f606073e9be2d",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "maroaiky II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1286265,
          lng: 46.3576702,
        },
        _id: "64f75285ef8f606073e9be2e",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "maroaiky I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1665928,
          lng: 46.3576702,
        },
        _id: "64f75285ef8f606073e9be2f",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "manitsevo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.153637,
          lng: 46.37086679999999,
        },
        _id: "64f75285ef8f606073e9be30",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "ambolovohitse ville",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1491571,
          lng: 46.3780663,
        },
        _id: "64f75285ef8f606073e9be31",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "bevoangy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1721445,
          lng: 46.3792664,
        },
        _id: "64f75285ef8f606073e9be32",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "ampotobato",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1577706,
          lng: 46.3756664,
        },
        _id: "64f75285ef8f606073e9be33",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "ambolovohitse tanamiary",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1631845,
          lng: 46.3936691,
        },
        _id: "64f75285ef8f606073e9be34",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "ambaniza morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1512302,
          lng: 46.4128792,
        },
        _id: "64f75285ef8f606073e9be35",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "andavaboay",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1627515,
          lng: 46.4248894,
        },
        _id: "64f75285ef8f606073e9be36",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "ambasy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0743133,
          lng: 46.5210757,
        },
        _id: "64f75285ef8f606073e9be37",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "elomaka",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0757984,
          lng: 46.4729592,
        },
        _id: "64f75285ef8f606073e9be38",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "antsovela sama",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1428464,
          lng: 46.4873892,
        },
        _id: "64f75285ef8f606073e9be39",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "antsovela ville",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0907852,
          lng: 46.4489184,
        },
        _id: "64f75285ef8f606073e9be3a",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "evoahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1470082,
          lng: 46.4272917,
        },
        _id: "64f75285ef8f606073e9be3b",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "bebea",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1522832,
          lng: 46.4417085,
        },
        _id: "64f75285ef8f606073e9be3c",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "belavenoka atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.138554,
          lng: 46.45612939999999,
        },
        _id: "64f75285ef8f606073e9be3d",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "tanandava sud II",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1578196,
          lng: 46.4633415,
        },
        _id: "64f75285ef8f606073e9be3e",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "vohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1578815,
          lng: 46.5090422,
        },
        _id: "64f75285ef8f606073e9be3f",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tanandava sud",
        fokontany: "maroloha",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1679518,
          lng: 46.3097128,
        },
        _id: "64f75285ef8f606073e9be40",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "sampona",
        fokontany: "beroroha ambato",
        __v: 0,
      },
      {
        coords: {
          lat: -25.0988486,
          lng: 46.2797632,
        },
        _id: "64f75285ef8f606073e9be41",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "sampona",
        fokontany: "ankilimanara",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1110322,
          lng: 46.3097128,
        },
        _id: "64f75285ef8f606073e9be42",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "sampona",
        fokontany: "ambolokohy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1229634,
          lng: 46.2905429,
        },
        _id: "64f75285ef8f606073e9be43",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "sampona",
        fokontany: "ambolokohy anatolily",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1126693,
          lng: 46.2689858,
        },
        _id: "64f75285ef8f606073e9be44",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "sampona",
        fokontany: "ankilimitraha",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1422015,
          lng: 46.3187012,
        },
        _id: "64f75285ef8f606073e9be45",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "sampona",
        fokontany: "ambahy ianakafy",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1426097,
          lng: 46.3085144,
        },
        _id: "64f75285ef8f606073e9be46",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "sampona",
        fokontany: "somay sanakambo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1248414,
          lng: 46.3408798,
        },
        _id: "64f75285ef8f606073e9be47",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "sampona",
        fokontany: "ankilidoga ezambe",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1479629,
          lng: 46.3037214,
        },
        _id: "64f75285ef8f606073e9be48",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "sampona",
        fokontany: "sampona ankilimiary",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1356999,
          lng: 46.2929387,
        },
        _id: "64f75285ef8f606073e9be49",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "sampona",
        fokontany: "sampona centre",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1455213,
          lng: 46.3228964,
        },
        _id: "64f75285ef8f606073e9be4a",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "sampona",
        fokontany: "ankilibe vahavola",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1750016,
          lng: 46.3288901,
        },
        _id: "64f75285ef8f606073e9be4b",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "sampona",
        fokontany: "manindra",
        __v: 0,
      },
      {
        coords: {
          lat: -25.158056,
          lng: 46.3408798,
        },
        _id: "64f75285ef8f606073e9be4c",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "sampona",
        fokontany: "vahavola",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1514172,
          lng: 46.2905429,
        },
        _id: "64f75285ef8f606073e9be4d",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "sampona",
        fokontany: "elanja centre",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1706592,
          lng: 46.2977307,
        },
        _id: "64f75285ef8f606073e9be4e",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "sampona",
        fokontany: "elanja androandria",
        __v: 0,
      },
      {
        coords: {
          lat: -25.161844,
          lng: 46.2737754,
        },
        _id: "64f75285ef8f606073e9be4f",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "sampona",
        fokontany: "mandrapasy centre",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1977304,
          lng: 46.2618022,
        },
        _id: "64f75285ef8f606073e9be50",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "sampona",
        fokontany: "mandrapasy amborignabo",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1195628,
          lng: 46.32649259999999,
        },
        _id: "64f75285ef8f606073e9be51",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "sampona",
        fokontany: "nasambola",
        __v: 0,
      },
      {
        coords: {
          lat: -25.123913,
          lng: 46.2737754,
        },
        _id: "64f75285ef8f606073e9be52",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "sampona",
        fokontany: "ankilimalaindio I",
        __v: 0,
      },
      {
        coords: {
          lat: -25.1893538,
          lng: 46.2905429,
        },
        _id: "64f75285ef8f606073e9be53",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "sampona",
        fokontany: "ankilimalaindio II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.81041,
          lng: 46.134628,
        },
        _id: "64f75285ef8f606073e9be54",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ifotaka",
        fokontany: "ifotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5264323,
          lng: 46.2282927,
        },
        _id: "64f75285ef8f606073e9be55",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ifotaka",
        fokontany: "befantiolotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6161863,
          lng: 46.2665911,
        },
        _id: "64f75285ef8f606073e9be56",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ifotaka",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6791208,
          lng: 46.25701369999999,
        },
        _id: "64f75285ef8f606073e9be57",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ifotaka",
        fokontany: "ambaninato",
        __v: 0,
      },
      {
        coords: {
          lat: -24.696175,
          lng: 46.1995885,
        },
        _id: "64f75285ef8f606073e9be58",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ifotaka",
        fokontany: "mangily",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7221292,
          lng: 46.0372508,
        },
        _id: "64f75285ef8f606073e9be59",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ifotaka",
        fokontany: "bekiria II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.7794175,
          lng: 46.0515529,
        },
        _id: "64f75285ef8f606073e9be5a",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ifotaka",
        fokontany: "bekiria I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8078069,
          lng: 46.0515529,
        },
        _id: "64f75285ef8f606073e9be5b",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ifotaka",
        fokontany: "fenoaivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8317947,
          lng: 46.10402999999999,
        },
        _id: "64f75285ef8f606073e9be5c",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ifotaka",
        fokontany: "analoalo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8011688,
          lng: 46.1302899,
        },
        _id: "64f75285ef8f606073e9be5d",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ifotaka",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8273629,
          lng: 46.15656389999999,
        },
        _id: "64f75285ef8f606073e9be5e",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ifotaka",
        fokontany: "ifotaka d.p.",
        __v: 0,
      },
      {
        coords: {
          lat: -24.833333,
          lng: 46.2,
        },
        _id: "64f75285ef8f606073e9be5f",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ifotaka",
        fokontany: "bebarimo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8652581,
          lng: 46.15656389999999,
        },
        _id: "64f75285ef8f606073e9be60",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ifotaka",
        fokontany: "beraketa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8835315,
          lng: 46.2259,
        },
        _id: "64f75285ef8f606073e9be61",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ifotaka",
        fokontany: "ankilimanjosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.886266,
          lng: 46.2139385,
        },
        _id: "64f75285ef8f606073e9be62",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ifotaka",
        fokontany: "tsaripioky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.8977017,
          lng: 46.1804618,
        },
        _id: "64f75285ef8f606073e9be63",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ifotaka",
        fokontany: "anjando",
        __v: 0,
      },
      {
        coords: {
          lat: -24.935604,
          lng: 46.1804618,
        },
        _id: "64f75285ef8f606073e9be64",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ifotaka",
        fokontany: "anjamahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.9750867,
          lng: 46.2235075,
        },
        _id: "64f75285ef8f606073e9be65",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ifotaka",
        fokontany: "tsileha",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5965369,
          lng: 46.4489184,
        },
        _id: "64f75285ef8f606073e9be66",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tranomaro",
        fokontany: "tranomaro I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4298086,
          lng: 46.38166649999999,
        },
        _id: "64f75285ef8f606073e9be67",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tranomaro",
        fokontany: "ranolava",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4444116,
          lng: 46.4489184,
        },
        _id: "64f75285ef8f606073e9be68",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tranomaro",
        fokontany: "beteny",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4711386,
          lng: 46.4970116,
        },
        _id: "64f75285ef8f606073e9be69",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tranomaro",
        fokontany: "tatsimo ampilira",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5239511,
          lng: 46.3528724,
        },
        _id: "64f75285ef8f606073e9be6a",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tranomaro",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5325859,
          lng: 46.4296942,
        },
        _id: "64f75285ef8f606073e9be6b",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tranomaro",
        fokontany: "amboropotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5913492,
          lng: 46.4873892,
        },
        _id: "64f75285ef8f606073e9be6c",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tranomaro",
        fokontany: "tranomaro II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6120856,
          lng: 46.5451516,
        },
        _id: "64f75285ef8f606073e9be6d",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tranomaro",
        fokontany: "beza",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6137853,
          lng: 46.3912683,
        },
        _id: "64f75285ef8f606073e9be6e",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tranomaro",
        fokontany: "antsira",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6016251,
          lng: 46.6222727,
        },
        _id: "64f75285ef8f606073e9be6f",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tranomaro",
        fokontany: "beroanga tsimiangatra",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6587768,
          lng: 46.6222727,
        },
        _id: "64f75285ef8f606073e9be70",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tranomaro",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6985424,
          lng: 46.4681501,
        },
        _id: "64f75285ef8f606073e9be71",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tranomaro",
        fokontany: "tsilamaha II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6812938,
          lng: 46.52589,
        },
        _id: "64f75285ef8f606073e9be72",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tranomaro",
        fokontany: "tsilamaha I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.681926,
          lng: 46.3432781,
        },
        _id: "64f75285ef8f606073e9be73",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tranomaro",
        fokontany: "andolobe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.35317,
          lng: 46.575432,
        },
        _id: "64f75285ef8f606073e9be74",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "maromby",
        fokontany: "maromby",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2313367,
          lng: 46.5451516,
        },
        _id: "64f75285ef8f606073e9be75",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "maromby",
        fokontany: "betsingilo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2557359,
          lng: 46.5066359,
        },
        _id: "64f75285ef8f606073e9be76",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "maromby",
        fokontany: "anaramaliniky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2952987,
          lng: 46.5644206,
        },
        _id: "64f75285ef8f606073e9be77",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "maromby",
        fokontany: "tanandava nord",
        __v: 0,
      },
      {
        coords: {
          lat: -24.356974,
          lng: 46.4970116,
        },
        _id: "64f75285ef8f606073e9be78",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "maromby",
        fokontany: "vohipaly",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4217243,
          lng: 46.5451516,
        },
        _id: "64f75285ef8f606073e9be79",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "maromby",
        fokontany: "ambatotsivala",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4223,
          lng: 46.58688,
        },
        _id: "64f75285ef8f606073e9be7a",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "maromby",
        fokontany: "andranondambo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4499802,
          lng: 46.6512239,
        },
        _id: "64f75285ef8f606073e9be7b",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "maromby",
        fokontany: "tsiloakarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4773921,
          lng: 46.6078033,
        },
        _id: "64f75285ef8f606073e9be7c",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "maromby",
        fokontany: "marohotro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5025254,
          lng: 46.5981595,
        },
        _id: "64f75285ef8f606073e9be7d",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "maromby",
        fokontany: "esaka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5323033,
          lng: 46.64157160000001,
        },
        _id: "64f75285ef8f606073e9be7e",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "maromby",
        fokontany: "esomony",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0210126,
          lng: 46.2474381,
        },
        _id: "64f75285ef8f606073e9be7f",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "elonty",
        fokontany: "elonty",
        __v: 0,
      },
      {
        coords: {
          lat: -23.8399855,
          lng: 46.3528724,
        },
        _id: "64f75285ef8f606073e9be80",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "elonty",
        fokontany: "mieba",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9010479,
          lng: 46.25701369999999,
        },
        _id: "64f75285ef8f606073e9be81",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "elonty",
        fokontany: "maroforoha",
        __v: 0,
      },
      {
        coords: {
          lat: -23.933333,
          lng: 46.35,
        },
        _id: "64f75285ef8f606073e9be82",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "elonty",
        fokontany: "ambatomanaky",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9651459,
          lng: 46.2905429,
        },
        _id: "64f75285ef8f606073e9be83",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "elonty",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9436629,
          lng: 46.1900242,
        },
        _id: "64f75285ef8f606073e9be84",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "elonty",
        fokontany: "beadabo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9880403,
          lng: 46.1948061,
        },
        _id: "64f75285ef8f606073e9be85",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "elonty",
        fokontany: "fanjakamandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0077483,
          lng: 46.2235075,
        },
        _id: "64f75285ef8f606073e9be86",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "elonty",
        fokontany: "besakoa nord",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9998137,
          lng: 46.1613426,
        },
        _id: "64f75285ef8f606073e9be87",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "elonty",
        fokontany: "soamanonga est",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0475467,
          lng: 46.2953347,
        },
        _id: "64f75285ef8f606073e9be88",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "elonty",
        fokontany: "tamotamo bas",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1090644,
          lng: 46.2282927,
        },
        _id: "64f75285ef8f606073e9be89",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "elonty",
        fokontany: "bemandresy androtsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3272799,
          lng: 46.708431,
        },
        _id: "64f75285ef8f606073e9be8a",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "esira",
        fokontany: "esira",
        __v: 0,
      },
      {
        coords: {
          lat: -24.16234,
          lng: 46.57887729999999,
        },
        _id: "64f75285ef8f606073e9be8b",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "esira",
        fokontany: "befihamy ihosy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1511245,
          lng: 46.64157160000001,
        },
        _id: "64f75285ef8f606073e9be8c",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "esira",
        fokontany: "betonta",
        __v: 0,
      },
      {
        coords: {
          lat: -24.164733,
          lng: 46.6801919,
        },
        _id: "64f75285ef8f606073e9be8d",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "esira",
        fokontany: "lapiry",
        __v: 0,
      },
      {
        coords: {
          lat: -24.172201,
          lng: 46.7285093,
        },
        _id: "64f75285ef8f606073e9be8e",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "esira",
        fokontany: "mandarano",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1888724,
          lng: 46.7623591,
        },
        _id: "64f75285ef8f606073e9be8f",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "esira",
        fokontany: "mandoha",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1864522,
          lng: 46.7962319,
        },
        _id: "64f75285ef8f606073e9be90",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "esira",
        fokontany: "andakatoposa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1618404,
          lng: 46.83497149999999,
        },
        _id: "64f75285ef8f606073e9be91",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "esira",
        fokontany: "belavenoka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2036015,
          lng: 46.8519294,
        },
        _id: "64f75285ef8f606073e9be92",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "esira",
        fokontany: "manombo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2327769,
          lng: 46.60298119999999,
        },
        _id: "64f75285ef8f606073e9be93",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "esira",
        fokontany: "eramamy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2791446,
          lng: 46.6801919,
        },
        _id: "64f75285ef8f606073e9be94",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "esira",
        fokontany: "mitray",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2614652,
          lng: 46.7381783,
        },
        _id: "64f75285ef8f606073e9be95",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "esira",
        fokontany: "emieba",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3255356,
          lng: 46.7575221,
        },
        _id: "64f75285ef8f606073e9be96",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "esira",
        fokontany: "ambatomasy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3691014,
          lng: 46.7188421,
        },
        _id: "64f75285ef8f606073e9be97",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "esira",
        fokontany: "mandabe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.174049,
          lng: 46.349171,
        },
        _id: "64f75285ef8f606073e9be98",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "mahaly",
        fokontany: "mahaly",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9872691,
          lng: 46.4200849,
        },
        _id: "64f75285ef8f606073e9be99",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "mahaly",
        fokontany: "babaria",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0538465,
          lng: 46.5547852,
        },
        _id: "64f75285ef8f606073e9be9a",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "mahaly",
        fokontany: "bepimay",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1530349,
          lng: 46.4585333,
        },
        _id: "64f75285ef8f606073e9be9b",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "mahaly",
        fokontany: "vohitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1508294,
          lng: 46.3720666,
        },
        _id: "64f75285ef8f606073e9be9c",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "mahaly",
        fokontany: "beza",
        __v: 0,
      },
      {
        coords: {
          lat: -24.178896,
          lng: 46.2378645,
        },
        _id: "64f75285ef8f606073e9be9d",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "mahaly",
        fokontany: "ambakaka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2745845,
          lng: 46.2665911,
        },
        _id: "64f75285ef8f606073e9be9e",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "mahaly",
        fokontany: "adabolava sud",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2009958,
          lng: 46.3528724,
        },
        _id: "64f75285ef8f606073e9be9f",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "mahaly",
        fokontany: "antraitray",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2701304,
          lng: 46.3336857,
        },
        _id: "64f75285ef8f606073e9bea0",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "mahaly",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2104712,
          lng: 46.4729592,
        },
        _id: "64f75285ef8f606073e9bea1",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "mahaly",
        fokontany: "tanampirafy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2914938,
          lng: 46.4200849,
        },
        _id: "64f75285ef8f606073e9bea2",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "mahaly",
        fokontany: "amboangy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3650999,
          lng: 46.3336857,
        },
        _id: "64f75285ef8f606073e9bea3",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "mahaly",
        fokontany: "ankilitoka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.391399,
          lng: 46.800461,
        },
        _id: "64f75285ef8f606073e9bea4",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "manevy",
        fokontany: "manevy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2764487,
          lng: 46.83497149999999,
        },
        _id: "64f75285ef8f606073e9bea5",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "manevy",
        fokontany: "andetsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3214688,
          lng: 46.8543525,
        },
        _id: "64f75285ef8f606073e9bea6",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "manevy",
        fokontany: "ambero",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3528498,
          lng: 46.83497149999999,
        },
        _id: "64f75285ef8f606073e9bea7",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "manevy",
        fokontany: "betaimboraka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3582855,
          lng: 46.7962319,
        },
        _id: "64f75285ef8f606073e9bea8",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "manevy",
        fokontany: "ampamatoha",
        __v: 0,
      },
      {
        coords: {
          lat: -24.410148,
          lng: 46.83497149999999,
        },
        _id: "64f75285ef8f606073e9bea9",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "manevy",
        fokontany: "sahakondro",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4414978,
          lng: 46.815598,
        },
        _id: "64f75285ef8f606073e9beaa",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "manevy",
        fokontany: "atsavoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4591159,
          lng: 46.7575221,
        },
        _id: "64f75285ef8f606073e9beab",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "manevy",
        fokontany: "ambotaky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4987799,
          lng: 46.815598,
        },
        _id: "64f75285ef8f606073e9beac",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "manevy",
        fokontany: "antseva",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5354413,
          lng: 46.7575221,
        },
        _id: "64f75285ef8f606073e9bead",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "manevy",
        fokontany: "vohibaka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.06842,
          lng: 46.074711,
        },
        _id: "64f75285ef8f606073e9beae",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tsivory",
        fokontany: "tsivory",
        __v: 0,
      },
      {
        coords: {
          lat: -23.9982979,
          lng: 46.10402999999999,
        },
        _id: "64f75285ef8f606073e9beaf",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tsivory",
        fokontany: "bevahy ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.066667,
          lng: 46.13333300000001,
        },
        _id: "64f75285ef8f606073e9beb0",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tsivory",
        fokontany: "tsilanja",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0892113,
          lng: 46.0801697,
        },
        _id: "64f75285ef8f606073e9beb1",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tsivory",
        fokontany: "ankilimary",
        __v: 0,
      },
      {
        coords: {
          lat: -24.040671,
          lng: 46.0372508,
        },
        _id: "64f75285ef8f606073e9beb2",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tsivory",
        fokontany: "ampiha",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1035074,
          lng: 46.0277184,
        },
        _id: "64f75285ef8f606073e9beb3",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tsivory",
        fokontany: "fandranarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.0752489,
          lng: 45.92298659999999,
        },
        _id: "64f75285ef8f606073e9beb4",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tsivory",
        fokontany: "imanjola",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1435478,
          lng: 45.99437,
        },
        _id: "64f75285ef8f606073e9beb5",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tsivory",
        fokontany: "marovotry",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1018565,
          lng: 45.97056380000001,
        },
        _id: "64f75285ef8f606073e9beb6",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tsivory",
        fokontany: "ankily",
        __v: 0,
      },
      {
        coords: {
          lat: -24.135746,
          lng: 46.0515529,
        },
        _id: "64f75285ef8f606073e9beb7",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tsivory",
        fokontany: "tsapa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.12568,
          lng: 46.1422309,
        },
        _id: "64f75285ef8f606073e9beb8",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tsivory",
        fokontany: "belenalena",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1635893,
          lng: 46.1422309,
        },
        _id: "64f75285ef8f606073e9beb9",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tsivory",
        fokontany: "amboropotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.191611,
          lng: 46.127902,
        },
        _id: "64f75285ef8f606073e9beba",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tsivory",
        fokontany: "farivolo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2564229,
          lng: 45.9729438,
        },
        _id: "64f75285ef8f606073e9bebb",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "marotsiraka",
        fokontany: "antanambao beakanga",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1968759,
          lng: 45.9848461,
        },
        _id: "64f75285ef8f606073e9bebc",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "marotsiraka",
        fokontany: "antsariky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.183333,
          lng: 46.066667,
        },
        _id: "64f75285ef8f606073e9bebd",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "marotsiraka",
        fokontany: "andindo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2050333,
          lng: 46.0467851,
        },
        _id: "64f75285ef8f606073e9bebe",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "marotsiraka",
        fokontany: "sakafia",
        __v: 0,
      },
      {
        coords: {
          lat: -24.266667,
          lng: 46.533333,
        },
        _id: "64f75285ef8f606073e9bebf",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "marotsiraka",
        fokontany: "soaserana II6",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2463031,
          lng: 45.9515272,
        },
        _id: "64f75285ef8f606073e9bec0",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "marotsiraka",
        fokontany: "tombonahy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.24247,
          lng: 46.0324844,
        },
        _id: "64f75285ef8f606073e9bec1",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "marotsiraka",
        fokontany: "voravy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.265237,
          lng: 46.1613426,
        },
        _id: "64f75285ef8f606073e9bec2",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "marotsiraka",
        fokontany: "besavoa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2528847,
          lng: 45.9860365,
        },
        _id: "64f75285ef8f606073e9bec3",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "marotsiraka",
        fokontany: "bekola",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2836793,
          lng: 45.9372548,
        },
        _id: "64f75285ef8f606073e9bec4",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "marotsiraka",
        fokontany: "ambinanivelo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2738746,
          lng: 46.0277184,
        },
        _id: "64f75285ef8f606073e9bec5",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "marotsiraka",
        fokontany: "befihamy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2836014,
          lng: 45.9598548,
        },
        _id: "64f75285ef8f606073e9bec6",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "marotsiraka",
        fokontany: "antranotany",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3085885,
          lng: 45.9277422,
        },
        _id: "64f75285ef8f606073e9bec7",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "marotsiraka",
        fokontany: "ankiliroa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2834293,
          lng: 45.9833812,
        },
        _id: "64f75285ef8f606073e9bec8",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "marotsiraka",
        fokontany: "marotsiraka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3306604,
          lng: 46.0277184,
        },
        _id: "64f75285ef8f606073e9bec9",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "marotsiraka",
        fokontany: "anjadoaky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3546914,
          lng: 45.98960779999999,
        },
        _id: "64f75285ef8f606073e9beca",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "marotsiraka",
        fokontany: "mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3333051,
          lng: 46.1135774,
        },
        _id: "64f75285ef8f606073e9becb",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "marotsiraka",
        fokontany: "antsonjo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2127235,
          lng: 45.8849587,
        },
        _id: "64f75285ef8f606073e9becc",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tomboarivo",
        fokontany: "tomboarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1757999,
          lng: 45.9134768,
        },
        _id: "64f75285ef8f606073e9becd",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tomboarivo",
        fokontany: "antaralava",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2106062,
          lng: 45.9182314,
        },
        _id: "64f75285ef8f606073e9bece",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tomboarivo",
        fokontany: "antsambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2286215,
          lng: 45.8897105,
        },
        _id: "64f75285ef8f606073e9becf",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tomboarivo",
        fokontany: "soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -24.1740443,
          lng: 45.8564576,
        },
        _id: "64f75285ef8f606073e9bed0",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tomboarivo",
        fokontany: "angebo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2307225,
          lng: 45.8564576,
        },
        _id: "64f75285ef8f606073e9bed1",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tomboarivo",
        fokontany: "beadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2604218,
          lng: 45.8992156,
        },
        _id: "64f75285ef8f606073e9bed2",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "tomboarivo",
        fokontany: "manandavenoka",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4890153,
          lng: 46.0467851,
        },
        _id: "64f75285ef8f606073e9bed3",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ebelo",
        fokontany: "ebelo tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4192965,
          lng: 46.0372508,
        },
        _id: "64f75285ef8f606073e9bed4",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ebelo",
        fokontany: "anaramaliniky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.446981,
          lng: 46.1135774,
        },
        _id: "64f75285ef8f606073e9bed5",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ebelo",
        fokontany: "valohazomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5169359,
          lng: 46.0324844,
        },
        _id: "64f75285ef8f606073e9bed6",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ebelo",
        fokontany: "ankaramangotroky",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5407621,
          lng: 46.0849408,
        },
        _id: "64f75285ef8f606073e9bed7",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ebelo",
        fokontany: "fenoarivo ranomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5429035,
          lng: 45.9610446,
        },
        _id: "64f75285ef8f606073e9bed8",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ebelo",
        fokontany: "bevalay",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5906191,
          lng: 46.0658592,
        },
        _id: "64f75285ef8f606073e9bed9",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ebelo",
        fokontany: "anarafito",
        __v: 0,
      },
      {
        coords: {
          lat: -24.6124375,
          lng: 46.1517858,
        },
        _id: "64f75285ef8f606073e9beda",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ebelo",
        fokontany: "ankobay",
        __v: 0,
      },
      {
        coords: {
          lat: -24.683333,
          lng: 46.083333,
        },
        _id: "64f75285ef8f606073e9bedb",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ebelo",
        fokontany: "amboetsy",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4126156,
          lng: 46.2282927,
        },
        _id: "64f75285ef8f606073e9bedc",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ranobe",
        fokontany: "ranobe",
        __v: 0,
      },
      {
        coords: {
          lat: -24.2923279,
          lng: 46.2235075,
        },
        _id: "64f75285ef8f606073e9bedd",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ranobe",
        fokontany: "behalomboro II",
        __v: 0,
      },
      {
        coords: {
          lat: -24.3367327,
          lng: 46.2282927,
        },
        _id: "64f75285ef8f606073e9bede",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ranobe",
        fokontany: "behalomboro I",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4168913,
          lng: 46.1613426,
        },
        _id: "64f75285ef8f606073e9bedf",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ranobe",
        fokontany: "ankamena",
        __v: 0,
      },
      {
        coords: {
          lat: -24.464849,
          lng: 46.170761,
        },
        _id: "64f75285ef8f606073e9bee0",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ranobe",
        fokontany: "beraketa",
        __v: 0,
      },
      {
        coords: {
          lat: -24.4927128,
          lng: 46.1613426,
        },
        _id: "64f75285ef8f606073e9bee1",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ranobe",
        fokontany: "ankaramena",
        __v: 0,
      },
      {
        coords: {
          lat: -24.5306219,
          lng: 46.1613426,
        },
        _id: "64f75285ef8f606073e9bee2",
        province: "toliary",
        region: "anosy",
        district: "amboasary-atsimo",
        commune: "ranobe",
        fokontany: "fenoarivo soamagnaly",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4346504,
          lng: 44.322473,
        },
        _id: "64f75285ef8f606073e9bee3",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "manja",
        fokontany: "manja II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.267004,
          lng: 44.3425754,
        },
        _id: "64f75285ef8f606073e9bee4",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "manja",
        fokontany: "androbotsiabo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3609814,
          lng: 44.2573774,
        },
        _id: "64f75285ef8f606073e9bee5",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "manja",
        fokontany: "andranomiteraky",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3954621,
          lng: 44.3378462,
        },
        _id: "64f75285ef8f606073e9bee6",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "manja",
        fokontany: "andranovorinampela",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4110783,
          lng: 44.3898416,
        },
        _id: "64f75285ef8f606073e9bee7",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "manja",
        fokontany: "antanimainty ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3797753,
          lng: 44.3709408,
        },
        _id: "64f75285ef8f606073e9bee8",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "manja",
        fokontany: "miary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4236662,
          lng: 44.3378462,
        },
        _id: "64f75285ef8f606073e9bee9",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "manja",
        fokontany: "mangamirafy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4174116,
          lng: 44.3212902,
        },
        _id: "64f75285ef8f606073e9beea",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "manja",
        fokontany: "andasibe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4275977,
          lng: 44.322473,
        },
        _id: "64f75285ef8f606073e9beeb",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "manja",
        fokontany: "manja I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.43618,
          lng: 44.3567602,
        },
        _id: "64f75285ef8f606073e9beec",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "manja",
        fokontany: "andoharano",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4676645,
          lng: 44.1910066,
        },
        _id: "64f75285ef8f606073e9beed",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "manja",
        fokontany: "androtsy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4550348,
          lng: 44.314193,
        },
        _id: "64f75285ef8f606073e9beee",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "manja",
        fokontany: "ambalanomby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5232457,
          lng: 44.3035453,
        },
        _id: "64f75285ef8f606073e9beef",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "beharona",
        fokontany: "beharona",
        __v: 0,
      },
      {
        coords: {
          lat: -21.40107,
          lng: 44.6378709,
        },
        _id: "64f75285ef8f606073e9bef0",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "beharona",
        fokontany: "besely",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4234777,
          lng: 44.4653693,
        },
        _id: "64f75285ef8f606073e9bef1",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "beharona",
        fokontany: "sorita",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4544773,
          lng: 44.5690225,
        },
        _id: "64f75285ef8f606073e9bef2",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "beharona",
        fokontany: "taimboraky",
        __v: 0,
      },
      {
        coords: {
          lat: -21.518721,
          lng: 44.45649,
        },
        _id: "64f75285ef8f606073e9bef3",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "beharona",
        fokontany: "betsioky",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5492059,
          lng: 44.2573774,
        },
        _id: "64f75285ef8f606073e9bef4",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "beharona",
        fokontany: "agnadabo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5927962,
          lng: 44.38039209999999,
        },
        _id: "64f75285ef8f606073e9bef5",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "beharona",
        fokontany: "tongarivo est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6310267,
          lng: 44.153039,
        },
        _id: "64f75285ef8f606073e9bef6",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "beharona",
        fokontany: "dindoha",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6684243,
          lng: 44.2668514,
        },
        _id: "64f75285ef8f606073e9bef7",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "beharona",
        fokontany: "malandira sud",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6664845,
          lng: 44.6630541,
        },
        _id: "64f75285ef8f606073e9bef8",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "beharona",
        fokontany: "sahomarebe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6940393,
          lng: 44.0770134,
        },
        _id: "64f75285ef8f606073e9bef9",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "beharona",
        fokontany: "ianadranto",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7821414,
          lng: 44.0675018,
        },
        _id: "64f75285ef8f606073e9befa",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "beharona",
        fokontany: "somenda",
        __v: 0,
      },
      {
        coords: {
          lat: -21.78998,
          lng: 44.138241,
        },
        _id: "64f75285ef8f606073e9befb",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "beharona",
        fokontany: "vondrove",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7684027,
          lng: 44.3614875,
        },
        _id: "64f75285ef8f606073e9befc",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "beharona",
        fokontany: "troboambola",
        __v: 0,
      },
      {
        coords: {
          lat: -21.816667,
          lng: 44.05,
        },
        _id: "64f75285ef8f606073e9befd",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "beharona",
        fokontany: "vatolampy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.382259,
          lng: 44.4686809,
        },
        _id: "64f75285ef8f606073e9befe",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "anontsibe centre",
        fokontany: "anontsibe centre",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2293724,
          lng: 44.6818377,
        },
        _id: "64f75285ef8f606073e9beff",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "anontsibe centre",
        fokontany: "bevava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2045096,
          lng: 44.53135690000001,
        },
        _id: "64f75285ef8f606073e9bf00",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "anontsibe centre",
        fokontany: "anja",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3169886,
          lng: 44.5596089,
        },
        _id: "64f75285ef8f606073e9bf01",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "anontsibe centre",
        fokontany: "antsavoa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3356243,
          lng: 44.6160619,
        },
        _id: "64f75285ef8f606073e9bf02",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "anontsibe centre",
        fokontany: "beravy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.292055,
          lng: 44.493661,
        },
        _id: "64f75285ef8f606073e9bf03",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "anontsibe centre",
        fokontany: "antaranta",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3358862,
          lng: 44.4464987,
        },
        _id: "64f75285ef8f606073e9bf04",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "anontsibe centre",
        fokontany: "antanimahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3608922,
          lng: 44.4700858,
        },
        _id: "64f75285ef8f606073e9bf05",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "anontsibe centre",
        fokontany: "anontsibe est",
        __v: 0,
      },
      {
        coords: {
          lat: -21.37973,
          lng: 44.4205393,
        },
        _id: "64f75285ef8f606073e9bf06",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "anontsibe centre",
        fokontany: "anontsikely",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3703151,
          lng: 44.4417799,
        },
        _id: "64f75285ef8f606073e9bf07",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "anontsibe centre",
        fokontany: "tongarivo sakalava",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4110183,
          lng: 44.43234080000001,
        },
        _id: "64f75285ef8f606073e9bf08",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "anontsibe centre",
        fokontany: "anjaloaky",
        __v: 0,
      },
      {
        coords: {
          lat: -21.110208,
          lng: 44.2763235,
        },
        _id: "64f75285ef8f606073e9bf09",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "soaserana",
        fokontany: "soaserana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.028231,
          lng: 44.122299,
        },
        _id: "64f75285ef8f606073e9bf0a",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "soaserana",
        fokontany: "antevamena",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1419041,
          lng: 44.4370606,
        },
        _id: "64f75285ef8f606073e9bf0b",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "soaserana",
        fokontany: "fenoarivo tsienimboay",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9908147,
          lng: 44.20997910000001,
        },
        _id: "64f75285ef8f606073e9bf0c",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "soaserana",
        fokontany: "vohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -21.133333,
          lng: 44.266667,
        },
        _id: "64f75285ef8f606073e9bf0d",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "soaserana",
        fokontany: "anderika",
        __v: 0,
      },
      {
        coords: {
          lat: -21.234051,
          lng: 44.16814,
        },
        _id: "64f75285ef8f606073e9bf0e",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "soaserana",
        fokontany: "miary soalengo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7181609,
          lng: 43.88979,
        },
        _id: "64f75285ef8f606073e9bf0f",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "ankiliabo",
        fokontany: "ankiliabo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.466868,
          lng: 43.7334089,
        },
        _id: "64f75285ef8f606073e9bf10",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "ankiliabo",
        fokontany: "betamenaky",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5112522,
          lng: 43.8004115,
        },
        _id: "64f75285ef8f606073e9bf11",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "ankiliabo",
        fokontany: "marolafika",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5805739,
          lng: 43.76213550000001,
        },
        _id: "64f75285ef8f606073e9bf12",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "ankiliabo",
        fokontany: "besatrohaky",
        __v: 0,
      },
      {
        coords: {
          lat: -21.618562,
          lng: 43.8482142,
        },
        _id: "64f75285ef8f606073e9bf13",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "ankiliabo",
        fokontany: "ambahia",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5934083,
          lng: 43.92460090000001,
        },
        _id: "64f75285ef8f606073e9bf14",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "ankiliabo",
        fokontany: "fiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -21.5682507,
          lng: 44.0008674,
        },
        _id: "64f75285ef8f606073e9bf15",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "ankiliabo",
        fokontany: "tanatsimira",
        __v: 0,
      },
      {
        coords: {
          lat: -21.6816253,
          lng: 43.91505919999999,
        },
        _id: "64f75285ef8f606073e9bf16",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "ankiliabo",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7571217,
          lng: 44.0008674,
        },
        _id: "64f75285ef8f606073e9bf17",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "ankiliabo",
        fokontany: "bengy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.7573127,
          lng: 43.8577691,
        },
        _id: "64f75285ef8f606073e9bf18",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "ankiliabo",
        fokontany: "tongarivo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8076489,
          lng: 43.9341408,
        },
        _id: "64f75285ef8f606073e9bf19",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "ankiliabo",
        fokontany: "rangainomby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8642091,
          lng: 43.99134060000001,
        },
        _id: "64f75285ef8f606073e9bf1a",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "ankiliabo",
        fokontany: "besaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.8581606,
          lng: 43.89597,
        },
        _id: "64f75285ef8f606073e9bf1b",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "ankiliabo",
        fokontany: "ankatsakatsa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2654458,
          lng: 43.8290988,
        },
        _id: "64f75285ef8f606073e9bf1c",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "andranopasy",
        fokontany: "andranopasy I",
        __v: 0,
      },
      {
        coords: {
          lat: -21.12689,
          lng: 43.867359,
        },
        _id: "64f75285ef8f606073e9bf1d",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "andranopasy",
        fokontany: "ankoba",
        __v: 0,
      },
      {
        coords: {
          lat: -21.392268,
          lng: 44.13404389999999,
        },
        _id: "64f75285ef8f606073e9bf1e",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "andranopasy",
        fokontany: "tsianihy",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3147096,
          lng: 43.6183335,
        },
        _id: "64f75285ef8f606073e9bf1f",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "andranopasy",
        fokontany: "andranopasy II",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3662234,
          lng: 43.8099758,
        },
        _id: "64f75285ef8f606073e9bf20",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "andranopasy",
        fokontany: "nosibe",
        __v: 0,
      },
      {
        coords: {
          lat: -21.3969143,
          lng: 43.6279334,
        },
        _id: "64f75285ef8f606073e9bf21",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "andranopasy",
        fokontany: "antaly",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4288719,
          lng: 43.70466529999999,
        },
        _id: "64f75285ef8f606073e9bf22",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "andranopasy",
        fokontany: "befamonty",
        __v: 0,
      },
      {
        coords: {
          lat: -21.4602843,
          lng: 43.6663143,
        },
        _id: "64f75285ef8f606073e9bf23",
        province: "toliary",
        region: "menabe",
        district: "manja",
        commune: "andranopasy",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2964052,
          lng: 44.2792831,
        },
        _id: "64f75285ef8f606073e9bf24",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "morondava",
        fokontany: "andakabe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2847287,
          lng: 44.2828344,
        },
        _id: "64f75285ef8f606073e9bf25",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "morondava",
        fokontany: "avaradrova",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2840466,
          lng: 44.2905281,
        },
        _id: "64f75285ef8f606073e9bf26",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "morondava",
        fokontany: "ambalanomby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2858213,
          lng: 44.30709479999999,
        },
        _id: "64f75285ef8f606073e9bf27",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "morondava",
        fokontany: "tsimahavaobe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2755588,
          lng: 44.2988122,
        },
        _id: "64f75285ef8f606073e9bf28",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "morondava",
        fokontany: "namahora avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2875037,
          lng: 44.3165589,
        },
        _id: "64f75285ef8f606073e9bf29",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "morondava",
        fokontany: "tsimahavaokely",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2844986,
          lng: 44.3260211,
        },
        _id: "64f75285ef8f606073e9bf2a",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "morondava",
        fokontany: "antsakoameloka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2878469,
          lng: 44.2822426,
        },
        _id: "64f75285ef8f606073e9bf2b",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "morondava",
        fokontany: "sans fil",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2924442,
          lng: 44.27513949999999,
        },
        _id: "64f75285ef8f606073e9bf2c",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "morondava",
        fokontany: "morondava centre",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2933096,
          lng: 44.2816507,
        },
        _id: "64f75285ef8f606073e9bf2d",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "morondava",
        fokontany: "andabatoara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2992613,
          lng: 44.2887527,
        },
        _id: "64f75285ef8f606073e9bf2e",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "morondava",
        fokontany: "ankisirasira sud",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2989308,
          lng: 44.2934868,
        },
        _id: "64f75285ef8f606073e9bf2f",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "morondava",
        fokontany: "ankisirasira nord",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2997496,
          lng: 44.2964454,
        },
        _id: "64f75285ef8f606073e9bf30",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "morondava",
        fokontany: "namahora sud",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3045715,
          lng: 44.30709479999999,
        },
        _id: "64f75285ef8f606073e9bf31",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "morondava",
        fokontany: "bemokijy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3121777,
          lng: 44.2905281,
        },
        _id: "64f75285ef8f606073e9bf32",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "morondava",
        fokontany: "ampasy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3027553,
          lng: 44.2869773,
        },
        _id: "64f75285ef8f606073e9bf33",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "morondava",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2993869,
          lng: 44.2680355,
        },
        _id: "64f75285ef8f606073e9bf34",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "morondava",
        fokontany: "nosikely",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3173049,
          lng: 44.2632988,
        },
        _id: "64f75285ef8f606073e9bf35",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "morondava",
        fokontany: "betania",
        __v: 0,
      },
      {
        coords: {
          lat: -20.28401,
          lng: 44.3474121,
        },
        _id: "64f75285ef8f606073e9bf36",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "bemanonga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.98848,
          lng: 44.5737286,
        },
        _id: "64f75285ef8f606073e9bf37",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "beroboka sud",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9687764,
          lng: 44.5030878,
        },
        _id: "64f75285ef8f606073e9bf38",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "andrahangy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0614214,
          lng: 44.4464987,
        },
        _id: "64f75285ef8f606073e9bf39",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "bosy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0558544,
          lng: 44.493661,
        },
        _id: "64f75285ef8f606073e9bf3a",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "ampataka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.14052,
          lng: 44.552559,
        },
        _id: "64f75285ef8f606073e9bf3b",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "marofandilia",
        __v: 0,
      },
      {
        coords: {
          lat: -20.166667,
          lng: 44.616667,
        },
        _id: "64f75285ef8f606073e9bf3c",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "ankaraobato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1545799,
          lng: 44.4181786,
        },
        _id: "64f75285ef8f606073e9bf3d",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "andranomena sud",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1848963,
          lng: 44.3520324,
        },
        _id: "64f75285ef8f606073e9bf3e",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "kivalo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.193428,
          lng: 44.53135690000001,
        },
        _id: "64f75285ef8f606073e9bf3f",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "bevolionga",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2350993,
          lng: 44.3709408,
        },
        _id: "64f75285ef8f606073e9bf40",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "antalitoka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2605439,
          lng: 44.4087348,
        },
        _id: "64f75285ef8f606073e9bf41",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "bekonazy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2375456,
          lng: 44.3189246,
        },
        _id: "64f75285ef8f606073e9bf42",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "kimony",
        __v: 0,
      },
      {
        coords: {
          lat: -20.25,
          lng: 44.316667,
        },
        _id: "64f75285ef8f606073e9bf43",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "ankirijibe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.28401,
          lng: 44.3474121,
        },
        _id: "64f75285ef8f606073e9bf44",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "tanandava 508",
        __v: 0,
      },
      {
        coords: {
          lat: -20.272566,
          lng: 44.3709408,
        },
        _id: "64f75285ef8f606073e9bf45",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "tsitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2978295,
          lng: 44.3945656,
        },
        _id: "64f75285ef8f606073e9bf46",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "tanambao marofototra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.302088,
          lng: 44.3591239,
        },
        _id: "64f75285ef8f606073e9bf47",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "tanandava benjamino",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3079312,
          lng: 44.3260211,
        },
        _id: "64f75285ef8f606073e9bf48",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "andranoteraka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3148058,
          lng: 44.3780295,
        },
        _id: "64f75285ef8f606073e9bf49",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "marovoay",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3168069,
          lng: 44.4158179,
        },
        _id: "64f75285ef8f606073e9bf4a",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "tandrokosy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3158865,
          lng: 44.3378462,
        },
        _id: "64f75285ef8f606073e9bf4b",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "androvakely",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3532951,
          lng: 44.3307515,
        },
        _id: "64f75285ef8f606073e9bf4c",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "tanandava soanafindra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3309332,
          lng: 44.2905281,
        },
        _id: "64f75285ef8f606073e9bf4d",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "antsakamirohaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3414844,
          lng: 44.3898416,
        },
        _id: "64f75285ef8f606073e9bf4e",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "troboambola",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3409919,
          lng: 44.3473041,
        },
        _id: "64f75285ef8f606073e9bf4f",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "androvabe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3588975,
          lng: 44.2763235,
        },
        _id: "64f75285ef8f606073e9bf50",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "lovobe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3449781,
          lng: 44.4228998,
        },
        _id: "64f75285ef8f606073e9bf51",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "ambato est",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3708592,
          lng: 44.363851,
        },
        _id: "64f75285ef8f606073e9bf52",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "ampananiha",
        __v: 0,
      },
      {
        coords: {
          lat: -20.384355,
          lng: 44.314193,
        },
        _id: "64f75285ef8f606073e9bf53",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "ankirijifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.428528,
          lng: 44.3520324,
        },
        _id: "64f75285ef8f606073e9bf54",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "bemanonga",
        fokontany: "ankazomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -20.336651,
          lng: 44.49799,
        },
        _id: "64f75285ef8f606073e9bf55",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "analaiva",
        fokontany: "analaiva",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2745489,
          lng: 44.5407761,
        },
        _id: "64f75285ef8f606073e9bf56",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "analaiva",
        fokontany: "belobaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.246018,
          lng: 44.4983747,
        },
        _id: "64f75285ef8f606073e9bf57",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "analaiva",
        fokontany: "betsinefo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.306057,
          lng: 44.5737286,
        },
        _id: "64f75285ef8f606073e9bf58",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "analaiva",
        fokontany: "andranovoritaretsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3045134,
          lng: 44.43234080000001,
        },
        _id: "64f75285ef8f606073e9bf59",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "analaiva",
        fokontany: "ampandra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2799356,
          lng: 44.4653693,
        },
        _id: "64f75285ef8f606073e9bf5a",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "analaiva",
        fokontany: "tanambao soalala",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3265683,
          lng: 44.4512171,
        },
        _id: "64f75285ef8f606073e9bf5b",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "analaiva",
        fokontany: "betsipotika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2865997,
          lng: 44.5030878,
        },
        _id: "64f75285ef8f606073e9bf5c",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "analaiva",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3306447,
          lng: 44.5407761,
        },
        _id: "64f75285ef8f606073e9bf5d",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "analaiva",
        fokontany: "antevamena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3463945,
          lng: 44.5572552,
        },
        _id: "64f75285ef8f606073e9bf5e",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "analaiva",
        fokontany: "antsaribao",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3434423,
          lng: 44.5737286,
        },
        _id: "64f75285ef8f606073e9bf5f",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "analaiva",
        fokontany: "tsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3549507,
          lng: 44.4795173,
        },
        _id: "64f75285ef8f606073e9bf60",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "analaiva",
        fokontany: "ambinda",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3806982,
          lng: 44.5596089,
        },
        _id: "64f75285ef8f606073e9bf61",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "analaiva",
        fokontany: "besonjo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3827339,
          lng: 44.4512171,
        },
        _id: "64f75285ef8f606073e9bf62",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "analaiva",
        fokontany: "anjahatelo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3956917,
          lng: 44.4983747,
        },
        _id: "64f75285ef8f606073e9bf63",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "analaiva",
        fokontany: "sarodrano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4103849,
          lng: 44.4087348,
        },
        _id: "64f75285ef8f606073e9bf64",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "analaiva",
        fokontany: "ankidafito",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4296854,
          lng: 44.4653693,
        },
        _id: "64f75285ef8f606073e9bf65",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "analaiva",
        fokontany: "laijoby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4428681,
          lng: 44.5407761,
        },
        _id: "64f75285ef8f606073e9bf66",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "analaiva",
        fokontany: "soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4642314,
          lng: 44.4889469,
        },
        _id: "64f75285ef8f606073e9bf67",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "analaiva",
        fokontany: "marohetay",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5364192,
          lng: 44.5407761,
        },
        _id: "64f75285ef8f606073e9bf68",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "analaiva",
        fokontany: "ankilimanintsy bezeky",
        __v: 0,
      },
      {
        coords: {
          lat: -20.584881,
          lng: 44.36145,
        },
        _id: "64f75285ef8f606073e9bf69",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "befasy",
        fokontany: "befasy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5217284,
          lng: 44.29526200000001,
        },
        _id: "64f75285ef8f606073e9bf6a",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "befasy",
        fokontany: "beleo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5471147,
          lng: 44.3331165,
        },
        _id: "64f75285ef8f606073e9bf6b",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "befasy",
        fokontany: "ambahivahibe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.472656,
          lng: 44.3898416,
        },
        _id: "64f75285ef8f606073e9bf6c",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "befasy",
        fokontany: "andranomamy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5169911,
          lng: 44.455935,
        },
        _id: "64f75285ef8f606073e9bf6d",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "befasy",
        fokontany: "misokitse",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6031471,
          lng: 44.3047285,
        },
        _id: "64f75285ef8f606073e9bf6e",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "befasy",
        fokontany: "antevamena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5982416,
          lng: 44.4653693,
        },
        _id: "64f75285ef8f606073e9bf6f",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "befasy",
        fokontany: "soarano bekininy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6917313,
          lng: 44.4370606,
        },
        _id: "64f75285ef8f606073e9bf70",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "befasy",
        fokontany: "bevantaza",
        __v: 0,
      },
      {
        coords: {
          lat: -20.821939,
          lng: 44.570099,
        },
        _id: "64f75285ef8f606073e9bf71",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "befasy",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9420893,
          lng: 44.53135690000001,
        },
        _id: "64f75285ef8f606073e9bf72",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "befasy",
        fokontany: "lavaravy tsiamaliha",
        __v: 0,
      },
      {
        coords: {
          lat: -20.904965,
          lng: 44.6442629,
        },
        _id: "64f75285ef8f606073e9bf73",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "befasy",
        fokontany: "menarandra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.966667,
          lng: 44.433333,
        },
        _id: "64f75285ef8f606073e9bf74",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "befasy",
        fokontany: "lohena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7371983,
          lng: 44.0012298,
        },
        _id: "64f75285ef8f606073e9bf75",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "belo sur mer",
        fokontany: "belo sur mer",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4345568,
          lng: 44.1933786,
        },
        _id: "64f75285ef8f606073e9bf76",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "belo sur mer",
        fokontany: "andika sur mer",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4272617,
          lng: 44.2384237,
        },
        _id: "64f75285ef8f606073e9bf77",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "belo sur mer",
        fokontany: "andika est",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4648114,
          lng: 44.2384237,
        },
        _id: "64f75285ef8f606073e9bf78",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "belo sur mer",
        fokontany: "manometinay",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4700363,
          lng: 44.148291,
        },
        _id: "64f75285ef8f606073e9bf79",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "belo sur mer",
        fokontany: "marovitiky",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4797654,
          lng: 44.1767723,
        },
        _id: "64f75285ef8f606073e9bf7a",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "belo sur mer",
        fokontany: "namakia",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5138435,
          lng: 44.1435424,
        },
        _id: "64f75285ef8f606073e9bf7b",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "belo sur mer",
        fokontany: "faratenina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5084216,
          lng: 44.2194625,
        },
        _id: "64f75285ef8f606073e9bf7c",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "belo sur mer",
        fokontany: "voloy II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5635651,
          lng: 44.1055372,
        },
        _id: "64f75285ef8f606073e9bf7d",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "belo sur mer",
        fokontany: "ankevo sur mer",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5644828,
          lng: 44.1910066,
        },
        _id: "64f75285ef8f606073e9bf7e",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "belo sur mer",
        fokontany: "ankevo est",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6894454,
          lng: 44.153039,
        },
        _id: "64f75285ef8f606073e9bf7f",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "belo sur mer",
        fokontany: "ambararata",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7514749,
          lng: 44.0770134,
        },
        _id: "64f75285ef8f606073e9bf80",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "belo sur mer",
        fokontany: "antsira",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8147811,
          lng: 44.1435424,
        },
        _id: "64f75285ef8f606073e9bf81",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "belo sur mer",
        fokontany: "marofihitra",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8894314,
          lng: 44.0579882,
        },
        _id: "64f75285ef8f606073e9bf82",
        province: "toliary",
        region: "menabe",
        district: "morondava",
        commune: "belo sur mer",
        fokontany: "ankilifolo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3832099,
          lng: 44.6642283,
        },
        _id: "64f75285ef8f606073e9bf83",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mahabo",
        fokontany: "mahabobe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3596421,
          lng: 44.6395639,
        },
        _id: "64f75285ef8f606073e9bf84",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mahabo",
        fokontany: "soafaosa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3834214,
          lng: 44.6888796,
        },
        _id: "64f75285ef8f606073e9bf85",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mahabo",
        fokontany: "tanandava I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3752933,
          lng: 44.6489614,
        },
        _id: "64f75285ef8f606073e9bf86",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mahabo",
        fokontany: "ampasifasy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3817854,
          lng: 44.6794902,
        },
        _id: "64f75285ef8f606073e9bf87",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mahabo",
        fokontany: "anjiro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3692343,
          lng: 44.6677507,
        },
        _id: "64f75285ef8f606073e9bf88",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mahabo",
        fokontany: "mahabokely",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2694167,
          lng: 44.6442629,
        },
        _id: "64f75285ef8f606073e9bf89",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilivalo",
        fokontany: "ankilivalo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2070758,
          lng: 44.6348645,
        },
        _id: "64f75285ef8f606073e9bf8a",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilivalo",
        fokontany: "bevoay",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2355289,
          lng: 44.6771425,
        },
        _id: "64f75285ef8f606073e9bf8b",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilivalo",
        fokontany: "mahasoa nord",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2203178,
          lng: 44.709999,
        },
        _id: "64f75285ef8f606073e9bf8c",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilivalo",
        fokontany: "antsondroka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2602248,
          lng: 44.658357,
        },
        _id: "64f75285ef8f606073e9bf8d",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilivalo",
        fokontany: "morafeno I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2659631,
          lng: 44.6113601,
        },
        _id: "64f75285ef8f606073e9bf8e",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilivalo",
        fokontany: "manambalia",
        __v: 0,
      },
      {
        coords: {
          lat: -20.287661,
          lng: 44.6019551,
        },
        _id: "64f75285ef8f606073e9bf8f",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilivalo",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3222842,
          lng: 44.6395639,
        },
        _id: "64f75285ef8f606073e9bf90",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilivalo",
        fokontany: "tanambao I",
        __v: 0,
      },
      {
        coords: {
          lat: -20.331214,
          lng: 44.5972518,
        },
        _id: "64f75285ef8f606073e9bf91",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilivalo",
        fokontany: "miary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3717509,
          lng: 44.6019551,
        },
        _id: "64f75285ef8f606073e9bf92",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilivalo",
        fokontany: "bezezika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3136099,
          lng: 44.709999,
        },
        _id: "64f75285ef8f606073e9bf93",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "analamitsivalana",
        fokontany: "analamitsivalana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2761536,
          lng: 44.6959205,
        },
        _id: "64f75285ef8f606073e9bf94",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "analamitsivalana",
        fokontany: "tanandava II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2821921,
          lng: 44.6771425,
        },
        _id: "64f75285ef8f606073e9bf95",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "analamitsivalana",
        fokontany: "andevonomby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2857517,
          lng: 44.7240732,
        },
        _id: "64f75285ef8f606073e9bf96",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "analamitsivalana",
        fokontany: "anjamahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3445059,
          lng: 44.6865325,
        },
        _id: "64f75285ef8f606073e9bf97",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "analamitsivalana",
        fokontany: "soaserana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2954632,
          lng: 44.7662703,
        },
        _id: "64f75285ef8f606073e9bf98",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "befotaka",
        fokontany: "ankiliabo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2226492,
          lng: 44.9626829,
        },
        _id: "64f75285ef8f606073e9bf99",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "befotaka",
        fokontany: "andranomandevy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.230169,
          lng: 44.811859,
        },
        _id: "64f75285ef8f606073e9bf9a",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "befotaka",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2369,
          lng: 44.760639,
        },
        _id: "64f75285ef8f606073e9bf9b",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "befotaka",
        fokontany: "andranovoribe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2581703,
          lng: 44.7662703,
        },
        _id: "64f75285ef8f606073e9bf9c",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "befotaka",
        fokontany: "nosibe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.258683,
          lng: 44.8224735,
        },
        _id: "64f75285ef8f606073e9bf9d",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "befotaka",
        fokontany: "ilaza",
        __v: 0,
      },
      {
        coords: {
          lat: -20.833229,
          lng: 45.67111999999999,
        },
        _id: "64f75285ef8f606073e9bf9e",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "befotaka",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3118944,
          lng: 44.8739331,
        },
        _id: "64f75285ef8f606073e9bf9f",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "befotaka",
        fokontany: "antanimbaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.315081,
          lng: 44.83763099999999,
        },
        _id: "64f75285ef8f606073e9bfa0",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "befotaka",
        fokontany: "saronanala",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5442578,
          lng: 44.7756422,
        },
        _id: "64f75285ef8f606073e9bfa1",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ampanihy",
        fokontany: "ampanihy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3556618,
          lng: 44.7170366,
        },
        _id: "64f75285ef8f606073e9bfa2",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ampanihy",
        fokontany: "besara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3710553,
          lng: 44.6982672,
        },
        _id: "64f75285ef8f606073e9bfa3",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ampanihy",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3760109,
          lng: 44.712929,
        },
        _id: "64f75285ef8f606073e9bfa4",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ampanihy",
        fokontany: "ankilimida",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3574743,
          lng: 44.7475208,
        },
        _id: "64f75285ef8f606073e9bfa5",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ampanihy",
        fokontany: "anjatelo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3820236,
          lng: 44.7076528,
        },
        _id: "64f75285ef8f606073e9bfa6",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ampanihy",
        fokontany: "andaboro",
        __v: 0,
      },
      {
        coords: {
          lat: -20.43098,
          lng: 44.6066578,
        },
        _id: "64f75285ef8f606073e9bfa7",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ampanihy",
        fokontany: "benato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3970047,
          lng: 44.6395639,
        },
        _id: "64f75285ef8f606073e9bfa8",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ampanihy",
        fokontany: "vangoroka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4275765,
          lng: 44.5737286,
        },
        _id: "64f75285ef8f606073e9bfa9",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ampanihy",
        fokontany: "agnolotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4077826,
          lng: 44.8224735,
        },
        _id: "64f75285ef8f606073e9bfaa",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ampanihy",
        fokontany: "manamby",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4251471,
          lng: 44.6536595,
        },
        _id: "64f75285ef8f606073e9bfab",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ampanihy",
        fokontany: "mananjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5072786,
          lng: 44.831834,
        },
        _id: "64f75285ef8f606073e9bfac",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ampanihy",
        fokontany: "bemahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.516667,
          lng: 44.616667,
        },
        _id: "64f75285ef8f606073e9bfad",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ampanihy",
        fokontany: "andohaviana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5934957,
          lng: 44.6818377,
        },
        _id: "64f75285ef8f606073e9bfae",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ampanihy",
        fokontany: "andranofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.716667,
          lng: 44.75,
        },
        _id: "64f75285ef8f606073e9bfaf",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ampanihy",
        fokontany: "ambinda",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7561597,
          lng: 44.89730489999999,
        },
        _id: "64f75285ef8f606073e9bfb0",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ampanihy",
        fokontany: "mahasoa sud",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7933369,
          lng: 44.8411927,
        },
        _id: "64f75285ef8f606073e9bfb1",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ampanihy",
        fokontany: "tanambao mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.41792,
          lng: 45.0513189,
        },
        _id: "64f75285ef8f606073e9bfb2",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilizato",
        fokontany: "ankilizato atsimondrano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2710229,
          lng: 45.186889,
        },
        _id: "64f75285ef8f606073e9bfb3",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilizato",
        fokontany: "andranomalio",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3711067,
          lng: 45.0933497,
        },
        _id: "64f75285ef8f606073e9bfb4",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilizato",
        fokontany: "manomba",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4024369,
          lng: 44.95334889999999,
        },
        _id: "64f75285ef8f606073e9bfb5",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilizato",
        fokontany: "filanjara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3806935,
          lng: 45.0513189,
        },
        _id: "64f75285ef8f606073e9bfb6",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilizato",
        fokontany: "ankilizato avaradrano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.433333,
          lng: 45.1,
        },
        _id: "64f75285ef8f606073e9bfb7",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilizato",
        fokontany: "ampandrabe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4955133,
          lng: 45.0466511,
        },
        _id: "64f75285ef8f606073e9bfb8",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilizato",
        fokontany: "beoky",
        __v: 0,
      },
      {
        coords: {
          lat: -20.466667,
          lng: 44.98333299999999,
        },
        _id: "64f75285ef8f606073e9bfb9",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilizato",
        fokontany: "ambiky",
        __v: 0,
      },
      {
        coords: {
          lat: -20.47897,
          lng: 45.2285309,
        },
        _id: "64f75285ef8f606073e9bfba",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilizato",
        fokontany: "tsianaloka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.4690246,
          lng: 45.3087733,
        },
        _id: "64f75285ef8f606073e9bfbb",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilizato",
        fokontany: "andimaky mahazoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.51939,
          lng: 45.007999,
        },
        _id: "64f75285ef8f606073e9bfbc",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilizato",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5439159,
          lng: 45.2806177,
        },
        _id: "64f75285ef8f606073e9bfbd",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilizato",
        fokontany: "ankatoky sakamaly",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6930715,
          lng: 45.3369459,
        },
        _id: "64f75285ef8f606073e9bfbe",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ankilizato",
        fokontany: "ampandramena",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7186095,
          lng: 45.186889,
        },
        _id: "64f75285ef8f606073e9bfbf",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ambia",
        fokontany: "ambia",
        __v: 0,
      },
      {
        coords: {
          lat: -20.616667,
          lng: 45.016667,
        },
        _id: "64f75285ef8f606073e9bfc0",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ambia",
        fokontany: "bevahy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6691877,
          lng: 45.0933497,
        },
        _id: "64f75285ef8f606073e9bfc1",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ambia",
        fokontany: "ankilimahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -20.6818233,
          lng: 44.98134520000001,
        },
        _id: "64f75285ef8f606073e9bfc2",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ambia",
        fokontany: "taolamboay",
        __v: 0,
      },
      {
        coords: {
          lat: -20.739519,
          lng: 45.1196399,
        },
        _id: "64f75285ef8f606073e9bfc3",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ambia",
        fokontany: "soatanimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7686817,
          lng: 45.0559871,
        },
        _id: "64f75285ef8f606073e9bfc4",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "ambia",
        fokontany: "tamotamo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0390318,
          lng: 44.9300055,
        },
        _id: "64f75285ef8f606073e9bfc5",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mandabe",
        fokontany: "mandabe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8181151,
          lng: 44.7943804,
        },
        _id: "64f75285ef8f606073e9bfc6",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mandabe",
        fokontany: "ambango",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9176495,
          lng: 44.7475208,
        },
        _id: "64f75285ef8f606073e9bfc7",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mandabe",
        fokontany: "soatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9426653,
          lng: 44.8692573,
        },
        _id: "64f75285ef8f606073e9bfc8",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mandabe",
        fokontany: "ambinany maharivo",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0671715,
          lng: 44.6630541,
        },
        _id: "64f75285ef8f606073e9bfc9",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mandabe",
        fokontany: "ankilimanjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1418568,
          lng: 44.831834,
        },
        _id: "64f75285ef8f606073e9bfca",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mandabe",
        fokontany: "ambinany zama",
        __v: 0,
      },
      {
        coords: {
          lat: -21.0669338,
          lng: 45.027985,
        },
        _id: "64f75285ef8f606073e9bfcb",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mandabe",
        fokontany: "anja",
        __v: 0,
      },
      {
        coords: {
          lat: -21.116667,
          lng: 45.083333,
        },
        _id: "64f75285ef8f606073e9bfcc",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mandabe",
        fokontany: "ambararatanaomby",
        __v: 0,
      },
      {
        coords: {
          lat: -21.1914736,
          lng: 45.0933497,
        },
        _id: "64f75285ef8f606073e9bfcd",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mandabe",
        fokontany: "fandroa",
        __v: 0,
      },
      {
        coords: {
          lat: -21.2162333,
          lng: 44.9440129,
        },
        _id: "64f75285ef8f606073e9bfce",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mandabe",
        fokontany: "besely sud",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3494236,
          lng: 45.5983492,
        },
        _id: "64f75285ef8f606073e9bfcf",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "malaimbandy",
        fokontany: "malaimbandy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2054373,
          lng: 45.506339,
        },
        _id: "64f75285ef8f606073e9bfd0",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "malaimbandy",
        fokontany: "bepingaratsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1796594,
          lng: 45.5723794,
        },
        _id: "64f75285ef8f606073e9bfd1",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "malaimbandy",
        fokontany: "mahasoa nord",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2612223,
          lng: 45.5346307,
        },
        _id: "64f75285ef8f606073e9bfd2",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "malaimbandy",
        fokontany: "ambonara nord",
        __v: 0,
      },
      {
        coords: {
          lat: -20.255659,
          lng: 45.464802,
        },
        _id: "64f75285ef8f606073e9bfd3",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "malaimbandy",
        fokontany: "soarano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2418191,
          lng: 45.5912652,
        },
        _id: "64f75285ef8f606073e9bfd4",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "malaimbandy",
        fokontany: "marolinta",
        __v: 0,
      },
      {
        coords: {
          lat: -20.2654792,
          lng: 45.6952715,
        },
        _id: "64f75285ef8f606073e9bfd5",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "malaimbandy",
        fokontany: "androtsy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3562215,
          lng: 45.3933422,
        },
        _id: "64f75285ef8f606073e9bfd6",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "malaimbandy",
        fokontany: "mahasoa pomay",
        __v: 0,
      },
      {
        coords: {
          lat: -20.3722876,
          lng: 45.6479676,
        },
        _id: "64f75285ef8f606073e9bfd7",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "malaimbandy",
        fokontany: "anosimbazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -20.442077,
          lng: 45.544065,
        },
        _id: "64f75285ef8f606073e9bfd8",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "malaimbandy",
        fokontany: "mandarano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.390056,
          lng: 45.73314860000001,
        },
        _id: "64f75285ef8f606073e9bfd9",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "malaimbandy",
        fokontany: "tambazo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5174749,
          lng: 45.4874873,
        },
        _id: "64f75285ef8f606073e9bfda",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "malaimbandy",
        fokontany: "fenoarivo pomay",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5026673,
          lng: 45.73314860000001,
        },
        _id: "64f75285ef8f606073e9bfdb",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "malaimbandy",
        fokontany: "mahatsinjo est",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5788018,
          lng: 45.6196079,
        },
        _id: "64f75285ef8f606073e9bfdc",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "malaimbandy",
        fokontany: "ankerika",
        __v: 0,
      },
      {
        coords: {
          lat: -20.83485,
          lng: 45.031311,
        },
        _id: "64f75285ef8f606073e9bfdd",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "beronono",
        fokontany: "beronono",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8432345,
          lng: 44.9440129,
        },
        _id: "64f75285ef8f606073e9bfde",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "beronono",
        fokontany: "ambalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.8867339,
          lng: 44.95334889999999,
        },
        _id: "64f75285ef8f606073e9bfdf",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "beronono",
        fokontany: "ambiky",
        __v: 0,
      },
      {
        coords: {
          lat: -20.99371,
          lng: 45.042622,
        },
        _id: "64f75285ef8f606073e9bfe0",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "beronono",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9833329,
          lng: 44.98333299999999,
        },
        _id: "64f75285ef8f606073e9bfe1",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "beronono",
        fokontany: "maharivokely",
        __v: 0,
      },
      {
        coords: {
          lat: -20.7912668,
          lng: 45.6385125,
        },
        _id: "64f75285ef8f606073e9bfe2",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mazavasoa",
        fokontany: "mazavasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5751258,
          lng: 45.6810754,
        },
        _id: "64f75285ef8f606073e9bfe3",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mazavasoa",
        fokontany: "mahavano",
        __v: 0,
      },
      {
        coords: {
          lat: -20.5901918,
          lng: 45.7426226,
        },
        _id: "64f75285ef8f606073e9bfe4",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mazavasoa",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.65,
          lng: 45.6666669,
        },
        _id: "64f75285ef8f606073e9bfe5",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mazavasoa",
        fokontany: "antsakoazato",
        __v: 0,
      },
      {
        coords: {
          lat: -20.777161,
          lng: 45.668869,
        },
        _id: "64f75285ef8f606073e9bfe6",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mazavasoa",
        fokontany: "ikaosy",
        __v: 0,
      },
      {
        coords: {
          lat: -20.9167472,
          lng: 45.5629394,
        },
        _id: "64f75285ef8f606073e9bfe7",
        province: "toliary",
        region: "menabe",
        district: "mahabo",
        commune: "mazavasoa",
        fokontany: "ampandramaneno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6992518,
          lng: 44.5737286,
        },
        _id: "64f75285ef8f606073e9bfe8",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "atsinanantsena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4953824,
          lng: 44.4795173,
        },
        _id: "64f75285ef8f606073e9bfe9",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "antambohobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4962555,
          lng: 44.5219358,
        },
        _id: "64f75285ef8f606073e9bfea",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "besely tsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5463532,
          lng: 44.5407761,
        },
        _id: "64f75285ef8f606073e9bfeb",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "ankatsaky ambalakida",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5314661,
          lng: 44.5737286,
        },
        _id: "64f75285ef8f606073e9bfec",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "andranotsiriry",
        __v: 0,
      },
      {
        coords: {
          lat: -19.550925,
          lng: 44.4582937,
        },
        _id: "64f75285ef8f606073e9bfed",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "andapotaly",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5818453,
          lng: 44.4488579,
        },
        _id: "64f75285ef8f606073e9bfee",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "ambakivao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5766972,
          lng: 44.5030878,
        },
        _id: "64f75285ef8f606073e9bfef",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "ambivibenetsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6212089,
          lng: 44.5549014,
        },
        _id: "64f75285ef8f606073e9bff0",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "ankotrofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5915991,
          lng: 44.4700858,
        },
        _id: "64f75285ef8f606073e9bff1",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "antsakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6268077,
          lng: 44.5219358,
        },
        _id: "64f75285ef8f606073e9bff2",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "andranofoty",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6446663,
          lng: 44.4795173,
        },
        _id: "64f75285ef8f606073e9bff3",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "mavohatoky",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6419345,
          lng: 44.499553,
        },
        _id: "64f75285ef8f606073e9bff4",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "belengo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6515953,
          lng: 44.5172245,
        },
        _id: "64f75285ef8f606073e9bff5",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "iaborano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6569097,
          lng: 44.5525475,
        },
        _id: "64f75285ef8f606073e9bff6",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "marotaola",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6502601,
          lng: 44.4464987,
        },
        _id: "64f75285ef8f606073e9bff7",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "ankatsao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6647664,
          lng: 44.5572552,
        },
        _id: "64f75285ef8f606073e9bff8",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "ambohidrahay",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6603988,
          lng: 44.4889469,
        },
        _id: "64f75285ef8f606073e9bff9",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "bejio",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6313115,
          lng: 44.43234080000001,
        },
        _id: "64f75285ef8f606073e9bffa",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "soarano mer",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6671877,
          lng: 44.5195802,
        },
        _id: "64f75285ef8f606073e9bffb",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "toby ankapaiky",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6830364,
          lng: 44.5360667,
        },
        _id: "64f75285ef8f606073e9bffc",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "soatsioky",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6848875,
          lng: 44.5525475,
        },
        _id: "64f75285ef8f606073e9bffd",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "avaradrova",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6986891,
          lng: 44.5419534,
        },
        _id: "64f75285ef8f606073e9bffe",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7004116,
          lng: 44.4653693,
        },
        _id: "64f75285ef8f606073e9bfff",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "antranoroa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7033846,
          lng: 44.5437192,
        },
        _id: "64f75285ef8f606073e9c000",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "andrefatsena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7065462,
          lng: 44.5466622,
        },
        _id: "64f75285ef8f606073e9c001",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "atsimontsena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6966855,
          lng: 44.43234080000001,
        },
        _id: "64f75285ef8f606073e9c002",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "antsakoamalinika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6982455,
          lng: 44.5172245,
        },
        _id: "64f75285ef8f606073e9c003",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "beraketa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6947931,
          lng: 44.4983747,
        },
        _id: "64f75285ef8f606073e9c004",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "samaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7375065,
          lng: 44.4512171,
        },
        _id: "64f75285ef8f606073e9c005",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "tomboarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7805111,
          lng: 44.4181786,
        },
        _id: "64f75285ef8f606073e9c006",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belo sur tsiribihina",
        fokontany: "tsimandrafoza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.276415,
          lng: 44.6912267,
        },
        _id: "64f75285ef8f606073e9c007",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "andimaky manambolo",
        fokontany: "ampanarena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2251477,
          lng: 44.6160619,
        },
        _id: "64f75285ef8f606073e9c008",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "andimaky manambolo",
        fokontany: "belobaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2116523,
          lng: 44.5690225,
        },
        _id: "64f75285ef8f606073e9c009",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "andimaky manambolo",
        fokontany: "ankaivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2087919,
          lng: 44.7146908,
        },
        _id: "64f75285ef8f606073e9c00a",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "andimaky manambolo",
        fokontany: "bemamba",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2500664,
          lng: 44.76158359999999,
        },
        _id: "64f75285ef8f606073e9c00b",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "andimaky manambolo",
        fokontany: "ambalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2075189,
          lng: 44.658357,
        },
        _id: "64f75285ef8f606073e9c00c",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "andimaky manambolo",
        fokontany: "ambaravaratany",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2751771,
          lng: 44.6348645,
        },
        _id: "64f75285ef8f606073e9c00d",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "andimaky manambolo",
        fokontany: "andimaky ampasy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2680363,
          lng: 44.7334536,
        },
        _id: "64f75285ef8f606073e9c00e",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "andimaky manambolo",
        fokontany: "antsakoazato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3051975,
          lng: 44.7334536,
        },
        _id: "64f75285ef8f606073e9c00f",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "andimaky manambolo",
        fokontany: "bevaho",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3705292,
          lng: 44.7475208,
        },
        _id: "64f75285ef8f606073e9c010",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "andimaky manambolo",
        fokontany: "ankilizato nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3281309,
          lng: 44.7943804,
        },
        _id: "64f75285ef8f606073e9c011",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "andimaky manambolo",
        fokontany: "tsibiray",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4246133,
          lng: 44.6630541,
        },
        _id: "64f75285ef8f606073e9c012",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "andimaky manambolo",
        fokontany: "behamotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6315675,
          lng: 44.6113601,
        },
        _id: "64f75285ef8f606073e9c013",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "bemarivo ankirondro",
        fokontany: "ankirondro nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5724732,
          lng: 44.6066578,
        },
        _id: "64f75285ef8f606073e9c014",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "bemarivo ankirondro",
        fokontany: "ampasimandroatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6065478,
          lng: 44.6019551,
        },
        _id: "64f75285ef8f606073e9c015",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "bemarivo ankirondro",
        fokontany: "mangotroka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6507487,
          lng: 44.59842769999999,
        },
        _id: "64f75285ef8f606073e9c016",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "bemarivo ankirondro",
        fokontany: "ankirondro sud",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6624618,
          lng: 44.6019551,
        },
        _id: "64f75285ef8f606073e9c017",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "bemarivo ankirondro",
        fokontany: "bevoay",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6776629,
          lng: 44.5831393,
        },
        _id: "64f75285ef8f606073e9c018",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "bemarivo ankirondro",
        fokontany: "vohimary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6906692,
          lng: 44.6160619,
        },
        _id: "64f75285ef8f606073e9c019",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "bemarivo ankirondro",
        fokontany: "mahavelonarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6786526,
          lng: 44.6395639,
        },
        _id: "64f75285ef8f606073e9c01a",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "bemarivo ankirondro",
        fokontany: "ankilizato est",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7697118,
          lng: 44.51251269999999,
        },
        _id: "64f75285ef8f606073e9c01b",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsimafana",
        fokontany: "tsimafana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6925739,
          lng: 44.72876369999999,
        },
        _id: "64f75285ef8f606073e9c01c",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsimafana",
        fokontany: "berendrika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7154424,
          lng: 44.6113601,
        },
        _id: "64f75285ef8f606073e9c01d",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsimafana",
        fokontany: "mananjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7390121,
          lng: 44.5360667,
        },
        _id: "64f75285ef8f606073e9c01e",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsimafana",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7320727,
          lng: 44.6771425,
        },
        _id: "64f75285ef8f606073e9c01f",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsimafana",
        fokontany: "ampanihy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7327294,
          lng: 44.6231138,
        },
        _id: "64f75285ef8f606073e9c020",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsimafana",
        fokontany: "tsitakabasia",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7505477,
          lng: 44.4842324,
        },
        _id: "64f75285ef8f606073e9c021",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsimafana",
        fokontany: "kaday",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7252393,
          lng: 44.6395639,
        },
        _id: "64f75285ef8f606073e9c022",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsimafana",
        fokontany: "tsaratompo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7331998,
          lng: 44.6513105,
        },
        _id: "64f75285ef8f606073e9c023",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsimafana",
        fokontany: "amboanio mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7333329,
          lng: 44.75,
        },
        _id: "64f75285ef8f606073e9c024",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsimafana",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7543321,
          lng: 44.709999,
        },
        _id: "64f75285ef8f606073e9c025",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsimafana",
        fokontany: "tsianaloka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7884036,
          lng: 44.7053066,
        },
        _id: "64f75285ef8f606073e9c026",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsimafana",
        fokontany: "kiboy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7824779,
          lng: 44.53135690000001,
        },
        _id: "64f75285ef8f606073e9c027",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsimafana",
        fokontany: "anja",
        __v: 0,
      },
      {
        coords: {
          lat: -19.977409,
          lng: 44.6048199,
        },
        _id: "64f75285ef8f606073e9c028",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "beroboka nord",
        fokontany: "beroboka nord",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8334625,
          lng: 44.6066578,
        },
        _id: "64f75285ef8f606073e9c029",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "beroboka nord",
        fokontany: "lambokely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8689914,
          lng: 44.493661,
        },
        _id: "64f75285ef8f606073e9c02a",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "beroboka nord",
        fokontany: "sabora",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9201715,
          lng: 44.5831393,
        },
        _id: "64f75285ef8f606073e9c02b",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "beroboka nord",
        fokontany: "tanambao mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9205879,
          lng: 44.6113601,
        },
        _id: "64f75285ef8f606073e9c02c",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "beroboka nord",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9097003,
          lng: 44.7193823,
        },
        _id: "64f75285ef8f606073e9c02d",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "beroboka nord",
        fokontany: "ankitrevo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9254721,
          lng: 44.5219358,
        },
        _id: "64f75285ef8f606073e9c02e",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "beroboka nord",
        fokontany: "ampihamy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9706073,
          lng: 44.6301646,
        },
        _id: "64f75285ef8f606073e9c02f",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "beroboka nord",
        fokontany: "belamoty",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9723026,
          lng: 44.7568965,
        },
        _id: "64f75285ef8f606073e9c030",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "beroboka nord",
        fokontany: "mandroatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8057296,
          lng: 44.82715400000001,
        },
        _id: "64f75285ef8f606073e9c031",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsaraotana",
        fokontany: "antsiraraky",
        __v: 0,
      },
      {
        coords: {
          lat: -19.769279,
          lng: 44.83472099999999,
        },
        _id: "64f75285ef8f606073e9c032",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsaraotana",
        fokontany: "tsaraotana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7693475,
          lng: 44.8832833,
        },
        _id: "64f75285ef8f606073e9c033",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsaraotana",
        fokontany: "tanambao ambato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7813038,
          lng: 44.8528884,
        },
        _id: "64f75285ef8f606073e9c034",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsaraotana",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7937657,
          lng: 44.8575658,
        },
        _id: "64f75285ef8f606073e9c035",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsaraotana",
        fokontany: "antroboky",
        __v: 0,
      },
      {
        coords: {
          lat: -19.783333,
          lng: 44.766667,
        },
        _id: "64f75285ef8f606073e9c036",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsaraotana",
        fokontany: "andranomandeha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8093908,
          lng: 44.8669193,
        },
        _id: "64f75285ef8f606073e9c037",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsaraotana",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.815814,
          lng: 44.8832833,
        },
        _id: "64f75285ef8f606073e9c038",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsaraotana",
        fokontany: "andimaky",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8369913,
          lng: 44.8458713,
        },
        _id: "64f75285ef8f606073e9c039",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsaraotana",
        fokontany: "mialiloha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8364034,
          lng: 44.8037467,
        },
        _id: "64f75285ef8f606073e9c03a",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "tsaraotana",
        fokontany: "antsetaky",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6426206,
          lng: 44.709999,
        },
        _id: "64f75285ef8f606073e9c03b",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "masoarivo",
        fokontany: "masoarivo I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5139098,
          lng: 44.7943804,
        },
        _id: "64f75285ef8f606073e9c03c",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "masoarivo",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5647628,
          lng: 44.8692573,
        },
        _id: "64f75285ef8f606073e9c03d",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "masoarivo",
        fokontany: "ampitarata",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5862777,
          lng: 44.6818377,
        },
        _id: "64f75285ef8f606073e9c03e",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "masoarivo",
        fokontany: "soatanimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.640866,
          lng: 44.7896966,
        },
        _id: "64f75285ef8f606073e9c03f",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "masoarivo",
        fokontany: "soanafindra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6563441,
          lng: 44.7885255,
        },
        _id: "64f75285ef8f606073e9c040",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "masoarivo",
        fokontany: "antsoalafiky hima",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6746569,
          lng: 44.7709565,
        },
        _id: "64f75285ef8f606073e9c041",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "masoarivo",
        fokontany: "ankazoberavy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.658211,
          lng: 44.733559,
        },
        _id: "64f75285ef8f606073e9c042",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "masoarivo",
        fokontany: "masoarivo II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6880294,
          lng: 44.831834,
        },
        _id: "64f75285ef8f606073e9c043",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "masoarivo",
        fokontany: "amboloando",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6916349,
          lng: 44.6724469,
        },
        _id: "64f75285ef8f606073e9c044",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "masoarivo",
        fokontany: "serinam",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7005917,
          lng: 44.6513105,
        },
        _id: "64f75285ef8f606073e9c045",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "masoarivo",
        fokontany: "bevilo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7333329,
          lng: 44.883333,
        },
        _id: "64f75285ef8f606073e9c046",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "masoarivo",
        fokontany: "androngony",
        __v: 0,
      },
      {
        coords: {
          lat: -19.271965,
          lng: 44.493661,
        },
        _id: "64f75285ef8f606073e9c047",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "aboalimena",
        fokontany: "aboalimena atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1885539,
          lng: 44.3851171,
        },
        _id: "64f75285ef8f606073e9c048",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "aboalimena",
        fokontany: "amboriabo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1939376,
          lng: 44.3520324,
        },
        _id: "64f75285ef8f606073e9c049",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "aboalimena",
        fokontany: "magnaritoka ankorobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.220107,
          lng: 44.4040122,
        },
        _id: "64f75285ef8f606073e9c04a",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "aboalimena",
        fokontany: "tanambao manambolo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2089495,
          lng: 44.455935,
        },
        _id: "64f75285ef8f606073e9c04b",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "aboalimena",
        fokontany: "aboalimena avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.320142,
          lng: 44.4276206,
        },
        _id: "64f75285ef8f606073e9c04c",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "aboalimena",
        fokontany: "ankirijy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.269274,
          lng: 44.38039209999999,
        },
        _id: "64f75285ef8f606073e9c04d",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "aboalimena",
        fokontany: "amboanio",
        __v: 0,
      },
      {
        coords: {
          lat: -19.383333,
          lng: 44.466667,
        },
        _id: "64f75285ef8f606073e9c04e",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "aboalimena",
        fokontany: "moravano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.63179,
          lng: 44.966999,
        },
        _id: "64f75285ef8f606073e9c04f",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ankalalobe",
        fokontany: "ankalalobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5444327,
          lng: 44.94868109999999,
        },
        _id: "64f75285ef8f606073e9c050",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ankalalobe",
        fokontany: "mahazoarivosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4787723,
          lng: 45.0933497,
        },
        _id: "64f75285ef8f606073e9c051",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ankalalobe",
        fokontany: "tanambao morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5785987,
          lng: 44.9580161,
        },
        _id: "64f75285ef8f606073e9c052",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ankalalobe",
        fokontany: "antsiriry",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5883179,
          lng: 45.0139904,
        },
        _id: "64f75285ef8f606073e9c053",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ankalalobe",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5139098,
          lng: 45.2056196,
        },
        _id: "64f75285ef8f606073e9c054",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ankalalobe",
        fokontany: "mahasoa II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5760311,
          lng: 45.1962533,
        },
        _id: "64f75285ef8f606073e9c055",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ankalalobe",
        fokontany: "andranomanenty mitombo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6200285,
          lng: 45.1588073,
        },
        _id: "64f75285ef8f606073e9c056",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ankalalobe",
        fokontany: "anketa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6630935,
          lng: 45.1775265,
        },
        _id: "64f75285ef8f606073e9c057",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ankalalobe",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6834116,
          lng: 44.9346751,
        },
        _id: "64f75285ef8f606073e9c058",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ankalalobe",
        fokontany: "betomba",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7116418,
          lng: 45.2431035,
        },
        _id: "64f75285ef8f606073e9c059",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ankalalobe",
        fokontany: "bekapika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7505588,
          lng: 45.1307427,
        },
        _id: "64f75285ef8f606073e9c05a",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ankalalobe",
        fokontany: "bemangily I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3314389,
          lng: 45.0373171,
        },
        _id: "64f75285ef8f606073e9c05b",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ambiky",
        fokontany: "ambiky",
        __v: 0,
      },
      {
        coords: {
          lat: -19.37005,
          lng: 44.92445,
        },
        _id: "64f75285ef8f606073e9c05c",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ambiky",
        fokontany: "andranobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4050717,
          lng: 44.9346751,
        },
        _id: "64f75285ef8f606073e9c05d",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ambiky",
        fokontany: "andranomiely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4284306,
          lng: 44.8599044,
        },
        _id: "64f75285ef8f606073e9c05e",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ambiky",
        fokontany: "ankerabe centre",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4765767,
          lng: 44.9580161,
        },
        _id: "64f75285ef8f606073e9c05f",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ambiky",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4851219,
          lng: 44.9159938,
        },
        _id: "64f75285ef8f606073e9c060",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ambiky",
        fokontany: "ambonara ampanity",
        __v: 0,
      },
      {
        coords: {
          lat: -19.514138,
          lng: 44.9860096,
        },
        _id: "64f75285ef8f606073e9c061",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ambiky",
        fokontany: "maropia",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5105004,
          lng: 44.95334889999999,
        },
        _id: "64f75285ef8f606073e9c062",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ambiky",
        fokontany: "bemangily II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7286,
          lng: 44.95911,
        },
        _id: "64f75285ef8f606073e9c063",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "berevo",
        fokontany: "berevo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.711021,
          lng: 45.019261,
        },
        _id: "64f75285ef8f606073e9c064",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "berevo",
        fokontany: "mahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7333923,
          lng: 45.0326508,
        },
        _id: "64f75285ef8f606073e9c065",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "berevo",
        fokontany: "andimado mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.74571,
          lng: 45.0456999,
        },
        _id: "64f75285ef8f606073e9c066",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "berevo",
        fokontany: "fenoarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7734887,
          lng: 45.0419839,
        },
        _id: "64f75285ef8f606073e9c067",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "berevo",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7523407,
          lng: 44.99533700000001,
        },
        _id: "64f75285ef8f606073e9c068",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "berevo",
        fokontany: "soanafindra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8260435,
          lng: 44.95334889999999,
        },
        _id: "64f75285ef8f606073e9c069",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "berevo",
        fokontany: "tsimaloto",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8264061,
          lng: 45.0186548,
        },
        _id: "64f75285ef8f606073e9c06a",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "berevo",
        fokontany: "tsangorana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9301764,
          lng: 44.8599044,
        },
        _id: "64f75285ef8f606073e9c06b",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "antsoha",
        fokontany: "antsoha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.86624,
          lng: 44.892231,
        },
        _id: "64f75285ef8f606073e9c06c",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "antsoha",
        fokontany: "bekily I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.898932,
          lng: 44.8411927,
        },
        _id: "64f75285ef8f606073e9c06d",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "antsoha",
        fokontany: "bekilikely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9057913,
          lng: 44.8926315,
        },
        _id: "64f75285ef8f606073e9c06e",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "antsoha",
        fokontany: "ankarena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9308675,
          lng: 44.9159938,
        },
        _id: "64f75285ef8f606073e9c06f",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "antsoha",
        fokontany: "lavaheloka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0109047,
          lng: 44.8692573,
        },
        _id: "64f75285ef8f606073e9c070",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "antsoha",
        fokontany: "bebozaka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.066667,
          lng: 44.883333,
        },
        _id: "64f75285ef8f606073e9c071",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "antsoha",
        fokontany: "ankorokily",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9537109,
          lng: 45.11615,
        },
        _id: "64f75285ef8f606073e9c072",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belinta",
        fokontany: "belinta",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8808814,
          lng: 45.1167167,
        },
        _id: "64f75285ef8f606073e9c073",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belinta",
        fokontany: "soaserana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8978011,
          lng: 45.2431035,
        },
        _id: "64f75285ef8f606073e9c074",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belinta",
        fokontany: "ankilimidahy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9434823,
          lng: 45.0653249,
        },
        _id: "64f75285ef8f606073e9c075",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belinta",
        fokontany: "bemokarana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9480741,
          lng: 45.1962533,
        },
        _id: "64f75285ef8f606073e9c076",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belinta",
        fokontany: "antalafotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9812793,
          lng: 45.0093264,
        },
        _id: "64f75285ef8f606073e9c077",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belinta",
        fokontany: "madoso II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9673828,
          lng: 45.1400956,
        },
        _id: "64f75285ef8f606073e9c078",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belinta",
        fokontany: "ankotrofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9806569,
          lng: 45.0653249,
        },
        _id: "64f75285ef8f606073e9c079",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belinta",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0481182,
          lng: 45.1307427,
        },
        _id: "64f75285ef8f606073e9c07a",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "belinta",
        fokontany: "andranomiolaky",
        __v: 0,
      },
      {
        coords: {
          lat: -19.784362,
          lng: 45.094433,
        },
        _id: "64f75285ef8f606073e9c07b",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ankiroroky",
        fokontany: "begidro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8177964,
          lng: 45.1962533,
        },
        _id: "64f75285ef8f606073e9c07c",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ankiroroky",
        fokontany: "behera",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8288328,
          lng: 45.0699945,
        },
        _id: "64f75285ef8f606073e9c07d",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ankiroroky",
        fokontany: "ankororoky",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8557797,
          lng: 45.1400956,
        },
        _id: "64f75285ef8f606073e9c07e",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ankiroroky",
        fokontany: "ambakaka centre",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8505693,
          lng: 45.0653249,
        },
        _id: "64f75285ef8f606073e9c07f",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ankiroroky",
        fokontany: "sakadomo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9003473,
          lng: 45.0466511,
        },
        _id: "64f75285ef8f606073e9c080",
        province: "toliary",
        region: "menabe",
        district: "belo sur tsiribihina",
        commune: "ankiroroky",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5290281,
          lng: 45.4709982,
        },
        _id: "64f75285ef8f606073e9c081",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "miandrivazo",
        fokontany: "miandrivazo I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4100631,
          lng: 45.4403911,
        },
        _id: "64f75285ef8f606073e9c082",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "miandrivazo",
        fokontany: "andromay",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4720748,
          lng: 45.4450986,
        },
        _id: "64f75285ef8f606073e9c083",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "miandrivazo",
        fokontany: "amboloando",
        __v: 0,
      },
      {
        coords: {
          lat: -19.460951,
          lng: 45.5568389,
        },
        _id: "64f75285ef8f606073e9c084",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "miandrivazo",
        fokontany: "anarabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5354306,
          lng: 45.46157849999999,
        },
        _id: "64f75285ef8f606073e9c085",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "miandrivazo",
        fokontany: "mamotsatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5277792,
          lng: 45.4556922,
        },
        _id: "64f75285ef8f606073e9c086",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "miandrivazo",
        fokontany: "miandrivazo II",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5389092,
          lng: 45.4427448,
        },
        _id: "64f75285ef8f606073e9c087",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "miandrivazo",
        fokontany: "andolobe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5354306,
          lng: 45.46157849999999,
        },
        _id: "64f75285ef8f606073e9c088",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "miandrivazo",
        fokontany: "ampanasana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5438449,
          lng: 45.4286244,
        },
        _id: "64f75285ef8f606073e9c089",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "miandrivazo",
        fokontany: "tsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5594223,
          lng: 45.4262714,
        },
        _id: "64f75285ef8f606073e9c08a",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "miandrivazo",
        fokontany: "antanimainty",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5745945,
          lng: 45.4450986,
        },
        _id: "64f75285ef8f606073e9c08b",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "miandrivazo",
        fokontany: "ambatomena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5894998,
          lng: 45.4780642,
        },
        _id: "64f75285ef8f606073e9c08c",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "miandrivazo",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6209445,
          lng: 45.4592239,
        },
        _id: "64f75285ef8f606073e9c08d",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "miandrivazo",
        fokontany: "betalatala",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6822628,
          lng: 45.506339,
        },
        _id: "64f75285ef8f606073e9c08e",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "miandrivazo",
        fokontany: "andranomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6158961,
          lng: 45.7142063,
        },
        _id: "64f75285ef8f606073e9c08f",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "dabolava",
        fokontany: "dabolava",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5819863,
          lng: 45.544065,
        },
        _id: "64f75285ef8f606073e9c090",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "dabolava",
        fokontany: "betamotamo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5674157,
          lng: 45.6479676,
        },
        _id: "64f75285ef8f606073e9c091",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "dabolava",
        fokontany: "andimaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6053727,
          lng: 45.6196079,
        },
        _id: "64f75285ef8f606073e9c092",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "dabolava",
        fokontany: "ankiranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.622829,
          lng: 45.7984579,
        },
        _id: "64f75285ef8f606073e9c093",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "dabolava",
        fokontany: "madiokely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5506467,
          lng: 45.3980449,
        },
        _id: "64f75285ef8f606073e9c094",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "bemahatazana",
        fokontany: "bemahatazana",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4373936,
          lng: 45.3181623,
        },
        _id: "64f75285ef8f606073e9c095",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "bemahatazana",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5054411,
          lng: 45.3275531,
        },
        _id: "64f75285ef8f606073e9c096",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "bemahatazana",
        fokontany: "tsienerena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5612841,
          lng: 45.3275531,
        },
        _id: "64f75285ef8f606073e9c097",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "bemahatazana",
        fokontany: "tsianaloka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.651661,
          lng: 45.31459,
        },
        _id: "64f75285ef8f606073e9c098",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "bemahatazana",
        fokontany: "ankazomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6923417,
          lng: 45.2853092,
        },
        _id: "64f75285ef8f606073e9c099",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "bemahatazana",
        fokontany: "antalafotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4918405,
          lng: 45.3886399,
        },
        _id: "64f75285ef8f606073e9c09a",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ampanihy",
        fokontany: "ampanihy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.448482,
          lng: 45.3839381,
        },
        _id: "64f75285ef8f606073e9c09b",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ampanihy",
        fokontany: "beteva",
        __v: 0,
      },
      {
        coords: {
          lat: -19.4882213,
          lng: 45.4145083,
        },
        _id: "64f75285ef8f606073e9c09c",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ampanihy",
        fokontany: "anahidrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6871102,
          lng: 45.4098039,
        },
        _id: "64f75285ef8f606073e9c09d",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "anosimena",
        fokontany: "masiakampy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.5844531,
          lng: 45.41686070000001,
        },
        _id: "64f75285ef8f606073e9c09e",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "anosimena",
        fokontany: "antsakoamadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6406309,
          lng: 45.4027482,
        },
        _id: "64f75285ef8f606073e9c09f",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "anosimena",
        fokontany: "ampihiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.66921,
          lng: 45.401508,
        },
        _id: "64f75285ef8f606073e9c0a0",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "anosimena",
        fokontany: "anosimena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6835437,
          lng: 45.4356841,
        },
        _id: "64f75285ef8f606073e9c0a1",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "anosimena",
        fokontany: "ankilimisarotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6661488,
          lng: 45.3651355,
        },
        _id: "64f75285ef8f606073e9c0a2",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "anosimena",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2501642,
          lng: 45.3745358,
        },
        _id: "64f75285ef8f606073e9c0a3",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "manandaza",
        fokontany: "manandaza",
        __v: 0,
      },
      {
        coords: {
          lat: -19.165554,
          lng: 45.2806177,
        },
        _id: "64f75285ef8f606073e9c0a4",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "manandaza",
        fokontany: "antsakoabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1751042,
          lng: 45.4027482,
        },
        _id: "64f75285ef8f606073e9c0a5",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "manandaza",
        fokontany: "andrafiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2167367,
          lng: 45.2056196,
        },
        _id: "64f75285ef8f606073e9c0a6",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "manandaza",
        fokontany: "beahidrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.1859384,
          lng: 45.4686431,
        },
        _id: "64f75285ef8f606073e9c0a7",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "manandaza",
        fokontany: "anosikely",
        __v: 0,
      },
      {
        coords: {
          lat: -19.2912487,
          lng: 45.4780642,
        },
        _id: "64f75285ef8f606073e9c0a8",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "manandaza",
        fokontany: "antsapandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.3257931,
          lng: 45.3181623,
        },
        _id: "64f75285ef8f606073e9c0a9",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "manandaza",
        fokontany: "jea",
        __v: 0,
      },
      {
        coords: {
          lat: -19.367004,
          lng: 45.4215658,
        },
        _id: "64f75285ef8f606073e9c0aa",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "manandaza",
        fokontany: "sakasarotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8005248,
          lng: 45.5281457,
        },
        _id: "64f75285ef8f606073e9c0ab",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankotrofotsy",
        fokontany: "ankotrofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7829527,
          lng: 45.4403911,
        },
        _id: "64f75285ef8f606073e9c0ac",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankotrofotsy",
        fokontany: "beraketa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7259888,
          lng: 45.4969122,
        },
        _id: "64f75285ef8f606073e9c0ad",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankotrofotsy",
        fokontany: "marotongo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8071694,
          lng: 45.4804198,
        },
        _id: "64f75285ef8f606073e9c0ae",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankotrofotsy",
        fokontany: "belolo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.817881,
          lng: 45.48361999999999,
        },
        _id: "64f75285ef8f606073e9c0af",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankotrofotsy",
        fokontany: "ankazondringitra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8084436,
          lng: 45.4027482,
        },
        _id: "64f75285ef8f606073e9c0b0",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankotrofotsy",
        fokontany: "amanda anosimanitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8685302,
          lng: 45.5346307,
        },
        _id: "64f75285ef8f606073e9c0b1",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankotrofotsy",
        fokontany: "ambalakida",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8662582,
          lng: 45.4827755,
        },
        _id: "64f75285ef8f606073e9c0b2",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankotrofotsy",
        fokontany: "antsoha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8762631,
          lng: 45.4403911,
        },
        _id: "64f75285ef8f606073e9c0b3",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankotrofotsy",
        fokontany: "mangarivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9076589,
          lng: 45.4215658,
        },
        _id: "64f75285ef8f606073e9c0b4",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankotrofotsy",
        fokontany: "ambonara",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9517517,
          lng: 45.3839381,
        },
        _id: "64f75285ef8f606073e9c0b5",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankotrofotsy",
        fokontany: "soarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.73868,
          lng: 45.43651999999999,
        },
        _id: "64f75285ef8f606073e9c0b6",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "isalo",
        fokontany: "isalo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.6977647,
          lng: 45.3322493,
        },
        _id: "64f75285ef8f606073e9c0b7",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "isalo",
        fokontany: "beoro",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7061215,
          lng: 45.3886399,
        },
        _id: "64f75285ef8f606073e9c0b8",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "isalo",
        fokontany: "bepeha",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7288908,
          lng: 45.3275531,
        },
        _id: "64f75285ef8f606073e9c0b9",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "isalo",
        fokontany: "adabozato",
        __v: 0,
      },
      {
        coords: {
          lat: -19.723296,
          lng: 45.4733534,
        },
        _id: "64f75285ef8f606073e9c0ba",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "isalo",
        fokontany: "antsikida",
        __v: 0,
      },
      {
        coords: {
          lat: -19.754725,
          lng: 45.454515,
        },
        _id: "64f75285ef8f606073e9c0bb",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "isalo",
        fokontany: "analambiby",
        __v: 0,
      },
      {
        coords: {
          lat: -19.7699544,
          lng: 45.4733534,
        },
        _id: "64f75285ef8f606073e9c0bc",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "isalo",
        fokontany: "soatanana morlot",
        __v: 0,
      },
      {
        coords: {
          lat: -19.8097759,
          lng: 45.3181623,
        },
        _id: "64f75285ef8f606073e9c0bd",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "isalo",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0025504,
          lng: 45.52991420000001,
        },
        _id: "64f75285ef8f606073e9c0be",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ambatolahy",
        fokontany: "ambatolahy I",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9373473,
          lng: 45.5157676,
        },
        _id: "64f75285ef8f606073e9c0bf",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ambatolahy",
        fokontany: "soaserana antevamena",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9441501,
          lng: 45.4780642,
        },
        _id: "64f75285ef8f606073e9c0c0",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ambatolahy",
        fokontany: "ankotrofotsy mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9163603,
          lng: 45.6574246,
        },
        _id: "64f75285ef8f606073e9c0c1",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ambatolahy",
        fokontany: "mahavavy",
        __v: 0,
      },
      {
        coords: {
          lat: -19.9842779,
          lng: 45.5016253,
        },
        _id: "64f75285ef8f606073e9c0c2",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ambatolahy",
        fokontany: "ankazoambo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0069537,
          lng: 45.4403911,
        },
        _id: "64f75285ef8f606073e9c0c3",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ambatolahy",
        fokontany: "tsiandrarafa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0414569,
          lng: 45.6385125,
        },
        _id: "64f75285ef8f606073e9c0c4",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ambatolahy",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0258166,
          lng: 45.5369891,
        },
        _id: "64f75285ef8f606073e9c0c5",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ambatolahy",
        fokontany: "ambatolahy II",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0246339,
          lng: 45.5110531,
        },
        _id: "64f75285ef8f606073e9c0c6",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ambatolahy",
        fokontany: "analamitsivalana",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0832745,
          lng: 45.4834414,
        },
        _id: "64f75285ef8f606073e9c0c7",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "manambina",
        fokontany: "manambina",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1107802,
          lng: 45.5912652,
        },
        _id: "64f75285ef8f606073e9c0c8",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "manambina",
        fokontany: "andraketa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0081113,
          lng: 45.3557371,
        },
        _id: "64f75285ef8f606073e9c0c9",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "manambina",
        fokontany: "besakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0868824,
          lng: 45.5157676,
        },
        _id: "64f75285ef8f606073e9c0ca",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "manambina",
        fokontany: "marolefo",
        __v: 0,
      },
      {
        coords: {
          lat: -20.0950699,
          lng: 45.3651355,
        },
        _id: "64f75285ef8f606073e9c0cb",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "manambina",
        fokontany: "anosibe ambararata",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1031516,
          lng: 45.4639333,
        },
        _id: "64f75285ef8f606073e9c0cc",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "manambina",
        fokontany: "ankaboka",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1443963,
          lng: 45.4027482,
        },
        _id: "64f75285ef8f606073e9c0cd",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "manambina",
        fokontany: "ambalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1489676,
          lng: 45.5346307,
        },
        _id: "64f75285ef8f606073e9c0ce",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "manambina",
        fokontany: "soatanimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -20.1182951,
          lng: 45.4969122,
        },
        _id: "64f75285ef8f606073e9c0cf",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "manambina",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.063061,
          lng: 45.347328,
        },
        _id: "64f75285ef8f606073e9c0d0",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "itondy",
        fokontany: "itondy",
        __v: 0,
      },
      {
        coords: {
          lat: -18.928466,
          lng: 45.3557371,
        },
        _id: "64f75285ef8f606073e9c0d1",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "itondy",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -19.0241544,
          lng: 45.4874873,
        },
        _id: "64f75285ef8f606073e9c0d2",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "itondy",
        fokontany: "ankarabo",
        __v: 0,
      },
      {
        coords: {
          lat: -19.083333,
          lng: 45.15,
        },
        _id: "64f75285ef8f606073e9c0d3",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "itondy",
        fokontany: "marerano",
        __v: 0,
      },
      {
        coords: {
          lat: -19.104749,
          lng: 45.2337297,
        },
        _id: "64f75285ef8f606073e9c0d4",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "itondy",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -19.139259,
          lng: 45.082802,
        },
        _id: "64f75285ef8f606073e9c0d5",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "itondy",
        fokontany: "bebozaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7770899,
          lng: 45.29234719999999,
        },
        _id: "64f75285ef8f606073e9c0d6",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankavandra",
        fokontany: "ankavandra atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6686561,
          lng: 45.3557371,
        },
        _id: "64f75285ef8f606073e9c0d7",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankavandra",
        fokontany: "tsotabato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7263056,
          lng: 45.2853092,
        },
        _id: "64f75285ef8f606073e9c0d8",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankavandra",
        fokontany: "antsakoazato",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7154002,
          lng: 45.2337297,
        },
        _id: "64f75285ef8f606073e9c0d9",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankavandra",
        fokontany: "ambakaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7283067,
          lng: 45.4309775,
        },
        _id: "64f75285ef8f606073e9c0da",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankavandra",
        fokontany: "bemonto",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7694851,
          lng: 45.3980449,
        },
        _id: "64f75285ef8f606073e9c0db",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankavandra",
        fokontany: "befe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7645988,
          lng: 45.2970397,
        },
        _id: "64f75285ef8f606073e9c0dc",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankavandra",
        fokontany: "ankavandra avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7766407,
          lng: 45.2524792,
        },
        _id: "64f75285ef8f606073e9c0dd",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankavandra",
        fokontany: "ambilorano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.805743,
          lng: 45.3181623,
        },
        _id: "64f75285ef8f606073e9c0de",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankavandra",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8265669,
          lng: 45.2337297,
        },
        _id: "64f75285ef8f606073e9c0df",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankavandra",
        fokontany: "antsakoamadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8857348,
          lng: 45.0933497,
        },
        _id: "64f75285ef8f606073e9c0e0",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankavandra",
        fokontany: "bevinoa",
        __v: 0,
      },
      {
        coords: {
          lat: -18.8655544,
          lng: 45.3933422,
        },
        _id: "64f75285ef8f606073e9c0e1",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankavandra",
        fokontany: "marolaka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.9199656,
          lng: 45.2056196,
        },
        _id: "64f75285ef8f606073e9c0e2",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankavandra",
        fokontany: "tsianaloka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5450144,
          lng: 45.2559956,
        },
        _id: "64f75285ef8f606073e9c0e3",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "soaloka",
        fokontany: "soaloka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.253836,
          lng: 45.2056196,
        },
        _id: "64f75285ef8f606073e9c0e4",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "soaloka",
        fokontany: "ambonara nord",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3277342,
          lng: 45.2056196,
        },
        _id: "64f75285ef8f606073e9c0e5",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "soaloka",
        fokontany: "ampasindava",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3905195,
          lng: 45.168166,
        },
        _id: "64f75285ef8f606073e9c0e6",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "soaloka",
        fokontany: "ambalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3987113,
          lng: 45.1026951,
        },
        _id: "64f75285ef8f606073e9c0e7",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "soaloka",
        fokontany: "ampanihikely",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4814908,
          lng: 45.4121561,
        },
        _id: "64f75285ef8f606073e9c0e8",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "soaloka",
        fokontany: "ambararata",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4248403,
          lng: 45.2524792,
        },
        _id: "64f75285ef8f606073e9c0e9",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "soaloka",
        fokontany: "ampanihy est",
        __v: 0,
      },
      {
        coords: {
          lat: -18.44236,
          lng: 45.126209,
        },
        _id: "64f75285ef8f606073e9c0ea",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "soaloka",
        fokontany: "ankiseny",
        __v: 0,
      },
      {
        coords: {
          lat: -18.466667,
          lng: 45.25,
        },
        _id: "64f75285ef8f606073e9c0eb",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "soaloka",
        fokontany: "ambodiriana",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5061608,
          lng: 45.21498769999999,
        },
        _id: "64f75285ef8f606073e9c0ec",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "soaloka",
        fokontany: "andolobe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5271681,
          lng: 45.1307427,
        },
        _id: "64f75285ef8f606073e9c0ed",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "soaloka",
        fokontany: "maroaboaly",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5537016,
          lng: 45.0653249,
        },
        _id: "64f75285ef8f606073e9c0ee",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "soaloka",
        fokontany: "ambalakazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5487683,
          lng: 45.2337297,
        },
        _id: "64f75285ef8f606073e9c0ef",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "soaloka",
        fokontany: "ambalarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.5632104,
          lng: 45.2665464,
        },
        _id: "64f75285ef8f606073e9c0f0",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "soaloka",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6099183,
          lng: 45.2524792,
        },
        _id: "64f75285ef8f606073e9c0f1",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "soaloka",
        fokontany: "bedriatra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6925906,
          lng: 45.1523743,
        },
        _id: "64f75285ef8f606073e9c0f2",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "betsipolitra",
        fokontany: "betsipolitra",
        __v: 0,
      },
      {
        coords: {
          lat: -18.601905,
          lng: 45.1026951,
        },
        _id: "64f75285ef8f606073e9c0f3",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "betsipolitra",
        fokontany: "morafeno bokarano",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6236228,
          lng: 45.2056196,
        },
        _id: "64f75285ef8f606073e9c0f4",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "betsipolitra",
        fokontany: "ambalamanga",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6460685,
          lng: 45.0653249,
        },
        _id: "64f75285ef8f606073e9c0f5",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "betsipolitra",
        fokontany: "ambatata",
        __v: 0,
      },
      {
        coords: {
          lat: -18.6626149,
          lng: 45.1354189,
        },
        _id: "64f75285ef8f606073e9c0f6",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "betsipolitra",
        fokontany: "ampoza atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7392159,
          lng: 45.0373171,
        },
        _id: "64f75285ef8f606073e9c0f7",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "betsipolitra",
        fokontany: "berano ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -18.7910118,
          lng: 45.1775265,
        },
        _id: "64f75285ef8f606073e9c0f8",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "betsipolitra",
        fokontany: "antsandoka",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4001059,
          lng: 45.0571542,
        },
        _id: "64f75285ef8f606073e9c0f9",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankondromena",
        fokontany: "ankondromena",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3071579,
          lng: 45.0064117,
        },
        _id: "64f75285ef8f606073e9c0fa",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankondromena",
        fokontany: "folakara nord",
        __v: 0,
      },
      {
        coords: {
          lat: -18.333333,
          lng: 44.9,
        },
        _id: "64f75285ef8f606073e9c0fb",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankondromena",
        fokontany: "antanimbaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3373969,
          lng: 45.0344006,
        },
        _id: "64f75285ef8f606073e9c0fc",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankondromena",
        fokontany: "folakara sud",
        __v: 0,
      },
      {
        coords: {
          lat: -18.4,
          lng: 44.98333299999999,
        },
        _id: "64f75285ef8f606073e9c0fd",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankondromena",
        fokontany: "antsalovabe",
        __v: 0,
      },
      {
        coords: {
          lat: -18.3635299,
          lng: 45.0466511,
        },
        _id: "64f75285ef8f606073e9c0fe",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankondromena",
        fokontany: "mahavelo",
        __v: 0,
      },
      {
        coords: {
          lat: -18.517579,
          lng: 44.9626829,
        },
        _id: "64f75285ef8f606073e9c0ff",
        province: "toliary",
        region: "menabe",
        district: "miandrivazo",
        commune: "ankondromena",
        fokontany: "ampanihy ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -12.7351139,
          lng: 48.9656911,
        },
        _id: "64f75285ef8f606073e9c100",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "ramena",
        fokontany: "ramena",
        __v: 0,
      },
      {
        coords: {
          lat: -12.282362,
          lng: 49.3754694,
        },
        _id: "64f75285ef8f606073e9c101",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "ramena",
        fokontany: "ankorikahely",
        __v: 0,
      },
      {
        coords: {
          lat: -12.320802,
          lng: 49.3938789,
        },
        _id: "64f75285ef8f606073e9c102",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "ramena",
        fokontany: "ivovona",
        __v: 0,
      },
      {
        coords: {
          lat: -12.3524922,
          lng: 49.3551622,
        },
        _id: "64f75285ef8f606073e9c103",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "ramena",
        fokontany: "andavakoera",
        __v: 0,
      },
      {
        coords: {
          lat: -12.3230743,
          lng: 49.3196417,
        },
        _id: "64f75285ef8f606073e9c104",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "ramena",
        fokontany: "betahitra",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4430151,
          lng: 49.268936,
        },
        _id: "64f75285ef8f606073e9c105",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "sakaramy",
        fokontany: "sakaramy",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4337671,
          lng: 49.2942833,
        },
        _id: "64f75285ef8f606073e9c106",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "sakaramy",
        fokontany: "ankazomibaboka",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4743709,
          lng: 49.2892129,
        },
        _id: "64f75285ef8f606073e9c107",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "sakaramy",
        fokontany: "sahasifotra",
        __v: 0,
      },
      {
        coords: {
          lat: -12.354476,
          lng: 49.2892129,
        },
        _id: "64f75285ef8f606073e9c108",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "antanamitarana",
        fokontany: "antafiamalama",
        __v: 0,
      },
      {
        coords: {
          lat: -12.3231349,
          lng: 49.2942833,
        },
        _id: "64f75285ef8f606073e9c109",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "antanamitarana",
        fokontany: "antanamitarana",
        __v: 0,
      },
      {
        coords: {
          lat: -12.3231349,
          lng: 49.2942833,
        },
        _id: "64f75285ef8f606073e9c10a",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "joffre ville",
        fokontany: "joffre ville",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5112809,
          lng: 49.1878994,
        },
        _id: "64f75285ef8f606073e9c10b",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "joffre ville",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -12.494686,
          lng: 49.2486661,
        },
        _id: "64f75285ef8f606073e9c10c",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "joffre ville",
        fokontany: "ambibaka",
        __v: 0,
      },
      {
        coords: {
          lat: -12.3859541,
          lng: 49.1828385,
        },
        _id: "64f75285ef8f606073e9c10d",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "antsahampano",
        fokontany: "antsahampano",
        __v: 0,
      },
      {
        coords: {
          lat: -12.306411,
          lng: 49.1436309,
        },
        _id: "64f75285ef8f606073e9c10e",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "antsahampano",
        fokontany: "ambararatahely",
        __v: 0,
      },
      {
        coords: {
          lat: -12.3132975,
          lng: 49.1499529,
        },
        _id: "64f75285ef8f606073e9c10f",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "antsahampano",
        fokontany: "anketrabe",
        __v: 0,
      },
      {
        coords: {
          lat: -12.3933302,
          lng: 49.1727178,
        },
        _id: "64f75285ef8f606073e9c110",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "antsahampano",
        fokontany: "andranotsimaty",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4043257,
          lng: 49.2334685,
        },
        _id: "64f75285ef8f606073e9c111",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "antsahampano",
        fokontany: "antongombato",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4595108,
          lng: 49.3856256,
        },
        _id: "64f75285ef8f606073e9c112",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahavanona",
        fokontany: "mahavanona",
        __v: 0,
      },
      {
        coords: {
          lat: -12.3780493,
          lng: 49.4720255,
        },
        _id: "64f75285ef8f606073e9c113",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahavanona",
        fokontany: "ambodivahibe",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4004915,
          lng: 49.3399364,
        },
        _id: "64f75285ef8f606073e9c114",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahavanona",
        fokontany: "andranomanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4128,
          lng: 49.477299,
        },
        _id: "64f75285ef8f606073e9c115",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahavanona",
        fokontany: "ampondrahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4226082,
          lng: 49.3602383,
        },
        _id: "64f75285ef8f606073e9c116",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahavanona",
        fokontany: "mahagaga",
        __v: 0,
      },
      {
        coords: {
          lat: -12.436473,
          lng: 49.3475488,
        },
        _id: "64f75285ef8f606073e9c117",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahavanona",
        fokontany: "nosimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4391671,
          lng: 49.4008633,
        },
        _id: "64f75285ef8f606073e9c118",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahavanona",
        fokontany: "antanivelatra",
        __v: 0,
      },
      {
        coords: {
          lat: -12.3966968,
          lng: 49.3957836,
        },
        _id: "64f75285ef8f606073e9c119",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahavanona",
        fokontany: "ampandriankilandy",
        __v: 0,
      },
      {
        coords: {
          lat: -12.450306,
          lng: 49.3602383,
        },
        _id: "64f75285ef8f606073e9c11a",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahavanona",
        fokontany: "ankazomenafelana",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4594776,
          lng: 49.411024,
        },
        _id: "64f75285ef8f606073e9c11b",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahavanona",
        fokontany: "ambilo",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4558688,
          lng: 49.3399364,
        },
        _id: "64f75285ef8f606073e9c11c",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahavanona",
        fokontany: "antetezana",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4724362,
          lng: 49.393244,
        },
        _id: "64f75285ef8f606073e9c11d",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahavanona",
        fokontany: "besokatra",
        __v: 0,
      },
      {
        coords: {
          lat: -12.476109,
          lng: 49.4135645,
        },
        _id: "64f75285ef8f606073e9c11e",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahavanona",
        fokontany: "ambodimadiro",
        __v: 0,
      },
      {
        coords: {
          lat: -12.481731,
          lng: 49.3297882,
        },
        _id: "64f75285ef8f606073e9c11f",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahavanona",
        fokontany: "antserasera",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4853655,
          lng: 49.4008633,
        },
        _id: "64f75285ef8f606073e9c120",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahavanona",
        fokontany: "daraina",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4872833,
          lng: 49.309497,
        },
        _id: "64f75285ef8f606073e9c121",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahavanona",
        fokontany: "antanimbaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5149857,
          lng: 49.309497,
        },
        _id: "64f75285ef8f606073e9c122",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahavanona",
        fokontany: "ankerana",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5131254,
          lng: 49.3500865,
        },
        _id: "64f75285ef8f606073e9c123",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahavanona",
        fokontany: "ampifinala",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5168355,
          lng: 49.268936,
        },
        _id: "64f75285ef8f606073e9c124",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahavanona",
        fokontany: "antsatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5481975,
          lng: 49.2132111,
        },
        _id: "64f75285ef8f606073e9c125",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahavanona",
        fokontany: "anjavimihavana",
        __v: 0,
      },
      {
        coords: {
          lat: -12.3109559,
          lng: 49.1208304,
        },
        _id: "64f75285ef8f606073e9c126",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mangaoka",
        fokontany: "mangaoka",
        __v: 0,
      },
      {
        coords: {
          lat: -12.2702049,
          lng: 49.0892906,
        },
        _id: "64f75285ef8f606073e9c127",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mangaoka",
        fokontany: "ampasindava",
        __v: 0,
      },
      {
        coords: {
          lat: -12.2922414,
          lng: 49.09686989999999,
        },
        _id: "64f75285ef8f606073e9c128",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mangaoka",
        fokontany: "antanamandriry",
        __v: 0,
      },
      {
        coords: {
          lat: -12.3345679,
          lng: 49.0514094,
        },
        _id: "64f75285ef8f606073e9c129",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mangaoka",
        fokontany: "mananara",
        __v: 0,
      },
      {
        coords: {
          lat: -12.3713411,
          lng: 49.0261692,
        },
        _id: "64f75285ef8f606073e9c12a",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mangaoka",
        fokontany: "matsaborimaiky",
        __v: 0,
      },
      {
        coords: {
          lat: -12.3786651,
          lng: 49.0918169,
        },
        _id: "64f75285ef8f606073e9c12b",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mangaoka",
        fokontany: "antanamisondrotra",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4117871,
          lng: 49.0716096,
        },
        _id: "64f75285ef8f606073e9c12c",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mangaoka",
        fokontany: "ambohibory",
        __v: 0,
      },
      {
        coords: {
          lat: -12.44228,
          lng: 49.11185099999999,
        },
        _id: "64f75285ef8f606073e9c12d",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mangaoka",
        fokontany: "ankorefo",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5111875,
          lng: 49.4669396,
        },
        _id: "64f75285ef8f606073e9c12e",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andrafiabe",
        fokontany: "andrafiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4389813,
          lng: 49.50255019999999,
        },
        _id: "64f75285ef8f606073e9c12f",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andrafiabe",
        fokontany: "ambolobozokely",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4667732,
          lng: 49.4771118,
        },
        _id: "64f75285ef8f606073e9c130",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andrafiabe",
        fokontany: "sahankazo ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4908791,
          lng: 49.4313508,
        },
        _id: "64f75285ef8f606073e9c131",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andrafiabe",
        fokontany: "ankitsakalaninaomby",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5000979,
          lng: 49.4567691,
        },
        _id: "64f75285ef8f606073e9c132",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andrafiabe",
        fokontany: "mandrosomiadana",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5186556,
          lng: 49.3805473,
        },
        _id: "64f75285ef8f606073e9c133",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andrafiabe",
        fokontany: "sahankazo ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4962394,
          lng: 49.5636473,
        },
        _id: "64f75285ef8f606073e9c134",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andrafiabe",
        fokontany: "ambolobozobe",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5259614,
          lng: 49.4974616,
        },
        _id: "64f75285ef8f606073e9c135",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andrafiabe",
        fokontany: "mosoro",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5616457,
          lng: 49.4072136,
        },
        _id: "64f75285ef8f606073e9c136",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andrafiabe",
        fokontany: "berafia",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5556305,
          lng: 49.3044253,
        },
        _id: "64f75285ef8f606073e9c137",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anketrakabe",
        fokontany: "anketrakabe",
        __v: 0,
      },
      {
        coords: {
          lat: -12.542697,
          lng: 49.309497,
        },
        _id: "64f75285ef8f606073e9c138",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anketrakabe",
        fokontany: "antanimenamihenjana",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5389989,
          lng: 49.2892129,
        },
        _id: "64f75285ef8f606073e9c139",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anketrakabe",
        fokontany: "andrafiamivory",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5547142,
          lng: 49.3627765,
        },
        _id: "64f75285ef8f606073e9c13a",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anketrakabe",
        fokontany: "andasaria",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5593245,
          lng: 49.2993541,
        },
        _id: "64f75285ef8f606073e9c13b",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anketrakabe",
        fokontany: "andranotsisiamalona",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5611522,
          lng: 49.2588002,
        },
        _id: "64f75285ef8f606073e9c13c",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anketrakabe",
        fokontany: "ambahivahikely",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5814984,
          lng: 49.2942833,
        },
        _id: "64f75285ef8f606073e9c13d",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anketrakabe",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5722698,
          lng: 49.3196417,
        },
        _id: "64f75285ef8f606073e9c13e",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anketrakabe",
        fokontany: "saharenana ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -12.583333,
          lng: 49.266667,
        },
        _id: "64f75285ef8f606073e9c13f",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anketrakabe",
        fokontany: "ambahivahibe",
        __v: 0,
      },
      {
        coords: {
          lat: -12.6314766,
          lng: 49.3526243,
        },
        _id: "64f75285ef8f606073e9c140",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "sadjoavato",
        fokontany: "sadjoavato",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5833845,
          lng: 49.3805473,
        },
        _id: "64f75285ef8f606073e9c141",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "sadjoavato",
        fokontany: "saharenana bas",
        __v: 0,
      },
      {
        coords: {
          lat: -12.6674866,
          lng: 49.29681859999999,
        },
        _id: "64f75285ef8f606073e9c142",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "sadjoavato",
        fokontany: "antsakoabe",
        __v: 0,
      },
      {
        coords: {
          lat: -12.6814273,
          lng: 49.3348621,
        },
        _id: "64f75285ef8f606073e9c143",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "sadjoavato",
        fokontany: "antserasera",
        __v: 0,
      },
      {
        coords: {
          lat: -12.63993,
          lng: 49.25322,
        },
        _id: "64f75285ef8f606073e9c144",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "antsalaka",
        fokontany: "antsalaka",
        __v: 0,
      },
      {
        coords: {
          lat: -12.6147198,
          lng: 49.2486661,
        },
        _id: "64f75285ef8f606073e9c145",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "antsalaka",
        fokontany: "antsalaka morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -12.6221478,
          lng: 49.276539,
        },
        _id: "64f75285ef8f606073e9c146",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "antsalaka",
        fokontany: "andaloesy",
        __v: 0,
      },
      {
        coords: {
          lat: -12.6285983,
          lng: 49.2613339,
        },
        _id: "64f75285ef8f606073e9c147",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "antsalaka",
        fokontany: "antsalaka nord",
        __v: 0,
      },
      {
        coords: {
          lat: -12.636804,
          lng: 49.1929609,
        },
        _id: "64f75285ef8f606073e9c148",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "antsalaka",
        fokontany: "antsakoakely",
        __v: 0,
      },
      {
        coords: {
          lat: -12.0550206,
          lng: 49.2325391,
        },
        _id: "64f75285ef8f606073e9c149",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andranovondronina",
        fokontany: "andranovondronina",
        __v: 0,
      },
      {
        coords: {
          lat: -11.9885611,
          lng: 49.2740045,
        },
        _id: "64f75285ef8f606073e9c14a",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andranovondronina",
        fokontany: "izengitry",
        __v: 0,
      },
      {
        coords: {
          lat: -12.0630949,
          lng: 49.2819251,
        },
        _id: "64f75285ef8f606073e9c14b",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andranovondronina",
        fokontany: "bedarabe",
        __v: 0,
      },
      {
        coords: {
          lat: -12.0957064,
          lng: 49.127197,
        },
        _id: "64f75285ef8f606073e9c14c",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andranovondronina",
        fokontany: "vohilava",
        __v: 0,
      },
      {
        coords: {
          lat: -12.1132833,
          lng: 49.3044253,
        },
        _id: "64f75285ef8f606073e9c14d",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andranovondronina",
        fokontany: "anjiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -12.1430852,
          lng: 49.2132111,
        },
        _id: "64f75285ef8f606073e9c14e",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andranovondronina",
        fokontany: "antsisikala",
        __v: 0,
      },
      {
        coords: {
          lat: -12.2387489,
          lng: 49.1828385,
        },
        _id: "64f75285ef8f606073e9c14f",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andranovondronina",
        fokontany: "andohazompona",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5037738,
          lng: 49.0211225,
        },
        _id: "64f75285ef8f606073e9c150",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andranofanjava",
        fokontany: "andranofanjava",
        __v: 0,
      },
      {
        coords: {
          lat: -12.3731878,
          lng: 48.9858082,
        },
        _id: "64f75285ef8f606073e9c151",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andranofanjava",
        fokontany: "ironona",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4081091,
          lng: 49.0261692,
        },
        _id: "64f75285ef8f606073e9c152",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andranofanjava",
        fokontany: "ankiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4172871,
          lng: 48.9757225,
        },
        _id: "64f75285ef8f606073e9c153",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andranofanjava",
        fokontany: "antsahafabe",
        __v: 0,
      },
      {
        coords: {
          lat: -12.45,
          lng: 49.016667,
        },
        _id: "64f75285ef8f606073e9c154",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andranofanjava",
        fokontany: "manondro",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5406731,
          lng: 49.0716096,
        },
        _id: "64f75285ef8f606073e9c155",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "andranofanjava",
        fokontany: "bemanevikabe",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5108561,
          lng: 48.8598651,
        },
        _id: "64f75285ef8f606073e9c156",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahalina",
        fokontany: "mahalina",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4630794,
          lng: 48.84980179999999,
        },
        _id: "64f75285ef8f606073e9c157",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahalina",
        fokontany: "ampondrabe",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4336153,
          lng: 48.7894596,
        },
        _id: "64f75285ef8f606073e9c158",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahalina",
        fokontany: "bobatsiratra",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5183891,
          lng: 48.9505159,
        },
        _id: "64f75285ef8f606073e9c159",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahalina",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -12.4887173,
          lng: 48.8145943,
        },
        _id: "64f75285ef8f606073e9c15a",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahalina",
        fokontany: "androtso",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5477371,
          lng: 48.9102087,
        },
        _id: "64f75285ef8f606073e9c15b",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mahalina",
        fokontany: "ambodimadiro",
        __v: 0,
      },
      {
        coords: {
          lat: -12.6816051,
          lng: 49.4872858,
        },
        _id: "64f75285ef8f606073e9c15c",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "ankarongana",
        fokontany: "ankarongana",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5666868,
          lng: 49.5178185,
        },
        _id: "64f75285ef8f606073e9c15d",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "ankarongana",
        fokontany: "antanambao ankerika",
        __v: 0,
      },
      {
        coords: {
          lat: -12.6037474,
          lng: 49.5178185,
        },
        _id: "64f75285ef8f606073e9c15e",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "ankarongana",
        fokontany: "ankorera",
        __v: 0,
      },
      {
        coords: {
          lat: -12.6440699,
          lng: 49.53263099999999,
        },
        _id: "64f75285ef8f606073e9c15f",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "ankarongana",
        fokontany: "irodo",
        __v: 0,
      },
      {
        coords: {
          lat: -12.6592815,
          lng: 49.3907044,
        },
        _id: "64f75285ef8f606073e9c160",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "ankarongana",
        fokontany: "madirobe",
        __v: 0,
      },
      {
        coords: {
          lat: -12.7409942,
          lng: 49.5076392,
        },
        _id: "64f75285ef8f606073e9c161",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "ankarongana",
        fokontany: "menagisy",
        __v: 0,
      },
      {
        coords: {
          lat: -12.7432142,
          lng: 49.23726749999999,
        },
        _id: "64f75285ef8f606073e9c162",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anivorano nord",
        fokontany: "anivorano I",
        __v: 0,
      },
      {
        coords: {
          lat: -12.6905399,
          lng: 49.2588002,
        },
        _id: "64f75285ef8f606073e9c163",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anivorano nord",
        fokontany: "beanamalao",
        __v: 0,
      },
      {
        coords: {
          lat: -12.6737564,
          lng: 49.1929609,
        },
        _id: "64f75285ef8f606073e9c164",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anivorano nord",
        fokontany: "marovato scama",
        __v: 0,
      },
      {
        coords: {
          lat: -12.729214,
          lng: 49.1929609,
        },
        _id: "64f75285ef8f606073e9c165",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anivorano nord",
        fokontany: "anivorano II",
        __v: 0,
      },
      {
        coords: {
          lat: -12.7405505,
          lng: 49.2790736,
        },
        _id: "64f75285ef8f606073e9c166",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anivorano nord",
        fokontany: "ampandrana",
        __v: 0,
      },
      {
        coords: {
          lat: -12.7570356,
          lng: 49.2182748,
        },
        _id: "64f75285ef8f606073e9c167",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anivorano nord",
        fokontany: "anivorano III",
        __v: 0,
      },
      {
        coords: {
          lat: -12.7645611,
          lng: 49.2588002,
        },
        _id: "64f75285ef8f606073e9c168",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anivorano nord",
        fokontany: "ambalafary scama",
        __v: 0,
      },
      {
        coords: {
          lat: -12.7720852,
          lng: 49.2993541,
        },
        _id: "64f75285ef8f606073e9c169",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anivorano nord",
        fokontany: "amparihy",
        __v: 0,
      },
      {
        coords: {
          lat: -12.7883888,
          lng: 49.1878994,
        },
        _id: "64f75285ef8f606073e9c16a",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anivorano nord",
        fokontany: "ampasimbengy",
        __v: 0,
      },
      {
        coords: {
          lat: -12.8366536,
          lng: 49.22333889999999,
        },
        _id: "64f75285ef8f606073e9c16b",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anivorano nord",
        fokontany: "marotaolana",
        __v: 0,
      },
      {
        coords: {
          lat: -12.8298051,
          lng: 49.3856256,
        },
        _id: "64f75285ef8f606073e9c16c",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anivorano nord",
        fokontany: "ambery",
        __v: 0,
      },
      {
        coords: {
          lat: -12.8511637,
          lng: 49.1524819,
        },
        _id: "64f75285ef8f606073e9c16d",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anivorano nord",
        fokontany: "marovato ivanga",
        __v: 0,
      },
      {
        coords: {
          lat: -12.8977562,
          lng: 49.2157429,
        },
        _id: "64f75285ef8f606073e9c16e",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anivorano nord",
        fokontany: "ambondromifehy",
        __v: 0,
      },
      {
        coords: {
          lat: -12.9552082,
          lng: 49.2132111,
        },
        _id: "64f75285ef8f606073e9c16f",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anivorano nord",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -12.8964649,
          lng: 49.34501119999999,
        },
        _id: "64f75285ef8f606073e9c170",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anivorano nord",
        fokontany: "andrafiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -12.97713,
          lng: 49.367859,
        },
        _id: "64f75285ef8f606073e9c171",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "anivorano nord",
        fokontany: "andrevokely",
        __v: 0,
      },
      {
        coords: {
          lat: -12.7185535,
          lng: 49.3856256,
        },
        _id: "64f75285ef8f606073e9c172",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "antsoha",
        fokontany: "antsoha",
        __v: 0,
      },
      {
        coords: {
          lat: -12.7222032,
          lng: 49.3551622,
        },
        _id: "64f75285ef8f606073e9c173",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "antsoha",
        fokontany: "mahavanona",
        __v: 0,
      },
      {
        coords: {
          lat: -12.729691,
          lng: 49.3957836,
        },
        _id: "64f75285ef8f606073e9c174",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "antsoha",
        fokontany: "ambohibory",
        __v: 0,
      },
      {
        coords: {
          lat: -12.7109129,
          lng: 49.268936,
        },
        _id: "64f75285ef8f606073e9c175",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "antsoha",
        fokontany: "morafeno scama",
        __v: 0,
      },
      {
        coords: {
          lat: -12.7201951,
          lng: 49.2816082,
        },
        _id: "64f75285ef8f606073e9c176",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "antsoha",
        fokontany: "mahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -12.8081738,
          lng: 48.9574466,
        },
        _id: "64f75285ef8f606073e9c177",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "bobasakoa",
        fokontany: "bobasakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -12.841042,
          lng: 48.9757225,
        },
        _id: "64f75285ef8f606073e9c178",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "bobasakoa",
        fokontany: "ambolipamba",
        __v: 0,
      },
      {
        coords: {
          lat: -12.866667,
          lng: 49,
        },
        _id: "64f75285ef8f606073e9c179",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "bobasakoa",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -12.833333,
          lng: 49.05,
        },
        _id: "64f75285ef8f606073e9c17a",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "ambondrona",
        fokontany: "ambondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -12.7435954,
          lng: 49.0716096,
        },
        _id: "64f75285ef8f606073e9c17b",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "ambondrona",
        fokontany: "andonakaomby",
        __v: 0,
      },
      {
        coords: {
          lat: -12.7880989,
          lng: 49.1120314,
        },
        _id: "64f75285ef8f606073e9c17c",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "ambondrona",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -12.7990192,
          lng: 49.0716096,
        },
        _id: "64f75285ef8f606073e9c17d",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "ambondrona",
        fokontany: "analamazava",
        __v: 0,
      },
      {
        coords: {
          lat: -12.8209325,
          lng: 49.01607629999999,
        },
        _id: "64f75285ef8f606073e9c17e",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "ambondrona",
        fokontany: "anketraka bemongo",
        __v: 0,
      },
      {
        coords: {
          lat: -12.8342106,
          lng: 49.0867644,
        },
        _id: "64f75285ef8f606073e9c17f",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "ambondrona",
        fokontany: "andranotolihina",
        __v: 0,
      },
      {
        coords: {
          lat: -12.6142888,
          lng: 49.0211225,
        },
        _id: "64f75285ef8f606073e9c180",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "bobakilandy",
        fokontany: "bobakilandy",
        __v: 0,
      },
      {
        coords: {
          lat: -12.5847822,
          lng: 49.0110305,
        },
        _id: "64f75285ef8f606073e9c181",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "bobakilandy",
        fokontany: "ampanangana",
        __v: 0,
      },
      {
        coords: {
          lat: -12.621875,
          lng: 49.1120314,
        },
        _id: "64f75285ef8f606073e9c182",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "bobakilandy",
        fokontany: "antsanavo",
        __v: 0,
      },
      {
        coords: {
          lat: -12.6250864,
          lng: 48.9303587,
        },
        _id: "64f75285ef8f606073e9c183",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "bobakilandy",
        fokontany: "manirenja",
        __v: 0,
      },
      {
        coords: {
          lat: -12.6512997,
          lng: 49.0716096,
        },
        _id: "64f75285ef8f606073e9c184",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "bobakilandy",
        fokontany: "ambatoafo",
        __v: 0,
      },
      {
        coords: {
          lat: -12.7098718,
          lng: 48.9404364,
        },
        _id: "64f75285ef8f606073e9c185",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mosorolava",
        fokontany: "mosorolava",
        __v: 0,
      },
      {
        coords: {
          lat: -12.7175997,
          lng: 49.0312164,
        },
        _id: "64f75285ef8f606073e9c186",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mosorolava",
        fokontany: "ankiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -12.7,
          lng: 48.95,
        },
        _id: "64f75285ef8f606073e9c187",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mosorolava",
        fokontany: "ampombiantambo",
        __v: 0,
      },
      {
        coords: {
          lat: -12.6990584,
          lng: 49.0059852,
        },
        _id: "64f75285ef8f606073e9c188",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mosorolava",
        fokontany: "andrafiamadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -12.7321855,
          lng: 48.9858082,
        },
        _id: "64f75285ef8f606073e9c189",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mosorolava",
        fokontany: "morarano",
        __v: 0,
      },
      {
        coords: {
          lat: -12.7617516,
          lng: 48.99589580000001,
        },
        _id: "64f75285ef8f606073e9c18a",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana II",
        commune: "mosorolava",
        fokontany: "mahagaga",
        __v: 0,
      },
      {
        coords: {
          lat: -12.2817025,
          lng: 49.28794540000001,
        },
        _id: "64f75285ef8f606073e9c18b",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "bazarikely",
        __v: 0,
      },
      {
        coords: {
          lat: -12.3231349,
          lng: 49.2942833,
        },
        _id: "64f75285ef8f606073e9c18c",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "cap diego",
        __v: 0,
      },
      {
        coords: {
          lat: -12.2697217,
          lng: 49.291748,
        },
        _id: "64f75285ef8f606073e9c18d",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "place kabary",
        __v: 0,
      },
      {
        coords: {
          lat: -12.2807425,
          lng: 49.3018896,
        },
        _id: "64f75285ef8f606073e9c18e",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "lazaret nord",
        __v: 0,
      },
      {
        coords: {
          lat: -12.2789205,
          lng: 49.2949171,
        },
        _id: "64f75285ef8f606073e9c18f",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "avenir",
        __v: 0,
      },
      {
        coords: {
          lat: -12.2858446,
          lng: 49.2885792,
        },
        _id: "64f75285ef8f606073e9c190",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "tanambao III",
        __v: 0,
      },
      {
        coords: {
          lat: -12.2865206,
          lng: 49.2939664,
        },
        _id: "64f75285ef8f606073e9c191",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "tanambao avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -12.2879071,
          lng: 49.2920649,
        },
        _id: "64f75285ef8f606073e9c192",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "tanambao tsena",
        __v: 0,
      },
      {
        coords: {
          lat: -12.2844925,
          lng: 49.27780629999999,
        },
        _id: "64f75285ef8f606073e9c193",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "mangarivotra",
        __v: 0,
      },
      {
        coords: {
          lat: -12.2899526,
          lng: 49.3018896,
        },
        _id: "64f75285ef8f606073e9c194",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "lazaret sud",
        __v: 0,
      },
      {
        coords: {
          lat: -12.2881553,
          lng: 49.2854104,
        },
        _id: "64f75285ef8f606073e9c195",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "tanambao V",
        __v: 0,
      },
      {
        coords: {
          lat: -12.2890668,
          lng: 49.28889600000001,
        },
        _id: "64f75285ef8f606073e9c196",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "tanambao IV",
        __v: 0,
      },
      {
        coords: {
          lat: -12.2902052,
          lng: 49.2936494,
        },
        _id: "64f75285ef8f606073e9c197",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "tanambao sud",
        __v: 0,
      },
      {
        coords: {
          lat: -12.2925245,
          lng: 49.2873116,
        },
        _id: "64f75285ef8f606073e9c198",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "grand pavois",
        __v: 0,
      },
      {
        coords: {
          lat: -12.2959698,
          lng: 49.2904805,
        },
        _id: "64f75285ef8f606073e9c199",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "soafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -12.2937004,
          lng: 49.27780629999999,
        },
        _id: "64f75285ef8f606073e9c19a",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -12.2977853,
          lng: 49.30062179999999,
        },
        _id: "64f75285ef8f606073e9c19b",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "cite ouvriere",
        __v: 0,
      },
      {
        coords: {
          lat: -12.2987588,
          lng: 49.28034090000001,
        },
        _id: "64f75285ef8f606073e9c19c",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "tsaramandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -12.301937,
          lng: 49.29808629999999,
        },
        _id: "64f75285ef8f606073e9c19d",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -12.318558,
          lng: 49.2816082,
        },
        _id: "64f75285ef8f606073e9c19e",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "ambalavola",
        __v: 0,
      },
      {
        coords: {
          lat: -12.3051803,
          lng: 49.2904805,
        },
        _id: "64f75285ef8f606073e9c19f",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "ambohimitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -12.306997,
          lng: 49.30062179999999,
        },
        _id: "64f75285ef8f606073e9c1a0",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "ambalakazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -12.3212863,
          lng: 49.29681859999999,
        },
        _id: "64f75285ef8f606073e9c1a1",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "scama",
        __v: 0,
      },
      {
        coords: {
          lat: -12.3342763,
          lng: 49.2537329,
        },
        _id: "64f75285ef8f606073e9c1a2",
        province: "antsiranana",
        region: "diana",
        district: "antsiranana I",
        commune: "diego suarez",
        fokontany: "anamakia",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2008891,
          lng: 49.0665589,
        },
        _id: "64f75285ef8f606073e9c1a3",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambilobe",
        fokontany: "matiakoho",
        __v: 0,
      },
      {
        coords: {
          lat: -13.142849,
          lng: 49.0059852,
        },
        _id: "64f75285ef8f606073e9c1a4",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambilobe",
        fokontany: "mantalimaro andrefana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1745489,
          lng: 49.0261692,
        },
        _id: "64f75285ef8f606073e9c1a5",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambilobe",
        fokontany: "matsaborikilandy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1560036,
          lng: 49.0261692,
        },
        _id: "64f75285ef8f606073e9c1a6",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambilobe",
        fokontany: "ambalamahatsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1693846,
          lng: 49.0716096,
        },
        _id: "64f75285ef8f606073e9c1a7",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambilobe",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1907487,
          lng: 49.0741351,
        },
        _id: "64f75285ef8f606073e9c1a8",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambilobe",
        fokontany: "anoronala",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1934139,
          lng: 49.06087729999999,
        },
        _id: "64f75285ef8f606073e9c1a9",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambilobe",
        fokontany: "antanamariazy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1914384,
          lng: 49.0476227,
        },
        _id: "64f75285ef8f606073e9c1aa",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambilobe",
        fokontany: "antafiankasaka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2004245,
          lng: 49.01607629999999,
        },
        _id: "64f75285ef8f606073e9c1ab",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambilobe",
        fokontany: "sengaloka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.206272,
          lng: 49.0463605,
        },
        _id: "64f75285ef8f606073e9c1ac",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambilobe",
        fokontany: "matsaborilaidama",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2465603,
          lng: 48.99085179999999,
        },
        _id: "64f75285ef8f606073e9c1ad",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambilobe",
        fokontany: "antsoha",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1677433,
          lng: 48.9647117,
        },
        _id: "64f75285ef8f606073e9c1ae",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "mantaly",
        fokontany: "mantaly",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1204357,
          lng: 48.9858082,
        },
        _id: "64f75285ef8f606073e9c1af",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "mantaly",
        fokontany: "anjavibe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.14972,
          lng: 48.9454759,
        },
        _id: "64f75285ef8f606073e9c1b0",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "mantaly",
        fokontany: "manongarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1710498,
          lng: 48.9479959,
        },
        _id: "64f75285ef8f606073e9c1b1",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "mantaly",
        fokontany: "ampanakana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1732315,
          lng: 48.9832866,
        },
        _id: "64f75285ef8f606073e9c1b2",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "mantaly",
        fokontany: "tanambao mantaly",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1960439,
          lng: 48.9454759,
        },
        _id: "64f75285ef8f606073e9c1b3",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "mantaly",
        fokontany: "andrafiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1832978,
          lng: 48.9694198,
        },
        _id: "64f75285ef8f606073e9c1b4",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "mantaly",
        fokontany: "antanamazava cie",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2184872,
          lng: 48.9656385,
        },
        _id: "64f75285ef8f606073e9c1b5",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "mantaly",
        fokontany: "antanamazava gvt",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1994539,
          lng: 48.9152456,
        },
        _id: "64f75285ef8f606073e9c1b6",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "mantaly",
        fokontany: "ampasimaty",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2126262,
          lng: 48.9353974,
        },
        _id: "64f75285ef8f606073e9c1b7",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "mantaly",
        fokontany: "ambodipo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2331197,
          lng: 48.9454759,
        },
        _id: "64f75285ef8f606073e9c1b8",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "mantaly",
        fokontany: "antanimiavotra",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2438274,
          lng: 48.9051723,
        },
        _id: "64f75285ef8f606073e9c1b9",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "mantaly",
        fokontany: "bobatanty",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1862872,
          lng: 48.8951009,
        },
        _id: "64f75285ef8f606073e9c1ba",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ampondralava",
        fokontany: "ampondralava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1576553,
          lng: 48.90265429999999,
        },
        _id: "64f75285ef8f606073e9c1bb",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ampondralava",
        fokontany: "andimaka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1565614,
          lng: 48.88503129999999,
        },
        _id: "64f75285ef8f606073e9c1bc",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ampondralava",
        fokontany: "androhitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1727599,
          lng: 48.932878,
        },
        _id: "64f75285ef8f606073e9c1bd",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ampondralava",
        fokontany: "antanamanjary",
        __v: 0,
      },
      {
        coords: {
          lat: -13.177027,
          lng: 48.8951009,
        },
        _id: "64f75285ef8f606073e9c1be",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ampondralava",
        fokontany: "bekolahy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2028564,
          lng: 48.88503129999999,
        },
        _id: "64f75285ef8f606073e9c1bf",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ampondralava",
        fokontany: "antanantanana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.0866443,
          lng: 49.10571359999999,
        },
        _id: "64f75285ef8f606073e9c1c0",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "tanambao marivorahona",
        fokontany: "tanambao marivorahona",
        __v: 0,
      },
      {
        coords: {
          lat: -12.9696352,
          lng: 49.1423666,
        },
        _id: "64f75285ef8f606073e9c1c1",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "tanambao marivorahona",
        fokontany: "betsimiranjana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.079452,
          lng: 49.0741351,
        },
        _id: "64f75285ef8f606073e9c1c2",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "tanambao marivorahona",
        fokontany: "ambodiampana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.0895898,
          lng: 49.0665589,
        },
        _id: "64f75285ef8f606073e9c1c3",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "tanambao marivorahona",
        fokontany: "mahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -13.0866443,
          lng: 49.10571359999999,
        },
        _id: "64f75285ef8f606073e9c1c4",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "tanambao marivorahona",
        fokontany: "marivorahona",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1193382,
          lng: 49.0766607,
        },
        _id: "64f75285ef8f606073e9c1c5",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "tanambao marivorahona",
        fokontany: "bobasatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1245275,
          lng: 49.0312164,
        },
        _id: "64f75285ef8f606073e9c1c6",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "tanambao marivorahona",
        fokontany: "antsatrambalo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.0885998,
          lng: 49.1828385,
        },
        _id: "64f75285ef8f606073e9c1c7",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "tanambao marivorahona",
        fokontany: "ananjaka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1491026,
          lng: 49.0867644,
        },
        _id: "64f75285ef8f606073e9c1c8",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "tanambao marivorahona",
        fokontany: "isesy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4380169,
          lng: 49.1625989,
        },
        _id: "64f75285ef8f606073e9c1c9",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambakirano",
        fokontany: "ambakirano",
        __v: 0,
      },
      {
        coords: {
          lat: -13.181042,
          lng: 49.1322531,
        },
        _id: "64f75285ef8f606073e9c1ca",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambakirano",
        fokontany: "mikaina",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1831959,
          lng: 49.1676582,
        },
        _id: "64f75285ef8f606073e9c1cb",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambakirano",
        fokontany: "anjavimilay",
        __v: 0,
      },
      {
        coords: {
          lat: -13.229655,
          lng: 49.1676582,
        },
        _id: "64f75285ef8f606073e9c1cc",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambakirano",
        fokontany: "ampasindava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2311515,
          lng: 49.127197,
        },
        _id: "64f75285ef8f606073e9c1cd",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambakirano",
        fokontany: "ambohibaky",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2140677,
          lng: 49.0867644,
        },
        _id: "64f75285ef8f606073e9c1ce",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambakirano",
        fokontany: "ankiamadoso",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2345887,
          lng: 49.09686989999999,
        },
        _id: "64f75285ef8f606073e9c1cf",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambakirano",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2429597,
          lng: 49.2005539,
        },
        _id: "64f75285ef8f606073e9c1d0",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambakirano",
        fokontany: "anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -13.26022,
          lng: 49.189449,
        },
        _id: "64f75285ef8f606073e9c1d1",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambakirano",
        fokontany: "ambodimadiro",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2590256,
          lng: 49.127197,
        },
        _id: "64f75285ef8f606073e9c1d2",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambakirano",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -13.283333,
          lng: 49.09999999999999,
        },
        _id: "64f75285ef8f606073e9c1d3",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambakirano",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2888652,
          lng: 49.1373096,
        },
        _id: "64f75285ef8f606073e9c1d4",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambakirano",
        fokontany: "antanabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3194608,
          lng: 49.22333889999999,
        },
        _id: "64f75285ef8f606073e9c1d5",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambakirano",
        fokontany: "behefaka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.333333,
          lng: 49.116667,
        },
        _id: "64f75285ef8f606073e9c1d6",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambakirano",
        fokontany: "ampasimaty",
        __v: 0,
      },
      {
        coords: {
          lat: -13.401835,
          lng: 49.2638678,
        },
        _id: "64f75285ef8f606073e9c1d7",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambakirano",
        fokontany: "ankotoba",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4267279,
          lng: 49.1524819,
        },
        _id: "64f75285ef8f606073e9c1d8",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambakirano",
        fokontany: "andraja",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5590449,
          lng: 49.3044253,
        },
        _id: "64f75285ef8f606073e9c1d9",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambakirano",
        fokontany: "anketrakabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5724911,
          lng: 49.1828385,
        },
        _id: "64f75285ef8f606073e9c1da",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambakirano",
        fokontany: "andrahary",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5159156,
          lng: 49.1322531,
        },
        _id: "64f75285ef8f606073e9c1db",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambakirano",
        fokontany: "betainkilotra",
        __v: 0,
      },
      {
        coords: {
          lat: -13.32093,
          lng: 49.011501,
        },
        _id: "64f75285ef8f606073e9c1dc",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambatoben'anjavy",
        fokontany: "ambatoben'anjavy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2358234,
          lng: 49.0312164,
        },
        _id: "64f75285ef8f606073e9c1dd",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambatoben'anjavy",
        fokontany: "ambalakaty",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2436374,
          lng: 49.0716096,
        },
        _id: "64f75285ef8f606073e9c1de",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambatoben'anjavy",
        fokontany: "bobasatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2954436,
          lng: 49.0514094,
        },
        _id: "64f75285ef8f606073e9c1df",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambatoben'anjavy",
        fokontany: "andongoza",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2839375,
          lng: 49.01607629999999,
        },
        _id: "64f75285ef8f606073e9c1e0",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambatoben'anjavy",
        fokontany: "matsaborilava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.39277,
          lng: 49.039879,
        },
        _id: "64f75285ef8f606073e9c1e1",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambatoben'anjavy",
        fokontany: "amborondolo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4030158,
          lng: 49.0312164,
        },
        _id: "64f75285ef8f606073e9c1e2",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambatoben'anjavy",
        fokontany: "andampy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4661293,
          lng: 49.0211225,
        },
        _id: "64f75285ef8f606073e9c1e3",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambatoben'anjavy",
        fokontany: "beranomaso",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5858171,
          lng: 49.0615086,
        },
        _id: "64f75285ef8f606073e9c1e4",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambatoben'anjavy",
        fokontany: "ankijabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.05239,
          lng: 49.020519,
        },
        _id: "64f75285ef8f606073e9c1e5",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsaravibe",
        fokontany: "antsaravibe",
        __v: 0,
      },
      {
        coords: {
          lat: -12.8914686,
          lng: 49.0716096,
        },
        _id: "64f75285ef8f606073e9c1e6",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsaravibe",
        fokontany: "matsaborimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -12.8985234,
          lng: 49.0110305,
        },
        _id: "64f75285ef8f606073e9c1e7",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsaravibe",
        fokontany: "antsatrabonko",
        __v: 0,
      },
      {
        coords: {
          lat: -12.9346295,
          lng: 49.01859940000001,
        },
        _id: "64f75285ef8f606073e9c1e8",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsaravibe",
        fokontany: "andrafiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -12.9390117,
          lng: 48.9807651,
        },
        _id: "64f75285ef8f606073e9c1e9",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsaravibe",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.00674,
          lng: 48.959942,
        },
        _id: "64f75285ef8f606073e9c1ea",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsaravibe",
        fokontany: "andranofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.0313149,
          lng: 48.9555563,
        },
        _id: "64f75285ef8f606073e9c1eb",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsaravibe",
        fokontany: "ampanasina",
        __v: 0,
      },
      {
        coords: {
          lat: -13.0278441,
          lng: 48.9858082,
        },
        _id: "64f75285ef8f606073e9c1ec",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsaravibe",
        fokontany: "ankobahoba",
        __v: 0,
      },
      {
        coords: {
          lat: -12.9948518,
          lng: 49.0312164,
        },
        _id: "64f75285ef8f606073e9c1ed",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsaravibe",
        fokontany: "antsambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.0413287,
          lng: 49.05645879999999,
        },
        _id: "64f75285ef8f606073e9c1ee",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsaravibe",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -13.0902583,
          lng: 49.0337401,
        },
        _id: "64f75285ef8f606073e9c1ef",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsaravibe",
        fokontany: "analasatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1042604,
          lng: 49.0463605,
        },
        _id: "64f75285ef8f606073e9c1f0",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsaravibe",
        fokontany: "andranomamy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.0715683,
          lng: 48.9001364,
        },
        _id: "64f75285ef8f606073e9c1f1",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsohimbondrona",
        fokontany: "antsohimbondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -13.0610059,
          lng: 48.9656385,
        },
        _id: "64f75285ef8f606073e9c1f2",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsohimbondrona",
        fokontany: "pilakibe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.0547942,
          lng: 48.88503129999999,
        },
        _id: "64f75285ef8f606073e9c1f3",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsohimbondrona",
        fokontany: "analasatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.0513454,
          lng: 48.9152456,
        },
        _id: "64f75285ef8f606073e9c1f4",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsohimbondrona",
        fokontany: "andranomamy",
        __v: 0,
      },
      {
        coords: {
          lat: -12.899359,
          lng: 48.6038129,
        },
        _id: "64f75285ef8f606073e9c1f5",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsohimbondrona",
        fokontany: "antenina",
        __v: 0,
      },
      {
        coords: {
          lat: -13.0902875,
          lng: 48.92532060000001,
        },
        _id: "64f75285ef8f606073e9c1f6",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsohimbondrona",
        fokontany: "sirama",
        __v: 0,
      },
      {
        coords: {
          lat: -13.101038,
          lng: 48.88503129999999,
        },
        _id: "64f75285ef8f606073e9c1f7",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsohimbondrona",
        fokontany: "mahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -13.0907128,
          lng: 48.9757225,
        },
        _id: "64f75285ef8f606073e9c1f8",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsohimbondrona",
        fokontany: "matsaborimalio",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1229633,
          lng: 48.8548333,
        },
        _id: "64f75285ef8f606073e9c1f9",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsohimbondrona",
        fokontany: "ambodikatakata chevalley",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1311969,
          lng: 48.9454759,
        },
        _id: "64f75285ef8f606073e9c1fa",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsohimbondrona",
        fokontany: "tsaratanana I",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1382839,
          lng: 48.9102087,
        },
        _id: "64f75285ef8f606073e9c1fb",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "antsohimbondrona",
        fokontany: "andranomatana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2300087,
          lng: 48.86834760000001,
        },
        _id: "64f75285ef8f606073e9c1fc",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "anjiabe ambony",
        fokontany: "anjiabe ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2222289,
          lng: 48.87748029999999,
        },
        _id: "64f75285ef8f606073e9c1fd",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "anjiabe ambony",
        fokontany: "tsaratanana II groupe 5",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2195542,
          lng: 48.8875486,
        },
        _id: "64f75285ef8f606073e9c1fe",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "anjiabe ambony",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -13.259286,
          lng: 48.87748029999999,
        },
        _id: "64f75285ef8f606073e9c1ff",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "anjiabe ambony",
        fokontany: "anjiabe ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2505932,
          lng: 48.8447708,
        },
        _id: "64f75285ef8f606073e9c200",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "anjiabe ambony",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2519812,
          lng: 48.8875486,
        },
        _id: "64f75285ef8f606073e9c201",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "anjiabe ambony",
        fokontany: "andranonabo ambany",
        __v: 0,
      },
      {
        coords: {
          lat: -13.15849,
          lng: 48.85511,
        },
        _id: "64f75285ef8f606073e9c202",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambodibonara",
        fokontany: "ambodibonara",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1451177,
          lng: 48.84980179999999,
        },
        _id: "64f75285ef8f606073e9c203",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambodibonara",
        fokontany: "ampasivelona",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1731263,
          lng: 48.8749635,
        },
        _id: "64f75285ef8f606073e9c204",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambodibonara",
        fokontany: "ampano",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1756787,
          lng: 48.8523175,
        },
        _id: "64f75285ef8f606073e9c205",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambodibonara",
        fokontany: "betainkilotra",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1829815,
          lng: 48.8422555,
        },
        _id: "64f75285ef8f606073e9c206",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambodibonara",
        fokontany: "andakorobe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1969292,
          lng: 48.848544,
        },
        _id: "64f75285ef8f606073e9c207",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambodibonara",
        fokontany: "betamboho",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2152461,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9c208",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambodibonara",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1896897,
          lng: 48.8648975,
        },
        _id: "64f75285ef8f606073e9c209",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambodibonara",
        fokontany: "ampasimazava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3436308,
          lng: 48.8762219,
        },
        _id: "64f75285ef8f606073e9c20a",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "beramanja",
        fokontany: "beramanja",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2719045,
          lng: 48.9303587,
        },
        _id: "64f75285ef8f606073e9c20b",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "beramanja",
        fokontany: "ankiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2915817,
          lng: 48.8648975,
        },
        _id: "64f75285ef8f606073e9c20c",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "beramanja",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3275592,
          lng: 48.9303587,
        },
        _id: "64f75285ef8f606073e9c20d",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "beramanja",
        fokontany: "mataipako",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3078583,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9c20e",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "beramanja",
        fokontany: "bedara",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3446229,
          lng: 48.8045391,
        },
        _id: "64f75285ef8f606073e9c20f",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "beramanja",
        fokontany: "antsotsomo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3449288,
          lng: 48.8296806,
        },
        _id: "64f75285ef8f606073e9c210",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "beramanja",
        fokontany: "antanimandry",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3651371,
          lng: 48.8145943,
        },
        _id: "64f75285ef8f606073e9c211",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "beramanja",
        fokontany: "ankazomborona",
        __v: 0,
      },
      {
        coords: {
          lat: -13.385975,
          lng: 48.84980179999999,
        },
        _id: "64f75285ef8f606073e9c212",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "beramanja",
        fokontany: "angodorofo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.383333,
          lng: 48.966667,
        },
        _id: "64f75285ef8f606073e9c213",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "beramanja",
        fokontany: "ankitokazo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3978565,
          lng: 48.9102087,
        },
        _id: "64f75285ef8f606073e9c214",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "beramanja",
        fokontany: "irarona",
        __v: 0,
      },
      {
        coords: {
          lat: -13.455547,
          lng: 48.7794089,
        },
        _id: "64f75285ef8f606073e9c215",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "beramanja",
        fokontany: "antsatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4830221,
          lng: 48.7542899,
        },
        _id: "64f75285ef8f606073e9c216",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "beramanja",
        fokontany: "ankazomahity",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4766711,
          lng: 48.6990679,
        },
        _id: "64f75285ef8f606073e9c217",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "beramanja",
        fokontany: "siranana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5435782,
          lng: 48.7091042,
        },
        _id: "64f75285ef8f606073e9c218",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "beramanja",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6170041,
          lng: 48.6589406,
        },
        _id: "64f75285ef8f606073e9c219",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "beramanja",
        fokontany: "belinta",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1581959,
          lng: 49.23726749999999,
        },
        _id: "64f75285ef8f606073e9c21a",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "betsiaka",
        fokontany: "betsiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.0343884,
          lng: 49.4364335,
        },
        _id: "64f75285ef8f606073e9c21b",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "betsiaka",
        fokontany: "ambararata loky",
        __v: 0,
      },
      {
        coords: {
          lat: -13.0601036,
          lng: 49.3754694,
        },
        _id: "64f75285ef8f606073e9c21c",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "betsiaka",
        fokontany: "ankaramy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1043622,
          lng: 49.3145691,
        },
        _id: "64f75285ef8f606073e9c21d",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "betsiaka",
        fokontany: "andrafialava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1420733,
          lng: 49.3907044,
        },
        _id: "64f75285ef8f606073e9c21e",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "betsiaka",
        fokontany: "ampahaka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1408231,
          lng: 49.2132111,
        },
        _id: "64f75285ef8f606073e9c21f",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "betsiaka",
        fokontany: "ankatoko",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1756271,
          lng: 49.3957836,
        },
        _id: "64f75285ef8f606073e9c220",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "betsiaka",
        fokontany: "tanambao mangily",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3203988,
          lng: 49.3247147,
        },
        _id: "64f75285ef8f606073e9c221",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "betsiaka",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2076091,
          lng: 49.1980228,
        },
        _id: "64f75285ef8f606073e9c222",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "betsiaka",
        fokontany: "mosorobe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2415581,
          lng: 49.2537329,
        },
        _id: "64f75285ef8f606073e9c223",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "betsiaka",
        fokontany: "sandranary",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5698116,
          lng: 48.9807651,
        },
        _id: "64f75285ef8f606073e9c224",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambarakaraka",
        fokontany: "ambarakaraka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4575475,
          lng: 48.9303587,
        },
        _id: "64f75285ef8f606073e9c225",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambarakaraka",
        fokontany: "mamoro",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4495969,
          lng: 48.8900659,
        },
        _id: "64f75285ef8f606073e9c226",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambarakaraka",
        fokontany: "ankinganio",
        __v: 0,
      },
      {
        coords: {
          lat: -13.483333,
          lng: 48.84999999999999,
        },
        _id: "64f75285ef8f606073e9c227",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambarakaraka",
        fokontany: "ankiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4771231,
          lng: 48.8648975,
        },
        _id: "64f75285ef8f606073e9c228",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambarakaraka",
        fokontany: "ankoby",
        __v: 0,
      },
      {
        coords: {
          lat: -13.490397,
          lng: 48.88503129999999,
        },
        _id: "64f75285ef8f606073e9c229",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambarakaraka",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5042862,
          lng: 48.8145943,
        },
        _id: "64f75285ef8f606073e9c22a",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambarakaraka",
        fokontany: "antanantanana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.53454,
          lng: 48.950649,
        },
        _id: "64f75285ef8f606073e9c22b",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambarakaraka",
        fokontany: "tsarafasy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.57646,
          lng: 48.94101,
        },
        _id: "64f75285ef8f606073e9c22c",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambarakaraka",
        fokontany: "ambendrana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.61082,
          lng: 49.00494,
        },
        _id: "64f75285ef8f606073e9c22d",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "ambarakaraka",
        fokontany: "ambakirano",
        __v: 0,
      },
      {
        coords: {
          lat: -13.54743,
          lng: 48.82561099999999,
        },
        _id: "64f75285ef8f606073e9c22e",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "anaborano ifasy",
        fokontany: "anaborano ifasy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5394239,
          lng: 48.8045391,
        },
        _id: "64f75285ef8f606073e9c22f",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "anaborano ifasy",
        fokontany: "andranomitilina",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5465042,
          lng: 48.9102087,
        },
        _id: "64f75285ef8f606073e9c230",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "anaborano ifasy",
        fokontany: "antarevoko",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5644612,
          lng: 48.8020256,
        },
        _id: "64f75285ef8f606073e9c231",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "anaborano ifasy",
        fokontany: "marodimaka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5758154,
          lng: 48.7542899,
        },
        _id: "64f75285ef8f606073e9c232",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "anaborano ifasy",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -13.572116,
          lng: 48.8171084,
        },
        _id: "64f75285ef8f606073e9c233",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "anaborano ifasy",
        fokontany: "antsiviranina",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7026713,
          lng: 48.9001364,
        },
        _id: "64f75285ef8f606073e9c234",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "anaborano ifasy",
        fokontany: "ambanikibo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5984093,
          lng: 48.7743842,
        },
        _id: "64f75285ef8f606073e9c235",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "anaborano ifasy",
        fokontany: "bobantsetry",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6193913,
          lng: 48.8095665,
        },
        _id: "64f75285ef8f606073e9c236",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "anaborano ifasy",
        fokontany: "maroriha",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6274992,
          lng: 48.7342029,
        },
        _id: "64f75285ef8f606073e9c237",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "anaborano ifasy",
        fokontany: "antsamolaly",
        __v: 0,
      },
      {
        coords: {
          lat: -13.64911,
          lng: 48.734619,
        },
        _id: "64f75285ef8f606073e9c238",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "anaborano ifasy",
        fokontany: "antsaba",
        __v: 0,
      },
      {
        coords: {
          lat: -13.715594,
          lng: 48.7794089,
        },
        _id: "64f75285ef8f606073e9c239",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "anaborano ifasy",
        fokontany: "mahatsara ambony",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7,
          lng: 49.116667,
        },
        _id: "64f75285ef8f606073e9c23a",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "manambato",
        fokontany: "manambato",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6384359,
          lng: 49.0918169,
        },
        _id: "64f75285ef8f606073e9c23b",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "manambato",
        fokontany: "ambilobekely",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7199774,
          lng: 49.3145691,
        },
        _id: "64f75285ef8f606073e9c23c",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "manambato",
        fokontany: "ambatomily",
        __v: 0,
      },
      {
        coords: {
          lat: -13.783709,
          lng: 49.0716096,
        },
        _id: "64f75285ef8f606073e9c23d",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "manambato",
        fokontany: "zarambavy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9153847,
          lng: 49.1221414,
        },
        _id: "64f75285ef8f606073e9c23e",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "manambato",
        fokontany: "ambohipato",
        __v: 0,
      },
      {
        coords: {
          lat: -13.816667,
          lng: 49.34999999999999,
        },
        _id: "64f75285ef8f606073e9c23f",
        province: "antsiranana",
        region: "diana",
        district: "ambilobe",
        commune: "manambato",
        fokontany: "ambodiala",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3908863,
          lng: 48.2816655,
        },
        _id: "64f75285ef8f606073e9c240",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "hell-ville",
        fokontany: "camp vert",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3902917,
          lng: 48.24432909999999,
        },
        _id: "64f75285ef8f606073e9c241",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "hell-ville",
        fokontany: "djabalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3806721,
          lng: 48.2766859,
        },
        _id: "64f75285ef8f606073e9c242",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "hell-ville",
        fokontany: "senganinga",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4053116,
          lng: 48.26174959999999,
        },
        _id: "64f75285ef8f606073e9c243",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "hell-ville",
        fokontany: "andavakotoko",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4135225,
          lng: 48.31404449999999,
        },
        _id: "64f75285ef8f606073e9c244",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "hell-ville",
        fokontany: "ambanoro",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3878854,
          lng: 48.26672790000001,
        },
        _id: "64f75285ef8f606073e9c245",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "hell-ville",
        fokontany: "la batterie",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4712828,
          lng: 48.3489356,
        },
        _id: "64f75285ef8f606073e9c246",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "ampangorina",
        fokontany: "ampangorina",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4584425,
          lng: 48.3539219,
        },
        _id: "64f75285ef8f606073e9c247",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "ampangorina",
        fokontany: "antitorona",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4636547,
          lng: 48.3339796,
        },
        _id: "64f75285ef8f606073e9c248",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "ampangorina",
        fokontany: "ampasibe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4789129,
          lng: 48.36389579999999,
        },
        _id: "64f75285ef8f606073e9c249",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "ampangorina",
        fokontany: "antamotamo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4841233,
          lng: 48.3439498,
        },
        _id: "64f75285ef8f606073e9c24a",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "ampangorina",
        fokontany: "anjiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3672288,
          lng: 48.3159131,
        },
        _id: "64f75285ef8f606073e9c24b",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "ambatozavavy",
        fokontany: "ambatozavavy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3153914,
          lng: 48.2841556,
        },
        _id: "64f75285ef8f606073e9c24c",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "ambatozavavy",
        fokontany: "marokindro",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3446324,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e9c24d",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "ambatozavavy",
        fokontany: "andranobe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3892813,
          lng: 48.3411455,
        },
        _id: "64f75285ef8f606073e9c24e",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "ambatozavavy",
        fokontany: "ampasipohy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4092107,
          lng: 48.3550127,
        },
        _id: "64f75285ef8f606073e9c24f",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "ambatozavavy",
        fokontany: "antafondro",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2749577,
          lng: 48.2891359,
        },
        _id: "64f75285ef8f606073e9c250",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "bemanondrobe",
        fokontany: "bemanondrobe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.214161,
          lng: 48.2841556,
        },
        _id: "64f75285ef8f606073e9c251",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "bemanondrobe",
        fokontany: "antsatrabevoa",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2286012,
          lng: 48.2642387,
        },
        _id: "64f75285ef8f606073e9c252",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "bemanondrobe",
        fokontany: "belamandy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.246997,
          lng: 48.2642387,
        },
        _id: "64f75285ef8f606073e9c253",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "bemanondrobe",
        fokontany: "befotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2364937,
          lng: 48.3025229,
        },
        _id: "64f75285ef8f606073e9c254",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "bemanondrobe",
        fokontany: "mahazandry",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2791041,
          lng: 48.3215193,
        },
        _id: "64f75285ef8f606073e9c255",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "bemanondrobe",
        fokontany: "antafianambitry",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2870397,
          lng: 48.23437699999999,
        },
        _id: "64f75285ef8f606073e9c256",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "bemanondrobe",
        fokontany: "andrahibo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2893945,
          lng: 48.26921720000001,
        },
        _id: "64f75285ef8f606073e9c257",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "bemanondrobe",
        fokontany: "maromaniry",
        __v: 0,
      },
      {
        coords: {
          lat: -13.31215,
          lng: 48.31404449999999,
        },
        _id: "64f75285ef8f606073e9c258",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "bemanondrobe",
        fokontany: "fascene gare",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3253407,
          lng: 48.3339796,
        },
        _id: "64f75285ef8f606073e9c259",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "bemanondrobe",
        fokontany: "andimakabo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.366277,
          lng: 48.18215850000001,
        },
        _id: "64f75285ef8f606073e9c25a",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "dzamandzar",
        fokontany: "dzamandzar ampasy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2718809,
          lng: 48.2045317,
        },
        _id: "64f75285ef8f606073e9c25b",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "dzamandzar",
        fokontany: "antanamitarana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3142631,
          lng: 48.2095048,
        },
        _id: "64f75285ef8f606073e9c25c",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "dzamandzar",
        fokontany: "orangea",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3454687,
          lng: 48.2045317,
        },
        _id: "64f75285ef8f606073e9c25d",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "dzamandzar",
        fokontany: "dzamandzar usine",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3678734,
          lng: 48.2244268,
        },
        _id: "64f75285ef8f606073e9c25e",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "dzamandzar",
        fokontany: "androadroatra",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3730791,
          lng: 48.2045317,
        },
        _id: "64f75285ef8f606073e9c25f",
        province: "antsiranana",
        region: "diana",
        district: "nosy-be",
        commune: "dzamandzar",
        fokontany: "ambatoloaka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6793449,
          lng: 48.4574892,
        },
        _id: "64f75285ef8f606073e9c260",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambanja",
        fokontany: "ambanja centre",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5757596,
          lng: 48.4088007,
        },
        _id: "64f75285ef8f606073e9c261",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambanja",
        fokontany: "antsahapano",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6131934,
          lng: 48.43376380000001,
        },
        _id: "64f75285ef8f606073e9c262",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambanja",
        fokontany: "andzavibe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6283085,
          lng: 48.4512446,
        },
        _id: "64f75285ef8f606073e9c263",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambanja",
        fokontany: "ambalavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6411791,
          lng: 48.4462495,
        },
        _id: "64f75285ef8f606073e9c264",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambanja",
        fokontany: "mahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6560811,
          lng: 48.4512446,
        },
        _id: "64f75285ef8f606073e9c265",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambanja",
        fokontany: "begavo II",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6639289,
          lng: 48.4499958,
        },
        _id: "64f75285ef8f606073e9c266",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambanja",
        fokontany: "begavo I",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6695753,
          lng: 48.4549913,
        },
        _id: "64f75285ef8f606073e9c267",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambanja",
        fokontany: "tanambao V",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6735837,
          lng: 48.4462495,
        },
        _id: "64f75285ef8f606073e9c268",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambanja",
        fokontany: "ankatafahely",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6776514,
          lng: 48.4662327,
        },
        _id: "64f75285ef8f606073e9c269",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambanja",
        fokontany: "ambatomenavava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6694055,
          lng: 48.4712296,
        },
        _id: "64f75285ef8f606073e9c26a",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambanja",
        fokontany: "bemangaoka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6747141,
          lng: 48.4574892,
        },
        _id: "64f75285ef8f606073e9c26b",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambanja",
        fokontany: "tanambao mission",
        __v: 0,
      },
      {
        coords: {
          lat: -13.659469,
          lng: 48.43376380000001,
        },
        _id: "64f75285ef8f606073e9c26c",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambanja",
        fokontany: "ampamakia",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6857827,
          lng: 48.4549913,
        },
        _id: "64f75285ef8f606073e9c26d",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambanja",
        fokontany: "ambaibo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.689281,
          lng: 48.4437522,
        },
        _id: "64f75285ef8f606073e9c26e",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambanja",
        fokontany: "androhibe cnia",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6886112,
          lng: 48.5087209,
        },
        _id: "64f75285ef8f606073e9c26f",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "benavony",
        fokontany: "benavony",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6644283,
          lng: 48.5037206,
        },
        _id: "64f75285ef8f606073e9c270",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "benavony",
        fokontany: "antsifitry",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6870269,
          lng: 48.5237246,
        },
        _id: "64f75285ef8f606073e9c271",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "benavony",
        fokontany: "ankarabato",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7148398,
          lng: 48.5237246,
        },
        _id: "64f75285ef8f606073e9c272",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "benavony",
        fokontany: "ambazoanahely",
        __v: 0,
      },
      {
        coords: {
          lat: -13.59282,
          lng: 48.414001,
        },
        _id: "64f75285ef8f606073e9c273",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambohimena",
        fokontany: "ambohimena",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5805235,
          lng: 48.36389579999999,
        },
        _id: "64f75285ef8f606073e9c274",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambohimena",
        fokontany: "ankify",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5764755,
          lng: 48.3439498,
        },
        _id: "64f75285ef8f606073e9c275",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambohimena",
        fokontany: "andimaka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6102818,
          lng: 48.3738715,
        },
        _id: "64f75285ef8f606073e9c276",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambohimena",
        fokontany: "ampahakabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6389262,
          lng: 48.4237772,
        },
        _id: "64f75285ef8f606073e9c277",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambohimena",
        fokontany: "matsaborilava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7012197,
          lng: 48.3888384,
        },
        _id: "64f75285ef8f606073e9c278",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsatsaka",
        fokontany: "antsatsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6739154,
          lng: 48.41379240000001,
        },
        _id: "64f75285ef8f606073e9c279",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsatsaka",
        fokontany: "ambodifinesy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6883549,
          lng: 48.3938283,
        },
        _id: "64f75285ef8f606073e9c27a",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsatsaka",
        fokontany: "ambodimantaly",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6896063,
          lng: 48.4112965,
        },
        _id: "64f75285ef8f606073e9c27b",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsatsaka",
        fokontany: "analavory",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6999637,
          lng: 48.37137740000001,
        },
        _id: "64f75285ef8f606073e9c27c",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsatsaka",
        fokontany: "antanabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7050638,
          lng: 48.3963234,
        },
        _id: "64f75285ef8f606073e9c27d",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsatsaka",
        fokontany: "ambodisakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7454667,
          lng: 48.37886,
        },
        _id: "64f75285ef8f606073e9c27e",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antranokarany",
        fokontany: "antranokarany",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6987065,
          lng: 48.3539219,
        },
        _id: "64f75285ef8f606073e9c27f",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antranokarany",
        fokontany: "antrema",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7037278,
          lng: 48.4237772,
        },
        _id: "64f75285ef8f606073e9c280",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antranokarany",
        fokontany: "androhibe sakalava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7169724,
          lng: 48.3414571,
        },
        _id: "64f75285ef8f606073e9c281",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antranokarany",
        fokontany: "marosely",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7199721,
          lng: 48.40131400000001,
        },
        _id: "64f75285ef8f606073e9c282",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antranokarany",
        fokontany: "befitina",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7222504,
          lng: 48.4237772,
        },
        _id: "64f75285ef8f606073e9c283",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antranokarany",
        fokontany: "mangabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7220809,
          lng: 48.3663895,
        },
        _id: "64f75285ef8f606073e9c284",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antranokarany",
        fokontany: "ampamakia",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7624289,
          lng: 48.3938283,
        },
        _id: "64f75285ef8f606073e9c285",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antranokarany",
        fokontany: "ambalamahogo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7479958,
          lng: 48.41379240000001,
        },
        _id: "64f75285ef8f606073e9c286",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antranokarany",
        fokontany: "ampondrabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7449796,
          lng: 48.3539219,
        },
        _id: "64f75285ef8f606073e9c287",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antranokarany",
        fokontany: "ankotika",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8710577,
          lng: 48.3589086,
        },
        _id: "64f75285ef8f606073e9c288",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "djangoa",
        fokontany: "djangoa",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8170048,
          lng: 48.3439498,
        },
        _id: "64f75285ef8f606073e9c289",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "djangoa",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8700001,
          lng: 48.3090619,
        },
        _id: "64f75285ef8f606073e9c28a",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "djangoa",
        fokontany: "ankarongana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0039423,
          lng: 48.3289951,
        },
        _id: "64f75285ef8f606073e9c28b",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "djangoa",
        fokontany: "tanambao belinta",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9679455,
          lng: 48.37886,
        },
        _id: "64f75285ef8f606073e9c28c",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "djangoa",
        fokontany: "tsiroamandidy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.62983,
          lng: 48.5387323,
        },
        _id: "64f75285ef8f606073e9c28d",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsakoamanondro",
        fokontany: "antsakoamanondro",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5074414,
          lng: 48.5287267,
        },
        _id: "64f75285ef8f606073e9c28e",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsakoamanondro",
        fokontany: "ankingabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.561369,
          lng: 48.5437358,
        },
        _id: "64f75285ef8f606073e9c28f",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsakoamanondro",
        fokontany: "mahamanina",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5814937,
          lng: 48.5287267,
        },
        _id: "64f75285ef8f606073e9c290",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsakoamanondro",
        fokontany: "andrahibo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6008152,
          lng: 48.5212237,
        },
        _id: "64f75285ef8f606073e9c291",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsakoamanondro",
        fokontany: "ambodimangasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6140639,
          lng: 48.4837239,
        },
        _id: "64f75285ef8f606073e9c292",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsakoamanondro",
        fokontany: "ankazokony",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6241852,
          lng: 48.5337293,
        },
        _id: "64f75285ef8f606073e9c293",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsakoamanondro",
        fokontany: "ambatofitatra",
        __v: 0,
      },
      {
        coords: {
          lat: -13.641843,
          lng: 48.4837239,
        },
        _id: "64f75285ef8f606073e9c294",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsakoamanondro",
        fokontany: "anjavimilay",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6462525,
          lng: 48.4712296,
        },
        _id: "64f75285ef8f606073e9c295",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsakoamanondro",
        fokontany: "madirofolo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.65,
          lng: 48.566667,
        },
        _id: "64f75285ef8f606073e9c296",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsakoamanondro",
        fokontany: "ambazoanabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5693821,
          lng: 48.4687311,
        },
        _id: "64f75285ef8f606073e9c297",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambalahonko",
        fokontany: "ambalahonko",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5284986,
          lng: 48.4711976,
        },
        _id: "64f75285ef8f606073e9c298",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambalahonko",
        fokontany: "ambiky",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5565128,
          lng: 48.4737282,
        },
        _id: "64f75285ef8f606073e9c299",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambalahonko",
        fokontany: "ambolikapiky",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5794331,
          lng: 48.4612362,
        },
        _id: "64f75285ef8f606073e9c29a",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambalahonko",
        fokontany: "ampanakana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5842722,
          lng: 48.4737282,
        },
        _id: "64f75285ef8f606073e9c29b",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambalahonko",
        fokontany: "andimakafito",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6041585,
          lng: 48.4462495,
        },
        _id: "64f75285ef8f606073e9c29c",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambalahonko",
        fokontany: "mahadera",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6144251,
          lng: 48.4512446,
        },
        _id: "64f75285ef8f606073e9c29d",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambalahonko",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8390951,
          lng: 48.24930579999999,
        },
        _id: "64f75285ef8f606073e9c29e",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ankingameloka",
        fokontany: "ankingameloka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8241838,
          lng: 48.24432909999999,
        },
        _id: "64f75285ef8f606073e9c29f",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ankingameloka",
        fokontany: "ambodimadiro",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8180337,
          lng: 48.3040797,
        },
        _id: "64f75285ef8f606073e9c2a0",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ankingameloka",
        fokontany: "berambo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8470837,
          lng: 48.2766859,
        },
        _id: "64f75285ef8f606073e9c2a1",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ankingameloka",
        fokontany: "antranofotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8519448,
          lng: 48.24432909999999,
        },
        _id: "64f75285ef8f606073e9c2a2",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ankingameloka",
        fokontany: "antanambao befatsy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8653334,
          lng: 48.2642387,
        },
        _id: "64f75285ef8f606073e9c2a3",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ankingameloka",
        fokontany: "djodjahely",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8423102,
          lng: 48.49497119999999,
        },
        _id: "64f75285ef8f606073e9c2a4",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "bemaneviky haut sambirano",
        fokontany: "bemaneviky haut sambirano",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8045815,
          lng: 48.4637344,
        },
        _id: "64f75285ef8f606073e9c2a5",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "bemaneviky haut sambirano",
        fokontany: "andranomandevy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8115159,
          lng: 48.4862231,
        },
        _id: "64f75285ef8f606073e9c2a6",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "bemaneviky haut sambirano",
        fokontany: "ambodifinesy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8282979,
          lng: 48.4437522,
        },
        _id: "64f75285ef8f606073e9c2a7",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "bemaneviky haut sambirano",
        fokontany: "mahatera",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8633418,
          lng: 48.43376380000001,
        },
        _id: "64f75285ef8f606073e9c2a8",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "bemaneviky haut sambirano",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8633079,
          lng: 48.5237246,
        },
        _id: "64f75285ef8f606073e9c2a9",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "bemaneviky haut sambirano",
        fokontany: "antsamala",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8767418,
          lng: 48.4537424,
        },
        _id: "64f75285ef8f606073e9c2aa",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "bemaneviky haut sambirano",
        fokontany: "ambahatra",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7718912,
          lng: 48.598804,
        },
        _id: "64f75285ef8f606073e9c2ab",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambodimanga ramena",
        fokontany: "ambodimanga ramena",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6921056,
          lng: 48.7091042,
        },
        _id: "64f75285ef8f606073e9c2ac",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambodimanga ramena",
        fokontany: "antsahavary",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7234377,
          lng: 48.5887875,
        },
        _id: "64f75285ef8f606073e9c2ad",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambodimanga ramena",
        fokontany: "antseva",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7818673,
          lng: 48.739224,
        },
        _id: "64f75285ef8f606073e9c2ae",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambodimanga ramena",
        fokontany: "antsahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7508564,
          lng: 48.5155971,
        },
        _id: "64f75285ef8f606073e9c2af",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambodimanga ramena",
        fokontany: "ambobaka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7417253,
          lng: 48.4737282,
        },
        _id: "64f75285ef8f606073e9c2b0",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambodimanga ramena",
        fokontany: "ambolidimaka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.791393,
          lng: 48.6489134,
        },
        _id: "64f75285ef8f606073e9c2b1",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambodimanga ramena",
        fokontany: "bezono",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7690566,
          lng: 48.448747,
        },
        _id: "64f75285ef8f606073e9c2b2",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambodimanga ramena",
        fokontany: "manambaro",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7998924,
          lng: 48.5087209,
        },
        _id: "64f75285ef8f606073e9c2b3",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambodimanga ramena",
        fokontany: "anaborano salama",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8820083,
          lng: 48.5299773,
        },
        _id: "64f75285ef8f606073e9c2b4",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "maevatanana",
        fokontany: "maevatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8452298,
          lng: 48.5487397,
        },
        _id: "64f75285ef8f606073e9c2b5",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "maevatanana",
        fokontany: "anjiabory",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8686991,
          lng: 48.5612516,
        },
        _id: "64f75285ef8f606073e9c2b6",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "maevatanana",
        fokontany: "ambalafary",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8908987,
          lng: 48.5562465,
        },
        _id: "64f75285ef8f606073e9c2b7",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "maevatanana",
        fokontany: "ampasimitera",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8929585,
          lng: 48.56625709999999,
        },
        _id: "64f75285ef8f606073e9c2b8",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "maevatanana",
        fokontany: "ambakirano",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9004596,
          lng: 48.5237246,
        },
        _id: "64f75285ef8f606073e9c2b9",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "maevatanana",
        fokontany: "ankidony",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4294502,
          lng: 48.5087209,
        },
        _id: "64f75285ef8f606073e9c2ba",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antafiambotry",
        fokontany: "antafiambotry",
        __v: 0,
      },
      {
        coords: {
          lat: -13.31627,
          lng: 48.4737282,
        },
        _id: "64f75285ef8f606073e9c2bb",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antafiambotry",
        fokontany: "andemby",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3417395,
          lng: 48.4799753,
        },
        _id: "64f75285ef8f606073e9c2bc",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antafiambotry",
        fokontany: "ampasikely",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3655867,
          lng: 48.5012206,
        },
        _id: "64f75285ef8f606073e9c2bd",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antafiambotry",
        fokontany: "mahabo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4220976,
          lng: 48.4806,
        },
        _id: "64f75285ef8f606073e9c2be",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antafiambotry",
        fokontany: "ampasindava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4294502,
          lng: 48.5087209,
        },
        _id: "64f75285ef8f606073e9c2bf",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antafiambotry",
        fokontany: "anjiamanoro",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4628083,
          lng: 48.5137217,
        },
        _id: "64f75285ef8f606073e9c2c0",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antafiambotry",
        fokontany: "ampasimbary",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4904646,
          lng: 48.6288642,
        },
        _id: "64f75285ef8f606073e9c2c1",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "maherivaratra",
        fokontany: "ampampamena",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4643132,
          lng: 48.613832,
        },
        _id: "64f75285ef8f606073e9c2c2",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "maherivaratra",
        fokontany: "ankigny",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4784487,
          lng: 48.56876,
        },
        _id: "64f75285ef8f606073e9c2c3",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "maherivaratra",
        fokontany: "maropamba",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5347775,
          lng: 48.6188423,
        },
        _id: "64f75285ef8f606073e9c2c4",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "maherivaratra",
        fokontany: "maherivaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5347775,
          lng: 48.6188423,
        },
        _id: "64f75285ef8f606073e9c2c5",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "maherivaratra",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5376161,
          lng: 48.5637543,
        },
        _id: "64f75285ef8f606073e9c2c6",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "maherivaratra",
        fokontany: "andilamboay",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9487353,
          lng: 48.56625709999999,
        },
        _id: "64f75285ef8f606073e9c2c7",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "marovato",
        fokontany: "marovato est",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9159399,
          lng: 48.5537441,
        },
        _id: "64f75285ef8f606073e9c2c8",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "marovato",
        fokontany: "manirenja",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9301396,
          lng: 48.56625709999999,
        },
        _id: "64f75285ef8f606073e9c2c9",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "marovato",
        fokontany: "andimaka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9324645,
          lng: 48.5437358,
        },
        _id: "64f75285ef8f606073e9c2ca",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "marovato",
        fokontany: "antsirasira",
        __v: 0,
      },
      {
        coords: {
          lat: -13.957511,
          lng: 48.541234,
        },
        _id: "64f75285ef8f606073e9c2cb",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "marovato",
        fokontany: "marovato ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9717214,
          lng: 48.5537441,
        },
        _id: "64f75285ef8f606073e9c2cc",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "marovato",
        fokontany: "mikotramihezana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0125394,
          lng: 48.5487397,
        },
        _id: "64f75285ef8f606073e9c2cd",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "marovato",
        fokontany: "ambakoana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9118358,
          lng: 48.9202828,
        },
        _id: "64f75285ef8f606073e9c2ce",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambohitrandriana",
        fokontany: "ambohitrandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9108965,
          lng: 48.58002459999999,
        },
        _id: "64f75285ef8f606073e9c2cf",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambohitrandriana",
        fokontany: "andrianabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9118358,
          lng: 48.9202828,
        },
        _id: "64f75285ef8f606073e9c2d0",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambohitrandriana",
        fokontany: "ampanasina",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9285873,
          lng: 48.5812763,
        },
        _id: "64f75285ef8f606073e9c2d1",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambohitrandriana",
        fokontany: "mahavanona",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9730295,
          lng: 48.6163371,
        },
        _id: "64f75285ef8f606073e9c2d2",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambohitrandriana",
        fokontany: "marovato savoka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9802661,
          lng: 48.6063175,
        },
        _id: "64f75285ef8f606073e9c2d3",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambohitrandriana",
        fokontany: "andavakaomby",
        __v: 0,
      },
      {
        coords: {
          lat: -13.947401,
          lng: 48.4587382,
        },
        _id: "64f75285ef8f606073e9c2d4",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambohimarina",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8764785,
          lng: 48.4862231,
        },
        _id: "64f75285ef8f606073e9c2d5",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambohimarina",
        fokontany: "antanamazava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8831811,
          lng: 48.496221,
        },
        _id: "64f75285ef8f606073e9c2d6",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambohimarina",
        fokontany: "antanambao ambahatra",
        __v: 0,
      },
      {
        coords: {
          lat: -13.947401,
          lng: 48.4587382,
        },
        _id: "64f75285ef8f606073e9c2d7",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambohimarina",
        fokontany: "migioko",
        __v: 0,
      },
      {
        coords: {
          lat: -13.76829,
          lng: 48.153141,
        },
        _id: "64f75285ef8f606073e9c2d8",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambaliha",
        fokontany: "ambaliha",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6963429,
          lng: 48.1399232,
        },
        _id: "64f75285ef8f606073e9c2d9",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambaliha",
        fokontany: "bandrany",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7317277,
          lng: 48.154826,
        },
        _id: "64f75285ef8f606073e9c2da",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambaliha",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.795385,
          lng: 48.194587,
        },
        _id: "64f75285ef8f606073e9c2db",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambaliha",
        fokontany: "ankobabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8179989,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e9c2dc",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ambaliha",
        fokontany: "ankotsopo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.02548,
          lng: 48.61578,
        },
        _id: "64f75285ef8f606073e9c2dd",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "marotolana",
        fokontany: "marotolana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0136338,
          lng: 48.7794089,
        },
        _id: "64f75285ef8f606073e9c2de",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "marotolana",
        fokontany: "mahadera",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0633317,
          lng: 48.6589406,
        },
        _id: "64f75285ef8f606073e9c2df",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "marotolana",
        fokontany: "mevadoany",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0451572,
          lng: 48.5937955,
        },
        _id: "64f75285ef8f606073e9c2e0",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "marotolana",
        fokontany: "ambodimabibo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0727149,
          lng: 48.74926749999999,
        },
        _id: "64f75285ef8f606073e9c2e1",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "marotolana",
        fokontany: "beangona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1017091,
          lng: 48.7091042,
        },
        _id: "64f75285ef8f606073e9c2e2",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "marotolana",
        fokontany: "ambalamanasy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0672506,
          lng: 48.4987208,
        },
        _id: "64f75285ef8f606073e9c2e3",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "marotolana",
        fokontany: "antanamandririna",
        __v: 0,
      },
      {
        coords: {
          lat: -14.133333,
          lng: 48.866667,
        },
        _id: "64f75285ef8f606073e9c2e4",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "marotolana",
        fokontany: "ampanopia",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1500795,
          lng: 48.5387323,
        },
        _id: "64f75285ef8f606073e9c2e5",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "marotolana",
        fokontany: "amberivery nafindra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2,
          lng: 48.583333,
        },
        _id: "64f75285ef8f606073e9c2e6",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "marotolana",
        fokontany: "antafiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6349206,
          lng: 48.0208484,
        },
        _id: "64f75285ef8f606073e9c2e7",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "bemaneviky ouest",
        fokontany: "bemaneviky ouest",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5362923,
          lng: 47.9663604,
        },
        _id: "64f75285ef8f606073e9c2e8",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "bemaneviky ouest",
        fokontany: "mangirankirana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5949905,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e9c2e9",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "bemaneviky ouest",
        fokontany: "ampasimena",
        __v: 0,
      },
      {
        coords: {
          lat: -13.584315,
          lng: 47.9762632,
        },
        _id: "64f75285ef8f606073e9c2ea",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "bemaneviky ouest",
        fokontany: "amporaha",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6400362,
          lng: 47.9119276,
        },
        _id: "64f75285ef8f606073e9c2eb",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "bemaneviky ouest",
        fokontany: "marotogny",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6256452,
          lng: 48.1101299,
        },
        _id: "64f75285ef8f606073e9c2ec",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "bemaneviky ouest",
        fokontany: "anjanozano",
        __v: 0,
      },
      {
        coords: {
          lat: -13.633333,
          lng: 47.95,
        },
        _id: "64f75285ef8f606073e9c2ed",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "bemaneviky ouest",
        fokontany: "ambalihabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6830321,
          lng: 48.03076129999999,
        },
        _id: "64f75285ef8f606073e9c2ee",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "bemaneviky ouest",
        fokontany: "maroamalona",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6953276,
          lng: 48.0902768,
        },
        _id: "64f75285ef8f606073e9c2ef",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "bemaneviky ouest",
        fokontany: "kongony",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6896158,
          lng: 47.906982,
        },
        _id: "64f75285ef8f606073e9c2f0",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "bemaneviky ouest",
        fokontany: "marohariva",
        __v: 0,
      },
      {
        coords: {
          lat: -13.71632,
          lng: 48.0357184,
        },
        _id: "64f75285ef8f606073e9c2f1",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "bemaneviky ouest",
        fokontany: "bandrakorony",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7388736,
          lng: 48.0555514,
        },
        _id: "64f75285ef8f606073e9c2f2",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "bemaneviky ouest",
        fokontany: "ampopo nord",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8622235,
          lng: 47.9614096,
        },
        _id: "64f75285ef8f606073e9c2f3",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "anorontsangana",
        fokontany: "anorontsangana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7372725,
          lng: 47.9812152,
        },
        _id: "64f75285ef8f606073e9c2f4",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "anorontsangana",
        fokontany: "antetezambato",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8027499,
          lng: 47.9416114,
        },
        _id: "64f75285ef8f606073e9c2f5",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "anorontsangana",
        fokontany: "betsiriry",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7978148,
          lng: 48.0505925,
        },
        _id: "64f75285ef8f606073e9c2f6",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "anorontsangana",
        fokontany: "beampongy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8592241,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e9c2f7",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "anorontsangana",
        fokontany: "antsahamanara",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9003039,
          lng: 48.0109374,
        },
        _id: "64f75285ef8f606073e9c2f8",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "anorontsangana",
        fokontany: "antanambao andranomody",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9312685,
          lng: 48.070431,
        },
        _id: "64f75285ef8f606073e9c2f9",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "anorontsangana",
        fokontany: "bezavona",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9598994,
          lng: 48.03076129999999,
        },
        _id: "64f75285ef8f606073e9c2fa",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "anorontsangana",
        fokontany: "ambodimanga sud",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0254699,
          lng: 47.9911208,
        },
        _id: "64f75285ef8f606073e9c2fb",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "anorontsangana",
        fokontany: "antsambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0075995,
          lng: 47.7982928,
        },
        _id: "64f75285ef8f606073e9c2fc",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "anorontsangana",
        fokontany: "berafia",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8734999,
          lng: 48.19733919999999,
        },
        _id: "64f75285ef8f606073e9c2fd",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsirabe",
        fokontany: "antsirabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.83542,
          lng: 48.1647635,
        },
        _id: "64f75285ef8f606073e9c2fe",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsirabe",
        fokontany: "ambodifinesy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8102013,
          lng: 48.1101299,
        },
        _id: "64f75285ef8f606073e9c2ff",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsirabe",
        fokontany: "berahodaka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8512928,
          lng: 48.1299903,
        },
        _id: "64f75285ef8f606073e9c300",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsirabe",
        fokontany: "befitina",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8550001,
          lng: 48.2144783,
        },
        _id: "64f75285ef8f606073e9c301",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsirabe",
        fokontany: "joja",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8909044,
          lng: 48.1647635,
        },
        _id: "64f75285ef8f606073e9c302",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsirabe",
        fokontany: "beanamalao",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8846838,
          lng: 48.1349565,
        },
        _id: "64f75285ef8f606073e9c303",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsirabe",
        fokontany: "maevatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8743149,
          lng: 48.0853146,
        },
        _id: "64f75285ef8f606073e9c304",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsirabe",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8841211,
          lng: 48.1101299,
        },
        _id: "64f75285ef8f606073e9c305",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsirabe",
        fokontany: "antsirakiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9124262,
          lng: 48.1349565,
        },
        _id: "64f75285ef8f606073e9c306",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsirabe",
        fokontany: "antanambao berondra",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9041159,
          lng: 48.0952394,
        },
        _id: "64f75285ef8f606073e9c307",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsirabe",
        fokontany: "antsakoabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9431802,
          lng: 48.1051659,
        },
        _id: "64f75285ef8f606073e9c308",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "antsirabe",
        fokontany: "antsahalalina",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6601317,
          lng: 48.36888339999999,
        },
        _id: "64f75285ef8f606073e9c309",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ankatafa",
        fokontany: "ankatafa",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6567531,
          lng: 48.3863436,
        },
        _id: "64f75285ef8f606073e9c30a",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ankatafa",
        fokontany: "ankazotelo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6533715,
          lng: 48.4038094,
        },
        _id: "64f75285ef8f606073e9c30b",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ankatafa",
        fokontany: "mevarevorevo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6617039,
          lng: 48.3539219,
        },
        _id: "64f75285ef8f606073e9c30c",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ankatafa",
        fokontany: "amporaha",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6853759,
          lng: 48.3339796,
        },
        _id: "64f75285ef8f606073e9c30d",
        province: "antsiranana",
        region: "diana",
        district: "ambanja",
        commune: "ankatafa",
        fokontany: "mahalony",
        __v: 0,
      },
      {
        coords: {
          lat: -14.900278,
          lng: 50.2733936,
        },
        _id: "64f75285ef8f606073e9c30e",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antalaha ambonivohitra",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8309824,
          lng: 50.2502016,
        },
        _id: "64f75285ef8f606073e9c30f",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antalaha ambonivohitra",
        fokontany: "ambatofisaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.847968,
          lng: 50.2398968,
        },
        _id: "64f75285ef8f606073e9c310",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antalaha ambonivohitra",
        fokontany: "tanambaon'ampano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8723789,
          lng: 50.2192924,
        },
        _id: "64f75285ef8f606073e9c311",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antalaha ambonivohitra",
        fokontany: "ambodibonara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8808876,
          lng: 50.2605081,
        },
        _id: "64f75285ef8f606073e9c312",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antalaha ambonivohitra",
        fokontany: "ambatomitraka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8933689,
          lng: 50.263085,
        },
        _id: "64f75285ef8f606073e9c313",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antalaha ambonivohitra",
        fokontany: "ambondrona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8989128,
          lng: 50.2089929,
        },
        _id: "64f75285ef8f606073e9c314",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antalaha ambonivohitra",
        fokontany: "antsirandambo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9057897,
          lng: 50.274038,
        },
        _id: "64f75285ef8f606073e9c315",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antalaha ambonivohitra",
        fokontany: "ambatoratsy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9286917,
          lng: 50.2605081,
        },
        _id: "64f75285ef8f606073e9c316",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antalaha ambonivohitra",
        fokontany: "anteviala",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9275814,
          lng: 50.2089929,
        },
        _id: "64f75285ef8f606073e9c317",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antalaha ambonivohitra",
        fokontany: "antsahamanenona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9118343,
          lng: 50.2772598,
        },
        _id: "64f75285ef8f606073e9c318",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antalaha ambonivohitra",
        fokontany: "antsonasona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9071884,
          lng: 50.28370409999999,
        },
        _id: "64f75285ef8f606073e9c319",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antalaha ambonivohitra",
        fokontany: "ankoalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9226,
          lng: 50.2888599,
        },
        _id: "64f75285ef8f606073e9c31a",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antalaha ambonivohitra",
        fokontany: "maherifody",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9472601,
          lng: 50.234745,
        },
        _id: "64f75285ef8f606073e9c31b",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antalaha ambonivohitra",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9616569,
          lng: 50.2811263,
        },
        _id: "64f75285ef8f606073e9c31c",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antalaha ambonivohitra",
        fokontany: "ambodikakazo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9776375,
          lng: 50.3120667,
        },
        _id: "64f75285ef8f606073e9c31d",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antalaha ambonivohitra",
        fokontany: "anjiamangotroka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.75,
          lng: 50.216667,
        },
        _id: "64f75285ef8f606073e9c31e",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampahana",
        fokontany: "ampahana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6465524,
          lng: 50.188399,
        },
        _id: "64f75285ef8f606073e9c31f",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampahana",
        fokontany: "andranotsara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6688418,
          lng: 50.1575212,
        },
        _id: "64f75285ef8f606073e9c320",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampahana",
        fokontany: "ampobe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6772544,
          lng: 50.19869509999999,
        },
        _id: "64f75285ef8f606073e9c321",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampahana",
        fokontany: "andrapengy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7058644,
          lng: 50.19869509999999,
        },
        _id: "64f75285ef8f606073e9c322",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampahana",
        fokontany: "maromokotra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7244369,
          lng: 50.1729581,
        },
        _id: "64f75285ef8f606073e9c323",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampahana",
        fokontany: "bekona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.733333,
          lng: 50.116667,
        },
        _id: "64f75285ef8f606073e9c324",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampahana",
        fokontany: "ambodimanga farimay",
        __v: 0,
      },
      {
        coords: {
          lat: -14.743812,
          lng: 50.0932428,
        },
        _id: "64f75285ef8f606073e9c325",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampahana",
        fokontany: "androhavitra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7344817,
          lng: 50.19869509999999,
        },
        _id: "64f75285ef8f606073e9c326",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampahana",
        fokontany: "antapolo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7808525,
          lng: 50.2270183,
        },
        _id: "64f75285ef8f606073e9c327",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampahana",
        fokontany: "antseranambidy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7980905,
          lng: 50.2295937,
        },
        _id: "64f75285ef8f606073e9c328",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampahana",
        fokontany: "ampotatra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7625904,
          lng: 50.1729581,
        },
        _id: "64f75285ef8f606073e9c329",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampahana",
        fokontany: "andamasina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0823887,
          lng: 50.2862819,
        },
        _id: "64f75285ef8f606073e9c32a",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampohibe",
        fokontany: "ampohibe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0007818,
          lng: 50.3198042,
        },
        _id: "64f75285ef8f606073e9c32b",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampohibe",
        fokontany: "antsirabato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9999336,
          lng: 50.2811263,
        },
        _id: "64f75285ef8f606073e9c32c",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampohibe",
        fokontany: "ambodivoangibe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0092863,
          lng: 50.3146457,
        },
        _id: "64f75285ef8f606073e9c32d",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampohibe",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0235747,
          lng: 50.2682391,
        },
        _id: "64f75285ef8f606073e9c32e",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampohibe",
        fokontany: "tanambao ambolo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0215064,
          lng: 50.30433009999999,
        },
        _id: "64f75285ef8f606073e9c32f",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampohibe",
        fokontany: "anjarina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0372288,
          lng: 50.32238359999999,
        },
        _id: "64f75285ef8f606073e9c330",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampohibe",
        fokontany: "ambodipont",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0339295,
          lng: 50.2605081,
        },
        _id: "64f75285ef8f606073e9c331",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampohibe",
        fokontany: "ambolo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0510986,
          lng: 50.3430226,
        },
        _id: "64f75285ef8f606073e9c332",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampohibe",
        fokontany: "sahantaha",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0496535,
          lng: 50.2785486,
        },
        _id: "64f75285ef8f606073e9c333",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampohibe",
        fokontany: "namohana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0705515,
          lng: 50.3559256,
        },
        _id: "64f75285ef8f606073e9c334",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampohibe",
        fokontany: "andongozabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.072221,
          lng: 50.2605081,
        },
        _id: "64f75285ef8f606073e9c335",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampohibe",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0849286,
          lng: 50.3559256,
        },
        _id: "64f75285ef8f606073e9c336",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampohibe",
        fokontany: "ankiakantely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0873621,
          lng: 50.252778,
        },
        _id: "64f75285ef8f606073e9c337",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampohibe",
        fokontany: "ambodibaro",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1003458,
          lng: 50.234745,
        },
        _id: "64f75285ef8f606073e9c338",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampohibe",
        fokontany: "tanambao mahatera",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1105249,
          lng: 50.2605081,
        },
        _id: "64f75285ef8f606073e9c339",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampohibe",
        fokontany: "ankiakahely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.102731,
          lng: 50.3378622,
        },
        _id: "64f75285ef8f606073e9c33a",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampohibe",
        fokontany: "marofinaritra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1265758,
          lng: 50.291438,
        },
        _id: "64f75285ef8f606073e9c33b",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampohibe",
        fokontany: "ampatakana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1113395,
          lng: 50.3791577,
        },
        _id: "64f75285ef8f606073e9c33c",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampohibe",
        fokontany: "andasibe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9886491,
          lng: 50.20384379999999,
        },
        _id: "64f75285ef8f606073e9c33d",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antombana",
        fokontany: "antombana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9855129,
          lng: 50.234745,
        },
        _id: "64f75285ef8f606073e9c33e",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antombana",
        fokontany: "ampatakamanitra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9595124,
          lng: 50.0983826,
        },
        _id: "64f75285ef8f606073e9c33f",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antombana",
        fokontany: "andongozabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9647711,
          lng: 50.0778262,
        },
        _id: "64f75285ef8f606073e9c340",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antombana",
        fokontany: "andamboarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9535482,
          lng: 50.1729581,
        },
        _id: "64f75285ef8f606073e9c341",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antombana",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9888165,
          lng: 50.0444369,
        },
        _id: "64f75285ef8f606073e9c342",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antombana",
        fokontany: "andavenoko",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0261222,
          lng: 50.2115676,
        },
        _id: "64f75285ef8f606073e9c343",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antombana",
        fokontany: "antserasera",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0019202,
          lng: 50.19869509999999,
        },
        _id: "64f75285ef8f606073e9c344",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antombana",
        fokontany: "antsahanandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9789057,
          lng: 50.11123389999999,
        },
        _id: "64f75285ef8f606073e9c345",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antombana",
        fokontany: "ambarabaha",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9930453,
          lng: 50.1446601,
        },
        _id: "64f75285ef8f606073e9c346",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antombana",
        fokontany: "lavatsiraka",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0064095,
          lng: 50.1858252,
        },
        _id: "64f75285ef8f606073e9c347",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antombana",
        fokontany: "ankazoarina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.00922,
          lng: 50.140171,
        },
        _id: "64f75285ef8f606073e9c348",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antombana",
        fokontany: "maromandia",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0429154,
          lng: 50.234745,
        },
        _id: "64f75285ef8f606073e9c349",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antombana",
        fokontany: "marolambo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0112654,
          lng: 50.1060931,
        },
        _id: "64f75285ef8f606073e9c34a",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antombana",
        fokontany: "andrikiriky",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0147885,
          lng: 50.1343732,
        },
        _id: "64f75285ef8f606073e9c34b",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antombana",
        fokontany: "marovany",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0244595,
          lng: 50.18067809999999,
        },
        _id: "64f75285ef8f606073e9c34c",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antombana",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0401758,
          lng: 50.19869509999999,
        },
        _id: "64f75285ef8f606073e9c34d",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antombana",
        fokontany: "antohodrano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0432871,
          lng: 50.1678121,
        },
        _id: "64f75285ef8f606073e9c34e",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antombana",
        fokontany: "fotsialanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8102703,
          lng: 50.0803953,
        },
        _id: "64f75285ef8f606073e9c34f",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antsahanoro",
        fokontany: "antsahanoro",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7795495,
          lng: 50.07011929999999,
        },
        _id: "64f75285ef8f606073e9c350",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antsahanoro",
        fokontany: "ampanisihana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8065654,
          lng: 50.085534,
        },
        _id: "64f75285ef8f606073e9c351",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antsahanoro",
        fokontany: "malotrandro",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8203511,
          lng: 50.1060931,
        },
        _id: "64f75285ef8f606073e9c352",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antsahanoro",
        fokontany: "ampohafana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8235092,
          lng: 50.0752571,
        },
        _id: "64f75285ef8f606073e9c353",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antsahanoro",
        fokontany: "ambohimarina II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8269966,
          lng: 50.1498042,
        },
        _id: "64f75285ef8f606073e9c354",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antsahanoro",
        fokontany: "antsorolava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8246193,
          lng: 50.2192924,
        },
        _id: "64f75285ef8f606073e9c355",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antsahanoro",
        fokontany: "androranga",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8087091,
          lng: 50.188399,
        },
        _id: "64f75285ef8f606073e9c356",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antsahanoro",
        fokontany: "marokosa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8140113,
          lng: 50.1678121,
        },
        _id: "64f75285ef8f606073e9c357",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antsahanoro",
        fokontany: "ampombolava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8563785,
          lng: 50.0958127,
        },
        _id: "64f75285ef8f606073e9c358",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antsahanoro",
        fokontany: "antsahaniatina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8503534,
          lng: 50.2167174,
        },
        _id: "64f75285ef8f606073e9c359",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antsahanoro",
        fokontany: "valambanina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9332978,
          lng: 50.1215169,
        },
        _id: "64f75285ef8f606073e9c35a",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antananambo",
        fokontany: "antananambo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8468971,
          lng: 50.188399,
        },
        _id: "64f75285ef8f606073e9c35b",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antananambo",
        fokontany: "marofinaritra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.844501,
          lng: 50.16523919999999,
        },
        _id: "64f75285ef8f606073e9c35c",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antananambo",
        fokontany: "tanambao manaribasy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.872313,
          lng: 50.1266591,
        },
        _id: "64f75285ef8f606073e9c35d",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antananambo",
        fokontany: "ambodigavo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8659958,
          lng: 50.188399,
        },
        _id: "64f75285ef8f606073e9c35e",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antananambo",
        fokontany: "tsaratananan'ambolobe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8775925,
          lng: 50.1060931,
        },
        _id: "64f75285ef8f606073e9c35f",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antananambo",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8829673,
          lng: 50.17810470000001,
        },
        _id: "64f75285ef8f606073e9c360",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antananambo",
        fokontany: "analamazava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8785984,
          lng: 50.064982,
        },
        _id: "64f75285ef8f606073e9c361",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antananambo",
        fokontany: "virembina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8999368,
          lng: 50.1678121,
        },
        _id: "64f75285ef8f606073e9c362",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antananambo",
        fokontany: "ambolobe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9169043,
          lng: 50.1575212,
        },
        _id: "64f75285ef8f606073e9c363",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antananambo",
        fokontany: "antohotokana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.90416,
          lng: 50.121159,
        },
        _id: "64f75285ef8f606073e9c364",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antananambo",
        fokontany: "ambodihintsina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9029404,
          lng: 50.0444369,
        },
        _id: "64f75285ef8f606073e9c365",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antananambo",
        fokontany: "nosibe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9136313,
          lng: 50.0958127,
        },
        _id: "64f75285ef8f606073e9c366",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antananambo",
        fokontany: "antsahamanara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9617356,
          lng: 50.0290326,
        },
        _id: "64f75285ef8f606073e9c367",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antananambo",
        fokontany: "antanantsara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9449874,
          lng: 50.1318017,
        },
        _id: "64f75285ef8f606073e9c368",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antananambo",
        fokontany: "antetezanintsina",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1774841,
          lng: 50.1009527,
        },
        _id: "64f75285ef8f606073e9c369",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "marofinaritra",
        fokontany: "marofinaritra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0269731,
          lng: 50.124088,
        },
        _id: "64f75285ef8f606073e9c36a",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "marofinaritra",
        fokontany: "ambatofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0146898,
          lng: 50.0084998,
        },
        _id: "64f75285ef8f606073e9c36b",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "marofinaritra",
        fokontany: "ambinanitelo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0356215,
          lng: 50.085534,
        },
        _id: "64f75285ef8f606073e9c36c",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "marofinaritra",
        fokontany: "ankorefo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.060257,
          lng: 49.998236,
        },
        _id: "64f75285ef8f606073e9c36d",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "marofinaritra",
        fokontany: "marofototra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.065511,
          lng: 50.13694470000001,
        },
        _id: "64f75285ef8f606073e9c36e",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "marofinaritra",
        fokontany: "tsarafanahy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.0874048,
          lng: 50.1729581,
        },
        _id: "64f75285ef8f606073e9c36f",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "marofinaritra",
        fokontany: "marovongo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1016007,
          lng: 50.1266591,
        },
        _id: "64f75285ef8f606073e9c370",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "marofinaritra",
        fokontany: "analampotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1154882,
          lng: 50.1472321,
        },
        _id: "64f75285ef8f606073e9c371",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "marofinaritra",
        fokontany: "antsambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1231642,
          lng: 50.07011929999999,
        },
        _id: "64f75285ef8f606073e9c372",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "marofinaritra",
        fokontany: "ambalarongana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1574572,
          lng: 49.91618860000001,
        },
        _id: "64f75285ef8f606073e9c373",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "marofinaritra",
        fokontany: "antsahantitra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1552924,
          lng: 50.1318017,
        },
        _id: "64f75285ef8f606073e9c374",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "marofinaritra",
        fokontany: "antakotako",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1935696,
          lng: 50.1318017,
        },
        _id: "64f75285ef8f606073e9c375",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "marofinaritra",
        fokontany: "androhofary",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2088938,
          lng: 50.0572767,
        },
        _id: "64f75285ef8f606073e9c376",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "marofinaritra",
        fokontany: "andrakadilana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2362139,
          lng: 50.15237639999999,
        },
        _id: "64f75285ef8f606073e9c377",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "marofinaritra",
        fokontany: "manakambahiny",
        __v: 0,
      },
      {
        coords: {
          lat: -15.25985,
          lng: 50.047539,
        },
        _id: "64f75285ef8f606073e9c378",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "marofinaritra",
        fokontany: "ampokafo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7901159,
          lng: 50.0290326,
        },
        _id: "64f75285ef8f606073e9c379",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "sarahandrano",
        fokontany: "sarahandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7239706,
          lng: 50.0547085,
        },
        _id: "64f75285ef8f606073e9c37a",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "sarahandrano",
        fokontany: "manantenina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.755715,
          lng: 50.0238988,
        },
        _id: "64f75285ef8f606073e9c37b",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "sarahandrano",
        fokontany: "mahavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8009412,
          lng: 50.0008018,
        },
        _id: "64f75285ef8f606073e9c37c",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "sarahandrano",
        fokontany: "andranofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8531265,
          lng: 50.0341669,
        },
        _id: "64f75285ef8f606073e9c37d",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "sarahandrano",
        fokontany: "andranomananika",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8501414,
          lng: 49.9854087,
        },
        _id: "64f75285ef8f606073e9c37e",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "sarahandrano",
        fokontany: "ambohimarina I",
        __v: 0,
      },
      {
        coords: {
          lat: -14.864434,
          lng: 49.9854087,
        },
        _id: "64f75285ef8f606073e9c37f",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "sarahandrano",
        fokontany: "mahasoa anivorano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9433415,
          lng: 49.8956943,
        },
        _id: "64f75285ef8f606073e9c380",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "andampy",
        fokontany: "andampy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8901068,
          lng: 49.9828436,
        },
        _id: "64f75285ef8f606073e9c381",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "andampy",
        fokontany: "andriandava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8825091,
          lng: 49.9008172,
        },
        _id: "64f75285ef8f606073e9c382",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "andampy",
        fokontany: "antanambaovao",
        __v: 0,
      },
      {
        coords: {
          lat: -14.883333,
          lng: 49.95,
        },
        _id: "64f75285ef8f606073e9c383",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "andampy",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9340758,
          lng: 49.9879739,
        },
        _id: "64f75285ef8f606073e9c384",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "andampy",
        fokontany: "antanambao antsakondrevo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9083279,
          lng: 49.86496589999999,
        },
        _id: "64f75285ef8f606073e9c385",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "andampy",
        fokontany: "antsabeorana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9961974,
          lng: 49.87520689999999,
        },
        _id: "64f75285ef8f606073e9c386",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "andampy",
        fokontany: "ankijanibe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.16406,
          lng: 50.419189,
        },
        _id: "64f75285ef8f606073e9c387",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambalabe",
        fokontany: "ambalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1453911,
          lng: 50.3585065,
        },
        _id: "64f75285ef8f606073e9c388",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambalabe",
        fokontany: "ambodiatafana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1596472,
          lng: 50.3120667,
        },
        _id: "64f75285ef8f606073e9c389",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambalabe",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1553252,
          lng: 50.291438,
        },
        _id: "64f75285ef8f606073e9c38a",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambalabe",
        fokontany: "ambodivoaseva",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1643944,
          lng: 50.3920682,
        },
        _id: "64f75285ef8f606073e9c38b",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambalabe",
        fokontany: "belonty",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1704496,
          lng: 50.3636686,
        },
        _id: "64f75285ef8f606073e9c38c",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambalabe",
        fokontany: "tananambo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1757199,
          lng: 50.3430226,
        },
        _id: "64f75285ef8f606073e9c38d",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambalabe",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1754152,
          lng: 50.3301224,
        },
        _id: "64f75285ef8f606073e9c38e",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambalabe",
        fokontany: "ambodivoanio",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1788223,
          lng: 50.3120667,
        },
        _id: "64f75285ef8f606073e9c38f",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambalabe",
        fokontany: "ambodivoafaho",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1915023,
          lng: 50.2811263,
        },
        _id: "64f75285ef8f606073e9c390",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambalabe",
        fokontany: "androhofotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -15.1834074,
          lng: 50.4256481,
        },
        _id: "64f75285ef8f606073e9c391",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambalabe",
        fokontany: "marambo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.207276,
          lng: 50.3791577,
        },
        _id: "64f75285ef8f606073e9c392",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambalabe",
        fokontany: "tanandava II",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2419559,
          lng: 50.3843215,
        },
        _id: "64f75285ef8f606073e9c393",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambalabe",
        fokontany: "tanambao IV",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6397669,
          lng: 50.1318017,
        },
        _id: "64f75285ef8f606073e9c394",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambinanifaho",
        fokontany: "ambinanifaho",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5768229,
          lng: 50.1266591,
        },
        _id: "64f75285ef8f606073e9c395",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambinanifaho",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6063374,
          lng: 50.17810470000001,
        },
        _id: "64f75285ef8f606073e9c396",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambinanifaho",
        fokontany: "ampanantsovana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6842822,
          lng: 50.07011929999999,
        },
        _id: "64f75285ef8f606073e9c397",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambinanifaho",
        fokontany: "beanamalao",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6530045,
          lng: 50.1266591,
        },
        _id: "64f75285ef8f606073e9c398",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambinanifaho",
        fokontany: "tsarahonenana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2434957,
          lng: 50.4489065,
        },
        _id: "64f75285ef8f606073e9c399",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambohitralanana",
        fokontany: "ambohitralanana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2165373,
          lng: 50.4463218,
        },
        _id: "64f75285ef8f606073e9c39a",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambohitralanana",
        fokontany: "anjahamarina I",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2735595,
          lng: 50.42048080000001,
        },
        _id: "64f75285ef8f606073e9c39b",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambohitralanana",
        fokontany: "antanambao ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2763514,
          lng: 50.4566612,
        },
        _id: "64f75285ef8f606073e9c39c",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambohitralanana",
        fokontany: "ambodirafia",
        __v: 0,
      },
      {
        coords: {
          lat: -15.283333,
          lng: 50.383333,
        },
        _id: "64f75285ef8f606073e9c39d",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambohitralanana",
        fokontany: "sahafary",
        __v: 0,
      },
      {
        coords: {
          lat: -15.2828053,
          lng: 50.3275427,
        },
        _id: "64f75285ef8f606073e9c39e",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambohitralanana",
        fokontany: "tanandavahely",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3163447,
          lng: 50.4411527,
        },
        _id: "64f75285ef8f606073e9c39f",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambohitralanana",
        fokontany: "sahanjahana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3895511,
          lng: 50.4463218,
        },
        _id: "64f75285ef8f606073e9c3a0",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambohitralanana",
        fokontany: "maharavo",
        __v: 0,
      },
      {
        coords: {
          lat: -15.3489027,
          lng: 50.4359841,
        },
        _id: "64f75285ef8f606073e9c3a1",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambohitralanana",
        fokontany: "ambohimahery",
        __v: 0,
      },
      {
        coords: {
          lat: -15.46305,
          lng: 50.433689,
        },
        _id: "64f75285ef8f606073e9c3a2",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambohitralanana",
        fokontany: "ratsianarana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4999393,
          lng: 50.39981580000001,
        },
        _id: "64f75285ef8f606073e9c3a3",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ambohitralanana",
        fokontany: "andranoampaha",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6029382,
          lng: 49.998236,
        },
        _id: "64f75285ef8f606073e9c3a4",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "lanjarivo",
        fokontany: "lanjarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5468602,
          lng: 50.0495725,
        },
        _id: "64f75285ef8f606073e9c3a5",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "lanjarivo",
        fokontany: "ambato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5723193,
          lng: 49.9879739,
        },
        _id: "64f75285ef8f606073e9c3a6",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "lanjarivo",
        fokontany: "sarahandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5790982,
          lng: 50.0444369,
        },
        _id: "64f75285ef8f606073e9c3a7",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "lanjarivo",
        fokontany: "vohitrarivo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.62032,
          lng: 50.07559999999999,
        },
        _id: "64f75285ef8f606073e9c3a8",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "lanjarivo",
        fokontany: "ambodilalona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6055351,
          lng: 50.0341669,
        },
        _id: "64f75285ef8f606073e9c3a9",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "lanjarivo",
        fokontany: "ambohimandroso",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6173965,
          lng: 50.0572767,
        },
        _id: "64f75285ef8f606073e9c3aa",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "lanjarivo",
        fokontany: "mangatsahatsa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6206062,
          lng: 50.0264657,
        },
        _id: "64f75285ef8f606073e9c3ab",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "lanjarivo",
        fokontany: "tanambao analanantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6276533,
          lng: 50.0958127,
        },
        _id: "64f75285ef8f606073e9c3ac",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "lanjarivo",
        fokontany: "vohitsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6414857,
          lng: 50.0238988,
        },
        _id: "64f75285ef8f606073e9c3ad",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "lanjarivo",
        fokontany: "analanantsoa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6461978,
          lng: 50.07011929999999,
        },
        _id: "64f75285ef8f606073e9c3ae",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "lanjarivo",
        fokontany: "mahadera",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6483825,
          lng: 49.9879739,
        },
        _id: "64f75285ef8f606073e9c3af",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "lanjarivo",
        fokontany: "soamitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6639317,
          lng: 50.0521405,
        },
        _id: "64f75285ef8f606073e9c3b0",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "lanjarivo",
        fokontany: "andampibe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6061391,
          lng: 49.9674551,
        },
        _id: "64f75285ef8f606073e9c3b1",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "lanjarivo",
        fokontany: "ambohimitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7382018,
          lng: 49.91618860000001,
        },
        _id: "64f75285ef8f606073e9c3b2",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antsambalahy",
        fokontany: "antsambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6621376,
          lng: 49.91618860000001,
        },
        _id: "64f75285ef8f606073e9c3b3",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antsambalahy",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7160263,
          lng: 49.9469432,
        },
        _id: "64f75285ef8f606073e9c3b4",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antsambalahy",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.811192,
          lng: 49.9469432,
        },
        _id: "64f75285ef8f606073e9c3b5",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antsambalahy",
        fokontany: "betavilona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7968462,
          lng: 49.9008172,
        },
        _id: "64f75285ef8f606073e9c3b6",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antsambalahy",
        fokontany: "ambinanin'ankalampona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8422921,
          lng: 49.8905718,
        },
        _id: "64f75285ef8f606073e9c3b7",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antsambalahy",
        fokontany: "antsirabe ankalampona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.811756,
          lng: 49.9725841,
        },
        _id: "64f75285ef8f606073e9c3b8",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "antsambalahy",
        fokontany: "ambarabaha",
        __v: 0,
      },
      {
        coords: {
          lat: -15.87834,
          lng: 50.2672809,
        },
        _id: "64f75285ef8f606073e9c3b9",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "vinanivao",
        fokontany: "vinanivao",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7768453,
          lng: 50.2553546,
        },
        _id: "64f75285ef8f606073e9c3ba",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "vinanivao",
        fokontany: "anovandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9219359,
          lng: 50.2141425,
        },
        _id: "64f75285ef8f606073e9c3bb",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "vinanivao",
        fokontany: "beankora",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7617847,
          lng: 50.1420882,
        },
        _id: "64f75285ef8f606073e9c3bc",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "vinanivao",
        fokontany: "fampotabe",
        __v: 0,
      },
      {
        coords: {
          lat: -15.9835467,
          lng: 50.15928,
        },
        _id: "64f75285ef8f606073e9c3bd",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "vinanivao",
        fokontany: "masoala",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6805,
          lng: 50.34983099999999,
        },
        _id: "64f75285ef8f606073e9c3be",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampanavoana",
        fokontany: "ampanavoana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.765091,
          lng: 50.3120667,
        },
        _id: "64f75285ef8f606073e9c3bf",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampanavoana",
        fokontany: "anjanazana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.752856,
          lng: 50.3056195,
        },
        _id: "64f75285ef8f606073e9c3c0",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampanavoana",
        fokontany: "ambodisatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7361949,
          lng: 50.3120667,
        },
        _id: "64f75285ef8f606073e9c3c1",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampanavoana",
        fokontany: "ambodipont",
        __v: 0,
      },
      {
        coords: {
          lat: -15.7036294,
          lng: 50.3507641,
        },
        _id: "64f75285ef8f606073e9c3c2",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampanavoana",
        fokontany: "marofinaritra",
        __v: 0,
      },
      {
        coords: {
          lat: -15.5459836,
          lng: 50.1215169,
        },
        _id: "64f75285ef8f606073e9c3c3",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampanavoana",
        fokontany: "antanamahalana",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6050996,
          lng: 50.1729581,
        },
        _id: "64f75285ef8f606073e9c3c4",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampanavoana",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6347396,
          lng: 50.3327022,
        },
        _id: "64f75285ef8f606073e9c3c5",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampanavoana",
        fokontany: "ambodivarotro",
        __v: 0,
      },
      {
        coords: {
          lat: -15.6258493,
          lng: 50.3585065,
        },
        _id: "64f75285ef8f606073e9c3c6",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampanavoana",
        fokontany: "ampanio",
        __v: 0,
      },
      {
        coords: {
          lat: -15.4721039,
          lng: 50.2244429,
        },
        _id: "64f75285ef8f606073e9c3c7",
        province: "antsiranana",
        region: "sava",
        district: "antalaha",
        commune: "ampanavoana",
        fokontany: "fampotakely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2593691,
          lng: 50.1613801,
        },
        _id: "64f75285ef8f606073e9c3c8",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "sambava cu",
        fokontany: "sambava centre",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2395888,
          lng: 50.1395164,
        },
        _id: "64f75285ef8f606073e9c3c9",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "sambava cu",
        fokontany: "ampisasahanala",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2441466,
          lng: 50.1266591,
        },
        _id: "64f75285ef8f606073e9c3ca",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "sambava cu",
        fokontany: "ambohitrakongona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.259693,
          lng: 50.1510903,
        },
        _id: "64f75285ef8f606073e9c3cb",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "sambava cu",
        fokontany: "antaimby",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2605288,
          lng: 50.1433741,
        },
        _id: "64f75285ef8f606073e9c3cc",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "sambava cu",
        fokontany: "analamandrorofo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2613642,
          lng: 50.1356589,
        },
        _id: "64f75285ef8f606073e9c3cd",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "sambava cu",
        fokontany: "antanifotsy I",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2639276,
          lng: 50.1485181,
        },
        _id: "64f75285ef8f606073e9c3ce",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "sambava cu",
        fokontany: "antsirabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2689971,
          lng: 50.1382305,
        },
        _id: "64f75285ef8f606073e9c3cf",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "sambava cu",
        fokontany: "antanifotsy II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1942957,
          lng: 50.0906731,
        },
        _id: "64f75285ef8f606073e9c3d0",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "sambava cu",
        fokontany: "ambariomihambana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2400484,
          lng: 50.1060931,
        },
        _id: "64f75285ef8f606073e9c3d1",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "sambava cu",
        fokontany: "menagisy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2682378,
          lng: 50.1671688,
        },
        _id: "64f75285ef8f606073e9c3d2",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "sambava cu",
        fokontany: "ambodisatrana I",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2707616,
          lng: 50.1292303,
        },
        _id: "64f75285ef8f606073e9c3d3",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "sambava cu",
        fokontany: "besopaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3018836,
          lng: 50.17810470000001,
        },
        _id: "64f75285ef8f606073e9c3d4",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "sambava cu",
        fokontany: "ambodisatrana II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2769515,
          lng: 50.130516,
        },
        _id: "64f75285ef8f606073e9c3d5",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "sambava cu",
        fokontany: "ambatofitatra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2867975,
          lng: 50.1858252,
        },
        _id: "64f75285ef8f606073e9c3d6",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "sambava cu",
        fokontany: "ampandrozonana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2891606,
          lng: 50.0906731,
        },
        _id: "64f75285ef8f606073e9c3d7",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "sambava cu",
        fokontany: "soavinandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2920759,
          lng: 50.0341669,
        },
        _id: "64f75285ef8f606073e9c3d8",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambohimalaza",
        fokontany: "ambohimalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2500344,
          lng: 50.0136323,
        },
        _id: "64f75285ef8f606073e9c3d9",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambohimalaza",
        fokontany: "antetezandravina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2654813,
          lng: 50.0315997,
        },
        _id: "64f75285ef8f606073e9c3da",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambohimalaza",
        fokontany: "ampangadiambato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.271879,
          lng: 50.0161988,
        },
        _id: "64f75285ef8f606073e9c3db",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambohimalaza",
        fokontany: "ambariotelo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2722786,
          lng: 50.0418692,
        },
        _id: "64f75285ef8f606073e9c3dc",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambohimalaza",
        fokontany: "antsambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2753624,
          lng: 50.0572767,
        },
        _id: "64f75285ef8f606073e9c3dd",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambohimalaza",
        fokontany: "antsahorana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2741173,
          lng: 50.0688349,
        },
        _id: "64f75285ef8f606073e9c3de",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambohimalaza",
        fokontany: "ankorabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3151702,
          lng: 50.0547085,
        },
        _id: "64f75285ef8f606073e9c3df",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambohimalaza",
        fokontany: "ambohitsivalana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.333735,
          lng: 50.0290326,
        },
        _id: "64f75285ef8f606073e9c3e0",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambohimalaza",
        fokontany: "antsahalalina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4365186,
          lng: 50.156235,
        },
        _id: "64f75285ef8f606073e9c3e1",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "farahalana",
        fokontany: "farahalana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3514593,
          lng: 50.188399,
        },
        _id: "64f75285ef8f606073e9c3e2",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "farahalana",
        fokontany: "antohomaro",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3204939,
          lng: 50.15237639999999,
        },
        _id: "64f75285ef8f606073e9c3e3",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "farahalana",
        fokontany: "ambia",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3948674,
          lng: 50.1678121,
        },
        _id: "64f75285ef8f606073e9c3e4",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "farahalana",
        fokontany: "benavony",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3886653,
          lng: 50.13694470000001,
        },
        _id: "64f75285ef8f606073e9c3e5",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "farahalana",
        fokontany: "masovariaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4044643,
          lng: 50.11508980000001,
        },
        _id: "64f75285ef8f606073e9c3e6",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "farahalana",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4123946,
          lng: 50.0778262,
        },
        _id: "64f75285ef8f606073e9c3e7",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "farahalana",
        fokontany: "antsirabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4248302,
          lng: 50.1395164,
        },
        _id: "64f75285ef8f606073e9c3e8",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "farahalana",
        fokontany: "ratsiteza",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3692453,
          lng: 50.11123389999999,
        },
        _id: "64f75285ef8f606073e9c3e9",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "farahalana",
        fokontany: "ampatakana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4225436,
          lng: 50.1163752,
        },
        _id: "64f75285ef8f606073e9c3ea",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "farahalana",
        fokontany: "ankadirano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.415504,
          lng: 50.0932428,
        },
        _id: "64f75285ef8f606073e9c3eb",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "farahalana",
        fokontany: "ambodiala",
        __v: 0,
      },
      {
        coords: {
          lat: -14.413885,
          lng: 50.1678121,
        },
        _id: "64f75285ef8f606073e9c3ec",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "farahalana",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.432906,
          lng: 50.1678121,
        },
        _id: "64f75285ef8f606073e9c3ed",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "farahalana",
        fokontany: "maheva",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4494226,
          lng: 50.1318017,
        },
        _id: "64f75285ef8f606073e9c3ee",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "farahalana",
        fokontany: "ambatomainty",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4441702,
          lng: 50.15880749999999,
        },
        _id: "64f75285ef8f606073e9c3ef",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "farahalana",
        fokontany: "anosy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4709579,
          lng: 50.1678121,
        },
        _id: "64f75285ef8f606073e9c3f0",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "farahalana",
        fokontany: "anosy/antsahaborara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.483333,
          lng: 50.116667,
        },
        _id: "64f75285ef8f606073e9c3f1",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "farahalana",
        fokontany: "antafononana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.502776,
          lng: 50.13694470000001,
        },
        _id: "64f75285ef8f606073e9c3f2",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "farahalana",
        fokontany: "antananambo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5148233,
          lng: 50.1729581,
        },
        _id: "64f75285ef8f606073e9c3f3",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "farahalana",
        fokontany: "amboronala",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5445539,
          lng: 50.1318017,
        },
        _id: "64f75285ef8f606073e9c3f4",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "farahalana",
        fokontany: "andranovato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2170204,
          lng: 50.0559926,
        },
        _id: "64f75285ef8f606073e9c3f5",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "nosiarina",
        fokontany: "nosiarina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2046967,
          lng: 50.0238988,
        },
        _id: "64f75285ef8f606073e9c3f6",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "nosiarina",
        fokontany: "ambodimatsiko",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2117377,
          lng: 49.9879739,
        },
        _id: "64f75285ef8f606073e9c3f7",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "nosiarina",
        fokontany: "bemalamatra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2150056,
          lng: 50.0161988,
        },
        _id: "64f75285ef8f606073e9c3f8",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "nosiarina",
        fokontany: "ambohimitombo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2271698,
          lng: 50.00593370000001,
        },
        _id: "64f75285ef8f606073e9c3f9",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "nosiarina",
        fokontany: "nosivolo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2331292,
          lng: 50.0238988,
        },
        _id: "64f75285ef8f606073e9c3fa",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "nosiarina",
        fokontany: "mahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2318507,
          lng: 50.064982,
        },
        _id: "64f75285ef8f606073e9c3fb",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "nosiarina",
        fokontany: "andampy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.248766,
          lng: 50.0547085,
        },
        _id: "64f75285ef8f606073e9c3fc",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "nosiarina",
        fokontany: "ambolomadinika",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3740989,
          lng: 49.9469432,
        },
        _id: "64f75285ef8f606073e9c3fd",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodivoara",
        fokontany: "ambodivoara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3431963,
          lng: 49.9700196,
        },
        _id: "64f75285ef8f606073e9c3fe",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodivoara",
        fokontany: "ambodivoafaho",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3514285,
          lng: 49.9520705,
        },
        _id: "64f75285ef8f606073e9c3ff",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodivoara",
        fokontany: "ambodihazovola",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3594914,
          lng: 49.9802785,
        },
        _id: "64f75285ef8f606073e9c400",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodivoara",
        fokontany: "mahitsiarongana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3627634,
          lng: 49.9495068,
        },
        _id: "64f75285ef8f606073e9c401",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodivoara",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3668769,
          lng: 49.9405347,
        },
        _id: "64f75285ef8f606073e9c402",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodivoara",
        fokontany: "ambalafary",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3745381,
          lng: 49.9725841,
        },
        _id: "64f75285ef8f606073e9c403",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodivoara",
        fokontany: "ampampamena II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3817301,
          lng: 49.9495068,
        },
        _id: "64f75285ef8f606073e9c404",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodivoara",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3823228,
          lng: 49.9290011,
        },
        _id: "64f75285ef8f606073e9c405",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodivoara",
        fokontany: "andengondroy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.389584,
          lng: 49.9648907,
        },
        _id: "64f75285ef8f606073e9c406",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodivoara",
        fokontany: "ambodivoangy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3965468,
          lng: 49.9290011,
        },
        _id: "64f75285ef8f606073e9c407",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodivoara",
        fokontany: "antsahamanara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4029996,
          lng: 49.9725841,
        },
        _id: "64f75285ef8f606073e9c408",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodivoara",
        fokontany: "antsirabolo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4097334,
          lng: 49.9238758,
        },
        _id: "64f75285ef8f606073e9c409",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodivoara",
        fokontany: "antanetilava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4064816,
          lng: 49.9546343,
        },
        _id: "64f75285ef8f606073e9c40a",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodivoara",
        fokontany: "analanambe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4149297,
          lng: 49.9495068,
        },
        _id: "64f75285ef8f606073e9c40b",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodivoara",
        fokontany: "ambodivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2834294,
          lng: 49.9674551,
        },
        _id: "64f75285ef8f606073e9c40c",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjinjaomby",
        fokontany: "anjinjaomby",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2622142,
          lng: 49.9443797,
        },
        _id: "64f75285ef8f606073e9c40d",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjinjaomby",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2776609,
          lng: 49.9623265,
        },
        _id: "64f75285ef8f606073e9c40e",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjinjaomby",
        fokontany: "ambavala",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2496369,
          lng: 49.9879739,
        },
        _id: "64f75285ef8f606073e9c40f",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjinjaomby",
        fokontany: "ambalamahogo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2764179,
          lng: 50.0033677,
        },
        _id: "64f75285ef8f606073e9c410",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjinjaomby",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -14.29332,
          lng: 49.9931047,
        },
        _id: "64f75285ef8f606073e9c411",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjinjaomby",
        fokontany: "marofototra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.316394,
          lng: 49.9546343,
        },
        _id: "64f75285ef8f606073e9c412",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjinjaomby",
        fokontany: "bevapaza",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3758334,
          lng: 50.0495725,
        },
        _id: "64f75285ef8f606073e9c413",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "morafeno",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3215551,
          lng: 50.0983826,
        },
        _id: "64f75285ef8f606073e9c414",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "morafeno",
        fokontany: "ankelimahavoky",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3424037,
          lng: 50.0958127,
        },
        _id: "64f75285ef8f606073e9c415",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "morafeno",
        fokontany: "ambodihasina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3551999,
          lng: 50.064982,
        },
        _id: "64f75285ef8f606073e9c416",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "morafeno",
        fokontany: "ambodivalotra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3708736,
          lng: 50.0367343,
        },
        _id: "64f75285ef8f606073e9c417",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "morafeno",
        fokontany: "anjahamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3754053,
          lng: 50.0238988,
        },
        _id: "64f75285ef8f606073e9c418",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "morafeno",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3807539,
          lng: 50.0033677,
        },
        _id: "64f75285ef8f606073e9c419",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "morafeno",
        fokontany: "ambalamanasy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.39148,
          lng: 50.086578,
        },
        _id: "64f75285ef8f606073e9c41a",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "morafeno",
        fokontany: "ankazomihogo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4113021,
          lng: 50.0136323,
        },
        _id: "64f75285ef8f606073e9c41b",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "morafeno",
        fokontany: "ambodihazomamy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4042596,
          lng: 49.99053929999999,
        },
        _id: "64f75285ef8f606073e9c41c",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "morafeno",
        fokontany: "ambodiriana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3985349,
          lng: 50.0444369,
        },
        _id: "64f75285ef8f606073e9c41d",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "morafeno",
        fokontany: "ankija",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4196025,
          lng: 50.0547085,
        },
        _id: "64f75285ef8f606073e9c41e",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "morafeno",
        fokontany: "ambodihelana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2373045,
          lng: 49.92643839999999,
        },
        _id: "64f75285ef8f606073e9c41f",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "analamaho",
        fokontany: "analamaho",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2056236,
          lng: 49.8982557,
        },
        _id: "64f75285ef8f606073e9c420",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "analamaho",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2031425,
          lng: 49.92131329999999,
        },
        _id: "64f75285ef8f606073e9c421",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "analamaho",
        fokontany: "andapa II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2247254,
          lng: 49.9700196,
        },
        _id: "64f75285ef8f606073e9c422",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "analamaho",
        fokontany: "antsinjorano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2208466,
          lng: 49.9033788,
        },
        _id: "64f75285ef8f606073e9c423",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "analamaho",
        fokontany: "antsahabelaokabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2632398,
          lng: 49.8905718,
        },
        _id: "64f75285ef8f606073e9c424",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "analamaho",
        fokontany: "anjingo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.240807,
          lng: 49.9085024,
        },
        _id: "64f75285ef8f606073e9c425",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "analamaho",
        fokontany: "antsahabelaokahely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2599501,
          lng: 49.92131329999999,
        },
        _id: "64f75285ef8f606073e9c426",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "analamaho",
        fokontany: "ambakirano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.32146,
          lng: 49.93166,
        },
        _id: "64f75285ef8f606073e9c427",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambohimitsinjo",
        fokontany: "ambohimitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2842396,
          lng: 49.9008172,
        },
        _id: "64f75285ef8f606073e9c428",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambohimitsinjo",
        fokontany: "ambodidivaina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2895818,
          lng: 49.8803281,
        },
        _id: "64f75285ef8f606073e9c429",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambohimitsinjo",
        fokontany: "sarahandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2924805,
          lng: 49.9123454,
        },
        _id: "64f75285ef8f606073e9c42a",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambohimitsinjo",
        fokontany: "ambodisakoana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2817763,
          lng: 49.9238758,
        },
        _id: "64f75285ef8f606073e9c42b",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambohimitsinjo",
        fokontany: "bemanevika",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2943437,
          lng: 49.93925309999999,
        },
        _id: "64f75285ef8f606073e9c42c",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambohimitsinjo",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3017554,
          lng: 49.9290011,
        },
        _id: "64f75285ef8f606073e9c42d",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambohimitsinjo",
        fokontany: "antsahovy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3378096,
          lng: 49.9315639,
        },
        _id: "64f75285ef8f606073e9c42e",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambohimitsinjo",
        fokontany: "tsaravinany",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1177642,
          lng: 50.0393017,
        },
        _id: "64f75285ef8f606073e9c42f",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "anjangoveratra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.011947,
          lng: 50.0547085,
        },
        _id: "64f75285ef8f606073e9c430",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "soatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0237531,
          lng: 50.0906731,
        },
        _id: "64f75285ef8f606073e9c431",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "mananjaran'ifosa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0498021,
          lng: 50.0547085,
        },
        _id: "64f75285ef8f606073e9c432",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "maevatananan'ifosa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0721125,
          lng: 50.0238988,
        },
        _id: "64f75285ef8f606073e9c433",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "tanambao ifosa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0768479,
          lng: 50.0958127,
        },
        _id: "64f75285ef8f606073e9c434",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "ampamintanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0761726,
          lng: 50.0444369,
        },
        _id: "64f75285ef8f606073e9c435",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "ambodisambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0983287,
          lng: 50.07268819999999,
        },
        _id: "64f75285ef8f606073e9c436",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "anjombalava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0959633,
          lng: 50.1086634,
        },
        _id: "64f75285ef8f606073e9c437",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "ankorakabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0976549,
          lng: 50.0932428,
        },
        _id: "64f75285ef8f606073e9c438",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "antanambazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -14.095108,
          lng: 50.0444369,
        },
        _id: "64f75285ef8f606073e9c439",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "ambavala",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1041668,
          lng: 50.0842493,
        },
        _id: "64f75285ef8f606073e9c43a",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "ambodivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.120151,
          lng: 50.0752571,
        },
        _id: "64f75285ef8f606073e9c43b",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "seranampotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1346061,
          lng: 50.0585609,
        },
        _id: "64f75285ef8f606073e9c43c",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "ankiakabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1456982,
          lng: 50.07268819999999,
        },
        _id: "64f75285ef8f606073e9c43d",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "andranomadio",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1499854,
          lng: 50.0405854,
        },
        _id: "64f75285ef8f606073e9c43e",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1593674,
          lng: 50.0341669,
        },
        _id: "64f75285ef8f606073e9c43f",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "ambohibary",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1680949,
          lng: 49.9828436,
        },
        _id: "64f75285ef8f606073e9c440",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "farafangahely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.172926,
          lng: 50.0547085,
        },
        _id: "64f75285ef8f606073e9c441",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "andilambe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1800264,
          lng: 49.9892566,
        },
        _id: "64f75285ef8f606073e9c442",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "anamboafo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.180821,
          lng: 50.011066,
        },
        _id: "64f75285ef8f606073e9c443",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "andavakatsantsa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1869556,
          lng: 50.0418692,
        },
        _id: "64f75285ef8f606073e9c444",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "antsapanamahazo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.184987,
          lng: 49.9725841,
        },
        _id: "64f75285ef8f606073e9c445",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "marolamba",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1923405,
          lng: 50.021332,
        },
        _id: "64f75285ef8f606073e9c446",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2018773,
          lng: 49.9623265,
        },
        _id: "64f75285ef8f606073e9c447",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjangoveratra",
        fokontany: "tanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3880116,
          lng: 49.87520689999999,
        },
        _id: "64f75285ef8f606073e9c448",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andratamarina",
        fokontany: "andratamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3147215,
          lng: 49.9110643,
        },
        _id: "64f75285ef8f606073e9c449",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andratamarina",
        fokontany: "ampampamena I",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3439642,
          lng: 49.9033788,
        },
        _id: "64f75285ef8f606073e9c44a",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andratamarina",
        fokontany: "sahamazava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3369364,
          lng: 49.8803281,
        },
        _id: "64f75285ef8f606073e9c44b",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andratamarina",
        fokontany: "soahitra I",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3572535,
          lng: 49.9046597,
        },
        _id: "64f75285ef8f606073e9c44c",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andratamarina",
        fokontany: "ambalajirofo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.364176,
          lng: 49.92131329999999,
        },
        _id: "64f75285ef8f606073e9c44d",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andratamarina",
        fokontany: "befotatra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.363325,
          lng: 49.8995364,
        },
        _id: "64f75285ef8f606073e9c44e",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andratamarina",
        fokontany: "betoho",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3653591,
          lng: 49.8803281,
        },
        _id: "64f75285ef8f606073e9c44f",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andratamarina",
        fokontany: "analampontsy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3937893,
          lng: 49.8803281,
        },
        _id: "64f75285ef8f606073e9c450",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andratamarina",
        fokontany: "andatsakala",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4061574,
          lng: 49.8828889,
        },
        _id: "64f75285ef8f606073e9c451",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andratamarina",
        fokontany: "ampandrana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4201449,
          lng: 49.8700862,
        },
        _id: "64f75285ef8f606073e9c452",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andratamarina",
        fokontany: "ambodivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2060464,
          lng: 49.86496589999999,
        },
        _id: "64f75285ef8f606073e9c453",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marogaona",
        fokontany: "marogaona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1585618,
          lng: 49.8521671,
        },
        _id: "64f75285ef8f606073e9c454",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marogaona",
        fokontany: "antsahabe II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1795225,
          lng: 49.8624059,
        },
        _id: "64f75285ef8f606073e9c455",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marogaona",
        fokontany: "ampasimandroatra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1747501,
          lng: 49.92131329999999,
        },
        _id: "64f75285ef8f606073e9c456",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marogaona",
        fokontany: "ambodivapaza",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1977777,
          lng: 49.9418164,
        },
        _id: "64f75285ef8f606073e9c457",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marogaona",
        fokontany: "ambodimanga angaika",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2138582,
          lng: 49.8803281,
        },
        _id: "64f75285ef8f606073e9c458",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marogaona",
        fokontany: "ambodiazomamy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2076943,
          lng: 49.8496077,
        },
        _id: "64f75285ef8f606073e9c459",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marogaona",
        fokontany: "antsakia",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2381316,
          lng: 49.85984610000001,
        },
        _id: "64f75285ef8f606073e9c45a",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marogaona",
        fokontany: "ambodiampana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2665217,
          lng: 49.85984610000001,
        },
        _id: "64f75285ef8f606073e9c45b",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marogaona",
        fokontany: "antsahabetanimena",
        __v: 0,
      },
      {
        coords: {
          lat: -14.46557,
          lng: 50.03232999999999,
        },
        _id: "64f75285ef8f606073e9c45c",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marojala",
        fokontany: "marojala",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4377068,
          lng: 50.0033677,
        },
        _id: "64f75285ef8f606073e9c45d",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marojala",
        fokontany: "ankitsaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4736077,
          lng: 49.9931047,
        },
        _id: "64f75285ef8f606073e9c45e",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marojala",
        fokontany: "ampohafana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5004831,
          lng: 50.0084998,
        },
        _id: "64f75285ef8f606073e9c45f",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marojala",
        fokontany: "mandrangotra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4819441,
          lng: 50.0341669,
        },
        _id: "64f75285ef8f606073e9c460",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marojala",
        fokontany: "antafiamatso",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4604982,
          lng: 50.0572767,
        },
        _id: "64f75285ef8f606073e9c461",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marojala",
        fokontany: "androtro",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4427553,
          lng: 50.0752571,
        },
        _id: "64f75285ef8f606073e9c462",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marojala",
        fokontany: "ofaina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4617621,
          lng: 50.0752571,
        },
        _id: "64f75285ef8f606073e9c463",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marojala",
        fokontany: "ambohitrosy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4584927,
          lng: 50.1060931,
        },
        _id: "64f75285ef8f606073e9c464",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marojala",
        fokontany: "anamborano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4826281,
          lng: 50.07268819999999,
        },
        _id: "64f75285ef8f606073e9c465",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marojala",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4923617,
          lng: 50.085534,
        },
        _id: "64f75285ef8f606073e9c466",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marojala",
        fokontany: "antalakimaso",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5188022,
          lng: 50.0752571,
        },
        _id: "64f75285ef8f606073e9c467",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marojala",
        fokontany: "andakoro",
        __v: 0,
      },
      {
        coords: {
          lat: -14.516667,
          lng: 50.05,
        },
        _id: "64f75285ef8f606073e9c468",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "marojala",
        fokontany: "saramby",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5185318,
          lng: 49.9571983,
        },
        _id: "64f75285ef8f606073e9c469",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsambaharo",
        fokontany: "antsambaharo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4863416,
          lng: 49.9623265,
        },
        _id: "64f75285ef8f606073e9c46a",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsambaharo",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -14.506733,
          lng: 49.9866913,
        },
        _id: "64f75285ef8f606073e9c46b",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsambaharo",
        fokontany: "bemana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5190107,
          lng: 49.9828436,
        },
        _id: "64f75285ef8f606073e9c46c",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsambaharo",
        fokontany: "ambatovelezina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5338247,
          lng: 49.9623265,
        },
        _id: "64f75285ef8f606073e9c46d",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsambaharo",
        fokontany: "andasibe lokoho",
        __v: 0,
      },
      {
        coords: {
          lat: -14.496167,
          lng: 49.9290011,
        },
        _id: "64f75285ef8f606073e9c46e",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsambaharo",
        fokontany: "antsahorana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5745259,
          lng: 49.9059405,
        },
        _id: "64f75285ef8f606073e9c46f",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsambaharo",
        fokontany: "andampy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.31808,
          lng: 49.823139,
        },
        _id: "64f75285ef8f606073e9c470",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andrahanjo",
        fokontany: "andrahanjo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3095523,
          lng: 49.7217761,
        },
        _id: "64f75285ef8f606073e9c471",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andrahanjo",
        fokontany: "antongodriha I",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3720616,
          lng: 49.72688410000001,
        },
        _id: "64f75285ef8f606073e9c472",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andrahanjo",
        fokontany: "antongodriha II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2899134,
          lng: 49.7882142,
        },
        _id: "64f75285ef8f606073e9c473",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andrahanjo",
        fokontany: "mevatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2751275,
          lng: 49.8086717,
        },
        _id: "64f75285ef8f606073e9c474",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andrahanjo",
        fokontany: "andrasaha",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2887209,
          lng: 49.8291362,
        },
        _id: "64f75285ef8f606073e9c475",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andrahanjo",
        fokontany: "ambohimanarina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.304766,
          lng: 49.8265777,
        },
        _id: "64f75285ef8f606073e9c476",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andrahanjo",
        fokontany: "tananambo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3023196,
          lng: 49.8496077,
        },
        _id: "64f75285ef8f606073e9c477",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andrahanjo",
        fokontany: "ambavala",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3681514,
          lng: 49.8240194,
        },
        _id: "64f75285ef8f606073e9c478",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andrahanjo",
        fokontany: "soahitra II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.14164,
          lng: 50.124439,
        },
        _id: "64f75285ef8f606073e9c479",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bemanevika",
        fokontany: "bemanevika I",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0375836,
          lng: 50.13694470000001,
        },
        _id: "64f75285ef8f606073e9c47a",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bemanevika",
        fokontany: "ampanakana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0545073,
          lng: 50.1266591,
        },
        _id: "64f75285ef8f606073e9c47b",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bemanevika",
        fokontany: "ambatojoby",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0775084,
          lng: 50.1472321,
        },
        _id: "64f75285ef8f606073e9c47c",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bemanevika",
        fokontany: "andasimahay",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0924079,
          lng: 50.1266591,
        },
        _id: "64f75285ef8f606073e9c47d",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bemanevika",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1265987,
          lng: 50.1318017,
        },
        _id: "64f75285ef8f606073e9c47e",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bemanevika",
        fokontany: "bemanevika II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1545134,
          lng: 50.0932428,
        },
        _id: "64f75285ef8f606073e9c47f",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bemanevika",
        fokontany: "antakoly",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1761719,
          lng: 50.0829646,
        },
        _id: "64f75285ef8f606073e9c480",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bemanevika",
        fokontany: "antsahakajoy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.166211,
          lng: 50.1163752,
        },
        _id: "64f75285ef8f606073e9c481",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bemanevika",
        fokontany: "antongopahitra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1636849,
          lng: 50.1395164,
        },
        _id: "64f75285ef8f606073e9c482",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bemanevika",
        fokontany: "ampasy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1872191,
          lng: 50.1266591,
        },
        _id: "64f75285ef8f606073e9c483",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bemanevika",
        fokontany: "maevatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1450325,
          lng: 49.802278,
        },
        _id: "64f75285ef8f606073e9c484",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "amboangibe",
        fokontany: "amboangibe centre",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1144725,
          lng: 49.7498754,
        },
        _id: "64f75285ef8f606073e9c485",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "amboangibe",
        fokontany: "ambinany beanatsindra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1212392,
          lng: 49.7600967,
        },
        _id: "64f75285ef8f606073e9c486",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "amboangibe",
        fokontany: "ambatolalaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1477182,
          lng: 49.7626523,
        },
        _id: "64f75285ef8f606073e9c487",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "amboangibe",
        fokontany: "ambato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1415473,
          lng: 49.790771,
        },
        _id: "64f75285ef8f606073e9c488",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "amboangibe",
        fokontany: "andravitokana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1384587,
          lng: 49.80483539999999,
        },
        _id: "64f75285ef8f606073e9c489",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "amboangibe",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.145437,
          lng: 49.81314769999999,
        },
        _id: "64f75285ef8f606073e9c48a",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "amboangibe",
        fokontany: "ampahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1550928,
          lng: 49.8112293,
        },
        _id: "64f75285ef8f606073e9c48b",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "amboangibe",
        fokontany: "anolakely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.158396,
          lng: 49.7217761,
        },
        _id: "64f75285ef8f606073e9c48c",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "amboangibe",
        fokontany: "mandritsarabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1789216,
          lng: 49.8240194,
        },
        _id: "64f75285ef8f606073e9c48d",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "amboangibe",
        fokontany: "antanimbaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1653551,
          lng: 49.832974,
        },
        _id: "64f75285ef8f606073e9c48e",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "amboangibe",
        fokontany: "amborangy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2,
          lng: 49.716667,
        },
        _id: "64f75285ef8f606073e9c48f",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "amboangibe",
        fokontany: "maromokotra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2101174,
          lng: 49.709008,
        },
        _id: "64f75285ef8f606073e9c490",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "amboangibe",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1924717,
          lng: 49.72688410000001,
        },
        _id: "64f75285ef8f606073e9c491",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "amboangibe",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2261297,
          lng: 49.7064548,
        },
        _id: "64f75285ef8f606073e9c492",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "amboangibe",
        fokontany: "andapabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2445798,
          lng: 49.6809281,
        },
        _id: "64f75285ef8f606073e9c493",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "amboangibe",
        fokontany: "bekatseko",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2535762,
          lng: 49.6554124,
        },
        _id: "64f75285ef8f606073e9c494",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "amboangibe",
        fokontany: "bemanarana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2775029,
          lng: 49.72688410000001,
        },
        _id: "64f75285ef8f606073e9c495",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "amboangibe",
        fokontany: "betsomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -14.20778,
          lng: 49.789162,
        },
        _id: "64f75285ef8f606073e9c496",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andrembona",
        fokontany: "andrembona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1986436,
          lng: 49.7575412,
        },
        _id: "64f75285ef8f606073e9c497",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andrembona",
        fokontany: "mahadera",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1859129,
          lng: 49.7882142,
        },
        _id: "64f75285ef8f606073e9c498",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andrembona",
        fokontany: "antanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2097487,
          lng: 49.8009993,
        },
        _id: "64f75285ef8f606073e9c499",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andrembona",
        fokontany: "ambaliha",
        __v: 0,
      },
      {
        coords: {
          lat: -14.242199,
          lng: 49.7626523,
        },
        _id: "64f75285ef8f606073e9c49a",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andrembona",
        fokontany: "antsakoampito",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2562091,
          lng: 49.8086717,
        },
        _id: "64f75285ef8f606073e9c49b",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "andrembona",
        fokontany: "andrasahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.13513,
          lng: 49.81398,
        },
        _id: "64f75285ef8f606073e9c49c",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "antindra",
        __v: 0,
      },
      {
        coords: {
          lat: -14,
          lng: 49.766667,
        },
        _id: "64f75285ef8f606073e9c49d",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "betsirebika",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0407418,
          lng: 49.80611409999999,
        },
        _id: "64f75285ef8f606073e9c49e",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "beromba",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0440738,
          lng: 49.7754319,
        },
        _id: "64f75285ef8f606073e9c49f",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0508283,
          lng: 49.7856575,
        },
        _id: "64f75285ef8f606073e9c4a0",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "beafomena",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0542518,
          lng: 49.8265777,
        },
        _id: "64f75285ef8f606073e9c4a1",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "bekambo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0586026,
          lng: 49.8009993,
        },
        _id: "64f75285ef8f606073e9c4a2",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0639663,
          lng: 49.7805445,
        },
        _id: "64f75285ef8f606073e9c4a3",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "ambodimadiro",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0692896,
          lng: 49.7894926,
        },
        _id: "64f75285ef8f606073e9c4a4",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "tanambao II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0666465,
          lng: 49.77031969999999,
        },
        _id: "64f75285ef8f606073e9c4a5",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "ankobalava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0766528,
          lng: 49.8086717,
        },
        _id: "64f75285ef8f606073e9c4a6",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "ambalamanasy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0797829,
          lng: 49.765208,
        },
        _id: "64f75285ef8f606073e9c4a7",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "antanifotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0814394,
          lng: 49.7498754,
        },
        _id: "64f75285ef8f606073e9c4a8",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "besahona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0807305,
          lng: 49.8291362,
        },
        _id: "64f75285ef8f606073e9c4a9",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "beroka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0659149,
          lng: 49.8496077,
        },
        _id: "64f75285ef8f606073e9c4aa",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "antsahamatsavana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1131534,
          lng: 49.8496077,
        },
        _id: "64f75285ef8f606073e9c4ab",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "antsahamagnara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0865436,
          lng: 49.7754319,
        },
        _id: "64f75285ef8f606073e9c4ac",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0896058,
          lng: 49.790771,
        },
        _id: "64f75285ef8f606073e9c4ad",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "antanambao I",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1001875,
          lng: 49.867526,
        },
        _id: "64f75285ef8f606073e9c4ae",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "ambatosoa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1042072,
          lng: 49.7575412,
        },
        _id: "64f75285ef8f606073e9c4af",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.095539,
          lng: 49.8086717,
        },
        _id: "64f75285ef8f606073e9c4b0",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "andranaly",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1164726,
          lng: 49.818903,
        },
        _id: "64f75285ef8f606073e9c4b1",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "analampary",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1103404,
          lng: 49.7882142,
        },
        _id: "64f75285ef8f606073e9c4b2",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "tsaravilona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1243091,
          lng: 49.7754319,
        },
        _id: "64f75285ef8f606073e9c4b3",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "antsirabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1236794,
          lng: 49.7958849,
        },
        _id: "64f75285ef8f606073e9c4b4",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "matahodafa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1394607,
          lng: 49.839371,
        },
        _id: "64f75285ef8f606073e9c4b5",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "ambohipoana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1385701,
          lng: 49.9059405,
        },
        _id: "64f75285ef8f606073e9c4b6",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antindra",
        fokontany: "antsavokabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5956651,
          lng: 49.8240194,
        },
        _id: "64f75285ef8f606073e9c4b7",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodiampana",
        fokontany: "ambodiampana lokoho",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4293889,
          lng: 49.9623265,
        },
        _id: "64f75285ef8f606073e9c4b8",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodiampana",
        fokontany: "lohanantsahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4458248,
          lng: 49.92643839999999,
        },
        _id: "64f75285ef8f606073e9c4b9",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodiampana",
        fokontany: "maevatanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4645656,
          lng: 49.9136264,
        },
        _id: "64f75285ef8f606073e9c4ba",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodiampana",
        fokontany: "sarahandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4754264,
          lng: 49.8854497,
        },
        _id: "64f75285ef8f606073e9c4bb",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodiampana",
        fokontany: "belalona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5420945,
          lng: 49.8982557,
        },
        _id: "64f75285ef8f606073e9c4bc",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodiampana",
        fokontany: "ambatobarika",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5183653,
          lng: 49.8982557,
        },
        _id: "64f75285ef8f606073e9c4bd",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodiampana",
        fokontany: "tanambao A",
        __v: 0,
      },
      {
        coords: {
          lat: -14.500189,
          lng: 49.8905718,
        },
        _id: "64f75285ef8f606073e9c4be",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodiampana",
        fokontany: "antsahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5189213,
          lng: 49.8777675,
        },
        _id: "64f75285ef8f606073e9c4bf",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodiampana",
        fokontany: "anahipisaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5851049,
          lng: 49.86496589999999,
        },
        _id: "64f75285ef8f606073e9c4c0",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambodiampana",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4766732,
          lng: 49.7524306,
        },
        _id: "64f75285ef8f606073e9c4c1",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "maroambihy",
        fokontany: "maroambihy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5045829,
          lng: 49.72688410000001,
        },
        _id: "64f75285ef8f606073e9c4c2",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "maroambihy",
        fokontany: "antanamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5203546,
          lng: 49.7575412,
        },
        _id: "64f75285ef8f606073e9c4c3",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "maroambihy",
        fokontany: "andranomifotitra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5245538,
          lng: 49.77798809999999,
        },
        _id: "64f75285ef8f606073e9c4c4",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "maroambihy",
        fokontany: "amboangibe lokoho",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4766732,
          lng: 49.7524306,
        },
        _id: "64f75285ef8f606073e9c4c5",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "maroambihy",
        fokontany: "mandena",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4931761,
          lng: 49.82146110000001,
        },
        _id: "64f75285ef8f606073e9c4c6",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "maroambihy",
        fokontany: "manantenina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5213697,
          lng: 49.8086717,
        },
        _id: "64f75285ef8f606073e9c4c7",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "maroambihy",
        fokontany: "ambohimanarina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4939128,
          lng: 49.85984610000001,
        },
        _id: "64f75285ef8f606073e9c4c8",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "maroambihy",
        fokontany: "maroambihy II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.117574,
          lng: 49.6771,
        },
        _id: "64f75285ef8f606073e9c4c9",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambatoafo",
        fokontany: "ambatoafo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0935848,
          lng: 49.6809281,
        },
        _id: "64f75285ef8f606073e9c4ca",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambatoafo",
        fokontany: "bevoay",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1150984,
          lng: 49.7294382,
        },
        _id: "64f75285ef8f606073e9c4cb",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambatoafo",
        fokontany: "befandrana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1136735,
          lng: 49.6987956,
        },
        _id: "64f75285ef8f606073e9c4cc",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambatoafo",
        fokontany: "andribavato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1214636,
          lng: 49.7141149,
        },
        _id: "64f75285ef8f606073e9c4cd",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambatoafo",
        fokontany: "beanatsindra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1214699,
          lng: 49.6554124,
        },
        _id: "64f75285ef8f606073e9c4ce",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambatoafo",
        fokontany: "ambodirafia",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1337714,
          lng: 49.6579635,
        },
        _id: "64f75285ef8f606073e9c4cf",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambatoafo",
        fokontany: "beamalona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1456552,
          lng: 49.6350078,
        },
        _id: "64f75285ef8f606073e9c4d0",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambatoafo",
        fokontany: "ambodivato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1544451,
          lng: 49.5967682,
        },
        _id: "64f75285ef8f606073e9c4d1",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambatoafo",
        fokontany: "antsambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1518189,
          lng: 49.6656173,
        },
        _id: "64f75285ef8f606073e9c4d2",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambatoafo",
        fokontany: "ambalavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1813232,
          lng: 49.6248081,
        },
        _id: "64f75285ef8f606073e9c4d3",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambatoafo",
        fokontany: "bealampona A",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1875023,
          lng: 49.6554124,
        },
        _id: "64f75285ef8f606073e9c4d4",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambatoafo",
        fokontany: "bekalamaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1866335,
          lng: 49.6044141,
        },
        _id: "64f75285ef8f606073e9c4d5",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambatoafo",
        fokontany: "fotsialanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2050514,
          lng: 49.5789316,
        },
        _id: "64f75285ef8f606073e9c4d6",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambatoafo",
        fokontany: "ambavala",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2321808,
          lng: 49.619709,
        },
        _id: "64f75285ef8f606073e9c4d7",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "ambatoafo",
        fokontany: "andantony",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0599519,
          lng: 49.7013486,
        },
        _id: "64f75285ef8f606073e9c4d8",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjialava",
        fokontany: "anjialava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0501364,
          lng: 49.6758241,
        },
        _id: "64f75285ef8f606073e9c4d9",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjialava",
        fokontany: "ankobalava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0381327,
          lng: 49.6924137,
        },
        _id: "64f75285ef8f606073e9c4da",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjialava",
        fokontany: "antsahamaletry",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0562573,
          lng: 49.7064548,
        },
        _id: "64f75285ef8f606073e9c4db",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjialava",
        fokontany: "analanambe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0605287,
          lng: 49.739656,
        },
        _id: "64f75285ef8f606073e9c4dc",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjialava",
        fokontany: "antanambaza",
        __v: 0,
      },
      {
        coords: {
          lat: -14.064472,
          lng: 49.6885849,
        },
        _id: "64f75285ef8f606073e9c4dd",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjialava",
        fokontany: "doanihely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0827031,
          lng: 49.709008,
        },
        _id: "64f75285ef8f606073e9c4de",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjialava",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.074528,
          lng: 49.6681689,
        },
        _id: "64f75285ef8f606073e9c4df",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjialava",
        fokontany: "antanambao II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0714618,
          lng: 49.6528614,
        },
        _id: "64f75285ef8f606073e9c4e0",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjialava",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.07468,
          lng: 49.739656,
        },
        _id: "64f75285ef8f606073e9c4e1",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "anjialava",
        fokontany: "ambohimitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.98727,
          lng: 50.108311,
        },
        _id: "64f75285ef8f606073e9c4e2",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "tanambao daoud",
        fokontany: "tanambao daoud",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8643885,
          lng: 50.0495725,
        },
        _id: "64f75285ef8f606073e9c4e3",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "tanambao daoud",
        fokontany: "ambatamandry",
        __v: 0,
      },
      {
        coords: {
          lat: -13.876396,
          lng: 50.11123389999999,
        },
        _id: "64f75285ef8f606073e9c4e4",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "tanambao daoud",
        fokontany: "anjala",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9059095,
          lng: 50.0444369,
        },
        _id: "64f75285ef8f606073e9c4e5",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "tanambao daoud",
        fokontany: "mosorobe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8904399,
          lng: 50.0136323,
        },
        _id: "64f75285ef8f606073e9c4e6",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "tanambao daoud",
        fokontany: "ambatomalaza",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8970313,
          lng: 50.0958127,
        },
        _id: "64f75285ef8f606073e9c4e7",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "tanambao daoud",
        fokontany: "antsahamazava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9440231,
          lng: 50.07011929999999,
        },
        _id: "64f75285ef8f606073e9c4e8",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "tanambao daoud",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9692815,
          lng: 50.1266591,
        },
        _id: "64f75285ef8f606073e9c4e9",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "tanambao daoud",
        fokontany: "mosorokely",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9463315,
          lng: 50.1060931,
        },
        _id: "64f75285ef8f606073e9c4ea",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "tanambao daoud",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9735602,
          lng: 50.0881035,
        },
        _id: "64f75285ef8f606073e9c4eb",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "tanambao daoud",
        fokontany: "ambodibonara",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9916327,
          lng: 50.0958127,
        },
        _id: "64f75285ef8f606073e9c4ec",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "tanambao daoud",
        fokontany: "andramanolotra",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9976824,
          lng: 50.1266591,
        },
        _id: "64f75285ef8f606073e9c4ed",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "tanambao daoud",
        fokontany: "ambodimandrorofo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0166206,
          lng: 50.1266591,
        },
        _id: "64f75285ef8f606073e9c4ee",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "tanambao daoud",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0226906,
          lng: 49.6681689,
        },
        _id: "64f75285ef8f606073e9c4ef",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "antsahavaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9387562,
          lng: 49.6605146,
        },
        _id: "64f75285ef8f606073e9c4f0",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "antsahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9601665,
          lng: 49.5789316,
        },
        _id: "64f75285ef8f606073e9c4f1",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "mahadera",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9747566,
          lng: 49.6758241,
        },
        _id: "64f75285ef8f606073e9c4f2",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "befamelona",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9597883,
          lng: 49.55346,
        },
        _id: "64f75285ef8f606073e9c4f3",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "ambinanindrano",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9871207,
          lng: 49.619709,
        },
        _id: "64f75285ef8f606073e9c4f4",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "bevanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.015752,
          lng: 49.6452092,
        },
        _id: "64f75285ef8f606073e9c4f5",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "marovitsika",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9941163,
          lng: 49.5840272,
        },
        _id: "64f75285ef8f606073e9c4f6",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9983949,
          lng: 49.7473204,
        },
        _id: "64f75285ef8f606073e9c4f7",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "ambavala",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0050517,
          lng: 49.6860325,
        },
        _id: "64f75285ef8f606073e9c4f8",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "ambatomasina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0158034,
          lng: 49.5865752,
        },
        _id: "64f75285ef8f606073e9c4f9",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "bemahogo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0200158,
          lng: 49.678376,
        },
        _id: "64f75285ef8f606073e9c4fa",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "ambalamanasy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0272577,
          lng: 49.5967682,
        },
        _id: "64f75285ef8f606073e9c4fb",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "bevato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.030383,
          lng: 49.7422107,
        },
        _id: "64f75285ef8f606073e9c4fc",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "andilampotaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0037596,
          lng: 49.72688410000001,
        },
        _id: "64f75285ef8f606073e9c4fd",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "antanananivo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0156802,
          lng: 49.70390159999999,
        },
        _id: "64f75285ef8f606073e9c4fe",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "angodrogodro",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0276235,
          lng: 49.65158599999999,
        },
        _id: "64f75285ef8f606073e9c4ff",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "ambalamanga",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0271788,
          lng: 49.6847564,
        },
        _id: "64f75285ef8f606073e9c500",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "marojala",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0460553,
          lng: 49.6554124,
        },
        _id: "64f75285ef8f606073e9c501",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "bemapaza",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0343079,
          lng: 49.7204992,
        },
        _id: "64f75285ef8f606073e9c502",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "marohasina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.051395,
          lng: 49.6350078,
        },
        _id: "64f75285ef8f606073e9c503",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "antsambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.042304,
          lng: 49.7192223,
        },
        _id: "64f75285ef8f606073e9c504",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "amparihy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0378917,
          lng: 49.61461019999999,
        },
        _id: "64f75285ef8f606073e9c505",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "antsahavaribe",
        fokontany: "beanatsindra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.075572,
          lng: 49.61461019999999,
        },
        _id: "64f75285ef8f606073e9c506",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "bevonotra",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9855789,
          lng: 49.5178185,
        },
        _id: "64f75285ef8f606073e9c507",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "beanatsindrabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0166172,
          lng: 49.34501119999999,
        },
        _id: "64f75285ef8f606073e9c508",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "ambodimandresy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0191136,
          lng: 49.4974616,
        },
        _id: "64f75285ef8f606073e9c509",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "antanambaobe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0444477,
          lng: 49.4364335,
        },
        _id: "64f75285ef8f606073e9c50a",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "bealampona B",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0456962,
          lng: 49.5127286,
        },
        _id: "64f75285ef8f606073e9c50b",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "antsahapolisy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0278833,
          lng: 49.5763839,
        },
        _id: "64f75285ef8f606073e9c50c",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "mahavanona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0483529,
          lng: 49.5611003,
        },
        _id: "64f75285ef8f606073e9c50d",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "ambodisambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0513714,
          lng: 49.4008633,
        },
        _id: "64f75285ef8f606073e9c50e",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "antsahabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.066567,
          lng: 49.5814793,
        },
        _id: "64f75285ef8f606073e9c50f",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "mariarano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0706596,
          lng: 49.5432745,
        },
        _id: "64f75285ef8f606073e9c510",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "melokanarana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0640065,
          lng: 49.37039189999999,
        },
        _id: "64f75285ef8f606073e9c511",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0775117,
          lng: 49.3907044,
        },
        _id: "64f75285ef8f606073e9c512",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "andasibe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0867237,
          lng: 49.2740045,
        },
        _id: "64f75285ef8f606073e9c513",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "ambohimitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1155589,
          lng: 49.41610499999999,
        },
        _id: "64f75285ef8f606073e9c514",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "antsiatsiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.115112,
          lng: 49.3907044,
        },
        _id: "64f75285ef8f606073e9c515",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "mahasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1503834,
          lng: 49.2638678,
        },
        _id: "64f75285ef8f606073e9c516",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.116442,
          lng: 49.4669396,
        },
        _id: "64f75285ef8f606073e9c517",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "ambalaharongana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.075555,
          lng: 49.4974616,
        },
        _id: "64f75285ef8f606073e9c518",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "andranomadio",
        __v: 0,
      },
      {
        coords: {
          lat: -14.09616,
          lng: 49.583179,
        },
        _id: "64f75285ef8f606073e9c519",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "ambanjahilatra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1058911,
          lng: 49.6248081,
        },
        _id: "64f75285ef8f606073e9c51a",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "mahidifiana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.114481,
          lng: 49.5738364,
        },
        _id: "64f75285ef8f606073e9c51b",
        province: "antsiranana",
        region: "sava",
        district: "sambava",
        commune: "bevonotra",
        fokontany: "anketrakabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.663639,
          lng: 49.6541369,
        },
        _id: "64f75285ef8f606073e9c51c",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "andapa",
        fokontany: "beanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6319217,
          lng: 49.6554124,
        },
        _id: "64f75285ef8f606073e9c51d",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "andapa",
        fokontany: "andongozabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6331994,
          lng: 49.6273579,
        },
        _id: "64f75285ef8f606073e9c51e",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "andapa",
        fokontany: "betsakotsako",
        __v: 0,
      },
      {
        coords: {
          lat: -14.647974,
          lng: 49.6528614,
        },
        _id: "64f75285ef8f606073e9c51f",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "andapa",
        fokontany: "sahamazava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6505908,
          lng: 49.6426587,
        },
        _id: "64f75285ef8f606073e9c520",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "andapa",
        fokontany: "antangena",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6532064,
          lng: 49.6324577,
        },
        _id: "64f75285ef8f606073e9c521",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "andapa",
        fokontany: "antohobalo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6594309,
          lng: 49.65668789999999,
        },
        _id: "64f75285ef8f606073e9c522",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "andapa",
        fokontany: "ankevaheva",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6710945,
          lng: 49.6273579,
        },
        _id: "64f75285ef8f606073e9c523",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "andapa",
        fokontany: "anjiahely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6814313,
          lng: 49.6656173,
        },
        _id: "64f75285ef8f606073e9c524",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "andapa",
        fokontany: "andapa sud",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6911104,
          lng: 49.6324577,
        },
        _id: "64f75285ef8f606073e9c525",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "tanandava",
        fokontany: "andapakova",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6923185,
          lng: 49.6439339,
        },
        _id: "64f75285ef8f606073e9c526",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "tanandava",
        fokontany: "tanambao sud",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6937215,
          lng: 49.6222585,
        },
        _id: "64f75285ef8f606073e9c527",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "tanandava",
        fokontany: "andapahely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6982663,
          lng: 49.6554124,
        },
        _id: "64f75285ef8f606073e9c528",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "tanandava",
        fokontany: "antanamangotroka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7079375,
          lng: 49.6222585,
        },
        _id: "64f75285ef8f606073e9c529",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "tanandava",
        fokontany: "ambalavoanio",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7210895,
          lng: 49.6171596,
        },
        _id: "64f75285ef8f606073e9c52a",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "tanandava",
        fokontany: "antsahameloka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8,
          lng: 49.683333,
        },
        _id: "64f75285ef8f606073e9c52b",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "tanandava",
        fokontany: "besariaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6657824,
          lng: 49.6018654,
        },
        _id: "64f75285ef8f606073e9c52c",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ankiakabe nord",
        fokontany: "tsarabanja",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6518606,
          lng: 49.61461019999999,
        },
        _id: "64f75285ef8f606073e9c52d",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ankiakabe nord",
        fokontany: "antsirampontsy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6731287,
          lng: 49.5916715,
        },
        _id: "64f75285ef8f606073e9c52e",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ankiakabe nord",
        fokontany: "marokobay",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6821184,
          lng: 49.612061,
        },
        _id: "64f75285ef8f606073e9c52f",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ankiakabe nord",
        fokontany: "ankiakabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6928454,
          lng: 49.5840272,
        },
        _id: "64f75285ef8f606073e9c530",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ankiakabe nord",
        fokontany: "beanantsaladina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6,
          lng: 49.633333,
        },
        _id: "64f75285ef8f606073e9c531",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "belaoka marovato",
        fokontany: "belaoka marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5963219,
          lng: 49.5865752,
        },
        _id: "64f75285ef8f606073e9c532",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "belaoka marovato",
        fokontany: "antanambaobe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5947632,
          lng: 49.6018654,
        },
        _id: "64f75285ef8f606073e9c533",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "belaoka marovato",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6068458,
          lng: 49.5916715,
        },
        _id: "64f75285ef8f606073e9c534",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "belaoka marovato",
        fokontany: "nosihely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6278998,
          lng: 49.6018654,
        },
        _id: "64f75285ef8f606073e9c535",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "belaoka marovato",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7986181,
          lng: 49.5687416,
        },
        _id: "64f75285ef8f606073e9c536",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambodimanga I",
        fokontany: "ambodimanga I",
        __v: 0,
      },
      {
        coords: {
          lat: -14.70803,
          lng: 49.6056885,
        },
        _id: "64f75285ef8f606073e9c537",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambodimanga I",
        fokontany: "antsahanongo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7637611,
          lng: 49.5381824,
        },
        _id: "64f75285ef8f606073e9c538",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambodimanga I",
        fokontany: "ampontsilahy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7723219,
          lng: 49.5789316,
        },
        _id: "64f75285ef8f606073e9c539",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambodimanga I",
        fokontany: "andilandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.833333,
          lng: 49.53333300000001,
        },
        _id: "64f75285ef8f606073e9c53a",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambodimanga I",
        fokontany: "amponaomby",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5974319,
          lng: 49.637558,
        },
        _id: "64f75285ef8f606073e9c53b",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "marovato",
        fokontany: "marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5198567,
          lng: 49.6401083,
        },
        _id: "64f75285ef8f606073e9c53c",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "marovato",
        fokontany: "ambodivohitra marovato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6100761,
          lng: 49.6528614,
        },
        _id: "64f75285ef8f606073e9c53d",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "marovato",
        fokontany: "sarahandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6163739,
          lng: 49.637558,
        },
        _id: "64f75285ef8f606073e9c53e",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "marovato",
        fokontany: "matsobe nord",
        __v: 0,
      },
      {
        coords: {
          lat: -14.606512,
          lng: 49.6413835,
        },
        _id: "64f75285ef8f606073e9c53f",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "marovato",
        fokontany: "antsambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6662726,
          lng: 49.7524306,
        },
        _id: "64f75285ef8f606073e9c540",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "andrakata",
        fokontany: "andrakata",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6461939,
          lng: 49.7013486,
        },
        _id: "64f75285ef8f606073e9c541",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "andrakata",
        fokontany: "masiaposa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7263245,
          lng: 49.7217761,
        },
        _id: "64f75285ef8f606073e9c542",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "andrakata",
        fokontany: "androka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.683333,
          lng: 49.783333,
        },
        _id: "64f75285ef8f606073e9c543",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "andrakata",
        fokontany: "ambalavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7026665,
          lng: 49.5178185,
        },
        _id: "64f75285ef8f606073e9c544",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "bealampona",
        fokontany: "bealampona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6363086,
          lng: 49.5967682,
        },
        _id: "64f75285ef8f606073e9c545",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "bealampona",
        fokontany: "anjavibe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6378603,
          lng: 49.5814793,
        },
        _id: "64f75285ef8f606073e9c546",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "bealampona",
        fokontany: "andratamarina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6420197,
          lng: 49.5560067,
        },
        _id: "64f75285ef8f606073e9c547",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "bealampona",
        fokontany: "ambodipont",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6588259,
          lng: 49.5458207,
        },
        _id: "64f75285ef8f606073e9c548",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "bealampona",
        fokontany: "beahipisaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6831391,
          lng: 49.4923735,
        },
        _id: "64f75285ef8f606073e9c549",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "bealampona",
        fokontany: "andasibe mahaverika",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6791168,
          lng: 49.5636473,
        },
        _id: "64f75285ef8f606073e9c54a",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "bealampona",
        fokontany: "beanjavidy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7216012,
          lng: 49.5178185,
        },
        _id: "64f75285ef8f606073e9c54b",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "bealampona",
        fokontany: "befingotra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6157469,
          lng: 49.5661944,
        },
        _id: "64f75285ef8f606073e9c54c",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "matsohely",
        fokontany: "ambalafontsy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6224173,
          lng: 49.5470939,
        },
        _id: "64f75285ef8f606073e9c54d",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "matsohely",
        fokontany: "antsampanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6317801,
          lng: 49.5636473,
        },
        _id: "64f75285ef8f606073e9c54e",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "matsohely",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6189264,
          lng: 49.5814793,
        },
        _id: "64f75285ef8f606073e9c54f",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "matsohely",
        fokontany: "antsirabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5894738,
          lng: 49.5763839,
        },
        _id: "64f75285ef8f606073e9c550",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "matsohely",
        fokontany: "ambohimitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6084015,
          lng: 49.5763839,
        },
        _id: "64f75285ef8f606073e9c551",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "matsohely",
        fokontany: "marofototra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.599151,
          lng: 49.5432745,
        },
        _id: "64f75285ef8f606073e9c552",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "andranomena",
        fokontany: "andranomena",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5788928,
          lng: 49.5254541,
        },
        _id: "64f75285ef8f606073e9c553",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "andranomena",
        fokontany: "ambohitranivo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5968906,
          lng: 49.5267268,
        },
        _id: "64f75285ef8f606073e9c554",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "andranomena",
        fokontany: "marotohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6053807,
          lng: 49.53627299999999,
        },
        _id: "64f75285ef8f606073e9c555",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "andranomena",
        fokontany: "ambodizavy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6125671,
          lng: 49.5509135,
        },
        _id: "64f75285ef8f606073e9c556",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "andranomena",
        fokontany: "besahoana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.518792,
          lng: 49.5891233,
        },
        _id: "64f75285ef8f606073e9c557",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambalamanasy II",
        fokontany: "ambalamanasy II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4883183,
          lng: 49.5789316,
        },
        _id: "64f75285ef8f606073e9c558",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambalamanasy II",
        fokontany: "ambavala",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4888452,
          lng: 49.6044141,
        },
        _id: "64f75285ef8f606073e9c559",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambalamanasy II",
        fokontany: "antanimbaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5177128,
          lng: 49.5381824,
        },
        _id: "64f75285ef8f606073e9c55a",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambalamanasy II",
        fokontany: "ambodivohitra kobahina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5195768,
          lng: 49.5814793,
        },
        _id: "64f75285ef8f606073e9c55b",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambalamanasy II",
        fokontany: "ambodimanga II",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5270738,
          lng: 49.60059099999999,
        },
        _id: "64f75285ef8f606073e9c55c",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambalamanasy II",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5432234,
          lng: 49.5814793,
        },
        _id: "64f75285ef8f606073e9c55d",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambalamanasy II",
        fokontany: "andongona",
        __v: 0,
      },
      {
        coords: {
          lat: -14.547713,
          lng: 49.61461019999999,
        },
        _id: "64f75285ef8f606073e9c55e",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambalamanasy II",
        fokontany: "andasibe kobahina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5574137,
          lng: 49.5814793,
        },
        _id: "64f75285ef8f606073e9c55f",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambalamanasy II",
        fokontany: "manirirano",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5739945,
          lng: 49.6044141,
        },
        _id: "64f75285ef8f606073e9c560",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambalamanasy II",
        fokontany: "antsahameloka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5792313,
          lng: 49.5840272,
        },
        _id: "64f75285ef8f606073e9c561",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambalamanasy II",
        fokontany: "ambodiala",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5983987,
          lng: 49.4262684,
        },
        _id: "64f75285ef8f606073e9c562",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambodiangezoka",
        fokontany: "ambodiangezoka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5644287,
          lng: 49.5127286,
        },
        _id: "64f75285ef8f606073e9c563",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambodiangezoka",
        fokontany: "belambo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5690837,
          lng: 49.4669396,
        },
        _id: "64f75285ef8f606073e9c564",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambodiangezoka",
        fokontany: "antanambe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6119966,
          lng: 49.5254541,
        },
        _id: "64f75285ef8f606073e9c565",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambodiangezoka",
        fokontany: "ambalavary",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6066941,
          lng: 49.5000058,
        },
        _id: "64f75285ef8f606073e9c566",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambodiangezoka",
        fokontany: "ampohafana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5998454,
          lng: 49.4898296,
        },
        _id: "64f75285ef8f606073e9c567",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambodiangezoka",
        fokontany: "antanambaohely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5899063,
          lng: 49.3856256,
        },
        _id: "64f75285ef8f606073e9c568",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambodiangezoka",
        fokontany: "ambodihasina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6522057,
          lng: 49.4237274,
        },
        _id: "64f75285ef8f606073e9c569",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambodiangezoka",
        fokontany: "antsirakengitra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.61646,
          lng: 49.49073,
        },
        _id: "64f75285ef8f606073e9c56a",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambodiangezoka",
        fokontany: "ambodisatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6672859,
          lng: 49.4618542,
        },
        _id: "64f75285ef8f606073e9c56b",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambodiangezoka",
        fokontany: "ambodidivaina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6507955,
          lng: 49.4847422,
        },
        _id: "64f75285ef8f606073e9c56c",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambodiangezoka",
        fokontany: "analanambe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6397968,
          lng: 49.5000058,
        },
        _id: "64f75285ef8f606073e9c56d",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambodiangezoka",
        fokontany: "andranovalo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.65032,
          lng: 49.5050946,
        },
        _id: "64f75285ef8f606073e9c56e",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambodiangezoka",
        fokontany: "antsavokabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6254136,
          lng: 49.5330908,
        },
        _id: "64f75285ef8f606073e9c56f",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambodiangezoka",
        fokontany: "ambohimarina",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6425037,
          lng: 49.5356365,
        },
        _id: "64f75285ef8f606073e9c570",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "ambodiangezoka",
        fokontany: "antsahamiango",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5634292,
          lng: 49.55346,
        },
        _id: "64f75285ef8f606073e9c571",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "betsakotsako andranotsara",
        fokontany: "betsakotsako andranotsara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5531906,
          lng: 49.5611003,
        },
        _id: "64f75285ef8f606073e9c572",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "betsakotsako andranotsara",
        fokontany: "mazavahely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5594798,
          lng: 49.5458207,
        },
        _id: "64f75285ef8f606073e9c573",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "betsakotsako andranotsara",
        fokontany: "andranotsara andohany",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5610378,
          lng: 49.5305451,
        },
        _id: "64f75285ef8f606073e9c574",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "betsakotsako andranotsara",
        fokontany: "antsahantsoy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5831255,
          lng: 49.5458207,
        },
        _id: "64f75285ef8f606073e9c575",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "betsakotsako andranotsara",
        fokontany: "ambalabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.582627,
          lng: 49.5661944,
        },
        _id: "64f75285ef8f606073e9c576",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "betsakotsako andranotsara",
        fokontany: "ambodimangasoa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5598472,
          lng: 49.7422107,
        },
        _id: "64f75285ef8f606073e9c577",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "belaoka lokoho",
        fokontany: "belaoka lokoho",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5266929,
          lng: 49.6962428,
        },
        _id: "64f75285ef8f606073e9c578",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "belaoka lokoho",
        fokontany: "ambatobe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5466831,
          lng: 49.7473204,
        },
        _id: "64f75285ef8f606073e9c579",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "belaoka lokoho",
        fokontany: "belambo lokoho",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5548461,
          lng: 49.7294382,
        },
        _id: "64f75285ef8f606073e9c57a",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "belaoka lokoho",
        fokontany: "antanambao lokoho",
        __v: 0,
      },
      {
        coords: {
          lat: -14.5645804,
          lng: 49.6962428,
        },
        _id: "64f75285ef8f606073e9c57b",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "belaoka lokoho",
        fokontany: "ambalavoanio lokoho",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6035617,
          lng: 49.7473204,
        },
        _id: "64f75285ef8f606073e9c57c",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "belaoka lokoho",
        fokontany: "ambahinkarabo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.773981,
          lng: 49.8137871,
        },
        _id: "64f75285ef8f606073e9c57d",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "anoviara",
        fokontany: "anoviara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7140063,
          lng: 49.765208,
        },
        _id: "64f75285ef8f606073e9c57e",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "anoviara",
        fokontany: "berokavato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.6805546,
          lng: 49.7984421,
        },
        _id: "64f75285ef8f606073e9c57f",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "anoviara",
        fokontany: "antsahavoara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.713508,
          lng: 49.7856575,
        },
        _id: "64f75285ef8f606073e9c580",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "anoviara",
        fokontany: "ambalakafe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.728035,
          lng: 49.7984421,
        },
        _id: "64f75285ef8f606073e9c581",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "anoviara",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -14.766667,
          lng: 49.84999999999999,
        },
        _id: "64f75285ef8f606073e9c582",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "anoviara",
        fokontany: "ambalambato",
        __v: 0,
      },
      {
        coords: {
          lat: -14.7766845,
          lng: 49.8496077,
        },
        _id: "64f75285ef8f606073e9c583",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "anoviara",
        fokontany: "maroambihy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8009432,
          lng: 49.8291362,
        },
        _id: "64f75285ef8f606073e9c584",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "anoviara",
        fokontany: "ambodivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8191844,
          lng: 49.8368121,
        },
        _id: "64f75285ef8f606073e9c585",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "anoviara",
        fokontany: "ambohimitsinjo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8368403,
          lng: 49.818903,
        },
        _id: "64f75285ef8f606073e9c586",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "anoviara",
        fokontany: "andembilemisy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8659708,
          lng: 49.8444891,
        },
        _id: "64f75285ef8f606073e9c587",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "anoviara",
        fokontany: "ambohitsara",
        __v: 0,
      },
      {
        coords: {
          lat: -14.38226,
          lng: 49.51561,
        },
        _id: "64f75285ef8f606073e9c588",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "doany",
        fokontany: "doany",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2631426,
          lng: 49.2638678,
        },
        _id: "64f75285ef8f606073e9c589",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "doany",
        fokontany: "anjialavahely",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3297896,
          lng: 49.5891233,
        },
        _id: "64f75285ef8f606073e9c58a",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "doany",
        fokontany: "andranoroa",
        __v: 0,
      },
      {
        coords: {
          lat: -14.35218,
          lng: 49.419621,
        },
        _id: "64f75285ef8f606073e9c58b",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "doany",
        fokontany: "andranomilolo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.333333,
          lng: 49.48333299999999,
        },
        _id: "64f75285ef8f606073e9c58c",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "doany",
        fokontany: "ambodizavy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.3057873,
          lng: 49.5178185,
        },
        _id: "64f75285ef8f606073e9c58d",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "doany",
        fokontany: "ambalihabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.384248,
          lng: 49.67072049999999,
        },
        _id: "64f75285ef8f606073e9c58e",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "doany",
        fokontany: "antsahaberaoka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.438922,
          lng: 49.3856256,
        },
        _id: "64f75285ef8f606073e9c58f",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "doany",
        fokontany: "befamatra",
        __v: 0,
      },
      {
        coords: {
          lat: -14.405351,
          lng: 49.5891233,
        },
        _id: "64f75285ef8f606073e9c590",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "doany",
        fokontany: "betsomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4379199,
          lng: 49.5178185,
        },
        _id: "64f75286ef8f606073e9c591",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "doany",
        fokontany: "androfiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.4106508,
          lng: 49.4771118,
        },
        _id: "64f75286ef8f606073e9c592",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "doany",
        fokontany: "betaholana",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1917487,
          lng: 49.4669396,
        },
        _id: "64f75286ef8f606073e9c593",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "anjialavabe",
        fokontany: "anjialavabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1590995,
          lng: 49.5381824,
        },
        _id: "64f75286ef8f606073e9c594",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "anjialavabe",
        fokontany: "antafiambe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2078287,
          lng: 49.5229088,
        },
        _id: "64f75286ef8f606073e9c595",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "anjialavabe",
        fokontany: "bekaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1834789,
          lng: 49.4262684,
        },
        _id: "64f75286ef8f606073e9c596",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "anjialavabe",
        fokontany: "ankiakabe",
        __v: 0,
      },
      {
        coords: {
          lat: -14.2367692,
          lng: 49.4567691,
        },
        _id: "64f75286ef8f606073e9c597",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "anjialavabe",
        fokontany: "antanambao I",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9215974,
          lng: 49.7534104,
        },
        _id: "64f75286ef8f606073e9c598",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "antsahamena",
        fokontany: "antsahamena",
        __v: 0,
      },
      {
        coords: {
          lat: -14.877019,
          lng: 49.67072049999999,
        },
        _id: "64f75286ef8f606073e9c599",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "antsahamena",
        fokontany: "befontsy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.8721335,
          lng: 49.7831009,
        },
        _id: "64f75286ef8f606073e9c59a",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "antsahamena",
        fokontany: "betaindrafia",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9525363,
          lng: 49.8035566,
        },
        _id: "64f75286ef8f606073e9c59b",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "antsahamena",
        fokontany: "betsomanga",
        __v: 0,
      },
      {
        coords: {
          lat: -14.9997002,
          lng: 49.7115614,
        },
        _id: "64f75286ef8f606073e9c59c",
        province: "antsiranana",
        region: "sava",
        district: "andapa",
        commune: "antsahamena",
        fokontany: "ambodiangezoka II",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3562073,
          lng: 50.0065752,
        },
        _id: "64f75286ef8f606073e9c59d",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "vohemar",
        fokontany: "andranomasibe I",
        __v: 0,
      },
      {
        coords: {
          lat: -13.358798,
          lng: 50.0078583,
        },
        _id: "64f75286ef8f606073e9c59e",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "vohemar",
        fokontany: "andranomasikely I",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3648405,
          lng: 49.9963117,
        },
        _id: "64f75286ef8f606073e9c59f",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "vohemar",
        fokontany: "andranomasibe II",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3635721,
          lng: 50.000481,
        },
        _id: "64f75286ef8f606073e9c5a0",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "vohemar",
        fokontany: "andranomasikely II",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3703549,
          lng: 50.0161988,
        },
        _id: "64f75286ef8f606073e9c5a1",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "vohemar",
        fokontany: "tanambao",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3787837,
          lng: 50.011066,
        },
        _id: "64f75286ef8f606073e9c5a2",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "vohemar",
        fokontany: "amboronana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3871368,
          lng: 49.9931047,
        },
        _id: "64f75286ef8f606073e9c5a3",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "vohemar",
        fokontany: "androrona",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4259845,
          lng: 49.9687373,
        },
        _id: "64f75286ef8f606073e9c5a4",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampondra",
        fokontany: "ampondra",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2306745,
          lng: 49.85984610000001,
        },
        _id: "64f75286ef8f606073e9c5a5",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampondra",
        fokontany: "lavarankana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2346439,
          lng: 49.9059405,
        },
        _id: "64f75286ef8f606073e9c5a6",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampondra",
        fokontany: "manambato",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3510563,
          lng: 49.92643839999999,
        },
        _id: "64f75286ef8f606073e9c5a7",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampondra",
        fokontany: "andampikely",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3497342,
          lng: 49.7217761,
        },
        _id: "64f75286ef8f606073e9c5a8",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampondra",
        fokontany: "tamasina",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3617423,
          lng: 49.83425339999999,
        },
        _id: "64f75286ef8f606073e9c5a9",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampondra",
        fokontany: "antsahandririnina",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4289813,
          lng: 49.79332789999999,
        },
        _id: "64f75286ef8f606073e9c5aa",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampondra",
        fokontany: "ambanja",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4520379,
          lng: 49.86496589999999,
        },
        _id: "64f75286ef8f606073e9c5ab",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampondra",
        fokontany: "ambodimadiro",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5005469,
          lng: 49.8240194,
        },
        _id: "64f75286ef8f606073e9c5ac",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampondra",
        fokontany: "ampizamatana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5488742,
          lng: 49.99053929999999,
        },
        _id: "64f75286ef8f606073e9c5ad",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "fanambana",
        fokontany: "fanambana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4754702,
          lng: 49.9879739,
        },
        _id: "64f75286ef8f606073e9c5ae",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "fanambana",
        fokontany: "anjavilava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5240755,
          lng: 49.9469432,
        },
        _id: "64f75286ef8f606073e9c5af",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "fanambana",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5914637,
          lng: 49.9059405,
        },
        _id: "64f75286ef8f606073e9c5b0",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "fanambana",
        fokontany: "amparibe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6370015,
          lng: 49.9469432,
        },
        _id: "64f75286ef8f606073e9c5b1",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "fanambana",
        fokontany: "ambakirano",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6866683,
          lng: 50.021332,
        },
        _id: "64f75286ef8f606073e9c5b2",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "fanambana",
        fokontany: "analovana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6927581,
          lng: 49.8700862,
        },
        _id: "64f75286ef8f606073e9c5b3",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "fanambana",
        fokontany: "antsahafotsy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6935129,
          lng: 49.9469432,
        },
        _id: "64f75286ef8f606073e9c5b4",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "fanambana",
        fokontany: "ankijabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5825,
          lng: 49.78516,
        },
        _id: "64f75286ef8f606073e9c5b5",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "milanoa",
        fokontany: "milanoa",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4739325,
          lng: 49.7831009,
        },
        _id: "64f75286ef8f606073e9c5b6",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "milanoa",
        fokontany: "ankorimpa",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5362443,
          lng: 49.619709,
        },
        _id: "64f75286ef8f606073e9c5b7",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "milanoa",
        fokontany: "ambodimanga II",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5223791,
          lng: 49.7422107,
        },
        _id: "64f75286ef8f606073e9c5b8",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "milanoa",
        fokontany: "androfialava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5253858,
          lng: 49.6605146,
        },
        _id: "64f75286ef8f606073e9c5b9",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "milanoa",
        fokontany: "marobakoly",
        __v: 0,
      },
      {
        coords: {
          lat: -13.566667,
          lng: 49.73333299999999,
        },
        _id: "64f75286ef8f606073e9c5ba",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "milanoa",
        fokontany: "ambodisakoa",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6043775,
          lng: 49.6809281,
        },
        _id: "64f75286ef8f606073e9c5bb",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "milanoa",
        fokontany: "ambodisatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5845665,
          lng: 49.5789316,
        },
        _id: "64f75286ef8f606073e9c5bc",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "milanoa",
        fokontany: "ankazomahitsy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6132915,
          lng: 49.8240194,
        },
        _id: "64f75286ef8f606073e9c5bd",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "milanoa",
        fokontany: "ambodivoanio",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6211966,
          lng: 49.86496589999999,
        },
        _id: "64f75286ef8f606073e9c5be",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "milanoa",
        fokontany: "makira",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6379528,
          lng: 49.6605146,
        },
        _id: "64f75286ef8f606073e9c5bf",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "milanoa",
        fokontany: "anjavibe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6429746,
          lng: 49.7831009,
        },
        _id: "64f75286ef8f606073e9c5c0",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "milanoa",
        fokontany: "beambatry",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6885262,
          lng: 49.8240194,
        },
        _id: "64f75286ef8f606073e9c5c1",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "milanoa",
        fokontany: "antsahampiana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.466667,
          lng: 49.683333,
        },
        _id: "64f75286ef8f606073e9c5c2",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "bobakindro",
        fokontany: "bobakindro",
        __v: 0,
      },
      {
        coords: {
          lat: -13.383333,
          lng: 49.4166669,
        },
        _id: "64f75286ef8f606073e9c5c3",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "bobakindro",
        fokontany: "analavelona",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3977217,
          lng: 49.6299077,
        },
        _id: "64f75286ef8f606073e9c5c4",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "bobakindro",
        fokontany: "ampotakalanana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4846349,
          lng: 49.7166685,
        },
        _id: "64f75286ef8f606073e9c5c5",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "bobakindro",
        fokontany: "maromokotra salafaina",
        __v: 0,
      },
      {
        coords: {
          lat: -13.5056738,
          lng: 49.5585535,
        },
        _id: "64f75286ef8f606073e9c5c6",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "bobakindro",
        fokontany: "ambalavato",
        __v: 0,
      },
      {
        coords: {
          lat: -13.545478,
          lng: 49.4262684,
        },
        _id: "64f75286ef8f606073e9c5c7",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "bobakindro",
        fokontany: "ambohibada",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2038932,
          lng: 49.6617903,
        },
        _id: "64f75286ef8f606073e9c5c8",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "daraina",
        fokontany: "daraina",
        __v: 0,
      },
      {
        coords: {
          lat: -13.0649696,
          lng: 49.5993167,
        },
        _id: "64f75286ef8f606073e9c5c9",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "daraina",
        fokontany: "befarafara",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1137624,
          lng: 49.6605146,
        },
        _id: "64f75286ef8f606073e9c5ca",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "daraina",
        fokontany: "amparihirano",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3228742,
          lng: 49.6299077,
        },
        _id: "64f75286ef8f606073e9c5cb",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "daraina",
        fokontany: "ambatoharanana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2995336,
          lng: 49.5076392,
        },
        _id: "64f75286ef8f606073e9c5cc",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "daraina",
        fokontany: "antsoha",
        __v: 0,
      },
      {
        coords: {
          lat: -13.3629611,
          lng: 49.4974616,
        },
        _id: "64f75286ef8f606073e9c5cd",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "daraina",
        fokontany: "behorefo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.4116446,
          lng: 49.5076392,
        },
        _id: "64f75286ef8f606073e9c5ce",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "daraina",
        fokontany: "andrafiamena",
        __v: 0,
      },
      {
        coords: {
          lat: -13.74499,
          lng: 49.6943089,
        },
        _id: "64f75286ef8f606073e9c5cf",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambalasatrana",
        fokontany: "ambalasatrana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6709112,
          lng: 49.7575412,
        },
        _id: "64f75286ef8f606073e9c5d0",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambalasatrana",
        fokontany: "ambodimanga",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6686547,
          lng: 49.7217761,
        },
        _id: "64f75286ef8f606073e9c5d1",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambalasatrana",
        fokontany: "rangovato",
        __v: 0,
      },
      {
        coords: {
          lat: -13.669814,
          lng: 49.6554124,
        },
        _id: "64f75286ef8f606073e9c5d2",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambalasatrana",
        fokontany: "ambaniriana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7022573,
          lng: 49.7013486,
        },
        _id: "64f75286ef8f606073e9c5d3",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambalasatrana",
        fokontany: "ambatojoby",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7090769,
          lng: 49.6401083,
        },
        _id: "64f75286ef8f606073e9c5d4",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambalasatrana",
        fokontany: "masiaposa",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7253286,
          lng: 49.7473204,
        },
        _id: "64f75286ef8f606073e9c5d5",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambalasatrana",
        fokontany: "antsohihy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7429495,
          lng: 49.6452092,
        },
        _id: "64f75286ef8f606073e9c5d6",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambalasatrana",
        fokontany: "anandroka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7543392,
          lng: 49.9464947,
        },
        _id: "64f75286ef8f606073e9c5d7",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "tsarabaria",
        fokontany: "tsarabaria",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7155619,
          lng: 49.8905718,
        },
        _id: "64f75286ef8f606073e9c5d8",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "tsarabaria",
        fokontany: "andrafialava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7341306,
          lng: 49.86496589999999,
        },
        _id: "64f75286ef8f606073e9c5d9",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "tsarabaria",
        fokontany: "antanamandriry",
        __v: 0,
      },
      {
        coords: {
          lat: -13.785363,
          lng: 50.0803953,
        },
        _id: "64f75286ef8f606073e9c5da",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "tsarabaria",
        fokontany: "manakana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7392864,
          lng: 49.818903,
        },
        _id: "64f75286ef8f606073e9c5db",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "tsarabaria",
        fokontany: "ambodimanga IV",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7431293,
          lng: 50.0084998,
        },
        _id: "64f75286ef8f606073e9c5dc",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "tsarabaria",
        fokontany: "amboditsoha",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7695242,
          lng: 49.8291362,
        },
        _id: "64f75286ef8f606073e9c5dd",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "tsarabaria",
        fokontany: "analanana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7749455,
          lng: 49.8508874,
        },
        _id: "64f75286ef8f606073e9c5de",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "tsarabaria",
        fokontany: "antohomaro",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7820575,
          lng: 49.9418164,
        },
        _id: "64f75286ef8f606073e9c5df",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "tsarabaria",
        fokontany: "antsivolanana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7991855,
          lng: 49.7882142,
        },
        _id: "64f75286ef8f606073e9c5e0",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "tsarabaria",
        fokontany: "ankatoko",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1738947,
          lng: 49.7319925,
        },
        _id: "64f75286ef8f606073e9c5e1",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "nosibe",
        fokontany: "nosibe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.0697418,
          lng: 49.87520689999999,
        },
        _id: "64f75286ef8f606073e9c5e2",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "nosibe",
        fokontany: "anjiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1513637,
          lng: 49.7115614,
        },
        _id: "64f75286ef8f606073e9c5e3",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "nosibe",
        fokontany: "moronjolava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1820434,
          lng: 49.87520689999999,
        },
        _id: "64f75286ef8f606073e9c5e4",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "nosibe",
        fokontany: "beraja",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2338572,
          lng: 49.7524306,
        },
        _id: "64f75286ef8f606073e9c5e5",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "nosibe",
        fokontany: "antsahampano",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2397782,
          lng: 49.8086717,
        },
        _id: "64f75286ef8f606073e9c5e6",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "nosibe",
        fokontany: "bobatsirevo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.2867514,
          lng: 49.83425339999999,
        },
        _id: "64f75286ef8f606073e9c5e7",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "nosibe",
        fokontany: "mafokovo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8399579,
          lng: 49.5891233,
        },
        _id: "64f75286ef8f606073e9c5e8",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "andravory",
        fokontany: "andravory",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8137812,
          lng: 49.5993167,
        },
        _id: "64f75286ef8f606073e9c5e9",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "andravory",
        fokontany: "ambararatabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8705192,
          lng: 49.6248081,
        },
        _id: "64f75286ef8f606073e9c5ea",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "andravory",
        fokontany: "bearamanja",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8822871,
          lng: 49.6605146,
        },
        _id: "64f75286ef8f606073e9c5eb",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "andravory",
        fokontany: "antsahanandriana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8849342,
          lng: 49.5789316,
        },
        _id: "64f75286ef8f606073e9c5ec",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "andravory",
        fokontany: "maromokotra",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7408605,
          lng: 49.4655136,
        },
        _id: "64f75286ef8f606073e9c5ed",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "andrafainkona",
        fokontany: "andrafainkona",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6521848,
          lng: 49.5891233,
        },
        _id: "64f75286ef8f606073e9c5ee",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "andrafainkona",
        fokontany: "andomboka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6436363,
          lng: 49.4974616,
        },
        _id: "64f75286ef8f606073e9c5ef",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "andrafainkona",
        fokontany: "ampisarahana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.6958678,
          lng: 49.4771118,
        },
        _id: "64f75286ef8f606073e9c5f0",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "andrafainkona",
        fokontany: "ambodimandresy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7453938,
          lng: 49.5381824,
        },
        _id: "64f75286ef8f606073e9c5f1",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "andrafainkona",
        fokontany: "mahazava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8858301,
          lng: 49.9418164,
        },
        _id: "64f75286ef8f606073e9c5f2",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampanefena",
        fokontany: "ampanefena atsimo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8069085,
          lng: 49.9725841,
        },
        _id: "64f75286ef8f606073e9c5f3",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampanefena",
        fokontany: "antanambazaha",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8057719,
          lng: 49.9546343,
        },
        _id: "64f75286ef8f606073e9c5f4",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampanefena",
        fokontany: "marotongotra",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8334975,
          lng: 49.9879739,
        },
        _id: "64f75286ef8f606073e9c5f5",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampanefena",
        fokontany: "ambatojoby",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8197807,
          lng: 49.9418164,
        },
        _id: "64f75286ef8f606073e9c5f6",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampanefena",
        fokontany: "ambodimanga I",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8385028,
          lng: 49.9290011,
        },
        _id: "64f75286ef8f606073e9c5f7",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampanefena",
        fokontany: "ampanobe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8400673,
          lng: 49.9008172,
        },
        _id: "64f75286ef8f606073e9c5f8",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampanefena",
        fokontany: "antsahatsara",
        __v: 0,
      },
      {
        coords: {
          lat: -13.851944,
          lng: 49.9495068,
        },
        _id: "64f75286ef8f606073e9c5f9",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampanefena",
        fokontany: "ampanefena avaratra",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8560954,
          lng: 49.9828436,
        },
        _id: "64f75286ef8f606073e9c5fa",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampanefena",
        fokontany: "doanihely",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8703694,
          lng: 49.9110643,
        },
        _id: "64f75286ef8f606073e9c5fb",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampanefena",
        fokontany: "ambahavo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8938633,
          lng: 49.9828436,
        },
        _id: "64f75286ef8f606073e9c5fc",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampanefena",
        fokontany: "antanambaovao",
        __v: 0,
      },
      {
        coords: {
          lat: -13.866667,
          lng: 50.016667,
        },
        _id: "64f75286ef8f606073e9c5fd",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampanefena",
        fokontany: "ambodisaina",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9226368,
          lng: 49.86496589999999,
        },
        _id: "64f75286ef8f606073e9c5fe",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampanefena",
        fokontany: "antaninaomby",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8986755,
          lng: 49.9110643,
        },
        _id: "64f75286ef8f606073e9c5ff",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampanefena",
        fokontany: "antsahamanara",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8529093,
          lng: 49.8700862,
        },
        _id: "64f75286ef8f606073e9c600",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambodisambalahy",
        fokontany: "antsahavaribe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8152038,
          lng: 49.8700862,
        },
        _id: "64f75286ef8f606073e9c601",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambodisambalahy",
        fokontany: "vondronina",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8091978,
          lng: 49.839371,
        },
        _id: "64f75286ef8f606073e9c602",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambodisambalahy",
        fokontany: "anketrakabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8257772,
          lng: 49.8880107,
        },
        _id: "64f75286ef8f606073e9c603",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambodisambalahy",
        fokontany: "antsambalahy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8354583,
          lng: 49.8291362,
        },
        _id: "64f75286ef8f606073e9c604",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambodisambalahy",
        fokontany: "ambodihasina",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8554629,
          lng: 49.8470483,
        },
        _id: "64f75286ef8f606073e9c605",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambodisambalahy",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8808847,
          lng: 49.8444891,
        },
        _id: "64f75286ef8f606073e9c606",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambodisambalahy",
        fokontany: "ambodivohitra",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8717674,
          lng: 49.8700862,
        },
        _id: "64f75286ef8f606073e9c607",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambodisambalahy",
        fokontany: "antsahorana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8916854,
          lng: 49.8035566,
        },
        _id: "64f75286ef8f606073e9c608",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambodisambalahy",
        fokontany: "ankitsika",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8345299,
          lng: 49.7524306,
        },
        _id: "64f75286ef8f606073e9c609",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambinanin'andravory",
        fokontany: "ambinanin'andravory",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7407188,
          lng: 49.77798809999999,
        },
        _id: "64f75286ef8f606073e9c60a",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambinanin'andravory",
        fokontany: "morafeno",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8005837,
          lng: 49.7473204,
        },
        _id: "64f75286ef8f606073e9c60b",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambinanin'andravory",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8419343,
          lng: 49.7422107,
        },
        _id: "64f75286ef8f606073e9c60c",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambinanin'andravory",
        fokontany: "andrafialava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8943968,
          lng: 49.7217761,
        },
        _id: "64f75286ef8f606073e9c60d",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambinanin'andravory",
        fokontany: "anjana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9280234,
          lng: 49.7013486,
        },
        _id: "64f75286ef8f606073e9c60e",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambinanin'andravory",
        fokontany: "befontsy",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9549619,
          lng: 49.7422107,
        },
        _id: "64f75286ef8f606073e9c60f",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ambinanin'andravory",
        fokontany: "behatsiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7805266,
          lng: 49.4738018,
        },
        _id: "64f75286ef8f606073e9c610",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "amboriala",
        fokontany: "amboriala",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7765684,
          lng: 49.4821986,
        },
        _id: "64f75286ef8f606073e9c611",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "amboriala",
        fokontany: "antsirabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.7963024,
          lng: 49.41610499999999,
        },
        _id: "64f75286ef8f606073e9c612",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "amboriala",
        fokontany: "marovovonana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8860399,
          lng: 49.3957836,
        },
        _id: "64f75286ef8f606073e9c613",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "amboriala",
        fokontany: "andravany",
        __v: 0,
      },
      {
        coords: {
          lat: -13.8989568,
          lng: 49.5076392,
        },
        _id: "64f75286ef8f606073e9c614",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "amboriala",
        fokontany: "fotsialanana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.01835,
          lng: 49.48481,
        },
        _id: "64f75286ef8f606073e9c615",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "maromokotra loky",
        fokontany: "maromokotra loky",
        __v: 0,
      },
      {
        coords: {
          lat: -12.8377216,
          lng: 49.5789316,
        },
        _id: "64f75286ef8f606073e9c616",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "maromokotra loky",
        fokontany: "anahimalandy loky",
        __v: 0,
      },
      {
        coords: {
          lat: -12.9122758,
          lng: 49.6299077,
        },
        _id: "64f75286ef8f606073e9c617",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "maromokotra loky",
        fokontany: "antserasera loky",
        __v: 0,
      },
      {
        coords: {
          lat: -12.9417836,
          lng: 49.5381824,
        },
        _id: "64f75286ef8f606073e9c618",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "maromokotra loky",
        fokontany: "tanambao loky",
        __v: 0,
      },
      {
        coords: {
          lat: -13.1277703,
          lng: 49.4872858,
        },
        _id: "64f75286ef8f606073e9c619",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "maromokotra loky",
        fokontany: "ankijomantsina",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9650746,
          lng: 49.9688822,
        },
        _id: "64f75286ef8f606073e9c61a",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "antsirabe nord",
        fokontany: "antsirabe nord I",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9047092,
          lng: 49.9418164,
        },
        _id: "64f75286ef8f606073e9c61b",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "antsirabe nord",
        fokontany: "manasamody",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9387638,
          lng: 49.9469432,
        },
        _id: "64f75286ef8f606073e9c61c",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "antsirabe nord",
        fokontany: "tsarahitra",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9359765,
          lng: 50.0290326,
        },
        _id: "64f75286ef8f606073e9c61d",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "antsirabe nord",
        fokontany: "antsikory",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9384455,
          lng: 49.92131329999999,
        },
        _id: "64f75286ef8f606073e9c61e",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "antsirabe nord",
        fokontany: "antafiambe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.943852,
          lng: 49.9008172,
        },
        _id: "64f75286ef8f606073e9c61f",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "antsirabe nord",
        fokontany: "antanandava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.966667,
          lng: 49.866667,
        },
        _id: "64f75286ef8f606073e9c620",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "antsirabe nord",
        fokontany: "antsahanonoka",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9869685,
          lng: 50.0238988,
        },
        _id: "64f75286ef8f606073e9c621",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "antsirabe nord",
        fokontany: "ankiabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.963386,
          lng: 49.9520705,
        },
        _id: "64f75286ef8f606073e9c622",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "antsirabe nord",
        fokontany: "antsirabe nord II",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9761216,
          lng: 50.064982,
        },
        _id: "64f75286ef8f606073e9c623",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "antsirabe nord",
        fokontany: "ambalihabe",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9775721,
          lng: 49.8803281,
        },
        _id: "64f75286ef8f606073e9c624",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "antsirabe nord",
        fokontany: "andampilava",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9954441,
          lng: 49.9469432,
        },
        _id: "64f75286ef8f606073e9c625",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "antsirabe nord",
        fokontany: "ankampila",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0167051,
          lng: 49.9828436,
        },
        _id: "64f75286ef8f606073e9c626",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "antsirabe nord",
        fokontany: "ambodipont",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0251355,
          lng: 49.9059405,
        },
        _id: "64f75286ef8f606073e9c627",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "antsirabe nord",
        fokontany: "antsirakiaka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0592242,
          lng: 49.9110643,
        },
        _id: "64f75286ef8f606073e9c628",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "antsirabe nord",
        fokontany: "lavarojo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.033333,
          lng: 50,
        },
        _id: "64f75286ef8f606073e9c629",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "antsirabe nord",
        fokontany: "antanamiketraka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0981212,
          lng: 49.9879739,
        },
        _id: "64f75286ef8f606073e9c62a",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "antsirabe nord",
        fokontany: "andravinambo",
        __v: 0,
      },
      {
        coords: {
          lat: -14.1048216,
          lng: 49.92643839999999,
        },
        _id: "64f75286ef8f606073e9c62b",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "antsirabe nord",
        fokontany: "sarahandrano",
        __v: 0,
      },
      {
        coords: {
          lat: -12.8752328,
          lng: 49.7319925,
        },
        _id: "64f75286ef8f606073e9c62c",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampisikinana",
        fokontany: "ampisikinana",
        __v: 0,
      },
      {
        coords: {
          lat: -12.8752328,
          lng: 49.7319925,
        },
        _id: "64f75286ef8f606073e9c62d",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampisikinana",
        fokontany: "antsampilay",
        __v: 0,
      },
      {
        coords: {
          lat: -12.99217,
          lng: 49.62307,
        },
        _id: "64f75286ef8f606073e9c62e",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampisikinana",
        fokontany: "ambararatamisakana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.0094122,
          lng: 49.7013486,
        },
        _id: "64f75286ef8f606073e9c62f",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampisikinana",
        fokontany: "tsaratanana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.0543508,
          lng: 49.7422107,
        },
        _id: "64f75286ef8f606073e9c630",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "ampisikinana",
        fokontany: "ampangia",
        __v: 0,
      },
      {
        coords: {
          lat: -14.02278,
          lng: 49.855171,
        },
        _id: "64f75286ef8f606073e9c631",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "belambo",
        fokontany: "belambo",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9593596,
          lng: 49.7882142,
        },
        _id: "64f75286ef8f606073e9c632",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "belambo",
        fokontany: "befiana",
        __v: 0,
      },
      {
        coords: {
          lat: -13.9522936,
          lng: 49.8240194,
        },
        _id: "64f75286ef8f606073e9c633",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "belambo",
        fokontany: "bemanasy",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0146382,
          lng: 49.8291362,
        },
        _id: "64f75286ef8f606073e9c634",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "belambo",
        fokontany: "maroangezoka",
        __v: 0,
      },
      {
        coords: {
          lat: -14.0153418,
          lng: 49.8803281,
        },
        _id: "64f75286ef8f606073e9c635",
        province: "antsiranana",
        region: "sava",
        district: "vohemar",
        commune: "belambo",
        fokontany: "ambodimanga",
        __v: 0,
      },
    ];

    // Extract unique combinations of region and district
    const uniqueRegionDistricts = Array.from(
      new Set(
        json.map((location) =>
          JSON.stringify({
            province: location.province,
            region: location.region,
            district: location.district,
          })
        )
      ),
      JSON.parse
    );

    // Extract unique combinations of region and district
    const uniqueCommunes = Array.from(
      new Set(
        json.map((location) =>
          JSON.stringify({
            province: location.province,
            region: location.region,
            district: location.district,
            commune: location.commune,
          })
        )
      ),
      JSON.parse
    );

    return {
      fokotanyList: json,
      communeList: uniqueCommunes,
      districtList: uniqueRegionDistricts,
    };
  };

  return { loadMap };
};
