import { useRedux } from "./useRedux";
import { useLocation } from "wouter";

export const useLogout = () => {
  const { resetReduxStore } = useRedux();
  const [location, setLocation] = useLocation();
  console.log(location);

  const logout = () => {
    //remove user from localStorage
    localStorage.removeItem("user");
    localStorage.removeItem("signup");

    //update the user redux state
    resetReduxStore();
    setLocation("/loader");
  };

  return { logout };
};


