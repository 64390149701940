import MyListingDetails from "../components/MyListingDetails";
import { Link } from "wouter";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, useDispatch } from "react-redux";
import {
  setPreviousUrl,
  resetImg,
  resetImgPreview,
  setSelectedLocationForUpdate,
} from "../redux/redux";
import { useEffect, useState } from "react";
import NotLogedIn from "../components/NotLogedIn";
import { MdAddCircleOutline } from "react-icons/md";
import { useLoader } from "../hooks/useLoader";

const ImageUpload = () => {
  // redux
  const usersProperties = useSelector((state) => state.usersProperties);
  const pagination = useSelector((state) => state.pagination);
  const user = useSelector((state) => state.user);
  const { loadUsersProperties } = useLoader();
  const dispatch = useDispatch();
  const [oneTimeTask, setOneTimeTask] = useState(null);

  //react-infinite-scroll-component states
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [propertiesSlice, setPropertiesSlice] = useState(
    usersProperties ? usersProperties.slice(startIndex, endIndex) : []
  );

  if (oneTimeTask === null) {
    if (pagination[0].previousUrl === "/update-property") {
      dispatch(setPreviousUrl(null));
      dispatch(resetImg());
      dispatch(resetImgPreview());
    }
    dispatch(setSelectedLocationForUpdate({ selectedLocation: null }));
    setOneTimeTask("done");
  }

  const fetchMoreData = () => {
    var newEndIndex = endIndex + 2;
    var newSlice = usersProperties.slice(endIndex, newEndIndex);
    console.log(newSlice);
    if (newSlice.length === 0) {
      setHasMore(false);
    } else {
      setPropertiesSlice(propertiesSlice.concat(newSlice));
      setStartIndex(endIndex);
      setEndIndex(newEndIndex);
      console.log(newEndIndex);
    }
  };

  useEffect(() => {
    const pageLoader = () => {
      if (!usersProperties && user) {
        loadUsersProperties(user._id);
      }
      console.log(usersProperties);
    };
    pageLoader();
  }, [usersProperties]);

  return (
    <div className="mylisting">
      {user && user ? (
        <div className="mylisting mt-5 pt-1">
          <div className="site-section site-section-sm bg-light">
            <div className="custom-container" style={{ paddingBottom: "80px" }}>
              <div className="mb-5 d-flex align-items-center justify-content-between mb-3">
                <h6 className="font-weight-light text-uppercase mt-1">
                  Vos annonces :
                </h6>
                <Link
                  to="/create-listing"
                  style={{ borderRadius: "15px" }}
                  className="btn btn-sm btn-success"
                >
                  <MdAddCircleOutline className="mb-1" /> Créer une annonce
                </Link>
              </div>
              {usersProperties && usersProperties.length === 0 ? (
                <>
                  <div className="mt-5 no-booking d-flex justify-content-center align-items-center">
                    <img
                      src="images/create-ad.jpg"
                      style={{ maxHeight: "45vh" }}
                      alt="Pas de connexion Internet"
                      className="img-fluid"
                    />
                  </div>
                  <center>
                    {" "}
                    <p style={{ fontWeight: "400" }} className="m-2">
                      Créez votre annonce dès maintenant et trouvez le parfait
                      acheteur ou locataire pour votre propriété !
                    </p>
                  </center>
                </>
              ) : (
                <InfiniteScroll
                  className="row"
                  dataLength={propertiesSlice.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<h4>Loading...</h4>}
                  endMessage={
                    <center>
                      <b>Yay! You have seen it all</b>
                    </center>
                  }
                >
                  {propertiesSlice &&
                    propertiesSlice.map(
                      (property) =>
                        property && (
                          <MyListingDetails
                            key={property._id}
                            property={property}
                          />
                        )
                    )}
                </InfiniteScroll>
              )}
            </div>
          </div>
        </div>
      ) : (
        <NotLogedIn />
      )}
    </div>
  );
};

export default ImageUpload;
