import Swal from "sweetalert2";

export const usePopup = () => {
  const featureUnderConstructionPopup = async () => {
    Swal.fire({
      html: `<img src="images/WorkingOn.jpg" alt="please log in" class="img-fluid"><br>  <p style={{ fontWeight: "400" }}>
        Nos développeurs travaillent actuellement sur cette fonctionnalité,
        qui sera bientôt disponible. Nous nous excusons pour la gêne occasionnée et vous remercions de
        votre patience.
      </p>`,
      showCloseButton: true,
      focusConfirm: false,
      confirmButtonText: "ok",
      confirmButtonColor: "#7cbd1e",
      customClass: {
        popup: "smaller-sweet-alert",
      },
    });
  };

  return {
    featureUnderConstructionPopup,
  };
};
