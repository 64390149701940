export const useSMS = () => {
  const sendSMS = async (number, message) => {
    // Remove the leading "0" from the phone number
    const phoneNumberWithoutLeadingZero = number.replace(/^0+/, "");

    // Add the madagascar country code "+261" at the beginning of the phone number
    const formattedPhoneNumber = "+261" + phoneNumberWithoutLeadingZero;

    let apiKey =
      "KQRkcyk2nB5-_8wGNait_mKLeBnmblNG06hP6x3iZaGcsa6gFuJ9K3PhcxOzvRxq";

    const json = await fetch("https://api.httpsms.com/v1/messages/send", {
      method: "POST",
      headers: {
        "x-api-key": apiKey,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        content: message,
        from: "+261345731292",
        to: formattedPhoneNumber,
      }),
    });

    const response = await json.json();
    console.log(response.data);
    console.log("status de la requet:" + response.data.status);
  };
  return { sendSMS };
};
