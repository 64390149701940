import { BsFillCheckCircleFill } from "react-icons/bs";

function NotificationCardDetails({ notification }) {
  const formattedDate = new Intl.DateTimeFormat("fr-FR", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "Indian/Antananarivo", 
  }).format(new Date(notification.created_at));

  let paymentInfo;
  if (notification.payment) {
    paymentInfo = {
      operator:
        notification.payment.type === "Airtel money"
          ? "airtel"
          : notification.payment.type === "Mvola"
          ? "telma"
          : notification.payment.type === "Orange money" && "orange",
    };
  }

  return (
    <div
      className={
        notification.confirmedBy
          ? notification.errorStatus
            ? "notification-list notification-list--error"
            : "notification-list notification-list--success"
          : "notification-list notification-list--unread"
      }
    >
      <div className="notification-list_content">
        <div className="notification-list_img">
          <img
            src={
              notification.confirmedBy
                ? notification.confirmedBy.avatar
                : "images/user-avatar.png"
            }
            className="border border-dark"
            alt="user"
          />
        </div>
        <div className="notification-list_detail">
          <small>
            <p>
              <b>
                {notification.confirmedBy
                  ? notification.confirmedBy.name +
                    " " +
                    notification.confirmedBy.firstname
                  : "Tranogasy"}
              </b>{" "}
              <small
                className={
                  notification.confirmedBy ? "text-success" : "text-sky-primary"
                }
              >
                <BsFillCheckCircleFill /> {notification.confirmedBy && "Admin"}
              </small>
            </p>
            <p className="text-muted">
              {notification.message && notification.message}
            </p>
            <p className="text-muted">
              <small>{notification.created_at && formattedDate}</small>
            </p>
          </small>
        </div>
      </div>

      <div className="align-items-left notification-list_feature-img mr-1">
        <img
          src={`images/${paymentInfo.operator}-avatar.jpg`}
          alt="Feature image"
        />
      </div>
    </div>
  );
}

export default NotificationCardDetails;
