import { useDispatch } from "react-redux";
import {
  setUser,
  setSignUp,
  setLoader,
  setNavbar,
  setTopNavbar,
  resetPagination,
  setTopProperties,
  setProperties,
  setNotifications,
  setNotificationReadingStatus,
  setNotificationCounterStatus,
  resetLikedPropreties,
  setUsersProperties,
  setTimer,
  resetAccountRecovery,
} from "../redux/redux";

export const useRedux = () => {
  const dispatch = useDispatch();

  const resetReduxStore = () => {
    console.log("resetimg redux Store");
    // reset the connected user state
    dispatch(setUser(null));
    // reset the user's timer state
    dispatch(setTimer(null));
    // reset the signup waitlist
    dispatch(setSignUp(null));
    // reset the loader state into undone
    dispatch(setLoader(null));
    // reset the navbar state into onshow
    dispatch(setNavbar(true));
    dispatch(setTopNavbar(true));
    // Reset pagination global state
    dispatch(resetPagination());
    // Resest topProperties state
    dispatch(setTopProperties(null));
    //Reset the initial property list
    dispatch(setProperties(null));
    //Reset the initial notifications list
    dispatch(setNotifications(null));
    dispatch(setNotificationReadingStatus(null));
    dispatch(setNotificationCounterStatus(0));
    //Reset the liked property list
    dispatch(resetLikedPropreties());
    //Reset the user's listing
    dispatch(setUsersProperties(null));
    dispatch(resetAccountRecovery());
  };
  return { resetReduxStore };
};
